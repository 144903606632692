import { useCallback } from 'react';
import useIsClientDischargeV3OnForPlan from 'ts-frontend/hooks/useIsClientDischargeV3OnForPlan';
import {
  TextDS,
  TouchableView,
  View,
  useEmotionTheme,
  useWindowWidthState,
  spacing,
} from '@talkspace/react-toolkit';

import { CircleExclamation, ChevronRight } from '@talkspace/react-toolkit/src/designSystems/icons';
import useOpenModalSafe from '@/hooks/useOpenModalSafe';

const { space100 } = spacing;

interface Props {
  roomID: number;
  isClosedUpdatedCoverage: boolean;
}

const EligibilityWarningBanner = ({ roomID, isClosedUpdatedCoverage }: Props) => {
  const { colors, colorRoles } = useEmotionTheme();
  const openModal = useOpenModalSafe();
  const { isLarge, isMobile } = useWindowWidthState();
  const isClientDischargeV3OnForPlan = useIsClientDischargeV3OnForPlan(roomID);

  const isClosed = isClosedUpdatedCoverage && !isClientDischargeV3OnForPlan;
  const subtitle = isClosed
    ? 'This room was closed due to insurance updates'
    : 'Update your coverage or payment method to continue your care';
  const isClickable = !isClosed;
  const onPress = isClickable ? () => openModal(`/eligibility-warning/${roomID}`) : undefined;

  const getBannerTitle = useCallback(() => {
    if (isClientDischargeV3OnForPlan) {
      return 'Coverage expired';
    }
    if (isClosedUpdatedCoverage) {
      return 'This room is closed';
    }
    return 'Insurance coverage expired';
  }, [isClientDischargeV3OnForPlan, isClosedUpdatedCoverage]);

  const title = getBannerTitle();
  return (
    <TouchableView
      align="center"
      justify="center"
      dataQa="chatBannerIneligibleInsurance"
      onPress={onPress}
      style={{
        width: '100%',
        backgroundColor: isClosed
          ? colorRoles.surfaces.neutralDefault
          : colorRoles.surfaces.criticalSubtleDefault,
        padding: '16px 52px',
        borderRadius: '0 0 8px 8px',
        boxShadow: `0 12px 20px 0 ${colors.TSBlack}14`,
        cursor: isClickable ? 'pointer' : 'default',
      }}
    >
      <View row align="center">
        <View row>
          {!isClosed && (
            <View style={{ marginRight: space100 }}>
              <CircleExclamation colorType="critical" />
            </View>
          )}
          <View style={{ maxWidth: isMobile || isLarge ? 240 : undefined }}>
            <TextDS
              variant="headingMd"
              colorRole={isClosed ? 'textDefault' : 'textCriticalDefault'}
            >
              {title}
            </TextDS>
            <TextDS variant="bodySm" colorRole="textSubtle">
              {subtitle}
            </TextDS>
          </View>
        </View>
        {isClickable && (
          <View style={{ position: 'absolute', right: 24 }}>
            <ChevronRight size="small" />
          </View>
        )}
      </View>
    </TouchableView>
  );
};

export default EligibilityWarningBanner;
