import { FunctionComponent } from 'react';
import * as React from 'react';
import { View, Text, useEmotionTheme } from '@talkspace/react-toolkit';

const ProfessionalInfo: FunctionComponent<{
  icon: React.ReactNode;
  title?: string;
  info?: string;
}> = ({ icon, title, info, children }) => {
  const { colors } = useEmotionTheme();
  return (
    <View
      row
      style={{
        marginTop: 5,
        marginBottom: 10,
      }}
    >
      <View>{icon}</View>
      <View
        flex={4}
        align="start"
        style={{
          marginLeft: 10,
        }}
      >
        {!!title && (
          <Text
            style={{
              color: colors.placeholderGrey,
              marginBottom: 5,
            }}
          >
            {title}
          </Text>
        )}
        <Text>{info || children}</Text>
      </View>
    </View>
  );
};
export default ProfessionalInfo;
