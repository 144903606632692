import { useEffect } from 'react';
import {
  ID_CLINICAL_PROGRESS_PANEL,
  ID_ACCOUNT_DETAILS_PANEL,
  ID_MANAGE_SUBSCRIPTION_PANEL,
  ID_NOTIFICATIONS_PANEL,
  ID_STARRED_MESSAGES_PANEL,
  ID_SHARED_FILES_PANEL,
  ID_PERSONAL_INFORMATION_PANEL,
} from '../../utils/IDConstants';

// programmatically focuses the main panel when the contents change for best keyboard and screen reader interaction
export const useA11y = (
  dashID: string,
  chatID: string,
  ID_MAIN_PANEL: string,
  isLeftPanelOnTop: boolean,
  isMobile: boolean,
  isChatHidden: boolean,
  isRightPanelOpen: boolean,
  pathname: string,
  isNewNav: boolean
) => {
  useEffect(() => {
    if (isNewNav) {
      return;
    }
    const dashBoardPanel = document.getElementById(dashID);
    const chatPanel = document.getElementById(chatID);
    const mainPanel = document.getElementById(ID_MAIN_PANEL);

    if (isLeftPanelOnTop && isMobile && dashBoardPanel) {
      dashBoardPanel.focus();
    } else if (chatPanel && !isChatHidden && !isRightPanelOpen) {
      chatPanel.focus();
    } else if (mainPanel && isRightPanelOpen && isChatHidden) {
      mainPanel.focus();
    }
  }, [
    isLeftPanelOnTop,
    isMobile,
    isChatHidden,
    isRightPanelOpen,
    dashID,
    chatID,
    ID_MAIN_PANEL,
    isNewNav,
  ]);

  let rightPanelId = ID_MAIN_PANEL;
  if (pathname.includes('clinical-progress')) {
    rightPanelId = ID_CLINICAL_PROGRESS_PANEL;
  } else if (pathname.includes('manage-subscription')) {
    rightPanelId = ID_MANAGE_SUBSCRIPTION_PANEL;
  } else if (pathname.includes('notifications')) {
    rightPanelId = ID_NOTIFICATIONS_PANEL;
  } else if (pathname.includes('account-details')) {
    rightPanelId = ID_ACCOUNT_DETAILS_PANEL;
  } else if (pathname.includes('personal-information')) {
    rightPanelId = ID_PERSONAL_INFORMATION_PANEL;
  } else if (pathname.includes('starred-messages')) {
    rightPanelId = ID_STARRED_MESSAGES_PANEL;
  } else if (pathname.includes('shared-files')) {
    rightPanelId = ID_SHARED_FILES_PANEL;
  }

  return { rightPanelId };
};

export default useA11y;
