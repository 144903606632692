import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const Heart = ({
  width = 15,
  height = 13,
  color,
  ...props
}: {
  width?: number;
  height?: number;
  color?: string;
}) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg width={width} height={height} viewBox="0 0 15 13" {...props}>
      <Path
        d="M7.47 13.857c-.294.008-2.603-1.462-4.825-3.524-6.51-6.044.55-12.597 4.564-7.51a.372.372 0 00.578-.005c3.96-5.05 11.216 1.421 4.425 7.64-2.207 2.023-4.45 3.39-4.743 3.4z"
        transform="translate(-729 -1137) translate(729 1136)"
        fill={color || colors.heartRed}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default Heart;
