import { useQuery } from 'react-query';

import { Booking } from 'ts-frontend/types';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { clientBookingQueryKey } from '../utils/queryKeys';

interface QueryProps {
  roomID: string;
  bookingID: string;
}
type QueryData = Booking;

const fetchClientBooking =
  ({ roomID, bookingID }: QueryProps) =>
  async () => {
    const {
      data: { data },
    } = await apiWrapper.get(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/bookings/${bookingID}`);
    return data;
  };

const useQueryClientBooking = ({ roomID, bookingID }: QueryProps) =>
  useQuery<QueryData, Error>({
    queryKey: clientBookingQueryKey({ roomID, bookingID }),
    queryFn: fetchClientBooking({ roomID, bookingID }),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(roomID && bookingID),
  });

export default useQueryClientBooking;
