import Svg, { G, Rect, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const EmailEnvelope = ({ width = 74, height = 58, strokeWidth = 5, ...props }) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg width={width} height={height} viewBox="0 0 74 58" {...props}>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Rect
          stroke={colors.cobaltGrey}
          strokeWidth={strokeWidth}
          fill={colors.white}
          x={2.5}
          y={2.5}
          width={width - strokeWidth}
          height={height - strokeWidth}
          rx={12}
        />
        <Path
          stroke={colors.lightGray}
          strokeWidth={4}
          strokeLinecap="round"
          d="M14 15.5L37.46 36.5 60 15.5"
        />
      </G>
    </Svg>
  );
};

export default EmailEnvelope;
