import { useReducer, useCallback, useEffect, createContext, useRef, useContext, FC } from 'react';
import { prepareDataByTimezone } from 'ts-frontend/helpers';
import { useTSAdminConfig } from 'ts-frontend/hooks/useTSAdminConfig';
import { AdminConfigName } from 'ts-frontend/types';
import MeetYourProviderApiHelper from '../utils/meetYourProviderApiHelper';
import ApiHelper from '../../utils/ApiHelper';
import { State, initialState, meetYourProviderReducer } from '../reducers/meetYourProvider';
import { getUserData } from '../../auth/helpers/token';
import { useHistory, useLocation } from '../../core/routerLib';
import ActionStatus from '../../components/ActionStatus';
import MeetYourProviderFullScreenWrapper from '../components/MeetYourProviderWrapper';

export interface MeetYourProviderActions {
  getTherapistInfoAction: (therapistID: number) => void;
  getInformedConsentAction: (
    roomID: number,
    therapistID: number,
    informedConsentID: number
  ) => void;
  getInformedConsentV2StatusAction: () => void;
  putAgreeToInformedConsentV2: (userID: number) => Promise<void>;
  setAgreedToInformedConsentAction: () => void;
}

export const MeetYourProviderStateContext = createContext<State | undefined>(undefined);
export const MeetYourProviderActionsContext = createContext<MeetYourProviderActions | undefined>(
  undefined
);

type MeetYourProviderProps = {
  isOnboarding?: boolean;
};

export const MeetYourProviderProvider: FC<MeetYourProviderProps> = ({ children, isOnboarding }) => {
  const location = useLocation();
  const history = useHistory();
  const [state, dispatch] = useReducer(meetYourProviderReducer, initialState);
  const apiRef = useRef(new ApiHelper());
  const { current: api } = apiRef;

  const stateRef = useRef(state);
  stateRef.current = state;

  function setState(partialState: Partial<State>) {
    dispatch({
      type: 'setState',
      payload: partialState,
    });
  }

  const getCustomerInformation = useCallback(() => {
    const { id } = getUserData();
    MeetYourProviderApiHelper.getCustomerInformation(id)
      .then((response) => {
        // Hide availability tab for UK clients
        dispatch({
          type: 'receiveCustomerInformation',
          payload: { showAvailability: response.data.data.countryState !== 'GB' },
        });
      })
      .catch(() => {
        dispatch({
          type: 'setError',
        });
      });
  }, []);

  const getInformedConsent = useCallback(
    (roomID: number, therapistID: number, informedConsentID: number) => {
      dispatch({ type: 'requestGetInformedConsent' });
      return api
        .getInformedConsent(roomID, therapistID, undefined, undefined, informedConsentID)
        .then((response) => {
          dispatch({
            type: 'receiveGetInformedConsent',
            payload: {
              informedConsent: response,
            },
          });
        })
        .catch(() => {
          dispatch({
            type: 'setError',
          });
        });
    },
    [api]
  );

  const getInformedConsentV2Status = useCallback(() => {
    const { id: userID } = getUserData();
    dispatch({ type: 'requestGetInformedConsentV2' });
    return api
      .getInformedConsentV2Status(userID)
      .then((response) => {
        dispatch({
          type: 'receiveGetInformedConsentV2',
          payload: {
            informedConsentV2: response,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: 'setError',
        });
      });
  }, [api]);

  const getTherapistInfo = useCallback(
    async (therapistID: number) => {
      dispatch({ type: 'requestGetTherapistInfo' });
      try {
        const response = await MeetYourProviderApiHelper.getTherapistInfo(therapistID);
        const { businessHours } = await api.getImplicitBusinessHours(therapistID);
        dispatch({
          type: 'receiveGetTherapistInfo',
          payload: {
            therapist: {
              ...response.data.data,
              implicitBusinessHoursByDay: prepareDataByTimezone(businessHours),
            },
          },
        });
      } catch {
        dispatch({ type: 'setError' });
      }
    },
    [api]
  );

  const setAgreedToInformedConsent = useCallback(() => {
    dispatch({
      type: 'receivePostAgreeToInformedConsent',
      payload: { signedInformedConsent: true },
    });
  }, []);

  useEffect(() => {
    getCustomerInformation();
  }, [getCustomerInformation]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.toString() !== '') {
      const initState = async () => {
        const queryParamInformedConsentID = Number(queryParams.get('informedConsentID'));
        const queryParamRoomID = Number(queryParams.get('roomID'));
        const queryParamTherapistID = Number(queryParams.get('therapistID')) || null;
        const queryParamShowProviderInfo = queryParams.get('showProviderInfo');
        await getInformedConsentV2Status();
        if (queryParamShowProviderInfo) {
          const showProviderTruthy =
            queryParamShowProviderInfo === 'true' || queryParamShowProviderInfo === '1';
          setState({ showProviderInfo: showProviderTruthy });
        }
        if (queryParamRoomID) {
          setState({ roomID: queryParamRoomID });
        }
        if (queryParamTherapistID) {
          setState({ therapistID: queryParamTherapistID });
          await getTherapistInfo(queryParamTherapistID);
        }
        if (queryParamInformedConsentID && queryParamTherapistID) {
          await getInformedConsent(
            queryParamRoomID,
            queryParamTherapistID,
            queryParamInformedConsentID
          );
        }
        setState({ isReady: true });
      };
      initState();
    }
  }, [location.search, getInformedConsent, getTherapistInfo, getInformedConsentV2Status]);

  const {
    data: treatmentIntakeInOnboardingActive,
    isSuccess: isSuccessTreatmentIntakeInOnboardingActive,
  } = useTSAdminConfig(AdminConfigName.TREATMENT_INTAKE_IN_ONBOARDING);

  useEffect(() => {
    if (
      state.isReady &&
      isSuccessTreatmentIntakeInOnboardingActive &&
      !treatmentIntakeInOnboardingActive &&
      !isOnboarding
    ) {
      if (stateRef.current.showProviderInfo) {
        history.push('/meet-your-provider/profile');
      } else {
        history.push('/meet-your-provider/informed-consent');
      }
    }
  }, [
    state.isReady,
    history,
    treatmentIntakeInOnboardingActive,
    isSuccessTreatmentIntakeInOnboardingActive,
    isOnboarding,
  ]);

  function putAgreeToInformedConsentV2(userID: number) {
    dispatch({ type: 'requestPutAgreeToInformedConsentV2' });
    return api
      .agreeToInformedConsentV2(userID)
      .then((success) => {
        if (!success) {
          throw new Error('Informed consent v2 was not sent successfully.');
        }
      })
      .catch((error) => {
        dispatch({
          type: 'setError',
          error,
        });
      });
  }

  const actions = {
    getTherapistInfoAction: getTherapistInfo,
    getInformedConsentAction: getInformedConsent,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    putAgreeToInformedConsentV2: useCallback(putAgreeToInformedConsentV2, []),
    setAgreedToInformedConsentAction: setAgreedToInformedConsent,
    getInformedConsentV2StatusAction: getInformedConsentV2Status,
  };

  if (state.error || state.isLoading) {
    return (
      <MeetYourProviderFullScreenWrapper>
        <ActionStatus
          isLoading={state.isLoading}
          isError={!!state.error}
          errorTitle="Something Went Wrong"
          errorSubTitle="Please check your internet connection and try again."
          errorButtonText="Close"
        />
      </MeetYourProviderFullScreenWrapper>
    );
  }

  return (
    <MeetYourProviderStateContext.Provider value={state}>
      <MeetYourProviderActionsContext.Provider value={actions}>
        {children}
      </MeetYourProviderActionsContext.Provider>
    </MeetYourProviderStateContext.Provider>
  );
};

export function useMeetYourProviderState() {
  const context = useContext(MeetYourProviderStateContext);
  if (context === undefined)
    throw new Error('useMeetYourProviderState must be used within a ContextProvider');
  return context;
}

export function useMeetYourProviderActions() {
  const context = useContext(MeetYourProviderActionsContext);
  if (context === undefined)
    throw new Error('useMeetYourProviderActions must be used within a ContextProvider');
  return context;
}
