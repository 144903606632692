/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: @Eric remove above line
import { useFormContext } from 'react-hook-form';
import A11yPhoneInput, { A11yPhoneInputProps } from '../A11yPhoneInput';

// Temporary fix until https://github.com/react-hook-form/react-hook-form/discussions/6665
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Path<T> = any;

interface RHFPhoneInputProps<
  TFieldValues extends Record<string, string>,
  FieldName extends Path<TFieldValues> = Path<TFieldValues>
> extends A11yPhoneInputProps {
  fieldName: FieldName;
  isRequired?: boolean;
}

const RHFPhoneInput = <TFieldValues extends Record<string, string>>(
  props: RHFPhoneInputProps<TFieldValues>
) => {
  const {
    fieldName,
    isRequired = true,
    handleInputValueChange,
    containerStyle,
    style,
    labelStyle,
    errorStyle,
    ...otherProps
  } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext<TFieldValues>();
  const isError = !!errors[fieldName];
  return (
    <A11yPhoneInput
      {...otherProps}
      isReactHookForm
      dataQa={`${fieldName}Input`}
      control={control}
      name={fieldName}
      errorMessage={errors[fieldName]?.message}
      isError={isError}
      rules={{ isRequired }}
      required={isRequired}
      handleInputValueChange={handleInputValueChange}
      containerStyle={{ marginBottom: 12, ...containerStyle }}
      style={style}
      labelStyle={labelStyle}
      errorStyle={errorStyle}
    />
  );
};

export default RHFPhoneInput;
