import { formatCurrency } from 'ts-frontend/helpers/billingUtils';
import { RoomStatus } from 'ts-frontend/entities/Room';
import { WizardGenericActions } from 'stepWizard';
import { OfferApiHelper } from 'offer';
import financialAidWizardApiHelper from '@/financialAidWizard/utils/financialAidWizardApiHelper';
import { OtherWizardGenericActions } from '@/utils/genericWizardActions';
import { State } from '../reducers/cancellationWizardState';
import AdminConfigAPI, { Coupon } from '../../utils/adminConfig';
import cancellationWizardApiHelper from '../utils/cancellationWizardApiHelper';
import therapistReviewApiHelper from '../../therapistReview/utils/therapistReviewApiHelper';

export const loadCouponDefinitions = async (
  roomID: number,
  roomStatus: number | null,
  couponCodes: string[],
  selectedIndex: number
): Promise<Partial<State>> => {
  let couponDiscountDisplay = '';
  let displayDiscountScreen = false;
  let isEligible = false;

  if (roomStatus !== RoomStatus.FREE_TRIAL_CHATBOT) {
    if (!couponCodes.length || selectedIndex > couponCodes.length - 1) {
      return {};
    }
    const eligibilityRes = await cancellationWizardApiHelper.isEligibleForCoupon(
      roomID,
      couponCodes[selectedIndex]
    );
    isEligible = (eligibilityRes && eligibilityRes.eligible) || false;
    if (eligibilityRes && eligibilityRes.coupon && eligibilityRes.coupon.value) {
      couponDiscountDisplay =
        eligibilityRes.coupon.valueType === 'fixed'
          ? formatCurrency(eligibilityRes.coupon.value, eligibilityRes.coupon.currency)
          : `${eligibilityRes.coupon.value}%`;
    }
    displayDiscountScreen = Boolean(isEligible && couponDiscountDisplay);
  }
  return {
    isEligibleForCoupon: isEligible,
    couponDiscountDisplay,
    displayDiscountScreen,
    hideDisplayDiscountScreen: !displayDiscountScreen,
  };
};

const MAINTENANCE_PLAN_PRIVATE_ROOM_OFFER_ID = 5;
const MAINTENANCE_PLAN_COUPLES_ROOM_OFFER_ID = 97;

const initState: WizardGenericActions<State>['initState'] = async (api, state) => {
  const offerApiHelper = new OfferApiHelper();
  const therapistInfo = await therapistReviewApiHelper.getTherapistInfo(state.roomID);
  const result = await AdminConfigAPI.getAdminOptionByName('cancellation_coupon_voucher', true);
  const coupon = result && result.data && (result.data.data as Coupon);
  const couponCodes = (coupon && coupon.codes) || [];

  const roomSubscription = state.roomData.subscription;

  const planModality = roomSubscription?.planDetail?.planModality;
  const firstPurchase = roomSubscription?.firstPurchase;
  const didLiveSession = !!roomSubscription?.redeemedVideoCredit;

  const subscriptionPlanID = roomSubscription?.id;
  const { offer } = await offerApiHelper.getOfferData(
    roomSubscription?.roomType === 'couplesRoom'
      ? MAINTENANCE_PLAN_COUPLES_ROOM_OFFER_ID
      : MAINTENANCE_PLAN_PRIVATE_ROOM_OFFER_ID
  );
  let maintenancePlanID: number | undefined;
  let planPriceDisplay;
  if (offer && offer.discountGroups.length > 0 && offer.discountGroups[0].plans.length > 0) {
    const plan = offer.discountGroups[0].plans[0];
    maintenancePlanID = plan.id;
    const planPrice = plan.billingPrice.amount;
    planPriceDisplay = `${formatCurrency(
      Math.ceil(planPrice / 4),
      offer.currency
    )}/week (${formatCurrency(planPrice, offer.currency)}/month)`;
  }
  const shouldDisplayMaintenanceOffer = !!(
    maintenancePlanID && maintenancePlanID !== subscriptionPlanID
  );

  const {
    data: { isEligible: isEligibleForFinancialAid },
  } = await financialAidWizardApiHelper.isEligible(state.roomID).catch(() => {
    return { data: { isEligible: false } };
  });

  const DID_NOT_FIT_BUDGET_CANCELLATION_REASON_VALUE = 'not_fit_budget';

  const setShowFinancialAidScreen = (cancellationReason: string, context, isV2 = false) => {
    const partialState: Partial<State> = {};
    partialState.showFinancialAidScreen =
      context.wizardContext.isEligibleForFinancialAid &&
      (cancellationReason === DID_NOT_FIT_BUDGET_CANCELLATION_REASON_VALUE || isV2);
    partialState.hideFinancialAidScreen = !partialState.showFinancialAidScreen;

    context.setState(partialState);
  };

  const setSelectedCancellationReason = (_, context) => {
    const partialState: Partial<State> = {};

    const { setState, wizardContext } = context;
    const { cancellationReasonV2, displayDiscountScreen } = wizardContext;

    partialState.isFeelingBetterReason = false;
    partialState.isDissatisfiedWithProviderReason = false;
    partialState.isDissatisfiedWithAppReason = false;
    partialState.isExpensiveReason = false;
    partialState.isOtherReason = false;

    switch (cancellationReasonV2) {
      case 'feeling_better':
        partialState.isFeelingBetterReason = true;
        partialState.flow = 'feelingBetter';
        break;
      case 'dissatisfied_with_provider':
        partialState.isDissatisfiedWithProviderReason = true;
        partialState.flow = displayDiscountScreen
          ? 'dissatisfiedWithProviderDiscount'
          : 'dissatisfiedWithProvider';
        break;
      case 'dissatisfied_with_app':
        partialState.isDissatisfiedWithAppReason = true;
        partialState.flow = displayDiscountScreen
          ? 'dissatisfiedWithAppDiscount'
          : 'dissatisfiedWithApp';
        break;
      case 'expensive':
        partialState.isExpensiveReason = true;
        partialState.flow = 'expensive';
        setShowFinancialAidScreen(cancellationReasonV2, context, true);
        break;
      case 'other':
        partialState.isOtherReason = true;
        partialState.flow = 'other';
        break;
      default:
        partialState.flow = 'straightToCancel';
        break;
    }
    setState(partialState);
  };
  const feelingBetterPostAction = (_, context) => {
    const partialState: Partial<State> = {};
    const { setState } = context;
    partialState.isConfirmCancellationHappy = true;
    setState(partialState);
  };
  const dissatisfiedWithProviderPostAction = (_, context) => {
    const partialState: Partial<State> = {};

    const { setState, wizardContext } = context;
    const { dissatisfiedWithProviderReason } = wizardContext;

    partialState.isDissatisfiedWithProviderOther = dissatisfiedWithProviderReason === 'other';
    setState(partialState);
  };

  return {
    status: roomSubscription?.status || null,
    therapistInfo,
    couponCodes,
    isEligibleForFinancialAid,
    setShowFinancialAidScreen,
    maintenancePlanID,
    shouldDisplayMaintenanceOffer,
    planPriceDisplay,
    setSelectedCancellationReason,
    feelingBetterPostAction,
    dissatisfiedWithProviderPostAction,
    planModality,
    firstPurchase,
    didLiveSession,
  };
};

const prePersist: OtherWizardGenericActions<State>['prePersist'] = async (state) => {
  if (state.therapistReview) {
    const reviewPayload = {
      ...state.therapistReview,
      ratingSource: `${state.wizardType} wizard`,
    };
    await therapistReviewApiHelper.postTherapistReview(reviewPayload);
  }
};

const cancellationWizardActions: OtherWizardGenericActions<State> = {
  initState,
  prePersist,
};

export default cancellationWizardActions;
