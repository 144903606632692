import moment from 'moment';

export const VERIFICATION_CODE_LENGTH = 6;

export const getIsTeenUser = (dateOfBirth) => {
  const dob = moment(dateOfBirth);
  const olderThan13 = moment(dob).add(13, 'years').isBefore(moment());
  const youngerThan18 = moment(dob).add(18, 'years').isAfter(moment());
  return olderThan13 && youngerThan18;
};
