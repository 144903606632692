export enum CommunityTargetTypeEnum {
  COMMUNITY = 'community',
}
export enum CommunityReferenceTypeEnum {
  POST = 'post',
  COMMENT = 'comment',
}

export enum CommunityFeedTypeEnum {
  TODAY = 'today',
  USER = 'user',
}

export type ReportableReference = Extract<
  CommunityReferenceTypeEnum,
  CommunityReferenceTypeEnum.POST | CommunityReferenceTypeEnum.COMMENT
>;

export type ReactableReference = Extract<
  CommunityReferenceTypeEnum,
  CommunityReferenceTypeEnum.POST
>;

export enum ReactionsEnum {
  CRYING_FACE = 'crying_face',
  RAISING_HANDS = 'raising_hands',
  MENDING_HEART = 'mending_heart',
  SMILING_FACE_WITH_OPEN_HANDS = 'smiling_face_with_open_hands',
  FACE_HOLDING_BACK_TEARS = 'face_holding_back_tears',
}

export enum EmojiSize {
  SMALL = 24,
  LARGE = 40,
}

export type DailyPrompt = {
  text: string;
  placeholders: Array<string>;
};

export type UserProfile = {
  displayName: string;
  state: string;
  dateOfBirth: string;
};
