import { FunctionComponent, useState, useEffect } from 'react';
import {
  View,
  ExtraHuge,
  Large,
  ResponsiveLayoutWithHeader,
  TherapistReviewForm,
  useEmotionTheme,
  PanelHeader,
} from '@talkspace/react-toolkit';
import { TherapistReviewData } from 'ts-frontend/types';
import { ERoom } from 'ts-frontend/entities/Room';
import { roomHasRealProvider } from 'ts-frontend/helpers';
import { withRouter, RouteComponentProps } from '../../../core/routerLib';
import styled from '../../../core/styled';
import MobileHeader from '../../../components/MobileLeftHeader';
import ActionStatus from '../../../components/ActionStatus/ActionStatus';
import { useA11y } from './ReviewTherapist.a11y';

const Wrapper = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;
  return {
    marginTop: 70,
    alignSelf: 'center',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: isMobile ? 335 : 600,
  };
});

const AnonymousText = styled(Large)({
  margin: 15,
  width: 330,
});

type Props = RouteComponentProps<{ roomID: string }> & {
  isLoading: boolean;
  error: string;
  handleBackButtonPress: () => void;
  handleCloseButtonPress: () => void;
  therapistID: number;
  therapistName: string;
  postReview: (reviewPayload: TherapistReviewData) => void;
  ratingSource?: string;
  room: ERoom;
  renderHeader?: () => React.ReactElement;
};

const ReviewTherapist: FunctionComponent<Props> = ({
  isLoading,
  error,
  handleBackButtonPress,
  handleCloseButtonPress,
  therapistID,
  therapistName,
  postReview,
  history,
  match,
  ratingSource = 'review therapist',
  room,
  renderHeader,
}) => {
  const [showActionStatus, setShowActionStatus] = useState(false);

  useEffect(() => {
    if (!roomHasRealProvider({ room, provider: { firstName: therapistName } })) {
      history.goBack();
    }
  }, [history, room, room.status, therapistName]);

  const handleOnSubmit = (reviewPayload: TherapistReviewData) => {
    setShowActionStatus(true);
    postReview(reviewPayload);
  };

  const { skipNavs } = useA11y(history, match);
  const { colors } = useEmotionTheme();
  return (
    <ResponsiveLayoutWithHeader
      renderHeader={
        renderHeader ||
        (() => (
          <PanelHeader
            skipNavs={skipNavs}
            renderLeft={() => (
              <MobileHeader title="Review" onBackButtonPress={handleBackButtonPress} />
            )}
            onRightPress={handleCloseButtonPress}
          />
        ))
      }
    >
      {showActionStatus ? (
        <ActionStatus
          isLoading={isLoading}
          isError={!!error}
          errorTitle="Something Went Wrong"
          errorSubTitle="Please try again later."
          errorButtonText="Back"
          errorButtonAction={() => {
            setShowActionStatus(false);
          }}
          successTitle="Thanks for your review"
          spinnerStyles={{ height: 63, width: 63 }}
          spinnerPrimaryColor={colors.periwinkleGrey}
          titleStyles={{ fontWeight: 800, fontSize: 29, marginTop: 25 }}
          subTitleStyles={{
            fontSize: 16,
            color: '#454A5E',
            marginTop: 13,
            marginBottom: 42,
          }}
        />
      ) : (
        <Wrapper>
          <ExtraHuge as="h2">{`Tell us about the quality of care you've received from ${therapistName} `}</ExtraHuge>
          <AnonymousText as="h3" variant="largeDarkGrey">
            Your feedback helps Talkspace improve its service. This will be shared with your
            therapist.
          </AnonymousText>
          <TherapistReviewForm
            therapistId={therapistID}
            submitButtonText="Submit"
            handleOnSubmit={handleOnSubmit}
            ratingSource={ratingSource}
            roomID={room.roomID}
          />
        </Wrapper>
      )}
    </ResponsiveLayoutWithHeader>
  );
};

export default withRouter(ReviewTherapist);
