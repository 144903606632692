import { OptionType } from '@talkspace/react-toolkit';
import {
  sleepQuality,
  sleepAidUse,
  socialSupportSystem,
  previousMentalHealthTreatment,
  hospitalizationHistory,
  suicideIdeationOrPlanning,
  homicidalIdeationOrPlanning,
  mentalHealthIssues,
  traumaticExperienceOptions,
  traumaticFlashbacksOptions,
  guiltOptions,
  isolationOptions,
  angerAggression,
} from '@talkspace/configs';

import { ValueType } from 'react-select';
import {
  BaseWizardState,
  baseWizardInitialState,
  keyValueToValueType,
  WizardPostAction,
} from 'stepWizard';
import { TFAdultOnboarding } from '@talkspace/i18n/types';

export interface State extends BaseWizardState {
  sleepQuality: string;
  sleepAidUse: string;
  socialSupportSystem: string;
  previousMentalHealthTreatment: null | string;
  previousMentalHealthIssues: number[];
  hospitalizationHistory: null | string;
  previousRelativesMentalHealthIssues: number[];
  traumaticExperience: boolean;
  traumaticFlashbacks: null | boolean;
  guilt: null | boolean;
  isolation: null | boolean;
  suicideIdeation: string;
  suicidePlanning: null | string;
  homicidalIdeation: null | string;
  homicidalPlanning: null | string;
  angerAggression: null | boolean;

  selectedPreviousMentalHealthTreatment: boolean | null;
  selectedSuicideIdeation: boolean | null;
  selectedSuicidePlanning: boolean | null;
  selectedTraumaticExperience: boolean | null;
  selectedTraumaticFlashbacks: boolean | null;
  selectedGuilt: boolean | null;
  selectedIsolation: boolean | null;
  selectedAngerAggression: boolean | null;

  sleepQualityOptions: ValueType<OptionType, true>;
  sleepAidUseOptions: ValueType<OptionType, true>;
  socialSupportSystemOptions: ValueType<OptionType, true>;
  previousMentalHealthTreatmentOptions: ValueType<OptionType, true>;
  previousMentalHealthIssuesOptions: ValueType<OptionType, true>;
  hospitalizationHistoryOptions: ValueType<OptionType, true>;
  previousRelativesMentalHealthIssuesOptions: ValueType<OptionType, true>;
  traumaticExperienceOptions: ValueType<OptionType, true>;
  traumaticFlashbacksOptions: ValueType<OptionType, true>;
  guiltOptions: ValueType<OptionType, true>;
  isolationOptions: ValueType<OptionType, true>;
  suicideIdeationOptions: ValueType<OptionType, true>;
  suicidePlanningOptions: ValueType<OptionType, true>;
  homicidalIdeationOrPlanningOptions: ValueType<OptionType, true>;
  angerAggressionOptions: ValueType<OptionType, true>;

  setPreviousMentalHealthTreatment?: WizardPostAction<State, void>;
  setTraumaticExperience?: WizardPostAction<State, void>;
  setTraumaticFlashbacks?: WizardPostAction<State, void>;
  setGuilt?: WizardPostAction<State, void>;
  setIsolation?: WizardPostAction<State, void>;
  setSuicideIdeation?: WizardPostAction<State, void>;
  setSuicidePlanning?: WizardPostAction<State, void>;
  setHomicidalIdeation?: WizardPostAction<State, void>;
  setHomicidalPlanning?: WizardPostAction<State, void>;
  setAngerAggression?: WizardPostAction<State, void>;
}

export const getInitialState = (
  clientUserID: number,
  roomID: number,
  source: string,
  contextID: string,
  tAdultOnboarding: TFAdultOnboarding
): State => {
  return {
    ...baseWizardInitialState(source, contextID),
    roomID,
    clientUserID,
    sleepQuality: '',
    sleepAidUse: '',
    socialSupportSystem: '',
    previousMentalHealthTreatment: null,
    previousMentalHealthIssues: [],
    hospitalizationHistory: null,
    previousRelativesMentalHealthIssues: [],
    traumaticExperience: false,
    traumaticFlashbacks: null,
    guilt: null,
    isolation: null,
    suicideIdeation: '',
    suicidePlanning: null,
    homicidalIdeation: null,
    homicidalPlanning: null,
    angerAggression: null,

    selectedPreviousMentalHealthTreatment: null,
    selectedSuicideIdeation: null,
    selectedSuicidePlanning: null,
    selectedTraumaticExperience: null,
    selectedTraumaticFlashbacks: null,
    selectedGuilt: null,
    selectedIsolation: null,
    selectedAngerAggression: null,

    sleepQualityOptions: keyValueToValueType(sleepQuality).map((option) => {
      return {
        ...option,
        label: tAdultOnboarding(`sleepQuality.${option.value}`, option.label),
      };
    }),
    sleepAidUseOptions: keyValueToValueType(sleepAidUse).map((option) => {
      return {
        ...option,
        label: tAdultOnboarding(`sleepAids.${option.value}`, option.label),
      };
    }),
    socialSupportSystemOptions: keyValueToValueType(socialSupportSystem).map((option) => {
      return {
        ...option,
        label: tAdultOnboarding(`socialSupport.${option.value}`, option.label),
      };
    }),
    previousMentalHealthTreatmentOptions: keyValueToValueType(previousMentalHealthTreatment).map(
      (option) => {
        return {
          ...option,
          label: tAdultOnboarding(`prevSeen.${option.value}`, option.label),
        };
      }
    ),
    previousMentalHealthIssuesOptions: keyValueToValueType(mentalHealthIssues).map((option) => {
      return {
        ...option,
        label: tAdultOnboarding(`mentalHealthDiagnosis.${option.value}`, option.label),
      };
    }),
    hospitalizationHistoryOptions: keyValueToValueType(hospitalizationHistory).map((option) => {
      return {
        ...option,
        label: tAdultOnboarding(`haveHospitalized.${option.value}`, option.label),
      };
    }),
    previousRelativesMentalHealthIssuesOptions: keyValueToValueType(mentalHealthIssues).map(
      (option) => {
        return {
          ...option,
          label: tAdultOnboarding(`immediateRelatives.${option.value}`, option.label),
        };
      }
    ),
    traumaticExperienceOptions: keyValueToValueType(traumaticExperienceOptions).map((option) => {
      return {
        ...option,
        label: tAdultOnboarding(`traumaticEvents.${option.value}`, option.label),
      };
    }),
    traumaticFlashbacksOptions: keyValueToValueType(traumaticFlashbacksOptions),
    guiltOptions: keyValueToValueType(guiltOptions),
    isolationOptions: keyValueToValueType(isolationOptions),
    suicideIdeationOptions: keyValueToValueType(suicideIdeationOrPlanning).map((option) => {
      return {
        ...option,
        label: tAdultOnboarding(`thoughtKillingYourself.${option.value}`, option.label),
      };
    }),
    suicidePlanningOptions: keyValueToValueType(suicideIdeationOrPlanning).map((option) => {
      return {
        ...option,
        label: tAdultOnboarding(`thoughtMightDo.${option.value}`, option.label),
      };
    }),
    homicidalIdeationOrPlanningOptions: keyValueToValueType(homicidalIdeationOrPlanning).map(
      (option) => {
        return {
          ...option,
          label: tAdultOnboarding(`thoughtKillingOthers.${option.value}`, option.label),
        };
      }
    ),
    angerAggressionOptions: keyValueToValueType(angerAggression).map((option) => {
      return {
        ...option,
        label: tAdultOnboarding(`excessiveAnger.${option.value}`, option.label),
      };
    }),
  };
};
