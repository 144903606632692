import { Tiny } from '@talkspace/react-toolkit';

const WelcomeScreenCitation = ({ citation }: { citation: string }) => {
  if (!citation) return null;
  return (
    <Tiny>
      <Tiny inline style={{ fontWeight: 'bold' }}>
        Source:{' '}
      </Tiny>
      {citation}
    </Tiny>
  );
};

export default WelcomeScreenCitation;
