import s from '@emotion/styled';
import { CreateStyled, ExtendEmotionTheme } from '@talkspace/react-toolkit';
import { RoomTheme } from '../../types';

// export interface EmotionStyle extends CSS.Properties<string | number> {}
export type EmotionStyle = object;

export type Theme = ExtendEmotionTheme<RoomTheme>;

const styled = s as CreateStyled<Theme>;

export default styled;
