import * as React from 'react';
import Svg, { Circle, Path } from 'svgs';
import { EmotionStyle } from '../../core/styled';

interface PsychRxProps {
  style?: EmotionStyle;
}

function PsychRx(props: PsychRxProps) {
  const { style } = props;
  const title = 'psychiatry perscription symbol';
  return (
    <Svg
      width={60}
      height={60}
      title={title}
      aria-label={title}
      viewBox="0 0 60 60"
      fill="none"
      {...props}
      style={{
        mixBlendMode: 'multiply',
        ...style,
      }}
    >
      <Circle cx={30} cy={30} r={30} fill="#fff" />
      <Circle cx={30} cy={30} r={30} fill="#F1DDDD" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.821 16.799c-1.214-.996-2.867-1.494-4.956-1.494h-4.88l-.202.007a2.7 2.7 0 00-2.498 2.692v18.291l.009.176a1.823 1.823 0 003.636-.176V28.79h1.566l7.18 8.605-5.488 6.45a.6.6 0 00.472.989l3.145-.078a.36.36 0 00.269-.13l3.992-4.824 3.987 4.823a.36.36 0 00.268.13l3.154.079a.6.6 0 00.473-.988l-5.622-6.632 5.008-5.785a.6.6 0 00-.453-.992h-3.282a.36.36 0 00-.281.134l-3.252 4.056-5.233-5.983.375-.074a6.167 6.167 0 002.613-1.274c1.677-1.361 2.515-3.11 2.515-5.249 0-2.138-.838-3.887-2.515-5.248zm-8.891 1.967h3.752l.345.006c1.232.049 2.138.362 2.717.941.632.632.947 1.41.947 2.333 0 .923-.315 1.7-.947 2.332-.632.632-1.652.948-3.062.948H23.93v-6.56z"
        fill="#005C65"
      />
    </Svg>
  );
}

export default PsychRx;
