import { useEffect } from 'react';

import { useHistory, useParams } from '@/core/routerLib';

const OnboardingPageBookFirstAsyncSession = () => {
  const history = useHistory();
  const { roomID } = useParams<{ roomID: string }>();
  // NOTE: no intro page for this step, redirect to /book-async-session right away
  useEffect(() => {
    history.push(`/room/${roomID}/onboarding/book-async-session`);
  }, [history, roomID]);

  return null;
};

export default OnboardingPageBookFirstAsyncSession;
