import Svg, { G, Circle, Path } from 'svgs';

const SvgComponent = ({ width = 22, height = 22, ...otherProps }) => (
  <Svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} {...otherProps}>
    <G transform="translate(1 1)" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <Circle stroke="#68768D" cx={10} cy={10} r={10} />
      <Path
        d="M10.7 8.695V15H9.01V8.695h1.69zM9.075 7.226a1.022 1.022 0 01-.325-.767c0-.303.108-.559.325-.767.217-.208.477-.312.78-.312.303 0 .563.104.78.312.217.208.325.464.325.767s-.108.559-.325.767a1.086 1.086 0 01-.78.312c-.303 0-.563-.104-.78-.312z"
        fill="#68768D"
      />
    </G>
  </Svg>
);

export default SvgComponent;
