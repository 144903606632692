import { FunctionComponent, useCallback, useEffect } from 'react';
import { persistData, useWizardActions, useWizardState } from 'stepWizard';
import V2FinalScreen from '@/cancellationWizardsShared/components/V2FinalScreen';
import { trackWizardEvent } from '@/utils/analytics/events';
import { getUserData } from '@/auth/helpers/token';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import useCancellationWizard from '../hooks/useCancellationWizard';
import { RouteComponentProps, withRouter } from '../../core/routerLib/routerLib';

const KeepSubscriptionContainer: FunctionComponent<RouteComponentProps> = () => {
  const { flow, experiment, experimentID, therapistInfo, responses } = useWizardState();
  const [state, { exitAction }] = useCancellationWizard();
  const { id: userID } = getUserData();
  const { setState } = useWizardActions();
  const { error } = state;
  const closeModal = useCloseModal();

  const lastStepName = 'Keep subscription';

  const handlePersist = useCallback(() => {
    persistData(responses, setState, lastStepName, error, {}, false);
  }, [error, responses, setState]);

  useEffect(() => {
    const handleRequestStatus = async () => {
      trackWizardEvent(lastStepName, 'Cancellation Wizard', {
        'User ID': userID,
        Application: 'Cancellation Wizard',
        label: lastStepName,
        eventPropertyValue: therapistInfo && therapistInfo.id,
        experiment,
        experimentID,
      });
      handlePersist();
    };
    handleRequestStatus();
  }, [experiment, experimentID, handlePersist, therapistInfo, userID]);

  const handleExitModal = () => {
    exitAction();
    closeModal();
  };

  return <V2FinalScreen flow={flow} actionHookState={state} onClick={handleExitModal} />;
};

export default withRouter(KeepSubscriptionContainer);
