import { FunctionComponent } from 'react';
import { View, useEmotionTheme } from '@talkspace/react-toolkit';
import Svg, { Path } from 'svgs';

import styled, { EmotionStyle } from 'core/styled';

export interface ClinicalProgressDashboardIconProps {
  width?: number;
  height?: number;
  iconWidth?: number;
  iconHeight?: number;
  iconStyle?: EmotionStyle;
}

type IconProps = Pick<ClinicalProgressDashboardIconProps, 'width' | 'height' | 'iconStyle'>;

const Icon: FunctionComponent<IconProps> = ({ width = 28, height = 22, iconStyle = {} }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'upwards trending chart';
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 28 22"
      style={iconStyle}
      title={titleText}
      aria-label={titleText}
    >
      <Path
        d="M30.749 31.835l7.295-7.768c.43-.455 1.113-.133 1.113.51v3.339c0 .797.664 2.166 1.418 2.166h-.026c.755 0 1.34-1.37 1.34-2.166v-7.222c0-1.596-1.12-2.166-2.628-2.166h-6.829c-.753 0-1.469.286-1.469 1.083v.18c0 .798.716 1.625 1.47 1.625h3.339c.609 0 .913.418.483.873l-6.47 6.66c-.533.565-1.398.474-1.932-.089l-.563-.641c-1.066-1.128-2.795-1.15-3.863-.022l-8.6 8.819c-.534.563-.253 1.472-.253 2.036v-.003c1.366.565 1.65.564 2.184-.001l7.509-7.808a1.387 1.387 0 011.995 0l.59.592a2.663 2.663 0 003.897.003"
        transform="translate(-14 -18)"
        fill={colors.emerald}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
};

const Wrapper = styled(View)({
  backgroundColor: '#FFFFFF',
  boxShadow: '0 3px 11px -3px rgba(16,109,149,0.30)',
  borderRadius: '50%',
});

const ClinicalProgressDashboardIcon: FunctionComponent<ClinicalProgressDashboardIconProps> = ({
  iconWidth,
  iconHeight,
  iconStyle,
  height = 58,
  width = 58,
}) => (
  <Wrapper align="center" justify="center" style={{ height, width }}>
    <Icon width={iconWidth} height={iconHeight} iconStyle={iconStyle} />
  </Wrapper>
);

export default ClinicalProgressDashboardIcon;
