import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { isPhoneNumberValid } from '@talkspace/react-toolkit';
import { contactRelationship } from '@talkspace/configs';

import PersonalInformationEditEmergencyContactModal from '../components/PersonalInformationEditEmergencyContactModal';
import {
  usePersonalInformationState,
  usePersonalInformationActions,
} from '../hooks/usePersonalInformation';
import { EmergencyContactData } from '../types';

const REQUIRED_ERROR = 'Required field.';

interface Props {
  handleOnClose: () => void;
}

const schema: yup.SchemaOf<EmergencyContactData> = yup.object().shape({
  contactName: yup.string().required(REQUIRED_ERROR).nullable(),
  contactPhone: yup
    .string()
    .required()
    .test(
      'is-valid-phone-number',
      'Phone number is missing or invalid.',
      (userInput) => !!(userInput && isPhoneNumberValid(userInput))
    )
    .nullable(),
  relationship: yup
    .string()
    .required()
    .oneOf(Object.keys(contactRelationship), 'Select relationship')
    .nullable(),
});
const PersonalInformationEditEmergencyContactModalContainer: React.VFC<Props> = ({
  handleOnClose,
}) => {
  const { emergencyContact, isLoading, error } = usePersonalInformationState();
  const { getEmergencyContact, updateEmergencyContact } = usePersonalInformationActions();
  const methods = useForm<EmergencyContactData>({
    resolver: yupResolver(schema),
    defaultValues: emergencyContact,
  });

  const { getValues, handleSubmit, reset } = methods;

  useEffect(() => {
    getEmergencyContact();
  }, [getEmergencyContact]);

  useEffect(() => {
    reset(emergencyContact);
  }, [emergencyContact, reset]);

  const onSubmit = () => {
    updateEmergencyContact(getValues()).then(() => {
      if (!error) handleOnClose();
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PersonalInformationEditEmergencyContactModal
          isLoading={isLoading}
          onClose={handleOnClose}
        />
      </form>
    </FormProvider>
  );
};

export default PersonalInformationEditEmergencyContactModalContainer;
