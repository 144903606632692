import { FunctionComponent, useEffect, useState } from 'react';
import {
  ExtraHuge,
  Large,
  Massive,
  RouterLinkWrapper,
  Small,
  useEmotionTheme,
  View,
  useContainerFocus,
  useUniqueID,
} from '@talkspace/react-toolkit';
import { routePromise } from 'ts-frontend/helpers';
import { useClientAuthActions, useClientAuthState } from '@/hooks/clientAuthContext';
import LoginForm from '@/login/components/LoginForm/LoginForm';
import {
  Link as RouterLink,
  RouteComponentProps,
  withRouter,
} from '../../../core/routerLib/routerLib';
import styled from '../../../core/styled';
import sessionStorage from '../../../core/storage/sessionStorage';
import useScreenSize, { isResponsive } from '../../../core/hooks/useScreenSize';
import { LoginFields } from '../LoginForm/types';

const Container = styled(View)({
  '&:focus': {
    outline: 'none',
  },
});

const FormContainer = styled(View)({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginLeft: 0,
  marginRight: 0,
});

const availableProviders = { alexa: 'Amazon Alexa' };

const LoginOauth: FunctionComponent<RouteComponentProps<{}, {}, { redirect?: string }>> = ({
  location,
}) => {
  const { containerRef } = useContainerFocus();
  const heading1ID = useUniqueID('heading1ID');

  const screenSize = useScreenSize();
  const [provider, setProvider] = useState('');
  const { isUpdating, redirectTo, isError } = useClientAuthState();
  const { loginOauth2Action, clearErrorsAction } = useClientAuthActions();

  // If the login screen mounts, that means we do not have a valid token
  // See src/auth/AuthContainer
  useEffect(() => {
    if (location.search) {
      const oauthQuery = new URLSearchParams(location.search);
      if (
        !oauthQuery.has('provider') ||
        !oauthQuery.has('redirect_uri') ||
        !oauthQuery.has('response_type') ||
        !oauthQuery.has('client_id') ||
        !oauthQuery.has('scope') ||
        !oauthQuery.has('state')
      ) {
        routePromise('/login');
        return;
      }
      sessionStorage.setItem('oauthQuery', location.search);
      setProvider(availableProviders[oauthQuery.get('provider') as string]);
    } else {
      routePromise('/login');
    }
  }, [location.search, provider]);

  const onSubmit = (loginFormData: LoginFields) => {
    loginOauth2Action({ email: loginFormData.email, password: loginFormData.password });
  };

  useEffect(() => {
    // Successful login:
    if (redirectTo && !isUpdating && !isError) {
      routePromise(redirectTo);
    }
  }, [isUpdating, isError, redirectTo]);

  const TitleComponent = isResponsive(screenSize) ? ExtraHuge : Massive;

  const { colors } = useEmotionTheme();
  return (
    <Container align="center" ref={containerRef} tabIndex={-1} aria-labelledby={heading1ID}>
      <View
        align="center"
        style={{
          marginTop: isResponsive(screenSize) ? 30 : 60,
          marginBottom: 29,
        }}
      >
        <TitleComponent as="h1" style={{ marginBottom: 10, textAlign: 'center' }} id={heading1ID}>
          Sign in to your account
        </TitleComponent>
        <View style={{ textAlign: 'center', width: 335 }}>
          <Large as="h2" variant="largeDarkGrey" style={{ fontWeight: 400 }}>
            Log in to link your Talkspace account with {provider}.
          </Large>
        </View>
      </View>
      <FormContainer>
        <LoginForm onSubmit={onSubmit} />
        <View style={{ marginTop: 20 }}>
          <Small>
            Don't have an account?{' '}
            <RouterLinkWrapper primaryColor={colors.green} roundedFocusStyle>
              <RouterLink
                onClick={clearErrorsAction}
                to={{
                  pathname: '/signup',
                }}
                data-qa="signUpLink"
              >
                <Small inline style={{ color: colors.green, fontWeight: 700 }}>
                  Sign up
                </Small>
              </RouterLink>
            </RouterLinkWrapper>
          </Small>
        </View>
      </FormContainer>
    </Container>
  );
};

export default withRouter(LoginOauth);
