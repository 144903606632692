import { View, spacing } from '@talkspace/react-toolkit';
import BookingsListItem from './BookingsListItem';
import BookingsListLoading from './BookingsListLoading';

import { UseNavShellData } from '../../hooks/useNavShellData';

const { space100 } = spacing;
interface Props extends Pick<UseNavShellData, 'bookings' | 'dataByRoomID' | 'activeSessionData'> {
  isLoading: boolean;
}

const BookingsList = ({ isLoading, bookings, dataByRoomID, activeSessionData }: Props) => {
  const sortedBookings = bookings
    ? [
        ...bookings.filter((booking) => booking.modality === 'messaging'),
        ...bookings.filter((booking) => booking.modality !== 'messaging'),
      ]
    : [];
  return (
    <View style={{ marginTop: space100 }}>
      {isLoading || !bookings || !dataByRoomID ? (
        <BookingsListLoading />
      ) : (
        sortedBookings.map((booking, i) => (
          <BookingsListItem
            key={booking.id}
            activeSessionData={activeSessionData}
            booking={booking}
            dataByRoomID={dataByRoomID}
            isLast={i === sortedBookings.length - 1}
          />
        ))
      )}
    </View>
  );
};
export default BookingsList;
