import { useEffect } from 'react';

import { useCommunityState } from './CommunityContext';
import { useOpenModal } from '../../utils/ModalsContextProvider';

const useTeenspaceOnboarding = () => {
  const openModal = useOpenModal();
  const { displayName, isTeenspaceOnboarded, isLoading } = useCommunityState();

  useEffect(() => {
    if (!isTeenspaceOnboarded && !isLoading) {
      // If a user already has a display name but didnt finish onboarding
      // navigate them directly to the daily question
      displayName
        ? openModal('/community/teenspace/onboarding/daily-question')
        : openModal('/community/teenspace/onboarding/welcome');
    }
  }, [isTeenspaceOnboarded, isLoading, displayName, openModal]);

  return { isLoading };
};

export default useTeenspaceOnboarding;
