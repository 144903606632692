import { useReducer, useCallback, useRef, useEffect } from 'react';
import moment from 'moment';
import ApiHelper from '../utils/ApiHelper';
import {
  initialState,
  schedulerReducer,
  BookingDuration,
  State,
  TherapistTimeslot,
  TherapistTimeslotData,
  TimeslotByDay,
  VideoCreditsAPIResponse,
  VideoCreditType,
} from '../reducers/schedulerReducer';

function getLocalTherapistTimeslotData(timeslotsByBookingDuration: TherapistTimeslotData) {
  return Object.entries(timeslotsByBookingDuration).reduce(
    (acc, [bookingDuration, timeslotsObject]) => {
      const timeslots = timeslotsObject.timeslots.map(({ start, end }) => {
        const localStart = moment(new Date(start)).format();
        const localEnd = moment(new Date(end)).format();
        return { start: localStart, end: localEnd };
      });
      const localTimeslotsObject = {
        ...timeslotsObject,
        timeslots,
      };
      acc[bookingDuration] = localTimeslotsObject;
      return acc;
    },
    {}
  );
}

function getMinAndMaxDates(timeslots: TherapistTimeslot[]) {
  return {
    minDate: moment(timeslots[0].start).format('YYYY-MM-DD'),
    maxDate: moment(timeslots[timeslots.length - 1].start).format('YYYY-MM-DD'),
  };
}

function createEmptyTimeslotsByDay(minDate: string, maxDate: string) {
  const daysDifference = moment(maxDate).diff(minDate, 'days') + 1;
  const daysToDisplay = daysDifference + (daysDifference % 3);
  const timeslotsByDay: TimeslotByDay[] = [];

  for (let i = 0; i < daysToDisplay; i += 1) {
    timeslotsByDay.push({
      date: moment(minDate).add(i, 'days').format('YYYY-MM-DD'),
      timeslots: [],
    });
  }
  return timeslotsByDay;
}

function createTherapistTimeslotDataByDay(timeslotsByBookingDuration: TherapistTimeslotData) {
  return Object.entries(timeslotsByBookingDuration).reduce(
    (acc, [bookingDuration, { timeslots }]) => {
      if (!timeslots.length) return acc;
      const { minDate, maxDate } = getMinAndMaxDates(timeslots);
      const timeslotsByDay: TimeslotByDay[] = createEmptyTimeslotsByDay(minDate, maxDate);
      timeslots.forEach(({ start, end }) => {
        const startDate = moment(start).format('YYYY-MM-DD');
        const dayObjectWithSameDate = timeslotsByDay.find(
          (dayObject) => dayObject.date === startDate
        );
        if (dayObjectWithSameDate) dayObjectWithSameDate.timeslots.push({ start, end });
      });

      acc[bookingDuration] = timeslotsByDay;
      return acc;
    },
    {}
  );
}

function getBookingDurationsForVideoCredits(
  credits: VideoCreditsAPIResponse,
  videoCreditType: VideoCreditType
) {
  return Object.entries(credits).reduce((acc, [key, numberOfCredits]) => {
    if (key.indexOf(videoCreditType) > -1 && numberOfCredits) {
      const creditDurationString: string = key.split(' ')[0];
      const creditDurationInt: number = parseInt(creditDurationString, 10);
      const bookingDurationInt: BookingDuration = creditDurationInt > 30 ? 60 : 30;
      acc.push(bookingDurationInt);
    }
    return acc;
  }, [] as number[]);
}

function getCreditDurationsForVideoCredits(credits: VideoCreditsAPIResponse) {
  return Object.entries(credits).reduce((acc, [key, numberOfCredits]) => {
    if (numberOfCredits) {
      const creditDurationString: string = key.split(' ')[0];
      const creditDurationInt: number = parseInt(creditDurationString, 10);
      acc.push(creditDurationInt);
    }
    return acc;
  }, [] as number[]);
}

export default function useScheduler(): [
  State,
  {
    getSchedulerData: (number, roomID) => {};
  }
] {
  const [state, dispatch] = useReducer(schedulerReducer, initialState);
  const apiRef = useRef(new ApiHelper());
  const { current: api } = apiRef;
  useEffect(
    () => () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      apiRef.current.cancelAll();
    },
    []
  );

  async function getSchedulerData(therapistUserID, roomID): Promise<void> {
    dispatch({ type: 'getVideoCredits' });
    try {
      const videoCredits = await api.getVideoCredits();

      const videoCreditBookingDurations = getBookingDurationsForVideoCredits(
        videoCredits,
        'psychiatry'
      );
      const videoCreditDurations: number[] = getCreditDurationsForVideoCredits(videoCredits);
      const timeslotsPromises = await Promise.all(
        videoCreditBookingDurations.map((bookingDuration) =>
          api.getTherapistTimeslots(therapistUserID, bookingDuration, roomID)
        )
      );

      const timeslotsByBookingDuration = videoCreditBookingDurations.reduce(
        (acc, bookingDuration, index) => {
          acc[bookingDuration] = timeslotsPromises[index];
          return acc;
        },
        {}
      );
      const hasVideoCredits = !!videoCreditDurations.length;

      const hasTimeslotForAtLeastOneDuration = !videoCreditBookingDurations.find(
        (duration) =>
          timeslotsByBookingDuration[duration] &&
          !timeslotsByBookingDuration[duration].timeslots.length
      );

      const localTherapistTimeslotData = getLocalTherapistTimeslotData(timeslotsByBookingDuration);

      const therapistTimeslotsByDay = createTherapistTimeslotDataByDay(localTherapistTimeslotData);

      dispatch({
        type: 'receiveGetVideoCredits',
        payload: {
          videoCredits,
          videoCreditBookingDurations,
          videoCreditDurations,
          timeslotsByBookingDuration,
          therapistTimeslotsByDay,
          hasTimeslotForAtLeastOneDuration,
          hasVideoCredits,
        },
      });
    } catch (e) {
      dispatch({ type: 'setIsError' });
    }
  }

  return [
    state,
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      getSchedulerData: useCallback(getSchedulerData, []),
    },
  ];
}
