import apiWrapper from '../../core/api/apiWrapper';
import apiHelper from '../../core/api/apiHelper';
import appConfig from '../configs';

type SSOProvider = 'zendesk';
const ZendeskPath = {
  KNOWLEDGE_BASE: '/',
  CONTACT_US: '/requests/new',
  MY_TICKETS: '/requests',
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ZendeskPath = keyof typeof ZendeskPath;

const getSsoType = (): SSOProvider | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('sso') as SSOProvider;
};

const getSSOToken = (provider: SSOProvider): Promise<string> =>
  apiWrapper
    .post(`${apiHelper().authAPIEndpoint}/v2/tokens/jwt/sso`, {
      target: provider,
    })
    .then((res) => res.data.data)
    .then((data) => data.token);

const goToZendesk = (returnTo: string) =>
  getSSOToken('zendesk')
    .then((token) => {
      const dst = `${appConfig.zendesk.subDomain}/access/jwt?jwt=${token}&return_to=${returnTo}`;
      window.location.href = dst;
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    });

const openZendesk = (path: ZendeskPath) => {
  // we must open first the window otherwise we get blocked by popup-blockers.
  const windowReference = window.open();
  return getSSOToken('zendesk')
    .then((token) => {
      const dst = `${appConfig.zendesk.subDomain}/access/jwt?jwt=${token}&return_to=${appConfig.zendesk.subDomain}${path}`;
      if (windowReference) windowReference.location.href = dst;
      else window.location.href = dst; // fallback if window.open fails.
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
    });
};

const loginAction = () => {
  switch (getSsoType()) {
    case 'zendesk':
      {
        const urlParams = new URLSearchParams(window.location.search);
        const returnTo = urlParams.get('return_to') || appConfig.zendesk.subDomain;
        goToZendesk(returnTo);
      }
      break;
    default:
      throw new Error('No SSO provider found');
  }
};

const ssoHelper = {
  openZendesk,
  ZendeskPath,
  loginAction,
  isSSO: getSsoType,
};

export default ssoHelper;
