import { OptionType, AddressInputValue } from '@talkspace/react-toolkit';
import { contactRelationship } from '@talkspace/configs';
import { ValueType } from 'react-select';
import { BaseWizardState, baseWizardInitialState, keyValueToValueType } from 'stepWizard';
import { TFAdultOnboarding } from '@talkspace/i18n/types';
import { EmergencyContactData } from 'ts-frontend/types';

export interface State extends BaseWizardState {
  roomID: number;
  clientUserID: number;
  name: {
    firstName: string;
    middleName: string;
    lastName: string;
  };
  address: AddressInputValue;
  phone: string;
  contactName?: string;
  relationship?: string;
  contactPhone?: string;
  relationshipOptions: ValueType<OptionType, true>;
  displayEmergencyContactName?: boolean;
  displayEmergencyContactAddress?: boolean;
  displayEmergencyContactPhone?: boolean;
  emergencyContactData?: EmergencyContactData;
}

export const getInitialState = (
  clientUserID: number,
  roomID: number,
  source: string,
  contextID: string,
  tAdultOnboarding: TFAdultOnboarding,
  emergencyContactData?: EmergencyContactData
): State => {
  return {
    ...baseWizardInitialState(source, contextID),
    roomID,
    clientUserID,
    name: {
      firstName: '',
      middleName: '',
      lastName: '',
    },
    address: {
      addressFromApi: {
        address: '',
        city: '',
        state: '',
        fullAddress: '',
        zipcode: '',
      },
      addressString: '',
    },
    phone: '',
    contactName: '',
    relationship: '',
    contactPhone: '',
    relationshipOptions: keyValueToValueType(contactRelationship).map((option) => {
      return {
        ...option,
        label: tAdultOnboarding(`whatRelationship.${option.value}`, option.label),
      };
    }),
    displayEmergencyContactName: !(
      (emergencyContactData?.firstName && emergencyContactData?.lastName) ||
      false
    ),
    displayEmergencyContactAddress: !(
      emergencyContactData?.address &&
      emergencyContactData?.city &&
      emergencyContactData?.state &&
      emergencyContactData?.zipcode
    ),
    displayEmergencyContactPhone: !(emergencyContactData?.phone || false),
    emergencyContactData,
  };
};
