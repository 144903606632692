import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../index.next';

const DoublePersonSwitch = ({ width = 82, height = 86 }) => {
  const { colorRoles } = useEmotionTheme();
  const titleText = 'DoublePersonSwitch';
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 82 86"
      fill="none"
      title={titleText}
      aria-label={titleText}
    >
      <Path
        d="M28.825 28.33a9.74 9.74 0 001.46-.416c4.937-1.866 7.123-7.122 5.202-11.591-1.944-4.52-7.42-6.727-12.203-4.917a9.614 9.614 0 00-2.03 1.067c-4.11 1.918-6.13 7.253-4.413 11.401 1.702 4.11 7.114 6.46 11.984 4.457zm4.852-11.229c1.497 3.48-.246 7.518-4.088 8.97-3.817 1.443-8.163-.286-9.696-3.852-1.51-3.51.276-7.53 4.088-8.97 3.794-1.436 8.157.271 9.696 3.852zM57.799 61.755c.49-.103.978-.235 1.46-.417 5.182-2.07 7.017-7.326 5.183-11.593-1.74-3.999-6.24-6.244-10.675-5.346-1.143.207-2.394.7-3.564 1.5-4.105 1.922-6.126 7.252-4.408 11.409 1.702 4.102 7.061 6.455 12.004 4.447zm4.835-11.228c1.406 3.274-.002 7.335-4.089 8.975-3.82 1.439-8.166-.288-9.695-3.853-1.775-4.11 1.023-8.537 5.289-9.316 3.566-.721 7.13 1.059 8.495 4.194zM23.08 75.834c-5.413-1.765-8.713-4.99-9.708-10.642l4.106-1.732c.587-.245.809-1.2.164-1.736-2.105-1.746-4.724-3.919-7.777-6.731a.986.986 0 00-1.492.185c-2.092 3.2-3.538 6.809-4.548 11.354a.985.985 0 001.36 1.114 15.313 15.313 0 013.668-1.08c1.707 7.851 7.73 11.756 14.011 11.185a.985.985 0 00.216-1.917zM54.086 9.468c5.672.607 9.56 3.086 11.701 8.4l-3.658 2.545a.986.986 0 00.103 1.681c3.096 1.627 5.767 3.047 9.1 5.026a.986.986 0 001.42-.49c1.385-3.565 2.052-7.394 2.098-12.05a.985.985 0 00-1.562-.809c-1.012.73-2.24 1.39-3.364 1.818-3.334-7.41-10.09-9.88-16.025-8.041a.985.985 0 00.187 1.92z"
        fill={colorRoles.icons.iconBrandDefault}
      />
      <Path
        d="M51.479 34.176C57.16 16.732 43.448.5 25.874.5 11.883.5.5 11.877.5 25.86c0 8.568 4.297 16.781 12.036 21.564 2.364 2.513 10.283 5.827 17.947 4.78a25.014 25.014 0 00-1.03 7.09c0 9.006 4.844 17.726 13.238 22.283 2.92 2.381 11.032 4.977 18.191 3.482 17.134-3.578 26.896-24.471 16.263-39.974-5.564-8.112-16.404-12.202-25.666-10.909zM39.009 76.52c8.068-13.252 24.6-14.681 31.144.447-8.975 7.795-22.364 7.629-31.143-.447zM2.47 25.86C2.47 12.964 12.97 2.47 25.875 2.47c16.663 0 28.112 17.052 21.534 32.58-2.408.715-4.688 1.826-6.815 3.27-7.524-10.802-23.327-10.109-32.95 2.207A23.468 23.468 0 012.47 25.86zm29.403 22.624a23.32 23.32 0 01-21.815-5.397c8.481-13.905 22.675-12.583 28.922-3.59a25.586 25.586 0 00-7.107 8.987zm-.45 10.81c0-7.526 3.588-14.426 9.486-18.813 14.97-11.053 37.322-.86 37.322 18.812 0 6.059-2.395 11.882-6.573 16.222l-.038-.086c-6.83-15.023-24.753-14.614-35.024-1.469a23.472 23.472 0 01-5.173-14.667z"
        fill={colorRoles.icons.iconBrandDefault}
      />
    </Svg>
  );
};

export default DoublePersonSwitch;
