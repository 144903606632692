import { FunctionComponent } from 'react';
import { RoomInviteProvider } from '../hooks/useRoomInvites';
import RoomInvitesContainer from '../containers/RoomInvitesContainer';
import { RouteComponentProps } from '../../core/routerLib';

const RoomInviteScreen: FunctionComponent<
  RouteComponentProps<{
    roomID: string;
  }>
> = (routeProps) => (
  <RoomInviteProvider>
    <RoomInvitesContainer {...routeProps} />
  </RoomInviteProvider>
);

export default RoomInviteScreen;
