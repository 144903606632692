import { Defs, Path, Stop, Svg } from 'svgs';

import { EmojiSize } from '../../types';

const SmilingFaceWithOpenHandsEmoji = ({ size }: { size: EmojiSize }) => {
  const title = 'smiling face with open hands emoji';
  return (
    <Svg
      title={title}
      aria-label={title}
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
    >
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint0_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint1_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint2_radial_4451_12858)"
        fillOpacity="0.6"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint3_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint4_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint5_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint6_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint7_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint8_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint9_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint10_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint11_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint12_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint13_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint14_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint15_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint16_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint17_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint18_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint19_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint20_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint21_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint22_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint23_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint24_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint25_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint26_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint27_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint28_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint29_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint30_radial_4451_12858)"
      />
      <Path
        d="M20.4215 36.6223C31.5062 36.6223 37.0452 29.1797 37.0452 19.9987C37.0452 10.8177 31.5062 3.375 20.4215 3.375C9.33687 3.375 3.79785 10.8177 3.79785 19.9987C3.79785 29.1797 9.33687 36.6223 20.4215 36.6223Z"
        fill="url(#paint31_radial_4451_12858)"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7373 17.766C13.3154 17.4563 14.0351 17.6739 14.3448 18.252C14.6958 18.9072 16.375 21.1877 20.4231 21.1877C24.4712 21.1877 26.1503 18.9072 26.5013 18.252C26.811 17.6739 27.5307 17.4563 28.1088 17.766C28.687 18.0757 28.9045 18.7954 28.5948 19.3735C27.928 20.6183 25.5357 23.5627 20.4231 23.5627C15.3105 23.5627 12.9182 20.6183 12.2513 19.3735C11.9416 18.7954 12.1592 18.0757 12.7373 17.766Z"
        fill="url(#paint32_radial_4451_12858)"
      />
      <Path
        d="M12.0887 14.2892C12.1458 13.9955 12.2765 13.6356 12.525 13.3649C12.738 13.1329 13.1164 12.875 13.8918 12.875C14.6539 12.875 15.042 13.1382 15.266 13.3842C15.5251 13.6686 15.6499 14.0312 15.6906 14.2657C15.8028 14.9118 16.4176 15.3447 17.0637 15.2325C17.7099 15.1203 18.1428 14.5055 18.0306 13.8593C17.9368 13.3191 17.6681 12.4943 17.0218 11.7848C16.3405 11.037 15.3115 10.5 13.8918 10.5C12.4855 10.5 11.4607 11.0122 10.7753 11.7589C10.1255 12.4669 9.86266 13.2945 9.75741 13.8358C9.63223 14.4796 10.0526 15.103 10.6964 15.2282C11.3402 15.3533 11.9636 14.9329 12.0887 14.2892Z"
        fill="#583F51"
      />
      <Path
        d="M12.0887 14.2892C12.1458 13.9955 12.2765 13.6356 12.525 13.3649C12.738 13.1329 13.1164 12.875 13.8918 12.875C14.6539 12.875 15.042 13.1382 15.266 13.3842C15.5251 13.6686 15.6499 14.0312 15.6906 14.2657C15.8028 14.9118 16.4176 15.3447 17.0637 15.2325C17.7099 15.1203 18.1428 14.5055 18.0306 13.8593C17.9368 13.3191 17.6681 12.4943 17.0218 11.7848C16.3405 11.037 15.3115 10.5 13.8918 10.5C12.4855 10.5 11.4607 11.0122 10.7753 11.7589C10.1255 12.4669 9.86266 13.2945 9.75741 13.8358C9.63223 14.4796 10.0526 15.103 10.6964 15.2282C11.3402 15.3533 11.9636 14.9329 12.0887 14.2892Z"
        fill="url(#paint33_radial_4451_12858)"
      />
      <Path
        d="M12.0887 14.2892C12.1458 13.9955 12.2765 13.6356 12.525 13.3649C12.738 13.1329 13.1164 12.875 13.8918 12.875C14.6539 12.875 15.042 13.1382 15.266 13.3842C15.5251 13.6686 15.6499 14.0312 15.6906 14.2657C15.8028 14.9118 16.4176 15.3447 17.0637 15.2325C17.7099 15.1203 18.1428 14.5055 18.0306 13.8593C17.9368 13.3191 17.6681 12.4943 17.0218 11.7848C16.3405 11.037 15.3115 10.5 13.8918 10.5C12.4855 10.5 11.4607 11.0122 10.7753 11.7589C10.1255 12.4669 9.86266 13.2945 9.75741 13.8358C9.63223 14.4796 10.0526 15.103 10.6964 15.2282C11.3402 15.3533 11.9636 14.9329 12.0887 14.2892Z"
        fill="url(#paint34_radial_4451_12858)"
      />
      <Path
        d="M12.0887 14.2892C12.1458 13.9955 12.2765 13.6356 12.525 13.3649C12.738 13.1329 13.1164 12.875 13.8918 12.875C14.6539 12.875 15.042 13.1382 15.266 13.3842C15.5251 13.6686 15.6499 14.0312 15.6906 14.2657C15.8028 14.9118 16.4176 15.3447 17.0637 15.2325C17.7099 15.1203 18.1428 14.5055 18.0306 13.8593C17.9368 13.3191 17.6681 12.4943 17.0218 11.7848C16.3405 11.037 15.3115 10.5 13.8918 10.5C12.4855 10.5 11.4607 11.0122 10.7753 11.7589C10.1255 12.4669 9.86266 13.2945 9.75741 13.8358C9.63223 14.4796 10.0526 15.103 10.6964 15.2282C11.3402 15.3533 11.9636 14.9329 12.0887 14.2892Z"
        fill="url(#paint35_radial_4451_12858)"
      />
      <Path
        d="M25.1512 14.2892C25.2083 13.9955 25.339 13.6356 25.5875 13.3649C25.8005 13.1329 26.1789 12.875 26.9543 12.875C27.7164 12.875 28.1045 13.1382 28.3285 13.3842C28.5876 13.6686 28.7124 14.0312 28.7531 14.2657C28.8653 14.9118 29.4801 15.3447 30.1262 15.2325C30.7724 15.1203 31.2053 14.5055 31.0931 13.8593C30.9993 13.3191 30.7306 12.4943 30.0843 11.7848C29.403 11.037 28.374 10.5 26.9543 10.5C25.548 10.5 24.5232 11.0122 23.8378 11.7589C23.188 12.4669 22.9252 13.2945 22.8199 13.8358C22.6947 14.4796 23.1151 15.103 23.7589 15.2282C24.4027 15.3533 25.0261 14.9329 25.1512 14.2892Z"
        fill="#583F51"
      />
      <Path
        d="M25.1512 14.2892C25.2083 13.9955 25.339 13.6356 25.5875 13.3649C25.8005 13.1329 26.1789 12.875 26.9543 12.875C27.7164 12.875 28.1045 13.1382 28.3285 13.3842C28.5876 13.6686 28.7124 14.0312 28.7531 14.2657C28.8653 14.9118 29.4801 15.3447 30.1262 15.2325C30.7724 15.1203 31.2053 14.5055 31.0931 13.8593C30.9993 13.3191 30.7306 12.4943 30.0843 11.7848C29.403 11.037 28.374 10.5 26.9543 10.5C25.548 10.5 24.5232 11.0122 23.8378 11.7589C23.188 12.4669 22.9252 13.2945 22.8199 13.8358C22.6947 14.4796 23.1151 15.103 23.7589 15.2282C24.4027 15.3533 25.0261 14.9329 25.1512 14.2892Z"
        fill="url(#paint36_radial_4451_12858)"
      />
      <Path
        d="M25.1512 14.2892C25.2083 13.9955 25.339 13.6356 25.5875 13.3649C25.8005 13.1329 26.1789 12.875 26.9543 12.875C27.7164 12.875 28.1045 13.1382 28.3285 13.3842C28.5876 13.6686 28.7124 14.0312 28.7531 14.2657C28.8653 14.9118 29.4801 15.3447 30.1262 15.2325C30.7724 15.1203 31.2053 14.5055 31.0931 13.8593C30.9993 13.3191 30.7306 12.4943 30.0843 11.7848C29.403 11.037 28.374 10.5 26.9543 10.5C25.548 10.5 24.5232 11.0122 23.8378 11.7589C23.188 12.4669 22.9252 13.2945 22.8199 13.8358C22.6947 14.4796 23.1151 15.103 23.7589 15.2282C24.4027 15.3533 25.0261 14.9329 25.1512 14.2892Z"
        fill="url(#paint37_radial_4451_12858)"
      />
      <Path
        d="M25.1512 14.2892C25.2083 13.9955 25.339 13.6356 25.5875 13.3649C25.8005 13.1329 26.1789 12.875 26.9543 12.875C27.7164 12.875 28.1045 13.1382 28.3285 13.3842C28.5876 13.6686 28.7124 14.0312 28.7531 14.2657C28.8653 14.9118 29.4801 15.3447 30.1262 15.2325C30.7724 15.1203 31.2053 14.5055 31.0931 13.8593C30.9993 13.3191 30.7306 12.4943 30.0843 11.7848C29.403 11.037 28.374 10.5 26.9543 10.5C25.548 10.5 24.5232 11.0122 23.8378 11.7589C23.188 12.4669 22.9252 13.2945 22.8199 13.8358C22.6947 14.4796 23.1151 15.103 23.7589 15.2282C24.4027 15.3533 25.0261 14.9329 25.1512 14.2892Z"
        fill="url(#paint38_radial_4451_12858)"
      />
      <Path
        d="M6.28319 23.9927C6.75728 23.4277 7.59963 23.354 8.16463 23.8281L11.6939 26.7895L11.3963 25.7242C11.1899 24.985 11.6218 24.2184 12.3609 24.012C13.1041 23.8044 13.8741 24.2421 14.0759 24.9868L14.8935 28.0029L15.8867 30.3922C15.905 30.4361 15.9211 30.4807 15.9351 30.5257C16.2889 31.2083 16.4893 31.9868 16.4893 32.8132C16.4893 35.5159 14.3457 37.707 11.7015 37.707C10.5382 37.707 9.47188 37.2829 8.64229 36.5779L3.3192 32.6621C2.69492 32.2029 2.56964 31.3205 3.04142 30.7056C3.24686 30.4379 3.53099 30.2661 3.83644 30.1978L3.3306 29.7734C2.73934 29.2773 2.66221 28.3957 3.15835 27.8045C3.36914 27.5533 3.64951 27.3949 3.94763 27.3338L3.79226 27.2034C3.20099 26.7073 3.12387 25.8258 3.62 25.2345C4.11614 24.6432 4.99765 24.5661 5.58892 25.0623L6.16529 25.5459C5.87543 25.07 5.90518 24.4432 6.28319 23.9927Z"
        fill="url(#paint39_radial_4451_12858)"
      />
      <Path
        d="M6.28319 23.9927C6.75728 23.4277 7.59963 23.354 8.16463 23.8281L11.6939 26.7895L11.3963 25.7242C11.1899 24.985 11.6218 24.2184 12.3609 24.012C13.1041 23.8044 13.8741 24.2421 14.0759 24.9868L14.8935 28.0029L15.8867 30.3922C15.905 30.4361 15.9211 30.4807 15.9351 30.5257C16.2889 31.2083 16.4893 31.9868 16.4893 32.8132C16.4893 35.5159 14.3457 37.707 11.7015 37.707C10.5382 37.707 9.47188 37.2829 8.64229 36.5779L3.3192 32.6621C2.69492 32.2029 2.56964 31.3205 3.04142 30.7056C3.24686 30.4379 3.53099 30.2661 3.83644 30.1978L3.3306 29.7734C2.73934 29.2773 2.66221 28.3957 3.15835 27.8045C3.36914 27.5533 3.64951 27.3949 3.94763 27.3338L3.79226 27.2034C3.20099 26.7073 3.12387 25.8258 3.62 25.2345C4.11614 24.6432 4.99765 24.5661 5.58892 25.0623L6.16529 25.5459C5.87543 25.07 5.90518 24.4432 6.28319 23.9927Z"
        fill="url(#paint40_radial_4451_12858)"
      />
      <Path
        d="M6.28319 23.9927C6.75728 23.4277 7.59963 23.354 8.16463 23.8281L11.6939 26.7895L11.3963 25.7242C11.1899 24.985 11.6218 24.2184 12.3609 24.012C13.1041 23.8044 13.8741 24.2421 14.0759 24.9868L14.8935 28.0029L15.8867 30.3922C15.905 30.4361 15.9211 30.4807 15.9351 30.5257C16.2889 31.2083 16.4893 31.9868 16.4893 32.8132C16.4893 35.5159 14.3457 37.707 11.7015 37.707C10.5382 37.707 9.47188 37.2829 8.64229 36.5779L3.3192 32.6621C2.69492 32.2029 2.56964 31.3205 3.04142 30.7056C3.24686 30.4379 3.53099 30.2661 3.83644 30.1978L3.3306 29.7734C2.73934 29.2773 2.66221 28.3957 3.15835 27.8045C3.36914 27.5533 3.64951 27.3949 3.94763 27.3338L3.79226 27.2034C3.20099 26.7073 3.12387 25.8258 3.62 25.2345C4.11614 24.6432 4.99765 24.5661 5.58892 25.0623L6.16529 25.5459C5.87543 25.07 5.90518 24.4432 6.28319 23.9927Z"
        fill="url(#paint41_radial_4451_12858)"
      />
      <Path
        d="M6.28319 23.9927C6.75728 23.4277 7.59963 23.354 8.16463 23.8281L11.6939 26.7895L11.3963 25.7242C11.1899 24.985 11.6218 24.2184 12.3609 24.012C13.1041 23.8044 13.8741 24.2421 14.0759 24.9868L14.8935 28.0029L15.8867 30.3922C15.905 30.4361 15.9211 30.4807 15.9351 30.5257C16.2889 31.2083 16.4893 31.9868 16.4893 32.8132C16.4893 35.5159 14.3457 37.707 11.7015 37.707C10.5382 37.707 9.47188 37.2829 8.64229 36.5779L3.3192 32.6621C2.69492 32.2029 2.56964 31.3205 3.04142 30.7056C3.24686 30.4379 3.53099 30.2661 3.83644 30.1978L3.3306 29.7734C2.73934 29.2773 2.66221 28.3957 3.15835 27.8045C3.36914 27.5533 3.64951 27.3949 3.94763 27.3338L3.79226 27.2034C3.20099 26.7073 3.12387 25.8258 3.62 25.2345C4.11614 24.6432 4.99765 24.5661 5.58892 25.0623L6.16529 25.5459C5.87543 25.07 5.90518 24.4432 6.28319 23.9927Z"
        fill="url(#paint42_radial_4451_12858)"
      />
      <Path
        d="M6.28319 23.9927C6.75728 23.4277 7.59963 23.354 8.16463 23.8281L11.6939 26.7895L11.3963 25.7242C11.1899 24.985 11.6218 24.2184 12.3609 24.012C13.1041 23.8044 13.8741 24.2421 14.0759 24.9868L14.8935 28.0029L15.8867 30.3922C15.905 30.4361 15.9211 30.4807 15.9351 30.5257C16.2889 31.2083 16.4893 31.9868 16.4893 32.8132C16.4893 35.5159 14.3457 37.707 11.7015 37.707C10.5382 37.707 9.47188 37.2829 8.64229 36.5779L3.3192 32.6621C2.69492 32.2029 2.56964 31.3205 3.04142 30.7056C3.24686 30.4379 3.53099 30.2661 3.83644 30.1978L3.3306 29.7734C2.73934 29.2773 2.66221 28.3957 3.15835 27.8045C3.36914 27.5533 3.64951 27.3949 3.94763 27.3338L3.79226 27.2034C3.20099 26.7073 3.12387 25.8258 3.62 25.2345C4.11614 24.6432 4.99765 24.5661 5.58892 25.0623L6.16529 25.5459C5.87543 25.07 5.90518 24.4432 6.28319 23.9927Z"
        fill="url(#paint43_radial_4451_12858)"
      />
      <Path
        d="M6.28319 23.9927C6.75728 23.4277 7.59963 23.354 8.16463 23.8281L11.6939 26.7895L11.3963 25.7242C11.1899 24.985 11.6218 24.2184 12.3609 24.012C13.1041 23.8044 13.8741 24.2421 14.0759 24.9868L14.8935 28.0029L15.8867 30.3922C15.905 30.4361 15.9211 30.4807 15.9351 30.5257C16.2889 31.2083 16.4893 31.9868 16.4893 32.8132C16.4893 35.5159 14.3457 37.707 11.7015 37.707C10.5382 37.707 9.47188 37.2829 8.64229 36.5779L3.3192 32.6621C2.69492 32.2029 2.56964 31.3205 3.04142 30.7056C3.24686 30.4379 3.53099 30.2661 3.83644 30.1978L3.3306 29.7734C2.73934 29.2773 2.66221 28.3957 3.15835 27.8045C3.36914 27.5533 3.64951 27.3949 3.94763 27.3338L3.79226 27.2034C3.20099 26.7073 3.12387 25.8258 3.62 25.2345C4.11614 24.6432 4.99765 24.5661 5.58892 25.0623L6.16529 25.5459C5.87543 25.07 5.90518 24.4432 6.28319 23.9927Z"
        fill="url(#paint44_radial_4451_12858)"
      />
      <Path
        d="M3.81203 30.1892C3.81203 30.1892 3.18812 30.3923 2.93119 30.8773C2.46407 31.7591 3.18128 32.5598 3.18128 32.5598L4.7529 33.7118C5.99494 34.6221 7.72955 34.4169 8.72481 33.2418L3.81203 30.1892Z"
        fill="url(#paint45_radial_4451_12858)"
      />
      <Path
        d="M3.81203 30.1892C3.81203 30.1892 3.18812 30.3923 2.93119 30.8773C2.46407 31.7591 3.18128 32.5598 3.18128 32.5598L4.7529 33.7118C5.99494 34.6221 7.72955 34.4169 8.72481 33.2418L3.81203 30.1892Z"
        fill="url(#paint46_radial_4451_12858)"
      />
      <Path
        d="M8.14551 30.7827L7.46116 32.4536C7.46116 32.4536 4.61301 30.6955 3.83372 30.1992C3.62498 30.0229 3.63889 30.0554 3.24461 29.6889C2.85032 29.3225 2.61658 28.5929 3.08832 27.893C3.29099 27.5923 3.66243 27.3814 3.96054 27.3203L8.14551 30.7827Z"
        fill="url(#paint47_radial_4451_12858)"
      />
      <Path
        d="M8.14551 30.7827L7.46116 32.4536C7.46116 32.4536 4.61301 30.6955 3.83372 30.1992C3.62498 30.0229 3.63889 30.0554 3.24461 29.6889C2.85032 29.3225 2.61658 28.5929 3.08832 27.893C3.29099 27.5923 3.66243 27.3814 3.96054 27.3203L8.14551 30.7827Z"
        fill="url(#paint48_radial_4451_12858)"
      />
      <Path
        d="M10.1807 29.2396L8.12611 30.7708L3.81622 27.2025C3.22495 26.7064 3.14782 25.8249 3.64396 25.2336C4.14009 24.6424 5.0216 24.5652 5.61287 25.0614L6.15657 25.5361C6.97652 26.3888 8.95703 28.129 10.1807 29.2396Z"
        fill="url(#paint49_radial_4451_12858)"
      />
      <Path
        d="M10.1807 29.2396L8.12611 30.7708L3.81622 27.2025C3.22495 26.7064 3.14782 25.8249 3.64396 25.2336C4.14009 24.6424 5.0216 24.5652 5.61287 25.0614L6.15657 25.5361C6.97652 26.3888 8.95703 28.129 10.1807 29.2396Z"
        fill="url(#paint50_radial_4451_12858)"
      />
      <Path
        d="M10.1807 29.2396L8.12611 30.7708L3.81622 27.2025C3.22495 26.7064 3.14782 25.8249 3.64396 25.2336C4.14009 24.6424 5.0216 24.5652 5.61287 25.0614L6.15657 25.5361C6.97652 26.3888 8.95703 28.129 10.1807 29.2396Z"
        fill="url(#paint51_linear_4451_12858)"
      />
      <Path
        d="M8.16236 23.8278C7.54216 23.3292 6.71683 23.4346 6.26513 24.0134C5.81988 24.5839 5.95552 25.1628 6.16774 25.5627C7.0723 26.4673 10.046 29.1135 10.046 29.1135L12.3705 29.4453L11.6877 26.7873C11.6877 26.7873 9.57314 24.9621 8.16236 23.8278Z"
        fill="url(#paint52_radial_4451_12858)"
      />
      <Path
        d="M8.16236 23.8278C7.54216 23.3292 6.71683 23.4346 6.26513 24.0134C5.81988 24.5839 5.95552 25.1628 6.16774 25.5627C7.0723 26.4673 10.046 29.1135 10.046 29.1135L12.3705 29.4453L11.6877 26.7873C11.6877 26.7873 9.57314 24.9621 8.16236 23.8278Z"
        fill="url(#paint53_radial_4451_12858)"
      />
      <Path
        d="M8.16236 23.8278C7.54216 23.3292 6.71683 23.4346 6.26513 24.0134C5.81988 24.5839 5.95552 25.1628 6.16774 25.5627C7.0723 26.4673 10.046 29.1135 10.046 29.1135L12.3705 29.4453L11.6877 26.7873C11.6877 26.7873 9.57314 24.9621 8.16236 23.8278Z"
        fill="url(#paint54_linear_4451_12858)"
      />
      <Path
        d="M34.5811 24.0044C34.107 23.4394 33.2646 23.3657 32.6996 23.8398L29.1704 26.8012L29.4679 25.7359C29.6744 24.9967 29.2425 24.2301 28.5033 24.0237C27.7602 23.8161 26.9902 24.2538 26.7883 24.9985L25.9707 28.0146L24.9775 30.4039C24.9593 30.4479 24.9431 30.4924 24.9291 30.5374C24.5754 31.2201 24.375 31.9986 24.375 32.8249C24.375 35.5276 26.5186 37.7187 29.1628 37.7187C30.326 37.7187 31.3924 37.2947 32.222 36.5896L37.5451 32.6739C38.1693 32.2146 38.2946 31.3322 37.8228 30.7173C37.6174 30.4496 37.3333 30.2778 37.0278 30.2096L37.5337 29.7851C38.1249 29.289 38.202 28.4075 37.7059 27.8162C37.4951 27.565 37.2148 27.4066 36.9166 27.3455L37.072 27.2151C37.6633 26.719 37.7404 25.8375 37.2443 25.2462C36.7481 24.655 35.8666 24.5778 35.2753 25.074L34.699 25.5576C34.9888 25.0817 34.9591 24.4549 34.5811 24.0044Z"
        fill="url(#paint55_radial_4451_12858)"
      />
      <Path
        d="M34.5811 24.0044C34.107 23.4394 33.2646 23.3657 32.6996 23.8398L29.1704 26.8012L29.4679 25.7359C29.6744 24.9967 29.2425 24.2301 28.5033 24.0237C27.7602 23.8161 26.9902 24.2538 26.7883 24.9985L25.9707 28.0146L24.9775 30.4039C24.9593 30.4479 24.9431 30.4924 24.9291 30.5374C24.5754 31.2201 24.375 31.9986 24.375 32.8249C24.375 35.5276 26.5186 37.7187 29.1628 37.7187C30.326 37.7187 31.3924 37.2947 32.222 36.5896L37.5451 32.6739C38.1693 32.2146 38.2946 31.3322 37.8228 30.7173C37.6174 30.4496 37.3333 30.2778 37.0278 30.2096L37.5337 29.7851C38.1249 29.289 38.202 28.4075 37.7059 27.8162C37.4951 27.565 37.2148 27.4066 36.9166 27.3455L37.072 27.2151C37.6633 26.719 37.7404 25.8375 37.2443 25.2462C36.7481 24.655 35.8666 24.5778 35.2753 25.074L34.699 25.5576C34.9888 25.0817 34.9591 24.4549 34.5811 24.0044Z"
        fill="url(#paint56_radial_4451_12858)"
      />
      <Path
        d="M34.5811 24.0044C34.107 23.4394 33.2646 23.3657 32.6996 23.8398L29.1704 26.8012L29.4679 25.7359C29.6744 24.9967 29.2425 24.2301 28.5033 24.0237C27.7602 23.8161 26.9902 24.2538 26.7883 24.9985L25.9707 28.0146L24.9775 30.4039C24.9593 30.4479 24.9431 30.4924 24.9291 30.5374C24.5754 31.2201 24.375 31.9986 24.375 32.8249C24.375 35.5276 26.5186 37.7187 29.1628 37.7187C30.326 37.7187 31.3924 37.2947 32.222 36.5896L37.5451 32.6739C38.1693 32.2146 38.2946 31.3322 37.8228 30.7173C37.6174 30.4496 37.3333 30.2778 37.0278 30.2096L37.5337 29.7851C38.1249 29.289 38.202 28.4075 37.7059 27.8162C37.4951 27.565 37.2148 27.4066 36.9166 27.3455L37.072 27.2151C37.6633 26.719 37.7404 25.8375 37.2443 25.2462C36.7481 24.655 35.8666 24.5778 35.2753 25.074L34.699 25.5576C34.9888 25.0817 34.9591 24.4549 34.5811 24.0044Z"
        fill="url(#paint57_radial_4451_12858)"
      />
      <Path
        d="M34.5811 24.0044C34.107 23.4394 33.2646 23.3657 32.6996 23.8398L29.1704 26.8012L29.4679 25.7359C29.6744 24.9967 29.2425 24.2301 28.5033 24.0237C27.7602 23.8161 26.9902 24.2538 26.7883 24.9985L25.9707 28.0146L24.9775 30.4039C24.9593 30.4479 24.9431 30.4924 24.9291 30.5374C24.5754 31.2201 24.375 31.9986 24.375 32.8249C24.375 35.5276 26.5186 37.7187 29.1628 37.7187C30.326 37.7187 31.3924 37.2947 32.222 36.5896L37.5451 32.6739C38.1693 32.2146 38.2946 31.3322 37.8228 30.7173C37.6174 30.4496 37.3333 30.2778 37.0278 30.2096L37.5337 29.7851C38.1249 29.289 38.202 28.4075 37.7059 27.8162C37.4951 27.565 37.2148 27.4066 36.9166 27.3455L37.072 27.2151C37.6633 26.719 37.7404 25.8375 37.2443 25.2462C36.7481 24.655 35.8666 24.5778 35.2753 25.074L34.699 25.5576C34.9888 25.0817 34.9591 24.4549 34.5811 24.0044Z"
        fill="url(#paint58_radial_4451_12858)"
      />
      <Path
        d="M34.5811 24.0044C34.107 23.4394 33.2646 23.3657 32.6996 23.8398L29.1704 26.8012L29.4679 25.7359C29.6744 24.9967 29.2425 24.2301 28.5033 24.0237C27.7602 23.8161 26.9902 24.2538 26.7883 24.9985L25.9707 28.0146L24.9775 30.4039C24.9593 30.4479 24.9431 30.4924 24.9291 30.5374C24.5754 31.2201 24.375 31.9986 24.375 32.8249C24.375 35.5276 26.5186 37.7187 29.1628 37.7187C30.326 37.7187 31.3924 37.2947 32.222 36.5896L37.5451 32.6739C38.1693 32.2146 38.2946 31.3322 37.8228 30.7173C37.6174 30.4496 37.3333 30.2778 37.0278 30.2096L37.5337 29.7851C38.1249 29.289 38.202 28.4075 37.7059 27.8162C37.4951 27.565 37.2148 27.4066 36.9166 27.3455L37.072 27.2151C37.6633 26.719 37.7404 25.8375 37.2443 25.2462C36.7481 24.655 35.8666 24.5778 35.2753 25.074L34.699 25.5576C34.9888 25.0817 34.9591 24.4549 34.5811 24.0044Z"
        fill="url(#paint59_radial_4451_12858)"
      />
      <Path
        d="M34.5811 24.0044C34.107 23.4394 33.2646 23.3657 32.6996 23.8398L29.1704 26.8012L29.4679 25.7359C29.6744 24.9967 29.2425 24.2301 28.5033 24.0237C27.7602 23.8161 26.9902 24.2538 26.7883 24.9985L25.9707 28.0146L24.9775 30.4039C24.9593 30.4479 24.9431 30.4924 24.9291 30.5374C24.5754 31.2201 24.375 31.9986 24.375 32.8249C24.375 35.5276 26.5186 37.7187 29.1628 37.7187C30.326 37.7187 31.3924 37.2947 32.222 36.5896L37.5451 32.6739C38.1693 32.2146 38.2946 31.3322 37.8228 30.7173C37.6174 30.4496 37.3333 30.2778 37.0278 30.2096L37.5337 29.7851C38.1249 29.289 38.202 28.4075 37.7059 27.8162C37.4951 27.565 37.2148 27.4066 36.9166 27.3455L37.072 27.2151C37.6633 26.719 37.7404 25.8375 37.2443 25.2462C36.7481 24.655 35.8666 24.5778 35.2753 25.074L34.699 25.5576C34.9888 25.0817 34.9591 24.4549 34.5811 24.0044Z"
        fill="url(#paint60_linear_4451_12858)"
      />
      <Path
        d="M34.5811 24.0044C34.107 23.4394 33.2646 23.3657 32.6996 23.8398L29.1704 26.8012L29.4679 25.7359C29.6744 24.9967 29.2425 24.2301 28.5033 24.0237C27.7602 23.8161 26.9902 24.2538 26.7883 24.9985L25.9707 28.0146L24.9775 30.4039C24.9593 30.4479 24.9431 30.4924 24.9291 30.5374C24.5754 31.2201 24.375 31.9986 24.375 32.8249C24.375 35.5276 26.5186 37.7187 29.1628 37.7187C30.326 37.7187 31.3924 37.2947 32.222 36.5896L37.5451 32.6739C38.1693 32.2146 38.2946 31.3322 37.8228 30.7173C37.6174 30.4496 37.3333 30.2778 37.0278 30.2096L37.5337 29.7851C38.1249 29.289 38.202 28.4075 37.7059 27.8162C37.4951 27.565 37.2148 27.4066 36.9166 27.3455L37.072 27.2151C37.6633 26.719 37.7404 25.8375 37.2443 25.2462C36.7481 24.655 35.8666 24.5778 35.2753 25.074L34.699 25.5576C34.9888 25.0817 34.9591 24.4549 34.5811 24.0044Z"
        fill="url(#paint61_linear_4451_12858)"
      />
      <Path
        d="M34.5811 24.0044C34.107 23.4394 33.2646 23.3657 32.6996 23.8398L29.1704 26.8012L29.4679 25.7359C29.6744 24.9967 29.2425 24.2301 28.5033 24.0237C27.7602 23.8161 26.9902 24.2538 26.7883 24.9985L25.9707 28.0146L24.9775 30.4039C24.9593 30.4479 24.9431 30.4924 24.9291 30.5374C24.5754 31.2201 24.375 31.9986 24.375 32.8249C24.375 35.5276 26.5186 37.7187 29.1628 37.7187C30.326 37.7187 31.3924 37.2947 32.222 36.5896L37.5451 32.6739C38.1693 32.2146 38.2946 31.3322 37.8228 30.7173C37.6174 30.4496 37.3333 30.2778 37.0278 30.2096L37.5337 29.7851C38.1249 29.289 38.202 28.4075 37.7059 27.8162C37.4951 27.565 37.2148 27.4066 36.9166 27.3455L37.072 27.2151C37.6633 26.719 37.7404 25.8375 37.2443 25.2462C36.7481 24.655 35.8666 24.5778 35.2753 25.074L34.699 25.5576C34.9888 25.0817 34.9591 24.4549 34.5811 24.0044Z"
        fill="url(#paint62_linear_4451_12858)"
      />
      <Path
        d="M34.5811 24.0044C34.107 23.4394 33.2646 23.3657 32.6996 23.8398L29.1704 26.8012L29.4679 25.7359C29.6744 24.9967 29.2425 24.2301 28.5033 24.0237C27.7602 23.8161 26.9902 24.2538 26.7883 24.9985L25.9707 28.0146L24.9775 30.4039C24.9593 30.4479 24.9431 30.4924 24.9291 30.5374C24.5754 31.2201 24.375 31.9986 24.375 32.8249C24.375 35.5276 26.5186 37.7187 29.1628 37.7187C30.326 37.7187 31.3924 37.2947 32.222 36.5896L37.5451 32.6739C38.1693 32.2146 38.2946 31.3322 37.8228 30.7173C37.6174 30.4496 37.3333 30.2778 37.0278 30.2096L37.5337 29.7851C38.1249 29.289 38.202 28.4075 37.7059 27.8162C37.4951 27.565 37.2148 27.4066 36.9166 27.3455L37.072 27.2151C37.6633 26.719 37.7404 25.8375 37.2443 25.2462C36.7481 24.655 35.8666 24.5778 35.2753 25.074L34.699 25.5576C34.9888 25.0817 34.9591 24.4549 34.5811 24.0044Z"
        fill="url(#paint63_radial_4451_12858)"
      />
      <Path
        d="M34.5811 24.0044C34.107 23.4394 33.2646 23.3657 32.6996 23.8398L29.1704 26.8012L29.4679 25.7359C29.6744 24.9967 29.2425 24.2301 28.5033 24.0237C27.7602 23.8161 26.9902 24.2538 26.7883 24.9985L25.9707 28.0146L24.9775 30.4039C24.9593 30.4479 24.9431 30.4924 24.9291 30.5374C24.5754 31.2201 24.375 31.9986 24.375 32.8249C24.375 35.5276 26.5186 37.7187 29.1628 37.7187C30.326 37.7187 31.3924 37.2947 32.222 36.5896L37.5451 32.6739C38.1693 32.2146 38.2946 31.3322 37.8228 30.7173C37.6174 30.4496 37.3333 30.2778 37.0278 30.2096L37.5337 29.7851C38.1249 29.289 38.202 28.4075 37.7059 27.8162C37.4951 27.565 37.2148 27.4066 36.9166 27.3455L37.072 27.2151C37.6633 26.719 37.7404 25.8375 37.2443 25.2462C36.7481 24.655 35.8666 24.5778 35.2753 25.074L34.699 25.5576C34.9888 25.0817 34.9591 24.4549 34.5811 24.0044Z"
        fill="url(#paint64_radial_4451_12858)"
      />
      <Path
        d="M34.5811 24.0044C34.107 23.4394 33.2646 23.3657 32.6996 23.8398L29.1704 26.8012L29.4679 25.7359C29.6744 24.9967 29.2425 24.2301 28.5033 24.0237C27.7602 23.8161 26.9902 24.2538 26.7883 24.9985L25.9707 28.0146L24.9775 30.4039C24.9593 30.4479 24.9431 30.4924 24.9291 30.5374C24.5754 31.2201 24.375 31.9986 24.375 32.8249C24.375 35.5276 26.5186 37.7187 29.1628 37.7187C30.326 37.7187 31.3924 37.2947 32.222 36.5896L37.5451 32.6739C38.1693 32.2146 38.2946 31.3322 37.8228 30.7173C37.6174 30.4496 37.3333 30.2778 37.0278 30.2096L37.5337 29.7851C38.1249 29.289 38.202 28.4075 37.7059 27.8162C37.4951 27.565 37.2148 27.4066 36.9166 27.3455L37.072 27.2151C37.6633 26.719 37.7404 25.8375 37.2443 25.2462C36.7481 24.655 35.8666 24.5778 35.2753 25.074L34.699 25.5576C34.9888 25.0817 34.9591 24.4549 34.5811 24.0044Z"
        fill="url(#paint65_radial_4451_12858)"
      />
      <Path
        d="M34.5811 24.0044C34.107 23.4394 33.2646 23.3657 32.6996 23.8398L29.1704 26.8012L29.4679 25.7359C29.6744 24.9967 29.2425 24.2301 28.5033 24.0237C27.7602 23.8161 26.9902 24.2538 26.7883 24.9985L25.9707 28.0146L24.9775 30.4039C24.9593 30.4479 24.9431 30.4924 24.9291 30.5374C24.5754 31.2201 24.375 31.9986 24.375 32.8249C24.375 35.5276 26.5186 37.7187 29.1628 37.7187C30.326 37.7187 31.3924 37.2947 32.222 36.5896L37.5451 32.6739C38.1693 32.2146 38.2946 31.3322 37.8228 30.7173C37.6174 30.4496 37.3333 30.2778 37.0278 30.2096L37.5337 29.7851C38.1249 29.289 38.202 28.4075 37.7059 27.8162C37.4951 27.565 37.2148 27.4066 36.9166 27.3455L37.072 27.2151C37.6633 26.719 37.7404 25.8375 37.2443 25.2462C36.7481 24.655 35.8666 24.5778 35.2753 25.074L34.699 25.5576C34.9888 25.0817 34.9591 24.4549 34.5811 24.0044Z"
        fill="url(#paint66_radial_4451_12858)"
      />
      <Path
        d="M34.5811 24.0044C34.107 23.4394 33.2646 23.3657 32.6996 23.8398L29.1704 26.8012L29.4679 25.7359C29.6744 24.9967 29.2425 24.2301 28.5033 24.0237C27.7602 23.8161 26.9902 24.2538 26.7883 24.9985L25.9707 28.0146L24.9775 30.4039C24.9593 30.4479 24.9431 30.4924 24.9291 30.5374C24.5754 31.2201 24.375 31.9986 24.375 32.8249C24.375 35.5276 26.5186 37.7187 29.1628 37.7187C30.326 37.7187 31.3924 37.2947 32.222 36.5896L37.5451 32.6739C38.1693 32.2146 38.2946 31.3322 37.8228 30.7173C37.6174 30.4496 37.3333 30.2778 37.0278 30.2096L37.5337 29.7851C38.1249 29.289 38.202 28.4075 37.7059 27.8162C37.4951 27.565 37.2148 27.4066 36.9166 27.3455L37.072 27.2151C37.6633 26.719 37.7404 25.8375 37.2443 25.2462C36.7481 24.655 35.8666 24.5778 35.2753 25.074L34.699 25.5576C34.9888 25.0817 34.9591 24.4549 34.5811 24.0044Z"
        fill="url(#paint67_radial_4451_12858)"
      />
      <Path
        d="M34.5811 24.0044C34.107 23.4394 33.2646 23.3657 32.6996 23.8398L29.1704 26.8012L29.4679 25.7359C29.6744 24.9967 29.2425 24.2301 28.5033 24.0237C27.7602 23.8161 26.9902 24.2538 26.7883 24.9985L25.9707 28.0146L24.9775 30.4039C24.9593 30.4479 24.9431 30.4924 24.9291 30.5374C24.5754 31.2201 24.375 31.9986 24.375 32.8249C24.375 35.5276 26.5186 37.7187 29.1628 37.7187C30.326 37.7187 31.3924 37.2947 32.222 36.5896L37.5451 32.6739C38.1693 32.2146 38.2946 31.3322 37.8228 30.7173C37.6174 30.4496 37.3333 30.2778 37.0278 30.2096L37.5337 29.7851C38.1249 29.289 38.202 28.4075 37.7059 27.8162C37.4951 27.565 37.2148 27.4066 36.9166 27.3455L37.072 27.2151C37.6633 26.719 37.7404 25.8375 37.2443 25.2462C36.7481 24.655 35.8666 24.5778 35.2753 25.074L34.699 25.5576C34.9888 25.0817 34.9591 24.4549 34.5811 24.0044Z"
        fill="url(#paint68_radial_4451_12858)"
      />
      <Path
        d="M37.0372 30.205C37.0372 30.205 37.7255 30.3749 37.9827 30.9729C38.4006 31.9445 37.6327 32.6027 37.6327 32.6027L34.6396 34.7967L33.6535 32.8067L37.0372 30.205Z"
        fill="url(#paint69_radial_4451_12858)"
      />
      <Path
        d="M37.0372 30.205C37.0372 30.205 37.7255 30.3749 37.9827 30.9729C38.4006 31.9445 37.6327 32.6027 37.6327 32.6027L34.6396 34.7967L33.6535 32.8067L37.0372 30.205Z"
        fill="url(#paint70_radial_4451_12858)"
      />
      <Path
        d="M33.1686 30.5912C32.7849 30.922 32.3406 31.6445 32.5514 31.8957C33.0475 32.487 34.2769 32.3919 34.8682 31.8957L37.4581 29.8524C38.1843 29.2153 38.1686 28.3881 37.6725 27.7969C37.4617 27.5457 37.2097 27.4087 36.9116 27.3477L33.1686 30.5912Z"
        fill="url(#paint71_radial_4451_12858)"
      />
      <Path
        d="M33.1686 30.5912C32.7849 30.922 32.3406 31.6445 32.5514 31.8957C33.0475 32.487 34.2769 32.3919 34.8682 31.8957L37.4581 29.8524C38.1843 29.2153 38.1686 28.3881 37.6725 27.7969C37.4617 27.5457 37.2097 27.4087 36.9116 27.3477L33.1686 30.5912Z"
        fill="url(#paint72_linear_4451_12858)"
      />
      <Path
        d="M33.1686 30.5912C32.7849 30.922 32.3406 31.6445 32.5514 31.8957C33.0475 32.487 34.2769 32.3919 34.8682 31.8957L37.4581 29.8524C38.1843 29.2153 38.1686 28.3881 37.6725 27.7969C37.4617 27.5457 37.2097 27.4087 36.9116 27.3477L33.1686 30.5912Z"
        fill="url(#paint73_radial_4451_12858)"
      />
      <Path
        d="M31.3128 28.563C30.717 29.0728 30.9149 29.8587 31.3128 30.3329C31.8089 30.9242 32.6904 31.0013 33.2817 30.5052L37.0632 27.2216C37.6545 26.7255 37.7168 25.8249 37.2207 25.2336C36.7246 24.6424 35.843 24.5652 35.2518 25.0614L34.6754 25.545C34.6017 25.666 34.5074 25.7772 34.3929 25.8732L31.3128 28.563Z"
        fill="url(#paint74_radial_4451_12858)"
      />
      <Path
        d="M31.3128 28.563C30.717 29.0728 30.9149 29.8587 31.3128 30.3329C31.8089 30.9242 32.6904 31.0013 33.2817 30.5052L37.0632 27.2216C37.6545 26.7255 37.7168 25.8249 37.2207 25.2336C36.7246 24.6424 35.843 24.5652 35.2518 25.0614L34.6754 25.545C34.6017 25.666 34.5074 25.7772 34.3929 25.8732L31.3128 28.563Z"
        fill="url(#paint75_radial_4451_12858)"
      />
      <Path
        d="M31.3128 28.563C30.717 29.0728 30.9149 29.8587 31.3128 30.3329C31.8089 30.9242 32.6904 31.0013 33.2817 30.5052L37.0632 27.2216C37.6545 26.7255 37.7168 25.8249 37.2207 25.2336C36.7246 24.6424 35.843 24.5652 35.2518 25.0614L34.6754 25.545C34.6017 25.666 34.5074 25.7772 34.3929 25.8732L31.3128 28.563Z"
        fill="url(#paint76_linear_4451_12858)"
      />
      <Path
        d="M32.6657 23.836C33.2363 23.3574 34.0868 23.4319 34.5656 24.0024C35.0377 24.5651 34.9728 25.4021 34.4195 25.8852L31.8239 28.1515C30.7247 29.1113 29.0166 28.2406 29.1475 26.7872L32.6657 23.836Z"
        fill="url(#paint77_radial_4451_12858)"
      />
      <Path
        d="M32.6657 23.836C33.2363 23.3574 34.0868 23.4319 34.5656 24.0024C35.0377 24.5651 34.9728 25.4021 34.4195 25.8852L31.8239 28.1515C30.7247 29.1113 29.0166 28.2406 29.1475 26.7872L32.6657 23.836Z"
        fill="url(#paint78_linear_4451_12858)"
      />
      <Path
        d="M32.6657 23.836C33.2363 23.3574 34.0868 23.4319 34.5656 24.0024C35.0377 24.5651 34.9728 25.4021 34.4195 25.8852L31.8239 28.1515C30.7247 29.1113 29.0166 28.2406 29.1475 26.7872L32.6657 23.836Z"
        fill="url(#paint79_radial_4451_12858)"
      />
      <Defs>
        <radialGradient
          id="paint0_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.7041 11.6875) rotate(131.878) scale(46.2516)"
        >
          <Stop stopColor="#FFF478" />
          <Stop offset="0.474827" stopColor="#FFB02E" />
          <Stop offset="1" stopColor="#F70A8D" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.4229 15.8438) rotate(101.31) scale(21.1928 27.1439)"
        >
          <Stop offset="0.787821" stopColor="#F59639" stopOpacity="0" />
          <Stop offset="0.972509" stopColor="#FF7DCE" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.7979 17.625) rotate(135) scale(48.702)"
        >
          <Stop offset="0.314853" stopOpacity="0" />
          <Stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.4229 21.1875) rotate(77.692) scale(33.4245)"
        >
          <Stop offset="0.507903" stopColor="#7D6133" stopOpacity="0" />
          <Stop offset="1" stopColor="#715B32" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.0166 20.5938) rotate(55.7131) scale(15.8098 11.4598)"
        >
          <Stop stopColor="#FFB849" />
          <Stop offset="1" stopColor="#FFB847" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.7666 22.375) rotate(9.86581) scale(13.8612)"
        >
          <Stop stopColor="#FFA64B" />
          <Stop offset="0.900412" stopColor="#FFAE46" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.2041 18.8125) rotate(43.9708) scale(70.1254)"
        >
          <Stop offset="0.185425" stopOpacity="0" />
          <Stop offset="1" stopOpacity="0.4" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(29.191 14.5927) rotate(39.8978) scale(1.95048 1.19386)"
        >
          <Stop stopColor="#D27C1D" />
          <Stop offset="1" stopColor="#CB7620" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.4846 12.4971) rotate(33.6901) scale(3.0223 1.36512)"
        >
          <Stop stopColor="#D28118" />
          <Stop offset="1" stopColor="#D68623" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.7539 13.8295) rotate(29.6831) scale(2.37435 1.92708)"
        >
          <Stop stopColor="#BE7227" />
          <Stop offset="1" stopColor="#C67A2A" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint10_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.8974 14.1389) rotate(4.8884) scale(3.14687 2.32493)"
        >
          <Stop stopColor="#D07E2D" />
          <Stop offset="1" stopColor="#D98833" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint11_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.0606 15.6035) rotate(-30.75) scale(1.89619 1.53469)"
        >
          <Stop stopColor="#DA8924" />
          <Stop offset="1" stopColor="#DC8924" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint12_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.8436 14.6563) rotate(23.8682) scale(2.17737 1.37349)"
        >
          <Stop stopColor="#D59A13" />
          <Stop offset="1" stopColor="#D59A13" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint13_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.4694 16.5268) rotate(-10.3912) scale(8.39675 6.19715)"
        >
          <Stop stopColor="#F1A227" />
          <Stop offset="1" stopColor="#F5A72C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint14_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.6532 13.807) rotate(132.51) scale(2.10891)"
        >
          <Stop stopColor="#E8942F" />
          <Stop offset="1" stopColor="#FAAB4B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint15_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.1458 34.0451) rotate(39.9448) scale(7.72219)"
        >
          <Stop stopColor="#F47360" />
          <Stop offset="1" stopColor="#F47360" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint16_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.7888 32.9668) rotate(119.445) scale(6.39775)"
        >
          <Stop stopColor="#F47360" />
          <Stop offset="1" stopColor="#F47360" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint17_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.6997 26.3525) rotate(128.804) scale(8.19719)"
        >
          <Stop stopColor="#FF6501" />
          <Stop offset="1" stopColor="#F9883A" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint18_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.90421 26.8564) rotate(48.0848) scale(10.6267)"
        >
          <Stop stopColor="#BC6526" />
          <Stop offset="1" stopColor="#CF7432" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint19_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.0428 31.6696) rotate(-19.4401) scale(3.25296 5.00018)"
        >
          <Stop offset="0.31649" stopColor="#F16E5C" />
          <Stop offset="1" stopColor="#BC642E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint20_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.2846 26.7976) rotate(69.7435) scale(3.83167 2.13879)"
        >
          <Stop offset="0.297036" stopColor="#BC642E" />
          <Stop offset="1" stopColor="#BC642E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint21_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.0375 28.2693) rotate(66.8014) scale(4.47523 1.55138)"
        >
          <Stop offset="0.297036" stopColor="#8C4A23" />
          <Stop offset="1" stopColor="#BC642E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint22_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.296 25.8964) rotate(41.9059) scale(4.2848 0.920222)"
        >
          <Stop offset="0.297036" stopColor="#8C4A23" />
          <Stop offset="1" stopColor="#BC642E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint23_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.61401 27.0915) rotate(30.5925) scale(7.81196 4.20866)"
        >
          <Stop offset="0.297036" stopColor="#BC5614" />
          <Stop offset="1" stopColor="#BC642E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint24_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.4808 31.4891) rotate(102.721) scale(5.26247 4.37379)"
        >
          <Stop offset="0.0677083" stopColor="#7E281F" />
          <Stop offset="1" stopColor="#E96C5E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint25_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.262 30.5502) rotate(108.159) scale(4.42576 2.28756)"
        >
          <Stop offset="0.0677083" stopColor="#B84D2D" />
          <Stop offset="0.598958" stopColor="#D5613F" />
          <Stop offset="1" stopColor="#D4613F" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint26_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.9186 28.3123) rotate(92.4003) scale(5.23664 3.0216)"
        >
          <Stop offset="0.0677083" stopColor="#B85B2C" />
          <Stop offset="1" stopColor="#B85B2C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint27_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.181 28.6495) rotate(95.6196) scale(4.91858 1.87658)"
        >
          <Stop offset="0.666667" stopColor="#BB5029" />
          <Stop offset="1" stopColor="#D4613F" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint28_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(30.4372 27.914) rotate(113.318) scale(4.78611 2.76164)"
        >
          <Stop offset="0.0677083" stopColor="#B85B2C" />
          <Stop offset="1" stopColor="#B85B2C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint29_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(29.9067 28.4445) rotate(122.242) scale(5.82368 2.52884)"
        >
          <Stop offset="0.666667" stopColor="#BB5029" />
          <Stop offset="1" stopColor="#D4613F" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint30_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.65302 18.1482) rotate(22.1688) scale(4.90397 5.0949)"
        >
          <Stop stopColor="#F97660" />
          <Stop offset="1" stopColor="#F97660" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint31_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.2806 18.945) rotate(173.658) scale(9.53965)"
        >
          <Stop stopColor="#FE9671" />
          <Stop offset="1" stopColor="#FFD15A" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint32_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.4231 15.9627) rotate(90) scale(8.55 9.34787)"
        >
          <Stop offset="0.517632" stopColor="#4E2553" />
          <Stop offset="0.702876" stopColor="#5B4852" />
          <Stop offset="1" stopColor="#4E2553" />
          <Stop offset="1" stopColor="#4E2553" />
        </radialGradient>
        <radialGradient
          id="paint33_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.8918 14.3334) rotate(90) scale(3.31096)"
        >
          <Stop offset="0.188596" stopColor="#1D1019" />
          <Stop offset="1" stopColor="#462B3F" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint34_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.8918 14.3334) rotate(90) scale(4.28392 4.8621)"
        >
          <Stop offset="0.718644" stopColor="#462B3F" stopOpacity="0" />
          <Stop offset="1" stopColor="#1D1019" />
        </radialGradient>
        <radialGradient
          id="paint35_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.8918 12.1328) rotate(90) scale(3.93359 8.26304)"
        >
          <Stop offset="0.429103" stopColor="#462B3F" stopOpacity="0" />
          <Stop offset="1" stopColor="#1D1019" />
        </radialGradient>
        <radialGradient
          id="paint36_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.9543 14.3334) rotate(90) scale(3.31096)"
        >
          <Stop offset="0.188596" stopColor="#1D1019" />
          <Stop offset="1" stopColor="#462B3F" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint37_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.9543 14.3334) rotate(90) scale(4.28392 4.8621)"
        >
          <Stop offset="0.718644" stopColor="#462B3F" stopOpacity="0" />
          <Stop offset="1" stopColor="#1D1019" />
        </radialGradient>
        <radialGradient
          id="paint38_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.9543 12.1328) rotate(90) scale(3.93359 8.26304)"
        >
          <Stop offset="0.429103" stopColor="#462B3F" stopOpacity="0" />
          <Stop offset="1" stopColor="#1D1019" />
        </radialGradient>
        <radialGradient
          id="paint39_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.2958 24.1121) rotate(117.586) scale(22.3534 22.2863)"
        >
          <Stop offset="0.239583" stopColor="#FFCC42" />
          <Stop offset="0.562377" stopColor="#FFB02E" />
          <Stop offset="1" stopColor="#F70A8D" />
        </radialGradient>
        <radialGradient
          id="paint40_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.7374 24.8444) rotate(124.346) scale(14.1216 19.0834)"
        >
          <Stop offset="0.65203" stopColor="#FFB745" stopOpacity="0" />
          <Stop offset="0.855204" stopColor="#F69044" />
          <Stop offset="0.930316" stopColor="#DE6A63" />
        </radialGradient>
        <radialGradient
          id="paint41_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.6155 25.8077) rotate(70.4012) scale(3.23222 1.70729)"
        >
          <Stop stopColor="#EE982C" />
          <Stop offset="1" stopColor="#EEA02C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint42_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.2918 24.2863) rotate(47.2906) scale(1.68666)"
        >
          <Stop stopColor="#DA8C2B" />
          <Stop offset="1" stopColor="#EEA02C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint43_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.4893 30.8413) rotate(90) scale(4.93227 2.22832)"
        >
          <Stop stopColor="#FFDA3C" />
          <Stop offset="1" stopColor="#FFDA3C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint44_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.6857 27.9282) rotate(75.3188) scale(4.35303 1.74872)"
        >
          <Stop stopColor="#FFD743" />
          <Stop offset="1" stopColor="#FFD743" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint45_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.46231 32.1327) rotate(34.5027) scale(2.82226 0.806521)"
        >
          <Stop stopColor="#FFCE43" />
          <Stop offset="1" stopColor="#FFCE43" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint46_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(4.50052 29.9105) rotate(38.4319) scale(4.24676 1.05178)"
        >
          <Stop offset="0.385833" stopColor="#83480A" />
          <Stop offset="0.609375" stopColor="#CD7313" />
          <Stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint47_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.73199 28.8072) rotate(-143.922) scale(3.92216 0.797306)"
        >
          <Stop stopColor="#A03B04" />
          <Stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint48_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.01984 29.5667) rotate(37.6781) scale(2.68978 0.705762)"
        >
          <Stop stopColor="#FFCE43" />
          <Stop offset="1" stopColor="#FFCE43" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint49_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7.86422 26.9085) rotate(-140.646) scale(4.49223 1.44993)"
        >
          <Stop stopColor="#A03B04" />
          <Stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint50_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.74881 27.5371) rotate(40.8979) scale(3.51025 0.870276)"
        >
          <Stop stopColor="#FFDB3F" />
          <Stop offset="1" stopColor="#FFDB3F" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint51_linear_4451_12858"
          x1="4.33061"
          y1="29.1909"
          x2="5.63952"
          y2="27.7484"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#CD7313" />
          <Stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint52_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.20148 25.7853) rotate(39.4968) scale(3.96745 0.592482)"
        >
          <Stop stopColor="#FFF478" stopOpacity="0.3" />
          <Stop offset="1" stopColor="#FFF478" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint53_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.0805 24.8906) rotate(146.31) scale(2.45949 4.79915)"
        >
          <Stop offset="0.532661" stopColor="#83480A" />
          <Stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint54_linear_4451_12858"
          x1="6.32347"
          y1="26.9002"
          x2="8.01141"
          y2="25.7289"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.173754" stopColor="#CD7313" />
          <Stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint55_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.301 22.8059) rotate(137.605) scale(33.0482 32.9491)"
        >
          <Stop offset="0.239583" stopColor="#FEB63A" />
          <Stop offset="0.537866" stopColor="#FFB02E" />
          <Stop offset="1" stopColor="#F70A8D" />
        </radialGradient>
        <radialGradient
          id="paint56_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.7796 36.2228) rotate(141.815) scale(15.2008 15.3205)"
        >
          <Stop offset="0.239583" stopColor="#FFB52F" stopOpacity="0" />
          <Stop offset="0.526687" stopColor="#FFD142" />
          <Stop offset="0.584428" stopColor="#FFD142" />
          <Stop offset="1" stopColor="#FFBB30" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint57_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.338 28.0495) rotate(68.724) scale(3.17515 3.09895)"
        >
          <Stop stopColor="#FEB32F" />
          <Stop offset="1" stopColor="#FEB32F" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint58_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.4534 28.9841) rotate(130.097) scale(2.88987 1.15202)"
        >
          <Stop stopColor="#FFBB33" />
          <Stop offset="1" stopColor="#FFBB33" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint59_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.1585 31.0038) rotate(136.035) scale(2.75072 0.987823)"
        >
          <Stop stopColor="#FFBB33" />
          <Stop offset="1" stopColor="#FFBB33" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint60_linear_4451_12858"
          x1="24.7158"
          y1="27.4371"
          x2="27.6703"
          y2="28.2048"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D28B23" />
          <Stop offset="1" stopColor="#D28B23" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint61_linear_4451_12858"
          x1="20.5566"
          y1="25.7763"
          x2="25.1711"
          y2="24.8448"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.328125" stopColor="#EE891E" />
          <Stop offset="0.864583" stopColor="#FA9428" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint62_linear_4451_12858"
          x1="33.3298"
          y1="33.2825"
          x2="34.8343"
          y2="35.2075"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#FFBF45" stopOpacity="0" />
          <Stop offset="1" stopColor="#FFAF4A" />
        </linearGradient>
        <radialGradient
          id="paint63_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.8153 29.2537) rotate(96.2892) scale(9.44544 9.5453)"
        >
          <Stop offset="0.65203" stopColor="#FFB745" stopOpacity="0" />
          <Stop offset="0.892907" stopColor="#F69044" />
          <Stop offset="1" stopColor="#DE6A63" />
        </radialGradient>
        <radialGradient
          id="paint64_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28.647 26.0872) rotate(104.916) scale(2.29615 0.942812)"
        >
          <Stop stopColor="#FFD445" />
          <Stop offset="1" stopColor="#FFD345" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint65_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.9748 24.6488) rotate(70.7505) scale(1.38604 0.992583)"
        >
          <Stop stopColor="#FFDA43" />
          <Stop offset="1" stopColor="#FFDA43" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint66_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.6528 25.8732) rotate(71.6912) scale(1.73688 1.24303)"
        >
          <Stop stopColor="#FFDA43" />
          <Stop offset="1" stopColor="#FFDA43" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint67_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28.8767 24.907) rotate(78.4422) scale(1.90877 1.38064)"
        >
          <Stop offset="0.328125" stopColor="#FFDA43" />
          <Stop offset="0.723958" stopColor="#FFDA43" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint68_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(29.1911 26.7458) rotate(135.736) scale(1.5601 0.24589)"
        >
          <Stop stopColor="#B9671B" />
          <Stop offset="0.849337" stopColor="#B9671B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint69_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.4924 31.0865) rotate(-32.8914) scale(3.40547 1.33606)"
        >
          <Stop stopColor="#A03B04" />
          <Stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint70_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.524 31.4951) rotate(81.28) scale(0.952435 0.567651)"
        >
          <Stop stopColor="#FFD943" />
          <Stop offset="1" stopColor="#FFD943" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint71_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.2619 28.8054) rotate(-41.1733) scale(4.10789 0.985033)"
        >
          <Stop stopColor="#A03B04" />
          <Stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint72_linear_4451_12858"
          x1="37.2613"
          y1="30.9018"
          x2="36.2394"
          y2="29.8655"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#CD7313" />
          <Stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint73_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.5338 28.5239) rotate(70.7423) scale(1.0578 0.827021)"
        >
          <Stop stopColor="#FFDB3F" />
          <Stop offset="1" stopColor="#FFDB3F" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint74_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.8359 27.1518) rotate(-39.7323) scale(4.94759 1.05921)"
        >
          <Stop stopColor="#A03B04" />
          <Stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint75_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.9354 25.8255) rotate(65.9778) scale(0.99492 0.553304)"
        >
          <Stop stopColor="#FFDB3F" />
          <Stop offset="1" stopColor="#FFDB3F" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint76_linear_4451_12858"
          x1="36.5766"
          y1="29.2394"
          x2="35.2488"
          y2="27.8439"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#CD7313" />
          <Stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint77_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(30.2299 24.4147) rotate(51.5132) scale(2.05348 4.34739)"
        >
          <Stop offset="0.545035" stopColor="#E1A21C" />
          <Stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint78_linear_4451_12858"
          x1="33.509"
          y1="27.9076"
          x2="32.3265"
          y2="26.956"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#CD7313" />
          <Stop offset="1" stopColor="#FA9428" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint79_radial_4451_12858"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(34.1067 24.5462) rotate(72.8947) scale(1.09129 0.564639)"
        >
          <Stop stopColor="#FFDB3F" />
          <Stop offset="1" stopColor="#FFDB3F" stopOpacity="0" />
        </radialGradient>
      </Defs>
    </Svg>
  );
};

export default SmilingFaceWithOpenHandsEmoji;
