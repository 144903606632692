import React, { useState } from 'react';
import { COLORS } from '@talkspace/react-toolkit';
import styled from '../../core/styled';

const RadioGroup = styled.div({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${COLORS.baliHaiGrey}`,
  borderRadius: 8,
  overflow: 'hidden',
});

const RadioAccordion = ({ children, onChange }) => {
  const [selectedValue, setSelectedValue] = useState(null);

  const handleRadioChange = (value) => {
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <RadioGroup>
      {React.Children.map(
        children.filter((x) => !x.props.hidden),
        (child, index) =>
          React.cloneElement(child, {
            isSelected: selectedValue === child.props.value,
            onRadioChangeInternal: handleRadioChange,
            index,
          })
      )}
    </RadioGroup>
  );
};

export default RadioAccordion;
