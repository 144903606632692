import { GridColProps } from '../../../components/grid';
import { OffsetByBreakpoint } from './gridTypes';

// eslint-disable-next-line import/prefer-default-export
export const offsetPropTransform = (props: OffsetByBreakpoint): GridColProps['colOffset'] => {
  const { lgOffset, mdOffset, smOffset, xlOffset, xsOffset } = props;

  const offset: GridColProps['colOffset'] = {
    xs: xsOffset,
    sm: smOffset,
    md: mdOffset,
    lg: lgOffset,
    xl: xlOffset,
  };

  // Returns an offset but filters out undefined keys
  return Object.keys(offset).reduce((acc, key) => {
    if (offset[key] !== undefined) {
      acc[key] = offset[key];
    }
    return acc;
  }, {} as NonNullable<GridColProps['colOffset']>);
};
