import Svg, { Defs, Path, G, Mask, Use, Ellipse } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const TalkspaceVerifiedIcon = (props) => {
  const titleText = 'Talspace verified';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width="53px"
      height="56px"
      viewBox="0 0 53 56"
      title={titleText}
      aria-label={titleText}
      {...props}
    >
      <Defs>
        <Path
          id="a"
          d="M5.18660067 0.127143816L0.0328672695 0.127143816 0.0328672695 6.81715229 5.18660067 6.81715229 5.18660067 0.127143816z"
        />
      </Defs>
      <G
        transform="translate(-301 -1318) translate(301 1318)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Path
          fill={colors.loblollyGrey}
          d="M0 19.6450942L1.07646075 20.0806024 1.7146314 18.5033318 6.66606143 20.5063443 7.23404778 19.1032409 2.28243686 17.1002284 2.92549147 15.5108403 1.8492116 15.0751511z"
        />
        <Path
          d="M7.552 13.791l.851-1.086-2.25-.9-.017.02 1.416 1.966zm1.972-.634L8.26 14.771l.769 1.068-.937 1.195-3.824-5.662 1-1.276 6.416 2.354-.936 1.195-1.224-.488z"
          fill={colors.loblollyGrey}
        />
        <Path
          fill={colors.loblollyGrey}
          d="M13.3060932 9.8375855L15.6002877 8.23210092 16.2661341 9.18341881 12.7315949 11.6568453 9.00278259 6.33018858 10.2431273 5.46224666z"
        />
        <Path
          fill={colors.loblollyGrey}
          d="M18.8928358 5.35302596L18.229341 5.56571604 19.0250645 8.04908979 17.5833922 8.51100345 15.5992386 2.3194794 17.0410918 1.85738487 17.8178222 4.28125604 18.3069416 4.12463222 18.8912078 1.26489107 20.6774706 0.692472609 19.6574468 4.10944007 22.8273171 6.831005 21.0750611 7.39221021z"
        />
        <G transform="translate(1.266 .054)">
          <G transform="translate(22.61)">
            <Mask id="b" fill={colors.white}>
              <Use xlinkHref="#a" href="#a" />
            </Mask>
            <Path
              d="M3.66 5.014c.003-.229-.07-.407-.22-.535-.15-.127-.42-.264-.81-.41-.818-.272-1.422-.56-1.813-.862-.391-.303-.582-.734-.573-1.294.009-.545.252-.981.73-1.31C1.45.274 2.051.116 2.778.128c.72.011 1.307.206 1.761.584.454.378.669.859.645 1.442l-.01.027-1.464-.024a.822.822 0 00-.254-.636c-.173-.165-.414-.25-.723-.255-.304-.005-.543.058-.718.19a.607.607 0 00-.267.49.525.525 0 00.253.462c.173.118.497.267.974.447.734.233 1.286.512 1.654.84.369.327.548.773.54 1.335-.01.571-.24 1.015-.69 1.33-.45.317-1.043.468-1.779.457-.738-.013-1.373-.208-1.906-.587-.533-.38-.787-.918-.76-1.614l.01-.027 1.468.024c-.006.375.091.645.291.81.2.163.505.248.916.255.312.005.546-.054.702-.176a.604.604 0 00.238-.488"
              fill={colors.loblollyGrey}
              mask="url(#b)"
            />
          </G>
          <Path
            d="M31.868 4.293l1.066.36c.31.105.575.1.795-.016.22-.115.375-.307.466-.575.093-.273.088-.525-.016-.755-.102-.23-.31-.397-.62-.502l-1.065-.36-.626 1.848zm-.373 1.1l-.714 2.11-1.434-.486L31.432.859l2.5.846c.73.248 1.247.63 1.55 1.15.301.518.35 1.08.146 1.683-.206.606-.585 1.022-1.14 1.248-.554.225-1.196.214-1.927-.033l-1.066-.36zM37.85 8.139l1.13.793.782-2.294-.022-.015-1.89 1.516zm.736 1.936l-1.677-1.179-1.027.824-1.242-.873 5.456-4.113 1.326.932-2.017 6.529-1.243-.873.424-1.247zM45.1 15.787l-.016.023c-.628.434-1.228.604-1.803.508-.574-.095-1.102-.463-1.583-1.103-.483-.642-.678-1.313-.586-2.012.093-.7.455-1.286 1.086-1.76l1.085-.815c.628-.473 1.286-.664 1.973-.575.687.088 1.263.442 1.728 1.06.494.658.706 1.284.636 1.88-.07.596-.392 1.146-.967 1.649l-.027.009-.883-1.174c.326-.271.52-.535.583-.793.063-.258-.027-.549-.27-.872a.94.94 0 00-.816-.399c-.338.009-.686.147-1.043.416l-1.092.82c-.36.27-.586.575-.678.912-.093.338-.026.657.2.957.217.29.45.438.695.443.246.005.545-.111.895-.349l.883 1.175z"
            fill={colors.loblollyGrey}
          />
          <Path
            fill={colors.loblollyGrey}
            d="M47.9581986 21.5460479L47.1082089 19.1420713 45.5797106 19.6822968 46.5814648 22.5156326 45.4867345 22.9026707 43.9803044 18.6419964 50.1109392 16.4751257 51.6204444 20.7439387 50.5255331 21.1309767 49.5208846 18.2891406 48.2029392 18.7550332 49.052929 21.1590099z"
          />
          <Path
            fill={colors.loblollyGrey}
            d="M1.99390341 40.5473748L1.8121116 40.7052646 1.82567816 40.7285954 2.03713549 40.6566136 6.79483515 39.3685364 7.59327201 40.7331169 0.915452901 42.1973507 0.092234471 40.79063 4.64516962 35.6952919 5.44143584 37.0558935z"
          />
          <Path
            fill={colors.loblollyGrey}
            d="M9.57158294 47.6796365L7.65670922 45.9952974 6.58567509 47.2122971 8.84278771 49.1975854 8.07564437 50.0693253 4.68183549 47.084249 8.97736792 42.2023243 12.3778696 45.1931882 11.6107263 46.0649281 9.34710171 44.0738523 8.42367167 45.1233766 10.3385454 46.8078965z"
          />
          <Path
            d="M14.508 49.568l.92.384c.28.117.52.143.722.077.202-.065.353-.218.453-.457.102-.244.108-.473.018-.687-.09-.213-.274-.378-.551-.494l-.911-.38-.65 1.557zm-.448 1.072l-.96 2.3-1.397-.585 2.507-6 2.307.965c.696.29 1.18.681 1.452 1.172.272.492.292 1.016.059 1.574-.13.31-.323.542-.58.697-.256.154-.573.235-.95.241.324.259.516.543.576.853.059.31.012.647-.14 1.01l-.157.379a2.594 2.594 0 00-.154.564c-.038.22-.016.397.064.53l-.035.083-1.434-.6c-.074-.13-.083-.311-.027-.543.056-.231.123-.439.2-.623l.151-.362c.12-.286.136-.536.048-.75-.088-.215-.278-.382-.57-.504l-.96-.401z"
            fill={colors.loblollyGrey}
          />
          <Path
            fill={colors.loblollyGrey}
            d="M22.2183235 55.3785111L20.724013 55.1980139 21.5047229 48.7427975 22.9990334 48.9234756z"
          />
          <Path
            fill={colors.loblollyGrey}
            d="M30.3192198 52.3824928L27.7383188 52.7284759 28.0861652 55.3223544 26.5855235 55.5236504 25.7210628 49.0789238 30.2542812 48.471057 30.4085782 49.6218623 27.3760014 50.028614 27.5840218 51.5776706 30.164742 51.2316875z"
          />
          <Path
            fill={colors.loblollyGrey}
            d="M36.3754014 53.074676L34.9845584 53.6498073 32.4993468 47.6411316 33.8903706 47.0658194z"
          />
          <Path
            fill={colors.loblollyGrey}
            d="M41.8235843 46.3712308L39.790772 47.9110636 40.7697345 49.2031197 43.1657686 47.3883813 43.8670689 48.3140172 40.2637925 51.0428165 36.3369085 45.8597618 39.9472396 43.1255367 40.6485399 44.0511726 38.2452703 45.8713367 39.0894717 46.9854276 41.122284 45.4457757z"
          />
          <Path
            d="M43.266 40.962l3.45 2.36.414-.604c.227-.332.29-.669.191-1.011-.1-.342-.336-.641-.71-.897l-.973-.665c-.37-.254-.733-.365-1.087-.333-.354.032-.644.214-.872.546l-.413.604zm3.554 4.265l-5.367-3.67 1.326-1.94c.432-.631 1-1.018 1.702-1.162.702-.144 1.376.005 2.02.445l.965.66c.647.443 1.03 1.017 1.15 1.723.119.706-.037 1.374-.47 2.006l-1.326 1.938z"
            fill={colors.loblollyGrey}
          />
        </G>
        <G transform="translate(10.341 16.84)">
          <Path
            d="M24.59.009c1.202 0 2.176.972 2.176 2.173V19.51c0 1.2-.974 2.174-2.176 2.174H7.32a2.175 2.175 0 01-2.175-2.172v-3.88L.74 11.098l-.137-.14.133-.144 4.41-4.771v-3.86c0-1.148.89-2.088 2.02-2.168L7.321.01zm-.466 18.883l-.208-.001v.207l.208-.206zm-16.13-.001h-.207l.208.207v-.207zM23.916 2.8H7.994v4.318l-.054.06-.628.679-2.82 3.053 2.776 2.857.668.688.059.06v4.375h15.921V2.8zM5.146 15.549v.084l.058.06-.058-.144zm-.79-4.779l.003.285.133-.144-.136-.14zM5.2 5.983l-.056.059v.081l.056-.14zm2.794-3.39l-.208.208h.207v-.207zm15.922 0v.208h.207l-.207-.207z"
            transform="translate(0 .777)"
            fill={colors.green}
            fillRule="nonzero"
          />
          <Path
            stroke={colors.white}
            strokeWidth={6.49999976}
            strokeLinecap="round"
            d="M15.1339887 14.79898L27.0372692 0.161533596"
          />
          <Path
            stroke={colors.green}
            strokeWidth={3.24355173}
            strokeLinecap="round"
            d="M10.1220816 9.61488439L15.1339887 14.79898 27.0372692 0.161533596"
          />
        </G>
        <Ellipse
          fill={colors.loblollyGrey}
          cx={1.29268293}
          cy={29.5363478}
          rx={1.29268293}
          ry={1.29545455}
        />
        <Ellipse
          fill={colors.loblollyGrey}
          cx={50.6731865}
          cy={30.3136285}
          rx={1.29268293}
          ry={1.29545455}
        />
      </G>
    </Svg>
  );
};

export default TalkspaceVerifiedIcon;
