import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';

const postMeetProvider = async (userID: number, roomID: number, therapistID: number): Promise<{}> =>
  apiWrapper.post(`${apiHelper().apiEndpoint}/v2/clients/${userID}/onboarding/meet-provider`, {
    roomID,
    therapistID,
  });

const onboardingApiHelper = { postMeetProvider };

export default onboardingApiHelper;
