import { useEffect } from 'react';
import { useNewMemberNav } from 'launchDarkly/hooks';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from '@/core/routerLib';
import useNavShellData from '../../hooks/useNavShellData';
import localStorage from '../../core/storage/localStorage';
import { HAS_LOGGED_IN_BEFORE } from '../../login/utils/twoFactorAuthenticationApiHelper';

const oldNavRouting = async (history) => {
  const goTo = sessionStorage.getItem('goTo');
  const redirectTo = sessionStorage.getItem('redirectTo');
  if (goTo && goTo !== '/login-success') {
    if (!redirectTo) {
      sessionStorage.removeItem('goTo');
    }
    history.push(goTo);
  } else {
    history.push('/rooms?action=after-login');
  }
};

const redirectIgnore = ['/rooms/therapist-details', '/login-success'];

const newNavRouting = async (history) => {
  const goTo = sessionStorage.getItem('goTo');
  const redirectTo = sessionStorage.getItem('redirectTo');
  if (goTo && !redirectIgnore.includes(goTo)) {
    sessionStorage.removeItem('goTo');
    history.push(goTo);
  } else if (redirectTo && !redirectIgnore.includes(redirectTo)) {
    sessionStorage.removeItem('redirectTo');
    history.push(redirectTo);
  } else {
    history.push('/home?action=after-login');
  }
};

const LoginSuccess = () => {
  const history = useHistory();
  const useNewNav = useNewMemberNav();
  const { dataByRoomID } = useNavShellData();

  useEffect(() => {
    const hasLoggedInBefore = localStorage.getItem(HAS_LOGGED_IN_BEFORE);
    if (!hasLoggedInBefore) {
      localStorage.setItem(HAS_LOGGED_IN_BEFORE, 'true');
    }
    if (dataByRoomID) {
      (async () => {
        if (isEmpty(dataByRoomID)) {
          history.push('/eligibility-widget');
        } else if (useNewNav) {
          await newNavRouting(history);
        } else {
          await oldNavRouting(history);
        }
      })();
    }
  }, [useNewNav, history, dataByRoomID]);

  return null;
};
export default LoginSuccess;
