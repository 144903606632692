import { useEffect } from 'react';
import { View, Large, AssessmentHead, AnimatedSpinner } from '@talkspace/react-toolkit';
import { useWizardActions, useWizardState } from 'stepWizard';
import styled from '@/core/styled';
import { State } from '../reducers/surveyWizardState';
import useSurveyWizard from '../hooks/useSurveyWizard';

const SPINNER_DURATION = 2000;

const Wrapper = styled(View)(({ theme: { window } }) => {
  return {
    alignSelf: 'center',
    justifyContent: 'center',
    position: 'relative',
    transition: 'left .2s',
    width: window.isMobile ? 330 : 420,
    alignItems: 'center',
    paddingTop: 66,
  };
});

const CompleteSurvey = () => {
  const { appSource, userRoomSurveyID, responses, surveySections, isSubmittingLastAnswer } =
    useWizardState<State>();
  const [{ isCompleted }, { completeSurveyAction }] = useSurveyWizard(appSource);
  const { closeWizard } = useWizardActions();
  useEffect(() => {
    const responsesCount = responses.steps.length;
    const answerableSections = surveySections.length - 1; // Remove welcome screen, not considered a step
    if (!isSubmittingLastAnswer && responsesCount === answerableSections)
      completeSurveyAction(responsesCount === answerableSections, userRoomSurveyID);
  }, [
    completeSurveyAction,
    isSubmittingLastAnswer,
    responses.steps,
    surveySections,
    userRoomSurveyID,
  ]);

  useEffect(() => {
    let timeout: number | undefined;
    if (isCompleted) {
      timeout = window.setTimeout(() => {
        closeWizard();
      }, SPINNER_DURATION);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [closeWizard, isCompleted]);
  const loadingText = 'Please hang on while we analyze your answers.';
  return (
    <Wrapper>
      <Large variant="largeDarkGrey" style={{ textAlign: 'center', paddingBottom: 30 }}>
        {loadingText}
      </Large>
      <AnimatedSpinner
        loadingText={loadingText}
        icon={AssessmentHead}
        style={{ paddingTop: 67 }}
        width={118}
        height={118}
      />
    </Wrapper>
  );
};

export default CompleteSurvey;
