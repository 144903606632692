import { ETherapistInfo } from 'ts-frontend/entities/Therapist';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { ECustomerInfo } from '../../entities/CustomerInfo';

interface CustomerInfoAPIResponse {
  data: { data: ECustomerInfo };
}

interface TherapistInfoAPIResponse {
  data: { data: ETherapistInfo };
}

async function getCustomerInformation(clientUserID: number): Promise<CustomerInfoAPIResponse> {
  return apiWrapper.get(`${apiHelper().apiEndpoint}/api/v1/clients/${clientUserID}`);
}

async function getTherapistInfo(therapistID: number): Promise<TherapistInfoAPIResponse> {
  return apiWrapper.get(`${apiHelper().apiEndpoint}/v2/therapist/${therapistID}/info`);
}

const meetYourProviderApiHelper = {
  getCustomerInformation,
  getTherapistInfo,
};

export default meetYourProviderApiHelper;
