import { FunctionComponent } from 'react';
import * as React from 'react';
import { View, commonStyles } from '@talkspace/react-toolkit';
import styled from '../../core/styled';

const Wrapper = styled(View)({
  ...commonStyles.general.layout.centered,
  alignSelf: 'center',
  flexGrow: 1,
  margin: 15,
});

const FullscreenWrapper: FunctionComponent<{
  style?: React.CSSProperties;
}> = ({ children, style }) => <Wrapper style={style}>{children}</Wrapper>;

export default FullscreenWrapper;
