import { FunctionComponent, useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { Caption, Paragraph, Avatar, View, ExtraHuge, COLORS } from '@talkspace/react-toolkit';
import loadingSpinner from '../../../assets/animations/loadingSpinner.json';
import checkmark from '../../../assets/animations/checkmark.json';
import styled from '../../../core/styled';
import TherapistsGif from '../../../assets/animations/therapists.gif';
import useScreenSize, { screenSizes } from '../../../core/hooks/useScreenSize';

const isResponsive = (screenSize: screenSizes) =>
  [screenSizes.EXTRA_SMALL, screenSizes.SMALL].includes(screenSize);

const Container = styled(View)({
  alignItems: 'center',
  maxWidth: 495,
  alignSelf: 'center',
  flexGrow: 1,
  width: '100%',
});

const Title = styled(Caption)<{ screenSize: screenSizes }>(({ screenSize }) => {
  return {
    textAlign: 'center',
    paddingBottom: 50,
    paddingTop: isResponsive(screenSize) ? 13 : 50,
    fontWeight: 400,
  };
});

const Steps = styled(View)<{ screenSize: screenSizes }>(({ screenSize }) => {
  return {
    width: '100%',
    flexDirection: isResponsive(screenSize) ? 'column' : 'row',
    justifyContent: 'space-between',
  };
});

const LeftContainer = styled(View)({
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
});

const RightContainer = styled(View)<{ screenSize: screenSizes }>(({ screenSize }) => {
  return {
    width: '300px',
    ...(isResponsive(screenSize)
      ? {
          alignSelf: 'center',
          paddingTop: 40,
        }
      : {}),
  };
});

const Row = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 35,
});

const AnimatedCheckmark = styled(View)({
  width: 64,
});

const StepIncomplete = styled(Paragraph)({
  color: COLORS.grey,
});

const StepComplete = styled(Paragraph)({
  color: COLORS.black,
});

const MatchInProgress: FunctionComponent<{}> = () => {
  const screenSize = useScreenSize();
  const [steps, setSteps] = useState<{ id: number; state: string; name: string }[]>([
    { id: 0, state: 'inactive', name: 'Calculating profile...' },
    { id: 1, state: 'inactive', name: 'Searching for matches...' },
    { id: 2, state: 'inactive', name: 'Analyzing matches...' },
    { id: 3, state: 'inactive', name: 'Returning best matches...' },
  ]);
  const [currentStep, setCurrentStep] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSteps((updatedSteps) =>
        updatedSteps.map((step, index) =>
          index === currentStep ? { ...step, state: 'active' } : step
        )
      );
      setCurrentStep(currentStep + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [currentStep, steps]);

  return (
    <Container>
      <ExtraHuge style={{ textAlign: 'center' }}>You're all done!</ExtraHuge>
      <Title screenSize={screenSize} style={{ textAlign: 'center', paddingTop: 15, maxWidth: 335 }}>
        Hang on while we find the best matched providers for you.
      </Title>
      <Steps screenSize={screenSize}>
        <LeftContainer>
          <View style={{ position: 'absolute' }}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: loadingSpinner,
              }}
              height={160}
              width={160}
            />
          </View>
          <Avatar width={130} height={130} image={TherapistsGif} />
        </LeftContainer>
        <RightContainer screenSize={screenSize}>
          {steps.map((step) =>
            step.state === 'inactive' ? (
              <Row key={step.id}>
                <StepIncomplete>{step.name}</StepIncomplete>
              </Row>
            ) : (
              <Row key={step.id}>
                <StepComplete>{step.name}</StepComplete>
                <AnimatedCheckmark>
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: false,
                      animationData: checkmark,
                    }}
                    height={100}
                    width={100}
                    speed={1.4}
                  />
                </AnimatedCheckmark>
              </Row>
            )
          )}
        </RightContainer>
      </Steps>
    </Container>
  );
};

export default MatchInProgress;
