import { FunctionComponent, useEffect, useRef, useMemo } from 'react';
import { useEmotionTheme, View } from '@talkspace/react-toolkit';
import { useWizardActions, useWizardState } from 'stepWizard';
import { useTSAdminConfig } from 'ts-frontend/hooks/useTSAdminConfig';
import { AdminConfigName } from 'ts-frontend/types';
import ActionStatus from '@/components/ActionStatus';
import styled from '@/core/styled';
import { RouteComponentProps } from '@/core/routerLib/routerLib';
import useEmergencyContact from '../hooks/useEmergencyContact';
import { State } from '../reducers/emergencyContactWizardState';

const Wrapper = styled(View)({
  alignSelf: 'center',
  width: 340,
});

const SubmitEmergencyContact: FunctionComponent<RouteComponentProps<{}>> = ({ history }) => {
  const isOnboarding = history.location.pathname.includes('/onboarding');
  const [{ error, isSuccess, isLoading }, { submitIntakeResults }] = useEmergencyContact();
  const requestSent = useRef(false);
  const { colors } = useEmotionTheme();

  const {
    roomID,
    clientUserID,
    name,
    address,
    phone,
    contactName,
    relationship,
    contactPhone,
    emergencyContactData,
  } = useWizardState() as State;
  const { onExitClick } = useWizardActions();

  const { data: treatmentIntakeInOnboardingActive } = useTSAdminConfig(
    AdminConfigName.TREATMENT_INTAKE_IN_ONBOARDING
  );

  const {
    country = '',
    city = '',
    state = '',
    zipcode = '',
    address: addressString = '',
  } = (address && address?.addressFromApi) || {};

  const { firstName, middleName, lastName } = name || {};

  // reconcile quickmatch data with wizard data
  const payload = useMemo(() => {
    return {
      firstName: emergencyContactData?.firstName || firstName,
      middleName: emergencyContactData?.middleName || middleName || null,
      lastName: emergencyContactData?.lastName || lastName,
      address: emergencyContactData?.address || addressString || null,
      address2: emergencyContactData?.address2 || address?.addressString || null,
      city: emergencyContactData?.city || city || null,
      state: emergencyContactData?.state || state || null,
      zipcode: emergencyContactData?.zipcode || zipcode || null,
      country: emergencyContactData?.country || country || null,
      phone: emergencyContactData?.phone || phone || null,
      contactName: contactName || null,
      relationship: relationship || null,
      contactPhone: contactPhone || null,
      isOnboarding,
    };
  }, [
    emergencyContactData,
    firstName,
    middleName,
    lastName,
    addressString,
    address,
    city,
    state,
    zipcode,
    country,
    phone,
    contactName,
    relationship,
    contactPhone,
    isOnboarding,
  ]);

  useEffect(() => {
    if (!requestSent.current) {
      requestSent.current = true;

      submitIntakeResults(roomID, clientUserID, payload);
    }
  }, [roomID, clientUserID, submitIntakeResults, payload]);

  useEffect(() => {
    if (isSuccess) {
      if (treatmentIntakeInOnboardingActive && isOnboarding) {
        history.push(`/room/${roomID}/onboarding/next`);
      } else {
        history.push(`/treatment-intake-launcher/room/${roomID}/source/chat`, { initStep: 0 });
      }
    }
  }, [treatmentIntakeInOnboardingActive, history, isSuccess, roomID, isOnboarding]);

  return (
    <Wrapper>
      {error || isLoading ? (
        <ActionStatus
          isLoading={isLoading}
          isError={!!error}
          errorTitle="Something Went Wrong"
          errorSubTitle="Please check your internet connection and try again."
          errorButtonText="Close"
          errorButtonAction={onExitClick}
          titleStyles={{ fontWeight: 800, fontSize: 29, marginTop: 25 }}
          subTitleStyles={{
            fontSize: 16,
            color: colors.darkGray,
            marginTop: 13,
            marginBottom: 42,
            width: 200,
            textAlign: 'center',
          }}
        />
      ) : null}
    </Wrapper>
  );
};
export default SubmitEmergencyContact;
