import moment from 'moment';
import { getAccessToken, getTokens } from '../../auth/helpers/token';
import { refreshToken, haltIfRevokeInProcess } from '../../auth/auth';

interface RequestOptions {
  headers?: HeadersInit;
  shouldIgnore401?: boolean;
  withCredentials?: boolean;
}

type RequestBody =
  | {
      [key: string]: any; // TODO: Extend this
    }
  | BodyInit;

interface ResponseWithStatus {
  data?: any;
  status?: number;
}

export class ErrorWithPayload extends Error {
  data: any;

  status: number;

  constructor(message: string, data: any, status: number) {
    super(message);
    this.data = data;
    this.status = status;

    Object.setPrototypeOf(this, ErrorWithPayload.prototype);
  }
}

const isString = (value: unknown) => typeof value === 'string' || value instanceof String;

function parseError(data: any, responseStatus: number) {
  let errorMessage = String(responseStatus);

  if (data && data.error) {
    if (isString(data.error)) {
      errorMessage = data.error;
    } else if (data.error.message) {
      errorMessage = data.error.message;
    }
  }
  return new ErrorWithPayload(errorMessage, data, responseStatus);
}

function parseJSON(response: Response): Promise<ResponseWithStatus> {
  if (response.status > 299) {
    return response
      .json()
      .then((data) => {
        const parsedError = parseError(data, response.status);
        return Promise.reject(parsedError);
      })
      .catch((e) => Promise.reject(new ErrorWithPayload(e.message, e, response.status)));
  }
  return response
    .text()
    .then((text) =>
      text ? { data: JSON.parse(text), status: response.status } : { status: response.status }
    );
}

function parseMainsite(res: ResponseWithStatus): Promise<ResponseWithStatus> {
  if (!res.data) return Promise.resolve(res);
  const returnCode = res.data.returnCode ? res.data.returnCode : res.data['returnCode '];
  if (returnCode === 4) throw new Error('401');
  return Promise.resolve(res);
}

class TokenRefreshedEventEmitter {
  eventListeners: ((success: boolean) => void)[] = [];

  callAllListeners = (success) => {
    this.eventListeners.forEach((fn) => typeof fn === 'function' && fn(success));
    this.eventListeners = [];
  };

  addEventListener = (fn) => {
    this.eventListeners.push(fn);
  };
}

class Client {
  eventEmitterTokenRefreshed = new TokenRefreshedEventEmitter();

  runningMiddlewareTokenRefresh = false;

  authHeader: string;

  constructor() {
    this.authHeader = `Bearer ${getAccessToken()}`;
    return this;
  }

  validateRequestToken = () => {
    if (this.runningMiddlewareTokenRefresh) {
      return new Promise<void>((resolve, reject) => {
        this.eventEmitterTokenRefreshed.addEventListener((refreshed) => {
          if (refreshed) {
            resolve();
          } else {
            reject(new Error('401'));
          }
        });
      });
    }
    return Promise.resolve();
  };

  tokenRefreshed = (success: boolean) => {
    this.runningMiddlewareTokenRefresh = false;
    this.eventEmitterTokenRefreshed.callAllListeners(success);
  };

  refreshTokenIfAboutToExpire = () => {
    const { accessTTL } = getTokens();
    // If it expires within 30 minutes
    if (accessTTL && moment(accessTTL).isBefore(moment().add(30, 'minutes'))) {
      this.runningMiddlewareTokenRefresh = true;
      return refreshToken().then((success) => {
        this.tokenRefreshed(success);
      });
    }
    return Promise.resolve();
  };

  middlewares = async () => {
    await haltIfRevokeInProcess();
    await this.validateRequestToken();
    await this.refreshTokenIfAboutToExpire();
  };

  request = async (
    method: 'GET' | 'PATCH' | 'POST' | 'PUT' | 'DELETE',
    url: RequestInfo,
    headers: HeadersInit,
    body?: RequestBody,
    shouldIgnore401 = false,
    withCredentials = false
  ): Promise<ResponseWithStatus> => {
    const requestOptions: RequestInit = {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      ...(withCredentials ? { credentials: 'include' } : {}),
    };
    if (body) {
      requestOptions.body = JSON.stringify(body);
    }

    await this.middlewares();

    const accessToken = getAccessToken();

    if (
      accessToken &&
      requestOptions.headers &&
      // @ts-ignore
      !requestOptions.headers.Authorization
    ) {
      // @ts-ignore
      requestOptions.headers.Authorization = `Bearer ${accessToken}`;
    }

    return fetch(url, requestOptions)
      .then(parseJSON)
      .then(parseMainsite)
      .catch((error) => {
        // Only attempt to refresh token when doing authenticated requests
        if (error.message === '401' && !shouldIgnore401) {
          this.runningMiddlewareTokenRefresh = true;
          refreshToken(false, true).then((refreshed: boolean) => {
            this.tokenRefreshed(refreshed);
          });
          return this.request(method, url, headers, body);
        }
        throw error;
      });
  };

  get = (url, options) =>
    this.request(
      'GET',
      url,
      options.headers,
      undefined,
      options.shouldIgnore401,
      options.withCredentials
    );

  patch = (url, body, options) =>
    this.request(
      'PATCH',
      url,
      options.headers,
      body,
      options.shouldIgnore401,
      options.withCredentials
    );

  post = (url, body, options) =>
    this.request(
      'POST',
      url,
      options.headers,
      body,
      options.shouldIgnore401,
      options.withCredentials
    );

  put = (url, body, options) =>
    this.request(
      'PUT',
      url,
      options.headers,
      body,
      options.shouldIgnore401,
      options.withCredentials
    );

  delete = (url, body, options) =>
    this.request(
      'DELETE',
      url,
      options.headers,
      body,
      options.shouldIgnore401,
      options.withCredentials
    );
}

interface Data {
  [key: string]: unknown;
}

interface MainsiteParams {
  appVersion: string;
  deviceType: string;
  [key: string]: string | number | boolean | number[];
}

function adaptMainsiteRequest(data: Data): MainsiteParams {
  return {
    appVersion: '1',
    deviceType: 'pc',
    ...data,
  };
}

export interface MainsiteResponse<T> {
  data?: {
    'result '?: T;
    result?: T;
    'returnCode '?: number;
    returnCode?: number;
  };
  status?: number;
}

interface AdaptedMainsiteResponse<T> {
  data?: T;
  error?: string;
}

function adaptMainsiteResponse<T>(res: MainsiteResponse<T>): AdaptedMainsiteResponse<T> {
  const response: AdaptedMainsiteResponse<T> = {};

  if (!res.data) {
    response.error = 'no data found on MainsiteResponse';
  } else if (res.data['result '] && res.data.result) {
    response.error = 'two results found on MainsiteResponse';
  } else {
    const returnCode = res.data.returnCode ? res.data.returnCode : res.data['returnCode '];

    const returnData = res.data.result ? res.data.result : res.data['result '];

    if (returnCode !== 1) {
      let errorReason = `${returnCode}`;
      switch (returnCode) {
        case 0:
          errorReason = 'Failed';
          break;
        case 2:
          errorReason = 'Empty';
          break;
        case 3:
          errorReason = 'Invalid Request';
          break;
        case 4:
          errorReason = 'Expired Token';
          break;
        case 101:
          errorReason = 'Unauthorized';
          break;
        case 121:
          // mainsite put human readable errors inside the result, we are extracting them in this case.
          errorReason =
            returnData && (returnData as any).error ? (returnData as any).error : 'Error 121';
          break;
        default:
          break;
      }
      response.error = errorReason;
    } else {
      response.data = returnData;
    }
  }

  return response;
}

const client = new Client();

// @ts-ignore: TODO - Use unknown
type ApiResponse<T = any> = Promise<{ data: T; status: number }>;

async function postMainsite<T>(
  url: RequestInfo,
  data: Data = {},
  options: RequestOptions = {}
): Promise<AdaptedMainsiteResponse<T>> {
  const res: MainsiteResponse<T> = await client.post(
    url,
    { params: adaptMainsiteRequest(data) },
    options
  );
  const adaptedResponse = adaptMainsiteResponse<T>(res);
  return adaptedResponse;
}

const apiWrapper = {
  // @ts-ignore
  get: <Response = any>(url: RequestInfo, options: RequestOptions = {}): ApiResponse<Response> =>
    client.get(url, options) as ApiResponse<Response>,
  // @ts-ignore
  patch: <Response = any>(
    url: RequestInfo,
    data?: RequestBody,
    options: RequestOptions = {}
  ): ApiResponse<Response> => client.patch(url, data, options) as ApiResponse<Response>,
  // @ts-ignore
  post: <Response = any>(
    url: RequestInfo,
    data?: RequestBody,
    options: RequestOptions = {}
  ): ApiResponse<Response> => client.post(url, data, options) as ApiResponse<Response>,
  // @ts-ignore
  put: <Response = any>(
    url: RequestInfo,
    data?: RequestBody,
    options: RequestOptions = {}
  ): ApiResponse<Response> => client.put(url, data, options) as ApiResponse<Response>,
  // @ts-ignore
  delete: <Response = any>(
    url: RequestInfo,
    data?: RequestBody,
    options: RequestOptions = {}
  ): ApiResponse<Response> => client.delete(url, data, options) as ApiResponse<Response>,
  postMainsite,
};

export default apiWrapper;
