import { FriendReferralInfo } from '../utils/friendReferralApiHelper';

export interface FriendReferralInfoState {
  friendReferralInfo?: FriendReferralInfo;
  isLoading: boolean;
  error?: string;
}

export type Action =
  | { type: 'requestGetFriendReferralInfo' }
  | {
      type: 'receiveGetFriendReferralInfo';
      payload: Pick<FriendReferralInfoState, 'friendReferralInfo'>;
    }
  | { type: 'setError'; error: string };

export const initialState: FriendReferralInfoState = {
  friendReferralInfo: undefined,
  isLoading: false,
};

export const frienReferralInfoReducer = (
  state: FriendReferralInfoState = initialState,
  action: Action
): FriendReferralInfoState => {
  switch (action.type) {
    case 'requestGetFriendReferralInfo':
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case 'receiveGetFriendReferralInfo':
      return {
        ...state,
        isLoading: false,
        error: '',
        ...action.payload,
      };
    case 'setError':
      return {
        ...state,
        error: action.error || 'Oops! Something went wrong. Please try again',
        isLoading: false,
      };
    default:
      return state;
  }
};
