import {
  previousMentalHealthTreatment as previousMentalHealthTreatmentOptions,
  traumaticExperienceOptions,
  traumaticFlashbacksOptions,
  guiltOptions,
  isolationOptions,
  suicideIdeationOrPlanning,
  homicidalIdeationOrPlanning,
  angerAggression,
} from '@talkspace/configs';
import { WizardGenericActions } from 'stepWizard';
import { WizardPostActionContext } from 'stepWizard/reducers/wizardState';
import { OtherWizardGenericActions } from '@/utils/genericWizardActions';
import { State } from '../reducers/mentalHealthWizardState';

const options = {
  previousMentalHealthTreatmentOptions,
  traumaticExperienceOptions,
  traumaticFlashbacksOptions,
  guiltOptions,
  isolationOptions,
  suicideIdeationOrPlanning,
  homicidalIdeationOrPlanning,
  angerAggression,
};

const setSelectedState = (
  stateName: string,
  optionsName: string,
  context: WizardPostActionContext<State>
) => {
  const partialState: Partial<State> = {};
  const { setState, wizardContext } = context;

  const stateNameCapitalized = stateName.charAt(0).toUpperCase() + stateName.slice(1);

  partialState[`selected${stateNameCapitalized}`] =
    options[optionsName][wizardContext[stateName]] !== options[optionsName].no &&
    options[optionsName][wizardContext[stateName]] !== options[optionsName].never;
  setState(partialState);
};

const initState: WizardGenericActions<State>['initState'] = async () => {
  return {
    setTraumaticExperience: (_, context) => {
      setSelectedState('traumaticExperience', 'traumaticExperienceOptions', context);
    },

    setTraumaticFlashbacks: (_, context) => {
      setSelectedState('traumaticFlashbacks', 'traumaticFlashbacksOptions', context);
    },

    setGuilt: (_, context) => {
      setSelectedState('guilt', 'guiltOptions', context);
    },

    setIsolation: (_, context) => {
      setSelectedState('isolation', 'isolationOptions', context);
    },

    setPreviousMentalHealthTreatment: (_, context) => {
      setSelectedState(
        'previousMentalHealthTreatment',
        'previousMentalHealthTreatmentOptions',
        context
      );
    },

    setSuicideIdeation: (_, context) => {
      setSelectedState('suicideIdeation', 'suicideIdeationOrPlanning', context);
    },

    setSuicidePlanning: (_, context) => {
      setSelectedState('suicidePlanning', 'suicideIdeationOrPlanning', context);
    },

    setHomicidalIdeation: (_, context) => {
      setSelectedState('homicidalIdeation', 'homicidalIdeationOrPlanning', context);
    },

    setHomicidalPlanning: (_, context) => {
      setSelectedState('homicidalPlanning', 'homicidalIdeationOrPlanning', context);
    },

    setAngerAggression: (_, context) => {
      setSelectedState('angerAggression', 'angerAggression', context);
    },
  };
};

const persist = async () => {};

const mentalHealthActions: OtherWizardGenericActions<State> = {
  initState,
  persist,
};

export default mentalHealthActions;
