import moment from 'moment';
import capitalize from 'lodash/capitalize';
import { animals, colors, adjectives } from '@talkspace/configs';
import { Subscription } from 'ts-frontend/types';
import { TEENSPACE_DAY_END_SHIFT_HOURS } from '../constants';

import { CommunityFeedTypeEnum } from '../types';

/**
 * This function is used to determine if any plan in a subscription
 * is eligible to access the Teenspace community
 * @param subscriptions list of subscriptions
 * @returns boolean
 */
export const hasTeenspaceCommunity = (subscriptions: Subscription[]): boolean =>
  subscriptions?.some((subscription) => subscription.community === 'teenspace');

/**
 *  Gets time in the timezone shifted by :shiftHours
 * @param timezone
 * @param shiftHours - amount of hours to shift into the past by
 * @returns
 */
export const getAdjustedLocalTime = ({
  utcTime,
  timezone,
  shiftHours,
}: {
  utcTime: string;
  timezone: string;
  shiftHours: number;
}) => moment(utcTime).tz(timezone).subtract(shiftHours, 'hours');

export const getCommunityFeedFilterTags = ({
  userID,
  timezone,
  feedType,
  shiftHours,
}: {
  userID: string;
  timezone: string;
  feedType: CommunityFeedTypeEnum;
  shiftHours: number;
}) => {
  const todayTag = getAdjustedLocalTime({
    utcTime: new Date().toISOString(),
    timezone,
    shiftHours,
  }).format('YYYY/MM/DD');
  const getTodayFeedTags = [todayTag];
  const getUserFeedTags = [userID];
  return feedType === 'user' ? getUserFeedTags : getTodayFeedTags;
};

export const getCreatePostTags = ({
  userID,
  timezone,
  shiftHours,
}: {
  userID: string;
  timezone: string;
  shiftHours: number;
}) => {
  const todayTag = getAdjustedLocalTime({
    utcTime: new Date().toISOString(),
    timezone,
    shiftHours,
  }).format('YYYY/MM/DD');
  return [userID, todayTag];
};

export const getCreatePostMetadata = ({ dailyPromptText }: { dailyPromptText: string }) => {
  return {
    dailyPromptText,
  };
};

interface ClientInformation {
  age?: number | null | undefined;
  yearOfBirth?: number | undefined;
  dateOfBirth?: Date | undefined;
}

export const getEstimatedDateOfBirth = (clientInfo: ClientInformation): string => {
  const today = new Date();
  const { age, yearOfBirth, dateOfBirth } = clientInfo;

  if (dateOfBirth) {
    return dateOfBirth.toISOString();
  }

  const birthYear = age ? today.getFullYear() - age : yearOfBirth;

  if (!birthYear) {
    throw new Error(
      `Cannot estimate date of birth. age, dateOfBirth, or yearOfBirth are: ${JSON.stringify({
        age,
        dateOfBirth,
        yearOfBirth,
      })}`
    );
  }

  return new Date(birthYear, today.getMonth(), today.getDate()).toISOString();
};

export const getAge = ({ dateOfBirth }: { dateOfBirth: string }): number =>
  moment().diff(moment(dateOfBirth), 'years');

export const getTimestampDisplayString = (time: string) => {
  const now = moment();
  const timeMoment = moment(time);
  // if < 1 hr, show total minutes elapsed “12m”
  const minutesAgo = now.diff(timeMoment, 'minutes');
  if (minutesAgo < 60) return `${minutesAgo}m`;

  // if < 24 hours, show total hours elapsed “3 hours ago
  const hoursAgo = now.diff(timeMoment, 'hours');
  if (hoursAgo < 24) return `${hoursAgo}h`;

  // if < 7 days, show days elapsed: “4d”
  const daysAgo = now.diff(timeMoment, 'days');
  if (daysAgo < 7) return `${daysAgo}d`;

  // if < 28 days, show weeks elapsed “3w”
  const weeksAgo = now.diff(timeMoment, 'weeks');
  if (daysAgo < 28) return `${weeksAgo}w`;

  // if < 1 year, show short date “April 3”
  const yearsAgo = now.diff(timeMoment, 'years');
  if (yearsAgo < 1) return timeMoment.format('MMMM D');

  // if > 1 year, return full date "April 3, 2023"
  return timeMoment.format('MMMM D, YYYY');
};

const getRandomElementFromList = <T>(list: T[]): T => {
  const randomIndex = Math.floor(Math.random() * list.length);
  return list[randomIndex];
};

export const generateUniqueName = () => {
  let uniqueName;
  do {
    uniqueName = [[...colors, ...adjectives], animals]
      .map((word) => capitalize(getRandomElementFromList(word)))
      .join('');
  } while (uniqueName.length > 20);

  return uniqueName;
};

export const getPostCommentingEndedAt = ({
  post,
  timezone,
  shiftHours,
}: {
  post: Amity.Post;
  timezone: string;
  shiftHours: number;
}) => {
  if (!post?.createdAt) return null;

  const localTime = getAdjustedLocalTime({
    utcTime: new Date().toISOString(),
    timezone,
    shiftHours,
  });
  const postCreationDay = getAdjustedLocalTime({
    utcTime: post.createdAt,
    timezone,
    shiftHours,
  }).startOf('day');

  // NOTE: commenting is ending at the :shiftHours AM
  return postCreationDay.clone().add(1, 'day').add(shiftHours, 'hours').isBefore(localTime)
    ? postCreationDay
    : null;
};

export const findTodaysPostByUser = ({
  posts,
  timezone,
}: {
  posts: Array<Amity.Post>;
  timezone: string;
}): Amity.Post | undefined => {
  const shiftHours = TEENSPACE_DAY_END_SHIFT_HOURS;
  const today = getAdjustedLocalTime({
    utcTime: moment.utc().toString(),
    timezone,
    shiftHours,
  });

  return (
    posts &&
    posts.find(
      (post) =>
        getAdjustedLocalTime({
          utcTime: new Date(post.createdAt).toUTCString(),
          timezone,
          shiftHours,
        })
          .startOf('day')
          .toISOString() === today.startOf('day').toISOString()
    )
  );
};

export const formatPlaceholder = (placeholder: string) =>
  placeholder.endsWith('...') ? placeholder : `${placeholder.replace('.', '')}...`;
