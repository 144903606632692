import { WizardScheme } from 'stepWizard';

const NPSWizardScheme: WizardScheme = {
  wizardVersion: 1,
  wizardType: 'nps',
  wizardModalTitle: 'NPS',
  hideProgressIndicator: true,
  steps: [
    {
      title: "We'd love to hear from you",
      subtitle: 'How likely are you to recommend us to a friend or coworker?',
      inputType: 'numbersRating',
      inputState: 'npsNumbersRating',
      nextButton: {
        displayText: 'Submit',
        actionDispatch: (value, wizardContext) => `/nps/done/room`,
        whenVisible: 'onSelect',
      },
      name: 'NPS Rating',
      additionalInfo: ['therapistInfo'],
      displayCondition: 'nps',
      postAction: 'ratingPostAction',
    },
  ],
};

export default NPSWizardScheme;
