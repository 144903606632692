/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useRef, useEffect, useContext, useReducer, useCallback } from 'react';

import * as React from 'react';
import { RoomStatus } from 'ts-frontend/entities/Room';
import { GeneralNoticePayload } from 'chat/redux/constants/chatTypes';
import ApiHelper from '../utils/ApiHelper';
import MainApiHelper from '@/utils/ApiHelper';
import {
  ChatBannerState,
  chatBannerReducer,
  ChatBannerActionTypes,
  chatBannerInitialState,
  EmailVerificationPayload,
} from '../reducers/chatBannerReducer';

export const ChatBannerStateContext = createContext<ChatBannerState | undefined>(undefined);

interface PayloadWithRoomID {
  roomID: number;
  isSessionBased?: boolean;
}

const getClientReactivationActionCreator =
  (dispatch: React.Dispatch<ChatBannerActionTypes>, api: ApiHelper) =>
  (payload: PayloadWithRoomID) => {
    const { roomID } = payload;
    dispatch({
      type: 'getClientReactivation',
      payload: { reactivationPayload: undefined },
    });
    return api
      .roomReactivation(roomID)
      .then((response) => {
        const { shouldReactivate } = response;
        let dispatchPayload = {};
        if (shouldReactivate) {
          dispatchPayload = {
            reactivationPayload: shouldReactivate ? response : undefined,
          };
        }
        dispatch({
          type: 'receiveClientReactivation',
          payload: dispatchPayload,
        });
      })
      .catch(api.dismissIfCancelled)
      .catch((error) => {
        dispatch({ type: 'setIsError', payload: error.message });
      });
  };

const getClientAwaitingSessionActionCreator =
  (dispatch: React.Dispatch<ChatBannerActionTypes>, api: ApiHelper) =>
  // eslint-disable-next-line consistent-return
  (payload: PayloadWithRoomID) => {
    const { roomID, isSessionBased } = payload;
    dispatch({
      type: 'getClientAwaitingSession',
    });
    if (!isSessionBased) {
      dispatch({
        type: 'receiveClientAwaitingSession',
        payload: {
          awaitingSessionPayload: undefined,
        },
      });
    } else {
      return api
        .getAwaitingSession(roomID)
        .then(async (response) => {
          const { id: sessionID } = response.data;
          let dispatchPayload = {};
          if (sessionID) {
            let lastCompletedSessionDate = '';
            const lastCompletedSessionResponse = await api.getLastCompletedSession(roomID);
            if (lastCompletedSessionResponse && lastCompletedSessionResponse.data) {
              ({ completedAt: lastCompletedSessionDate } = lastCompletedSessionResponse.data);
            }
            dispatchPayload = {
              awaitingSessionPayload: { sessionID, lastCompletedSessionDate },
            };
          }
          dispatch({
            type: 'receiveClientAwaitingSession',
            payload: dispatchPayload,
          });
        })
        .catch(api.dismissIfCancelled)
        .catch((error) => {
          dispatch({ type: 'setIsError', payload: error.message });
        });
    }
  };

const updateRoomExpirationDateActionCreator =
  (dispatch: React.Dispatch<ChatBannerActionTypes>) => (expirationDate: Date) => {
    dispatch({
      type: 'receiveUpdateRoomExpiration',
      payload: { roomExpirationDate: expirationDate },
    });
  };

const getEmailVerificationStatusActionCreator =
  (dispatch: React.Dispatch<ChatBannerActionTypes>, api: ApiHelper) =>
  (isEmailVerificationEnabled: boolean) => {
    if (isEmailVerificationEnabled) {
      dispatch({
        type: 'getEmailVerificationStatus',
        payload: {
          emailVerificationPayload: {
            email: '',
            pendingEmail: '',
            emailStatus: undefined,
          },
        },
      });
      api
        .getClientInfo()
        .then((res) => {
          const { emailVerificationStatus, email, pendingEmail } = res.data;
          dispatch({
            type: 'receiveEmailVerificationStatus',
            payload: {
              emailVerificationPayload: {
                email,
                pendingEmail,
                emailStatus: emailVerificationStatus,
              },
            },
          });
        })
        .catch(api.dismissIfCancelled)
        .catch((error) => {
          dispatch({ type: 'setIsError', payload: error.message });
        });
    }
  };

const get2FAClientCreator =
  (dispatch: React.Dispatch<ChatBannerActionTypes>, api: MainApiHelper) => () => {
    dispatch({
      type: 'get2FAClientStatus',
      payload: {
        is2faOptIn: false,
        hasPhoneNumber: false,
      },
    });
    api
      .get2FAStatus()
      .then((res) => {
        const { status, phoneNumber } = res;
        dispatch({
          type: 'receive2FAClientStatus',
          payload: {
            is2faOptIn: status === 'on',
            hasPhoneNumber: !!phoneNumber,
          },
        });
      })
      .catch((error) => {
        dispatch({ type: 'setIsError', payload: error.message });
      });
  };

const sendEmailVerificationActionCreator =
  (dispatch: React.Dispatch<ChatBannerActionTypes>, api: ApiHelper) => (email: string) => {
    dispatch({ type: 'sendEmailVerification' });
    api
      .sendEmailVerification(email)
      .then(() => {
        dispatch({
          type: 'emailVerificationSent',
        });
      })
      .catch(api.dismissIfCancelled)
      .catch((error) => {
        dispatch({ type: 'setIsError', payload: error.message });
      });
  };

const dismissReactivationBannerActionCreator =
  (dispatch: React.Dispatch<ChatBannerActionTypes>) => () => {
    dispatch({
      type: 'dismissReactivationBanner',
      payload: { reactivationPayload: undefined },
    });
  };

const setEmailVerificationPayloadActionCreator =
  (dispatch: React.Dispatch<ChatBannerActionTypes>) =>
  (emailVerificationPayload: EmailVerificationPayload) => {
    dispatch({
      type: 'setEmailVerificationPayload',
      payload: {
        emailVerificationPayload,
      },
    });
  };

const setPartnerMissingActionCreator =
  (dispatch: React.Dispatch<ChatBannerActionTypes>) => (isMissing: boolean) => {
    dispatch({
      type: 'partnerMissing',
      payload: { partnerMissingPayload: isMissing },
    });
  };

const setPsychiatristReminderActionCreator =
  (dispatch: React.Dispatch<ChatBannerActionTypes>) => (shouldShow: boolean) => {
    dispatch({
      type: 'psychiatristReminder',
      payload: { psychiatristReminderPayload: shouldShow },
    });
  };

const setCreditCardDeclinedActionCreator =
  (dispatch: React.Dispatch<ChatBannerActionTypes>) => (roomStatus: RoomStatus) => {
    dispatch({
      type: 'creditCardDeclined',
      payload: { creditCardDeclinedPayload: roomStatus === RoomStatus.PAST_DUE },
    });
  };

const setEligibilityWidgetActionCreator =
  (dispatch: React.Dispatch<ChatBannerActionTypes>) => (shouldShow: boolean) => {
    dispatch({
      type: 'eligibilityWidget',
      payload: { eligiblityWidgetPayload: shouldShow },
    });
  };

const setPendingInvoicesBannerCreator =
  (dispatch: React.Dispatch<ChatBannerActionTypes>) => (hasPendingInvoices: boolean) => {
    dispatch({
      type: 'pendingInvoices',
      payload: { hasPendingInvoices },
    });
  };

const setMatchWithNewProviderBannerCreator =
  (dispatch: React.Dispatch<ChatBannerActionTypes>) => (isProviderUnavailable: boolean) =>
    dispatch({
      type: 'matchWithNewProvider',
      payload: { matchWithNewProviderPayload: isProviderUnavailable },
    });

const setMatchWithSameProviderBannerCreator =
  (dispatch: React.Dispatch<ChatBannerActionTypes>) => (isProviderAvailable: boolean) =>
    dispatch({
      type: 'matchWithSameProvider',
      payload: { matchWithSameProviderPayload: isProviderAvailable },
    });

const setGeneralNoticeBannerCreator =
  (dispatch: React.Dispatch<ChatBannerActionTypes>) =>
  (generalNoticePayload: GeneralNoticePayload | undefined) => {
    dispatch({
      type: 'setGeneralNotice',
      payload: { generalNoticePayload },
    });
  };

export interface ChatBannerActions {
  getClientReactivationAction: ReturnType<typeof getClientReactivationActionCreator>;
  getClientAwaitingSessionAction: ReturnType<typeof getClientAwaitingSessionActionCreator>;
  updateRoomExpirationDate: ReturnType<typeof updateRoomExpirationDateActionCreator>;
  getEmailVerificationStatusAction: ReturnType<typeof getEmailVerificationStatusActionCreator>;
  sendEmailVerificationAction: ReturnType<typeof sendEmailVerificationActionCreator>;
  dismissReactivationBanner: ReturnType<typeof dismissReactivationBannerActionCreator>;
  setCreditCardDeclined: ReturnType<typeof setCreditCardDeclinedActionCreator>;
  setEmailVerificationPayload: ReturnType<typeof setEmailVerificationPayloadActionCreator>;
  setPartnerMissing: ReturnType<typeof setPartnerMissingActionCreator>;
  setPsychiatristReminder: ReturnType<typeof setPsychiatristReminderActionCreator>;
  setEligibilityWidget: ReturnType<typeof setEligibilityWidgetActionCreator>;
  get2FAClientAction: ReturnType<typeof get2FAClientCreator>;
  setPendingInvoices: ReturnType<typeof setPendingInvoicesBannerCreator>;
  setMatchWithNewProvider: ReturnType<typeof setMatchWithNewProviderBannerCreator>;
  setMatchWithSameProvider: ReturnType<typeof setMatchWithSameProviderBannerCreator>;
  applyReactivationTray?: () => void;
  setGeneralNotice: ReturnType<typeof setGeneralNoticeBannerCreator>;
}

export const ChatBannerActionsContext = createContext<ChatBannerActions | undefined>(undefined);

export const ChatBannerContextProvider: React.FC<{ isTeenFunnelUser?: boolean }> = ({
  children,
  isTeenFunnelUser,
}) => {
  const [state, dispatch] = useReducer(chatBannerReducer, chatBannerInitialState);
  const { current: mainApi } = useRef(new MainApiHelper());

  const apiRef = useRef(new ApiHelper());
  const { current: api } = apiRef;
  useEffect(
    () => () => {
      apiRef.current.cancelAll();
    },
    []
  );

  useEffect(() => {
    if (isTeenFunnelUser !== undefined)
      dispatch({
        type: 'setIsTeenFunnelUser',
        payload: {
          isTeenFunnelUser,
        },
      });
  }, [isTeenFunnelUser]);

  const getClientReactivationAction = getClientReactivationActionCreator(dispatch, api);
  const getClientAwaitingSessionAction = getClientAwaitingSessionActionCreator(dispatch, api);
  const updateRoomExpirationDateAction = updateRoomExpirationDateActionCreator(dispatch);
  const getEmailVerificationStatus = getEmailVerificationStatusActionCreator(dispatch, api);
  const sendEmailVerification = sendEmailVerificationActionCreator(dispatch, api);
  const dismissReactivationBannerAction = dismissReactivationBannerActionCreator(dispatch);
  const setCreditCardDeclined = setCreditCardDeclinedActionCreator(dispatch);
  const setEmailVerificationPayload = setEmailVerificationPayloadActionCreator(dispatch);
  const setPartnerMissing = setPartnerMissingActionCreator(dispatch);
  const setPsychiatristReminder = setPsychiatristReminderActionCreator(dispatch);
  const setEligibilityWidget = setEligibilityWidgetActionCreator(dispatch);
  const get2FAClient = get2FAClientCreator(dispatch, mainApi);
  const setPendingInvoices = setPendingInvoicesBannerCreator(dispatch);
  const setMatchWithNewProvider = setMatchWithNewProviderBannerCreator(dispatch);
  const setMatchWithSameProvider = setMatchWithSameProviderBannerCreator(dispatch);
  const setGeneralNotice = setGeneralNoticeBannerCreator(dispatch);

  const actions: ChatBannerActions = {
    dismissReactivationBanner: useCallback(dismissReactivationBannerAction, []),
    getClientReactivationAction: useCallback(getClientReactivationAction, []),
    getClientAwaitingSessionAction: useCallback(getClientAwaitingSessionAction, []),
    updateRoomExpirationDate: useCallback(updateRoomExpirationDateAction, []),
    getEmailVerificationStatusAction: useCallback(getEmailVerificationStatus, []),
    sendEmailVerificationAction: useCallback(sendEmailVerification, []),
    setCreditCardDeclined: useCallback(setCreditCardDeclined, []),
    setEmailVerificationPayload: useCallback(setEmailVerificationPayload, []),
    setPartnerMissing: useCallback(setPartnerMissing, []),
    setPsychiatristReminder: useCallback(setPsychiatristReminder, []),
    setEligibilityWidget: useCallback(setEligibilityWidget, []),
    get2FAClientAction: useCallback(get2FAClient, []),
    setPendingInvoices: useCallback(setPendingInvoices, []),
    setMatchWithNewProvider: useCallback(setMatchWithNewProvider, []),
    setMatchWithSameProvider: useCallback(setMatchWithSameProvider, []),
    setGeneralNotice: useCallback(setGeneralNotice, []),
  };

  return (
    <ChatBannerStateContext.Provider value={state}>
      <ChatBannerActionsContext.Provider value={{ ...actions }}>
        {children}
      </ChatBannerActionsContext.Provider>
    </ChatBannerStateContext.Provider>
  );
};

export function useChatBannerState() {
  const context = useContext(ChatBannerStateContext);
  if (context === undefined)
    throw new Error('ChatBannerStateContext must be used within a ContextProvider');
  return context;
}

export function useChatBannerActions() {
  const context = useContext(ChatBannerActionsContext);
  if (context === undefined)
    throw new Error('ChatBannerActionsContext must be used within a ContextProvider');
  return context;
}
