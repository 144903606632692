import { FunctionComponent, useState } from 'react';
import {
  View,
  ExtraHuge,
  Large,
  Tiny,
  Mini,
  Button,
  TouchableView,
  Image,
  Clipboard,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { FriendReferralInfo } from '../../utils/friendReferralApiHelper';
import FacebookRounded from '../../../components/icons/FacebookRounded';
import styled from '../../../core/styled/styled';
import TwitterRounded from '../../../components/icons/TwitterRounded';
import InviteSummaryCard from '../InvitesSummaryCard';
import { useA11y } from './FriendReferral.a11y';

const InviteFriendsButton = styled(Button)({
  width: 222,
  marginTop: 0,
});

const ShareButtonsRow = styled(View)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: 15,
  alignItems: 'center',
});

const Container = styled(View)({
  '&:focus': {
    outline: 'none',
  },
});

const FriendReferral: FunctionComponent<{
  friendReferralInfo: FriendReferralInfo;
}> = ({ friendReferralInfo }) => {
  const { colors } = useEmotionTheme();
  const [shareButtonText, setShareButtonText] = useState('Invite friends');
  const copyLinkToClipBoard = () => {
    Clipboard.setString(friendReferralInfo.referralUrl || '').then((success) => {
      if (success) {
        setShareButtonText('Link copied!');
      } else {
        setShareButtonText('Could not copy link.');
      }
    });
  };

  const handleOnTwitterPress = () => {
    const width = 575;
    const height = 400;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;
    const url = `https://twitter.com/share?url=${encodeURIComponent(
      `${friendReferralInfo.referralUrl}&p=3&c=2`
    )}&text=${encodeURIComponent(friendReferralInfo.shareMessage)}`;
    const opts = `${'status=1,width='}${width},height=${height},top=${top},left=${left}`;
    window.open(url, 'friend referral', opts);
  };

  const handleOnFacebookPress = () => {
    const width = 575;
    const height = 400;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;
    const url = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
      `${friendReferralInfo.referralUrl}&p=3&c=1`
    )}`;
    const opts = `${'status=1,width='}${width},height=${height},top=${top},left=${left}`;
    window.open(url, 'friend referral', opts);
  };

  const { titleText, titleTextId, boldTitleTextId, shareTextId, disclaimerId, containerRef } =
    useA11y();

  return (
    <Container align="center" ref={containerRef} aria-labelledby={titleTextId} tabIndex={-1}>
      <Image
        src="/images/friendReferralBanner.png"
        width={210}
        height={212}
        alt="Two friends exchanging a gift"
        style={{
          marginBottom: 25,
        }}
      />
      <Tiny as="h2" variant="tinyAllCaps" id={titleTextId}>
        {titleText}
      </Tiny>
      <ExtraHuge as="h1" id={boldTitleTextId}>
        {friendReferralInfo.referralTitle}
      </ExtraHuge>
      <Large as="h3" id={shareTextId} variant="largeDarkGrey" style={{ margin: 10 }}>
        Share Talkspace and get rewarded with credit
        <Large variant="largeDarkGrey" inline aria-hidden="true">
          *
        </Large>
      </Large>

      <View style={{ alignItems: 'center' }}>
        <ShareButtonsRow>
          <InviteFriendsButton
            aria-describedby={`${shareTextId} ${disclaimerId} ${boldTitleTextId}`}
            onPress={copyLinkToClipBoard}
            text={shareButtonText}
          />

          <TouchableView
            aria-label="Share on Facebook"
            aria-describedby={boldTitleTextId}
            style={{ marginLeft: 13, marginRight: 13 }}
            onPress={handleOnFacebookPress}
          >
            <FacebookRounded fill={colors.chambray} />
          </TouchableView>
          <TouchableView
            aria-label="Share on Twitter"
            aria-describedby={boldTitleTextId}
            onPress={handleOnTwitterPress}
          >
            <TwitterRounded fill={colors.pictonBlue} />
          </TouchableView>
        </ShareButtonsRow>
        <InviteSummaryCard friendReferralInfo={friendReferralInfo} />
      </View>
      <Mini id={disclaimerId} style={{ marginTop: 10 }}>
        <Mini inline aria-hidden="true">
          *
        </Mini>
        Subject to terms and conditions
      </Mini>
    </Container>
  );
};

export default FriendReferral;
