import { useCallback, useMemo } from 'react';
import { useDeepLinkAction, DeepLinkAction } from '../hooks/useDeepLink';
import ClientChatApiHelper from '../clientChat/utils/ApiHelper';
import { useOpenModal } from './ModalsContextProvider';
import useOpenModalSafe from '@/hooks/useOpenModalSafe';
import configs from './configs';

const DEBUG = false; // IF YOU SEE THIS AS TRUE IN A PR, REQUEST TO CHANGE IT TO FALSE

interface ChatDeepLinksProps {
  roomID: number;
  planID: number | null;
}

export const useChatDeepLinks = ({ roomID, planID }: ChatDeepLinksProps) => {
  const openModal = useOpenModal();

  useDeepLinkAction(DeepLinkAction.APPLY_COUPON)(
    useMemo(() => {
      return { openModal, roomID };
    }, [openModal, roomID])
  );

  useDeepLinkAction(DeepLinkAction.OFFER)(
    useMemo(() => {
      return { openModal, roomID };
    }, [openModal, roomID])
  );

  useDeepLinkAction(DeepLinkAction.FRIEND_REFERRAL)(openModal);

  useDeepLinkAction(DeepLinkAction.CHECK_IN)(
    useMemo(() => {
      return { openModal, roomID };
    }, [openModal, roomID])
  );

  useDeepLinkAction(DeepLinkAction.MATCHING_INTAKE)(
    useMemo(() => {
      return { openModal, roomID };
    }, [openModal, roomID])
  );

  useDeepLinkAction(DeepLinkAction.AFTER_LOGIN)(
    useMemo(() => {
      return { openModal, planID };
    }, [openModal, planID])
  );

  useDeepLinkAction(DeepLinkAction.CHECK_REACTIVATION)(
    useCallback(
      (onSuccess) => {
        const clientChatApi = new ClientChatApiHelper();
        clientChatApi
          .clientReactivation()
          .then((res) => {
            if (!res.shouldReactivate) {
              // todo: add reactivation status to session storage
              sessionStorage.setItem('reactivationCheckDone', 'true');
            }

            onSuccess({
              reactivationPayload: res,
              openModal,
            });
          })
          .catch(clientChatApi.dismissIfCancelled)
          .catch((err) => {
            console.error(err); // eslint-disable-line no-console
          });
      },
      [openModal]
    )
  );

  useDeepLinkAction(DeepLinkAction.VIDEO_CALL)(
    useMemo(() => {
      return { openModal, roomID };
    }, [openModal, roomID])
  );

  useDeepLinkAction(DeepLinkAction.PROVIDER_DEPARTING_SWITCH)(
    useMemo(() => {
      return { openModal, roomID };
    }, [openModal, roomID])
  );
};

interface HomeDeepLinksProps {
  planID: number | null;
}

export const useNavShellDeepLinks = ({ planID }: HomeDeepLinksProps) => {
  const openModal = useOpenModalSafe();

  useDeepLinkAction(DeepLinkAction.ACCOUNT)({ openModal });

  useDeepLinkAction(DeepLinkAction.FRIEND_REFERRAL)(openModal);

  useDeepLinkAction(DeepLinkAction.AFTER_LOGIN)(
    useMemo(() => {
      return { openModal, planID };
    }, [openModal, planID])
  );

  useDeepLinkAction(DeepLinkAction.CHECK_REACTIVATION)(
    useCallback(
      (onSuccess) => {
        const clientChatApi = new ClientChatApiHelper();
        clientChatApi
          .clientReactivation()
          .then((res) => {
            if (!res.shouldReactivate) {
              // todo: add reactivation status to session storage
              sessionStorage.setItem('reactivationCheckDone', 'true');
            }

            onSuccess({
              reactivationPayload: res,
              openModal,
            });
          })
          .catch(clientChatApi.dismissIfCancelled)
          .catch((err) => {
            console.error(err); // eslint-disable-line no-console
          });
      },
      [openModal]
    )
  );
};

interface MyAccountDeepLinksProps {
  changeEmail: () => void;
  changePassword: () => void;
  updatePaymentDetails: () => void;
  changePhoneNumber: () => void;
}

export const useAccountDetailsDeepLinks = ({
  changeEmail,
  changePassword,
  updatePaymentDetails,
  changePhoneNumber,
}: MyAccountDeepLinksProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useDeepLinkAction(DeepLinkAction.CHANGE_PASSWORD)(useCallback(changePassword, []));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useDeepLinkAction(DeepLinkAction.CHANGE_EMAIL)(useCallback(changeEmail, []));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useDeepLinkAction(DeepLinkAction.UPDATE_PAYMENT)(useCallback(updatePaymentDetails, []));
  useDeepLinkAction(DeepLinkAction.CHANGE_PHONE_NUMBER)(changePhoneNumber);
};

/**
 * @param {string} link example '/email-verification/sent'
 * @returns {string} firebaseDeeplink
 *
 * Used to construct a firebase link from a redirect path returned from auth api.
 * example https://talkspace.page.link/?link=/email-verification/sent&apn=1234&isi=1234&ibi=1234&ibi=1234&ofl=1234
 *
 * If this link leads back to web, it is consumed in:
 * - apps/client-web/src/containers/CrossPlatformDeepLink.tsx
 */
export const getFirebaseDeepLink = (link: string): string =>
  `${configs.deepLinks.subDomain}/?link=${encodeURIComponent(link)}&apn=${
    configs.deepLinks.android.apn
  }&isi=${configs.deepLinks.iOS.appStoreID}&ibi=${configs.deepLinks.iOS.bundleID}${
    DEBUG ? '&d=1' : ''
  }`;

const getPathname = (link: string): string => {
  const url = new URL(link);
  return url.pathname + url.search + url.hash;
};

export const getUniversalDeepLink = (link: string): string =>
  `${configs.deepLinks.universalDeepLinkDomain}${getPathname(link)}`;
