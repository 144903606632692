import { useMemo, useCallback, useState, useEffect } from 'react';
import { RoomType } from 'ts-frontend/entities/Room';
import * as React from 'react';
import {
  Button,
  TabRadioGroup,
  ScrollView,
  spacing,
  SpacingView,
  StickyDrawerParentContainer,
  StickyDrawer,
  TextDS,
  Grid,
  Col,
  Row,
  StatusTag,
  StatusTagVariant,
  View,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { withRouter, RouteComponentProps } from '../../../core/routerLib';
import { useMainState } from '../../../hooks/mainContext';
import { UpcomingSurvey } from '../../reducers/surveys';
import { getProgressMessageText } from '../ClinicalProgressDashboardTool';
import { useCloseModal } from '../../../utils/ModalsContextProvider';
import RoomSelectionCard from '../../../2storybook/components/RoomSelectionCard';
import { sortRoomsByLastMessageDate } from '../../../utils/extractRoomEntitiesFromState';
import { trackNavPageView } from '../../../utils/analytics/events';

const { space300, space400, space800 } = spacing;

const getTagVariant = (roomType: RoomType): StatusTagVariant => {
  if (roomType === 'couples_room') return 'therapyCouples';
  if (roomType === 'psychiatry_room') return 'psychiatry';
  return 'psychotherapy';
};

const getMessageText = (
  upcomingSurveysByRoomID: { [roomID: string]: UpcomingSurvey[] } = {},
  roomID: number,
  isV2: boolean
) => {
  const { progressMessageLine1, progressMessageLine2 } = getProgressMessageText(
    upcomingSurveysByRoomID[roomID] || [],
    roomID,
    isV2
  );
  return `${progressMessageLine1} \n${progressMessageLine2}`;
};

const ID_THERAPIST_NAME = 'ID_THERAPIST_NAME';
const ID_CLINICAL_PROGRESS = 'ID_CLINICAL_PROGRESS';

const ClinicalProgressRoomList = ({
  location,
  isV2,
}: { isV2?: boolean } & RouteComponentProps<
  {},
  {},
  {
    currentRoomID: number;
    upcomingSurveysByRoomID?: { [roomID: string]: UpcomingSurvey[] };
  }
>) => {
  const closeModal = useCloseModal();
  const routeState = location.state || {};
  const { roomsByID, therapistsByID } = useMainState();
  const { isMobile } = useWindowWidthState();
  const rooms = useMemo(
    () => Object.values(roomsByID).sort(sortRoomsByLastMessageDate),
    [roomsByID]
  );
  const { upcomingSurveysByRoomID, currentRoomID } = routeState;
  const [checkedRoom, setCheckedRoom] = useState<number>(currentRoomID);

  useEffect(() => {
    trackNavPageView('clinical-progress-select');
  }, []);

  useEffect(() => {
    if (!checkedRoom && rooms && rooms.length) {
      setCheckedRoom(rooms[0].roomID);
    }
  }, [checkedRoom, rooms]);

  const handleSubmit = useCallback(() => {
    closeModal({
      navigateTo: 'clinicalProgress',
      metadata: { roomID: checkedRoom },
    });
  }, [checkedRoom, closeModal]);

  return (
    <StickyDrawerParentContainer style={{ width: '100%' }}>
      <Grid>
        <Row>
          {!isMobile && (
            <Col xs={4}>
              <></>
            </Col>
          )}
          <Col xs={4} sm={8} md={8} lg={4}>
            <SpacingView centerContent centerAlign itemSpacing="space150">
              <TextDS variant="heading2xl">You’re tracking progress with multiple providers</TextDS>
              <TextDS variant="body">Select which progress area you’d like to view</TextDS>
            </SpacingView>
          </Col>
        </Row>
      </Grid>

      {isV2 ? (
        <View style={{ marginTop: space300 }}>
          <Grid>
            <Row>
              {!isMobile && (
                <Col xs={4}>
                  <></>
                </Col>
              )}
              <Col xs={4} sm={8} md={8} lg={4}>
                <TabRadioGroup
                  legendText="Select a room to view clinical progress"
                  initialSelection={!!checkedRoom}
                >
                  <SpacingView centerContent centerAlign fullWidth>
                    {rooms.map((room, i) => (
                      <RoomSelectionCard
                        fullWidth
                        room={room}
                        therapist={therapistsByID[room.therapistID]}
                        setSelectedRoom={setCheckedRoom}
                        selectedRoom={checkedRoom}
                        tinyText={getMessageText(upcomingSurveysByRoomID, room.roomID, true)}
                        key={room.roomID}
                        ariaLabelledBy={`${ID_THERAPIST_NAME}-${i}`}
                        ariaDescribedBy={`${ID_CLINICAL_PROGRESS}-${i}`}
                        isTabFirstOption={i === 0 && !checkedRoom}
                        topRow={<StatusTag variant={getTagVariant(room.roomType)} />}
                      />
                    ))}
                  </SpacingView>
                </TabRadioGroup>
              </Col>
            </Row>
          </Grid>
          <StickyDrawer>
            <Button
              text="Continue"
              onPress={handleSubmit}
              disabled={!checkedRoom}
              style={{ width: '100%' }}
            />
          </StickyDrawer>
        </View>
      ) : (
        <>
          <ScrollView
            style={{ maxHeight: '40vh', minWidth: 345, paddingBottom: space800 + space400 }}
          >
            <TabRadioGroup
              legendText="Select a room to view clinical progress"
              initialSelection={!!checkedRoom}
            >
              <SpacingView>
                {rooms.map((room, i) => (
                  <RoomSelectionCard
                    room={room}
                    therapist={therapistsByID[room.therapistID]}
                    setSelectedRoom={setCheckedRoom}
                    selectedRoom={checkedRoom}
                    tinyText={getMessageText(upcomingSurveysByRoomID, room.roomID, false)}
                    key={room.roomID}
                    ariaLabelledBy={`${ID_THERAPIST_NAME}-${i}`}
                    ariaDescribedBy={`${ID_CLINICAL_PROGRESS}-${i}`}
                    isTabFirstOption={i === 0 && !checkedRoom}
                  />
                ))}
              </SpacingView>
            </TabRadioGroup>
          </ScrollView>
          <Button text="Continue" onPress={handleSubmit} style={{ marginTop: 20, width: 335 }} />
        </>
      )}
    </StickyDrawerParentContainer>
  );
};

export default withRouter(ClinicalProgressRoomList);
