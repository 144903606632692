import { FunctionComponent, useState, useEffect } from 'react';

import applyCoupon, { Coupon, Billing } from '../utils/apiHelper';
import { RouteComponentProps } from '../../core/routerLib/routerLib';
import CouponModal from '../components/CouponModal';
import { useCloseModal } from '../../utils/ModalsContextProvider';
import ssoHelper, { ZendeskPath } from '../../utils/sso';

type Props = RouteComponentProps<{ roomID: string; code: string }>;

const VoucherCouponContainer: FunctionComponent<Props> = ({ match }) => {
  const { roomID, code } = match.params;

  const closeModal = useCloseModal();
  const [isError, setIsError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [couponDetails, setCouponDetails] = useState<Coupon>({} as unknown as Coupon);
  const [billingDetails, setBillingDetails] = useState<Billing>({} as unknown as Billing);

  useEffect(() => {
    if (code) {
      applyCoupon(roomID, code)
        .then((res) => {
          if (res && res.success) {
            setCouponDetails(res.coupon);
            setBillingDetails(res.billing);
          } else {
            setIsError(true);
          }
        })
        .catch(() => {
          setIsError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      closeModal();
    }
  }, [roomID, code, closeModal]);

  const handleOnContactCustomerSupportPress = () => {
    setLoading(true);
    ssoHelper.openZendesk(ssoHelper.ZendeskPath.CONTACT_US as ZendeskPath).finally(() => {
      setLoading(false);
    });
  };

  return (
    <CouponModal
      couponDetails={couponDetails}
      billingDetails={billingDetails}
      isError={isError}
      isLoading={isLoading}
      onContactCustomerSupportPress={handleOnContactCustomerSupportPress}
      onClosePress={() => closeModal()}
    />
  );
};

export default VoucherCouponContainer;
