import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { History } from 'history';
import { StepWizardProvider } from 'stepWizard';
import { ThemedFlagsProvider } from 'launchDarkly';
import { useGenericWizardActions } from '@/utils/genericWizardActions';
import { getUserData } from '@/auth/helpers/token';
import { useMainActions, useMainState } from '@/hooks/mainContext';
import Routes from './Routes';
import { withRouter, RouteComponentProps } from '../core/routerLib';
import { getInitialState, State } from './reducers/checkInWizardState';
import checkInWizardActions from './hooks/checkInWizardActions';
import ApiHelper from '../utils/ApiHelper';

interface CheckInRouteParams {
  roomID: number;
  source?: string;
  checkInSource?: string;
  userPromptID?: number;
  videoCallID?: number;
  contextID?: string;
  history: History;
}

const CheckInWizard: FunctionComponent<RouteComponentProps<CheckInRouteParams>> = ({
  history,
  match: {
    params: { roomID, source, contextID = '', checkInSource, userPromptID, videoCallID },
  },
}) => {
  const { id } = getUserData();
  const { getLVSSubscriptions, getAllRooms } = useMainActions();
  const { subscriptionsByID } = useMainState();
  const isIntroCreditEligible = subscriptionsByID[roomID]?.subscription.isIntroCreditEligible;

  const getFirstState = useCallback(getInitialState, [
    roomID,
    source,
    contextID,
    checkInSource,
    isIntroCreditEligible,
    videoCallID,
    userPromptID,
  ]);
  const [initialState, setInitialState] = useState<State | undefined>();
  const genericCheckInWizardActions = useGenericWizardActions(checkInWizardActions);

  useEffect(() => {
    getLVSSubscriptions();
    getAllRooms(id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isIntroCreditEligible !== undefined) {
      setInitialState(
        getFirstState(
          id,
          roomID,
          source,
          contextID,
          userPromptID,
          isIntroCreditEligible,
          checkInSource,
          videoCallID
        )
      );
    }
    // eslint-disable-next-line
  }, [isIntroCreditEligible]);
  return (
    <>
      {initialState ? (
        <StepWizardProvider
          ApiHelperOverride={ApiHelper}
          initialState={initialState}
          genericActions={genericCheckInWizardActions}
        >
          <ThemedFlagsProvider versionKey="checkInWizard">
            <Routes history={history} />
          </ThemedFlagsProvider>
        </StepWizardProvider>
      ) : null}
    </>
  );
};

export default withRouter(CheckInWizard);
