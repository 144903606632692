import { useEffect } from 'react';

import { RouteComponentProps, withRouter } from '@/core/routerLib';

const OnboardingPageMeetYourProvider = ({
  match: {
    params: { roomID },
  },
  history,
}: RouteComponentProps<{ roomID: number }>) => {
  // NOTE: no intro page for this step, redirect to /meet-your-provider right away
  useEffect(() => {
    history.push(`/room/${roomID}/onboarding/meet-your-provider`);
  }, [history, roomID]);

  return null;
};

export default withRouter(OnboardingPageMeetYourProvider);
