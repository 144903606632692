import { ErrorScreen } from '@talkspace/react-toolkit/src/designSystems/organisms';
import { CalendarError } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import { useIsModal } from '../../utils/ModalsContextProvider';

interface Props {
  onPressUpdatePlan: () => void;
  onPressClose: () => void;
}
const ScheduleChooseProviderNoBookableRooms = ({ onPressClose, onPressUpdatePlan }: Props) => {
  const isModal = useIsModal();
  return (
    <ErrorScreen
      headerTitle="Book a session"
      illustration={<CalendarError />}
      title="Update your plan to continue care"
      description="You either canceled your plan, used all your credits, or your coverage has expired. Once you update your plan, you’ll be able to book your next session."
      buttonText="Update plan"
      onClose={onPressClose}
      onPressButton={onPressUpdatePlan}
      safeAreaVariant={isModal ? 'none' : 'setHeightAndAddTop'}
    />
  );
};
export default ScheduleChooseProviderNoBookableRooms;
