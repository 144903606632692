import { OptionType } from '@talkspace/react-toolkit';
import TSConfigs from '@talkspace/configs';
import { OtherWizardGenericActions } from 'utils/genericWizardActions';
import { State } from '../reducers/matchingIntakeWizardState';

export const getTherapistAbilityPreferences = () => {
  const configKey = 'therapistAbilityPreferences';
  return [
    ...Object.keys(TSConfigs[configKey] ? TSConfigs[configKey] : {}).map((key) => {
      return {
        value: key,
        label: TSConfigs[configKey][key],
      };
    }),
  ];
};

const initState = async (api, state) => {
  const existingPreferences = await api.getMatchingPreferences(state.roomID.toString());
  const {
    data: { data: intakesStatus },
  } = await api.getIntakesStatus(state.roomID);
  const customerInfo = await api.getCustomerInformation(state.clientUserID);
  let providerGender = existingPreferences && existingPreferences.providerGender;
  if (!providerGender && customerInfo.therapistGenderPreference)
    providerGender = customerInfo.therapistGenderPreference.toLowerCase();
  const presentingProblems = (await api.getPresentingProblems())
    .map((problem): OptionType => {
      return { label: problem.value, value: problem.id };
    })
    .sort((firstOption, secondOption) =>
      firstOption.label.toLowerCase().localeCompare(secondOption.label.toLowerCase())
    );
  return {
    countryState: customerInfo.countryState,
    dateOfBirth: customerInfo.dateOfBirth,
    providerGender,
    therapistEthnicity: existingPreferences && existingPreferences.therapistEthnicity,
    seekHelpOptions: presentingProblems,
    alreadyCompleted:
      intakesStatus.intakeStatus === 'submitted' && intakesStatus.isIntakeAvailable === false,
    completedAtDate: intakesStatus.matchingCompletedAt,
    therapistAbilityPreferences: getTherapistAbilityPreferences(),
  };
};

const persist = async (api, state, wizardCompleted) => {
  // save only if wizard completed
  if (wizardCompleted) {
    // eslint-disable-next-line no-console
    console.log(wizardCompleted);
  }
};

const matchingIntakeActions: OtherWizardGenericActions<State> = {
  initState,
  persist,
};

export default matchingIntakeActions;
