import { FunnelName } from '@/utils/analytics/trackerTypes';
import { SessionModality, VideoCreditOffer, TimeslotByDay } from '../../ts-frontend/types';

export type MixpanelSessionModality = 'Video' | 'Audio' | 'Live Chat' | 'Async Chat';

export const mixpanelSessionModalities: Record<SessionModality, MixpanelSessionModality> = {
  video: 'Video',
  audio: 'Audio',
  chat: 'Live Chat',
  messaging: 'Async Chat',
};

export enum ScheduledBy {
  therapist = 'Provider',
  client = 'Client',
}

export enum CreditType {
  therapy = 'Therapy',
  psychiatry = 'Psychiatry',
  introduction = 'Introductory',
}

type VisitTherapistSchedulerTrackingData = {
  therapistID: number;
  funnelName: FunnelName;
  modality?: MixpanelSessionModality;
  roomID: number | undefined;
  scheduledBy: ScheduledBy;
  creditType: CreditType;
  noAvailability: boolean | undefined;
  actualTimes?: string[];
  numberOfSlots?: number;
  numberOfProviders?: number;
  matched?: boolean;
  roomStatus?: number;
};

const getVisitTherapistSchedulerTrackingData = (
  funnelName: FunnelName,
  therapistID: number,
  roomID?: number,
  creditType?: VideoCreditOffer['type'],
  modality?: SessionModality,
  isTherapist?: boolean,
  noAvailability?: boolean,
  timeSlotsByDay?: TimeslotByDay[],
  roomStatus?: number,
  matched?: boolean
): VisitTherapistSchedulerTrackingData => {
  let onboardingData = {};

  if (funnelName === 'Onboarding' && !isTherapist) {
    const numberOfSlots =
      timeSlotsByDay?.reduce((partialTotal, data) => partialTotal + data.timeslots.length, 0) || 0;

    const numberOfProviders = [
      ...new Set(
        timeSlotsByDay
          ?.flatMap(({ timeslots }) => timeslots)
          .flatMap(({ therapists }) => therapists)
      ),
    ].length;

    const actualTimes = timeSlotsByDay
      ?.flatMap(({ timeslots }) => timeslots)
      .map(({ start }) => start);

    onboardingData = {
      numberOfSlots,
      actualTimes,
      numberOfProviders,
      roomStatus,
      matched,
    };
  }

  return {
    therapistID,
    funnelName,
    modality: modality ? mixpanelSessionModalities[modality] : undefined,
    roomID,
    scheduledBy: isTherapist ? ScheduledBy.therapist : ScheduledBy.client,
    creditType: CreditType[creditType || ''],
    noAvailability,
    ...(funnelName === 'Onboarding' && onboardingData),
  };
};

// eslint-disable-next-line import/prefer-default-export
export { getVisitTherapistSchedulerTrackingData };
