import { ETherapistInfo } from 'ts-frontend/entities/Therapist';
import { RoomType, RoomStatus, AccountType } from 'ts-frontend/entities/Room';
import {
  TouchableView,
  View,
  ExtraBig,
  Tiny,
  Small,
  GivingHeart,
  SmallCalendar,
  EmotionStyle,
} from '@talkspace/react-toolkit';
import moment from 'moment';
import { ElementType } from 'react';
import TherapistAvatar from '../TherapistAvatar';
import styled from '../../core/styled';
import useEligibilityWarning from '../../EligibilityWarning/hooks/useEligibilityWarning';
import RoomWarningStatusTag from '../RoomSummaryCard/RoomWarningStatusTag';

const CardContainer = styled(TouchableView)(() => {
  return {
    borderRadius: 10,
    width: '100%',
    maxWidth: 400,
    boxShadow: ` 0px 4px 14px -4px rgba(52, 43, 80, 0.15)`,
  };
});

const CardHeader = styled(View)<{ headerBgColor: string }>(({ headerBgColor }) => {
  return {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    padding: 15,
    background: headerBgColor,
  };
});

const CardSectionContainer = styled(View)(() => {
  return {
    paddingTop: 24,
    paddingBottom: 24,
  };
});

interface CardSectionProps {
  title: string;
  info?: string;
  Icon: ElementType;
  isInsuranceIneligible?: boolean;
  isClosedUpdatedCoverage?: boolean;
}

const CardSection = ({
  title,
  info,
  Icon,
  isInsuranceIneligible,
  isClosedUpdatedCoverage,
}: CardSectionProps) => (
  <View style={{ marginBottom: 16, marginLeft: 17, marginRight: 17 }} row>
    <Icon />
    <View style={{ marginLeft: 10, marginRight: 10, flexBasis: 'fit-content' }}>
      <Tiny>{title}</Tiny>
      <Small variant="smallTSBlack" style={{ marginTop: 4 }}>
        {info}
      </Small>
      {isInsuranceIneligible && !isClosedUpdatedCoverage && (
        <RoomWarningStatusTag text="Insurance coverage expired" />
      )}
      {isInsuranceIneligible && isClosedUpdatedCoverage && (
        <RoomWarningStatusTag text="Room closed" isGreyVariant />
      )}
    </View>
  </View>
);

interface CoverageCardProps {
  label: string;
  style?: EmotionStyle;
  accountType: AccountType;
  Icon: ElementType;
  headerBgColor: string;
  roomID?: number;
  roomStatus?: RoomStatus;
  roomType: RoomType;
  provider?: ETherapistInfo;
  lastSession?: string;
  handlePress: (coverageCardParams: {
    isInsuranceIneligible: boolean;
    isClosedUpdatedCoverage: boolean;
  }) => void;
}

const CoverageCard = ({
  label,
  Icon,
  accountType,
  roomID,
  roomStatus,
  roomType,
  provider,
  lastSession,
  headerBgColor,
  style,
  handlePress,
}: CoverageCardProps) => {
  const { isInsuranceIneligible, isClosedUpdatedCoverage } = useEligibilityWarning({
    roomID: roomID || -1,
  });
  const getCoverageInfo = () => {
    switch (accountType) {
      case 'b2c':
        return 'Self-pay';
      case 'bh':
        return 'Covered by healthcare provider';
      case 'dte':
      case 'eap':
        return 'Covered by employer';
      default:
        return '';
    }
  };

  const sessionHistoryInfo =
    lastSession && moment(lastSession).isValid()
      ? `Last session ${moment(lastSession).format('MM/DD/YY')}`
      : 'No session history';

  const providerLicenses = `${provider?.licenses?.length ? ', ' : ''}${provider?.licenses.join(
    ', '
  )}`;

  return (
    <CardContainer
      style={style}
      onPress={() => {
        handlePress({ isInsuranceIneligible, isClosedUpdatedCoverage });
      }}
    >
      <CardHeader row justify="space-between" headerBgColor={headerBgColor}>
        <ExtraBig>{label}</ExtraBig>
        <Icon />
      </CardHeader>
      <CardSectionContainer>
        {provider && (
          <CardSection
            title="Provider"
            info={provider ? `${provider.firstName} ${provider.lastName}${providerLicenses}` : ''}
            Icon={() => (
              <TherapistAvatar
                hideStatus
                size="extraSmall"
                roomType={roomType}
                roomStatus={roomStatus}
                therapist={provider}
              />
            )}
          />
        )}
        <CardSection
          title="Coverage"
          info={getCoverageInfo()}
          Icon={GivingHeart}
          isInsuranceIneligible={isInsuranceIneligible}
          isClosedUpdatedCoverage={isClosedUpdatedCoverage}
        />
        <CardSection title="Session history" info={sessionHistoryInfo} Icon={SmallCalendar} />
      </CardSectionContainer>
    </CardContainer>
  );
};

export default CoverageCard;
