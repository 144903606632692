/* eslint-disable camelcase */
import { ClientMatchPresentingProblem } from 'ts-frontend/types';
import { TherapistType } from 'ts-frontend/entities/Therapist';

export interface Match {
  additionalLanguages?: string[];
  therapistId: number;
  implicitBusinessHoursByDay: ImplicitBusinessHoursByDay | undefined;
  imageURL: string;
  info: Info;
  videoURL: string;
  licenses: License[];
  expertise: Expertise;
  availability: Availability;
  bestMatch: boolean;
}

export interface Availability {
  availableNow: boolean;
  nextAvailableDatetimeInSeconds: null;
  nextAvailableDatetimeUTC: null;
}

export interface Expertise {
  focus: string[];
  treatmentApproach: any[];
}

export interface Info {
  public_details: string;
  psychologytoday_profile_id: string;
  first_clinical_license_date: string;
  first_name: string;
  last_name: string;
  created_at: string;
  businessHours: BusinessHours;
  joinedYearsAgo: number;
  joinedMonthsAgo: number;
  psychologyTodayLink: string;
  yearsInPractice: number;
  monthsInPractice: number;
  businessDays: BusinessDays;
  paidUsers: number;
  therapistType: TherapistType;
}

export interface BusinessDays {
  Sunday?: number;
  Monday?: number;
  Tuesday?: number;
  Wednesday?: number;
  Thursday?: number;
  Friday?: number;
  Saturday?: number;
}

export interface BusinessHours {
  businessHoursAvailability: BusinessHoursAvailability;
  therapistTimezone: string;
}

export interface ImplicitBusinessHour {
  hourValue: number;
  hourIndex: number;
}
export interface ImplicitBusinessHoursAPI {
  businessHours: ImplicitBusinessHour[];
  therapistID: number;
}

export interface BusinessHoursAvailability {
  blocks: ImplicitBusinessHoursByDay;
  status: string;
  timeOff?: TimeOff;
}

export interface TimeOff {
  dateStart?: string;
  dateEnd?: string;
}

export type BusinessHoursDay = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

export interface ImplicitBusinessHoursByDay {
  mon: number[];
  tue: number[];
  wed: number[];
  thu: number[];
  fri: number[];
  sat: number[];
  sun: number[];
}

export interface License {
  id: number;
  number: string;
  state: string;
  stateAbb: string;
  country: string;
  countryAbb: string;
  expirationDate: string;
  type: string;
  typeDescription: string;
  typeId: number;
}

export interface State {
  matches: Match[] | null;
  therapistIsUnavailable: boolean;
  canBookIntroSession: boolean;
  showAvailability: boolean;
  isLoading: boolean;
  error: string | null;
  isB2B?: boolean;
  roomPresentingProblems: ClientMatchPresentingProblem[];
}

export type Action =
  | { type: 'getInPlatformMatches' }
  | { type: 'receiveGetInPlatformMatches'; payload: Partial<State> }
  | { type: 'getImplicitBusinessHours' }
  | { type: 'getTherapistTimeslots' }
  | { type: 'getRoomPresentingProblems' }
  | {
      type: 'receiveRoomPresentingProblems';
      payload: { roomPresentingProblems: ClientMatchPresentingProblem[] };
    }
  | { type: 'receiveGetTherapistTimeslots'; payload: { canBookIntroSession: boolean } }
  | { type: 'receiveGetImplicitBusinessHours'; payload: Partial<State> }
  | { type: 'setTherapistIsUnavailable'; payload: { therapistID: number } }
  | { type: 'requestSwitchTherapist' }
  | { type: 'receiveRequestSwitchTherapist' }
  | { type: 'requestNewInPlatformMatches' }
  | { type: 'receiveNewInPlatformMatches' }
  | { type: 'resetTherapistIsUnavailable' }
  | { type: 'receiveCustomerInformation'; payload: { showAvailability: boolean } }
  | { type: 'setIsError'; error: string };

export const initialState: State = {
  matches: null,
  therapistIsUnavailable: false,
  canBookIntroSession: false,
  showAvailability: false,
  isLoading: false,
  error: null,
  roomPresentingProblems: [],
};

export const inPlatformMatchingReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'getInPlatformMatches':
    case 'getTherapistTimeslots':
    case 'getImplicitBusinessHours':
    case 'getRoomPresentingProblems':
    case 'requestSwitchTherapist':
    case 'requestNewInPlatformMatches':
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case 'receiveGetInPlatformMatches':
    case 'receiveGetTherapistTimeslots':
    case 'receiveGetImplicitBusinessHours':
    case 'receiveRoomPresentingProblems':
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case 'receiveRequestSwitchTherapist':
    case 'receiveNewInPlatformMatches':
    case 'resetTherapistIsUnavailable':
      return {
        ...state,
        therapistIsUnavailable: false,
        isLoading: false,
      };
    case 'setTherapistIsUnavailable': {
      const matches = (state.matches || []).filter(
        (m) => m.therapistId !== action.payload.therapistID
      );
      return {
        ...state,
        therapistIsUnavailable: true,
        isLoading: false,
        matches,
      };
    }
    case 'receiveCustomerInformation': {
      const { showAvailability } = action.payload;
      return {
        ...state,
        showAvailability,
      };
    }
    case 'setIsError':
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};
