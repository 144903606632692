import { FunctionComponent } from 'react';
import { TouchableView, View, Large } from '@talkspace/react-toolkit';
import styled from '../../../core/styled';
import CheckmarkIcon from '../../../components/icons/CheckmarkIcon';

interface ObjectivesListItemProps {
  text?: string;
  index: number;
  completed: boolean;
  onPress?: () => void;
}

const Wrapper = styled(TouchableView)({
  alignItems: 'center',
  marginTop: 7,
  marginBottom: 7,
});

const ItemNumberCircle = styled(View)<{ active: boolean }>(({ active, theme: { colors } }) => {
  return {
    width: 30,
    height: 30,
    borderRadius: 15,
    marginTop: 3,
    marginRight: 15,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
    backgroundColor: active ? colors.limaGreen : colors.periwinkleGrey,
  };
});

const ObjectivesListItem: FunctionComponent<ObjectivesListItemProps> = ({
  index,
  onPress,
  text,
  completed,
}) => (
  <Wrapper onPress={onPress} row>
    <ItemNumberCircle active={completed}>
      {completed ? (
        <CheckmarkIcon style={{ fill: '#fff' }} />
      ) : (
        <Large style={{ color: '#fff' }}>{index + 1}</Large>
      )}
    </ItemNumberCircle>
    <Large style={{ workBreak: 'breakword' }}>{text}</Large>
  </Wrapper>
);

export default ObjectivesListItem;
