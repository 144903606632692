import { FunctionComponent, useEffect } from 'react';
import { useEmotionTheme } from '@talkspace/react-toolkit';
import {
  PromiseMessageDataHandler,
  PromiseMessageTypeNames,
  PromiseMessageQMInitDataReceiver,
} from 'ts-promise-message';
import { GenericWizardWelcomeScreen } from 'stepWizard';
import { ETherapistInfo } from 'ts-frontend/entities/Therapist';
import { QMSessionTypeEnum } from 'ts-frontend/types';
import coverageIllustration from '../../assets/coverageIllustration.png';
import { useLocation } from '../../core/routerLib';
import {
  trackOpenEligibilityWidget,
  trackStartEligibilityWidget,
} from '../../utils/analytics/events';
import QuickmatchFrame from '../../containers/QuickmatchFrame';
import { getSessionType } from '../eligibilityWidgetUtils';

interface WelcomeScreenProps {
  qmFlowPath: string;
  autoStart: boolean;
  showCloseButton: boolean;
  providerInfo?: ETherapistInfo | null;
}

const WelcomeScreen: FunctionComponent<WelcomeScreenProps> = ({
  qmFlowPath,
  autoStart,
  showCloseButton,
  providerInfo,
}) => {
  const location = useLocation();
  const { colors } = useEmotionTheme();

  const params = new URLSearchParams(location.search);
  const source = params.get('source') as QMSessionTypeEnum | null;

  useEffect(() => {
    if (autoStart) {
      trackStartEligibilityWidget();
    }

    if (source) {
      trackOpenEligibilityWidget(source);
    }
  }, []); // eslint-disable-line

  const currentProvider: PromiseMessageQMInitDataReceiver['currentProvidersInfo'] = providerInfo
    ? [
        {
          firstName: providerInfo.firstName,
          lastName: providerInfo.lastName,
          therapistID: providerInfo.id,
          status: 'available',
          therapistImage: providerInfo.imageURL,
          therapistLicenses: providerInfo.licenses,
          title: providerInfo.title,
          therapistType: providerInfo.type,
        },
      ]
    : [];

  const onQMInitData: PromiseMessageDataHandler<PromiseMessageTypeNames.getQMInitData> = (
    _data,
    callback
  ) => {
    callback({
      currentProvidersInfo: currentProvider,
      hasOnlyPlaceholderProviders: false,
    });
  };

  const sessionType = getSessionType(source);

  return (
    <QuickmatchFrame
      frameTitle="Check my coverage"
      autoStart={autoStart}
      qmFlowPath={qmFlowPath}
      showCloseButton={showCloseButton}
      onQMInitData={onQMInitData}
      sessionType={sessionType}
      render={({ handleLaunchButton }) => (
        <GenericWizardWelcomeScreen
          firstHeadlineRowText="Check my"
          secondHeadlineRowText="coverage"
          subHeadlineText="5min"
          description="Complete a brief questionnaire to verify coverage provided by your insurance, employer, or educational organization. You'll then be matched with a provider and subscription covered by your plan. If you're currently matched with a provider, that room and subscription will remain active alongside the new room unless you cancel it."
          onButtonPress={handleLaunchButton}
          buttonText="Begin"
          imageURL={coverageIllustration}
          imageHeight={160}
          imageWidth={260}
          imageXPos={90}
          imageYPos={102}
          imageMobileXPos={0}
          imageMobileYPos={140}
          backgroundColor={colors.welcomeBlue}
          graphicCardStyles={{ marginTop: 20 }}
        />
      )}
    />
  );
};

export default WelcomeScreen;
