import { FunctionComponent } from 'react';
import Svg, { G, Circle } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const GoalsIcon: FunctionComponent<{
  color?: string;
  height?: number;
  width?: string;
  style?: object;
}> = ({ width = 36, height = 36, color, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg width={width} height={height} viewBox="0 0 36 36" {...otherProps}>
      <G transform="translate(2 2)" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle stroke={color || colors.yellowSea} strokeWidth={3} cx={16} cy={16} r={16} />
        <Circle stroke={color || colors.yellowSea} strokeWidth={3} cx={16} cy={16} r={9} />
        <Circle fill={color || colors.yellowSea} cx={16} cy={16} r={3} />
      </G>
    </Svg>
  );
};
export default GoalsIcon;
