import * as React from 'react';

import { RoomInviteProvider } from '../roomInvites/hooks/useRoomInvites';
import { RouteComponentProps, Route } from '../core/routerLib';
import MainContainer from '../containers/MainContainer';
import { TreatmentPlannerContextProvider } from '../clinicalProgress/hooks/treatmentPlannerContext';

const MainScreen: React.FunctionComponent<RouteComponentProps> = () => (
  <TreatmentPlannerContextProvider>
    <RoomInviteProvider>
      <Route path={['/rooms', '/room/:roomID', '/room']} component={MainContainer} />
    </RoomInviteProvider>
  </TreatmentPlannerContextProvider>
);
export default MainScreen;
