import { QMSessionTypeEnum } from 'ts-frontend/types';

// eslint-disable-next-line import/prefer-default-export
export const getSessionType = (source: null | string): QMSessionTypeEnum => {
  switch (source) {
    case QMSessionTypeEnum.Eligibility:
      return QMSessionTypeEnum.Eligibility;
    case QMSessionTypeEnum.Reactivation:
      return QMSessionTypeEnum.Reactivation;
    case QMSessionTypeEnum.MBHIneligibility:
      return QMSessionTypeEnum.MBHIneligibility;
    case QMSessionTypeEnum.UpdateCoverage:
      return QMSessionTypeEnum.UpdateCoverage;
    default:
      return QMSessionTypeEnum.Eligibility;
  }
};
