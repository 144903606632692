import { FunctionComponent, SetStateAction, Dispatch } from 'react';
import * as React from 'react';
import {
  View,
  Text,
  ExtraHuge,
  Large,
  Button,
  RadioButton,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import moment from 'moment';
import styled from '../../../core/styled';

interface SharingPreferencesActionViewProps {
  isSharing: boolean | null;
  setIsSharing: Dispatch<SetStateAction<boolean | null>>;
  confirmDate: string | null;
  onAgreePress: (e: React.MouseEvent<Element, MouseEvent>) => void;
  error: string | null;
}

const Wrapper = styled(View)(({ theme: { window } }) => {
  return {
    paddingTop: 17,
    paddingRight: window.isMobile ? 20 : 87,
    paddingLeft: window.isMobile ? 20 : 78,
    paddingBottom: 90,
    alignItems: 'center',
  };
});

const SharingPreferencesActionView: FunctionComponent<SharingPreferencesActionViewProps> = ({
  isSharing,
  setIsSharing,
  confirmDate,
  onAgreePress,
  error,
}) => {
  const { colors } = useEmotionTheme();
  return (
    <Wrapper>
      <View style={{ maxWidth: 375 }}>
        <ExtraHuge style={{ marginBottom: 18 }}>Sharing preferences</ExtraHuge>
        <Large variant="largeDarkGrey" style={{ marginBottom: 40 }}>
          Your chat history will only be shared with authorized individuals, including: a new
          therapist, a matching agent, and/or a partner you have invited. Sharing this history can
          be useful to your new therapist and/or matching agent.
        </Large>
        <View align="end">
          <View row justify="space-between" style={{ alignSelf: 'stretch', marginBottom: 10 }}>
            <Large variant="largeDarkGrey">Allow chat history sharing</Large>
            <RadioButton
              isActive={!!isSharing}
              onPress={() => setIsSharing(true)}
              disabled={!!confirmDate}
            />
          </View>
          <View row justify="space-between" style={{ alignSelf: 'stretch', marginBottom: 40 }}>
            <Large variant="largeDarkGrey">Keep chat history private</Large>
            <RadioButton
              isActive={!isSharing && isSharing !== null}
              onPress={() => setIsSharing(false)}
              disabled={!!confirmDate}
            />
          </View>
        </View>
        <View>
          <View row justify="center">
            <Button
              text={
                confirmDate
                  ? `Confirmed on ${moment(confirmDate).format('MMMM D YYYY')}`
                  : 'Confirm'
              }
              disabled={!!confirmDate || isSharing === null}
              onPress={onAgreePress}
              style={{ marginRight: 14, backgroundColor: colors.permaTalkspaceDarkGreen }}
            />
          </View>
          {error && <Text style={{ color: colors.red }}>{error}</Text>}
        </View>
      </View>
    </Wrapper>
  );
};

export default SharingPreferencesActionView;
