import getParamByName from '../../utils/queryString';

const getInviteUrlParams = (): { invitationKey?: string; invitationToken?: string } => {
  return {
    invitationKey: getParamByName('invitationKey') || undefined,
    invitationToken: getParamByName('invitationToken') || undefined,
  };
};

export default getInviteUrlParams;
