import { clientProgress } from '@talkspace/configs';

export interface TreatmentPlan {
  id: number;
  committed: boolean;
  roomID: number;
  archived: boolean;
  updatedAt: Date;
  totalObjectives: number;
  completedObjectives: number;
  presentingProblems: string[];
  goals: Goal[];
  objectives: Objective[];
}

export interface Goal {
  id: number;
  treatmentPlanID: number;
  presentingProblemID: number;
  presentingProblemGoalID?: number;
  description: string;
  standard: boolean;
  completed?: boolean;
}

export type ClientProgress = keyof typeof clientProgress;

export interface Objective {
  id: number;
  treatmentPlanID: number;
  presentingProblemID: number;
  presentingProblemObjectiveID?: number;
  description: string;
  standard: boolean;
  completed: boolean;
  overallProgress?: ClientProgress;
}

export interface TreatmentPlannerState {
  treatmentPlansByRoomID: {
    [key: string]: TreatmentPlan;
  };
  isLoading: boolean;
  error: string;
}

export const initialState: TreatmentPlannerState = {
  treatmentPlansByRoomID: {},
  isLoading: false,
  error: '',
};

export type TreatmentPlannerAction =
  | {
      type: 'receiveGetTreatmentPlanner';
      payload: Partial<TreatmentPlan>;
    }
  | { type: 'requestGetTreatmentPlanner' }
  | { type: 'setError'; error?: string }
  | { type: 'clearError' };

export const treatmentPlannerReducer = (
  state: TreatmentPlannerState = initialState,
  action: TreatmentPlannerAction
): TreatmentPlannerState => {
  switch (action.type) {
    case 'requestGetTreatmentPlanner':
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case 'receiveGetTreatmentPlanner':
      return {
        ...state,
        isLoading: false,
        error: '',
        treatmentPlansByRoomID: action.payload.roomID
          ? {
              ...state.treatmentPlansByRoomID,
              [action.payload.roomID]: action.payload,
            }
          : state.treatmentPlansByRoomID,
      };
    case 'setError':
      return {
        ...state,
        error: action.error || 'Something went wrong. Please try again later',
        isLoading: false,
      };
    case 'clearError':
      return {
        ...state,
        error: '',
      };
    default:
      return state;
  }
};
