import { View } from '@talkspace/react-toolkit';
import styled from '../../../core/styled';

interface ClinicalProgressCardProps {
  disabled?: boolean;
}

const ClinicalProgressCard = styled(View)<ClinicalProgressCardProps>(
  ({ disabled, theme: { window, colors } }) => {
    const { isMobile } = window;
    return {
      borderRadius: 10,
      border: disabled ? 'none' : `1px solid ${colors.periwinkleGrey}`,
      backgroundColor: disabled ? colors.permaLinkWaterGrey : colors.white,
      marginLeft: 24,
      marginRight: 30,
      paddingTop: isMobile ? 20 : 37,
      paddingRight: isMobile ? 20 : 30,
      paddingBottom: 18,
      paddingLeft: isMobile ? 20 : 25,
      flex: 1,
    };
  }
);

export default ClinicalProgressCard;
