import { useState, forwardRef } from 'react';
import {
  Button,
  Big,
  Large,
  View,
  Collapsible,
  CaretDown,
  Image,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { PlanData, ExperimentTherapyData } from 'ts-frontend/types';
import { PaymentCardWrapper } from 'checkout';
import { formatCurrency } from 'ts-frontend/helpers/billingUtils';
import CaretUp from '@talkspace/react-toolkit/src/components/icons/CaretUp';
import styled from '@/core/styled';
import Badge from './Badge';
import { ID_PAYMENT_CARD_WRAPPER } from '@/utils/IDConstants';
import {
  isMessagingTherapy,
  isVideoMessagingTherapy,
  isVideoMessagingWorkshopsTherapy,
} from '../../utils/constants';
import Check from '../../assets/Check.png';

interface Props {
  billingFrequencies: PlanData[];
  therapyData: ExperimentTherapyData;
  disabled?: boolean;
  recommended?: boolean;
  onPlanSelected?: (plan: PlanData, monthlyPlan: PlanData) => void;
  setExpandedPlan?: () => void;
}

const StyledPaymentCardWrapper = styled(PaymentCardWrapper)(({ theme: { colors } }) => {
  return {
    maxWidth: 327,
    minWidth: 327,
    padding: 0,
    margin: 0,
    marginBottom: 15,
    transition: 'none',
    border: `1px solid ${colors.permaSummerCloud}`,
  };
});

const TherapyContainer = styled(View)<{
  themeColor: string;
  planId: number;
  originalPrice?: number;
  isCollapsed: boolean;
  recommended?: boolean;
}>(({ themeColor, planId, originalPrice, isCollapsed, recommended, theme: { colors } }) => {
  return {
    left: 0,
    top: 693,
    flexGrow: 1,
    flexShrink: 1,
    borderRadius: 12,
    ...(isVideoMessagingTherapy(planId) &&
      !isCollapsed && {
        height: 570,
        background: `linear-gradient(180deg,${themeColor} 51.2%,${colors.white} 49%);`,
      }),
    ...(isVideoMessagingTherapy(planId) &&
      isCollapsed && {
        height: 147,
        ...(!originalPrice && { height: 125 }),
        background: themeColor,
      }),
    ...(isMessagingTherapy(planId) &&
      !isCollapsed && {
        height: 518,
        background: `linear-gradient(180deg,${themeColor} 53%,${colors.white} 52%)`,
      }),
    ...(isMessagingTherapy(planId) &&
      isCollapsed && {
        height: 105,
        ...(!originalPrice && { height: 85 }),
        background: themeColor,
      }),
    ...(isVideoMessagingWorkshopsTherapy(planId) &&
      !isCollapsed && {
        height: 590,
        background: `linear-gradient(180deg,${themeColor} 49.4%,${colors.white} 48%);`,
      }),
    ...(isVideoMessagingWorkshopsTherapy(planId) &&
      isCollapsed && {
        height: 137,
        ...(!originalPrice && { height: 115 }),
        background: themeColor,
      }),
    ...(recommended && { marginTop: 23 }),
  };
});

const TherapyNameWrapper = styled(View)<{ recommended?: boolean }>(
  ({ recommended, theme: { colors } }) => {
    return {
      fontFamily: 'Quarto',
      fontStyle: 'normal',
      color: colors.permaRhino,
      fontWeight: 700,
      fontSize: 26,
      position: 'absolute',
      top: recommended ? 40 : 8,
      left: 16,
      right: 50,
    };
  }
);

const ToggleIconWrapper = styled(View)<{
  isDownIcon: boolean;
  displayName: string;
  recommended?: boolean;
}>(({ isDownIcon, recommended }) => {
  return {
    display: 'inline-block',
    height: '2em',
    width: '2em',
    backgroundColor: 'white',
    borderRadius: '50%',
    marginLeft: 95,
    position: 'absolute',
    left: 185,
    paddingLeft: 8,
    paddingTop: !isDownIcon ? 4 : 3,
    top: recommended ? 43 : 10,
    verticalAlign: 'middle',
  };
});

const NewPriceWrapper = styled(View)<{ recommended?: boolean; planId: number }>(
  ({ recommended, planId }) => {
    let topMargin = 50;
    if (recommended) {
      topMargin = 115;
    } else if (isVideoMessagingWorkshopsTherapy(planId)) {
      topMargin = 83;
    }
    return {
      position: 'absolute',
      top: topMargin,
      left: 16,
    };
  }
);

const OriginalPriceWrapper = styled(View)<{ recommended?: boolean; planId: number }>(
  ({ recommended, planId, theme: { colors } }) => {
    let topMargin = 75;
    if (recommended) {
      topMargin = 140;
    } else if (isVideoMessagingWorkshopsTherapy(planId)) {
      topMargin = 107;
    }

    return {
      color: colors.black,
      position: 'absolute',
      top: topMargin,
      left: 16,
      flexDirection: 'row',
    };
  }
);

const ImageWrapper = styled(View)<{ planId: number }>(({ planId }) => {
  return {
    justifyContent: 'center',
    alignItems: 'center',
    ...(isVideoMessagingTherapy(planId) && {
      marginTop: 25,
      marginLeft: 10,
    }),
    ...(isVideoMessagingWorkshopsTherapy(planId) && {
      marginTop: 40,
    }),
    ...(isMessagingTherapy(planId) && {
      marginTop: 55,
      marginLeft: 30,
    }),
  };
});

const CollapsibleWrapper = styled(Collapsible)({
  margin: 16,
});

const InfoWrapper = styled(Large)({
  marginBottom: 15,
});

const ListWrapper = styled(View)({
  alignItems: 'center',
  flexDirection: 'row',
});

const ListTextWrapper = styled(Large)({
  marginLeft: 15,
  marginBottom: 15,
});

const StrikeThroughWrapper = styled(View)({
  textDecoration: 'line-through',
  marginLeft: 5,
});

const UpIcon = styled(CaretUp)(({ theme: { colors } }) => {
  return {
    color: colors.permaBlueStoneNew,
  };
});

const DownIcon = styled(CaretDown)(({ theme: { colors } }) => {
  return {
    color: colors.permaBlueStoneNew,
  };
});

const SelectPlanButton = styled(Button)(({ theme: { colors } }) => {
  return {
    width: '100%',
    maxWidth: 345,
    marginBottom: 10,
    backgroundColor: colors.permaCasal,
  };
});

const ExperimentPlan = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    billingFrequencies,
    disabled,
    onPlanSelected,
    recommended,
    setExpandedPlan,
    therapyData,
  } = props;

  const { colors } = useEmotionTheme();

  const ID_BILLING_OPTIONS = 'ID_EXPERIMENT_BILLING_OPTIONS';
  const selectedBillingFrequencyIndex = 0;
  const [isCollapsed, setCollapsed] = useState<boolean>(!recommended || false);

  const {
    planId,
    displayName,
    therapyName,
    therapyImage,
    therapyInfo,
    therapyBullets,
    dataQA,
    backgroundColor,
    newPrice,
    originalPrice,
    currency,
    unit,
  } = therapyData;

  const isSelectable = !!onPlanSelected;
  const expandOrSelectPlan = (): void => {
    setExpandedPlan?.();
    if (!isSelectable) return;
    setCollapsed(!isCollapsed);
  };

  return (
    <View>
      <StyledPaymentCardWrapper
        className="experiment-plan-card"
        id={ID_PAYMENT_CARD_WRAPPER}
        dataQa={`${dataQA}Plan`}
        aria-controls={ID_BILLING_OPTIONS}
        aria-checked={!isCollapsed}
        tabIndex={!isCollapsed ? 0 : -1}
        touchable
        recommended={recommended}
        highlighted={false}
        disabled={disabled}
        primaryColor={colors.green}
        onPress={expandOrSelectPlan}
      >
        {recommended && <Badge isDefaultBadge={false} text="Most Popular" />}
        <TherapyContainer
          ref={ref as React.RefObject<HTMLDivElement>}
          planId={planId}
          isCollapsed={isCollapsed}
          themeColor={backgroundColor}
          recommended={recommended}
          originalPrice={originalPrice}
        >
          {therapyImage && !isCollapsed && (
            <ImageWrapper planId={planId}> {therapyImage()} </ImageWrapper>
          )}
          <TherapyNameWrapper recommended={recommended}>{therapyName}</TherapyNameWrapper>
          {!isCollapsed && (
            <ToggleIconWrapper recommended={recommended} isDownIcon displayName={displayName}>
              <UpIcon />
            </ToggleIconWrapper>
          )}
          {isCollapsed && (
            <ToggleIconWrapper
              recommended={recommended}
              isDownIcon={false}
              displayName={displayName}
            >
              <DownIcon />
            </ToggleIconWrapper>
          )}
          {newPrice && currency && (
            <NewPriceWrapper planId={planId} recommended={recommended}>
              <Big variant="bigDarkGreen" style={{ marginBottom: 6 }}>
                {formatCurrency(newPrice, currency)}
                {unit}
              </Big>
            </NewPriceWrapper>
          )}
          {originalPrice && currency && (
            <OriginalPriceWrapper planId={planId} recommended={recommended}>
              Original price:{' '}
              <StrikeThroughWrapper>
                {formatCurrency(originalPrice, currency)}
                {unit}
              </StrikeThroughWrapper>
            </OriginalPriceWrapper>
          )}
          {!isCollapsed && (
            <CollapsibleWrapper isCollapsed={isCollapsed}>
              <InfoWrapper>{therapyInfo}</InfoWrapper>

              {therapyBullets.map((therapyBullet, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <ListWrapper key={`bullet-${i}`}>
                  <Image
                    src={Check}
                    style={{ marginBottom: 15 }}
                    height={9}
                    width={11}
                    alt="check"
                  />
                  <ListTextWrapper>{therapyBullet}</ListTextWrapper>
                </ListWrapper>
              ))}

              <View align="center">
                <SelectPlanButton
                  onPress={(e) => {
                    onPlanSelected?.(
                      billingFrequencies[selectedBillingFrequencyIndex],
                      billingFrequencies[0]
                    );
                    // prevent propagation press on expandOrSelectPlan
                    e.stopPropagation();
                  }}
                  text="Select plan"
                  roundedFocusStyle
                  primaryColor={colors.green}
                  tabIndex={!isCollapsed ? 0 : -1}
                  style={{ fontWeight: 700 }}
                  dataQa={`${dataQA}Button`}
                />
              </View>
            </CollapsibleWrapper>
          )}
        </TherapyContainer>
      </StyledPaymentCardWrapper>
    </View>
  );
});

export default ExperimentPlan;
