import { FunctionComponent, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router';
import { persistData, useWizardState, useWizardActions, WizardActionStatus } from 'stepWizard';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { trackWizardEvent } from '@/utils/analytics/events';
import useFinancialAidWizard from '../hooks/useFinancialAidWizard';
import { RouteComponentProps } from '../../core/routerLib';

const FinancialAidEligibilityContainer: FunctionComponent<RouteComponentProps<{}>> = ({
  history,
}) => {
  const {
    isVerifyExitModalOpen,
    source,
    responses,
    clientUserID,
    roomID,
    eventCategory,
    employmentStatus,
    spouseEmploymentStatus,
    householdEarnings,
    isServingMilitary,
    isVeteran,
    benefitsReceived,
    isLivingInRuralArea,
    persisted,
  } = useWizardState();
  const closeModal = useCloseModal();
  const [state, { getFinancialAidEligibilityToken, exitAction }] = useFinancialAidWizard();
  const { setState } = useWizardActions();
  const { error, isSuccess, discountAmountInPercent, eligibilityToken } = state;
  responses.discountAmountInPercent = discountAmountInPercent;

  const handlePersist = useCallback(() => {
    persistData(
      responses,
      setState,
      'Eligibility shown',
      error,
      { eligibilityShown: Boolean(isSuccess) },
      isSuccess
    );
  }, [error, isSuccess, responses, setState]);

  async function handleExitModal() {
    exitAction();
    handlePersist();
  }

  useEffect(() => {
    if (persisted) closeModal();
  }, [persisted, closeModal]);

  useEffect(() => {
    if (isSuccess) {
      trackWizardEvent('Financial Aid Eligibility Shown', eventCategory, {
        'User ID': clientUserID,
        'Financial Aid Amount In Percent': discountAmountInPercent,
        label: 'Financial Aid Amount In Percent',
        Application: eventCategory,
        eventProperty: discountAmountInPercent,
        eventPropertyValue: 0.0,
      });
    }
  }, [isSuccess]); // eslint-disable-line

  const handleApplyFinancialAid = () => {
    setState({ eligibilityShown: true, discountAmountInPercent, eligibilityToken, responses });
    history.push('/financial-aid/apply-financial-aid', { source });
  };

  useEffect(() => {
    if (isVerifyExitModalOpen) {
      handleExitModal();
    }
  }, [isVerifyExitModalOpen]); // eslint-disable-line

  useEffect(() => {
    getFinancialAidEligibilityToken(roomID, {
      employmentStatus,
      spouseEmploymentStatus,
      yearlyFamilyEarnings: householdEarnings,
      servingMilitary: isServingMilitary !== undefined,
      militaryVeteran: isVeteran !== undefined,
      federalProgramBenefits: benefitsReceived || [],
      liveInRuralArea: isLivingInRuralArea !== undefined,
    });
  }, []); // eslint-disable-line

  return (
    <WizardActionStatus
      successTitle="You've been approved!"
      successSubTitleHeadline={`${discountAmountInPercent}% off your payments`}
      successSubTitle={`Based on the information you provided, you've been approved for ${discountAmountInPercent}% off.`}
      successButtonText="Apply financial aid discount"
      successButtonDataQa="applyFinancialAidApplyButton"
      handleAction={handleApplyFinancialAid}
      actionHookState={state}
    />
  );
};

export default withRouter(FinancialAidEligibilityContainer);
