import moment from 'moment';
import { UpcomingSurvey } from '../reducers/surveys';

const checkIsSurveyDue = (upcomingSurvey: UpcomingSurvey): boolean => {
  const now = moment();
  const dueAt = moment(upcomingSurvey.dueAt);
  const deliverAt = moment(upcomingSurvey.deliverAt);
  // the cron runs on the 5, so we round up a bit to be sure its delivered
  // Round down to every 5 minutes
  const minutesRoundedDown = Math.floor(deliverAt.minutes() / 5) * 5;
  const isDelivered = deliverAt
    .startOf('hour')
    .add(minutesRoundedDown + 5, 'minutes')
    .startOf('minute')
    .add(1, 'minute') // The cron seems to deliver around the 34 seconds mark ATM, add some buffer
    .isBefore(now);
  return dueAt.isAfter(now) && isDelivered;
};

export default checkIsSurveyDue;
