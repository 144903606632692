export type VideoCreditType = 'psychiatry' | 'therapy' | 'introduction';

export type ErrorScreen = 'error' | 'noAvailability' | 'noVideoCredits';

export type BookingScreenType =
  | 'timeslotsScreen'
  | 'confirmScreen'
  | 'formSubmitSuccess'
  | 'formSubmitError';

export type VideoCreditDuration = 10 | 30 | 45 | 60;

export type BookingDuration = 30 | 60;

export interface CreateBookingSchema {
  start: string;
  end: string;
  bookingType: string;
}

export interface VideoCreditsAPIResponse {
  '30 minute therapy credits': number;
  '45 minute therapy credits': number;
  '30 minute psychiatry credits': number;
  '60 minute psychiatry credits': number;
}

export interface TimeslotsByBookingDuration {
  [key: number]: TherapistTimeslotData;
}

export interface TimeslotsByDayByBookingDuration {
  [key: number]: TimeslotByDay[];
}

export interface TherapistTimeslotsAPIResponse {
  timeslots: TherapistTimeslot[];
  therapistTimezone: string;
}

export interface TherapistTimeslot {
  start: string;
  end: string;
}

export interface TherapistTimeslotData {
  [key: number]: TherapistTimeslotsAPIResponse[];
}

export interface TimeslotByDay {
  date: string;
  timeslots: TherapistTimeslot[];
}

export interface State {
  videoCredits: VideoCreditsAPIResponse | undefined;
  videoCreditBookingDurations: number[] | undefined;
  videoCreditDurations: number[] | undefined;
  therapistTimeslotsByDay: TimeslotsByDayByBookingDuration | undefined;
  timeslotsByBookingDuration: TimeslotsByBookingDuration | undefined;
  hasTimeslotForAtLeastOneDuration: boolean | undefined;
  hasVideoCredits: boolean | undefined;
  localTimezone: string;
  isLoading: boolean;
  isError: boolean;
}

export type Action =
  | { type: 'getVideoCredits' }
  | {
      type: 'receiveGetVideoCredits';
      payload: Pick<
        State,
        | 'videoCredits'
        | 'videoCreditBookingDurations'
        | 'videoCreditDurations'
        | 'timeslotsByBookingDuration'
        | 'therapistTimeslotsByDay'
        | 'hasTimeslotForAtLeastOneDuration'
        | 'hasVideoCredits'
      >;
    }
  | { type: 'setIsError' };

export const initialState: State = {
  hasTimeslotForAtLeastOneDuration: undefined,
  hasVideoCredits: undefined,
  videoCredits: undefined,
  therapistTimeslotsByDay: undefined,
  videoCreditBookingDurations: undefined,
  videoCreditDurations: undefined,
  timeslotsByBookingDuration: undefined,
  localTimezone: Intl
    ? Intl.DateTimeFormat().resolvedOptions().timeZone
    : `(GMT ${(new Date().getTimezoneOffset() / 60) * -1})`,
  isLoading: false,
  isError: false,
};

export const schedulerReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'getVideoCredits':
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case 'receiveGetVideoCredits':
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        isError: false,
      };
    case 'setIsError':
      return {
        ...initialState,
        isLoading: false,
        isError: true,
      };
    default:
      return state;
  }
};
