import { useEffect, useRef } from 'react';
import { useUniqueID } from '@talkspace/react-toolkit';

// programmatically focuses the contents container of the welcome screen allowing for screen readers to the title and contents on render
export const useA11y = () => {
  const titleID = useUniqueID('titleID');
  const promptID = useUniqueID('promptID');
  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.focus();
    }
  }, []);

  return { titleID, promptID, wrapperRef };
};

export default useA11y;
