import { View, Spinner } from '@talkspace/react-toolkit';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { useParams } from '@/core/routerLib';
import { useOnboardingState } from '../hooks/onboardingContext';
import OnboardingPageMedicalHistory from './OnboardingPageMedicalHistory';
import OnboardingPageEmergencyContact from './OnboardingPageEmergencyContact';
import OnboardingPageMeetYourProvider from './OnboardingPageMeetYourProvider';
import OnboardingPageInformedConsent from './OnboardingPageInformedConsent';
import OnboardingPageBookFirstSession from './OnboardingPageBookFirstSession';
import OnboardingPageBookFirstAsyncSession from './OnboardingPageBookFirstAsyncSession';
import OnboardingPageBookAndActivate from './OnboardingPageBookAndActivate';
import OnboardingPageTeenEmergencyContact from './OnboardingPageTeenEmergencyContact';
import OnboardingPageTeenTreatmentIntake from './OnboardingPageTeenTreatmentIntake';
import OnboardingPageTeenParentalConsent from './OnboardingPageTeenParentalConsent';
import OnboardingPageAllDone from './OnboardingPageAllDone';

const Onboarding = () => {
  const useNewNav = useNewMemberNav();
  const closeModal = useCloseModal();
  const { roomID } = useParams<{ roomID: string }>();
  const { steps, currentStepIndex, hasStarted, isLoading, isTeensIntake, isRedirectToRoom } =
    useOnboardingState();

  if (steps === null) {
    return (
      <View align="center" justify="center" style={{ height: '100vh' }}>
        <Spinner />
      </View>
    );
  }

  if (steps.length === 0 && !isLoading) {
    closeModal(
      useNewNav
        ? {
            navigateTo: 'home',
            metadata: { path: '/' },
          }
        : {
            navigateTo: 'room',
            metadata: { roomID: Number(roomID) },
          }
    );
  }

  if (hasStarted && currentStepIndex === steps.length)
    return (
      <OnboardingPageAllDone
        totalSteps={steps.length}
        isTeensIntake={isTeensIntake}
        isRedirectToRoom={isRedirectToRoom}
      />
    );

  const { step } = steps && steps[currentStepIndex] ? steps[currentStepIndex] : { step: undefined };

  switch (step) {
    case 'TREATMENT_INTAKE':
      return (
        <OnboardingPageMedicalHistory totalSteps={steps.length} currentStep={currentStepIndex} />
      );
    case 'EMERGENCY_CONTACT':
      return (
        <OnboardingPageEmergencyContact totalSteps={steps.length} currentStep={currentStepIndex} />
      );
    case 'MEET_YOUR_PROVIDER':
      return <OnboardingPageMeetYourProvider />;
    case 'INFORMED_CONSENT':
      return (
        <OnboardingPageInformedConsent totalSteps={steps.length} currentStep={currentStepIndex} />
      );
    case 'BOOK_LIVE_SESSION':
      return (
        <OnboardingPageBookFirstSession totalSteps={steps.length} currentStep={currentStepIndex} />
      );
    case 'BOOK_ASYNC_SESSION':
      return <OnboardingPageBookFirstAsyncSession />;
    case 'BOOK_AND_ACTIVATE':
      return (
        <OnboardingPageBookAndActivate totalSteps={steps.length} currentStep={currentStepIndex} />
      );
    case 'ASYNC_SESSION_STARTED':
      return (
        <OnboardingPageBookFirstSession totalSteps={steps.length} currentStep={currentStepIndex} />
      );
    case 'TEEN_PARENTAL_CONSENT':
      return (
        <OnboardingPageTeenParentalConsent
          totalSteps={steps.length}
          currentStep={currentStepIndex}
        />
      );
    case 'TEEN_TREATMENT_INTAKE':
      return (
        <OnboardingPageTeenTreatmentIntake
          totalSteps={steps.length}
          currentStep={currentStepIndex}
        />
      );
    case 'TEEN_EMERGENCY_CONTACT':
      return (
        <OnboardingPageTeenEmergencyContact
          totalSteps={steps.length}
          currentStep={currentStepIndex}
        />
      );
    default:
      return (
        <View align="center" justify="center" style={{ height: '100vh' }}>
          <Spinner />
        </View>
      );
  }
};
export default Onboarding;
