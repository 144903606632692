import { Button, Spinner, styled } from '@talkspace/react-toolkit';

const ViewPost = styled(Button)(({ theme: { colorRoles } }) => {
  return {
    background: colorRoles.surfaces.surfaceInteractiveDefault,
    '&:hover': {
      background: 'none',
    },
    '&:pressed': {
      background: 'none',
    },
    border: 'none',
    width: '100%',
  };
});

const ViewPostButton = ({ isLoading, onViewPostPress, isMobile, dailyPrompt }) => {
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <ViewPost
      isSecondary
      removeBorder
      onPress={onViewPostPress}
      dataQa="openPostCreatorViewPostButton"
      sizeDS={isMobile ? 'large' : 'medium'}
      text="View my post"
      variant="tertiary"
      isLoading={!dailyPrompt}
    />
  );
};

export default ViewPostButton;
