import { EmotionStyle } from '@talkspace/react-toolkit';
import Svg, { Circle, Path, Ellipse } from 'svgs';

const TeenMedicalHistory = ({ style: propStyle }: { style?: EmotionStyle }) => {
  const title = 'Complete mental health intake';
  const style = {
    ...propStyle,
    marginLeft: 20,
  };
  return (
    <Svg
      width={278}
      height={240}
      viewBox="0 0 278 240"
      fill="none"
      aria-label={title}
      title={title}
      style={style}
    >
      <Circle cx={120.68} cy={120} r={120} fill="#E1F0EE" />
      <Path
        d="M13 24.456C24.825 19.183 27.186 6.622 26.889 1 28.952 18.06 35.156 24.198 38 25.135 28 26.685 24.706 37.025 24.31 42 23.119 26.026 16.273 23.648 13 24.456z"
        fill="#D2E9E6"
      />
      <Path
        d="M140 55.456c11.825-5.273 14.187-17.834 13.889-23.456 2.063 17.06 8.267 23.198 11.111 24.135-10 1.55-13.294 11.89-13.69 16.865-1.191-15.974-8.036-18.352-11.31-17.544z"
        fill="#BBDDD9"
      />
      <Path fill="#FEF9F9" d="M26 69.4766H126V187.0016H26z" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.168 140.81c0-.708.572-1.283 1.279-1.283h50.128a1.28 1.28 0 011.278 1.283 1.28 1.28 0 01-1.278 1.283H51.447a1.28 1.28 0 01-1.279-1.283zM50.168 153.642c0-.708.572-1.283 1.279-1.283h50.128a1.28 1.28 0 011.278 1.283 1.28 1.28 0 01-1.278 1.283H51.447a1.28 1.28 0 01-1.279-1.283z"
        fill="#8A98AF"
      />
      <Path
        d="M75.744 129.522c-4.502-8.211-16.965-19.416-22.635-23.992 6.24 0 17.178 9.067 21.867 13.6 7.878-14.78 22.464-27.03 28.773-31.306-8.9 9.648-22.379 31.819-28.005 41.698z"
        fill="#97B61B"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.926 76.404h63.811c-.043-1.24-.691-4.337-2.942-6.8-2.357-2.58-4.983-3.359-8.404-4.373-.663-.197-1.356-.402-2.082-.631l-.594-.187c-4.047-1.268-5.805-1.82-8.613-6.228C83.162 53.566 81.5 51 76.512 51c-4.988 0-7.034 2.438-8.44 5.645-.264.6-.478 1.205-.688 1.801-.916 2.588-1.772 5.007-6.346 6.154-.67.168-1.374.323-2.1.483-5.365 1.183-11.872 2.618-14.012 11.32zM76.767 62.29a3.97 3.97 0 003.964-3.978 3.97 3.97 0 00-3.964-3.977 3.97 3.97 0 00-3.964 3.977 3.97 3.97 0 003.964 3.978z"
        fill="#8A98AF"
      />
      <Path
        d="M206.111 101.192c25.124-7.827 40.979-8.943 45.766-8.522 5.176 8.585 4.156 20.411 2.998 25.25-18.937 2.209-20.989 5.05-25.565 7.733-4.577 2.683-12.31 11.52-16.413 16.097-3.282 3.661-16.202 8.574-22.251 10.573 1.63 1.052 4.923 3.851 5.05 6.628.158 3.472-1.105 3.314-3.157 2.052-1.641-1.01-7.838-3.472-10.731-4.577l-4.892 1.894c3.314 2.683 6.313 7.259 5.366 10.416-.947 3.156-3.157.315-4.735-.947-1.262-1.01-6.628-4.524-9.153-6.155-5.471 2.209-17.233 6.975-20.515 8.364-4.104 1.736-4.893-1.105-3.946-6.786.758-4.545 8.838-9.784 12.783-11.836-.894-.684-2.777-2.683-3.156-5.208-.379-2.524 1.946-9.573 3.156-12.782-1.262-1.263-3.314-2.367-19.569-1.421-16.254.947-27.775-3.945-28.564-5.681-.789-1.736-1.262-3.787 1.894-3.63 3.156.158 9.784 0 30.458-2.209 20.673-2.209 33.772-9.469 65.176-19.253z"
        fill="#B6735A"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M156.914 133.058a.632.632 0 01.89-.072c.435.37 1.23.796 2.446 1.177 1.207.377 2.793.699 4.779.885 2.475.232 5.822.117 8.912-.002l.364-.014c1.403-.054 2.739-.106 3.884-.12 1.228-.015 2.293.013 2.994.144l.442.083.066.444c.119.789-.284 1.971-1.386 3.318-1.127 1.376-3.052 3.015-6.193 4.746-3.889 2.143-7.814 3.966-10.86 5.311-1.378.609-2.579 1.12-3.514 1.519l-.285.121c-.487.208-.886.379-1.188.512a7.102 7.102 0 00-.545.258.631.631 0 01-.65-1.083 7.55 7.55 0 01.684-.329c.311-.138.718-.312 1.203-.519l.286-.122a274.81 274.81 0 003.499-1.512c3.03-1.338 6.917-3.144 10.761-5.262 3.045-1.678 4.828-3.222 5.825-4.439.674-.824.961-1.462 1.067-1.876-.555-.048-1.297-.062-2.191-.051-1.127.013-2.447.064-3.854.118l-.36.014c-3.066.118-6.504.239-9.079-.002-2.054-.193-3.73-.528-5.037-.937-1.298-.406-2.271-.896-2.888-1.42a.632.632 0 01-.072-.89z"
        fill="#7C5142"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M167.558 135.206c.347.035.6.344.566.691a164.44 164.44 0 01-.492 4.31c-.201 1.564-.429 3.097-.632 3.842-.142.519-.314 1.012-.458 1.425-.054.156-.105.3-.148.431a4.73 4.73 0 00-.168.597c-.028.147-.018.2-.019.2l-.001-.002a.631.631 0 11-1.209.363c-.083-.276-.056-.562-.012-.793.045-.242.124-.503.211-.764.053-.161.11-.324.169-.491.139-.4.287-.824.417-1.298.175-.644.394-2.083.598-3.671.202-1.568.383-3.227.487-4.274a.632.632 0 01.691-.566zM174.671 135.208a.631.631 0 01.552.702c-.156 1.303-.367 4.393-.005 6.443a.631.631 0 01-1.243.219c-.396-2.242-.165-5.486-.006-6.813a.631.631 0 01.702-.551zM176.768 158.345a.631.631 0 01-.378.809l-6.943 2.525a.631.631 0 01-.432-1.186l6.944-2.525c.328-.119.69.05.809.377zM190.655 152.978a.631.631 0 01-.378.809l-6.944 2.525a.63.63 0 01-.431-1.186l6.944-2.525c.327-.119.69.05.809.377z"
        fill="#7C5142"
      />
      <Path
        d="M243.095 124.452c.972-.897 5.382-3.175 15.241-5.108 5.565-1.091 11.383-.917 11.383-.917 3.327-4.352 1.39-25.448-9.584-39.018-12.987 7.544-18.82 3.237-22.513 2.19-4.726-1.338-9.905 1.677-7.838 6.284 2.066 4.607 6.617 3.257 8.01 7.128 1.393 3.871-2.456 5.252-1.064 9.124 1.393 3.871 7.163 3.704 9.358 8.598 1.757 3.916-1.264 9.444-2.993 11.719z"
        fill="#9FBC2A"
      />
      <Ellipse
        cx={254.831}
        cy={114.042}
        rx={1.26224}
        ry={1.25465}
        transform="rotate(-114.161 254.831 114.042)"
        fill="#F2FBCD"
      />
      <Ellipse
        cx={245.159}
        cy={100.698}
        rx={1.26224}
        ry={1.25465}
        transform="rotate(-114.161 245.159 100.698)"
        fill="#F2FBCD"
      />
      <Ellipse
        cx={235.896}
        cy={86.1786}
        rx={1.26224}
        ry={1.25465}
        transform="rotate(-114.161 235.896 86.179)"
        fill="#F2FBCD"
      />
      <Ellipse
        cx={252.935}
        cy={88.9129}
        rx={1.26224}
        ry={1.25465}
        transform="rotate(-114.161 252.935 88.913)"
        fill="#F2FBCD"
      />
      <Ellipse
        cx={262.355}
        cy={97.4715}
        rx={1.26224}
        ry={1.25465}
        transform="rotate(-114.161 262.355 97.472)"
        fill="#F2FBCD"
      />
      <Ellipse
        cx={266.071}
        cy={111.639}
        rx={1.26224}
        ry={1.25465}
        transform="rotate(-114.161 266.071 111.639)"
        fill="#F2FBCD"
      />
    </Svg>
  );
};

export default TeenMedicalHistory;
