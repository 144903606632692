import { FunctionComponent } from 'react';
import Svg, { G, Circle, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

interface PlayButtonProps {
  color?: string;
  width?: number;
  height?: number;
  style?: object;
}

const PlayButton: FunctionComponent<PlayButtonProps> = ({
  color,
  width = 38,
  height = 38,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'play';
  return (
    <Svg width={width} height={height} title={titleText} aria-label={titleText} {...otherProps}>
      <G
        transform="translate(-32 -336) translate(19 322) translate(14 15)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Circle stroke={color || colors.white} strokeWidth={2} cx={18} cy={18} r={18} />
        <Path
          d="M17.712 12.863c.76-1.08 1.998-1.073 2.754 0l5.79 8.212c1.272 1.806.522 3.27-1.69 3.27H13.612c-2.206 0-2.963-1.463-1.69-3.27l5.79-8.212z"
          fill={color || colors.white}
          transform="rotate(90 19.09 18.2)"
        />
      </G>
    </Svg>
  );
};

export default PlayButton;
