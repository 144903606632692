import useMutationCancelBooking from 'inRoomScheduling/hooks/useMutationCancelBooking';
import { CANCELLATION_WINDOW_HOURS } from 'inRoomScheduling/utils/constants';
import isBookingBetweenNowAndNHoursLater from 'inRoomScheduling/utils/isBookingBetweenNowAndNHoursLater';
import { shuffleArray } from '@talkspace/react-toolkit';
import { ClientCancellationReasonEnum, ClientCancellationReasonOption } from 'ts-frontend/types';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import { useHistory, useParams } from '@/core/routerLib';
import { getUserData } from '@/auth/helpers/token';

import useQueryClientBooking from '../../hooks/useQueryClientBooking';
import CancelSessionSelectReason from '../components/CancelSessionSelectReason';
import formatBookingDate from '../utils/formatBookingDate';

const cancellationReasonOptions: ClientCancellationReasonOption[] = [
  ...shuffleArray([
    {
      value: ClientCancellationReasonEnum.CLIENT_NOT_AVAILABLE,
      label: 'I can no longer meet at this time',
    },
    {
      value: ClientCancellationReasonEnum.PROVIDER_NOT_AVAILABLE,
      label: 'My provider can no longer meet at this time',
    },
    {
      value: ClientCancellationReasonEnum.PROVIDER_NOT_RIGHT_FIT,
      label: 'I do not think my provider is the right fit',
    },
  ]),
  { value: ClientCancellationReasonEnum.OTHER, label: 'Other' },
];

const CancelSessionSelectReasonContainer = () => {
  const history = useHistory();
  const { id: clientUserID } = getUserData();
  const { roomID, bookingID } = useParams<{ roomID: string; bookingID: string }>();
  const { data: businessLineData, isLoading: isLoadingBusinessLine } = useQueryBusinessLine(
    roomID,
    clientUserID
  );
  const { isEAP } = businessLineData || {};
  const { data: booking, isLoading: isLoadingClientBooking } = useQueryClientBooking({
    roomID,
    bookingID,
  });

  const { mutate: cancelBooking, error: cancellationError } = useMutationCancelBooking();
  const formattedStartTime = booking && formatBookingDate(booking);
  const isBookingTooSoon =
    booking && isBookingBetweenNowAndNHoursLater(booking, CANCELLATION_WINDOW_HOURS);
  const isBookingConfirmed = booking?.timekitBookingState === 'confirmed';
  const isLoading = isLoadingBusinessLine || isLoadingClientBooking;
  return (
    <CancelSessionSelectReason
      options={cancellationReasonOptions}
      isLoading={isLoading}
      isEAP={isEAP}
      formattedStartTime={formattedStartTime}
      isBookingTooSoon={isBookingTooSoon}
      isBookingConfirmed={isBookingConfirmed}
      error={cancellationError}
      onPressCancelSession={(cancellationReason, cancellationReasonOtherText) => () => {
        cancelBooking(
          {
            roomID,
            bookingID,
            data: {
              shouldRedeemCredit: !!isBookingTooSoon && isBookingConfirmed,
              reason: cancellationReason,
              ...(cancellationReason === ClientCancellationReasonEnum.OTHER
                ? { metadata: { cancellationReason: cancellationReasonOtherText } }
                : {}),
            },
          },
          {
            onSuccess: () => {
              history.push(
                `/room/${roomID}/session-details/${bookingID}/cancel-session/confirmation`,
                { cancellationReason }
              );
            },
          }
        );
      }}
    />
  );
};

export default CancelSessionSelectReasonContainer;
