import { State } from '../reducers/npsWizardState';
import ApiHelper from '../../utils/ApiHelper';
import { OtherWizardGenericActions } from '../../utils/genericWizardActions';

const initState = async (api, state) => {
  const ratingPostAction = (rating: number, context) => {
    const partialState: Partial<State> = {};
    partialState.npsData = {
      rating,
    };
    context.setState(partialState);
  };

  const updatedResponses = {
    ...state.responses,
  };
  return {
    ratingPostAction,
    responses: updatedResponses,
  };
};

const persist = async (api, state) => {
  if (state.userPromptID) {
    await api.updateUserPromptToDone(state.roomID, state.userPromptID);
  }
};

const npsWizardActions: OtherWizardGenericActions<State, ApiHelper> = {
  initState,
  persist,
};

export default npsWizardActions;
