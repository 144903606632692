import { EmotionStyle, TextDS, View, styled } from '@talkspace/react-toolkit';

const UserPostText = styled(TextDS)<{ index }>(({ index, theme: { spacing } }) => {
  return {
    marginTop: index > 0 ? spacing('space050') : undefined,
  };
});

interface Props {
  post: Amity.Post;
  style?: EmotionStyle;
}
const PostText = ({ post, style }: Props) => {
  const {
    data: { text },
  } = post;
  return (
    <View style={style}>
      {text.split('\n').map((line, i) => (
        <UserPostText
          index={i}
          // eslint-disable-next-line react/no-array-index-key
          key={`line-${i}`}
        >
          {line}
        </UserPostText>
      ))}
    </View>
  );
};
export default PostText;
