import React from 'react';

import { useEmotionTheme, View, spacing } from '@talkspace/react-toolkit';

const { space150 } = spacing;

interface Props {
  children: React.ReactElement;
}
const CommentBubble = ({ children }: Props) => {
  const { colorRoles } = useEmotionTheme();
  return (
    <View
      style={{
        backgroundColor: colorRoles.surfaces.decorativeNeutral400,
        borderRadius: space150,
        padding: space150,
        width: 'fit-content',
      }}
    >
      {children}
    </View>
  );
};

export default CommentBubble;
