import { FunctionComponent } from 'react';
import {
  useUniqueID,
  View,
  Modal,
  TouchableView,
  Large,
  CloseButton,
  Image,
} from '@talkspace/react-toolkit';

import styled from '../../core/styled';

const Container = styled(TouchableView)(({ theme: { window, colors } }) => {
  const { isMobile } = window;
  if (isMobile)
    return {
      backgroundColor: colors.white,
      height: '100%',
      width: '100%',
    };

  return {
    backgroundColor: colors.white,
    minHeight: 380,
    width: 500,
    borderRadius: 10,
  };
});

const Message = styled(Large)(({ theme: { window } }) => {
  const isResponsive = window.isSmall || window.isMedium;
  return {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: 20,
    paddingLeft: isResponsive ? 25 : 85,
    paddingRight: isResponsive ? 25 : 85,
  };
});

interface Props {
  buttonText?: string;
  description: string;
  iconURL?: string;
  onClosePress: Function;
  onButtonPress: Function;
}

const GenericModal: FunctionComponent<Props> = ({
  onButtonPress,
  onClosePress,
  description,
  iconURL,
  buttonText = 'Continue',
}) => {
  const ID_MODAL_DESCRIPTION = useUniqueID('ID_MODAL_DESCRIPTION');
  return (
    <Modal isVisible onBackdropPress={() => onClosePress()} titleID={ID_MODAL_DESCRIPTION}>
      <Container tabIndex={-1}>
        <View row justify="end" style={{ padding: 16 }}>
          <CloseButton onPress={() => onClosePress()} />
        </View>
        <View align="center" justify="center" style={{ paddingBottom: 20 }}>
          {iconURL && <Image alt="" src={iconURL} style={{ zoom: 0.5 }} />}
          <Message id={ID_MODAL_DESCRIPTION} variant="largeDarkGrey">
            {description}
          </Message>
          <TouchableView onPress={() => onButtonPress()} style={{ padding: 30 }}>
            <Large variant="largeBoldWideGreen">{buttonText}</Large>
          </TouchableView>
        </View>
      </Container>
    </Modal>
  );
};

export default GenericModal;
