import { RoomStatus } from 'ts-frontend/entities/Room';

export const PENDING_PLANS = [RoomStatus.PENDING_SUBSCRIPTION, RoomStatus.MBA];

export const ACTIVE_PLANS = [
  RoomStatus.ACTIVE,
  RoomStatus.FREE_TRIAL,
  RoomStatus.NOT_RENEWING,
  RoomStatus.PAST_DUE,
  RoomStatus.FROZEN,
  RoomStatus.FREE_TRIAL_CHATBOT,
];

export const INACTIVE_PLANS = [RoomStatus.CLOSED, RoomStatus.CANCELED];

export const isPendingPlan = (status: RoomStatus) => PENDING_PLANS.includes(status);

export const isActivePlan = (status: RoomStatus) => ACTIVE_PLANS.includes(status);

export const isInactivePlan = (status: RoomStatus) => INACTIVE_PLANS.includes(status);
