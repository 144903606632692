import BookingsList from '../components/BookingsList';
import { UseNavShellData } from '../../hooks/useNavShellData';

interface Props extends Pick<UseNavShellData, 'activeSessionData' | 'bookings' | 'dataByRoomID'> {
  isLoading: boolean;
}
const BookingsListController = ({
  activeSessionData,
  bookings,
  dataByRoomID,
  isLoading,
}: Props) => (
  <BookingsList
    activeSessionData={activeSessionData}
    bookings={bookings}
    dataByRoomID={dataByRoomID}
    isLoading={isLoading}
  />
);

export default BookingsListController;
