/* eslint-disable react-hooks/exhaustive-deps */
import { CognitoUser } from '@aws-amplify/auth';
import finishForgotPassword from '@talkspace/auth/finishForgotPassword';
import initiateForgotPassword from '@talkspace/auth/initiateForgotPassword';
import verifyAttribute from '@talkspace/auth/verifyAttribute';
import verifyAttributeCode from '@talkspace/auth/verifyAttributeCode';
import confirmSignIn from '@talkspace/auth/confirmSignIn';
import { SMS_MFA, AuthResultCode } from '@talkspace/auth/types';
import register from '@talkspace/auth/register';
import signIn from '@talkspace/auth/signIn';
import signOut from '@talkspace/auth/signOut';
import React, {
  useReducer,
  createContext,
  useCallback,
  useContext,
  useRef,
  useEffect,
} from 'react';
import {
  isPhoneNumberValid,
  parseCountryCallingCode,
  useA11yActions,
} from '@talkspace/react-toolkit';
import { routePromise, sleep } from 'ts-frontend/helpers';
import formatPhoneNumber from 'ts-frontend/utils/phoneNumbers';
import Base64 from 'ts-frontend/utils/Base64';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import {
  trackClient2FA,
  trackClient2FAVerification,
} from '../TwoFactorAuthentication/utils/analytics';
import ApiHelper, { RegistrationMessage } from '../utils/ApiHelper';
import {
  getAccessToken,
  getUserData,
  setTokens,
  storeBypassTwoFactorToken,
} from '../auth/helpers/token';
import { revokeToken, deleteDeviceToken } from '../auth/auth';

import {
  ClientAuthState,
  clientAuthReducer,
  ClientAuthActionTypes,
  clientAuthInitialState,
  VerifyResponse,
  TwoFAStatus,
} from '../reducers/clientAuthReducer';
import { setTrackerUserID } from '../utils/analytics/eventTracker';
import {
  AnalyticsData,
  trackRegisterUserEvent,
  trackAliasUserEvent,
  trackUserLogin,
  setPeopleLogin,
  trackUserSuccessLogin,
} from '../utils/analytics/events';
import { processInvitation } from '../roomInvites';
import AdminConfigAPI, { AdminConfig } from '../utils/adminConfig';
import { AdminConfigs } from '../reducers/mainReducer';
import {
  getClient2FA,
  patchEnable2FA,
  patchPhoneNumber2FA,
} from '../login/utils/twoFactorAuthenticationApiHelper';
import sessionStorage from '../core/storage/sessionStorage';
import storage, { StoragePersistanceMode } from '../core/storage';
import {
  IS_ONBOARDING_DISMISSED_STORAGE_KEY,
  IS_ONBOARDING_DISMISSED_TEST_ACCOUNT_STORAGE_KEY,
} from '../onboarding/util/onboardingStorage';
import { ELIGIBILITY_WARNING_DISMISSED_STORAGE_KEY } from '../EligibilityWarning/hooks/useEligibilityWarning';
import { LOCAL_STORAGE_TEENSPACE_COMMUNITY } from '../community/constants';

export const ClientAuthStateContext = createContext<ClientAuthState | undefined>(undefined);

interface LoginPayload {
  email: string;
  password: string;
  options?: {
    invitationKey?: string;
  };
}

interface LoginSsoUser {
  accessToken: string;
  idToken: string;
  tokenType: string;
  expiresIn: number;
  qmSessionID?: string;
}

interface LoginAetnaSsoUser {
  loginHint?: string;
  code?: string;
}

interface LoginOauth2Payload {
  email: string;
  password: string;
}

interface ForgotPasswordPayload {
  email: string;
}

interface ChangePasswordPayload {
  password: string;
  confirmedPassword: string;
}

interface ChangePasswordCognitoPayload {
  code: string;
  email: string;
  password: string;
}

interface VerifyPayload {
  otpKey: number;
  rememberDevice?: boolean;
  otpToken: string;
}

/* Actions Block */
const loadProviderInfoCreator =
  (dispatch: React.Dispatch<ClientAuthActionTypes>, api: ApiHelper) => (slugName: string) => {
    dispatch({ type: 'loadProvider' });
    api
      .getTherapistBySlugName(slugName)
      .then((therapistInfo) => {
        dispatch({
          type: 'loadProviderSuccess',
          payload: { providerInfo: therapistInfo },
        });
      })
      // eslint-disable-next-line no-console
      .catch(console.error);
  };

const loginActionCreator =
  (dispatch: React.Dispatch<ClientAuthActionTypes>, api: ApiHelper) => (payload: LoginPayload) => {
    const { email, password } = payload;
    dispatch({ type: 'postLogIn' });
    return api
      .postLoginClient2FA(email, password)
      .then(async (res) => {
        const {
          userID,
          access,
          refresh,
          redirectTo,
          twoFAStatus,
          otpToken,
          phoneNumberLast2Digits,
          ...otherTokenData
        } = res;
        setTrackerUserID(userID, true);
        setPeopleLogin(userID);
        trackUserLogin();
        trackUserSuccessLogin();
        const invitationKey = payload.options && payload.options.invitationKey;
        // handle invitation - attempt to consume if parameter exists - don't fail login
        if (phoneNumberLast2Digits) {
          dispatch({
            type: 'setPhoneNumberLast2Digits',
            payload: { phoneNumberLast2Digits, showPhoneError: false },
          });
        }
        if (redirectTo) {
          dispatch({
            type: 'postLogInSuccessWithEmailVerification',
            payload: { redirectTo },
          });
        } else {
          dispatch({
            type: 'setTwoFAStatus',
            payload: { twoFAStatus },
          });

          if (!access && twoFAStatus === 'on' && !!phoneNumberLast2Digits) {
            if (invitationKey) {
              dispatch({
                type: 'setInvitationKey',
                payload: { invitationKey },
              });
            }
            dispatch({
              type: 'setOTPToken',
              payload: { otpToken, phoneNumberLast2Digits },
            });
          } else {
            setTokens({ accessToken: access, refreshToken: refresh, ...otherTokenData }, userID);
            if (invitationKey) {
              await processInvitation(invitationKey).catch((err) => {
                // eslint-disable-next-line no-console
                console.error('invitation key not valid', err);
              });
            }
          }

          // give time for trackers to report login
          setTimeout(() => {
            dispatch({
              type: 'postLogInSuccess',
            });
          }, 1000);
        }
      })
      .catch((error) => {
        dispatch({ type: 'setIsError', error: String(error.status) });
      });
  };

const verifyLoginActionCreator =
  (dispatch: React.Dispatch<ClientAuthActionTypes>, api: ApiHelper, invitationKey: string) =>
  async ({ otpKey, otpToken }: VerifyPayload) => {
    try {
      dispatch({ type: 'request2faVerify' });
      const res = await api.postVerify2FA({ otpKey, otpToken, rememberDevice: true });

      if (res !== 204) {
        trackClient2FA('2FA authentication success');
        const { bypassTwoFactorToken, access, refresh, userID, ...otherTokenData } =
          res as VerifyResponse;
        setTokens({ accessToken: access, refreshToken: refresh, ...otherTokenData }, userID);
        storeBypassTwoFactorToken(bypassTwoFactorToken);
        if (invitationKey) {
          await processInvitation(invitationKey).catch((err) => {
            // eslint-disable-next-line no-console
            console.error('invitation key not valid', err);
          });
        }
      } else {
        trackClient2FAVerification('2FA verification enable success');
      }
      dispatch({
        type: 'receivedValidVerificationCode',
        payload: { enabled2faSuccess: true, showVerificationCodeError: false },
      });
    } catch (error) {
      const { id } = getUserData();
      if (id) {
        trackClient2FAVerification('2FA verification enable failed');
      } else {
        trackClient2FA('2FA authentication failed');
      }
      const { status } = error;
      if ([401, 400].includes(status)) {
        dispatch({
          type: 'receivedInvalidVerificationCode',
          payload: { showVerificationCodeError: true },
        });
      } else if (status === 404) {
        dispatch({
          type: 'setCodeExpiredError',
          payload: { showCodeExpiredError: true },
        });
      } else {
        dispatch({
          type: 'setIsError',
          error: 'Check your internet connection and try again.',
        });
      }
    }
  };

const get2FAStatusActionCreator =
  (dispatch: React.Dispatch<ClientAuthActionTypes>, api: ApiHelper) => async () => {
    try {
      const { status: twoFAStatus, phoneNumber } = await api.get2FAStatus();
      const phoneNumberLast2Digits = phoneNumber && phoneNumber.slice(-2);
      dispatch({
        type: 'getTwoFAStatusAndPhone',
        payload: { twoFAStatus, phoneNumberLast2Digits },
      });
    } catch (error) {
      dispatch({ type: 'setIsError', error: 'Error getting 2fa status' });
    }
  };

const loginCognitoActionCreator =
  (
    dispatch: React.Dispatch<ClientAuthActionTypes>,
    api: ApiHelper,
    cognitoUserRef?: React.MutableRefObject<CognitoUser | undefined>
  ) =>
  async (payload: LoginPayload) => {
    const { email, password } = payload;

    dispatch({ type: 'postLogIn' });

    try {
      const cognitoUser = await signIn({
        email,
        password,
      });

      if (cognitoUser.result === AuthResultCode.NO_MATCH) {
        dispatch({ type: 'setIsError', error: '401' });

        return;
      }

      if (cognitoUser.result === AuthResultCode.TOO_MANY_ATTEMPTS) {
        dispatch({ type: 'setIsError', error: '429' });

        return;
      }

      if (cognitoUser.result === AuthResultCode.RESET_REQUIRED) {
        dispatch({ type: 'setIsError', error: '400' });
        routePromise('/reset-password');
        return;
      }

      if (cognitoUser.is2FA && cognitoUser.userObject && cognitoUserRef) {
        dispatch({
          type: 'postLogInSuccessWithCognito',
          payload: {
            loggedInWith2fa: !!cognitoUser.is2FA,
            email,
            password,
          },
        });
        // eslint-disable-next-line no-param-reassign
        cognitoUserRef.current = cognitoUser.userObject;
        return;
      }

      if (cognitoUser.result !== AuthResultCode.SUCCESS || !cognitoUser.idToken) {
        dispatch({ type: 'setIsError', error: 'could not sign in' });

        return;
      }

      const { userID, access, refresh, redirectTo, ...otherTokenData } =
        await api.exchangeCognitoTokenForTalkspaceToken(cognitoUser.idToken);

      setTokens({ accessToken: access, refreshToken: refresh, ...otherTokenData }, userID);

      // handle invitation - attempt to consume if parameter exists - don't fail login
      if (payload.options && payload.options.invitationKey) {
        await processInvitation(payload.options.invitationKey).catch((err) => {
          // eslint-disable-next-line no-console
          console.error('invitation key not valid', err);
        });
      }

      const { phoneNumber, phoneNumberCountryCode, disable2FA } = await getClient2FA(userID);
      const parsedCountryCode = parseCountryCallingCode(phoneNumberCountryCode || 'US');
      const formattedPhoneNumber = parsedCountryCode + phoneNumber;
      dispatch({
        type: 'receiveGetClient2faStatus',
        payload: {
          has2FA: !disable2FA,
          ...(phoneNumber &&
            phoneNumberCountryCode && {
              hasPhoneNumber: true,
              phoneNumber: formattedPhoneNumber,
              phoneNumberCountryCode,
            }),
        },
      });

      setTrackerUserID(userID, true);
      setPeopleLogin(userID);
      trackUserLogin();
      // give time for trackers to report login
      await sleep(1000);

      dispatch({ type: 'postLogInSuccess' });
    } catch (error) {
      dispatch({ type: 'setIsError', error: error as Error });
    }
  };

const loginSSOActionCreator =
  (dispatch: React.Dispatch<ClientAuthActionTypes>, api: ApiHelper) =>
  async (payload: LoginSsoUser) => {
    dispatch({ type: 'postLogIn' });

    try {
      const { userID, access, refresh, redirectTo, ...otherTokenData } =
        await api.exchangeCognitoTokenForTalkspaceToken(payload.idToken, payload.qmSessionID);
      setTrackerUserID(userID, true);
      setPeopleLogin(userID);
      trackUserLogin();

      setTokens({ accessToken: access, refreshToken: refresh, ...otherTokenData }, userID);

      // give time for trackers to report login
      await sleep(100);

      dispatch({
        type: 'postLogInSuccess',
      });
    } catch (error) {
      dispatch({ type: 'setIsErrorLoginSSOAction', error });
    }
  };

const loginAetnaSSOActionCreator =
  (dispatch: React.Dispatch<ClientAuthActionTypes>, api: ApiHelper) =>
  async (payload: LoginAetnaSsoUser) => {
    dispatch({ type: 'postLogIn' });
    let res;
    try {
      if (payload.loginHint) {
        res = await api.loginToAetnaSSOWithHint(payload.loginHint);

        const { userID, access, refresh, redirectTo, ...otherTokenData } = res;

        setTrackerUserID(userID, true);
        setPeopleLogin(userID);
        trackUserLogin();

        setTokens({ accessToken: access, refreshToken: refresh, ...otherTokenData }, userID);
      }

      // give time for trackers to report login
      await sleep(100);

      dispatch({
        type: 'postLogInSuccess',
      });
    } catch (error) {
      dispatch({ type: 'setIsError', error });
    }
    return res;
  };

const loginOauth2ActionCreator =
  (dispatch: React.Dispatch<ClientAuthActionTypes>, api: ApiHelper) =>
  (payload: LoginOauth2Payload) => {
    const { email, password } = payload;
    dispatch({ type: 'postLogIn' });
    return api
      .postOauth2LoginClient(email, password)
      .then(({ redirect }) => {
        if (redirect) {
          dispatch({
            type: 'postLogInSuccessWithOauth',
            payload: { redirectTo: redirect },
          });
        }
      })
      .catch((error) => {
        dispatch({ type: 'setIsError', error });
      });
  };

const registerActionCreator =
  (
    dispatch: React.Dispatch<ClientAuthActionTypes>,
    api: ApiHelper,
    isCognitoLoginEnabled?: boolean
  ) =>
  async (registrationMessage: RegistrationMessage) => {
    dispatch({ type: 'postRegister' });
    try {
      let registerFn;
      if (isCognitoLoginEnabled) {
        const { userDetails, ...regMessageRest } = registrationMessage;
        const { password, ...userDetailsRest } = userDetails;
        registerFn = api.cognitoPostRegister({ userDetails: userDetailsRest, ...regMessageRest });
      } else {
        registerFn = api.postRegister(registrationMessage);
      }
      const { redirectURL, authToken, userID } = await registerFn;
      // No auth token means users email is already registered
      if (isCognitoLoginEnabled && authToken) {
        const { phoneNumber, phoneNumberCountryCode } = registrationMessage.userDetails;
        const cognitoPhoneNumber = phoneNumber
          ? formatPhoneNumber(phoneNumber, phoneNumberCountryCode)
          : undefined;
        await register({
          email: registrationMessage.userDetails.email,
          password: registrationMessage.userDetails.password,
          phoneNumber: cognitoPhoneNumber,
          authToken,
        });
      }
      const { origin, pathname, search, hash } = new URL(redirectURL);
      const urlParams = new URLSearchParams(hash.replace('#', '?'));
      const analyticsDataURLParam = urlParams.get('analytics');
      urlParams.delete('analytics');

      let delay = 0;
      if (userID) {
        setTrackerUserID(String(userID));
        // Alias change shouldn't be necessary with ID Merge enabled
        // https://help.mixpanel.com/hc/en-us/articles/360039133851#enable-id-merge
        trackAliasUserEvent(String(userID));
        setPeopleLogin(userID);
        delay = 2000;
      }
      if (analyticsDataURLParam) {
        try {
          const { sendToTrackers } = JSON.parse(
            Base64.atob(analyticsDataURLParam)
          ) as AnalyticsData;

          trackRegisterUserEvent(sendToTrackers);
          delay = 2000;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn('parsed sign-up & alias event failed');
        }
      }

      const urlHashParamsString = urlParams.toString() && `#${urlParams.toString()}`;

      // give time for trackers to report register
      await sleep(delay);

      await routePromise(`${origin}${pathname}${search}${urlHashParamsString}`);
    } catch (error) {
      dispatch({
        type: 'setIsError',
        error: error as Error,
      });
    }
  };

const logoutActionCreator =
  (
    dispatch: React.Dispatch<ClientAuthActionTypes>,
    api: ApiHelper,
    clearSessionStorage: () => void
  ) =>
  (gotoAfterLogout?: string) => {
    dispatch({ type: 'postLogOut' });
    api.forgetDevice();
    deleteDeviceToken();
    clearSessionStorage();
    revokeToken(gotoAfterLogout);
    signOut();
  };

const forgotPasswordActionCreator =
  (dispatch: React.Dispatch<ClientAuthActionTypes>, api: ApiHelper) =>
  (payload: ForgotPasswordPayload) => {
    const { email } = payload;
    dispatch({ type: 'postForgotPassword' });
    return api
      .postForgotPassword(email)
      .then(() => {
        dispatch({ type: 'postForgotPasswordSuccess' });
      })
      .catch(api.dismissIfCancelled)
      .catch((error) => {
        dispatch({ type: 'setIsError', error });
      });
  };

const forgotPasswordCognitoActionCreator =
  (dispatch: React.Dispatch<ClientAuthActionTypes>, api: ApiHelper) =>
  async (payload: ForgotPasswordPayload) => {
    const { email } = payload;
    dispatch({ type: 'postForgotPassword' });

    try {
      await initiateForgotPassword({ email });

      dispatch({ type: 'postForgotPasswordSuccess' });
    } catch (e) {
      const error = e as string | Error;

      dispatch({ type: 'setIsError', error });
    }
  };

const changePasswordActionCreator =
  (dispatch: React.Dispatch<ClientAuthActionTypes>, api: ApiHelper) =>
  (payload: ChangePasswordPayload) => {
    const { password, confirmedPassword } = payload;
    dispatch({ type: 'postChangePassword' });
    const { id } = getUserData();

    return api
      .postChangePassword(id, password, confirmedPassword)
      .then(({ email, redirectLink }) => {
        storage.setStoragePersistanceMode(StoragePersistanceMode.SESSION);
        if (redirectLink) {
          window.location.replace(redirectLink);
        } else {
          loginActionCreator(dispatch, api)({ email, password });
        }
      })
      .catch((error) => {
        dispatch({ type: 'setIsError', error });
      });
  };

const changePasswordCognitoActionCreator =
  (dispatch: React.Dispatch<ClientAuthActionTypes>, api: ApiHelper) =>
  async ({ code, email, password }: ChangePasswordCognitoPayload) => {
    dispatch({ type: 'postChangePassword' });

    try {
      await finishForgotPassword({
        code,
        email,
        password,
      });

      await loginCognitoActionCreator(dispatch, api)({ email, password });
    } catch (e) {
      const error = e as string | Error;

      dispatch({ type: 'setIsError', error });
    }
  };

const clearErrorsActionCreator = (dispatch) => () => {
  dispatch({ type: 'clearErrors' });
};

const getAdminConfigOptionActionCreator =
  (dispatch) =>
  (optionName: AdminConfig): Promise<null> => {
    dispatch({ type: 'getAdminConfigOptions' });
    return AdminConfigAPI.getPublicAdminOptionByName(optionName).then((response) => {
      if (response.data) {
        const { data: optionValue } = response.data;
        let keyName: keyof AdminConfigs;
        let keyValue: Required<AdminConfigs>[keyof AdminConfigs];
        switch (optionName) {
          case 'cognito_user_migration':
            keyName = 'isCognitoLoginEnabled';
            keyValue = Boolean(optionValue) as Required<AdminConfigs>['isCognitoLoginEnabled'];
            break;
          default:
            // eslint-disable-next-line no-console
            console.error('Invalid admin config', optionName);
            return null;
        }
        dispatch({
          type: 'receiveAdminConfigOptions',
          payload: {
            adminConfigs: { [keyName]: keyValue },
          },
        });
      }
      return null;
    });
  };

const update2faReminderAndRedirect = () => {
  if (ReactFrameService.instance().isInFrame()) {
    ReactFrameService.instance().closePopup();
  } else {
    routePromise('/login-success');
  }
};

const update2faReminderActionCreator = (dispatch: React.Dispatch<ClientAuthActionTypes>) => () => {
  dispatch({ type: 'set2faReminder' });
  update2faReminderAndRedirect();
};

const getShouldOpen2faVerifyActionCreator = (twoFAStatus?: TwoFAStatus, otpToken?: string) => () =>
  twoFAStatus === 'on' && otpToken;

const getShouldOpen2faReminderActionCreator = (twoFAStatus?: TwoFAStatus) => () =>
  twoFAStatus === 'required' || twoFAStatus === 'suggested';

const clearVerificationCodeErrorActionCreator =
  (dispatch: React.Dispatch<ClientAuthActionTypes>) => () => {
    dispatch({
      type: 'setVerificationCodeError',
      payload: { showVerificationCodeError: false },
    });
  };

const setExpiredCodeErrorActionCreator =
  (dispatch: React.Dispatch<ClientAuthActionTypes>) => (showCodeExpiredError: boolean) => {
    dispatch({
      type: 'setCodeExpiredError',
      payload: { showCodeExpiredError },
    });
    dispatch({
      type: 'setVerificationCodeError',
      payload: { showVerificationCodeError: false },
    });
  };

const resendVerificationCodeActionCreator =
  (dispatch: React.Dispatch<ClientAuthActionTypes>) => async () => {
    try {
      dispatch({ type: 'requestVerifyAttributeCode' });
      await verifyAttribute('phone_number');
      dispatch({ type: 'requestVerifyAttributeCodeSuccess' });
    } catch {
      dispatch({ type: 'setIsError', error: 'Error while resending SMS code.' });
    }
  };

const setErrorActionCreator =
  (dispatch: React.Dispatch<ClientAuthActionTypes>) => async (error: string) => {
    dispatch({ type: 'setIsError', error });
  };

const changePhoneNumberActionCreator =
  (dispatch: React.Dispatch<ClientAuthActionTypes>) => (phoneNumber: string) => {
    dispatch({ type: 'setPhoneNumber', payload: { phoneNumber, showPhoneError: false } });
  };

const changePhoneNumberCountryCodeActionCreator =
  (dispatch: React.Dispatch<ClientAuthActionTypes>) => (phoneNumberCountryCode: string) =>
    dispatch({
      type: 'setPhoneNumberCountryCode',
      payload: { phoneNumberCountryCode, showPhoneError: false },
    });

const onPressContinueActionCreator =
  (
    dispatch: React.Dispatch<ClientAuthActionTypes>,
    phoneNumber: string,
    phoneNumberCountryCode: string,
    hasPhoneNumber: boolean
  ) =>
  async () => {
    if (!phoneNumber || !isPhoneNumberValid(phoneNumber)) {
      dispatch({ type: 'setPhoneNumberError', payload: { showPhoneError: true } });
    } else {
      try {
        dispatch({ type: 'request2faCode' });
        if (!hasPhoneNumber) {
          await patchPhoneNumber2FA(getUserData().id, phoneNumber, phoneNumberCountryCode);
        }
        await verifyAttribute('phone_number');
        dispatch({
          type: 'request2faCodeSuccess',
          payload: { request2faCodeSuccess: true },
        });
      } catch (err) {
        dispatch({ type: 'setIsError', error: 'Error while sending SMS code.' });
      }
    }
  };

const validateVerificationCodeActionCreator =
  (
    dispatch: React.Dispatch<ClientAuthActionTypes>,
    api: ApiHelper,
    cognitoUserRef?: React.MutableRefObject<CognitoUser | undefined>
  ) =>
  async (code: string) => {
    dispatch({ type: 'requestValidateVerificationCode' });
    try {
      if (!getAccessToken() && cognitoUserRef && cognitoUserRef.current) {
        const loggedInCognitoUser = await confirmSignIn({
          cognitoUser: cognitoUserRef.current,
          code,
          mfaType: SMS_MFA,
        });

        if (loggedInCognitoUser.result === AuthResultCode.CODE_MISMATCH) {
          dispatch({
            type: 'receivedInvalidVerificationCode',
            payload: { showVerificationCodeError: true },
          });
          return;
        }

        if (loggedInCognitoUser.result !== AuthResultCode.SUCCESS || !loggedInCognitoUser.idToken) {
          dispatch({ type: 'setIsError', error: 'could not sign in' });
          return;
        }

        const { userID, access, refresh, redirectTo, ...otherTokenData } =
          await api.exchangeCognitoTokenForTalkspaceToken(loggedInCognitoUser.idToken);

        setTokens({ accessToken: access, refreshToken: refresh, ...otherTokenData }, userID);
      } else {
        const isCodeConfirmed = await verifyAttributeCode('phone_number', code);
        if (!isCodeConfirmed) {
          dispatch({
            type: 'receivedInvalidVerificationCode',
            payload: { showVerificationCodeError: true },
          });
          return;
        }
        await patchEnable2FA(getUserData().id, false);
      }
      dispatch({
        type: 'receivedValidVerificationCode',
        payload: { enabled2faSuccess: true, showVerificationCodeError: false },
      });
    } catch {
      dispatch({ type: 'setIsError', error: 'Error while verifying SMS code.' });
    }
  };

const setOTPTokenActionCreator =
  (dispatch: React.Dispatch<ClientAuthActionTypes>) => (otpToken: string, phoneNumber: string) => {
    const phoneNumberLast2Digits = phoneNumber.length > 2 ? phoneNumber.slice(-2) : phoneNumber;
    dispatch({
      type: 'setOTPToken',
      payload: { otpToken, phoneNumberLast2Digits },
    });
  };

const setTwoFAStatusActionCreator =
  (dispatch: React.Dispatch<ClientAuthActionTypes>) =>
  (twoFAStatus: TwoFAStatus, phoneNumber?: string) => {
    const phoneNumberLast2Digits = phoneNumber ? phoneNumber.slice(-2) : '';
    dispatch({
      type: 'getTwoFAStatusAndPhone',
      payload: { twoFAStatus, phoneNumberLast2Digits },
    });
  };

const clearOTPTokenActionCreator = (dispatch: React.Dispatch<ClientAuthActionTypes>) => () => {
  dispatch({
    type: 'clearOTPToken',
  });
};

/* End Actions Block */

export interface ClientAuthActions {
  loadProviderInfoAction: ReturnType<typeof loadProviderInfoCreator>;
  loginAction: ReturnType<typeof loginActionCreator>;
  loginCognitoAction: ReturnType<typeof loginCognitoActionCreator>;
  loginSSOAction: ReturnType<typeof loginSSOActionCreator>;
  loginAetnaSSOAction: ReturnType<typeof loginAetnaSSOActionCreator>;
  loginOauth2Action: ReturnType<typeof loginOauth2ActionCreator>;
  logoutAction: ReturnType<typeof logoutActionCreator>;
  registerAction: ReturnType<typeof registerActionCreator>;
  clearErrorsAction: ReturnType<typeof clearErrorsActionCreator>;
  forgotPasswordAction: ReturnType<typeof forgotPasswordActionCreator>;
  forgotPasswordCognitoAction: ReturnType<typeof forgotPasswordCognitoActionCreator>;
  changePasswordAction: ReturnType<typeof changePasswordActionCreator>;
  changePasswordCognitoAction: ReturnType<typeof changePasswordCognitoActionCreator>;
  getAdminConfigOptionAction: ReturnType<typeof getAdminConfigOptionActionCreator>;
  onPressContinueAction: ReturnType<typeof onPressContinueActionCreator>;
  changePhoneNumberAction: ReturnType<typeof changePhoneNumberActionCreator>;
  changePhoneNumberCountryCodeAction: ReturnType<typeof changePhoneNumberCountryCodeActionCreator>;
  validateVerificationCodeAction: ReturnType<typeof validateVerificationCodeActionCreator>;
  clearVerificationCodeErrorAction: ReturnType<typeof clearVerificationCodeErrorActionCreator>;
  update2faReminderAction: ReturnType<typeof update2faReminderActionCreator>;
  resendVerificationCodeAction: ReturnType<typeof resendVerificationCodeActionCreator>;
  getShouldOpen2faVerifyAction: ReturnType<typeof getShouldOpen2faVerifyActionCreator>;
  getShouldOpen2faReminderAction: ReturnType<typeof getShouldOpen2faReminderActionCreator>;
  setErrorAction: ReturnType<typeof setErrorActionCreator>;
  verifyLoginAction: ReturnType<typeof verifyLoginActionCreator>;
  setOTPTokenAction: ReturnType<typeof setOTPTokenActionCreator>;
  setTwoFAStatusAction: ReturnType<typeof setTwoFAStatusActionCreator>;
  get2FAStatusAction: ReturnType<typeof get2FAStatusActionCreator>;
  clearOTPTokenAction: ReturnType<typeof clearOTPTokenActionCreator>;
  setExpiredCodeErrorAction: ReturnType<typeof setExpiredCodeErrorActionCreator>;
}

export const ClientAuthActionsContext = createContext<ClientAuthActions | undefined>(undefined);

export function ClientAuthContextProvider({ children }) {
  const [state, dispatch] = useReducer(clientAuthReducer, clientAuthInitialState);
  const cognitoUserRef = useRef<CognitoUser>();
  const apiRef = useRef(new ApiHelper());
  const { current: api } = apiRef;

  useEffect(
    () => () => {
      apiRef.current.cancelAll();
    },
    []
  );

  const { clearContrastSessionStorage } = useA11yActions();
  const loadProviderInfoAction = loadProviderInfoCreator(dispatch, api);
  const loginAction = loginActionCreator(dispatch, api);
  const loginCognitoAction = loginCognitoActionCreator(dispatch, api, cognitoUserRef);
  const loginSSOAction = loginSSOActionCreator(dispatch, api);
  const loginAetnaSSOAction = loginAetnaSSOActionCreator(dispatch, api);
  const loginOauth2Action = loginOauth2ActionCreator(dispatch, api);
  const logoutAction = logoutActionCreator(dispatch, api, () => {
    clearContrastSessionStorage();
    sessionStorage.removeItem('sawIntroLVSReminderModal');
    sessionStorage.removeItem(IS_ONBOARDING_DISMISSED_STORAGE_KEY);
    sessionStorage.removeItem(IS_ONBOARDING_DISMISSED_TEST_ACCOUNT_STORAGE_KEY);
    sessionStorage.removeItem(ELIGIBILITY_WARNING_DISMISSED_STORAGE_KEY);
    sessionStorage.removeItem('reactivationPopupOpened');
    sessionStorage.removeItem('ctReactivationPopupOpened');
    sessionStorage.removeItem('videoSessionInfo');
    sessionStorage.removeItem(LOCAL_STORAGE_TEENSPACE_COMMUNITY);
  });
  const registerAction = registerActionCreator(
    dispatch,
    api,
    state.adminConfigs.isCognitoLoginEnabled
  );
  const clearErrorsAction = clearErrorsActionCreator(dispatch);
  const forgotPasswordAction = forgotPasswordActionCreator(dispatch, api);
  const forgotPasswordCognitoAction = forgotPasswordCognitoActionCreator(dispatch, api);
  const changePasswordAction = changePasswordActionCreator(dispatch, api);
  const changePasswordCognitoAction = changePasswordCognitoActionCreator(dispatch, api);
  const getAdminConfigOptionAction = getAdminConfigOptionActionCreator(dispatch);
  const onPressContinueAction = onPressContinueActionCreator(
    dispatch,
    state.phoneNumber,
    state.phoneNumberCountryCode,
    state.hasPhoneNumber
  );
  const changePhoneNumberAction = changePhoneNumberActionCreator(dispatch);
  const changePhoneNumberCountryCodeAction = changePhoneNumberCountryCodeActionCreator(dispatch);
  const validateVerificationCodeAction = validateVerificationCodeActionCreator(
    dispatch,
    api,
    cognitoUserRef
  );
  const clearVerificationCodeErrorAction = clearVerificationCodeErrorActionCreator(dispatch);
  const update2faReminderAction = update2faReminderActionCreator(dispatch);
  const resendVerificationCodeAction = resendVerificationCodeActionCreator(dispatch);
  const setErrorAction = setErrorActionCreator(dispatch);
  const verifyLoginAction = verifyLoginActionCreator(dispatch, api, state.invitationKey);
  const setOTPTokenAction = setOTPTokenActionCreator(dispatch);
  const setTwoFAStatusAction = setTwoFAStatusActionCreator(dispatch);
  const get2FAStatusAction = get2FAStatusActionCreator(dispatch, api);
  const clearOTPTokenAction = clearOTPTokenActionCreator(dispatch);
  const setExpiredCodeErrorAction = setExpiredCodeErrorActionCreator(dispatch);

  const actions: ClientAuthActions = {
    loadProviderInfoAction: useCallback(loadProviderInfoAction, []),
    loginAction: useCallback(loginAction, []),
    loginCognitoAction: useCallback(loginCognitoAction, [cognitoUserRef]),
    loginSSOAction: useCallback(loginSSOAction, []),
    loginAetnaSSOAction: useCallback(loginAetnaSSOAction, []),
    loginOauth2Action: useCallback(loginOauth2Action, []),
    logoutAction: useCallback(logoutAction, []),
    registerAction: useCallback(registerAction, [state.adminConfigs.isCognitoLoginEnabled]),
    clearErrorsAction: useCallback(clearErrorsAction, []),
    forgotPasswordAction: useCallback(forgotPasswordAction, []),
    forgotPasswordCognitoAction: useCallback(forgotPasswordCognitoAction, []),
    changePasswordAction: useCallback(changePasswordAction, []),
    changePasswordCognitoAction: useCallback(changePasswordCognitoAction, []),
    getAdminConfigOptionAction: useCallback(getAdminConfigOptionAction, []),
    onPressContinueAction: useCallback(onPressContinueAction, [
      state.phoneNumber,
      state.phoneNumberCountryCode,
      state.hasPhoneNumber,
    ]),
    changePhoneNumberAction: useCallback(changePhoneNumberAction, []),
    changePhoneNumberCountryCodeAction: useCallback(changePhoneNumberCountryCodeAction, []),
    validateVerificationCodeAction: useCallback(validateVerificationCodeAction, [cognitoUserRef]),
    clearVerificationCodeErrorAction: useCallback(clearVerificationCodeErrorAction, []),
    resendVerificationCodeAction: useCallback(resendVerificationCodeAction, []),
    update2faReminderAction: useCallback(update2faReminderAction, [state.redirectTo]),
    setErrorAction: useCallback(setErrorAction, []),
    getShouldOpen2faVerifyAction: useCallback(
      getShouldOpen2faVerifyActionCreator(state.twoFAStatus, state.otpToken),
      [state.twoFAStatus, state.otpToken]
    ),
    getShouldOpen2faReminderAction: useCallback(
      getShouldOpen2faReminderActionCreator(state.twoFAStatus),
      [state.twoFAStatus]
    ),
    verifyLoginAction: useCallback(verifyLoginAction, [state.invitationKey]),
    setOTPTokenAction: useCallback(setOTPTokenAction, []),
    setTwoFAStatusAction: useCallback(setTwoFAStatusAction, []),
    get2FAStatusAction: useCallback(get2FAStatusAction, []),
    clearOTPTokenAction: useCallback(clearOTPTokenAction, []),
    setExpiredCodeErrorAction: useCallback(setExpiredCodeErrorAction, []),
  };

  return (
    <ClientAuthStateContext.Provider value={state}>
      <ClientAuthActionsContext.Provider value={actions}>
        {children}
      </ClientAuthActionsContext.Provider>
    </ClientAuthStateContext.Provider>
  );
}

export function useClientAuthState() {
  const context = useContext(ClientAuthStateContext);
  if (context === undefined)
    throw new Error('ClientAuthStateContext must be used within a ContextProvider');
  return context;
}

export function useClientAuthActions() {
  const context = useContext(ClientAuthActionsContext);
  if (context === undefined)
    throw new Error('ClientAuthActionsContext must be used within a ContextProvider');
  return context;
}
