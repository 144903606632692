import { useCallback, useEffect } from 'react';
import { useToastState } from '@talkspace/react-toolkit/src/hooks/ToastProvider';
import CommunityCreatePostSuccess from '../components/CommunityCreatePostSuccess';
import { TEENSPACE_DAY_END_SHIFT_HOURS, TEENSPACE } from '../constants';
import { useCommunityState } from '../hooks/CommunityContext';
import useQueryCommunityFilteredPostCount from '../hooks/useQueryCommunityFilteredPostCount';
import { getCommunityFeedFilterTags } from '../utils/helpers';
import { CommunityFeedTypeEnum } from '../types';
import useSharePostWithTherapist from '../hooks/useSharePostWithTherapist';
import { useHistory } from '@/core/routerLib';
import { useCloseModal } from '../../utils/ModalsContextProvider';
import useQueryNotificationPreference from '../hooks/useQueryNotificationPreference';

const CommunityCreatePostSuccessContainer = () => {
  const history = useHistory<{ responseText: string }>();
  const { responseText } = history.location.state;
  const closeModal = useCloseModal();
  const { userID, clientTimezone, communityID, accessToken, dailyPrompt, hasActiveRoom } =
    useCommunityState();
  const { data: userPostCount, isLoading } = useQueryCommunityFilteredPostCount({
    communityID,
    accessToken,
    tags: getCommunityFeedFilterTags({
      userID,
      timezone: clientTimezone,
      feedType: CommunityFeedTypeEnum.USER,
      shiftHours: TEENSPACE_DAY_END_SHIFT_HOURS,
    }),
  });
  const { sharePostWithTherapist } = useSharePostWithTherapist({
    userID,
    dailyPrompt,
    responseText,
  });

  const { showToast } = useToastState();

  const { data: notificationPreferenceData } = useQueryNotificationPreference({
    clientUserID: userID,
  });

  const communityDailyPromptNotification = (notificationPreferenceData?.categories || []).find(
    (category) => category.name === 'communityDailyPrompt'
  );

  const onPressContinue = () => {
    closeModal({ navigateTo: TEENSPACE });
  };

  const onPressShareWithTherapist = () => {
    sharePostWithTherapist().then(() => {
      showToast({ type: 'success', text: 'Your post was shared with your therapist' });
      closeModal({ navigateTo: TEENSPACE });
    });
  };
  const onClose = () => {
    closeModal({ navigateTo: TEENSPACE });
  };

  const onPressSkip = useCallback(() => {
    if (communityDailyPromptNotification && !communityDailyPromptNotification.push) {
      history.push('/community/teenspace/notification-prompt');
    } else {
      closeModal({ navigateTo: TEENSPACE });
    }
  }, [closeModal, communityDailyPromptNotification, history]);

  useEffect(() => {
    if (!responseText) {
      closeModal({ navigateTo: TEENSPACE });
    }
  }, [closeModal, responseText]);

  return (
    <CommunityCreatePostSuccess
      isLoading={isLoading}
      userPostCount={userPostCount}
      communityDisplayString="Teenspace"
      primaryCTAText={hasActiveRoom ? 'Share with therapist' : 'Continue'}
      onPressPrimaryCTA={hasActiveRoom ? onPressShareWithTherapist : onPressContinue}
      secondaryCTAText={hasActiveRoom ? 'Maybe later' : undefined}
      onPressSecondaryCTA={hasActiveRoom ? onPressSkip : undefined}
      onClose={onClose}
    />
  );
};

export default CommunityCreatePostSuccessContainer;
