import { View } from '@talkspace/react-toolkit';
import styled from '@/core/styled';

const Description = styled(View)({
  padding: 17,
  textAlign: 'center',
  marginBottom: 30,
});

export default Description;
