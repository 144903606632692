import { FunctionComponent } from 'react';
import { toast, ToastContainer as ToastifyContainer, ToastContainerProps } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toast.css';

const ToastContainer: FunctionComponent<ToastContainerProps> = ({
  closeButton = false,
  autoClose = 3000,
  position = toast.POSITION.BOTTOM_RIGHT,
  hideProgressBar = true,
  ...otherProps
}) => (
  <ToastifyContainer
    closeButton={closeButton}
    autoClose={autoClose}
    position={position}
    hideProgressBar={hideProgressBar}
    {...otherProps}
  />
);

export { toast, ToastContainer };
