/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useCallback, useRef, useEffect } from 'react';
import { prepareDataByTimezone } from 'ts-frontend/helpers';
import ApiHelper from '../utils/ApiHelper';
import ClientWebApiHelper from '../../utils/ApiHelper';
import {
  ImplicitBusinessHoursAPI,
  Match,
  inPlatformMatchingReducer,
  State,
  initialState,
} from '../reducers/inPlatformMatchingReducer';
import { getUserData } from '../../auth/helpers/token';

function mergeImplicitBusinessHoursWithMatches(
  implicitBusinessHours: ImplicitBusinessHoursAPI[],
  matches: Match[]
): Match[] {
  return matches.map((match) => {
    const therapistsHours = implicitBusinessHours.find((s) => s.therapistID === match.therapistId);
    const { businessHours = [] } = therapistsHours || {};
    if (businessHours && businessHours.length) {
      return {
        ...match,
        implicitBusinessHoursByDay: prepareDataByTimezone(businessHours),
      };
    }
    return match;
  });
}

export default function useInPlatformMatching(): [
  State,
  {
    getTherapistTimeslots: (therapistID, roomID) => Promise<void>;
    getInPlatformMatches: (roomID, matchID) => Promise<void>;
    getRoomPresentingProblems: (roomID) => Promise<void>;
    requestSwitchTherapist: (roomID, matchID, therapistID) => Promise<void>;
    requestNewInPlatformMatches: (roomID) => Promise<void>;
    requestSwitchToMatchingAgent: (roomID) => Promise<void>;
    requestSwitchToMatchingAgentCT: (roomID) => Promise<void>;
    resetTherapistIsUnavailable: () => void;
  }
] {
  const [state, dispatch] = useReducer(inPlatformMatchingReducer, initialState);
  const apiRef = useRef(new ApiHelper());
  const { current: api } = apiRef;
  const clientWebApiRef = useRef(new ClientWebApiHelper());

  const getCustomerInformation = useCallback(async () => {
    const { id } = getUserData();
    try {
      const customerInfo = await clientWebApiRef.current.getCustomerInformation(id);
      const { countryState } = customerInfo;
      // Hide availability tab for UK clients
      return dispatch({
        type: 'receiveCustomerInformation',
        payload: { showAvailability: countryState !== 'GB' },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return undefined;
  }, []);

  useEffect(() => {
    getCustomerInformation();
  }, [getCustomerInformation]);

  useEffect(
    () => () => {
      apiRef.current.cancelAll();
      clientWebApiRef.current.cancelAll();
    },
    []
  );

  async function getInPlatformMatches(roomID, matchID): Promise<void> {
    dispatch({ type: 'getInPlatformMatches' });
    try {
      const response = await api.getMatches(roomID, matchID);
      const implicitBusinessHoursRes = await api.getImplicitBusinessHours(
        response.data.map((m) => m.therapistId)
      );
      const matches = mergeImplicitBusinessHoursWithMatches(
        implicitBusinessHoursRes.data,
        response.data
      );
      return dispatch({
        type: 'receiveGetInPlatformMatches',
        payload: {
          matches,
          isB2B: response.isB2B,
        },
      });
    } catch (error) {
      return dispatch({
        type: 'setIsError',
        error: error.message,
      });
    }
  }

  async function requestNewInPlatformMatches(roomID): Promise<void> {
    dispatch({ type: 'requestNewInPlatformMatches' });
    return api
      .postNewMatchesRequest(roomID)
      .then(() =>
        dispatch({
          type: 'receiveNewInPlatformMatches',
        })
      )
      .catch((error) =>
        dispatch({
          type: 'setIsError',
          error: error.message,
        })
      );
  }

  async function getRoomPresentingProblems(roomID): Promise<void> {
    dispatch({ type: 'getRoomPresentingProblems' });
    return api
      .getRoomPresentingProblems(roomID)
      .then((roomPresentingProblems) =>
        dispatch({
          type: 'receiveRoomPresentingProblems',
          payload: {
            roomPresentingProblems,
          },
        })
      )
      .catch((error) =>
        dispatch({
          type: 'setIsError',
          error: error.message,
        })
      );
  }

  async function getTherapistTimeslots(therapistID: number, roomID: number): Promise<void> {
    dispatch({ type: 'getTherapistTimeslots' });
    return api.getTherapistTimeslots(therapistID, 30, roomID).then((therapistTimeslots) => {
      dispatch({
        type: 'receiveGetTherapistTimeslots',
        payload: { canBookIntroSession: therapistTimeslots.timeslots.length > 0 },
      });
    });
  }

  function requestSwitchTherapist(roomID, matchID, therapistID): Promise<void> {
    dispatch({ type: 'requestSwitchTherapist' });
    return api
      .postSwitchTherapist(roomID, matchID, therapistID)
      .then(({ error }) => {
        if (error) {
          // treat unavailable error not as an error because it occurs on the
          // confirm screen which, unlike the other errors, is full-screen
          if (error === 'unavailable') {
            return dispatch({
              type: 'setTherapistIsUnavailable',
              payload: { therapistID },
            });
          }
          return dispatch({
            type: 'setIsError',
            error,
          });
        }
        return dispatch({ type: 'receiveRequestSwitchTherapist' });
      })
      .catch((error) =>
        dispatch({
          type: 'setIsError',
          error: error.message,
        })
      );
  }

  function resetTherapistIsUnavailable(): void {
    return dispatch({
      type: 'resetTherapistIsUnavailable',
    });
  }

  function requestSwitchToMatchingAgent(roomID): Promise<void> {
    // no dispatch for request required as if success, this will reload the room
    return api
      .requestMatchingAgent(roomID)
      .then(({ therapistWasSwitched }) => {
        if (therapistWasSwitched) {
          window.history.back();
        } else {
          throw new Error('You are already in the room with a consultation therapist.');
        }
      })
      .catch((err) =>
        dispatch({
          type: 'setIsError',
          error:
            err.message === '400'
              ? 'Transfer to matching agent action was already completed'
              : err.message,
        })
      );
  }
  function requestSwitchToMatchingAgentCT(roomID: number): Promise<void> {
    // no dispatch for request required as if success, this will reload the room
    return api.inPlatformMatchingRequestNewMatch(roomID).catch((err) =>
      dispatch({
        type: 'setIsError',
        error: err.message,
      })
    );
  }

  return [
    state,
    {
      getTherapistTimeslots: useCallback(getTherapistTimeslots, []),
      getInPlatformMatches: useCallback(getInPlatformMatches, []),
      getRoomPresentingProblems: useCallback(getRoomPresentingProblems, []),
      requestSwitchTherapist: useCallback(requestSwitchTherapist, []),
      requestNewInPlatformMatches: useCallback(requestNewInPlatformMatches, []),
      resetTherapistIsUnavailable: useCallback(resetTherapistIsUnavailable, []),
      requestSwitchToMatchingAgent: useCallback(requestSwitchToMatchingAgent, []),
      requestSwitchToMatchingAgentCT: useCallback(requestSwitchToMatchingAgentCT, []),
    },
  ];
}
