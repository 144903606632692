import { FunctionComponent } from 'react';
import { COLORS, Modal } from '@talkspace/react-toolkit';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import ActionStatus from '../ActionStatus';
import { ELIGIBILITY_RESPONSE_STATUS } from '../../types';

interface CreateSessionReportModalProps {
  eligibilityStatus?: ELIGIBILITY_RESPONSE_STATUS;
  isSuccess: boolean;
  isError: boolean;
  successTitle: string;
  successSubtitle: string;
  successButtonText: string;
  errorTitle: string;
  errorSubtitle: string;
  errorButtonText: string;
}

const CreateSessionReportModal: FunctionComponent<CreateSessionReportModalProps> = ({
  eligibilityStatus,
  isSuccess,
  isError,
  successTitle,
  successSubtitle,
  successButtonText,
  errorButtonText,
  errorTitle,
  errorSubtitle,
}) => {
  const isLoading = eligibilityStatus === undefined && !isSuccess && !isError;
  const closeModal = useCloseModal();
  return (
    <Modal.Panel onBackdropPress={() => closeModal()}>
      <ActionStatus
        isLoading={isLoading}
        isError={isError}
        errorTitle={errorTitle}
        errorSubTitle={errorSubtitle}
        errorButtonText={errorButtonText}
        errorButtonAction={() => closeModal()}
        successTitle={successTitle}
        successSubTitle={successSubtitle}
        successButtonAction={() => closeModal()}
        successButtonText={successButtonText}
        spinnerStyles={{ height: 63, width: 63 }}
        titleStyles={{ fontWeight: 800, fontSize: 29, marginTop: 25 }}
        subTitleStyles={{
          fontSize: 16,
          color: COLORS.darkGray,
          marginTop: 13,
          marginBottom: 30,
          width: 300,
          textAlign: 'center',
        }}
      />
    </Modal.Panel>
  );
};

export default CreateSessionReportModal;
