import Svg, { Path, G, Circle } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const PasswordDots = ({ width = 77, height = 5, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'dots';
  return (
    <Svg
      width={width}
      height={height}
      aria-hidden="true"
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 77 5"
      {...otherProps}
    >
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <Path
          fill={colors.white}
          stroke={colors.periwinkleGrey}
          strokeWidth="0.5"
          d="M638.75 158.009c0-5.665 4.595-10.259 10.258-10.259h480.984c5.667 0 10.258 4.59 10.258 10.259V373.99c0 5.665-4.595 10.259-10.258 10.259H649.008c-5.667 0-10.258-4.59-10.258-10.259V158.01z"
          transform="translate(-665 -344)"
        />
        <G fill={colors.osloGrey} transform="translate(-665 -344) translate(665 344)">
          <Circle cx="74.5" cy="2.5" r="2.5" />
          <Circle cx="65.5" cy="2.5" r="2.5" />
          <Circle cx="56.5" cy="2.5" r="2.5" />
          <Circle cx="47.5" cy="2.5" r="2.5" />
          <Circle cx="38.5" cy="2.5" r="2.5" />
          <Circle cx="29.5" cy="2.5" r="2.5" />
          <Circle cx="20.5" cy="2.5" r="2.5" />
          <Circle cx="11.5" cy="2.5" r="2.5" />
          <Circle cx="2.5" cy="2.5" r="2.5" />
        </G>
      </G>
    </Svg>
  );
};

export default PasswordDots;
