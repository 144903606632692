/* eslint-disable import/prefer-default-export */
import Base64 from './Base64';

interface Resource<Payload> {
  path: string;
  method: string;
  payload: Payload;
}

export interface ParsedJWT<Payload> {
  userID: string;
  resource: Array<Resource<Payload>>;
  iat: number;
  exp: number;
  iss: string;
  jti: string;
}

export const parseJwt = <Payload>(token: string): ParsedJWT<Payload> => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    Base64.atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
};
