import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const DailyReminder = ({ width = 22, height = 20, ...props }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'daily reminder';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <Path
          fill={colors.white}
          stroke={colors.iconLightGrey}
          strokeWidth="0.5"
          d="M635.75 157.995c0-5.657 4.595-10.245 10.258-10.245h480.984c5.666 0 10.258 4.586 10.258 10.245V352.25h-501.5V157.995z"
          transform="translate(-661 -259)"
        />
        <Path
          fill={colors.green}
          fillRule="nonzero"
          d="M18 7a3.5 3.5 0 110-7 3.5 3.5 0 010 7zm1.41 10.007c0 1.538-1.231 2.793-2.745 2.793H3.655C2.139 19.8.91 18.55.91 17.007v-9.09-4.23C.91 2.149 2.14.894 3.655.894h10.077a4.97 4.97 0 00-.616 1.529H3.655A1.26 1.26 0 002.41 3.687v13.32c0 .699.557 1.265 1.245 1.265h13.01a1.26 1.26 0 001.245-1.265V8.5L18 8.5c.49 0 .963-.07 1.41-.202v8.71z"
          transform="translate(-661 -259) translate(660 254) translate(1 5)"
        />
      </g>
    </Svg>
  );
};

export default DailyReminder;
