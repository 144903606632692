import { FunctionComponent, useCallback, useEffect } from 'react';
import { View, Spinner } from '@talkspace/react-toolkit';
import { persistData, useWizardActions, useWizardState } from 'stepWizard';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import styled from '../../core/styled';

const Wrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      alignSelf: 'center',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: isMobile ? '15%' : '8%',
      marginBottom: 'auto',
      width: 340,
    };
  }
);

const NPSDone: FunctionComponent<{}> = () => {
  const { responses, isVerifyExitModalOpen, roomID, persisted, daysDelay } = useWizardState();

  const closeModal = useCloseModal();
  const { setState, closeWizard } = useWizardActions();
  const handleExitModal = useCallback(() => {
    closeWizard(roomID, true, undefined);
  }, [closeWizard, roomID]);

  useEffect(() => {
    if (isVerifyExitModalOpen) {
      closeModal();
    }
  }, [closeModal, isVerifyExitModalOpen]);

  useEffect(() => {
    // Submit NPS upon mount
    persistData(
      { ...responses, daysDelay },
      setState,
      'NPS done',
      undefined,
      { NPSSubmitted: true },
      true
    );
  }, [responses, daysDelay, setState]);

  useEffect(() => {
    // Close modal upon NPS submission
    if (persisted) {
      handleExitModal();
    }
  }, [handleExitModal, persisted]);

  return (
    <Wrapper>
      <Spinner />
    </Wrapper>
  );
};

export default NPSDone;
