import { useRef } from 'react';
import { Moment } from 'moment';

import {
  Col,
  Grid,
  Row,
  View,
  useEmotionTheme,
  ResponsiveLayoutWithHeader,
  PanelHeader,
  BackNextButton,
} from '@talkspace/react-toolkit';

import { useHistory } from '@/core/routerLib';

import CommunityReferenceThreeDotMenu from './CommunityReferenceThreeDotMenu';
import UserTag from './UserTag';

import CommentingEndedBanner from './CommentingEndedBanner';
import CreateCommentInput from './CreateCommentInput';
import { CommunityReferenceTypeEnum } from '../types';
import PostText from './PostText';
import PostToolbar from './PostToolbar';
import CommunityPostComments from './CommunityPostComments';

interface Props {
  post: Amity.Post;
  isLoadingPost: boolean;
  commentingEndedAt: Moment | null;
}
const CommunityPostDetails = ({ post, isLoadingPost, commentingEndedAt }: Props) => {
  const history = useHistory();
  const commentInputRef = useRef<HTMLInputElement>(null);
  const { spacing, colorRoles } = useEmotionTheme();

  return (
    <>
      <ResponsiveLayoutWithHeader
        dataQa="teenspaceHeader"
        renderHeader={() => (
          <PanelHeader
            useNewNavHeader
            renderLeft={() => (
              <>
                <BackNextButton
                  onPress={() => history.goBack()}
                  hidden
                  primaryColor={colorRoles.icons.iconBrandDefault}
                  caretColor={colorRoles.icons.iconBrandDefault}
                  dataQa="backButton"
                />
                <View flex={1} align="center" justify="center" />
              </>
            )}
            renderRight={() =>
              isLoadingPost ? (
                <></>
              ) : (
                <View align="end">
                  <CommunityReferenceThreeDotMenu
                    reference={post}
                    referenceType={CommunityReferenceTypeEnum.POST}
                  />
                </View>
              )
            }
          />
        )}
      >
        <Grid>
          <Row>
            <Col>
              {!isLoadingPost && (
                <View style={{ padding: `${spacing('space100')}px 0` }}>
                  <UserTag user={post.creator} />
                  <PostText
                    post={post}
                    style={{
                      padding: `${spacing('space100')}px 0`,
                      marginBottom: spacing('space100'),
                      marginTop: spacing('space100'),
                    }}
                  />
                  <PostToolbar post={post} commentInputRef={commentInputRef} />
                  {commentingEndedAt && (
                    <CommentingEndedBanner commentingEndedAt={commentingEndedAt} />
                  )}
                </View>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <CommunityPostComments post={post} />
            </Col>
          </Row>
          {!commentingEndedAt && <CreateCommentInput ref={commentInputRef} postID={post?.postId} />}
        </Grid>
      </ResponsiveLayoutWithHeader>
    </>
  );
};

export default CommunityPostDetails;
