import { useEffect, useState } from 'react';

import { CommentRepository } from '@amityco/ts-sdk';

interface Props {
  commentID: string;
}
const useCommunityComment = ({ commentID }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [comment, setComment] = useState<Amity.Comment>();

  useEffect(() => {
    const unsubscribe = CommentRepository.getComment(commentID, ({ data, loading, error }) => {
      setIsLoading(loading);
      setIsError(error);
      setComment(data);
    });
    unsubscribe();
  }, [commentID]);

  return { comment, isLoading, isError };
};

export default useCommunityComment;
