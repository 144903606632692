import { useReducer, useCallback, useRef } from 'react';
import { wizardActionReducer, wizardActionInitialState, WizardActionState } from 'stepWizard';
import refundWizardApiHelper from '../utils/refundWizardApiHelper';
import { Charge } from '../utils/refundWizardTypes';

export default function useRefundWizard(): [
  WizardActionState,
  {
    applyRefund: (charge: Charge) => void;
    submitSupportRequest: (userID: number, bodyParams: object) => void;
    exitAction: () => void;
  }
] {
  const [state, dispatch] = useReducer(wizardActionReducer, wizardActionInitialState());
  const apiRefRefundWizard = useRef(refundWizardApiHelper);

  function applyRefund(charge: Charge) {
    dispatch({ type: 'requestAction' });
    apiRefRefundWizard.current
      .applyRefund(charge)
      .then(() => {
        dispatch({
          type: 'receiveAction',
        });
      })
      .catch((error) => {
        dispatch({
          type: 'setError',
          error: (error && error.message) || '',
        });
      });
  }

  function submitSupportRequest(userID: number, bodyParams: object) {
    dispatch({ type: 'requestAction' });
    apiRefRefundWizard.current
      .submitSupportRequest(userID, bodyParams)
      .then(() => {
        dispatch({
          type: 'receiveAction',
        });
      })
      .catch((error) => {
        dispatch({
          type: 'setError',
          error: (error && error.message) || '',
        });
      });
  }

  function exitAction() {
    dispatch({ type: 'exitAction' });
  }

  return [
    state as WizardActionState,
    {
      applyRefund: useCallback(applyRefund, []),
      submitSupportRequest: useCallback(submitSupportRequest, []),
      exitAction: useCallback(exitAction, []),
    },
  ];
}
