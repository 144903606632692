import { FunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

interface EnvelopeProps {
  color?: string;
  width?: number;
  height?: number;
  style?: object;
}
const Envelope: FunctionComponent<EnvelopeProps> = ({
  color,
  width = 22,
  height = 17,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'envelope';
  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      title={titleText}
      aria-label={titleText}
      fill="none"
      {...otherProps}
    >
      <Path
        d="M1 3a2 2 0 012-2h16a2 2 0 012 2v11a2 2 0 01-2 2H3a2 2 0 01-2-2V3z"
        stroke={color || colors.green}
        strokeWidth={1.8}
      />
      <Path
        d="M1.5 2l8.796 8.712a1 1 0 001.408 0L20.5 2"
        stroke={color || colors.green}
        strokeWidth={1.8}
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default Envelope;
