import { FunctionComponent } from 'react';
import { getVWOWidgetsHeight } from 'ts-analytics/VWO/helpers';
import Svg, { G, Path } from 'svgs';
import { View, ExtraHuge, BaseButton, useEmotionTheme, Screen } from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { RouteComponentProps } from '@/core/routerLib';

const CheckIcon = (props) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg width="45px" height="38px" viewBox="0 0 45 38" {...props}>
      <G
        transform="translate(-29 -34)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Path
          stroke={colors.green}
          strokeWidth={8}
          strokeLinecap="round"
          d="M33 51.9762995L46.2055945 65.952599 69.4641766 38"
        />
      </G>
    </Svg>
  );
};

const IconContainer = styled(View)({
  borderRadius: '50%',
  boxShadow: '0 8px 24px -2px rgba(0,30,67,0.20)',
  height: 103,
  marginBottom: 30,
  width: 103,
});

const Container = styled(View)({
  flexGrow: 1,
  paddingBottom: 65,
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 65,
  textAlign: 'center',
});

const SuccessScreen: FunctionComponent<
  RouteComponentProps<
    {
      roomID: number;
    },
    {},
    { goToUrl?: string; bookWithIntroSession?: boolean }
  >
> = ({ history, location }) => {
  const { goToUrl = '', bookWithIntroSession = false } = location.state || {};
  const closeModal = useCloseModal();

  const goTo = () => {
    history.replace(goToUrl, { ...(bookWithIntroSession && { bookWithIntroSession }) });
  };

  const VWOWidgetsHeight = getVWOWidgetsHeight();

  return (
    <Screen
      safeAreaVariant="setHeightAndAddTop"
      additionalHeaderTopPadding={VWOWidgetsHeight}
      title="Change plan"
      onCloseButtonClick={closeModal}
    >
      <Container align="center" justify="space-between">
        <View align="center">
          <IconContainer align="center" justify="center">
            <CheckIcon />
          </IconContainer>
          <ExtraHuge>Your plan has been changed</ExtraHuge>
        </View>
        <BaseButton
          onPress={goToUrl ? goTo : () => closeModal({ actionPerformed: 'subscribedToPlan' })}
        >
          Close
        </BaseButton>
      </Container>
    </Screen>
  );
};

export default SuccessScreen;
