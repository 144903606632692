import { useEffect, useRef } from 'react';
import { useEmotionTheme } from '@talkspace/react-toolkit';
import ActionStatus from '../../components/ActionStatus';
import TwoFactorAuthenticationWrapper from '../containers/TwoFactorAuthenticationWrapper';
import { useClientAuthActions, useClientAuthState } from '../../hooks/clientAuthContext';
import LoadingScreen from '../../screens/LoadingScreen';

const TwoFactorAuthenticationClose = () => {
  const { isUpdating, isError, twoFAStatus, enabled2faSuccess } = useClientAuthState();
  const { update2faReminderAction } = useClientAuthActions();
  const { colors } = useEmotionTheme();
  const close2faTimeout = useRef<undefined | ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (!isError) {
      close2faTimeout.current = setTimeout(() => update2faReminderAction(), 1000);
    }
    return () => {
      if (close2faTimeout.current) clearTimeout(close2faTimeout.current);
    };
  }, [isError, update2faReminderAction]);

  return (
    <>
      {isError ? (
        <TwoFactorAuthenticationWrapper>
          <ActionStatus
            isLoading={isUpdating}
            isError={isError}
            showSuccessState={enabled2faSuccess && twoFAStatus !== 'on'}
            successTitle="Two-factor authentication has been enabled"
            errorTitle="Something Went Wrong"
            errorSubTitle="Check your internet connection and try again later"
            errorButtonText="Close"
            errorButtonAction={update2faReminderAction}
            titleStyles={{ fontWeight: 800, fontSize: 22, marginTop: 25, textAlign: 'center' }}
            subTitleStyles={{
              fontSize: 16,
              color: colors.darkGray,
              marginTop: 13,
              marginBottom: 42,
              width: 200,
              textAlign: 'center',
            }}
          />
        </TwoFactorAuthenticationWrapper>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

export default TwoFactorAuthenticationClose;
