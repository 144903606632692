import { useFlags } from 'launchDarkly/FlagsProvider';
import { Large, Massive, View, SuccessCheckmark, Spinner } from '@talkspace/react-toolkit';

const EmailVerified = () => {
  const { emailVerificationCopyChange } = useFlags();
  return emailVerificationCopyChange === 'treatment' ? (
    <View justify="center" align="center" style={{ paddingTop: 68, textAlign: 'center' }}>
      <SuccessCheckmark width={65} height={65} />
      <Massive style={{ paddingTop: 25 }}>Your email has been verified</Massive>
      <Large variant="largeDarkGrey" style={{ paddingTop: 20 }}>
        Hang tight! Just a few more questions before we get you set up with a provider.
      </Large>
      <View style={{ padding: 30 }}>
        <Spinner />
      </View>
    </View>
  ) : (
    <View justify="center" align="center" style={{ paddingTop: 68, textAlign: 'center' }}>
      <SuccessCheckmark width={65} height={65} />
      <Massive style={{ paddingTop: 25 }}>Your email has been verified</Massive>
      <Large variant="largeDarkGrey" style={{ paddingTop: 20 }}>
        Thank you!
      </Large>
    </View>
  );
};

export default EmailVerified;
