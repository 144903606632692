import { MainContextProvider } from '../hooks/mainContext';
import { InsuranceEligibilityProvider } from './hooks/InsuranceEligibilityContext';
import Routes from './Routes';

const InsuranceEligibility = () => (
  <MainContextProvider>
    <InsuranceEligibilityProvider>
      <Routes />
    </InsuranceEligibilityProvider>
  </MainContextProvider>
);

export default InsuranceEligibility;
