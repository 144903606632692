import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import { CANCELLATION_WINDOW_HOURS } from 'inRoomScheduling/utils/constants';
import isBookingBetweenNowAndNHoursLater from 'inRoomScheduling/utils/isBookingBetweenNowAndNHoursLater';
import { getUserData } from '@/auth/helpers/token';
import { useHistory, useLocation, useParams } from '@/core/routerLib';

import useQueryClientBooking from '../../hooks/useQueryClientBooking';
import CancelSession from '../components/CancelSession';
import formatBookingDate from '../utils/formatBookingDate';

const CancelSessionContainer = () => {
  const history = useHistory();
  const { id: clientUserID } = getUserData();
  const { roomID, bookingID } = useParams<{ roomID: string; bookingID: string }>();
  const { data: businessLineData, isLoading: isLoadingBusinessLine } = useQueryBusinessLine(
    roomID,
    clientUserID
  );
  const { isBH, isEAP } = businessLineData || {};
  const { data: booking, isLoading: isLoadingBooking } = useQueryClientBooking({
    roomID,
    bookingID,
  });

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const hasPendingInvoices = !!params.get('hasPendingInvoices');

  const formattedStartTime = booking && formatBookingDate(booking);
  const isBookingTooSoon =
    booking && isBookingBetweenNowAndNHoursLater(booking, CANCELLATION_WINDOW_HOURS);
  const isBookingConfirmed = booking?.timekitBookingState === 'confirmed';

  const handlePressRescheduleSession = () => {
    if (hasPendingInvoices) {
      history.replace(
        `/in-room-scheduling/room/${roomID}/pending-invoices?source=cancelSession&bookingID=${bookingID}`
      );
    } else {
      history.push(`/in-room-scheduling/room/${roomID}/select-timeslot`, {
        isReschedule: true,
        selectedBookingID: bookingID,
      });
    }
  };

  return (
    <CancelSession
      isLoading={isLoadingBooking || isLoadingBusinessLine}
      formattedStartTime={formattedStartTime}
      isBH={isBH}
      isEAP={isEAP}
      isBookingTooSoon={isBookingTooSoon}
      isBookingConfirmed={isBookingConfirmed}
      onPressCancelSession={() =>
        history.push(`/room/${roomID}/session-details/${bookingID}/cancel-session/select-reason`)
      }
      onPressRescheduleSession={handlePressRescheduleSession}
    />
  );
};

export default CancelSessionContainer;
