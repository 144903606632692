import { useEmotionTheme, View, TextDS, Spinner, styled } from '@talkspace/react-toolkit';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router';
import DisplayNameInputField from '../../components/DisplayNameInputField';
import TeenspaceContinueButton from './TeenspaceContinueButton';
import useMutationPutCommunityUserData from '../../hooks/useMutationPutCommunityUserData';
import { DISPLAY_NAME_ERRORS, GENERIC_ERROR } from '../../constants';
import useQueryCommunityUserData from '../../hooks/useQueryCommunityUserData';
import {
  trackCommunityDisplayNameChanged,
  trackCommunityOnboardingCTAPressed,
} from '../../utils/analytics';
import { getUserData } from '../../../auth/helpers/token';

const displayNameValue = 'displayName';

const TitleText = styled(TextDS)(({ theme: { spacing } }) => {
  return {
    marginBottom: spacing('space200'),
    marginTop: spacing('space500'),
  };
});

const TitleView = styled(View)(({ theme: { spacing } }) => {
  return {
    marginTop: spacing('space150'),
  };
});

const Wrapper = styled(View)(({ theme: { spacing } }) => {
  return {
    textAlign: 'center',
    height: '100%',
  };
});

const TeenspaceProfileSetup = () => {
  const {
    formState: { errors },
    watch,
    trigger,
    setError,
  } = useFormContext();
  const { colorRoles } = useEmotionTheme();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuggestedName, setIsSuggestedName] = useState<boolean>(true);
  const history = useHistory();
  const { id: clientUserID } = getUserData();
  const { refetch: refetchCommunityUserData } = useQueryCommunityUserData({ clientUserID });
  const {
    mutate: putCommunityUserData,
    reset: resetMutation,
    isError,
  } = useMutationPutCommunityUserData();

  const displayName = watch(displayNameValue);
  const canContinue = !isError && !errors.displayName && displayName;
  const isFormError = displayName.length > 20 || displayName.length < 5;

  const onContinuePress = async () => {
    trackCommunityOnboardingCTAPressed({ pageName: 'teenspaceProfileSetup' });
    setIsLoading(true);
    if (isFormError) {
      trigger(displayNameValue);
      setIsLoading(false);
      return;
    }
    putCommunityUserData(
      { displayName },
      {
        onSuccess: () => {
          refetchCommunityUserData();
          trackCommunityDisplayNameChanged({ location: 'teenspaceOnboarding', isSuggestedName });
          history.push('/community/teenspace/onboarding/daily-question');
        },
        onError: (err) => {
          const putCommunityUserDataStatusCode = err.data?.status;
          if (putCommunityUserDataStatusCode !== 200) {
            const putCommunityUserDataErrorMessage =
              (putCommunityUserDataStatusCode &&
                DISPLAY_NAME_ERRORS[putCommunityUserDataStatusCode]) ||
              GENERIC_ERROR;
            setError(displayNameValue, { message: putCommunityUserDataErrorMessage });
          }
          setIsLoading(false);
        },
      }
    );
  };

  return isLoading ? (
    <View justify="center" align="center" style={{ marginTop: 140 }}>
      <Spinner />
    </View>
  ) : (
    <Wrapper align="center" justify="space-between">
      <View align="center" style={{ textAlign: 'center' }}>
        <View align="stretch">
          <TitleText variant="heading2xl">Choose your anonymous display name</TitleText>
        </View>
        <TitleView align="stretch" flex={1}>
          <TextDS style={{ color: colorRoles.typography.textSubtle }} variant="body">
            You can edit this in your profile later
          </TextDS>
        </TitleView>
        <DisplayNameInputField
          resetMutation={resetMutation}
          setIsSuggestedName={setIsSuggestedName}
        />
      </View>
      <TeenspaceContinueButton
        stretch
        size="large"
        onPress={onContinuePress}
        text="Continue"
        dataQa="teenspaceProfileSetupContinueButton"
        disabled={!canContinue}
      />
    </Wrapper>
  );
};

export default TeenspaceProfileSetup;
