import { SkeletonLoader, View, spacing } from '@talkspace/react-toolkit';

const { space025, space050, space100, space200, space300 } = spacing;

const BookingsListLoading = () => {
  const textLoaderBaseStyle = {
    width: 124,
    marginTop: space025 + space050,
    marginBottom: space100,
  };
  return (
    <>
      {['loading-booking-1', 'loading-booking-2', 'loading-booking-3'].map((name) => (
        <View
          key={name}
          row
          style={{ padding: `${space300}px ${space200}px ${space200}px`, marginTop: space200 }}
        >
          <SkeletonLoader variant="Icon64" style={{ marginRight: space200 }} />
          <View>
            <SkeletonLoader
              variant="headingXs"
              style={{ ...textLoaderBaseStyle, marginTop: space025 }}
            />
            <SkeletonLoader variant="headingSm" style={{ ...textLoaderBaseStyle, width: 226 }} />
            <SkeletonLoader variant="headingSm" style={{ ...textLoaderBaseStyle }} />
          </View>
        </View>
      ))}
    </>
  );
};

export default BookingsListLoading;
