import { FunctionComponent, useCallback, useEffect } from 'react';
import { persistData, useWizardState, useWizardActions, WizardActionStatus } from 'stepWizard';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { trackWizardEvent } from '@/utils/analytics/events';
import { getUserData } from '@/auth/helpers/token';
import useCancellationWizard from '../hooks/useCancellationWizard';
import { RouteComponentProps, withRouter } from '../../core/routerLib/routerLib';

const SubscribeToMaintenancePlanContainer: FunctionComponent<RouteComponentProps<{}>> = () => {
  const {
    roomID,
    isVerifyExitModalOpen,
    responses,
    maintenancePlanID,
    experiment,
    experimentID,
    therapistInfo,
  } = useWizardState();
  const closeModal = useCloseModal();
  const [state, { subscribeToMaintenancePlan, exitAction }] = useCancellationWizard();
  const { setState } = useWizardActions();
  const { error, isSuccess } = state;
  const { id: userID } = getUserData();
  const lastStepName = 'Subscribed to maintenance plan';

  const handlePersist = useCallback(() => {
    persistData(
      responses,
      setState,
      lastStepName,
      error,
      { subscribedToMaintenance: true },
      isSuccess
    );
  }, [error, isSuccess, responses, setState]);

  const handleExitModal = () => {
    exitAction();
    closeModal();
  };

  useEffect(() => {
    if (isVerifyExitModalOpen) {
      handleExitModal();
    }
  }, [isVerifyExitModalOpen]); // eslint-disable-line

  useEffect(() => {
    if (!roomID) {
      closeModal();
      return;
    }
    subscribeToMaintenancePlan(roomID, maintenancePlanID);
  }, [roomID]); // eslint-disable-line

  useEffect(() => {
    // persisting the data on error or success
    const handleRequestStatus = async () => {
      if (isSuccess || error) {
        if (isSuccess) {
          trackWizardEvent(lastStepName, 'Cancellation Wizard', {
            'User ID': userID,
            Application: 'Cancellation Wizard',
            label: lastStepName,
            eventPropertyValue: therapistInfo && therapistInfo.id,
            experiment,
            experimentID,
          });
        }
        handlePersist();
      }
    };
    handleRequestStatus();
  }, [isSuccess, error]); // eslint-disable-line

  return (
    <WizardActionStatus
      successTitle="Your subscription has been changed."
      successButtonText="Done"
      handleAction={handleExitModal}
      actionHookState={state}
    />
  );
};

export default withRouter(SubscribeToMaintenancePlanContainer);
