import { FunctionComponent } from 'react';
import Svg, { G, Circle, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

interface WorkingTodayIconProps {
  color?: string;
  width?: number;
  height?: number;
  style?: object;
}

const WorkingTodayIcon: FunctionComponent<WorkingTodayIconProps> = ({
  color,
  width = 21,
  height = 21,
  ...otherProps
}: {
  color?: string;
  width?: number;
  height?: number;
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'working today check mark';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...otherProps}
    >
      <G
        transform="translate(-146 -519) translate(146 519)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Circle fill={color || colors.emerald} cx={10.5} cy={10.5} r={7.5} />
        <Path
          d="M10.5 21C16.299 21 21 16.299 21 10.5S16.299 0 10.5 0 0 4.701 0 10.5 4.701 21 10.5 21zm0-3a7.5 7.5 0 110-15 7.5 7.5 0 010 15z"
          fill={colors.white}
          fillRule="nonzero"
        />
        <Path
          d="M9.852 11.561L8.106 10.23a1 1 0 00-1.212 1.59l3.465 2.644 3.935-6.091a1 1 0 10-1.68-1.086l-2.762 4.275z"
          fill={colors.white}
          fillRule="nonzero"
        />
      </G>
    </Svg>
  );
};

export default WorkingTodayIcon;
