import { RoomType } from 'ts-frontend/entities/Room';
import { ServiceType } from 'ts-frontend/types';

export const QMFlows: Record<Exclude<RoomType, 'peer_support_group_room'>, number> = {
  private_room: 66,
  couples_room: 67,
  psychiatry_room: 68,
};

export const ROOM_TYPE_TO_SERVICE_TYPE: Record<
  Exclude<RoomType, 'peer_support_group_room'>,
  ServiceType
> = {
  private_room: 'psychotherapy',
  couples_room: 'therapyCouples',
  psychiatry_room: 'psychiatry',
};

const CONSTANTS = {
  QMFlows,
  ROOM_TYPE_TO_SERVICE_TYPE,
};

export default CONSTANTS;
