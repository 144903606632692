import { FunctionComponent, useState } from 'react';
import {
  View,
  Small,
  Large,
  Link,
  Massive,
  ExtraHuge,
  RouterLinkWrapper,
  useEmotionTheme,
  useContainerFocus,
  useUniqueID,
} from '@talkspace/react-toolkit';
import { getIsIonic } from 'ts-ionic';
import { QMSessionTypeEnum } from 'ts-frontend/types';
import Browser from 'ts-ionic/plugins/browser';
import { useClientAuthActions } from '@/hooks/clientAuthContext';
import LoginForm from '@/login/components/LoginForm/LoginForm';
import {
  Link as RouterLink,
  RouteComponentProps,
  withRouter,
} from '../../../core/routerLib/routerLib';
import styled from '../../../core/styled';
import useScreenSize, { isResponsive } from '../../../core/hooks/useScreenSize';
import appConfig from '../../../utils/configs';
import { getInviteHashParams } from '../../../roomInvites';
import { useAttribution } from '../../../hooks/useAttribution';
import { getReferralParams, getVoucherCode } from '../../utils/queryHelpers';
import useRedirectAfterLogin from '../../hooks/useRedirectAfterLogin';
import QuickmatchFrame from '../../../containers/QuickmatchFrame';

const Container = styled(View)({ '&:focus': { outline: 'none' } });

const FormContainer = styled(View)({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginLeft: 0,
  marginRight: 0,
});

const QMFrameModal = ({
  show,
  onHide,
  qmFlowPath,
  anonID,
  anonToken,
}: {
  show: boolean;
  onHide: () => void;
  qmFlowPath: string;
  anonID: string;
  anonToken: string;
}) =>
  show ? (
    <QuickmatchFrame
      frameTitle="Sign up"
      qmFlowPath={qmFlowPath}
      render={() => null}
      autoStart
      sessionType={QMSessionTypeEnum.SignUp}
      anonID={anonID}
      onClosePress={onHide}
      anonToken={anonToken}
      showCloseButton={false}
    />
  ) : null;

const Login: FunctionComponent<RouteComponentProps<{}, {}, { redirect?: string }>> = ({
  history,
  location,
}) => {
  const { containerRef } = useContainerFocus();
  const heading1ID = useUniqueID('heading1ID');
  const inviteHashParams = getInviteHashParams();
  const screenSize = useScreenSize();
  const [showModal, setShowModal] = useState(false);
  const [qmFlowPath, setQmFlowPath] = useState('');

  const { data, isFetching } = useAttribution();

  const { clearErrorsAction } = useClientAuthActions();

  const TitleComponent = isResponsive(screenSize) ? ExtraHuge : Massive;

  useRedirectAfterLogin();

  const getRedirectQuery = () => {
    const queryParams = new URLSearchParams(window.location.search);
    // for zendesk sso pass the entire query
    if (queryParams.get('sso') === 'zendesk') {
      return window.location.search;
    }
    // only whitelisted params are passed
    return '';
  };

  const onSignupClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();

    if (isFetching) {
      return;
    }

    clearErrorsAction();
    const [referrerID, channel, platform] = getReferralParams();

    if (
      data?.goto === 'REDIRECT_URL' &&
      data.params.redirectUrl &&
      // Do normal signup on these cases:
      !(
        inviteHashParams.invitationKey ||
        window.location.search.includes('force=1') ||
        getVoucherCode() ||
        referrerID ||
        channel ||
        platform
      )
    ) {
      const isQMFlow = data.params.redirectUrl.includes(appConfig.endpoints.quickMatchWebEndpoint);
      if (getIsIonic()) {
        if (isQMFlow) {
          setQmFlowPath(
            data.params.redirectUrl.replace(appConfig.endpoints.quickMatchWebEndpoint, '')
          );
          setShowModal(true);
        } else {
          Browser.open({ url: data.params.redirectUrl });
        }
      } else {
        window.location.href = data.params.redirectUrl;
      }
    } else {
      history.push({
        pathname: '/signup',
        state: location.state,
        hash: inviteHashParams.invitationKey && `invitationKey=${inviteHashParams.invitationKey}`,
      });
    }
  };

  const { colors } = useEmotionTheme();

  return (
    <>
      <QMFrameModal
        show={showModal}
        onHide={() => setShowModal(false)}
        qmFlowPath={qmFlowPath}
        // Has to be set if `showModal` is true
        anonID={data?.params.sessionID || ''}
        anonToken={data?.params.accessToken || ''}
      />
      <Container align="center" ref={containerRef} aria-labelledby={heading1ID} tabIndex={-1}>
        <View
          align="center"
          style={{
            marginTop: isResponsive(screenSize) ? 30 : 60,
            marginBottom: 29,
          }}
        >
          <TitleComponent as="h1" style={{ marginBottom: 10, textAlign: 'center' }} id={heading1ID}>
            Log in to your account
          </TitleComponent>
          <View style={{ textAlign: 'center', width: 335 }}>
            <Large as="h2" variant="largeDarkGrey" style={{ fontWeight: 400 }}>
              Log in to continue your therapy journey towards a happier, healthier you.
            </Large>
          </View>
        </View>
        <FormContainer>
          <LoginForm hasRememberMeCheckbox />
          {appConfig.featureFlags.ssoLogin && (
            <View style={{ marginTop: 20 }}>
              <RouterLinkWrapper primaryColor={colors.green} roundedFocusStyle>
                <RouterLink to="/login/sso" data-qa="ssoSignInLink">
                  <Small inline style={{ color: colors.green, fontWeight: 700 }}>
                    Log in with Single Sign-On (SSO)
                  </Small>
                </RouterLink>
              </RouterLinkWrapper>
            </View>
          )}
          <View style={{ marginTop: 20 }}>
            <Small>
              Don't have an account?{' '}
              <RouterLinkWrapper primaryColor={colors.green} roundedFocusStyle>
                {/* The `to` prop here is only temporary. It will be overwritten by the click handler */}
                <RouterLink onClick={onSignupClick} to="/signup" data-qa="signUpLink">
                  <Small inline style={{ color: colors.green, fontWeight: 700 }}>
                    Sign up
                  </Small>
                </RouterLink>
              </RouterLinkWrapper>
            </Small>
          </View>
          <View style={{ marginTop: 20 }}>
            <Small>
              Talkspace Provider?{' '}
              <RouterLinkWrapper primaryColor={colors.green} roundedFocusStyle>
                <Link
                  href={`${appConfig.endpoints.therapistAppEndpoint}/login${getRedirectQuery()}`}
                  data-qa="providerSignInLink"
                >
                  <Small inline style={{ color: colors.green, fontWeight: 700 }}>
                    Log in
                  </Small>
                </Link>
              </RouterLinkWrapper>
            </Small>
          </View>
        </FormContainer>
      </Container>
    </>
  );
};

export default withRouter(Login);
