/* eslint-disable @typescript-eslint/no-use-before-define */
import { FunctionComponent, useState } from 'react';

import * as React from 'react';
import {
  View,
  TouchableView,
  BaseButton,
  Button,
  Large,
  Big,
  ArrowLeft,
  ArrowRight,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import moment from 'moment';
import ScrollViewComponent from 'chat/components/ScrollViewComponent';
import styled from '../../../core/styled';
import { withRouter, RouteComponentProps } from '../../../core/routerLib';
import { webOnlyStyle } from '../../../core/styled/styleHelpers';
import { TherapistTimeslot, TimeslotByDay } from '../../reducers/schedulerReducer';

const SchedulerSelectTimeslot: FunctionComponent<
  {
    roomID?: number;
    localTimezone?: string;
    timeslots?: TimeslotByDay[];
    selectedTimeslot?: TherapistTimeslot;
    setSelectedTimeslot: (timeslot: TherapistTimeslot) => void;
  } & RouteComponentProps
> | null = ({
  roomID,
  localTimezone,
  timeslots,
  selectedTimeslot,
  setSelectedTimeslot,
  history,
}) => {
  if (!selectedTimeslot || !timeslots || !localTimezone) return null;
  let city = localTimezone;
  if (city.split('/')[1]) {
    city = localTimezone.split('/')[1].includes('_')
      ? localTimezone.split('/')[1].replace('_', ' ')
      : localTimezone.split('/')[1];
  }
  const bookingStartTime = moment(selectedTimeslot.start).format('MMM D, h:mmA');
  return (
    <View style={{ display: 'flex' }} justify="center" align="center">
      <Large variant="largeDarkGrey" style={{ width: 366, textAlign: 'center', marginTop: 3 }}>
        Please pick a time that suits your schedule. Availability is shown in {city} time.
      </Large>
      <TimeslotCarousel
        timeslots={timeslots}
        selectedTimeslot={selectedTimeslot}
        setSelectedTimeslot={setSelectedTimeslot}
      />
      <Button
        onPress={() => history.push('/scheduler/confirm-booking', { roomID })}
        style={{
          width: 302,
          marginTop: 0,
          fontWeight: 'bold',
          position: 'relative',
          bottom: 18,
        }}
        text={`Book ${bookingStartTime}`}
      />
    </View>
  );
};

const TimeslotWrapper = styled(ScrollViewComponent)({
  width: 343,
  paddingLeft: 22,
  paddingRight: 22,
  marginTop: 18,
  height: 261,
  ...webOnlyStyle({
    WebkitMaskImage:
      '-webkit-gradient(linear, left top, left bottom, color-stop(85%, rgb(34,47,45)), color-stop(92%, rgba(34,47,45,0)))',
  }),
});

const TimeslotCarousel: FunctionComponent<{
  timeslots: TimeslotByDay[];
  selectedTimeslot: TherapistTimeslot;
  setSelectedTimeslot: (timeslot: TherapistTimeslot) => void;
}> = ({ timeslots, selectedTimeslot, setSelectedTimeslot }) => {
  const [page, setPage] = useState<number>(0);
  const timeslotColumns = timeslots.slice(page * 3, page * 3 + 3);
  const maxLength = Math.max(
    ...timeslotColumns.map(({ timeslots: columnTimeslots }) => columnTimeslots.length)
  );
  const lastPage = Math.ceil(timeslots.length / 3) - 1;
  const { colors } = useEmotionTheme();
  return (
    <View align="center">
      <View row style={{ display: 'flex', width: 346, marginTop: 28, marginLeft: 28 }}>
        <BaseButton disabled={page === 0} onPress={() => setPage(page - 1)}>
          <ArrowLeft color={page === 0 ? colors.lightGray : colors.green} />
        </BaseButton>
        <View row style={{ width: 302 }}>
          {timeslotColumns.map(({ date }, index) => (
            <CarouselDateHeader date={date} index={index} key={date} />
          ))}
        </View>
        <BaseButton disabled={page === lastPage} onPress={() => setPage(page + 1)}>
          <ArrowRight color={page === lastPage ? colors.lightGray : colors.green} />
        </BaseButton>
      </View>
      <TimeslotWrapper align="start" row>
        {timeslotColumns.map((timeslotColumn, index) => (
          <TimeslotColumn
            timeslots={timeslotColumn}
            selectedTimeslot={selectedTimeslot}
            setSelectedTimeslot={setSelectedTimeslot}
            maxLength={maxLength}
            key={timeslotColumn.date}
            index={index}
          />
        ))}
      </TimeslotWrapper>
    </View>
  );
};

const BlankTimeslot = styled(View)(({ theme: { colors } }) => {
  return { width: 94, height: 53, borderRadius: 5, backgroundColor: colors.permaLinkWaterGrey };
});

const AvailableTimeslot = styled(TouchableView)<{ isSelected: boolean }>(
  ({ isSelected, theme: { colors } }) => {
    return {
      width: 94,
      height: 53,
      borderRadius: 5,
      outline: 'none',
      justifyContent: 'center',
      backgroundColor: isSelected ? colors.green : colors.white,
      ...webOnlyStyle({
        border: `1px solid ${isSelected ? colors.green : colors.a11yLinkWaterGrey}`,
      }),
    };
  }
);

const CarouselDateHeader: FunctionComponent<{
  date: string;
  index: number;
}> = ({ date, index }) => (
  <View align="center" style={{ width: 94, marginLeft: index === 0 ? 0 : 10 }}>
    <Big>{moment(date).format('ddd,')}</Big>
    <Big variant="bigMedium">{moment(date).format('MMM D')}</Big>
  </View>
);

const TimeslotColumn: FunctionComponent<{
  timeslots: TimeslotByDay;
  index: number;
  maxLength: number;
  selectedTimeslot: TherapistTimeslot;
  setSelectedTimeslot: (timeslot: TherapistTimeslot) => void;
}> = ({ maxLength, index, timeslots, selectedTimeslot, setSelectedTimeslot }) => {
  const timeslotArray: React.ReactElement[] = [];
  const marginBottom = maxLength > 4 ? 33 : 0;
  const { colors } = useEmotionTheme();
  for (let i = 0; i < maxLength; i += 1) {
    if (timeslots.timeslots[i]) {
      const isSelected = selectedTimeslot.start === timeslots.timeslots[i].start;
      timeslotArray.push(
        <AvailableTimeslot
          align="center"
          key={i}
          isSelected={isSelected}
          onPress={() => setSelectedTimeslot(timeslots.timeslots[i])}
          style={{
            marginLeft: index === 0 ? 0 : 10,
            marginTop: i === 0 ? 0 : 5,
            marginBottom: i === maxLength - 1 ? marginBottom : 0,
          }}
        >
          <Large
            style={{
              color: isSelected ? colors.white : colors.black,
            }}
          >
            {moment(timeslots.timeslots[i].start).format('h:mm A')}
          </Large>
        </AvailableTimeslot>
      );
    } else {
      timeslotArray.push(
        <BlankTimeslot
          key={i}
          style={{
            marginTop: i === 0 ? 0 : 5,
            marginLeft: index === 0 ? 0 : 10,
          }}
        />
      );
    }
  }
  return <View>{timeslotArray.map((el) => el)}</View>;
};

export default withRouter(SchedulerSelectTimeslot);
