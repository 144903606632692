import { LiveSessionModality } from 'ts-frontend/types';
import { useQuery } from 'react-query';
import { VideoCallAPIResponse } from 'chat/types/videoCallTypes';

import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { activeSessionAllRoomsQueryKey } from '../utils/queryKeys';

const getActiveSessionAllRooms =
  (clientUserID: number, modality?: LiveSessionModality) => (): Promise<VideoCallAPIResponse> =>
    apiWrapper
      .get(
        `${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/active-session${
          modality ? `?modality=${modality}` : ''
        }`
      )
      .then((res) => res.data.data)
      .catch((err) => {
        if (err.status === 404) return null;
        throw err;
      });

const useQueryActiveSessionAllRooms = ({
  clientUserID,
  modality,
}: {
  clientUserID: number;
  modality?: LiveSessionModality;
}) =>
  useQuery<VideoCallAPIResponse, Error>({
    staleTime: 0,
    cacheTime: 60 * 1000,
    refetchOnWindowFocus: true,
    queryKey: activeSessionAllRoomsQueryKey({ clientUserID, modality }),
    queryFn: getActiveSessionAllRooms(clientUserID, modality),
    enabled: !!clientUserID,
  });

export default useQueryActiveSessionAllRooms;
