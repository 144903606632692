import { Spinner, View } from '@talkspace/react-toolkit';
import useQueryTherapistDetails from 'ts-frontend/hooks/useQueryTherapistDetails';
import useQueryClientDetails from 'ts-frontend/hooks/useQueryClientDetails';
import { prepareDataByTimezone } from 'ts-frontend/helpers';
import useQueryTherapistImplicitBusinessHours from 'ts-frontend/hooks/useQueryTherapistImplicitBusinessHours';
import { useHistory, useParams } from '@/core/routerLib';

import { getUserData } from '../../auth/helpers/token';
import { useOnboardingState } from '../hooks/onboardingContext';
import MeetYourProvider from '../components/MeetYourProvider';

import apiHelper from '../utils/apiHelper';

const MeetYourProviderContainer = () => {
  const history = useHistory();
  const { roomID } = useParams<{ roomID: string }>();
  const { steps, currentStepIndex } = useOnboardingState();
  const { id: userID } = getUserData();

  const therapistID = steps ? steps[currentStepIndex]?.metadata?.therapistID : undefined;

  const { isLoading: isLoadingTherapist, data: therapistDetails } =
    useQueryTherapistDetails(therapistID);
  const { isLoading: isLoadingHours, data: businessHours } =
    useQueryTherapistImplicitBusinessHours(therapistID);

  const implicitBusinessHoursByDay = businessHours && prepareDataByTimezone(businessHours);

  const { isLoading: isLoadingClient, data: { countryState } = {} } = useQueryClientDetails(userID);

  const showAvailability = countryState !== 'GB';
  const isLoading = isLoadingTherapist || isLoadingHours || isLoadingClient;
  const therapistData = therapistDetails && { ...therapistDetails, implicitBusinessHoursByDay };

  const handleMeetProvider = async () => {
    if (!therapistID) {
      return;
    }

    await apiHelper.postMeetProvider(userID, Number(roomID), therapistID);

    const asyncSessionAutoStartedStep = steps
      ? steps.find((it) => it.step === 'ASYNC_SESSION_STARTED')
      : null;

    if (asyncSessionAutoStartedStep) {
      history.push(`/room/${roomID}/onboarding/async-session-started`, {
        startDate: asyncSessionAutoStartedStep?.metadata?.startDate,
        therapistID,
      });
    } else {
      history.push(`/room/${roomID}/onboarding/next`);
    }
  };

  return isLoading ? (
    <View style={{ height: '100vh' }}>
      <Spinner />
    </View>
  ) : (
    <MeetYourProvider
      therapist={therapistData}
      showAvailability={showAvailability}
      onMainPress={handleMeetProvider}
    />
  );
};

export default MeetYourProviderContainer;
