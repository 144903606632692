import { Button, TextDS, View, spacing } from '@talkspace/react-toolkit';
import {
  ArrowRotateRight,
  CircleExclamation,
} from '@talkspace/react-toolkit/src/designSystems/icons';

import { useHistory } from '@/core/routerLib';

const { space100, space200, space600 } = spacing;

const CommunityFeedConnectionError = () => {
  const history = useHistory();

  const onClickRefresh = () => {
    history.go(0);
  };
  return (
    <View align="center" style={{ marginTop: space600 }}>
      <CircleExclamation colorType="subtle" size="major" />
      <TextDS variant="body" colorRole="textSubtle" style={{ marginTop: space100 }}>
        Posts aren’t available right now. Please refresh or try again later.
      </TextDS>
      <Button
        variant="tertiary"
        dataQa="refreshButton"
        text="Refresh"
        onClick={onClickRefresh}
        style={{ marginTop: space200, width: '100%' }}
        Icon={() => <ArrowRotateRight colorType="brand" />}
      />
    </View>
  );
};
export default CommunityFeedConnectionError;
