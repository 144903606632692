import { WizardGenericActions } from 'stepWizard';
import { OtherWizardGenericActions } from '@/utils/genericWizardActions';
import { State } from '../reducers/emergencyContactWizardState';

const initState: WizardGenericActions<State>['initState'] = async (_, state) => state;

const persist = async () => {};

const emergencyContactActions: OtherWizardGenericActions<State> = {
  initState,
  persist,
};

export default emergencyContactActions;
