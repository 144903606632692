import { BaseWizardState, WizardGenericActions, StepWizardApiHelper } from 'stepWizard';
import { AdditionalMetaData } from 'stepWizard/reducers/wizardState';
import ReactFrameService from '../auth/reactFrame/ReactFrameService';
import { ClosePopupAction } from '../auth/reactFrame/ReactFrameTypes';
import { useCloseModal } from './ModalsContextProvider';

export const closeWizardAction =
  (closeModal: ReturnType<typeof useCloseModal>) =>
  (roomID?: number, shouldNavigateToRoom?: boolean, additionalMetaData?: AdditionalMetaData) => {
    const reactFrameService = ReactFrameService.instance();
    const closingPayload = roomID
      ? {
          navigateTo: 'room',
          metadata: { roomID, additionalMetaData },
        }
      : {
          navigateTo: 'dashboard',
          metadata: { additionalMetaData },
        };
    if (reactFrameService.isInFrame() && closingPayload) {
      reactFrameService.closePopup(closingPayload as ClosePopupAction);
    } else if (shouldNavigateToRoom && roomID) {
      closeModal(closingPayload as ClosePopupAction);
    } else closeModal();
  };

export type OtherWizardGenericActions<
  T extends BaseWizardState,
  R extends StepWizardApiHelper = StepWizardApiHelper
> = Omit<WizardGenericActions<T, R>, 'closeWizardAction'>;

export const useGenericWizardActions = <T extends BaseWizardState, R extends StepWizardApiHelper>(
  otherActions: OtherWizardGenericActions<T, R>
): WizardGenericActions<T, R> => {
  const closeModal = useCloseModal();

  return {
    closeWizardAction: closeWizardAction(closeModal),
    ...otherActions,
  };
};
