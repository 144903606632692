import Svg, { Circle, Defs, Path, Rect, Stop } from 'svgs';

const EmergencyContact = () => (
  <Svg width="240" height="299" viewBox="0 0 240 299" fill="none">
    <Circle cx="120" cy="157" r="120" fill="url(#paint0_linear_408_30156)" />
    <Path
      d="M182.841 94.6019C181.452 94.0041 176.476 91.4883 174.162 90.3052L175.092 99.646C183.523 101.514 184.576 95.3492 182.841 94.6019Z"
      fill="#FFC6A7"
    />
    <Path
      d="M184.205 115.152C182.667 114.205 177.489 110.108 175.092 108.177C173.914 110.668 171.918 115.999 173.356 117.393C175.154 119.137 178.377 119.075 181.353 118.888C184.329 118.701 186.126 116.335 184.205 115.152Z"
      fill="#FFC6A7"
    />
    <Path
      d="M183.089 130.533C182.196 129.736 177.716 125.966 175.588 124.181L173.356 131.965C174.1 132.339 176.282 133.148 179.059 133.397C182.531 133.709 184.205 131.529 183.089 130.533Z"
      fill="#FFC6A7"
    />
    <Path
      d="M83.3459 104.69C85.1312 95.8224 88.2225 88.3747 89.545 85.7593C111.407 116.895 154.486 182.704 151.907 196.852C148.684 214.538 142.733 219.27 135.542 236.706C129.789 250.655 133.971 275.73 136.782 286.524C136.782 286.524 130.583 293 106.53 293C82.478 293 76.031 286.524 76.031 286.524C79.5025 242.435 79.3785 226.245 79.8744 214.413C80.3703 202.581 76.527 172.815 76.031 156.625C75.5351 140.434 81.1143 115.774 83.3459 104.69Z"
      fill="#FFC6A7"
    />
    <Rect x="88.9248" y="29.3408" width="87.0347" height="167.262" fill="#4D546B" />
    <Rect x="88.9248" y="20" width="87.0347" height="167.262" fill="#68768D" />
    <Rect x="94.1323" y="32.8281" width="76.8682" height="148.083" fill="white" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M104.5 109.5C104.5 108.672 105.172 108 106 108H158.5C159.328 108 160 108.672 160 109.5C160 110.328 159.328 111 158.5 111H106C105.172 111 104.5 110.328 104.5 109.5Z"
      fill="#8A98AF"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M104.5 118C104.5 117.172 105.172 116.5 106 116.5H158.5C159.328 116.5 160 117.172 160 118C160 118.828 159.328 119.5 158.5 119.5H106C105.172 119.5 104.5 118.828 104.5 118Z"
      fill="#8A98AF"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M104.5 128C104.5 127.172 105.172 126.5 106 126.5H149.5C150.328 126.5 151 127.172 151 128C151 128.828 150.328 129.5 149.5 129.5H106C105.172 129.5 104.5 128.828 104.5 128Z"
      fill="#8A98AF"
    />
    <Circle cx="132.5" cy="73.5" r="22.5" fill="url(#paint1_linear_408_30156)" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M143.95 81.321C143.109 77.5141 140.478 74.7046 137.015 73.4295C138.85 71.9823 139.92 69.6419 139.527 67.0795C139.071 64.1067 136.547 61.7279 133.5 61.3811C129.293 60.9017 125.725 64.109 125.725 68.1335C125.725 70.2755 126.739 72.184 128.319 73.4295C124.855 74.7046 122.226 77.5141 121.383 81.321C121.078 82.7048 122.216 84.0002 123.661 84.0002H141.672C143.118 84.0002 144.257 82.7048 143.95 81.321Z"
      fill="#6159D1"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M115.829 26.6007C115.829 26.3256 116.051 26.1025 116.325 26.1025H148.684C148.958 26.1025 149.18 26.3256 149.18 26.6007C149.18 26.8758 148.958 27.0989 148.684 27.0989H116.325C116.051 27.0989 115.829 26.8758 115.829 26.6007Z"
      fill="#4D546B"
    />
    <Path
      d="M88.0585 155.762C83.7936 160.743 82.81 163.317 82.8513 163.982V207.447L123.517 208.07C124.385 185.403 121.657 176.062 118.806 162.985C115.954 149.908 117.814 124.377 120.17 114.538C122.525 104.699 123.145 92.4934 121.533 88.2589C119.922 84.0244 117.07 84.5226 115.706 85.768C114.342 87.0135 105.044 115.285 102.316 124.377C99.5888 133.468 93.3897 149.534 88.0585 155.762Z"
      fill="#FFC6A7"
    />
    <Path
      d="M113.174 90.9635C114.166 87.3766 115.654 82.8266 116.274 81C116.914 81.6227 118.443 83.5781 119.435 86.4177C120.675 89.9672 117.948 93.2053 115.778 96.9416C113.608 100.678 112.12 99.6193 111.252 98.3739C110.385 97.1284 111.934 95.4471 113.174 90.9635Z"
      fill="#C28C71"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M124.151 196.604C124.502 196.623 124.772 196.941 124.755 197.315C124.625 200.076 124.051 204.689 122.752 209.613C121.455 214.531 119.421 219.816 116.34 223.875C116.119 224.167 115.719 224.212 115.445 223.976C115.172 223.741 115.13 223.314 115.35 223.023C118.278 219.165 120.253 214.077 121.527 209.246C122.8 204.422 123.358 199.909 123.483 197.247C123.501 196.873 123.8 196.585 124.151 196.604Z"
      fill="#C28C71"
    />
    <Path
      d="M76 285C86.1667 291.167 111.8 300.3 133 287.5"
      stroke="#3B54C6"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <Circle cx="76" cy="284" r="6" fill="#F460A1" />
    <Circle cx="89" cy="290" r="6" fill="#F460A1" />
    <Circle cx="103" cy="293" r="6" fill="#F460A1" />
    <Circle cx="118" cy="293" r="6" fill="#F460A1" />
    <Circle cx="133" cy="287" r="6" fill="#F460A1" />
    <Path
      d="M190 23.4563C201.825 18.1835 204.187 5.62175 203.889 0C205.952 17.0591 212.156 23.1978 215 24.1348C205 25.6856 201.706 36.0244 201.31 41C200.119 25.0265 193.274 22.6485 190 23.4563Z"
      fill="#BCCCFF"
    />
    <Path
      d="M26 184.802C39.628 179.964 43.5082 167.585 43.7448 162C45.3671 177.545 51.9242 185.925 55 188.172C43.5622 188.648 39.9592 201.589 39.5874 208C38.0462 193.01 29.8869 186.289 26 184.802Z"
      fill="#BCCCFF"
    />
    <Defs>
      <linearGradient
        id="paint0_linear_408_30156"
        x1="0"
        y1="157"
        x2="240"
        y2="157"
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#E4F2F5" />
        <Stop offset="1" stopColor="#E7F5E1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_408_30156"
        x1="132.5"
        y1="51"
        x2="132.5"
        y2="96"
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#AAA5EA" />
        <Stop offset="1" stopColor="#897FF4" />
      </linearGradient>
    </Defs>
  </Svg>
);

export default EmergencyContact;
