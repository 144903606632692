enum IC_CONTENT_TYPE {
  TITLE = 'title',
  PARAGRAPH = 'paragraph',
  DECLARATION = 'declaration',
}

const { TITLE, PARAGRAPH, DECLARATION } = IC_CONTENT_TYPE;

const IC_CONTENT = [
  {
    type: DECLARATION,
    message: `If you are in a life threatening situation of experiencing a medical emergency, you should dial 911 or 988 and/or use https://helpnow.talkspace.com for immediate help.`,
  },
  { type: TITLE, message: `Informed Consent` },
  {
    type: PARAGRAPH,
    message: `By creating a Talkspace account by clicking “Create account”, you confirm you are 13 years of age or older, have reviewed the risks and consent to treatment using the Talkspace platform.`,
  },
  {
    type: PARAGRAPH,
    message: `Telehealth services involve the delivery of mental health services using electronic communications between a Provider and you, who are not in the same physical location.`,
  },
  {
    type: PARAGRAPH,
    message: `The purpose of this consent is to provide you with the potential risks and limitations of receiving treatment through telehealth and to obtain your informed consent to receive healthcare services from Talkspace providers or prescribers using the Talkspace platform. This consent for treatment continues throughout the course of your treatment by any Provider on the Talkspace platform. `,
  },
  {
    type: PARAGRAPH,
    message: `If you have questions about the consent please reach out to privacy@talkspace.com.`,
  },
  {
    type: PARAGRAPH,
    message: `I understand that the Services include, but are not limited to, consultation and treatment using audio, video, written, and/or data communications.`,
  },
  {
    type: PARAGRAPH,
    message: `I understand that my communication with a Provider using audio, video, written, and/or data communications, including communication of my medical and mental health information, is recorded in a transcript in my medical record documentation that is used to support my Provider’s evaluation and treatment plan.`,
  },
  {
    type: PARAGRAPH,
    message: `I understand that if I have signed up for Talkspace therapy services, if I want a "real time"  video, audio, or chat session or instant message style discussion with my therapist, I will need to schedule it. Otherwise, my therapist and I will communicate through messaging not as instant messages or in real time.`,
  },
  {
    type: PARAGRAPH,
    message: `I understand that my Provider will assess if telehealth services are right for me and may not be able to provide certain services to me. My Provider, in their clinical judgment, may recommend additional or alternative services, and may also refer me to another provider or type of care, such as seeing a medical doctor for further evaluation and treatment.`,
  },
  {
    type: PARAGRAPH,
    message: `I understand and acknowledge I have the right to withdraw my consent to Services at any time without affecting my right to future care or treatment by Talkspace and its Providers, nor risk the loss or withdrawal of any health benefits to which I would otherwise be entitled.`,
  },
  {
    type: PARAGRAPH,
    message: `I understand that the records concerning any Services I receive from Talkspace may be shared with my reported Primary Care Physician (“PCP”). I understand I may notify Talkspace via email to privacy@talkspace.com, at any time, that I withdraw consent to share this information with my PCP.`,
  },
  {
    type: PARAGRAPH,
    message: `I understand and acknowledge the location of my Services is the state where my Provider is licensed and physically located, even when I am communicating with my Provider by digital or text message platforms.`,
  },
  {
    type: PARAGRAPH,
    message: `I understand that I will be asked to provide “Emergency Contact Information” (name and telephone number) for your personal contact and/or a close family member/relation to your Provider to be used in case of a mental health crisis or other emergency where knowledge of your location is crucial and you agree to obtain their consent before providing their Contact Information to your Therapist.`,
  },
  {
    type: PARAGRAPH,
    message: `I understand that It is not recommended that I suddenly stop contacting my provider or "drop out" without talking to my provider about the termination of my treatment. Providers and clients talk about the expected length of treatment and ending the provider-client relationship.`,
  },
  { type: TITLE, message: `Potential Risks` },
  {
    type: PARAGRAPH,
    message: `By participating in the Services, I understand there are potential risks with this or any treatment. These risks include, but may include limitations on providing services through telehealth, confidentiality and data privacy/protection.`,
  },
  { type: TITLE, message: `Telehealth Limitations` },
  {
    type: PARAGRAPH,
    message: `I understand that telehealth may limit my Provider(s) from identifying the need for emergency medical care or treatment for you due to lack of in-person treatment and that the use of technology may impact my Provider(s) ability to correctly diagnose or treat me.`,
  },
  { type: TITLE, message: `Confidentiality` },
  {
    type: PARAGRAPH,
    message: `I understand that the laws that protect privacy and the confidentiality of healthcare information, including HIPAA, apply to telehealth services.`,
  },
  {
    type: PARAGRAPH,
    message: `I understand that there may be times when my provider must or should report my healthcare information to others for safety or legal reasons.`,
  },
  { type: TITLE, message: `Data Privacy and Protection` },
  {
    type: PARAGRAPH,
    message: `I understand that the electronic systems used in telehealth include features to protect the privacy and security of my healthcare information, and will include protections against intentional or unintentional breaches. I understand that these protections could fail, causing a breach of privacy of my medical or other information.`,
  },
  {
    type: PARAGRAPH,
    message: `I understand that personal information that identifies me or contains protected health information will not be shared to any third party without my consent, except as authorized by law for the purposes of treatment, payment/billing, and certain administrative activities as a part of healthcare operations, or as otherwise described in Talkspace’s Notice of Privacy Practices.`,
  },
  {
    type: TITLE,
    message: `Teen Consent`,
  },
  {
    type: PARAGRAPH,
    message: `Each state has its own requirements regarding the consent of treatment for minors. As appropriate to applicable law, the following applies for parents or legal guardians completing an informed consent for a minor between the ages of 13 and 17 (“teen”).‍`,
  },
  {
    type: PARAGRAPH,
    message: `I acknowledge I am the parent or legal guardian of the teen who will be using Talkspace Services.`,
  },
  {
    type: PARAGRAPH,
    message: `I understand that in consenting to treatment for my teen, I agree and understand the terms set forth above, as they apply to my teen. `,
  },
  {
    type: PARAGRAPH,
    message: `I understand that my teen is the client of the Provider (and not me).`,
  },
  {
    type: PARAGRAPH,
    message: `I understand and acknowledge that I do not have access to my teen’s Provider or, where applicable, the teen’s medical record documentation, including transcripts, without my teen’s written permission.`,
  },
  {
    type: PARAGRAPH,
    message: `I understand the Provider will explain your rights and limitations to access of information concerning my teen’s treatment and will make this information available to my teen.`,
  },
  {
    type: PARAGRAPH,
    message: `I also understand additional information concerning my rights and limitation to access is available on Talkspace.com at the FAQ page.`,
  },
];

export { IC_CONTENT, IC_CONTENT_TYPE };
