import { View } from '@talkspace/react-toolkit';

import CardLoader from './CardLoader';

const ForYouCardLoader = () => (
  <View>
    <CardLoader cardVariant="noBorder" />
    <CardLoader cardVariant="noBorder" />
    <CardLoader cardVariant="noBorder" />
  </View>
);

export default ForYouCardLoader;
