import * as React from 'react';
import {
  View,
  ExtraHuge,
  Button,
  AssessmentHead,
  Large,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import { useA11y } from './WelcomeScreen.a11y';

const Wrapper = styled(View)(({ theme: { window } }) => {
  return {
    alignSelf: 'center',
    justifyContent: 'center',
    position: 'relative',
    transition: 'left .2s',
    width: window.isMobile ? 330 : 420,
    alignItems: 'center',
    ':focus': {
      outline: 'none',
    },
  };
});

const StyledButton = styled(Button)(({ theme: { colors } }) => {
  return {
    transition: 'all 200ms ease-in-out',
    fontWeight: 700,
    '&:not([disabled]):hover': {
      backgroundColor: colors.permaTropicalRainForest,
    },
  };
});

const WelcomeScreen = ({
  handleStartButton,
  title = 'Take the test with the following in mind',
  prompt,
  Citation,
}: {
  handleStartButton: () => void;
  title?: string;
  prompt: string;
  Citation?: React.FC;
}) => {
  const { colors } = useEmotionTheme();
  const { titleID, promptID, wrapperRef } = useA11y();
  return (
    <Wrapper as="main" tabIndex={-1} aria-labelledby={titleID} ref={wrapperRef}>
      <AssessmentHead />
      <ExtraHuge as="h1" id={titleID} style={{ textAlign: 'center', marginBottom: 23 }}>
        {title}
      </ExtraHuge>
      <Large
        id={promptID}
        as="h2"
        style={{ textAlign: 'center', color: colors.darkGray }}
        variant="largeDarkGrey"
      >
        {prompt}
      </Large>
      <StyledButton
        aria-describedby={promptID}
        text="Continue"
        style={{ marginTop: 80, marginBottom: 25, width: '100%', fontWeight: 700 }}
        onPress={handleStartButton}
        roundedFocusStyle
        primaryColor={colors.green}
      />
      {Citation && <Citation />}
    </Wrapper>
  );
};

export default WelcomeScreen;
