import { useMutation } from 'react-query';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

interface ReactiveRoomMutateVariables {
  roomID: number;
}

const reactivateRoom = async (params: ReactiveRoomMutateVariables) => {
  const { roomID } = params;
  const data = await apiWrapper.post(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/activate-room`);
  return data.data;
};

const useMutationActivateRoom = () =>
  useMutation<void, Error, ReactiveRoomMutateVariables>(reactivateRoom);

export default useMutationActivateRoom;
