import { useEffect } from 'react';
import { useUniqueID, useModalActions, useContainerFocus } from '@talkspace/react-toolkit';

export const useA11y = () => {
  const { setModalTitle } = useModalActions();
  const titleText = 'Refer a friend';

  useEffect(() => {
    setModalTitle(titleText);
  }, [setModalTitle]);

  const titleTextId = useUniqueID('titleTextId');
  const boldTitleTextId = useUniqueID('boldtitleTextId');
  const shareTextId = useUniqueID('shareTextId');
  const disclaimerId = useUniqueID('disclaimerId');
  const { containerRef } = useContainerFocus();

  return { titleText, titleTextId, boldTitleTextId, shareTextId, disclaimerId, containerRef };
};

export default useA11y;
