import { FunctionComponent, useEffect } from 'react';
import WelcomeBackCTReactivation from '../components/WelcomeBackCTReactivation/WelcomeBackCTReactivation';
import roomReactivationData from '../utils/previousPrimaryTherapistsAvailable';
import { getUserData } from '../../auth/helpers/token';
import { trackTalkspacePageView } from '../../utils/analytics/events';

const WelcomeBackCTReactivationContainer: FunctionComponent<{ roomID: number }> = ({ roomID }) => {
  const { lastActiveRoomID, lastActiveRoomType } = roomReactivationData.ReadCache(
    getUserData().id,
    roomID
  );

  useEffect(() => {
    trackTalkspacePageView('Reactivation', 'CT Reactivation', {
      Flow: 'CT Reactivation',
    });
  }, []);

  return (
    <WelcomeBackCTReactivation
      roomID={roomID}
      lastActiveRoomID={lastActiveRoomID}
      lastActiveRoomType={lastActiveRoomType}
    />
  );
};

export default WelcomeBackCTReactivationContainer;
