import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const MiniNotepad = ({
  width = 16,
  height = 17,
  color,
  ...props
}: {
  width?: number;
  height?: number;
  color?: string;
}) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg width={width} height={height} viewBox="0 0 16 17" {...props}>
      <Path
        d="M10.857 2.214V1.1a1.1 1.1 0 011.1-1.1h.014a1.1 1.1 0 011.1 1.1v1.121c0 .033-.001.065-.004.096a3.001 3.001 0 012.219 2.897v8.179a3 3 0 01-3 3H3a3 3 0 01-3-3V5.214c0-1.386.94-2.553 2.218-2.897a1.114 1.114 0 01-.004-.096V1.1a1.1 1.1 0 011.1-1.1h.015a1.1 1.1 0 011.1 1.1v1.114h6.428zM9.65 6.38l-3.27 3.767-1.303-1.027a1.1 1.1 0 00-1.362 1.728l2.95 2.325 4.646-5.35a1.1 1.1 0 10-1.66-1.443z"
        transform="translate(-729 -1053) translate(729 1051) translate(0 2)"
        fill={color || colors.purple}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default MiniNotepad;
