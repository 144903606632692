import {
  StickyDrawer,
  TextDS,
  View,
  Link,
  StickyDrawerParentContainer,
  OneColumnLayout,
} from '@talkspace/react-toolkit';
import { useState } from 'react';
import {
  ArrowRotateRight,
  Ban,
  MessageHeart,
  UserXMark,
  FlagPennant,
} from '@talkspace/react-toolkit/src/designSystems/icons';
import { useHistory } from 'react-router';
import PenToSquareFilled from '../../assets/icons/PenToSquareFilled';
import GuidelineItem from './GuideLineItem';
import styled from '../../../core/styled';
import useMutationPutCommunityUserData from '../../hooks/useMutationPutCommunityUserData';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { trackCommunityOnboardingCTAPressed } from '../../utils/analytics';
import { useCommunityState } from '../../hooks/CommunityContext';
import TeenspaceContinueButton from './TeenspaceContinueButton';

const StyledLink = styled(Link)(({ theme: { colorRoles } }) => {
  return {
    textDecorationLine: 'underline',
    color: colorRoles.typography.textBrandDefault,
  };
});
const StyledBody = styled(TextDS)(({ theme: { colorRoles, spacing } }) => {
  return {
    textAlign: 'center',
    color: colorRoles.typography.textSubtle,
    marginTop: spacing('space100'),
    marginBottom: spacing('space500'),
    width: '100%',
  };
});
const StyledHeader = styled(TextDS)(({ theme: { window, spacing } }) => {
  const { isMobile } = window;
  return {
    textAlign: 'center',
    marginTop: isMobile ? spacing('space400') : spacing('space800'),
    width: '100%',
  };
});

const GuidelineAgreement = styled(TextDS)(({ theme: { colorRoles, spacing } }) => {
  return {
    marginBottom: spacing('space200'),
    color: colorRoles.typography.textSubtlest,
    textAlign: 'center',
  };
});

const GuidelineView = styled(View)(({ theme: { spacing } }) => {
  return {
    marginBottom: spacing('space150'),
  };
});

const moderatorGuidelineText =
  'Talkspace moderates posts to ensure content adheres to our community guidelines. If, at our sole discretion, Talkspace believes that your posts indicate an imminent threat of harm to yourself or to another person we may reach out to the emergency contact you provided during intake, or emergency services.';

const therapyReplacementText = (
  <TextDS dataQa="teenspaceImmediateHelpNumberLink" colorRole="textSubtle" variant="bodySm">
    Teenspace Community is not therapy. Use your Talkspace therapy room for therapy. If you’re in
    crisis, don’t use this app. Call or text
    <StyledLink href="tel:988" text="988" />
    for immediate help.
  </TextDS>
);

const fullCommunityGuidelinesText = (
  <TextDS dataQa="teenspaceCommunityGuidelinesLink" colorRole="textSubtle" variant="bodySm">
    <StyledLink
      target="_blank"
      href="https://assets.talkspace.com/public/TeenspaceCommunityGuidelines.pdf"
      text="Read the full community guidelines"
      stopPropagation
    />
  </TextDS>
);

const GUIDELINES = [
  {
    title: 'Don’t use as therapy replacement',
    description: therapyReplacementText,
    icon: UserXMark,
  },
  {
    title: 'Be a contributor',
    description:
      'Join your peers in creating a safe space for honesty and connection. Use your voice to support and encourage others.',
    icon: MessageHeart,
  },
  {
    title: 'No discrimination',
    description:
      'Posts containing hate or discrimination based on race, ethnicity, gender, sexual orientation, or disability are not allowed.',
    icon: Ban,
  },
  {
    title: 'Post appropriately',
    description:
      'Ensure that your posts are relevant to the daily topic and don’t contain identifying, illegal, explicit, or promotional content.',
    icon: PenToSquareFilled,
  },
  {
    title: 'Report inappropriate content',
    description: 'Please flag any content or behavior that violates our community guidelines.',
    icon: FlagPennant,
  },
  {
    title: undefined,
    description: moderatorGuidelineText,
    icon: undefined,
  },
  {
    title: undefined,
    description: fullCommunityGuidelinesText,
    icon: undefined,
  },
];
const GUIDELINE_AGREEMENT =
  'By continuing you agree that the Teenspace community is not therapy and consent to these community guidelines';
const Guidelines = () => {
  const { isConnected } = useCommunityState();
  const history = useHistory();
  const { mutate: putCommunityUserData } = useMutationPutCommunityUserData();
  const closeModal = useCloseModal();
  const showCreatePost =
    history.location.pathname === '/community/teenspace/onboarding/guidelines/continue';
  const [isOnboardingError, setIsOnboardingError] = useState(false);

  const onContinuePress = async () => {
    trackCommunityOnboardingCTAPressed({ pageName: 'teenspaceCommunityGuidelines' });
    putCommunityUserData(
      { onboardedTeenspaceAt: String(new Date()) },
      {
        onSuccess: (data) => {
          if (data) {
            isConnected && showCreatePost
              ? closeModal({ navigateTo: 'createTeenspacePost' })
              : closeModal();
          }
        },
        onError: (err) => {
          if (err.data?.status !== 200) {
            setIsOnboardingError(true);
          }
        },
      }
    );
  };
  return (
    <View align="center" style={{ height: '100%', width: '100%', maxWidth: undefined }}>
      <StickyDrawerParentContainer style={{ width: '100%' }}>
        <View align="center">
          <StyledHeader variant="heading2xl">Please read our community guidelines</StyledHeader>
          <StyledBody variant="body">
            Here's how you can help us create a truly supportive space for teens to share stories
          </StyledBody>
        </View>
        <OneColumnLayout lg={12} xl={12} sm={8} md={8}>
          {GUIDELINES.map((guideline) => (
            <GuidelineItem guideline={guideline} />
          ))}
        </OneColumnLayout>
        <StickyDrawer contentWrapperStyle={{ padding: '24px' }}>
          <OneColumnLayout>
            <View align="center" justify="center">
              <TeenspaceContinueButton
                size="large"
                stretch
                sizeDS="medium"
                variant="primary"
                onPress={onContinuePress}
                Icon={
                  isOnboardingError ? () => <ArrowRotateRight colorType="inverse" /> : () => <></>
                }
                text={isOnboardingError ? 'Try again' : 'Continue'}
                dataQa="teenspaceCommunityGuidelineContinueButton"
              />

              <GuidelineView align="center">
                <GuidelineAgreement variant="bodyXs">{GUIDELINE_AGREEMENT}</GuidelineAgreement>
              </GuidelineView>
            </View>
          </OneColumnLayout>
        </StickyDrawer>
      </StickyDrawerParentContainer>
    </View>
  );
};

export default Guidelines;
