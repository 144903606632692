import SocketService from '../../utils/socket/SocketService';

type Listener = (isUserEventsSocketConnected: boolean) => void;

export default class UserEventsSocketService {
  pollingInterval: number | undefined;

  pollingIntervalTime = 0;

  registerRoomInitTimeout: number | undefined;

  io: SocketService = SocketService.instance();

  isUserEventsSocketConnected = false;

  isMounted = false;

  userEventsListeners: Listener[] = [];

  constructor(pollingIntervalTimeSec = 0) {
    if (pollingIntervalTimeSec > 0)
      this.pollingIntervalTime = Math.max(2, pollingIntervalTimeSec) * 1000;
    this.socketInit();
  }

  private socketInit = () => {
    this.isMounted = true;
    this.io.addStateListener(this.socketStateChange);
  };

  private startPolling = (): void => {
    if (this.pollingInterval) return;
    this.notifyListener();
    if (!this.pollingIntervalTime) return;
    this.pollingInterval = window.setInterval(() => {
      this.notifyListener();
    }, this.pollingIntervalTime);
  };

  private stopPolling = () => {
    clearInterval(this.pollingInterval);
    this.pollingInterval = undefined;
    // make a last call incase a message was entered during the register
    if (this.isMounted) this.notifyListener();
  };

  private socketRegisterRoomInit = () => {
    if (this.registerRoomInitTimeout) {
      clearTimeout(this.registerRoomInitTimeout);
      this.registerRoomInitTimeout = undefined;
    }

    this.registerRoomEvents()
      .then(() => {
        this.isUserEventsSocketConnected = true;
        this.stopPolling();
      })
      .catch(() => {
        this.isUserEventsSocketConnected = false;
        if (this.isMounted)
          this.registerRoomInitTimeout = window.setTimeout(() => {
            this.socketRegisterRoomInit();
          }, 2000);
      });
  };

  private socketStateChange = (state: boolean) => {
    if (state) this.socketRegisterRoomInit();
    else {
      this.isUserEventsSocketConnected = false;
      this.startPolling();
    }
  };

  public unmount = (shouldUnregisterUser = false) => {
    this.isMounted = false;
    this.userEventsListeners = [];
    this.isUserEventsSocketConnected = false;
    this.stopPolling();
    if (shouldUnregisterUser) this.unregisterRoomEvents().catch(() => undefined);

    this.io.removeStateListener(this.socketStateChange);
  };

  private registerRoomEvents = (): Promise<unknown> => this.io.emit('registerUser', {});

  private unregisterRoomEvents = (): Promise<unknown> => this.io.emit('unregisterUser', {});

  public addListener = (fn: Listener, cbOnInsert = true): void => {
    if (typeof fn !== 'function') return;
    this.userEventsListeners.push(fn);
    if (cbOnInsert) fn(this.isUserEventsSocketConnected);
  };

  public removeListener = (fn: Listener): void => {
    if (typeof fn !== 'function') return;
    const idx = this.userEventsListeners.indexOf(fn);
    if (idx > -1) this.userEventsListeners.splice(idx, 1);
  };

  private notifyListener = () => {
    this.userEventsListeners.forEach((fn) => fn(this.isUserEventsSocketConnected));
  };

  public on = this.io.on;

  public off = this.io.off;
}
