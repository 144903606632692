import { FunctionComponent } from 'react';
import ModalClosingScreen from '../../2storybook/components/ModalClosingScreen';
import { RouteComponentProps } from '../../core/routerLib';
import ValidateEmailContainer from '../containers/ValidateEmail';

const ValidateEmail: FunctionComponent<RouteComponentProps> = (props) => (
  <ModalClosingScreen showCloseButton={false}>
    <ValidateEmailContainer {...props} />
  </ModalClosingScreen>
);

export default ValidateEmail;
