import { RoomPlanInfo } from 'ts-frontend/types';
import cancelPromiseHelper from '../../core/api/cancelPromiseHelper';
import apiWrapper from '../../core/api/apiWrapper';
import apiHelper from '../../core/api/apiHelper';

interface APIResponse<T> {
  data: T;
}

export interface InsuranceEligibilityInfo {
  isEligible: boolean;
  copayCents: number;
  maximumCost: number;
}

export interface RoomTherapistInfo {
  therapistID: number;
  firstName: string;
}

export default class InsuranceEligibilityAPIHelper {
  private wrapWithCancel: (req: Promise<any>) => Promise<any>;

  public reset: () => void;

  public dismissIfCancelled: (err: Error) => any;

  public cancelAll: () => void;

  constructor() {
    const { cancelAll, reset, dismissIfCancelled, wrapWithCancel } = cancelPromiseHelper();
    this.wrapWithCancel = wrapWithCancel;
    this.reset = reset;
    this.dismissIfCancelled = dismissIfCancelled;
    this.cancelAll = cancelAll;
  }

  /**
   * api call to: `/v2/rooms/${roomID}/verify-insurance-eligibility`
   * @returns Promise
   */
  public verifyInsuranceEligibility = (
    roomID: number
  ): Promise<APIResponse<InsuranceEligibilityInfo>> =>
    this.wrapWithCancel(
      apiWrapper.post(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/verify-insurance-eligibility`)
    ).then((res) => res.data);

  /**
   * api call to: `/v3/rooms/${roomID}/purchase-bh-session`
   * @returns Promise
   */
  public purchaseBHSession = (roomID: number, cardToken?: string): Promise<void> =>
    this.wrapWithCancel(
      apiWrapper.post(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/purchase-bh-session`, {
        creditToken: cardToken,
      })
    ).then((res) => res.data);

  /**
   * api call to: `/v2/rooms/${roomID}/plan`
   * @returns Promise
   */
  public getRoomPlan(roomID: number): Promise<APIResponse<RoomPlanInfo>> {
    return this.wrapWithCancel(
      apiWrapper.get(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/plan`)
    ).then((res) => res.data);
  }
}
