import { useCallback, useEffect } from 'react';
import { useWizardActions, useWizardState, persistData } from 'stepWizard';
import { getUserData } from '@/auth/helpers/token';
import { useHistory } from '@/core/routerLib/routerLib';
import InPlatformMatchConfirm from '../../clientChat/components/InPlatformMatchConfirm/index';
import useSwitchWizard from '../hooks/useSwitchWizard';
import { useMainActions } from '../../hooks/mainContext';
import ActionStatus from '../../components/ActionStatus/ActionStatus';
import InRoomSchedulingWrapper from '../../components/InRoomSchedulingWrapper';
import {
  popIsOnboardingDismissed,
  popIsOnboardingDismissedTestAccount,
} from '../../onboarding/util/onboardingStorage';
import { State } from '../reducers/switchWizardState';

interface TherapistConfirmationContainerProps {
  isV2?: boolean;
  handleExitModal?: () => void;
}

const TherapistConfirmationContainer = ({
  isV2 = true,
  handleExitModal = () => {},
}: TherapistConfirmationContainerProps) => {
  const history = useHistory();
  const [state, { switchTherapist }] = useSwitchWizard();
  const { error, isSuccess, isLoading, canBookIntroSession, confirmedTherapist } = state;
  const { setState } = useWizardActions();
  const { getAllRooms } = useMainActions();
  const { id: userID } = getUserData();
  const {
    roomID,
    newTherapistMatch,
    informedConsentGiven,
    isMessageHistoryShared,
    responses,
    persisted,
  } = useWizardState<State>();

  const handlePersist = useCallback(() => {
    persistData(responses, setState, 'Therapist switch confirmation', error, {}, isSuccess);
  }, [error, isSuccess, responses, setState]);

  useEffect(() => {
    if (isSuccess && persisted) {
      if (isV2) {
        history.push('/switch-provider/success', { roomID });
      } else if (!canBookIntroSession) {
        handleExitModal();
      }
    }
  }, [handleExitModal, isSuccess, persisted, canBookIntroSession, history, isV2, roomID]);

  useEffect(() => {
    if (isSuccess && persisted) {
      popIsOnboardingDismissed(roomID);
      popIsOnboardingDismissedTestAccount(roomID);
    }
  }, [isSuccess, persisted, userID, roomID, getAllRooms]);

  useEffect(() => {
    // persisting the data on error or success
    const handleRequestStatus = async () => {
      if (isSuccess || error) {
        handlePersist();
      }
    };
    handleRequestStatus();
  }, [isSuccess, error]); // eslint-disable-line

  const handleOnConfirmSwitchPress = useCallback(async () => {
    if (newTherapistMatch) {
      switchTherapist(
        roomID,
        newTherapistMatch.therapistID,
        informedConsentGiven,
        isMessageHistoryShared
      );
    }
  }, [informedConsentGiven, isMessageHistoryShared, newTherapistMatch, roomID, switchTherapist]);

  if (canBookIntroSession && confirmedTherapist) {
    return (
      <InRoomSchedulingWrapper
        hideHeader={canBookIntroSession}
        bookWithIntroSession={canBookIntroSession}
      />
    );
  }

  if (error || isLoading || isSuccess) {
    return (
      <>
        <ActionStatus
          isLoading={isLoading || isSuccess}
          isError={!!error}
          errorTitle="Something Went Wrong"
          errorSubTitle="Please check your internet connection and try again."
          errorButtonText="Close"
          errorButtonAction={handleExitModal}
          titleStyles={{ fontWeight: 800, fontSize: 29, marginTop: 25 }}
          subTitleStyles={{
            fontSize: 16,
            color: '#454A5E',
            marginTop: 13,
            marginBottom: 42,
            width: 200,
            textAlign: 'center',
          }}
        />
      </>
    );
  }

  return (
    <>
      <InPlatformMatchConfirm
        canBookIntroSession={canBookIntroSession}
        switchTherapist={handleOnConfirmSwitchPress}
        therapistName={newTherapistMatch ? newTherapistMatch.therapistFirstName : ''}
        therapistImageURL={newTherapistMatch ? newTherapistMatch.therapistImage : ''}
        therapistID={newTherapistMatch ? newTherapistMatch.therapistID : null}
        numberOfMatches={1}
        handleSecondaryClick={() => undefined}
        goBackToMatches={() => undefined}
        shouldExplanationBlockAppear={false}
        isV2={isV2}
      />
    </>
  );
};

export default TherapistConfirmationContainer;
