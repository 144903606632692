import moment from 'moment';
import { OptionType } from '@talkspace/react-toolkit';
import { formatCurrency } from 'ts-frontend/helpers/billingUtils';

// eslint-disable-next-line import/prefer-default-export
export const keyValueToOptionType = (obj: object): OptionType<string | number>[] =>
  Object.keys(obj).map((key) => {
    const { createdAt, currency, payedAmount, displayName } = obj[key];
    const year = moment(createdAt).format('MMM DD, YYYY');
    const price = formatCurrency(payedAmount, currency);
    const label = `${year} - ${price} - ${displayName}`;
    return { label, value: +key };
  });
