import { TherapistReviewData } from 'ts-frontend/types';
import { TherapistInfo } from '../utils/therapistReviewApiHelper';

export interface TherapistReviewState {
  therapistReviewData: Partial<TherapistReviewData>;
  therapist: Partial<TherapistInfo>;
  isTherapistDataReady: boolean;
  isLoading: boolean;
  error: string;
}

export type Action =
  | { type: 'requestPostTherapistReview' }
  | {
      type: 'receivePostTherapistReview';
      payload: Pick<TherapistReviewState, 'therapistReviewData'>;
    }
  | { type: 'requestGetTherapistInfo' }
  | {
      type: 'receiveGetTherapistInfo';
      payload: Pick<TherapistReviewState, 'therapist'>;
    }
  | { type: 'setError'; error: string };

export const initialState: TherapistReviewState = {
  therapistReviewData: {},
  therapist: {},
  isLoading: false,
  isTherapistDataReady: false,
  error: '',
};

export const therapistReviewReducer = (
  state: TherapistReviewState = initialState,
  action: Action
): TherapistReviewState => {
  switch (action.type) {
    case 'requestPostTherapistReview':
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case 'receivePostTherapistReview':
      return {
        ...state,
        isLoading: false,
        error: '',
        ...action.payload,
      };
    case 'requestGetTherapistInfo':
      return {
        ...state,
        isTherapistDataReady: false,
      };
    case 'receiveGetTherapistInfo':
      return {
        ...state,
        isTherapistDataReady: true,
        ...action.payload,
      };
    case 'setError':
      return {
        ...state,
        error: action.error || 'Oops! Something went wrong. Please try again',
        isLoading: false,
      };
    default:
      return state;
  }
};
