import { useEffect, useState, useCallback } from 'react';
import configs from '@/utils/configs';

import { addListenerVWO, removeListenerVWO } from './VWOSetup';
import experiments, {
  ExperimentNames,
  Variant,
  VariantName,
  Experiments,
  getExperimentID,
} from './experiments';

const { debugMode } = configs.analytics.VWO;

export default function useVWO<T1 extends ExperimentNames, T2 extends VariantName<T1>>(
  experimentName: T1,
  defaultKey: T2 = (Object.keys(experiments[experimentName].values) as T2[])[0]
) {
  const constrainedExperiments = experiments as {
    [K1 in T1]: {
      description: Experiments[T1]['description'];
      values: { [K2 in T2]: Experiments[T1]['values'][K2] };
    };
  };
  const experiment = constrainedExperiments[experimentName];
  const experimentID = getExperimentID(experimentName);
  const [variant, setVariant] = useState<[boolean, Variant<T1>, string]>([
    false,
    experiment.values[defaultKey],
    experimentID,
  ]);
  const updateVariant = useCallback(
    (key?: T2, id?: string) => {
      if (key === undefined || key === null) return;

      if (id !== experimentID) return;

      if (!experiment || Object.keys(experiment).length === 0)
        throw new Error('WVO: experiment ID not found');

      if (debugMode) {
        // eslint-disable-next-line no-console
        if (!experiment.values[key]) console.warn('WVO: variant missing in values object');

        // eslint-disable-next-line no-console
        console.log(`WVO: ${experiment.description} variant data:`, experiment.values[key]);
      }

      const overrideVariant = sessionStorage
        ? parseInt(sessionStorage.getItem(experimentName) || '', 10)
        : 0;
      if (!isNaN(overrideVariant) && experiment.values[overrideVariant]) {
        if (debugMode)
          // eslint-disable-next-line no-console
          console.log(
            `WVO: ${experiment.description} OVERRIDE FOUND variant data:`,
            experiment.values[overrideVariant]
          );
        setVariant([true, experiment.values[overrideVariant], experimentID]);
      } else {
        setVariant([true, experiment.values[key], experimentID]);
      }
    },
    [experiment, experimentID, experimentName]
  );

  useEffect(() => {
    const eventHandler = (data) => {
      // data[0] will always contain the event Name
      // Other data parameter changes according to the event
      const experimentId = `VWO-${data[1]}`;
      const variationId = data[2] - 1; // VWO starts the variants array in position 1, we align it.

      if (debugMode) {
        // eslint-disable-next-line no-console
        console.log(`WVO: experiment sdk found id: ${experimentId} variant id: ${variationId}`);
      }
      updateVariant(variationId as T2, experimentId as string);
    };

    addListenerVWO(eventHandler);

    return () => {
      removeListenerVWO(eventHandler);
    };
  }, [experimentID, experimentName, updateVariant]);

  return variant;
}
