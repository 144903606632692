import { useEffect } from 'react';
import UserEventsSocketService from '../clientChat/utils/UserEventsSocketService';

interface UseChatContainerSocketOptions {
  refetchRoomDetails: (options?: any) => Promise<any> | void;
}
const useChatContainerSocket = ({ refetchRoomDetails }: UseChatContainerSocketOptions) => {
  useEffect(() => {
    const refetchRoomDetailsWrappingFunction = () => {
      refetchRoomDetails();
    };
    const userEventsSocketService = new UserEventsSocketService();

    userEventsSocketService.on('roomActivated', refetchRoomDetailsWrappingFunction);
    userEventsSocketService.on('clientDischarged', refetchRoomDetailsWrappingFunction);

    return () => {
      userEventsSocketService.off('roomActivated', refetchRoomDetailsWrappingFunction);
      userEventsSocketService.off('clientDischarged', refetchRoomDetailsWrappingFunction);

      userEventsSocketService.unmount(false);
    };
  }, [refetchRoomDetails]);
};

export default useChatContainerSocket;
