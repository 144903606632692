import { Route, Switch } from 'react-router';
import { useEffect } from 'react';
import WelcomeBackScreen from './screens/WelcomeBack';
import WelcomeBackCTReactivation from './screens/WelcomeBackCTReactivation';
import sessionStorage from '../core/storage/sessionStorage';

const Routes = () => {
  useEffect(() => {
    sessionStorage.setItem('reactivationPopupOpened', 'true');
    sessionStorage.setItem('ctReactivationPopupOpened', 'true');
  }, []);

  return (
    <Switch>
      <Route
        exact
        path="/room-reactivation/ct-reactivation"
        component={WelcomeBackCTReactivation}
      />
      <Route exact path="/room-reactivation/:roomID" component={WelcomeBackScreen} />
      <Route
        exact
        path="/room-reactivation/engagement/:roomID"
        render={(props) => <WelcomeBackScreen {...props} ctEngaged />}
      />
      <Route path="/room-reactivation" component={WelcomeBackScreen} />
    </Switch>
  );
};

export default Routes;
