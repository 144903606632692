import { useEffect, useState, FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { checkEmailValidity } from 'ts-frontend/helpers';
import {
  Password as RHFPassword,
  RouterLinkWrapper,
  Tiny,
  useEmotionTheme,
  View,
  RHFInput,
  Button,
  RHFCheckbox,
  usePasswordValidation,
} from '@talkspace/react-toolkit';
import { PasswordInputMode } from 'ts-frontend/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { getIsIonic } from 'ts-ionic';
import { removeTokens } from '@/auth/helpers/token';
import { useClientAuthActions, useClientAuthState } from '@/hooks/clientAuthContext';
import { StoragePersistanceMode } from '@/core/storage/storage';
import { Link as RouterLink } from '../../../core/routerLib/routerLib';
import storage from '../../../core/storage';
import styled from '../../../core/styled';
import { LoginFieldNames, LoginFields, LoginFormYupContext } from './types';
import { getInviteHashParams } from '../../../roomInvites';

const Form = styled.form();

const SubmitButton = (props) => (
  <Button
    type="submit"
    style={{
      marginTop: 20,
      fontFamily: 'Roboto',
      fontSize: 19,
      fontWeight: 'bold',
      width: 335,
      minHeight: 55,
      borderRadius: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      letterSpacing: 0.74,
      textAlign: 'center',
    }}
    {...props}
  />
);

const DEFAULT_EMAIL_ERROR = 'Please enter an email.';
const loginSchema: yup.SchemaOf<LoginFields> = yup.object().shape({
  email: yup
    .string()
    .test(
      'has-entered-email',
      DEFAULT_EMAIL_ERROR,
      (userInput: LoginFields['email']) => !!userInput
    )
    .test(
      'is-email-valid',
      'Invalid email.',
      (userInput: LoginFields['email']) => !!userInput && checkEmailValidity(userInput)
    )
    .required(DEFAULT_EMAIL_ERROR),
  password: yup
    .string()
    .test(
      'is-password-valid',
      'Please enter a password.',
      (userInput: LoginFields['password'], testContext: yup.TestContext<LoginFormYupContext>) => {
        testContext.options.context?.onSubmitPassword(true);
        return !testContext.options.context?.isPasswordInvalid();
      }
    )
    .required('Please enter a password.'),
  rememberMe: yup.bool().optional(),
});

const LoginForm: FunctionComponent<{
  hasRememberMeCheckbox?: boolean;
  onSubmit?: (loginFormData: LoginFields) => void;
}> = ({ hasRememberMeCheckbox, onSubmit }) => {
  const [clientEmail, setClientEmail] = useState('');
  const [loginAlert, setLoginAlert] = useState(false);
  const [{ isPasswordInvalid, onSubmitPassword }, passwordProps] = usePasswordValidation({
    passwordInputMode: PasswordInputMode.LOGIN,
    email: clientEmail,
  });
  const methods = useForm<LoginFields>({
    resolver: yupResolver(loginSchema),
    context: {
      isPasswordInvalid,
      onSubmitPassword,
    },
    defaultValues: {
      email: '',
      password: '',
      rememberMe: getIsIonic(),
    } as LoginFields,
  });

  const { handleSubmit, watch, setError } = methods;
  const emailValue = watch(LoginFieldNames.email);

  useEffect(() => {
    if (emailValue) setClientEmail(emailValue);
  }, [emailValue, setClientEmail]);

  const { loginAction, loginCognitoAction, clearErrorsAction } = useClientAuthActions();
  const {
    isUpdating,
    isError,
    errorMessage,
    adminConfigs: { isCognitoLoginEnabled },
  } = useClientAuthState();

  // If the login screen mounts, that means we do not have a valid token
  // See src/auth/AuthContainer
  useEffect(() => {
    // Remove tokens from localStorage and sessionStorage.
    storage.setStoragePersistanceMode(StoragePersistanceMode.LOCAL);
    removeTokens();
    storage.setStoragePersistanceMode(StoragePersistanceMode.SESSION);
    removeTokens();
  }, []);

  useEffect(() => {
    if (isError) {
      if (errorMessage === '429') {
        setError(
          LoginFieldNames.email,
          {
            type: 'multiple-failed-attempts',
            message: 'Multiple failed login attempts. Your Account is temporarily locked.',
          },
          { shouldFocus: true }
        );
        setLoginAlert(true);
      } else if (errorMessage === '505') {
        setError(LoginFieldNames.email, {
          type: 'unsupported-version',
          message: 'Version not supported, please reload page.',
        });
        setLoginAlert(true);
        window.location.reload();
      } else if (errorMessage === '401') {
        setError(LoginFieldNames.email, {
          type: 'invalid-credentials',
          message: 'Invalid email or password.',
        });
        setLoginAlert(true);
      } else {
        setError(
          LoginFieldNames.email,
          {
            type: 'missing-or-invalid',
            message: emailValue ? 'Invalid email.' : DEFAULT_EMAIL_ERROR,
          },
          { shouldFocus: true }
        );
      }
    }
  }, [emailValue, errorMessage, isError, setError]);

  const { colors } = useEmotionTheme();
  const inviteHashParams = getInviteHashParams();
  const submitHandler = (loginFormData: LoginFields) => {
    if (isUpdating) return;
    const { email, password, rememberMe } = loginFormData;
    onSubmitPassword(true);
    // Sets  the storage to either local or session depending if the user wants to
    // persist their tokens
    storage.setStoragePersistanceMode(
      rememberMe ? StoragePersistanceMode.LOCAL : StoragePersistanceMode.SESSION
    );
    if (onSubmit) {
      onSubmit(loginFormData);
    } else if (isCognitoLoginEnabled) {
      loginCognitoAction({
        email,
        password,
        options: { invitationKey: inviteHashParams.invitationKey },
      });
    } else {
      loginAction({ email, password, options: { invitationKey: inviteHashParams.invitationKey } });
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(submitHandler)}>
        <View align="center">
          <RHFInput
            fieldName={LoginFieldNames.email}
            label="Email"
            inputType="email"
            isErrorAlert={loginAlert}
            enterKeyHint="next"
          />
          <RHFPassword
            {...passwordProps}
            fieldName={LoginFieldNames.password}
            dataQa="loginPasswordInput"
            outerContainerStyle={{ marginBottom: 0 }}
            enterKeyHint="done"
          />
          <RouterLinkWrapper
            style={{
              marginLeft: 4,
              marginTop: 3,
              width: 'fit-content',
              borderRadius: 5,
              alignSelf: 'start',
            }}
            primaryColor={colors.green}
            roundedFocusStyle
          >
            <RouterLink
              onClick={clearErrorsAction}
              to="/forgot-password"
              data-qa="forgotPasswordLink"
            >
              <Tiny style={{ color: colors.green, fontWeight: 700 }}>Forgot password?</Tiny>
            </RouterLink>
          </RouterLinkWrapper>
          {hasRememberMeCheckbox && !getIsIonic() && (
            <RHFCheckbox
              fieldName={LoginFieldNames.rememberMe}
              label="Remember me"
              containerStyle={{ marginTop: 18 }}
            />
          )}
          <SubmitButton
            roundedFocusStyle
            primaryColor={colors.green}
            isLoading={isUpdating}
            disabled={isUpdating}
            text="Log in"
            dataQa="signInButton"
          />
        </View>
      </Form>
    </FormProvider>
  );
};

export default LoginForm;
