import { useTranslation } from '@talkspace/i18n';
import { OpenBook } from '../../components/icons';
import ForYouCard, { ForYouCardProps } from './ForYouCard';

interface MentalHealthResourcesCardProps
  extends Pick<ForYouCardProps, 'onPress' | 'disabled' | 'dataQa'> {}

const MentalHealthResourcesCard = ({
  onPress,
  disabled,
  dataQa,
}: MentalHealthResourcesCardProps) => {
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  return (
    <ForYouCard
      dataQa={dataQa}
      onPress={onPress}
      disabled={disabled}
      Icon={<OpenBook />}
      titleText={tnewMemberNav('home.mental', 'Mental health resources')}
      bodyText={tnewMemberNav('home.yourSource', 'Your source for tips on becoming your best self')}
    />
  );
};

export default MentalHealthResourcesCard;
