import { useRef, useCallback } from 'react';
import ApiHelper from '../utils/ApiHelper';
import useSimpleReducer, {
  receiveActionPayload,
  errorActionPayload,
  requestActionPayload,
  PayloadBooleans,
} from '../../hooks/useSimpleReducer';

type UserPromptEventType = 'nps' | 'therapist_check_in' | 'post_async_prompt';
type NPSUserPromptMetadata = { daysDelay: number };
type UserPromptMetadata = undefined | NPSUserPromptMetadata;
export interface UserPrompt {
  id: number;
  eventType: UserPromptEventType;
  metadata: UserPromptMetadata;
}

interface UserPromptsState {
  userPrompts: UserPrompt[];
  roomID?: number;
  isLoading: boolean;
  isError: boolean;
}

type UserPromptsAction =
  | { type: 'requestGetUserPrompts'; payload: PayloadBooleans }
  | {
      type: 'receiveGetUserPrompts';
      payload: PayloadBooleans & { userPrompts: UserPrompt[]; roomID: number };
    }
  | { type: 'setIsError'; payload: PayloadBooleans };

const initialState: UserPromptsState = {
  userPrompts: [],
  isLoading: false,
  isError: false,
};

export default function useUserPrompts(): [
  UserPromptsState,
  {
    getUserPrompts: (roomID: number) => void;
  }
] {
  const apiRef = useRef(new ApiHelper());
  const [state, dispatch] = useSimpleReducer<UserPromptsState, UserPromptsAction>(initialState);

  function getUserPrompts(roomID: number) {
    dispatch({
      type: 'requestGetUserPrompts',
      payload: requestActionPayload,
    });
    apiRef.current
      .getUserPrompts(roomID)
      .then((res) => {
        if (res.data) {
          dispatch({
            type: 'receiveGetUserPrompts',
            payload: {
              userPrompts: res.data,
              roomID,
              ...receiveActionPayload,
            },
          });
        } else {
          throw new Error('No user prompts information');
        }
      })
      .catch(() => {
        dispatch({
          type: 'setIsError',
          payload: errorActionPayload,
        });
      });
  }

  return [
    state,
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      getUserPrompts: useCallback(getUserPrompts, []),
    },
  ];
}
