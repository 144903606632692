import { VoidFunctionComponent } from 'react';
import { View, ExtraHuge, Large, Button, BaseButton } from '@talkspace/react-toolkit';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { useHistory, useParams } from '@/core/routerLib';
import styled from '../../core/styled';
import { SuperbillsIconBig } from '../../components/icons';
import Description from '../components/Description';
import SuperbillsWrapper from '../components/SuperbillsWrapper';

const MarginedWrapper = styled(View)({
  marginBottom: 22,
});

const SecondaryButton = styled(BaseButton)(({ theme: { colors } }) => {
  return {
    color: colors.slateGrey,
  };
});

const ErrorSuperbillsScreen: VoidFunctionComponent = () => {
  const closeModal = useCloseModal();
  const history = useHistory();
  const { roomID } = useParams<{ roomID: string }>();

  const handleRetry = () => {
    history.push(`/superbills/room/${roomID}/view-superbill`);
  };
  const handleClose = () => {
    closeModal();
  };

  return (
    <SuperbillsWrapper>
      <MarginedWrapper>
        <SuperbillsIconBig />
      </MarginedWrapper>
      <MarginedWrapper>
        <ExtraHuge style={{ marginButton: 20 }}>Something went wrong</ExtraHuge>
      </MarginedWrapper>
      <Description>
        <Large variant="largeDarkGrey">
          There was an issue generating your superbill. Please try again later.
        </Large>
      </Description>
      <Button onPress={handleRetry} style={{ marginBottom: 20 }} dataQa="retry">
        Retry
      </Button>
      <SecondaryButton onPress={handleClose} dataQa="close">
        Close
      </SecondaryButton>
    </SuperbillsWrapper>
  );
};

export default ErrorSuperbillsScreen;
