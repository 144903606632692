import { VoidFunctionComponent } from 'react';
import {
  Tray,
  BaseButton,
  View,
  Huge,
  Large,
  Standard,
  useUniqueID,
} from '@talkspace/react-toolkit';
import { useLocation } from '../../../core/routerLib';
import { useCloseModal } from '../../../utils/ModalsContextProvider';
import SparklingCalendarV2 from '../../../assets/SparklingCalendarV2';

const KeepMessagingModal: VoidFunctionComponent = () => {
  const {
    state: { roomID },
  } = useLocation<{ roomID: string }>();

  const ID_TITLE = useUniqueID('titleId');
  const ID_SUB_TITLE = useUniqueID('subTitleId');

  const closeModal = useCloseModal();

  const onSubmit = () => {
    window.location.href = `/in-room-scheduling/room/${roomID}`;
  };

  return (
    <Tray handleClose={closeModal} dataQa="keepMessagingModal">
      <View align="center">
        <SparklingCalendarV2 />
        <Huge
          as="h1"
          id={ID_TITLE}
          style={{
            textAlign: 'center',
            marginBottom: 20,
            marginTop: 30,
          }}
        >
          Are you in a Messaging Session?
        </Huge>

        <Large
          id={ID_SUB_TITLE}
          variant="largeDarkGrey"
          style={{ textAlign: 'center', marginBottom: 50 }}
        >
          It looks like you haven’t started a Messaging Session yet. If you want to continue the
          conversation with your therapist, start a Messaging Session now.
        </Large>

        <BaseButton onPress={onSubmit} data-qa="bookASessionButton">
          <Standard variant="standardBoldGreen">Start Messaging Session</Standard>
        </BaseButton>
      </View>
    </Tray>
  );
};

export default KeepMessagingModal;
