import { Subscription } from 'ts-frontend/types';

export type ModalType = 'pausePlan' | 'therapistSwitch' | 'updatePaymentDetails';

export interface State {
  superbillsFlag: boolean;
  subscriptions: Subscription[];
  isModalOpen: boolean;
  openModalType?: ModalType;
  successModalText: string;
  isLoading: boolean;
  error: string;
}

export type Action =
  | { type: 'openModal'; payload: Pick<State, 'openModalType'> }
  | { type: 'closeModal' }
  | { type: 'setSuccessModalText'; payload: Pick<State, 'successModalText'> }
  | { type: 'requestGetSubscriptions' }
  | { type: 'receiveGetSubscriptions'; payload: Pick<State, 'subscriptions'> }
  | { type: 'requestPauseSubscription' }
  | { type: 'receivePauseSubscription'; payload: Partial<State> }
  | { type: 'requestResumeSubscription' }
  | { type: 'receiveResumeSubscription'; payload: Partial<State> }
  | { type: 'requestUndoCancelSubscription' }
  | { type: 'receiveUndoCancelSubscription'; payload: Partial<State> }
  | { type: 'requestUndoCancelNonSubscription' }
  | { type: 'receiveUndoCancelNonSubscription'; payload?: Partial<State> }
  | { type: 'requestAdminOptionSuperbills' }
  | { type: 'receiveAdminOptionSuperbills'; payload?: Partial<State> }
  | { type: 'setError'; error?: string };

export interface APIResponse {
  data: Subscription[];
}

export const initialState: State = {
  superbillsFlag: false,
  subscriptions: [],
  isModalOpen: false,
  successModalText: '',
  isLoading: false,
  error: '',
};

export const subscriptionsReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'openModal':
      return {
        ...state,
        isModalOpen: true,
        ...action.payload,
      };
    case 'closeModal':
      return {
        ...state,
        isModalOpen: false,
        openModalType: undefined,
        successModalText: '',
        error: '',
      };
    case 'setSuccessModalText':
      return {
        ...state,
        ...action.payload,
      };
    case 'requestGetSubscriptions':
    case 'requestPauseSubscription':
    case 'requestResumeSubscription':
    case 'requestUndoCancelSubscription':
    case 'requestUndoCancelNonSubscription':
    case 'requestAdminOptionSuperbills':
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case 'receiveGetSubscriptions':
    case 'receivePauseSubscription':
    case 'receiveResumeSubscription':
    case 'receiveUndoCancelSubscription':
    case 'receiveUndoCancelNonSubscription':
    case 'receiveAdminOptionSuperbills':
      return {
        ...state,
        isLoading: false,
        error: '',
        ...(action.payload || {}),
      };
    case 'setError':
      return {
        ...state,
        error: action.error || 'Oops! Something went wrong. Please refresh the page',
        isLoading: false,
      };
    default:
      return state;
  }
};
