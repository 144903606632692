import { SurveyResult } from 'ts-frontend/types';
import apiHelper from '../../core/api/apiHelper';
import apiWrapper from '../../core/api/apiWrapper';
import { UpcomingSurvey } from '../reducers/surveys';
import { TreatmentPlan, Goal, Objective } from '../reducers/treatmentPlanner';

export interface ApiResponse<T = unknown> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: T;
}

interface SurveysResponse {
  results: SurveyResult[];
  upcomingSurveys: UpcomingSurvey[];
}

function getClientSurveys(): Promise<ApiResponse<{ data: SurveysResponse }>> {
  return apiWrapper.get(`${apiHelper().surveyAPIEndpoint}/v2/client-surveys`);
}
function getTreatmentPlanner(roomID: number): Promise<ApiResponse<{ data: TreatmentPlan[] }>> {
  return apiWrapper.get(
    `${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/treatment-plans?type=current`
  );
}
function getGoals(
  roomID: number,
  treatmentPlannerID: number
): Promise<ApiResponse<{ data: Goal[] }>> {
  return apiWrapper.get(
    `${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/treatment-plans/${treatmentPlannerID}/goals`
  );
}
function getObjectives(
  roomID: number,
  treatmentPlannerID: number
): Promise<ApiResponse<{ data: Objective[] }>> {
  return apiWrapper.get(
    `${
      apiHelper().apiEndpoint
    }/api/v1/rooms/${roomID}/treatment-plans/${treatmentPlannerID}/objectives`
  );
}

const clinicalProgressApiHelper = {
  getClientSurveys,
  getTreatmentPlanner,
  getGoals,
  getObjectives,
};

export default clinicalProgressApiHelper;
