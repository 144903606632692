import { Screen } from '@talkspace/react-toolkit';
import IsraelSupportContainer from '../containers/IsraelSupportContainer';

const IsraelSupportScreen = () => (
  <Screen showBackButton={false} showCloseButton={false}>
    <IsraelSupportContainer />
  </Screen>
);

export default IsraelSupportScreen;
