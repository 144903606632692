import { NotificationPreference, APINotificationPreference } from 'ts-frontend/types/Notifications';

export interface NotificationPreferencesByName {
  soundNotification: NotificationPreference;
  sendMessagesReceipts: NotificationPreference;
  accountUpdate: NotificationPreference;
  roomActivity: NotificationPreference;
  sessionReminder: NotificationPreference;
  marketingBlog: NotificationPreference;
  marketingEducation: NotificationPreference;
  marketingNews: NotificationPreference;
  marketingPromotions: NotificationPreference;
  superbills: NotificationPreference;
  communityDailyPrompt: NotificationPreference;
  communityComments: NotificationPreference;
}

export interface APIResponse {
  data: { categories: APINotificationPreference[] };
}

export interface State {
  notificationPreferencesByName: NotificationPreferencesByName | null;
  isLoading: boolean;
  isEditing: boolean;
  isError: boolean;
  superbillsFlag: boolean;
}

export type Action =
  | { type: 'getNotificationPreferences' }
  | { type: 'receiveGetNotificationPreferences'; payload: Partial<State> }
  | { type: 'updateNotificationPreferences' }
  | { type: 'receiveUpdateNotificationPreferences'; payload: Partial<State> }
  | { type: 'requestAdminOptionSuperbills' }
  | { type: 'receiveAdminOptionSuperbills'; payload: Partial<State> }
  | { type: 'setIsEditing'; payload: Partial<State> }
  | { type: 'setIsError' };

export const initialState: State = {
  notificationPreferencesByName: null,
  isLoading: false,
  isEditing: false,
  isError: false,
  superbillsFlag: false,
};

export const notificationPreferencesReducer = (
  state: State = initialState,
  action: Action
): State => {
  switch (action.type) {
    case 'getNotificationPreferences':
    case 'updateNotificationPreferences':
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case 'receiveGetNotificationPreferences':
    case 'receiveUpdateNotificationPreferences':
    case 'receiveAdminOptionSuperbills':
    case 'setIsEditing':
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case 'setIsError':
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
