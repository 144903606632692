import { FunctionComponent, useState, useEffect, useRef } from 'react';
import { toast } from 'chat/components/Toast';
import { RouteComponentProps } from '../../../core/routerLib';
import SSSNoMatchesActionView from './SSSNoMatchesActionView';
import ApiHelper from '../../utils/ApiHelper';

const SSSNoMatchesAction: FunctionComponent<
  RouteComponentProps<{
    roomID: number;
  }>
> = ({ history, match }) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const apiRef = useRef(new ApiHelper());
  const { current: api } = apiRef;

  useEffect(() => {
    setIsLoading(true);
    api
      .requestMatchingAgent(match.params.roomID)
      .then(({ therapistWasSwitched }) => {
        if (therapistWasSwitched) history.goBack();
        else setIsError(true);
      })
      .catch(api.dismissIfCancelled)
      .catch((err) => {
        if (err.message === '500') {
          toast('Error. Please contact customer support.', { type: 'error' });
          history.goBack();
        } else {
          setIsError(true);
        }
      })
      .finally(() => setIsLoading(false));
    return () => {
      api.cancelAll();
    };
  }, [api, history, match.params.roomID]);

  const handleOnBack = (event) => {
    if (event) event.stopPropagation();
    history.goBack();
  };

  return (
    <SSSNoMatchesActionView
      onBackdropPress={handleOnBack}
      isLoading={isLoading}
      isError={isError}
    />
  );
};

export default SSSNoMatchesAction;
