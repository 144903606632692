import { FunctionComponent } from 'react';
import {
  View,
  Button,
  ExtraHuge,
  Password,
  usePasswordValidation,
  SpacingView,
  spacing,
} from '@talkspace/react-toolkit';

import { PasswordInputMode } from 'ts-frontend/types';
import styled from '../../../core/styled/styled';

const { space600 } = spacing;

const Container = styled(View)({ width: 336, paddingBottom: space600 });

const HeaderWrapper = styled(View)({
  textAlign: 'left',
  marginBottom: 30,
  paddingLeft: 4,
});

const InputWrapper = styled(View)({
  marginBottom: 10,
});

interface Props {
  nickname: string;
  email: string;
  updatePassword: (password: string, confirmedPassword: string) => void;
  onCancelPress: () => void;
}

const ChangePasswordModal: FunctionComponent<Props> = ({
  nickname,
  email,
  updatePassword,
  onCancelPress,
}) => {
  const [{ password: newPassword, onSubmitPassword: onSubmitNewPassword }, newPasswordProps] =
    usePasswordValidation({
      passwordInputMode: PasswordInputMode.CHANGE_PASSWORD,
      email,
      nickname,
    });

  const [
    { password: confirmedPassword, onSubmitPassword: onSubmitConfirmedPassword },
    confirmedPasswordProps,
  ] = usePasswordValidation({
    passwordInputMode: PasswordInputMode.CONFIRM_PASSWORD,
    passwordToCompare: newPassword,
  });

  const handleSavePress = () => {
    const isNewPasswordValid = onSubmitNewPassword();
    const isConfirmedPasswordValid = onSubmitConfirmedPassword(!isNewPasswordValid);
    if (isNewPasswordValid && isConfirmedPasswordValid) {
      updatePassword(newPassword, confirmedPassword);
    }
  };

  return (
    <Container>
      <HeaderWrapper>
        <ExtraHuge>Change password</ExtraHuge>
      </HeaderWrapper>
      <InputWrapper>
        <Password {...newPasswordProps} label="New password" dataQa="myAccountNewPasswordInput" />
      </InputWrapper>
      <InputWrapper>
        <Password
          {...confirmedPasswordProps}
          label="Confirm new password"
          dataQa="myAccountConfirmNewPasswordInput"
        />
      </InputWrapper>
      <SpacingView>
        <Button
          text="Save"
          onPress={handleSavePress}
          dataQa="myAccountChangePasswordSaveButton"
          style={{ flex: 1 }}
        />
        <Button
          text="Cancel"
          variant="secondary"
          onPress={onCancelPress}
          dataQa="myAccountChangePasswordCancelButton"
          style={{ flex: 1 }}
        />
      </SpacingView>
    </Container>
  );
};

export default ChangePasswordModal;
