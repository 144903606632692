import capitalize from 'lodash/capitalize';

import { ServiceType } from 'ts-frontend/types';
import { TextDS, View, services, useEmotionTheme, spacing } from '@talkspace/react-toolkit';
import { CircleExclamation } from '@talkspace/react-toolkit/src/designSystems/icons';

const { space050 } = spacing;

interface Props {
  serviceType: ServiceType;
  payerName: string;
  therapistFirstName: string;
}

const EligibilityWarningSection = ({ serviceType, payerName, therapistFirstName }: Props) => {
  const { colorRoles } = useEmotionTheme();

  const serviceName = capitalize(services.find((s) => s.value === serviceType)?.name);
  return (
    <View
      align="center"
      style={{
        width: '100%',
        borderRadius: 8,
        padding: 12,
        backgroundColor: colorRoles.surfaces.criticalSubtleDefault,
      }}
    >
      <View row align="center">
        <CircleExclamation colorType="critical" />
        <TextDS
          variant="headingLg"
          colorRole="textCriticalDefault"
          style={{ marginLeft: space050 }}
        >
          Insurance coverage expired
        </TextDS>
      </View>
      {payerName && (
        <TextDS variant="body" colorRole="textSubtle" style={{ marginTop: 10, fontWeight: 700 }}>
          {payerName}
        </TextDS>
      )}
      <TextDS
        variant="bodySm"
        colorRole="textSubtle"
        style={{ marginTop: 4, fontSize: 12, lineHeight: '16px' }}
      >
        {serviceName} with {therapistFirstName}
      </TextDS>
    </View>
  );
};

export default EligibilityWarningSection;
