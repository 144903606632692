import { Availability } from 'ts-frontend/entities/Therapist';
import {
  BusinessDays,
  Expertise,
  ImplicitBusinessHoursByDay,
  Info,
  InformedConsentAPIResponse,
  InformedConsentV2StatusAPI,
  License,
} from 'ts-frontend/types';

export interface State {
  informedConsentID: number | null;
  roomID: number | null;
  signedInformedConsent: boolean;
  showAvailability: boolean;
  showProviderInfo: boolean;
  therapistID: number;
  informedConsent?: InformedConsentAPIResponse;
  informedConsentV2?: InformedConsentV2StatusAPI;
  therapist?: {
    additionalLanguages?: string[];
    availability: Availability;
    bestMatch: boolean;
    businessDays: BusinessDays;
    expertise: Expertise;
    id: number;
    imageURL: string;
    implicitBusinessHoursByDay: ImplicitBusinessHoursByDay | undefined;
    info: Info;
    licenses: License[];
    videoURL: string;
  };
  error: string;
  isLoading: boolean;
  isReady: boolean;
}

export const initialState: State = {
  informedConsent: undefined,
  informedConsentV2: undefined,
  informedConsentID: null,
  roomID: null,
  showAvailability: false,
  showProviderInfo: false,
  signedInformedConsent: false,
  therapist: undefined,
  therapistID: 0,
  error: '',
  isLoading: false,
  isReady: false,
};

export type Action =
  | { type: 'requestGetTherapistInfo' }
  | { type: 'requestGetInformedConsent' }
  | { type: 'requestGetInformedConsentV2' }
  | { type: 'requestPutAgreeToInformedConsentV2' }
  | {
      type: 'receiveGetTherapistInfo';
      payload: { therapist };
    }
  | {
      type: 'receiveGetInformedConsent';
      payload: { informedConsent: InformedConsentAPIResponse };
    }
  | {
      type: 'receiveGetInformedConsentV2';
      payload: { informedConsentV2: InformedConsentV2StatusAPI };
    }
  | {
      type: 'receivePostAgreeToInformedConsent';
      payload: { signedInformedConsent: boolean };
    }
  | {
      type: 'receiveCustomerInformation';
      payload: { showAvailability: boolean };
    }
  | {
      type: 'setState';
      payload: Partial<State>;
    }
  | { type: 'setError'; error?: string };

export const meetYourProviderReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'requestGetInformedConsent':
    case 'requestGetTherapistInfo':
    case 'requestPutAgreeToInformedConsentV2':
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case 'receiveCustomerInformation':
    case 'receiveGetInformedConsent':
    case 'receiveGetInformedConsentV2':
    case 'receiveGetTherapistInfo':
    case 'receivePostAgreeToInformedConsent':
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: '',
      };
    case 'setState':
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    case 'setError':
      return {
        ...state,
        error: action.error || 'Something went wrong. Please try again later',
        isLoading: false,
      };
    default:
      return state;
  }
};
