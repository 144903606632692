import { FunctionComponent, useState, useEffect } from 'react';

import { PaymentDetails } from 'ts-frontend/types';
import { EmotionThemeProvider } from '@talkspace/react-toolkit';
import BaseModal from '../../../components/BaseModal';
import ChangeNicknameModal from '../ChangeNicknameModal';
import PasswordRequiredModal from '../PasswordRequiredModal';
import ChangeEmailModal from '../ChangeEmailModal';
import ChangePasswordModal from '../ChangePasswordModal';
import UpdatePaymentDetailsModal from '../UpdatePaymentDetailsModal';
import EmailVerificationModal from '../EmailVerificationModal';
import SuccessModal from '../SuccessModal';
import ErrorModal from '../ErrorModal';
import { ModalType } from '../../reducers/accountDetails';
import ChangePhoneModal from '../ChangePhoneModal';
import ChangePhoneNumber2FAModal from '../ChangePhoneNumber2FAModal';
import VerifyPhoneModal from '../VerifyPhoneModal';

interface Props {
  isLoading: boolean;
  error: string;
  openModalType?: ModalType;
  successModalText: string;
  successModalTitle?: string;
  nextModalType: ModalType;
  nickname: string;
  email: string;
  currentPhoneNumber: string;
  verificationCodeError: boolean;
  paymentDetails?: PaymentDetails;
  pendingPhoneNumber?: string;
  twoFATokenExpiredError: string;
  isResendOTPLoading: boolean;
  isVerifyCodeLoading: boolean;
  closeModal: () => void;
  clearError: () => void;
  updateNickname: (nickname: string) => void;
  verifyPassword: (password: string, nextModalType: ModalType) => void;
  updateEmail: (email: string) => void;
  updatePassword: (password: string, confirmedPassword: string) => void;
  updatePaymentDetails: (cardToken: string) => void;
  clearVerificationCodeError: () => void;
  handleBackToChangePhoneNumber: () => void;
  resendVerificationCode: () => void;
  verifyCode: (code: number) => void;
  changePhoneNumber: (phoneNumber: string, phoneNumberCountryCode: string) => void;
  clearErrorsAndOTP: () => void;
  verifyNumber: () => void;
}

const unCamelCaseIntoTitle = (openModalType: string) => openModalType.split(/(?=[A-Z])/).join(' ');

const AccountDetailsModal: FunctionComponent<Props> = ({
  isLoading,
  error,
  openModalType,
  successModalText,
  successModalTitle,
  nextModalType,
  nickname,
  email,
  paymentDetails,
  currentPhoneNumber,
  verificationCodeError,
  pendingPhoneNumber,
  twoFATokenExpiredError,
  isResendOTPLoading,
  isVerifyCodeLoading,
  closeModal,
  clearError,
  updateNickname,
  verifyPassword,
  updateEmail,
  updatePassword,
  updatePaymentDetails,
  clearVerificationCodeError,
  handleBackToChangePhoneNumber,
  resendVerificationCode,
  verifyCode,
  changePhoneNumber,
  clearErrorsAndOTP,
  verifyNumber,
}) => {
  const [titleText, setTitleText] = useState('');
  const [isSuccessOrError, setIsSuccessOrError] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  useEffect(() => {
    if (successModalText) {
      setTitleText(successModalText);
      setIsSuccessOrError(true);
    } else if (error && error !== 'Incorrect password') {
      setIsSuccessOrError(true);
      setTitleText(error);
    } else if (openModalType) {
      setTitleText(unCamelCaseIntoTitle(openModalType));
    }
  }, [successModalText, error, openModalType]);

  function renderModalContent() {
    if (successModalText || successModalTitle) {
      return (
        <SuccessModal
          message={successModalText}
          title={successModalTitle}
          onCancelPress={closeModal}
          setIsCancel={setIsCancel}
        />
      );
    }
    // If error is "Incorrect password" we want to show it below the input inside PasswordRequiredModal and NOT in the ErrorModal
    if (error && error !== 'Incorrect password') {
      return <ErrorModal error={error} onCancelPress={closeModal} setIsCancel={setIsCancel} />;
    }

    switch (openModalType) {
      case 'changeNickname':
        return (
          <ChangeNicknameModal
            initialNickname={nickname}
            updateNickname={updateNickname}
            onCancelPress={closeModal}
          />
        );
      case 'passwordRequired':
        return (
          <PasswordRequiredModal
            errorFromAPI={error}
            onContinuePress={(password) => verifyPassword(password, nextModalType)}
            onCancelPress={closeModal}
            clearError={clearError}
          />
        );
      case 'changeEmail':
        return <ChangeEmailModal updateEmail={updateEmail} onCancelPress={closeModal} />;
      case 'changePassword':
        return (
          <ChangePasswordModal
            nickname={nickname}
            email={email}
            updatePassword={updatePassword}
            onCancelPress={closeModal}
          />
        );
      case 'updatePaymentDetails':
        return (
          <EmotionThemeProvider version="0.0.0">
            <UpdatePaymentDetailsModal
              isEditMode={Boolean(paymentDetails)}
              email={email}
              updatePaymentDetails={updatePaymentDetails}
              onCancelPress={closeModal}
            />
          </EmotionThemeProvider>
        );
      case 'changePhoneNumber':
        return (
          <ChangePhoneModal
            isLoading={isLoading}
            updatePhoneNumber={changePhoneNumber}
            onCancelPress={closeModal}
          />
        );
      case 'verifyPhoneNumber':
        return (
          <EmotionThemeProvider version="0.0.0">
            <VerifyPhoneModal
              isResendOTPLoading={isResendOTPLoading}
              isVerifyCodeLoading={isVerifyCodeLoading}
              isLoading={isLoading}
              currentPhoneNumber={currentPhoneNumber}
              pendingPhoneNumber={pendingPhoneNumber}
              verificationCodeError={verificationCodeError}
              twoFATokenExpiredError={twoFATokenExpiredError}
              verifyCode={verifyCode}
              clearVerificationCodeError={clearVerificationCodeError}
              resendVerificationCode={resendVerificationCode}
              handleBackToChangePhoneNumber={handleBackToChangePhoneNumber}
              clearErrorsAndOTP={clearErrorsAndOTP}
              verifyNumber={verifyNumber}
            />
          </EmotionThemeProvider>
        );
      case 'emailVerification':
        return <EmailVerificationModal email={email} onCancelPress={closeModal} />;
      case 'changePhoneNumber2FA':
        return (
          <ChangePhoneNumber2FAModal
            updatePhoneNumber={changePhoneNumber}
            onCancelPress={closeModal}
          />
        );
      default:
        return null;
    }
  }

  return (
    <BaseModal
      titleText={titleText}
      isLoading={isLoading}
      onClosePress={closeModal}
      focusMainOnClose={isSuccessOrError || isCancel}
      isCancel={isCancel}
    >
      {renderModalContent()}
    </BaseModal>
  );
};

export default AccountDetailsModal;
