import { FunctionComponent, useState } from 'react';
import { useEmotionTheme, BaseButton, Text } from '@talkspace/react-toolkit';
import { ClosePopupAction } from '@/auth/reactFrame/ReactFrameTypes';
import ssoHelper, { ZendeskPath } from '@/utils/sso';
import { useCloseModal } from '../../../utils/ModalsContextProvider';
import { trackWizardEvent } from '../../../utils/analytics/events';
import ReactFrameService from '../../../auth/reactFrame/ReactFrameService';

const TalkToMatchingAgentOrSupport: FunctionComponent<{
  roomID: number;
  eventCategory?: string;
  clientUserID?: number;
  formerTherapistID?: number;
  ariaDescribedBy?: string;
  isB2B?: boolean;
}> = ({
  roomID,
  eventCategory,
  clientUserID,
  formerTherapistID,
  ariaDescribedBy,
  isB2B = false,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const closeModal = useCloseModal();

  const handleOnClick = (): void => {
    setIsLoading(true);

    const reactFrameService = ReactFrameService.instance();
    if (eventCategory === 'Self Serve Switch' && clientUserID) {
      trackWizardEvent('User contact support Option', eventCategory, {
        'User ID': clientUserID.toString(),
        label: 'Contact support',
        Application: eventCategory,
        eventPropertyValue: formerTherapistID || 0.0,
      });
    }
    if (reactFrameService.isInFrame()) {
      setIsLoading(false);
      const closingPayload = {
        navigateTo: 'zendesk',
        metadata: { path: 'CONTACT_US' },
      };
      reactFrameService.closePopup(closingPayload as ClosePopupAction);
    } else {
      ssoHelper.openZendesk(ssoHelper.ZendeskPath.CONTACT_US as ZendeskPath).finally(() => {
        closeModal({
          navigateTo: 'room',
          metadata: { roomID },
        });
      });
    }
  };

  const { colors } = useEmotionTheme();
  return (
    <BaseButton
      aria-describedby={ariaDescribedBy}
      onPress={handleOnClick}
      disabled={isLoading}
      dataQa="talkToMatchingAgentOrSupportContact"
    >
      <Text
        style={{
          color: colors.green,
          fontWeight: 700,
          fontSize: 12,
        }}
      >
        {' '}
        Contact support
      </Text>
    </BaseButton>
  );
};

export default TalkToMatchingAgentOrSupport;
