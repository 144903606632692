import { useFormContext } from 'react-hook-form';

import {
  A11yPhoneInput,
  Button,
  ExtraHuge,
  Input,
  Modal,
  EmotionTheme,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { contactRelationship } from '@talkspace/configs';
import styled from '@/core/styled';

import SelectRHF from '../SelectRHF';

const relationshipOptions = Object.entries(contactRelationship).map(([value, label]) => {
  return {
    value,
    label,
  };
});

const Title = styled(ExtraHuge)({
  marginBottom: 8,
});

const selectStyle = { marginTop: 12 };

const modalContentStyle = {
  width: '100%',
};
const baseInputStyle = (colors: EmotionTheme['colors'], isError: boolean) => {
  return {
    containerStyle: {
      width: '100%',
      marginTop: 12,
    },
    labelStyle: {
      marginBottom: 0,
    },
    inputStyle: {
      width: '100%',
      borderColor: isError ? colors.torchRed : colors.permaLividBlueNew,
      color: colors.black,
    },
  };
};

interface Props {
  isLoading: boolean;
  onClose: () => void;
}
const PersonalInformationEditEmergencyContactModal = ({ onClose, isLoading }: Props) => {
  const { colors } = useEmotionTheme();
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <Modal.Panel
      isLoading={isLoading}
      onBackdropPress={onClose}
      contentViewStyle={modalContentStyle}
    >
      <Title as="h1">Emergency contact</Title>
      <Input
        dataQa="contactNameInput"
        wrappedInputProps={{
          ...baseInputStyle(colors, !!errors.contactName),
          label: 'Emergency contact name',
          isError: !!errors.contactName,
          errorMessage: errors.contactName?.message,
        }}
        placeholder="Enter emergency contact name"
        placeHolderStyle={{ color: colors.permaWaikawaGreyNew }}
        {...register('contactName')}
      />
      <A11yPhoneInput
        control={control}
        name="contactPhone"
        isError={!!errors.contactPhone}
        isReactHookForm
        dataQa="phoneInput"
        label="Emergency contact phone number"
        width="100%"
        containerStyle={{ marginTop: 12 }}
      />
      <SelectRHF
        control={control}
        label="Relationship with emergency contact"
        options={relationshipOptions}
        fieldName="relationship"
        errorMessage={errors.relationship?.message}
        wrapperStyle={selectStyle}
        borderColor={errors.relationship?.message ? colors.torchRed : colors.permaLividBlueNew}
      />
      <Button
        dataQa="saveButton"
        text="Save"
        style={{ width: '100%', marginTop: 30, backgroundColor: colors.permaTalkspaceDarkGreen }}
      />
      <Button
        dataQa="cancelButton"
        text="Cancel"
        onPress={onClose}
        style={{
          color: colors.permaTalkspaceDarkGreen,
          backgroundColor: colors.white,
          width: '100%',
        }}
      />
    </Modal.Panel>
  );
};

export default PersonalInformationEditEmergencyContactModal;
