import { useCallback } from 'react';
import { useNewMemberNav } from 'launchDarkly/hooks';

import { useTranslation } from '@talkspace/i18n';
import { useParams } from '@/core/routerLib';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import OnboardingPage from './OnboardingPage';
import AllDone from '../assets/AllDone';
import AllDoneTeens from '../assets/AllDoneTeens';

const OnboardingPageAllDone = ({
  totalSteps,
  isTeensIntake,
  isRedirectToRoom,
}: {
  totalSteps: number;
  isTeensIntake?: boolean;
  isRedirectToRoom?: boolean;
}) => {
  const useNewNav = useNewMemberNav();
  const { roomID } = useParams<{ roomID: string }>();
  const closeModal = useCloseModal();
  const { t: tTeenOnboarding } = useTranslation('teenOnboarding');

  const onClose = useCallback(() => {
    closeModal(
      useNewNav && !isRedirectToRoom
        ? {
            navigateTo: 'home',
            metadata: { path: '/' },
          }
        : {
            navigateTo: 'room',
            metadata: { roomID: Number(roomID) },
          }
    );
  }, [closeModal, roomID, isRedirectToRoom, useNewNav]);
  return (
    <OnboardingPage
      totalSteps={totalSteps}
      currentStep={totalSteps}
      illustration={isTeensIntake ? <AllDoneTeens /> : <AllDone />}
      title={tTeenOnboarding('allDone.title', 'You’re all done!')}
      description={
        isTeensIntake
          ? tTeenOnboarding(
              'allDone.descriptionTherapy',
              'You’ve completed all the steps needed to begin therapy'
            )
          : tTeenOnboarding(
              'allDone.descriptionTreatment',
              'You’ve completed all the steps needed to begin treatment'
            )
      }
      ctaText={tTeenOnboarding('allDone.close', 'Close')}
      onPress={onClose}
    />
  );
};

export default OnboardingPageAllDone;
