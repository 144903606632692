import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { History } from 'history';
import { StepWizardProvider } from 'stepWizard';
import Routes from './Routes';
import { getInitialState, State, RefundWizardSource } from './reducers/refundWizardState';
import refundWizardActions from './hooks/refundWizardActions';
import { MainContextProvider } from '../hooks/mainContext';
import { getUserData } from '../auth/helpers/token';
import { RouteComponentProps } from '../core/routerLib';
import { useGenericWizardActions } from '../utils/genericWizardActions';

interface RefundRouteParams {
  source: RefundWizardSource;
  contextID?: string;
  history: History;
}

const RefundWizard: FunctionComponent<RouteComponentProps<RefundRouteParams>> = ({
  history,
  match: {
    params: { source, contextID = '' },
  },
}) => {
  const { id } = getUserData();
  const getFirstState = useCallback(getInitialState, [source, contextID]);
  const [initialState, setInitialState] = useState<State | undefined>();
  const genericRefundWizardActions = useGenericWizardActions(refundWizardActions);
  useEffect(() => {
    setInitialState(getFirstState(id, source, contextID));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {initialState ? (
        <MainContextProvider>
          <StepWizardProvider
            initialState={initialState}
            genericActions={genericRefundWizardActions}
          >
            <Routes history={history} />
          </StepWizardProvider>
        </MainContextProvider>
      ) : null}
    </>
  );
};

export default RefundWizard;
