import { VoidFunctionComponent } from 'react';
import Svg, { G, Path, Circle } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';
import { EmotionStyle } from '../../core/styled';

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const CellPhone: VoidFunctionComponent<IconProps> = ({
  width = 16,
  height = 23,
  color,
  ...props
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Smartphone';
  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      title={titleText}
      aria-label={titleText}
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M1140.25 372.75V607c0 2.83-1.149 5.392-3.005 7.247a10.23 10.23 0 01-7.253 3.003h0-480.984a10.23 10.23 0 01-7.255-3.001 10.214 10.214 0 01-3.003-7.25h0V372.75h501.5z"
          stroke={color ?? colors.green}
          strokeWidth={0.5}
          fill="#FFF"
          transform="translate(-666 -463)"
        />
        <G transform="translate(-666 -463) translate(666 463)">
          <Path
            d="M12.5.75c.76 0 1.447.308 1.945.805.497.498.805 1.186.805 1.945h0v16c0 .76-.308 1.447-.805 1.945a2.741 2.741 0 01-1.945.805h0-9c-.76 0-1.447-.308-1.945-.805A2.741 2.741 0 01.75 19.5h0v-16c0-.76.308-1.447.805-1.945A2.741 2.741 0 013.5.75h0z"
            stroke={color ?? colors.green}
            strokeWidth={1.5}
          />
          <Circle fill={color ?? colors.green} cx={8} cy={18} r={1.5} />
        </G>
      </G>
    </Svg>
  );
};

export default CellPhone;
