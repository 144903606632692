import { CognitoUser } from '@aws-amplify/auth';

export const SMS_MFA = 'SMS_MFA';

export type MFA_TYPE = 'SMS_MFA';

export enum AuthResultCode {
  CODE_MISMATCH = 'code-mismatch',
  NETWORK_ERROR = 'network-error',
  NO_MATCH = 'no-match',
  SUCCESS = 'success',
  RESET_REQUIRED = 'reset-required',
  TOO_MANY_ATTEMPTS = 'too-many-attempts',
}

export type SignInParameters = {
  email: string;
  password: string;
};

export type SignInResult = {
  idToken?: string;
  result: AuthResultCode;
  is2FA?: boolean;
  userObject?: CognitoUser;
  phoneNumber?: string;
};

export type SignInErrorCodes =
  | 'UserNotConfirmedException'
  | 'InvalidPasswordException'
  | 'NotAuthorizedException'
  | 'TooManyFailedAttemptsException'
  | 'PasswordResetRequiredException'
  | 'NetworkError';
