import { useOnboardingState } from './onboardingContext';
import { Step } from '../types';

const getHasRequiredStep = (steps: Step[] | null, currentStepIndex: number): boolean => {
  if (!steps) {
    return false;
  }
  const requiredIndexes = steps
    .map((s, i) => (s.isRequired ? i : null))
    .filter((n) => n !== null) as number[];

  const lastRequiredStep = requiredIndexes.length ? Math.max(...requiredIndexes) : -1;
  return lastRequiredStep > currentStepIndex;
};

const useAllowClosing = () => {
  const { steps, currentStepIndex } = useOnboardingState();
  const { step } = steps && steps[currentStepIndex] ? steps[currentStepIndex] : { step: undefined };
  const hasLaterRequiredStep = getHasRequiredStep(steps, currentStepIndex);
  if (!step) {
    return true;
  }

  return ['BOOK_LIVE_SESSION', 'BOOK_ASYNC_SESSION'].includes(step) && !hasLaterRequiredStep;
};

export default useAllowClosing;
