import { useMutation } from 'react-query';

import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

export interface RenewVoucherVariables {
  roomID: number;
  shouldRenew: boolean;
}

const renewVoucher = async (params: RenewVoucherVariables): Promise<void> => {
  await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/payments/room/${params.roomID}/vouchers/dte-renewal`,
    { shouldRenew: params.shouldRenew }
  );
};

const useMutationRenewVoucher = () => useMutation<void, Error, RenewVoucherVariables>(renewVoucher);

export default useMutationRenewVoucher;
