import { FunctionComponent } from 'react';
import { Screen } from '@talkspace/react-toolkit';
import { useCloseModal, useIsModal } from '@/utils/ModalsContextProvider';
import { EmotionStyle } from '@/core/styled';

interface ScreenProps {
  showBackButton?: boolean;
  showCloseButton?: boolean;
  title?: string;
  onScrollChange?: (scrollTop: number) => void;
  onBackButtonClick?: () => void;
  closeButtonDataQa?: string;
  backButtonDataQa?: string;
  backButtonStyles?: EmotionStyle;
}

const ModalClosingScreen: FunctionComponent<ScreenProps> = ({
  children,
  onScrollChange,
  showBackButton = false,
  showCloseButton = true,
  title,
  onBackButtonClick,
  closeButtonDataQa,
  backButtonDataQa,
  backButtonStyles,
}) => {
  const closeModal = useCloseModal();
  const isModal = useIsModal();
  return (
    <Screen
      safeAreaVariant={isModal ? 'none' : 'setHeightAndAddTop'}
      onScrollChange={onScrollChange}
      showBackButton={showBackButton}
      showCloseButton={showCloseButton}
      title={title}
      onCloseButtonClick={closeModal}
      onBackButtonClick={onBackButtonClick}
      closeButtonDataQa={closeButtonDataQa}
      backButtonDataQa={backButtonDataQa}
      backButtonStyles={backButtonStyles}
    >
      {children}
    </Screen>
  );
};

export default ModalClosingScreen;
