import { Defs, Ellipse, Path, Stop, Svg } from 'svgs';

import { EmojiSize } from '../../types';

const FaceHoldingBackTearsEmoji = ({ size }: { size: EmojiSize }) => {
  const title = 'face holding back tears emoji';
  return (
    <Svg
      title={title}
      aria-label={title}
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
    >
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint0_radial_4451_12873)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint1_radial_4451_12873)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint2_radial_4451_12873)"
        fillOpacity="0.6"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint3_radial_4451_12873)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint4_radial_4451_12873)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint5_radial_4451_12873)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint6_radial_4451_12873)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint7_radial_4451_12873)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint8_radial_4451_12873)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint9_radial_4451_12873)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint10_radial_4451_12873)"
        fillOpacity="0.8"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint11_radial_4451_12873)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint12_radial_4451_12873)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint13_radial_4451_12873)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint14_radial_4451_12873)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint15_radial_4451_12873)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint16_radial_4451_12873)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint17_radial_4451_12873)"
      />
      <Path
        d="M15.5548 27.5181C15.9936 27.0306 16.7444 26.9911 17.2319 27.4298C17.8454 27.982 18.8669 28.3274 20 28.3274C21.1331 28.3274 22.1546 27.982 22.7681 27.4298C23.2556 26.9911 24.0064 27.0306 24.4452 27.5181C24.8839 28.0056 24.8444 28.7564 24.3569 29.1952C23.1892 30.2461 21.5388 30.7024 20 30.7024C18.4612 30.7024 16.8108 30.2461 15.6431 29.1952C15.1556 28.7564 15.1161 28.0056 15.5548 27.5181Z"
        fill="url(#paint18_radial_4451_12873)"
      />
      <Path
        d="M18.5156 20.5938C18.5156 23.3811 16.2561 25.6406 13.4687 25.6406C10.6814 25.6406 8.42188 23.3811 8.42188 20.5938C8.42188 17.8064 10.6814 15.5469 13.4687 15.5469C16.2561 15.5469 18.5156 17.8064 18.5156 20.5938Z"
        fill="white"
      />
      <Path
        d="M21.4844 20.5938C21.4844 23.3811 23.7439 25.6406 26.5313 25.6406C29.3186 25.6406 31.5781 23.3811 31.5781 20.5938C31.5781 17.8064 29.3186 15.5469 26.5313 15.5469C23.7439 15.5469 21.4844 17.8064 21.4844 20.5938Z"
        fill="white"
      />
      <Path
        d="M21.4844 20.2969C21.4844 22.7563 23.4781 24.75 25.9375 24.75C28.3969 24.75 30.3906 22.7563 30.3906 20.2969C30.3906 17.8375 28.3969 15.8438 25.9375 15.8438C23.4781 15.8438 21.4844 17.8375 21.4844 20.2969Z"
        fill="#3E272F"
      />
      <Path
        d="M14.0625 24.75C16.5219 24.75 18.5156 22.7563 18.5156 20.2969C18.5156 17.8375 16.5219 15.8438 14.0625 15.8438C11.6031 15.8438 9.60938 17.8375 9.60938 20.2969C9.60938 22.7563 11.6031 24.75 14.0625 24.75Z"
        fill="#3E272F"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4809 9.37548C13.8984 9.46169 14.167 9.87002 14.0808 10.2875C13.7142 12.0625 12.718 13.2483 11.6735 13.9908C10.65 14.7183 9.55379 15.0403 8.89171 15.0888C8.46656 15.1199 8.09668 14.8005 8.06557 14.3753C8.03446 13.9501 8.3539 13.5803 8.77906 13.5492C9.14814 13.5221 9.97593 13.3034 10.7791 12.7326C11.5612 12.1766 12.294 11.3064 12.5689 9.9753C12.6551 9.55782 13.0635 9.28927 13.4809 9.37548Z"
        fill="#4F384A"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4809 9.37548C13.8984 9.46169 14.167 9.87002 14.0808 10.2875C13.7142 12.0625 12.718 13.2483 11.6735 13.9908C10.65 14.7183 9.55379 15.0403 8.89171 15.0888C8.46656 15.1199 8.09668 14.8005 8.06557 14.3753C8.03446 13.9501 8.3539 13.5803 8.77906 13.5492C9.14814 13.5221 9.97593 13.3034 10.7791 12.7326C11.5612 12.1766 12.294 11.3064 12.5689 9.9753C12.6551 9.55782 13.0635 9.28927 13.4809 9.37548Z"
        fill="url(#paint19_radial_4451_12873)"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4809 9.37548C13.8984 9.46169 14.167 9.87002 14.0808 10.2875C13.7142 12.0625 12.718 13.2483 11.6735 13.9908C10.65 14.7183 9.55379 15.0403 8.89171 15.0888C8.46656 15.1199 8.09668 14.8005 8.06557 14.3753C8.03446 13.9501 8.3539 13.5803 8.77906 13.5492C9.14814 13.5221 9.97593 13.3034 10.7791 12.7326C11.5612 12.1766 12.294 11.3064 12.5689 9.9753C12.6551 9.55782 13.0635 9.28927 13.4809 9.37548Z"
        fill="url(#paint20_radial_4451_12873)"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4809 9.37548C13.8984 9.46169 14.167 9.87002 14.0808 10.2875C13.7142 12.0625 12.718 13.2483 11.6735 13.9908C10.65 14.7183 9.55379 15.0403 8.89171 15.0888C8.46656 15.1199 8.09668 14.8005 8.06557 14.3753C8.03446 13.9501 8.3539 13.5803 8.77906 13.5492C9.14814 13.5221 9.97593 13.3034 10.7791 12.7326C11.5612 12.1766 12.294 11.3064 12.5689 9.9753C12.6551 9.55782 13.0635 9.28927 13.4809 9.37548Z"
        fill="url(#paint21_radial_4451_12873)"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4809 9.37548C13.8984 9.46169 14.167 9.87002 14.0808 10.2875C13.7142 12.0625 12.718 13.2483 11.6735 13.9908C10.65 14.7183 9.55379 15.0403 8.89171 15.0888C8.46656 15.1199 8.09668 14.8005 8.06557 14.3753C8.03446 13.9501 8.3539 13.5803 8.77906 13.5492C9.14814 13.5221 9.97593 13.3034 10.7791 12.7326C11.5612 12.1766 12.294 11.3064 12.5689 9.9753C12.6551 9.55782 13.0635 9.28927 13.4809 9.37548Z"
        fill="url(#paint22_radial_4451_12873)"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4732 9.37548C26.0557 9.46169 25.7871 9.87002 25.8733 10.2875C26.2399 12.0625 27.2361 13.2483 28.2806 13.9908C29.3041 14.7183 30.4003 15.0403 31.0624 15.0888C31.4875 15.1199 31.8574 14.8005 31.8885 14.3753C31.9196 13.9501 31.6002 13.5803 31.175 13.5492C30.806 13.5221 29.9782 13.3034 29.175 12.7326C28.393 12.1766 27.6601 11.3064 27.3852 9.9753C27.299 9.55782 26.8906 9.28927 26.4732 9.37548Z"
        fill="#462E2E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4732 9.37548C26.0557 9.46169 25.7871 9.87002 25.8733 10.2875C26.2399 12.0625 27.2361 13.2483 28.2806 13.9908C29.3041 14.7183 30.4003 15.0403 31.0624 15.0888C31.4875 15.1199 31.8574 14.8005 31.8885 14.3753C31.9196 13.9501 31.6002 13.5803 31.175 13.5492C30.806 13.5221 29.9782 13.3034 29.175 12.7326C28.393 12.1766 27.6601 11.3064 27.3852 9.9753C27.299 9.55782 26.8906 9.28927 26.4732 9.37548Z"
        fill="url(#paint23_radial_4451_12873)"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4732 9.37548C26.0557 9.46169 25.7871 9.87002 25.8733 10.2875C26.2399 12.0625 27.2361 13.2483 28.2806 13.9908C29.3041 14.7183 30.4003 15.0403 31.0624 15.0888C31.4875 15.1199 31.8574 14.8005 31.8885 14.3753C31.9196 13.9501 31.6002 13.5803 31.175 13.5492C30.806 13.5221 29.9782 13.3034 29.175 12.7326C28.393 12.1766 27.6601 11.3064 27.3852 9.9753C27.299 9.55782 26.8906 9.28927 26.4732 9.37548Z"
        fill="url(#paint24_radial_4451_12873)"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4732 9.37548C26.0557 9.46169 25.7871 9.87002 25.8733 10.2875C26.2399 12.0625 27.2361 13.2483 28.2806 13.9908C29.3041 14.7183 30.4003 15.0403 31.0624 15.0888C31.4875 15.1199 31.8574 14.8005 31.8885 14.3753C31.9196 13.9501 31.6002 13.5803 31.175 13.5492C30.806 13.5221 29.9782 13.3034 29.175 12.7326C28.393 12.1766 27.6601 11.3064 27.3852 9.9753C27.299 9.55782 26.8906 9.28927 26.4732 9.37548Z"
        fill="url(#paint25_radial_4451_12873)"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4732 9.37548C26.0557 9.46169 25.7871 9.87002 25.8733 10.2875C26.2399 12.0625 27.2361 13.2483 28.2806 13.9908C29.3041 14.7183 30.4003 15.0403 31.0624 15.0888C31.4875 15.1199 31.8574 14.8005 31.8885 14.3753C31.9196 13.9501 31.6002 13.5803 31.175 13.5492C30.806 13.5221 29.9782 13.3034 29.175 12.7326C28.393 12.1766 27.6601 11.3064 27.3852 9.9753C27.299 9.55782 26.8906 9.28927 26.4732 9.37548Z"
        fill="url(#paint26_radial_4451_12873)"
      />
      <Ellipse
        cx="27.7451"
        cy="18.9599"
        rx="1.39911"
        ry="1.76189"
        transform="rotate(-37.3021 27.7451 18.9599)"
        fill="url(#paint27_radial_4451_12873)"
      />
      <Ellipse
        cx="15.7959"
        cy="18.9599"
        rx="1.39911"
        ry="1.76189"
        transform="rotate(-37.3021 15.7959 18.9599)"
        fill="url(#paint28_radial_4451_12873)"
      />
      <Path
        d="M9.46034 24.1272C9.201 23.8333 9.4315 23.4068 9.82349 23.4068H10.4077C10.8749 23.4068 11.1754 23.3504 11.4689 23.2953C11.7489 23.2428 12.0225 23.1914 12.4281 23.1914C13.08 23.1914 13.521 23.2429 13.9717 23.2955C14.4428 23.3506 14.9246 23.4068 15.6688 23.4068H17.114C17.506 23.4068 17.7365 23.8333 17.4772 24.1272C16.498 25.237 15.0651 25.937 13.4687 25.937C11.8724 25.937 10.4395 25.237 9.46034 24.1272Z"
        fill="#427DFF"
      />
      <Path
        d="M9.46034 24.1272C9.201 23.8333 9.4315 23.4068 9.82349 23.4068H10.4077C10.8749 23.4068 11.1754 23.3504 11.4689 23.2953C11.7489 23.2428 12.0225 23.1914 12.4281 23.1914C13.08 23.1914 13.521 23.2429 13.9717 23.2955C14.4428 23.3506 14.9246 23.4068 15.6688 23.4068H17.114C17.506 23.4068 17.7365 23.8333 17.4772 24.1272C16.498 25.237 15.0651 25.937 13.4687 25.937C11.8724 25.937 10.4395 25.237 9.46034 24.1272Z"
        fill="url(#paint29_radial_4451_12873)"
      />
      <Path
        d="M9.46034 24.1272C9.201 23.8333 9.4315 23.4068 9.82349 23.4068H10.4077C10.8749 23.4068 11.1754 23.3504 11.4689 23.2953C11.7489 23.2428 12.0225 23.1914 12.4281 23.1914C13.08 23.1914 13.521 23.2429 13.9717 23.2955C14.4428 23.3506 14.9246 23.4068 15.6688 23.4068H17.114C17.506 23.4068 17.7365 23.8333 17.4772 24.1272C16.498 25.237 15.0651 25.937 13.4687 25.937C11.8724 25.937 10.4395 25.237 9.46034 24.1272Z"
        fill="url(#paint30_linear_4451_12873)"
      />
      <Path
        d="M9.46034 24.1272C9.201 23.8333 9.4315 23.4068 9.82349 23.4068H10.4077C10.8749 23.4068 11.1754 23.3504 11.4689 23.2953C11.7489 23.2428 12.0225 23.1914 12.4281 23.1914C13.08 23.1914 13.521 23.2429 13.9717 23.2955C14.4428 23.3506 14.9246 23.4068 15.6688 23.4068H17.114C17.506 23.4068 17.7365 23.8333 17.4772 24.1272C16.498 25.237 15.0651 25.937 13.4687 25.937C11.8724 25.937 10.4395 25.237 9.46034 24.1272Z"
        fill="url(#paint31_radial_4451_12873)"
      />
      <Path
        d="M9.46034 24.1272C9.201 23.8333 9.4315 23.4068 9.82349 23.4068H10.4077C10.8749 23.4068 11.1754 23.3504 11.4689 23.2953C11.7489 23.2428 12.0225 23.1914 12.4281 23.1914C13.08 23.1914 13.521 23.2429 13.9717 23.2955C14.4428 23.3506 14.9246 23.4068 15.6688 23.4068H17.114C17.506 23.4068 17.7365 23.8333 17.4772 24.1272C16.498 25.237 15.0651 25.937 13.4687 25.937C11.8724 25.937 10.4395 25.237 9.46034 24.1272Z"
        fill="url(#paint32_radial_4451_12873)"
      />
      <Path
        d="M9.46034 24.1272C9.201 23.8333 9.4315 23.4068 9.82349 23.4068H10.4077C10.8749 23.4068 11.1754 23.3504 11.4689 23.2953C11.7489 23.2428 12.0225 23.1914 12.4281 23.1914C13.08 23.1914 13.521 23.2429 13.9717 23.2955C14.4428 23.3506 14.9246 23.4068 15.6688 23.4068H17.114C17.506 23.4068 17.7365 23.8333 17.4772 24.1272C16.498 25.237 15.0651 25.937 13.4687 25.937C11.8724 25.937 10.4395 25.237 9.46034 24.1272Z"
        fill="url(#paint33_radial_4451_12873)"
      />
      <Path
        d="M30.5397 24.1272C30.799 23.8333 30.5685 23.4068 30.1765 23.4068H29.5923C29.1251 23.4068 28.8246 23.3504 28.5311 23.2953C28.2511 23.2428 27.9775 23.1914 27.5719 23.1914C26.92 23.1914 26.479 23.2429 26.0283 23.2955C25.5572 23.3506 25.0754 23.4068 24.3312 23.4068H22.886C22.494 23.4068 22.2635 23.8333 22.5228 24.1272C23.502 25.237 24.9349 25.937 26.5313 25.937C28.1276 25.937 29.5605 25.237 30.5397 24.1272Z"
        fill="url(#paint34_radial_4451_12873)"
      />
      <Path
        d="M30.5397 24.1272C30.799 23.8333 30.5685 23.4068 30.1765 23.4068H29.5923C29.1251 23.4068 28.8246 23.3504 28.5311 23.2953C28.2511 23.2428 27.9775 23.1914 27.5719 23.1914C26.92 23.1914 26.479 23.2429 26.0283 23.2955C25.5572 23.3506 25.0754 23.4068 24.3312 23.4068H22.886C22.494 23.4068 22.2635 23.8333 22.5228 24.1272C23.502 25.237 24.9349 25.937 26.5313 25.937C28.1276 25.937 29.5605 25.237 30.5397 24.1272Z"
        fill="url(#paint35_linear_4451_12873)"
      />
      <Path
        d="M30.5397 24.1272C30.799 23.8333 30.5685 23.4068 30.1765 23.4068H29.5923C29.1251 23.4068 28.8246 23.3504 28.5311 23.2953C28.2511 23.2428 27.9775 23.1914 27.5719 23.1914C26.92 23.1914 26.479 23.2429 26.0283 23.2955C25.5572 23.3506 25.0754 23.4068 24.3312 23.4068H22.886C22.494 23.4068 22.2635 23.8333 22.5228 24.1272C23.502 25.237 24.9349 25.937 26.5313 25.937C28.1276 25.937 29.5605 25.237 30.5397 24.1272Z"
        fill="url(#paint36_radial_4451_12873)"
      />
      <Path
        d="M30.5397 24.1272C30.799 23.8333 30.5685 23.4068 30.1765 23.4068H29.5923C29.1251 23.4068 28.8246 23.3504 28.5311 23.2953C28.2511 23.2428 27.9775 23.1914 27.5719 23.1914C26.92 23.1914 26.479 23.2429 26.0283 23.2955C25.5572 23.3506 25.0754 23.4068 24.3312 23.4068H22.886C22.494 23.4068 22.2635 23.8333 22.5228 24.1272C23.502 25.237 24.9349 25.937 26.5313 25.937C28.1276 25.937 29.5605 25.237 30.5397 24.1272Z"
        fill="url(#paint37_radial_4451_12873)"
      />
      <Defs>
        <radialGradient
          id="paint0_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.2813 11.6875) rotate(132.839) scale(44.5352)"
        >
          <Stop stopColor="#FFF478" />
          <Stop offset="0.474827" stopColor="#FFB02E" />
          <Stop offset="1" stopColor="#F70A8D" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20 15.8438) rotate(101.31) scale(21.1928 27.1439)"
        >
          <Stop offset="0.787821" stopColor="#F59639" stopOpacity="0" />
          <Stop offset="0.972509" stopColor="#FF7DCE" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.375 17.625) rotate(135) scale(48.702)"
        >
          <Stop offset="0.314853" stopOpacity="0" />
          <Stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20 21.1875) rotate(77.692) scale(33.4245)"
        >
          <Stop offset="0.507903" stopColor="#7D6133" stopOpacity="0" />
          <Stop offset="1" stopColor="#715B32" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.5938 20.5938) rotate(55.7131) scale(15.8098 11.4598)"
        >
          <Stop stopColor="#FFB849" />
          <Stop offset="1" stopColor="#FFB847" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.3438 22.375) rotate(9.86581) scale(13.8612)"
        >
          <Stop stopColor="#FFA64B" />
          <Stop offset="0.900412" stopColor="#FFAE46" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.7812 18.8125) rotate(43.9708) scale(70.1254)"
        >
          <Stop offset="0.185425" stopOpacity="0" />
          <Stop offset="1" stopOpacity="0.4" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.2577 12.3119) rotate(149.493) scale(1.65669 4.38168)"
        >
          <Stop stopColor="#D48C0E" stopOpacity="0" />
          <Stop offset="0.328125" stopColor="#D48C0E" stopOpacity="0.79" />
          <Stop offset="0.653942" stopColor="#D48C0E" stopOpacity="0.177083" />
          <Stop offset="1" stopColor="#D48C0E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.1149 14.1646) rotate(63.435) scale(1.45875 3.85817)"
        >
          <Stop stopColor="#D48C0E" stopOpacity="0" />
          <Stop offset="0.078125" stopColor="#D48C0E" stopOpacity="0.79" />
          <Stop offset="0.653942" stopColor="#D48C0E" stopOpacity="0.177083" />
          <Stop offset="1" stopColor="#D48C0E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(29.7125 14.3853) rotate(113.375) scale(1.49211 2.9316)"
        >
          <Stop offset="0.11706" stopColor="#D48C0E" />
          <Stop offset="0.766003" stopColor="#D48C0E" stopOpacity="0.19" />
          <Stop offset="1" stopColor="#D48C0E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint10_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.3201 13.7964) rotate(-4.08562) scale(4.42278)"
        >
          <Stop stopColor="#D59A13" />
          <Stop offset="1" stopColor="#D59A13" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint11_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.3245 13.2794) rotate(47.0454) scale(1.27597 3.21381)"
        >
          <Stop stopColor="#D48C0E" />
          <Stop offset="0.776042" stopColor="#D48C0E" stopOpacity="0.145833" />
          <Stop offset="1" stopColor="#D48C0E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint12_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.3 12.6186) rotate(134.275) scale(1.76302 3.46388)"
        >
          <Stop offset="0.0929187" stopColor="#D48C0E" stopOpacity="0.81" />
          <Stop offset="0.720937" stopColor="#D48C0E" stopOpacity="0.169583" />
          <Stop offset="1" stopColor="#D48C0E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint13_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.1511 10.9469) rotate(170.405) scale(1.04818 1.69975)"
        >
          <Stop stopColor="#D48C0E" stopOpacity="0.78" />
          <Stop offset="0.658073" stopColor="#D48C0E" stopOpacity="0.169583" />
          <Stop offset="1" stopColor="#D48C0E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint14_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.7777 11.1114) rotate(18.9246) scale(1.16785 2.29451)"
        >
          <Stop stopColor="#D48C0E" stopOpacity="0.56" />
          <Stop offset="0.658234" stopColor="#D48C0E" stopOpacity="0.145833" />
          <Stop offset="1" stopColor="#D48C0E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint15_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28.9758 12.1792) rotate(-45) scale(1.21047 2.61391)"
        >
          <Stop stopColor="#FFF478" />
          <Stop offset="1" stopColor="#FFF478" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint16_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.2989 21.5882) rotate(120.712) scale(6.73026)"
        >
          <Stop offset="0.479527" stopColor="#C84E28" />
          <Stop offset="1" stopColor="#FFB247" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint17_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.3625 21.1117) rotate(-177.207) scale(6.25282)"
        >
          <Stop offset="0.31498" stopColor="#392108" />
          <Stop offset="1" stopColor="#C87928" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint18_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20 26.2307) rotate(90) scale(4.91879 6.66463)"
        >
          <Stop offset="0.34763" stopColor="#4D3027" />
          <Stop offset="0.628228" stopColor="#57444A" />
          <Stop offset="1" stopColor="#4E2553" />
          <Stop offset="1" stopColor="#502A56" />
        </radialGradient>
        <radialGradient
          id="paint19_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.0314 13.1108) rotate(61.384) scale(0.932471 2.75347)"
        >
          <Stop stopColor="#4C3636" />
          <Stop offset="1" stopColor="#4C3636" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint20_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.1589 10.7817) rotate(43.9191) scale(0.839453 4.08164)"
        >
          <Stop offset="0.309609" stopColor="#4C3636" />
          <Stop offset="1" stopColor="#4C3636" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint21_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.6707 9.47307) rotate(115.628) scale(2.09721 1.61817)"
        >
          <Stop stopColor="#4C3636" />
          <Stop offset="0.479481" stopColor="#4C3636" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint22_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.13293 14.4963) rotate(-30.4655) scale(1.01652 3.13318)"
        >
          <Stop stopColor="#4C3636" />
          <Stop offset="1" stopColor="#4C3636" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint23_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(29.7447 12.8356) rotate(120.958) scale(1.07178 2.95546)"
        >
          <Stop offset="0.22426" stopColor="#5E4F4F" />
          <Stop offset="1" stopColor="#5E4F4F" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint24_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.6018 10.5509) rotate(148.815) scale(0.918101 3.15539)"
        >
          <Stop stopColor="#5E4F4F" />
          <Stop offset="0.739583" stopColor="#5E4F4F" stopOpacity="0.15625" />
          <Stop offset="1" stopColor="#5E4F4F" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint25_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.7849 9.3651) rotate(85.6013) scale(0.968371 1.51499)"
        >
          <Stop stopColor="#5E4F4F" />
          <Stop offset="1" stopColor="#5E4F4F" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint26_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.9343 14.3907) rotate(164.407) scale(1.10522 1.72798)"
        >
          <Stop stopColor="#5E4F4F" />
          <Stop offset="1" stopColor="#5E4F4F" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint27_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.3819 20.7491) rotate(-70.1721) scale(3.51744 4.32598)"
        >
          <Stop offset="0.134935" stopColor="#FAF9F9" stopOpacity="0" />
          <Stop offset="1" stopColor="#FAF9F9" />
        </radialGradient>
        <radialGradient
          id="paint28_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.4327 20.7491) rotate(-70.1721) scale(3.51744 4.32598)"
        >
          <Stop offset="0.134935" stopColor="#FAF9F9" stopOpacity="0" />
          <Stop offset="1" stopColor="#FAF9F9" />
        </radialGradient>
        <radialGradient
          id="paint29_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.2625 23.5405) rotate(98.3333) scale(2.4221 7.82829)"
        >
          <Stop offset="0.286349" stopColor="#4D8AFF" />
          <Stop offset="0.882353" stopColor="#3E4FFF" />
        </radialGradient>
        <linearGradient
          id="paint30_linear_4451_12873"
          x1="17.005"
          y1="25.3177"
          x2="16.3907"
          y2="24.3084"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.0952251" stopColor="#3B4FFF" />
          <Stop offset="1" stopColor="#3B4FFF" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint31_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.6789 23.9731) rotate(64.9047) scale(0.463687 1.43044)"
        >
          <Stop stopColor="#659DFF" />
          <Stop offset="1" stopColor="#4F8BFF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint32_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.4687 24.1848) rotate(90) scale(0.901109 2.98265)"
        >
          <Stop stopColor="#3F80FF" />
          <Stop offset="1" stopColor="#417EFF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint33_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.8185 23.9923) rotate(12.3391) scale(1.01388 0.345224)"
        >
          <Stop stopColor="#3F80FF" />
          <Stop offset="1" stopColor="#417EFF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint34_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.7375 23.5405) rotate(81.6667) scale(2.4221 7.82829)"
        >
          <Stop offset="0.286349" stopColor="#4D8AFF" />
          <Stop offset="0.882353" stopColor="#3E4FFF" />
        </radialGradient>
        <linearGradient
          id="paint35_linear_4451_12873"
          x1="22.995"
          y1="25.3177"
          x2="23.6093"
          y2="24.3084"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.0952251" stopColor="#3B4FFF" />
          <Stop offset="1" stopColor="#3B4FFF" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint36_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(29.9045 23.9411) rotate(-111.181) scale(0.467797 1.30463)"
        >
          <Stop stopColor="#659DFF" />
          <Stop offset="1" stopColor="#4579FF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint37_radial_4451_12873"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.5313 24.1848) rotate(90) scale(0.901109 2.98265)"
        >
          <Stop stopColor="#3F80FF" />
          <Stop offset="1" stopColor="#417EFF" stopOpacity="0" />
        </radialGradient>
      </Defs>
    </Svg>
  );
};

export default FaceHoldingBackTearsEmoji;
