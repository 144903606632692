import { FunctionComponent, useEffect, MutableRefObject } from 'react';
import { Checkbox } from '@talkspace/react-toolkit';
import AccountPanel from '../AccountPanel';
import AccountPanelRow from '../AccountPanelRow';
import { MarketingCommunicationsByName } from '../../reducers/marketingCommunications';
import useObjectState from '../../hooks/useObjectState';

export interface FormRef {
  getFormState: () => Partial<MarketingCommunicationsByName>;
}

const NotificationPreferencesEdit: FunctionComponent<{
  marketingCommunicationsByName: MarketingCommunicationsByName;
  formRef: MutableRefObject<FormRef | null>;
  isError: boolean;
}> = ({ marketingCommunicationsByName, formRef, isError }) => {
  const [form, setForm] = useObjectState<MarketingCommunicationsByName>(
    marketingCommunicationsByName
  );

  useEffect(() => {
    if (isError) {
      setForm(marketingCommunicationsByName);
    }
  }, [marketingCommunicationsByName, isError, setForm]);

  const { marketingNews, marketingPromotions, marketingBlog, marketingEducation } = form;

  // eslint-disable-next-line no-param-reassign
  formRef.current = { getFormState: () => form };

  return (
    <AccountPanel>
      <AccountPanelRow
        label="Marketing communications"
        description="Keep up with what's going on at Talkspace, including new promotions and features, educational content, and updates from our blog"
        actionComponent={
          <>
            <Checkbox
              label="Promotions"
              isChecked={marketingPromotions.push || marketingPromotions.email}
              setIsChecked={(bool) =>
                setForm({
                  marketingPromotions: {
                    ...marketingPromotions,
                    email: !!bool,
                  },
                })
              }
            />
            <Checkbox
              label="Product news"
              isChecked={marketingNews.push || marketingNews.email}
              setIsChecked={(bool) =>
                setForm({
                  marketingNews: {
                    ...marketingNews,
                    email: !!bool,
                  },
                })
              }
            />
            <Checkbox
              label="Product education"
              isChecked={marketingEducation.push || marketingEducation.email}
              setIsChecked={(bool) =>
                setForm({
                  marketingEducation: {
                    ...marketingEducation,
                    email: !!bool,
                  },
                })
              }
            />
            <Checkbox
              label="Blog updates"
              isChecked={marketingBlog.push || marketingBlog.email}
              setIsChecked={(bool) =>
                setForm({
                  marketingBlog: {
                    ...marketingBlog,
                    email: !!bool,
                  },
                })
              }
            />
          </>
        }
      />
    </AccountPanel>
  );
};

export default NotificationPreferencesEdit;
