import { useEffect } from 'react';

interface Props {
  containerRef: React.RefObject<HTMLElement | undefined>;
  callback: () => void;
}
const useHandleClickOutsideContainer = ({ containerRef, callback }: Props) => {
  useEffect(() => {
    const handleCallback = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        callback();
      }
    };
    document.addEventListener('click', handleCallback);
    return () => {
      document.removeEventListener('click', handleCallback);
    };
  }, [callback, containerRef]);
};

export default useHandleClickOutsideContainer;
