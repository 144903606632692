import { useState, useEffect } from 'react';
import * as React from 'react';
import {
  View,
  Button,
  ExtraHuge,
  A11yPhoneInput,
  isPhoneNumberValid,
  SpacingView,
  spacing,
} from '@talkspace/react-toolkit';

import { trackClient2FAVerification } from '@/TwoFactorAuthentication/utils/analytics';
import styled from '../../../core/styled/styled';

const { space300 } = spacing;

const Container = styled(View)({ width: 336 });

const HeaderWrapper = styled(View)({
  textAlign: 'left',
  marginBottom: 30,
  paddingLeft: 4,
});

const InputWrapper = styled(View)({
  marginBottom: space300,
});

interface Props {
  updatePhoneNumber: (
    phoneNumber: string,
    phoneNumberCountryCode: string,
    is2faModal?: boolean
  ) => void;
  onCancelPress: () => void;
  isLoading: boolean;
}

const ChangePhoneModal: React.VFC<Props> = ({ updatePhoneNumber, onCancelPress, isLoading }) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState<string>('US');
  const [isValidPhone, setIsValidPhone] = useState<boolean>(true);

  useEffect(() => {
    if (phoneNumber) {
      setIsValidPhone(isPhoneNumberValid(phoneNumber));
    }
  }, [phoneNumber]);

  const handleSavePress = () => {
    if (isValidPhone && phoneNumber) {
      updatePhoneNumber(phoneNumber, phoneNumberCountryCode);
    }
  };

  const onChangePhoneNumber = (newPhoneNumber?: string) => {
    setPhoneNumber(newPhoneNumber || '');
  };

  const onChangePhoneNumberCountryCode = (newPhoneNumberCountryCode: string) => {
    setPhoneNumberCountryCode(newPhoneNumberCountryCode);
  };

  const handleCancel = () => {
    trackClient2FAVerification('Cancel change phone number - Login and Security');
    onCancelPress();
  };

  return (
    <Container>
      <HeaderWrapper>
        <ExtraHuge as="h1">Change phone number</ExtraHuge>
      </HeaderWrapper>
      <InputWrapper>
        <A11yPhoneInput
          dataQa="myAccountChangeNumberInput"
          wrapperStyle={{ marginBottom: 18 }}
          style={{ width: 335 }}
          value={phoneNumber}
          handleInputValueChange={onChangePhoneNumber}
          handleCountryChange={onChangePhoneNumberCountryCode}
          isError={!isValidPhone}
        />
      </InputWrapper>
      <SpacingView>
        <Button
          text="Save"
          onPress={handleSavePress}
          dataQa="myAccountChangeNumberSaveButton"
          isLoading={isLoading}
          disabled={isLoading}
          style={{ flex: 1 }}
        />
        <Button
          text="Cancel"
          variant="secondary"
          onPress={handleCancel}
          dataQa="myAccountChangeNumberCancelButton"
          style={{ flex: 1 }}
        />
      </SpacingView>
    </Container>
  );
};

export default ChangePhoneModal;
