import { FunctionComponent } from 'react';
import Svg, { G, Circle, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

interface OffTodayIconProps {
  color?: string;
  width?: number;
  height?: number;
  style?: object;
}

const OffTodayIcon: FunctionComponent<OffTodayIconProps> = ({
  color,
  width = 21,
  height = 21,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'day off';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...otherProps}
    >
      <G
        transform="translate(-146 -549) translate(146 549)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Circle fill={color || colors.amber} cx={10.5} cy={10.5} r={7.5} />
        <Path
          d="M10.5 21C16.299 21 21 16.299 21 10.5S16.299 0 10.5 0 0 4.701 0 10.5 4.701 21 10.5 21zm0-3a7.5 7.5 0 110-15 7.5 7.5 0 010 15z"
          fill={colors.white}
          fillRule="nonzero"
        />
        <Path
          d="M11.5 7.109a1 1 0 00-2-.012l-.026 4.355 2.737 2.592a1 1 0 101.375-1.451l-2.107-1.996.021-3.488z"
          fill={colors.white}
          fillRule="nonzero"
        />
      </G>
    </Svg>
  );
};

export default OffTodayIcon;
