import {
  ClientMatchPresentingProblem,
  ImplicitBusinessHour,
  TherapistTimeslot,
} from 'ts-frontend/types';
import { Button, spacing, TextDS, useEmotionTheme, View } from '@talkspace/react-toolkit';
import moment from 'moment';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { TherapistType } from 'ts-frontend/entities/Therapist';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useTranslation } from '@talkspace/i18n';
import CircleUnderlay from '@talkspace/react-toolkit/src/designSystems/components/CircleUnderlay';
import { DoublePersonSwitch as DoublePersonSwitchIllustration } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import { StickyBottomContainer } from 'stepWizard/components/StepWizard/StepWizardStyles';
import MbaMatchInProgress from 'inRoomScheduling/components/MbaMatchInProgress';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import { ClosePopupAction } from '@/auth/reactFrame/ReactFrameTypes';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import ssoHelper, { ZendeskPath } from '@/utils/sso';
import ErrorModal from '../../clientChat/components/ErrorModal/ErrorModal';
import apiHelper from '../../core/api/apiHelper';
import apiWrapper from '../../core/api/apiWrapper';
import { RouteComponentProps, withRouter } from '../../core/routerLib/routerLib';
import styled from '../../core/styled';
import MatchInProgress from '../components/MatchInProgress';
import MatchResults from '../components/MatchResults';
import MatchResultsV2 from '../components/MatchResultsV2';
import useMatchResults from '../hooks/useMatchResults';
import { MatchPayload, MatchResultsData, MatchResult } from '../matchingTypes';

const { space100, space200, space300, space400 } = spacing;
const StyledErrorModalView = styled(View)({
  width: 315,
  height: 208,
  alignSelf: 'center',
});
const NoProviderMatchesIllustration = () => (
  <CircleUnderlay size={136} colorRole="brandSubtleDefault">
    <DoublePersonSwitchIllustration />
  </CircleUnderlay>
);
const StickyFooterCloseButton = ({ closeModal, buttonColorRole, buttonText }) => (
  <StickyBottomContainer noBorder isSticky>
    <Button
      isLoading={false}
      onPress={() => closeModal()}
      style={{
        width: 320,
        backgroundColor: buttonColorRole,
        marginTop: 0,
      }}
      dataQa="MatchPreferenceSummaryPrimaryButton"
      roundedFocusStyle
      aria-describedby="Find new provider"
    >
      <TextDS variant="headingMd" colorRole="textInverse">
        {buttonText}
      </TextDS>
    </Button>
  </StickyBottomContainer>
);
const AvailabilitySwitchNoProviderMatches = ({
  typographyColorRole,
  buttonColorRole,
  noProviderMatchTitle,
  noProviderMatchSubtitle,
  closeModal,
  buttonText,
}) => {
  const { textSubtle } = typographyColorRole;
  const { brandPrimarySurfaceDefault } = buttonColorRole;
  return (
    <View
      style={{
        alignItems: 'center',
        marginTop: space400,
        marginLeft: space200,
        marginRight: space200,
      }}
    >
      <NoProviderMatchesIllustration />
      <TextDS variant="headingXl" style={{ marginTop: space300 }}>
        {noProviderMatchTitle}
      </TextDS>
      <TextDS
        style={{
          marginTop: space100,
          textAlign: 'center',
          color: textSubtle,
        }}
      >
        {noProviderMatchSubtitle}
      </TextDS>
      <StickyFooterCloseButton
        closeModal={closeModal}
        buttonColorRole={brandPrimarySurfaceDefault}
        buttonText={buttonText}
      />
    </View>
  );
};

interface ExtendedMatchPayload extends MatchPayload {
  sessionModality?: string;
}

const cleanMatchPayload = (clientPreferences: ExtendedMatchPayload): MatchPayload => {
  const { sessionModality, ...rest } = clientPreferences;
  return rest;
};

interface MatchResultsContainerProps extends RouteComponentProps {
  roomID: number;
  matchPayload: MatchPayload;
  selectedPresentingProblemAsString: string;
  selectedPresentingProblemsAsExpertise: ClientMatchPresentingProblem[];
  skipPayment: boolean;
  handleSwitchWizardSelectedTherapist?: (
    therapistID: number,
    therapistFirstName: string,
    therapistImage: string,
    therapistType: TherapistType
  ) => void;
  eventCategory?: string;
  clientUserID?: number;
  formerTherapistID?: number;
  setMatchResultsError?: (error: string | null) => void;
  matches?: MatchResult[];
  timeslots?: TherapistTimeslot[];
  isBooking?: boolean;
}

const getNextAvailableTimeSlot = (timeslots: TherapistTimeslot[], therapistID: number) => {
  const nextTimeSlot = timeslots.find((timeslot) => timeslot.therapists?.includes(therapistID));

  return nextTimeSlot ? nextTimeSlot?.start : '';
};

const MatchResultsContainer: FunctionComponent<MatchResultsContainerProps> = ({
  history,
  roomID,
  matchPayload,
  selectedPresentingProblemAsString,
  selectedPresentingProblemsAsExpertise,
  skipPayment,
  handleSwitchWizardSelectedTherapist,
  eventCategory,
  clientUserID,
  formerTherapistID,
  setMatchResultsError,
  matches: matchesProp,
  isBooking,
  timeslots,
}) => {
  const { matchResultsState, setMatchResultsState } = useMatchResults();
  const [isB2B, setIsB2B] = useState(false);
  const [apiCallDuration, setApiCallDuration] = useState(0);

  const closeModal = useCloseModal();
  const { colorRoles } = useEmotionTheme();
  const { availabilitySwitch } = useFlags();

  const { t: tMatchResults } = useTranslation('scheduler');
  const noProviderMatchTitle = tMatchResults(
    'MatchResults.noProviderMatchTitle',
    'Check back soon!'
  );
  const noProviderMatchSubtitle = tMatchResults(
    'MatchResults.noProviderMatchSubtitle',
    `We don't have a provider match for you at the moment. Our network is expanding, with new therapists joining all the time. Please check back in a few days for more options.`
  );
  const buttonText = tMatchResults('MatchResults.buttonText', 'Close');

  useEffect(() => {
    (async () => {
      const startWatch = moment();
      try {
        let isB2BMatches: boolean = false;
        let matches: MatchResult[] = [];

        if (matchesProp) {
          matches = matchesProp;
          isB2BMatches = true;
        } else {
          const cleanedMatchPayload = cleanMatchPayload(matchPayload);
          const matchResult = (
            await apiWrapper.post(
              `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/suggest-therapist-in-platform`,
              { ...(cleanedMatchPayload || {}) }
            )
          ).data.data as MatchResultsData;

          ({ matches, isB2B: isB2BMatches } = matchResult);
        }

        const therapistIds = matches.map(({ userId }) => userId).join(',');
        const implicitBusinessHours: { [index: string]: ImplicitBusinessHour[] } = (
          await apiWrapper.get(
            `${
              apiHelper().apiEndpoint
            }/v2/therapist/implicit-business-hours?therapistIDs=${therapistIds}`
          )
        ).data.data.reduce((therapistHours, { therapistID, businessHours }) => {
          return { ...therapistHours, [therapistID]: businessHours };
        }, {});

        const stopWatch = moment();

        setApiCallDuration(moment.duration(stopWatch.diff(startWatch)).asMilliseconds());

        setMatchResultsState({
          matchResults: matches.map((result) => {
            return {
              ...result,
              details: {
                ...result.details,
                businessHours: implicitBusinessHours[result.userId],
                image: `${apiHelper().cdnEndpoint}/images/application/therapist/440/${
                  result.userId
                }.png`,
                expertise: {
                  ...result.details.expertise,
                  mostRelevant: result.details.expertise.mostRelevant.length
                    ? result.details.expertise.mostRelevant
                    : result.details.allFieldsOfExpertise.slice(0, 5),
                },
                ...(availabilitySwitch && timeslots
                  ? {
                      nextAvailableTimeslot: getNextAvailableTimeSlot(timeslots, result.details.id),
                    }
                  : {}),
              },
            };
          }),
        });
        setIsB2B(isB2BMatches);
      } catch (e) {
        if (setMatchResultsError) {
          setMatchResultsError(e.message);
        } else {
          // TODO trigger a toast message in the previous screen
          history.goBack();
        }
      }
    })();
  }, [
    history,
    matchesProp,
    setMatchResultsState,
    matchPayload,
    roomID,
    setMatchResultsError,
    availabilitySwitch,
    timeslots,
  ]);

  useEffect(() => {
    const LOADER_ANIMATION_DURATION = 5000;
    if (!matchResultsState.matchResults) return undefined;
    const timer = setTimeout(() => {
      setMatchResultsState({
        isLoading: false,
      });
    }, LOADER_ANIMATION_DURATION - apiCallDuration);
    return () => {
      clearTimeout(timer);
      setApiCallDuration(0);
    };
  }, [apiCallDuration, matchResultsState.matchResults, setMatchResultsState]);

  const handleOnContactSupportClick = useCallback(() => {
    const reactFrameService = ReactFrameService.instance();
    if (reactFrameService.isInFrame()) {
      const closingPayload = {
        navigateTo: 'zendesk',
        metadata: { path: 'CONTACT_US' },
      };
      reactFrameService.closePopup(closingPayload as ClosePopupAction);
    } else {
      ssoHelper.openZendesk(ssoHelper.ZendeskPath.CONTACT_US as ZendeskPath).finally(() => {
        closeModal({
          navigateTo: 'room',
          metadata: { roomID },
        });
      });
    }
  }, [closeModal, roomID]);

  if (matchResultsState.isLoading)
    return availabilitySwitch ? (
      <MbaMatchInProgress isAvailabilitySwitch={availabilitySwitch} />
    ) : (
      <MatchInProgress />
    );
  if (matchResultsState.matchResults && matchResultsState.matchResults.length > 0) {
    return availabilitySwitch ? (
      <MatchResultsV2
        roomID={roomID}
        matchPayload={matchPayload}
        selectedPresentingProblemAsString={selectedPresentingProblemAsString}
        selectedPresentingProblemsAsExpertise={selectedPresentingProblemsAsExpertise}
        skipPayment={skipPayment}
        handleSwitchWizardSelectedTherapist={handleSwitchWizardSelectedTherapist}
        eventCategory={eventCategory}
        clientUserID={clientUserID}
        formerTherapistID={formerTherapistID}
        isB2B={isB2B}
        isBooking={isBooking}
      />
    ) : (
      <MatchResults
        roomID={roomID}
        matchPayload={matchPayload}
        selectedPresentingProblemAsString={selectedPresentingProblemAsString}
        selectedPresentingProblemsAsExpertise={selectedPresentingProblemsAsExpertise}
        skipPayment={skipPayment}
        handleSwitchWizardSelectedTherapist={handleSwitchWizardSelectedTherapist}
        eventCategory={eventCategory}
        clientUserID={clientUserID}
        formerTherapistID={formerTherapistID}
        isB2B={isB2B}
      />
    );
  }
  return (
    <>
      <StyledErrorModalView>
        {availabilitySwitch ? (
          <AvailabilitySwitchNoProviderMatches
            {...{
              typographyColorRole: colorRoles.typography,
              buttonColorRole: colorRoles.button,
              noProviderMatchTitle,
              noProviderMatchSubtitle,
              closeModal,
              buttonText,
            }}
          />
        ) : (
          <ErrorModal
            error="Find your match"
            errorMessage={
              <>
                <b>Still searching for your match</b>
                <br />
                <br />
                Hello! Due to high demand, we're not currently able to match you with a provider. We
                know this can feel discouraging and hope you understand that we are doing our best
                to support our clients and providers.
                <br />
                <br />
                We value you as a Talkspace client and want to ensure this next match is a good fit
                for you! Our provider availability changes constantly so we recommend waiting a few
                days and then trying this process again.
              </>
            }
            showPrimaryButton={false}
            onButtonPress={handleOnContactSupportClick}
            buttonText="Contact customer support"
            doesErrorIconAppear={false}
            buttonDataQa="matchResultsErrorModalButton"
          />
        )}
      </StyledErrorModalView>
    </>
  );
};

export default withRouter(MatchResultsContainer);
