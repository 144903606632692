import { useQuery } from 'react-query';

import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/utils/api';
import { therapistImplicitBusinessHoursQueryKey } from './queryKeys';

export type TherapistImplicitBusinessHoursResponse = { hourValue: number; hourIndex: number }[];

const fetchTherapistImplicitBusinessHours = (therapistID?: number | string) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/therapist/implicit-business-hours?therapistIDs=${therapistID}`
  );
  return data.data[0].businessHours;
};

const useQueryTherapistImplicitBusinessHours = (therapistID?: number | string) =>
  useQuery<TherapistImplicitBusinessHoursResponse, Error>({
    queryKey: therapistImplicitBusinessHoursQueryKey(therapistID),
    queryFn: fetchTherapistImplicitBusinessHours(therapistID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: Boolean(therapistID),
  });

export default useQueryTherapistImplicitBusinessHours;
