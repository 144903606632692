import { useMutation } from 'react-query';

import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

import { ConfirmAsyncSessionParams } from '../types';

interface CreateAsyncSessionMutateVariables {
  roomID: number;
  data: ConfirmAsyncSessionParams;
}

const createAsyncSession = async ({
  roomID,
  data,
}: CreateAsyncSessionMutateVariables): Promise<void> => {
  await apiWrapper.post(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/async-session`, data);
};

const useMutationCreateAsyncSession = () =>
  useMutation<void, Error, CreateAsyncSessionMutateVariables>(createAsyncSession, {
    onSuccess: () => {
      const refetchSessionStatusEvent = new CustomEvent('refetchSessionStatus', {
        detail: {},
      });
      window.dispatchEvent(refetchSessionStatusEvent);
    },
  });

export default useMutationCreateAsyncSession;
