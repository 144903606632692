import { BaseWizardState, baseWizardInitialState } from 'stepWizard';

interface NPSPayload {
  rating: number;
}

export interface State extends BaseWizardState {
  nps: boolean;
  roomID: number;
  clientUserID: number;
  source: string;
  eventCategory: string;
  npsData: NPSPayload;
  userPromptID?: number;
}

export const getInitialState = (clientUserID, roomID, source, contextID, userPromptID): State => {
  return {
    ...baseWizardInitialState(source, contextID, {
      userPromptID,
    }),
    nps: true, // align with `displayCondition` in scheme
    roomID,
    clientUserID,
    eventCategory: 'NPS',
    npsData: {
      rating: -1,
    },
    userPromptID,
  };
};
