import { useEffect } from 'react';
import { useTranslation } from '@talkspace/i18n';
import { trackBookFirstSessionPrompt } from '../../utils/analytics/events';
import { useHistory, useParams } from '@/core/routerLib';
import OnboardingPage from './OnboardingPage';
import BookFirstSession from '../assets/BookFirstSession';
import CameraSparkles from '../assets/CameraSparkles';
import { useOnboardingState } from '../hooks/onboardingContext';
import useAllowClosing from '../hooks/useAllowClosing';

const OnboardingPageBookFirstSession = ({
  totalSteps,
  currentStep,
}: {
  totalSteps: number;
  currentStep: number;
}) => {
  const history = useHistory();
  const { roomID } = useParams<{ roomID: string | undefined }>();
  const { steps } = useOnboardingState();
  const { metadata: { isMessagingFirstVideoRequired = false } = {} } = steps
    ? steps[currentStep]
    : {};
  const { t: tBookingScreen } = useTranslation('bookingScreen');
  const allowClosing = useAllowClosing();

  useEffect(() => {
    if (roomID) {
      trackBookFirstSessionPrompt({
        roomID: Number(roomID),
        modalityNudge: 'Match Based on Availability',
      });
    }
  }, [roomID]);

  let illustration = <BookFirstSession />;
  let title = tBookingScreen('book.title', 'Book your first session');
  let description = tBookingScreen(
    'book.description',
    'Direct interaction with your provider is clinically proven to help you quickly form a meaningful bond with them.'
  );
  // for messaging only plans in states that require video show different intro information
  if (isMessagingFirstVideoRequired) {
    illustration = <CameraSparkles />;
    title = tBookingScreen('book.book', 'Book your intro video call');
    description = tBookingScreen(
      'book.your',
      'Your state requires you to meet your provider over video before messaging therapy begins. Direct interaction with your provider is clinically proven to help you quickly form a meaningful bond with them.'
    );
  }
  return (
    <OnboardingPage
      totalSteps={totalSteps}
      currentStep={currentStep}
      illustration={illustration}
      title={title}
      description={description}
      ctaText={tBookingScreen('book.continue', 'Continue')}
      onPress={() => history.push(`/room/${roomID}/onboarding/book-session`)}
      allowClosing={allowClosing}
      preventReopening
    />
  );
};

export default OnboardingPageBookFirstSession;
