import { useEffect, useState, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from '@talkspace/i18n';
import { useFlags } from 'launchDarkly/FlagsProvider';
import * as yup from 'yup';
import {
  View,
  Button,
  Large,
  ExtraHuge,
  RHFInput,
  useEmotionTheme,
  OptionType,
  Label,
  Checkbox,
  SelectRounded,
  A11Y_COLORS,
  Spinner,
  useWindowWidthState,
} from '@talkspace/react-toolkit';

import { TFParentalConsentV2 } from '@talkspace/i18n/types';
import { ValueType } from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import { getParamByName, parseJwt } from 'ts-frontend/utils';
import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';
import ConsentReceived from '../components/ConsentReceived';
import ConsentFailed from '../components/ConsentFailed';
import AcceptTerms from '../components/AcceptTerms';
import NYCAcceptTerms from '../components/NYCAcceptTerms';
import useScreenSize, { isResponsive } from '../../core/hooks/useScreenSize';
import styled from '../../core/styled';
import useMutationSubmitConsent from '../hooks/useMutationSubmitConsent';

interface ParentalConsentFields {
  consenterRelationship: string;
  consenterFirstName: string;
  consenterLastName: string;
}

const StyledLabelContainer = styled(View)(() => {
  return {
    alignItems: 'center',
  };
});

const StyledLabel = styled(Label)({
  marginBottom: 7,
});

const MainView = styled(View)({
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: 49,
  maxWidth: 335,
});

const SubmitButton = ({ ...props }) => {
  const { colors } = useEmotionTheme();
  return (
    <Button
      type="submit"
      style={{
        fontFamily: 'Roboto',
        backgroundColor: colors.green,
        fontSize: 19,
        fontWeight: 'bold',
        width: 335,
        minHeight: 55,
        borderRadius: 10,
        display: 'flex',
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.white,
        letterSpacing: 0.74,
        textAlign: 'center',
      }}
      {...props}
    />
  );
};

const dropdownStyles = {
  clearIndicator: (provider) => {
    return {
      ...provider,
      color: A11Y_COLORS.permaLividBlueNew,
    };
  },
  control: (provider) => {
    return {
      ...provider,
      border: `1px solid ${A11Y_COLORS.permaLividBlueNew}`,
    };
  },
  dropdownIndicator: (provider) => {
    return {
      ...provider,
      color: A11Y_COLORS.permaLividBlueNew,
    };
  },
  menu: (provider) => {
    return {
      ...provider,
      borderColor: A11Y_COLORS.permaLividBlueNew,
    };
  },
};

const getParentalConsentSchema: (
  tCopy: TFParentalConsentV2,
  flagEnabled: boolean
) => yup.SchemaOf<ParentalConsentFields> = (tCopy: TFParentalConsentV2, flagEnabled: boolean) =>
  yup.object().shape({
    consenterRelationship: yup
      .string()
      .test(
        'has-selected-relation',
        flagEnabled ? tCopy('validation.relationship') : 'Please select relationship.',
        (userInput: ParentalConsentFields['consenterRelationship']) => !!userInput
      )
      .required(flagEnabled ? tCopy('validation.relationship') : 'Please select relationship.'),
    consenterFirstName: yup
      .string()
      .test(
        'has-entered-first-name',
        flagEnabled ? tCopy('validation.firstName') : 'Please enter first name.',
        (userInput: ParentalConsentFields['consenterFirstName']) => !!userInput
      )
      .required(flagEnabled ? tCopy('validation.firstName') : 'Please enter first name.'),
    consenterLastName: yup
      .string()
      .test(
        'has-entered-last-name',
        flagEnabled ? tCopy('validation.lastName') : 'Please enter last name.',
        (userInput: ParentalConsentFields['consenterLastName']) => !!userInput
      )
      .required(flagEnabled ? tCopy('validation.lastName') : 'Please enter last name.'),
  });

const getRelationshipOptions = (tCopy: TFParentalConsentV2, flagEnabled: boolean) => [
  {
    label: flagEnabled ? tCopy('dropdownRelationshipToTeen.optionParent') : 'Parent',
    value: 'parent',
  },
  {
    label: flagEnabled ? tCopy('dropdownRelationshipToTeen.optionGuardian') : 'Guardian',
    value: 'guardian',
  },
];

const ParentalConsentContainer = () => {
  const {
    mutate: submitConsent,
    isLoading: isSubmitConsentLoading,
    isSuccess: isSubmitConsentSuccess,
    isError: isSubmitConsentError,
    error: submitConsentError,
  } = useMutationSubmitConsent();
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isFormAlreadySubmitted, setIsFormAlreadySubmitted] = useState(false);
  const [consentError, setConsentError] = useState('');
  const [consentErrorTitle, setConsentErrorTitle] = useState('');
  const [userFirstName, setUserFirstName] = useState('');
  const [getLink, setGetLink] = useState<string | undefined>(undefined);
  const [postLink, setPostLink] = useState<string | undefined>(undefined);
  const screenSize = useScreenSize();
  const [dropdownValue, setDropdownValue] = useState<OptionType<string>>({
    value: '',
    label: '',
  });
  const { isMobile } = useWindowWidthState();
  const token = getParamByName('token');
  const isNYCTeen = getParamByName('isNYCTeen');

  const WIDTH = isMobile ? 335 : 378;
  const HALF_WIDTH = isMobile ? 164 : 185;
  const { localizationParentalConsent } = useFlags();

  const { t: tParentalConsent } = useTranslation('parentalConsentV2', {
    lng: localizationParentalConsent ? undefined : 'en',
  });

  const parentalConsentSchema = useMemo(
    () => getParentalConsentSchema(tParentalConsent, localizationParentalConsent),
    [tParentalConsent, localizationParentalConsent]
  );
  const methods = useForm<ParentalConsentFields>({
    resolver: yupResolver(parentalConsentSchema),
    defaultValues: {
      consenterRelationship: '',
      consenterFirstName: '',
      consenterLastName: '',
    } as ParentalConsentFields,
  });
  const { handleSubmit, setValue } = methods;

  const relationshipOptions = useMemo(
    () => getRelationshipOptions(tParentalConsent, localizationParentalConsent),
    [tParentalConsent, localizationParentalConsent]
  );

  useEffect(() => {
    if (token && !getLink && !postLink) {
      try {
        const { resource } = parseJwt(token);
        const getParentConsentLink = resource.find((x) => x.method === 'get')?.path;
        const postParentConsentLink = resource.find((x) => x.method === 'post')?.path;
        setGetLink(getParentConsentLink);
        setPostLink(postParentConsentLink);
      } catch (e) {
        setConsentError(
          localizationParentalConsent
            ? tParentalConsent('errors.loadingConsentForm')
            : 'Error loading consent'
        );
      }
    } else if (!token) {
      setConsentError(
        localizationParentalConsent ? tParentalConsent('errors.invalidToken') : 'Token is invalid'
      );
    }
  }, [token, getLink, postLink, tParentalConsent, localizationParentalConsent]);

  useEffect(() => {
    async function loadConsent() {
      try {
        const parentalConsentRes = await apiWrapper.get(`${apiHelper().apiEndpoint}${getLink}`);
        const { data } = parentalConsentRes;
        const {
          data: {
            consenterFirstName,
            consenterLastName,
            consenterRelationship,
            userFirstName: uFirstName,
            consentGrantedAt,
          },
        } = data;
        if (consentGrantedAt) {
          setIsFormAlreadySubmitted(true);
        }
        setValue('consenterFirstName', consenterFirstName);
        setValue('consenterLastName', consenterLastName);
        setUserFirstName(uFirstName || 'N/A');
        const relationship = relationshipOptions.find((o) => o.value === consenterRelationship);
        if (relationship) {
          setValue('consenterRelationship', consenterRelationship);
          setDropdownValue(relationship);
        }
        setIsFormLoading(false);
      } catch (error) {
        if (error?.data?.status === 400) {
          setConsentError(
            localizationParentalConsent
              ? tParentalConsent('errors.linkNoLongerValid')
              : 'This link is no longer valid. Please check your email for the most recent link to this form.'
          );
          setConsentErrorTitle(
            localizationParentalConsent
              ? tParentalConsent('errors.linkNoLongerValidTitle')
              : 'Expired link'
          );
        } else {
          setConsentError(
            localizationParentalConsent
              ? tParentalConsent('errors.loadingConsentForm')
              : 'Error loading consent form'
          );
        }
        setIsFormLoading(false);
      }
    }

    if (getLink) {
      loadConsent();
    }
  }, [getLink, setValue, relationshipOptions, tParentalConsent, localizationParentalConsent]);

  const handleDropdownChange = (value: ValueType<OptionType<string | undefined>, boolean>) => {
    setDropdownValue(value as OptionType<string>);
    setValue('consenterRelationship', (value as OptionType<string>).value);
  };

  const onSubmit = (data: ParentalConsentFields) => {
    if (!token || !postLink) return;
    submitConsent({
      token,
      urlPath: postLink,
      body: data,
    });
  };

  const disableSubmitButton = !termsAccepted || isSubmitConsentLoading;

  if (isFormLoading) {
    return <Spinner />;
  }
  if (isSubmitConsentSuccess) {
    return (
      <MainView>
        <ConsentReceived isConsentAlreadyGranted={false} />
      </MainView>
    );
  }

  const isConsentAlreadyGranted =
    isFormAlreadySubmitted || submitConsentError?.message === 'Consent already granted';
  if (isConsentAlreadyGranted) {
    return (
      <MainView>
        <ConsentReceived isConsentAlreadyGranted={isConsentAlreadyGranted} />
      </MainView>
    );
  }
  if (consentError || isSubmitConsentError) {
    const errorMessage = consentError || submitConsentError?.message || '';
    return (
      <MainView>
        <ConsentFailed error={errorMessage} title={consentErrorTitle} />
      </MainView>
    );
  }
  return (
    <View align="center">
      <View
        align="center"
        style={{
          marginTop: isResponsive(screenSize) ? 30 : 60,
          marginBottom: 29,
        }}
      >
        <ExtraHuge style={{ marginBottom: 10, textAlign: 'center' }}>
          {localizationParentalConsent ? (
            <Trans t={tParentalConsent} i18nKey="consentPage.headline">
              Informed consent for {{ first_name: userFirstName }}
            </Trans>
          ) : (
            <>Informed consent for {userFirstName}</>
          )}
        </ExtraHuge>
        <View
          style={{
            textAlign: 'center',
            width: WIDTH,
          }}
        >
          <Large variant="largeDarkGrey">
            {localizationParentalConsent ? (
              <Trans t={tParentalConsent} i18nKey="consentPage.body">
                In order for {{ first_name: userFirstName }} to start therapy, we need your consent.
                Please fill out this form at your earliest convenience.
              </Trans>
            ) : (
              <>
                In order for {userFirstName} to start therapy, we need your consent. Please fill out
                this form at your earliest convenience.
              </>
            )}
          </Large>
        </View>
      </View>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: WIDTH }}>
            <RHFInput
              fieldName="consenterFirstName"
              label={
                localizationParentalConsent
                  ? tParentalConsent('consentPage.fieldLabelFirstName')
                  : 'First name'
              }
              containerStyle={{ width: HALF_WIDTH }}
              wrapperStyle={{ width: HALF_WIDTH }}
              labelStyle={{ color: A11Y_COLORS.permaWaikawaGreyNew }}
              inputStyle={{ borderColor: A11Y_COLORS.permaLividBlueNew }}
            />
            <RHFInput
              fieldName="consenterLastName"
              label={
                localizationParentalConsent
                  ? tParentalConsent('consentPage.fieldLabelLastName')
                  : 'Last name'
              }
              containerStyle={{ width: HALF_WIDTH }}
              wrapperStyle={{ width: HALF_WIDTH }}
              labelStyle={{ color: A11Y_COLORS.permaWaikawaGreyNew }}
              inputStyle={{ borderColor: A11Y_COLORS.permaLividBlueNew }}
            />
          </View>
          <View
            style={{
              width: '100%',
              marginTop: 10,
              alignSelf: 'center',
              marginBottom: -0,
            }}
          >
            <StyledLabelContainer row style={{ marginBottom: 0 }}>
              <StyledLabel style={{ marginBottom: 0, color: A11Y_COLORS.permaWaikawaGreyNew }}>
                <Trans t={tParentalConsent} i18nKey="consentPage.fieldLabelRelationshipToTeen">
                  Relationship to teen
                </Trans>
              </StyledLabel>
            </StyledLabelContainer>
            <SelectRounded
              aria-labelledby="relationship"
              options={relationshipOptions}
              value={dropdownValue}
              onChange={handleDropdownChange}
              isMulti={false}
              isInputReadOnly
              isCreatable={false}
              wrapperStyle={{
                margin: 0,
                width: '100%',
              }}
              styles={dropdownStyles}
              dataQa="selectRelationshipDropdown"
            />
          </View>
          <View
            style={{
              width: WIDTH,
              justifyContent: 'space-between',
              flexDirection: 'row',
              marginTop: 18,
            }}
          >
            <Checkbox
              checkboxStyle={{ width: 24, height: 24, marginLeft: -7 }}
              label=""
              isChecked={termsAccepted}
              setIsChecked={(value: boolean) => setTermsAccepted(value)}
              dataQa="acceptTermsCheckbox"
            />
            {isNYCTeen ? (
              <NYCAcceptTerms userFirstName={userFirstName} />
            ) : (
              <AcceptTerms userFirstName={userFirstName} />
            )}
          </View>

          <SubmitButton
            style={{ marginTop: isMobile ? 30 : 100, width: '100%' }}
            text={localizationParentalConsent ? tParentalConsent('consentPage.ctaText') : 'Submit'}
            isLoading={isSubmitConsentLoading}
            disabled={disableSubmitButton}
            data-qa="submitParentalConsentFormButton"
          />
        </form>
      </FormProvider>
    </View>
  );
};

export default ParentalConsentContainer;
