import { FunctionComponent, useRef, useState, createRef } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { ABTest, TabRadioGroup, useEmotionTheme } from '@talkspace/react-toolkit';
import { PlanData, Currency } from 'ts-frontend/types';
import { EmotionStyle } from '@/core/styled';
import { ExperimentPlan, Plan } from './Plan';
import { ID_PAYMENT_CARD_WRAPPER } from '@/utils/IDConstants';
import { useRoomOffersActions, useRoomOffersState } from '../hooks/roomOfferContext';
import { RoomOfferState } from '../types';
import {
  checkForExperimentalPlanPricing,
  TherapyTypes,
  isVideoMessagingTherapy,
  isVideoMessagingWorkshopsTherapy,
  isMessagingTherapy,
  isValidPlanId,
} from '../utils/constants';

interface PlanListProps {
  plansMatrix: PlanData[][];
  plansMatrixDisplayReady: RoomOfferState['plansMatrixDisplayReady'];
  onPlanSelected: (plan: PlanData, monthlyPlan: PlanData) => void;
  currentPlanID?: number;
  isTrial: boolean;
  style?: EmotionStyle;
}

const PlanList: FunctionComponent<PlanListProps> = (props) => {
  // I wanted to remove isTrial as it's not used and lint was complaining about it. But, I have to remove it then I would have to much multiple files that passing this prop.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { plansMatrix, plansMatrixDisplayReady, onPlanSelected, currentPlanID, isTrial, style } =
    props;
  const { flowID } = useRoomOffersState();
  const { experimentalPlanPricing } = useFlags();

  // Experimental plan pricing is available only for Online Therapy (Flow 66) and Teens Therapy (Flow 97).
  const isExperimentalPlanPricing = checkForExperimentalPlanPricing(
    flowID,
    !!experimentalPlanPricing
  );

  const [expandedPlanIndex, setExpandedPlanIndex] = useState<number | null>(null);
  const { trackPlanExpendAction } = useRoomOffersActions();
  const { colors } = useEmotionTheme();

  const cardRefs = useRef<React.RefObject<HTMLDivElement> | null[]>([]);
  if (isExperimentalPlanPricing) {
    plansMatrix.forEach((_, i) => {
      cardRefs.current[i] = createRef();
    });
  }

  function expandPlan(idx: number) {
    setTimeout(() => {
      if (isExperimentalPlanPricing && cardRefs.current[idx].current !== null) {
        cardRefs.current[idx].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);

    setExpandedPlanIndex(idx);
    if (idx < plansMatrix.length && plansMatrix[idx])
      trackPlanExpendAction(plansMatrix[idx][0].displayName);
  }

  return (
    <TabRadioGroup
      // TODO: @Eric - How was this working before?
      initialSelection={false}
      legendText="Choose a plan then choose a billing option"
      idSelector={ID_PAYMENT_CARD_WRAPPER}
      style={style}
    >
      {plansMatrix.map((billingFrequencies, i) => {
        const key = `plan-${i}`;
        let isRecommended: boolean = false;
        let planId: number = 0;
        let therapyName: string = '';
        let displayName: string = '';
        let therapyImage!: () => JSX.Element;
        let therapyInfo: string = '';
        let therapyBullets: ReadonlyArray<string> = [];
        let backgroundColor: string = '';
        let dataQA: string = '';
        let newPrice: number | undefined;
        let originalPrice: number | undefined;
        let currency: Currency | undefined;
        let unit: string | undefined;

        // each frequency within the plan has this same data so we can just destruct from the first frequency to take first price. (default)
        const [{ offerPrice }] = billingFrequencies as PlanData[];
        const { videoTherapy, messagingTherapy, videoMessagingTherapy } = TherapyTypes;
        displayName = billingFrequencies[0]?.displayName;
        planId = billingFrequencies[0]?.id;

        if (isExperimentalPlanPricing && isValidPlanId(planId)) {
          newPrice = offerPrice?.amount;
          originalPrice = offerPrice?.originalPrice;
          currency = offerPrice?.currency;
          unit = offerPrice?.unit;

          // Based on therapy name create therapy data which will be prepared first before passing to ExperimentPlan component.
          if (isVideoMessagingTherapy(planId)) {
            isRecommended = true;
            therapyName = displayName;
            therapyImage = videoTherapy?.therapyImage;
            therapyInfo = videoTherapy?.therapyInfo;
            therapyBullets = videoTherapy?.therapyBullets;
            dataQA = videoTherapy?.dataQA;
            backgroundColor = colors.permaSolitudeNew;
          } else if (isMessagingTherapy(planId)) {
            therapyName = displayName;
            therapyImage = messagingTherapy?.therapyImage;
            therapyInfo = messagingTherapy?.therapyInfo;
            therapyBullets = messagingTherapy?.therapyBullets;
            dataQA = messagingTherapy?.dataQA;
            backgroundColor = colors.aquaSqueeze;
          } else if (isVideoMessagingWorkshopsTherapy(planId)) {
            therapyName = displayName;
            therapyImage = videoMessagingTherapy?.therapyImage;
            therapyInfo = videoMessagingTherapy?.therapyInfo;
            therapyBullets = videoMessagingTherapy?.therapyBullets;
            dataQA = videoMessagingTherapy?.dataQA;
            backgroundColor = colors.catskillWhite;
          }
        }

        const experimentVariant = (() => {
          if (isExperimentalPlanPricing && isValidPlanId(planId)) {
            return 'experimentalPlanPricing';
          }
          return 'control';
        })();

        return (
          <ABTest experimentVariant={experimentVariant}>
            <ABTest.When variant="experimentalPlanPricing">
              <ExperimentPlan
                key={key}
                recommended={isRecommended}
                therapyData={{
                  planId,
                  displayName,
                  therapyName,
                  therapyImage,
                  therapyInfo,
                  therapyBullets,
                  dataQA,
                  backgroundColor,
                  newPrice,
                  originalPrice,
                  currency,
                  unit,
                }}
                billingFrequencies={billingFrequencies}
                onPlanSelected={onPlanSelected}
                setExpandedPlan={() => expandPlan(i)}
                ref={cardRefs.current[i] as React.RefObject<HTMLDivElement>}
              />
            </ABTest.When>

            <ABTest.Default>
              <Plan
                key={key}
                billingFrequencies={billingFrequencies}
                plansMatrixDisplayReady={plansMatrixDisplayReady}
                style={{ marginBottom: 12 }}
                isExpanded={expandedPlanIndex === i}
                setExpandedPlan={() => expandPlan(i)}
                disabled={
                  billingFrequencies.length === 1 && billingFrequencies[0].id === currentPlanID
                }
                currentPlanID={currentPlanID}
                onPlanSelected={onPlanSelected}
                wrapperClassName="plan-wrapper"
              />
            </ABTest.Default>
          </ABTest>
        );
      })}
    </TabRadioGroup>
  );
};

export default PlanList;
