import { postLanguagesForMatching } from './switchWizardApiHelper';

const ENGLISH_ID = 15;

// eslint-disable-next-line import/prefer-default-export
export const actionDispatchGetLanguages =
  (skipPath?: string) => async (value, wizardContext, flags, setState) => {
    const { languageMatchingSwitchWizard } = flags;

    const skipAction = skipPath || '/switch-provider/therapist-matches';
    if (!languageMatchingSwitchWizard) {
      return skipAction;
    }

    setState({ isLoading: true, preferredLanguageOptions: undefined });
    const { roomID, countryState, existingPreferences, newTherapistFocus } = wizardContext;
    const { ageGroups } = existingPreferences;
    const [clientCountry, clientState] = countryState.split('-');

    const payload = {
      ageGroups,
      therapistGender: value,
      fieldsOfExpertise: newTherapistFocus,
      clientState,
      clientCountry,
    };
    const {
      data: {
        data: { languages },
      },
    } = await postLanguagesForMatching(roomID, payload);
    const options = languages
      .filter((language) => language.id !== ENGLISH_ID)
      .map((language) => {
        return { label: language.name, value: language.id };
      });
    if (!options?.length) {
      return skipAction;
    }
    const englishOption = { label: 'English', value: ENGLISH_ID };

    setState({
      preferredLanguageOptions: [englishOption, ...options],
      isLoading: false,
    });
    return 'next';
  };
