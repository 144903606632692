import Svg, { Path, Circle, G } from 'svgs';
import { TouchableView, useEmotionTheme } from '@talkspace/react-toolkit';
import styled from '../../core/styled';
import { webOnlyStyle } from '../../core/styled/styleHelpers';

const CloseButtonSVG = styled(Svg)(({ theme: { colors } }) => {
  return {
    ...webOnlyStyle({
      display: 'block',
      '&:hover circle': {
        fill: colors.periwinkleGrey,
      },
      '&:hover path': {
        fill: colors.charcoal,
      },
    }),
  };
});

const CloseButton = ({
  onPress,
  width = 35,
  height = 35,
  backgroundColor,
  color,
  ...props
}: {
  // Hard to appease typescript on click/press events
  onPress?: (e?: any) => void;
  width?: number;
  height?: number;
  backgroundColor?: string;
  color?: string;
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'close X';
  return (
    <TouchableView onPress={onPress} aria-label="Close" {...props}>
      <CloseButtonSVG
        width={width}
        height={height}
        title={titleText}
        aria-label={titleText}
        viewBox="0 0 35 35"
      >
        <G
          transform="translate(-325 -16) translate(325 16)"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <Circle
            fill={backgroundColor || colors.permaLinkWaterGrey}
            cx={17.5}
            cy={17.5}
            r={17.5}
          />
          <Path
            d="M17.5 16.259l5.013-5.004a.87.87 0 011.233.002.875.875 0 01-.002 1.235L18.726 17.5l5.018 5.008c.34.34.342.893.002 1.235a.87.87 0 01-1.233.002L17.5 18.741l-5.013 5.004a.87.87 0 01-1.233-.002.875.875 0 01.002-1.235l5.018-5.008-5.018-5.008a.875.875 0 01-.002-1.235.87.87 0 011.233-.002l5.013 5.004z"
            fill={color || colors.slateGrey}
            fillRule="nonzero"
          />
        </G>
      </CloseButtonSVG>
    </TouchableView>
  );
};

export default CloseButton;
