import GridCol from '../../../components/grid/GridCol';
import { useGridContext } from './gridContext';
import { ColumnsByBreakpoint, OffsetByBreakpoint } from './gridTypes';
import { offsetPropTransform } from './propTransforms';
import { EmotionStyle } from '../../../core/styled';

type ColProps = {
  children: React.ReactNode;
  className?: string;
  style?: EmotionStyle;
} & ColumnsByBreakpoint &
  OffsetByBreakpoint;

const Col = ({ children, style, className, ...props }: ColProps) => {
  const { lgOffset, mdOffset, smOffset, xlOffset, xsOffset, ...compatibleProps } = props;
  const { gutterWidth, breakpoints, columns, rowSpacing } = useGridContext();

  return (
    <GridCol
      colOffset={offsetPropTransform(props)}
      breakpoints={breakpoints}
      columns={columns}
      gutterWidth={gutterWidth}
      rowSpacing={rowSpacing}
      {...compatibleProps}
    >
      {children}
    </GridCol>
  );
};

export default Col;
