import styled from '../../../core/styled';
import ClinicalProgressCard from '../ClinicalProgressCard';

const NoItemsCard = styled(ClinicalProgressCard)(({ theme: { window } }) => {
  const { isMobile } = window;
  return isMobile
    ? {
        flex: 'none',
        height: 178,
        justifyContent: 'center',
        paddingTop: 0,
        paddingBottom: 0,
      }
    : {
        flex: 'none',
        flexDirection: 'row',
        height: 178,
        paddingLeft: 35,
        alignItems: 'center',
        paddingTop: 0,
        paddingBottom: 0,
      };
});

export default NoItemsCard;
