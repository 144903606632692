import { OptionType } from '@talkspace/react-toolkit';
import {
  therapistGenderPreference,
  gender,
  attributionSourcesV3,
  relationshipStatus,
} from '@talkspace/configs';
import { ValueType } from 'react-select';
import {
  BaseWizardState,
  baseWizardInitialState,
  keyValueToValueType,
  permutateArray,
} from 'stepWizard';
import { RoomType } from 'ts-frontend/entities/Room';
import { MatchingPreferences } from '@/entities/MatchingPreferences';

export interface State extends BaseWizardState {
  seekHelpReason?: number[];
  alreadySeenTherapist: string;
  haveBeenPrescribedMedicationBefore: string;
  treatmentGoal: string;
  countryState?: string;
  providerGender?: string;
  clientGender?: string;
  relationshipStatus?: string;
  hearAboutUs?: string;
  roomID: number;
  roomType?: RoomType;
  clientUserID: number;
  dateOfBirth?: string;

  // we need this so we don't override existing preferences (when sending back)
  // which we don't allow editing in the wizard - such as planGroup
  existingPreferences?: MatchingPreferences;

  // input values
  seekHelpOptions: ValueType<OptionType, true>;
  alreadySeenTherapistOptions: ValueType<OptionType, true>;
  haveBeenPrescribedMedicationBeforeOptions: ValueType<OptionType, true>;
  providerGenderOptions: ValueType<OptionType, true>;
  clientGenderOptions: ValueType<OptionType, true>;
  relationshipOptions: ValueType<OptionType, true>;
  hearAboutUsOptions: ValueType<OptionType, true>;
  eventCategory: string;
}

const getReferralOptions = () => {
  let vals = keyValueToValueType(attributionSourcesV3);
  // keep "other" always last
  const other = vals.splice(-1)[0];
  vals = permutateArray(vals);
  vals.push(other);
  return vals;
};

// eslint-disable-next-line import/prefer-default-export
export const getInitialState = (
  clientUserID: number,
  roomID: number,
  source: string,
  contextID: string,
  roomType: RoomType = 'private_room'
): State => {
  return {
    ...baseWizardInitialState(source, contextID),
    roomID,
    clientUserID,
    roomType,
    alreadyCompleted: false,
    completedAtDate: '',
    alreadySeenTherapist: 'No',
    haveBeenPrescribedMedicationBefore: 'No',
    treatmentGoal: '',
    countryState: 'US',
    dateOfBirth: undefined,
    providerGender: undefined,
    clientGender: undefined,
    relationshipStatus: undefined,
    hearAboutUs: undefined,
    seekHelpReason: [],
    seekHelpOptions: [],
    alreadySeenTherapistOptions: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    haveBeenPrescribedMedicationBeforeOptions: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    relationshipOptions: keyValueToValueType(relationshipStatus),
    hearAboutUsOptions: getReferralOptions(),
    clientGenderOptions: keyValueToValueType(gender),
    providerGenderOptions: keyValueToValueType(therapistGenderPreference).filter((option) => {
      const castOption = option as OptionType;
      return castOption && castOption.label !== 'No preference';
    }),
    eventCategory: 'Matching Intake',
  };
};
