import { isStripeUsingLink, useStripeTs } from 'stripe/stripeContext';
import { useEffect, useState, useCallback } from 'react';
import useQueryClientUsageStats from 'ts-frontend/hooks/useQueryClientUsageStats';
import {
  InRoomSchedulingProvider,
  InRoomSchedulingContainer,
  useInRoomSchedulingActions,
  TherapistInfo,
  useInRoomSchedulingState,
} from 'inRoomScheduling';
import type { InRoomSchedulingContainerProps } from 'inRoomScheduling/containers';

import paymentAPI from 'offer/utils/paymentApiHelper';
import { extractRoomEntitiesFromState } from '../../utils/extractRoomEntitiesFromState';
import { useMainActions, useMainState } from '../../hooks/mainContext';
import { RouteComponentProps, withRouter } from '../../core/routerLib';
import { getUserData } from '../../auth/helpers/token';
import useQueryOnboarding from '../../onboarding/hooks/useQueryOnboarding';

import useQueryNotificationPreference from '../../community/hooks/useQueryNotificationPreference';
import useMutationPatchNotificationPreferences from '../../community/hooks/useMutationPatchNotificationPreferences';
import { useIsModal } from '../../utils/ModalsContextProvider';

type Props = RouteComponentProps<
  {
    roomID: string;
  },
  {},
  {
    roomID: number;
  }
> & {
  // Used in switchWizard
  futureTherapistID?: number;
} & Pick<
    InRoomSchedulingContainerProps,
    | 'preBookingAction'
    | 'hideHeader'
    | 'isCloseable'
    | 'bookWithIntroSession'
    | 'isOnboarding'
    | 'dismissOnboarding'
    | 'fetchOnboarding'
    | 'flowVariant'
  >;

const InRoomSchedulingWrapperContainer = ({
  match,
  location,
  hideHeader = false,
  isCloseable = true,
  isOnboarding = false,
  bookWithIntroSession = false,
  flowVariant,
  dismissOnboarding,
  fetchOnboarding,
  preBookingAction,
  futureTherapistID,
}: Props) => {
  const mainState = useMainState();
  const { getAllRooms } = useMainActions();
  const { therapistInfo } = useInRoomSchedulingState();

  const roomID = Number(match.params.roomID) || location.state.roomID;
  const { id: clientUserID } = getUserData();
  const [useStripeLink, setUseStripeLink] = useState(true);
  const stripe = useStripeTs();
  const { data: onboardingData } = useQueryOnboarding({
    roomID: Number(roomID),
    userID: clientUserID,
  });

  const step = onboardingData?.steps?.find(
    ({ step: onboardStep }) => onboardStep === 'BOOK_AND_ACTIVATE'
  );
  const { sessionModality, shouldSkipModalitySelection } = step?.metadata || {
    sessionModality: undefined,
    shouldSkipModalitySelection: false,
  };

  const { data: notificationPreferences } = useQueryNotificationPreference({ clientUserID });
  const { mutate: patchNotificationPreferences } = useMutationPatchNotificationPreferences();
  const sessionReminderPrefs = notificationPreferences?.categories?.find(
    (cat) => cat.name === 'sessionReminder'
  );

  useEffect(() => {
    getAllRooms(clientUserID);
    setUseStripeLink(isStripeUsingLink(stripe));
  }, [clientUserID, getAllRooms, stripe]);

  const onLoadStripeLink = async () => {
    const { id } = getUserData();
    const res = await paymentAPI.getSetupIntent({ userID: id });
    return res;
  };

  const { dispatchSetClientUsageStats } = useInRoomSchedulingActions();

  let therapist: TherapistInfo;
  if (futureTherapistID && therapistInfo?.id === futureTherapistID) {
    therapist = therapistInfo;
  } else {
    ({ therapist } = extractRoomEntitiesFromState(mainState, roomID));
  }

  const { data: clientUsageStats, isLoading: isLoadingClientUsageStats } = useQueryClientUsageStats(
    {
      clientID: clientUserID,
      therapistID: therapist?.id,
    }
  );

  useEffect(() => {
    if (!isLoadingClientUsageStats) {
      dispatchSetClientUsageStats(clientUsageStats);
    }
  }, [isLoadingClientUsageStats, clientUsageStats, dispatchSetClientUsageStats]);

  const enableSessionReminders = useCallback(
    (options: { onSuccess: () => void; onError: () => void }) => {
      if (sessionReminderPrefs) {
        patchNotificationPreferences(
          {
            categories: [
              {
                name: 'sessionReminder',
                sms: true,
                push: sessionReminderPrefs.push,
                email: sessionReminderPrefs.email,
              },
            ],
          },
          {
            onSuccess: options.onSuccess,
            onError: options.onError,
          }
        );
      } else {
        options.onError();
      }
    },
    [sessionReminderPrefs, patchNotificationPreferences]
  );

  const isInModal = useIsModal();

  return (
    <InRoomSchedulingContainer
      hideAddToCalendar={false}
      room={extractRoomEntitiesFromState(mainState, roomID).room}
      therapist={therapist}
      clientUserID={clientUserID}
      hideHeader={hideHeader}
      isCloseable={isCloseable}
      isOnboarding={isOnboarding}
      bookWithIntroSession={bookWithIntroSession}
      useStripeLink={useStripeLink}
      preRegistrationSelectedModality={sessionModality}
      shouldSkipModalitySelection={shouldSkipModalitySelection}
      flowVariant={flowVariant}
      onLoadStripeLink={onLoadStripeLink}
      dismissOnboarding={dismissOnboarding}
      fetchOnboarding={fetchOnboarding}
      preBookingAction={preBookingAction}
      enableSessionReminders={
        sessionReminderPrefs && !sessionReminderPrefs.sms ? enableSessionReminders : undefined
      }
      isInModal={isInModal}
    />
  );
};

interface ContextWrapperProps extends Props {
  noContextProvider?: boolean;
}

const InRoomSchedulingWrapper = ({ noContextProvider, ...props }: ContextWrapperProps) =>
  noContextProvider ? (
    <InRoomSchedulingWrapperContainer {...props} />
  ) : (
    <InRoomSchedulingProvider>
      <InRoomSchedulingWrapperContainer {...props} />
    </InRoomSchedulingProvider>
  );

export default withRouter(InRoomSchedulingWrapper);
