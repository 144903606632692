import { FunctionComponent, useState, useEffect } from 'react';
import { toast, useWindowWidthState, useUniqueID, Screen } from '@talkspace/react-toolkit';
import { getVWOWidgetsHeight } from 'ts-analytics/VWO/helpers';
import ActionStatus from '../components/ActionStatus';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { RoomOfferState, RoomOfferRouteParams, CouponInputMode, TherapistInfo } from '../types';
import OfferContainer from '../containers/OfferContainer';
import { RouteComponentProps } from '@/core/routerLib/routerLib';
import { useRoomOffersActions, useRoomOffersState } from '../hooks/roomOfferContext';

const OfferScreen: FunctionComponent<
  RouteComponentProps<
    RoomOfferRouteParams,
    {},
    { bookWithIntroSession?: boolean; goToUrl?: string; selectedTherapist?: TherapistInfo }
  >
> = ({ location: { search, state } }) => {
  const closeModal = useCloseModal();
  const { setRoomOfferStateAction, loadRoomOfferAction } = useRoomOffersActions();
  const {
    isLoadingRoomOffer,
    isErrorRoomOffer,
    showCloseButton,
    offer,
    isChangePlan,
    experimentError,
  } = useRoomOffersState();

  const [stickHeader, setStickHeader] = useState<boolean>(false);
  const [showBackButton, setShowBackButton] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const offerHeaderId = useUniqueID('offerHeader');

  const handleOnScrollChange = (scrollTop: number) => {
    setStickHeader(scrollTop > 50);
  };

  const { isMobile } = useWindowWidthState();

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    setShowBackButton(urlParams.get('showBackButton') === 'true');

    const isEmailRequired = urlParams.get('isEmailRequired');
    const boughtFromQS = urlParams.get('boughtFrom');
    const couponInputMode = urlParams.get('couponInputMode');
    const isShowCloseButton =
      urlParams.get('showCloseButton') === null || urlParams.get('showCloseButton') === 'true';
    const hideProviderInfo = urlParams.get('hide_provider_info') === 'true';

    const stateParams: Partial<RoomOfferState> = {
      boughtFrom: boughtFromQS ? +boughtFromQS : undefined,
      isEmailRequired: isEmailRequired === 'true',
      showCloseButton: isShowCloseButton,
      couponInputMode:
        typeof couponInputMode === 'string' ? (couponInputMode as CouponInputMode) : undefined,
      hideProviderInfo,
    };
    if (state && state.selectedTherapist) {
      stateParams.therapistInfo = state.selectedTherapist;
      stateParams.goToUrl = state.goToUrl;
      stateParams.bookWithIntroSession = state.bookWithIntroSession;
    }
    setRoomOfferStateAction(stateParams);

    if (!offer) loadRoomOfferAction();
  }, [loadRoomOfferAction, offer, search, setRoomOfferStateAction, state]);

  useEffect(() => {
    if (experimentError) {
      setIsLoading(false);
    } else {
      setIsLoading(isLoadingRoomOffer);
    }
  }, [experimentError, isLoadingRoomOffer]);

  if (isErrorRoomOffer) {
    toast('Something went wrong. Please try again later.');
    return null;
  }

  const VWOWidgetsHeight = getVWOWidgetsHeight();

  return (
    <Screen
      safeAreaVariant="setHeightAndAddTop"
      additionalHeaderTopPadding={VWOWidgetsHeight}
      onCloseButtonClick={closeModal}
      onScrollChange={handleOnScrollChange}
      showBackButton={showBackButton}
      showCloseButton={showCloseButton}
      title={isChangePlan ? 'Change plan' : undefined}
      titleId={offerHeaderId}
      backButtonStyles={{ position: 'fixed', zIndex: 4, top: isMobile ? 24 : undefined }}
    >
      {experimentError || isLoading ? (
        <ActionStatus
          isError={!!experimentError}
          errorButtonAction={() => closeModal()}
          isLoading={isLoading}
          errorTitle={experimentError}
        />
      ) : (
        <>
          <OfferContainer stickHeader={stickHeader} titleId={offerHeaderId} />
        </>
      )}
    </Screen>
  );
};

export default OfferScreen;
