import { FunctionComponent } from 'react';
import { useNewMemberNav } from 'launchDarkly/hooks';
import {
  View,
  TouchableView,
  Big,
  CaretLeft,
  ResponsiveLayoutWithHeader,
  useEmotionTheme,
  PanelHeader,
} from '@talkspace/react-toolkit';
import SharedFilesScreen from 'chat/screens/SharedFilesScreen';
import { RouteComponentProps, withRouter } from '../core/routerLib';
import { useMainActions } from '../hooks/mainContext';
import styled from '../core/styled';
import { File as FileIcon } from '../components/icons';
import { useCloseModal } from '@/utils/ModalsContextProvider';

type Props = RouteComponentProps<{
  roomID: string;
}>;

const SharedFilesContainer: FunctionComponent<Props> = ({ match, history }) => {
  const { setDropdownMenuVisible } = useMainActions();
  const useNewNav = useNewMemberNav();
  const closeModal = useCloseModal();

  const handleOnRoomPanelClosePress = () => {
    if (useNewNav) {
      closeModal({
        navigateTo: 'room',
        metadata: {
          roomID: Number(match.params.roomID),
        },
      });
    } else {
      history.push(`/room/${match.params.roomID}`);
    }
  };

  const handleOnBackPress = () => {
    setDropdownMenuVisible(true);
    history.goBack();
  };

  const CaretLeftWrapper = styled(TouchableView)(({ theme: { window } }) => {
    return {
      display: window.isSmall || window.isMedium ? 'flex' : 'none',
      justifyContent: 'center',
      marginRight: 20,
      lineHeight: 0, // fixes bootstrap lineHeight 20px causing this to render at 26px height
    };
  });
  const { colors } = useEmotionTheme();
  return (
    <ResponsiveLayoutWithHeader
      renderHeader={() => (
        <PanelHeader
          title="Shared files"
          onRightPress={handleOnRoomPanelClosePress}
          renderLeft={() => (
            <View row align="center">
              <CaretLeftWrapper onPress={handleOnBackPress}>
                <CaretLeft color={colors.green} />
              </CaretLeftWrapper>
              <FileIcon style={{ marginRight: 9 }} />
              <Big>Shared files</Big>
            </View>
          )}
        />
      )}
    >
      <SharedFilesScreen
        isTherapistChat={false}
        roomID={Number(match.params.roomID)}
        onClosePress={handleOnRoomPanelClosePress}
      />
    </ResponsiveLayoutWithHeader>
  );
};

export default withRouter(SharedFilesContainer);
