import { View } from '@talkspace/react-toolkit';
import styled from '@/core/styled';

const SuperbillsContent = styled(View)({
  minHeight: '100%',
  width: '100%',
  maxWidth: 335,
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
});

export default SuperbillsContent;
