import { SkipNavigation } from '@talkspace/react-toolkit';
import { History } from 'history';
import { match } from 'react-router';

export const useA11y = (
  history: History,
  routeMatch: match<{ roomID: string }>
): { skipNavs: SkipNavigation[] | undefined } => {
  const cameFromRoomDetails =
    history.location.state && history.location.state.from
      ? history.location.state.from === 'room-details'
      : false;
  const cameFromTherapistDetails =
    history.location.state && history.location.state.from
      ? history.location.state.from === 'therapist-details'
      : false;

  const returnToDetailsPanel = () => {
    if (cameFromTherapistDetails) {
      history.push({
        pathname: `/room/${routeMatch.params.roomID}/therapist-details`,
        state: {
          from: null,
        },
      });
    } else if (cameFromRoomDetails) {
      history.push({
        pathname: `/room/${routeMatch.params.roomID}/room-details`,
        state: {
          from: null,
        },
      });
    }
  };

  let skipNavs: SkipNavigation[] | undefined;
  if (cameFromRoomDetails) {
    skipNavs = [
      {
        buttonText: 'Return to room details',
        onPress: returnToDetailsPanel,
      },
    ];
  } else if (cameFromTherapistDetails) {
    skipNavs = [
      {
        buttonText: 'Return to care provider details',
        onPress: returnToDetailsPanel,
      },
    ];
  }

  return { skipNavs };
};

export default useA11y;
