import { FunctionComponent, useState, useRef } from 'react';
import {
  View,
  Large,
  Huge,
  Button,
  Standard,
  BaseButton,
  Link,
  Avatar,
  HiddenText,
  useUniqueID,
  useContainerFocus,
} from '@talkspace/react-toolkit';
import { ConsentDialogItem } from 'ts-frontend/types';
import ScrollViewComponent from 'chat/components/ScrollViewComponent';
import styled from '../../core/styled';
import apiHelper from '../../core/api/apiHelper';
import { IC_CONTENT } from '../../utils/informedConsent/informedConsentConstant';

interface InformedConsentActionViewProps {
  hasV1InformedConsent: boolean;
  hasConsented: boolean;
  agreedDate: string;
  onAgreePress: (e?) => void;
  returnToSplash?: () => void;
  error: string | null;
  hasReturnButton?: boolean | undefined;
  therapistID?: number;
  scrollHeight?: number;
  hasV2InformedConsent?: boolean;
}

const ConsentTextWrapper = styled(View)(({ theme: { window } }) => {
  return {
    marginTop: 15,
    paddingBottom: 70,
    paddingLeft: window.isMobile ? 23 : 63,
    paddingRight: window.isMobile ? 13 : 53,
  };
});

const FadeOut = styled(View)({
  backgroundImage: 'linear-gradient(to top, rgba(255,255,255,1),rgba(255,255,255,0))',
  backgroundRepeat: 'repeat-x',
  height: 100,
  pointerEvents: 'none',
  width: '90%',
  marginTop: -98,
  zIndex: 1,
});

const AvatarView = styled(View)({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
});

const Error = styled(Large)(({ theme: { colors } }) => {
  return { color: colors.red, position: 'absolute', bottom: 13 };
});

const ScrollView = styled(ScrollViewComponent)<{ scrollHeight?: number }>(
  ({
    scrollHeight,
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      paddingLeft: 14,
      alignItems: 'center',
      height: scrollHeight && !isMobile ? scrollHeight : (11 * window.innerHeight) / 16,
      '-webkit-overflow-scrolling': 'auto',
    };
  }
);

const CombinedAvatars: FunctionComponent<{ therapistID?: number }> = ({ therapistID }) => (
  <AvatarView>
    <Avatar
      width={148}
      height={148}
      image={
        therapistID
          ? `${apiHelper().cdnEndpoint}/images/application/therapist/440/${therapistID}.png`
          : ''
      }
      imageStyle={{ width: '100%', height: '100%' }}
      style={{
        borderRadius: '150%',
        border: '10px solid white',
        zIndex: 1,
      }}
    />
    <Avatar
      width={128}
      height={128}
      image=""
      style={{
        marginLeft: -30,
      }}
    />
  </AvatarView>
);

const ConsentText: FunctionComponent<{
  consentDialog: ConsentDialogItem[];
}> = ({ consentDialog }) => {
  // TODO: links are not clickable on the modal, they should be
  const handleOnClick = () => window.open('http://www.talkspace.com', '_blank');
  const TalkspaceLink = () => (
    <Link target="_blank" onClick={handleOnClick} href="http://www.talkspace.com">
      talkspace.com
    </Link>
  );
  const htmlRegex = /<.*?>.+<\/.*?>/gi;

  const componentArr = consentDialog.map(({ type, message }) => {
    if (type === 'title')
      return (
        <Huge as="h2" key={message} style={{ marginBottom: 20, marginTop: 20 }}>
          {message}
        </Huge>
      );
    // hack to replace html with Link
    const [msg, msg2] = message.split(htmlRegex);
    return (
      <Large
        as="p"
        key={message}
        variant="largeDarkGrey"
        inline
        style={{
          marginTop: 10,
          marginBottom: 10,
          wordBreak: 'break-word',
          textAlign: type === 'declaration' ? 'center' : 'left',
        }}
      >
        {msg}
        {msg2 && <TalkspaceLink />}
        {msg2}
      </Large>
    );
  });
  return <ConsentTextWrapper>{componentArr}</ConsentTextWrapper>;
};

const Container = styled(View)<{ hasScrolled?: boolean }>(({ hasScrolled }) => {
  return {
    borderTop: hasScrolled ? '1px solid rgba(0,0,0,0.06)' : '1px solid #fff',
    '&:focus': { outline: 'none' },
  };
});

const InformedConsentActionView: FunctionComponent<InformedConsentActionViewProps> = ({
  hasV1InformedConsent,
  onAgreePress,
  returnToSplash,
  error,
  hasReturnButton = true,
  therapistID,
  scrollHeight,
  hasConsented,
  agreedDate,
  hasV2InformedConsent,
}) => {
  const [hasVisitedBottom, setHasVisitedBottom] = useState<boolean>(false);
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);
  const scrollViewRef = useRef<HTMLDivElement | null>(null);
  const hiddenTitleId = useUniqueID('hiddenTitleId');
  const consentAgreementId = useUniqueID('consentAgreementId');
  const { containerRef } = useContainerFocus();
  const consentAgreement = IC_CONTENT[0]?.message;
  const IS_AT_BOTTOM_GRACE_PERCENTAGE = 0.1;

  const handleOnScroll = (e) => {
    const { scrollHeight: targetScrollHeight, scrollTop, clientHeight } = e.target;
    const isAtBottom =
      targetScrollHeight - Math.abs(scrollTop) <=
      clientHeight + targetScrollHeight * IS_AT_BOTTOM_GRACE_PERCENTAGE;
    const isAtTop = scrollTop === 0;
    if (isAtTop) setHasScrolled(false);
    else if (!hasScrolled) setHasScrolled(true);
    if (isAtBottom) {
      setHasVisitedBottom(true);
    }
  };

  function scrollToBottom() {
    // setTimeout and webkit-overflow-scroll: 'auto' allows scrollToBottom to work on
    // iOs during a momentum based scroll
    setTimeout(() => {
      if (scrollViewRef.current) {
        const scrollOptions = {
          top: scrollViewRef.current.scrollHeight,
          left: 0,
        };
        if (scrollViewRef.current.clientHeight === scrollViewRef.current.scrollHeight) {
          setHasVisitedBottom(true);
        }
        if (scrollViewRef.current.scrollTo) {
          scrollViewRef.current.scrollTo(scrollOptions);
        }
      }
    }, 10);
  }

  // mainsite sends a string "null"

  let buttonText = 'Scroll to bottom';
  let onClickHandler = scrollToBottom;
  let disabled = false;
  if (hasConsented) {
    buttonText = `Agreed on ${agreedDate}`;
    onClickHandler = () => null;
    disabled = true;
  } else if (hasVisitedBottom) {
    buttonText = 'Agree';
    onClickHandler = onAgreePress;
  }
  return (
    <Container
      hasScrolled={hasScrolled}
      ref={containerRef}
      aria-labelledby={hiddenTitleId}
      tabIndex={-1}
    >
      <HiddenText id={hiddenTitleId}>Informed consent agreement</HiddenText>
      <ScrollView ref={scrollViewRef} onScroll={handleOnScroll} scrollHeight={scrollHeight}>
        <View style={{ height: 15 }} />
        <CombinedAvatars therapistID={therapistID} />
        <ConsentText consentDialog={IC_CONTENT} />
        <HiddenText id={consentAgreementId}>{consentAgreement}</HiddenText>
      </ScrollView>
      <View align="center">
        <FadeOut />
        <Button
          onClick={onClickHandler}
          aria-describedby={hasVisitedBottom && !hasConsented ? consentAgreementId : undefined}
          text={buttonText}
          disabled={disabled}
        />
        {hasReturnButton && (
          <BaseButton onPress={returnToSplash} style={{ marginTop: 10 }}>
            <Standard variant="standardDarkGrey">Go back</Standard>
          </BaseButton>
        )}
        {error && <Error>{error}</Error>}
      </View>
    </Container>
  );
};

export default InformedConsentActionView;
