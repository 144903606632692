import { FunctionComponent, useState, useEffect } from 'react';
import { WorkflowActionResult } from 'chat/redux/constants/chatTypes';
import { RouteComponentProps } from '../../../core/routerLib/routerLib';
import IntakeActionView from './IntakeActionView';
import ApiHelper from '../../utils/ApiHelper';

let api: ApiHelper;

const IntakeAction: FunctionComponent<
  RouteComponentProps<
    {
      roomID: number;
    },
    {},
    { messageID: number; messageType: number }
  >
> = ({ history, match, location }) => {
  const [workflowAction, setWorkflowAction] = useState<WorkflowActionResult>();
  const [error, setError] = useState(null);

  useEffect(() => {
    api = new ApiHelper();

    return () => {
      api.cancelAll();
    };
  }, []);

  useEffect(() => {
    const { messageID, messageType } = location.state;
    api
      .getRedirectToken()
      .then((jwtToken) => {
        setWorkflowAction({
          jwtToken,
          messageID,
          messageType,
          roomID: match.params.roomID,
        });
      })
      .catch(api.dismissIfCancelled)
      .catch((err) => {
        setError(err.message);
      });
  }, [location.state, match.params.roomID]);
  if (error) return null;
  return <IntakeActionView onBackdropPress={history.goBack} workflowAction={workflowAction} />;
};

export default IntakeAction;
