import { withRouter, RouteComponentProps } from '@/core/routerLib';
import BookSession from '../components/BookSession';
import { setIsOnboardingDismissed } from '../util/onboardingStorage';

const BookSessionContainer = ({
  match: {
    params: { roomID },
  },
}: RouteComponentProps<{ roomID: number }>) => {
  const dismiss = () => {
    setIsOnboardingDismissed(roomID);
  };
  return <BookSession dismissOnboarding={dismiss} />;
};

export default withRouter(BookSessionContainer);
