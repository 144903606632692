import { InformedConsentAPIResponse, ClientMatchPresentingProblem } from 'ts-frontend/types';
import {
  ClientReactivationAPIResponse,
  ClientAwaitingSessionAPIResponse,
  LastCompletedSessionAPIResponse,
} from 'chat/redux/constants/chatTypes';
import apiWrapper from '../../core/api/apiWrapper';
import apiHelper from '../../core/api/apiHelper';
import cancelPromiseHelper from '../../core/api/cancelPromiseHelper';
import { getUserData } from '../../auth/helpers/token';
import {
  CreateBookingSchema,
  TherapistTimeslotsAPIResponse,
  VideoCreditsAPIResponse,
} from '../reducers/schedulerReducer';

import { Match, ImplicitBusinessHoursAPI } from '../reducers/inPlatformMatchingReducer';
import {
  SharingPreferencesAPIResponse,
  AllowViewMessagesBeforeTherapistJoinedRoomType,
} from '../components/SharingPreferencesAction/SharingPreferencesTypes';
import { ClientInfo } from '../reducers/chatBannerReducer';

interface ApiResponse<T = any> {
  data: T;
}

export interface UserSettings {
  soundNotification: boolean;
  sendMessagesReceipts: boolean;
  highContrast: boolean;
}

export default class ApiHelper {
  private wrapWithCancel: (req: Promise<any>) => Promise<any>;

  public reset: () => void;

  public dismissIfCancelled: (err: Error) => any;

  public cancelAll: () => void;

  constructor() {
    const { cancelAll, reset, dismissIfCancelled, wrapWithCancel } = cancelPromiseHelper();
    this.wrapWithCancel = wrapWithCancel;
    this.reset = reset;
    this.dismissIfCancelled = dismissIfCancelled;
    this.cancelAll = cancelAll;
  }

  // #region Messages Actions

  /**
   * api call to: `/v2/rooms/{roomID}/provider/{providerUserID}/informed-consents`
   * wrapWithCancel
   * @param  {number} roomID
   * @param  {number} providerUserID
   * @param doesInformedConsentRecordExist
   * @param messageID
   * @returns Promise
   */

  public getInformedConsent = (
    roomID: number,
    providerUserID: number,
    messageID: number,
    doesInformedConsentRecordExist = true
  ): Promise<InformedConsentAPIResponse> =>
    this.wrapWithCancel(
      apiWrapper.get(
        `${
          apiHelper().apiEndpoint
        }/v2/rooms/${roomID}/provider/${providerUserID}/informed-consent?doesInformedConsentRecordExist=${doesInformedConsentRecordExist}${
          // Don't send messageID if no informedConsentRecord exists
          doesInformedConsentRecordExist ? `&messageID=${messageID}` : ''
        }`
      )
    ).then((res: ApiResponse) => res.data.data);

  /**
   * api call to: `/v2/rooms/{roomId}/matches/{matchId}`
   * wrapWithCancel
   * @param  {number} roomID
   * @param  {lastMessageId} optional last message id lo load partial
   * @returns Promise
   */
  public getMatches = (
    roomID: number,
    matchID: number
  ): Promise<{ data: Match[]; isB2B?: boolean }> =>
    this.wrapWithCancel(
      apiWrapper.get(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/matches/${matchID}`)
    ).then((res) => {
      if (res.data && res.data.error) throw new Error(res.data.error);
      return res.data;
    });

  /**
   * api call to: `/v2/rooms/{roomId}/suggest-therapists-self-service`
   * wrapWithCancel
   * @param  {number} roomID
   * @returns Promise
   */
  public postNewMatchesRequest = (roomID: number): Promise<{}> =>
    this.wrapWithCancel(
      apiWrapper.post(
        `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/suggest-therapists-self-service`,
        {}
      )
    ).then((res) => {
      if (res.data && res.data.error) throw new Error(res.data.error);
      return res.data;
    });

  /**
   * api call to: `/v2/rooms/{roomID}/matches/{matchID}/select-therapist/:switchTherapistID
   * wrapWithCancel
   * @param  {number} roomID
   * @param  {lastMessageId} optional last message id lo load partial
   * @returns Promise
   */
  public postSwitchTherapist = (
    roomID: number,
    matchID: number,
    therapistID: number
  ): Promise<{ data: Match[]; error?: string }> =>
    this.wrapWithCancel(
      apiWrapper.post(
        `${
          apiHelper().apiEndpoint
        }/v2/rooms/${roomID}/matches/${matchID}/select-therapist/${therapistID}`,
        {}
      )
    ).then((res) => res.data); // need fine-grain error handling in the reducer

  /**
   * api call to: `/v2/rooms/{roomID}/transfer-matching-therapist`
   * wrapWithCancel
   * @returns Promise
   */
  public requestMatchingAgent = (roomID: number): Promise<{ therapistWasSwitched: boolean }> =>
    this.wrapWithCancel(
      apiWrapper.post(
        `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/transfer-matching-therapist`,
        {}
      )
    ).then((res) => {
      if (res.data && res.data.error) throw new Error(res.data.error);
      return res.data;
    });

  /**
   * api call to: GET `/v2/therapist/{therapistUserID}/timeslots`
   * @returns Promise
   */
  public getTherapistTimeslots = (
    therapistUserID: number,
    duration: number,
    roomID: number
  ): Promise<TherapistTimeslotsAPIResponse> =>
    this.wrapWithCancel(
      apiWrapper
        .get(
          `${
            apiHelper().apiEndpoint
          }/v2/therapist/${therapistUserID}/timeslots?length=${duration} minutes&from=1 day&to=30 days&roomID=${roomID}`
        )
        .then((res) => res.data.data)
    );

  /**
   * api call to: GET '/api/v1/rooms/{roomID}/clients/{clientUserID}/presenting-problems'
   * wrapWithCancel
   * @param  {number} roomID
   * @returns Promise
   */
  public getRoomPresentingProblems = (roomID: number): Promise<ClientMatchPresentingProblem[]> =>
    this.wrapWithCancel(
      apiWrapper
        .get(
          `${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/clients/${
            getUserData().id
          }/presenting-problems`
        )
        .then((res: ApiResponse) =>
          res.data.data.map((p) => ({ name: p.value, ...p } as ClientMatchPresentingProblem))
        )
    );

  /**
   * api call to: GET `/v2/clients/{userID}/video-credits`
   * @returns Promise
   */
  public getVideoCredits = (): Promise<VideoCreditsAPIResponse> =>
    this.wrapWithCancel(
      apiWrapper.get(`${apiHelper().apiEndpoint}/v2/clients/${getUserData().id}/video-credits`)
    ).then((res) => res.data.data);

  /**
   * api call to: POST `/v2/rooms/{roomID}/bookings`
   * @returns Promise
   */
  public createBooking = (roomID: number, data: CreateBookingSchema): Promise<boolean> =>
    this.wrapWithCancel(
      apiWrapper.post(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/bookings`, data as any)
    ).then((res) => res.status === 201);

  /**
   * api call to: `/v2/therapist/implicit-business-hours?therapistIDs=77807,94592`
   * wrapWithCancel
   * @param  {therapistIDs} therapistIDs
   * @returns Promise
   */
  public getImplicitBusinessHours = (
    therapistIDs: number[]
  ): Promise<{ data: ImplicitBusinessHoursAPI[] }> =>
    this.wrapWithCancel(
      apiWrapper.get(
        `${
          apiHelper().apiEndpoint
        }/v2/therapist/implicit-business-hours?therapistIDs=${therapistIDs.join(',')}`
      )
    ).then((res) => {
      if (res.data && res.data.error) throw new Error(res.data.error);
      return res.data;
    });

  /**
   * api call to: `/v3/users/redirect-token`
   * wrapWithCancel
   * @returns Promise
   */
  public getRedirectToken = (): Promise<any> =>
    this.wrapWithCancel(apiWrapper.get(`${apiHelper().apiEndpoint}/v3/users/redirect-token`)).then(
      (res) => {
        const {
          data: {
            data: { redirectToken },
          },
        } = res;
        return redirectToken;
      }
    );

  /**
   * api call to: `/v2/rooms/{roomID}/sharing-preferences`
   * wrapWithCancel
   * @returns Promise
   */
  public getSharingPreferences = (roomID: number): Promise<SharingPreferencesAPIResponse> =>
    this.wrapWithCancel(
      apiWrapper.get(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/sharing-preferences`)
    ).then((res) => {
      const {
        data: { data },
      } = res;
      return data;
    });

  /**
   * api call to: `/v2/rooms/{roomID}/sharing-preferences`
   * wrapWithCancel
   * @returns Promise
   */
  public setSharingPreferences = (
    roomID: number,
    willAllow: AllowViewMessagesBeforeTherapistJoinedRoomType
  ): Promise<boolean> => {
    const payload = {
      allowViewMessagesBeforeTherapistJoinedRoom: willAllow,
    };
    return this.wrapWithCancel(
      apiWrapper.patch(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/sharing-preferences`, payload)
    ).then((res) => res.status === '204');
  };

  // #endregion

  // #region User Settings

  /**
   * api call to: GET `/v3/users/{userID}/user-settings`
   * @returns Promise
   */
  public getUserSettings = (): Promise<UserSettings> =>
    apiWrapper
      .get(`${apiHelper().apiEndpoint}/v3/users/${getUserData().id}/user-settings`)
      .then((res) => (res.data && res.data.data ? res.data.data : undefined));

  /**
   * api call to: PATCH `/v3/users/{userID}/user-settings`
   * @returns Promise
   */
  public setUserSettings = (data: UserSettings): Promise<any> =>
    apiWrapper.patch(
      `${apiHelper().apiEndpoint}/v3/users/${getUserData().id}/user-settings`,
      data as any
    );

  // #endregion

  /**
   * api POST call to: `/v2/rooms/:roomID/messages/request-new-matches-from-ct`
   * @param  {number} roomID
   * @returns Promise
   */
  public inPlatformMatchingRequestNewMatch = (roomID: number): Promise<void> =>
    this.wrapWithCancel(
      apiWrapper.post(
        `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/messages/request-new-matches-from-ct`
      )
    );

  /**
   * api GET call to: `/v3/clients/:clientUserID/reactivation-status/rooms/:roomID`
   * @param  {number} clientUserID
   * @param  {number} roomID
   * @returns Promise
   */
  public roomReactivation = (roomID: number): Promise<ClientReactivationAPIResponse> =>
    this.wrapWithCancel(
      apiWrapper.get(
        `${apiHelper().apiEndpoint}/v3/clients/${
          getUserData().id
        }/reactivation-status/rooms/${roomID}`
      )
    ).then((res: ApiResponse) => res.data.data);

  /**
   * api GET call to: `/v3/clients/:clientUserID/reactivation-status`
   * @param  {number} clientUserID
   * @returns Promise
   */
  public clientReactivation = (): Promise<ClientReactivationAPIResponse> =>
    this.wrapWithCancel(
      apiWrapper.get(
        `${apiHelper().apiEndpoint}/v3/clients/${getUserData().id}/reactivation-status`
      )
    ).then((res: ApiResponse) => res.data.data);

  /**
   * api GET call to: `/v2/rooms/:roomID/cases/awaiting-session`
   * @param  {number} roomID
   * @returns Promise
   */
  public getAwaitingSession = (roomID?: number): Promise<ClientAwaitingSessionAPIResponse> =>
    this.wrapWithCancel(
      apiWrapper.get(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/cases/awaiting-session`)
    ).then((res) => res.data);

  /**
   * api GET call to: `/v2/rooms/:roomID/cases/last-completed-session`
   * @param  {number} roomID
   * @returns Promise
   */
  public getLastCompletedSession = (roomID?: number): Promise<LastCompletedSessionAPIResponse> =>
    this.wrapWithCancel(
      apiWrapper.get(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/cases/last-completed-session`)
    ).then((res) => res.data);

  public getClientInfo = (): Promise<{ data: ClientInfo }> =>
    this.wrapWithCancel(
      apiWrapper.get(`${apiHelper().apiEndpoint}/v2/clients/${getUserData().id}`)
    ).then((res) => res.data as { data: ClientInfo });

  public sendEmailVerification(email: string): Promise<ApiResponse> {
    return this.wrapWithCancel(
      apiWrapper.post(`${apiHelper().authAPIEndpoint}/v2/auth/email-verification`, { email })
    ).then((res: ApiResponse) => res.data);
  }

  public getUserPrompts(roomID: number): Promise<ApiResponse> {
    return this.wrapWithCancel(
      apiWrapper.get(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/user-prompts`)
    ).then((res: ApiResponse) => res.data);
  }

  // #endregion
}
