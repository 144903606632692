import { FunctionComponent } from 'react';
import { TextDS, View, useEmotionTheme } from '@talkspace/react-toolkit';
import { Trans, useTranslation } from '@talkspace/i18n';
import { matchPreferencesSummaryPresentingProblems } from 'ts-frontend/helpers/presentingProblems';

const MatchPreferenceRow: FunctionComponent<{
  preferenceCategory: string;
  preferenceResponse: string;
}> = ({ preferenceCategory, preferenceResponse }) => {
  const { t: tSwitchWizard } = useTranslation('switchWizard');
  const { colorRoles, spacing } = useEmotionTheme();
  return (
    <View
      row
      justify="space-between"
      style={{
        padding: spacing('space200'),
        borderBottom: `1px solid ${colorRoles.borders.borderDefault}`,
      }}
    >
      <TextDS variant="body">{preferenceCategory}</TextDS>
      <TextDS variant="headingMd" style={{ textAlign: 'end' }}>
        <Trans
          t={tSwitchWizard}
          i18nKey={
            matchPreferencesSummaryPresentingProblems?.find(
              (prob) => prob.name === preferenceResponse
            )?.translationKey
          }
          defaults={
            matchPreferencesSummaryPresentingProblems?.find(
              (prob) => prob.name === preferenceResponse
            )?.name
          }
        >
          {preferenceResponse}
        </Trans>
      </TextDS>
    </View>
  );
};

export default MatchPreferenceRow;
