import { FunctionComponent, useState } from 'react';
import {
  useUniqueID,
  View,
  Button,
  ExtraHuge,
  Tiny,
  TSInput,
  HiddenLabel,
  useEmotionTheme,
  SpacingView,
} from '@talkspace/react-toolkit';
import { checkNicknameValidity } from 'ts-frontend/helpers';
import styled from '../../../core/styled/styled';

const HeaderWrapper = styled(View)({
  width: 336,
  textAlign: 'left',
  marginBottom: 30,
  paddingLeft: 4,
});

const InputWrapper = styled(View)({
  marginBottom: 10,
});

const Label = styled(Tiny)({
  textAlign: 'left',
  paddingLeft: 4,
  paddingTop: 4,
});

const NicknameRequirements = styled(Label)(({ theme: { colors } }) => {
  return { color: colors.slateGrey };
});

const Error = styled(Label)(({ theme: { colors } }) => {
  return { color: colors.red };
});

interface Props {
  initialNickname: string;
  updateNickname: (nickname: string) => void;
  onCancelPress: () => void;
}

const ChangeNicknameModal: FunctionComponent<Props> = ({
  initialNickname,
  updateNickname,
  onCancelPress,
}) => {
  const { colors } = useEmotionTheme();
  const [nickname, setNickname] = useState(initialNickname);
  const [nicknameError, setNicknameError] = useState('');

  const handleNicknameChange = (value: string) => {
    setNicknameError('');
    setNickname(value);
  };

  const handleSavePress = () => {
    const nickNameError = checkNicknameValidity(nickname);
    const isNicknameError = nickNameError === '';
    setNicknameError(nickNameError);
    if (isNicknameError) {
      updateNickname(nickname);
    }
  };

  const inputID = useUniqueID('inputID');
  const nicknameReqsID = useUniqueID('nicknameReqs');
  const nicknameErrorID = useUniqueID('nicknameError');
  return (
    <View>
      <HeaderWrapper>
        <ExtraHuge>Change nickname</ExtraHuge>
      </HeaderWrapper>
      <InputWrapper>
        <HiddenLabel htmlFor={inputID}>Nickname</HiddenLabel>
        <TSInput
          dataQa="myAccountChangeNicknameInput"
          id={inputID}
          ariaDescribedBy={nicknameError ? nicknameErrorID : nicknameReqsID}
          onChange={handleNicknameChange}
          value={nickname}
          style={{ fontSize: 16, borderColor: nicknameError && colors.red, minWidth: 336 }}
          shouldFocus={!!nicknameError}
        />
        {nicknameError ? (
          <Error id={nicknameErrorID}>{nicknameError}</Error>
        ) : (
          <NicknameRequirements id={nicknameReqsID}>
            Must be between 2-10 characters and start with a letter.
          </NicknameRequirements>
        )}
      </InputWrapper>
      <SpacingView>
        <Button
          onPress={handleSavePress}
          dataQa="myAccountChangeNicknameSaveButton"
          text="Save"
          style={{ flex: 1 }}
        />
        <Button
          variant="secondary"
          onPress={onCancelPress}
          dataQa="myAccountChangeNicknameCancelButton"
          text="Cancel"
          style={{ flex: 1 }}
        />
      </SpacingView>
    </View>
  );
};

export default ChangeNicknameModal;
