import { SessionModality } from 'ts-frontend/types';

export type OnboardingStep =
  | 'TEEN_TREATMENT_INTAKE'
  | 'TEEN_EMERGENCY_CONTACT'
  | 'TEEN_PARENTAL_CONSENT'
  | 'TREATMENT_INTAKE'
  | 'EMERGENCY_CONTACT'
  | 'INFORMED_CONSENT'
  | 'BOOK_LIVE_SESSION'
  | 'BOOK_ASYNC_SESSION'
  | 'ASYNC_SESSION_STARTED'
  | 'MEET_YOUR_PROVIDER'
  | 'BOOK_AND_ACTIVATE';

export const TEEN_STEP_TYPES: Record<string, OnboardingStep> = {
  TEEN_TREATMENT_INTAKE: 'TEEN_TREATMENT_INTAKE',
  TEEN_EMERGENCY_CONTACT: 'TEEN_EMERGENCY_CONTACT',
  TEEN_PARENTAL_CONSENT: 'TEEN_PARENTAL_CONSENT',
};

export interface OnBoardingMetadata {
  isMessagingFirstVideoRequired?: boolean;
  shouldSkipModalitySelection?: boolean;
  sessionModality?: SessionModality;
  prefill?: Record<string, string | number>;
  therapistID?: number;
  startDate?: string;
}

export interface Step {
  step: OnboardingStep;
  isRequired: boolean;
  metadata: OnBoardingMetadata;
}
