import { FunctionComponent, useState } from 'react';
import { ExtraHuge, View, Large, Button, EnvelopeX, Text } from '@talkspace/react-toolkit';
import styled from '../../core/styled';
import { useRoomInvitesActions, RoomInviteStatus } from '../hooks/useRoomInvites';

const Wrapper = styled(View)({
  maxWidth: 600,
  paddingLeft: 30,
  paddingRight: 30,
  alignSelf: 'center',
  alignItems: 'center',
  width: '100%',
});

const IconContainer = styled(View)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 50,
});

const MessageTitleContainer = styled(View)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 15,
});

const MessageContainer = styled(View)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 20,
  textAlign: 'center',
});

const ButtonContainer = styled(View)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 40,
  textAlign: 'center',
});

const RevokeButtonMargin = styled(View)({
  marginTop: 20,
});

const InvitationSentSuccess: FunctionComponent<{
  inviteEmail: string;
  roomID: number;
  setInviteScreen: (screen: RoomInviteStatus) => void;
}> = ({ inviteEmail, roomID, setInviteScreen }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { createInvite } = useRoomInvitesActions();
  const resendInvitation = () => {
    setIsLoading(true);
    createInvite(roomID, inviteEmail);
  };

  const sendDiffEmailAddress = () => {
    setInviteScreen(RoomInviteStatus.HAS_NO_INVITES);
  };
  return (
    <Wrapper>
      <IconContainer>
        <EnvelopeX width={83} />
      </IconContainer>
      <MessageTitleContainer>
        <ExtraHuge>Invitation link expired</ExtraHuge>
      </MessageTitleContainer>
      <MessageContainer>
        <Large variant="largeDarkGrey">
          The invitation we sent has expired. Would you like to send a new invitation link to{' '}
          <Text inline style={{ fontWeight: 'bold' }}>
            {inviteEmail}
          </Text>
          ?
        </Large>
      </MessageContainer>
      <ButtonContainer>
        <Button isLoading={isLoading} onPress={resendInvitation}>
          Resend Invitation
        </Button>
        <RevokeButtonMargin>
          <Button onPress={sendDiffEmailAddress} isSecondary size="small" removeBorder>
            Send to a different email address
          </Button>
        </RevokeButtonMargin>
      </ButtonContainer>
    </Wrapper>
  );
};

export default InvitationSentSuccess;
