import { FunctionComponent, useCallback, useEffect } from 'react';
import { View, ExtraHuge, Button, Large } from '@talkspace/react-toolkit';
import moment from 'moment-timezone';
import { persistData, useWizardActions, useWizardState } from 'stepWizard';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { useMainState } from '@/hooks/mainContext';
import styled from '../../core/styled';
import ChatClouds from '../../assets/icons/ChatClouds';

const Span = styled.span({});
const Bold = styled(Large)({
  fontWeight: 700,
});

function getTitles(
  rating: number,
  checkInSource: string,
  therapistFirstName: string,
  nextBookingStartTime: string
) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezoneAbbr = moment.tz(timezone).zoneAbbr();
  const nextBookingStartTimeMoment = moment(nextBookingStartTime).tz(timezone);
  const nextBookingDayFormatted = moment(nextBookingStartTimeMoment).format('dddd, MMMM Do');
  const nextBookingTimeFormatted = `${moment(nextBookingStartTimeMoment).format(
    'h:mmA'
  )} ${timezoneAbbr}`;
  const result = {
    title: '',
    subtitle: (
      <Large>
        Be sure to give {therapistFirstName} any feedback you have so that you can get the most out
        of your experience. Keep in mind that you can change your provider anytime.
      </Large>
    ),
  };
  switch (checkInSource) {
    case 'intro-lvs':
      if (rating >= 4) {
        result.title = 'Keep up the great work!';
        result.subtitle = (
          <Large>
            Be sure to give {therapistFirstName} any feedback you have so that you can get the most
            out of your experience. We'll check back in again soon
          </Large>
        );
      }
      break;
    case 'check-in':
    case 'lvs':
      if (rating >= 4) {
        result.title = 'Keep up the great work!';
        result.subtitle =
          rating === 5 ? (
            <Large inline>
              We'll check back in again soon to make sure things are still going well.
            </Large>
          ) : (
            <Large inline>
              Be sure to give {therapistFirstName} any feedback you have so that you can get the
              most out of your experience. We'll check back in again soon
            </Large>
          );
        if (nextBookingStartTime) {
          result.subtitle = (
            <Span>
              <Large inline>Your next session with {therapistFirstName} is scheduled for</Large>{' '}
              <Bold inline variant="largeDarkGrey">
                {nextBookingDayFormatted}
              </Bold>{' '}
              <Large inline>at</Large>{' '}
              <Bold inline variant="largeDarkGrey">
                {nextBookingTimeFormatted}
              </Bold>
            </Span>
          );
        }
      }
      break;
    case 'psychiatry-lvs':
      if (rating >= 4) {
        result.title = 'Keep up the great work!';
        result.subtitle =
          rating === 5 ? (
            <Large inline>
              We'll check back in again soon to make sure things are still going well.
            </Large>
          ) : (
            <Large inline>
              Be sure to give {therapistFirstName} feedback so that you can get the most out of your
              experience. We'll check back in again soon.
            </Large>
          );
        if (nextBookingStartTime) {
          result.subtitle = (
            <Span>
              <Large inline>Your next session with {therapistFirstName} is scheduled for</Large>{' '}
              <Bold inline variant="largeDarkGrey">
                {nextBookingDayFormatted}
              </Bold>{' '}
              <Large inline>at</Large>{' '}
              <Bold inline variant="largeDarkGrey">
                {nextBookingTimeFormatted}
              </Bold>
            </Span>
          );
        }
      } else {
        result.subtitle = (
          <Large inline>
            Be sure to give {therapistFirstName} feedback so that you can get the most out of your
            experience.
          </Large>
        );
      }
      break;
    default:
      break;
  }
  return result;
}

const Wrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      alignSelf: 'center',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: isMobile ? '15%' : '8%',
      marginBottom: 'auto',
      width: 340,
    };
  }
);

const Title = styled(ExtraHuge)({
  marginBottom: 20,
  marginTop: 20,
});

const DoneButton = styled(Button)(({ theme: { colors } }) => {
  return { border: 'none', color: colors.slateGrey, backgroundColor: 'transparent' };
});

const CheckInDone: FunctionComponent<{}> = () => {
  const {
    responses,
    isVerifyExitModalOpen,
    checkInData,
    therapistInfo,
    checkInSource,
    roomID,
    nextBookingStartTime,
    periodicCheckIn,
  } = useWizardState();
  const closeModal = useCloseModal();
  const { setState, closeWizard } = useWizardActions();
  const { subscriptionsByID, roomsByID } = useMainState();
  const room = roomsByID[roomID];
  const subscription = subscriptionsByID[roomID];

  const { title, subtitle } = getTitles(
    checkInData.rating,
    checkInSource,
    therapistInfo.firstName,
    nextBookingStartTime
  );
  const MAX_CHECK_IN_RATING = 5;
  const handleExitModal = useCallback(() => {
    const shouldOpenAppReview = checkInData.rating === MAX_CHECK_IN_RATING;
    closeWizard(roomID, true, shouldOpenAppReview ? { actionType: 'openAppReview' } : undefined);
  }, [checkInData.rating, closeWizard, roomID]);

  const shouldRedirectToInRoomScheduling =
    ['lvs', 'live-chat', 'psychiatry-lvs'].includes(checkInSource) &&
    checkInData.rating > 3 &&
    !nextBookingStartTime &&
    !periodicCheckIn &&
    (room.accountType !== 'dte' || subscription.videoCredits?.length);

  useEffect(() => {
    if (shouldRedirectToInRoomScheduling) {
      window.location.href = `/in-room-scheduling/room/${roomID}?source=check-in-wizard&therapistRating=${checkInData.rating}`;
    }
  }, [shouldRedirectToInRoomScheduling, roomID, checkInData]);

  useEffect(() => {
    if (isVerifyExitModalOpen) {
      closeModal();
    }
  }, [isVerifyExitModalOpen]); // eslint-disable-line

  useEffect(() => {
    const handlePersist = () => {
      persistData(responses, setState, 'Check in done', undefined, { CheckInDone: true }, true);
    };
    handlePersist();
  }, []); // eslint-disable-line

  return shouldRedirectToInRoomScheduling ? null : (
    <Wrapper>
      <ChatClouds />
      <Title>{title}</Title>
      {subtitle}
      <DoneButton text="Done" onPress={handleExitModal} />
    </Wrapper>
  );
};

export default CheckInDone;
