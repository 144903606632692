import { Booking } from 'ts-frontend/types';
import moment from 'moment';

const isBookingBetweenNowAndNHoursLater = (booking: Booking, hoursDistance: number): boolean => {
  const now = moment.utc();
  const startDate = moment(booking.startTime).utc();
  const nHoursFromStartDate = moment.utc(startDate).subtract(hoursDistance, 'hours');
  return now.isAfter(nHoursFromStartDate);
};

export default isBookingBetweenNowAndNHoursLater;
