import Svg, { Defs, Path, G, Mask, Use } from 'svgs';

const Video = ({ size = 87, ...otherProps }) => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 67 67" {...otherProps}>
    <Defs>
      <Path
        d="M11.916 7.43C26.755-3.269 49.237-2.705 60.462 11.024c6.08 7.437 7.634 16.785 5.836 27.252-1.585 9.222-7.031 16.937-13.22 22.267-13.505 11.63-34.066 6.533-45.29-7.196C-3.439 39.618-2.923 18.13 11.915 7.43z"
        id="Video-a"
      />
    </Defs>
    <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <Mask id="Video-b" fill="#fff">
        <Use xlinkHref="#Video-a" />
      </Mask>
      <Use fill="#5C84FB" xlinkHref="#Video-a" />
      <G mask="url(#Video-b)">
        <Path
          d="M21.615 10.458c50 0 26.161 4.846 45-8 18.838-12.845 33.22 41.743 16.951 47.737C70.082 55.333 53.106 65.768 32.64 81.5c-6.826 5.247-61.024-71.042-11.024-71.042z"
          fill="#5B4EC0"
          transform="translate(-14.615 -16.458)"
        />
        <Path
          d="M42.615 97.458c-39 0-39.277-68.71-29.555-63.434a312.105 312.105 0 0110.482 5.962c13.7 8.135 26.116 17.983 37.248 29.544a82.011 82.011 0 017.25 8.623c6.912 9.498-9.156 8.172-25.425 19.305z"
          fill="#103E8C"
          transform="translate(-14.615 -16.458)"
        />
        <Path
          d="M35.635 78.798c12.266-8.666 19.066-13.333 20.4-14 3.053 2.44 6.913 6.66 11.58 12.66l-10.98 9.34-19-3-3-4 1-1z"
          fill="#116AB7"
          transform="translate(-14.615 -16.458)"
        />
      </G>
      <G fill="#FFF" fillRule="nonzero">
        <Path
          d="M35.259 6.979L29.92 9.647a1.172 1.172 0 11-1.048-2.097l5.337-2.668a2.343 2.343 0 013.395 2.095v12.621a2.345 2.345 0 01-3.393 2.098l-5.339-2.669a1.172 1.172 0 011.048-2.097l5.339 2.668V6.978zM6.505 2.345a3.517 3.517 0 00-3.518 3.517v14.852a3.517 3.517 0 003.518 3.518h14.07a3.517 3.517 0 003.517-3.518V5.862a3.517 3.517 0 00-3.517-3.517H6.505zm0-2.345h14.07a5.862 5.862 0 015.862 5.862v14.852a5.862 5.862 0 01-5.862 5.863H6.505a5.862 5.862 0 01-5.863-5.863V5.862A5.862 5.862 0 016.505 0z"
          transform="translate(14.656 18.844)"
        />
      </G>
    </G>
  </Svg>
);

export default Video;
