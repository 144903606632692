import { Currency } from 'ts-frontend/types';
import apiWrapper from '../../core/api/apiWrapper';
import apiHelper from '../../core/api/apiHelper';

export type CouponValueType = 'fixed' | 'percent';
export type CouponDurationType = 'once' | 'recurring' | 'forever';

export interface Coupon {
  value: number;
  valueType: CouponValueType;
  duration: CouponDurationType;
  currency: Currency;
}

export interface Billing {
  recurringDates: string[];
}

export interface ApplyCouponResponse {
  success: boolean;
  coupon: Coupon;
  billing: Billing;
}

/**
 * api call to: `/v2/payments/room/{roomID}/voucher-coupon/{couponCode}/apply`
 * wrapWithCancel
 * @param  {string} roomID
 * @param  {string} couponCode
 * @returns Promise
 */
export default function applyCoupon(
  roomID: string,
  couponCode: string
): Promise<ApplyCouponResponse | undefined> {
  return apiWrapper
    .post(
      `${apiHelper().apiEndpoint}/v2/payments/room/${roomID}/voucher-coupon/${couponCode}/apply`
    )
    .then((res) => {
      if (!res.data || !res.data.data) throw new Error('failed to apply coupon');
      return res.data.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}
