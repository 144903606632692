import apiHelper from '../../core/api/apiHelper';
import apiWrapper from '../../core/api/apiWrapper';

interface ApiResponse {
  data?: any;
}

interface SwitchTherapistRequest {
  roomID: number;
  newTherapistID: number;
  consentSigned: boolean;
  shareHistory: boolean | null | undefined; // to fit SharingPreferencesActionViewProps
}

const postTransferMatchingAgent = (
  roomID: number,
  checkAvailability?: boolean
): Promise<ApiResponse> =>
  apiWrapper.post(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/transfer-matching-therapist`, {
    checkAlreadyCompletedAction: false,
    checkAvailability: !!checkAvailability,
  });

const switchTherapist = (switchTherapistRequest: SwitchTherapistRequest): Promise<ApiResponse> => {
  const { newTherapistID, roomID, consentSigned, shareHistory } = switchTherapistRequest;
  return apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/select-therapist/${newTherapistID}`,
    {
      consentSigned,
      shareHistory,
    }
  );
};

const switchTherapistApiHelper = {
  postTransferMatchingAgent,
  switchTherapist,
};

export default switchTherapistApiHelper;
