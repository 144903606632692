import { FunctionComponent, useCallback } from 'react';
import * as React from 'react';
import {
  Big,
  Tiny,
  View,
  Standard,
  TouchableView,
  CloseButton,
  HiddenText,
  useA11yState,
  useA11yActions,
  useEmotionTheme,
  usePanelManagerActions,
  InitialsAvatar,
  EmotionStyle,
} from '@talkspace/react-toolkit';
import moment from 'moment';
import ToggleSwitch from '2storybook/components/ToggleSwitch';
import { isPendingSubscriptionRoom } from 'ts-frontend/helpers';
import { useFlag } from 'launchDarkly/FlagsProvider';
import { RoomStatus } from 'ts-frontend/entities/Room';
import { isActivePlan } from '../../utils/subscriptionsHelper';
import { RouteComponentProps, withRouter } from '../../core/routerLib';
import { useClientAuthActions } from '../../hooks/clientAuthContext';
import { useMainActions, useMainState } from '../../hooks/mainContext';
import { COLORS } from '../../utils/design';
import styled from '../../core/styled';
import DropdownMenuModal from '../DropdownMenuModal';
import HoverItem from '../MenuNavLink';
import { useOpenModal } from '../../utils/ModalsContextProvider';
import { EMe, UserAuthProvider } from '../../entities/Me';
import A11yFigure from '../../assets/icons/A11yFigure';
import myAccountApiHelper from '../../myAccount/utils/myAccountApiHelper';
import { getUserData } from '../../auth/helpers/token';
import {
  ID_ACCOUNT_MENU_DROPDOWN_BUTTON,
  ID_ACCOUNT_MENU_DROPDOWN,
  ID_CUSTOMER_SUPPORT_PANEL,
  ID_ACCOUNT_DETAILS_PANEL,
  ID_PERSONAL_INFORMATION_PANEL,
  ID_MANAGE_SUBSCRIPTION_PANEL,
  ID_NOTIFICATIONS_PANEL,
  ID_STARRED_MESSAGES_PANEL,
  ID_SHARED_FILES_PANEL,
} from '../../utils/IDConstants';
import { trackUserSettingsEvent } from '@/utils/analytics/events';

const HoverBottom = styled(TouchableView)({
  fontSize: 14,
  '&:hover': {
    color: COLORS.bayouxBlue,
  },
});

const ResponsiveHeader = styled(View)(({ theme: { window } }) => {
  return {
    display: window.isSmall || window.isMedium ? 'flex' : 'none',
    justifyContent: 'flex-end',
    padding: 16,
  };
});

const ResponsiveInitialsAvatar = styled(InitialsAvatar)(({ theme: { window } }) =>
  window.isSmall || window.isMedium
    ? {
        width: 58,
        height: 58,
        marginLeft: 16,
        fontSize: 29,
      }
    : { marginLeft: 16 }
);

const MenuItemSubtitle = styled(Tiny)(() => {
  return {
    marginLeft: 30,
    marginRight: 6,
    fontSize: 12,
  };
});

interface MenuItemProps {
  onPress: () => void;
  ariaControls?: string;
  disabled?: boolean;
  dataQa?: string;
  style?: EmotionStyle;
}

const MenuItem: React.FunctionComponent<MenuItemProps> = ({
  children,
  onPress,
  ariaControls,
  disabled,
  dataQa,
  style,
}) => (
  <HoverItem
    disabled={disabled}
    onPress={onPress}
    aria-controls={ariaControls}
    dataQa={dataQa}
    style={style}
  >
    {children}
  </HoverItem>
);

const HighContrastMode = () => {
  const { isHighContrast } = useA11yState();
  const { setColorContrast } = useA11yActions();
  const { id } = getUserData();

  const handleToggle = () => {
    setColorContrast(!isHighContrast);
    myAccountApiHelper.patchUserSettings(id, { highContrast: !isHighContrast }).catch((error) => {
      // eslint-disable-next-line no-console
      console.warn('Could not update high_contrast setting', error);
    });
  };
  return (
    <View
      style={{
        marginLeft: 10,
        marginRight: 19,
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Standard style={{ marginLeft: 30, marginBottom: 10 }}>High contrast mode</Standard>
      <ToggleSwitch
        isOn={isHighContrast}
        onValueChange={handleToggle}
        name="highContrast"
        // these colors are accessible as is, leave them hardcoded
        onBackgroundColor="#2445FF"
        offBackgroundColor="#758AAE"
        switchWidth={46}
        switchHeight={27}
        label="high contrast mode"
        switchIcon={<A11yFigure />}
        onPos={44}
        offPos={2}
        circleSize={23}
      />
    </View>
  );
};

const DropdownMenu: FunctionComponent<RouteComponentProps<{ roomID: number }>> = ({
  history,
  match,
}) => {
  const b2BMemberInactivity = useFlag('b2BMemberInactivity');
  const { logoutAction } = useClientAuthActions();
  const { setDropdownMenuVisible } = useMainActions();
  const { isDropdownMenuVisible, roomsByID, therapistsByID, subscriptionsByID } = useMainState();
  const { setIsLeftPanelOnTop } = usePanelManagerActions();
  const me = useMainState().me as EMe;
  const { authProvider } = me;
  const hasActiveRooms = Object.values(roomsByID).some(({ status }) => isActivePlan(status));
  const hasB2bRoomsWithInactiveStatus = Object.values(roomsByID).some((room) => {
    const { subscription } = subscriptionsByID?.[room.roomID] || {};

    return (
      RoomStatus.CLOSED === room.status &&
      ['bh', 'eap', 'dte'].includes(room.accountType) &&
      !!subscription?.isClosedInactive
    );
  });

  const getShouldDisableSwitchProvider = () => {
    if (b2BMemberInactivity && (hasB2bRoomsWithInactiveStatus || hasActiveRooms)) {
      return false;
    }

    return !hasActiveRooms;
  };

  const hasNoMatchesRoom = Object.values(roomsByID).some((room) => {
    const provider = therapistsByID ? therapistsByID[room.therapistID] : undefined;
    return isPendingSubscriptionRoom({ room, provider });
  });

  const pushAndHide = useCallback(
    (path = '') => {
      setDropdownMenuVisible(false);
      setIsLeftPanelOnTop(false);

      history.push(`${match.url}/${path}`);
    },
    [history, match.url, setDropdownMenuVisible, setIsLeftPanelOnTop]
  );

  const openModal = useOpenModal();
  const joinDate = moment(me.createdAt).format('MMM D, YYYY');
  const { colors } = useEmotionTheme();

  const logoutHelper = useCallback(() => {
    if (authProvider === UserAuthProvider.AETNA) {
      openModal('/sso/logout-popups/aetna', undefined, false, true);
    } else {
      logoutAction();
    }
  }, [openModal, logoutAction, authProvider]);

  const handlePersonalInformationPress = useCallback(() => {
    trackUserSettingsEvent(me.id, 'View Personal Information', 'Account Dropdown');
    pushAndHide('my-account/personal-information');
  }, [pushAndHide, me.id]);

  const handleLoginAndSecurityPress = useCallback(() => {
    trackUserSettingsEvent(me.id, 'View Login Security', 'Account Dropdown');
    pushAndHide('my-account/account-details');
  }, [pushAndHide, me.id]);

  const handleNotificationsPress = useCallback(() => {
    trackUserSettingsEvent(me.id, 'View Notifications', 'Account Dropdown');
    pushAndHide('my-account/notifications');
  }, [pushAndHide, me.id]);

  const handlePaymentAndPlanPress = useCallback(() => {
    trackUserSettingsEvent(me.id, 'View Payment Plan', 'Account Dropdown');
    pushAndHide('my-account/manage-subscription');
  }, [pushAndHide, me.id]);

  const handleUpdateCoveragePress = useCallback(() => {
    trackUserSettingsEvent(me.id, 'Update Coverage', 'Account Dropdown');
    openModal(`/update-coverage`);
  }, [openModal, me.id]);

  const handleAddNewServicePress = useCallback(() => {
    trackUserSettingsEvent(me.id, 'Add Service', 'Account Dropdown');
    history.push(`/add-new-service/room/${match.params.roomID}`);
  }, [history, match.params.roomID, me.id]);

  const handleChangeProviderPress = useCallback(() => {
    trackUserSettingsEvent(me.id, 'Change Provider', 'Account Dropdown');
    openModal(`/change-provider`);
  }, [me.id, openModal]);

  const handleStarredMessagesPressed = useCallback(() => {
    trackUserSettingsEvent(me.id, 'View Starred Messages', 'Account Dropdown');
    pushAndHide('starred-messages');
  }, [pushAndHide, me.id]);

  const handleSharedFilesPressed = useCallback(() => {
    trackUserSettingsEvent(me.id, 'View Shared Files', 'Account Dropdown');
    pushAndHide('shared-files');
  }, [pushAndHide, me.id]);

  const handleFriendReferralPressed = useCallback(() => {
    trackUserSettingsEvent(me.id, 'Refer Friend', 'Account Dropdown');
    openModal('/friend-referral');
  }, [openModal, me.id]);

  const handleCustomerSupportPressed = useCallback(() => {
    trackUserSettingsEvent(me.id, 'Customer Support', 'Account Dropdown');
    pushAndHide('customer-support');
  }, [pushAndHide, me.id]);

  const shouldDisableSwitchProvider = getShouldDisableSwitchProvider();

  return isDropdownMenuVisible ? (
    <DropdownMenuModal
      onBackdropPress={() => setDropdownMenuVisible(false)}
      hide={!isDropdownMenuVisible}
      ariaLabelledby={ID_ACCOUNT_MENU_DROPDOWN_BUTTON}
      id={ID_ACCOUNT_MENU_DROPDOWN}
    >
      <ResponsiveHeader row>
        <CloseButton
          onPress={() => {
            setDropdownMenuVisible(false);
            setIsLeftPanelOnTop(true);
          }}
        />
      </ResponsiveHeader>
      <View
        flex={1}
        style={{
          flexGrow: 1,
          overflowY: 'auto',
          paddingBottom: 30,
          paddingTop: 30,
        }}
      >
        <View style={{ marginBottom: 20 }} row align="center">
          <ResponsiveInitialsAvatar displayName={me.displayName} />
          <View style={{ marginLeft: 10 }}>
            <Big
              as="h1"
              style={{
                whitespace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {me.displayName}
            </Big>
            <Tiny as="h2" style={{ color: colors.slateGrey }}>
              Joined {joinDate}
            </Tiny>
          </View>
        </View>
        <View style={{ marginBottom: 20 }}>
          <HighContrastMode />
          <View style={{ marginBottom: 20 }}>
            <Tiny as="h3" variant="tinyAllCaps" style={{ marginLeft: 20 }}>
              Account
            </Tiny>
            <MenuItem
              dataQa="personal-information"
              onPress={handlePersonalInformationPress}
              ariaControls={
                history.location.pathname.includes('personal-information')
                  ? ID_PERSONAL_INFORMATION_PANEL
                  : undefined
              }
            >
              <Standard style={{ marginLeft: 30 }}>Personal information</Standard>
            </MenuItem>
            <MenuItem
              dataQa="account-details"
              onPress={handleLoginAndSecurityPress}
              ariaControls={
                history.location.pathname.includes('account-details')
                  ? ID_ACCOUNT_DETAILS_PANEL
                  : undefined
              }
            >
              <Standard style={{ marginLeft: 30 }}>Login and security</Standard>
            </MenuItem>
            <Tiny as="h3" variant="tinyAllCaps" style={{ marginLeft: 20, marginTop: 22 }}>
              Plan, Eligibility, and Coverage
            </Tiny>
            <MenuItem
              dataQa="manage-subscription"
              onPress={handlePaymentAndPlanPress}
              ariaControls={
                history.location.pathname.includes('manage-subscription')
                  ? ID_MANAGE_SUBSCRIPTION_PANEL
                  : undefined
              }
              style={{ height: 75 }}
            >
              <Standard style={{ marginLeft: 30 }}>View payment and plan</Standard>
              <MenuItemSubtitle>
                Check your billing details and plan information or change providers
              </MenuItemSubtitle>
            </MenuItem>
            <MenuItem
              dataQa="check-coverage"
              onPress={handleUpdateCoveragePress}
              disabled={hasNoMatchesRoom}
              style={{ height: 75 }}
            >
              <Standard
                variant={hasNoMatchesRoom ? 'standardDarkGrey' : undefined}
                style={{ marginLeft: 30 }}
              >
                Update my coverage
              </Standard>
              <MenuItemSubtitle>
                Add, change, or update insurance and EAP details or switch to self-pay
              </MenuItemSubtitle>
            </MenuItem>
            <MenuItem
              dataQa="add-new-service"
              onPress={handleAddNewServicePress}
              disabled={hasNoMatchesRoom}
              style={{ height: 75 }}
            >
              <Standard
                variant={hasNoMatchesRoom ? 'standardDarkGrey' : undefined}
                style={{ marginLeft: 30 }}
              >
                Add a new service
              </Standard>
              <MenuItemSubtitle>We offer therapy, psychiatry, and couples therapy</MenuItemSubtitle>
            </MenuItem>
            <MenuItem
              dataQa="change-provider"
              onPress={handleChangeProviderPress}
              disabled={shouldDisableSwitchProvider}
              style={{ height: 75 }}
            >
              <Standard
                variant={shouldDisableSwitchProvider ? 'standardDarkGrey' : undefined}
                style={{ marginLeft: 30 }}
              >
                Change provider
              </Standard>
              <MenuItemSubtitle>
                It’s common to try a few providers before finding the right fit
              </MenuItemSubtitle>
            </MenuItem>
          </View>
          <Tiny as="h3" variant="tinyAllCaps" style={{ marginLeft: 20 }}>
            Messaging
          </Tiny>
          <MenuItem
            dataQa="starred-messages"
            onPress={handleStarredMessagesPressed}
            ariaControls={
              history.location.pathname.includes('starred-messages')
                ? ID_STARRED_MESSAGES_PANEL
                : undefined
            }
          >
            <Standard style={{ marginLeft: 30 }}>Starred messages</Standard>
          </MenuItem>
          <MenuItem
            dataQa="shared-files"
            onPress={handleSharedFilesPressed}
            ariaControls={
              history.location.pathname.includes('shared-files') ? ID_SHARED_FILES_PANEL : undefined
            }
          >
            <Standard style={{ marginLeft: 30, Bottom: 20 }}>Shared files</Standard>
          </MenuItem>

          <MenuItem
            dataQa="notifications"
            onPress={handleNotificationsPress}
            ariaControls={
              history.location.pathname.includes('notifications')
                ? ID_NOTIFICATIONS_PANEL
                : undefined
            }
          >
            <Standard style={{ marginLeft: 30 }}>Notifications</Standard>
          </MenuItem>

          <MenuItem dataQa="friend-referral" onPress={handleFriendReferralPressed}>
            <Standard style={{ marginLeft: 30 }}>Refer a friend</Standard>
          </MenuItem>
          <MenuItem
            dataQa="customer-support"
            onPress={handleCustomerSupportPressed}
            ariaControls={
              history.location.pathname.includes('customer-support')
                ? ID_CUSTOMER_SUPPORT_PANEL
                : undefined
            }
          >
            <Standard style={{ marginLeft: 30 }}>Customer support</Standard>
          </MenuItem>
        </View>
        <View
          row
          align="center"
          justify="space-between"
          style={{
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          <HoverBottom onPress={logoutHelper} dataQa="logout">
            <Standard variant="standardDarkGrey">Log out</Standard>
          </HoverBottom>
          <HoverBottom
            dataQa="legal"
            elementType="a"
            onPress={() => window.open('https://www.talkspace.com/public/privacy-policy', '_blank')}
          >
            <Standard variant="standardDarkGrey">Legal</Standard>
            <HiddenText>opens in new tab</HiddenText>
          </HoverBottom>
        </View>
      </View>
    </DropdownMenuModal>
  ) : null;
};

export default withRouter(DropdownMenu);
