import { FunctionComponent, Suspense } from 'react';
import { Spinner } from '@talkspace/react-toolkit';
import { RouteComponentProps } from '../../core/routerLib/routerLib';
import ModalClosingScreen from '../../2storybook/components/ModalClosingScreen';
import WelcomeBackContainer from '../containers/WelcomeBack';

interface RouteParams {
  roomID: string;
}

const WelcomeBackScreen: FunctionComponent<
  RouteComponentProps<RouteParams> & {
    ctEngaged: boolean;
  }
> = ({ match, ctEngaged = false }) => {
  const { roomID } = match.params;
  return (
    <ModalClosingScreen
      closeButtonDataQa="welcomeBackScreenCloseButton"
      backButtonDataQa="welcomeBackScreenBackButton"
    >
      <Suspense fallback={<Spinner isLoading />}>
        <WelcomeBackContainer roomID={Number(roomID)} isCTRoom={ctEngaged && !!roomID} />
      </Suspense>
    </ModalClosingScreen>
  );
};

export default WelcomeBackScreen;
