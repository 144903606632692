import * as React from 'react';
import {
  Router as DOMRouter,
  Switch,
  Redirect,
  withRouter,
  MemoryRouter,
  RouteComponentProps as RCP,
  useHistory,
} from 'react-router';
import { Route, Link, NavLink } from 'react-router-dom';
import { createBrowserHistory, createMemoryHistory, MemoryHistory as MH, History } from 'history';

const browserHistory = createBrowserHistory();

const getBrowserHistory = (): History => browserHistory;

const Router: React.FC<{ history?: History<unknown> }> = ({
  children,
  history = browserHistory,
}) => <DOMRouter history={history}>{children}</DOMRouter>;

const NativeRouter: React.FunctionComponent<{}> = () => null;

export type RouteComponentProps<
  Params = {},
  StaticContext = {},
  // @ts-ignore
  LocationState = unknown
> = RCP<Params, StaticContext, LocationState>;

export type MemoryHistory = MH;

export * from 'react-router';

export {
  Router,
  Link,
  NavLink,
  Route,
  Switch,
  Redirect,
  withRouter,
  MemoryRouter,
  NativeRouter,
  createMemoryHistory,
  getBrowserHistory,
  useHistory,
};
