import { VoidFunctionComponent } from 'react';
import { BackNextButton, Large, View } from '@talkspace/react-toolkit';
import { useHistory, useParams } from '@/core/routerLib';
import styled from '@/core/styled';
import useQueryGetSuperbills from '@/superbills/hooks/useQueryGetSuperbills';
import { useSuperbillsActions, useSuperbillsState } from '../hooks/superbillsContext';
import { createDateLabel } from '../utils';
import Description from './Description';
import { Superbill } from '../types';

const SuperbillWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
    height: 72,
    width: '100%',
    paddingLeft: 29,
    paddingRight: 29,
    borderRadius: 5,
    marginBottom: 15,
    border: `1px solid ${colors.catskillWhite}`,
  };
});

const SuperbillList: VoidFunctionComponent = () => {
  const { roomID } = useParams<{ roomID: string }>();
  const history = useHistory();
  const { isNameVerified } = useSuperbillsState();
  const { setSuperbillsStateAction } = useSuperbillsActions();
  const { data: superbillData } = useQueryGetSuperbills(+roomID);

  const handleSuperbillClick = (superbill: Superbill) => {
    if (superbillData?.clientName) {
      if (!isNameVerified) {
        setSuperbillsStateAction({ currentSuperbill: superbill });
        history.push(`/superbills/room/${roomID}/verify-name`);
      } else {
        setSuperbillsStateAction({ currentSuperbill: superbill });
        history.push(`/superbills/room/${roomID}/view-superbill`);
      }
    } else {
      history.push(`/superbills/room/${roomID}/no-name`);
    }
  };

  return (
    <>
      <Description>
        <Large variant="largeDarkGrey" dataQa="superbillsListDescription">
          Superbills become available 3 days after the end of each billing cycle or session and
          contain all of the information necessary for you to file an out of network insurance
          claim. Superbills require a diagnosis code, so they will only be available once your
          provider has submitted a diagnosis.
        </Large>
      </Description>
      {superbillData?.superbills.map((superbill, index) => {
        const { id, startDate, endDate } = superbill;
        return (
          <SuperbillWrapper key={id}>
            <Large variant="largeBoldWide">{createDateLabel(startDate, endDate)}</Large>
            <BackNextButton
              isRight
              onPress={() => handleSuperbillClick(superbill)}
              dataQa={`superbillIndex${index}Id${id}`}
            />
          </SuperbillWrapper>
        );
      })}
    </>
  );
};

export default SuperbillList;
