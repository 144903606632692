import { FunctionComponent } from 'react';
import { BillingPrice, LineItem } from 'ts-frontend/types/payment';
import { withRouter, RouteComponentProps } from '@/core/routerLib';
import NextRenewalPaymentDetails from '../components/NextRenewalPaymentDetails/NextRenewalPaymentDetails';

interface NextRenewalPaymentDetailsRouteParams {
  lineItems: LineItem[];
  total: number;
  savings: number;
  billingPrice: BillingPrice;
  renewalDate: string;
}

const NextRenewalPaymentDetailsScreen: FunctionComponent<
  RouteComponentProps<{}, {}, NextRenewalPaymentDetailsRouteParams>
> = ({ location }) => {
  const { lineItems, total, savings, billingPrice, renewalDate } = location.state;
  return (
    <NextRenewalPaymentDetails
      lineItems={lineItems}
      total={total}
      savings={savings}
      billingPrice={billingPrice}
      renewalDate={renewalDate}
    />
  );
};

export default withRouter(NextRenewalPaymentDetailsScreen);
