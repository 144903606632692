import Svg, { Defs, Path, Circle, G } from 'svgs';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

// leave these colors hardcoded, the figure is accessible as is
function SvgComponent(props) {
  const titleText = 'accessibility figure';
  return (
    <Svg
      width="11px"
      height="15px"
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 11 15"
      {...props}
    >
      <Defs>
        <Path
          d="M0 9.998C0 4.476 4.485 0 10.001 0h272.998C288.522 0 293 4.485 293 9.998v492.004c0 5.522-4.485 9.998-10.001 9.998H10.001C4.478 512 0 507.515 0 502.002V9.998z"
          id="b"
        />
        <Circle id="d" cx={32.5} cy={13.5} r={11.5} />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M0 68h375v1219H4.005A4.007 4.007 0 010 1283V68z"
          fill="#FFF"
          transform="translate(-322 -129)"
        />
        <G transform="translate(-255 -82)">
          <Path
            stroke="#EFF3FA"
            d="M282.999.5a9.471 9.471 0 016.716 2.783 9.468 9.468 0 012.785 6.715h0v492.004a9.465 9.465 0 01-2.784 6.714A9.474 9.474 0 01283 511.5h0H10.001a9.471 9.471 0 01-6.716-2.783A9.468 9.468 0 01.5 502.002h0V9.998a9.465 9.465 0 012.784-6.714A9.474 9.474 0 0110 .5h0z"
            fill="#FFF"
          />
        </G>
        <Path
          d="M13.49 0C6.04 0 0 6.043 0 13.5 0 20.956 6.037 27 13.49 27h19.02C39.96 27 46 20.957 46 13.5 46 6.044 39.963 0 32.51 0H13.49z"
          fill="#2445FF"
          transform="translate(-27 -6)"
        />
        <G transform="translate(-27 -6)">
          <Circle
            strokeOpacity={0.1}
            stroke="#000"
            strokeWidth={0.5}
            fill="#FFF"
            cx={32.5}
            cy={13.5}
            r={11.75}
          />
        </G>
        <Path
          d="M37.973 9.728c.115.444-.144.947-.574 1.065l-3.53 1.095c-.172.059-.315.236-.287.443.029.592.086 1.864.201 2.338.287 1.36 1.865 5.088 1.865 5.088a.89.89 0 01-.401 1.154.746.746 0 01-.345.089c-.315 0-.631-.178-.746-.503l-1.664-3.58-1.607 3.55a.805.805 0 01-.746.503.746.746 0 01-.345-.088c-.43-.207-.602-.71-.401-1.154 0 0 1.578-3.728 1.865-5.089.287-1.36.172-1.746.2-2.337 0-.207-.114-.385-.286-.444l-3.558-1.065a.885.885 0 01-.574-1.095c.143-.444.631-.65 1.061-.532 0 0 3.243 1.065 4.39 1.065 1.149 0 4.449-1.095 4.449-1.095.43-.118.918.148 1.033.592zM32.492 6c.89 0 1.607.74 1.607 1.657s-.718 1.657-1.607 1.657c-.89 0-1.607-.74-1.607-1.657 0-.888.717-1.657 1.607-1.657z"
          fill="#2445FF"
          transform="translate(-27 -6)"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
