import { Subscription } from 'ts-frontend/types';

import { Avatar, Card, RadioButton, Tag, TextDS, View, spacing } from '@talkspace/react-toolkit';
import { roomTypeToRoomTagData } from 'ts-frontend/utils/tagData';
import {
  getPlanCoverageDisplayString,
  getTherapistNameDisplayString,
  getCreditsDisplayString,
} from 'ts-frontend/utils/displayStrings';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { AggregatedRoomData } from '../../hooks/useNavShellData';

const { space025, space075, space150 } = spacing;

interface Props {
  roomData: AggregatedRoomData | undefined;
  subscription: Subscription;
  selectedRoomID: number | undefined;
  onPress: () => void;
}
const ScheduleChooseProviderListItem = ({
  roomData,
  subscription,
  selectedRoomID,
  onPress,
}: Props) => {
  const {
    id: roomID,
    roomType,
    therapist: { image },
    insuranceStatus,
  } = subscription;
  const therapistNameDisplayString =
    roomData?.therapistInfo &&
    getTherapistNameDisplayString({
      therapistInfo: roomData.therapistInfo,
    });
  const coverageDisplayString = getPlanCoverageDisplayString({ subscription, insuranceStatus });
  const useNewNav = useNewMemberNav();
  const creditsDisplayString = getCreditsDisplayString({ subscription, useNewNav });
  return (
    <Card onPress={onPress}>
      <View row justify="space-between">
        <View row flex={1}>
          <Avatar image={image} width={64} height={64} />
          <View flex={1} style={{ marginLeft: space150 }}>
            <Tag {...roomTypeToRoomTagData[roomType]} />
            {therapistNameDisplayString && (
              <TextDS variant="headingMd" style={{ marginTop: space075 }}>
                {therapistNameDisplayString}
              </TextDS>
            )}
            {coverageDisplayString && (
              <TextDS variant="bodySm" style={{ marginTop: space025 }}>
                {coverageDisplayString}
              </TextDS>
            )}
            {creditsDisplayString && (
              <TextDS variant="bodySm" style={{ marginTop: space025 }}>
                {creditsDisplayString}
              </TextDS>
            )}
          </View>
        </View>
        <RadioButton isActive={selectedRoomID === roomID} onPress={onPress} />
      </View>
    </Card>
  );
};

export default ScheduleChooseProviderListItem;
