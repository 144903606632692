import { useMutation } from 'react-query';

import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

export interface ResendOTPVariables {
  otpToken: string;
  sendEmail: boolean;
}

export interface ResendOTPResponse {
  otpToken: string;
  ttlSeconds: number;
  userPhone: string;
}

const resendOTP = async ({
  otpToken,
  sendEmail,
}: ResendOTPVariables): Promise<ResendOTPResponse> => {
  const response = await apiWrapper.post(
    `${apiHelper().authAPIEndpoint}/v3/2fa/resend/?sendEmail=${sendEmail}`,
    {
      otpToken,
    }
  );
  return response.data.data;
};

const useMutationResendOTP = () =>
  useMutation<ResendOTPResponse, Error, ResendOTPVariables>(resendOTP);

export default useMutationResendOTP;
