import {
  Button,
  SimpleHeader,
  Spinner,
  StickyDrawer,
  TextDS,
  View,
  spacing,
  OneColumnLayout,
  ResponsiveLayoutWithHeader,
} from '@talkspace/react-toolkit';

import HandWithHeartIllustration from '../assets/illustrations/HandWithHeartIllustration';

const { space050, space150, space200, space300, space400 } = spacing;

interface Props {
  isLoading: boolean;
  userPostCount: number | undefined;
  communityDisplayString: string;
  primaryCTAText: string;
  onPressPrimaryCTA: () => void;
  secondaryCTAText: string | undefined;
  onPressSecondaryCTA: (() => void) | undefined;
  onClose: () => void;
}
const CommunityCreatePostSuccess = ({
  isLoading,
  userPostCount,
  communityDisplayString,
  primaryCTAText,
  onPressPrimaryCTA,
  secondaryCTAText,
  onPressSecondaryCTA,
  onClose,
}: Props) => {
  const postCountCopy = `So far, you’ve shared
  ${isLoading || !userPostCount ? null : `${userPostCount} ${userPostCount > 1 ? 'posts' : 'post'}`}
  in the ${communityDisplayString} community.`;
  const secondaryCopy = secondaryCTAText ? 'Do you want to share it with your therapist, too?' : '';
  return (
    <ResponsiveLayoutWithHeader
      renderHeader={() => <SimpleHeader title="Teenspace" onClose={onClose} />}
    >
      <OneColumnLayout>
        <View align="center" justify="space-between" style={{ height: '100%' }}>
          <TextDS variant="heading2xl" style={{ marginTop: space400 }}>
            Great work!
          </TextDS>
          <View style={{ marginTop: space150 }}>
            <TextDS inline style={{ textAlign: 'center' }}>
              {postCountCopy} {secondaryCopy}
            </TextDS>
          </View>
          <View style={{ position: 'relative', marginTop: space300 }}>
            <HandWithHeartIllustration />
            <View align="center" style={{ width: '100%', position: 'absolute', top: 75 }}>
              {/* // TODO: custom decorative font for the number */}
              {isLoading ? (
                <Spinner />
              ) : (
                <TextDS variant="decorative/heading3Xl" colorRole="textInverse">
                  {userPostCount}
                </TextDS>
              )}
            </View>
          </View>
          <StickyDrawer noBorder contentWrapperStyle={{ padding: `${space300}px 0` }}>
            <OneColumnLayout>
              <Button
                dataQa="primaryButton"
                style={{ marginTop: space200, width: '100%', maxWidth: undefined }}
                text={primaryCTAText}
                onPress={onPressPrimaryCTA}
              />
              {secondaryCTAText && onPressSecondaryCTA && (
                <Button
                  dataQa="secondaryButton"
                  style={{ marginTop: space050, width: '100%', maxWidth: undefined }}
                  variant="tertiary"
                  text={secondaryCTAText}
                  onPress={onPressSecondaryCTA}
                />
              )}
            </OneColumnLayout>
          </StickyDrawer>
        </View>
      </OneColumnLayout>
    </ResponsiveLayoutWithHeader>
  );
};

export default CommunityCreatePostSuccess;
