import { VoidFunctionComponent } from 'react';
import { useParams } from 'core/routerLib';
import { useHistory } from 'react-router-dom';
import { View, Button, Large, Tiny, Big, BaseButton } from '@talkspace/react-toolkit';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import styled from '@/core/styled';
import useQueryGetSuperbills from '../hooks/useQueryGetSuperbills';
import { useSuperbillsActions } from '../hooks/superbillsContext';
import SuperbillsWrapper from '../components/SuperbillsWrapper';

const SecondaryButton = styled(BaseButton)(({ theme: { colors } }) => {
  return {
    color: colors.slateGrey,
  };
});

const VerifyNameScreen: VoidFunctionComponent = () => {
  const { roomID } = useParams<{ roomID: string }>();
  const { data: superbillData } = useQueryGetSuperbills(+roomID, false);
  const history = useHistory();
  const closeModal = useCloseModal();
  const { setSuperbillsStateAction } = useSuperbillsActions();

  const handleConfirm = () => {
    setSuperbillsStateAction({ isNameVerified: true });
    history.push(`/superbills/room/${roomID}/view-superbill`);
  };
  const handleEdit = () => {
    closeModal({
      navigateTo: 'personalInformation',
      metadata: { roomID: +roomID },
      action: `personal-details`,
    });
  };

  return (
    <SuperbillsWrapper>
      <Large
        variant="largeDarkGrey"
        style={{ marginBottom: 60 }}
        dataQa="superbillsVerifyNameDescription"
      >
        To generate your superbill, confirm that you’ve entered your full name exactly as it appears
        on your insurance card.
      </Large>
      <View style={{ marginBottom: 50 }}>
        <Tiny style={{ marginBottom: 10 }} dataQa="superbillsVerifyNamePrompt">
          Name that will be your superbill
        </Tiny>
        <Big dataQa="superbillsClientName">{superbillData?.clientName}</Big>
      </View>
      <Button onPress={handleConfirm} style={{ marginBottom: 20 }} dataQa="confirmName">
        Confirm name
      </Button>
      <SecondaryButton onPress={handleEdit} dataQa="editPersonalDetails">
        Edit personal details
      </SecondaryButton>
    </SuperbillsWrapper>
  );
};

export default VerifyNameScreen;
