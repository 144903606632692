import paymentAPI from 'offer/utils/paymentApiHelper';
import { PaymentWizardContainer } from 'checkout/containers';
import { extractRoomEntitiesFromState } from '../../utils/extractRoomEntitiesFromState';
import { useMainState } from '../../hooks/mainContext';
import { RouteComponentProps, withRouter } from '../../core/routerLib';
import { getUserData } from '../../auth/helpers/token';

type Props = RouteComponentProps<{ roomID: string }, {}, {}> & {};

const PaymentWizardWrapperContainer = ({ match }: Props) => {
  const mainState = useMainState();
  const roomID = Number(match.params.roomID);
  const { id: clientUserID } = getUserData();

  const onLoadStripeLink = async () => {
    const { id } = getUserData();
    const res = await paymentAPI.getSetupIntent({ userID: id });
    return res;
  };

  const onStripeLinkError = async () => {
    throw new Error('Error loading Stripe link');
  };

  const { therapist } = extractRoomEntitiesFromState(mainState, roomID);

  return (
    <PaymentWizardContainer
      therapist={therapist}
      clientUserID={clientUserID}
      onLoadStripeLink={onLoadStripeLink}
      onStripeLinkError={onStripeLinkError}
    />
  );
};

const PaymentWizardWrapper = (props: Props) => <PaymentWizardWrapperContainer {...props} />;

export default withRouter(PaymentWizardWrapper);
