import * as React from 'react';
import camelCase from 'lodash/camelCase';

import { useNewMemberNav } from 'launchDarkly/hooks';
import {
  Big,
  Large,
  View,
  TouchableView,
  useWindowWidthState,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import styled, { EmotionStyle } from '@/core/styled';

interface Props {
  title: string;
  editLinkText: string;
  onEditClick: () => void;
  wrapperStyle?: EmotionStyle;
}

const Container = styled(View)<{ useNewNav: boolean }>(
  ({
    theme: {
      window: { isMobile },
      colors,
    },
    useNewNav,
  }) => {
    const newNavStyles = {
      alignItems: 'stretch',
      flexFlow: 'column nowrap',
      minWidth: '100px',
      maxWidth: '100%',
    };
    return {
      padding: '20px 24px',
      border: `1px solid ${colors.periwinkleGrey}`,
      borderRadius: isMobile ? 0 : 10,
      ...(useNewNav ? newNavStyles : {}),
    };
  }
);

const PersonalInformationDataSection: React.FunctionComponent<Props> = ({
  title,
  editLinkText,
  onEditClick,
  wrapperStyle = {},
  children,
}) => {
  const { colors } = useEmotionTheme();
  const { isDesktop } = useWindowWidthState();
  const useNewNav = useNewMemberNav();
  const editLinkStyle = {
    marginTop: !isDesktop ? 12 : 0,
    borderRadius: 5,
    width: 'fit-content',
    height: 'fit-content',
  };

  const editLink = (
    <TouchableView
      onPress={onEditClick}
      primaryColor={colors.green}
      roundedFocusStyle
      style={editLinkStyle}
    >
      <Large
        variant="largeBoldWideGreen"
        dataQa={`personalInformationEdit${camelCase(title)}Button`}
      >
        {editLinkText}
      </Large>
    </TouchableView>
  );
  return (
    <Container style={wrapperStyle} useNewNav={useNewNav}>
      <Big>{title}</Big>
      <View style={{ flexDirection: 'column', marginTop: 10 }}>
        {React.Children.map(children, (child, i) => {
          if (i === 0 && isDesktop) {
            return (
              <View
                style={{
                  flexDirection: isDesktop ? 'row' : 'column',
                  justifyContent: 'space-between',
                }}
              >
                {child}
                {editLink}
              </View>
            );
          }
          return child;
        })}
        {!isDesktop && !useNewNav && editLink}
      </View>
    </Container>
  );
};

export default PersonalInformationDataSection;
