import { FunctionComponent, useEffect, useRef } from 'react';
import { View } from '@talkspace/react-toolkit';
import isEqual from 'lodash/isEqual';
import MarketingCommunications from '../components/MarketingCommunications/MarketingCommunications';
import MarketingCommunicationsEdit, {
  FormRef,
} from '../components/MarketingCommunicationsEdit/MarketingCommunicationsEdit';
import AccountPanelHeading from '../components/AccountPanelHeading';

import useMarketingCommunications from '../hooks/useMarketingCommunications';

const MarketingCommunicationsContainer: FunctionComponent<{}> = () => {
  const formRef = useRef<FormRef | null>(null);

  const [state, { getMarketingCommunications, patchMarketingCommunications, setIsEditing }] =
    useMarketingCommunications();

  useEffect(() => {
    getMarketingCommunications();
  }, [getMarketingCommunications]);

  const handleSaveChanges = () => {
    if (formRef.current) {
      const formState = formRef.current.getFormState();
      const payload = Object.entries(formState)
        .map(([name, marketingCommunications]) => {
          if (state.marketingCommunicationsByName && state.marketingCommunicationsByName[name]) {
            if (!isEqual(state.marketingCommunicationsByName[name], marketingCommunications)) {
              return { name, ...marketingCommunications };
            }
          }
          return null;
        })
        .filter(Boolean);
      if (!payload.length) {
        setIsEditing(false);
      } else {
        patchMarketingCommunications(payload, formState);
      }
    }
  };
  return state.marketingCommunicationsByName ? (
    <View style={{ flex: 1, maxWidth: 1014 }}>
      <AccountPanelHeading
        title="Notification preferences"
        titleStyles={{ fontSize: 17, fontWeight: 500 }}
        isEditing={state.isEditing}
        isLoading={state.isLoading}
        setIsEditing={setIsEditing}
        saveChanges={handleSaveChanges}
      />
      {state.isEditing ? (
        <MarketingCommunicationsEdit
          marketingCommunicationsByName={state.marketingCommunicationsByName}
          formRef={formRef}
          isError={state.isError}
        />
      ) : (
        <MarketingCommunications
          marketingCommunicationsByName={state.marketingCommunicationsByName}
        />
      )}
    </View>
  ) : (
    <View />
  );
};

export default MarketingCommunicationsContainer;
