import { ApiResponse, BaseApiHelper, callBasedOnMode } from 'ts-frontend/apiHelpers';
import { AppSource, Survey, SurveyUserResponsePayload } from 'ts-frontend/types';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/utils/api';
import { SurveySource } from '../types';

class SurveyWizardApiHelper extends BaseApiHelper {
  source: SurveySource;

  constructor(source: SurveySource) {
    super();
    this.source = source;
  }

  /**
   * This is not actually used by the surveyWizardApiHelper
   * @param clientUserID number
   * @param payload any
   */
  public saveWizardStepsResults = (clientUserID: number, payload: any): Promise<ApiResponse> =>
    apiWrapper.post(`${apiHelper().apiEndpoint}/v3/users/${clientUserID}/wizard`, payload);

  private getUserRoomSurvey(userRoomSurveyID: number): Promise<ApiResponse<{ data: Survey }>> {
    return this.wrapWithCancel(
      apiWrapper.get(`${apiHelper().surveyAPIEndpoint}/v3/surveys/${userRoomSurveyID}`)
    );
  }

  private getSurveyQM(surveyID: number): Promise<ApiResponse<{ data: Survey }>> {
    return this.wrapWithCancel(
      apiWrapper.get(`${apiHelper().surveyAPIEndpoint}/v3/qm/surveys/${surveyID}`)
    );
  }

  public getSurvey(surveyID: number): Promise<Survey> {
    return callBasedOnMode(this.source, {
      [AppSource.client]: () => this.getUserRoomSurvey(surveyID),
      [AppSource.qm]: () => this.getSurveyQM(surveyID),
      // @ts-ignore: There are differences as to how apiWrappers deal with data.data...
    }).then((response: any) => {
      if (this.source === AppSource.qm) return response;
      return response.data.data;
    });
  }

  private postUserRoomSurveyAnswer(
    userResponse: SurveyUserResponsePayload,
    userRoomSurveyID: number
  ): Promise<void> {
    return this.wrapWithCancel(
      apiWrapper.post(
        `${apiHelper().surveyAPIEndpoint}/v3/surveys/${userRoomSurveyID}/answer`,
        userResponse
      )
    ).then(() => {});
  }

  private postSurveyAnswerQM(
    userResponse: SurveyUserResponsePayload,
    userRoomSurveyID: number
  ): Promise<void> {
    return this.wrapWithCancel(
      apiWrapper.post(
        `${apiHelper().surveyAPIEndpoint}/v3/qm/surveys/${userRoomSurveyID}/answer`,
        userResponse
      )
    ).then(() => {});
  }

  public postSurveyUserResponse(
    userResponse: SurveyUserResponsePayload,
    userRoomSurveyID: number
  ): Promise<void> {
    return callBasedOnMode(this.source, {
      [AppSource.client]: () => this.postUserRoomSurveyAnswer(userResponse, userRoomSurveyID),
      [AppSource.qm]: () => this.postSurveyAnswerQM(userResponse, userRoomSurveyID),
    });
  }

  private putCompleteUserRoomSurvey(isCompleted: boolean, userRoomSurveyID: number): Promise<void> {
    return this.wrapWithCancel(
      apiWrapper.put(`${apiHelper().surveyAPIEndpoint}/v3/surveys/${userRoomSurveyID}/complete`, {
        isCompleted,
      })
    ).then((response) => {
      if (response.status !== 200) throw new Error(String(response.status));
    });
  }

  private putCompleteSurveyQM(isCompleted: boolean, userRoomSurveyID: number): Promise<void> {
    return this.wrapWithCancel(
      apiWrapper.put(
        `${apiHelper().surveyAPIEndpoint}/v3/qm/surveys/${userRoomSurveyID}/complete`,
        {
          isCompleted,
        }
      )
    ).then(() => {});
  }

  public putCompleteSurvey(isCompleted: boolean, userRoomSurveyID: number): Promise<void> {
    return callBasedOnMode(this.source, {
      [AppSource.client]: () => this.putCompleteUserRoomSurvey(isCompleted, userRoomSurveyID),
      [AppSource.qm]: () => this.putCompleteSurveyQM(isCompleted, userRoomSurveyID),
    });
  }
}

export default SurveyWizardApiHelper;
