import { RoomInvite } from 'ts-frontend/entities/RoomInvite';
import apiHelper from '../../core/api/apiHelper';
import apiWrapper from '../../core/api/apiWrapper';
import parseJwt from '../../utils/jwt';

interface ApiResponse<ResponseType> {
  data: ResponseType;
}

interface validateInvitationResponse {
  invitationSentToEmail: string | null;
}

export const getInvites = async (roomID: number): Promise<ApiResponse<RoomInvite[]>> => {
  const { data } = await apiWrapper.get(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/invites`);
  return data;
};

export const createInvite = (roomID: number, email: string) =>
  apiWrapper.post(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/invites`, { email });

export const revokeInvite = (roomID: number, inviteID: number) =>
  apiWrapper.delete(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/invites/${inviteID}`);

export const validateInvitationToken = async (
  invitationToken: string
): Promise<ApiResponse<validateInvitationResponse>> => {
  const { resource } = parseJwt(invitationToken);
  const { data } = await apiWrapper.get(`${apiHelper().apiEndpoint}${resource[0].path}`, {
    headers: { Authorization: `Bearer ${invitationToken}` },
  });
  return data;
};

export const processInvitation = (invitationKey: string) =>
  apiWrapper.post(`${apiHelper().apiEndpoint}/v2/invites/process-invite`, { invitationKey });
