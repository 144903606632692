import { useEffect } from 'react';
import UserEventsSocketService from '../clientChat/utils/UserEventsSocketService';

interface UseRoomSubscriptionSocketOptions {
  getLVSSubscriptions: () => void;
}

export default function useRoomSubscriptionSocket(options: UseRoomSubscriptionSocketOptions) {
  const { getLVSSubscriptions } = options;
  useEffect(() => {
    getLVSSubscriptions();
    const userEventsSocketService = new UserEventsSocketService();
    userEventsSocketService.on('changedPayment', () => getLVSSubscriptions());
    userEventsSocketService.on('changedRoomStatus', () => getLVSSubscriptions());
    userEventsSocketService.on('changedSubscription', () => getLVSSubscriptions());

    return () => {
      userEventsSocketService.off('changedPayment', () => getLVSSubscriptions());
      userEventsSocketService.off('changedRoomStatus', () => getLVSSubscriptions());
      userEventsSocketService.off('changedSubscription', () => getLVSSubscriptions());
      userEventsSocketService.unmount(false);
    };
  }, [getLVSSubscriptions]);
}
