import { FunctionComponent } from 'react';
import { View, Button } from '@talkspace/react-toolkit';

interface ActionButtonsProps {
  onCancel: () => void;
  onSave: () => void;
  isLoading?: boolean;
  saveButtonText?: string;
}

const ActionButtons: FunctionComponent<ActionButtonsProps> = ({
  onCancel,
  onSave,
  isLoading,
  saveButtonText = 'Save changes',
}) => (
  <View row>
    <Button size="small" isSecondary text="Cancel" onPress={onCancel} style={{ marginRight: 4 }} />
    <Button size="small" text={saveButtonText} onPress={onSave} disabled={isLoading} />
  </View>
);

export default ActionButtons;
