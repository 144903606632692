import * as React from 'react';
import { Modal, useModalActions } from '@talkspace/react-toolkit';
import { withRouter, RouteComponentProps } from '../../../core/routerLib';
import { UpcomingSurvey } from '../../reducers/surveys';
import { useCloseModal } from '../../../utils/ModalsContextProvider';
import ClinicalProgressRoomList from '../ClinicalProgressRoomList';

const ClinicalProgressRoomModal: React.FunctionComponent<
  RouteComponentProps<
    {},
    {},
    {
      currentRoomID: number;
      upcomingSurveysByRoomID?: { [roomID: string]: UpcomingSurvey[] };
    }
  >
> = () => {
  const closeModal = useCloseModal();
  const { setModalTitle } = useModalActions();
  const titleText =
    'You are tracking your progress with multiple therapists. Select which progress area you’d like to view.';
  setModalTitle(titleText);
  return (
    <Modal.Panel onBackdropPress={() => closeModal()} contentViewStyle={{ paddingBottom: 60 }}>
      <ClinicalProgressRoomList />
    </Modal.Panel>
  );
};

export default withRouter(ClinicalProgressRoomModal);
