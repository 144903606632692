import React from 'react';

import {
  ResponsiveLayoutWithHeader,
  SimpleHeader,
  View,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { useCloseModal } from '@/utils/ModalsContextProvider';

interface Props {
  children: React.ReactNode;
  title?: string;
  onBack?: () => void;
  isCloseable?: boolean;
}

const PostAsyncPromptBaseScreen = ({ children, title, onBack, isCloseable = true }: Props) => {
  const { isMobile } = useWindowWidthState();
  const closeModal = useCloseModal();
  const onClose = isCloseable ? closeModal : undefined;
  return (
    <ResponsiveLayoutWithHeader
      renderHeader={() => <SimpleHeader title={title} onClose={onClose} onBack={onBack} />}
      noHeaderLine={!title}
      contentWrapperStyle={{ paddingBottom: 40 }}
    >
      <View style={{ maxWidth: isMobile ? 327 : 394, alignItems: 'center', alignSelf: 'center' }}>
        {children}
      </View>
    </ResponsiveLayoutWithHeader>
  );
};

export default PostAsyncPromptBaseScreen;
