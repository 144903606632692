import { FunctionComponent } from 'react';
import SuperbillsContainer from './SuperbillsContainer';
import SuperbillsContent from './SuperbillsContent';

const SuperbillsWrapper: FunctionComponent = ({ children }) => (
  <SuperbillsContainer>
    <SuperbillsContent>{children}</SuperbillsContent>
  </SuperbillsContainer>
);

export default SuperbillsWrapper;
