import { useReducer, useCallback } from 'react';
import { TherapistReviewData } from 'ts-frontend/types';
import {
  TherapistReviewState,
  initialState,
  therapistReviewReducer,
} from '../reducers/therapistReviewReducer';
import API from '../utils/therapistReviewApiHelper';

export default function useTherapistReview(): [
  TherapistReviewState,
  {
    postTherapistReview: (reviewPayload: TherapistReviewData) => void;
    getTherapistInfo: (roomID: number) => void;
  }
] {
  const [state, dispatch] = useReducer(therapistReviewReducer, initialState);

  function postTherapistReview(reviewPayload: TherapistReviewData) {
    dispatch({ type: 'requestPostTherapistReview' });
    API.postTherapistReview(reviewPayload)
      .then((therapistReviewData) => {
        if (therapistReviewData) {
          dispatch({
            type: 'receivePostTherapistReview',
            payload: {
              therapistReviewData,
            },
          });
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        dispatch({ type: 'setError', error: 'Unable to load content' });
      });
  }

  function getTherapistInfo(roomID: number) {
    dispatch({ type: 'requestGetTherapistInfo' });
    API.getTherapistInfo(roomID)
      .then((therapistInfo) => {
        dispatch({
          type: 'receiveGetTherapistInfo',
          payload: { therapist: therapistInfo },
        });
      })
      .catch(() => {
        dispatch({ type: 'setError', error: 'Unable to load content' });
      });
  }

  return [
    state,
    {
      postTherapistReview: useCallback(postTherapistReview, []),
      getTherapistInfo: useCallback(getTherapistInfo, []),
    },
  ];
}
