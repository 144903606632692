import { CommunityRepository } from '@amityco/ts-sdk';
import { useEffect, useState } from 'react';
import { useCommunityState } from './CommunityContext';

const useCommunity = ({ communityID }) => {
  const { isConnected } = useCommunityState();
  const [response, setResponse] = useState<Amity.LiveObject<Amity.Community>>();

  useEffect(() => {
    let unsubscribe: () => void;

    if (isConnected && communityID) {
      unsubscribe = CommunityRepository.getCommunity(communityID, (res) => {
        setResponse(res);
      });
      return () => {
        unsubscribe?.();
      };
    }
    return () => {};
  }, [isConnected, communityID]);

  const { data, ...rest } = response || {};

  return { community: data, ...rest };
};

export default useCommunity;
