import { GetClient2faResponse } from 'ts-frontend/types';
import apiHelper from '../../core/api/apiHelper';
import apiWrapper from '../../core/api/apiWrapper';

export const HAS_LOGGED_IN_BEFORE = 'hasLoggedInBefore';

export const getClient2FA = (clientUserID: number): Promise<GetClient2faResponse> =>
  apiWrapper
    .get(`${apiHelper().apiEndpoint}/v2/users/${clientUserID}/2fa`)
    .then((res) => res.data.data);

export const patchEnable2FA = (clientUserID: number, disable2FA: boolean): Promise<boolean> =>
  apiWrapper
    .patch(`${apiHelper().apiEndpoint}/v2/users/${clientUserID}/2fa`, { disable2FA })
    .then((res) => res.status === 204);

export const patchPhoneNumber2FA = (
  clientUserID: number,
  phoneNumber: string,
  phoneNumberCountryCode: string
): Promise<boolean> =>
  apiWrapper
    .patch(`${apiHelper().apiEndpoint}/v2/users/${clientUserID}/2fa`, {
      phoneNumber,
      phoneNumberCountryCode,
    })
    .then((res) => res.status === 204);
