import { Route, Switch } from '@/core/routerLib';
import TreatmentIntakeLauncherContainer from './containers/TreatmentIntakeLauncherContainer';

const Routes = () => (
  <Switch>
    <Route
      exact
      path="/treatment-intake-launcher/room/:roomID/source/:source"
      component={TreatmentIntakeLauncherContainer}
    />
  </Switch>
);

export default Routes;
