import { FunctionComponent, useEffect, useState, VoidFunctionComponent } from 'react';
import {
  View,
  RouterLinkWrapper,
  Spinner,
  useEmotionTheme,
  CreateAccountForm,
  Tiny,
  SignUpData,
} from '@talkspace/react-toolkit';
import useVWO from 'ts-analytics/VWO/useVWO';
import configs from '@/utils/configs';

import {
  Link as RouterLink,
  RouteComponentProps,
  withRouter,
} from '../../../core/routerLib/routerLib';
import { removeTokens } from '../../../auth/helpers/token';
import { useClientAuthState, useClientAuthActions } from '../../../hooks/clientAuthContext';
import storage from '../../../core/storage';
import { StoragePersistanceMode } from '../../../core/storage/storage';
import { getInviteHashParams, useValidateInvitationToken } from '../../../roomInvites';
import { RegistrationMessage } from '../../../utils/ApiHelper';
import { trackTalkspacePageView, trackLandingPageCTAFlowCT } from '../../../utils/analytics/events';
import {
  DEFAULT_FUNNEL_VARIATION,
  getFlowData,
  getRegistrationCookie,
} from '../../../utils/attributionHelper';
import { getReferralParams, getVoucherCode } from '../../utils/queryHelpers';
import { useAttribution } from '../../../hooks/useAttribution';

const ClientWebSignupFooter: VoidFunctionComponent<{
  clearErrorsAction: () => void;
  invitationKey?: string;
}> = ({ clearErrorsAction, invitationKey }) => {
  const { colors } = useEmotionTheme();

  return (
    <View align="center" style={{ width: 335 }}>
      {configs.featureFlags.ssoRegister && (
        <View style={{ marginTop: 20 }}>
          <RouterLinkWrapper>
            <RouterLink to="/login/sso/register" data-qa="ssoRegisterLink">
              <Tiny variant="tinyBoldGreen" inline>
                Register with Single Sign-On (SSO)
              </Tiny>
            </RouterLink>
          </RouterLinkWrapper>
        </View>
      )}
      <View style={{ marginTop: 20 }}>
        <Tiny>
          Already have an account?{' '}
          <RouterLinkWrapper primaryColor={colors.green} roundedFocusStyle>
            <RouterLink
              onClick={clearErrorsAction}
              to={{ pathname: '/login', hash: invitationKey && `invitationKey=${invitationKey}` }}
              data-qa="loginLink"
            >
              <Tiny variant="tinyBoldGreen" inline>
                Log in
              </Tiny>
            </RouterLink>
          </RouterLinkWrapper>
        </Tiny>
      </View>
    </View>
  );
};

const Signup: FunctionComponent<
  RouteComponentProps<{ slugName?: string }, {}, { redirect?: string }>
> = ({ match }) => {
  const [isLoadingRedirect, setIsLoadingRedirect] = useState(false);
  const inviteHashParams = getInviteHashParams();
  const { isFetching, data: entrypointData } = useAttribution();
  const { isUpdating, isError, errorMessage, providerInfo } = useClientAuthState();
  const { testingInviteToken, invitationSentToEmail } = useValidateInvitationToken();
  const { registerAction, clearErrorsAction, loadProviderInfoAction } = useClientAuthActions();
  // TODO: Replace with VWO variable
  const [experimentActive, experiment] = useVWO('SIGNUP_WITH_PHONE');

  const isPhoneNumberExperimentOn = experimentActive && experiment.showPhoneInput;

  const { slugName } = match.params;

  // If the signup screen mounts, that means we do not have a valid token
  // See src/auth/AuthContainer

  useEffect(() => {
    // Remove tokens from localStorage and sessionStorage.
    storage.setStoragePersistanceMode(StoragePersistanceMode.LOCAL);
    removeTokens();
    storage.setStoragePersistanceMode(StoragePersistanceMode.SESSION);
    removeTokens();
  }, []);

  useEffect(() => {
    if (slugName) loadProviderInfoAction(slugName);
  }, [loadProviderInfoAction, slugName]);

  useEffect(() => {
    const flowData = getFlowData();

    trackTalkspacePageView('Signup', flowData.funnelVariation, flowData, true);

    trackLandingPageCTAFlowCT();
  }, []);

  useEffect(() => {
    if (!isFetching && entrypointData) {
      setIsLoadingRedirect(true);
      const [referrerID, channel, platform] = getReferralParams();
      if (
        entrypointData.goto === 'REDIRECT_URL' &&
        entrypointData.params.redirectUrl &&
        // Do normal signup on these cases:
        !(
          inviteHashParams.invitationKey ||
          slugName ||
          window.location.search.includes('force=1') ||
          getVoucherCode() ||
          referrerID ||
          channel ||
          platform
        )
      ) {
        window.location.href = entrypointData.params.redirectUrl;
      } else {
        setIsLoadingRedirect(false);
      }
    }
  }, [entrypointData, inviteHashParams.invitationKey, isFetching, slugName]);

  const onSubmit = async (formState: SignUpData, password: string) => {
    // Extract currentReferrerUrl
    const { currentReferrerUrl, ...flowData } = getFlowData({ ignoreCookie: true });
    const [referrerID, channel, platform] = getReferralParams();
    const accessCode = getVoucherCode();
    const registrationCookie = getRegistrationCookie();
    const customerInformation = {
      ...(formState.country &&
        configs.featureFlags.countryStateDropDown && { country: formState.country }),
      ...(formState.state && { state: formState.state }),
    };

    const message: RegistrationMessage = {
      userDetails: {
        email: formState.email as string,
        nickname: formState.nickname,
        password,
        customerInformation,
        timezone: window.Intl ? window.Intl.DateTimeFormat().resolvedOptions().timeZone : '',
        ...(isPhoneNumberExperimentOn
          ? {
              phoneNumber: formState.phoneNumber,
              phoneNumberCountryCode: formState.phoneNumberCountryCode,
            }
          : {}),
      },
      flowData,
      invitationKey: inviteHashParams.invitationKey,
    };
    if (accessCode) {
      message.paymentDetails = { voucherCode: accessCode };
    }
    if (referrerID) {
      message.flowData.friendReferral = { referrerID, channel, platform };
    }
    if (providerInfo) {
      const BOUGHT_FROM_ROOM_USING_THERAPIST_SLUG = 27;
      if (message.flowData.funnelVariation === DEFAULT_FUNNEL_VARIATION)
        message.flowData.funnelVariation = 'therapist_direct';
      message.therapistData = {
        therapistID: providerInfo.id,
      };
      message.flowData.boughtFrom = BOUGHT_FROM_ROOM_USING_THERAPIST_SLUG;
      if (providerInfo.type === 'psychiatrist') {
        message.flowData.roomType = 'psychiatryRoom';
      }
    }

    if (registrationCookie) {
      message.flowData = { ...message.flowData, ...registrationCookie.flowData };
      if (registrationCookie.qmSessionID) message.qmSessionID = registrationCookie.qmSessionID;
    }

    // must be after the cookie spread
    if (!message.flowData.referrerUrl) {
      delete message.flowData.referrerUrl;
    }

    registerAction(message);
  };

  const formBodyCopy = getVoucherCode()
    ? 'Sign up to start using your Talkspace Giftcard.'
    : `Sign up to join over 1 million people \nwho already feel happier.`;

  const showSpinner = testingInviteToken || isFetching || isLoadingRedirect;
  return showSpinner ? (
    <Spinner />
  ) : (
    <View align="center">
      <CreateAccountForm
        clientEmail={invitationSentToEmail as string}
        isUpdating={isUpdating}
        onSubmit={onSubmit}
        isError={isError}
        errorMessage={errorMessage}
        bodyCopy={formBodyCopy}
        showCountryStateDropdown={configs.featureFlags.countryStateDropDown}
        showPhoneNumberInput={isPhoneNumberExperimentOn}
        minimumAge={13}
        loginFooter={() => (
          <ClientWebSignupFooter
            invitationKey={inviteHashParams.invitationKey}
            clearErrorsAction={clearErrorsAction}
          />
        )}
      />
    </View>
  );
};

export default withRouter(Signup);
