import { View } from '@talkspace/react-toolkit';
import styled from '@/core/styled';

const SuperbillsContainer = styled(View)({
  display: 'flex',
  alignItems: 'center',
  minHeight: '100%',
  padding: 20,
});

export default SuperbillsContainer;
