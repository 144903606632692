import moment from 'moment';

import {
  Button,
  OneColumnLayout,
  TextDS,
  useEmotionTheme,
  useWindowWidthState,
  View,
} from '@talkspace/react-toolkit';

import NotepadWithCheckmark from '../assets/NotapadWithCheckmark';

const NYCTeenspaceAge = moment().diff(moment('2023-09-01'), 'years');
const ageString =
  {
    1: 'One',
    2: 'Two',
    3: 'Three',
  }[NYCTeenspaceAge] || NYCTeenspaceAge;

interface Props {
  onPressCTA: () => void;
  isLoading: boolean;
}
const DTERenewalNYCTeen = ({ onPressCTA, isLoading }: Props) => {
  const { spacing, safeAreaInsets } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  return (
    <OneColumnLayout>
      <View
        justify="space-between"
        style={{
          textAlign: 'center',
          height: isMobile
            ? `calc(100vh - ${safeAreaInsets.top}px - ${safeAreaInsets.bottom}px)`
            : undefined,
          padding: `calc(${spacing('space800')}px + ${safeAreaInsets.top}px) 0 calc(${spacing(
            'space600'
          )}px - ${safeAreaInsets.bottom}px)`,
        }}
      >
        <View align="center" style={{ marginTop: spacing('space500') }}>
          <NotepadWithCheckmark />
          <TextDS variant="headingXl" style={{ marginTop: spacing('space300') }}>
            NYC Teenspace Turns {ageString}!
          </TextDS>
          <TextDS style={{ marginTop: spacing('space150') }}>
            Every year we need to confirm that you would like to continue free therapy with NYC
            Teenspace
          </TextDS>
        </View>
        <Button
          dataQa="continueWithTeenspaceButton"
          onPress={onPressCTA}
          text="Continue with NYC Teenspace"
          stretch
          style={{ marginTop: spacing('space500') }}
          disabled={isLoading}
        />
      </View>
    </OneColumnLayout>
  );
};

export default DTERenewalNYCTeen;
