import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';

import { View } from '@talkspace/react-toolkit';
import { InformedConsentAPIResponse, InformedConsentV2StatusAPI } from 'ts-frontend/types';
import { RouteComponentProps, withRouter, useParams } from '@/core/routerLib';
import { useMainActions, useMainState } from '@/hooks/mainContext';
import { getUserData } from '@/auth/helpers/token';
import ApiHelper from '@/utils/ApiHelper';
import InformedConsentActionView from '../../components/InformedConsentAction/InformedConsentActionView';
import { getAgreedDate } from '../../utils/informedConsent/informedConsentHelpers';

const InformedConsentContainer: FunctionComponent<RouteComponentProps> = ({ history }) => {
  const { id: userID } = getUserData();
  const { informedConsentStatus: { roomID, therapistID, informedConsentID } = {} } = useMainState();
  const { getInformedConsentStatus } = useMainActions();
  const { roomID: currentRoomID } = useParams<{ roomID: string | undefined }>();

  const { current: api } = useRef(new ApiHelper());

  const [informedConsent, setInformedConsent] = useState<InformedConsentAPIResponse>();
  const [informedConsentV2, setInformedConsentV2] = useState<InformedConsentV2StatusAPI>();

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    getInformedConsentStatus(userID);
  }, [getInformedConsentStatus, userID]);

  useEffect(() => {
    if (roomID && therapistID && informedConsentID) {
      Promise.all([
        api.getInformedConsent(roomID, therapistID, undefined, undefined, informedConsentID),
        api.getInformedConsentV2Status(userID),
      ])
        .then(([consent, consentV2]) => {
          setInformedConsent(consent);
          setInformedConsentV2(consentV2);
        })
        .catch(({ message }) => setError(message));
    }
  }, [roomID, therapistID, api, informedConsentID, userID]);

  const onAgreePress = useCallback(() => {
    if (roomID && therapistID) {
      api.agreeToInformedConsentV2(userID).then(() => {
        history.push(`/room/${roomID}/onboarding/next`);
      });
    } else if (currentRoomID) {
      api.agreeToInformedConsentV2(userID).then(() => {
        history.push(`/room/${currentRoomID}/onboarding/next`);
      });
    }
  }, [roomID, therapistID, api, userID, history, currentRoomID]);

  const agreedDate = getAgreedDate(informedConsent, informedConsentV2);

  return (
    <View align="center">
      <View style={{ maxWidth: 720, paddingTop: 60 }}>
        <InformedConsentActionView
          hasV1InformedConsent={!!informedConsent}
          hasV2InformedConsent={informedConsentV2?.hasV2}
          onAgreePress={onAgreePress}
          error={error}
          hasReturnButton={false}
          agreedDate={agreedDate}
          hasConsented={false}
        />
      </View>
    </View>
  );
};

export default withRouter(InformedConsentContainer);
