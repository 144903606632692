import { useMemo, useRef } from 'react';

import useInfiniteScroll from '@talkspace/react-toolkit/src/hooks/useInfiniteScroll';

import { useEmotionTheme } from '@talkspace/react-toolkit';
import CommunityFeedEnd from './CommunityFeedEnd';
import MoreResultsLoader from './MoreResultsLoader';
import CommunityPostComment from './CommunityPostComment';
import useCommunityPostComments from '../hooks/useCommunityPostComments';

const CommunityPostComments = ({ post }: { post: Amity.Post }) => {
  const lastCommentRef = useRef<HTMLDivElement | null>(null);
  const { spacing } = useEmotionTheme();

  const {
    comments,
    hasMore,
    onLoadMore,
    isLoading: isLoadingComments,
  } = useCommunityPostComments({ post });

  const isLoadingMore = useMemo(
    () => isLoadingComments && comments && comments.length > 0,
    [isLoadingComments, comments]
  );

  useInfiniteScroll({ bottomElementRef: lastCommentRef, hasMore, onLoadMore });

  return (
    <>
      {!isLoadingComments &&
        comments &&
        comments.map((comment, i) => {
          const isLast = i === comments.length - 1;
          return (
            <CommunityPostComment
              key={`comment-${comment.commentId}`}
              ref={isLast ? lastCommentRef : undefined}
              comment={comment}
            />
          );
        })}
      {isLoadingMore && <MoreResultsLoader text="More comments incoming..." />}
      {!isLoadingComments && !hasMore && (
        <CommunityFeedEnd style={{ marginBottom: spacing('space800') + spacing('space200') }} />
      )}
    </>
  );
};

export default CommunityPostComments;
