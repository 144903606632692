import {
  View,
  Avatar,
  TherapistSearch,
  RoundFilledCheckmark,
  RoundRectExclaimationMark,
  CouplesDefaultAvatar,
  RoundFilledExclamation,
} from '@talkspace/react-toolkit';
import { ERoom } from 'ts-frontend/entities/Room';
import { ETherapistInfo } from 'ts-frontend/entities/Therapist';
import { roomHasRealProvider } from 'ts-frontend/helpers';
import styled from '../../core/styled';

const AVATAR_SIZE = 87;

const Styled = {
  AvatarWrapper: styled(View)<{
    width?: number;
    height?: number;
  }>(({ width, height }) => {
    return {
      height: height || AVATAR_SIZE,
      width: width || AVATAR_SIZE,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      position: 'relative',
      zIndex: 0,
    };
  }),
  AvatarStatusIconWrapper: styled(View)({
    bottom: 0,
    right: 0,
    position: 'absolute',
    zIndex: 3,
  }),
  FinalAvatarWrapper: styled(View)<{ therapistAvatarSize: number }>(
    ({ theme: { colors }, therapistAvatarSize }) => {
      return {
        width: therapistAvatarSize,
        height: therapistAvatarSize,
        borderRadius: '50%',
        overflow: 'hidden',
        backgroundColor: colors.permaHawkesBlue,
      };
    }
  ),
  CouplesWrapper: styled(View)({
    marginTop: 30,
    marginBottom: 6,
  }),
  CouplesProviderAvatar: styled(Avatar)(({ theme: { colors } }) => {
    return {
      boxSizing: 'content-box',
      borderRadius: '50%',
      border: `6px solid ${colors.white}`,
      position: 'relative',
    };
  }),
};

interface TherapistFinalAvatarProps {
  alt: string;
  imageURL: string;
  hasProvider: boolean;
  therapistAvatarSize: number;
}

const TherapistFinalAvatar = ({
  alt,
  imageURL,
  hasProvider,
  therapistAvatarSize,
}: TherapistFinalAvatarProps) => {
  if (!hasProvider) {
    return (
      <Styled.FinalAvatarWrapper
        justify="center"
        align="center"
        therapistAvatarSize={therapistAvatarSize}
      >
        <TherapistSearch width={34} height={31} />
      </Styled.FinalAvatarWrapper>
    );
  }

  return (
    <Avatar height={therapistAvatarSize} width={therapistAvatarSize} image={imageURL} alt={alt} />
  );
};

interface AvatarStatusIconProps {
  isProviderAvailable: boolean;
  isCoupleRoom: boolean;
  hide?: boolean;
  isReactivationTray?: boolean;
}

const AvatarStatusIcon = ({
  isCoupleRoom,
  isProviderAvailable,
  hide,
  isReactivationTray = false,
}: AvatarStatusIconProps) => {
  if (hide) return null;

  const avatarStatusIconSize = isReactivationTray ? 20 : 25;

  const statusIconPositionStyle = isCoupleRoom
    ? {
        left: 32,
        bottom: -12,
      }
    : {
        left: 55,
        bottom: 2,
      };

  const getIconComponent = () => {
    if (isReactivationTray && !isProviderAvailable) {
      return <RoundFilledExclamation width={avatarStatusIconSize} height={avatarStatusIconSize} />;
    }

    if (!isProviderAvailable) {
      return <RoundRectExclaimationMark />;
    }

    if (isProviderAvailable) {
      return <RoundFilledCheckmark width={avatarStatusIconSize} height={avatarStatusIconSize} />;
    }

    return null;
  };

  return (
    <Styled.AvatarStatusIconWrapper style={statusIconPositionStyle}>
      {getIconComponent()}
    </Styled.AvatarStatusIconWrapper>
  );
};

interface TherapistAvatarV2Props {
  therapist: ETherapistInfo;
  room: ERoom;
  isProviderAvailable: boolean;
  hideStatus?: boolean;
  isReactivationTray?: boolean;
}

const TherapistAvatarV2 = ({
  room,
  therapist,
  isProviderAvailable,
  hideStatus = false,
  isReactivationTray = false,
}: TherapistAvatarV2Props) => {
  const { status: roomStatus, roomType } = room || {};
  const imageAlt = therapist.imageURL ? 'care provider profile picture' : 'care provider avatar';
  const altWithStatus = `${imageAlt}, status: ${therapist.availability}`;

  const isCoupleRoom = roomType === 'couples_room';
  const reactivationAvatarSize = isReactivationTray ? 64 : AVATAR_SIZE;
  const couplesAvatarSize = isCoupleRoom ? 85 : AVATAR_SIZE;
  const therapistAvatarSize = isReactivationTray ? reactivationAvatarSize : couplesAvatarSize;
  return (
    <Styled.AvatarWrapper aria-hidden="true" height={therapistAvatarSize}>
      {isCoupleRoom ? (
        <Styled.CouplesWrapper flex={1} row>
          <Styled.CouplesProviderAvatar
            image={therapist.imageURL}
            width={therapistAvatarSize}
            height={therapistAvatarSize}
          />
          <CouplesDefaultAvatar
            size={therapistAvatarSize}
            style={{ marginTop: 6, marginLeft: -20 }}
          />
        </Styled.CouplesWrapper>
      ) : (
        <TherapistFinalAvatar
          alt={altWithStatus}
          imageURL={therapist.imageURL}
          hasProvider={roomHasRealProvider({ room: { status: roomStatus }, provider: therapist })}
          therapistAvatarSize={therapistAvatarSize}
        />
      )}

      {hideStatus === false && (
        <AvatarStatusIcon
          isCoupleRoom={isCoupleRoom}
          isProviderAvailable={isProviderAvailable}
          isReactivationTray
        />
      )}
    </Styled.AvatarWrapper>
  );
};

export default TherapistAvatarV2;
