import { EmergencyContactData, MyInformationData } from '../types';

export interface State {
  myInformation: MyInformationData;
  emergencyContact: EmergencyContactData;
  isLoading: boolean;
  isLoadingMyInformation: boolean;
  isLoadingEmergencyContact: boolean;
  error: string | null;
}

export type ModalType = 'editMyInformation' | 'editEmergencyContact';

export type Action =
  | { type: 'requestGetMyInformation' }
  | {
      type: 'receiveGetMyInformation';
      payload: MyInformationData;
    }
  | { type: 'requestUpdateMyInformation'; payload: MyInformationData }
  | { type: 'receiveUpdateMyInformation'; payload: MyInformationData }
  | { type: 'requestGetEmergencyContact' }
  | {
      type: 'receiveGetEmergencyContact';
      payload: EmergencyContactData;
    }
  | { type: 'requestUpdateEmergencyContact'; payload: EmergencyContactData }
  | { type: 'receiveUpdateEmergencyContact'; payload: EmergencyContactData }
  | { type: 'setEmergencyContactError'; error: string }
  | { type: 'setMyInformationError'; error: string }
  | { type: 'openModal'; payload: ModalType }
  | { type: 'closeModal' };

export const initialState: State = {
  myInformation: {
    firstName: null,
    lastName: null,
    dateOfBirth: null,
    country: null,
    state: null,
    city: null,
    zipcode: null,
    address: null,
    address2: null,
  },
  emergencyContact: {
    contactName: null,
    contactPhone: null,
    relationship: null,
  },
  isLoading: true,
  isLoadingEmergencyContact: false,
  isLoadingMyInformation: false,
  error: null,
};

export const personalInformationReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'requestGetEmergencyContact':
    case 'requestUpdateEmergencyContact':
      return {
        ...state,
        isLoading: true,
        isLoadingEmergencyContact: true,
        error: null,
      };
    case 'requestGetMyInformation':
    case 'requestUpdateMyInformation':
      return {
        ...state,
        isLoading: true,
        isLoadingMyInformation: true,
        error: null,
      };
    case 'receiveGetEmergencyContact':
    case 'receiveUpdateEmergencyContact':
      return {
        ...state,
        emergencyContact: {
          ...action.payload,
        },
        isLoading: state.isLoadingMyInformation,
        isLoadingEmergencyContact: false,
        error: null,
      };
    case 'receiveGetMyInformation':
    case 'receiveUpdateMyInformation':
      return {
        ...state,
        myInformation: {
          ...action.payload,
        },
        isLoading: state.isLoadingEmergencyContact,
        isLoadingMyInformation: false,
        error: null,
      };
    case 'setEmergencyContactError':
      return {
        ...state,
        error: action.error,
      };
    case 'setMyInformationError':
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};
