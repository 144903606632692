import { useHistory, useParams } from '@/core/routerLib';
import useBookingsOutsideBillingCycle from '../hooks/useBookingsOutsideBillingCycle';
import { getUserData } from '@/auth/helpers/token';
import { useCloseModal } from '@/utils/ModalsContextProvider';

import ManageSubscriptionSessionsToCancel from '../components/ManageSubscriptionSessionsToCancel';

const ManageSubscriptionSessionsToCancelContainer = () => {
  const history = useHistory();
  const { roomID } = useParams<{ roomID: string }>();
  const closeModal = useCloseModal();

  const handleBack = () => history.goBack();
  const handleClose = () => closeModal();

  const handleKeepSessions = () =>
    history.push(`/cancel-subscription/room/${roomID}/source/my-account/thanks-for-staying`);
  const handleCancelSessions = () =>
    history.push(`/cancel-subscription/room/${roomID}/source/my-account`);

  const { id: clientUserID } = getUserData();
  const {
    bookings: sessionsToCancel,
    isLoading,
    billingCycleEnd,
  } = useBookingsOutsideBillingCycle(clientUserID, Number(roomID));
  return (
    <ManageSubscriptionSessionsToCancel
      isLoading={isLoading}
      billingCycleEnd={billingCycleEnd}
      sessionsToCancel={sessionsToCancel}
      onPressBack={handleBack}
      onPressClose={handleClose}
      onPressKeepSessions={handleKeepSessions}
      onPressCancelSessions={handleCancelSessions}
    />
  );
};

export default ManageSubscriptionSessionsToCancelContainer;
