import { FunctionComponent } from 'react';
import ToggleSwitch from '../../../2storybook/components/ToggleSwitch/ToggleSwitch';

interface Props {
  isOn: boolean;
  onChange: (value: boolean) => void;
  name: string;
  // requiring label prop for accessibility purposes
  label: string;
  info?: string;
  controlled?: boolean;
  onBackgroundColor?: string;
}

const NotificationToggle: FunctionComponent<Props> = ({
  isOn,
  onChange,
  controlled = false,
  name,
  label,
  info,
  onBackgroundColor,
}) => (
  <ToggleSwitch
    onBackgroundColor={onBackgroundColor}
    isOn={isOn}
    onValueChange={onChange}
    name={name}
    label={label}
    info={info}
    controlled={controlled}
  />
);

export default NotificationToggle;
