import { FunctionComponent } from 'react';
import {
  ExtraHuge,
  View,
  EnvelopeX,
  Text,
  ResponsiveLayoutWithHeader,
  TSLogo,
  RedX,
} from '@talkspace/react-toolkit';
import { useParams } from 'react-router-dom';
import { useFlags } from 'launchDarkly/FlagsProvider';
import styled from '@/core/styled';
import { Link as RouterLink } from '@/core/routerLib/routerLib';

const Wrapper = styled(View)({
  maxWidth: 400,
  paddingLeft: 30,
  paddingRight: 30,
  alignSelf: 'center',
  alignItems: 'center',
  width: '100%',
});

const Header = styled(View)({
  flex: 1,
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: 20,
  paddingRight: 16,
});

const IconContainer = styled(View)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 80,
});
const TextContainer = styled(View)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 20,
  textAlign: 'center',
});

const Invalid: FunctionComponent = () => (
  <>
    <IconContainer>
      <RedX />
    </IconContainer>
    <TextContainer>
      <ExtraHuge>Something went wrong</ExtraHuge>
    </TextContainer>
    <TextContainer>
      <Text>Please try again later.</Text>
    </TextContainer>
  </>
);

const Expired: FunctionComponent = () => (
  <>
    <IconContainer>
      <EnvelopeX width={83} />
    </IconContainer>
    <TextContainer>
      <ExtraHuge>Link Expired</ExtraHuge>
    </TextContainer>
    <TextContainer>
      <Text>
        For your partner’s security, this link has expired. To join your couples therapy room, ask
        your partner to send you a new invitation.
      </Text>
    </TextContainer>
  </>
);

const InvitationSentSuccess: FunctionComponent = () => {
  const { reason } = useParams<{ reason: 'expired' | 'invalid' }>();
  const { brandRefreshChanges } = useFlags();
  return (
    <ResponsiveLayoutWithHeader
      renderHeader={() => (
        <Header>
          <RouterLink to="/">
            <TSLogo
              style={{ marginLeft: 6, marginTop: 4 }}
              variant={brandRefreshChanges ? '2024' : 'old'}
            />
          </RouterLink>
        </Header>
      )}
    >
      <Wrapper>{reason === 'expired' ? <Expired /> : <Invalid />}</Wrapper>
    </ResponsiveLayoutWithHeader>
  );
};

export default InvitationSentSuccess;
