import { FunctionComponent } from 'react';
import * as React from 'react';
import { BaseButton, View, Large, ExtraHuge, RedX } from '@talkspace/react-toolkit';
import styled from '../../../core/styled';

import ChatModal from '../ChatModal';

interface SSSNoMatchesActionViewProps {
  onBackdropPress: (e: React.MouseEvent<Element, MouseEvent>) => void;
  isLoading: boolean;
  isError: boolean;
}

const CloseButton = styled(BaseButton)({
  marginTop: 30,
  marginBottom: 60,
  backgroundColor: '#fff',
});

const SSSNoMatchesActionView: FunctionComponent<SSSNoMatchesActionViewProps> = ({
  onBackdropPress,
  isError,
  isLoading,
}) => (
  <ChatModal onBackdropPress={onBackdropPress} isVisible isLoading={isLoading}>
    {isError && (
      <View align="center">
        <View style={{ marginBottom: 10, marginTop: 20 }}>
          <RedX />
        </View>
        <ExtraHuge style={{ marginBottom: 12, marginTop: 20 }}>Action already completed.</ExtraHuge>
        <Large variant="largeDarkGrey">
          You have already been transferred to a matching agent.
        </Large>
        <CloseButton onPress={onBackdropPress}>
          <Large variant="largeDarkGrey">Close</Large>
        </CloseButton>
      </View>
    )}
  </ChatModal>
);

export default SSSNoMatchesActionView;
