import { FunctionComponent, useEffect } from 'react';
import { View, Breadcrumbs, useWindowWidthState } from '@talkspace/react-toolkit';
import { PlanData } from 'ts-frontend/types';
import { RouteComponentProps, withRouter } from '@/core/routerLib/routerLib';
import { useRoomOffersActions, useRoomOffersState } from '../hooks/roomOfferContext';
import styled from '@/core/styled';
import Offer from '../components/Offer';
import StickyHeader from '../components/StickyHeader';
import { RoomOfferRouteParams } from '../types';
import pushOfferRoute from '../utils/pushOfferRoute';
import OffersDiscountBanner from '../components/OffersDiscountBanner';

const Container = styled(View)({
  minHeight: '100%',
  paddingBottom: 20,
  paddingTop: 5,
});

export type ReturnTo = 'checkout' | 'default';

interface OfferContainerProps extends RouteComponentProps<RoomOfferRouteParams> {
  stickHeader?: boolean;
  titleId?: string;
}

const OfferBreadcrumbs = ({ isMobile }) => {
  const items = [{ label: 'Choose plan' }, { label: 'Review details' }, { label: 'Checkout' }];

  return (
    <Breadcrumbs
      style={{
        width: isMobile ? 'calc(100% - 20px)' : 475,
        maxWidth: isMobile ? 455 : 475,
        minWidth: 300,
        marginLeft: isMobile ? 0 : -20,
      }}
      activeIndex={0}
      items={items}
    />
  );
};

const OfferContainer: FunctionComponent<OfferContainerProps> = ({
  history,
  match,
  location,
  stickHeader,
  titleId,
}) => {
  const {
    trialOfferPrice,
    trialOfferPriceDisplay,
    isErrorSubscription,
    currentPlan,
    isChangePlan,
    plansMatrix,
    insuranceEligibility,
    promoToDisplay,
    offer,
    offerID,
  } = useRoomOffersState();

  const { setRoomOfferStateAction, planSelectedAction } = useRoomOffersActions();

  useEffect(() => {
    if (isErrorSubscription) {
      setRoomOfferStateAction({ isErrorSubscription: false });
      pushOfferRoute(match, history, location, '/payment-details');
    }
  }, [history, isErrorSubscription, location, match, setRoomOfferStateAction]);

  const handleOnPlanSelected = (plan: PlanData, monthlyPlan: PlanData) => {
    planSelectedAction(plan, monthlyPlan);
  };

  const { isMobile } = useWindowWidthState();

  return (
    <Container align="center">
      <input type="hidden" data-qa="offerID" value={offerID} />
      {!isChangePlan && (
        <StickyHeader stickHeader={stickHeader}>
          <OfferBreadcrumbs isMobile={isMobile} />
        </StickyHeader>
      )}
      {plansMatrix && (
        <>
          {(insuranceEligibility || promoToDisplay) && (
            <OffersDiscountBanner
              currency={offer?.currency || 'USD'}
              insuranceEligibility={insuranceEligibility}
              promoToDisplay={promoToDisplay}
            />
          )}
          <Offer
            plansMatrix={plansMatrix}
            currentPlan={currentPlan}
            titleId={titleId}
            trialOfferPrice={trialOfferPrice}
            trialOfferPriceDisplay={trialOfferPriceDisplay}
            onPlanSelected={handleOnPlanSelected}
            style={{ maxWidth: 475 }}
          />
        </>
      )}
    </Container>
  );
};

export default withRouter(OfferContainer);
