import { FunctionComponent } from 'react';
import { View, Standard, Large, BaseButton, CreditCard } from '@talkspace/react-toolkit';
import { PaymentDetails } from 'ts-frontend/types';
import { CreditCardDetails } from 'checkout';
import { useTranslation } from '@talkspace/i18n';
import styled from '../../../core/styled';

const PaymentButton = styled(BaseButton)(({ disabled, theme: { colors } }) => {
  return {
    padding: 0,
    margin: 0,
    backgroundColor: colors.white,
    ':hover': {
      cursor: disabled ? 'initial' : undefined,
    },
  };
});

const UpdateOrAdd = styled(Large)<{ disabled?: boolean }>(({ disabled, theme: { colors } }) => {
  return {
    color: disabled ? colors.periwinkleGrey : undefined,
  };
});

interface CardDetailsProps {
  onUpdatePress?: () => void;
  disabled?: boolean;
  paymentDetails?: PaymentDetails;
}

const CardDetails: FunctionComponent<CardDetailsProps> = ({
  onUpdatePress,
  disabled,
  paymentDetails,
}) => {
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  const titleText: string = paymentDetails ? 'Update Payment Method' : 'Add Payment Method';
  return (
    <>
      <View row justify="space-between" style={{ marginBottom: 10 }}>
        <Standard>{tnewMemberNav('payment.payment', 'Payment')}</Standard>
        <PaymentButton
          type="button"
          disabled={disabled}
          onPress={onUpdatePress}
          data-qa="myAccountPaymentUpdateOrAddButton"
          aria-label={titleText}
        >
          <UpdateOrAdd variant="largeBoldWideGreen" disabled={disabled}>
            {paymentDetails
              ? tnewMemberNav('payment.update', 'Update')
              : tnewMemberNav('payment.add', 'Add card')}
          </UpdateOrAdd>
        </PaymentButton>
      </View>
      {paymentDetails ? (
        <CreditCardDetails paymentDetails={paymentDetails} />
      ) : (
        <View row style={{ marginTop: 10 }} data-qa="myAccountPaymentNoDetailsAvailable">
          <CreditCard style={{ marginRight: 20 }} />
          <Standard variant="standardDarkGrey">
            {tnewMemberNav('payment.no', 'No payment details availabile')}
          </Standard>
        </View>
      )}
    </>
  );
};

export default CardDetails;
