import { FunctionComponent, useEffect } from 'react';
import { Modal, View, Huge, Large, Button } from '@talkspace/react-toolkit';
import MeetYourProviderWrapper from '../components/MeetYourProviderWrapper';
import SparklingCalendarV2 from '../../assets/SparklingCalendarV2';
import { useMeetYourProviderState } from '../hooks/meetYourProviderContext';
import { RouteComponentProps } from '../../core/routerLib';
import { trackBookFirstSessionPrompt } from '../../utils/analytics/events';

const titleText = 'Book Session';

const BookSessionScreen: FunctionComponent<RouteComponentProps> = ({ history }) => {
  const { roomID, therapistID } = useMeetYourProviderState();

  useEffect(() => {
    if (roomID && therapistID) {
      trackBookFirstSessionPrompt({
        roomID,
        providerID: therapistID,
        modalityNudge: 'Generic',
      });
    }
  }, [roomID, therapistID]);

  const handleOnClick = () => {
    if (!roomID) {
      return;
    }
    history.push(`/in-room-scheduling/room/${roomID}`);
  };

  return (
    <Modal isVisible titleText={titleText}>
      <MeetYourProviderWrapper titleText={titleText}>
        <View align="center" justify="space-between" style={{ maxWidth: 335, textAlign: 'center' }}>
          <SparklingCalendarV2 />
          <Huge style={{ marginTop: 20 }}>Book your first session!</Huge>
          <Large variant="largeDarkGrey" style={{ marginTop: 12 }}>
            Meeting your provider through a live session is proven to be clinically important to
            forming a meaningful bond.
          </Large>
          <Button
            dataQa="bookYourFirstSessionButton"
            disabled={!roomID}
            text="Book your first session"
            onClick={handleOnClick}
            style={{ width: '100%', marginTop: 40 }}
          />
        </View>
      </MeetYourProviderWrapper>
    </Modal>
  );
};

export default BookSessionScreen;
