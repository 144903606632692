import {
  FunctionComponent,
  useState,
  useEffect,
  useRef,
  useMemo,
  useLayoutEffect,
  SetStateAction,
  Dispatch,
} from 'react';

import * as React from 'react';
import { View, Modal, VimeoPlayer } from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import { ECMSItem } from '../entities/CMSItem';
import HelpfulTipCard from '../components/HelpfulTipCard';
import ApiHelper from '../utils/ApiHelper';

const Wrapper = styled(View)({
  flexDirection: 'row',
  overflowY: 'hidden',
  whiteSpace: 'nowrap',
  /* hide scrollbar on chrome, firefox and edge */
  WebkitScrollbar: {
    display: 'none',
  },
  MsOverflowStyle: 'none',
  scrollbarWidth: 'none',
});

const scrollToRef = (childRef: React.MutableRefObject<HTMLDivElement | null>, smooth: boolean) => {
  if (childRef.current !== null)
    childRef.current.scrollIntoView({
      inline: 'center',
      behavior: smooth ? 'smooth' : 'auto',
    });
};

const HelpfulTips: FunctionComponent<{
  cmsItems: ECMSItem[];
  clientID: number;
  setCMSItems: Dispatch<SetStateAction<ECMSItem[]>>;
  isPlayerVisible: boolean;
  setIsPlayerVisible: Dispatch<SetStateAction<boolean>>;
  isCMSItemsEmpty: boolean;
}> = ({
  cmsItems,
  clientID,
  setCMSItems,
  isPlayerVisible,
  setIsPlayerVisible,
  isCMSItemsEmpty,
}) => {
  const [videoProgress, setVideoProgress] = useState('');
  const [currentCMSItem, setCurrentCMSItem] = useState<ECMSItem | null>(null);
  const [allowScroll, setAllowScroll] = useState(true);
  const [allowSmoothScroll, setAllowSmoothScroll] = useState(false);
  const apiRef = useRef(new ApiHelper());
  const { current: api } = apiRef;
  const currentRef = useRef<HTMLDivElement | null>(null);

  const getFirstUnwatchedAfterLastWatched = useMemo(() => {
    const lastWatchedItemIndex = cmsItems
      .slice()
      .reverse()
      .findIndex((e, i) => e.isViewed && i >= 0);

    if (!isCMSItemsEmpty) {
      if (lastWatchedItemIndex === -1) {
        return cmsItems[0].id;
      }
      return cmsItems[Math.min(cmsItems.length - lastWatchedItemIndex, cmsItems.length - 1)].id;
    }
    return 0;
  }, [cmsItems, isCMSItemsEmpty]);

  useEffect(
    () => () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      apiRef.current.cancelAll();
    },
    []
  );

  useLayoutEffect(() => {
    if (allowScroll) {
      scrollToRef(currentRef, allowSmoothScroll);
    }
  }, [currentRef, cmsItems, allowScroll, allowSmoothScroll]);

  useEffect(() => {
    const markAsViewedAndReFetch = async () => {
      if (currentCMSItem) {
        await api.markCMSItemAsViewed(clientID, currentCMSItem.id);
        const fetchedCMSItems = await api.getCMSItems(clientID);
        setCMSItems(fetchedCMSItems);
        setCurrentCMSItem({ ...currentCMSItem, isViewed: true });
      }
    };
    if (Number(videoProgress) >= 0.2 && currentCMSItem && currentCMSItem.isViewed === false) {
      markAsViewedAndReFetch();
    }
  }, [api, clientID, currentCMSItem, videoProgress, setCMSItems]);

  const handleOnPress = (cmsItem) => {
    if (cmsItem.itemType === 'vimeoVideo') {
      setIsPlayerVisible(true);
      setCurrentCMSItem(cmsItem);
      setAllowScroll(false);
    }
  };

  const handleOnBackdropPress = React.useCallback(() => {
    setIsPlayerVisible(false);
    setVideoProgress('');
    setAllowScroll(true);
    setAllowSmoothScroll(true);
  }, [setIsPlayerVisible]);

  const [ariaLabel, setAriaLabel] = useState(' ');
  const setModalAriaLabel = (title: string) => {
    setAriaLabel(title);
  };
  const dialogStyle = { height: '100%', width: '100%' };
  return (
    <>
      <Modal
        isVisible={isPlayerVisible}
        onBackdropPress={handleOnBackdropPress}
        titleText={ariaLabel}
        dialogStyle={dialogStyle}
      >
        {!!isPlayerVisible && !!currentCMSItem && (
          <VimeoPlayer
            autoPlay
            title
            url={currentCMSItem.itemData.embedURL}
            onProgress={setVideoProgress}
            onClose={handleOnBackdropPress}
            setModalTitle={setModalAriaLabel}
            muted={false}
          />
        )}
      </Modal>
      <Wrapper
        style={{
          paddingLeft: 10,
          marginBottom: 29,
        }}
      >
        {cmsItems.map((item) => (
          <HelpfulTipCard
            item={item}
            onPress={() => handleOnPress(item)}
            ref={Number(item.id) === Number(getFirstUnwatchedAfterLastWatched) ? currentRef : null}
            key={item.id}
          />
        ))}
      </Wrapper>
    </>
  );
};
export default HelpfulTips;
