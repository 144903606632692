import { breakpointsValuesV1, WidthBreakpoints } from '../../hooks/useWindowWidth';

const getColumns = (windowWidthState: WidthBreakpoints) => {
  const { isSmall, isMedium } = windowWidthState;
  if (isSmall) {
    return 4;
  }
  if (isMedium) {
    return 8;
  }
  return 12;
};

const getGutterWidth = (windowWidthState: WidthBreakpoints) => {
  const { isSmall, isMedium } = windowWidthState;
  if (isSmall) {
    return 8;
  }
  if (isMedium) {
    return 16;
  }
  return 24;
};

const getGridMargin = (windowWidthState: WidthBreakpoints) => {
  const { isSmall, isMedium, isLarge, isDesktop } = windowWidthState;
  if (isSmall) {
    return 16;
  }
  if (isMedium) {
    return 24;
  }
  if (isLarge) {
    return 32;
  }
  if (isDesktop) {
    return 40;
  }
  return 40;
};

const getGridStyles = (windowWidthState: WidthBreakpoints) => {
  const columns = getColumns(windowWidthState);
  const gutterWidth = getGutterWidth(windowWidthState);
  const sideMargin = getGridMargin(windowWidthState);

  return {
    breakpoints: breakpointsValuesV1,
    containerMaxWidths: breakpointsValuesV1,
    columns,
    gutterWidth,
    sideMargin,
  };
};

export default getGridStyles;
