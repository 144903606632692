import { History } from 'history';
import { WizardScreen, useWizardActions } from 'stepWizard';
import { Screen } from '@talkspace/react-toolkit';
import { Route, Switch } from '../core/routerLib';
import useWindowWidth from '../hooks/useWindowWidth';
import ApplyRefundContainer from './containers/ApplyRefundContainer';
import RefundPolicyContainer from './containers/RefundPolicyContainer';
import SubmitSupportRequestContainer from './containers/SubmitSupportRequestContainer';
import refundWizardScheme from './refundWizardScheme';
import useOnBrowserBack from '../hooks/useOnBrowserBack';
import useNavHelpers from '../hooks/useNavHelpers';

const Routes = ({ history }: { history: History }) => {
  const { isMobile } = useWindowWidth();
  const { onExitClick } = useWizardActions();
  const { navigateToDefault } = useNavHelpers({ history });
  const { pathname } = history.location;
  const showBackButton = pathname.includes('/refund-purchase/refund-policy');

  const isNavigatingBack = useOnBrowserBack(history, () => {
    navigateToDefault();
  });

  return (
    <Switch>
      <Screen
        safeAreaVariant="setHeightAndAddTop"
        showBackButton={showBackButton}
        onCloseButtonClick={onExitClick}
        title={isMobile ? ' ' : ''}
      >
        <Route exact path="/refund-purchase/refund-policy" component={RefundPolicyContainer} />
        <Route exact path="/refund-purchase/purchase-refunded" component={ApplyRefundContainer} />
        <Route
          exact
          path="/refund-purchase/support-request-submitted"
          render={(props) => (
            <SubmitSupportRequestContainer isNavigatingBack={isNavigatingBack} {...props} />
          )}
        />
        <Route
          exact
          path={[
            '/refund-purchase/source/:source/context-id/:contextID',
            '/refund-purchase/source/:source',
          ]}
          render={() => <WizardScreen wizardScheme={refundWizardScheme} />}
        />
      </Screen>
    </Switch>
  );
};

export default Routes;
