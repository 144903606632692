import TwoFactorAuthenticationVerifyNumber from './screens/TwoFactorAuthenticationVerifyNumber';
import TwoFactorAuthenticationVerify from './screens/TwoFactorAuthenticationVerify';
import TwoFactorAuthenticationClose from './screens/TwoFactorAuthenticationClose';
import TwoFactorAuthenticationChangeNumber from './screens/TwoFactorAuthenticationChangeNumber';
import TwoFactorAuthenticationReminder from './screens/TwoFactorAuthenticationReminder';
import { Route } from '../core/routerLib';

const Routes = () => (
  <>
    <Route exact path="/2fa/reminder" component={TwoFactorAuthenticationReminder} />
    <Route
      exact
      path="/2fa/reminder/verify-number"
      component={TwoFactorAuthenticationVerifyNumber}
    />
    <Route
      exact
      path="/2fa/reminder/change-number"
      component={TwoFactorAuthenticationChangeNumber}
    />
    <Route exact path="/2fa/verify" component={TwoFactorAuthenticationVerify} />
    <Route exact path="/2fa/close" component={TwoFactorAuthenticationClose} />
  </>
);

export default Routes;
