import { Tag, spacing } from '@talkspace/react-toolkit';

const { space100 } = spacing;
interface Props {
  text: string;
  isGreyVariant?: boolean;
}

const RoomWarningStatusTag = ({ text, isGreyVariant }: Props) => (
  <Tag
    color={isGreyVariant ? 'decorativeNeutral400' : 'criticalBoldDefault'}
    text={text}
    style={{ marginTop: space100 }}
  />
);

export default RoomWarningStatusTag;
