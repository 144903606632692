import { useQuery } from 'react-query';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { getNotificationPreferencesDataQueryKey } from '../../utils/queryKeys';
import { APINotificationPreference } from '../../myAccount/reducers/marketingCommunications';

interface QueryProps {
  clientUserID: string | number;
}

interface APIResponse {
  categories: APINotificationPreference[];
}

const fetchNotificationPreferences =
  ({ clientUserID }: QueryProps) =>
  async () => {
    const {
      data: { data },
    } = await apiWrapper.get(
      `${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/communication-preferences`
    );

    return data;
  };

const useQueryNotificationPreference = ({ clientUserID }: QueryProps) =>
  useQuery<APIResponse, Error>({
    queryKey: getNotificationPreferencesDataQueryKey({ clientUserID }),
    queryFn: fetchNotificationPreferences({ clientUserID }),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(clientUserID),
  });

export default useQueryNotificationPreference;
