import { FunctionComponent, useEffect } from 'react';

import { UserAuthProvider } from '../../entities/Me';
import { withRouter, RouteComponentProps } from '../../core/routerLib';
import { useClientAuthActions } from '../../hooks/clientAuthContext';

interface B2BCancellationRouteParams {
  provider: UserAuthProvider;
}

const SSOLogout: FunctionComponent<RouteComponentProps<B2BCancellationRouteParams>> = ({
  match: {
    params: { provider },
  },
}) => {
  const { logoutAction } = useClientAuthActions();

  useEffect(() => {
    // This is a temporary workaround till cognito logout will be resolved.
    if (provider === UserAuthProvider.AETNA) logoutAction('https://www.resourcesforliving.com/');
    else logoutAction();
  }, [provider, logoutAction]);

  return <>Redirecting...</>;
};

export default withRouter(SSOLogout);
