import { FunctionComponent } from 'react';
import {
  View,
  Text,
  Spinner,
  Large,
  BaseButton,
  Button,
  HiddenText,
  RedX,
  SuccessCheckmark,
  SuccessCheckmarkV2,
  EmotionStyle,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';

const Wrapper = styled(View)(({ theme: { window: isMobile } }) => {
  return {
    marginTop: 80,
    alignSelf: 'center',
    alignItems: 'center',
    maxWidth: isMobile ? 335 : 600,
  };
});

const ResultWrapper = styled(View)({
  alignSelf: 'center',
  alignItems: 'center',
});

const CloseBaseButton = styled(BaseButton)(({ theme: { colors } }) => {
  return { marginTop: 40, marginBottom: 60, backgroundColor: colors.white };
});

const CloseButton = styled(Button)(({ theme: { colors } }) => {
  return { marginTop: 40, marginBottom: 60, backgroundColor: colors.green };
});

const SpinnerWrapper = styled(View)({
  minWidth: 63,
});

interface Props {
  isLoading: boolean;
  isError: boolean;
  errorTitle?: string;
  errorSubTitle?: string;
  successTitle?: string;
  successSubTitleHeadline?: string;
  successSubTitle?: string;
  successButtonText?: string;
  errorButtonText?: string;
  errorPrimaryButtonText?: string;
  successButtonAction?: () => void;
  errorButtonAction?: () => void;
  errorPrimaryButtonAction?: () => void;
  spinnerStyles?: EmotionStyle;
  spinnerPrimaryColor?: string;
  spinnerThickness?: number;
  titleStyles?: {};
  subTitleHeadlineStyles?: {};
  subTitleStyles?: {};
  showSuccessState?: boolean;
  successCheckMarkExist?: boolean;
  buttonStyles?: {};
  isBaseButton?: boolean;
  isV2CheckMark?: boolean;
}

const ActionStatus: FunctionComponent<Props> = ({
  isLoading,
  isError,
  errorTitle = 'Something went wrong',
  errorSubTitle,
  successTitle = 'Success!',
  successSubTitleHeadline,
  successSubTitle,
  successButtonText,
  errorButtonText = 'Close',
  errorPrimaryButtonText,
  successButtonAction,
  errorButtonAction,
  errorPrimaryButtonAction,
  spinnerStyles,
  spinnerPrimaryColor,
  spinnerThickness,
  titleStyles,
  subTitleHeadlineStyles,
  subTitleStyles,
  successCheckMarkExist = true,
  showSuccessState = true,
  buttonStyles,
  isBaseButton = true,
  isV2CheckMark = false,
}) => (
  <Wrapper>
    {(() => {
      if (isLoading)
        return (
          // TODO: replace with AnimatedSpinner component from toolkit
          <SpinnerWrapper aria-atomic="true" aria-live="polite">
            <HiddenText>Loading...</HiddenText>
            <Spinner
              primaryColor={spinnerPrimaryColor}
              style={spinnerStyles}
              thickness={spinnerThickness}
            />
          </SpinnerWrapper>
        );
      if (isError)
        return (
          <ResultWrapper>
            <RedX size={74} />
            <Text style={titleStyles}>{errorTitle}</Text>
            {errorSubTitle && <Text style={subTitleStyles}>{errorSubTitle}</Text>}
            {errorPrimaryButtonAction && (
              <CloseButton onPress={errorPrimaryButtonAction} style={buttonStyles}>
                <Large variant="largeWhite">{errorPrimaryButtonText}</Large>
              </CloseButton>
            )}
            {errorButtonAction &&
              (isBaseButton ? (
                <CloseBaseButton onPress={errorButtonAction} style={buttonStyles}>
                  <Large variant="largeBoldWideGreen">{errorButtonText}</Large>
                </CloseBaseButton>
              ) : (
                <CloseButton onPress={errorButtonAction} style={buttonStyles}>
                  <Large variant="largeBoldWhite">{errorButtonText}</Large>
                </CloseButton>
              ))}
          </ResultWrapper>
        );
      if (showSuccessState) {
        return (
          <ResultWrapper>
            {successCheckMarkExist &&
              (isV2CheckMark ? <SuccessCheckmarkV2 /> : <SuccessCheckmark />)}
            <Text style={titleStyles}>{successTitle}</Text>
            {successSubTitleHeadline && (
              <Text style={subTitleHeadlineStyles}>{successSubTitleHeadline}</Text>
            )}
            {successSubTitle && <Text style={subTitleStyles}>{successSubTitle}</Text>}
            {successButtonAction &&
              (isBaseButton ? (
                <CloseBaseButton onPress={successButtonAction}>
                  <Large variant="largeBoldWideGreen" style={buttonStyles}>
                    {successButtonText}
                  </Large>
                </CloseBaseButton>
              ) : (
                <CloseButton onPress={successButtonAction} style={buttonStyles}>
                  <Large variant="largeBoldWhite">{successButtonText}</Large>
                </CloseButton>
              ))}
          </ResultWrapper>
        );
      }
      return <></>;
    })()}
  </Wrapper>
);

export default ActionStatus;
