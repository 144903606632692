import { useCallback } from 'react';
import { Big, styled, View, TSLogo, CloseButton } from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { isTestAccount } from 'ts-frontend/helpers';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { useMainState } from '@/hooks/mainContext';
import {
  setIsOnboardingDismissed,
  setIsOnboardingDismissedTestAccount,
} from '@/onboarding/util/onboardingStorage';

const Wrapper = styled(View)(({ theme: { colors, safeAreaInsets } }) => {
  return {
    align: 'center',
    backgroundColor: colors.white,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    paddingTop: safeAreaInsets.top,
    paddingBottom: safeAreaInsets.bottom,
    zIndex: 1000,
  };
});

const NavBar = styled(View)(({ theme: { colors, spacing } }) => {
  return {
    height: 66,
    width: '100%',
    flexDirection: 'row',
    backgroundColor: colors.white,
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    borderBottom: `0.5px solid ${colors.heatherGrey}`,
    padding: `0 ${spacing('space200', true)}`,
  };
});

const Content = styled(View)({
  flex: 1,
  paddingTop: 0,
  overflowY: 'auto',
});

const CloseButtonWrapper = styled(View)(({ theme: { spacing } }) => {
  return {
    padding: `${(spacing('space100'), true)} 0`,
  };
});
const CloseButtonSpacer = styled(View)({
  width: 35,
  height: 30,
});

interface Props {
  titleText?: string;
  children: React.ReactNode;
  showLogo?: boolean;
  showCloseButton?: boolean;
  preventReopening?: boolean;
  roomID?: number | null;
}

const MeetYourProviderWrapper = ({
  titleText = '',
  children,
  showLogo,
  showCloseButton,
  preventReopening,
  roomID,
}: Props) => {
  const { brandRefreshChanges } = useFlags();
  const closeModal = useCloseModal();
  const { me } = useMainState();
  const onClose = useCallback(() => {
    if (roomID) {
      if (isTestAccount(me?.email)) {
        setIsOnboardingDismissedTestAccount(roomID);
      } else if (preventReopening) {
        setIsOnboardingDismissed(roomID);
      }
      closeModal();
    }
  }, [closeModal, roomID, me?.email, preventReopening]);

  const isClosable = showCloseButton && roomID;
  return (
    <Wrapper>
      <NavBar>
        {isClosable && <CloseButtonSpacer />}
        <View>
          {showLogo ? (
            <TSLogo variant={brandRefreshChanges ? '2024' : 'old'} />
          ) : (
            <Big>{titleText}</Big>
          )}
        </View>
        {isClosable && (
          <CloseButtonWrapper>
            <CloseButton dataQa="meetYourProviderCloseBtn" onPress={onClose} />
          </CloseButtonWrapper>
        )}
      </NavBar>
      <Content align="center">{children}</Content>
    </Wrapper>
  );
};

export default MeetYourProviderWrapper;
