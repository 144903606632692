import { useEffect } from 'react';
import useNavShellData from './useNavShellData';

import { getShouldShowEligibilityWarning } from '../EligibilityWarning/hooks/useEligibilityWarning';
import { useOpenModal } from '../utils/ModalsContextProvider';

const usePopupEligibilityWarning = () => {
  const openModal = useOpenModal();
  const { dataByRoomID } = useNavShellData();

  useEffect(() => {
    if (!dataByRoomID) {
      return;
    }
    const roomIDWithIneligibilityWarning = Object.keys(dataByRoomID).find((roomID) => {
      const { subscription } = dataByRoomID[roomID];
      if (!subscription?.insuranceStatus) return false;
      if (getShouldShowEligibilityWarning(subscription)) return true;
      return false;
    });

    if (roomIDWithIneligibilityWarning) {
      openModal(`/eligibility-warning/${roomIDWithIneligibilityWarning}`);
    }
  }, [dataByRoomID, openModal]);
};
export default usePopupEligibilityWarning;
