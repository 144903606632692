import { Auth } from '@aws-amplify/auth';

type FinishVerifyEmailParameters = {
  code: string;
};

type FinishVerifyEmailResult = {
  result: 'no-match' | 'network-error' | 'success';
};

const finishVerifyEmail = async ({
  code,
}: FinishVerifyEmailParameters): Promise<FinishVerifyEmailResult> => {
  const [username, codeProper] = code.split(':');

  try {
    await Auth.confirmSignUp(username, codeProper);

    return {
      result: 'success',
    };
  } catch (e) {
    const error = e as { code: string };

    if (error.code === 'CodeMismatchException') {
      return { result: 'no-match' };
    }

    if (error.code === 'NetworkError') {
      return { result: 'network-error' };
    }

    throw error;
  }
};

export default finishVerifyEmail;
