import { Auth } from '@aws-amplify/auth';
import { AuthError } from '@aws-amplify/auth/lib-esm/Errors';

const verifyAttributeCode = async (attribute: string, code: string): Promise<boolean> => {
  try {
    await Auth.verifyCurrentUserAttributeSubmit(attribute, code);
  } catch (err) {
    const error = err as AuthError;
    if (error.name === 'AuthError' || error.name === 'CodeMismatchException') {
      return false;
    }
    throw error;
  }
  return true;
};

export default verifyAttributeCode;
