import { useReducer, useCallback, useRef } from 'react';
import { wizardActionReducer, wizardActionInitialState, WizardActionState } from 'stepWizard';
import b2bCancellationWizardApiHelper from '../utils/b2bCancellationWizardApiHelper';

interface B2BCancellationState extends WizardActionState {}

export default function useB2BCancellationWizard(): [
  B2BCancellationState,
  {
    cancelNonSubscription: (roomID: number, clientMetGoals: boolean) => void;
    exitAction: () => void;
  }
] {
  const [state, dispatch] = useReducer(wizardActionReducer, wizardActionInitialState());

  const apiRefCancellationWizard = useRef(b2bCancellationWizardApiHelper);

  function cancelNonSubscription(roomID: number, clientMetGoals?: boolean) {
    dispatch({ type: 'requestAction' });
    apiRefCancellationWizard.current
      .cancelNonSubscription(roomID, clientMetGoals)
      .then(() => {
        dispatch({
          type: 'receiveAction',
        });
      })
      .catch((error) => {
        dispatch({
          type: 'setError',
          error: (error && error.message) || '',
        });
      });
  }

  function exitAction() {
    dispatch({ type: 'exitAction' });
  }

  return [
    state as B2BCancellationState,
    {
      cancelNonSubscription: useCallback(cancelNonSubscription, []),
      exitAction: useCallback(exitAction, []),
    },
  ];
}
