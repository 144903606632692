import { useEffect } from 'react';

import { SimpleHeader } from '@talkspace/react-toolkit';

import { useHistory, useParams } from '@/core/routerLib';
import { extractRoomEntitiesFromState } from '@/utils/extractRoomEntitiesFromState';

import { useMainState } from '../hooks/mainContext';
import useTherapistReview from '../therapistReview/hooks/useTherapistReview';
import ReviewTherapist from '../therapistReview/components/ReviewTherapist';
import LoadingScreen from '../screens/LoadingScreen';

const ReviewTherapistContainer = () => {
  const history = useHistory();
  const [state, { postTherapistReview, getTherapistInfo }] = useTherapistReview();
  const { roomID } = useParams<{ roomID: string }>();
  const mainState = useMainState();
  const { room } = extractRoomEntitiesFromState(mainState, Number(roomID));
  const onClose = () => {
    history.goBack();
  };

  useEffect(() => {
    getTherapistInfo(Number(roomID));
  }, [getTherapistInfo, roomID]);

  if (!state.isTherapistDataReady || !room) return <LoadingScreen />;

  return (
    <ReviewTherapist
      renderHeader={() => <SimpleHeader title="Leave a review" onClose={onClose} />}
      isLoading={state.isLoading}
      error={state.error}
      handleBackButtonPress={onClose}
      handleCloseButtonPress={onClose}
      therapistID={state.therapist.id || 0}
      therapistName={state.therapist.firstName || ''}
      postReview={postTherapistReview}
      room={room}
    />
  );
};

export default ReviewTherapistContainer;
