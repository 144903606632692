import { useCallback, useEffect, useState, useMemo } from 'react';

import { useHistory } from '@/core/routerLib';
import { useOpenModal } from '@/utils/ModalsContextProvider';

import ScheduleChooseProvider from '../components/ScheduleChooseProvider';
import useNavShellData from '../hooks/useNavShellData';

const ScheduleChooseProviderContainer = () => {
  const history = useHistory();
  const openModal = useOpenModal();
  const {
    dataByRoomID,
    subscriptions,
    queries: { isLoadingSubscriptions },
  } = useNavShellData();

  const bookableSubscriptions = useMemo(
    () => subscriptions?.filter((subscription) => subscription.subscription.canCreateBooking) || [],
    [subscriptions]
  );

  const [selectedRoomID, setSelectedRoomID] = useState<number>();

  const onPressContinue = useCallback(
    (roomID?: number) => () => {
      if (roomID && subscriptions?.length === 1) {
        history.push(`/in-room-scheduling/room/${roomID}`);
      } else if (roomID) {
        history.push(`/in-room-scheduling/room/${roomID}`, { selectedProvider: true });
      }
    },
    [history, subscriptions]
  );

  useEffect(() => {
    if (subscriptions && bookableSubscriptions.length) {
      setSelectedRoomID(bookableSubscriptions[0].id);
      if (bookableSubscriptions.length === 1) {
        onPressContinue(bookableSubscriptions[0].id)();
      }
    }
  }, [onPressContinue, subscriptions, bookableSubscriptions]);

  const onPressUpdatePlan = useCallback(() => {
    const reactivationRoom = subscriptions?.find((subscription) => subscription.status === 7);
    if (reactivationRoom) {
      history.push(`/room-reactivation/${reactivationRoom.id}`);
    } else {
      history.push(`/update-coverage`);
    }
  }, [subscriptions, history]);

  const onPressUpdatePayment = useCallback(
    (updateRoomID: number) => {
      openModal(`/payment-wizard/room/${updateRoomID}?source=updatePaymentMethod`);
    },
    [openModal]
  );

  return (
    <ScheduleChooseProvider
      dataByRoomID={dataByRoomID}
      subscriptions={subscriptions}
      isLoading={isLoadingSubscriptions}
      onPressContinue={onPressContinue(selectedRoomID)}
      onPressUpdatePlan={onPressUpdatePlan}
      onPressUpdatePayment={onPressUpdatePayment}
      selectedRoomID={selectedRoomID}
      setSelectedRoomID={(roomID: number) => {
        setSelectedRoomID(roomID);
      }}
    />
  );
};

export default ScheduleChooseProviderContainer;
