import { useState, useEffect, FunctionComponent } from 'react';
import { withRouter, RouteComponentProps } from '@/core/routerLib';
import CreateSessionReportModal from '../components/CreateSessionReportModal';
import ApiHelper from '../utils/ApiHelper';
import { ELIGIBILITY_RESPONSE_STATUS } from '../types';

const getSuccessText = (status) =>
  status === ELIGIBILITY_RESPONSE_STATUS.sessionAlreadyOpen
    ? 'You already have an open session with your provider.'
    : 'Great news! It seems that you are currently eligible for coverage. A new session has been opened for you to continue working with your provider.';

const CreateSessionReportModalContainer: FunctionComponent<
  RouteComponentProps<{
    roomID: string;
  }>
> = ({ match, location }) => {
  const { roomID } = match.params;
  const [eligibilityStatus, setEligibilityStatus] = useState<
    ELIGIBILITY_RESPONSE_STATUS | undefined
  >();
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [savedCaseID, setSavedCaseID] = useState<string>();

  useEffect(() => {
    const caseID = new URLSearchParams(location.search).get('caseID');
    if (caseID) {
      setSavedCaseID(caseID);
    } else {
      setIsError(true);
    }
  }, [savedCaseID, location.search]);

  useEffect(() => {
    if (savedCaseID) {
      const api = new ApiHelper();
      api
        .postCreateSessionReport(Number(roomID), Number(savedCaseID))
        .then((response) => {
          const { data } = response;
          const { status } = data;
          setEligibilityStatus(status);
          if (status === ELIGIBILITY_RESPONSE_STATUS.notEligible) {
            setIsError(true);
          } else {
            setIsSuccess(true);
          }
        })
        .catch(() => {
          setIsError(true);
        });
    }
  }, [roomID, setEligibilityStatus, savedCaseID]);

  return (
    <CreateSessionReportModal
      eligibilityStatus={eligibilityStatus}
      isSuccess={isSuccess}
      isError={isError}
      successTitle="You're covered"
      errorSubtitle="Unfortunately, it seems your eligibility under this plan could not be confirmed. Please use the “Check my coverage” tool in your account settings to see if you have additional coverage options, or reach out to our support team if you need assistance."
      errorTitle="Error"
      successSubtitle={getSuccessText(eligibilityStatus)}
      errorButtonText="Close"
      successButtonText="Close"
    />
  );
};

export default withRouter(CreateSessionReportModalContainer);
