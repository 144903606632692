import { Auth } from '@aws-amplify/auth';

const verifyAttribute = async (attribute: string): Promise<void> => {
  try {
    await Auth.verifyCurrentUserAttribute(attribute);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export default verifyAttribute;
