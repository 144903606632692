import { useEffect, useState } from 'react';

const useVideoCallMinimized = () => {
  const [isVideoCallMinimized, setIsVideoCallMinimized] = useState(false);

  useEffect(() => {
    const handleVideoCallMinimized = (
      e: CustomEvent<{
        isMinimized: boolean;
      }>
    ) => {
      setIsVideoCallMinimized(e.detail.isMinimized);
    };
    document.addEventListener('videoCallMinimized', handleVideoCallMinimized);

    return () => document.removeEventListener('videoCallMinimized', handleVideoCallMinimized);
  }, []);

  return isVideoCallMinimized;
};

export default useVideoCallMinimized;
