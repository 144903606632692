import { FunctionComponent } from 'react';
import { TouchableView, View, Big, Small } from '@talkspace/react-toolkit';
import { ReminderStatus, curriedCreditType } from 'ts-frontend/helpers/lvsReminderStatuses';
import { VideoCredit } from 'ts-frontend/types';
import styled from '@/core/styled';
import SparklingCalendar from '../../assets/SparklingCalendar';

export interface TherapistReminders {
  introSessionCompletedAt: string | null;
  introSessionNextStartTime: string | null;
  therapySessionLastCompletedAt: string | null;
  therapySessionNextStartTime: string | null;
  videoCredits?: VideoCredit[];
}

const Wrapper = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    minHeight: 73,
    backgroundColor: colors.white,
    paddingTop: 5,
    paddingBottom: 15,
  };
});

const getTitles = (
  status?: ReminderStatus,
  date?: string,
  remaining?: number,
  videoCredits?: VideoCredit[]
) => {
  const result: { title?: string; subtitle?: string } = { title: undefined, subtitle: undefined };
  const getCreditType = curriedCreditType(videoCredits);
  const therapyCreditMinute = getCreditType('therapy')?.creditMinutes;
  switch (status) {
    case ReminderStatus.INTRO_SESSION:
      result.title = "Let's book your 10min intro session";
      result.subtitle = "It's a great way for you and your provider to get to know each other.";
      break;

    case ReminderStatus.NEVER_SCHEDULED_1LVS:
      result.title = `Let's book your first ${
        therapyCreditMinute ? `${therapyCreditMinute} min` : ''
      } session`;
      result.subtitle = `Your plan includes 1 session each month -- let's be sure you book it before it expires on ${date}.`;
      break;

    case ReminderStatus.HAS_SCHEDULED_NONESCHEDULED_1LVS:
      result.title = "It's time to book another live session";
      result.subtitle = `Book your session before your monthly credit expires on ${date}.`;
      break;

    case ReminderStatus.HAS_SCHEDULED_1SCHEDULED_1LVS:
      result.title = `Next month's credit will activate on ${date}`;
      result.subtitle = 'Until then, you can schedule an additional session for $65.';
      break;

    case ReminderStatus.NEVER_SCHEDULED_NONESCHEDULED_4LVS:
      result.title = `Let’s book your first session`;
      result.subtitle = `Your plan includes multiple sessions each month`;
      break;
    case ReminderStatus.HAS_SCHEDULED_NONESCHEDULED_4LVS:
    case ReminderStatus.HAS_SCHEDULED_3SCHEDULED_4LVS:
    case ReminderStatus.HAS_SCHEDULED_XSCHEDULED_4LVS:
      result.title = `Let’s book your next session`;
      result.subtitle = `Your plan includes multiple sessions each month `;
      break;

    case ReminderStatus.NEVER_SCHEDULE_INITIAL_EVALUATION:
      result.title = 'Book your initial evaluation';
      result.subtitle = 'Take the first step and begin your psychiatric care.';
      break;

    case ReminderStatus.NEVER_SCHEDULE_FOLLOW_UP:
      result.title = 'Book your next follow-up session';
      result.subtitle = 'We recommend a session every 2-3 months to manage your treatment.';
      break;

    case ReminderStatus.NEVER_SCHEDULE_FOLLOW_UP_LONGTIME:
      result.title = "You're due for a follow-up session";
      result.subtitle = `It's been over ${remaining} months since your last session. You may be in need of medication refills.`;
      break;

    default:
      result.title = "Let's book your 10min intro session";
      result.subtitle = "It's a great way for you and your provider to get to know each other.";
      break;
  }
  return result;
};

const ContextualReminderCard: FunctionComponent<{
  status?: ReminderStatus;
  date?: string;
  remaining?: number;
  videoCredits?: VideoCredit[];
}> = ({ status, date, remaining, videoCredits }) => {
  if (status === ReminderStatus.HAS_SCHEDULED_4SCHEDULED_4LVS) {
    return null;
  }

  const { title, subtitle } = getTitles(status, date, remaining, videoCredits);

  return (
    <Wrapper>
      <View row>
        <SparklingCalendar style={{ marginRight: 25 }} />
        <View flex={1}>
          <Big style={{ marginBottom: 4 }}>{title}</Big>
          <Small>{subtitle}</Small>
        </View>
      </View>
    </Wrapper>
  );
};

export default ContextualReminderCard;
