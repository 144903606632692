import Svg, { Circle, Defs, Ellipse, Path, Rect, Stop } from 'svgs';

const CameraSparkles = () => {
  const title = 'Camera with sparkles';
  return (
    <Svg
      width="317"
      height="284"
      viewBox="0 0 317 284"
      fill="none"
      aria-label={title}
      title={title}
    >
      <Circle r="120" transform="matrix(-1 0 0 1 156 141)" fill="url(#paint0_linear_1299_38897)" />
      <Rect x="87.9999" y="73" width="127.847" height="135" fill="#6159D1" />
      <Ellipse cx="155.435" cy="135.578" rx="35.8252" ry="35.8594" fill="#FF0075" />
      <Path d="M210.929 125.383L291.009 84.953V185.5L210.929 156.32V125.383Z" fill="#6159D1" />
      <Ellipse
        cx="294.873"
        cy="135.227"
        rx="22.1273"
        ry="50.9766"
        fill="url(#paint1_linear_1299_38897)"
      />
      <Path
        d="M246 23.4563C257.825 18.1835 260.187 5.62175 259.889 0C261.952 17.0591 268.156 23.1978 271 24.1348C261 25.6856 257.706 36.0244 257.31 41C256.119 25.0265 249.274 22.6485 246 23.4563Z"
        fill="#BCCCFF"
      />
      <Path
        d="M233 266.456C244.825 261.183 247.187 248.622 246.889 243C248.952 260.059 255.156 266.198 258 267.135C248 268.686 244.706 279.024 244.31 284C243.119 268.026 236.274 265.649 233 266.456Z"
        fill="#BCCCFF"
      />
      <Path
        d="M0 96.4563C11.8254 91.1835 14.1865 78.6217 13.8889 73C15.9524 90.0591 22.1561 96.1978 25 97.1348C15 98.6856 11.7063 109.024 11.3095 114C10.119 98.0265 3.27381 95.6485 0 96.4563Z"
        fill="#BCCCFF"
      />
      <Defs>
        <linearGradient
          id="paint0_linear_1299_38897"
          x1="-1.19209e-07"
          y1="120"
          x2="240"
          y2="120"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DBF4F2" />
          <Stop offset="1" stopColor="#E3E8FB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1299_38897"
          x1="294.873"
          y1="84.25"
          x2="294.714"
          y2="154.221"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.375" stopColor="#BCCCFF" />
          <Stop offset="1" stopColor="#8EA8FD" />
        </linearGradient>
      </Defs>
    </Svg>
  );
};

export default CameraSparkles;
