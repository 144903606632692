/* eslint-disable camelcase */
import { FunctionComponent } from 'react';
import { Huge, ExtraHuge, Large, View, Button, Image } from '@talkspace/react-toolkit';
import ActionStatus from 'stepWizard/components/ActionStatus';
import styled from '@/core/styled';
import {
  image_30_day_pause,
  image_continue_with_credit,
  image_stay_tuned_with_credit,
  image_stay_tuned_without_credit,
  images_financial_aid,
} from '../../assets/CancellationWizardV2Images';
import { Flow } from '../utils/cancellationWizardTypes';

const FinalScreenWrapper = styled(View)({
  width: 320,
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'center',
  textAlign: 'center',
});
const TitleWrapper = styled(View)({
  marginBottom: 20,
});

const ImageWrapper = styled(Image)({
  width: '120%',
  margin: '20px 0',
  alignItems: 'center',
});

const LargeWrapper = styled(Large)({
  display: 'inline',
  marginBottom: 40,
});

const ButtonWrapper = styled(Button)({
  width: '100%',
});

type actionHookState = {
  error: string;
  isLoading: boolean;
  isSuccess: boolean;
};

interface Props {
  flow: Flow;
  credit?: string;
  date?: string;
  actionHookState: actionHookState;
  onClick?: () => void;
}

interface SubtitleParams {
  credit?: string;
  date?: string;
}

interface FinalScreenProps {
  image?: string;
  title: () => string | JSX.Element;
  subtitle: (subtitleParams: SubtitleParams) => string | JSX.Element;
  buttonDataQa: string;
}

const finalScreens: Record<
  Exclude<
    Flow,
    'dissatisfiedWithProvider' | 'dissatisfiedWithProviderDiscount' | 'straightToCancel'
  >,
  FinalScreenProps
> = {
  feelingBetter: {
    image: image_30_day_pause,
    title: () => (
      <ExtraHuge>
        We’ll see you again
        <br />
        in a month!
      </ExtraHuge>
    ),
    subtitle: ({ date }) => (
      <View>
        Your subscription has been paused will automatically resume on {date}.
        <br />
        <br />
        During this pause, it is a fantastic opportunity to integrate what you have learned and put
        some lessons into practice
      </View>
    ),
    buttonDataQa: 'cancellationFinalScreenFeelingBetterSubmit',
  },
  dissatisfiedWithAppDiscount: {
    image: image_stay_tuned_with_credit,
    title: () => (
      <Huge>
        Fantastic! Continue your excellent work and stay tuned for new features coming soon in the
        app!
      </Huge>
    ),
    subtitle: ({ credit, date }) => (
      <>
        Your one-time <LargeWrapper variant="largeBoldBrightGreen">{credit} credit</LargeWrapper>{' '}
        will be applied to your next payment on {date}.
      </>
    ),
    buttonDataQa: 'cancellationFinalScreenDissatisfiedWithAppDiscountSubmit',
  },
  dissatisfiedWithApp: {
    image: image_stay_tuned_without_credit,
    title: () => (
      <Huge>
        Fantastic! Continue your excellent work and stay tuned for new features coming soon in the
        app!
      </Huge>
    ),
    subtitle: () => '',
    buttonDataQa: 'cancellationFinalScreenDissatisfiedWithAppSubmit',
  },
  expensive: {
    image: images_financial_aid,
    title: () => (
      <Huge>
        Fantastic!
        <br />
        We’re glad we can make your next month more affordable.
      </Huge>
    ),
    subtitle: ({ credit, date }) => (
      <>
        Your one-time <LargeWrapper variant="largeBoldBrightGreen">{credit} credit</LargeWrapper>{' '}
        will be applied to your next payment on {date}.
      </>
    ),
    buttonDataQa: 'cancellationFinalScreenExpensiveSubmit',
  },
  other: {
    image: image_continue_with_credit,
    title: () => (
      <Huge>
        Fantastic!
        <br />
        We’re thrilled you’re continuing with Talkspace.
      </Huge>
    ),
    subtitle: ({ credit, date }) => (
      <>
        Your one-time <LargeWrapper variant="largeBoldBrightGreen">{credit} credit</LargeWrapper>{' '}
        will be applied to your next payment on {date}.
      </>
    ),
    buttonDataQa: 'cancellationFinalScreenOtherSubmit',
  },
};

const V2FinalScreen: FunctionComponent<Props> = ({
  flow,
  credit,
  actionHookState,
  onClick,
  date,
}) => {
  const { error, isLoading, isSuccess } = actionHookState;

  return error || isLoading ? (
    <ActionStatus
      isLoading={isLoading}
      isError={!!error}
      showSuccessState={isSuccess}
      errorTitle="Something Went Wrong"
      errorSubTitle="Please check your internet connection and try again."
      errorButtonText="Close"
    />
  ) : (
    <FinalScreenWrapper>
      <ImageWrapper src={finalScreens[flow].image} />
      <TitleWrapper as="h1">{finalScreens[flow].title()}</TitleWrapper>
      <LargeWrapper>{finalScreens[flow].subtitle({ credit, date })}</LargeWrapper>
      <ButtonWrapper onClick={onClick} dataQa={finalScreens[flow].buttonDataQa}>
        Done
      </ButtonWrapper>
    </FinalScreenWrapper>
  );
};

export default V2FinalScreen;
