import { TextDS, View } from '@talkspace/react-toolkit';
import styled from '@/core/styled';

const CalendarIconBox = styled(View)(
  ({
    theme: {
      colorRoles,
      window: { isMobile },
    },
  }) => {
    return {
      backgroundColor: colorRoles.surfaces.decorativeNeutral300,
      borderRadius: 10,
      width: 64,
      height: isMobile ? 72 : 64,
      mixBlendMode: 'multiply',
    };
  }
);

const CalendarIcon = ({ month, day }: { month: string; day: number | string }) => (
  <CalendarIconBox align="center" justify="center" className="calendar-icon-box">
    <View>
      <TextDS colorRole="textSubtle" variant="headingXs">
        {month.toUpperCase()}
      </TextDS>
    </View>
    <View>
      <TextDS colorRole="textSubtle" variant="headingXl">
        {day}
      </TextDS>
    </View>
  </CalendarIconBox>
);

export default CalendarIcon;
