import { Room, ERoom } from 'ts-frontend/entities/Room';

import { Subscription, InformedConsentStatusAPI } from 'ts-frontend/types';
import { ETherapistInfo, TherapistInfo } from 'ts-frontend/entities/Therapist';
import { EMessagePreview } from 'ts-frontend/entities/MessagePreview';
import { EClient, Client } from '../entities/Client';
import { EMe } from '../entities/Me';
import { ECMSItem, CMSItem } from '../entities/CMSItem';

export interface RoomsAPIResponse {
  data: ERoom[];
  included: Included;
}

export interface Included {
  therapistInfo: ETherapistInfo[];
  clients: EClient[];
  cmsItems: ECMSItem[];
  me: EMe;
}

export interface AdminConfigs {
  emailVerificationFlagIsActive?: boolean;
  isClinicalProgressEnabled?: boolean;
  isCognitoLoginEnabled?: boolean;
  treatmentIntakeInOnboardingActive?: boolean;
}

type InformedConsentStatus = InformedConsentStatusAPI & {
  isLoading: boolean;
};

export type Action =
  | { type: 'getAllRooms' }
  | {
      type: 'receiveGetAllRooms';
      payload: {
        rooms: Room[];
        therapists: TherapistInfo[];
        clients: Client[];
        cmsItems: CMSItem[];
      };
    }
  | { type: 'getLVSSubscriptions' }
  | { type: 'receiveLVSGetSubscriptions'; payload: Subscription[] }
  | { type: 'getClient' }
  | { type: 'getLVSSubscriptions' }
  | { type: 'getInformedConsentStatus' }
  | { type: 'receiveLVSGetSubscriptions'; payload: Subscription[] }
  | { type: 'receiveGetClient'; payload: Partial<State> }
  | {
      type: 'receiveGetInformedConsentStatus';
      payload: { informedConsentStatus: InformedConsentStatusAPI };
    }
  | { type: 'receiveNickname'; payload: { displayName: string } }
  | { type: 'getLastMessages' }
  | {
      type: 'setLastReadAck';
      payload: { roomID: number; lastReadAckedMessageID: number };
    }
  | {
      type: 'receiveLastMessage';
      payload: { roomID: number; lastMessage: EMessagePreview };
    }
  | {
      type: 'setDropdownMenuVisible';
      payload: Pick<State, 'isDropdownMenuVisible'>;
    }
  | {
      type: 'getAdminConfigOption';
    }
  | {
      type: 'receiveAdminConfigOption';
      payload: { adminConfigs: AdminConfigs };
    }
  | { type: 'setIsError'; error: string };

export interface RoomsByID {
  [key: string]: ERoom;
}
export interface ClientsByID {
  [key: string]: EClient;
}
export interface ClientsByRoomID {
  [key: string]: EClient[];
}
export interface TherapistsByID {
  [key: string]: ETherapistInfo;
}

export interface SubscriptionsByID {
  [key: string]: Subscription;
}

export interface State {
  roomsByID: RoomsByID;
  clientsByID: ClientsByID;
  clientsByRoomID: ClientsByRoomID;
  therapistsByID: TherapistsByID;
  subscriptionsByID: SubscriptionsByID;
  me: EMe | null;
  cmsItems: ECMSItem[];
  isDropdownMenuVisible: boolean;
  adminConfigs: AdminConfigs;
  error: string | null;
  informedConsentStatus: InformedConsentStatus;
  isSubscriptionEnabled: boolean;
}

export const initialState: State = {
  roomsByID: {},
  clientsByID: {},
  clientsByRoomID: {},
  therapistsByID: {},
  subscriptionsByID: {},
  me: null,
  cmsItems: [],
  isDropdownMenuVisible: false,
  adminConfigs: {},
  error: null,
  informedConsentStatus: {
    informedConsentID: null,
    roomID: null,
    shouldSignConsent: false,
    showProviderInfo: false,
    therapistID: null,
    isLoading: false,
  },
  isSubscriptionEnabled: false,
};

function transformGetAllRoomsPayload(rooms, therapists, clients, cmsItems, state) {
  const roomsByID = rooms.reduce((prev, next) => {
    return {
      ...prev,
      [next.roomID]: {
        ...new ERoom(next),
        lastMessage: state.roomsByID[next.roomID] && state.roomsByID[next.roomID].lastMessage,
        lastReadAckedMessageID:
          state.roomsByID[next.roomID] && state.roomsByID[next.roomID].lastReadAckedMessageID,
      },
    };
  }, {});

  const therapistsByID = therapists.reduce((prev, next) => {
    return {
      ...prev,
      [next.id]: new ETherapistInfo(next),
    };
  }, {});

  const clientsByID = clients.reduce((prev, next) => {
    return {
      ...prev,
      [next.id]: new EClient(next),
    };
  }, {});

  const clientsByRoomID = clients.reduce((acc, client) => {
    const { roomIDs } = client;
    return {
      ...acc,
      ...roomIDs.reduce((acc2, roomID) => {
        return {
          ...acc2,
          [roomID]: [...(acc[roomID] || []), client],
        };
      }, {}),
    };
  }, {});

  return {
    roomsByID,
    therapistsByID,
    clientsByID,
    clientsByRoomID,
    cmsItems: cmsItems.map((item) => new ECMSItem(item)),
  };
}

export const roomsReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'getAllRooms':
    case 'getClient':
    case 'getLastMessages':
    case 'getAdminConfigOption':
      return {
        ...state,
        error: null,
      };
    case 'getInformedConsentStatus':
      return {
        ...state,
        informedConsentStatus: { ...state.informedConsentStatus, isLoading: true },
        error: null,
      };
    case 'receiveGetAllRooms':
      return {
        ...state,
        ...transformGetAllRoomsPayload(
          action.payload.rooms,
          action.payload.therapists,
          action.payload.clients,
          action.payload.cmsItems,
          state
        ),
      };

    case 'receiveAdminConfigOption':
      return {
        ...state,
        adminConfigs: {
          ...state.adminConfigs,
          ...action.payload.adminConfigs,
        },
      };
    case 'receiveGetClient':
      return {
        ...state,
        ...action.payload,
      };
    case 'receiveGetInformedConsentStatus':
      return {
        ...state,
        informedConsentStatus: {
          ...action.payload.informedConsentStatus,
          isLoading: false,
        },
      };
    case 'receiveNickname':
      return {
        ...state,
        me: state.me ? { ...state.me, ...action.payload } : null,
      };
    case 'receiveLastMessage':
      if (!state.roomsByID[action.payload.roomID]) return state;
      if (state.roomsByID[action.payload.roomID].lastMessage?.isEqual(action.payload.lastMessage))
        return state;
      return {
        ...state,
        roomsByID: {
          ...state.roomsByID,
          [action.payload.roomID]: {
            ...state.roomsByID[action.payload.roomID],
            lastMessage: action.payload.lastMessage,
          },
        },
      };
    case 'receiveLVSGetSubscriptions':
      return {
        ...state,
        subscriptionsByID: action.payload.reduce((prev, next) => {
          return {
            ...prev,
            [next.id]: next,
          };
        }, {}),
        isSubscriptionEnabled: false,
      };
    case 'setLastReadAck':
      return {
        ...state,
        roomsByID: {
          ...state.roomsByID,
          [action.payload.roomID]: {
            ...state.roomsByID[action.payload.roomID],
            lastReadAckedMessageID: action.payload.lastReadAckedMessageID,
          },
        },
      };
    case 'setIsError':
      return {
        ...state,
        error: action.error,
      };
    case 'setDropdownMenuVisible':
      return {
        ...state,
        ...action.payload,
      };

    case 'getLVSSubscriptions':
      return {
        ...state,
        isSubscriptionEnabled: true,
      };
    default:
      return state;
  }
};
