import { Image } from '@talkspace/react-toolkit';
import VideoMessagingWorkshopsImage from '../assets/plans/VideoMessagingWorkshops.png';
import MessagingTherapyImage from '../assets/plans/MessagingTherapy.png';
import VideoMessagingTherapyImage from '../assets/plans/VideoMessagingTherapy.png';

// eslint-disable-next-line import/prefer-default-export
export const TherapyTypes = {
  messagingTherapy: {
    therapyName: 'Messaging Therapy',
    therapyImage: () => (
      <Image src={MessagingTherapyImage} height={221} width={325} alt="Messaging therapy" />
    ),
    therapyInfo: `Send and receive messages from your therapist anytime, anywhere. No scheduling necessary.`,
    therapyBullets: ['Quick and convenient', 'Daily text responses 5 days a week'],
    dataQA: 'SelectMessagingTherapy',
  },
  videoTherapy: {
    therapyName: 'Video + Messaging Therapy',
    therapyImage: () => (
      <Image src={VideoMessagingTherapyImage} height={268} width={325} alt="Video therapy" />
    ),
    therapyInfo: `Schedule live video sessions and message your therapist any time.`,
    therapyBullets: [
      '30-min video calls',
      'Flexible scheduling',
      'Message your therapist between sessions',
    ],
    dataQA: 'SelectVideoMessaging',
  },
  videoMessagingTherapy: {
    therapyName: 'Video + Messaging + Workshops',
    therapyImage: () => (
      <Image
        src={VideoMessagingWorkshopsImage}
        height={253}
        width={325}
        alt="Video + Messaging Therapy"
      />
    ),
    therapyInfo: `Get access to everything Talkspace offers, including video sessions, messaging, and expert-led workshops.`,
    therapyBullets: [
      '30-min video calls',
      'Message your therapist between sessions',
      'Attend weekly workshops',
    ],
    dataQA: 'SelectVideoMessagingWorkshops',
  },
} as const;

const FLOW_132_CONSUMER_THERAPY = 130; // Online therapy
const FLOW_140_TEEN_THERAPY = 140; // Teens therapy
const PLAN_990 = 990; // 'Messaging Therapy';
const PLAN_991 = 991; // 'Video + Messaging Therapy';
const PLAN_992 = 992; // 'Video + Messaging + Workshops';
const PLAN_995 = 995; // 'Video + Messaging + Workshops paid every 3 months';
const PLAN_998 = 998; // 'Video + Messaging + Workshops paid every 6 months';
const PLAN_1103 = 1103; // 'Video + Messaging + Workshops v4';

export const isMessagingTherapy = (planId: number): boolean => PLAN_990 === planId;
export const isVideoMessagingTherapy = (planId: number): boolean => PLAN_991 === planId;
export const isVideoMessagingWorkshopsTherapy = (planId: number): boolean =>
  [PLAN_992, PLAN_995, PLAN_998, PLAN_1103].includes(planId);

export const isValidPlanId = (planId: number): boolean =>
  [PLAN_990, PLAN_991, PLAN_992].includes(planId);

export const checkForExperimentalPlanPricing = (
  flowID: number | null,
  experimentalPlanPricing: boolean
): boolean =>
  flowID !== null &&
  [FLOW_132_CONSUMER_THERAPY, FLOW_140_TEEN_THERAPY].includes(flowID) &&
  experimentalPlanPricing;
