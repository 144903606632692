export interface PresentingProblems {
  id: number;
  value: string;
  expertiseID?: number;
}

// eslint-disable-next-line import/prefer-default-export
export class EPresentingProblems implements PresentingProblems {
  id: number;

  value: string;

  expertiseID?: number;

  constructor(PresentingProblems: PresentingProblems) {
    this.id = PresentingProblems.id;
    this.value = PresentingProblems.value;
    this.expertiseID = PresentingProblems.expertiseID;
  }
}
