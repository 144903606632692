import Routes from './Routes';
import UpdatePaymentDetailsModal from './components/UpdatePaymentDetailsModal';
import SuccessModal from './components/SuccessModal';
import ErrorModal from './components/ErrorModal';

const MyAccount = () => <Routes />;

export default MyAccount;

export { UpdatePaymentDetailsModal, SuccessModal, ErrorModal };
