import Svg, { Path, G, Defs, Rect } from 'svgs';

const DollarSignComponent = () => (
  <Svg width="84px" height="84px" viewBox="0 0 84 84" color="#758DD7">
    <Defs>
      <Rect id="b" x={0} y={0} width={70} height={70} rx={20} />
    </Defs>
    <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <G transform="translate(-13 -578) translate(20 582)">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <use fill="#FFF" xlinkHref="#b" />
      </G>
      <G fill="#8399DB" fillRule="nonzero">
        <Path
          d="M11.104 36.184a19.026 19.026 0 01-9.668-10.133c-3.927-9.711.76-20.731 10.428-24.658 9.712-3.926 20.774.76 24.7 10.43 3.8 9.415-.506 20.181-9.753 24.361-1.52.676-2.533-1.604-1.055-2.322 8.022-3.63 11.737-12.962 8.444-21.11C30.822 4.391 21.238.337 12.836 3.715 4.433 7.136.38 16.72 3.8 25.122c1.52 3.8 4.476 7.01 8.487 8.825l.38-2.027c.042-.211.337-.296.464-.084l3.125 5.404-6.25.76c-.21.042-.38-.253-.21-.422l1.308-1.394z"
          transform="translate(-13 -578) translate(20 582) translate(16 16)"
        />
        <Path
          d="M24.869 12.836c0 .59-.633 1.773-1.267 1.773-.084 0-.76-.253-1.942-.718-1.224-.464-2.196-.718-2.913-.718-1.605 0-2.407.634-2.407 1.9 0 .845.718 1.605 2.153 2.238 2.491 1.098 3.8 1.689 3.97 1.773 1.435.93 2.153 2.196 2.153 3.8 0 1.267-.338 2.323-1.056 3.167-.718.845-1.689 1.309-2.956 1.436 0 1.055.338 2.617-1.266 2.617-.634 0-1.056-.126-1.267-.337-.38-.423-.211-1.647-.211-2.196-1.056-.084-4.644-.844-4.644-2.153 0-.591.506-1.774 1.182-1.774.084 0 .76.212 1.942.676 1.224.464 2.153.676 2.913.676 1.563 0 2.323-.634 2.323-1.9 0-.93-.718-1.69-2.112-2.323-1.308-.549-2.617-1.14-3.968-1.689-1.436-.886-2.112-2.11-2.112-3.673 0-2.787 1.774-4.391 4.434-4.771 0-.38-.042-.802-.042-1.182 0-.971.464-1.436 1.35-1.436 1.056 0 1.478.38 1.478 1.436 0 .38-.042.76-.042 1.182 1.014.084 4.307.887 4.307 2.196z"
          transform="translate(-13 -578) translate(20 582) translate(16 16)"
        />
      </G>
    </G>
  </Svg>
);

export default DollarSignComponent;
