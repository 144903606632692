import { useCallback } from 'react';
import { useHistory } from '@/core/routerLib';
import { useOpenModal, OpenModal } from '@/utils/ModalsContextProvider';

const useOpenModalSafe = () => {
  const history = useHistory();
  const openModal = useOpenModal(true);
  const openModalWrapped = useCallback<OpenModal>(
    (
      url: string,
      state?: object,
      fullScreen?: boolean,
      transparent?: boolean,
      onClose?: () => void
    ) => {
      if (openModal) {
        openModal(url, state, fullScreen, transparent, onClose);
      } else {
        history.push(url, state);
      }
    },
    [openModal, history]
  );
  return openModalWrapped;
};

export default useOpenModalSafe;
