import { FunctionComponent, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useWizardState, useWizardActions, persistData, WizardActionStatus } from 'stepWizard';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { trackWizardEvent } from '@/utils/analytics/events';
import useFinancialAidWizard from '../hooks/useFinancialAidWizard';
import { RouteComponentProps } from '../../core/routerLib';

const ApplyFinancialAidContainer: FunctionComponent<RouteComponentProps<{}>> = ({ history }) => {
  const {
    isVerifyExitModalOpen,
    responses,
    clientUserID,
    eventCategory,
    discountAmountInPercent,
    eligibilityToken,
  } = useWizardState();
  const closeModal = useCloseModal();
  const [state, { applyFinancialAid, exitAction }] = useFinancialAidWizard();
  const { setState } = useWizardActions();
  const { error, isSuccess } = state;

  const handlePersist = useCallback(() => {
    persistData(
      responses,
      setState,
      'Financial aid applied',
      error,
      { financialAidApplied: Boolean(isSuccess) },
      isSuccess
    );
  }, [error, isSuccess, responses, setState]);

  const handleExitModal = () => {
    exitAction();
    closeModal();
  };

  useEffect(() => {
    if (isVerifyExitModalOpen) {
      handleExitModal();
    }
  }, [isVerifyExitModalOpen]); // eslint-disable-line

  useEffect(() => {
    // persisting the data on error or success
    const handleRequestStatus = async () => {
      if (isSuccess || error) {
        handlePersist();
      }
    };
    handleRequestStatus();
  }, [isSuccess, error]); // eslint-disable-line

  useEffect(() => {
    if (isSuccess) {
      trackWizardEvent('Financial Aid Applied', eventCategory, {
        'User ID': clientUserID,
        'Financial Aid Amount In Percent': discountAmountInPercent,
        label: 'Financial Aid Amount In Percent',
        Application: eventCategory,
        eventProperty: discountAmountInPercent,
        eventPropertyValue: 0.0,
      });
    }
  }, [isSuccess]); // eslint-disable-line

  useEffect(() => {
    applyFinancialAid(eligibilityToken);
  }, []); // eslint-disable-line

  return (
    <WizardActionStatus
      successTitle="Your financial aid has been applied."
      successButtonText="Done"
      successButtonDataQa="financialAidDoneButton"
      handleAction={handleExitModal}
      actionHookState={state}
    />
  );
};

export default withRouter(ApplyFinancialAidContainer);
