import apiWrapper from '../../core/api/apiWrapper';
import apiHelper from '../../core/api/apiHelper';
import { PreviousPrimaryTherapistAvailable } from '../roomReactivationTypes';
import ApiHelper from '../../utils/ApiHelper';
import { UserAuthProvider } from '../../entities/Me';

interface ApiResponse {
  data: {
    lastActiveRoomID: number;
    lastActiveRoomType: string;
    activeTherapists: {
      roomID: number;
      roomType: string;
      therapistID: number;
      name: string;
      license: string;
      status: string;
      title: string;
    }[];
    hasOnlyPlaceholderProviders: boolean;
    skipPayment: boolean;
  };
}

let cache: {
  lastActiveRoomID: number;
  lastActiveRoomType: string;
  previousPrimaryTherapistsAvailable: PreviousPrimaryTherapistAvailable[];
  skipPayment: boolean;
  authProvider: UserAuthProvider | undefined;
  hasOnlyPlaceholderProviders: boolean;
};

async function get(clientUserID: number, roomID?: number) {
  const api = new ApiHelper();
  let url = `${apiHelper().apiEndpoint}/v3/clients/${clientUserID}/reactivation-data`;

  if (roomID) url = `${url}?roomID=${roomID}`;

  const [
    {
      data: { data: reactivationData },
    },
    {
      data: { authProvider },
    },
  ] = await Promise.all([
    apiWrapper.get(url) as Promise<{ data: ApiResponse }>,
    api.getClient(clientUserID),
  ]);
  cache = {
    ...reactivationData,
    authProvider,
    previousPrimaryTherapistsAvailable: (reactivationData.activeTherapists || []).map(
      (therapist) => {
        return {
          ...therapist,
          firstName: therapist.name.split(' ')[0],
          lastName: therapist.name.split(' ')[1],
          therapistID: therapist.therapistID,
          therapistImage: `${apiHelper().cdnEndpoint}/images/application/therapist/440/${
            therapist.therapistID
          }.png`,
          therapistLicenses: [therapist.license],
        };
      }
    ),
  };
}

const ReadCache = (clientUserID: number, roomID?: number) => {
  if (cache) {
    return cache;
  }

  throw get(clientUserID, roomID);
};

const previousPrimaryTherapistsAvailable = { ReadCache, get };

export default previousPrimaryTherapistsAvailable;
