import { FunctionComponent, useState } from 'react';
import {
  Button,
  Big,
  Large,
  View,
  Collapsible,
  withHover,
  TabRadioGroup,
  useEmotionTheme,
  WithHoverProps,
} from '@talkspace/react-toolkit';
import { PlanData } from 'ts-frontend/types';
import { formatCurrency } from 'ts-frontend/helpers/billingUtils';
import { PaymentCardWrapper, PlanDescription } from 'checkout';
import styled, { EmotionStyle } from '@/core/styled';
import Badge from './Badge';
import PlanIcon from './PlanIcon';
import BillingFrequency, { BillingFrequencyVariant } from './BillingFrequency';
import PlanOfferPrice from './PlanOfferPrice';
import { ID_PAYMENT_CARD_WRAPPER } from '@/utils/IDConstants';
import { RoomOfferState } from '../../types';

interface Props extends WithHoverProps {
  billingFrequencies: PlanData[];
  plansMatrixDisplayReady: RoomOfferState['plansMatrixDisplayReady'];
  style?: EmotionStyle;
  disabled?: boolean;
  isExpanded: boolean;
  currentPlanID?: number;
  onPlanSelected?: (plan: PlanData, monthlyPlan: PlanData) => void;
  setExpandedPlan?: () => void;
  className?: string;
}

const BillingFrequencyWrapper = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;
  return {
    width: isMobile ? '100%' : 395,
  };
});

const StyledBillingFrequency = styled(BillingFrequency)(({ theme: { window }, variant }) => {
  if (variant !== 'horizontal') return {};

  const { isMobile } = window;
  return {
    '&:not(:first-of-type)': {
      paddingLeft: isMobile ? 23 : 35,
    },
    '&:not(:last-child)': {
      borderRight: '1px solid #E3E9ED',
      paddingRight: isMobile ? 23 : 35,
    },
  };
});

const SizedPlanIcon = styled(PlanIcon)<{ hasBadge: boolean }>(({ hasBadge }) => {
  return {
    marginTop: hasBadge ? 16 : 2,
    marginRight: 5,
    width: 67,
    height: 67,
  };
});

const ContinueButton = styled(Button)<{ canPickSelectedFrequency: boolean }>(
  ({ canPickSelectedFrequency, theme: { colors } }) => {
    return {
      width: '100%',
      maxWidth: 345,
      marginBottom: 10,
      backgroundColor: colors.green,
      visibility: canPickSelectedFrequency ? undefined : 'hidden',
    };
  }
);

const StyledPaymentCardWrapper = styled(PaymentCardWrapper)<{
  badge?: string;
  isHovering: boolean;
}>(({ badge, isHovering }) => {
  return {
    boxShadow: badge || isHovering ? '0px 5px 18px rgba(35, 60, 81, 0.25)' : 'none',
  };
});

function composeSavingString(currPlan: PlanData): string {
  const saving = currPlan.savings || 0;
  return saving === 0 ? '' : `Save ${formatCurrency(saving, currPlan.billingPrice.currency)}`;
}

const billingFrequencyVariant: BillingFrequencyVariant = 'vertical';

const Plan: FunctionComponent<Props> = ({
  billingFrequencies,
  plansMatrixDisplayReady,
  currentPlanID,
  style,
  disabled,
  isExpanded,
  setExpandedPlan,
  onPlanSelected,
  isHovering,
  className,
}) => {
  const { colors } = useEmotionTheme();

  const isOnePlan = billingFrequencies.length === 1;

  const [selectedBillingFrequencyIndex, setSelectedBillingFrequencyIndex] = useState<number>(0);

  // each frequency within the plan has this same data so we can just destruct from the first frequency
  const [{ displayName, badge, offerPrice, icon, description }] = billingFrequencies;

  const badgeToUse = billingFrequencies.some(({ id }) => id === currentPlanID)
    ? 'Your current plan'
    : badge;

  const canPickSelectedFrequency =
    billingFrequencies[selectedBillingFrequencyIndex].id !== currentPlanID;

  const isSelectable = !!onPlanSelected;

  const expandOrSelectPlan = (): void => {
    if (!plansMatrixDisplayReady) return;
    if (!isSelectable) return;
    // if there are no billing frequency options, we select the only plan
    if (isOnePlan) {
      onPlanSelected?.(billingFrequencies[0], billingFrequencies[0]);
    } else {
      setExpandedPlan?.();
    }
  };

  const ID_BILLING_OPTIONS = 'ID_BILLING_OPTIONS';

  return (
    <StyledPaymentCardWrapper
      role="radio"
      className="plan-card"
      id={ID_PAYMENT_CARD_WRAPPER}
      aria-controls={ID_BILLING_OPTIONS}
      aria-checked={isExpanded}
      tabIndex={isExpanded ? 0 : -1}
      touchable={!isExpanded}
      badge={badge}
      isHovering={isHovering}
      highlighted={isHovering && isSelectable}
      style={style}
      disabled={disabled}
      roundedFocusStyle
      primaryColor={colors.green}
      onPress={expandOrSelectPlan}
    >
      {badgeToUse && <Badge text={badgeToUse} />}

      <View row>
        <View style={{ flexGrow: 1, flexShrink: 1 }}>
          {offerPrice && <PlanOfferPrice offerPrice={offerPrice} style={{ marginBottom: 15 }} />}
          <View row>
            <Big variant="bigDarkGreen" style={{ marginBottom: 6 }} dataQa="plan-name">
              {displayName}
            </Big>
            {isOnePlan && disabled && (
              <Big variant="bigDarkGrey" style={{ color: '#133FAB', marginLeft: 6 }}>
                - Your plan
              </Big>
            )}
          </View>
        </View>
        {icon && <SizedPlanIcon icon={icon} hasBadge={!!badge} />}
      </View>
      <PlanDescription description={description} />
      <View
        row
        align="center"
        alignSelf="end"
        style={{
          marginTop: isExpanded ? 10 : 14,
          display: isExpanded || !isSelectable ? 'none' : 'block',
        }}
      >
        {isOnePlan && disabled ? (
          <Large variant="largeBoldWide" style={{ color: colors.periwinkleGrey }}>
            Select plan
          </Large>
        ) : (
          <Large variant="largeBoldWideGreen">Select plan</Large>
        )}
      </View>
      <Collapsible isCollapsed={!isExpanded}>
        {billingFrequencyVariant !== 'vertical' && (
          <Large variant="largeDarkGrey" style={{ marginBottom: 28 }}>
            <Large inline variant="largeDarkGrey" style={{ fontWeight: 500 }}>
              Save up to 20%
            </Large>{' '}
            with one of the billing options below.
          </Large>
        )}

        <View align="center" style={{ marginTop: 20 }}>
          <BillingFrequencyWrapper row={billingFrequencyVariant !== 'vertical'} justify="center">
            {/* Display header text for OOP experiment */}
            {!!billingFrequencies.find((el) => el.oopPromoExperiment) && (
              <Large
                variant="largeBoldWide"
                style={{
                  marginTop: 8,
                  marginBottom: 12,
                }}
              >
                Choose a billing cycle
              </Large>
            )}

            <TabRadioGroup initialSelection legendText="Choose a billing frequency option">
              {billingFrequencies.map((plan: PlanData, i: number) => (
                <StyledBillingFrequency
                  key={plan.id}
                  planExpanded={isExpanded}
                  isSelected={selectedBillingFrequencyIndex === i}
                  plan={plan}
                  onPress={() => setSelectedBillingFrequencyIndex(i)}
                  variant={billingFrequencyVariant}
                  isCurrentPlan={plan.id === currentPlanID}
                  savingString={composeSavingString(plan)}
                  oopPromoExperiment={plan.oopPromoExperiment}
                />
              ))}
            </TabRadioGroup>
          </BillingFrequencyWrapper>

          <ContinueButton
            onPress={(e) => {
              onPlanSelected?.(
                billingFrequencies[selectedBillingFrequencyIndex],
                billingFrequencies[0]
              );
              // prevent propagation press on expandOrSelectPlan
              e.stopPropagation();
            }}
            text="Continue"
            roundedFocusStyle
            primaryColor={colors.green}
            tabIndex={isExpanded && canPickSelectedFrequency ? 0 : -1}
            canPickSelectedFrequency={canPickSelectedFrequency}
            aria-hidden={canPickSelectedFrequency ? 'false' : 'true'}
            style={{ fontWeight: 700 }}
            dataQa="SelectPlanContinueButton"
          />
        </View>
      </Collapsible>
    </StyledPaymentCardWrapper>
  );
};

export default withHover(Plan);
