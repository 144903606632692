import {
  Large,
  Massive,
  View,
  Button,
  Link,
  CheckMark,
  Big,
  Small,
  useEmotionTheme,
  A11Y_COLORS,
} from '@talkspace/react-toolkit';
import { FunctionComponent, useEffect } from 'react';
import { checkEmailValidity, routePromise } from 'ts-frontend/helpers';
import { Trans, useTranslation } from '@talkspace/i18n';
import { withRouter, RouteComponentProps } from '../../core/routerLib';
import EmailEnvelope from '../assets/EmailEnvelope';
import useEmailVerification from '../hooks/useEmailVerification';
import getParamByName from '../../utils/queryString';
import { useClientAuthState } from '../../hooks/clientAuthContext';
import { storeEmailVerificationLastDisplay } from '../../auth/helpers/token';

const EmailSent: FunctionComponent<
  RouteComponentProps & {
    email: string;
    roomID: number;
    clickFromRoomBanner: boolean;
  }
> = ({ email, roomID, clickFromRoomBanner }) => {
  let userEmail = email || getParamByName('email') || '';

  const token = getParamByName('token');

  const {
    adminConfigs: { isCognitoLoginEnabled },
  } = useClientAuthState();
  if (!checkEmailValidity(userEmail)) userEmail = 'Invalid Email';
  const [{ emailSent, isLoading }, { sendEmailVerificationAction, sendCognitoUserConfirmation }] =
    useEmailVerification();
  const isFromProvider = sessionStorage.getItem('oauthQuery') !== null;
  const { colors } = useEmotionTheme();

  useEffect(() => {
    if (clickFromRoomBanner) {
      storeEmailVerificationLastDisplay();
    }
  }, [clickFromRoomBanner]);
  const { t: tEmailVerification } = useTranslation('emailVerification');
  return (
    <View justify="center" align="center" style={{ paddingTop: 68, textAlign: 'center' }}>
      <View justify="center" align="center" style={{ maxWidth: '85%' }}>
        <EmailEnvelope />
        <Massive style={{ paddingTop: 10 }}>
          {tEmailVerification('emailVerification.title', 'Verification email sent')}
        </Massive>
        <Trans>
          <Large variant="largeDarkGrey" style={{ paddingTop: 20, color: A11Y_COLORS.slateGrey }}>
            {tEmailVerification('emailVerification.description', 'Verification email sent')}
          </Large>
          <Large
            variant="largeDarkGrey"
            style={{ fontWeight: 'bold', color: A11Y_COLORS.slateGrey }}
          >
            {userEmail}
          </Large>
        </Trans>
        {!isFromProvider && (
          <Large variant="largeDarkGrey" style={{ paddingTop: 20, color: A11Y_COLORS.slateGrey }}>
            {tEmailVerification(
              'emailVerification.pleaseFollow',
              "Please follow the instructions in the email to continue. Check your spam and promotion box in case you can't find it in your box."
            )}
          </Large>
        )}
        {isFromProvider && (
          <Large variant="largeDarkGrey" style={{ paddingTop: 20, color: A11Y_COLORS.slateGrey }}>
            {tEmailVerification(
              'emailVerification.please',
              'Please follow the instructions in the email to verify your account.'
            )}
          </Large>
        )}
        {!isFromProvider && (
          <Button
            style={{
              fontWeight: 'bold',
              marginTop: 60,
              backgroundColor:
                isLoading || emailSent ? A11Y_COLORS.extraLightGrey : A11Y_COLORS.green,
            }}
            disabled={isLoading || emailSent}
            isLoading={isLoading}
            onPress={() =>
              isCognitoLoginEnabled
                ? sendCognitoUserConfirmation(userEmail)
                : sendEmailVerificationAction(userEmail)
            }
          >
            {emailSent ? (
              <Big variant="bigWide">
                <CheckMark color={`${colors.white}`} style={{ marginRight: 11 }} />
                {tEmailVerification('emailVerification.resent', 'Email resent')}
              </Big>
            ) : (
              <Big variant="bigWide">
                {tEmailVerification('emailVerification.resend', 'Resend email')}
              </Big>
            )}
          </Button>
        )}
        {isFromProvider && (
          <Button
            style={{
              fontWeight: 'bold',
              marginTop: 60,
              backgroundColor: isLoading || A11Y_COLORS.green,
            }}
            disabled={isLoading}
            isLoading={isLoading}
            onPress={() => routePromise(`/oauth${sessionStorage.getItem('oauthQuery')}`)}
          >
            <Big variant="bigWide">Complete Alexa account linking</Big>
          </Button>
        )}
        {isFromProvider && (
          <Button
            style={{
              fontWeight: 'bold',
              backgroundColor: 'none',
              color: colors.green,
            }}
            isSecondary
            disabled={isLoading || emailSent}
            isLoading={isLoading}
            onPress={() =>
              isCognitoLoginEnabled
                ? sendCognitoUserConfirmation(userEmail)
                : sendEmailVerificationAction(userEmail)
            }
          >
            {emailSent ? (
              <Big variant="bigDarkGreen">
                {tEmailVerification('emailVerification.resent', 'Email resent')}
              </Big>
            ) : (
              <Big variant="bigDarkGreen">
                {tEmailVerification('emailVerification.resend', 'Resend email')}
              </Big>
            )}
          </Button>
        )}
        {(!clickFromRoomBanner || emailSent) && token && userEmail && (
          <Large variant="largeDarkGrey" style={{ marginTop: 20 }}>
            {tEmailVerification('emailVerification.typo', 'Typo in your email?')}{' '}
            <Link
              href={`/email-verification/change-email?email=${userEmail}&token=${token}`}
              style={{ textDecoration: 'none', fontWeight: 'bold', color: A11Y_COLORS.greenText }}
              text={tEmailVerification('emailVerification.update', 'Update email')}
              dataQa="update-email-link"
            />
          </Large>
        )}
        {(!clickFromRoomBanner || emailSent) && (
          <Small style={{ paddingTop: 20, position: 'absolute', bottom: 30 }}>
            {tEmailVerification('emailVerification.need', 'Need help?')}{' '}
            <Link
              href="https://help.talkspace.com/hc/en-us"
              text={tEmailVerification('emailVerification.contact', 'Contact support')}
              target="_blank"
              style={{
                textDecoration: 'none',
                fontWeight: 'bold',
                color: A11Y_COLORS.greenText,
                fontSize: 14,
              }}
            />
          </Small>
        )}

        {!emailSent && clickFromRoomBanner && (
          <Link
            href={`/room/${roomID}/my-account/account-details`}
            text={tEmailVerification('emailVerification.use', 'Use a different email')}
            style={{
              textDecoration: 'none',
              fontWeight: 'bold',
              color: A11Y_COLORS.greenText,
              fontSize: 14,
              paddingTop: 20,
            }}
          />
        )}
      </View>
    </View>
  );
};

export default withRouter(EmailSent);
