import { Large, Massive, View, A11Y_COLORS } from '@talkspace/react-toolkit';
import { checkEmailValidity } from 'ts-frontend/helpers';
import EmailEnvelope from '../assets/EmailEnvelope';
import getParamByName from '../../utils/queryString';

const LinkExpiredComponent = () => {
  let email = getParamByName('email') || '';
  if (!checkEmailValidity(email)) email = 'Invalid Email';

  return (
    <View justify="center" align="center" style={{ paddingTop: 68, textAlign: 'center' }}>
      <EmailEnvelope />
      <Massive style={{ paddingTop: 20 }}>Your verification link has expired</Massive>
      <Large variant="largeDarkGrey" style={{ paddingTop: 20, color: A11Y_COLORS.slateGrey }}>
        We just sent a new verification email to
      </Large>
      <Large variant="largeDarkGrey" style={{ fontWeight: 'bold', color: A11Y_COLORS.slateGrey }}>
        {email}
      </Large>
      <Large
        variant="largeDarkGrey"
        style={{ paddingTop: 20, paddingLeft: 30, paddingRight: 30, color: A11Y_COLORS.slateGrey }}
      >
        Please click the link in the email to verify your email address.
      </Large>
    </View>
  );
};

export default LinkExpiredComponent;
