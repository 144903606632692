type offersAccessTokenResponse = {
  token?: string;
};

const getOffersAccessToken = async (): Promise<offersAccessTokenResponse> => {
  // eslint-disable-next-line no-console
  console.log('Should not run this code in client web');
  return {};
};

const accessTokenHelper = {
  getOffersAccessToken,
};

export default accessTokenHelper;
