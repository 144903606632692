import { useNewMemberNav } from 'launchDarkly/hooks';
import { Route, Switch, Redirect } from '../core/routerLib';
import MarketingCommunications from './screens/MarketingCommunications';
import AccountDetailsContainer from './containers/AccountDetails';
import ManageSubscriptionContainer from './containers/ManageSubscription';
import NotificationsContainer from './containers/Notifications';
import PersonalInformationContainer from './containers/PersonalInformation';
import ModalsProvider from '../utils/ModalsContextProvider';
import { MainContextProvider } from '../hooks/mainContext';
import ModalRoutes from '../ModalRoutes';
import ReactFrameService from '../auth/reactFrame/ReactFrameService';

function closePopup() {
  if (ReactFrameService.instance().isInFrame()) {
    ReactFrameService.instance().closePopup();
  }
}

const Routes = () => {
  const useNewNav = useNewMemberNav();
  return (
    <MainContextProvider>
      <ModalsProvider ModalRoutes={ModalRoutes}>
        <Switch>
          <Route path="/my-account/marketing-unsubscribe" component={MarketingCommunications} />
          <Route
            exact
            path="/my-account"
            render={(props) => {
              if (useNewNav) {
                props.history.replace('/home/account/manage-subscription');
              }
              props.history.replace('/my-account/manage-subscription');
              return null;
            }}
          />
          <Route
            exact
            path="/my-account/personal-information"
            render={(routeProps) =>
              useNewNav ? (
                <Redirect to={`/home/account/personal-information${routeProps.location.search}`} />
              ) : (
                <PersonalInformationContainer
                  onCloseButtonPress={closePopup}
                  onBackButtonPress={closePopup}
                />
              )
            }
          />
          <Route
            exact
            path="/my-account/account-details"
            render={(routeProps) =>
              useNewNav ? (
                <Redirect to={`/home/account/account-details${routeProps.location.search}`} />
              ) : (
                <AccountDetailsContainer
                  onCloseButtonPress={closePopup}
                  onBackButtonPress={closePopup}
                />
              )
            }
          />
          <Route
            path="/my-account/manage-subscription"
            render={(routeProps) =>
              useNewNav ? (
                <Redirect to={`/home/account/manage-subscription${routeProps.location.search}`} />
              ) : (
                <ManageSubscriptionContainer
                  onCloseButtonPress={closePopup}
                  onBackButtonPress={closePopup}
                />
              )
            }
          />
          <Route
            path="/my-account/notifications"
            render={(routeProps) =>
              useNewNav ? (
                <Redirect to={`/home/account/notifications${routeProps.location.search}`} />
              ) : (
                <NotificationsContainer
                  onCloseButtonPress={closePopup}
                  onBackButtonPress={closePopup}
                />
              )
            }
          />
        </Switch>
      </ModalsProvider>
    </MainContextProvider>
  );
};

export default Routes;
