import { FunctionComponent, useState } from 'react';
import {
  Tiny,
  View,
  Large,
  Big,
  ClinicalProgressLineChart,
  ClinicalProgressGaugeChart,
  BaseButton,
  Standard,
  COLORS,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { SurveyResult } from 'ts-frontend/types';
import styled from '../../../core/styled';
import { useOpenModal } from '../../../utils/ModalsContextProvider';
import { EmotionStyle } from '../../../core/styled/styled';
import ArrowUpRight from '../icons/ArrowUpRight';
import ArrowDownRight from '../icons/ArrowDownRight';

interface SymptomTrackerProps {
  surveyResults: SurveyResult[];
  style?: EmotionStyle;
}

const CHART_WIDTH = 260;

const Wrapper = styled(View)(({ theme: { window, colors } }) => {
  const { isDesktop } = window;
  return {
    width: isDesktop ? 'unset' : 267,
    borderRadius: 10,
    border: `.5px solid ${colors.periwinkleGrey}`,
    paddingLeft: 25,
    paddingTop: 30,
    paddingRight: isDesktop ? 30 : 25,
    paddingBottom: 20,
    justifyContent: 'center',
    marginTop: 15,
    marginLeft: isDesktop ? 24 : 0,
    marginRight: isDesktop ? 30 : 0,
  };
});

const ScrollSpacingWrapper = styled(View)(({ theme: { window } }) => {
  const { isDesktop } = window;
  return {
    paddingRight: isDesktop ? 0 : 15,
  };
});

const ResponsiveToggle = styled(View)(({ theme: { window } }) => {
  const { isDesktop } = window;
  return {
    flexDirection: isDesktop ? 'row' : 'column-reverse',
  };
});

const SurveyLabel = styled(Big)(({ theme: { window } }) => {
  const { isDesktop } = window;
  return {
    marginBottom: 19,
    maxWidth: isDesktop ? 'unset' : '77%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
  };
});

const ScoreRangeLabel = styled(Large)(({ theme: { colors } }) => {
  return {
    width: '77%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: colors.baliHaiGrey,
  };
});

const LeftOrMobileBottom = styled(View)<{ isShowingGaugeChart: boolean }>(
  ({ theme: { window }, isShowingGaugeChart }) => {
    // This component holds the chart and unfortunately must adjust to the
    // size of either chart responsively. Numbers are somewhat arbitrary :|
    const { isDesktop } = window;
    if (isShowingGaugeChart) {
      return {
        marginRight: isDesktop ? 72 : 0,
        marginLeft: isDesktop ? -20 : 0,
        marginTop: isDesktop ? -20 : 0,
      };
    } // else line chart
    return {
      marginRight: isDesktop ? 95 : 0,
      marginLeft: -23,
      marginTop: -20,
    };
  }
);

const RightOrMobileTop = styled(View)({ flex: 1 });

const ButtonsWrapper = styled(View)(({ theme: { window } }) => {
  const { isDesktop } = window;
  return {
    justifyContent: isDesktop ? 'flex-start' : 'space-between',
    alignItems: 'center',
    marginTop: 20,
  };
});

const ChartButtonsWrapper = styled(View)(({ theme: { window } }) => {
  const { isDesktop } = window;
  return {
    width: isDesktop ? CHART_WIDTH : 'unset',
    marginRight: isDesktop ? 72 : 0,
  };
});

const ScoreChangeWrapper = styled(View)<{ isHidden: boolean }>(
  ({ theme: { window }, isHidden }) => {
    const { isDesktop } = window;
    return {
      display: isHidden ? 'none' : 'flex',
      position: 'relative',
      top: isDesktop ? 0 : -40,
    };
  }
);

const ChartButton = styled(BaseButton)<{ isActive?: boolean }>(
  ({ isActive, theme: { colors } }) => {
    return {
      width: 65,
      height: 31,
      borderRadius: 5,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: isActive ? colors.a11yLinkWaterGrey : 'transparent',
    };
  }
);

const SymptomTracker: FunctionComponent<SymptomTrackerProps> = ({ surveyResults, style }) => {
  const { colors } = useEmotionTheme();
  const [
    {
      maximum,
      surveyLabel,
      assessmentLabel,
      scoreChangePercent,
      learnMoreText,
      score,
      scoreRanges,
      scaleRangeLabel,
      progressSummary,
      isScoreReversed,
    },
  ] = surveyResults;

  const [showAll, setShowAll] = useState<boolean>(false);

  const allSurveyScores = surveyResults.map(({ completedAt, score: s }) => {
    return {
      score: s,
      date: completedAt,
    };
  });
  // survey scores are newer to older, chart is older to newer, left2right
  const surveyScores = showAll
    ? allSurveyScores.slice().reverse()
    : allSurveyScores.slice(0, 4).reverse();

  const isScoreIncrease = scoreChangePercent && scoreChangePercent >= 0;
  const arrowColor = isScoreIncrease === isScoreReversed ? colors.green : colors.pink;
  const isShowingGaugeChart = allSurveyScores.length === 1;
  const isShowingChartButtons = allSurveyScores.length > 4;

  const openModal = useOpenModal();

  const openMoreInfoModal = () => {
    openModal(
      '/survey-more-info',
      {
        moreInfoText: learnMoreText,
        assessmentLabel,
      },
      false,
      true
    );
  };

  return (
    // this other wrapper creates extra space at the end of each item because
    // margins at the end of a horizontal scroll do not preserve space
    <ScrollSpacingWrapper>
      <Wrapper style={style}>
        <SurveyLabel>{surveyLabel}</SurveyLabel>
        <ResponsiveToggle>
          <LeftOrMobileBottom isShowingGaugeChart={isShowingGaugeChart}>
            {isShowingGaugeChart ? (
              <ClinicalProgressGaugeChart
                score={score}
                max={maximum}
                shouldRenderLabels={false}
                scoreRanges={scoreRanges}
              />
            ) : (
              <ClinicalProgressLineChart surveyScores={surveyScores} max={maximum} />
            )}
          </LeftOrMobileBottom>
          <RightOrMobileTop>
            <View row justify="space-between" align="center" style={{ marginBottom: 8 }}>
              <ScoreRangeLabel variant="largeBoldWide">{scaleRangeLabel}</ScoreRangeLabel>
              <ScoreChangeWrapper
                row
                align="center"
                isHidden={!scoreChangePercent && scoreChangePercent !== 0}
              >
                {isScoreIncrease ? (
                  <ArrowUpRight color={arrowColor} />
                ) : (
                  <ArrowDownRight color={arrowColor} />
                )}
                <Standard
                  style={{
                    color: arrowColor,
                    marginLeft: 3,
                  }}
                >
                  {scoreChangePercent}%
                </Standard>
              </ScoreChangeWrapper>
            </View>
            <Large>{progressSummary}</Large>
          </RightOrMobileTop>
        </ResponsiveToggle>
        <ButtonsWrapper row>
          <ChartButtonsWrapper row>
            {isShowingChartButtons && (
              <>
                <ChartButton
                  isActive={!showAll}
                  style={{ marginLeft: -12 }}
                  onPress={() => setShowAll(false)}
                >
                  <Tiny style={{ fontWeight: 500, color: COLORS.black }}>Recent</Tiny>
                </ChartButton>
                <ChartButton isActive={showAll} onPress={() => setShowAll(true)}>
                  <Tiny style={{ fontWeight: 500, color: COLORS.black }}>All</Tiny>
                </ChartButton>
              </>
            )}
          </ChartButtonsWrapper>
          {learnMoreText && (
            <BaseButton style={{ height: 31 }} onPress={openMoreInfoModal}>
              <Large variant="largeBoldWideGreen">More info</Large>
            </BaseButton>
          )}
        </ButtonsWrapper>
      </Wrapper>
    </ScrollSpacingWrapper>
  );
};

export default SymptomTracker;
