import { useQuery } from 'react-query';

import { EmergencyContactData } from 'ts-frontend/types';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { emergencyContactQueryKey } from '../utils/queryKeys';

interface QueryProps {
  userID: number;
}

type QueryData = EmergencyContactData;

const fetchEmergencyContact =
  ({ userID }: QueryProps) =>
  async () => {
    const {
      data: { data },
    } = await apiWrapper.get(`${apiHelper().apiEndpoint}/v3/users/${userID}/emergency-contact`);
    return data;
  };

const useQueryEmergencyContact = ({ userID }: QueryProps) =>
  useQuery<QueryData, Error>({
    queryKey: emergencyContactQueryKey({ userID }),
    queryFn: fetchEmergencyContact({ userID }),
    cacheTime: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(userID),
  });

export default useQueryEmergencyContact;
