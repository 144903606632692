import camelCase from 'lodash/camelCase';

import { Large, Standard, View } from '@talkspace/react-toolkit';
import { EmotionStyle } from '@/core/styled';

interface Props {
  title: string;
  value: string | number | null;
  wrapperStyle?: EmotionStyle;
}
const PersonalInformationDataRow = ({ title, value, wrapperStyle }: Props) => (
  <View style={wrapperStyle}>
    <Standard>{title}</Standard>
    <Large variant="largeDarkGrey" dataQa={camelCase(`${title} value`)}>
      {value || '-'}
    </Large>
  </View>
);

export default PersonalInformationDataRow;
