import { useEffect, useState, useRef, useCallback } from 'react';
import {
  Large,
  Modal,
  View,
  useEmotionTheme,
  BaseButton,
  Button,
  VerificationCodeInput,
  Link,
  useUniqueID,
  Spinner,
} from '@talkspace/react-toolkit';
import { useHistory } from 'core/routerLib';
import { routePromise } from 'ts-frontend/helpers';
import TwoFactorAuthenticationWrapper from '../containers/TwoFactorAuthenticationWrapper';
import { useClientAuthActions, useClientAuthState } from '../../hooks/clientAuthContext';
import useMutationResendOTP, { ResendOTPResponse } from '../hooks/useMutationResendOTP';
import useMutationRevokeOTP from '../hooks/useMutationRevokeOTP';
import { trackClient2FA, trackClient2FAVerification } from '../utils/analytics';
import useSend2FARequestWithTimeout from '../hooks/useSend2FARequestWithTimeout';
import { VERIFICATION_CODE_LENGTH } from '../utils/helpers';

const spinnerStyles = {
  width: 68,
  height: 68,
  marginTop: 10,
};

const TwoFactorAuthenticationVerify = () => {
  const { colors } = useEmotionTheme();
  const history = useHistory();
  const contactSupportID = useUniqueID('contactSupportID');
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [sendToEmail, setSendToEmail] = useState<boolean>(false);
  const {
    isUpdating,
    showVerificationCodeError,
    twoFAStatus,
    otpToken,
    phoneNumberLast2Digits,
    showCodeExpiredError,
    enabled2faSuccess,
  } = useClientAuthState();
  const {
    clearVerificationCodeErrorAction,
    update2faReminderAction,
    verifyLoginAction,
    setOTPTokenAction,
    setExpiredCodeErrorAction,
    clearOTPTokenAction,
  } = useClientAuthActions();

  const { mutate: resendOTP, isLoading: isResendLoading } = useMutationResendOTP();
  const { mutate: revokeOTP } = useMutationRevokeOTP();

  const isLoading = isUpdating || isResendLoading;
  const isError = showVerificationCodeError || showCodeExpiredError;

  const close2faTimeout = useRef<undefined | ReturnType<typeof setTimeout>>();

  const clearErrors = useCallback(() => {
    setExpiredCodeErrorAction(false);
    clearVerificationCodeErrorAction();
  }, [setExpiredCodeErrorAction, clearVerificationCodeErrorAction]);

  const handleOnPress = useCallback(() => {
    trackClient2FA('Continue code verification');
    verifyLoginAction({ otpKey: Number(verificationCode), otpToken });
  }, [verifyLoginAction, otpToken, verificationCode]);

  useSend2FARequestWithTimeout(isError, isLoading, verificationCode, handleOnPress);

  useEffect(() => {
    if (showCodeExpiredError) {
      close2faTimeout.current = setTimeout(() => {
        if (twoFAStatus === 'on') {
          routePromise('/login');
        } else {
          clearErrors();
          history.push('/2fa/reminder/change-number');
        }
      }, 2000);
    }
    return () => {
      if (close2faTimeout.current) clearTimeout(close2faTimeout.current);
    };
  }, [showCodeExpiredError, twoFAStatus, history, clearErrors]);

  useEffect(
    () => () => {
      if ((enabled2faSuccess && otpToken) || showCodeExpiredError) {
        clearOTPTokenAction();
      }
    },
    [enabled2faSuccess, otpToken, showCodeExpiredError, revokeOTP, clearOTPTokenAction]
  );

  const handleSendCode = ({ sendEmail = false }) => {
    if (isResendLoading) {
      return;
    }
    setSendToEmail(sendEmail);
    clearErrors();
    setVerificationCode('');
    resendOTP(
      { otpToken, sendEmail },
      {
        onError: () => setExpiredCodeErrorAction(true),
        onSuccess: (res: ResendOTPResponse) => setOTPTokenAction(res.otpToken, res.userPhone),
      }
    );
  };

  const handleChangePhoneNumber = () => {
    trackClient2FAVerification('Go back to change phone number');
    clearOTPTokenAction();
    clearErrors();
    history.push('/2fa/reminder/change-number');
  };

  const handleReminderLater = () => {
    trackClient2FAVerification('I’ll do this later enable 2FA');
    update2faReminderAction();
  };

  const handleSendToEmailOrPhone = ({ sendEmail = false }) => {
    trackClient2FA(`Send to ${sendEmail ? 'Email' : 'Phone'}`);
    handleSendCode({ sendEmail });
  };

  const handleResendCode = ({ sendEmail = false }) => {
    trackClient2FA(`Resend code - ${sendEmail ? 'Email' : 'SMS'}`);
    handleSendCode({ sendEmail });
  };

  return (
    <Modal isMobileFullscreen isVisible titleText="Please enter your verification code">
      <TwoFactorAuthenticationWrapper>
        <View flex={1} align="center" style={{ marginTop: 50, maxWidth: 345, textAlign: 'center' }}>
          {showVerificationCodeError && (
            <Large
              aria-live="polite"
              variant="largeDarkGrey"
              style={{ color: colors.red, fontWeight: 500 }}
            >
              Invalid verification code.
            </Large>
          )}
          {showCodeExpiredError && (
            <Large
              aria-live="polite"
              variant="largeDarkGrey"
              style={{ color: colors.red, fontWeight: 500 }}
            >
              The verification code has expired.
            </Large>
          )}

          {showCodeExpiredError ? (
            <Spinner style={spinnerStyles} containerStyle={spinnerStyles} />
          ) : (
            <>
              {sendToEmail ? (
                <Large variant="largeDarkGrey" style={{ maxWidth: 298 }}>
                  Please enter the 6-digit verification code sent to your email address.
                </Large>
              ) : (
                <Large variant="largeDarkGrey" style={{ maxWidth: 298 }}>
                  Please enter the 6-digit verification code sent to your phone number ending in{' '}
                  {phoneNumberLast2Digits}.
                </Large>
              )}
              <VerificationCodeInput
                isError={showVerificationCodeError}
                style={{ marginTop: 30 }}
                onChange={setVerificationCode}
                onSubmit={handleOnPress}
                clearVerificationCodeError={clearVerificationCodeErrorAction}
                clearVerificationCode={isResendLoading || showVerificationCodeError}
                isLoading={isUpdating || isResendLoading}
                autoFocus
                isDisabled={isLoading && verificationCode.length === VERIFICATION_CODE_LENGTH}
              />
              <Button
                dataQa="2faVerifyContinueButton"
                disabled={isUpdating || verificationCode.length < VERIFICATION_CODE_LENGTH}
                isLoading={isUpdating || isResendLoading}
                text="Continue"
                onPress={handleOnPress}
                primaryColor={colors.green}
                style={{ width: '100%', marginTop: 30 }}
                roundedFocusStyle
              />
              <Large variant="largeDarkGrey" style={{ marginTop: twoFAStatus === 'on' ? 30 : 25 }}>
                Didn’t receive a verification code?
              </Large>
              {twoFAStatus === 'on' ? (
                <View
                  row
                  justify="center"
                  style={{ columnGap: 5, alignItems: 'center', minWidth: '400px' }}
                >
                  <BaseButton
                    data-qa="2faVerifyResendCodeButton"
                    onPress={() => handleResendCode({ sendEmail: sendToEmail })}
                    style={{ height: 23, border: 'none' }}
                    primaryColor={colors.green}
                    roundedFocusStyle
                  >
                    <Large variant="largeBoldWideGreen">Resend code</Large>
                  </BaseButton>

                  <Large style={{ color: colors.altoGrey, fontSize: 20, fontWeight: 100 }}>|</Large>
                  <BaseButton
                    data-qa={`2faVerifySendTo${sendToEmail ? 'Phone' : 'Email'}Button`}
                    onPress={() => handleSendToEmailOrPhone({ sendEmail: !sendToEmail })}
                    style={{ height: 23, border: 'none' }}
                    primaryColor={colors.green}
                    roundedFocusStyle
                  >
                    <Large variant="largeBoldWideGreen">
                      {sendToEmail ? 'Send to phone' : 'Send to email'}
                    </Large>
                  </BaseButton>

                  <Large style={{ color: colors.altoGrey, fontSize: 20, fontWeight: 100 }}>|</Large>
                  <Link
                    onClick={() => trackClient2FA('Contact support')}
                    dataQa="2faVerifyContactSupportButton"
                    aria-describedby={contactSupportID}
                    href="mailto:support@talkspace.com"
                    text="Contact support"
                    target="_blank"
                    style={{ margin: 0, height: 23, textDecoration: 'none' }}
                  >
                    <Large style={{ lineHeight: '23px' }} variant="largeBoldWideGreen">
                      Contact support
                    </Large>
                  </Link>
                </View>
              ) : (
                <>
                  <View
                    row
                    justify="center"
                    style={{ columnGap: 5, alignItems: 'center', minWidth: '400px' }}
                  >
                    <BaseButton
                      data-qa="2faVerifyResendCodeButton"
                      onPress={() => handleResendCode({ sendEmail: false })}
                      style={{ borderRadius: 5 }}
                      primaryColor={colors.green}
                      roundedFocusStyle
                    >
                      <Large variant="largeBoldWideGreen">Resend code</Large>
                    </BaseButton>
                    <Large style={{ color: colors.altoGrey, fontSize: 20, fontWeight: 100 }}>
                      |
                    </Large>
                    <BaseButton
                      data-qa="2faChangePhoneButton"
                      onPress={handleChangePhoneNumber}
                      style={{ borderRadius: 5 }}
                      primaryColor={colors.green}
                      roundedFocusStyle
                    >
                      <Large variant="largeBoldWideGreen">Change phone number</Large>
                    </BaseButton>
                  </View>

                  {twoFAStatus !== 'required' && (
                    <BaseButton
                      data-qa="2faVerifyLaterButton"
                      onPress={handleReminderLater}
                      style={{ marginTop: 20, borderRadius: 5 }}
                      primaryColor={colors.green}
                      roundedFocusStyle
                    >
                      <Large variant="largeBoldWideGreen">I’ll do this later</Large>
                    </BaseButton>
                  )}
                </>
              )}
            </>
          )}
        </View>
      </TwoFactorAuthenticationWrapper>
    </Modal>
  );
};

export default TwoFactorAuthenticationVerify;
