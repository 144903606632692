import { EmotionStyle, SkeletonLoader, TextDS, View, styled } from '@talkspace/react-toolkit';
import { useCommunityState } from '../hooks/CommunityContext';
import { getAge } from '../utils/helpers';

const UserInfoText = styled(TextDS)(({ theme: { spacing } }) => {
  return {
    marginLeft: spacing('space100'),
  };
});

interface Props {
  user: Amity.User | undefined;
  style?: EmotionStyle;
}
const UserTag = ({ user, style }: Props) => {
  const { userID: currentUserID } = useCommunityState();
  const { userId: userID, displayName, metadata } = user || {};
  const { state, dateOfBirth } = metadata || {};
  const age = getAge({ dateOfBirth });
  const isLoading = !userID || !displayName;
  const isMe = userID === currentUserID;
  const infoString = isMe ? '(me)' : `${state}, ${age}`;
  return (
    <View row style={style}>
      {isLoading ? (
        <View style={{ height: 20 }}>
          <SkeletonLoader variant="headingMd" style={{ width: 100 }} />
        </View>
      ) : (
        <>
          <TextDS variant="headingMd">{displayName}</TextDS>
          <UserInfoText colorRole="textSubtlest">{infoString}</UserInfoText>
        </>
      )}
    </View>
  );
};

export default UserTag;
