import { FunctionComponent, useState, useEffect, useMemo } from 'react';
import { reducePresentingProblemsOptionsByValue } from 'ts-frontend/helpers/presentingProblems';
import { useWizardState, useWizardActions } from 'stepWizard';
import { ClientMatchPresentingProblem } from 'ts-frontend/types';
import useQuerySubscriptions from 'ts-frontend/hooks/useQuerySubscriptions';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useInRoomSchedulingActions, useInRoomSchedulingState } from 'inRoomScheduling';
import { MatchPayload, MatchResultsData } from '@/therapistMatches/matchingTypes';
import { MatchResultsProvider } from '@/therapistMatches/hooks/useMatchResults';
import MatchResults from '@/therapistMatches/containers/MatchResults';
import { trackWizardEvent } from '@/utils/analytics/events';
import { RouteComponentProps, withRouter } from '../../core/routerLib/routerLib';
import useSwitchWizard from '../hooks/useSwitchWizard';
import ActionStatus from '../../components/ActionStatus/ActionStatus';
import { useMainActions, useMainState } from '../../hooks/mainContext';
import { extractRoomEntitiesFromState } from '../../utils/extractRoomEntitiesFromState';
import useQueryPostSuggestTherapistBookings from '../hooks/useQueryPostSuggestTherapistBookings';
import { State } from '../reducers/switchWizardState';

const MatchResultsContainer: FunctionComponent<RouteComponentProps> = ({ history }) => {
  const [matchPayload, setMatchPayload] = useState<MatchPayload>();
  const [matchResultsError, setMatchResultsError] = useState<string | null>(null);
  const [selectedPresentingProblemAsString, setSelectedPresentingProblemsAsString] = useState('');
  const [selectedPresentingProblemsAsExpertise, setSelectedPresentingProblemsAsExpertise] =
    useState<ClientMatchPresentingProblem[]>([]);
  const { dispatchSetTimeslotsForMatch, dispatchSetRoomAndTherapistInfo } =
    useInRoomSchedulingActions();
  const { selectedBookingDuration } = useInRoomSchedulingState();
  const { availabilitySwitch } = useFlags();
  const {
    roomID,
    countryState,
    newTherapistFocus,
    therapistGender,
    therapistEthnicity,
    presentingProblemsOptions,
    existingPreferences,
    eventCategory,
    clientUserID,
    responses,
    therapistReview,
    preferredLanguage,
    preferredLanguageFilter,
    timeslots,
    matches,
    isLiveModality,
  } = useWizardState<State>();
  const [state, { patchClientInfo }] = useSwitchWizard();
  const { data: subscriptions } = useQuerySubscriptions({ clientUserID });
  const subscription = subscriptions?.find((s) => s.id === roomID);
  const currentTherapistID = subscription?.therapistID;
  const isBH = !!subscription?.subscription?.isBH;
  const mainState = useMainState();
  const { error, isLoading } = state;
  const { setState, onExitClick } = useWizardActions();

  const isBookingSwitch = availabilitySwitch && isBH && isLiveModality;
  useEffect(() => {
    setState({ isBookingSwitch });
  }, [isBookingSwitch, setState]);

  let formerTherapistID;
  if (therapistReview) {
    formerTherapistID = therapistReview.id || therapistReview.therapistId;
  }

  const { getAllRooms } = useMainActions();
  const hasRooms = useMemo(
    () => Object.keys(mainState.clientsByRoomID).length !== 0,
    [mainState.clientsByRoomID]
  );

  const clientCountry = countryState ? countryState.split('-')[0] : '';
  const bookingMatchPayload = useMemo(() => {
    return {
      ...existingPreferences,
      sessionLength: selectedBookingDuration || 30,
      fieldsOfExpertise: newTherapistFocus,
      therapistGender,
      therapistEthnicity,
      clientCountry,
      languages: preferredLanguage && preferredLanguageFilter === 'hard' ? [preferredLanguage] : [],
      languagesSoft:
        preferredLanguage && preferredLanguageFilter === 'soft' ? [preferredLanguage] : [],
      roomType: subscription?.roomType || 'private_room',
    };
  }, [
    existingPreferences,
    newTherapistFocus,
    preferredLanguage,
    preferredLanguageFilter,
    subscription?.roomType,
    clientCountry,
    therapistGender,
    therapistEthnicity,
    selectedBookingDuration,
  ]);

  const {
    data: matchesData,
    isLoading: isLoadingSuggestTherapistBookings,
    isFetched: isFetchedSuggestTherapistBookings,
  } = useQueryPostSuggestTherapistBookings({
    roomID,
    payload: bookingMatchPayload,
    disabled: !isBookingSwitch || !countryState,
  });

  useEffect(() => {
    if (matchesData && !isLoadingSuggestTherapistBookings && isFetchedSuggestTherapistBookings) {
      setState({ ...matchesData });
    }
  }, [setState, matchesData, isLoadingSuggestTherapistBookings, isFetchedSuggestTherapistBookings]);

  useEffect(() => {
    if (!hasRooms) {
      getAllRooms(clientUserID);
    }
  }, [getAllRooms, clientUserID, hasRooms]);

  useEffect(() => {
    patchClientInfo(clientUserID, {
      countryState,
    });
    if (!error) {
      if (clientUserID && countryState && presentingProblemsOptions) {
        const presentingProblemsAsExpertise = reducePresentingProblemsOptionsByValue([
          ...presentingProblemsOptions,
        ]);
        setMatchPayload({
          ...existingPreferences,
          fieldsOfExpertise: newTherapistFocus,
          therapistGender,
          therapistEthnicity,
          clientCountry,
          languages:
            preferredLanguage && preferredLanguageFilter === 'hard' ? [preferredLanguage] : [],
          languagesSoft:
            preferredLanguage && preferredLanguageFilter === 'soft' ? [preferredLanguage] : [],
          roomType: subscription?.roomType || 'private_room',
        });
        setSelectedPresentingProblemsAsString(
          presentingProblemsOptions
            .filter((problem) => (newTherapistFocus || []).includes(problem.value))
            .map((problem) => problem.label)
            .join(', ')
        );
        setSelectedPresentingProblemsAsExpertise(
          presentingProblemsAsExpertise.filter((problem) =>
            (newTherapistFocus || []).includes(problem.expertiseID || -1)
          )
        );
      }
    }
  }, [clientUserID]); // eslint-disable-line

  const handleOnExitClick = () => {
    const currentStepResponses = responses;
    currentStepResponses.lastStepName = 'Match results';
    setState({ responses: currentStepResponses });
    onExitClick();
  };

  const handleOnAgreePress = (
    therapistID: number,
    therapistFirstName: string,
    therapistImage: string,
    therapistType: string
  ) => {
    trackWizardEvent('Sign Informed Consent', eventCategory, {
      'Therapist ID': therapistID,
      'User ID': clientUserID,
      label: 'Sign Informed Consent',
      Application: eventCategory,
      eventProperty: therapistID.toString(),
      eventPropertyValue: 0.0,
    });
    const currentStepResponses = responses;
    currentStepResponses.lastStepName = 'Sharing Preferences';
    // TODO: @Abanob: Eventually remove the informedConsentGiven from the API side and remove all of its occurrences from switchWizard
    setState({
      newTherapistMatch: { therapistID, therapistFirstName, therapistImage, therapistType },
      informedConsentGiven: false,
      responses: currentStepResponses,
    });
    const { room } = extractRoomEntitiesFromState(mainState, roomID);
    const matchData = (matches as MatchResultsData['matches'])?.find(
      (m) => m.userId === therapistID
    );
    if (selectedBookingDuration && timeslots) {
      const timeslotsForMatch = timeslots?.filter((t) => t.therapists?.includes(therapistID)) || [];
      dispatchSetTimeslotsForMatch(timeslotsForMatch, selectedBookingDuration);
    }
    dispatchSetRoomAndTherapistInfo(room, {
      id: therapistID,
      firstName: therapistFirstName,
      lastName: matchData?.details.lastName || '',
    });
    if (isBookingSwitch) {
      history.push(`/switch-provider/booking/${roomID}/select-timeslot`, {
        roomID,
      });
    } else {
      history.push('/switch-provider/sharing-preferences', {
        roomID,
      });
    }
  };

  const filteredMatchData = useMemo(
    () => matchesData?.matches.filter((match) => match.userId !== currentTherapistID),
    [matchesData?.matches, currentTherapistID]
  );
  const filteredTimeslotData = useMemo(
    () =>
      matchesData?.timeslots.map((timeslot) => {
        return {
          ...timeslot,
          therapists: timeslot.therapists?.filter((tID) => tID !== currentTherapistID),
        };
      }),
    [matchesData?.timeslots, currentTherapistID]
  );

  if (
    error ||
    isLoading ||
    isLoadingSuggestTherapistBookings ||
    (!isFetchedSuggestTherapistBookings && isBookingSwitch) ||
    matchResultsError
  ) {
    return (
      <>
        <ActionStatus
          isLoading={isLoading || isLoadingSuggestTherapistBookings}
          isError={!!error || !!matchResultsError}
          errorTitle="Something Went Wrong"
          errorSubTitle="Please check your internet connection and try again."
          errorButtonText="Close"
          errorButtonAction={handleOnExitClick}
          titleStyles={{ fontWeight: 800, fontSize: 29, marginTop: 25 }}
          subTitleStyles={{
            fontSize: 16,
            color: '#454A5E',
            marginTop: 13,
            marginBottom: 42,
            width: 200,
            textAlign: 'center',
          }}
        />
      </>
    );
  }
  return (
    <>
      {matchPayload ? (
        <MatchResultsProvider>
          <MatchResults
            roomID={roomID}
            matchPayload={matchPayload}
            selectedPresentingProblemAsString={selectedPresentingProblemAsString}
            selectedPresentingProblemsAsExpertise={selectedPresentingProblemsAsExpertise}
            skipPayment
            handleSwitchWizardSelectedTherapist={handleOnAgreePress}
            eventCategory={eventCategory}
            clientUserID={clientUserID}
            formerTherapistID={formerTherapistID}
            setMatchResultsError={setMatchResultsError}
            timeslots={filteredTimeslotData}
            matches={isBookingSwitch ? filteredMatchData : undefined}
            isBooking={!!isBookingSwitch}
          />
        </MatchResultsProvider>
      ) : null}
    </>
  );
};

export default withRouter(MatchResultsContainer);
