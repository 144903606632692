import moment from 'moment';
import { Booking } from 'ts-frontend/types';
import capitalize from 'lodash/capitalize';
import { useFlags } from 'launchDarkly/FlagsProvider';
import {
  Big,
  Button,
  FullScreenWrapper,
  Huge,
  LiveSessionIcon,
  Small,
  Spinner,
  Standard,
  View,
  useEmotionTheme,
} from '@talkspace/react-toolkit';

interface Props {
  isLoading: boolean;
  billingCycleEnd?: Date;
  sessionsToCancel?: Booking[];
  onPressBack: () => void;
  onPressClose: () => void;
  onPressCancelSessions: () => void;
  onPressKeepSessions: () => void;
}
const ManageSubscriptionSessionsToCancel = ({
  isLoading,
  billingCycleEnd,
  sessionsToCancel,
  onPressBack,
  onPressClose,
  onPressCancelSessions,
  onPressKeepSessions,
}: Props) => {
  const { colors } = useEmotionTheme();
  const { brandRefreshChanges } = useFlags();
  // sessionsToCancel must be not empty, this should never happen
  if (!isLoading && !sessionsToCancel?.length) return <Spinner />;
  return (
    <FullScreenWrapper
      goBack={onPressBack}
      showCloseButton
      onCloseButtonClick={onPressClose}
      newLogo={brandRefreshChanges}
    >
      <View
        style={{
          maxWidth: 335,
          textAlign: 'center',
          justifyContent: 'space-between',
        }}
      >
        {isLoading ? (
          <View align="center">
            <Spinner />
          </View>
        ) : (
          <>
            <Huge style={{ fontSize: 29, lineHeight: '34px', fontWeight: 700 }}>
              These sessions will be canceled
            </Huge>
            <Standard
              style={{
                marginTop: 10,
                color: colors.permaRiverBed,
                fontSize: 16,
                lineHeight: '20px',
                fontWeight: 400,
              }}
            >
              Sessions scheduled after {moment(billingCycleEnd).format('MMM Do')} will be lost if
              you cancel your plan today. Your plan renews every 30 days and the following sessions
              are scheduled outside of your current billing cycle.
            </Standard>

            <View style={{ marginTop: 30, padding: '0 20px' }}>
              {sessionsToCancel &&
                sessionsToCancel.map(({ id, modality, startTime, creditMinutes }, i) => {
                  const startMoment = moment(startTime);
                  const endMoment = moment(startTime).add(creditMinutes, 'minutes');
                  return (
                    <View
                      row
                      key={`session-${id}`}
                      style={{ marginTop: i === 0 ? undefined : 8, padding: 10 }}
                      align="center"
                    >
                      <LiveSessionIcon modality={modality} width={45} height={45} />
                      <View style={{ marginLeft: 15 }} align="start">
                        <Big>Live {capitalize(modality)} session</Big>
                        <Small>
                          {startMoment.format(`ddd, MMM D`)} {startMoment.format('h:mmA')} -{' '}
                          {endMoment.format('h:mmA')}
                        </Small>
                      </View>
                    </View>
                  );
                })}
              <Button
                dataQa="keepSessions"
                text="Never mind, keep my sessions"
                onPress={onPressKeepSessions}
                style={{
                  marginTop: 60,
                  backgroundColor: colors.permaTalkspaceDarkGreen,
                }}
              />
              <Button
                dataQa="cancelSessions"
                text="Continue with cancellation"
                onPress={onPressCancelSessions}
                style={{
                  marginTop: 10,
                  width: '100%',
                  backgroundColor: colors.white,
                  color: colors.permaTalkspaceDarkGreen,
                }}
              />
            </View>
          </>
        )}
      </View>
    </FullScreenWrapper>
  );
};

export default ManageSubscriptionSessionsToCancel;
