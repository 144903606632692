import { useEffect } from 'react';
import { getUserData } from '../../auth/helpers/token';
import useQueryOnboarding from '../hooks/useQueryOnboarding';
import { useHistory, useParams, useRouteMatch } from '@/core/routerLib';
import BookSession from '../components/BookSession';
import { setIsOnboardingDismissed } from '../util/onboardingStorage';
import useNavHelpers from '../../hooks/useNavHelpers';

const BookAndActivateContainer = () => {
  const { roomID } = useParams<{ roomID: string | undefined }>();
  const history = useHistory();
  const match = useRouteMatch();
  const { id: userID } = getUserData();
  const { navigateToDefault } = useNavHelpers({ history, roomID });
  const { data: onBoardingData, refetch: fetchOnboarding } = useQueryOnboarding({
    roomID: Number(roomID),
    userID,
  });
  const doesBookAndActivateStepExists = Boolean(
    onBoardingData?.steps?.find(({ step }) => step === 'BOOK_AND_ACTIVATE')
  );

  useEffect(() => {
    if (!doesBookAndActivateStepExists) {
      navigateToDefault();
    }
  }, [doesBookAndActivateStepExists, onBoardingData, match, navigateToDefault]);

  const dismissOnboarding = () => {
    if (roomID) {
      setIsOnboardingDismissed(Number(roomID));
    }
  };

  return (
    <BookSession
      flowVariant="bookAndActivate"
      fetchOnboarding={fetchOnboarding}
      dismissOnboarding={dismissOnboarding}
    />
  );
};

export default BookAndActivateContainer;
