import {
  Button,
  SimpleHeader,
  StickyDrawer,
  TextDS,
  View,
  OneColumnLayout,
  ResponsiveLayoutWithHeader,
  Spinner,
  styled,
} from '@talkspace/react-toolkit';
import { ArrowRotateRight } from '@talkspace/react-toolkit/src/designSystems/icons';
import TeenspaceNotificationPrompt from '../assets/illustrations/DailyPromptNotificationIllustration';

const SPINNER_MARGIN = 240;
const increaseFactorText = (
  <TextDS colorRole="textSubtle" inline style={{ fontWeight: 700 }}>
    5x more
  </TextDS>
);

const StyledHeader = styled(TextDS)(({ theme: { spacing } }) => {
  return {
    textAlign: 'center',
    marginTop: spacing('space400'),
  };
});

const StyledBody = styled(View)(({ theme: { spacing } }) => {
  return {
    marginTop: spacing('space150'),
  };
});
const PromptView = styled(View)(({ theme: { spacing } }) => {
  return {
    marginTop: spacing('space400'),
  };
});

const RemindMeButton = styled(Button)(({ theme: { spacing } }) => {
  return {
    marginTop: spacing('space200'),
    maxWidth: undefined,
  };
});
const MaybeLaterButton = styled(Button)(({ theme: { spacing } }) => {
  return {
    padding: spacing('space200'),
    marginTop: spacing('space050'),
    maxWidth: undefined,
  };
});

const StyledStickyDrawer = styled(StickyDrawer)(({ theme: { spacing } }) => {
  return {
    padding: `${spacing('space300')}px 0`,
  };
});
interface Props {
  isLoading: boolean;
  isError: boolean;
  onPressRemindMe: () => void;
  onPressSkip: () => void;
  onClose: () => void;
}
const DailyPromptNotificationScreen = ({
  isLoading,
  isError,
  onPressRemindMe,
  onPressSkip,
  onClose,
}: Props) => (
  <ResponsiveLayoutWithHeader
    renderHeader={() => <SimpleHeader title="Teenspace" onClose={onClose} />}
  >
    <OneColumnLayout>
      <View align="center" justify="center" style={{ height: '100%' }}>
        {isLoading ? (
          <Spinner style={{ marginTop: SPINNER_MARGIN }} />
        ) : (
          <>
            <StyledHeader variant="heading2xl">
              Build a reflection habit with reminders
            </StyledHeader>
            <StyledBody>
              <TextDS inline style={{ textAlign: 'center' }}>
                You’ll be {increaseFactorText} likely to build a healthy reflection habit by
                enabling daily reminders
              </TextDS>
            </StyledBody>
            <PromptView>
              <TeenspaceNotificationPrompt />
            </PromptView>
            <StyledStickyDrawer noBorder contentWrapperStyle={{}}>
              <OneColumnLayout>
                <View align="center">
                  <RemindMeButton
                    size="large"
                    dataQa="teenspaceRemindMeButton"
                    Icon={isError ? () => <ArrowRotateRight colorType="inverse" /> : () => <></>}
                    text={isError ? 'Try again' : 'Remind me'}
                    onPress={onPressRemindMe}
                    style={{ width: '100%' }}
                  />
                  <MaybeLaterButton
                    size="large"
                    dataQa="teenspaceMaybeLaterButton"
                    variant="tertiary"
                    text="Maybe later"
                    onPress={onPressSkip}
                    style={{ width: '100%' }}
                  />
                </View>
              </OneColumnLayout>
            </StyledStickyDrawer>
          </>
        )}
      </View>
    </OneColumnLayout>
  </ResponsiveLayoutWithHeader>
);

export default DailyPromptNotificationScreen;
