import { FunctionComponent, useEffect, useState } from 'react';
import {
  BaseButton,
  Modal,
  View,
  TouchableView,
  Large,
  ExtraHuge,
  Standard,
  RedX,
} from '@talkspace/react-toolkit';
import Lottie from 'react-lottie';
import { WorkflowActionResult } from 'chat/redux/constants/chatTypes';
import styled from '../../../core/styled';
import loadingSpinner from '../../assets/loadingSpinner.json';

type IntakeModalState = 'loading' | 'load-success' | 'load-error' | 'closed';

const Wrapper = styled(TouchableView)(({ theme: { window } }) => {
  const isResponsive: boolean = window.width <= 550;
  return {
    borderRadius: isResponsive ? 0 : 5,
    backgroundColor: 'transparent',
    alignItems: 'stretch',
    height: isResponsive ? '100%' : 700,
    width: isResponsive ? '100%' : 551,
    overflowY: 'hidden',
  };
});

const IntakeLoaderWrapper = styled(TouchableView)(({ theme: { window } }) => {
  const isResponsive: boolean = window.width <= 550;
  return {
    cursor: 'default',
    backgroundColor: '#FFF',
    marginTop: isResponsive ? 0 : 75,
    borderRadius: isResponsive ? 0 : 5,
    height: isResponsive ? '100%' : 550,
    width: isResponsive ? '100%' : 551,
    overflowY: 'hidden',
  };
});

const IntakeActionView: FunctionComponent<{
  onBackdropPress: () => void;
  workflowAction?: WorkflowActionResult;
}> = ({ onBackdropPress, workflowAction }) => {
  const [intakeModalState, setIntakeModalState] = useState<IntakeModalState>('loading');

  useEffect(() => {
    const modalStateListener = (event: MessageEvent) => {
      if (!event.isTrusted) return;
      if (event.data.intakeModalState) setIntakeModalState(event.data.intakeModalState);
      if (event.data.intakeModalState === 'closed') onBackdropPress();
    };
    window.addEventListener('message', modalStateListener);
    return () => {
      window.removeEventListener('message', modalStateListener);
    };
  }, [onBackdropPress]);

  return (
    <Modal isVisible onBackdropPress={onBackdropPress}>
      <Wrapper onPress={(e) => e.stopPropagation()}>
        <>
          {intakeModalState === 'loading' && (
            <IntakeLoaderWrapper>
              <View style={{ marginTop: 120 }}>
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: loadingSpinner,
                  }}
                  height={66}
                  width={66}
                />
              </View>
            </IntakeLoaderWrapper>
          )}
          {intakeModalState === 'load-error' && (
            <IntakeLoaderWrapper align="center">
              <RedX style={{ marginTop: 120 }} />
              <ExtraHuge style={{ marginTop: 30 }}>Something went wrong</ExtraHuge>
              <Large variant="largeDarkGrey" style={{ marginTop: 10 }}>
                Please try refreshing the page or contact customer support
              </Large>
              <BaseButton onPress={onBackdropPress} style={{ marginTop: 40 }}>
                <Standard variant="standardDarkGrey">Close</Standard>
              </BaseButton>
            </IntakeLoaderWrapper>
          )}
        </>
      </Wrapper>
    </Modal>
  );
};

export default IntakeActionView;
