import apiWrapper from '../../core/api/apiWrapper';
import apiHelper from '../../core/api/apiHelper';
import cancelPromiseHelper from '../../core/api/cancelPromiseHelper';

export interface FriendReferralInfo {
  earnedReferralCount: number;
  emailBody: string;
  referralIcon: string;
  emailSubject: string;
  referralMessage: string;
  referralTitle: string;
  referralUrl: string;
  registeredReferralCount: number;
  shareMessage: string;
}

class FriendReferralApiHelper {
  private wrapWithCancel: (req: Promise<any>) => Promise<any>;

  public reset: () => void;

  public dismissIfCancelled: (err: Error) => any;

  public cancelAll: () => void;

  constructor() {
    const { cancelAll, reset, dismissIfCancelled, wrapWithCancel } = cancelPromiseHelper();
    this.wrapWithCancel = wrapWithCancel;
    this.reset = reset;
    this.dismissIfCancelled = dismissIfCancelled;
    this.cancelAll = cancelAll;
  }

  public getFriendReferralInfo(userID: number): Promise<FriendReferralInfo | undefined> {
    return this.wrapWithCancel(
      apiWrapper.get<FriendReferralInfo>(
        `${apiHelper().apiEndpoint}/v2/clients/${userID}/friend-referral`
      )
    ).then((res) => res.data.data);
  }
}

export default new FriendReferralApiHelper();
