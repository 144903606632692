import { EmotionThemeProvider } from '@talkspace/react-toolkit';
import { useIsTeenspaceActive } from 'launchDarkly/hooks';
import { Redirect, Route, Switch } from '@/core/routerLib';
import CommunityCreatePost from './containers/CommunityCreatePost';
import CommunityCreatePostSuccess from './containers/CommunityCreatePostSuccess';
import DailyPromptNotificationScreenContainer from './containers/DailyPromptNotificationScreenContainer';

import TeenspaceOnboardingRouter from './teenspaceOnboarding';

const CommunityRoutes = () => {
  const isTeenspaceActive = useIsTeenspaceActive();
  if (!isTeenspaceActive) return <Redirect to="/home" />;
  return (
    <EmotionThemeProvider version="2.0.0">
      <Switch>
        <Route path="/community/teenspace/onboarding" component={TeenspaceOnboardingRouter} />
        <Route exact path="/community/teenspace/create-post" component={CommunityCreatePost} />
        <Route
          exact
          path="/community/teenspace/create-post/success"
          component={CommunityCreatePostSuccess}
        />
        <Route
          exact
          path="/community/teenspace/notification-prompt"
          component={DailyPromptNotificationScreenContainer}
        />
      </Switch>
    </EmotionThemeProvider>
  );
};

export default CommunityRoutes;
