import { FunctionComponent, useCallback } from 'react';
import { RouteComponentProps, withRouter } from '@/core/routerLib';
import SwitchReview from '../components/SwitchReview';
import therapistAPI from '../../therapistReview/utils/therapistReviewApiHelper';

interface SwitchReviewContainerProps extends RouteComponentProps {
  handleExitModal: () => void;
}
const SwitchReviewContainer: FunctionComponent<SwitchReviewContainerProps> = ({
  handleExitModal,
}) => {
  const handleOnContinuePress = useCallback(
    (formData) => {
      const reviewPayload = {
        therapistId: formData.therapistId,
        reviewText: formData.reviewText,
        ratingValue: formData.ratingValue,
        roomId: formData.roomId,
        ratingSource: `switchTherapist wizard`,
      };
      therapistAPI.postTherapistReview(reviewPayload).then(() => {
        handleExitModal();
      });
    },
    [handleExitModal]
  );

  const handleOnSkip = useCallback(() => {
    handleExitModal();
  }, [handleExitModal]);

  return <SwitchReview onSubmit={handleOnContinuePress} onSkip={handleOnSkip} />;
};

export default withRouter(SwitchReviewContainer);
