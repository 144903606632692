import { Auth } from '@aws-amplify/auth';

type ChangePasswordParameters = {
  email: string;
  newPassword: string;
  oldPassword: string;
};

const changePassword = async ({
  email,
  newPassword,
  oldPassword,
}: ChangePasswordParameters): Promise<void> => {
  try {
    const user = await Auth.signIn({ password: oldPassword, username: email });

    await Auth.changePassword(user, oldPassword, newPassword);
  } finally {
    await Auth.signOut();
  }
};

export default changePassword;
