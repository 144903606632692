import { FunctionComponent, useEffect, useState } from 'react';
import {
  View,
  ExtraHuge,
  Tiny,
  Standard,
  Large,
  Button,
  BaseButton,
  GraphicCard,
  useUniqueID,
  HiddenText,
} from '@talkspace/react-toolkit';
import styled from '../../core/styled';
import { webOnlyStyle } from '../../core/styled/styleHelpers';
import guyWithPenURL from './guy_with_pen.png';
import useWindowWidth from '../../hooks/useWindowWidth';

interface InformedConsentActionSplashProps {
  hasConsented: boolean;
  agreedDate: string;
  hasAgreedJustNow?: boolean;
  onBeginPress: () => void;
  onBackdropPress: () => void;
  viewAgreement: () => void;
  isOldMessageType?: boolean;
}
interface AllDoneViewProps {
  visible: boolean;
  returnToChat: () => void;
  viewAgreement: () => void;
  isMobile?: boolean;
  isOldMessageType?: boolean;
}

const AllDoneWrapper = styled(View)<{
  visible: boolean;
  isMobile?: boolean;
  isOldMessageType?: boolean;
}>(({ visible, isMobile, isOldMessageType }) => {
  const leftValue = isOldMessageType ? '-23%' : '-32%';
  return {
    width: '100%',
    left: isMobile ? 0 : leftValue,
    alignItems: 'center',
    marginTop: 71,
    ...webOnlyStyle({
      position: 'relative',
      display: visible ? 'flex' : 'none',
    }),
  };
});

const AllDoneView: FunctionComponent<AllDoneViewProps> = ({
  visible,
  returnToChat,
  viewAgreement,
  isMobile,
  isOldMessageType,
}) => (
  <AllDoneWrapper visible={visible} isMobile={isMobile} isOldMessageType={isOldMessageType}>
    <View style={{ width: 230, marginBottom: 170 }}>
      <ExtraHuge style={{ textAlign: 'center' }}>You're all done!</ExtraHuge>
      <Large
        variant="largeDarkGrey"
        style={{ textAlign: 'center', marginTop: 17, marginBottom: 20 }}
      >
        Thank you for completing the Informed Consent form. You can now begin treatment.
      </Large>
    </View>
    <Button text="Return to chat" onPress={returnToChat} />
    <BaseButton style={{ marginTop: 10 }}>
      <Standard variant="standardDarkGrey" onPress={viewAgreement}>
        View agreement
      </Standard>
    </BaseButton>
  </AllDoneWrapper>
);

const SplashPositioningWrapper = styled(View)<{
  shouldPushLeft: boolean;
  isMobile: boolean;
}>(({ shouldPushLeft, isMobile }) => {
  return {
    flexDirection: 'row',
    justifyContent: 'center',
    left: shouldPushLeft && isMobile ? '-50%' : 0,
    ...webOnlyStyle({
      position: 'relative',
      transition: 'left .2s',
    }),
  };
});

const SplashWrapper = styled(View)(({ theme: { window } }) => {
  return {
    marginTop: -13,
    width: window.isMobile ? '100%' : 500,
    alignItems: 'center',
  };
});

const InformedConsentActionSplash: FunctionComponent<InformedConsentActionSplashProps> = ({
  onBeginPress,
  viewAgreement,
  onBackdropPress,
  hasAgreedJustNow,
  hasConsented,
  agreedDate,
  isOldMessageType,
}) => {
  const { isSmall, isMedium, isMobile } = useWindowWidth();
  const [shouldPushLeft, setShouldPushLeft] = useState<boolean>(false);
  // mainsite sends a string "null"
  const infoTextId = useUniqueID('infoTextId');
  const agreedOnTextId = useUniqueID('agreedOnTextId');
  const agreedOnText = `Agreed on ${agreedDate}`;

  useEffect(() => {
    let timer;
    if (hasConsented && hasAgreedJustNow) {
      timer = setTimeout(() => {
        setShouldPushLeft(true);
      }, 1200);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [hasAgreedJustNow, hasConsented]);

  return (
    <SplashPositioningWrapper shouldPushLeft={shouldPushLeft} isMobile={isMobile}>
      <SplashWrapper>
        <GraphicCard
          isResponsive={isSmall}
          bgImageURL={guyWithPenURL}
          bgHeight={169}
          bgWidth={188}
          bgX={isSmall ? 48 : 149}
          bgY={isSmall ? 147 : 103}
          isActive={!!hasConsented}
          style={{
            opacity: shouldPushLeft ? 0.4 : 1,
            transition: 'opacity .2s',
          }}
        >
          <View flex={1} style={{ padding: 28 }}>
            <ExtraHuge as="h1" style={{ color: 'white' }}>
              Giving consent
              <br />
              to treatment
            </ExtraHuge>
            <Tiny as="h2" style={{ color: 'white', fontWeight: 700, marginTop: 7 }}>
              Completion time: 2min
            </Tiny>
          </View>
        </GraphicCard>
        {!shouldPushLeft && (
          <>
            <View
              style={{
                marginLeft: isSmall || isMedium ? 20 : 70,
                marginRight: isSmall || isMedium ? 20 : 70,
              }}
            >
              <Large
                id={infoTextId}
                variant="largeDarkGrey"
                style={{ textAlign: 'center', marginTop: 17, marginBottom: 20 }}
              >
                Because therapy is a form of health care treatment, you must consent to receiving
                treatment before working with your therapist.
              </Large>
            </View>
            {hasConsented ? (
              <>
                <HiddenText id={agreedOnTextId}>{agreedOnText}</HiddenText>
                <Button text={agreedOnText} disabled />
                <BaseButton
                  aria-describedby={`${infoTextId} ${agreedOnTextId}`}
                  style={{ marginTop: 10 }}
                >
                  <Standard variant="standardDarkGrey" onPress={viewAgreement}>
                    View agreement
                  </Standard>
                </BaseButton>
              </>
            ) : (
              <Button aria-describedby={infoTextId} text="Begin" onPress={onBeginPress} />
            )}
          </>
        )}
      </SplashWrapper>
      <AllDoneView
        visible={shouldPushLeft}
        returnToChat={onBackdropPress}
        viewAgreement={viewAgreement}
        isMobile={isMobile}
        isOldMessageType={isOldMessageType}
      />
    </SplashPositioningWrapper>
  );
};

export default InformedConsentActionSplash;
