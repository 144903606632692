import {
  FunctionComponent,
  createContext,
  useContext,
  useReducer,
  useCallback,
  useRef,
} from 'react';
import { RoomInvite } from 'ts-frontend/entities/RoomInvite';
import moment from 'moment';
import {
  getInvites,
  revokeInvite as revokeInviteApiHelper,
  createInvite as createInviteApiHelper,
} from '../utils/roomInvitesApiHelper';

export enum RoomInviteStatus {
  LOADING,
  HAS_NO_INVITES,
  HAS_ACTIVE_INVITE,
  HAS_EXPIRED_INVITE,
  HAS_REVOKED_INVITE,
}

interface RoomInviteState {
  [roomID: number]:
    | {
        fetching?: boolean;
        invites?: RoomInvite[];
        isFetching?: boolean;
        currentInvitation?: RoomInvite;
        roomInviteStatus?: RoomInviteStatus;
      }
    | undefined;
}

interface RoomInviteActions {
  fetchRoomInvites: (roomID: number) => void;
  createInvite: (roomID: number, email: string) => void;
  revokeInvite: (roomID: number, inviteID: number) => void;
}

const RoomInviteStateContext = createContext<RoomInviteState | undefined>(undefined);

const RoomInviteActionsContext = createContext<RoomInviteActions | undefined>(undefined);

const initialReducerState = {};
const reducer = (
  currentState: RoomInviteState,
  action: { payload: Partial<RoomInviteState> }
): RoomInviteState => {
  return {
    ...currentState,
    ...action.payload,
  };
};

const RoomInviteProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialReducerState);
  const stateRef = useRef(state);
  stateRef.current = state;

  const invitesRequest = (roomID: number) => {
    dispatch({
      payload: {
        [roomID]: {
          ...(stateRef.current[roomID] || {}),
          fetching: true,
        },
      },
    });
    getInvites(roomID).then((res) => {
      const invites = res.data;
      const currentInvitation = invites[0];
      const isCurrentInvitationRevoked = Boolean(currentInvitation && currentInvitation.revokedAt);
      const isCurrentInvitationExpired =
        currentInvitation && moment().isAfter(currentInvitation.expiresAt);
      let roomInviteStatus = RoomInviteStatus.LOADING;
      if (currentInvitation) {
        if (isCurrentInvitationRevoked) {
          roomInviteStatus = RoomInviteStatus.HAS_REVOKED_INVITE;
        } else if (isCurrentInvitationExpired) {
          roomInviteStatus = RoomInviteStatus.HAS_EXPIRED_INVITE;
        } else {
          roomInviteStatus = RoomInviteStatus.HAS_ACTIVE_INVITE;
        }
      } else {
        roomInviteStatus = RoomInviteStatus.HAS_NO_INVITES;
      }
      dispatch({
        payload: {
          [roomID]: {
            fetching: false,
            currentInvitation,
            invites,
            roomInviteStatus,
          },
        },
      });
    });
  };

  const fetchInvites = (newRoomID: number) => {
    const currentInviteState = stateRef.current[newRoomID];
    const currentlyFetchingInvites = currentInviteState?.fetching;
    if (!currentlyFetchingInvites && !currentInviteState) {
      invitesRequest(newRoomID);
    }
  };

  const createInvite = async (roomID: number, email: string) => {
    await createInviteApiHelper(roomID, email);
    invitesRequest(roomID);
  };

  const revokeInvite = async (roomID: number, inviteID: number) => {
    await revokeInviteApiHelper(roomID, inviteID);
    invitesRequest(roomID);
  };

  const actions = {
    fetchRoomInvites: useCallback(fetchInvites, []),
    createInvite: useCallback(createInvite, []),
    revokeInvite: useCallback(revokeInvite, []),
  };

  return (
    <RoomInviteStateContext.Provider value={state}>
      <RoomInviteActionsContext.Provider value={actions}>
        {children}
      </RoomInviteActionsContext.Provider>
    </RoomInviteStateContext.Provider>
  );
};

const useRoomInviteState = (): RoomInviteState => {
  const context = useContext(RoomInviteStateContext);
  if (context === undefined) {
    throw new Error('useRoomInviteState must be used within a RoomInviteProvider');
  }
  return context;
};

const useRoomInvitesActions = (): RoomInviteActions => {
  const context = useContext(RoomInviteActionsContext);
  if (context === undefined) {
    throw new Error('useRoomInvitesActions must be used within a RoomInviteProvider');
  }
  return context;
};

export { RoomInviteProvider, useRoomInviteState, useRoomInvitesActions };
