import {
  createContext,
  useReducer,
  FunctionComponent,
  useContext,
  useCallback,
  useRef,
  useEffect,
} from 'react';

import { LineItem, PlanData } from 'ts-frontend/types';
import {
  calculateTotals,
  transformPlan,
  generateEligibilityLineItems,
} from 'ts-frontend/helpers/billingUtils';
import InsuranceEligibilityAPIHelper, {
  InsuranceEligibilityInfo,
} from '../utils/InsuranceEligibilityAPIHelper';
import { GENERAL_ERROR, GENERAL_VERIFICATION_ERROR, NOT_ELIGIBLE_ERROR } from '../utils/constants';

const parseVerificationError = (e: Error) =>
  Number.isNaN(Number(e.message)) ? e.message : GENERAL_VERIFICATION_ERROR;

const parsePurchaseError = (e: Error) =>
  Number.isNaN(Number(e.message)) ? e.message : GENERAL_ERROR;

type ActionStatus = 'loading' | 'error' | 'success';

interface InsuranceEligibilityState {
  eligibilityInfo?: InsuranceEligibilityInfo;
  planInfo?: PlanData;
  lineItems: LineItem[];
  savings: number;
  verificationStatus?: ActionStatus;
  verificationError: string;
  purchaseStatus?: ActionStatus;
  purchaseError: string;
}

export interface InsuranceEligibilityActions {
  verifyEligibility: (roomID: number) => void;
  purchaseBHSession: (roomID: number, cardToken?: string) => void;
  resetError: () => void;
}

const InsuranceEligibilityStateContext = createContext<InsuranceEligibilityState | undefined>(
  undefined
);
const InsuranceEligibilityActionsContext = createContext<InsuranceEligibilityActions | undefined>(
  undefined
);

const insuranceEligibilityReducer = (
  currentState: InsuranceEligibilityState,
  action: { payload?: Partial<InsuranceEligibilityState> }
): InsuranceEligibilityState => {
  return { ...currentState, ...action.payload };
};

const initialState: InsuranceEligibilityState = {
  eligibilityInfo: undefined,
  planInfo: undefined,
  lineItems: [],
  savings: 0,
  verificationStatus: undefined,
  verificationError: '',
  purchaseStatus: undefined,
  purchaseError: '',
};

export const InsuranceEligibilityProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(insuranceEligibilityReducer, initialState);

  const { current: api } = useRef(new InsuranceEligibilityAPIHelper());

  useEffect(() => () => api.reset(), [api]);

  const verifyEligibility = (roomID: number) => {
    dispatch({
      payload: {
        verificationStatus: 'loading',
      },
    });

    api
      .verifyInsuranceEligibility(roomID)
      .then((eligibilityResponse) => {
        const { copayCents, maximumCost, isEligible } = eligibilityResponse.data;
        if (!isEligible) {
          throw new Error(NOT_ELIGIBLE_ERROR);
        }
        api.getRoomPlan(roomID).then((roomPlanResponse) => {
          const lineItems = generateEligibilityLineItems({
            maximumCost,
            copayCents,
            currency: roomPlanResponse.data.currency,
          });
          dispatch({
            payload: {
              verificationStatus: 'success',
              eligibilityInfo: {
                copayCents,
                maximumCost,
                isEligible,
              },
              planInfo: transformPlan(roomPlanResponse.data),
              lineItems,
              savings: calculateTotals(lineItems, maximumCost).savings,
            },
          });
        });
      })
      .catch(api.dismissIfCancelled)
      .catch((e) => {
        dispatch({
          payload: {
            verificationStatus: 'error',
            verificationError: parseVerificationError(e),
          },
        });
      });
  };

  const purchaseBHSession = (roomID: number, cardToken?: string) => {
    dispatch({ payload: { purchaseStatus: 'loading' } });
    api
      .purchaseBHSession(roomID, cardToken)
      .then(() => {
        dispatch({
          payload: { purchaseStatus: 'success' },
        });
      })
      .catch(api.dismissIfCancelled)
      .catch((e) => {
        dispatch({
          payload: {
            purchaseStatus: 'error',
            purchaseError: parsePurchaseError(e),
          },
        });
      });
  };

  const resetError = () => {
    dispatch({
      payload: {
        purchaseStatus: undefined,
        purchaseError: '',
      },
    });
  };

  const actions: InsuranceEligibilityActions = {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    verifyEligibility: useCallback(verifyEligibility, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    purchaseBHSession: useCallback(purchaseBHSession, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    resetError: useCallback(resetError, []),
  };

  return (
    <InsuranceEligibilityStateContext.Provider value={state}>
      <InsuranceEligibilityActionsContext.Provider value={actions}>
        {children}
      </InsuranceEligibilityActionsContext.Provider>
    </InsuranceEligibilityStateContext.Provider>
  );
};

export const useInsuranceEligibilityState = () => {
  const context = useContext(InsuranceEligibilityStateContext);
  if (context === undefined) {
    throw new Error(
      'useInsuranceEligibilityState must be used within a InsuranceEligibilityProvider'
    );
  }
  return context;
};

export const useInsuranceEligibilityActions = () => {
  const context = useContext(InsuranceEligibilityActionsContext);
  if (context === undefined) {
    throw new Error(
      'useInsuranceEligibilityActions must be used within a InsuranceEligibilityProvider'
    );
  }
  return context;
};
