import { useEffect, useState } from 'react';

import useQuerySessionStatus from 'ts-frontend/hooks/useQuerySessionStatus';
import useMutationCreateAsyncSession from 'inRoomScheduling/hooks/useMutationCreateAsyncSession';
import { useParams } from '@/core/routerLib';
import { useMainActions, useMainState } from '@/hooks/mainContext';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { getUserData } from '@/auth/helpers/token';

import { extractRoomEntitiesFromState } from '../../utils/extractRoomEntitiesFromState';
import ApiHelper from '../../utils/ApiHelper';
import PostAsyncPromptCTAScreen from '../components/PostAsyncPromptCTAScreen';
import PostAsyncPromptStartMessagingScreen from '../components/PostAsyncPromptStartMessagingScreen';
import PostAsyncPromptConfirmationScreen from '../components/PostAsyncPromptConfirmationScreen';
import PostAsyncPromptCancellationPolicyScreen from '../components/PostAsyncPromptCancellationPolicyScreen';

type PostAsyncPromptScreenType = 'cta' | 'start-messaging' | 'confirmation' | 'cancellation-policy';

const PostAsyncPromptContainer = () => {
  const { id: userID } = getUserData();
  const { roomID, userPromptID } = useParams<{ roomID: string; userPromptID: string }>();
  const [currentScreen, setCurrentScreen] = useState<PostAsyncPromptScreenType>('cta');
  const { data: sessionStatusData } = useQuerySessionStatus(Number(roomID));
  const mainState = useMainState();
  const { getAllRooms, getLVSSubscriptions } = useMainActions();
  const closeModal = useCloseModal();

  const [entities, setEntities] = useState<ReturnType<typeof extractRoomEntitiesFromState>>();

  const {
    mutate: createAsyncSession,
    isLoading: isLoadingCreateAsyncSession,
    isError: isErrorCreateAsyncSession,
  } = useMutationCreateAsyncSession();

  useEffect(() => {
    new ApiHelper().updateUserPromptToDone(Number(roomID), Number(userPromptID));
    getAllRooms(userID);
    getLVSSubscriptions();
  }, [getAllRooms, getLVSSubscriptions, roomID, userID, userPromptID]);

  useEffect(() => {
    setEntities(extractRoomEntitiesFromState(mainState, Number(roomID)));
  }, [mainState, roomID]);

  const { therapist, room, subscription } = entities || {};
  if (!therapist || !room || !subscription) return null;

  switch (currentScreen) {
    case 'cta':
      return (
        <PostAsyncPromptCTAScreen
          providerName={therapist.firstName}
          sessionEndDate={sessionStatusData?.previousMessagingSession?.endDate || ''}
          sessionsRemaining={subscription.videoCredits?.[0]?.creditRemaining || 0}
          onContinue={() => setCurrentScreen('start-messaging')}
        />
      );
    case 'start-messaging':
      return (
        <PostAsyncPromptStartMessagingScreen
          onContinue={() => {
            createAsyncSession(
              {
                roomID: Number(roomID),
                data: { isPurchase: false, funnelName: 'Post Async Prompt' },
              },
              { onSuccess: () => setCurrentScreen('confirmation') }
            );
          }}
          isLoading={isLoadingCreateAsyncSession}
          isError={isErrorCreateAsyncSession}
          onOpenCancellationPolicy={() => setCurrentScreen('cancellation-policy')}
        />
      );
    case 'confirmation':
      return (
        <PostAsyncPromptConfirmationScreen
          room={room}
          therapist={therapist}
          onContinue={() => closeModal()}
        />
      );
    case 'cancellation-policy':
      return (
        <PostAsyncPromptCancellationPolicyScreen
          onBack={() => setCurrentScreen('start-messaging')}
        />
      );
    default:
      return null;
  }
};

export default PostAsyncPromptContainer;
