import { ValueType } from 'react-select';
import {
  cancellationReasons,
  cancellationReasonsV2,
  dissatisfiedWithProviderReasons,
} from '@talkspace/configs';
import { OptionType, shuffleArray } from '@talkspace/react-toolkit';
import { BaseWizardState, baseWizardInitialState, keyValueToValueType } from 'stepWizard';
import { FirstPurchase } from 'ts-frontend/types';
import { Flow } from '@/cancellationWizardsShared/utils/cancellationWizardTypes';
import { AggregatedRoomData } from '@/hooks/useNavShellData';

export interface State extends BaseWizardState {
  roomID: number;
  status: number | null;
  clientUserID: number;
  isEligibleForCoupon: boolean;
  couponDiscountDisplay: string;
  couponApplied: boolean;
  providerSwitched: boolean;
  subscriptionPaused: boolean;
  subscribedToMaintenance: boolean;
  canceledSubscription: boolean;
  cancellationReason: string;
  reasonForCancellation: ValueType<OptionType, true>;
  displayDiscountScreen: boolean;
  hideDisplayDiscountScreen: boolean;
  couponCodes: string[];
  couponCodeIndex: number;
  maintenancePlanID?: number;
  planPriceDisplay?: string;
  cancellationStepButtonText: string;
  isEligibleForFinancialAid: boolean;
  showFinancialAidScreen: boolean;
  hideFinancialAidScreen: boolean;
  shouldDisplayMaintenanceOffer: boolean;
  cancellationReasonsV2: ValueType<OptionType, true>;
  cancellationReasonV2: string;
  isFeelingBetterReason: boolean;
  isDissatisfiedWithProviderReason: boolean;
  isDissatisfiedWithAppReason: boolean;
  isExpensiveReason: boolean;
  isOtherReason: boolean;
  isConfirmCancellationHappy: boolean;
  dissatisfiedWithProviderReasons: ValueType<OptionType, true>;
  dissatisfiedWithProviderReason: string;
  isDissatisfiedWithProviderOther: boolean;
  flow?: Flow;
  experiment: object;
  experimentID: string;
  planModality?: string;
  firstPurchase?: FirstPurchase;
  didLiveSession?: boolean;
  roomData: AggregatedRoomData;
}

// eslint-disable-next-line import/prefer-default-export
export const getInitialState = (
  clientUserID: number,
  roomID: number,
  source: string,
  contextID: string
): State => {
  return {
    ...baseWizardInitialState(source, contextID),
    roomID,
    status: null,
    clientUserID,
    isEligibleForCoupon: false,
    couponDiscountDisplay: '0',
    couponApplied: false,
    providerSwitched: false,
    subscriptionPaused: false,
    subscribedToMaintenance: false,
    reasonForCancellation: shuffleArray(keyValueToValueType(cancellationReasons)) as ValueType<
      OptionType<string>,
      true
    >,
    cancellationReason: '',
    canceledSubscription: false,
    displayDiscountScreen: false,
    hideDisplayDiscountScreen: true,
    eventCategory: 'Cancellation',
    couponCodes: [],
    couponCodeIndex: 0,
    cancellationStepButtonText: 'Cancel subscription renewal',
    isEligibleForFinancialAid: false,
    showFinancialAidScreen: false,
    hideFinancialAidScreen: true,
    shouldDisplayMaintenanceOffer: true,
    cancellationReasonsV2: shuffleArray(keyValueToValueType(cancellationReasonsV2)) as ValueType<
      OptionType<string>,
      true
    >,
    cancellationReasonV2: '',
    isFeelingBetterReason: false,
    isDissatisfiedWithProviderReason: false,
    isDissatisfiedWithAppReason: false,
    isExpensiveReason: false,
    isOtherReason: false,
    isConfirmCancellationHappy: false,
    dissatisfiedWithProviderReasons: keyValueToValueType(dissatisfiedWithProviderReasons),
    dissatisfiedWithProviderReason: '',
    isDissatisfiedWithProviderOther: false,
    experiment: {},
    experimentID: '',
    roomData: {},
  };
};
