import { FunctionComponent } from 'react';
import { BaseButton, View, Large, ExtraHuge, RedX } from '@talkspace/react-toolkit';

import styled from '../../../core/styled/styled';

const CustomerSupportButton = styled(BaseButton)(({ theme: { colors } }) => {
  return { marginTop: 0, backgroundColor: colors.white };
});

const CloseButton = styled(BaseButton)(({ theme: { colors } }) => {
  return { marginTop: 30, marginBottom: 60, backgroundColor: colors.white };
});

interface Props {
  onContactCustomerSupportPress: () => void;
  onClosePress: () => void;
}

const Error: FunctionComponent<Props> = (props) => {
  const { onContactCustomerSupportPress, onClosePress } = props;

  return (
    <>
      <View style={{ marginBottom: 10 }}>
        <RedX size={74} />
      </View>
      <ExtraHuge style={{ width: 282, marginBottom: 30 }} dataQa="couponError">
        The coupon you tried is not valid
      </ExtraHuge>
      <Large variant="largeDarkGrey" dataQa="couponErrorTitle">
        Need help with a coupon?
      </Large>
      <CustomerSupportButton
        onPress={onContactCustomerSupportPress}
        dataQa="couponErrorContactButton"
      >
        <Large variant="largeBoldWideGreen">Contact customer support</Large>
      </CustomerSupportButton>
      <CloseButton onPress={onClosePress} dataQa="couponErrorCloseButton">
        <Large variant="largeDarkGrey" dataQa="couponError">
          Close
        </Large>
      </CloseButton>
    </>
  );
};

export default Error;
