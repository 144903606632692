import { FunctionComponent } from 'react';
import { RouteComponentProps } from '../../core/routerLib/routerLib';
import MoreInfoModal from '../components/MoreInfoModal';

const MoreInfoModalContainer: FunctionComponent<
  RouteComponentProps<
    {},
    {},
    {
      assessmentLabel: string;
      moreInfoText: string;
    }
  >
> = ({ location }) => (
  <MoreInfoModal
    assessmentLabel={location.state.assessmentLabel}
    moreInfoText={location.state.moreInfoText}
  />
);

export default MoreInfoModalContainer;
