import { WizardScheme } from 'stepWizard';

const b2bCancellationWizardScheme: WizardScheme = {
  wizardVersion: 1,
  wizardType: 'b2bCancellation',
  wizardModalTitle: 'cancel plan',
  steps: [
    {
      title: 'How has your experience with your current Talkspace provider been?',
      subtitle:
        "Consider only your provider's performance in providing care, not any app or site issues you may have encountered. This feedback will be anonymously shared with your provider.",
      inputType: 'therapistReview',
      inputState: 'therapistReview',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'autoAdvance',
      },
      secondaryButton: {
        displayText: 'I have not interacted enough with this provider to rate them',
        defaultValue: 'skip-have-not-interacted-enough',
        actionDispatch: `next`,
        whenVisible: 'always',
        displayStyle: 'secondaryFooter',
      },
      name: 'Therapist Review',
      additionalInfo: ['therapistInfo'],
    },
    {
      title: 'Would you be open to trying a new provider?',
      subtitle:
        'We have over 3,000 licensed therapists in our network and are confident we can find you a better match.',
      inputState: 'providerSwitched',
      nextButton: {
        displayText: 'Yes, work with a new provider',
        actionDispatch:
          '/room/:roomID/my-account/manage-subscription?action=cancellation-switch-exit&source=:source&contextID=:contextID',
        whenVisible: 'always',
        source: 'b2b-cancellation',
        fullReload: true,
        persistOnDispatch: true,
      },
      secondaryButton: {
        displayText: "No, I'm not interested in a new provider",
        actionDispatch: 'next',
        whenVisible: 'always',
      },
      name: 'Switch provider',
      backOption: 'back',
    },
    {
      title: 'Please tell us why you are cancelling your plan today.',
      inputType: 'select',
      inputState: 'cancellationReason',
      inputOptions: 'reasonForCancellation',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      name: 'Reason for cancelling',
      backOption: 'back',
    },
    {
      title: `We're sorry to hear that you are canceling your plan.`,
      subtitle: `If there is anything we can do to improve your experience, please reach out to our customer support team.`,
      inputState: '',
      nextButton: {
        displayText: 'Cancel my plan',
        actionDispatch: '/cancel-non-subscription/cancel',
        whenVisible: 'always',
      },
      name: 'Sure you want to cancel',
      backOption: 'back',
    },
  ],
};

export default b2bCancellationWizardScheme;
