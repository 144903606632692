import { useReducer, useCallback, useRef } from 'react';
import { wizardActionReducer, wizardActionInitialState, WizardActionState } from 'stepWizard';
import paymentApiHelper from 'offer/utils/paymentApiHelper';
import financialAidWizardApiHelper from '../utils/financialAidWizardApiHelper';

interface financialAidState extends WizardActionState {
  discountAmountInPercent: number;
  eligibilityToken: string;
}

export default function useFinancialAidWizard(): [
  financialAidState,
  {
    getFinancialAidEligibilityToken: (roomID: number, userAnswers: object) => void;
    applyFinancialAid: (token: string) => void;
    exitAction: () => void;
  }
] {
  const additionalStateProps = {
    discountAmountInPercent: 0,
    eligibilityToken: '',
  };

  const [state, dispatch] = useReducer(
    wizardActionReducer,
    wizardActionInitialState(additionalStateProps)
  );
  const apiRefFinancialAidWizard = useRef(financialAidWizardApiHelper);
  const apiRefPayment = useRef(paymentApiHelper);

  function getFinancialAidEligibilityToken(roomID, userAnswers) {
    dispatch({ type: 'requestAction' });
    apiRefFinancialAidWizard.current
      .getFinancialAidEligibilityToken(roomID, userAnswers)
      .then((data) => {
        const discountAmountInPercent = data && data.data.discountAmountInPercent;
        const eligibilityToken = data && data.data.token;
        dispatch({
          type: 'receiveAction',
          payload: { discountAmountInPercent, eligibilityToken },
        });
      })
      .catch((error) => {
        dispatch({
          type: 'setError',
          error: (error && error.message) || '',
        });
      });
  }

  function applyFinancialAid(token) {
    dispatch({ type: 'requestAction' });
    apiRefPayment.current
      .applyCouponByType(token)
      .then(() => {
        dispatch({
          type: 'receiveAction',
        });
      })
      .catch((error) => {
        dispatch({
          type: 'setError',
          error: (error && error.message) || '',
        });
      });
  }

  function exitAction() {
    dispatch({ type: 'exitAction' });
  }

  return [
    state as financialAidState,
    {
      getFinancialAidEligibilityToken: useCallback(getFinancialAidEligibilityToken, []),
      applyFinancialAid: useCallback(applyFinancialAid, []),
      exitAction: useCallback(exitAction, []),
    },
  ];
}
