/* eslint-disable react/no-array-index-key */
import { FunctionComponent } from 'react';
import { View, Large, Carousel } from '@talkspace/react-toolkit';
import { SurveyResult } from 'ts-frontend/types';
import SurveyResultView from '../SurveyResultView';

/**
 * ((680 - 340) / 2)  - (20 + 8)
 * (20 + 8) = Arrow width with horizontal padding (left and right)
 * 680 - Max container width (Card * 2 - Arrows don't show when there are < 2 cards)
 * 340 - Card / Result max width (hard-coded in SurveyResultView)
 * Division by 2 - Splits the container available space in half for each arrow
 * Since the arrows are absolutely position, this makes it so that the arrow
 * never touches the card container.
 * Will need a more elegant solution when we re-use the Carousel
 */
const ARROW_HORIZONTAL_MARGIN = 142;

const SurveyResults: FunctionComponent<{
  surveyResultsByLabel?: { [key: string]: SurveyResult[] };
}> = ({ surveyResultsByLabel }) => {
  const surveyLabels = Object.keys(surveyResultsByLabel || {});
  if (!surveyResultsByLabel || !surveyLabels.length) return null;
  return (
    <View align="center" aria-label="survey results">
      {surveyLabels.length === 1 ? (
        <SurveyResultView
          surveyResult={surveyResultsByLabel[surveyLabels[0]][0]}
          prevSurveyResult={surveyResultsByLabel[surveyLabels[0]][1]}
        />
      ) : (
        <Carousel
          containerStyle={{ maxWidth: 680 }}
          arrowHorizontalMargin={ARROW_HORIZONTAL_MARGIN}
        >
          {surveyLabels.map((label, index) => (
            <SurveyResultView
              surveyResult={surveyResultsByLabel[label][0]}
              prevSurveyResult={surveyResultsByLabel[label][1]}
              key={index}
            />
          ))}
        </Carousel>
      )}
      <Large variant="largeLightGrey" style={{ width: 340, fontWeight: 500 }}>
        Discussion points
      </Large>
      <View as="ul" style={{ marginTop: 2, marginBottom: 18, maxWidth: 380 }}>
        <Large variant="largeDarkGrey" as="li" style={{ marginTop: 9 }}>
          What does my score mean?
        </Large>
        <Large variant="largeDarkGrey" as="li" style={{ marginTop: 9 }}>
          What are some strategies I can practice to manage my symptoms better?
        </Large>
        <Large variant="largeDarkGrey" as="li" style={{ marginTop: 9 }}>
          Lately I’ve been feeling…
        </Large>
      </View>
    </View>
  );
};

export default SurveyResults;
