import Svg, { Defs, Path, G, Use } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const Error = ({ ...props }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'error';
  return (
    <Svg
      width="74"
      height="74"
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 74 74"
      {...props}
    >
      <Defs>
        <Path
          id="b"
          d="M0 10.003C0 4.48 4.475 0 10.002 0h479.996C495.522 0 500 4.48 500 10.003v345.994c0 5.524-4.475 10.003-10.002 10.003H10.002C4.478 366 0 361.52 0 355.997V10.003z"
        />
        <filter
          id="a"
          width="104.4%"
          height="106%"
          x="-2.2%"
          y="-1.9%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology in="SourceAlpha" radius="2" result="shadowSpreadOuter1" />
          <feOffset dy="4" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0.156418748 0 0 0 0 0.255632926 0 0 0 0.4 0"
          />
        </filter>
      </Defs>
      <G
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-563 -319)"
      >
        <Path fill={colors.fordGrey} d="M0 0H1200V850H0z" />
        <G transform="translate(350 242)">
          <Use fill={colors.trueBlack} xlinkHref="#b" href="#b" />
          <Path
            fill={colors.white}
            stroke={colors.permaLinkWaterGrey}
            strokeLinejoin="round"
            d="M.5 10.003v345.994c0 5.248 4.254 9.503 9.502 9.503h479.996a9.5 9.5 0 009.502-9.503V10.003c0-5.248-4.254-9.503-9.502-9.503H10.002A9.5 9.5 0 00.5 10.003z"
          />
        </G>
        <Path
          stroke={colors.heartRed}
          strokeWidth="4.4"
          d="M54.387 6.839C39.412-4.446 18.124-1.454 6.84 13.52-4.446 28.497-1.454 49.785 13.52 61.07c14.976 11.285 36.264 8.293 47.549-6.683 11.285-14.975 8.293-36.263-6.683-47.548h0z"
          transform="translate(423 322) translate(143)"
        />
        <Path
          fill={colors.heartRed}
          d="M37.05 33.952l12.391 12.392a2.19 2.19 0 11-3.097 3.097l-12.392-12.39-12.39 12.39a2.19 2.19 0 11-3.099-3.097l12.392-12.392-12.392-12.39a2.19 2.19 0 113.098-3.099l12.391 12.392 12.392-12.392a2.19 2.19 0 113.097 3.098l-12.39 12.391z"
          transform="translate(423 322) translate(143)"
        />
      </G>
    </Svg>
  );
};

export default Error;
