import { FunctionComponent } from 'react';
import { View, Tiny, Large } from '@talkspace/react-toolkit';
import styled, { EmotionStyle } from '../core/styled';

export interface DetailsRowProps {
  title?: string;
  text?: string;
  textItems?: string[];
  style?: EmotionStyle;
  shouldRenderTextItemsInline?: boolean;
  renderLeft?: () => JSX.Element;
  isCollapsed?: boolean;
}

const Wrapper = styled(View)({
  marginBottom: 8,
  marginTop: 11,
  alignSelf: 'stretch',
});

const TextItems: FunctionComponent<{
  textItems: string[];
  title?: string;
  shouldRenderTextItemsInline?: boolean;
  isCollapsed?: boolean;
}> = ({ textItems, shouldRenderTextItemsInline, title, isCollapsed }) =>
  shouldRenderTextItemsInline ? (
    <Large style={{ textAlign: 'left' }}>
      {isCollapsed ? textItems.slice(0, 2).join(', ') : textItems.join(', ')}
    </Large>
  ) : (
    <View>
      {textItems.map((t, i) => (
        <Large
          key={`t ${i}` /* eslint-disable-line react/no-array-index-key */}
          style={{ marginBottom: i === 0 && !title ? 3 : 0 }}
        >
          {t}
        </Large>
      ))}
    </View>
  );

const DetailsRow: FunctionComponent<DetailsRowProps> = ({
  title,
  text,
  textItems,
  renderLeft,
  isCollapsed,
  shouldRenderTextItemsInline = false,
  ...otherProps
}) => {
  if (textItems && textItems.length === 0) return null;
  if (!text && !textItems) return null;
  return (
    <Wrapper {...otherProps}>
      <View row>
        {renderLeft && <View style={{ marginTop: 3, minWidth: 38 }}>{renderLeft()}</View>}
        <View flex={1}>
          {title && (
            <Tiny variant="tinyAllCaps" style={{ marginBottom: 3 }}>
              {title}
            </Tiny>
          )}
          {text ? (
            <Large style={{ textAlign: 'left' }}>{text}</Large>
          ) : (
            textItems && (
              <TextItems
                title={title}
                textItems={textItems}
                shouldRenderTextItemsInline={shouldRenderTextItemsInline}
                isCollapsed={isCollapsed}
              />
            )
          )}
        </View>
      </View>
    </Wrapper>
  );
};

export default DetailsRow;
