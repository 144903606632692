import { useMutation } from 'react-query';
import { APINotificationPreference, NotificationPreference } from 'ts-frontend/types/Notifications';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { getUserData } from '../../auth/helpers/token';

export interface APIResponse {
  data: { categories: APINotificationPreference[] };
}

export interface Payload {
  categories: NotificationPreference[];
}

const patchNotificationPreferences = async ({ categories }: Payload): Promise<any> => {
  const userID = getUserData().id;
  const queryParams: Payload = {
    categories,
  };

  const response = await apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v2/clients/${userID}/communication-preferences`,
    queryParams
  );
  return response;
};
const useMutationPatchNotificationPreferences = () =>
  useMutation<
    void,
    {
      data?: { data };
    },
    Payload
  >(patchNotificationPreferences);

export default useMutationPatchNotificationPreferences;
