import { FunctionComponent, useEffect, useState } from 'react';
import { ExtraHuge, View } from '@talkspace/react-toolkit';
import { routePromise } from 'ts-frontend/helpers';
import { getAccessToken } from '@/auth/helpers/token';
import styled from '@/core/styled';
import { useClientAuthActions, useClientAuthState } from '@/hooks/clientAuthContext';
import LoadingScreen from '@/screens/LoadingScreen';
import getParamByName from '@/utils/queryString';
import { useAttribution } from '@/hooks/useAttribution';
import ProviderSSOCallbackError from '../ProviderSSOCallbackError';
import isInWebView from '../SSOLogin/isInWebView';
import { useCloseModal } from '../../../utils/ModalsContextProvider';
import { RouteComponentProps, withRouter } from '../../../core/routerLib/routerLib';

const HeaderContainer = styled(View)({
  marginLeft: 17,
  marginRight: 17,
  marginBottom: 15,
  marginTop: 55,
  textAlign: 'center',
});

const ProviderSSOCallback: FunctionComponent<
  RouteComponentProps<{ provider?: string }, {}, { redirect?: string }>
> = ({ match }) => {
  let { provider } = match.params;
  provider = provider?.toLocaleLowerCase() || '';

  const { loginAetnaSSOAction, setErrorAction } = useClientAuthActions();
  const { isError, isUpdating } = useClientAuthState();
  const [errorFromHash] = useState(() => getParamByName('error_description'));
  const { data, isFetched } = useAttribution();
  const closeModal = useCloseModal();

  useEffect(() => {
    const loginAetna = async () => {
      const metadata = await loginAetnaSSOAction({
        loginHint: getParamByName('login_hint') || '',
        code: getParamByName('code') || '',
      });

      if (isInWebView()) {
        closeModal({
          navigateTo: 'dashboard',
          metadata,
        });
      }
    };

    if (provider === 'aetna') {
      loginAetna();
    } else {
      setErrorAction(`Unknown provider ${provider}`);
    }
  }, [errorFromHash, loginAetnaSSOAction, data, isFetched, closeModal, provider, setErrorAction]);

  useEffect(() => {
    if (isUpdating || isError || errorFromHash || !getAccessToken() || isInWebView()) {
      return;
    }

    routePromise('/login-success');
  });

  if (isError || errorFromHash) {
    return <ProviderSSOCallbackError />;
  }

  if (isInWebView()) {
    return (
      <View flex={1} align="center">
        <HeaderContainer>
          <ExtraHuge>redirecting</ExtraHuge>
        </HeaderContainer>
      </View>
    );
  }

  return <LoadingScreen />;
};

export default withRouter(ProviderSSOCallback);
