import { useEmotionTheme, View, Screen } from '@talkspace/react-toolkit';
import { WizardScreen, useWizardActions } from 'stepWizard';
import { useTranslation } from '@talkspace/i18n';
import { Route, Switch, useHistory } from '@/core/routerLib';
import useWindowWidth from '../../clientChat/hooks/useWindowWidth';
import medicalHealthScheme from './medicalHealthScheme';
import { SubmitMedicalHealth } from './containers/submitMedicalHealth';

const Routes = () => {
  const { onExitClick } = useWizardActions();
  const { isMobile } = useWindowWidth();
  const { colors } = useEmotionTheme();
  const { t: tAdultOnboarding } = useTranslation('adultOnboarding');
  const {
    location: { pathname },
  } = useHistory();

  const isOnboarding = pathname.includes('/onboarding');

  const showCloseButton = !isOnboarding;
  const scheme = medicalHealthScheme(tAdultOnboarding);
  const wizardScheme = {
    ...scheme,
    ...(isOnboarding ? { hideProgressIndicator: true } : {}),
  };
  return (
    <Switch>
      <View style={{ background: colors.white }}>
        <Screen
          safeAreaVariant="setHeightAndAddTop"
          onCloseButtonClick={onExitClick}
          title={isMobile ? 'Medical history intake' : ''}
          showCloseButton={showCloseButton}
          scrollViewStyles={{ padding: !isMobile ? '0 22px 22px 22px' : 0 }}
        >
          <Route
            exact
            path={[
              '/medical-health-wizard/submit',
              '/room/:roomID/onboarding/medical-health/source/:source/submit',
            ]}
            component={SubmitMedicalHealth}
          />
          <Route
            exact
            path={[
              '/medical-health-wizard/room/:roomID/source/:source',
              '/room/:roomID/onboarding/medical-health/source/:source',
            ]}
            render={() => <WizardScreen wizardScheme={wizardScheme} />}
          />
        </Screen>
      </View>
    </Switch>
  );
};

export default Routes;
