import { Room, RoomStatus } from 'ts-frontend/entities/Room';
import { EClient } from 'ts-frontend/entities/Client';
import { RoomInviteStatus } from '../hooks/useRoomInvites';

export const getIsInviteRoomWithMissingPartner = (room: Room, roomClients: EClient[]): boolean =>
  room.roomType === 'couples_room' && room.status === RoomStatus.ACTIVE && !roomClients;

export const getIsInviteRoomWithMissingPartnerAndNoInvites = (
  room: Room,
  roomClients: EClient[],
  roomInviteStatus?: RoomInviteStatus
): boolean =>
  getIsInviteRoomWithMissingPartner(room, roomClients) &&
  roomInviteStatus !== undefined &&
  roomInviteStatus !== RoomInviteStatus.HAS_ACTIVE_INVITE &&
  roomInviteStatus !== RoomInviteStatus.LOADING;
