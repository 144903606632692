import { View, TextDS, Button, styled } from '@talkspace/react-toolkit';
import { useHistory } from 'react-router';
import DailyPromptView from '../../components/DailyPromptView';
import { useCommunityState } from '../../hooks/CommunityContext';
import { trackCommunityOnboardingCTAPressed } from '../../utils/analytics';

const ButtonView = styled(View)(({ theme: { spacing } }) => {
  return {
    marginBottom: spacing('space200'),
    width: '100%',
  };
});

const PromptView = styled(View)(({ theme: { spacing } }) => {
  return {
    marginTop: spacing('space600'),
  };
});

const TitleView = styled(View)(({ theme: { spacing } }) => {
  return {
    marginTop: spacing('space400'),
  };
});

const TitleText = styled(TextDS)(({ theme: { spacing } }) => {
  return {
    marginTop: spacing('space150'),
  };
});

const ContinueButton = styled(Button)(() => {
  return {
    padding: '18px 24px',
    maxWidth: undefined,
    minWidth: '100px',
    borderRadius: '12px',
  };
});

const TryLaterButton = styled(Button)(() => {
  return {
    background: 'none',
    border: 'none',
  };
});

const Wrapper = styled(View)(() => {
  return {
    textAlign: 'center',
    height: '100%',
  };
});

const DailyQuestion = () => {
  const { dailyPrompt, replyCount } = useCommunityState();
  const history = useHistory();
  const onContinuePress = () => {
    trackCommunityOnboardingCTAPressed({ pageName: 'teenspaceDailyQuestionInfo' });
    history.push('/community/teenspace/onboarding/guidelines/continue');
  };
  const onTryLaterPress = () => {
    history.push('/community/teenspace/onboarding/guidelines/try-later');
  };
  return (
    <Wrapper align="center" justify="space-between">
      <View align="center">
        <TitleView>
          <TextDS dataQa="answerTheDailyQuestionText" variant="heading2xl">
            Answer the daily question
          </TextDS>
        </TitleView>
        <TitleText variant="body">
          Each day, you can see a new topic, respond anonymously, and support others. Give it a try!
        </TitleText>
        <PromptView>
          <DailyPromptView sentence={dailyPrompt && dailyPrompt.text} replyCount={replyCount} />
        </PromptView>
      </View>

      <ButtonView align="center">
        <ContinueButton
          size="large"
          stretch
          dataQa="teenspaceShareYourThoughtsButton"
          onPress={onContinuePress}
          text="Share your thoughts"
        />
        <TryLaterButton
          size="large"
          dataQa="teenspaceTryLaterButton"
          isSecondary
          onPress={onTryLaterPress}
          stretch
          variant="secondary"
          text="I’ll try it later"
        />
      </ButtonView>
    </Wrapper>
  );
};

export default DailyQuestion;
