import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useEmotionTheme, View, TouchableView } from '@talkspace/react-toolkit';
import DailyPrompt from './DailyPrompt';
import { LOCAL_STORAGE_LAST_CHECKED_DAILY_PROMPT_TIMESTAMP } from '../constants';
import TeenspaceLeftWing from '../../assets/icons/TeenspaceLeftWing';
import TeenspaceRightWing from '../../assets/icons/TeenspaceRightWing';
import { hasSeenDailyPromptToday } from '../utils/communityStorage';
import { trackDailyPromptRevealed } from '../utils/analytics';

const DailyPromptView = ({ sentence, replyCount }) => {
  const [hasRevealedDailyPrompt, setHasRevealedDailyPrompt] = useState(() =>
    hasSeenDailyPromptToday()
  );
  const { colorRoles } = useEmotionTheme();

  const answerCountText = `${replyCount} answers`;
  const isReplyCountValid = replyCount && replyCount !== 0;
  const dailyQuestion =
    hasRevealedDailyPrompt && sentence ? sentence : 'Click to reveal the question';
  const history = useHistory();

  const isInOnboardingFlow =
    history.location.pathname === '/community/teenspace/onboarding/daily-question';
  const amountOfReplies =
    hasRevealedDailyPrompt && isReplyCountValid && !isInOnboardingFlow ? answerCountText : '';

  useEffect(() => {
    setHasRevealedDailyPrompt(hasRevealedDailyPrompt === true);
  }, [hasRevealedDailyPrompt]);

  const shouldEnableOnPress = !isInOnboardingFlow && hasRevealedDailyPrompt;
  const onPress = () => {
    if (shouldEnableOnPress) {
      history.push('/home/teenspace');
    }
  };

  const handleDailyPromptReveal = () => {
    setHasRevealedDailyPrompt(true);
    trackDailyPromptRevealed();
    localStorage.setItem(
      LOCAL_STORAGE_LAST_CHECKED_DAILY_PROMPT_TIMESTAMP,
      new Date().toISOString()
    );
  };

  return (
    <TouchableView onPress={onPress} row style={{ width: '100%' }} data-qa="teenspaceDailyPrompt">
      <View style={{ flexShrink: 0 }}>
        <TeenspaceLeftWing />
      </View>
      <View
        flex={1}
        justify="center"
        align="center"
        style={{
          backgroundColor: colorRoles.surfaces.decorativeLiftedLemon,
          width: '100%',
        }}
      >
        <DailyPrompt
          sentence={dailyQuestion}
          replyCount={amountOfReplies}
          handleDailyPromptReveal={handleDailyPromptReveal}
          onPress={shouldEnableOnPress ? onPress : undefined}
        />
      </View>
      <View style={{ flexShrink: 0 }}>
        <TeenspaceRightWing />
      </View>
    </TouchableView>
  );
};

export default DailyPromptView;
