import { FunctionComponent } from 'react';

import { useEmotionTheme, View } from '@talkspace/react-toolkit';
import { useNewMemberNav } from 'launchDarkly/hooks';
import BaseAccordion from './Base';
import NotificationTitle from '../NotificationTitle';
import NotificationPanelRow from '../NotificationPanelRow';
import { arrowContainerStyles, bodyStyle, titleContainerStyles } from '../../myAccountStyles';

interface Props {
  isOpen: boolean;
  handlePress: (value: boolean) => void;
  isMobile: boolean;
  communityComments: { push: boolean };
  communityDailyPrompt: { push: boolean };
  handleDailyQuestionPushToggle: (value: number) => void;
  handleCommentsPushToggle: (value: number) => void;
}

const TeenspaceNotificationsAccordion: FunctionComponent<Props> = ({
  isOpen,
  handlePress,
  isMobile,
  communityComments,
  communityDailyPrompt,
  handleDailyQuestionPushToggle,
  handleCommentsPushToggle,
}) => {
  const { colors, colorRoles } = useEmotionTheme();
  const useNewNav = useNewMemberNav();
  const subtitleDescription = [communityComments, communityDailyPrompt].some(
    (comments) => comments?.push
  )
    ? 'On'
    : 'Off';
  const arrowFillColor = useNewNav ? colorRoles.icons.iconDefaultDefault : colors.periwinkleGrey;

  return (
    <BaseAccordion
      bodyStyle={bodyStyle}
      titleContainerStyles={titleContainerStyles}
      open={isOpen}
      onPress={handlePress}
      disabled={false}
      titleComponent={() => (
        <View data-qa="teenspaceNotifications">
          <NotificationTitle
            title="Teenspace"
            isOpen={isOpen}
            isMobile={isMobile}
            subTitle={
              isOpen
                ? 'Receive notifications about activity in the Teenspace community'
                : subtitleDescription
            }
          />
        </View>
      )}
      arrowStyles={{ fill: arrowFillColor }}
      arrowContainerStyles={arrowContainerStyles}
      useNewNav={useNewNav}
    >
      {communityDailyPrompt && (
        <View data-qa="dailyQuestionNotificationToggle">
          <NotificationPanelRow
            label="Daily question"
            isOn={communityDailyPrompt.push}
            onToggle={handleDailyQuestionPushToggle}
            name="communityDailyPromptPush"
          />
        </View>
      )}
      {communityComments && (
        <View data-qa="communityCommentsNotificationToggle">
          <NotificationPanelRow
            label="Comments"
            isOn={communityComments.push}
            onToggle={handleCommentsPushToggle}
            name="communityCommentsPush"
          />
        </View>
      )}
    </BaseAccordion>
  );
};

export default TeenspaceNotificationsAccordion;
