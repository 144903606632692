import { FunctionComponent, useState } from 'react';
import {
  View,
  Big,
  Small,
  Button,
  useUniqueID,
  useEmotionTheme,
  TouchableView,
  Large,
} from '@talkspace/react-toolkit';
import camelCase from 'lodash/camelCase';
import * as React from 'react';
import styled, { EmotionStyle } from '../../../core/styled';

interface BannerProps {
  style?: EmotionStyle;
  isMobile: boolean;
  title?: string;
  dataQa?: string;
  secondaryTitle?: string;
  subtitle?: () => JSX.Element;
  secondarySubtitle?: string;
  iconComponent?: JSX.Element;
  buttonTitle?: string;
  secondaryButtonTitle?: string;
  primaryOnPress?: () => void;
  secondaryOnPress?: () => void;
  alternativeButtonTitle?: string;
  alternativeOnPress?: () => void;
}

const primaryButtonStyles = (
  isMobile: boolean,
  isMainViewDisplayed: boolean,
  backgroundColor: string
) => {
  const getWidth = (isMobileView: boolean, isMainView: boolean) => {
    if (isMobileView) {
      return isMainView ? '100%' : 200;
    }
    return 225;
  };

  return {
    width: getWidth(isMobile, isMainViewDisplayed),
    height: 44,
    backgroundColor,
  };
};

const secondaryButtonStyles = (backgroundColor: string) => {
  return { width: 97, height: 44, backgroundColor, marginLeft: 10 };
};

const BannerView = styled(View)(({ theme: { colors }, style }) => {
  return {
    borderRadius: 10,
    backgroundColor: colors.permaLinkWaterGrey,
    padding: 20,
    marginTop: 15,
    margin: '0 15px 15px 15px',
    ...style,
  };
});

const BannerHeader = styled(View)(() => {
  return {
    flexDirection: 'row',
  };
});

const ButtonRow = styled(View)(() => {
  return {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
  };
});

const BannerHeaderText = styled(View)(() => {
  return {
    paddingTop: 9,
    paddingLeft: 6,
    flex: '0 1 auto',
  };
});

const SummaryCardActionBanner: FunctionComponent<BannerProps> = ({
  style = {},
  isMobile = false,
  title,
  secondaryTitle,
  subtitle,
  secondarySubtitle,
  iconComponent,
  primaryOnPress,
  secondaryOnPress,
  secondaryButtonTitle,
  buttonTitle,
  alternativeButtonTitle,
  alternativeOnPress,
  dataQa,
}) => {
  const [isMainViewDisplayed, setIsMainViewDisplayed] = useState(true);
  const setOnPrimaryPress = primaryOnPress || (() => setIsMainViewDisplayed(false));
  const { colors } = useEmotionTheme();
  const subTitleID = useUniqueID('subTitleID');
  return (
    <BannerView style={style}>
      <View>
        <BannerHeader>
          {iconComponent}
          <BannerHeaderText>
            <Big>{isMainViewDisplayed ? title : secondaryTitle}</Big>
            <Small id={subTitleID}>
              {isMainViewDisplayed && subtitle ? subtitle() : secondarySubtitle}
            </Small>
          </BannerHeaderText>
        </BannerHeader>
        {buttonTitle && (
          <>
            <ButtonRow>
              <Button
                data-qa={dataQa}
                aria-describedby={subTitleID}
                text={isMainViewDisplayed ? buttonTitle : secondaryButtonTitle}
                onPress={isMainViewDisplayed ? setOnPrimaryPress : secondaryOnPress}
                style={primaryButtonStyles(isMobile, isMainViewDisplayed, colors.green)}
              />
              {!isMainViewDisplayed && (
                <Button
                  text="Cancel"
                  onPress={() => {
                    setIsMainViewDisplayed(true);
                  }}
                  style={secondaryButtonStyles(colors.periwinkleGrey)}
                />
              )}
            </ButtonRow>
            {alternativeButtonTitle && alternativeOnPress && (
              <TouchableView
                onPress={alternativeOnPress}
                primaryColor={colors.green}
                roundedFocusStyle
                style={{ alignItems: 'center', paddingTop: 20 }}
              >
                <Large variant="largeBoldWideGreen" dataQa={camelCase(alternativeButtonTitle)}>
                  {alternativeButtonTitle}
                </Large>
              </TouchableView>
            )}
          </>
        )}
      </View>
    </BannerView>
  );
};

export default SummaryCardActionBanner;
