import { FunctionComponent, useEffect } from 'react';
import { View, Button, BaseButton, Standard, ScrollView, Screen } from '@talkspace/react-toolkit';
import { SurveyResult } from 'ts-frontend/types';
import { useSurveysState, useSurveysActions } from '../hooks/surveysContext';
import { withRouter, RouteComponentProps } from '../../core/routerLib';
import { useCloseModal } from '../../utils/ModalsContextProvider';
import SurveyResults from '../components/SurveyResults';
import extractRoomEntities from '../../utils/extractRoomEntitiesFromState';
import { useMainActions, useMainState } from '../../hooks/mainContext';
import extractRoomSurveyResultsByLabel from '../utils/extractRoomSurveyResultsByLabel';
import { getUserData } from '../../auth/helpers/token';

// we don't want all the label results, just the labels of the current survey
function filterForCurrentLabelsOnly(
  surveyResultsByLabel: { [key: string]: SurveyResult[] },
  userRoomSurveyID?: number
): { [key: string]: SurveyResult[] } {
  return Object.entries(surveyResultsByLabel).reduce((acc, [label, surveys]) => {
    const shouldIncludeLabel = surveys.some((s) => s.userRoomSurveyID === userRoomSurveyID);
    return shouldIncludeLabel
      ? {
          ...acc,
          [label]: surveys,
        }
      : acc;
  }, {});
}

const SurveyResultsContainer: FunctionComponent<
  RouteComponentProps<{
    roomID: number;
    userRoomSurveyID: number;
  }>
> = ({ match }) => {
  const { surveyResultsByRoomID } = useSurveysState();
  const { dispatchGetSurveys } = useSurveysActions();
  const closeModal = useCloseModal();
  const { getAllRooms } = useMainActions();
  const { therapist } = extractRoomEntities(useMainState(), match.params.roomID);

  useEffect(() => {
    const { id } = getUserData();
    if (!therapist) {
      getAllRooms(id);
    }
  }, [getAllRooms, therapist]);

  useEffect(() => {
    dispatchGetSurveys();
  }, [dispatchGetSurveys]);

  const surveyResultsByLabel = Object.keys(surveyResultsByRoomID).length
    ? extractRoomSurveyResultsByLabel(surveyResultsByRoomID, match.params.roomID)
    : undefined;

  const currentSurveyResultsByLabel = surveyResultsByLabel
    ? filterForCurrentLabelsOnly(surveyResultsByLabel, +match.params.userRoomSurveyID)
    : undefined;

  const hasResults =
    currentSurveyResultsByLabel && Object.keys(currentSurveyResultsByLabel).length > 0;
  return (
    <Screen showCloseButton onCloseButtonClick={closeModal}>
      <View align="center" justify="center" style={{ paddingBottom: 38, overflowX: 'hidden' }}>
        <ScrollView style={{ minHeight: hasResults ? undefined : 300 }}>
          {hasResults && <SurveyResults surveyResultsByLabel={currentSurveyResultsByLabel} />}
        </ScrollView>
        <Button
          text={`Discuss with ${therapist?.firstName || 'your provider'}`}
          onPress={() =>
            closeModal({
              navigateTo: 'room',
              metadata: { roomID: match.params.roomID },
            })
          }
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflowX: 'hidden',
          }}
        />
        <BaseButton onPress={() => closeModal()} style={{ marginTop: 15 }}>
          <Standard variant="standardDarkGrey">Done</Standard>
        </BaseButton>
      </View>
    </Screen>
  );
};

export default withRouter(SurveyResultsContainer);
