import { forwardRef } from 'react';

import { View, spacing, useEmotionTheme } from '@talkspace/react-toolkit';

import CommunityPostCommentPreview from './CommunityPostCommentPreview';
import UserTag from './UserTag';
import CommunityReferenceThreeDotMenu from './CommunityReferenceThreeDotMenu';
import { CommunityReferenceTypeEnum } from '../types';
import PostToolbar from './PostToolbar';
import PostText from './PostText';

const { space100 } = spacing;

interface Props {
  post: Amity.Post;
}
const CommunityPost = forwardRef<HTMLDivElement, Props>(({ post }, ref) => {
  const { colorRoles } = useEmotionTheme();
  const { creator, commentsCount } = post;

  const showCommentPreview = commentsCount > 0;

  return (
    <View
      ref={ref}
      style={{ flex: 1, backgroundColor: colorRoles.surfaces.surfaceInteractiveDefault }}
    >
      <View
        row
        style={{ flex: 1, padding: `${space100}px 0` }}
        justify="space-between"
        align="center"
      >
        <UserTag user={creator} />
        <CommunityReferenceThreeDotMenu
          reference={post}
          referenceType={CommunityReferenceTypeEnum.POST}
        />
      </View>
      <PostText post={post} style={{ padding: `${space100}px 0` }} />
      <PostToolbar post={post} />
      {showCommentPreview && <CommunityPostCommentPreview post={post} />}
      <View style={{ height: space100, backgroundColor: colorRoles.surfaces.neutralDefault }} />
    </View>
  );
});

export default CommunityPost;
