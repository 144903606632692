import { EmotionStyle } from '@talkspace/react-toolkit';
import Svg, { Path, Ellipse } from 'svgs';

const AllDoneTeens = ({ style }: { style?: EmotionStyle }) => {
  const title = 'All done';
  return (
    <Svg
      width={299}
      height={280}
      viewBox="0 0 299 280"
      fill="none"
      aria-label={title}
      title={title}
      style={style}
    >
      <Path
        d="M191.694 107.94l-74.886-12.284-22.71-3.648-7.789-1.226a5.54 5.54 0 00-.55-.065c.177.047.358.085.541.11l1.606.28 6.163 1.021 22.706 3.66 74.981 11.839c29.266 4.607 55.77 8.801 74.933 11.917 9.579 1.555 17.332 2.836 22.682 3.751 2.682.457 4.755.822 6.158 1.082.725.1 1.44.25 2.134.447a17.493 17.493 0 01-2.174-.248 459.45 459.45 0 01-6.192-.906c-5.37-.815-13.136-2.029-22.723-3.577-19.193-2.996-45.655-7.342-74.88-12.153z"
        fill="#D2E9E6"
      />
      <Path
        d="M190.244 112.239l-72.323-19.866-21.951-5.947-5.986-1.591-1.562-.4c-.35-.084-.529-.125-.538-.124.168.066.343.123.523.168l1.541.44 5.955 1.651 21.949 5.96 72.518 19.433c28.312 7.567 53.943 14.45 72.465 19.505a2876.696 2876.696 0 0121.905 6.052c2.583.725 4.582 1.302 5.931 1.703.702.176 1.383.397 2.034.66a16.905 16.905 0 01-2.124-.468 491.48 491.48 0 01-6.003-1.534c-5.204-1.36-12.725-3.36-21.994-5.863-18.546-4.999-44.119-12.007-72.34-19.779z"
        fill="#D2E9E6"
      />
      <Path
        d="M187.782 116.264l-67.949-27.056-20.641-8.134-5.638-2.177-1.472-.558a4.78 4.78 0 00-.509-.174c.152.081.312.154.48.217l1.447.593 5.586 2.241 20.639 8.142L187.983 116c26.651 10.39 50.763 19.822 68.172 26.717a2862.531 2862.531 0 0120.571 8.226c2.391.982 4.292 1.758 5.55 2.291.667.246 1.301.536 1.891.865a15.312 15.312 0 01-2.023-.684 426.367 426.367 0 01-5.672-2.134 2370.282 2370.282 0 01-20.702-8.053c-17.446-6.836-41.477-16.389-67.988-26.963z"
        fill="#D2E9E6"
      />
      <Path
        d="M184.381 119.908l-61.863-33.564-18.816-10.11-5.148-2.727-1.347-.702a3.575 3.575 0 00-.473-.225c.134.095.279.183.433.262l1.316.731 5.086 2.777 18.807 10.122 62.258 33.205c24.322 12.954 46.323 24.7 62.181 33.266 7.929 4.281 14.33 7.764 18.728 10.196a344.823 344.823 0 015.039 2.816c.609.312 1.177.66 1.695 1.04a15.004 15.004 0 01-1.874-.883c-1.171-.614-2.934-1.506-5.189-2.681a2445.173 2445.173 0 01-18.894-10.042c-15.913-8.519-37.796-20.371-61.939-33.481z"
        fill="#D2E9E6"
      />
      <Path
        d="M180.117 123.082l-54.328-39.2-16.538-11.831-4.537-3.198-1.188-.822a4.674 4.674 0 00-.442-.285c.112.108.237.21.373.304l1.126.843 4.458 3.24c3.924 2.805 9.562 6.846 16.536 11.84l54.851 38.908c21.408 15.176 40.764 28.933 54.695 38.944a2021.235 2021.235 0 0116.428 11.896c1.93 1.416 3.38 2.517 4.407 3.271a11.04 11.04 0 011.464 1.193 12.26 12.26 0 01-1.679-1.055 332.936 332.936 0 01-4.584-3.162 2212.288 2212.288 0 01-16.641-11.765c-13.991-9.966-33.213-23.813-54.401-39.121z"
        fill="#D2E9E6"
      />
      <Path
        d="M175.105 125.717L129.619 81.86l-13.873-13.25-3.811-3.607c-.438-.4-.771-.706-1.005-.927a2.323 2.323 0 00-.366-.307c.095.114.198.224.309.332l.957.945 3.73 3.62 13.859 13.257 46.033 43.612c17.964 17.004 34.233 32.453 45.91 43.639a1707.19 1707.19 0 0113.745 13.308 188.648 188.648 0 013.667 3.649c.454.414.852.854 1.188 1.312a10.973 10.973 0 01-1.435-1.205c-.901-.804-2.189-2.003-3.873-3.557a1722.26 1722.26 0 01-13.986-13.199c-11.735-11.153-27.828-26.642-45.563-43.765z"
        fill="#D2E9E6"
      />
      <Path
        d="M169.464 127.745l-35.531-47.444-10.865-14.345-2.989-3.907-.813-.991a3.033 3.033 0 00-.288-.338c.056.122.132.24.227.354.177.247.421.586.741 1.023l2.91 3.916 10.851 14.351 36.143 47.262c14.121 18.44 26.875 35.161 36.003 47.278 4.567 6.063 8.235 10.979 10.728 14.387a237.219 237.219 0 012.841 3.936c.36.45.66.919.894 1.399a8.81 8.81 0 01-1.168-1.318 253.786 253.786 0 01-3.068-3.868 1853.482 1853.482 0 01-10.983-14.307c-9.207-12.108-21.779-28.869-35.633-47.388z"
        fill="#D2E9E6"
      />
      <Path
        d="M163.333 129.117c-9.625-19.481-18.337-37.108-24.654-49.882-3.194-6.367-5.782-11.52-7.582-15.1-.904-1.77-1.615-3.145-2.105-4.098l-.559-1.063a2.021 2.021 0 00-.22-.357c.037.124.089.247.153.368l.506 1.072c.457.957 1.143 2.328 2.011 4.114l7.563 15.1c6.483 12.749 15.419 30.351 25.308 49.775 9.888 19.423 18.826 37.017 25.173 49.77 3.179 6.377 5.713 11.544 7.429 15.122a181.328 181.328 0 011.931 4.128c.26.476.452.964.572 1.456a7.528 7.528 0 01-.862-1.425 242.292 242.292 0 01-2.179-4.084c-1.883-3.553-4.497-8.703-7.717-15.074-6.429-12.727-15.16-30.353-24.768-49.822z"
        fill="#D2E9E6"
      />
      <Path
        d="M156.867 129.796c-5.106-19.955-9.74-38.01-13.099-51.1-1.716-6.525-3.115-11.81-4.051-15.476-.505-1.797-.888-3.228-1.16-4.203-.133-.472-.243-.833-.321-1.095a2.404 2.404 0 00-.126-.372c.002.125.022.25.061.375.058.263.148.625.255 1.097.247.975.6 2.393 1.062 4.21l4.051 15.48 13.778 51.06c5.401 19.929 10.256 37.98 13.651 51.05 1.701 6.535 3.04 11.828 3.921 15.485.446 1.831.772 3.253.976 4.217.154.491.232.985.232 1.476a6.19 6.19 0 01-.539-1.46 169.44 169.44 0 01-1.238-4.202c-1.036-3.652-2.48-8.935-4.22-15.469-3.493-13.072-8.145-31.13-13.233-51.073z"
        fill="#D2E9E6"
      />
      <Path
        d="M150.212 129.762c-.476-19.935-.901-37.971-1.214-51.046-.204-6.523-.369-11.807-.485-15.473-.073-1.82-.137-3.234-.176-4.212-.03-.468-.049-.832-.062-1.092a1.683 1.683 0 00-.049-.377 1.48 1.48 0 00-.021.37c0 .262 0 .623.004 1.092.014.978.045 2.392.081 4.206.109 3.668.267 8.952.459 15.473.491 13.085 1.169 31.114 1.91 51.082.742 19.968 1.421 37.989 1.775 51.053.183 6.533.29 11.816.325 15.465.015 1.823.006 3.24-.003 4.201.035.493-.003.982-.113 1.46a5.712 5.712 0 01-.199-1.48c-.082-.962-.175-2.386-.26-4.212-.189-3.659-.404-8.947-.626-15.482-.443-13.069-.893-31.109-1.346-51.028z"
        fill="#D2E9E6"
      />
      <Path
        d="M143.542 129.017c4.107-19.424 7.825-37.002 10.516-49.745 1.302-6.363 2.351-11.512 3.084-15.091l.811-4.108.21-1.067c.032-.121.046-.246.042-.371-.034.12-.058.242-.073.366-.05.254-.12.608-.248 1.06-.189.955-.505 2.327-.887 4.097-.706 3.579-1.79 8.74-3.09 15.09l-9.846 49.855c-3.845 19.469-7.323 37.081-9.972 49.809-1.323 6.369-2.43 11.517-3.228 15.069a142.672 142.672 0 01-.975 4.082 5.44 5.44 0 01-.439 1.409c-.024-.492.022-.98.139-1.456.142-.949.384-2.337.711-4.124.655-3.579 1.659-8.76 2.939-15.114 2.482-12.763 6.175-30.349 10.306-49.761z"
        fill="#D2E9E6"
      />
      <Path
        d="M137.006 127.593c8.605-18.445 16.393-35.137 22.036-47.223 2.771-6.046 5.011-10.943 6.568-14.345.754-1.69 1.336-3.003 1.741-3.91.19-.438.336-.78.435-1.023.059-.114.102-.232.128-.353a1.441 1.441 0 00-.195.334c-.112.24-.282.574-.49 1.01-.43.899-1.044 2.207-1.84 3.882-1.557 3.402-3.809 8.297-6.58 14.343-5.48 12.14-13.025 28.88-21.376 47.412-8.351 18.533-15.894 35.263-21.488 47.36a1328.281 1328.281 0 01-6.702 14.307 201.522 201.522 0 01-1.914 3.866c-.189.46-.45.902-.777 1.317.095-.479.254-.947.477-1.399.359-.905.925-2.23 1.662-3.935 1.48-3.405 3.672-8.319 6.433-14.379 5.491-12.135 13.261-28.835 21.882-47.264z"
        fill="#D2E9E6"
      />
      <Path
        d="M130.782 125.509A68873.17 68873.17 0 00163.93 81.99c4.191-5.582 7.589-10.094 9.947-13.229l2.652-3.616c.288-.41.511-.723.672-.947.083-.106.154-.217.214-.331a1.986 1.986 0 00-.273.305c-.174.222-.415.527-.732.924-.643.831-1.565 2.035-2.749 3.58l-9.965 13.222c-8.333 11.21-19.826 26.679-32.551 43.767-12.725 17.088-24.192 32.552-32.636 43.702-4.216 5.578-7.662 10.075-10.084 13.173-1.206 1.529-2.163 2.744-2.826 3.546a6.308 6.308 0 01-1.09 1.199c.202-.457.47-.895.802-1.309.58-.841 1.449-2.07 2.587-3.643 2.283-3.151 5.637-7.705 9.814-13.281 8.411-11.176 20.108-26.566 33.07-43.543z"
        fill="#D2E9E6"
      />
      <Path
        d="M125.031 122.825c16.963-15.123 32.311-28.808 43.433-38.729l13.074-11.787 3.511-3.224.904-.845c.11-.092.206-.192.285-.3-.129.081-.244.171-.343.269l-.952.825c-.835.728-2.043 1.805-3.594 3.18-3.103 2.79-7.567 6.812-13.084 11.778l-42.871 39.028c-16.716 15.243-31.852 29.034-42.962 38.957a1645.91 1645.91 0 01-13.192 11.705 199.347 199.347 0 01-3.664 3.143 7.791 7.791 0 01-1.37 1.047 7.4 7.4 0 011.112-1.182c.791-.735 1.934-1.843 3.449-3.263 3.024-2.815 7.449-6.865 12.956-11.849 11.005-9.952 26.336-23.648 43.308-38.753z"
        fill="#D2E9E6"
      />
      <Path
        d="M119.879 119.595c20.534-12.885 39.096-24.552 52.577-33.006 6.686-4.244 12.1-7.675 15.853-10.061l4.281-2.763 1.1-.729c.132-.076.251-.163.355-.258a3.932 3.932 0 00-.406.224l-1.143.68-4.347 2.709-15.864 10.05-52.086 33.402c-20.31 13.033-38.737 24.832-52.16 33.283a1678.038 1678.038 0 01-15.96 9.98 359.67 359.67 0 01-4.403 2.666c-.494.326-1.034.62-1.614.876.405-.38.87-.727 1.387-1.036.955-.649 2.376-1.594 4.217-2.803 3.71-2.423 9.07-5.875 15.752-10.133 13.355-8.539 31.933-20.22 52.461-33.081z"
        fill="#D2E9E6"
      />
      <Path
        d="M115.456 115.918l60.392-26.514c7.69-3.407 13.911-6.182 18.251-8.098l4.931-2.231c.542-.258.968-.454 1.267-.591.153-.06.293-.134.418-.218-.158.053-.31.112-.455.177l-1.312.555-4.953 2.18-18.269 8.09-59.998 26.907c-23.395 10.494-44.608 20.012-60.033 26.824-7.715 3.403-13.984 6.158-18.33 8.004-2.183.945-3.88 1.653-5.04 2.128a11.91 11.91 0 01-1.807.678c.5-.322 1.047-.608 1.636-.855 1.106-.531 2.748-1.286 4.882-2.277 4.263-1.954 10.462-4.75 18.15-8.179 15.348-6.846 36.686-16.246 60.27-26.58z"
        fill="#D2E9E6"
      />
      <Path
        d="M111.868 111.869l66.735-19.372 20.177-5.94 5.461-1.644 1.412-.44a3.05 3.05 0 00.465-.163c-.173.03-.339.07-.496.124-.348.092-.827.227-1.443.4l-5.482 1.586-20.181 5.93-66.414 19.805c-25.908 7.743-49.392 14.726-66.446 19.709-8.53 2.494-15.445 4.49-20.246 5.834a399.153 399.153 0 01-5.55 1.532c-.63.202-1.295.358-1.985.465a12.031 12.031 0 011.836-.66c1.236-.398 3.06-.97 5.427-1.693a2194.508 2194.508 0 0120.104-6.033c16.986-5.032 40.562-11.893 66.626-19.44z"
        fill="#D2E9E6"
      />
      <Path
        d="M109.198 107.557l71.454-11.722 21.631-3.622 5.868-1.018 1.515-.277c.177-.025.349-.06.512-.109a5.169 5.169 0 00-.536.066l-1.539.235-5.894.954-21.611 3.617-71.22 12.174c-27.802 4.765-52.996 9.056-71.266 12.082a2599.784 2599.784 0 01-21.676 3.521c-2.617.396-4.615.696-5.947.894-.684.124-1.391.2-2.112.226a13.943 13.943 0 012.026-.441 430.08 430.08 0 015.839-1.07c5.08-.919 12.448-2.177 21.574-3.722 18.208-3.075 43.473-7.23 71.382-11.788z"
        fill="#D2E9E6"
      />
      <Path
        d="M107.523 103.083l74.484-3.74 22.55-1.205 6.119-.36 1.591-.106a4.78 4.78 0 00.534-.053 4.196 4.196 0 00-.544.004l-1.602.066-6.138.292-22.551 1.176-74.353 4.189c-29.016 1.653-55.3 3.121-74.348 4.1-9.525.494-17.237.869-22.574 1.099-2.673.116-4.75.197-6.16.237a16.47 16.47 0 01-2.166.007 15.99 15.99 0 012.11-.217c1.392-.108 3.455-.247 6.112-.417 5.307-.338 13.006-.78 22.523-1.3 19.021-1.005 45.343-2.334 74.413-3.772z"
        fill="#D2E9E6"
      />
      <Path
        d="M106.892 98.555l75.618 4.36 22.912 1.245 6.226.31 1.622.066c.186.012.371.014.554.006a6.502 6.502 0 00-.558-.054l-1.619-.109-6.227-.37-22.916-1.258-75.605-3.903c-29.503-1.505-56.225-2.898-75.57-3.984-9.675-.54-17.506-1.003-22.916-1.353-2.706-.172-4.807-.327-6.235-.427A16.435 16.435 0 010 92.857a17.68 17.68 0 012.168.015c1.426.044 3.53.126 6.231.25 5.405.246 13.237.637 22.912 1.15 19.342 1.035 46.069 2.562 75.581 4.282z"
        fill="#D2E9E6"
      />
      <Path
        d="M107.303 94.087l74.905 12.222 22.712 3.631 7.783 1.226c.183.028.366.049.55.065a4.456 4.456 0 00-.54-.111l-1.6-.278-6.17-1.018-22.71-3.64-74.994-11.776c-29.27-4.582-55.777-8.76-74.945-11.855A3112.953 3112.953 0 019.61 78.82a604.566 604.566 0 01-6.16-1.073 17.658 17.658 0 01-2.134-.448c.728.037 1.456.118 2.175.244 1.415.193 3.506.5 6.193.902 5.37.81 13.17 2.022 22.73 3.54 19.192 3.004 45.66 7.317 74.89 12.103z"
        fill="#D2E9E6"
      />
      <Path
        d="M108.747 89.782l72.336 19.804 21.96 5.931 5.988 1.583 1.562.401c.175.052.355.093.538.123a4.914 4.914 0 00-.524-.164l-1.541-.44-5.955-1.647-21.96-5.94-72.53-19.37c-28.33-7.549-53.964-14.41-72.495-19.45a3126.079 3126.079 0 01-21.916-6.028 551.62 551.62 0 01-5.933-1.695c-.7-.179-1.382-.4-2.035-.66.722.108 1.434.263 2.125.464 1.38.338 3.405.854 6.01 1.53a3239.093 3239.093 0 0121.998 5.844c18.556 4.983 44.14 11.97 72.372 19.714z"
        fill="#D2E9E6"
      />
      <Path
        d="M111.193 85.777l67.992 27.002 20.667 8.1 5.638 2.176 1.473.558c.165.068.337.127.514.176a4.2 4.2 0 00-.486-.219l-1.447-.592-5.594-2.234-20.641-8.13-68.306-26.597C84.336 75.647 60.207 66.233 42.789 59.35a2623.149 2623.149 0 01-20.581-8.206c-2.391-.978-4.3-1.755-5.557-2.288a14.482 14.482 0 01-1.892-.86c.7.183 1.377.41 2.024.679 1.308.477 3.221 1.194 5.673 2.13a2642.45 2642.45 0 0120.718 8.035c17.454 6.833 41.495 16.365 68.019 26.938z"
        fill="#D2E9E6"
      />
      <Path
        d="M114.592 82.11l61.938 33.516 18.838 10.097 5.148 2.722c.587.303 1.025.535 1.349.698.151.082.308.159.471.229a3.48 3.48 0 00-.433-.262l-1.316-.731-5.093-2.774-18.823-10.107-62.331-33.16C90.001 69.406 67.984 57.674 52.11 49.123a1851.455 1851.455 0 01-18.744-10.177 288.373 288.373 0 01-5.046-2.818c-.62-.312-1.2-.661-1.733-1.042.66.255 1.287.55 1.875.88 1.17.614 2.933 1.505 5.196 2.678a2443.735 2443.735 0 0118.916 10.027c15.952 8.513 37.859 20.347 62.018 33.439z"
        fill="#D2E9E6"
      />
      <Path
        d="M118.851 78.932l54.413 39.161 16.56 11.818 4.538 3.193 1.194.824c.129.099.271.189.426.269a4.262 4.262 0 00-.38-.301l-1.126-.843-4.472-3.238-16.552-11.825-54.911-38.862C97.11 63.971 77.726 50.226 63.785 40.23c-6.979-5.003-12.6-9.063-16.455-11.89a325.672 325.672 0 01-4.407-3.272 10.393 10.393 0 01-1.464-1.189c.601.319 1.163.672 1.678 1.055 1.061.713 2.609 1.777 4.592 3.154 3.975 2.774 9.647 6.773 16.656 11.756 14.011 9.964 33.25 23.792 54.466 39.088z"
        fill="#D2E9E6"
      />
      <Path
        d="M123.857 76.292l45.558 43.82 13.901 13.242 3.814 3.586 1.007.923c.105.111.228.214.366.307a2.969 2.969 0 00-.31-.328l-.956-.95c-.869-.836-2.128-2.053-3.738-3.617l-13.887-13.243c-11.804-11.164-28.103-26.561-46.103-43.58-18.001-17.02-34.307-32.414-45.982-43.608a1545.153 1545.153 0 01-13.78-13.296 239.223 239.223 0 01-3.673-3.645 9.2 9.2 0 01-1.19-1.309c.53.371 1.012.772 1.438 1.197.9.808 2.189 2.003 3.88 3.554 3.34 3.103 8.13 7.608 14.007 13.19 11.764 11.17 27.857 26.66 45.648 43.757z"
        fill="#D2E9E6"
      />
      <Path
        d="M129.491 74.262l35.615 47.414 10.892 14.341 3 3.887c.347.433.611.766.796 1.006.086.116.185.228.296.335a2.362 2.362 0 00-.234-.355l-.742-1.019a470.227 470.227 0 00-2.92-3.897l-10.878-14.346c-9.28-12.098-22.098-28.795-36.219-47.235-14.121-18.44-26.937-35.145-36.083-47.269-4.58-6.06-8.255-10.974-10.73-14.375a198.471 198.471 0 01-2.841-3.935 7.458 7.458 0 01-.9-1.4c.449.417.84.86 1.167 1.321.725.88 1.76 2.18 3.113 3.871 2.637 3.367 6.402 8.251 11.016 14.3 9.166 12.088 21.772 28.842 35.652 47.356z"
        fill="#D2E9E6"
      />
      <Path
        d="M135.621 72.868l24.741 49.861c3.209 6.361 5.803 11.516 7.61 15.091.942 1.777 1.621 3.147 2.112 4.096l.565 1.064c.06.121.131.24.214.356a1.804 1.804 0 00-.147-.367 120.26 120.26 0 01-.512-1.073c-.458-.952-1.149-2.329-2.018-4.111l-7.594-15.075c-6.528-12.747-15.482-30.326-25.395-49.758-9.912-19.431-18.893-37.006-25.254-49.753a1219.225 1219.225 0 01-7.456-15.118 192.2 192.2 0 01-1.943-4.13 6.804 6.804 0 01-.573-1.451c.358.458.647.935.862 1.424.519.941 1.257 2.307 2.192 4.083 1.883 3.557 4.516 8.705 7.743 15.073 6.444 12.717 15.21 30.335 24.853 49.788z"
        fill="#D2E9E6"
      />
      <Path
        d="M142.085 72.198l13.172 51.09 4.11 15.476c.506 1.82.892 3.234 1.165 4.209.143.452.243.833.321 1.09.027.126.072.251.133.373a1.6 1.6 0 00-.068-.376c-.059-.258-.142-.623-.255-1.097l-1.068-4.21c-.969-3.667-2.365-8.964-4.09-15.478l-13.857-51.052c-5.441-19.925-10.322-37.972-13.743-51.046-1.714-6.533-3.059-11.827-3.952-15.486-.447-1.827-.78-3.25-.984-4.214A5.416 5.416 0 01122.731 0c.248.48.432.968.552 1.461.301.959.719 2.378 1.244 4.2 1.043 3.653 2.5 8.938 4.246 15.472 3.5 13.065 8.185 31.124 13.312 51.065z"
        fill="#D2E9E6"
      />
      <Path
        d="M148.723 72.225c.509 19.931.974 37.957 1.31 51.048.217 6.525.388 11.806.51 15.477.081 1.817.144 3.231.183 4.208.03.473.054.838.068 1.098.002.124.018.249.049.373a1.44 1.44 0 00.021-.371c0-.258-.006-.623-.004-1.091-.02-.979-.051-2.389-.086-4.207-.123-3.666-.288-8.95-.498-15.475-.511-13.084-1.22-31.122-2.001-51.084-.781-19.961-1.491-37.995-1.87-51.058-.196-6.535-.316-11.817-.357-15.47-.02-1.825-.007-3.236-.004-4.198-.03-.493.006-.982.108-1.461.14.491.209.986.206 1.476.08.966.175 2.386.265 4.217.202 3.658.424 8.947.658 15.483.481 13.071.969 31.113 1.442 51.035z"
        fill="#D2E9E6"
      />
      <Path
        d="M155.411 72.977c-4.07 19.43-7.756 37.012-10.428 49.758-1.258 6.371-2.327 11.521-3.053 15.097l-.811 4.108-.211 1.066a2.43 2.43 0 00-.072.367c.052-.117.089-.237.11-.361.049-.25.12-.608.242-1.061.188-.954.498-2.327.88-4.097.707-3.579 1.772-8.743 3.065-15.095 2.522-12.77 5.945-30.394 9.751-49.865 3.807-19.471 7.254-37.092 9.88-49.828 1.309-6.37 2.404-11.521 3.203-15.073.402-1.775.725-3.15.961-4.084.08-.482.229-.954.446-1.408.02.492-.027.98-.139 1.456-.142.949-.384 2.337-.704 4.125-.649 3.58-1.641 8.763-2.909 15.12-2.456 12.762-6.103 30.347-10.211 49.775z"
        fill="#D2E9E6"
      />
      <Path
        d="M161.943 74.383c-8.568 18.456-16.318 35.154-21.939 47.259l-6.544 14.354c-.748 1.691-1.33 3.004-1.735 3.911-.185.443-.329.781-.429 1.024a1.48 1.48 0 00-.128.353c.082-.105.146-.218.189-.335.119-.239.282-.575.496-1.005.424-.904 1.038-2.212 1.828-3.888 1.557-3.402 3.79-8.3 6.555-14.347 5.45-12.154 12.974-28.918 21.284-47.44 8.31-18.521 15.821-35.283 21.397-47.387 2.778-6.054 5.068-10.943 6.702-14.307a177.848 177.848 0 011.907-3.867c.187-.462.446-.905.772-1.322-.09.48-.247.949-.47 1.4-.36.905-.92 2.235-1.656 3.936-1.475 3.41-3.661 8.325-6.409 14.388-5.496 12.125-13.235 28.834-21.82 47.273z"
        fill="#D2E9E6"
      />
      <Path
        d="M168.169 76.459l-33.065 43.557c-4.184 5.579-7.572 10.102-9.916 13.238l-2.653 3.617c-.288.41-.511.722-.671.946a1.862 1.862 0 00-.208.333c.106-.095.197-.197.273-.305l.726-.926c.642-.831 1.558-2.035 2.742-3.58 2.358-3.139 5.751-7.657 9.935-13.235l32.442-43.802c12.638-17.106 24.126-32.58 32.546-43.738 4.209-5.579 7.645-10.086 10.059-13.181a205.138 205.138 0 012.822-3.555 6.83 6.83 0 011.083-1.196 6.079 6.079 0 01-.818 1.328c-.574.841-1.443 2.07-2.575 3.645a1049.835 1049.835 0 01-9.791 13.293c-8.33 11.163-19.999 26.57-32.931 43.56z"
        fill="#D2E9E6"
      />
      <Path
        d="M173.932 79.145C157 94.277 141.661 107.98 130.576 117.91c-5.506 4.976-9.959 9.005-13.05 11.8l-3.507 3.228-.897.847a1.74 1.74 0 00-.285.299c.126-.082.241-.172.344-.268.225-.192.537-.465.944-.822.835-.728 2.047-1.826 3.588-3.186l13.061-11.789c10.964-10.006 26.085-23.817 42.79-39.075 16.706-15.258 31.824-29.056 42.916-38.99a1071.526 1071.526 0 0113.172-11.73 192.598 192.598 0 013.66-3.153 8.135 8.135 0 011.332-1.053c-.311.423-.683.82-1.113 1.187-.791.734-1.928 1.844-3.436 3.265-3.019 2.82-7.438 6.87-12.933 11.861-10.985 9.977-26.293 23.69-43.23 38.813z"
        fill="#D2E9E6"
      />
      <Path
        d="M179.086 82.366l-52.509 33.056-15.831 10.077-4.274 2.764-1.093.726c-.13.081-.251.168-.362.261.147-.065.283-.14.406-.224l1.142-.679 4.342-2.715 15.842-10.067 52.011-33.414c20.282-13.053 38.68-24.84 52.085-33.332a1206.06 1206.06 0 0115.951-9.999c1.897-1.166 3.376-2.046 4.404-2.67a9.717 9.717 0 011.613-.872c-.4.373-.857.716-1.365 1.022-.95.654-2.37 1.599-4.212 2.808a2160.93 2160.93 0 01-15.728 10.15c-13.378 8.518-31.907 20.215-52.422 33.108z"
        fill="#D2E9E6"
      />
      <Path
        d="M183.516 86.04l-60.341 26.586-18.213 8.101-4.925 2.231c-.542.259-.968.454-1.268.596-.151.06-.29.131-.417.213.16-.051.312-.11.456-.177l1.311-.554 4.954-2.184 18.227-8.107 59.919-26.966c23.403-10.53 44.564-20.049 59.979-26.875 7.704-3.414 13.958-6.158 18.307-8.025a286.393 286.393 0 015.035-2.133 12.573 12.573 0 011.821-.68c-.496.325-1.042.613-1.631.86-1.106.53-2.741 1.287-4.877 2.282a2169.666 2169.666 0 01-18.127 8.196c-15.335 6.877-36.648 16.281-60.21 26.636z"
        fill="#D2E9E6"
      />
      <Path
        d="M187.117 90.087l-66.695 19.43-20.161 5.959-5.457 1.658-1.412.439c-.167.045-.325.1-.472.167.172-.034.34-.075.502-.123l1.443-.4 5.484-1.594 20.165-5.95 66.367-19.86c25.894-7.767 49.357-14.774 66.401-19.772a2353.98 2353.98 0 0120.232-5.862 383.791 383.791 0 015.55-1.536c.63-.202 1.295-.357 1.985-.465-.572.255-1.181.472-1.821.65-1.229.399-3.055.975-5.415 1.703-4.726 1.451-11.59 3.52-20.094 6.043-17.004 5.06-40.562 11.923-66.602 19.513z"
        fill="#D2E9E6"
      />
      <Path
        d="M189.788 94.395l-71.465 11.78-21.61 3.643-5.862 1.023-1.502.279c-.178.027-.35.064-.514.113.183-.013.362-.035.537-.066l1.532-.236 5.895-.957 21.621-3.633 71.213-12.24c27.793-4.788 52.972-9.098 71.239-12.142a2353.162 2353.162 0 0121.667-3.54c2.561-.404 4.56-.709 5.916-.903.681-.128 1.385-.21 2.103-.245-.634.197-1.303.348-1.995.451-1.342.253-3.295.62-5.834 1.075-5.079.919-12.45 2.185-21.57 3.735-18.227 3.106-43.476 7.28-71.371 11.863z"
        fill="#D2E9E6"
      />
      <Path
        d="M191.47 98.87l-74.462 3.79-22.54 1.224-6.121.368-1.591.106c-.182.006-.361.024-.534.054.185.007.369.004.55-.008l1.596-.062 6.14-.301 22.551-1.214 74.334-4.257c29.008-1.675 55.284-3.166 74.33-4.167 9.52-.503 17.233-.883 22.571-1.117a389.2 389.2 0 016.154-.242 16.276 16.276 0 012.167-.011 16.38 16.38 0 01-2.112.221c-1.391.108-3.455.251-6.105.423-5.314.34-13.002.792-22.521 1.317-19.025 1.06-45.338 2.412-74.407 3.876z"
        fill="#D2E9E6"
      />
      <Path
        d="M192.113 103.4l-75.617-4.296-22.91-1.227-6.227-.301-1.622-.067a4.523 4.523 0 00-.548-.004c.183.028.367.045.551.052l1.62.11 6.228.365 22.914 1.236 75.6 3.83c29.508 1.479 56.23 2.838 75.576 3.916a2917 2917 0 0122.914 1.335c2.706.168 4.807.327 6.235.423.726.024 1.454.099 2.173.222-.715.043-1.441.04-2.169-.011a477.496 477.496 0 01-6.226-.245 3055.23 3055.23 0 01-22.908-1.132c-19.347-1.01-46.072-2.515-75.584-4.206z"
        fill="#D2E9E6"
      />
      <Path
        d="M134.154 48.2c10.048 4 15.891 15.034 17.556 20.051 5.615-14.326 13.934-18.94 25.513-20.09 11.578-1.15 28.796 8.63 27.151 26.193-1.316 14.05-17.55 36.872-25.503 46.526l-31.529 38.07c-6.87-7.012-24.072-25.257-37.917-42.145-17.306-21.11-19.072-39.543-10.946-56.434 8.126-16.89 23.115-17.17 35.675-12.17z"
        fill="#9FBC2A"
      />
      <Path
        opacity={0.7}
        d="M178.772 116.675c-5.408 7.212-20 23.501-32 36.001l-32.273-36.001c-8.501-9.999 24.402 24.146 37.773 19.002 19.5-7.501 43.276-44.52 41.501-41.502-5 8.501-9.001 14.5-15.001 22.5z"
        fill="#527134"
      />
      <Ellipse
        opacity={0.5}
        cx={103.265}
        cy={97.8635}
        rx={1.5}
        ry={2.5}
        transform="rotate(-20 103.265 97.864)"
        fill="#EDF3C2"
      />
      <Path
        opacity={0.5}
        d="M110.695 49.15c9.742-4.583 21.434-1.019 26.305 3.055-11.801-5.294-25.785-3.48-33.5 7.795-6.964 10.178-5 26.5-3.522 32.934.641 2.79-5.425-9.205-3.477-21.934.461-3.016 4.451-17.268 14.194-21.85z"
        fill="#EDF3C2"
      />
      <Path
        d="M183.003 52.895c-1.397-1.596-10.814-.718-17.726 2.832-5.188 2.664-10.905 13.305-9.808 11.875 1.097-1.43 2.858-5.32 9.675-9.879 6.817-4.559 19.257-3.231 17.859-4.828z"
        fill="#B1CA86"
      />
      <Path
        d="M63.2 41.327C75.35 35.908 77.777 23 77.471 17.223c2.12 17.53 8.495 23.838 11.418 24.801-10.276 1.594-13.661 12.218-14.069 17.331-1.223-16.414-8.257-18.858-11.622-18.028zM211.791 74.057c12.152-5.418 14.578-18.327 14.273-24.104 2.12 17.53 8.495 23.839 11.418 24.802-10.277 1.593-13.661 12.218-14.069 17.33-1.223-16.414-8.258-18.858-11.622-18.028z"
        fill="#D2E9E6"
      />
      <Path
        d="M86.667 268.288c-29.644 3.421-46.04-11.621-50.532-19.57 7.568-6.925 33.859-32.891 47.493-46.357l.25-.247c13.56-13.393 28.858-25.587 39.699-27.659 17.417-3.329 37.164-3.104 46.042-5.06 8.877-1.957 15.958-14.227 20.245-19.025 4.287-4.799 7.831-6.926 11.359-6.673 2.823.202 4.058 1.366 4.322 1.923 3.007-3.964 7.918-14.273 13.67-15.104 4.601-.666 7.663.839 8.618 1.675 4.465-8.013 7.712-9.765 12.965-9.177 5.181.58 3.99 3.716 3.516 4.962l-.019.05c-.458 1.211-3.671 9.684-4.71 12.731-.832 2.437-5.458 15.04-7.667 21.036 1.268-1.919 5.692-7.428 13.251-14.103 9.448-8.344 13.96-8.549 17.205-6.836 3.245 1.714.575 5.094-2.35 8.641-2.341 2.837-15.774 25.165-22.198 35.974 7.691-.455 13.252.843 17.749 3.016 4.497 2.174 2.177 6.055-.908 6.972-3.085.916-12.136 6.577-17.082 10.164-4.946 3.588-8.937 7.584-13.733 9.251-4.796 1.667-17.922 4.87-29.725 10.373-19.79 9.228-32.485 9.721-54.15 5.948-7.953-1.385-17.185 9.002-23.511 14.384-11.496 9.779-24.455 16.312-29.799 22.711z"
        fill="#E29D7D"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.169 146.325c-2.729 6.649-11.779 21.977-26.288 30.246l-.265.151-.305-.044c-1.416-.205-3.614-.409-5.59-.36-.991.025-1.889.113-2.591.281-.744.177-1.089.404-1.206.563.181-1.668 4.829-1.915 9.399-1.458 16.03-9.381 19.48-20.001 26.846-29.379z"
        fill="#B96946"
      />
      <Path
        d="M243.948 195.583c-.874 1.736-.24 4.154.563 5.393 3.531-2.057 11.686-5.828 13.055-6.428 1.711-.751 3.073-2.002 1.983-3.297-1.09-1.295-2.558-1.797-4.619-1.548-2.061.25-9.889 3.71-10.982 5.88z"
        fill="#FFC6A7"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M237.859 185.905c-5.439 1.903-17.744 6.453-25.549 8.76-.794.235-1.572.47-2.34.702-3.884 1.175-7.487 2.265-11.439 2.743-4.771.577-10.035.266-17.019-1.751-6.873-1.985-16.955-4.752-27.568-6.142-10.632-1.393-21.684-1.389-30.549 2.072-4.07 1.589 6.963-6.561 31.886-3.296 10.756 1.41 23.762 5.477 30.65 7.467 6.779 1.957 11.371 1.229 15.366.35 3.995-.878 8.117-2.557 11.999-3.73.776-.234 4.898-1.838 5.717-2.08 14.862-5.186 22.521-6.382 18.846-5.095z"
        fill="#B96946"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M231.883 162.007c-5.829 5.439-19.236 19.043-25.859 26.552l-.34.385-.513-.113c-2.749-.604-9.772-1.133-15.624 1.558-.003.002 5.048-4.367 15.473-3.323 19.981-20.796 23.526-22.691 26.863-25.059zM227.679 132.624c-1.75 3.427-4.276 9.343-7.358 14.593-3.068 5.224-6.341 10.323-8.789 12.969-4.736 5.119-14.501 16.844-18.801 22.078l-.37.451-.571-.174c-1.808-.55-7.087-1.26-13.003.615-.756.24 3.36-3.496 13.588-1.605 4.458-5.418 8.06-10.711 12.708-15.735 2.307-2.494 5.632-5.95 9.237-10.841 5.208-7.065 11.639-21.285 13.359-22.351z"
        fill="#B96946"
      />
      <Path
        d="M95.1 228.681c-12.664-12.326-15.187-20.969-14.865-23.75-1.134-2.69-7.475 5.316-15.076 12.707-.366-1.217-2.633-3.04-7.357-2.52s-7.913 6.365-10.707 10.288c-4.474-.87-7.756-.229-19.34 6.924-9.265 5.722-9.079 10.469-7.317 13.758 10.312 15.918 41.227 27.425 61.156 32.797 6.91 1.863 15.3 1.755 18.746-4.333 3.187-5.631.149-11.217 2.607-13.414 7.158-6.402 12.971-13.674 15.535-15.967 2.564-2.293 2.625-3.06 2.335-3.157-3.295.691-13.053-1.007-25.717-13.333z"
        fill="#EDF3C2"
      />
      <Path
        d="M54.628 266.201c-.14.01-.04-3.241-.121-7.24-.081-4-.282-7.229-.147-7.251a8.74 8.74 0 01.335 2.078c.136 1.301.256 3.112.287 5.124.03 2.012-.01 3.834-.094 5.155a8.586 8.586 0 01-.26 2.134zM84.435 263.58a56.86 56.86 0 01-7.602 1.419 60.148 60.148 0 01-7.711.929 62.081 62.081 0 017.61-1.415 60.555 60.555 0 017.703-.933zM77.576 250.084c.154-1.71.533-3.36 1.133-4.928a18.07 18.07 0 011.849-4.686 17.161 17.161 0 01-1.136 4.937 17.38 17.38 0 01-1.846 4.677zM108.284 256.448a56.183 56.183 0 01-5.842-9.499 27.577 27.577 0 013.137 4.56 28.535 28.535 0 012.705 4.939zM37.375 252.2a33.55 33.55 0 011.819 4.853 32.066 32.066 0 011.54 4.864 19.895 19.895 0 01-2.093-4.789 17.66 17.66 0 01-1.266-4.928zM31.311 247.175a40.304 40.304 0 01-8.27 2.73 19.872 19.872 0 014.054-1.586 20.846 20.846 0 014.216-1.144zM42.564 238.039a30 30 0 01-3.982-11.161c.14-.023.79 2.54 1.896 5.617 1.105 3.077 2.22 5.488 2.086 5.544zM63.635 242.122c-1.942.091-3.89.014-5.824-.232a33.2 33.2 0 01-5.777-.75 35.326 35.326 0 015.824.231c1.942.083 3.875.334 5.777.751zM89.95 233.42a53.751 53.751 0 016.19-3.792c.197-.12.391.359.233.442a53.403 53.403 0 01-6.424 3.35zM76.54 216.65a53.673 53.673 0 01-3.97-6.078c-.125-.194.349-.401.435-.246a53.447 53.447 0 013.535 6.324zM87.051 272.303a53.708 53.708 0 01-2.025 6.972c-.065.221-.532-.003-.47-.17a53.436 53.436 0 012.495-6.802zM73.283 234.948c-.033-1.53.1-3.039.395-4.503.13-1.51.423-2.984.874-4.398a20.806 20.806 0 01-.387 4.509 19.448 19.448 0 01-.882 4.392zM91.576 247.756a27.07 27.07 0 013.962-2.689 25.37 25.37 0 014.154-2.301 26.653 26.653 0 01-3.955 2.694 24.415 24.415 0 01-4.161 2.296zM64.575 222.529a30.927 30.927 0 01-5.178 2.486 31.63 31.63 0 01-5.383 2.015 33.037 33.037 0 015.186-2.481 31.929 31.929 0 015.375-2.02z"
        fill="#75A14A"
      />
      <Path
        d="M48.563 226.816a.744.744 0 01.124.106l.334.327 1.26 1.273a53.923 53.923 0 002.02 2.004c.816.765 1.678 1.663 2.707 2.598 1.03.936 2.133 1.985 3.372 3.055a556.5 556.5 0 003.98 3.395c2.86 2.307 6.069 4.804 9.56 7.246 3.49 2.441 6.942 4.589 10.133 6.464 1.633.902 3.126 1.776 4.581 2.566 1.456.791 2.808 1.492 4.06 2.12 1.252.629 2.4 1.173 3.403 1.654 1.003.481 1.87.884 2.587 1.198l1.661.755.426.199.14.08a.494.494 0 01-.156-.03l-.437-.173-1.672-.698a56.908 56.908 0 01-2.62-1.158c-1.01-.476-2.166-1.004-3.421-1.616-1.255-.612-2.634-1.3-4.085-2.084a320.23 320.23 0 01-4.609-2.548 157.238 157.238 0 01-10.163-6.465c-3.51-2.434-6.713-4.93-9.566-7.277-1.408-1.19-2.761-2.288-3.963-3.42-1.202-1.133-2.341-2.125-3.352-3.092-1.012-.967-1.895-1.851-2.683-2.629a42.924 42.924 0 01-1.966-2.04l-1.222-1.311-.306-.346-.127-.153z"
        fill="#527134"
      />
      <Path
        d="M97.777 261.431a55.039 55.039 0 01-27.05-9.249c-8.015-5.401-14.388-12.802-18.437-21.408 13.833 12.508 28.153 23.82 45.487 30.657z"
        fill="#4E8419"
        fillOpacity={0.32}
      />
    </Svg>
  );
};

export default AllDoneTeens;
