import Svg, { Defs, Path, Circle, G, Use, Ellipse } from 'svgs';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

const NoAvailabilityIcon = ({ width = 96, height = 81, ...otherProps }) => (
  <Svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} {...otherProps}>
    <Defs>
      <Path d="M14.6 29.105l42.34 33.042" id="a" />
      <Circle id="c" cx={13} cy={13} r={13} />
    </Defs>
    <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <Path
        d="M55.762 0a5.16 5.16 0 015.16 5.16v6.033c0 .082-.002.163-.005.243C66.875 13.121 71.242 18.6 71.242 25.1v41.415c0 7.84-6.356 14.197-14.197 14.197H14.197C6.357 80.71 0 74.355 0 66.514V25.099C0 18.6 4.367 13.121 10.327 11.436a4.23 4.23 0 01-.007-.243V5.16a5.16 5.16 0 1110.32 0v5.742h29.962V5.16A5.16 5.16 0 0155.762 0z"
        fill="#A1ABC6"
      />
      <Path
        d="M38.57 33.244a4.83 4.83 0 014.831 4.83v16.973a4.83 4.83 0 01-4.83 4.83H21.62a4.83 4.83 0 01-4.831-4.83V38.075a4.83 4.83 0 014.83-4.831h16.95zm18.116 4.39c.166.292.254.622.254.958V53.55a1.932 1.932 0 01-2.89 1.678l-7.807-4.457a1.932 1.932 0 01-.975-1.678V43.05c0-.694.372-1.335.975-1.679l7.807-4.456a1.932 1.932 0 012.636.72z"
        fill="#FFF"
      />
      <G strokeLinecap="round">
        <Use stroke="#A1ABC6" strokeWidth={9} href="#a" />
        <Use stroke="#FFF" strokeWidth={4.5} href="#a" />
      </G>
      <G>
        <G transform="translate(58 8)">
          <Use fill="#000" href="#c" />
          <Circle stroke="#FFF" strokeWidth={3} fill="#BECBE0" cx={13} cy={13} r={14.5} />
        </G>
        <G transform="translate(58 8) translate(10.5 5.5)" fill="#FFF">
          <Path d="M0.9375 0H4.0625V9.84615385H0.9375z" />
          <Ellipse
            transform="rotate(.5 2.5 13.555)"
            cx={2.5}
            cy={13.5545018}
            rx={1.8749978}
            ry={1.84615604}
          />
        </G>
      </G>
    </G>
  </Svg>
);

export default NoAvailabilityIcon;
