import { TherapistReviewData } from 'ts-frontend/types';
import apiHelper from '../../core/api/apiHelper';
import apiWrapper from '../../core/api/apiWrapper';

export interface ApiResponse<T = unknown> {
  data?: T;
}

export interface TherapistInfo {
  id: number;
  firstName: string;
}

function postTherapistReview(
  reviewPayload: TherapistReviewData
): Promise<TherapistReviewData | undefined> {
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v2/therapist/${reviewPayload.therapistId}/reviews`, {
      rating: reviewPayload.ratingValue,
      comment: reviewPayload.reviewText,
      roomID: reviewPayload.roomId,
      ratingSource: reviewPayload.ratingSource,
    })
    .then((res) => res.data);
}

function getTherapistInfo(roomID: number): Promise<TherapistInfo> {
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/room-profile-info`)
    .then((res) => res.data.data)
    .then((res) => {
      if (res) {
        const therapist: TherapistInfo = {
          id: res.therapistID,
          firstName: res.firstName,
        };
        return therapist;
      }
      throw new Error('Therapist data missing');
    });
}

const therapistReviewApiHelper = {
  postTherapistReview,
  getTherapistInfo,
};

export default therapistReviewApiHelper;
