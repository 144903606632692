import { typeNames } from './ReactFrameTypes';
import sessionStorage from '../../core/storage/sessionStorage';

export const getParentOrigin = () => {
  const { hash } = window.location;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hashParams: any = {};

  hash.replace(/[#&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    hashParams[key] = value;
    return '';
  });
  const isIFrame = window.location !== window.parent.location;
  /**
   * This is made to avoid conflicts in session storage `parentOrigin` key between two iFrames
   * of the same app (for example, Eligibility Widget). Another alternative is to use the document.title
   */
  const prefixOrigin = isIFrame ? 'iFrame_' : '';
  const memoryParentOrigin = sessionStorage.getItem(`${prefixOrigin}parentOrigin`);

  const parentOrigin = decodeURIComponent(hashParams.origin || memoryParentOrigin);

  if (parentOrigin && parentOrigin !== 'null') {
    if (!memoryParentOrigin) sessionStorage.setItem(`${prefixOrigin}parentOrigin`, parentOrigin);
    return parentOrigin;
  }
  return null;
};

const isMobileParentOrigin = () => getParentOrigin() === 'mobileClient';

/**
 * This method is exclusive to iFrames
 * @deprecated
 */
export const isiOS = (type: typeNames = 'finishLoading') =>
  (window as any).webkit &&
  (window as any).webkit.messageHandlers &&
  typeof (window as any).webkit.messageHandlers[type] === 'object' &&
  isMobileParentOrigin();

/**
 * This method is exclusive to iFrames
 * See getPlatform from analytics for Ionic
 * @deprecated
 */
export const isAndroid = () => !!(window as any).toAndroidDevice && isMobileParentOrigin();

/**
 * This method is exclusive to iFrames
 * See getPlatform from analytics for Ionic
 * @deprecated
 */
export const isMobile = () => isiOS() || isAndroid();

const nativeWebviewViewport =
  'width=device-width, initial-scale=1, user-scalable=no, viewport-fit=contain';
export const changeViewportToContain = () => {
  try {
    const el = document.querySelector('meta[name="viewport"]');
    if (el) {
      el.setAttribute('content', nativeWebviewViewport);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Error while updating viewport fit');
  }
};
