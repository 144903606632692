import { useMutation } from 'react-query';
import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';

interface useMutationSuperbillsVariables {
  roomID: string;
  superbillID: string;
}

const createSuperbill = async (params: useMutationSuperbillsVariables): Promise<void> => {
  const { roomID, superbillID } = params;
  await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/superbills/${superbillID}/create-document`
  );
};

const useMutationCreateSuperbill = () =>
  useMutation<void, Error, useMutationSuperbillsVariables>(createSuperbill);

export default useMutationCreateSuperbill;
