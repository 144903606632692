import { Auth } from '@aws-amplify/auth';
import { getTokens } from '../../apps/client-web/src/auth/helpers/token';

interface CustomAuthSignInParameters {
  email: string;
}

interface CustomAuthSignInResult {
  result: 'network-error' | 'success';
}

const customAuthSignIn = async ({
  email,
}: CustomAuthSignInParameters): Promise<CustomAuthSignInResult> => {
  try {
    await Auth.signIn(email);
    const { accessToken } = getTokens();
    await Auth.sendCustomChallengeAnswer(email, accessToken!);

    return { result: 'success' };
  } catch (e) {
    const error = e as { code: string };

    if (error.code === 'NetworkError') return { result: 'network-error' };

    throw error;
  }
};

export default customAuthSignIn;
