export interface ClientOnRoomsAPI {
  id: number;
  name: string;
  roomIDs: number[];
}

export interface Client {
  id: number;
  displayName: string;
  roomIDs: number[];
}

// eslint-disable-next-line import/prefer-default-export
export class EClient implements Client {
  id: number;

  displayName: string;

  roomIDs: number[];

  constructor(client: ClientOnRoomsAPI) {
    this.id = client.id;
    this.displayName = client.name;
    this.roomIDs = client.roomIDs;
  }
}
