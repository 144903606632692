import { FunctionComponent } from 'react';
import moment from 'moment';
import { View, Large, Tiny, BaseButton, useWindowWidth } from '@talkspace/react-toolkit';
import {
  calculateTotals,
  formatCurrency,
  generateConsumerLineItems,
} from 'ts-frontend/helpers/billingUtils';
import { RoomStatus } from 'ts-frontend/entities/Room';
import { SubscriptionInfo, PlanData } from 'ts-frontend/types';
import { useTranslation } from '@talkspace/i18n';
import { TFNewMemberNav } from '@talkspace/i18n/types';
import { COLORS } from '@/utils/design';
import useOpenModalSafe from '@/hooks/useOpenModalSafe';
import styled from '../../../core/styled';

const Container = styled(View)(() => {
  return {
    marginTop: 10,
    marginBottom: 10,
    maxWidth: 360,
  };
});

const LineThroughText = styled(Large)({
  textDecoration: 'line-through',
});

const ClearButton = styled(BaseButton)({
  padding: 0,
  margin: 0,
  backgroundColor: COLORS.white,
  textAlign: 'left',
  paddingTop: 5,
  marginBottom: 35,
});

const DATE_FORMAT_STRING = 'MMM DD, YYYY';

interface NextRenewalProps {
  subscriptionInfo: SubscriptionInfo;
  roomStatus: number;
}

function getRenewalString(
  subscriptionInfo: SubscriptionInfo,
  roomStatus: number,
  tnewMemberNav: TFNewMemberNav
) {
  const {
    endSubscriptionDate,
    price,
    currency,
    renewalDate,
    discounts,
    voucherValue,
    customerBalanceCents,
    lineItems,
    totalPrice,
  } = subscriptionInfo;
  const { CLOSED, NOT_RENEWING, CANCELED, FREE_TRIAL_CHATBOT } = RoomStatus;
  const showExpiry =
    endSubscriptionDate &&
    ![CLOSED, NOT_RENEWING, CANCELED, FREE_TRIAL_CHATBOT].includes(roomStatus);
  let sectionSubTitle = <>-</>;
  let sectionTitle = 'Next renewal';
  if (renewalDate) {
    const originalPrice = formatCurrency(Number(price), currency, {
      showDecimal: true,
    });
    const hasCouponDiscounts = Boolean(discounts && discounts.length > 0);
    const hasVoucherDiscount = Boolean(voucherValue);
    const hasCustomerBalance = Boolean(customerBalanceCents);
    const hasLineItemsDiscount = Boolean(lineItems && lineItems.length > 0);
    const hasPriceDiscount =
      hasCouponDiscounts || hasVoucherDiscount || hasCustomerBalance || hasLineItemsDiscount;
    const originalPriceText = hasPriceDiscount ? (
      <LineThroughText inline>{originalPrice}</LineThroughText>
    ) : (
      <Large inline>{originalPrice}</Large>
    );
    const discountedPrice =
      hasPriceDiscount && totalPrice !== undefined
        ? formatCurrency(totalPrice, currency, {
            showDecimal: true,
          })
        : '';
    const discountedPriceText = discountedPrice ? (
      <Large inline variant="largeBoldBrightGreen">
        {discountedPrice}
      </Large>
    ) : null;
    sectionSubTitle = (
      <>
        Your next payment is for {originalPriceText} {discountedPriceText} on{' '}
        {moment(renewalDate).format(DATE_FORMAT_STRING)}
      </>
    );
  } else if (showExpiry) {
    sectionSubTitle = (
      <>Your plan will expire on {moment(endSubscriptionDate).format(DATE_FORMAT_STRING)}</>
    );
    sectionTitle = tnewMemberNav('payment.expiry', 'Expiry');
  }
  return { sectionTitle, sectionSubTitle };
}
const getNextRenewalDetailsProps = (subscriptionInfo: SubscriptionInfo) => {
  const {
    planDisplayName: displayName,
    price,
    voucherValue,
    billingCycleValue: cycleValue,
    billingCycleUnit: unit,
    discounts,
    currency,
    renewalDate,
    customerBalanceCents,
    lineItems,
  } = subscriptionInfo;

  const amount = Number(price);
  const billingPrice = { amount, cycleValue, unit, currency };
  const offerPrice = { amount, unit, currency };
  const planData = { displayName, billingPrice, offerPrice };
  const changePlanCheckoutInfo = {
    customerBalanceCents: Number(customerBalanceCents),
    prorationAmountCents: 0,
  };
  const validatedCoupons = discounts && discounts.length > 0 ? discounts : undefined;
  const nextPaymentDetailsLineItems = generateConsumerLineItems(
    planData as PlanData,
    validatedCoupons,
    undefined,
    changePlanCheckoutInfo,
    voucherValue,
    lineItems,
    true
  );
  const { total, savings } = calculateTotals(nextPaymentDetailsLineItems, amount);
  return {
    lineItems: nextPaymentDetailsLineItems,
    total,
    savings,
    billingPrice,
    renewalDate,
  };
};

const NextRenewal: FunctionComponent<NextRenewalProps> = ({ subscriptionInfo, roomStatus }) => {
  const openModal = useOpenModalSafe();
  const { isMobile } = useWindowWidth();

  const { renewalDate, isTrial, trialEnd } = subscriptionInfo;
  const currentRenewalDate = isTrial ? trialEnd : renewalDate;
  const updatedSubscriptionInfo = { ...subscriptionInfo, renewalDate: currentRenewalDate };
  const { t: tnewMemberNav } = useTranslation('newMemberNav');

  const handleViewDetailsPress = () => {
    const nextRenewalPaymentDetailsProps = getNextRenewalDetailsProps(updatedSubscriptionInfo);
    openModal(`/next-renewal-payment-details`, nextRenewalPaymentDetailsProps, isMobile);
  };

  const { sectionTitle, sectionSubTitle } = getRenewalString(
    updatedSubscriptionInfo,
    roomStatus,
    tnewMemberNav
  );

  return (
    <Container>
      <Tiny>{sectionTitle}</Tiny>
      <Large>{sectionSubTitle}</Large>
      {currentRenewalDate && (
        <ClearButton onPress={() => handleViewDetailsPress()} dataQa="nextRenewalViewDetailsButton">
          <Large variant="largeBoldWideGreen">View details</Large>
        </ClearButton>
      )}
    </Container>
  );
};

export default NextRenewal;
