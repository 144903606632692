import { TouchableView, withHover, WithHoverProps } from '@talkspace/react-toolkit';
import styled from '../../core/styled';

const MenuNavLink = styled(TouchableView)<WithHoverProps>(({ isHovering, theme: { colors } }) => {
  return {
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: 38,
    borderRadius: 5,
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: isHovering ? colors.permaLinkWaterGrey : 'transparent',
    display: 'flex',
  };
});

export default withHover(MenuNavLink);
