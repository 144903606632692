import moment from 'moment';
import { useState, useCallback, useEffect } from 'react';
import { useFlag } from 'launchDarkly/FlagsProvider';
import localStorage from '@/core/storage/localStorage';
import { trackEvent } from '@/utils/analytics/eventTracker';

interface UseReactivationTrayParams {
  canRematch: boolean;
  isClosedInactive: boolean;
  isB2B: boolean;
  isB2C: boolean;
  isLoading: boolean;
  roomID: number;
  shouldActivateDueToDischarge: boolean;
}

const LOCAL_STORAGE_INACTIVITY_KEY = 'dismissWelcomeBackInactivityTray';
const LOCAL_STORAGE_ACTIVITY_KEY = 'dismissWelcomeBackActivityTray';

const parseStorage = (key: string): Record<number, string> => {
  const valueString = localStorage.getItem(key);
  if (!valueString) {
    return {};
  }
  try {
    return JSON.parse(valueString);
  } catch (error) {
    return {};
  }
};

const hasSeenTrayByRoom = (roomID: number, key: string) => {
  const storageObj = parseStorage(key);
  const seenTrayDate: string | null | undefined = storageObj[roomID];
  if (!seenTrayDate) {
    return false;
  }
  if (moment(seenTrayDate).isAfter(moment().subtract(1, 'week'))) {
    return true;
  }
  return false;
};
const setStorageValueForRoom = (roomID: number, key: string) => {
  const storageObj = parseStorage(key);
  storageObj[roomID] = new Date().toISOString();
  localStorage.setItem(key, JSON.stringify(storageObj));
};

interface UseReactivationTrayResult {
  isProviderAvailable: boolean;
  b2BMemberInactivity: boolean | undefined;
  hasSeenWelcomeBackInactivityTray: boolean;
  hasSeenWelcomeBackActivityTray: boolean;
  dismissReactivationTray: () => void;
  showReactivationTray: boolean | undefined;
  showReactivationFlow?: boolean | null;
  applyReactivationTray?: () => void;
}

export default function useReactivationTray({
  canRematch,
  isClosedInactive,
  isLoading,
  isB2B,
  roomID,
}: UseReactivationTrayParams): UseReactivationTrayResult {
  const b2BMemberInactivity = useFlag('b2BMemberInactivity');
  const [hasSeenWelcomeBackInactivityTray, setHasSeenWelcomeBackInactivityTray] = useState(() =>
    hasSeenTrayByRoom(roomID, LOCAL_STORAGE_INACTIVITY_KEY)
  );
  const [hasSeenWelcomeBackActivityTray, setHasSeenWelcomeBackActivityTray] = useState(() =>
    hasSeenTrayByRoom(roomID, LOCAL_STORAGE_ACTIVITY_KEY)
  );

  const isProviderAvailable = canRematch;

  const showReactivationTray =
    !isLoading &&
    ((isProviderAvailable && !hasSeenWelcomeBackActivityTray) ||
      (!isProviderAvailable && !hasSeenWelcomeBackInactivityTray)) &&
    isClosedInactive &&
    isB2B &&
    b2BMemberInactivity;

  const dismissReactivationTray = useCallback(() => {
    if (isProviderAvailable) {
      setStorageValueForRoom(roomID, LOCAL_STORAGE_ACTIVITY_KEY);
      setHasSeenWelcomeBackActivityTray(true);
    } else {
      setStorageValueForRoom(roomID, LOCAL_STORAGE_INACTIVITY_KEY);
      setHasSeenWelcomeBackInactivityTray(true);
    }
  }, [isProviderAvailable, roomID]);

  useEffect(() => {
    if (showReactivationTray) {
      trackEvent(
        'Inactivity Prompt',
        {
          actionName: 'inactivityPromptInteraction',
          isProviderAvailable,
          roomID,
        },
        ['tsAnalytics']
      );
    }
  }, [roomID, showReactivationTray, isProviderAvailable]);

  return {
    isProviderAvailable,
    b2BMemberInactivity,
    hasSeenWelcomeBackInactivityTray,
    hasSeenWelcomeBackActivityTray,
    dismissReactivationTray,
    showReactivationTray,
  };
}
