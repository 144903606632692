import { AccordionContainer } from '@talkspace/react-toolkit';

import styled from '../../../core/styled';

const BaseAccordion = styled(AccordionContainer)<{ useNewNav?: boolean }>(
  ({ useNewNav, theme: { window } }) => {
    const { isMobile } = window;

    const defaultStyle = { maxWidth: isMobile ? '100%' : 500 };
    const newNavStyle = { maxWidth: '100%' };

    return {
      marginBottom: 0,
      borderRadius: 0,
      borderBottom: 0,
      width: 'unset',
      minWidth: isMobile ? '100%' : '335',
      padding: 'unset',
      ...(useNewNav ? newNavStyle : defaultStyle),
    };
  }
);

export default BaseAccordion;
