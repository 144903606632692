import {
  useEmotionTheme,
  EmotionStyle,
  ExclamationCircle,
  Small,
  View,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';

const Container = styled(View)(({ theme: { colors } }) => {
  return {
    backgroundColor: '#EFF3FA', // NOTE: Intentionally not using EmotionTheme colors. Must not change color in high contrast
    borderRadius: 10,
    padding: '10px 15px',
  };
});
interface Props {
  text: string;
  style?: EmotionStyle;
}
const IncompleteInfoTooltip = ({ text, style }: Props) => {
  const { colors } = useEmotionTheme();
  return (
    <Container row align="center" style={style}>
      <ExclamationCircle color={colors.torchRed} />
      <Small style={{ marginLeft: 8 }}>{text}</Small>
    </Container>
  );
};

export default IncompleteInfoTooltip;
