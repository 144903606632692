import {
  Button,
  FullScreenWrapper,
  Huge,
  Standard,
  View,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';
import GymPerson from '../../../assets/GymPerson';

interface Props {
  onPressBack: () => void;
  onPressClose: () => void;
  onPressGoToRoom: () => void;
}

const ManageSubscriptionThanksForStaying = ({
  onPressBack,
  onPressClose,
  onPressGoToRoom,
}: Props) => {
  const { colors } = useEmotionTheme();
  const { brandRefreshChanges } = useFlags();
  return (
    <FullScreenWrapper
      goBack={onPressBack}
      showCloseButton
      onCloseButtonClick={onPressClose}
      newLogo={brandRefreshChanges}
    >
      <View
        style={{
          maxWidth: 335,
          textAlign: 'center',
          justifyContent: 'space-between',
          align: 'center',
        }}
      >
        <>
          <GymPerson />
          <Huge style={{ marginTop: 30, fontSize: 29, lineHeight: '34px', fontWeight: 700 }}>
            You’ve got this. We are cheering you on.
          </Huge>
          <Standard
            style={{
              marginTop: 10,
              color: colors.permaRiverBed,
              fontSize: 16,
              lineHeight: '20px',
              fontWeight: 400,
            }}
          >
            We know life is busy and we applaud your decision to continue prioritizing your mental
            health.
          </Standard>
          <Button
            dataQa="goBackToRoom"
            text="Go back to my room"
            onPress={onPressGoToRoom}
            style={{
              marginTop: 40,
              width: '100%',
              backgroundColor: colors.permaTalkspaceDarkGreen,
            }}
          />
        </>
      </View>
    </FullScreenWrapper>
  );
};

export default ManageSubscriptionThanksForStaying;
