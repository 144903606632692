import getParamByName from './queryString';

export interface FlowData {
  roomType: string;
  funnelVariation: string;
  registrationUrl: string;
  referrerUrl: string;
}

export interface RegistrationCookie {
  flowData: FlowData;
  qmSessionID?: string;
}

export const DEFAULT_FUNNEL_VARIATION = 'direct';

export const getRegistrationCookie = (): RegistrationCookie | null => {
  const REGISTER_COOKIE = '_tsRegisterFlow';

  function readCookie(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0, len = ca.length; i < len; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return '';
  }

  const regCookie = readCookie(REGISTER_COOKIE);
  try {
    return JSON.parse(regCookie);
  } catch (error) {
    return null;
  }
};

export const getFlowData = ({ ignoreCookie = false, removeCurrentReferrer = false } = {}) => {
  const funnelVariation = getParamByName('funnel') || DEFAULT_FUNNEL_VARIATION;
  const registrationCookie = getRegistrationCookie();

  const flowData = {
    registrationUrl: window.location.href,
    funnelVariation,
    roomType: 'privateRoom',
    referrerUrl: document.referrer || '',
    ...(registrationCookie?.flowData && !ignoreCookie ? registrationCookie.flowData : {}),
    ...(!removeCurrentReferrer && { currentReferrerUrl: document.referrer }),
  };

  return flowData;
};
