import { WizardScheme } from 'stepWizard';

const financialAidWizardScheme: WizardScheme = {
  wizardVersion: 1,
  wizardType: 'financialAid',
  wizardModalTitle: 'financial aid',
  steps: [
    {
      title: 'What best describes your current employment status?',
      inputType: 'select',
      inputState: 'employmentStatus',
      inputOptions: 'employmentStatusOptions',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      name: 'Employment status',
      backOption: 'back',
    },
    {
      title: 'What best describes the current employment status of your spouse?',
      inputType: 'select',
      inputState: 'spouseEmploymentStatus',
      inputOptions: 'spouseEmploymentStatusOptions',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      name: 'Spouse employment status',
      backOption: 'back',
    },
    {
      title: 'How much does your household earn from working on a monthly basis?',
      subtitle: `Please include your spouse's income, gross wages, salaries, income from business, tips, etc. if applicable.`,
      inputType: 'select',
      inputState: 'householdEarnings',
      inputOptions: 'householdEarningsOptions',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      name: 'Household earnings',
      backOption: 'back',
    },
    {
      title:
        'Are you currently serving on active duty in the U.S Armed Forces for purposes other than training?',
      inputState: 'isServingMilitary',
      nextButton: {
        displayText: 'Yes',
        actionDispatch: 'next',
        whenVisible: 'always',
      },
      secondaryButton: {
        displayText: 'No',
        actionDispatch: 'next',
        whenVisible: 'always',
        displayStyle: 'primary',
      },
      name: 'Is serving in military',
      backOption: 'back',
    },
    {
      title: 'Are you a veteran of the U.S Armed Forces?',
      inputState: 'isVeteran',
      nextButton: {
        displayText: 'Yes',
        actionDispatch: 'next',
        whenVisible: 'always',
      },
      secondaryButton: {
        displayText: 'No',
        actionDispatch: 'next',
        whenVisible: 'always',
        displayStyle: 'primary',
      },
      name: 'Is veteran',
      backOption: 'back',
    },
    {
      title:
        'In the last 2 years, did you or anyone in your household receive benefits from any of these federal programs?',
      inputType: 'multiSelect',
      inputTypePlaceholder: 'Select',
      inputState: 'benefitsReceived',
      inputOptions: 'benefitsReceivedOptions',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      secondaryButton: {
        displayText: 'No, my household did not receive benefits from these programs.',
        actionDispatch: 'next',
        whenVisible: 'hideOnSelect',
      },
      name: 'Household benefits',
      backOption: 'back',
    },
    {
      title: 'Do you live in a rural area?',
      subtitle: `A "rural area" is defined as having a population of less than 50,000 in the surrounding 25 miles.`,
      inputState: 'isLivingInRuralArea',
      nextButton: {
        displayText: 'Yes',
        actionDispatch: 'next',
        whenVisible: 'always',
      },
      secondaryButton: {
        displayText: 'No',
        actionDispatch: 'next',
        whenVisible: 'always',
        displayStyle: 'primary',
      },
      name: 'Is living in rural area',
      backOption: 'back',
    },
    {
      title: `We're ready to check your financial aid eligibility.`,
      subtitle: `By continuing, you certify that the information you've provided is correct.`,
      inputState: '',
      nextButton: {
        displayText: 'Check eligibility',
        actionDispatch: '/financial-aid/financial-aid-eligibility',
        whenVisible: 'always',
      },
      name: 'Check Eligibility',
      backOption: 'back',
    },
  ],
};

export default financialAidWizardScheme;
