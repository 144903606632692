import { useQuery, useQueryClient, QueryKey } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../core/api/apiWrapper';
import { onboardingV3QueryKey } from '../../utils/queryKeys';

import { Step } from '../types';

interface QueryProps {
  roomID?: number;
  userID?: number;
}

export interface OnboardingAPIResponseV3 {
  [roomID: number]: Array<Step>;
}

const fetchOnboarding =
  ({ roomID, userID }: QueryProps) =>
  async () => {
    if (!userID) {
      throw new Error('useQueryOnboarding missing user id');
    }
    const queryParam = roomID ? `?roomID=${roomID}` : '';
    const { data } = await apiWrapper.get(
      `${apiHelper().apiEndpoint}/v3/clients/${userID}/onboarding${queryParam}`
    );
    return data.data;
  };

const getCompatibleQueryKeys = (options: Partial<QueryProps>) => {
  const { userID, roomID } = options;
  if (!userID) {
    return [];
  }
  const keys: QueryKey[] = [
    onboardingV3QueryKey({
      userID,
      roomID: undefined,
    }),
    onboardingV3QueryKey({
      userID,
      roomID,
    }),
  ];
  return keys;
};

const useQueryOnboardingV3 = ({ roomID, userID }: QueryProps) => {
  const queryClient = useQueryClient();
  const query = useQuery<OnboardingAPIResponseV3, Error>({
    queryKey: onboardingV3QueryKey({ roomID, userID }),
    queryFn: fetchOnboarding({ roomID, userID }),
    initialData: () => {
      const compatibleKeys = getCompatibleQueryKeys({
        roomID,
        userID,
      });
      const cacheData = compatibleKeys
        .map((oneKey) => queryClient.getQueryData<OnboardingAPIResponseV3>(oneKey))
        .find(Boolean);
      return cacheData && roomID ? { [roomID]: cacheData[roomID] } : {};
    },
    initialDataUpdatedAt: () => {
      const compatibleKeys = getCompatibleQueryKeys({
        roomID,
        userID,
      });
      const cacheState = compatibleKeys
        .map((oneKey) => queryClient.getQueryState<OnboardingAPIResponseV3>(oneKey))
        .find(Boolean);
      return cacheState?.dataUpdatedAt;
    },
    cacheTime: Infinity,
    staleTime: 0,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    enabled: Boolean(userID),
  });
  return query;
};

export default useQueryOnboardingV3;
