import { FunctionComponent, useState } from 'react';
import { ExtraHuge, View, Large, Button, Envelope, Text } from '@talkspace/react-toolkit';
import styled from '../../core/styled';
import { useRoomInvitesActions } from '../hooks/useRoomInvites';

const Wrapper = styled(View)({
  maxWidth: 600,
  paddingLeft: 30,
  paddingRight: 30,
  alignSelf: 'center',
  alignItems: 'center',
  width: '100%',
});

const IconContainer = styled(View)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 50,
});

const MessageTitleContainer = styled(View)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 15,
});

const MessageContainer = styled(View)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 20,
  textAlign: 'center',
});

const ButtonContainer = styled(View)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 40,
  textAlign: 'center',
});

const RevokeButtonMargin = styled(View)({
  marginTop: 20,
});

const InvitationSentSuccess: FunctionComponent<{
  roomID: number;
  inviteEmail: string;
  inviteID: number;
  handleClosePanel: () => void;
}> = ({ roomID, inviteEmail, inviteID, handleClosePanel }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { revokeInvite } = useRoomInvitesActions();
  const handleRevokeInvitation = () => {
    setIsLoading(true);
    revokeInvite(roomID, inviteID);
  };
  return (
    <Wrapper>
      <IconContainer>
        <Envelope width={74} />
      </IconContainer>
      <MessageTitleContainer>
        <ExtraHuge>Invitation email sent</ExtraHuge>
      </MessageTitleContainer>
      <MessageContainer>
        <Large variant="largeDarkGrey">
          We sent an invitation to{' '}
          <Text inline style={{ fontWeight: 'bold' }}>
            {inviteEmail}
          </Text>
          .
        </Large>
        <Large variant="largeDarkGrey" style={{ marginTop: 20 }}>
          Their link will be valid for the next 24 hours. We’ll let you know when they’ve joined the
          room.
        </Large>
      </MessageContainer>
      <ButtonContainer>
        <Button onClick={handleClosePanel}>Done</Button>
        <RevokeButtonMargin>
          <Button
            isLoading={isLoading}
            isSecondary
            size="small"
            removeBorder
            onClick={handleRevokeInvitation}
          >
            Revoke Invitation
          </Button>
        </RevokeButtonMargin>
      </ButtonContainer>
    </Wrapper>
  );
};

export default InvitationSentSuccess;
