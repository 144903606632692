import Svg, { Defs, Path, G, Use } from 'svgs';

const BookingErrorIcon = ({ width = 50, height = 57, ...otherProps }) => (
  <Svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} {...otherProps}>
    <Defs>
      <Path d="M10 20.5L39 43" id="a" />
    </Defs>
    <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <Path
        d="M38.724 0a3.583 3.583 0 013.583 3.583v4.341l-.003.138c4.142 1.199 7.17 5.02 7.17 9.548v29.249c0 5.488-4.45 9.937-9.938 9.937H9.938C4.449 56.796 0 52.347 0 46.86v-29.25c0-4.527 3.028-8.348 7.17-9.547a2.655 2.655 0 01-.003-.138v-4.34a3.583 3.583 0 117.167 0l-.001 4.087H35.14V3.583A3.583 3.583 0 0138.724 0z"
        transform="translate(-577 -334) translate(577 334)"
        fill="#A1ABC6"
      />
      <Path
        d="M26.506 23.318a3.22 3.22 0 013.22 3.22v11.696a3.22 3.22 0 01-3.22 3.22H14.721a3.22 3.22 0 01-3.221-3.22V26.539a3.22 3.22 0 013.22-3.22h11.786zm12.326 2.952c.11.194.168.413.168.636V37.2a1.288 1.288 0 01-1.924 1.12l-5.418-3.074a1.288 1.288 0 01-.652-1.12V29.98c0-.464.249-.892.652-1.12l5.418-3.075a1.288 1.288 0 011.756.484z"
        fill="#FFF"
        transform="translate(-577 -334) translate(577 334)"
      />
      <G strokeLinecap="round" transform="translate(-577 -334) translate(577 334)">
        <Use stroke="#A1ABC6" strokeWidth={6} href="#a" />
        <Use stroke="#FFF" strokeWidth={3} href="#a" />
      </G>
    </G>
  </Svg>
);

export default BookingErrorIcon;
