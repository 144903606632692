import { ExtraHuge, View } from '@talkspace/react-toolkit';
import { useEffect, useState } from 'react';
import { routePromise } from 'ts-frontend/helpers';
import { getAccessToken } from '@/auth/helpers/token';
import styled from '@/core/styled';
import { useClientAuthActions, useClientAuthState } from '@/hooks/clientAuthContext';
import LoadingScreen from '@/screens/LoadingScreen';
import getParamByName from '@/utils/queryString';
import { useAttribution } from '@/hooks/useAttribution';
import SSOCallbackError from '../SSOCallbackError';
import isInWebView from '../SSOLogin/isInWebView';

const HeaderContainer = styled(View)({
  marginLeft: 17,
  marginRight: 17,
  marginBottom: 15,
  marginTop: 55,
  textAlign: 'center',
});

const SSOCallback = () => {
  const { loginSSOAction } = useClientAuthActions();
  const { isErrorLoginSSOAction, isUpdating } = useClientAuthState();
  const [errorFromHash] = useState(() => getParamByName('error_description'));
  const { data, isFetched } = useAttribution();

  useEffect(() => {
    if (errorFromHash || isInWebView() || !isFetched) {
      return;
    }

    loginSSOAction({
      accessToken: getParamByName('access_token') || '',
      idToken: getParamByName('id_token') || '',
      tokenType: getParamByName('token_type') || '',
      expiresIn: +(getParamByName('expires_in') || '0'),
      qmSessionID: data?.params.sessionID,
    });
  }, [errorFromHash, loginSSOAction, data, isFetched]);

  useEffect(() => {
    if (
      isUpdating ||
      isErrorLoginSSOAction ||
      errorFromHash ||
      !getAccessToken() ||
      isInWebView()
    ) {
      return;
    }

    routePromise('/login-success');
  });

  if (isErrorLoginSSOAction || errorFromHash) {
    return <SSOCallbackError />;
  }

  if (isInWebView()) {
    return (
      <View flex={1} align="center">
        <HeaderContainer>
          <ExtraHuge>redirecting</ExtraHuge>
        </HeaderContainer>
      </View>
    );
  }

  return <LoadingScreen />;
};

export default SSOCallback;
