import { Route, Switch, useRouteMatch } from '@/core/routerLib';

import CancelSession from './containers/CancelSession';
import CancelSessionSelectReason from './containers/CancelSessionSelectReason';
import CancelSessionConfirmation from './containers/CancelSessionConfirmation';

const Routes = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      {/* <Route path="/" component={SessionDetails} />  TODO: add new overhauled session details page here */}
      <Route exact path={`${match.path}/cancel-session`} component={CancelSession} />
      <Route exact path={`${match.path}/cancel-session/ineligible`} component={CancelSession} />
      <Route
        exact
        path={`${match.path}/cancel-session/select-reason`}
        component={CancelSessionSelectReason}
      />
      <Route
        exact
        path={`${match.path}/cancel-session/confirmation`}
        component={CancelSessionConfirmation}
      />
    </Switch>
  );
};

export default Routes;
