import { useQuery } from 'react-query';
import { getCommunityFilteredPostCountQueryKey } from '../../utils/queryKeys';

interface QueryProps {
  accessToken: string | undefined;
  communityID: string | undefined;
  tags: string[];
}

const fetchCommunityFilteredPostCount =
  ({ accessToken, communityID, tags }: QueryProps) =>
  async (): Promise<number> => {
    const response = await fetch(
      `https://beta.amity.services/api/v3/search/posts?targetType=community&targetIds[]=${communityID}&tags[]=${tags.join(
        ','
      )}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: { found: number } = await response.json();
    return data.found;
  };

const useQueryCommunityFilteredPostCount = ({
  communityID,
  accessToken,
  tags, // NOTE: Amity API is filtering posts using OR operation, not AND for this tags list
  disabled,
}: QueryProps & { disabled?: boolean }) =>
  useQuery<number, Error>({
    queryKey: getCommunityFilteredPostCountQueryKey({ communityID, tags }),
    queryFn: fetchCommunityFilteredPostCount({ accessToken, communityID, tags }),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: [communityID, accessToken, tags].every((e) => Boolean(e)) && !disabled,
  });

export default useQueryCommunityFilteredPostCount;
