import { FunctionComponent, useEffect, useCallback, useState } from 'react';
import * as React from 'react';
import { View, Grid, Row, Col, Card, TextDS, spacing } from '@talkspace/react-toolkit';
import { useTranslation } from '@talkspace/i18n';
import SearchSpyglass from '@talkspace/react-toolkit/src/designSystems/illustrations/SearchSpyglass';
import { ClientMatchPresentingProblem } from 'ts-frontend/types';
import { TherapistType } from 'ts-frontend/entities/Therapist';
import { MatchPreferenceReason } from '../../../types';
import FullscreenWrapper from '../../../components/FullscreenWrapper';
import TherapistMatchDirectoryCard from '../../../components/TherapistMatchDirectoryCard';
import useMatchResults from '../../hooks/useMatchResults';
import { MatchResult, MatchPayload } from '../../matchingTypes';
import { trackEvent } from '../../../utils/analytics/eventTracker';
import { trackWizardEvent } from '../../../utils/analytics/events';
import OtherAvailableProviders from '../OtherAvailableProviders';

const getMatchReasons = ({
  matchPayload,
  therapistMatch,
  selectedPresentingProblemsAsExpertise,
}: {
  matchPayload: MatchPayload;
  therapistMatch?: MatchResult | null;
  selectedPresentingProblemsAsExpertise: ClientMatchPresentingProblem[];
}): MatchPreferenceReason[] => {
  if (!therapistMatch) {
    return [];
  }

  const matchedExpertise = selectedPresentingProblemsAsExpertise.filter((pProblem) =>
    therapistMatch.details.allFieldsOfExpertise.find(
      (expertise) => expertise === pProblem.expertiseName
    )
  );

  const expertiseReasons: MatchPreferenceReason[] = matchedExpertise.map((expertise) => {
    return {
      value: expertise.name,
      matchPreferenceName: 'presentingProblem',
    };
  });
  const matchReasons: MatchPreferenceReason[] = [...expertiseReasons.slice(0, 3)];
  if (matchPayload.languages?.length || matchPayload.languagesSoft?.length) {
    const allLangs = therapistMatch.details.additionalLanguages.join(', ');
    matchReasons.push({ value: allLangs, matchPreferenceName: 'language' });
  }
  if (matchPayload.therapistGender) {
    matchReasons.push({
      value: therapistMatch.details.gender === 1 ? 'Male' : 'Female',
      matchPreferenceName: 'gender',
    });
  }
  if (therapistMatch.details.yearsExperience > 2) {
    matchReasons.push({
      value: `${therapistMatch.details.yearsExperience} years`,
      matchPreferenceName: 'yearsOfExperience',
    });
  }

  return matchReasons;
};

const getAnalyticsEventProperties = (matchResults?: MatchResult[]) => {
  if (matchResults && matchResults.length > 0) {
    return {
      matchesFound: 'true',
      therapistIDs: matchResults.map((result) => `${result.details.id}`).join(','),
    };
  }
  return {
    matchesFound: 'false',
    therapistIDs: '',
  };
};

interface MatchResultsProps extends React.HTMLAttributes<HTMLDivElement> {
  roomID: number;
  matchPayload: MatchPayload;
  selectedPresentingProblemAsString: string;
  selectedPresentingProblemsAsExpertise: ClientMatchPresentingProblem[];
  skipPayment: boolean;
  handleSwitchWizardSelectedTherapist?: (
    therapistID: number,
    therapistFirstName: string,
    therapistImage: string,
    therapistType: TherapistType
  ) => void;
  eventCategory?: string;
  clientUserID?: number;
  formerTherapistID?: number;
  isB2B?: boolean;
  isBooking?: boolean;
}

const MatchResultsV2: FunctionComponent<MatchResultsProps> = ({
  roomID,
  matchPayload,
  selectedPresentingProblemAsString,
  selectedPresentingProblemsAsExpertise,
  skipPayment,
  handleSwitchWizardSelectedTherapist,
  eventCategory,
  clientUserID,
  formerTherapistID,
  isB2B,
  isBooking,
  ...otherProps
}) => {
  const { matchResultsState } = useMatchResults();
  const [showList, setShowList] = useState(false);
  const { t: tSwitchWizard } = useTranslation('switchWizard');

  useEffect(() => {
    trackEvent('Meet Matched Therapists', {
      eventCategory: 'Reactivation',
      eventCategoryKey: 'Funnel Name',
    });
  }, []);

  useEffect(() => {
    if (eventCategory === 'Self Serve Switch') {
      const eventProps = getAnalyticsEventProperties(matchResultsState.matchResults);
      trackWizardEvent('Meet Matched Therapists', eventCategory, {
        'Match Found': eventProps.matchesFound,
        'Therapist ID': eventProps.therapistIDs,
        'User Id': clientUserID ? clientUserID.toString() : '',
        label: 'Matches Shown',
        Application: eventCategory,
        eventProperty: eventProps.therapistIDs,
        eventPropertyValue: formerTherapistID || 0.0,
      });
    }
  }, [clientUserID, formerTherapistID, matchResultsState.matchResults, eventCategory]);
  const topMatch = matchResultsState?.matchResults?.length
    ? matchResultsState?.matchResults[0]
    : null;

  const onPressMoreMatches = useCallback(() => {
    setShowList(true);
  }, []);

  if (!matchResultsState?.matchResults || !matchResultsState?.matchResults?.length) {
    return null;
  }

  const showMoreProviders = matchResultsState?.matchResults?.length > 1;
  return (
    <FullscreenWrapper
      style={{
        marginTop: 13,
        marginBottom: 60,
      }}
      {...otherProps}
    >
      <Grid>
        {topMatch && !showList && (
          <Row style={{ paddingBottom: spacing.space300 }}>
            <Col xs={4} sm={8} md={8} lgOffset={2} lg={8} xlOffset={2} xl={8}>
              <TherapistMatchDirectoryCard
                matchResult={topMatch}
                matchPreferenceReasons={getMatchReasons({
                  matchPayload,
                  therapistMatch: topMatch,
                  selectedPresentingProblemsAsExpertise,
                })}
                peopleHelped={0}
                topSpecialties={topMatch.details.expertise.mostRelevant}
                nextAvailable={topMatch.details.nextAvailableTimeslot}
                isRecommended
                isHighlighted
                isBooking={isBooking}
                onPress={() => {
                  if (handleSwitchWizardSelectedTherapist) {
                    handleSwitchWizardSelectedTherapist(
                      topMatch.userId,
                      topMatch.details.firstName,
                      topMatch.details.image || '',
                      topMatch.details.therapistType
                    );
                  }
                }}
              />
            </Col>
          </Row>
        )}

        {!showList && showMoreProviders && (
          <Row style={{ paddingBottom: spacing.space300 }}>
            <Col xs={4} sm={8} md={8} lgOffset={2} lg={8} xlOffset={2} xl={8}>
              <OtherAvailableProviders
                matchResults={matchResultsState.matchResults}
                onPress={onPressMoreMatches}
              />
            </Col>
          </Row>
        )}

        {showList &&
          matchResultsState.matchResults.map((matchResult) => (
            <Row style={{ paddingBottom: spacing.space300 }}>
              <Col xs={4} sm={8} md={8} lgOffset={2} lg={8} xlOffset={2} xl={8}>
                <TherapistMatchDirectoryCard
                  matchResult={matchResult}
                  matchPreferenceReasons={getMatchReasons({
                    matchPayload,
                    therapistMatch: matchResult,
                    selectedPresentingProblemsAsExpertise,
                  })}
                  peopleHelped={0}
                  topSpecialties={matchResult.details.expertise.mostRelevant}
                  nextAvailable={matchResult.details.nextAvailableTimeslot}
                  isBooking={isBooking}
                  onPress={() => {
                    if (handleSwitchWizardSelectedTherapist) {
                      handleSwitchWizardSelectedTherapist(
                        matchResult.userId,
                        matchResult.details.firstName,
                        matchResult.details.image || '',
                        matchResult.details.therapistType
                      );
                    }
                  }}
                />
              </Col>
            </Row>
          ))}
        <Row>
          <Col xs={4} sm={8} md={8} lgOffset={2} lg={8} xlOffset={2} xl={8}>
            <Card surfaceRoleVariantName="subtle" variant="noBorder" isDisabled>
              <View align="center" justify="center">
                <View>
                  <SearchSpyglass />
                </View>
                <TextDS variant="headingMd">
                  {tSwitchWizard('MatchResults.notSure', 'Not sure you’ve found the right fit?')}
                </TextDS>
                <TextDS variant="bodySm" style={{ textAlign: 'center' }}>
                  {tSwitchWizard(
                    'MatchResults.bestWayToKnow',
                    'The best way to know is by trying a session. We make it easy to change therapists at any time.'
                  )}
                </TextDS>
              </View>
            </Card>
          </Col>
        </Row>
      </Grid>
    </FullscreenWrapper>
  );
};

export default MatchResultsV2;
