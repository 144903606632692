import { FunctionComponent, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router';
import { formatCurrency } from 'ts-frontend/helpers/billingUtils';
import { persistData, useWizardState, useWizardActions, WizardActionStatus } from 'stepWizard';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { trackWizardEvent } from '@/utils/analytics/events';
import useRefundWizard from '../hooks/useRefundWizard';
import { RouteComponentProps } from '../../core/routerLib';

const ApplyRefundContainer: FunctionComponent<RouteComponentProps<{}>> = ({ history }) => {
  const {
    isVerifyExitModalOpen,
    source,
    responses,
    clientUserID,
    eventCategory,
    selectedRefundableCharge: charge,
  } = useWizardState();
  const closeModal = useCloseModal();
  const [state, { applyRefund, exitAction }] = useRefundWizard();
  const { setState, closeWizard } = useWizardActions();
  const { error, isSuccess } = state;

  const formattedRefundAmount = () => {
    if (isSuccess) {
      return formatCurrency(charge.refundableAmount, charge.currency);
    }
    return '';
  };

  const handlePersist = useCallback(() => {
    persistData(responses, setState, 'Refund applied', error, { refundApplied: true }, isSuccess);
  }, [error, isSuccess, responses, setState]);

  const handleExitModal = () => {
    exitAction();
    if (source === 'payment-and-plan') {
      closeWizard();
    } else {
      closeModal({
        navigateTo: 'dashboard',
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      trackWizardEvent('Refund Issued', eventCategory, {
        'User ID': clientUserID,
        'Refund Amount': charge.refundableAmount,
        label: 'Refund Amount',
        Application: eventCategory,
        eventProperty: charge.refundableAmount,
        eventPropertyValue: 0.0,
      });
    }
  }, [isSuccess]); // eslint-disable-line

  const handleErrorSupportButton = () => {
    setState({ didRefundFail: true });
    history.push('/refund-purchase/support-request-submitted', { source });
  };

  useEffect(() => {
    if (isVerifyExitModalOpen) {
      handleExitModal();
    }
  }, [isVerifyExitModalOpen]); // eslint-disable-line

  useEffect(() => {
    applyRefund(charge);
  }, []); // eslint-disable-line

  useEffect(() => {
    // persisting the data on error or success
    const handleRequestStatus = async () => {
      if (isSuccess || error) {
        handlePersist();
      }
    };
    handleRequestStatus();
  }, [isSuccess, error]); // eslint-disable-line

  return (
    <WizardActionStatus
      errorPrimaryButtonText="Submit a support request"
      errorPrimaryButtonAction={handleErrorSupportButton}
      successTitle="You've been refunded"
      successSubTitleHeadline={`Refund Amount: ${formattedRefundAmount()}`}
      successSubTitle="We have refunded the amount to your original method of payment. Please allow 3-5 business days for it to appear in your account."
      successButtonText="Done"
      handleAction={handleExitModal}
      actionHookState={state}
    />
  );
};

export default withRouter(ApplyRefundContainer);
