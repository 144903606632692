import React from 'react';
import { Trans, useTranslation } from '@talkspace/i18n';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { A11Y_COLORS, ExtraHuge, GreenCircleCheckMark, Large } from '@talkspace/react-toolkit';

const ConsentReceived = ({ isConsentAlreadyGranted }: { isConsentAlreadyGranted: boolean }) => {
  const { localizationParentalConsent } = useFlags();
  const { t: tParentalConsent } = useTranslation('parentalConsentV2', {
    lng: localizationParentalConsent ? undefined : 'en',
  });
  return (
    <>
      <GreenCircleCheckMark style={{ marginBottom: 26 }} />
      {isConsentAlreadyGranted ? (
        <ExtraHuge
          dataQa="parentalConsentFormHasAlreadyBeenSignedLabel"
          style={{ marginBottom: 20 }}
        >
          <Trans t={tParentalConsent} i18nKey="consentConfirmation.alreadyBeenSigned">
            The consent form has already been signed
          </Trans>
        </ExtraHuge>
      ) : (
        <ExtraHuge dataQa="parentalConsentFormHasBeenReceivedLabel" style={{ marginBottom: 20 }}>
          <Trans t={tParentalConsent} i18nKey="consentConfirmation.headline">
            The consent form has been received
          </Trans>
        </ExtraHuge>
      )}
      {!isConsentAlreadyGranted && (
        <Large style={{ color: A11Y_COLORS.permaGrey }}>
          <Trans t={tParentalConsent} i18nKey="consentConfirmation.body">
            Thank you!
          </Trans>
        </Large>
      )}
    </>
  );
};

export default ConsentReceived;
