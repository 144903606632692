import {
  getCommunityTopic,
  PostRepository,
  subscribeTopic,
  SubscriptionLevels,
} from '@amityco/ts-sdk';
import { useEffect, useState } from 'react';

import { useCommunityState } from './CommunityContext';
import useCommunity from './useCommunity';
import { CommunityTargetTypeEnum } from '../types';

const useCommunityPosts = ({
  communityID,
  tags,
}: {
  communityID: string | undefined;
  tags: string[];
}) => {
  const [posts, setPosts] = useState<Amity.Post[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [onLoadMoreObj, setOnLoadMoreObj] = useState<any>({});

  const { isConnected } = useCommunityState();
  const { community } = useCommunity({ communityID });

  useEffect(() => {
    const disposers: Amity.Unsubscriber[] = [];

    if (isConnected && communityID && community) {
      const unsubscribe = PostRepository.getPosts(
        { targetId: communityID, targetType: CommunityTargetTypeEnum.COMMUNITY, tags, limit: 10 },
        ({ data: postsData, onNextPage, hasNextPage, loading, error }) => {
          setIsLoading(loading);
          setIsError(error);
          setPosts(postsData);
          setHasMore(hasNextPage || false);
          setOnLoadMoreObj({
            func: () => {
              // NOTE: have to wrap this function with () => onNextPage?.(), if used directly like onClick={onNextPage} it does not work properly
              onNextPage?.();
            },
          });
          disposers.push(
            subscribeTopic(getCommunityTopic(community, SubscriptionLevels.POST), () => {
              setIsError(true);
            })
          );
        }
      );
      disposers.push(unsubscribe);
    }
    return () => {
      disposers.forEach((fn) => fn());
    };
  }, [isConnected, tags, communityID, community]);

  return { posts, hasMore, onLoadMore: onLoadMoreObj.func, isLoading, isError };
};

export default useCommunityPosts;
