import { PlanData, BillingFrequency, InsuranceEligibility } from 'ts-frontend/types';
import { transformPlan, calculatePlanSavings } from 'ts-frontend/helpers/billingUtils';
import { calculateNetPrice } from 'ts-frontend/helpers';
import { DiscountGroup, OfferData, PromoToDisplay } from '../types';

function getDiscountGroupFrequency(discountGroup) {
  switch (discountGroup.display_inner_subtitle) {
    case '1 month':
      return BillingFrequency.Monthly;
    case '3 months':
      return BillingFrequency.Quarterly;
    case '6 months':
      return BillingFrequency.Biannual;
    default:
      return null;
  }
}

function transformDiscountGroup(discountGroup): DiscountGroup {
  const plans: PlanData[] = discountGroup.plans.map((plan) => transformPlan(plan));

  // This is pretty hacky, the second of 3 plans is the recommended plan
  if (plans.length === 3) {
    const [, plan2] = plans;
    plan2.badge = 'Recommended';
  }

  return {
    plans,
    frequency: getDiscountGroupFrequency(discountGroup),
    discountPercent: discountGroup.discountGroupJSON.display_top_discount,
  };
}

export function transformOffer(offer): OfferData {
  const discountGroups = offer.discountGroups
    ? offer.discountGroups
        .filter((discountGroup) => discountGroup.plans && discountGroup.plans.length > 0)
        .map(transformDiscountGroup)
    : [{ plans: offer.plans.map(transformPlan) }];
  return {
    id: offer.id,
    discountGroups,
    trialable: offer.trialable,
    trialValue: offer.trialValue,
    location: offer.location,
    currency: offer.currency,
    roomType: offer.roomType,
  };
}

export function createPlansMatrix(
  discountGroups: DiscountGroup[],
  showPlans: string | null
): PlanData[][] {
  const prepPlansMatrix: PlanData[][] = [];
  const plansMatrix: PlanData[][] = [];

  const showPlansArr = showPlans
    ? decodeURIComponent(showPlans)
        .split(',')
        .map((x) => Number(x))
    : [];

  discountGroups.forEach(({ plans, discountPercent }) => {
    plans.forEach((plan: PlanData, i: number) => {
      // eslint-disable-next-line no-param-reassign
      plan.discountPercent = discountPercent;

      // filter out plans
      if (showPlansArr.length && !showPlansArr.includes(plan.id)) return;

      if (!prepPlansMatrix[i]) {
        prepPlansMatrix[i] = [plan];
      } else {
        prepPlansMatrix[i].push(plan);
      }
    });
  });

  // the filtered plans can result and empty plans by positioning on index i,
  // this will prevent it from resulting empty plansMatrix and break the offers.
  prepPlansMatrix.forEach((plans) => {
    if (plans && plans.length) plansMatrix.push(plans);
  });

  // This is pretty hacky, the second of 3 plans is recommended, so it goes to the top
  if (plansMatrix.length === 3) {
    const [plan0, plan1] = plansMatrix;
    plansMatrix[0] = plan1;
    plansMatrix[1] = plan0;
  }

  // calculate plan savings, this should move to the backend OR the API should return data so we can calculate it
  plansMatrix.forEach((plans) => {
    plans.forEach((plan) => {
      // eslint-disable-next-line no-param-reassign
      plan.savings = calculatePlanSavings(plans[0], plan);
    });
  });

  return plansMatrix;
}

export const planWithInsuranceDiscount = (
  plan: PlanData,
  insuranceEligibility: InsuranceEligibility
) => {
  const { offerPrice, billingPrice, ...priceArrRest } = plan;
  const currentOffer = offerPrice?.amount || 0;
  let newAmount: number;
  let newAmountWeekly: number;
  if (insuranceEligibility.isEligible) {
    newAmount = calculateNetPrice(
      currentOffer * 4,
      insuranceEligibility.copayCents || 0,
      insuranceEligibility.coinsurancePercent || 0,
      insuranceEligibility.deductible || 0
    );
    newAmountWeekly = newAmount / 4;
  } else {
    const { ineligiblePromo = 0, ineligiblePromoWeeks = 0 } = insuranceEligibility;
    if (!ineligiblePromo || !ineligiblePromoWeeks) {
      newAmountWeekly = currentOffer;
    } else {
      newAmountWeekly = currentOffer - ineligiblePromo / ineligiblePromoWeeks;
    }
    newAmount = billingPrice.amount;
  }
  return {
    ...priceArrRest,
    offerPrice: {
      ...offerPrice!,
      originalPrice: currentOffer,
      amount: newAmountWeekly,
    },
    billingPrice: {
      ...billingPrice!,
      amount: newAmount * (billingPrice?.cycleValue || 1),
    },
  };
};

export const createPlanMatrixWithInsuranceDiscount = (
  planMatrix: PlanData[][],
  insuranceEligibility: InsuranceEligibility
): PlanData[][] =>
  planMatrix.map((priceArr) =>
    priceArr
      .filter(({ discountPercent }) => !discountPercent)
      .map((plan) => planWithInsuranceDiscount(plan, insuranceEligibility))
  );

export const planWithOutOfPocketPromo = (plan: PlanData, promoToDisplay?: PromoToDisplay) => {
  const { offerPrice, billingPrice, ...priceArrRest } = plan;
  const currentOffer = offerPrice?.amount || 0;
  if (!promoToDisplay) {
    return { ...plan, oopPromoExperiment: true };
  }
  const { promoAmount, promoWeeks } = promoToDisplay;
  const newAmountWeekly = currentOffer - promoAmount / promoWeeks;
  const newAmount = billingPrice.amount;
  return {
    ...priceArrRest,
    offerPrice: {
      ...offerPrice!,
      originalPrice: currentOffer,
      amount: newAmountWeekly,
    },
    billingPrice: {
      ...billingPrice!,
      amount: newAmount * (billingPrice?.cycleValue || 1),
    },
    oopPromoExperiment: true,
  };
};

export const createPlanMatrixForOutOfPocketPromo = (
  planMatrix: PlanData[][],
  promoToDisplay?: PromoToDisplay,
  additionalBillingCycles?: boolean
): PlanData[][] =>
  planMatrix.map((priceArr) =>
    priceArr
      .filter(({ discountPercent }) => {
        // Return all plans if additionalBillingCycles
        if (additionalBillingCycles) {
          return true;
        }
        // Otherwise return monthly plans, identified by no discounts
        return !discountPercent;
      })
      .map((plan) => planWithOutOfPocketPromo(plan, promoToDisplay))
  );
