import { useState, useEffect } from 'react';

export enum screenSizes {
  EXTRA_SMALL = 'xs',
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  DEFAULT = 'lg',
}

export const isResponsive = (screenSize: screenSizes) =>
  [screenSizes.EXTRA_SMALL, screenSizes.SMALL].includes(screenSize);

const between = (value: number, interval: [number, number]): boolean =>
  value >= interval[0] && value <= interval[1];

const sizeByWidth = (width: number): screenSizes =>
  new Map([
    [between(width, [0, 375]), screenSizes.EXTRA_SMALL],
    [between(width, [376, 767]), screenSizes.SMALL],
    [between(width, [768, 1125]), screenSizes.MEDIUM],
    [width > 1126, screenSizes.LARGE],
  ]).get(true) || screenSizes.DEFAULT;

export default function useScreenSize(): screenSizes {
  const [screenSize, setScreenSize] = useState<screenSizes>(sizeByWidth(window.innerWidth));

  useEffect(() => {
    const startResize = () => setScreenSize(sizeByWidth(window.innerWidth));

    window.addEventListener('resize', startResize);

    return () => {
      window.removeEventListener('resize', startResize);
    };
  }, []);

  return screenSize;
}
