import { useQuery } from 'react-query';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { getCommunityUserDataQueryKey } from '../../utils/queryKeys';

interface QueryProps {
  clientUserID: string | number;
  disabled?: boolean;
}

interface CommunityUserDataResponse {
  userID: string;
  displayName: string;
  onboardedTeenspaceAt: string | undefined;
}

const fetchCommunityUserData =
  ({ clientUserID }: QueryProps) =>
  async () => {
    const {
      data: { data },
    } = await apiWrapper.get(
      `${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/community-user-data`
    );
    const communityUserDataResponse: CommunityUserDataResponse = {
      userID: data.userID,
      displayName: data.displayName,
      onboardedTeenspaceAt: data.onboardedTeenspaceAt,
    };
    return communityUserDataResponse;
  };

const useQueryCommunityUserData = ({ clientUserID, disabled }: QueryProps) =>
  useQuery<CommunityUserDataResponse, Error>({
    queryKey: getCommunityUserDataQueryKey({ clientUserID }),
    queryFn: fetchCommunityUserData({ clientUserID }),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(clientUserID) && !disabled,
  });

export default useQueryCommunityUserData;
