import { Auth } from '@aws-amplify/auth';

type InitiateForgotPasswordParameters = {
  email: string;
};

const initiateForgotPassword = async ({
  email,
}: InitiateForgotPasswordParameters): Promise<void> => {
  await Auth.forgotPassword(email);
};

export default initiateForgotPassword;
