import { useTranslation } from '@talkspace/i18n';
import { RouteComponentProps, withRouter } from '@/core/routerLib';

import OnboardingPage from './OnboardingPage';
import ParentalConsent from '../assets/ParentalConsent';

type propsType = RouteComponentProps<{ roomID: number }> & {
  totalSteps: number;
  currentStep: number;
};
const OnboardingPageTeenParentalConsent = (props: propsType) => {
  const {
    match: {
      params: { roomID },
    },
    history,
    totalSteps,
    currentStep,
  } = props;
  const { t: tTeenOnboarding } = useTranslation('teenOnboarding');
  return (
    <OnboardingPage
      totalSteps={totalSteps}
      currentStep={currentStep}
      illustration={<ParentalConsent />}
      title={tTeenOnboarding('letsCompleteProfile.title', "Let's complete your profile")}
      description={tTeenOnboarding(
        'letsCompleteProfile.description',
        "We'll start by guiding you through the consent process. Then we will ask a few more questions so you'll be ready for therapy"
      )}
      ctaText={tTeenOnboarding('letsCompleteProfile.ctaText', 'Start now')}
      onPress={() => {
        history.push(`/room/${roomID}/onboarding/parental-consent`);
      }}
    />
  );
};

export default withRouter(OnboardingPageTeenParentalConsent);
