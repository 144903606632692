import { SpacingView } from '@talkspace/react-toolkit';

import CardLoader from './CardLoader';

const YourSupportTeamLoader = () => (
  <SpacingView itemSpacing="space200">
    <CardLoader hasTag />
    <CardLoader />
  </SpacingView>
);

export default YourSupportTeamLoader;
