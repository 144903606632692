import { SkipNavigation } from '@talkspace/react-toolkit';
import { History } from 'history';
import { match } from 'react-router';
import { ID_CHAT_PROVIDER_DETAILS_BUTTON } from '../utils/IDConstants';

const useA11y = (history: History, routeMatch: match<{ roomID: string }>) => {
  const cameFromRoomDetails =
    history.location.state && history.location.state.from
      ? history.location.state.from === 'room-details'
      : false;

  const returnToRoomDetails = () => {
    history.push({
      pathname: `/room/${routeMatch.params.roomID}/room-details`,
      state: {
        from: null,
      },
    });
  };

  const skipNavs: SkipNavigation[] = cameFromRoomDetails
    ? [
        {
          buttonText: 'Return to room details',
          onPress: returnToRoomDetails,
        },
        {
          buttonText: 'Return to chat room',
          returnID: ID_CHAT_PROVIDER_DETAILS_BUTTON,
        },
      ]
    : [
        {
          buttonText: 'Return to chat room',
          returnID: ID_CHAT_PROVIDER_DETAILS_BUTTON,
        },
      ];

  return { skipNavs };
};

export default useA11y;
