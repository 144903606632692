import {
  EmotionStyle,
  MessageSmall,
  TextDS,
  TouchableView,
  spacing,
} from '@talkspace/react-toolkit';
import { useHistory } from '@/core/routerLib';
import { useCommunityState } from '../hooks/CommunityContext';

const { space050 } = spacing;

interface Props {
  post: Amity.Post;
  commentInputRef?: React.RefObject<HTMLInputElement>;
  style?: EmotionStyle;
}
const CommentsLink = ({ post, commentInputRef, style }: Props) => {
  const history = useHistory();
  const { userID } = useCommunityState();
  const {
    postId: postID,
    creator: { userId: creatorUserID },
  } = post;
  const isOwnPost = creatorUserID === userID;

  const text = isOwnPost ? 'Comment' : 'Show support';
  return (
    <TouchableView
      row
      align="center"
      dataQa={`openPostDetails-${postID}`}
      onPress={() => {
        const postDetailsPath = `/home/teenspace/post/${postID}`;
        if (history.location.pathname === postDetailsPath) {
          commentInputRef?.current?.focus();
        } else {
          history.push(postDetailsPath);
        }
      }}
      style={style}
    >
      <MessageSmall size="major" colorType="subtlest" />
      <TextDS variant="headingMd" colorRole="textSubtlest" style={{ marginLeft: space050 }}>
        {text}
      </TextDS>
    </TouchableView>
  );
};

export default CommentsLink;
