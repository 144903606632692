import { useMutation } from 'react-query';

import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

export interface RevokeOTPVariables {
  otpToken: string;
}

const revokeOTP = async ({ otpToken }: RevokeOTPVariables): Promise<void> => {
  await apiWrapper.post(`${apiHelper().authAPIEndpoint}/v3/2fa/revoke`, {
    otpToken,
  });
};

const useMutationRevokeOTP = () => useMutation<void, Error, RevokeOTPVariables>(revokeOTP);

export default useMutationRevokeOTP;
