import { FunctionComponent, useRef, useState } from 'react';
import { Breadcrumbs, Screen } from '@talkspace/react-toolkit';
import { getVWOWidgetsHeight } from 'ts-analytics/VWO/helpers';
import { RouteComponentProps } from '@/core/routerLib';
import PaymentDetailsContainer from '../containers/PaymentDetailsContainer';
import { RoomOfferRouteParams } from '../types';
import { useRoomOffersState } from '../hooks/roomOfferContext';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import StickyHeader from '../components/StickyHeader';

const PaymentBreadcrumbs = () => {
  const activeIndex = 2;
  const items = [{ label: 'Choose plan' }, { label: 'Review details' }, { label: 'Checkout' }];
  return <Breadcrumbs activeIndex={activeIndex} items={items} />;
};

const PaymentDetailsScreen: FunctionComponent<RouteComponentProps<RoomOfferRouteParams>> = () => {
  const { isChangePlan, showCloseButton } = useRoomOffersState();

  const [stickHeader, setStickHeader] = useState<boolean>(false);

  const closeModal = useCloseModal();

  const handleOnScrollChange = (scrollTop: number) => {
    setStickHeader(scrollTop > 50);
  };

  const VWOWidgetsHeight = getVWOWidgetsHeight();

  const breadcrumbsRef = useRef<HTMLDivElement>(null);
  return (
    <Screen
      safeAreaVariant="setHeightAndAddTop"
      additionalHeaderTopPadding={VWOWidgetsHeight}
      onCloseButtonClick={closeModal}
      onScrollChange={handleOnScrollChange}
      showBackButton
      showCloseButton={showCloseButton}
      shouldNotFocus
      title={isChangePlan ? 'Change plan' : undefined}
    >
      {!isChangePlan && (
        <StickyHeader ref={breadcrumbsRef} stickHeader={stickHeader}>
          <PaymentBreadcrumbs />
        </StickyHeader>
      )}
      <PaymentDetailsContainer topRef={breadcrumbsRef} />
    </Screen>
  );
};

export default PaymentDetailsScreen;
