import { WizardScheme } from 'stepWizard';
import moment from 'moment';

import WelcomeScreen from './components/WelcomeScreen';

const matchingIntakeScheme: WizardScheme = {
  wizardVersion: 1.1,
  wizardType: 'matchingIntake',
  wizardModalTitle: 'matching intake',
  welcomeScreen: (handleStartButtonClick, completedAtDate, isCompleted) => (
    <WelcomeScreen
      handleStartButton={handleStartButtonClick}
      completedAtDate={completedAtDate}
      isCompleted={isCompleted}
    />
  ),
  steps: [
    {
      title: 'What led you to seek help today?',
      inputType: 'searchableMultiSelect',
      inputState: 'seekHelpReason',
      inputOptions: 'seekHelpOptions',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      name: 'Seek Help Reason',
    },
    {
      title: 'Have you been to a therapist before?',
      inputType: 'select',
      inputState: 'alreadySeenTherapist',
      inputOptions: 'alreadySeenTherapistOptions',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      name: 'Already seen therapist',
      backOption: 'back',
    },
    {
      title: 'What are you hoping to get out of therapy?',
      inputType: 'multilineText',
      inputTypePlaceholder: 'Try to describe what your goals are in your own words',
      inputState: 'treatmentGoal',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      name: 'Get out of therapy hopes',
      backOption: 'back',
    },
    {
      inputType: 'stateCountrySelector',
      inputState: 'countryState',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'autoAdvance',
      },
      name: 'State country',
      backOption: 'back',
    },
    {
      title: "What's your date of birth?",
      inputType: 'date',
      inputState: 'dateOfBirth',
      nextButton: {
        displayText: 'Next',
        actionDispatch: (value, wizardContext) => {
          const age = moment().diff(value, 'years');
          if (age < 13) {
            return '/matching-intake/under13';
          }
          return 'next';
        },
        whenVisible: 'onSelect',
      },
      name: 'Date of birth',
      backOption: 'back',
      validationRequired: true,
    },
    {
      title: 'I am looking for a provider that will: ',
      inputType: 'select',
      inputState: 'therapistAbilityPreference',
      inputOptions: 'therapistAbilityPreferences',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      secondaryButton: {
        displayText: 'Not sure',
        actionDispatch: 'next',
        defaultValue: null,
      },
      name: 'Therapist ability preference',
      backOption: 'back',
    },
    {
      title: 'Do you have a preference in the gender of your provider?',
      inputType: 'select',
      inputState: 'providerGender',
      inputOptions: 'providerGenderOptions',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      secondaryButton: {
        displayText: 'No preference',
        actionDispatch: 'next',
        defaultValue: null,
      },
      name: 'Therapist gender',
      backOption: 'back',
    },
    {
      title: 'What gender do you identify with?',
      inputType: 'select',
      inputState: 'clientGender',
      inputOptions: 'clientGenderOptions',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      name: 'Client gender',
      backOption: 'back',
    },
    {
      title: "What's your relationship status?",
      inputType: 'select',
      inputState: 'relationshipStatus',
      inputOptions: 'relationshipOptions',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      secondaryButton: {
        displayText: 'Skip',
        actionDispatch: 'next',
        defaultValue: null,
      },
      name: 'Relationship status',
      backOption: 'back',
    },
    {
      title: 'One last thing, how did you hear about us?',
      inputType: 'select',
      inputState: 'hearAboutUs',
      inputOptions: 'hearAboutUsOptions',
      nextButton: {
        displayText: 'Submit',
        actionDispatch: '/matching-intake/submit',
        whenVisible: 'onSelect',
      },
      name: 'Hear about us',
      backOption: 'back',
    },
  ],
};

export default matchingIntakeScheme;
