import { VoidFunctionComponent } from 'react';
import { View, Large, Button } from '@talkspace/react-toolkit';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { useParams } from '@/core/routerLib';
import styled from '../../core/styled';
import { SuperbillsIconBig } from '../../components/icons';
import SuperbillsWrapper from '../components/SuperbillsWrapper';

const MarginedWrapper = styled(View)({
  marginBottom: 3,
});
export const Description = styled(View)({
  padding: 17,
  textAlign: 'center',
  marginBottom: 11,
});

const NoNameScreen: VoidFunctionComponent = () => {
  const closeModal = useCloseModal();
  const { roomID } = useParams<{ roomID: string }>();

  const handleEdit = () => {
    closeModal({
      navigateTo: 'personalInformation',
      metadata: { roomID: +roomID },
      action: `personal-details`,
    });
  };

  return (
    <SuperbillsWrapper>
      <MarginedWrapper>
        <SuperbillsIconBig />
      </MarginedWrapper>
      <Description>
        <Large variant="largeDarkGrey">
          To generate your superbill, we’ll need to collect your personal details. Please make sure
          to enter your full name exactly as it appears on your insurance card.
        </Large>
      </Description>
      <Button onPress={handleEdit} dataQa="continue">
        Continue
      </Button>
    </SuperbillsWrapper>
  );
};

export default NoNameScreen;
