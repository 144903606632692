import moment from 'moment';

import { ServiceType, Subscription, InsuranceStatus } from 'ts-frontend/types';
import useQuerySubscriptions from 'ts-frontend/hooks/useQuerySubscriptions';
import { RoomStatus } from 'ts-frontend/entities/Room';
import { ROOM_TYPE_TO_SERVICE_TYPE } from 'chat/constants';
import localStorage from '@/core/storage/localStorage';
import sessionStorage from '@/core/storage/sessionStorage';
import { getUserData } from '@/auth/helpers/token';

export const ELIGIBILITY_WARNING_DISMISSED_STORAGE_KEY = 'eligibilityWarningDismissed';

type EligibilityWarningDismissalData = { [roomID: number]: { dismissedAt: string } };

const getStorage = (isWithinGracePeriod: boolean): Storage => {
  if (isWithinGracePeriod) {
    return sessionStorage;
  }
  return localStorage;
};

const getDismissalData = (isWithinGracePeriod: boolean): EligibilityWarningDismissalData | {} =>
  JSON.parse(
    getStorage(isWithinGracePeriod).getItem(ELIGIBILITY_WARNING_DISMISSED_STORAGE_KEY) || `{}`
  );

const getIsWithinGracePeriod = (insuranceStatus: InsuranceStatus | undefined): boolean => {
  const { lastEligibilityCheckDate, gracePeriod } = insuranceStatus || {};
  return moment(lastEligibilityCheckDate).add(gracePeriod, 'hours').isAfter(moment());
};

const getIsWithinPostGracePeriod = (insuranceStatus: InsuranceStatus): boolean => {
  const { lastEligibilityCheckDate, postGracePeriod } = insuranceStatus;
  return moment(lastEligibilityCheckDate).add(postGracePeriod, 'hours').isAfter(moment());
};

const getIsInsuranceIneligible = (insuranceStatus: InsuranceStatus | undefined): boolean =>
  insuranceStatus ? insuranceStatus.eligibilityStatus === 'not eligible' : false;

const getIsClosedUpdatedCoverage = (subscription: Subscription | undefined): boolean => {
  const { status, isClosedIneligible } = subscription || {};
  return status === RoomStatus.CLOSED && !isClosedIneligible;
};

export const getShouldShowEligibilityWarning = (
  subscription: Subscription | undefined
): boolean => {
  const { insuranceStatus } = subscription || {};
  if (!insuranceStatus) return false;

  const { roomID } = insuranceStatus || {};
  const isWithinGracePeriod = getIsWithinGracePeriod(insuranceStatus);

  if (!getIsInsuranceIneligible(insuranceStatus) || getIsClosedUpdatedCoverage(subscription))
    return false;

  const dismissalData: EligibilityWarningDismissalData = getDismissalData(isWithinGracePeriod);
  if (!dismissalData[roomID]?.dismissedAt) return true;

  const { dismissedAt } = dismissalData[roomID];
  if (!dismissedAt) return true;

  // if within grace period, don't show (cleared on tab close or logout)
  if (isWithinGracePeriod) {
    return false;
  }
  // if out of grace period, don't show for 24 hours
  return moment(dismissedAt).add(24, 'hours').isBefore(moment());
};

interface EligibilityWarningProps {
  roomID: number;
}

type EligibilityWarningReturn = {
  shouldShowEligibilityWarning: boolean;
  isInsuranceIneligible: boolean;
  isClosedUpdatedCoverage: boolean;
  isLoading: boolean;
  insuranceStatus: InsuranceStatus | undefined;
  isWithinGracePeriod: boolean;
  flowParameters: { keepProvider: boolean; skipCapacityCheck: boolean } | undefined;
  serviceType: ServiceType;
  dismissEligibilityWarning: () => void;
  clearEligibilityWarningDismissed: () => void;
  refetchEligibilityWarning: () => void;
};

const useEligibilityWarning = ({ roomID }: EligibilityWarningProps): EligibilityWarningReturn => {
  const { id: clientUserID } = getUserData();
  const {
    data: subscriptions,
    isLoading: isLoadingSubscriptions,
    refetch: refetchSubscriptions,
  } = useQuerySubscriptions({
    clientUserID,
    includeInsuranceStatus: true,
  });

  const isLoading = isLoadingSubscriptions;
  const subscription = subscriptions?.find((s) => s.id === roomID);
  const { insuranceStatus, roomType } = subscription || {};

  const serviceType = roomType ? ROOM_TYPE_TO_SERVICE_TYPE[roomType] : null;

  const isInsuranceIneligible = getIsInsuranceIneligible(insuranceStatus);
  const isClosedUpdatedCoverage = getIsClosedUpdatedCoverage(subscription);

  if (isLoading || !insuranceStatus || !isInsuranceIneligible || isClosedUpdatedCoverage) {
    return {
      shouldShowEligibilityWarning: false,
      isInsuranceIneligible,
      isClosedUpdatedCoverage,
      isLoading,
      insuranceStatus,
      isWithinGracePeriod: false,
      flowParameters: undefined,
      serviceType,
      dismissEligibilityWarning: () => {},
      clearEligibilityWarningDismissed: () => {},
      refetchEligibilityWarning: () => {},
    };
  }

  const isWithinGracePeriod = getIsWithinGracePeriod(insuranceStatus);
  const isWithinPostGracePeriod = getIsWithinPostGracePeriod(insuranceStatus);

  return {
    shouldShowEligibilityWarning: getShouldShowEligibilityWarning(subscription),
    isInsuranceIneligible,
    isClosedUpdatedCoverage,
    isLoading,
    insuranceStatus,
    isWithinGracePeriod,
    flowParameters: {
      keepProvider: true,
      skipCapacityCheck: isWithinPostGracePeriod,
    },
    serviceType,
    dismissEligibilityWarning: () => {
      const dismissalData: EligibilityWarningDismissalData = getDismissalData(isWithinGracePeriod);
      dismissalData[roomID] = {
        dismissedAt: new Date().toString(),
      };
      getStorage(isWithinGracePeriod).setItem(
        ELIGIBILITY_WARNING_DISMISSED_STORAGE_KEY,
        JSON.stringify(dismissalData)
      );
    },
    clearEligibilityWarningDismissed: () => {
      getStorage(isWithinGracePeriod).removeItem(ELIGIBILITY_WARNING_DISMISSED_STORAGE_KEY);
    },

    refetchEligibilityWarning: refetchSubscriptions,
  };
};

export default useEligibilityWarning;
