export interface MessagePreviewAPI {
  roomId: number;
  messageId: number;
  previewText: string;
  message: string;
  messageType: number;
  createdAt: string;
  userId: number;
  userType: number;
}

export interface MessagePreview {
  roomID: number;
  messageID: number;
  message: string;
  messageType: number;
  createdAt: Date;
  userID: number;
  userType: number;
}

// eslint-disable-next-line import/prefer-default-export
export class EMessagePreview implements MessagePreview {
  roomID: number;

  messageID: number;

  message: string;

  messageType: number;

  createdAt: Date;

  userID: number;

  userType: number;

  constructor(message: MessagePreviewAPI) {
    this.roomID = message.roomId;
    this.messageID = message.messageId;
    this.message = message.message || message.previewText;
    this.messageType = message.messageType;
    this.createdAt = new Date(message.createdAt);
    this.userID = message.userId;
    this.userType = message.userType;
  }

  isEqual(other: EMessagePreview): boolean {
    return (
      this.messageID === other.messageID &&
      this.createdAt.getTime() === other.createdAt.getTime() &&
      this.userType === other.userType
    );
  }
}
