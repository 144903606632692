import { G, Defs, Path, Rect, Svg } from 'svgs';

import { useEmotionTheme, useUniqueID } from '@talkspace/react-toolkit';

const standardPath =
  'M16.9494 2.67734C16.265 1.99297 15.1588 1.99297 14.4744 2.67734L13.5338 3.61484L16.5932 6.67422L17.5338 5.73359C18.2182 5.04922 18.2182 3.94297 17.5338 3.25859L16.9494 2.67734ZM7.59941 9.55234C7.40879 9.74297 7.26191 9.97734 7.17754 10.2367L6.25254 13.0117C6.16191 13.2805 6.23379 13.5773 6.43379 13.7805C6.63379 13.9836 6.93066 14.0523 7.20254 13.9617L9.97754 13.0367C10.2338 12.9523 10.4682 12.8055 10.6619 12.6148L15.89 7.38359L12.8275 4.32109L7.59941 9.55234ZM5.21191 3.99922C3.55566 3.99922 2.21191 5.34297 2.21191 6.99922V14.9992C2.21191 16.6555 3.55566 17.9992 5.21191 17.9992H13.2119C14.8682 17.9992 16.2119 16.6555 16.2119 14.9992V11.9992C16.2119 11.4461 15.765 10.9992 15.2119 10.9992C14.6588 10.9992 14.2119 11.4461 14.2119 11.9992V14.9992C14.2119 15.5523 13.765 15.9992 13.2119 15.9992H5.21191C4.65879 15.9992 4.21191 15.5523 4.21191 14.9992V6.99922C4.21191 6.44609 4.65879 5.99922 5.21191 5.99922H8.21191C8.76504 5.99922 9.21191 5.55234 9.21191 4.99922C9.21191 4.44609 8.76504 3.99922 8.21191 3.99922H5.21191Z';

const PenToSquareFilled = () => {
  const clipPathID = useUniqueID('ID');
  const { colorRoles } = useEmotionTheme();
  const title = 'Pen to square filled';
  return (
    <Svg title={title} aria-label={title} width="21" height="20" viewBox="0 0 21 20" fill="none">
      <G clip-path="url(#clip0_3281_19438)">
        <Path d={standardPath} fill={colorRoles.icons.iconSubtlestDefault} />
      </G>
      <Defs>
        <clipPath id={`clip${clipPathID}`}>
          <Rect width="20" height="20" fill="white" transform="translate(0.5)" />
        </clipPath>
      </Defs>
    </Svg>
  );
};

export default PenToSquareFilled;
