import { ValueType } from 'react-select';
import { OptionType } from '@talkspace/react-toolkit';
import { BaseWizardState, baseWizardInitialState } from 'stepWizard';

export interface State extends BaseWizardState {
  roomID: number;
  clientUserID: number;
  employmentStatus: string;
  employmentStatusOptions: ValueType<OptionType, true>;
  spouseEmploymentStatus: string;
  spouseEmploymentStatusOptions: ValueType<OptionType, true>;
  householdEarnings: string;
  householdEarningsOptions: ValueType<OptionType, true>;
  isServingMilitary: boolean;
  isVeteran: boolean;
  benefitsReceived: string[];
  benefitsReceivedOptions: ValueType<OptionType, true>;
  isLivingInRuralArea: boolean;
  eligibilityShown: boolean;
  financialAidApplied: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const getInitialState = (
  clientUserID: number,
  roomID: number,
  source: string,
  contextID: string
): State => {
  return {
    ...baseWizardInitialState(source, contextID),
    roomID,
    clientUserID,
    employmentStatus: '',
    employmentStatusOptions: [],
    spouseEmploymentStatus: '',
    spouseEmploymentStatusOptions: [],
    householdEarnings: '',
    householdEarningsOptions: [],
    isServingMilitary: false,
    isVeteran: false,
    benefitsReceived: [],
    benefitsReceivedOptions: [],
    isLivingInRuralArea: false,
    eligibilityShown: false,
    financialAidApplied: false,
  };
};
