import { useReducer, useCallback, useRef } from 'react';
import { wizardActionReducer, wizardActionInitialState, WizardActionState } from 'stepWizard';
import SurveyWizardApiHelper from '../utils/surveyWizardApiHelper';
import { SurveySource } from '../types';

interface SurveyState extends WizardActionState {
  isCompleted: boolean;
}

interface Actions {
  completeSurveyAction: (isCompleted: boolean, userRoomSurveyID: number) => void;
}

export default function useSurveyWizard(source: SurveySource): [SurveyState, Actions] {
  const additionalStateProps: Partial<SurveyState> = {
    isCompleted: false,
  };

  const [state, dispatch] = useReducer(
    wizardActionReducer,
    wizardActionInitialState(additionalStateProps)
  );
  const apiRef = useRef(new SurveyWizardApiHelper(source));

  const completeSurveyAction: Actions['completeSurveyAction'] = (isCompleted, userRoomSurveyID) => {
    dispatch({ type: 'requestAction' });

    apiRef.current
      .putCompleteSurvey(isCompleted, userRoomSurveyID)
      .then(() => {
        dispatch({ type: 'receiveAction', payload: { isCompleted: true } });
      })
      .catch((error) => {
        dispatch({ type: 'setError', error: (error && error.message) || '' });
      });
  };

  return [
    state as SurveyState,
    {
      completeSurveyAction: useCallback(completeSurveyAction, []),
    },
  ];
}
