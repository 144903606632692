import { useState, useEffect } from 'react';
import { InformedConsentAPIResponse, InformedConsentV2StatusAPI } from 'ts-frontend/types';
import { getUserData } from '../auth/helpers/token';
import ApiHelper from '../utils/ApiHelper';
import { getAgreedDate } from '../utils/informedConsent/informedConsentHelpers';

let api: ApiHelper;

const useInformedConsentV2 = ({
  messageSentBy,
  messageID,
  roomID,
}: {
  messageSentBy?: number;
  messageID?: number;
  roomID?: number;
}) => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [informedConsent, setInformedConsent] = useState<InformedConsentAPIResponse | undefined>(
    undefined
  );
  const [informedConsentV2, setInformedConsentV2] = useState<
    InformedConsentV2StatusAPI | undefined
  >(undefined);
  const { id: userID } = getUserData();

  useEffect(() => {
    api = new ApiHelper();

    return () => {
      api.cancelAll();
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);

    const fetchV1Data = async () => {
      if (!roomID) return undefined;
      try {
        const informedConsentV1Response = await api.getInformedConsent(
          roomID,
          messageSentBy || 0,
          messageID
        );
        return informedConsentV1Response;
      } catch (err) {
        return undefined;
      }
    };

    const fetchV2Data = async () => {
      try {
        const informedConsentV2Response = await api.getInformedConsentV2Status(userID);
        return informedConsentV2Response;
      } catch (err) {
        setError(err.message);
        return undefined;
      }
    };

    Promise.all([fetchV1Data(), fetchV2Data()])
      .then(([informedConsentV1Response, informedConsentV2Response]) => {
        setIsLoading(false);
        setInformedConsent(informedConsentV1Response);
        setInformedConsentV2(informedConsentV2Response);
      })
      .catch(api.dismissIfCancelled);
  }, [messageID, messageSentBy, roomID, userID]);

  const handleAgree = async () => {
    setIsLoading(true);
    setError('');

    try {
      await api.agreeToInformedConsentV2(userID);
      const fetchedInformedConsentV2 = await api.getInformedConsentV2Status(userID);
      setInformedConsentV2(fetchedInformedConsentV2);
    } catch (err) {
      setError(err.message);
      throw new Error('Informed consent v2 was not sent successfully.');
    } finally {
      setIsLoading(false);
    }
  };
  const { shouldSignConsent: shouldSignConsentV2 } = informedConsentV2 || {};

  const agreedDate = getAgreedDate(informedConsent, informedConsentV2);
  const hasConsented = !shouldSignConsentV2;

  return {
    informedConsent,
    informedConsentV2,
    agreedDate,
    hasConsented,
    isLoading,
    handleAgree,
    error,
  };
};

export default useInformedConsentV2;
