import { PaymentDetails } from 'ts-frontend/types';
import { EmailVerificationStatus } from '../../entities/Me';

export interface ActionAuth {
  token: string;
  expiration: Date;
}

export type ModalType =
  | 'changeNickname'
  | 'passwordRequired'
  | 'changeEmail'
  | 'changePassword'
  | 'updatePaymentDetails'
  | 'emailVerification'
  | 'changePhoneNumber2FA'
  | 'changePhoneNumber'
  | 'verifyPhoneNumber';

export interface State {
  currentPassword: string | null;
  disable2FA: boolean;
  nickname: string;
  email: string;
  pendingEmail: string;
  phoneNumber: string;
  phoneNumberCountryCode: string;
  paymentDetails?: PaymentDetails;
  actionAuth: ActionAuth;
  isModalOpen: boolean;
  openModalType?: ModalType;
  successModalText: string;
  successModalTitle?: string;
  isLoading: boolean;
  isLinkLoaded: boolean;
  isPhoneNumberLoading: boolean;
  emailVerificationStatus?: EmailVerificationStatus;
  error: string;
  verificationCodeError: boolean;
  otpToken: string;
  pendingPhoneNumber: string;
  pendingCountryCode: string;
  backModal: ModalType;
  twoFATokenExpiredError: string;
  isVerifyCodeLoading: boolean;
}

export type Action =
  | { type: 'openModal'; payload: Pick<State, 'openModalType'> }
  | { type: 'closeModal' }
  | { type: 'setCurrentPassword'; payload: Pick<State, 'currentPassword'> }
  | { type: 'setSuccessModalText'; payload: Pick<State, 'successModalText' | 'successModalTitle'> }
  | { type: 'requestUpdateNickname' }
  | { type: 'receiveUpdateNickname'; payload: Pick<State, 'nickname'> }
  | { type: 'requestVerifyPassword' }
  | { type: 'receiveVerifyPassword'; payload: Pick<State, 'actionAuth'> }
  | { type: 'requestUpdateEmail' }
  | {
      type: 'receiveUpdateEmail';
      payload: Pick<State, 'email' | 'pendingEmail' | 'emailVerificationStatus'>;
    }
  | { type: 'requestUpdatePassword' }
  | { type: 'receiveUpdatePassword' }
  | { type: 'requestUpdatePhoneNumber' }
  | {
      type: 'receiveUpdatePhoneNumber';
      payload: Pick<State, 'phoneNumber' | 'phoneNumberCountryCode'>;
    }
  | { type: 'requestGetClientPhoneNumber' }
  | {
      type: 'receiveGetClientPhoneNumber';
      payload: Pick<State, 'phoneNumber' | 'phoneNumberCountryCode' | 'disable2FA'>;
    }
  | { type: 'requestGetPaymentDetails' }
  | {
      type: 'receiveGetPaymentDetails';
      payload: Pick<State, 'nickname' | 'email' | 'paymentDetails'>;
    }
  | { type: 'requestUpdatePaymentDetails' }
  | {
      type: 'receiveUpdatePaymentDetails';
      payload: Pick<State, 'paymentDetails'>;
    }
  | { type: 'requestGetSetupIntent' }
  | {
      type: 'receiveGetSetupIntent';
    }
  | { type: 'requestSendEmailVerification' }
  | {
      type: 'receiveSendEmailVerification';
    }
  | { type: 'requestUpdate2FA' }
  | { type: 'receiveUpdate2FA'; payload: Pick<State, 'disable2FA'> }
  | { type: 'setVerificationCodeError'; payload: Pick<State, 'verificationCodeError'> }
  | { type: 'setError'; error?: string }
  | { type: 'setOTPToken'; payload: Pick<State, 'otpToken'> }
  | { type: 'setBackModal'; payload: Pick<State, 'backModal'> }
  | { type: 'clearOTPToken'; payload: Pick<State, 'otpToken'> }
  | { type: 'clearError' }
  | { type: 'set2faTokenExpiredError'; payload: Pick<State, 'twoFATokenExpiredError'> }
  | {
      type: 'setPendingPhoneNumber';
      payload: Pick<State, 'pendingPhoneNumber' | 'pendingCountryCode'>;
    };

export const initialState: State = {
  currentPassword: null,
  disable2FA: false,
  isVerifyCodeLoading: false,
  phoneNumber: '',
  phoneNumberCountryCode: '',
  pendingPhoneNumber: '',
  pendingCountryCode: '',
  nickname: '',
  email: '',
  pendingEmail: '',
  paymentDetails: undefined,
  actionAuth: {} as unknown as ActionAuth,
  isModalOpen: false,
  successModalText: '',
  emailVerificationStatus: undefined,
  isLoading: false,
  isLinkLoaded: false,
  isPhoneNumberLoading: false,
  error: '',
  verificationCodeError: false,
  otpToken: '',
  backModal: 'passwordRequired',
  twoFATokenExpiredError: '',
};

export const accountDetailsReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'openModal':
      return {
        ...state,
        isModalOpen: true,
        ...action.payload,
      };
    case 'closeModal':
      return {
        ...state,
        isModalOpen: false,
        openModalType: undefined,
        successModalText: '',
        successModalTitle: '',
        error: '',
      };
    case 'setSuccessModalText':
    case 'setCurrentPassword':
    case 'setPendingPhoneNumber':
    case 'setBackModal':
      return {
        ...state,
        ...action.payload,
      };
    case 'requestGetPaymentDetails':
    case 'requestUpdatePaymentDetails':
    case 'requestUpdateNickname':
    case 'requestUpdateEmail':
    case 'requestUpdatePassword':
    case 'requestUpdatePhoneNumber':
    case 'requestSendEmailVerification':
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case 'requestUpdate2FA':
      return {
        ...state,
        isVerifyCodeLoading: true,
        error: '',
      };
    case 'requestGetClientPhoneNumber':
      return {
        ...state,
        isPhoneNumberLoading: true,
        error: '',
      };
    case 'receiveGetPaymentDetails':
    case 'receiveUpdatePaymentDetails':
    case 'receiveUpdateNickname':
    case 'receiveVerifyPassword':
    case 'receiveUpdate2FA':
    case 'receiveUpdatePhoneNumber':
    case 'receiveUpdateEmail':
      return {
        ...state,
        isLoading: false,
        isVerifyCodeLoading: false,
        error: '',
        ...action.payload,
      };
    case 'requestGetSetupIntent':
      return {
        ...state,
        isLinkLoaded: false,
      };
    case 'receiveGetSetupIntent':
      return {
        ...state,
        isLinkLoaded: true,
      };
    case 'receiveGetClientPhoneNumber':
      return {
        ...state,
        isPhoneNumberLoading: false,
        error: '',
        ...action.payload,
      };
    case 'receiveUpdatePassword':
    case 'receiveSendEmailVerification':
      return {
        ...state,
        isLoading: false,
        error: '',
      };
    case 'setError':
      return {
        ...state,
        error: action.error || 'Something went wrong. Please try again later',
        isLoading: false,
        isVerifyCodeLoading: false,
      };
    case 'set2faTokenExpiredError':
    case 'setVerificationCodeError':
    case 'clearOTPToken':
    case 'setOTPToken':
      return {
        ...state,
        ...action.payload,
        isVerifyCodeLoading: false,
      };
    case 'clearError':
      return {
        ...state,
        error: '',
      };
    default:
      return state;
  }
};
