import { Moment } from 'moment';

import { ShieldOutlineIcon, CalendarOutlineIcon } from '@talkspace/react-toolkit';

import EligibilityWarningInfoItem from './EligibilityWarningInfoItem';

interface Props {
  updateDeadline: Moment;
}
const EligibilityWarningInfo = ({ updateDeadline }: Props) => (
  <>
    <EligibilityWarningInfoItem
      icon={<ShieldOutlineIcon />}
      title="Update coverage or payment"
      text="To continue care, you’ll need to update your coverage or payment method."
    />
    <EligibilityWarningInfoItem
      icon={<CalendarOutlineIcon />}
      title="Protect your upcoming sessions"
      text={`To prevent unexpected fees, sessions before ${updateDeadline.format(
        'MMM DD'
      )} will be canceled if your account isn’t updated 48 hours before the session starts.`}
    />
  </>
);

export default EligibilityWarningInfo;
