import { SelectModality, useInRoomSchedulingActions } from 'inRoomScheduling';
import { useEffect } from 'react';
import { useWizardActions, useWizardState } from 'stepWizard';
import { SessionModality } from 'ts-frontend/types';
import { EmotionThemeProvider, useWindowWidthState, View } from '@talkspace/react-toolkit';
import { useMainActions, useMainState } from '@/hooks/mainContext';
import { State } from '@/switchWizard/reducers/switchWizardState';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { extractRoomEntitiesFromState } from '@/utils/extractRoomEntitiesFromState';

const SelectModalityContainer = () => {
  const mainState = useMainState();
  const wizardContext = useWizardState() as State;
  const { roomID, clientUserID } = wizardContext;
  const { setStepValue, setDefaultStepValue, setState } = useWizardActions();
  const { isMobile } = useWindowWidthState();
  const closeModal = useCloseModal();
  const { dispatchModalityType, dispatchSetRoomAndTherapistInfo, dispatchGetVideoCreditOffers } =
    useInRoomSchedulingActions();
  const { getAllRooms } = useMainActions();

  useEffect(() => {
    getAllRooms(clientUserID);
    setDefaultStepValue('video');
    setState({ isLiveModality: true });
    dispatchGetVideoCreditOffers(roomID);
    // eslint-disable-next-line
  }, []);

  // sets the room to be used for scheduler
  useEffect(() => {
    const { therapist, room } = extractRoomEntitiesFromState(mainState, roomID);
    dispatchSetRoomAndTherapistInfo(room, therapist);
  }, [dispatchSetRoomAndTherapistInfo, mainState, roomID]);

  const onSelectModality = (type: SessionModality) => {
    setState({ isLiveModality: type !== 'messaging' });
    dispatchModalityType(type);
    setStepValue(type);
  };

  return (
    <View flex={1} align="center" justify="center">
      <View align="stretch" style={isMobile ? { paddingLeft: 20, paddingRight: 20 } : undefined}>
        <EmotionThemeProvider version="1.0.0">
          <SelectModality
            onClose={closeModal}
            handleChooseModality={onSelectModality}
            onSkipBookNextSession={() => {}}
            roomID={roomID}
            roomType={mainState.roomsByID?.[roomID]?.roomType}
            shouldSkipModalitySelection={false}
            clientUserID={clientUserID}
            isOnboarding={false}
            isTherapist={false}
            isFromPostLVSCheckInWizard={false}
            isFromCheckInWizard={false}
            bookingIntroSession={false}
            shouldHideTitle
            shouldHideMessagingSessionTitle
            shouldHideButtonContainer
            shouldUseDsIcons
            flowVariant="switchWizard"
            shouldIgnoreActiveMessagingSession
          />
        </EmotionThemeProvider>
      </View>
    </View>
  );
};

export default SelectModalityContainer;
