import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const TeenspaceRightWing = ({
  width = 33,
  height = 180,
  color,
  ...props
}: {
  width?: number;
  height?: number;
  color?: string;
}) => {
  const { colorRoles } = useEmotionTheme();
  return (
    <Svg width={width} height={height} viewBox="0 0 33 180" {...props}>
      <Path
        d="M27 0H0V180H5C8.31371 180 11 177.314 11 174V92C11 88.6863 13.6863 86 17 86H27C30.3137 86 33 83.3137 33 80V6C33 2.68629 30.3137 0 27 0Z"
        fill={colorRoles.surfaces.decorativeLiftedLemon}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default TeenspaceRightWing;
