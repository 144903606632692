import { View, Spinner } from '@talkspace/react-toolkit';
import useIsClientDischargeV3OnForPlan from 'ts-frontend/hooks/useIsClientDischargeV3OnForPlan';
import { useHistory, useParams } from '@/core/routerLib';
import { useCloseModal } from '@/utils/ModalsContextProvider';

import useQueryTherapistInfo from '../../hooks/useQueryTherapistInfo';
import EligibilityWarning from '../components/EligibilityWarning';
import useEligibilityWarning from '../hooks/useEligibilityWarning';

const EligibilityWarningContainer = () => {
  const { roomID } = useParams<{ roomID: string }>();
  const history = useHistory();
  const { data: therapistInfo, isLoading: isLoadingTherapistInfo } = useQueryTherapistInfo({
    roomID,
  });
  const {
    insuranceStatus,
    isInsuranceIneligible,
    isClosedUpdatedCoverage,
    isWithinGracePeriod,
    isLoading: isLoadingEligibilityWarning,
    flowParameters,
    serviceType,
    dismissEligibilityWarning,
  } = useEligibilityWarning({
    roomID: Number(roomID),
  });
  const isLoading = isLoadingEligibilityWarning || isLoadingTherapistInfo;
  const closeModal = useCloseModal();

  const isClientDischargeV3OnForPlan = useIsClientDischargeV3OnForPlan(roomID);
  const isClosed = isClosedUpdatedCoverage && isClientDischargeV3OnForPlan === false;

  if (isLoading)
    return (
      <View align="center" justify="center" style={{ height: '100vh' }}>
        <Spinner />
      </View>
    );

  if (!insuranceStatus || !isInsuranceIneligible || isClosed) {
    closeModal();
    return null;
  }
  const { keepProvider, skipCapacityCheck } = flowParameters || {};
  return (
    <EligibilityWarning
      serviceType={serviceType}
      isWithinGracePeriod={isWithinGracePeriod}
      insuranceStatus={insuranceStatus}
      therapistInfo={therapistInfo!}
      onClose={() => {
        dismissEligibilityWarning();
        closeModal();
      }}
      onContinue={() => {
        const params = new URLSearchParams({
          updateCoverageRoomID: roomID,
          source: 'mbh-ineligibility',
          serviceType,
          keepProvider: String(keepProvider),
          skipCapacityCheck: String(skipCapacityCheck),
          previousTherapistId: therapistInfo!.therapistID
            ? therapistInfo!.therapistID.toString()
            : '',
        });
        history.push(`/what-to-expect?${params.toString()}`);
      }}
    />
  );
};

export default EligibilityWarningContainer;
