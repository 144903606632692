import { Route, Switch } from '../core/routerLib';
import { MeetYourProviderProvider } from './hooks/meetYourProviderContext';
import InformedConsentScreen from './screens/InformedConsentScreen';
import BookSessionScreen from './screens/BookSessionScreen';
import ProfileScreen from './screens/ProfileScreen';
import BookAsyncSessionScreen from './screens/BookAsyncSessionScreen';

const Routes = () => (
  <MeetYourProviderProvider>
    <Switch>
      <Route path="/meet-your-provider/profile" component={ProfileScreen} />
      <Route path="/meet-your-provider/informed-consent" component={InformedConsentScreen} />
      <Route path="/meet-your-provider/book-session" component={BookSessionScreen} />
      <Route path="/meet-your-provider/book-async-session" component={BookAsyncSessionScreen} />
    </Switch>
  </MeetYourProviderProvider>
);

export default Routes;
