import { RefObject } from 'react';
import { A11Y_COLORS, Checkbox, RHFInput, RHFSelect, View } from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useTranslation } from '@talkspace/i18n';

const textFieldContainerStyle = { width: '98%', marginLeft: 2 };
const textFieldLabelStyle = { color: A11Y_COLORS.permaWaikawaGreyNew };
const textFieldInputStyle = { borderColor: A11Y_COLORS.permaLividBlueNew };

const languageOptions = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'es',
    label: 'Spanish',
  },
];

const handleInputFocus = (e) => {
  const scrollY = e.target.getBoundingClientRect().top + window.scrollY - 160;

  window.scrollTo({
    top: scrollY,
    behavior: 'smooth',
  });
};

const ConsenterDetailsForm = ({
  consenterAlsoEmergencyContact,
  setConsenterAlsoEmergencyContact,
  showConsenterAlsoEmergencyContactCheckbox,
  relation,
  detailsRef,
}: {
  consenterAlsoEmergencyContact: any;
  setConsenterAlsoEmergencyContact: any;
  showConsenterAlsoEmergencyContactCheckbox: boolean;
  relation: string;
  detailsRef?: RefObject<HTMLInputElement>;
}) => {
  const { localizationParentalConsent } = useFlags();
  const { t: tTeenOnboarding } = useTranslation('teenOnboarding');
  return (
    <View ref={detailsRef}>
      <RHFInput
        fieldName="firstName"
        label={
          relation === 'parent'
            ? tTeenOnboarding('sendConsentFormTo.parentFirst', 'Parent first name')
            : tTeenOnboarding('sendConsentFormTo.guardianFirst', 'Guardian first name')
        }
        containerStyle={textFieldContainerStyle}
        labelStyle={textFieldLabelStyle}
        inputStyle={textFieldInputStyle}
        handleInputFocus={handleInputFocus}
      />
      <RHFInput
        fieldName="lastName"
        label={
          relation === 'parent'
            ? tTeenOnboarding('sendConsentFormTo.parentLast', 'Parent last name')
            : tTeenOnboarding('sendConsentFormTo.guardianLast', 'Guardian last name')
        }
        containerStyle={textFieldContainerStyle}
        labelStyle={textFieldLabelStyle}
        inputStyle={textFieldInputStyle}
        handleInputFocus={handleInputFocus}
      />
      <RHFInput
        fieldName="email"
        label={
          relation === 'parent'
            ? tTeenOnboarding('sendConsentFormTo.parentRegEmail', 'Parent email')
            : tTeenOnboarding('sendConsentFormTo.guardianRegEmail', 'Guardian email')
        }
        containerStyle={textFieldContainerStyle}
        labelStyle={textFieldLabelStyle}
        inputStyle={textFieldInputStyle}
        handleInputFocus={handleInputFocus}
      />
      {localizationParentalConsent && (
        <RHFSelect
          fieldName="language"
          label={tTeenOnboarding('sendConsentFormTo.theirLanguage', 'Their preferred language')}
          containerStyle={textFieldContainerStyle}
          labelStyle={textFieldLabelStyle}
          inputStyle={textFieldInputStyle}
          handleInputFocus={handleInputFocus}
          options={languageOptions}
          menuPosition="fixed"
          registerOptionValue
        />
      )}
      <View
        style={{
          justifyContent: 'left',
          flexDirection: 'row',
        }}
      >
        {showConsenterAlsoEmergencyContactCheckbox && (
          <Checkbox
            checkedColor={A11Y_COLORS.green}
            checkboxStyle={{ width: 24, height: 24 }}
            label={
              relation === 'parent'
                ? tTeenOnboarding(
                    'sendConsentFormTo.parentEmergency',
                    'My parent is also my emergency contact'
                  )
                : tTeenOnboarding(
                    'sendConsentFormTo.guardianEmergency',
                    'My guardian is also my emergency contact'
                  )
            }
            isLabelOnRight
            isChecked={consenterAlsoEmergencyContact}
            setIsChecked={(value: boolean) => setConsenterAlsoEmergencyContact(value)}
            dataQa="consenterAlsoEmergencyContactCheckbox"
            labelStyle={{
              marginTop: 3,
              color: A11Y_COLORS.darkGray,
              fontSize: 13,
              fontWeight: '400',
            }}
          />
        )}
      </View>
    </View>
  );
};

export default ConsenterDetailsForm;
