import { useReducer, useCallback } from 'react';
import {
  FriendReferralInfoState,
  initialState,
  frienReferralInfoReducer,
} from '../reducers/frienReferralInfoReducer';
import FriendReferralApiHelper from '../utils/friendReferralApiHelper';
import { getUserData } from '../../auth/helpers/token';

export default function useFriendReferralInfo(): [
  FriendReferralInfoState,
  {
    getFriendReferralInfo: () => void;
  }
] {
  const [state, dispatch] = useReducer(frienReferralInfoReducer, initialState);

  function getFriendReferralInfo(): void {
    dispatch({ type: 'requestGetFriendReferralInfo' });

    const { id: userID } = getUserData();

    FriendReferralApiHelper.getFriendReferralInfo(userID)
      .then((friendReferralInfo) => {
        if (friendReferralInfo) {
          dispatch({
            type: 'receiveGetFriendReferralInfo',
            payload: {
              friendReferralInfo,
            },
          });
        } else {
          throw new Error('Unable to fetch data');
        }
      })
      .catch(FriendReferralApiHelper.dismissIfCancelled)
      .catch(() => {
        dispatch({ type: 'setError', error: 'Unable to load content' });
      });
  }
  return [
    state,
    {
      getFriendReferralInfo: useCallback(getFriendReferralInfo, []),
    },
  ];
}
