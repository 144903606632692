export type ItemType = 'vimeoVideo';

export interface CMSItemData {
  title: string;
  duration: string;
  embedURL: string;
  subtitle: string;
  thumbnailURL: string;
  liveStreamURL: string;
  highDef720pURL: string;
  highDef1080pURL: string;
  standard360pURL: string;
  standard540pURL: string;
}

export interface CMSItem {
  id: string;
  itemType: ItemType;
  itemData: CMSItemData;
  isViewed: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export class ECMSItem implements CMSItem {
  id: string;

  itemType: ItemType;

  itemData: CMSItemData;

  isViewed: boolean;

  constructor(CMSItem: CMSItem) {
    this.id = CMSItem.id;
    this.itemType = CMSItem.itemType;
    this.itemData = CMSItem.itemData;
    this.isViewed = CMSItem.isViewed;
  }
}
