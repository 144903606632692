import { View } from '../../../components';
import { useWindowWidthState } from '../../../hooks/windowWidthContext';
import { HybridType } from './gridTypes';
import { getBreakpoint, getBreakpoints, getGridConfig } from './gridUtils';
import { EmotionStyle } from '../../../core/styled';

type GridProps = {
  children: React.ReactNode;
  hybridType?: HybridType;
  className?: string;
  style?: EmotionStyle;
};

const GridMarginView = ({ children, hybridType, className, style }: GridProps) => {
  const { width } = useWindowWidthState();
  const breakpoints = getBreakpoints(hybridType);
  const breakpoint = getBreakpoint(width, hybridType || undefined, breakpoints);
  const { sideMargin } = getGridConfig(breakpoint, hybridType || undefined);

  return (
    <View
      style={{
        margin: `0 ${sideMargin}px`,
        ...style,
      }}
      className={className}
    >
      {children}
    </View>
  );
};

export default GridMarginView;
