import { useQuery } from 'react-query';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { getDailyPromptQueryKey } from '../../utils/queryKeys';

interface QueryProps {
  clientUserID: string | number;
  disabled?: boolean;
}

export interface DailyPrompt {
  text: string;
  placeholders: Array<string>;
}

const fetchDailyPrompt =
  ({ clientUserID }: QueryProps) =>
  async () => {
    const response = await apiWrapper.get(
      `${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/community/daily-prompt`
    );
    const data = response?.data?.data ?? {};

    const dailyPrompt: DailyPrompt = {
      text: data.text,
      placeholders: data.placeholders,
    };

    return dailyPrompt;
  };

const useQueryDailyPrompt = ({ clientUserID, disabled }: QueryProps) =>
  useQuery<DailyPrompt, Error>({
    queryKey: getDailyPromptQueryKey({ clientUserID }),
    queryFn: fetchDailyPrompt({ clientUserID }),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(clientUserID) && !disabled,
  });

export default useQueryDailyPrompt;
