import { Route, Switch } from '../core/routerLib';
import ClinicalProgress from './screens/ClinicalProgress';

const Routes = () => (
  <Switch>
    <Route path="/room/:roomID" component={ClinicalProgress} />
  </Switch>
);

export default Routes;
