import apiHelper from '../core/api/apiHelper';
import apiWrapper from '../core/api/apiWrapper';

export type AdminConfig =
  | 'email_verification'
  | 'clinical_progress_web'
  | 'cancellation_coupon_voucher'
  | 'cx_response_time_in_days'
  | 'new_payments_feature'
  | 'cognito_user_migration'
  | 'superbills'
  | 'stripe_link'
  | 'treatment_intake_in_onboarding';

interface ApiResponse {
  data?: Data;
  status?: number;
}

interface Data {
  data: Coupon | number;
  success: boolean;
}

export interface Coupon {
  codes: string[] | undefined;
}

const getAdminOptionByName = (name: AdminConfig, isJSON = false): Promise<ApiResponse> => {
  const jsonString = isJSON ? '&json=true' : '';
  return apiWrapper.get(
    `${apiHelper().apiEndpoint}/v3/users/get-admin-config-value?key=${name}${jsonString}`
  );
};

const getPublicAdminOptionByName = (name: AdminConfig): Promise<ApiResponse> =>
  apiWrapper.get(`${apiHelper().publicAPIEndpoint}/public/v1/get-admin-config-value?key=${name}`);

const getPublicAdminFlagValue = async (flagName: AdminConfig): Promise<boolean> => {
  const configVal = await getPublicAdminOptionByName(flagName);
  return !!configVal?.data?.data;
};

const adminConfig = {
  getAdminOptionByName,
  getPublicAdminOptionByName,
  getPublicAdminFlagValue,
};

export default adminConfig;
