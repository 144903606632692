// DO NOT IMPORT THIS FILE DIRECTLY (use eventTracker)
/* eslint-disable */
import configs, { detectEnv } from '@/utils/configs';

const DEBUG = detectEnv !== 'prod';

const VWOConfig = configs.analytics.VWO;

export const initVWO = (callback = null) => {
  window._vwo_code =
    window._vwo_code ||
    (function () {
      const account_id = VWOConfig.accountID;
      const settings_tolerance = 5000;
      const library_tolerance = 5000;
      const use_existing_jquery = false;
      const is_spa = 1;
      const hide_element = null; // we don't want to hide anything usually set to 'body';

      /* DO NOT EDIT BELOW THIS LINE */
      let f = false;
      const d = document;
      const code = {
        use_existing_jquery() {
          return use_existing_jquery;
        },
        library_tolerance() {
          return library_tolerance;
        },
        finish() {
          if (!f) {
            VWOConfig.debugMode && console.log('VWO: snipped attached successfully');
            f = true;
            const a = d.getElementById('_vis_opt_path_hides');
            if (a) a.parentNode.removeChild(a);
            if (typeof callback === 'function') callback();
          }
        },
        finished() {
          return f;
        },
        load(a) {
          const b = d.createElement('script');
          b.src = a;
          b.type = 'text/javascript';
          b.innerText;
          b.onerror = function () {
            _vwo_code.finish();
          };
          d.getElementsByTagName('head')[0].appendChild(b);
        },
        init() {
          window.settings_timer = setTimeout('_vwo_code.finish()', settings_tolerance);
          const a = d.createElement('style');
          const b = hide_element
            ? `${hide_element}{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}`
            : '';
          const h = d.getElementsByTagName('head')[0];
          a.setAttribute('id', '_vis_opt_path_hides');
          a.setAttribute('type', 'text/css');
          if (a.styleSheet) a.styleSheet.cssText = b;
          else a.appendChild(d.createTextNode(b));
          h.appendChild(a);
          this.load(
            `${VWOConfig.librarySRC}/j.php?a=${account_id}&u=${encodeURIComponent(
              d.URL
            )}&f=${+is_spa}&r=${Math.random()}`
          );
          return settings_timer;
        },
      };
      window._vwo_settings_timer = code.init();
      return code;
    })();
};

export const trackVWOConversionGoal = (goalID) => {
  if (!goalID) return;
  window._vis_opt_queue = window._vis_opt_queue || [];
  window._vis_opt_queue.push(function () {
    _vis_opt_goal_conversion(goalID);
  });
  if (DEBUG) {
    console.log(`Fired VWO Conversion -- Goal ID: ${goalID}`);
  }
};

export const trackVWOPageView = (URL) => {
  window.VWO = window.VWO || [];
  window.VWO.push([
    'activate',
    {
      virtualPageUrl: URL,
    },
  ]);
};

export const trackPurchaseGoal = () => trackVWOConversionGoal(VWOConfig.purchaseGoalID);

export const trackRegisterGoal = () => trackVWOConversionGoal(VWOConfig.registerGoalID);

export const trackInitiatedQMGoal = () =>
  trackVWOConversionGoal(VWOConfig.initiatedQuickmatchGoalID);

export const trackQMLeadCapturedGoal = () => trackVWOConversionGoal(VWOConfig.qmLeadCaptureGoalID);

export const trackSelectedPlanGoal = () => trackVWOConversionGoal(VWOConfig.selectedPlanGoalID);

export const trackQMPurchaseGoal = () => trackVWOConversionGoal(VWOConfig.qmPurchaseGoalID);

export const addListenerVWO = (eventHandler) => {
  window.VWO = window.VWO || [];
  window.VWO.push(['onEventReceive', 'vA', eventHandler]);
};

export const removeListenerVWO = (eventHandler) => {
  if (!window.VWO || !window.VWO._ || !window.VWO._.prVWO.forEach) return;
  // unmount the handler from the VWO object
  let idx = -1;
  window.VWO._.prVWO.forEach((elm, i) => {
    if (elm[2] && elm[2] === eventHandler) idx = i;
  });
  if (idx > -1) window.VWO._.prVWO.splice(idx, 1);
};
