import { Ellipse, Path, Rect, Svg } from 'svgs';

const HandWithHeartIllustration = () => {
  const titleText = 'hand with heart';
  return (
    <Svg
      width="343"
      height="280"
      viewBox="0 0 343 280"
      fill="none"
      aria-label={titleText}
      title={titleText}
    >
      <Rect width="343" height="280" fill="white" />
      <Path
        d="M213.694 107.94L138.808 95.6564L116.099 92.0083L108.309 90.782C108.127 90.7512 107.944 90.7295 107.76 90.717C107.936 90.7644 108.117 90.8015 108.3 90.8278L109.906 91.1063L116.069 92.1281L138.775 95.7887L213.756 107.627C243.022 112.234 269.526 116.428 288.689 119.544C298.268 121.099 306.021 122.38 311.371 123.295C314.053 123.752 316.126 124.117 317.529 124.377C318.254 124.477 318.969 124.627 319.663 124.824C318.936 124.787 318.208 124.704 317.489 124.576C316.074 124.384 313.983 124.076 311.297 123.67C305.927 122.855 298.161 121.641 288.574 120.093C269.381 117.097 242.919 112.751 213.694 107.94Z"
        fill="#D2E9E6"
      />
      <Path
        d="M212.244 112.239L139.921 92.3734L117.97 86.426L111.984 84.835L110.422 84.4344C110.073 84.3508 109.893 84.3096 109.884 84.3111C110.052 84.3775 110.227 84.4338 110.407 84.4793L111.948 84.9195L117.903 86.5701L139.852 92.53L212.37 111.963C240.682 119.53 266.313 126.413 284.835 131.468C294.099 133.997 301.58 136.064 306.74 137.52C309.323 138.245 311.322 138.822 312.671 139.223C313.373 139.399 314.054 139.62 314.705 139.883C313.984 139.772 313.272 139.615 312.581 139.415C311.201 139.077 309.178 138.557 306.578 137.881C301.374 136.521 293.853 134.521 284.584 132.018C266.038 127.019 240.465 120.011 212.244 112.239Z"
        fill="#D2E9E6"
      />
      <Path
        d="M209.782 116.264L141.833 89.2076L121.192 81.0737L115.554 78.8974L114.081 78.339C113.918 78.2722 113.748 78.2138 113.573 78.1645C113.725 78.2462 113.885 78.319 114.054 78.3819L115.5 78.9748L121.086 81.216L141.725 89.3582L209.983 115.999C236.634 126.389 260.746 135.821 278.155 142.716C286.856 146.165 293.887 148.977 298.726 150.942C301.117 151.924 303.018 152.7 304.276 153.233C304.943 153.479 305.577 153.769 306.167 154.098C305.468 153.914 304.79 153.685 304.144 153.414C302.836 152.938 300.923 152.216 298.472 151.28C293.563 149.403 286.463 146.649 277.77 143.227C260.324 136.391 236.293 126.838 209.782 116.264Z"
        fill="#D2E9E6"
      />
      <Path
        d="M206.381 119.908L144.518 86.3438L125.702 76.2333L120.554 73.5069L119.207 72.8051C119.059 72.7206 118.901 72.6453 118.734 72.5803C118.868 72.6752 119.013 72.7627 119.167 72.8418L120.483 73.5731L125.569 76.3499L144.376 86.4719L206.634 119.677C230.956 132.631 252.957 144.377 268.815 152.943C276.744 157.224 283.145 160.707 287.543 163.139C289.741 164.344 291.438 165.303 292.582 165.955C293.191 166.267 293.759 166.615 294.277 166.995C293.619 166.738 292.992 166.442 292.403 166.112C291.232 165.498 289.469 164.606 287.214 163.431C282.729 161.079 276.272 157.653 268.32 153.389C252.407 144.87 230.524 133.018 206.381 119.908Z"
        fill="#D2E9E6"
      />
      <Path
        d="M202.117 123.082L147.789 83.8819L131.251 72.0508L126.714 68.8534L125.526 68.0306C125.387 67.9297 125.24 67.8347 125.084 67.7463C125.196 67.8538 125.321 67.9554 125.457 68.0499L126.583 68.893L131.041 72.1337C134.965 74.9381 140.603 78.9788 147.577 83.9731L202.428 122.881C223.836 138.057 243.192 151.814 257.123 161.825C264.089 166.827 269.702 170.894 273.551 173.721C275.481 175.137 276.931 176.238 277.958 176.992C278.496 177.361 278.986 177.76 279.422 178.185C278.817 177.868 278.255 177.515 277.743 177.13C276.682 176.417 275.135 175.349 273.159 173.968C269.184 171.195 263.514 167.187 256.518 162.203C242.527 152.237 223.305 138.39 202.117 123.082Z"
        fill="#D2E9E6"
      />
      <Path
        d="M197.105 125.717L151.619 81.8601L137.746 68.6099L133.935 65.0029C133.497 64.6031 133.164 64.2971 132.93 64.0755C132.824 63.9652 132.701 63.8623 132.564 63.7688C132.659 63.8825 132.762 63.9934 132.873 64.101L133.83 65.0464L137.56 68.6666L151.419 81.9232L197.452 125.535C215.416 142.539 231.685 157.988 243.362 169.174C249.202 174.776 253.893 179.325 257.107 182.482C258.734 184.061 259.952 185.288 260.774 186.131C261.228 186.545 261.626 186.985 261.962 187.443C261.433 187.069 260.953 186.665 260.527 186.238C259.626 185.434 258.338 184.235 256.654 182.681C253.319 179.579 248.536 175.071 242.668 169.482C230.933 158.329 214.84 142.84 197.105 125.717Z"
        fill="#D2E9E6"
      />
      <Path
        d="M191.464 127.745L155.933 80.3011L145.068 65.9563L142.079 62.049L141.266 61.0577C141.181 60.9415 141.085 60.8287 140.978 60.7201C141.034 60.8422 141.11 60.9608 141.205 61.0738C141.382 61.3208 141.626 61.6598 141.946 62.0969L144.856 66.0132L155.707 80.3643L191.85 127.626C205.971 146.066 218.725 162.787 227.853 174.904C232.42 180.967 236.088 185.883 238.581 189.291C239.834 190.996 240.787 192.323 241.422 193.227C241.782 193.677 242.082 194.146 242.316 194.626C241.868 194.209 241.477 193.768 241.148 193.308C240.43 192.426 239.395 191.125 238.08 189.44C235.468 186.077 231.704 181.189 227.097 175.133C217.89 163.025 205.318 146.264 191.464 127.745Z"
        fill="#D2E9E6"
      />
      <Path
        d="M185.333 129.117C175.708 109.636 166.996 92.0091 160.679 79.2353C157.485 72.8685 154.897 67.7142 153.097 64.1358C152.193 62.3653 151.482 60.9905 150.992 60.037L150.433 58.9738C150.375 58.8517 150.301 58.7323 150.213 58.6169C150.25 58.7411 150.302 58.864 150.366 58.9848L150.872 60.0567C151.329 61.0136 152.015 62.3845 152.883 64.1709L160.446 79.2716C166.929 92.02 175.865 109.622 185.754 129.046C195.642 148.469 204.58 166.063 210.927 178.816C214.106 185.193 216.64 190.36 218.356 193.938C219.211 195.727 219.867 197.119 220.287 198.066C220.547 198.542 220.739 199.03 220.859 199.522C220.509 199.062 220.22 198.585 219.997 198.097C219.484 197.158 218.746 195.791 217.818 194.013C215.935 190.46 213.321 185.31 210.101 178.939C203.672 166.212 194.941 148.586 185.333 129.117Z"
        fill="#D2E9E6"
      />
      <Path
        d="M178.867 129.796C173.761 109.841 169.127 91.7862 165.768 78.6961C164.052 72.1711 162.653 66.8861 161.717 63.2203C161.212 61.4225 160.829 59.9919 160.557 59.0167C160.424 58.5447 160.314 58.1838 160.236 57.9224C160.205 57.7975 160.163 57.6732 160.11 57.5502C160.112 57.6749 160.132 57.8005 160.171 57.925C160.229 58.1876 160.319 58.5498 160.426 59.022C160.673 59.9973 161.026 61.4148 161.488 63.2316L165.539 78.7116L179.317 129.772C184.718 149.701 189.573 167.752 192.968 180.822C194.669 187.357 196.008 192.65 196.889 196.307C197.335 198.138 197.661 199.56 197.865 200.524C198.019 201.015 198.097 201.509 198.097 202C197.851 201.522 197.67 201.033 197.558 200.54C197.256 199.582 196.839 198.163 196.32 196.338C195.284 192.686 193.84 187.403 192.1 180.869C188.607 167.797 183.955 149.739 178.867 129.796Z"
        fill="#D2E9E6"
      />
      <Path
        d="M172.212 129.762C171.736 109.827 171.311 91.7906 170.998 78.7162C170.794 72.193 170.629 66.9089 170.513 63.2434C170.44 61.4232 170.376 60.0086 170.337 59.0315C170.307 58.5627 170.288 58.199 170.275 57.9391C170.273 57.8138 170.257 57.6877 170.226 57.5621C170.204 57.6838 170.196 57.8077 170.205 57.9324C170.205 58.1945 170.205 58.5553 170.209 59.0242C170.223 60.0016 170.254 61.4155 170.29 63.2297C170.399 66.8984 170.557 72.1815 170.749 78.7028C171.24 91.7881 171.918 109.817 172.659 129.785C173.401 149.753 174.08 167.774 174.434 180.838C174.617 187.371 174.724 192.654 174.759 196.303C174.774 198.126 174.765 199.543 174.756 200.504C174.791 200.997 174.753 201.486 174.643 201.964C174.511 201.471 174.444 200.976 174.444 200.484C174.362 199.522 174.269 198.098 174.184 196.272C173.995 192.613 173.78 187.325 173.558 180.79C173.115 167.721 172.665 149.681 172.212 129.762Z"
        fill="#D2E9E6"
      />
      <Path
        d="M165.542 129.017C169.649 109.593 173.367 92.0154 176.058 79.2721C177.36 72.9087 178.409 67.7598 179.142 64.1807C179.492 62.4058 179.764 61.0268 179.953 60.0727C180.043 59.6144 180.113 59.2602 180.163 59.0061C180.195 58.8846 180.209 58.7602 180.205 58.6345C180.171 58.7552 180.147 58.8775 180.132 59.0012C180.082 59.2553 180.012 59.6095 179.884 60.0618C179.695 61.0159 179.379 62.3881 178.997 64.158C178.291 67.7369 177.207 72.8975 175.907 79.2485C173.385 92.0183 169.906 109.634 166.061 129.103C162.216 148.572 158.738 166.184 156.089 178.912C154.766 185.281 153.659 190.429 152.861 193.981C152.458 195.756 152.128 197.135 151.886 198.063C151.806 198.545 151.659 199.017 151.447 199.472C151.423 198.98 151.469 198.492 151.586 198.016C151.728 197.067 151.97 195.679 152.297 193.892C152.952 190.313 153.956 185.132 155.236 178.778C157.718 166.015 161.411 148.429 165.542 129.017Z"
        fill="#D2E9E6"
      />
      <Path
        d="M159.006 127.593C167.611 109.148 175.399 92.4556 181.042 80.3704C183.813 74.3241 186.053 69.4272 187.61 66.0251C188.364 64.3352 188.946 63.0221 189.351 62.115C189.541 61.6767 189.687 61.3344 189.786 61.0923C189.845 60.9785 189.888 60.8601 189.914 60.7387C189.832 60.8445 189.767 60.9565 189.719 61.0731C189.607 61.3132 189.437 61.6474 189.229 62.0828C188.799 62.9818 188.185 64.2899 187.389 65.9646C185.832 69.3667 183.58 74.2616 180.809 80.3079C175.329 92.4489 167.784 109.188 159.433 127.72C151.082 146.253 143.539 162.983 137.945 175.08C135.148 181.131 132.851 186.019 131.243 189.387C130.426 191.072 129.78 192.375 129.329 193.253C129.14 193.713 128.879 194.155 128.552 194.57C128.647 194.091 128.806 193.623 129.029 193.171C129.388 192.266 129.954 190.941 130.691 189.236C132.171 185.831 134.363 180.917 137.124 174.857C142.615 162.722 150.385 146.022 159.006 127.593Z"
        fill="#D2E9E6"
      />
      <Path
        d="M152.782 125.509C165.733 108.517 177.448 93.1389 185.93 81.9904C190.121 76.4083 193.519 71.8956 195.877 68.7613L198.529 65.145C198.817 64.7349 199.04 64.4221 199.201 64.1982C199.284 64.0919 199.355 63.9812 199.415 63.8668C199.311 63.9622 199.219 64.0642 199.142 64.1717C198.968 64.3936 198.727 64.6993 198.41 65.0961C197.767 65.9272 196.845 67.1309 195.661 68.6757L185.696 81.8978C177.363 93.1084 165.87 108.577 153.145 125.665C140.42 142.753 128.953 158.217 120.51 169.367C116.293 174.945 112.847 179.442 110.425 182.54C109.219 184.069 108.262 185.284 107.599 186.086C107.303 186.515 106.938 186.917 106.51 187.285C106.711 186.828 106.979 186.39 107.311 185.976C107.89 185.135 108.76 183.906 109.898 182.333C112.181 179.182 115.535 174.628 119.712 169.052C128.123 157.876 139.82 142.486 152.782 125.509Z"
        fill="#D2E9E6"
      />
      <Path
        d="M147.031 122.825C163.994 107.702 179.342 94.0172 190.464 84.0962L203.538 72.3087L207.049 69.0854L207.953 68.2396C208.063 68.1476 208.159 68.0475 208.238 67.9409C208.109 68.0212 207.994 68.111 207.895 68.209L206.943 69.0344C206.108 69.7621 204.9 70.8392 203.349 72.2146C200.246 75.0038 195.782 79.0264 190.265 83.9918L147.394 123.02C130.678 138.263 115.542 152.054 104.432 161.977C98.8971 166.94 94.366 170.939 91.2397 173.682C89.6762 175.055 88.427 176.113 87.5756 176.825C87.1781 177.209 86.719 177.56 86.2057 177.872C86.5189 177.452 86.8912 177.057 87.3179 176.69C88.1092 175.955 89.2522 174.847 90.7667 173.427C93.7909 170.612 98.2157 166.562 103.723 161.578C114.728 151.626 130.059 137.93 147.031 122.825Z"
        fill="#D2E9E6"
      />
      <Path
        d="M141.879 119.595C162.413 106.71 180.975 95.0431 194.456 86.589C201.142 82.3447 206.556 78.914 210.309 76.5279L214.59 73.765L215.69 73.0361C215.822 72.9597 215.941 72.8733 216.045 72.7783C215.902 72.8469 215.766 72.9216 215.639 73.0022L214.496 73.6816L210.149 76.3909L194.285 86.4417L142.199 119.843C121.888 132.876 103.462 144.675 90.0395 153.126C83.3356 157.363 77.8749 160.769 74.0793 163.106C72.1757 164.271 70.6926 165.172 69.6759 165.772C69.1823 166.098 68.6422 166.392 68.062 166.648C68.4674 166.268 68.9321 165.921 69.4491 165.612C70.4042 164.963 71.825 164.018 73.6663 162.809C77.3756 160.386 82.7355 156.934 89.4177 152.676C102.773 144.137 121.351 132.456 141.879 119.595Z"
        fill="#D2E9E6"
      />
      <Path
        d="M137.456 115.918L197.848 89.4041C205.538 85.9966 211.759 83.2215 216.099 81.3056L221.03 79.0748C221.572 78.8165 221.998 78.6215 222.297 78.4838C222.45 78.4233 222.59 78.3502 222.715 78.266C222.557 78.3188 222.405 78.3781 222.26 78.4435L220.948 78.9975L215.995 81.1775L197.726 89.2689L137.728 116.175C114.333 126.669 93.1199 136.187 77.6949 142.999C69.9796 146.402 63.7106 149.157 59.3657 151.003C57.1824 151.948 55.4854 152.656 54.3255 153.131C53.7625 153.398 53.1572 153.625 52.5181 153.809C53.0178 153.487 53.5654 153.201 54.1538 152.954C55.26 152.423 56.9018 151.668 59.0361 150.677C63.2989 148.723 69.4984 145.927 77.1864 142.498C92.5341 135.652 113.872 126.252 137.456 115.918Z"
        fill="#D2E9E6"
      />
      <Path
        d="M133.868 111.869L200.603 92.497L220.78 86.5575L226.241 84.9128L227.653 84.4735C227.817 84.43 227.973 84.3754 228.118 84.3104C227.945 84.3394 227.779 84.3809 227.622 84.4342C227.274 84.5256 226.795 84.6606 226.179 84.8343L220.697 86.4199L200.516 92.3501L134.102 112.155C108.194 119.898 84.7097 126.881 67.6556 131.864C59.1251 134.358 52.2112 136.354 47.4096 137.698C45.0076 138.377 43.1358 138.89 41.8602 139.23C41.23 139.432 40.565 139.588 39.8752 139.695C40.4479 139.433 41.0629 139.212 41.7114 139.035C42.9467 138.637 44.7719 138.065 47.1375 137.342C51.8632 135.891 58.7322 133.827 67.2418 131.309C84.2281 126.277 107.804 119.416 133.868 111.869Z"
        fill="#D2E9E6"
      />
      <Path
        d="M131.198 107.557L202.652 95.8352L224.283 92.2126L230.151 91.1946L231.666 90.9176C231.843 90.8935 232.015 90.8572 232.178 90.8093C231.996 90.8222 231.817 90.8442 231.642 90.8752L230.103 91.1097L224.209 92.0635L202.598 95.6807L131.378 107.855C103.577 112.62 78.3821 116.911 60.1121 119.937C50.9732 121.454 43.5638 122.654 38.4361 123.458C35.8186 123.854 33.8209 124.154 32.4894 124.352C31.8055 124.476 31.0979 124.552 30.3771 124.578C31.0225 124.385 31.701 124.237 32.4028 124.137C33.7458 123.88 35.6978 123.517 38.242 123.067C43.3213 122.148 50.6905 120.89 59.8156 119.345C78.024 116.27 103.289 112.115 131.198 107.557Z"
        fill="#D2E9E6"
      />
      <Path
        d="M129.523 103.083L204.007 99.3435L226.557 98.138L232.676 97.7782L234.267 97.6721C234.448 97.6641 234.627 97.6462 234.801 97.6187C234.618 97.6085 234.436 97.61 234.257 97.6233L232.655 97.6889L226.517 97.9813L203.966 99.1567L129.613 103.346C100.598 104.999 74.3128 106.467 55.2646 107.446C45.7397 107.94 38.028 108.315 32.6907 108.545C30.0185 108.661 27.9412 108.742 26.5312 108.782C25.8182 108.832 25.0929 108.834 24.3652 108.789C25.0505 108.671 25.7571 108.599 26.4758 108.572C27.8669 108.464 29.9298 108.325 32.5868 108.155C37.8937 107.817 45.5927 107.375 55.11 106.855C74.131 105.85 100.453 104.521 129.523 103.083Z"
        fill="#D2E9E6"
      />
      <Path
        d="M128.892 98.5545L204.51 102.915L227.422 104.16L233.648 104.47L235.27 104.536C235.456 104.548 235.641 104.55 235.824 104.542C235.639 104.516 235.453 104.498 235.266 104.488L233.647 104.379L227.42 104.009L204.504 102.751L128.899 98.8477C99.3956 97.3433 72.6744 95.9504 53.3299 94.8639C43.6536 94.3244 35.8234 93.8606 30.4129 93.5115C27.7073 93.339 25.6056 93.1842 24.178 93.0841C23.45 93.0572 22.7204 92.9812 22 92.8572C22.7152 92.8177 23.4409 92.8227 24.1681 92.872C25.5939 92.916 27.6971 92.998 30.3993 93.1227C35.8044 93.3679 43.6361 93.7588 53.311 94.2724C72.6528 95.3069 99.3799 96.8339 128.892 98.5545Z"
        fill="#D2E9E6"
      />
      <Path
        d="M129.303 94.0869L204.208 106.309L226.92 109.94L234.703 111.166C234.886 111.194 235.069 111.215 235.253 111.231C235.076 111.183 234.896 111.146 234.713 111.12L233.113 110.842L226.943 109.824L204.233 106.184L129.239 94.4077C99.969 89.8255 73.4617 85.6482 54.2941 82.5533C44.7135 81.0064 36.9605 79.7257 31.6086 78.8187C28.9268 78.3621 26.8528 78.0013 25.4494 77.746C24.7258 77.6401 24.0113 77.4902 23.315 77.2982C24.0426 77.3346 24.7706 77.4161 25.4897 77.5418C26.9054 77.7346 28.9964 78.0423 31.6826 78.4437C37.0535 79.2549 44.8526 80.4655 54.4131 81.9835C73.605 84.9877 100.073 89.3005 129.303 94.0869Z"
        fill="#D2E9E6"
      />
      <Path
        d="M130.747 89.7823L203.083 109.586L225.043 115.517L231.031 117.1L232.593 117.501C232.768 117.553 232.948 117.594 233.131 117.624C232.962 117.56 232.787 117.506 232.607 117.46L231.066 117.02L225.111 115.373L203.151 109.433L130.621 90.0631C102.292 82.5145 76.6571 75.6521 58.1256 70.6126C48.8661 68.0938 41.3769 66.0341 36.2096 64.5848C33.6322 63.8611 31.6323 63.2886 30.2765 62.8904C29.5764 62.711 28.8955 62.4902 28.2422 62.2307C28.9644 62.3381 29.6762 62.4933 30.367 62.6941C31.7477 63.0318 33.7716 63.548 36.3776 64.2246C41.5827 65.581 49.1039 67.577 58.3754 70.0676C76.9309 75.0509 102.515 82.0384 130.747 89.7823Z"
        fill="#D2E9E6"
      />
      <Path
        d="M133.193 85.7766L201.185 112.779L221.852 120.879L227.49 123.055L228.963 123.613C229.128 123.681 229.3 123.74 229.477 123.789C229.323 123.707 229.16 123.634 228.991 123.57L227.544 122.978L221.95 120.744L201.309 112.614L133.003 86.0174C106.336 75.6461 82.207 66.2327 64.7891 59.3494C56.0794 55.9119 49.0481 53.1038 44.2079 51.1433C41.8165 50.1653 39.9087 49.3882 38.6506 48.855C37.9867 48.6076 37.3533 48.3194 36.7593 47.9945C37.4582 48.1784 38.136 48.4059 38.7833 48.674C40.0913 49.1506 42.0043 49.8684 44.4558 50.8042C49.3651 52.6769 56.4659 55.4272 65.1743 58.8387C82.6279 65.6718 106.669 75.2044 133.193 85.7766Z"
        fill="#D2E9E6"
      />
      <Path
        d="M136.592 82.1105L198.53 115.626L217.368 125.723L222.516 128.445C223.103 128.748 223.541 128.98 223.865 129.143C224.016 129.225 224.173 129.302 224.336 129.372C224.202 129.277 224.058 129.189 223.903 129.11L222.587 128.379L217.494 125.605L198.671 115.498L136.34 82.3372C112.001 69.4068 89.9838 57.6753 74.1102 49.1235C66.1635 44.8653 59.7654 41.3707 55.3656 38.9468C53.1613 37.7406 51.4631 36.7859 50.3199 36.1291C49.7001 35.8168 49.1199 35.4679 48.5872 35.0874C49.2461 35.342 49.8738 35.6365 50.4618 35.9667C51.6329 36.5807 53.3952 37.4724 55.6578 38.6448C60.1501 40.9931 66.614 44.4164 74.5741 48.6724C90.5264 57.1851 112.433 69.0194 136.592 82.1105Z"
        fill="#D2E9E6"
      />
      <Path
        d="M140.851 78.9319L195.264 118.093L211.824 129.911L216.362 133.104L217.556 133.928C217.685 134.027 217.827 134.117 217.982 134.197C217.864 134.092 217.737 133.991 217.602 133.896L216.476 133.053L212.004 129.815L195.452 117.99L140.541 79.128C119.11 63.9705 99.7257 50.2264 85.7851 40.2304C78.8062 35.2267 73.1851 31.1671 69.3304 28.3393C67.4057 26.928 65.9175 25.8259 64.9235 25.068C64.3822 24.7023 63.8915 24.3041 63.4589 23.8794C64.0605 24.198 64.6224 24.5511 65.1373 24.9341C66.1984 25.6467 67.7458 26.7109 69.7288 28.0884C73.7041 30.8622 79.3759 34.861 86.3848 39.8436C100.396 49.8078 119.635 63.6364 140.851 78.9319Z"
        fill="#D2E9E6"
      />
      <Path
        d="M145.857 76.2918L191.415 120.112L205.316 133.354L209.13 136.94L210.137 137.863C210.242 137.974 210.365 138.077 210.503 138.17C210.411 138.056 210.308 137.947 210.193 137.842L209.237 136.892C208.368 136.056 207.109 134.839 205.499 133.275L191.612 120.032C179.808 108.868 163.509 93.4707 145.509 76.4518C127.508 59.4329 111.202 44.0384 99.5274 32.844C93.6671 27.2475 88.9683 22.7059 85.7473 19.5482C84.1208 17.969 82.9028 16.742 82.0735 15.9026C81.6207 15.4887 81.2226 15.0506 80.8845 14.5942C81.4144 14.965 81.8956 15.3659 82.3216 15.7913C83.2218 16.5989 84.5107 17.7941 86.2011 19.3447C89.5426 22.4483 94.3327 26.9527 100.209 32.5346C111.973 43.7043 128.066 59.1942 145.857 76.2918Z"
        fill="#D2E9E6"
      />
      <Path
        d="M151.491 74.2619L187.106 121.676L197.998 136.017L200.998 139.904C201.345 140.337 201.609 140.67 201.794 140.91C201.88 141.026 201.979 141.138 202.09 141.245C202.025 141.123 201.947 141.005 201.856 140.89L201.114 139.871C200.441 138.962 199.46 137.647 198.194 135.974L187.316 121.628C178.036 109.53 165.218 92.8332 151.097 74.3931C136.976 55.9531 124.16 39.2478 115.014 27.1241C110.434 21.0633 106.759 16.1501 104.284 12.7491C103.031 11.044 102.071 9.72011 101.443 8.81352C101.079 8.36298 100.778 7.89403 100.543 7.41322C100.992 7.83121 101.383 8.27405 101.71 8.73534C102.435 9.61408 103.47 10.9155 104.823 12.6062C107.46 15.9726 111.225 20.857 115.839 26.906C125.005 38.9942 137.611 55.748 151.491 74.2619Z"
        fill="#D2E9E6"
      />
      <Path
        d="M157.621 72.8684L182.362 122.729C185.571 129.09 188.165 134.245 189.972 137.82C190.914 139.597 191.593 140.967 192.084 141.916L192.649 142.98C192.709 143.101 192.78 143.22 192.863 143.336C192.829 143.212 192.78 143.09 192.716 142.969C192.592 142.713 192.423 142.36 192.204 141.896C191.746 140.944 191.055 139.567 190.186 137.785L182.592 122.71C176.064 109.963 167.11 92.3835 157.197 72.9521C147.285 53.5206 138.304 35.9457 131.943 23.1993C128.751 16.82 126.211 11.6559 124.487 8.08105C123.626 6.29153 122.97 4.89956 122.544 3.95173C122.292 3.47579 122.1 2.98992 121.971 2.49978C122.329 2.95778 122.618 3.43526 122.833 3.92431C123.352 4.86512 124.09 6.23143 125.025 8.00681C126.908 11.5637 129.541 16.7124 132.768 23.08C139.212 35.7965 147.978 53.4149 157.621 72.8684Z"
        fill="#D2E9E6"
      />
      <Path
        d="M164.085 72.1979L177.257 123.288L181.367 138.764C181.873 140.584 182.259 141.998 182.532 142.973C182.675 143.425 182.775 143.806 182.853 144.063C182.88 144.189 182.925 144.314 182.986 144.436C182.979 144.311 182.956 144.185 182.918 144.06C182.859 143.802 182.776 143.437 182.663 142.963L181.595 138.753C180.626 135.086 179.23 129.789 177.505 123.275L163.648 72.2235C158.207 52.2975 153.326 34.251 149.905 21.1771C148.191 14.6438 146.846 9.34994 145.953 5.69106C145.506 3.8637 145.173 2.44091 144.969 1.47663C144.82 0.985955 144.74 0.491398 144.731 0C144.979 0.479264 145.163 0.96846 145.283 1.46149C145.584 2.41973 146.002 3.83868 146.527 5.66122C147.57 9.31363 149.027 14.5992 150.773 21.1333C154.273 34.1982 158.958 52.2568 164.085 72.1979Z"
        fill="#D2E9E6"
      />
      <Path
        d="M170.723 72.2247C171.232 92.1561 171.697 110.182 172.033 123.273C172.25 129.798 172.421 135.079 172.543 138.75C172.624 140.567 172.687 141.981 172.726 142.958C172.756 143.431 172.78 143.796 172.794 144.056C172.796 144.18 172.812 144.305 172.843 144.429C172.866 144.307 172.873 144.183 172.864 144.058C172.864 143.8 172.858 143.435 172.86 142.967C172.84 141.988 172.809 140.578 172.774 138.76C172.651 135.094 172.486 129.81 172.276 123.285C171.765 110.201 171.056 92.1628 170.275 72.2014C169.494 52.24 168.784 34.2059 168.405 21.1425C168.209 14.6077 168.089 9.32633 168.048 5.67268C168.028 3.84794 168.041 2.43676 168.044 1.47489C168.014 0.982231 168.05 0.4933 168.152 0.014037C168.292 0.505131 168.361 0.999511 168.358 1.4899C168.438 2.45626 168.533 3.87588 168.623 5.70731C168.825 9.36479 169.047 14.6536 169.281 21.1901C169.762 34.261 170.25 52.3033 170.723 72.2247Z"
        fill="#D2E9E6"
      />
      <Path
        d="M177.411 72.9768C173.341 92.4065 169.655 109.989 166.983 122.735C165.725 129.106 164.656 134.256 163.93 137.832C163.58 139.607 163.307 140.986 163.119 141.94C163.028 142.398 162.958 142.752 162.908 143.006C162.875 143.127 162.85 143.249 162.836 143.373C162.888 143.256 162.925 143.136 162.946 143.012C162.995 142.762 163.066 142.404 163.188 141.951C163.376 140.997 163.686 139.624 164.068 137.854C164.775 134.275 165.84 129.111 167.133 122.759C169.655 109.989 173.078 92.3651 176.884 72.8939C180.691 53.4228 184.138 35.8025 186.764 23.0661C188.073 16.6953 189.168 11.545 189.967 7.99338C190.369 6.21807 190.692 4.84274 190.928 3.90903C191.008 3.42651 191.157 2.95512 191.374 2.50146C191.394 2.99314 191.347 3.48045 191.235 3.95737C191.093 4.90588 190.851 6.29404 190.531 8.08217C189.882 11.6616 188.89 16.8453 187.622 23.2012C185.166 35.9643 181.519 53.5489 177.411 72.9768Z"
        fill="#D2E9E6"
      />
      <Path
        d="M183.943 74.3833C175.375 92.8385 167.625 109.537 162.004 121.642L155.46 135.996C154.712 137.687 154.13 139 153.725 139.907C153.54 140.35 153.396 140.688 153.296 140.931C153.238 141.044 153.195 141.163 153.168 141.284C153.25 141.179 153.314 141.066 153.357 140.949C153.476 140.71 153.639 140.374 153.853 139.944C154.277 139.04 154.891 137.732 155.681 136.056C157.238 132.654 159.471 127.756 162.236 121.709C167.686 109.555 175.21 92.791 183.52 74.2694C191.83 55.7478 199.341 38.9862 204.917 26.8819C207.695 20.8282 209.985 15.9392 211.619 12.5749C212.43 10.8897 213.075 9.58653 213.526 8.70819C213.713 8.24614 213.972 7.80318 214.298 7.38623C214.208 7.86627 214.051 8.33485 213.828 8.7857C213.468 9.69134 212.908 11.0209 212.172 12.7221C210.697 16.1328 208.511 21.0469 205.763 27.1097C200.267 39.2352 192.528 55.9436 183.943 74.3833Z"
        fill="#D2E9E6"
      />
      <Path
        d="M190.169 76.4589L157.104 120.016C152.92 125.595 149.532 130.118 147.188 133.254L144.535 136.871C144.247 137.281 144.024 137.593 143.864 137.817C143.781 137.923 143.712 138.035 143.656 138.15C143.762 138.055 143.853 137.953 143.929 137.845L144.655 136.919C145.297 136.088 146.213 134.884 147.397 133.339C149.755 130.2 153.148 125.682 157.332 120.104L189.774 76.3021C202.412 59.1964 213.9 43.7226 222.32 32.5642C226.529 26.9851 229.965 22.4783 232.379 19.383C233.59 17.8337 234.538 16.634 235.201 15.8277C235.5 15.4025 235.863 15.0021 236.284 14.632C236.077 15.0956 235.803 15.5402 235.466 15.9596C234.892 16.8015 234.023 18.0307 232.891 19.6053C230.614 22.7608 227.272 27.3125 223.1 32.8976C214.77 44.0615 203.101 59.4682 190.169 76.4589Z"
        fill="#D2E9E6"
      />
      <Path
        d="M195.932 79.1446C179 94.2768 163.661 107.98 152.576 117.911C147.07 122.887 142.617 126.916 139.526 129.711L136.019 132.939L135.122 133.786C135.012 133.878 134.916 133.978 134.837 134.085C134.963 134.003 135.078 133.913 135.181 133.817C135.406 133.625 135.718 133.352 136.125 132.995C136.96 132.267 138.172 131.169 139.713 129.809L152.774 118.02C163.738 108.014 178.859 94.2031 195.564 78.9449C212.27 63.6867 227.388 49.8885 238.48 39.9542C243.986 34.9785 248.505 30.9731 251.652 28.2251C253.216 26.8475 254.46 25.7846 255.312 25.0724C255.7 24.6897 256.147 24.337 256.644 24.0191C256.333 24.4417 255.961 24.8391 255.531 25.2057C254.74 25.9403 253.603 27.0501 252.095 28.4709C249.076 31.2906 244.657 35.3419 239.162 40.3321C228.177 50.3091 212.869 64.0217 195.932 79.1446Z"
        fill="#D2E9E6"
      />
      <Path
        d="M201.086 82.366L148.577 115.422L132.746 125.499L128.472 128.263L127.379 128.989C127.249 129.07 127.128 129.157 127.017 129.25C127.164 129.185 127.3 129.11 127.423 129.026L128.565 128.347L132.907 125.632L148.749 115.565L200.76 82.1514C221.042 69.0975 239.44 57.3104 252.845 48.8185C259.54 44.5585 265.002 41.1482 268.796 38.82C270.693 37.6538 272.172 36.774 273.2 36.1497C273.688 35.8204 274.228 35.5285 274.813 35.2781C274.413 35.6514 273.956 35.9938 273.448 36.3004C272.498 36.9542 271.078 37.899 269.236 39.1084C265.527 41.5316 260.18 44.9857 253.508 49.2579C240.13 57.7756 221.601 69.4733 201.086 82.366Z"
        fill="#D2E9E6"
      />
      <Path
        d="M205.516 86.0405L145.175 112.626L126.962 120.727L122.037 122.958C121.495 123.217 121.069 123.412 120.769 123.554C120.618 123.614 120.478 123.685 120.352 123.767C120.512 123.716 120.664 123.657 120.807 123.59L122.119 123.036L127.073 120.852L145.3 112.745L205.219 85.7789C228.622 75.2481 249.783 65.7304 265.198 58.9038C272.902 55.49 279.156 52.7458 283.505 50.8787C285.682 49.9326 287.379 49.2246 288.54 48.7458C289.109 48.4797 289.719 48.2522 290.361 48.0659C289.865 48.391 289.319 48.6791 288.73 48.9261C287.624 49.4565 285.989 50.2129 283.853 51.2085C279.59 53.1663 273.402 55.9683 265.726 59.4037C250.391 66.2815 229.078 75.6851 205.516 86.0405Z"
        fill="#D2E9E6"
      />
      <Path
        d="M209.117 90.0871L142.422 109.517L122.261 115.476L116.803 117.134L115.392 117.573C115.225 117.618 115.067 117.673 114.92 117.74C115.092 117.706 115.26 117.665 115.422 117.617L116.865 117.217L122.349 115.623L142.514 109.673L208.881 89.8136C234.775 82.0462 258.238 75.0389 275.282 70.0411C283.808 67.5385 290.716 65.5374 295.514 64.1791C297.916 63.5005 299.782 62.9823 301.064 62.6429C301.694 62.4415 302.359 62.2857 303.049 62.1778C302.477 62.4329 301.868 62.6503 301.228 62.8276C299.999 63.2269 298.173 63.8029 295.813 64.5311C291.087 65.9824 284.223 68.0507 275.719 70.574C258.715 75.6338 235.157 82.4974 209.117 90.0871Z"
        fill="#D2E9E6"
      />
      <Path
        d="M211.788 94.3954L140.323 106.175L118.713 109.818L112.851 110.841L111.349 111.12C111.171 111.147 110.999 111.184 110.835 111.233C111.018 111.22 111.197 111.198 111.372 111.167L112.904 110.931L118.799 109.974L140.42 106.341L211.633 94.1004C239.426 89.3127 264.605 85.0027 282.872 81.9587C292 80.4316 299.404 79.2258 304.539 78.4189C307.1 78.0145 309.099 77.7105 310.455 77.516C311.136 77.388 311.84 77.3061 312.558 77.2714C311.924 77.4677 311.255 77.6186 310.563 77.722C309.221 77.9746 307.268 78.3417 304.729 78.7969C299.65 79.7156 292.279 80.9824 283.159 82.5323C264.932 85.6384 239.683 89.8127 211.788 94.3954Z"
        fill="#D2E9E6"
      />
      <Path
        d="M213.47 98.8701L139.008 102.66L116.468 103.884L110.347 104.252L108.756 104.358C108.574 104.364 108.395 104.382 108.222 104.412C108.407 104.419 108.591 104.416 108.773 104.404L110.368 104.342L116.508 104.041L139.059 102.827L213.393 98.5702C242.401 96.8949 268.677 95.4039 287.723 94.4028C297.243 93.8997 304.956 93.5201 310.294 93.2865C312.961 93.1651 315.038 93.084 316.448 93.0439C317.161 92.9921 317.886 92.9884 318.615 93.0328C317.929 93.151 317.222 93.2252 316.503 93.2545C315.112 93.362 313.048 93.5054 310.398 93.6766C305.084 94.0171 297.396 94.4693 287.877 94.9942C268.852 96.0544 242.539 97.4063 213.47 98.8701Z"
        fill="#D2E9E6"
      />
      <Path
        d="M214.113 103.4L138.496 99.104L115.586 97.8773L109.359 97.5757L107.737 97.5094C107.553 97.4967 107.37 97.4952 107.189 97.5047C107.372 97.5325 107.556 97.5501 107.74 97.5572L109.36 97.6661L115.588 98.0322L138.502 99.2682L214.102 103.098C243.61 104.577 270.332 105.936 289.678 107.014C299.349 107.548 307.18 108.004 312.592 108.349C315.298 108.517 317.399 108.676 318.827 108.772C319.553 108.796 320.281 108.871 321 108.994C320.285 109.037 319.559 109.034 318.831 108.983C317.412 108.94 315.308 108.858 312.605 108.738C307.199 108.497 299.366 108.114 289.697 107.606C270.35 106.596 243.625 105.091 214.113 103.4Z"
        fill="#D2E9E6"
      />
      <Path
        d="M156.154 48.2001C166.202 52.1996 172.045 63.2341 173.71 68.2514C179.325 53.9253 187.644 49.3111 199.223 48.1611C210.801 47.0111 228.019 56.7907 226.374 74.354C225.058 88.4046 208.824 111.226 200.871 120.88L169.342 158.95C162.472 151.938 145.27 133.693 131.425 116.805C114.119 95.6947 112.353 77.2616 120.479 60.3713C128.605 43.481 143.594 43.2007 156.154 48.2001Z"
        fill="#9FBC2A"
      />
      <Path
        opacity="0.7"
        d="M200.772 116.675C195.364 123.887 180.772 140.176 168.772 152.676L136.499 116.675C127.998 106.676 160.901 140.821 174.272 135.677C193.772 128.176 217.548 91.1563 215.773 94.1746C210.773 102.676 206.772 108.675 200.772 116.675Z"
        fill="#527134"
      />
      <Ellipse
        opacity="0.5"
        cx="125.265"
        cy="97.8635"
        rx="1.5"
        ry="2.5"
        transform="rotate(-20 125.265 97.8635)"
        fill="#EDF3C2"
      />
      <Path
        opacity="0.5"
        d="M132.695 49.1497C142.437 44.5674 154.129 48.1314 159 52.2046C147.199 46.9115 133.215 48.7257 125.5 60C118.536 70.1784 120.5 86.5 121.978 92.9342C122.619 95.7241 116.553 83.7286 118.501 71C118.962 67.9835 122.952 53.732 132.695 49.1497Z"
        fill="#EDF3C2"
      />
      <Path
        d="M205.003 52.8955C203.606 51.2994 194.189 52.1775 187.277 55.7271C182.089 58.3912 176.372 69.0323 177.469 67.6018C178.566 66.1713 180.327 62.2815 187.144 57.723C193.961 53.1644 206.401 54.4915 205.003 52.8955Z"
        fill="#B1CA86"
      />
      <Path
        d="M85.1992 41.3269C97.3513 35.9084 99.7776 22.9997 99.4718 17.2227C101.592 34.753 107.967 41.0613 110.89 42.0242C100.614 43.6178 97.229 54.2423 96.8212 59.3553C95.5978 42.9405 88.5635 40.4969 85.1992 41.3269Z"
        fill="#D2E9E6"
      />
      <Path
        d="M233.791 74.0574C245.943 68.6389 248.369 55.7302 248.064 49.9531C250.184 67.4835 256.559 73.7918 259.482 74.7546C249.205 76.3483 245.821 86.9728 245.413 92.0858C244.19 75.671 237.155 73.2274 233.791 74.0574Z"
        fill="#D2E9E6"
      />
      <Path
        d="M108.667 268.288C79.0226 271.709 62.6268 256.667 58.1345 248.718C65.7034 241.793 91.994 215.827 105.628 202.361L105.878 202.114C119.438 188.721 134.736 176.527 145.577 174.455C162.994 171.126 182.741 171.351 191.619 169.395C200.496 167.438 207.577 155.168 211.864 150.37C216.151 145.571 219.695 143.444 223.223 143.697C226.046 143.899 227.281 145.063 227.545 145.62C230.552 141.656 235.463 131.347 241.215 130.516C245.816 129.85 248.878 131.355 249.833 132.191C254.298 124.178 257.545 122.426 262.798 123.014C267.979 123.594 266.788 126.73 266.314 127.976L266.295 128.026C265.837 129.237 262.624 137.71 261.585 140.757C260.753 143.194 256.127 155.797 253.918 161.793C255.186 159.874 259.61 154.365 267.169 147.69C276.617 139.346 281.129 139.141 284.374 140.854C287.619 142.568 284.949 145.948 282.024 149.495C279.683 152.332 266.25 174.66 259.826 185.469C267.517 185.014 273.078 186.312 277.575 188.485C282.072 190.659 279.752 194.54 276.667 195.457C273.582 196.373 264.531 202.034 259.585 205.621C254.639 209.209 250.648 213.205 245.852 214.872C241.056 216.539 227.93 219.742 216.127 225.245C196.337 234.473 183.642 234.966 161.977 231.193C154.024 229.808 144.792 240.195 138.466 245.577C126.97 255.356 114.011 261.889 108.667 268.288Z"
        fill="#E29D7D"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M227.169 146.325C224.44 152.974 215.39 168.302 200.881 176.571L200.616 176.722L200.311 176.678C198.895 176.473 196.697 176.269 194.721 176.318C193.73 176.343 192.832 176.431 192.13 176.599C191.386 176.776 191.041 177.003 190.924 177.162C191.105 175.494 195.753 175.247 200.323 175.704C216.353 166.323 219.803 155.703 227.169 146.325Z"
        fill="#B96946"
      />
      <Path
        d="M265.948 195.583C265.074 197.319 265.708 199.737 266.511 200.976C270.042 198.919 278.197 195.148 279.566 194.548C281.277 193.797 282.639 192.546 281.549 191.251C280.459 189.956 278.991 189.454 276.93 189.703C274.869 189.953 267.041 193.413 265.948 195.583Z"
        fill="#FFC6A7"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M259.859 185.905C254.42 187.808 242.115 192.358 234.31 194.665C233.516 194.9 232.738 195.135 231.97 195.367C228.086 196.542 224.483 197.632 220.531 198.11C215.76 198.687 210.496 198.376 203.512 196.359C196.639 194.374 186.557 191.607 175.944 190.217C165.312 188.824 154.26 188.828 145.395 192.289C141.325 193.878 152.358 185.728 177.281 188.993C188.037 190.403 201.043 194.47 207.931 196.46C214.71 198.417 219.302 197.689 223.297 196.81C227.292 195.932 231.414 194.253 235.296 193.08C236.072 192.846 240.194 191.242 241.013 191C255.875 185.814 263.534 184.618 259.859 185.905Z"
        fill="#B96946"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M253.883 162.007C248.054 167.446 234.647 181.05 228.024 188.559L227.684 188.944L227.171 188.831C224.422 188.227 217.399 187.698 211.547 190.389C211.544 190.391 216.595 186.022 227.02 187.066C247.001 166.27 250.546 164.375 253.883 162.007Z"
        fill="#B96946"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M249.679 132.624C247.929 136.051 245.403 141.967 242.321 147.217C239.253 152.441 235.98 157.54 233.532 160.186C228.796 165.305 219.031 177.03 214.731 182.264L214.361 182.715L213.79 182.541C211.982 181.991 206.703 181.281 200.787 183.156C200.031 183.396 204.147 179.66 214.375 181.551C218.833 176.133 222.435 170.84 227.083 165.816C229.39 163.322 232.715 159.866 236.32 154.975C241.528 147.91 247.959 133.69 249.679 132.624Z"
        fill="#B96946"
      />
      <Path
        d="M117.1 228.681C104.436 216.355 101.913 207.712 102.235 204.931C101.101 202.241 94.7601 210.247 87.1592 217.638C86.7935 216.421 84.5256 214.598 79.8019 215.118C75.0782 215.638 71.889 221.483 69.0954 225.406C64.621 224.536 61.3386 225.177 49.7559 232.33C40.4897 238.052 40.6763 242.799 42.4381 246.088C52.7497 262.006 83.6646 273.513 103.594 278.885C110.504 280.748 118.893 280.64 122.34 274.552C125.527 268.921 122.489 263.335 124.947 261.138C132.105 254.736 137.918 247.464 140.482 245.171C143.046 242.878 143.107 242.111 142.817 242.014C139.522 242.705 129.764 241.007 117.1 228.681Z"
        fill="#EDF3C2"
      />
      <Path
        d="M76.6277 266.201C76.4881 266.211 76.5874 262.96 76.5065 258.961C76.4257 254.961 76.2254 251.732 76.3602 251.71C76.5538 252.379 76.6661 253.077 76.6947 253.788C76.8313 255.089 76.9512 256.9 76.9819 258.912C77.0126 260.924 76.9724 262.746 76.8876 264.067C76.8914 264.781 76.8039 265.499 76.6277 266.201Z"
        fill="#75A14A"
      />
      <Path
        d="M106.435 263.58C103.947 264.223 101.407 264.697 98.8333 264.999C96.2952 265.473 93.7189 265.784 91.1217 265.928C93.6168 265.3 96.1589 264.828 98.7325 264.513C101.268 264.039 103.841 263.727 106.435 263.58Z"
        fill="#75A14A"
      />
      <Path
        d="M99.5765 250.084C99.7299 248.374 100.109 246.724 100.709 245.156C101.105 243.511 101.724 241.942 102.558 240.47C102.42 242.188 102.039 243.842 101.422 245.407C101.035 247.052 100.417 248.618 99.5765 250.084Z"
        fill="#75A14A"
      />
      <Path
        d="M130.284 256.448C128.06 253.537 126.104 250.358 124.442 246.949C125.621 248.335 126.671 249.862 127.579 251.509C128.625 253.033 129.531 254.686 130.284 256.448Z"
        fill="#75A14A"
      />
      <Path
        d="M59.3754 252.2C60.1149 253.793 60.7226 255.415 61.1936 257.053C61.843 258.655 62.3577 260.282 62.7333 261.917C61.8097 260.377 61.1064 258.767 60.641 257.128C59.9721 255.511 59.5465 253.853 59.3754 252.2Z"
        fill="#75A14A"
      />
      <Path
        d="M53.3113 247.175C50.6741 248.377 47.9008 249.292 45.0406 249.905C46.3235 249.232 47.6832 248.7 49.095 248.319C50.4502 247.793 51.8636 247.409 53.3113 247.175Z"
        fill="#75A14A"
      />
      <Path
        d="M64.5643 238.039C62.5005 234.6 61.1467 230.806 60.5816 226.878C60.7224 226.855 61.3724 229.418 62.4776 232.495C63.5828 235.572 64.6977 237.983 64.5643 238.039Z"
        fill="#75A14A"
      />
      <Path
        d="M85.6352 242.122C83.6932 242.213 81.7443 242.136 79.8114 241.89C77.8692 241.81 75.9358 241.559 74.0342 241.14C75.9764 241.056 77.9246 241.134 79.8581 241.371C81.8001 241.454 83.7334 241.705 85.6352 242.122Z"
        fill="#75A14A"
      />
      <Path
        d="M111.949 233.42C113.928 232.016 115.997 230.749 118.141 229.628C118.337 229.508 118.531 229.987 118.373 230.07C116.307 231.333 114.16 232.452 111.949 233.42Z"
        fill="#75A14A"
      />
      <Path
        d="M98.5403 216.65C97.0793 214.713 95.7528 212.682 94.5702 210.572C94.4445 210.378 94.9185 210.171 95.0053 210.326C96.3275 212.355 97.5086 214.469 98.5403 216.65Z"
        fill="#75A14A"
      />
      <Path
        d="M109.051 272.303C108.533 274.674 107.856 277.003 107.026 279.275C106.961 279.496 106.494 279.272 106.556 279.105C107.234 276.78 108.068 274.507 109.051 272.303Z"
        fill="#75A14A"
      />
      <Path
        d="M95.2829 234.948C95.2497 233.418 95.3822 231.909 95.6781 230.445C95.8076 228.935 96.1006 227.461 96.5523 226.047C96.586 227.578 96.4562 229.089 96.1651 230.556C96.0378 232.066 95.7421 233.538 95.2829 234.948Z"
        fill="#75A14A"
      />
      <Path
        d="M113.576 247.756C114.823 246.738 116.15 245.838 117.537 245.067C118.86 244.165 120.252 243.394 121.692 242.766C120.448 243.787 119.123 244.689 117.737 245.46C116.415 246.366 115.02 247.135 113.576 247.756Z"
        fill="#75A14A"
      />
      <Path
        d="M86.5754 222.529C84.9244 223.519 83.1903 224.352 81.3969 225.015C79.6605 225.851 77.8583 226.526 76.0135 227.03C77.6725 226.051 79.4082 225.221 81.1997 224.549C82.9339 223.713 84.7333 223.037 86.5754 222.529Z"
        fill="#75A14A"
      />
      <Path
        d="M70.5629 226.816C70.6081 226.847 70.6497 226.882 70.687 226.922L71.021 227.249L72.2803 228.522C72.8164 229.081 73.4859 229.76 74.3013 230.526C75.1167 231.291 75.9789 232.189 77.0083 233.124C78.0377 234.06 79.1415 235.109 80.3799 236.179C81.6183 237.248 82.963 238.391 84.3603 239.574C87.2203 241.881 90.4289 244.378 93.9197 246.82C97.4105 249.261 100.862 251.409 104.053 253.284C105.686 254.186 107.179 255.06 108.634 255.85C110.09 256.641 111.442 257.342 112.694 257.97C113.946 258.599 115.093 259.143 116.097 259.624C117.1 260.105 117.968 260.508 118.684 260.822L120.345 261.577L120.771 261.776L120.912 261.856C120.858 261.855 120.805 261.844 120.755 261.826L120.318 261.653L118.646 260.955C117.921 260.656 117.037 260.274 116.027 259.797C115.017 259.321 113.86 258.793 112.605 258.181C111.35 257.569 109.971 256.881 108.52 256.097C107.07 255.313 105.53 254.459 103.911 253.549C100.713 251.678 97.2582 249.518 93.7478 247.084C90.2374 244.65 87.0346 242.154 84.1822 239.807C82.7744 238.617 81.421 237.519 80.2189 236.387C79.0168 235.254 77.8778 234.262 76.8666 233.295C75.8553 232.328 74.9725 231.444 74.1836 230.666C73.3947 229.888 72.7415 229.218 72.2178 228.626L70.9963 227.315L70.6899 226.969L70.5629 226.816Z"
        fill="#527134"
      />
      <Path
        d="M119.777 261.431C110.092 260.781 100.74 257.583 92.7262 252.182C84.7123 246.781 78.3387 239.38 74.2896 230.774C88.1234 243.282 102.443 254.594 119.777 261.431Z"
        fill="#4E8419"
        fillOpacity="0.32"
      />
    </Svg>
  );
};

export default HandWithHeartIllustration;
