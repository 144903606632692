import { BaseButton, RedX, Large, View } from '@talkspace/react-toolkit';
import styled from '../../../core/styled/styled';
import { ssoRetry } from './ssoRetryTracker';

const BackButton = styled(BaseButton)({ marginTop: 40, marginBottom: 60 });

const SSOCallbackError = () => (
  <View
    align="center"
    style={{
      textAlign: 'center',
      marginTop: 55,
    }}
  >
    <RedX />
    <Large
      style={{
        marginTop: 25,
      }}
      variant="largeDarkGrey"
    >
      There was an error logging in.
      <br />
      Please try again later.
    </Large>
    <BackButton onPress={ssoRetry}>
      <Large variant="largeBoldWideGreen">Go back</Large>
    </BackButton>
  </View>
);

export default SSOCallbackError;
