import SocketService from '../../utils/socket/SocketService';
import UserEventsSocketService from './UserEventsSocketService';
import ApiHelper, { UserSettings } from './ApiHelper';

type Listener = (data: UserSettings) => void;

let instance: UserSettingsSocketService | undefined;

export default class UserSettingsSocketService {
  io!: SocketService;

  userEventsSocketService!: UserEventsSocketService | undefined;

  apiHelper!: ApiHelper;

  userSettingsListeners: Listener[] = [];

  lastUserSettings!: UserSettings;

  constructor() {
    if (instance) {
      return instance;
    }
    instance = this;

    this.io = SocketService.instance();
    this.apiHelper = new ApiHelper();
    this.socketInit();
  }

  static instance(): UserSettingsSocketService {
    return new UserSettingsSocketService();
  }

  /**
   * Register callback to get current and future user settings,
   * the callback will be called immediately with current state if exists
   * @param  {Function} fn callbacks with UserSettings
   * @returns void
   */
  public addListener = (fn: Listener): void => {
    if (typeof fn !== 'function') return;
    this.userSettingsListeners.push(fn);
    if (this.lastUserSettings) fn(this.lastUserSettings);
  };

  public removeListener = (fn: Listener): void => {
    if (typeof fn !== 'function') return;
    const idx = this.userSettingsListeners.indexOf(fn);
    if (idx > -1) this.userSettingsListeners.splice(idx, 1);
  };

  private getUserSettings = async () => {
    const settings = await this.apiHelper.getUserSettings();
    this.notifyListener(settings);
  };

  private socketInit = () => {
    this.io.on('userSettings', this.notifyListener);
    this.userEventsSocketService = new UserEventsSocketService();
    this.userEventsSocketService.addListener(this.userRegisterStateChange);
  };

  /**
   * this should be called when user logs out.
   */
  public destroy = () => {
    this.io.off('userSettings', this.notifyListener);
    if (this.userEventsSocketService) {
      this.userEventsSocketService.removeListener(this.userRegisterStateChange);
      this.userEventsSocketService.unmount(true);
    }
    this.userSettingsListeners = [];
    this.userEventsSocketService = undefined;
    instance = undefined;
  };

  private notifyListener = (data) => {
    this.lastUserSettings = { ...this.lastUserSettings, ...data };
    this.userSettingsListeners.forEach((fn) => fn(this.lastUserSettings));
  };

  private userRegisterStateChange = (state: boolean) => {
    if (state) {
      // eslint-disable-next-line no-console
      this.getUserSettings().catch((err) => console.error(err));
    }
  };
}
