import { FunctionComponent } from 'react';
import { View, Large, Spinner as ToolkitSpinner, COLORS } from '@talkspace/react-toolkit';

interface Props {
  text?: string;
}

const Spinner: FunctionComponent<Props> = ({ text }) => (
  <View style={{ height: 70 }}>
    <ToolkitSpinner
      isLoading
      primaryColor={COLORS.baliHaiGrey}
      containerStyle={{
        height: 70,
        transform: 'scale(2)',
        marginBottom: 20,
      }}
    />
    {text && (
      <Large variant="largeLightGrey" style={{ marginTop: 20, textAlign: 'center' }}>
        {text}
      </Large>
    )}
  </View>
);

export default Spinner;
