import { WizardScheme } from 'stepWizard';
import { TFAdultOnboarding } from '@talkspace/i18n/types';

const medicalHealthScheme: (tAdultOnboarding: TFAdultOnboarding) => WizardScheme = (
  tAdultOnboarding
) => {
  return {
    wizardModalTitle: 'medical intake',
    wizardVersion: 1,
    wizardType: 'medicalIntake',
    disablePersist: true,
    steps: [
      {
        title: tAdultOnboarding(
          'currentTreatment.title',
          'Are you currently being treated or have you ever been treated for any of the following medical issues?'
        ),
        inputType: 'searchableMultiSelect',
        inputState: 'medicalIssues',
        inputTypePlaceholder: tAdultOnboarding('currentTreatment.select', 'Select'),
        nextButton: {
          displayText: tAdultOnboarding('currentTreatment.next', 'Next'),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        secondaryButton: {
          displayText: tAdultOnboarding('currentTreatment.skip', 'Skip'),
          actionDispatch: 'next',
          whenVisible: 'always',
        },
        inputOptions: 'medicalIssuesOptions',
        name: 'medicalIssues',
      },
      {
        title: tAdultOnboarding('chronic.title', 'Are you experiencing chronic pain?'),
        inputType: 'select',
        inputTypePlaceholder: tAdultOnboarding('chronic.select', 'Select'),
        inputState: 'hasChronicPain',
        nextButton: {
          displayText: tAdultOnboarding('chronic.next', 'Next'),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'hasChronicPainOptions',
        name: 'hasChronicPain',
        postAction: 'setSelectedHasChronicPain',
        backOption: 'back',
      },
      {
        title: tAdultOnboarding('pregnant.title', 'Are you currently pregnant?'),
        inputType: 'select',
        inputTypePlaceholder: tAdultOnboarding('pregnant.select', 'Select'),
        inputState: 'isPregnant',
        nextButton: {
          displayText: tAdultOnboarding('pregnant.next', 'Next'),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        inputOptions: 'isPregnantOptions',
        postAction: 'setSelectedIsPregnant',
        name: 'isPregnant',
        backOption: 'back',
        displayCondition: 'showPregnancyQuestion',
      },
      {
        title: tAdultOnboarding(
          'medication.title',
          'Are you currently taking any of the following psychiatric medications?'
        ),
        inputType: 'creatableSearchableMultiSelect',
        inputState: 'medications',
        inputTypePlaceholder: tAdultOnboarding('medication.select', 'Select'),
        nextButton: {
          displayText: tAdultOnboarding('medication.next', 'Next'),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        secondaryButton: {
          displayText: tAdultOnboarding('medication.skip', 'Skip'),
          actionDispatch: 'next',
          whenVisible: 'always',
        },
        inputOptions: 'medicationsOptions',
        name: 'medications',
        backOption: 'back',
      },
      {
        title: tAdultOnboarding(
          'list.title',
          "List any over-the-counter medications, supplements, vitamins, or herbs you're currently taking"
        ),
        inputType: 'multilineText',
        inputState: 'otcMedications',
        inputTypePlaceholder: tAdultOnboarding(
          'list.description',
          'Over-the-counter medication...'
        ),
        nextButton: {
          displayText: tAdultOnboarding('list.next', 'Next'),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        secondaryButton: {
          displayText: tAdultOnboarding('list.skip', 'Skip'),
          actionDispatch: 'next',
          whenVisible: 'always',
        },
        name: 'otcMedications',
        backOption: 'back',
      },
      {
        title: tAdultOnboarding(
          'pharmacyAddress.title',
          'Please provide your preferred pharmacy address'
        ),
        inputType: 'address',
        inputState: 'pharmacyAddress',
        nextButton: {
          displayText: tAdultOnboarding('pharmacyAddress.next', 'Next'),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        secondaryButton: {
          displayText: tAdultOnboarding('pharmacyAddress.skip', 'Skip'),
          actionDispatch: 'next',
          whenVisible: 'always',
        },
        name: 'pharmacyAddress',
        backOption: 'back',
      },
      {
        title: tAdultOnboarding(
          'drugAllergies.title',
          'Please describe any drug allergies you have and your reaction(s) to them'
        ),
        inputType: 'multilineText',
        inputState: 'drugAllergies',
        inputTypePlaceholder: tAdultOnboarding('drugAllergies.description', 'Drug allergies...'),
        nextButton: {
          displayText: tAdultOnboarding('drugAllergies.next', 'Next'),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        secondaryButton: {
          displayText: tAdultOnboarding('drugAllergies.skip', 'Skip'),
          actionDispatch: 'next',
          whenVisible: 'always',
        },
        name: 'drugAllergies',
        backOption: 'back',
      },
      {
        title: tAdultOnboarding(
          'previousSubstance.title',
          'Do you currently use or have you previously used any of the following controlled substances?'
        ),
        inputType: 'searchableMultiSelect',
        inputState: 'controlledSubstances',
        inputTypePlaceholder: tAdultOnboarding('previousSubstance.select', 'Select'),
        nextButton: {
          displayText: tAdultOnboarding('previousSubstance.submit', 'Submit'),
          actionDispatch: (_, wizardContext) => {
            if (wizardContext.source === 'onboarding') {
              return `/room/${wizardContext.roomID}/onboarding/medical-health/source/onboarding/submit`;
            }
            return '/medical-health-wizard/submit';
          },
          whenVisible: 'onSelect',
        },
        secondaryButton: {
          displayText: tAdultOnboarding(
            'previousSubstance.10',
            'I don’t use any controlled substances'
          ),
          actionDispatch: (_, wizardContext) => {
            if (wizardContext.source === 'onboarding') {
              return `/room/${wizardContext.roomID}/onboarding/medical-health/source/onboarding/submit`;
            }
            return '/medical-health-wizard/submit';
          },
          whenVisible: 'always',
        },
        inputOptions: 'controlledSubstancesOptions',
        name: 'controlledSubstances',
        backOption: 'back',
      },
    ],
  };
};

export default medicalHealthScheme;
