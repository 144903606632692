import moment from 'moment';
import { InformedConsentAPIResponse, InformedConsentV2StatusAPI } from 'ts-frontend/types';

// eslint-disable-next-line import/prefer-default-export
export const getAgreedDate = (
  informedConsentV1?: InformedConsentAPIResponse,
  informedConsentV2?: InformedConsentV2StatusAPI
) => {
  const { clickOkDate } = informedConsentV1 || {};
  const { agreedAtRegistration, clickOKDate: clickOKDateV2 } = informedConsentV2 || {};
  const format = 'MMMM D YYYY';
  if (informedConsentV2?.hasV2) {
    const momentToReturn =
      informedConsentV2.agreedAtRegistration && informedConsentV2.clickOKDate
        ? moment.max([moment(agreedAtRegistration), moment(clickOKDateV2)])
        : moment(informedConsentV2.agreedAtRegistration || informedConsentV2.clickOKDate);
    return momentToReturn.format(format);
  }
  return moment(clickOkDate).format(format);
};
