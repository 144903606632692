import * as React from 'react';
import { RoomStatus } from 'ts-frontend/entities/Room';
import { SharedChatContextProvider } from 'chat/hooks/sharedChatContext';
import { TherapistType } from 'chat/redux/constants/chatTypes';
import { NativeFeaturesEnum, shouldShowNativeFeature } from 'ts-ionic';
import IntakeAction from './components/IntakeIframeActions';
import { Route } from '../core/routerLib/routerLib';
import InformedConsentAction from '../components/InformedConsentAction/InformedConsentAction';
import SSSNoMatchesAction from './components/SSSNoMatchesAction/SSSNoMatchesAction';
import SharingPreferencesAction from './components/SharingPreferencesAction';
import ClientChatScreen from './screens/ClientChatScreen';

const ClientRoutes: React.FunctionComponent<{
  therapistType: TherapistType;
  therapistFirstName: string;
  roomStatus: RoomStatus;
  isChatHidden?: boolean;
  hasSeenWelcomeBackInactivityTray?: boolean;
  hasSeenWelcomeBackActivityTray?: boolean;
  applyReactivationTray?: () => void;
}> = ({
  therapistFirstName,
  therapistType,
  isChatHidden,
  roomStatus,
  hasSeenWelcomeBackInactivityTray = false,
  hasSeenWelcomeBackActivityTray = false,
  applyReactivationTray,
}) => (
  <SharedChatContextProvider
    isTherapistChat={false}
    therapistFirstName={therapistFirstName}
    therapistType={therapistType}
    isChatHidden={isChatHidden}
    roomStatus={roomStatus}
    hasSeenWelcomeBackInactivityTray={hasSeenWelcomeBackInactivityTray}
    hasSeenWelcomeBackActivityTray={hasSeenWelcomeBackActivityTray}
    applyReactivationTray={applyReactivationTray}
    isMobileApp={shouldShowNativeFeature(NativeFeaturesEnum.MEDIA)}
  >
    <Route path="/room/:roomID" component={ClientChatScreen} />
    <Route
      path={['/room/:roomID/modal/sss-no-matches', '/room/:roomID/*/modal/sss-no-matches']}
      component={SSSNoMatchesAction}
    />
    <Route
      path={['/room/:roomID/modal/intake', '/room/:roomID/*/modal/intake']}
      component={IntakeAction}
    />
    <Route
      path={['/room/:roomID/modal/informed-consent', '/room/:roomID/*/modal/informed-consent']}
      component={InformedConsentAction}
    />
    <Route
      path={[
        '/room/:roomID/modal/sharing-preferences',
        '/room/:roomID/*/modal/sharing-preferences',
      ]}
      component={SharingPreferencesAction}
    />
  </SharedChatContextProvider>
);

export default ClientRoutes;
