import { FunctionComponent, useCallback, useEffect } from 'react';
import { Big, Button, ExtraHuge, Large, useEmotionTheme } from '@talkspace/react-toolkit';
import { persistData, useWizardState, useWizardActions, WizardActionStatus } from 'stepWizard';
import moment from 'moment';
import { FirstPurchase } from 'ts-frontend/types';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { RouteComponentProps, withRouter } from '../../core/routerLib/routerLib';
import useCancellationWizard from '../hooks/useCancellationWizard';

interface ShouldShowRefundButtonParams {
  cancellationReason: string;
  planModality: string;
  firstPurchase?: FirstPurchase;
  didLiveSession?: boolean;
}

const shouldShowRefundButton = ({
  cancellationReason,
  planModality,
  firstPurchase,
  didLiveSession,
}: ShouldShowRefundButtonParams) => {
  const twoWeekAgo = moment().subtract(2, 'weeks');
  const firstTransactionWithinTwoWeeks = moment(firstPurchase?.conversionDate).isAfter(twoWeekAgo);
  return (
    !didLiveSession &&
    planModality === 'liveOnly' &&
    firstTransactionWithinTwoWeeks &&
    ['provider_not_responsive', 'low_availability'].includes(cancellationReason)
  );
};

const CancelSubscriptionContainer: FunctionComponent<
  RouteComponentProps & { isNavigatingBack: boolean }
> = ({ location, isNavigatingBack, history }) => {
  const {
    roomID,
    isVerifyExitModalOpen,
    responses,
    flow,
    cancellationReason,
    planModality,
    firstPurchase,
    didLiveSession,
    contextID,
  } = useWizardState();
  const { colors } = useEmotionTheme();
  const closeModal = useCloseModal();
  const [state, { cancelSubscription, exitAction }] = useCancellationWizard();
  const { setState } = useWizardActions();
  const { error, isSuccess, endOfBillingCycleDate } = state;
  const lastStepName = 'Cancel subscription';

  const handlePersist = useCallback(() => {
    persistData(
      responses,
      setState,
      lastStepName,
      error,
      { canceledSubscription: true },
      isSuccess
    );
  }, [error, isSuccess, responses, setState]);

  const handleExitModal = () => {
    exitAction();
    closeModal();
  };

  useEffect(() => {
    if (isVerifyExitModalOpen) {
      handleExitModal();
    }
  }, [isVerifyExitModalOpen]); // eslint-disable-line

  useEffect(() => {
    // persisting the data on error or success
    const handleRequestStatus = async () => {
      if (isSuccess || error) {
        handlePersist();
      }
    };
    handleRequestStatus();
  }, [isSuccess, error, location]); // eslint-disable-line

  useEffect(() => {
    if (!roomID || isNavigatingBack) {
      closeModal();
      return;
    }
    const clientMetGoals = flow === 'feelingBetter';
    cancelSubscription(roomID, { clientMetGoals, cancellationReason });
  }, [roomID]); // eslint-disable-line

  const successTitle = flow ? (
    <ExtraHuge>Your subscription cancellation has been processed.</ExtraHuge>
  ) : (
    'Your subscription auto-renewal has been cancelled'
  );

  const openRefundPage = () => {
    history.push(`/refund-purchase/source/cancellation/context-id/${contextID}`);
  };

  const successSubTitle = flow ? (
    <>
      Your subscription is set to end on
      <br />
      {endOfBillingCycleDate}.
      <br />
      <br />
      We wish you the very best. If you decide you would like to try us again, simply return to{' '}
      <Large inline variant="largeBoldWideGreen">
        Payment and plan
      </Large>{' '}
      and resubscribe.
    </>
  ) : (
    <>
      The subscription will end on {endOfBillingCycleDate}
      <br />
      {shouldShowRefundButton({
        planModality,
        cancellationReason,
        firstPurchase,
        didLiveSession,
      }) && (
        <Button
          onPress={openRefundPage}
          style={{ width: 320 }}
          dataQa="CancelSubscriptionRefundButton"
          roundedFocusStyle
          primaryColor={colors.permaTalkspaceDarkGreen}
        >
          <Big variant="bigWide">Check refund eligibility</Big>
        </Button>
      )}
    </>
  );

  return (
    <WizardActionStatus
      successCheckMarkExist={Boolean(flow)}
      successTitle={successTitle}
      successSubTitle={successSubTitle}
      successButtonText="Close"
      handleAction={handleExitModal}
      buttonStyles={{
        padding: error ? 0 : 130,
        backgroundColor: 'none',
        color: colors.permaTalkspaceDarkGreen,
      }}
      isBaseButton
      actionHookState={state}
      subTitleStyles={{
        marginTop: flow ? 25 : 13,
      }}
      style={{
        marginTop: flow ? 25 : 80,
      }}
    />
  );
};

export default withRouter(CancelSubscriptionContainer);
