import { Trans } from '@talkspace/i18n';
import useTranslation from '@talkspace/i18n/hooks/useTranslation';
import useStringTranslation from '@talkspace/i18n/hooks/useStringTranslation';

import { useUniqueID } from '../../hooks/a11yHelper';
import ExtraTiny from '../Typography/ExtraTiny';
import Link from '../Link';
import HiddenText from '../HiddenText';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

const baseTextStyle = {
  letterSpacing: -0.2,
  lineHeight: '16px',
};

interface Props {
  isLoggedIn?: boolean;
  style?: EmotionStyle;
  dataQa?: string;
}
const EmergencyDisclaimer = ({ isLoggedIn, style, dataQa }: Props) => {
  const emergencyInfoID = useUniqueID('emergencyInfoID');
  const newTabInfoID = useUniqueID('newTabInfoID');
  const { colors } = useEmotionTheme();

  const { t: tReactToolkit } = useTranslation('react-toolkit');
  const resourcesLinkText = useStringTranslation(
    'these resources',
    'react-toolkit',
    'EmergencyDisclaimer.resourcesLinkText'
  );
  return (
    <>
      <ExtraTiny
        id={emergencyInfoID}
        style={{
          ...baseTextStyle,
          color: colors.osloGrey,
          textAlign: 'center',
          ...style,
        }}
      >
        <Trans t={tReactToolkit} i18nKey="EmergencyDisclaimer.bodyText">
          If you’re in a life-threatening situation,{' '}
          <ExtraTiny inline style={{ ...baseTextStyle, fontWeight: 700, color: colors.TSBlack }}>
            don’t use this app
          </ExtraTiny>
          . Call 988 {isLoggedIn ? 'or +1(833) 929-1721' : ''} or use
          <Link
            dataQa={dataQa}
            isNested
            aria-describedby={`${emergencyInfoID} ${newTabInfoID}`}
            href="https://helpnow.talkspace.com/"
            text={resourcesLinkText}
            target="_blank"
            primaryColor={colors.permaTalkspaceDarkGreen}
            roundedFocusStyle
            style={{
              textDecoration: 'none',
              fontWeight: 'bold',
              textDecorationLine: 'underline',
              marginRight: 0,
            }}
          />
          .
        </Trans>
      </ExtraTiny>
      <HiddenText id={newTabInfoID}>opens in new tab</HiddenText>
    </>
  );
};

export default EmergencyDisclaimer;
