import { WindowWidthValues } from '@talkspace/react-toolkit';
import { TherapistType } from 'ts-frontend/entities/Therapist';

export interface RoomTheme {
  window: WindowWidthValues;
}

export interface TherapistInfo {
  firstName: string;
  lastName: string;
  therapistID: number;
  therapistLicenses: string[];
  therapistImage: string;
  therapistType?: TherapistType;
}

export enum ELIGIBILITY_RESPONSE_STATUS {
  notEligible = 'notEligible',
  sessionAlreadyOpen = 'sessionAlreadyOpen',
}

export type MatchPreferenceName = 'gender' | 'presentingProblem' | 'language' | 'yearsOfExperience';

export interface MatchPreferenceReason {
  value: string;
  matchPreferenceName: MatchPreferenceName;
}

export interface MatchPreferenceReasonByProviderID {
  [x: number]: MatchPreferenceReason[];
}
