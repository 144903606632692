import { useQuery } from 'react-query';
import { MessagePreviewAPI } from 'ts-frontend/entities/MessagePreview';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

import { lastMessagesQueryKey } from '../utils/queryKeys';

interface QueryProps {
  clientUserID: string | number;
}

const fetchLastMessages =
  ({ clientUserID }: QueryProps) =>
  async () => {
    const {
      data: { data },
    } = await apiWrapper.get(
      `${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/rooms/last-messages`
    );
    return data;
  };

const useQueryLastMessages = ({ clientUserID }: QueryProps) =>
  useQuery<MessagePreviewAPI[], Error>({
    queryKey: lastMessagesQueryKey({ clientUserID }),
    queryFn: fetchLastMessages({ clientUserID }),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(clientUserID),
  });

export default useQueryLastMessages;
