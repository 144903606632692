import { useState, useEffect } from 'react';
import useQuerySubscriptions from 'ts-frontend/hooks/useQuerySubscriptions';
import useQueryCustomerInformation from '@/hooks/useQueryCustomerInformation';
import { getIsTeenUser } from '../utils/helpers';

const useIsTeenFunnelUser = (userID: number) => {
  const [isLoading, setIsLoading] = useState(true);
  const { data: customerInformation, isError: isErrorCustomerInformation } =
    useQueryCustomerInformation({ userID });
  const { dateOfBirth } = customerInformation || {};

  const { data: subscriptions, isError: isErrorSubscriptions } = useQuerySubscriptions({
    clientUserID: userID,
  });

  // react query may retry if an error occurs causing a loading loop
  // This hook is non-critical, if we've received data or any error
  // we should always render
  useEffect(() => {
    if (
      (customerInformation && subscriptions) ||
      isErrorCustomerInformation ||
      isErrorSubscriptions
    ) {
      setIsLoading(false);
    }
  }, [customerInformation, subscriptions, isErrorCustomerInformation, isErrorSubscriptions]);
  const hasB2BSubscription = subscriptions?.find((sub) => sub.subscription.isB2B);
  const isTeenUser = getIsTeenUser(dateOfBirth);
  return {
    isTeenFunnelUser: hasB2BSubscription && isTeenUser,
    isLoadingTeenFunnelUser: isLoading,
  };
};

export default useIsTeenFunnelUser;
