import { G, Defs, Path, Rect, Svg } from 'svgs';

import { useEmotionTheme } from '@talkspace/react-toolkit';

const FaceSurprise = () => {
  const { colorRoles } = useEmotionTheme();
  const title = 'Surprised Face';
  return (
    <Svg title={title} aria-label={title} width="21" height="20" viewBox="0 0 21 20" fill="none">
      <G clip-path="url(#clip0_3281_19438)">
        <Path
          d="M18.625 10C18.625 7.84512 17.769 5.77849 16.2452 4.25476C14.7215 2.73102 12.6549 1.875 10.5 1.875C8.34512 1.875 6.27849 2.73102 4.75476 4.25476C3.23102 5.77849 2.375 7.84512 2.375 10C2.375 12.1549 3.23102 14.2215 4.75476 15.7452C6.27849 17.269 8.34512 18.125 10.5 18.125C12.6549 18.125 14.7215 17.269 16.2452 15.7452C17.769 14.2215 18.625 12.1549 18.625 10ZM0.5 10C0.5 7.34784 1.55357 4.8043 3.42893 2.92893C5.3043 1.05357 7.84784 0 10.5 0C13.1522 0 15.6957 1.05357 17.5711 2.92893C19.4464 4.8043 20.5 7.34784 20.5 10C20.5 12.6522 19.4464 15.1957 17.5711 17.0711C15.6957 18.9464 13.1522 20 10.5 20C7.84784 20 5.3043 18.9464 3.42893 17.0711C1.55357 15.1957 0.5 12.6522 0.5 10ZM7.39062 6.875C7.72215 6.875 8.04009 7.0067 8.27451 7.24112C8.50893 7.47554 8.64062 7.79348 8.64062 8.125C8.64062 8.45652 8.50893 8.77446 8.27451 9.00888C8.04009 9.2433 7.72215 9.375 7.39062 9.375C7.0591 9.375 6.74116 9.2433 6.50674 9.00888C6.27232 8.77446 6.14062 8.45652 6.14062 8.125C6.14062 7.79348 6.27232 7.47554 6.50674 7.24112C6.74116 7.0067 7.0591 6.875 7.39062 6.875ZM12.3906 8.125C12.3906 7.79348 12.5223 7.47554 12.7567 7.24112C12.9912 7.0067 13.3091 6.875 13.6406 6.875C13.9721 6.875 14.2901 7.0067 14.5245 7.24112C14.7589 7.47554 14.8906 7.79348 14.8906 8.125C14.8906 8.45652 14.7589 8.77446 14.5245 9.00888C14.2901 9.2433 13.9721 9.375 13.6406 9.375C13.3091 9.375 12.9912 9.2433 12.7567 9.00888C12.5223 8.77446 12.3906 8.45652 12.3906 8.125ZM10.5 11.25C11.163 11.25 11.7989 11.5134 12.2678 11.9822C12.7366 12.4511 13 13.087 13 13.75C13 14.413 12.7366 15.0489 12.2678 15.5178C11.7989 15.9866 11.163 16.25 10.5 16.25C9.83696 16.25 9.20107 15.9866 8.73223 15.5178C8.26339 15.0489 8 14.413 8 13.75C8 13.087 8.26339 12.4511 8.73223 11.9822C9.20107 11.5134 9.83696 11.25 10.5 11.25Z"
          fill={colorRoles.icons.iconSubtlestDefault}
        />
      </G>
      <Defs>
        <clipPath id="clip0_3281_19438">
          <Rect width="20" height="20" fill="white" transform="translate(0.5)" />
        </clipPath>
      </Defs>
    </Svg>
  );
};

export default FaceSurprise;
