import { FunctionComponent, useEffect } from 'react';
import styled from 'core/styled';
import { View } from '@talkspace/react-toolkit';
import { persistData, useWizardState, useWizardActions } from 'stepWizard';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { trackWizardEvent } from '@/utils/analytics/events';
import { getUserData } from '@/auth/helpers/token';
import { RouteComponentProps, withRouter } from '../../core/routerLib/routerLib';
import PausePlanModal from '../../myAccount/components/PausedPlanModal';
import useCancellationWizard from '../hooks/useCancellationWizard';

const StyledView = styled(View)({
  alignSelf: 'center',
});

const PauseSubscriptionContainer: FunctionComponent<RouteComponentProps> = ({ history }) => {
  const {
    roomID,
    contextID,
    source,
    responses,
    persisted,
    therapistInfo,
    experiment,
    experimentID,
  } = useWizardState();
  const closeModal = useCloseModal();
  const { setState } = useWizardActions();
  const [, { exitAction }] = useCancellationWizard();
  const { id: userID } = getUserData();
  const lastStepName = 'Pause Subscription Offer';

  trackWizardEvent(lastStepName, 'Cancellation Wizard', {
    'User ID': userID,
    Application: 'Cancellation Wizard',
    label: lastStepName,
    eventPropertyValue: therapistInfo && therapistInfo.id,
    experiment,
    experimentID,
  });

  const handlePauseTherapy = () => {
    history.push('/cancel-subscription/subscription-paused', {
      roomID,
      contextID,
      source,
    });
  };

  const handleExitModal = () => {
    exitAction();
    persistData(responses, setState, lastStepName, '', {}, false);
  };

  useEffect(() => {
    if (persisted) closeModal();
  }, [closeModal, persisted]);

  return (
    <>
      <StyledView>
        <PausePlanModal
          onCancelPress={handleExitModal}
          pausePlan={handlePauseTherapy}
          period={30}
        />
      </StyledView>
    </>
  );
};

export default withRouter(PauseSubscriptionContainer);
