import { FunctionComponent } from 'react';
import { View } from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import Logo from '../../../assets/Logo';

const Wrapper = styled(View)(({ theme: { colors } }) => {
  return {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 23,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: colors.white,
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.06)',
    zIndex: 2,
  };
});

const NavBar: FunctionComponent<{}> = () => (
  <Wrapper>
    <Logo />
  </Wrapper>
);

export default NavBar;
