import {
  SquarePlus,
  Phone,
  CircleQuestion,
  PenToSquare,
  Files,
} from '@talkspace/react-toolkit/src/designSystems/icons';
import {
  immediateCrisisSupportLink,
  helpNowLink,
  helpCenterLink,
  reportAnIssueLink,
  viewMyTicketsLink,
} from 'ts-frontend/constants';
import { TFNewMemberNav } from '@talkspace/i18n/types';
import ssoHelper, { ZendeskPath } from '@/utils/sso';
import { AccountCard } from '../../types';

export const getCrisisSupportCards = ({
  tnewMemberNav,
  isUS,
}: {
  tnewMemberNav: TFNewMemberNav;
  isUS: boolean;
}): AccountCard[] => [
  ...(isUS
    ? [
        {
          titleText: tnewMemberNav('help.call', 'Call +1(833) 929-1721'),
          bodyText: tnewMemberNav('help.immediate', '24/7 immediate crisis support'),
          Icon: <Phone size="major" colorType="brand" />,
          hideCaret: false,
          route: immediateCrisisSupportLink,
          dataQa: 'accountHelpCallCrisisSupport',
        },
      ]
    : []),
  {
    titleText: tnewMemberNav('help.emergency', 'Emergency resources'),
    bodyText: tnewMemberNav('help.more', 'More hotlines and helpful websites'),
    Icon: <SquarePlus size="major" colorType="brand" />,
    route: helpNowLink,
    hideCaret: false,
    dataQa: 'accountHelpEmergencyResources',
  },
];

export const getTechnicalSupportCards = (tnewMemberNav: TFNewMemberNav): AccountCard[] => [
  {
    titleText: tnewMemberNav('help.visit', 'Visit our help center'),
    Icon: <CircleQuestion size="major" colorType="brand" />,
    hideCaret: false,
    route: helpCenterLink,
    dataQa: 'accountHelpVisitHelpCenter',
    zendeskPath: ssoHelper.ZendeskPath.KNOWLEDGE_BASE as ZendeskPath,
  },
  {
    titleText: tnewMemberNav('help.report', 'Report an issue'),
    Icon: <PenToSquare size="major" colorType="brand" />,
    hideCaret: false,
    route: reportAnIssueLink,
    dataQa: 'accountHelpReportAnIssue',
    zendeskPath: ssoHelper.ZendeskPath.CONTACT_US as ZendeskPath,
  },
  {
    titleText: tnewMemberNav('help.view', 'View my tickets'),
    Icon: <Files size="major" colorType="brand" />,
    hideCaret: false,
    route: viewMyTicketsLink,
    dataQa: 'accountHelpViewTickets',
    zendeskPath: ssoHelper.ZendeskPath.MY_TICKETS as ZendeskPath,
  },
];
