import { useMutation } from 'react-query';

import apiHelper from '@/utils/api';

interface SubmitConsentMutateVariables {
  token: string;
  urlPath: string;
  body: any;
}

const submitConsent = async (params: SubmitConsentMutateVariables): Promise<void> => {
  const { token, urlPath, body } = params;

  const response = await fetch(`${apiHelper().apiEndpoint}${urlPath}`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    const parsedResponse = await response.json();

    throw new Error(parsedResponse.errorMessage || 'Something went wrong.');
  }
};

const useMutationSubmitConsent = () =>
  useMutation<void, Error, SubmitConsentMutateVariables>(submitConsent);

export default useMutationSubmitConsent;
