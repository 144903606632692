import { FunctionComponent, useEffect } from 'react';
import {
  ResponsiveLayoutWithHeader,
  View,
  TSLogo,
  useA11yActions,
  useWindowWidthState,
  getScreenSafeAreaInsets,
} from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';
import SSOLogin from './components/SSOLogin';
import SSOCallback from './components/SSOCallback';
import ProviderSSOCallback from './components/ProviderSSOCallback';
import LoginOauth from './components/LoginOauth';
import { Route } from '../core/routerLib';
import Login from './components/Login';
import Signup from './components/Signup';
import ForgotPassword from './components/ForgotPassword';
import ChangePassword from './components/ChangePassword';
import styled from '../core/styled';

const Header = styled(View)({
  flex: 1,
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: 20,
  paddingRight: 16,
});

const AddHeaderWrapper: FunctionComponent<{ newLogo?: boolean; isMobile?: boolean }> = ({
  children,
  newLogo,
  isMobile,
}) => {
  const safeAreaInsets = getScreenSafeAreaInsets();
  return (
    <>
      <ResponsiveLayoutWithHeader
        safeAreaVariant="none"
        containerStyle={isMobile ? { marginTop: 18 + safeAreaInsets.top } : undefined}
        hideHeader={isMobile}
        noHeaderLine={isMobile && newLogo}
        renderHeader={() => (
          <Header>
            {(!isMobile || !newLogo) && (
              <TSLogo style={{ marginLeft: 6 }} variant={newLogo ? '2024' : 'old'} />
            )}
          </Header>
        )}
      >
        {children}
      </ResponsiveLayoutWithHeader>
    </>
  );
};

const Routes = () => {
  const { setColorContrast } = useA11yActions();
  const { brandRefreshChanges } = useFlags();
  const { isMobile } = useWindowWidthState();
  useEffect(() => {
    setColorContrast(true);
    return () => {
      setColorContrast(false);
    };
  }, [setColorContrast]);
  return (
    <>
      <Route
        exact
        path="/"
        render={() => (
          <AddHeaderWrapper newLogo={brandRefreshChanges} isMobile={isMobile}>
            <Login />
          </AddHeaderWrapper>
        )}
      />
      <Route
        exact
        path="/login"
        render={() => (
          <AddHeaderWrapper newLogo={brandRefreshChanges} isMobile={isMobile}>
            <Login />
          </AddHeaderWrapper>
        )}
      />
      <Route exact path="/login/sso" render={() => <SSOLogin mode="logIn" />} />
      <Route exact path="/login/sso/register" render={() => <SSOLogin mode="register" />} />
      <Route
        exact
        path="/login/sso/callback"
        render={() => (
          <AddHeaderWrapper newLogo={brandRefreshChanges} isMobile={isMobile}>
            <SSOCallback />
          </AddHeaderWrapper>
        )}
      />
      <Route
        exact
        path="/login/sso/callback/:provider?"
        render={() => (
          <AddHeaderWrapper newLogo={brandRefreshChanges} isMobile={isMobile}>
            <ProviderSSOCallback />
          </AddHeaderWrapper>
        )}
      />
      <Route exact path="/login/sso/close" render={() => <View>Closing...</View>} />
      <Route
        path="/oauth"
        render={() => (
          <AddHeaderWrapper newLogo={brandRefreshChanges} isMobile={isMobile}>
            <LoginOauth />
          </AddHeaderWrapper>
        )}
      />
      <Route
        path="/signup/:slugName?"
        render={() => (
          <AddHeaderWrapper newLogo={brandRefreshChanges} isMobile={isMobile}>
            <Signup />
          </AddHeaderWrapper>
        )}
      />

      <Route
        exact
        path="/forgot-password"
        render={() => (
          <AddHeaderWrapper newLogo={brandRefreshChanges} isMobile={isMobile}>
            <ForgotPassword />
          </AddHeaderWrapper>
        )}
      />
      <Route
        exact
        path="/reset-password"
        render={() => (
          <AddHeaderWrapper newLogo={brandRefreshChanges} isMobile={isMobile}>
            <ForgotPassword isResetPassword />
          </AddHeaderWrapper>
        )}
      />
      <Route
        path="/change-password"
        render={() => (
          <AddHeaderWrapper newLogo={brandRefreshChanges} isMobile={isMobile}>
            <ChangePassword />
          </AddHeaderWrapper>
        )}
      />
    </>
  );
};

export default Routes;
