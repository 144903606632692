import { View, TouchableView, BaseButton, TextDS, TSAvatar } from '@talkspace/react-toolkit';
import { useTranslation } from '@talkspace/i18n';
import { MatchResult } from '../../matchingTypes';
import styled from '@/core/styled';

const Circle = styled(View)<{
  size: number | string;
}>(({ size, theme: { colorRoles } }) => {
  return {
    border: `dashed 1px ${colorRoles.borders.borderDefault}`,
    borderRadius: '50%',
    backgroundColor: 'white',
    overflow: 'hidden',
    width: size,
    height: size,
  };
});

const OverlappingContainer = styled(View)({
  marginLeft: -15,
});

const ButtonWrapper = styled(View)(({ theme: { spacing } }) => {
  return {
    margin: spacing('space100'),
    padding: spacing('space100'),
  };
});
const TitleWrapper = styled(View)(({ theme: { spacing } }) => {
  return {
    marginBottom: spacing('space150'),
  };
});

interface OtherAvailableProvidersProps {
  matchResults: MatchResult[];
  onPress: () => void;
}
const OtherAvailableProviders = ({ matchResults, onPress }: OtherAvailableProvidersProps) => {
  const { t: tSwitchWizard } = useTranslation('switchWizard');
  return (
    <TouchableView onPress={onPress} dataQa="otherAvailableProvidersWrapperButton">
      <TitleWrapper>
        <TextDS variant="headingMd">
          {tSwitchWizard('MatchResults.otherAvailableProviders', 'Other available providers')}
        </TextDS>
      </TitleWrapper>
      <View row>
        {matchResults.slice(1, 4).map((match, i) =>
          i === 0 ? (
            <View>
              <TSAvatar image={match.details.image} size={64} />
            </View>
          ) : (
            <OverlappingContainer>
              <TSAvatar image={match.details.image} size={64} />
            </OverlappingContainer>
          )
        )}
        {matchResults.length > 4 && (
          <OverlappingContainer>
            <Circle size={64} align="center" justify="center">
              <TextDS variant="headingMd">+{matchResults.length - 3}</TextDS>
            </Circle>
          </OverlappingContainer>
        )}
        <ButtonWrapper align="center" justify="center">
          <BaseButton dataQa="otherAvailableProvidersSeeAll">
            <TextDS variant="headingMd" colorRole="buttonBrandSecondary">
              {tSwitchWizard('MatchResults.otherAvailableProviders', 'See all')}
            </TextDS>
          </BaseButton>
        </ButtonWrapper>
      </View>
    </TouchableView>
  );
};
export default OtherAvailableProviders;
