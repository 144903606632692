import { FunctionComponent, useEffect } from 'react';
import {
  View,
  Link,
  Small,
  Button,
  Massive,
  Password,
  ExtraHuge,
  useEmotionTheme,
  usePasswordValidation,
} from '@talkspace/react-toolkit';
import { PasswordInputMode } from 'ts-frontend/types';
import styled from '../../../core/styled';
import { webOnlyStyle } from '../../../core/styled/styleHelpers';
import useScreenSize, { isResponsive } from '../../../core/hooks/useScreenSize';
import { useClientAuthActions, useClientAuthState } from '../../../hooks/clientAuthContext';
import useRedirectAfterLogin from '../../hooks/useRedirectAfterLogin';
import getParamByName from '../../../utils/queryString';

const Form = styled(View)({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginLeft: 0,
  marginRight: 0,
});

const SubmitButton = ({ onPress, ...props }) => {
  const { colors } = useEmotionTheme();
  return (
    <Button
      type="submit"
      onPress={onPress}
      style={{
        fontFamily: 'Roboto',
        backgroundColor: colors.green,
        fontSize: 19,
        fontWeight: 'bold',
        width: 335,
        minHeight: 55,
        borderRadius: 10,
        display: 'flex',
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.white,
        letterSpacing: 0.74,
        textAlign: 'center',
      }}
      {...props}
    />
  );
};

const ChangePassword: FunctionComponent<{}> = () => {
  const {
    adminConfigs: { isCognitoLoginEnabled },
    isUpdating,
    isError,
    errorMessage,
  } = useClientAuthState();
  const { changePasswordAction, changePasswordCognitoAction, clearErrorsAction } =
    useClientAuthActions();
  const screenSize = useScreenSize();

  useRedirectAfterLogin();

  const [
    { password: newPassword, onSubmitPassword: onSubmitNewPassword, setPasswordError },
    newPasswordProps,
  ] = usePasswordValidation({
    passwordInputMode: PasswordInputMode.CHANGE_PASSWORD,
  });

  const [
    { password: confirmedPassword, onSubmitPassword: onSubmitConfirmedPassword },
    confirmedPasswordProps,
  ] = usePasswordValidation({
    passwordInputMode: PasswordInputMode.CONFIRM_PASSWORD,
    passwordToCompare: newPassword,
  });

  const onSubmit = () => {
    if (isUpdating) return;
    const isNewPasswordValid = onSubmitNewPassword();
    const isConfirmedPasswordValid = onSubmitConfirmedPassword(!isNewPasswordValid);
    if (isNewPasswordValid && isConfirmedPasswordValid) {
      if (isCognitoLoginEnabled) {
        changePasswordCognitoAction({
          code: getParamByName('code') || '',
          email: atob(getParamByName('email') || ''),
          password: newPassword,
        });
      } else {
        clearErrorsAction();
        changePasswordAction({ password: newPassword, confirmedPassword });
      }
    }
  };

  useEffect(() => {
    if (isError) {
      setPasswordError(errorMessage);
    }
  }, [isError, errorMessage, setPasswordError]);

  const TitleComponent = isResponsive(screenSize) ? ExtraHuge : Massive;

  const { colors } = useEmotionTheme();
  return (
    <View align="center">
      <View
        align="center"
        style={{
          marginTop: isResponsive(screenSize) ? 30 : 60,
          marginBottom: 29,
        }}
      >
        <TitleComponent style={{ marginBottom: 10, textAlign: 'center' }}>
          Change password
        </TitleComponent>
      </View>
      <Form>
        <Password
          {...newPasswordProps}
          label="New password"
          containerStyle={{ marginBottom: 18 }}
          onSubmitEditing={onSubmit}
        />
        <Password
          {...confirmedPasswordProps}
          isConfirmationInput
          label="Confirm new password"
          value={confirmedPassword}
          containerStyle={{ marginBottom: 0 }}
          onSubmitEditing={onSubmit}
        />
        <SubmitButton onPress={onSubmit} text="Save" isLoading={isUpdating} disabled={isUpdating} />
        <View style={{ marginTop: 20 }}>
          <Small>
            Need help?{' '}
            <Link
              target="_blank"
              href="https://help.talkspace.com"
              style={{ marginLeft: 0, marginRight: 0 }}
            >
              <Small
                inline
                style={{
                  ...webOnlyStyle({ display: 'inline' }),
                  color: colors.green,
                  fontWeight: 700,
                }}
              >
                Customer support
              </Small>
            </Link>
          </Small>
        </View>
      </Form>
    </View>
  );
};

export default ChangePassword;
