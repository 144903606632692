import { useEffect, useState } from 'react';
import { History } from 'history';
import { getUserData } from 'auth/helpers/token';
import { useGenericWizardActions } from 'utils/genericWizardActions';
import { StepWizardProvider } from 'stepWizard';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { ThemedFlagsProvider } from 'launchDarkly';
import { InRoomSchedulingProvider } from 'inRoomScheduling';
import Routes from './Routes';
import { getInitialState, State } from './reducers/switchWizardState';
import switchWizardActions from './hooks/switchWizardActions';
import ApiHelper from '../utils/ApiHelper';

interface Props {
  roomID: number;
  source: string;
  contextID?: string;
  history: History;
}

const SwitchWizard = ({ roomID, source, contextID = '', history }: Props) => {
  const { id } = getUserData();
  const [initialState, setInitialState] = useState<State | undefined>();
  const { availabilitySwitch } = useFlags();
  const genericSwitchWizardActions = useGenericWizardActions(switchWizardActions);
  useEffect(() => {
    if (roomID) {
      const firstState = getInitialState({
        clientUserID: id,
        roomID,
        source,
        contextID,
        isV2: !!availabilitySwitch,
      });
      setInitialState(firstState);
    }
  }, [contextID, id, roomID, source, availabilitySwitch]);
  return (
    <ThemedFlagsProvider versionKey={availabilitySwitch ? 'switchWizard' : 'default'}>
      {initialState ? (
        <InRoomSchedulingProvider>
          <StepWizardProvider
            ApiHelperOverride={ApiHelper}
            initialState={initialState}
            genericActions={genericSwitchWizardActions}
          >
            <Routes history={history} />
          </StepWizardProvider>
        </InRoomSchedulingProvider>
      ) : null}
    </ThemedFlagsProvider>
  );
};

export default SwitchWizard;
