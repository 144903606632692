import { BoughtFrom } from 'offer';
import { getDefaultOfferByRoomType } from '@/utils/offerHelper';

export default function getOfferUrl({ roomID, roomType }: { roomID: number; roomType?: string }) {
  const offerID = getDefaultOfferByRoomType(roomType);
  const params = new URLSearchParams({
    showBackButton: 'true',
    boughtFrom: String(BoughtFrom.reactivation),
  });
  return `/room-offer/${roomID}/offer/${offerID}?${params.toString()}`;
}
