import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import getInviteHashParams from '../utils/getInviteUrlParams';
import { validateInvitationToken } from '../utils/roomInvitesApiHelper';

interface invitationToken {
  testingInviteToken: boolean;
  invitationSentToEmail: string | null;
}

const useValidateInvitationToken = (): invitationToken => {
  const { invitationToken } = getInviteHashParams();
  const history = useHistory();
  const [state, setState] = useState<invitationToken>({
    testingInviteToken: Boolean(invitationToken),
    invitationSentToEmail: null,
  });

  useEffect(() => {
    if (invitationToken) {
      validateInvitationToken(invitationToken)
        .then((res) => {
          const { invitationSentToEmail } = res.data;
          setState({ testingInviteToken: false, invitationSentToEmail });
        })
        .catch(({ message }) => {
          const reason = message === 'expired' ? message : 'invalid';
          history.replace(`/invite-error/${reason}`);
        });
    }
  }, [history, invitationToken]);
  return state;
};

export default useValidateInvitationToken;
