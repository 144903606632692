import { useEffect } from 'react';
import { History } from 'history';
import { WizardScreen, useWizardActions, useWizardState } from 'stepWizard';
import { Screen } from '@talkspace/react-toolkit';
import { State } from '@/CancellationWizard/reducers/cancellationWizardState';
import { loadCouponDefinitions } from '@/CancellationWizard/hooks/cancellationWizardActions';
import { Route, Switch } from '@/core/routerLib';
import PauseSubscriptionContainer from './containers/PauseSubscriptionContainer';
import SubscriptionPausedContainer from './containers/SubscriptionPausedContainer';
import ApplyDiscountContainer from './containers/ApplyDiscountContainer';
import SubscribeToMaintenancePlanContainer from './containers/SubscribeToMaintenancePlanContainer';
import CancelSubscriptionContainer from './containers/CancelSubscriptionContainer';
import KeepSubscriptionContainer from './containers/KeepSubscriptionContainer';
import useWindowWidth from '../hooks/useWindowWidth';
import useOnBrowserBack from '../hooks/useOnBrowserBack';
import cancellationWizardScheme from './cancellationWizardScheme';

interface RoutesParams {
  history: History;
}

const Routes = ({ history }: RoutesParams) => {
  const { isMobile } = useWindowWidth();
  const { setState, onExitClick } = useWizardActions();
  const showBackButton = history.location.pathname.includes('/pause-therapy');
  const wizardState = useWizardState() as State;
  const COUPON_CODE_INDEX = 0;

  useEffect(() => {
    if (!wizardState.isInitialized) return;
    loadCouponDefinitions(
      wizardState.roomID,
      wizardState.status as number,
      wizardState.couponCodes,
      COUPON_CODE_INDEX
    ).then((eligibility) => {
      setState({ ...eligibility, couponCodeIndex: COUPON_CODE_INDEX });
    });
  }, [
    wizardState.isInitialized,
    wizardState.status,
    wizardState.roomID,
    wizardState.couponCodes,
    setState,
  ]);
  const isNavigatingBack = useOnBrowserBack(history, () => {
    history.push(`/room/${wizardState.roomID}/my-account/manage-subscription`);
  });
  return (
    <Switch>
      <Screen
        safeAreaVariant="setHeightAndAddTop"
        showBackButton={showBackButton}
        onCloseButtonClick={onExitClick}
        title={isMobile ? ' ' : ''}
      >
        <Route
          exact
          path="/cancel-subscription/apply-discount"
          component={ApplyDiscountContainer}
        />
        <Route
          exact
          path="/cancel-subscription/pause-therapy"
          component={PauseSubscriptionContainer}
        />
        <Route
          exact
          path="/cancel-subscription/subscription-paused"
          component={SubscriptionPausedContainer}
        />
        <Route
          exact
          path="/cancel-subscription/switch-to-maintenance-plan"
          component={SubscribeToMaintenancePlanContainer}
        />
        <Route
          exact
          path="/cancel-subscription/cancel"
          render={(props) => (
            <CancelSubscriptionContainer isNavigatingBack={isNavigatingBack} {...props} />
          )}
        />
        <Route
          exact
          path="/cancel-subscription/keep-subscription"
          component={KeepSubscriptionContainer}
        />
        <Route
          exact
          path={[
            '/cancel-subscription/room/:roomID/source/:source/context-id/:contextID',
            '/cancel-subscription/room/:roomID/source/:source',
          ]}
          render={() => <WizardScreen wizardScheme={cancellationWizardScheme} />}
        />
      </Screen>
    </Switch>
  );
};

export default Routes;
