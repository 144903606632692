import { CancellationPolicy } from '@talkspace/react-toolkit';

import PostAsyncPromptBaseScreen from './PostAsyncPromptBaseScreen';

interface Props {
  onBack: () => void;
}

const PostAsyncPromptCancellationPolicyScreen = ({ onBack }: Props) => (
  <PostAsyncPromptBaseScreen title="Start a session" onBack={onBack} isCloseable={false}>
    <CancellationPolicy variant="async" />
  </PostAsyncPromptBaseScreen>
);

export default PostAsyncPromptCancellationPolicyScreen;
