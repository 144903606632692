import { FunctionComponent } from 'react';
import { Currency, InsuranceEligibility } from 'ts-frontend/types';
import { View, ExtraBig, Large } from '@talkspace/react-toolkit';
import { formatCurrency } from 'ts-frontend/helpers/billingUtils';
import styled from '@/core/styled';
import { PromoToDisplay } from '../types';

const BannerBackground = styled(View)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      width: '100%',
      background: colors.bannerBlue,
      textAlign: 'center',
      color: colors.white,
      paddingTop: 32,
      paddingBottom: 25,
      marginBottom: 30,
      maxWidth: isMobile ? 440 : 494,
    };
  }
);

const ExtraBigWhite = styled(ExtraBig)(({ theme: { colors } }) => {
  return {
    color: colors.white,
    marginBottom: 10,
  };
});

const LargeWhite = styled(Large)(({ theme: { colors } }) => {
  return {
    color: colors.white,
  };
});

const OffersDiscountBanner: FunctionComponent<{
  currency: Currency;
  insuranceEligibility?: InsuranceEligibility;
  promoToDisplay?: PromoToDisplay;
}> = ({ currency, insuranceEligibility, promoToDisplay }) => {
  let title = '';
  let subTitle = '';
  let showBanner = true;
  const { coinsurancePercent, copayCents, ineligiblePromo, ineligiblePromoWeeks } =
    insuranceEligibility || {};
  const { promoAmount, promoWeeks } = promoToDisplay || {};

  if (coinsurancePercent || copayCents) {
    title = `Insurance discount automatically applied`;
    subTitle = 'for the duration of time you are covered under your health plan.';
  } else if (ineligiblePromo && ineligiblePromoWeeks) {
    const offPerWeek = ineligiblePromo / ineligiblePromoWeeks;
    title = `${formatCurrency(
      offPerWeek,
      currency
    )} off per week for the first ${ineligiblePromoWeeks} weeks`;
    subTitle = 'Discount automatically applied at checkout.';
  } else if (promoAmount && promoWeeks) {
    const offPerWeek = promoAmount / promoWeeks;
    title = `${formatCurrency(
      offPerWeek,
      currency
    )} off per week for the first ${promoWeeks} weeks`;
    subTitle = 'Discount automatically applied at checkout.';
  } else {
    showBanner = false;
  }
  return showBanner ? (
    <BannerBackground>
      <ExtraBigWhite>{title}</ExtraBigWhite>
      <LargeWhite>{subTitle}</LargeWhite>
    </BannerBackground>
  ) : null;
};
export default OffersDiscountBanner;
