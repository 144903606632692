export enum LoginFieldNames {
  email = 'email',
  password = 'password',
  rememberMe = 'rememberMe',
}

export interface LoginFields {
  [LoginFieldNames.email]: string;
  [LoginFieldNames.password]: string;
  [LoginFieldNames.rememberMe]?: boolean;
}

export interface LoginFormYupContext {
  isPasswordInvalid: () => string | undefined;
  onSubmitPassword: (shouldAutoFocus: boolean) => void;
}
