import Svg, { Path, Defs, Rect, G, Use } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const FreeConsultationPlan = ({ width = 74, height = 74, ...props }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'free consultation plan';
  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      title={titleText}
      aria-label={titleText}
      {...props}
    >
      <Defs>
        <Rect id="b" width="58" height="58" x="0" y="0" rx="29" />
        <filter
          id="a"
          width="146.6%"
          height="146.6%"
          x="-23.3%"
          y="-18.1%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology in="SourceAlpha" radius="1.5" result="shadowSpreadOuter1" />
          <feOffset dy="3" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5.5" />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.062745098 0 0 0 0 0.427620878 0 0 0 0 0.584313725 0 0 0 0.3 0"
          />
        </filter>
      </Defs>
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <Path
          fill={colors.permaLinkWaterGrey}
          d="M10 0h451c5.523 0 10 4.477 10 10v157c0 5.523-4.477 10-10 10H10c-5.523 0-10-4.477-10-10V10C0 4.477 4.477 0 10 0z"
          transform="translate(-1560 -249) translate(1547 234)"
        />
        <G transform="translate(-1560 -249) translate(1547 234) translate(21 20)">
          <use fill={colors.trueBlack} filter="url(#a)" xlinkHref="#b" />
          <Use fill={colors.white} xlinkHref="#b" href="#b" />
        </G>
        <G>
          <Path
            fill={colors.green}
            d="M4 0h22a4 4 0 014 4v7a4 4 0 01-4 4H4a4 4 0 01-4-4V4a4 4 0 014-4z"
            transform="translate(-1560 -249) translate(1582 271)"
          />
          <Path
            fill={colors.waikawaGray}
            fillRule="nonzero"
            d="M12.223 8.23v10.126l-1.039-1.058a2.236 2.236 0 00-2.872-.28c-.52.373-.886.933-.978 1.586a2.369 2.369 0 00.489 1.805l4.156 5.102C13.293 27.098 15.187 28 17.204 28h2.597a5.79 5.79 0 004.248-1.804c1.161-1.152 1.772-2.676 1.772-4.325v-5.227c0-1.244-1.008-2.27-2.23-2.27-.367 0-.734.093-1.07.28-.397-.747-1.13-1.214-1.986-1.214-.367 0-.734.093-1.07.28-.397-.747-1.13-1.213-1.986-1.213-.275 0-.55.062-.794.155V8.231a2.23 2.23 0 00-4.462 0z"
            transform="translate(-1560 -249) translate(1582 271)"
          />
          <Path
            fill={colors.white}
            d="M19.038 17.484a.75.75 0 00.733-.746V15.71c0-.435.367-.809.794-.809.428 0 .795.374.795.81v1.96a.75.75 0 00.733.746.75.75 0 00.734-.747v-.933-.094c0-.435.366-.808.794-.808.428 0 .795.373.795.808v5.227a4.705 4.705 0 01-1.345 3.298 4.538 4.538 0 01-3.239 1.369h-2.597a5.26 5.26 0 01-4.095-1.96l-4.187-5.134a.83.83 0 01-.183-.653.716.716 0 01.367-.56.814.814 0 01.458-.155c.214 0 .428.093.58.248l1.681 1.68c.306.312.764.405 1.162.25.397-.156.672-.56.672-1.027V8.276c0-.436.367-.81.794-.81a.71.71 0 01.55.25.844.844 0 01.245.56v7.528a.75.75 0 00.733.747.75.75 0 00.734-.747v-1.026c0-.436.366-.81.794-.81.428 0 .795.374.795.81v1.96a.688.688 0 00.703.746z"
            transform="translate(-1560 -249) translate(1582 271)"
          />
        </G>
      </G>
    </Svg>
  );
};

export default FreeConsultationPlan;
