import { useMutation } from 'react-query';
import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';

interface postParentalConsentDetailsVariables {
  userID: number;
  data: any;
}

const postParentalConsentDetails = async (
  params: postParentalConsentDetailsVariables
): Promise<void> => {
  const { userID, data } = params;

  await apiWrapper
    .post(`${apiHelper().apiEndpoint}/v2/users/${userID}/parental-consent`, data)
    .catch((e) => {
      throw new Error('Something went wrong.');
    });
};

const useMutationParentalConsentDetails = () =>
  useMutation<void, Error, postParentalConsentDetailsVariables>(postParentalConsentDetails);

export default useMutationParentalConsentDetails;
