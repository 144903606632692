import React from 'react';
import { Card, TextDS, TouchableViewProps, View, spacing } from '@talkspace/react-toolkit';
import styled from '@/core/styled';

const { space050, space150, space200, space100 } = spacing;

const LeftColumn = styled(View)({
  width: 64,
  marginRight: space150,
});

export interface ForYouCardProps extends Omit<TouchableViewProps, 'style'> {
  Icon?: React.ReactNode;
  titleText?: string | React.ReactNode;
  bodyText?: string | React.ReactNode;
  disabled?: boolean;
  onPress?: React.MouseEventHandler<HTMLDivElement> | undefined;
  dataQa?: string;
}

const ForYouCard = ({ Icon, titleText, bodyText, disabled, onPress, dataQa }: ForYouCardProps) => (
  <Card
    onPress={onPress}
    dataQa={dataQa}
    disabled={disabled}
    variant="noBorder"
    padding={`${space200}px ${space200}px ${space200}px ${space100}px`}
  >
    {() => (
      <View row flex={1}>
        <LeftColumn align="center">{Icon}</LeftColumn>
        <View flex={1}>
          <TextDS variant="headingMd" colorRole="textDefault">
            {titleText}
          </TextDS>
          <TextDS colorRole="textSubtlest" variant="bodySm" style={{ marginTop: space050 }}>
            {bodyText}
          </TextDS>
        </View>
      </View>
    )}
  </Card>
);

export default ForYouCard;
