import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { A11Y_COLORS, Button, ExtraHuge, View } from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import ParentalConsentDetailsContainer from '@/parentalConsentDetails/containers/ParentalConsentDetailsContainer';
import { useCloseModal } from '../../utils/ModalsContextProvider';
import { getUserData } from '../../auth/helpers/token';
import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';

const MainView = styled(View)({
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: 49,
  maxWidth: 335,
});

const StyledButton = styled(Button)(({ theme: { colors } }) => {
  return {
    position: 'sticky',
    top: 100,
    fontFamily: 'Roboto',
    backgroundColor: colors.green,
    fontSize: 19,
    fontWeight: 'bold',
    width: 335,
    minHeight: 55,
    borderRadius: 10,
    display: 'flex',
    marginTop: 0,
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.white,
    letterSpacing: 0.74,
    textAlign: 'center',
  };
});

const ParentalConsentResubmitContainer: FunctionComponent = () => {
  const closeModal = useCloseModal();
  const [consentAlreadyGranted, setConsentAlreadyGranted] = useState<boolean>(false);
  const { id: userID } = getUserData();

  const getConsentGranted = useCallback(async () => {
    const consentGrantedRes = await apiWrapper.get(
      `${apiHelper().apiEndpoint}/v2/users/${userID}/parental-consent/consent-granted`
    );
    setConsentAlreadyGranted(consentGrantedRes?.data?.data?.consentGranted);
  }, [userID]);

  useEffect(() => {
    getConsentGranted();
  }, [getConsentGranted]);

  const onComplete = useCallback(() => {
    closeModal();
  }, [closeModal]);

  if (consentAlreadyGranted) {
    return (
      <MainView>
        <ExtraHuge
          dataQa="parentalConsentDetailsFormHasAlreadyBeenSignedLabel"
          style={{ marginBottom: 20 }}
        >
          The consent form has already been signed
        </ExtraHuge>
        <StyledButton
          type="submit"
          style={{ width: 335, backgroundColor: A11Y_COLORS.green, padding: '14px 24px' }}
          text="Go back"
          dataQa="goBackButtonFormAlreadySigned"
          onClick={onComplete}
        />
      </MainView>
    );
  }

  return (
    <ParentalConsentDetailsContainer
      onComplete={onComplete}
      title="Contact information"
      showBackButton={false}
      showCloseButton
      onCloseButtonClick={closeModal}
      isResubmit
    />
  );
};

export default ParentalConsentResubmitContainer;
