import { FunctionComponent } from 'react';
import Lottie from 'react-lottie';
import { View } from '@talkspace/react-toolkit';
import loadingSpinner from '../../assets/animations/loadingSpinner.json';
import ValidateEmailError from './ValidateEmailError';
import EmailVerified from './EmailVerified';

const ValidateEmail: FunctionComponent<{
  token: string | null;
  isLoading: boolean;
  error: string;
  emailVerifiedRedirect: string;
}> = ({ token, isLoading, error, emailVerifiedRedirect }) => (
  <>
    {token && !error && isLoading && (
      <View justify="center" align="center" style={{ paddingTop: 68, textAlign: 'center' }}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loadingSpinner,
          }}
          height={160}
          width={160}
        />
      </View>
    )}
    {token && !isLoading && !error && emailVerifiedRedirect && <EmailVerified />}
    {(error || !token) && <ValidateEmailError />}
  </>
);

export default ValidateEmail;
