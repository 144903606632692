import { RouteComponentProps } from '../core/routerLib';
import ReactFrameService from '../auth/reactFrame/ReactFrameService';
import LoadingScreen from '../screens/LoadingScreen';
import { safeRouteNavigateOrPush } from '../utils/analytics/helpers';
import useNavHelpers from '../hooks/useNavHelpers';

const CrossPlatformDeepLink = ({ location, history }: RouteComponentProps) => {
  const { navigateToDefault } = useNavHelpers({ history });
  // TODO: Move this out of here into its own hook/component
  const urlParams = new URLSearchParams(location.search);
  const to = decodeURIComponent(urlParams.get('to') || '');
  if (ReactFrameService.isMobile()) {
    window.location.href = to;
  } else if (to.startsWith('/room-offer')) {
    // Includes roomID and offerID optional coupon code example:
    // https://hostname/dl/?to=/room-offer/236233/offer/62%3Fcoupon=APPLY45
    // https://hostname/dl/?to=/room-offer/236233/offer/62%3Fcoupon=APPLY45%26show_plans=160%2C161

    const roomID = to.replace(/\/room-offer\/(\d+)\/?.*/g, '$1');
    const offerID = to.replace(/.*\/offer\/(\d+)\/?.*/g, '$1');

    if (Number.isNaN(Number(roomID)) || Number.isNaN(Number(offerID))) {
      navigateToDefault();
    } else {
      // backward compatibility for old deeplinks
      const oldCoupon = to.includes('coupon=') ? to.replace(/.*coupon=/g, '') : null;

      const queryString = new URLSearchParams(to.split('?')[1]);

      if (!queryString.get('coupon') && oldCoupon) queryString.set('coupon', oldCoupon);

      const url = `/room/${roomID}?action=offer&offerID=${offerID}${
        queryString.toString() ? `&${queryString.toString()}` : ''
      }`;
      history.replace(url);
    }
  } else {
    safeRouteNavigateOrPush(history, to);
  }
  return <LoadingScreen />;
};

export default CrossPlatformDeepLink;
