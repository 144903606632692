import { ColumnsByBreakpoint, OffsetByBreakpoint, RowStyleProps } from './gridTypes';
import GridRow from '../../../components/grid/GridRow';
import { useGridContext } from './gridContext';
import { EmotionStyle } from '../../../core/styled';

type RowProps = {
  children: React.ReactNode;
  className?: string;
  style?: EmotionStyle;
} & ColumnsByBreakpoint &
  OffsetByBreakpoint &
  RowStyleProps;

const Row = ({ children, align, justify, ...props }: RowProps) => {
  const { gutterWidth } = useGridContext();
  return (
    <GridRow gutterWidth={gutterWidth} {...props}>
      {children}
    </GridRow>
  );
};

export default Row;
