import { Small, RouterLinkWrapper, useEmotionTheme } from '@talkspace/react-toolkit';
import { FunctionComponent } from 'react';
import { Link } from '@/core/routerLib/routerLib';
import isInWebView from '../SSOLogin/isInWebView';

const SSOLoginBackLink: FunctionComponent<{
  mode: 'logIn' | 'register';
}> = ({ mode }) => {
  const { colors } = useEmotionTheme();
  let linkPath;
  let linkText;

  if (mode === 'logIn') {
    linkPath = '/login';
    linkText = 'Sign In';
  } else {
    linkPath = '/signup';
    linkText = 'Sign Up';
  }

  if (isInWebView()) {
    linkPath = '/login/sso/close';
  }

  return (
    <Small style={{ marginTop: 10 }}>
      Not seeing your provider?{' '}
      <RouterLinkWrapper primaryColor={colors.green} roundedFocusStyle>
        <Link to={linkPath}>
          <Small
            inline
            style={{
              color: colors.green,
              fontWeight: 700,
            }}
          >
            Return to {linkText}
          </Small>
        </Link>
      </RouterLinkWrapper>
    </Small>
  );
};

export default SSOLoginBackLink;
