import { useReducer, useCallback, useEffect } from 'react';
import { toast } from '@talkspace/react-toolkit';
import { Preferences, APINotificationPreference } from 'ts-frontend/types/Notifications';
import {
  NotificationPreferencesByName,
  notificationPreferencesReducer,
  initialState,
  APIResponse,
  State,
} from '../reducers/notificationPreferences';
import API from '../utils/myAccountApiHelper';
import { getUserData } from '../../auth/helpers/token';
import AdminConfigAPI from '../../utils/adminConfig';

interface V3APIResponse {
  data: {
    sendMessageReceipts: boolean;
    soundNotification: boolean;
  };
}

const arrayToNotificationPreferencesByName = (
  arr: APINotificationPreference[]
): NotificationPreferencesByName =>
  arr.reduce((prev, { name = '', email = false, sms = false, push = false }) => {
    return {
      ...prev,
      [name as Preferences]: { email, sms, push },
    };
  }, {}) as NotificationPreferencesByName;

export default function useNotificationPreferences(): [
  State,
  {
    getNotificationPreferences;
    patchNotificationPreferences;
    setIsEditing;
  }
] {
  const [state, dispatch] = useReducer(notificationPreferencesReducer, initialState);

  useEffect(() => {
    dispatch({ type: 'requestAdminOptionSuperbills' });
    AdminConfigAPI.getAdminOptionByName('superbills')
      .then((res) => {
        dispatch({
          type: 'receiveAdminOptionSuperbills',
          payload: {
            superbillsFlag: !!res?.data?.data,
          },
        });
      })
      .catch((error) => {
        toast(error);
      });
  }, []);

  function getNotificationPreferences(): void {
    dispatch({ type: 'getNotificationPreferences' });
    const { id } = getUserData();
    Promise.all([API.getNotificationPreferences(id), API.getUserSettings(id)])
      .then(([notificationRes, settingsRes]) => {
        const { data: notificationData } = notificationRes.data as APIResponse;
        const { data: settingsData } = settingsRes.data as V3APIResponse;
        const formattedSettingsData: APINotificationPreference[] = Object.entries(settingsData).map(
          ([key, value]) => {
            return {
              name: key as Preferences,
              email: value,
              push: value,
              sms: value,
            };
          }
        );
        dispatch({
          type: 'receiveGetNotificationPreferences',
          payload: {
            notificationPreferencesByName: arrayToNotificationPreferencesByName([
              ...notificationData.categories,
              ...formattedSettingsData,
            ]),
          },
        });
      })
      .catch((error) => {
        const errorMessage = Number.isInteger(+error.message)
          ? 'There was an error. Please try again.'
          : error.message;
        toast(errorMessage);
        dispatch({ type: 'setIsError' });
      });
  }

  function patchNotificationPreferences(
    payload: APINotificationPreference[],
    formState: NotificationPreferencesByName
  ): void {
    const { id } = getUserData();
    const userSettingsUpdates = payload
      .filter((s) => ['soundNotification', 'sendMessagesReceipts'].includes(s.name))
      .reduce((prev, { name, email }) => {
        return { ...prev, [name]: !!email };
      }, {});
    const notificationPreferenceUpdates = payload.filter(
      (s) => !['soundNotification', 'sendMessagesReceipts'].includes(s.name)
    );

    const promises: Promise<{}>[] = [];
    if (Object.keys(userSettingsUpdates).length)
      promises.push(API.patchUserSettings(id, userSettingsUpdates));
    if (notificationPreferenceUpdates.length) {
      promises.push(
        API.patchNotificationPreferences(id, {
          categories: notificationPreferenceUpdates,
        })
      );
    }
    if (promises.length) {
      dispatch({ type: 'updateNotificationPreferences' });
      Promise.all(promises)
        .then(() => {
          dispatch({
            type: 'receiveUpdateNotificationPreferences',
            payload: {
              notificationPreferencesByName: formState,
              isEditing: false,
            },
          });
        })
        .catch((error) => {
          const errorMessage = Number.isInteger(+error.message)
            ? 'There was an error. Please try again.'
            : error.message;
          toast(errorMessage);
          dispatch({ type: 'setIsError' });
        });
    }
  }

  function setIsEditing(isEditing: boolean): void {
    dispatch({ type: 'setIsEditing', payload: { isEditing } });
  }

  return [
    state,
    {
      getNotificationPreferences: useCallback(getNotificationPreferences, []),
      patchNotificationPreferences: useCallback(patchNotificationPreferences, []),
      setIsEditing: useCallback(setIsEditing, []),
    },
  ];
}
