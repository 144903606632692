import { Spinner } from '@talkspace/react-toolkit';
import { Route } from '../core/routerLib';
import { getCachedTeenspaceEligibility } from './utils/communityStorage';
import useNavShellData from '../hooks/useNavShellData';
import { hasTeenspaceCommunity } from './utils/helpers';
import TeenspaceScreen from '../home/screens/TeenspaceScreen';
import TeenspacePostDetailsScreen from '../home/screens/TeenspacePostDetailsScreen';

const TeenspaceHomeRouter = () => {
  const cachedTeenspaceEligibility = getCachedTeenspaceEligibility();
  const { subscriptions } = useNavShellData();
  const shouldShowTeenspaceFeatures = subscriptions && hasTeenspaceCommunity(subscriptions);
  return shouldShowTeenspaceFeatures || cachedTeenspaceEligibility ? (
    <>
      <Route exact path={['/home/teenspace']} component={TeenspaceScreen} />
      <Route exact path={['/home/teenspace/post/:postID']} component={TeenspacePostDetailsScreen} />
    </>
  ) : (
    <Spinner />
  );
};

export default TeenspaceHomeRouter;
