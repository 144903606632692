import { createContext, useContext, useReducer, useCallback, FunctionComponent } from 'react';
import { SuperbillsActions, SuperbillsState } from '../types';

export const superbillsInitialState: SuperbillsState = {
  isNameVerified: false,
  currentSuperbill: null,
};

const StateContext = createContext<SuperbillsState>(superbillsInitialState);
export { StateContext as SuperbillsStateContext };

const ActionsContext = createContext<SuperbillsActions | undefined>(undefined);

function SuperbillsReducer(
  currentState: SuperbillsState,
  action: { type: string; payload?: Partial<SuperbillsState> }
): SuperbillsState {
  return { ...currentState, ...action.payload };
}

export const SuperbillsProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(SuperbillsReducer, superbillsInitialState);

  function setSuperbillsState(payload: Partial<SuperbillsState>): void {
    dispatch({
      type: 'setSuperbillsState',
      payload,
    });
  }

  const actions: SuperbillsActions = {
    setSuperbillsStateAction: useCallback(setSuperbillsState, []),
  };

  return (
    <StateContext.Provider value={state}>
      <ActionsContext.Provider value={actions}>{children}</ActionsContext.Provider>
    </StateContext.Provider>
  );
};

export function useSuperbillsState(): SuperbillsState {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error('StateContext must be used within a ContextProvider');
  }
  return context;
}

export function useSuperbillsActions() {
  const context = useContext(ActionsContext);
  if (context === undefined) {
    throw new Error('ActionsContext must be used within a ContextProvider');
  }
  return context;
}
