const clientChatKeys = {
  all: ['client'],
  canRematch: (roomID: number): string[] => [
    ...clientChatKeys.all,
    'canRematch',
    roomID.toString(),
  ],
};

export default clientChatKeys;
