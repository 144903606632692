import { FC } from 'react';
import { Breadcrumb, Col, Grid, TextDS, useWindowWidthState } from '@talkspace/react-toolkit';
import { BreadcrumbRoute } from '@talkspace/react-toolkit/src/designSystems/components/Breadcrumb';

interface AccountPageWrapperProps {
  title: string;
  breadcrumbRoutes?: BreadcrumbRoute[];
  id?: string;
  role?: string;
}

const AccountPageWrapper: FC<AccountPageWrapperProps> = ({
  title,
  breadcrumbRoutes,
  id,
  role,
  children,
}) => {
  const { isMobile } = useWindowWidthState();

  return (
    <Grid fluid rowSpacing={isMobile ? 'space0' : 'space400'} id={id} role={role}>
      <Col sm={8} lg={8}>
        {!isMobile && breadcrumbRoutes && <Breadcrumb routes={breadcrumbRoutes} />}
        <TextDS variant="heading2xl" style={{ marginBottom: 32 }}>
          {title}
        </TextDS>
        {children}
      </Col>
    </Grid>
  );
};

export default AccountPageWrapper;
