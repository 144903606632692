import { Defs, Path, Stop, Svg } from 'svgs';

import { EmojiSize } from '../../types';

const MendingHeartEmoji = ({ size }: { size: EmojiSize }) => {
  const title = 'mending heart emoji';
  return (
    <Svg
      title={title}
      aria-label={title}
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
    >
      <Path
        d="M32.9042 8.58585C31.3863 10.0506 30.4186 12.2698 30.4186 14.7538C30.4186 18.1346 32.2112 21.0248 34.7434 22.1903C31.5022 28.3921 25.2069 33.1482 21.3483 35.3844C20.6251 35.8036 19.7439 35.8272 19.0179 35.4129C16.1774 33.7921 12.2108 30.5922 9.15981 27.224C10.3027 25.7957 11.0026 23.8748 11.0026 21.7623C11.0026 17.8856 8.2767 13.6654 4.03027 13.778C5.30368 7.70448 12.4521 5.45927 16.8667 8.44602C17.6524 8.97758 18.8594 9.8769 19.6739 10.6034C20.0136 10.9063 20.5899 10.8743 20.9198 10.5608C21.3735 10.1298 21.9552 9.68464 22.5028 9.2656C22.6958 9.11794 22.8846 8.97351 23.062 8.83409C26.2582 6.32218 30.1207 6.61085 32.9042 8.58585Z"
        fill="url(#paint0_radial_4451_12852)"
      />
      <Path
        d="M32.9042 8.58585C31.3863 10.0506 30.4186 12.2698 30.4186 14.7538C30.4186 18.1346 32.2112 21.0248 34.7434 22.1903C31.5022 28.3921 25.2069 33.1482 21.3483 35.3844C20.6251 35.8036 19.7439 35.8272 19.0179 35.4129C16.1774 33.7921 12.2108 30.5922 9.15981 27.224C10.3027 25.7957 11.0026 23.8748 11.0026 21.7623C11.0026 17.8856 8.2767 13.6654 4.03027 13.778C5.30368 7.70448 12.4521 5.45927 16.8667 8.44602C17.6524 8.97758 18.8594 9.8769 19.6739 10.6034C20.0136 10.9063 20.5899 10.8743 20.9198 10.5608C21.3735 10.1298 21.9552 9.68464 22.5028 9.2656C22.6958 9.11794 22.8846 8.97351 23.062 8.83409C26.2582 6.32218 30.1207 6.61085 32.9042 8.58585Z"
        fill="url(#paint1_radial_4451_12852)"
      />
      <Path
        d="M32.9042 8.58585C31.3863 10.0506 30.4186 12.2698 30.4186 14.7538C30.4186 18.1346 32.2112 21.0248 34.7434 22.1903C31.5022 28.3921 25.2069 33.1482 21.3483 35.3844C20.6251 35.8036 19.7439 35.8272 19.0179 35.4129C16.1774 33.7921 12.2108 30.5922 9.15981 27.224C10.3027 25.7957 11.0026 23.8748 11.0026 21.7623C11.0026 17.8856 8.2767 13.6654 4.03027 13.778C5.30368 7.70448 12.4521 5.45927 16.8667 8.44602C17.6524 8.97758 18.8594 9.8769 19.6739 10.6034C20.0136 10.9063 20.5899 10.8743 20.9198 10.5608C21.3735 10.1298 21.9552 9.68464 22.5028 9.2656C22.6958 9.11794 22.8846 8.97351 23.062 8.83409C26.2582 6.32218 30.1207 6.61085 32.9042 8.58585Z"
        fill="url(#paint2_radial_4451_12852)"
      />
      <Path
        d="M32.9042 8.58585C31.3863 10.0506 30.4186 12.2698 30.4186 14.7538C30.4186 18.1346 32.2112 21.0248 34.7434 22.1903C31.5022 28.3921 25.2069 33.1482 21.3483 35.3844C20.6251 35.8036 19.7439 35.8272 19.0179 35.4129C16.1774 33.7921 12.2108 30.5922 9.15981 27.224C10.3027 25.7957 11.0026 23.8748 11.0026 21.7623C11.0026 17.8856 8.2767 13.6654 4.03027 13.778C5.30368 7.70448 12.4521 5.45927 16.8667 8.44602C17.6524 8.97758 18.8594 9.8769 19.6739 10.6034C20.0136 10.9063 20.5899 10.8743 20.9198 10.5608C21.3735 10.1298 21.9552 9.68464 22.5028 9.2656C22.6958 9.11794 22.8846 8.97351 23.062 8.83409C26.2582 6.32218 30.1207 6.61085 32.9042 8.58585Z"
        fill="url(#paint3_radial_4451_12852)"
      />
      <Path
        d="M32.9042 8.58585C31.3863 10.0506 30.4186 12.2698 30.4186 14.7538C30.4186 18.1346 32.2112 21.0248 34.7434 22.1903C31.5022 28.3921 25.2069 33.1482 21.3483 35.3844C20.6251 35.8036 19.7439 35.8272 19.0179 35.4129C16.1774 33.7921 12.2108 30.5922 9.15981 27.224C10.3027 25.7957 11.0026 23.8748 11.0026 21.7623C11.0026 17.8856 8.2767 13.6654 4.03027 13.778C5.30368 7.70448 12.4521 5.45927 16.8667 8.44602C17.6524 8.97758 18.8594 9.8769 19.6739 10.6034C20.0136 10.9063 20.5899 10.8743 20.9198 10.5608C21.3735 10.1298 21.9552 9.68464 22.5028 9.2656C22.6958 9.11794 22.8846 8.97351 23.062 8.83409C26.2582 6.32218 30.1207 6.61085 32.9042 8.58585Z"
        fill="url(#paint4_linear_4451_12852)"
      />
      <Path
        d="M32.9042 8.58585C31.3863 10.0506 30.4186 12.2698 30.4186 14.7538C30.4186 18.1346 32.2112 21.0248 34.7434 22.1903C31.5022 28.3921 25.2069 33.1482 21.3483 35.3844C20.6251 35.8036 19.7439 35.8272 19.0179 35.4129C16.1774 33.7921 12.2108 30.5922 9.15981 27.224C10.3027 25.7957 11.0026 23.8748 11.0026 21.7623C11.0026 17.8856 8.2767 13.6654 4.03027 13.778C5.30368 7.70448 12.4521 5.45927 16.8667 8.44602C17.6524 8.97758 18.8594 9.8769 19.6739 10.6034C20.0136 10.9063 20.5899 10.8743 20.9198 10.5608C21.3735 10.1298 21.9552 9.68464 22.5028 9.2656C22.6958 9.11794 22.8846 8.97351 23.062 8.83409C26.2582 6.32218 30.1207 6.61085 32.9042 8.58585Z"
        fill="url(#paint5_linear_4451_12852)"
      />
      <Path
        d="M32.9042 8.58585C31.3863 10.0506 30.4186 12.2698 30.4186 14.7538C30.4186 18.1346 32.2112 21.0248 34.7434 22.1903C31.5022 28.3921 25.2069 33.1482 21.3483 35.3844C20.6251 35.8036 19.7439 35.8272 19.0179 35.4129C16.1774 33.7921 12.2108 30.5922 9.15981 27.224C10.3027 25.7957 11.0026 23.8748 11.0026 21.7623C11.0026 17.8856 8.2767 13.6654 4.03027 13.778C5.30368 7.70448 12.4521 5.45927 16.8667 8.44602C17.6524 8.97758 18.8594 9.8769 19.6739 10.6034C20.0136 10.9063 20.5899 10.8743 20.9198 10.5608C21.3735 10.1298 21.9552 9.68464 22.5028 9.2656C22.6958 9.11794 22.8846 8.97351 23.062 8.83409C26.2582 6.32218 30.1207 6.61085 32.9042 8.58585Z"
        fill="url(#paint6_radial_4451_12852)"
      />
      <Path
        d="M32.9042 8.58585C31.3863 10.0506 30.4186 12.2698 30.4186 14.7538C30.4186 18.1346 32.2112 21.0248 34.7434 22.1903C31.5022 28.3921 25.2069 33.1482 21.3483 35.3844C20.6251 35.8036 19.7439 35.8272 19.0179 35.4129C16.1774 33.7921 12.2108 30.5922 9.15981 27.224C10.3027 25.7957 11.0026 23.8748 11.0026 21.7623C11.0026 17.8856 8.2767 13.6654 4.03027 13.778C5.30368 7.70448 12.4521 5.45927 16.8667 8.44602C17.6524 8.97758 18.8594 9.8769 19.6739 10.6034C20.0136 10.9063 20.5899 10.8743 20.9198 10.5608C21.3735 10.1298 21.9552 9.68464 22.5028 9.2656C22.6958 9.11794 22.8846 8.97351 23.062 8.83409C26.2582 6.32218 30.1207 6.61085 32.9042 8.58585Z"
        fill="url(#paint7_radial_4451_12852)"
      />
      <Path
        d="M32.9042 8.58585C31.3863 10.0506 30.4186 12.2698 30.4186 14.7538C30.4186 18.1346 32.2112 21.0248 34.7434 22.1903C31.5022 28.3921 25.2069 33.1482 21.3483 35.3844C20.6251 35.8036 19.7439 35.8272 19.0179 35.4129C16.1774 33.7921 12.2108 30.5922 9.15981 27.224C10.3027 25.7957 11.0026 23.8748 11.0026 21.7623C11.0026 17.8856 8.2767 13.6654 4.03027 13.778C5.30368 7.70448 12.4521 5.45927 16.8667 8.44602C17.6524 8.97758 18.8594 9.8769 19.6739 10.6034C20.0136 10.9063 20.5899 10.8743 20.9198 10.5608C21.3735 10.1298 21.9552 9.68464 22.5028 9.2656C22.6958 9.11794 22.8846 8.97351 23.062 8.83409C26.2582 6.32218 30.1207 6.61085 32.9042 8.58585Z"
        fill="url(#paint8_radial_4451_12852)"
      />
      <Path
        d="M32.9042 8.58585C31.3863 10.0506 30.4186 12.2698 30.4186 14.7538C30.4186 18.1346 32.2112 21.0248 34.7434 22.1903C31.5022 28.3921 25.2069 33.1482 21.3483 35.3844C20.6251 35.8036 19.7439 35.8272 19.0179 35.4129C16.1774 33.7921 12.2108 30.5922 9.15981 27.224C10.3027 25.7957 11.0026 23.8748 11.0026 21.7623C11.0026 17.8856 8.2767 13.6654 4.03027 13.778C5.30368 7.70448 12.4521 5.45927 16.8667 8.44602C17.6524 8.97758 18.8594 9.8769 19.6739 10.6034C20.0136 10.9063 20.5899 10.8743 20.9198 10.5608C21.3735 10.1298 21.9552 9.68464 22.5028 9.2656C22.6958 9.11794 22.8846 8.97351 23.062 8.83409C26.2582 6.32218 30.1207 6.61085 32.9042 8.58585Z"
        fill="url(#paint9_radial_4451_12852)"
      />
      <Path
        d="M32.9042 8.58585C31.3863 10.0506 30.4186 12.2698 30.4186 14.7538C30.4186 18.1346 32.2112 21.0248 34.7434 22.1903C31.5022 28.3921 25.2069 33.1482 21.3483 35.3844C20.6251 35.8036 19.7439 35.8272 19.0179 35.4129C16.1774 33.7921 12.2108 30.5922 9.15981 27.224C10.3027 25.7957 11.0026 23.8748 11.0026 21.7623C11.0026 17.8856 8.2767 13.6654 4.03027 13.778C5.30368 7.70448 12.4521 5.45927 16.8667 8.44602C17.6524 8.97758 18.8594 9.8769 19.6739 10.6034C20.0136 10.9063 20.5899 10.8743 20.9198 10.5608C21.3735 10.1298 21.9552 9.68464 22.5028 9.2656C22.6958 9.11794 22.8846 8.97351 23.062 8.83409C26.2582 6.32218 30.1207 6.61085 32.9042 8.58585Z"
        fill="url(#paint10_radial_4451_12852)"
      />
      <Path
        d="M32.9042 8.58585C31.3863 10.0506 30.4186 12.2698 30.4186 14.7538C30.4186 18.1346 32.2112 21.0248 34.7434 22.1903C31.5022 28.3921 25.2069 33.1482 21.3483 35.3844C20.6251 35.8036 19.7439 35.8272 19.0179 35.4129C16.1774 33.7921 12.2108 30.5922 9.15981 27.224C10.3027 25.7957 11.0026 23.8748 11.0026 21.7623C11.0026 17.8856 8.2767 13.6654 4.03027 13.778C5.30368 7.70448 12.4521 5.45927 16.8667 8.44602C17.6524 8.97758 18.8594 9.8769 19.6739 10.6034C20.0136 10.9063 20.5899 10.8743 20.9198 10.5608C21.3735 10.1298 21.9552 9.68464 22.5028 9.2656C22.6958 9.11794 22.8846 8.97351 23.062 8.83409C26.2582 6.32218 30.1207 6.61085 32.9042 8.58585Z"
        fill="url(#paint11_radial_4451_12852)"
      />
      <Path
        d="M32.9042 8.58585C31.3863 10.0506 30.4186 12.2698 30.4186 14.7538C30.4186 18.1346 32.2112 21.0248 34.7434 22.1903C31.5022 28.3921 25.2069 33.1482 21.3483 35.3844C20.6251 35.8036 19.7439 35.8272 19.0179 35.4129C16.1774 33.7921 12.2108 30.5922 9.15981 27.224C10.3027 25.7957 11.0026 23.8748 11.0026 21.7623C11.0026 17.8856 8.2767 13.6654 4.03027 13.778C5.30368 7.70448 12.4521 5.45927 16.8667 8.44602C17.6524 8.97758 18.8594 9.8769 19.6739 10.6034C20.0136 10.9063 20.5899 10.8743 20.9198 10.5608C21.3735 10.1298 21.9552 9.68464 22.5028 9.2656C22.6958 9.11794 22.8846 8.97351 23.062 8.83409C26.2582 6.32218 30.1207 6.61085 32.9042 8.58585Z"
        fill="url(#paint12_radial_4451_12852)"
      />
      <Path
        d="M32.9042 8.58585C31.3863 10.0506 30.4186 12.2698 30.4186 14.7538C30.4186 18.1346 32.2112 21.0248 34.7434 22.1903C31.5022 28.3921 25.2069 33.1482 21.3483 35.3844C20.6251 35.8036 19.7439 35.8272 19.0179 35.4129C16.1774 33.7921 12.2108 30.5922 9.15981 27.224C10.3027 25.7957 11.0026 23.8748 11.0026 21.7623C11.0026 17.8856 8.2767 13.6654 4.03027 13.778C5.30368 7.70448 12.4521 5.45927 16.8667 8.44602C17.6524 8.97758 18.8594 9.8769 19.6739 10.6034C20.0136 10.9063 20.5899 10.8743 20.9198 10.5608C21.3735 10.1298 21.9552 9.68464 22.5028 9.2656C22.6958 9.11794 22.8846 8.97351 23.062 8.83409C26.2582 6.32218 30.1207 6.61085 32.9042 8.58585Z"
        fill="url(#paint13_radial_4451_12852)"
      />
      <Path
        d="M32.9042 8.58585C31.3863 10.0506 30.4186 12.2698 30.4186 14.7538C30.4186 18.1346 32.2112 21.0248 34.7434 22.1903C31.5022 28.3921 25.2069 33.1482 21.3483 35.3844C20.6251 35.8036 19.7439 35.8272 19.0179 35.4129C16.1774 33.7921 12.2108 30.5922 9.15981 27.224C10.3027 25.7957 11.0026 23.8748 11.0026 21.7623C11.0026 17.8856 8.2767 13.6654 4.03027 13.778C5.30368 7.70448 12.4521 5.45927 16.8667 8.44602C17.6524 8.97758 18.8594 9.8769 19.6739 10.6034C20.0136 10.9063 20.5899 10.8743 20.9198 10.5608C21.3735 10.1298 21.9552 9.68464 22.5028 9.2656C22.6958 9.11794 22.8846 8.97351 23.062 8.83409C26.2582 6.32218 30.1207 6.61085 32.9042 8.58585Z"
        fill="url(#paint14_linear_4451_12852)"
      />
      <Path
        d="M5.5384 22.2432H10.6486C15.1436 19.4808 20.4935 16.1731 24.4854 13.6992H4.04601C3.61346 15.6777 3.80703 18.0672 4.92893 20.8185C5.08167 21.2774 5.28728 21.754 5.5384 22.2432Z"
        fill="url(#paint15_linear_4451_12852)"
      />
      <Path
        d="M5.5384 22.2432H10.6486C15.1436 19.4808 20.4935 16.1731 24.4854 13.6992H4.04601C3.61346 15.6777 3.80703 18.0672 4.92893 20.8185C5.08167 21.2774 5.28728 21.754 5.5384 22.2432Z"
        fill="url(#paint16_linear_4451_12852)"
      />
      <Path
        d="M5.5384 22.2432H10.6486C15.1436 19.4808 20.4935 16.1731 24.4854 13.6992H4.04601C3.61346 15.6777 3.80703 18.0672 4.92893 20.8185C5.08167 21.2774 5.28728 21.754 5.5384 22.2432Z"
        fill="url(#paint17_linear_4451_12852)"
      />
      <Path
        d="M5.5384 22.2432H10.6486C15.1436 19.4808 20.4935 16.1731 24.4854 13.6992H4.04601C3.61346 15.6777 3.80703 18.0672 4.92893 20.8185C5.08167 21.2774 5.28728 21.754 5.5384 22.2432Z"
        fill="url(#paint18_linear_4451_12852)"
      />
      <Path
        d="M36.3675 13.6992H32.9882L17.7734 22.2432H34.7129C35.0441 21.614 35.3441 20.9697 35.6069 20.3117C36.6815 17.9247 36.8405 15.6602 36.3675 13.6992Z"
        fill="url(#paint19_linear_4451_12852)"
      />
      <Path
        d="M36.3675 13.6992H32.9882L17.7734 22.2432H34.7129C35.0441 21.614 35.3441 20.9697 35.6069 20.3117C36.6815 17.9247 36.8405 15.6602 36.3675 13.6992Z"
        fill="url(#paint20_linear_4451_12852)"
      />
      <Path
        d="M36.3675 13.6992H32.9882L17.7734 22.2432H34.7129C35.0441 21.614 35.3441 20.9697 35.6069 20.3117C36.6815 17.9247 36.8405 15.6602 36.3675 13.6992Z"
        fill="url(#paint21_linear_4451_12852)"
      />
      <Path
        d="M36.3675 13.6992H32.9882L17.7734 22.2432H34.7129C35.0441 21.614 35.3441 20.9697 35.6069 20.3117C36.6815 17.9247 36.8405 15.6602 36.3675 13.6992Z"
        fill="url(#paint22_linear_4451_12852)"
      />
      <Path
        d="M9.18737 27.2474C11.9463 26.0276 17.2723 23.6248 22.6684 21.1904L22.6755 21.1872C28.0734 18.752 33.5404 16.2856 36.577 14.9406C36.3124 12.2172 34.862 9.95143 32.8641 8.55078C25.7846 11.5859 10.168 19.3364 5.30566 21.762C6.09619 23.4856 7.50381 25.3927 9.18737 27.2474Z"
        fill="url(#paint23_linear_4451_12852)"
      />
      <Path
        d="M9.18737 27.2474C11.9463 26.0276 17.2723 23.6248 22.6684 21.1904L22.6755 21.1872C28.0734 18.752 33.5404 16.2856 36.577 14.9406C36.3124 12.2172 34.862 9.95143 32.8641 8.55078C25.7846 11.5859 10.168 19.3364 5.30566 21.762C6.09619 23.4856 7.50381 25.3927 9.18737 27.2474Z"
        fill="url(#paint24_linear_4451_12852)"
      />
      <Path
        d="M9.18737 27.2474C11.9463 26.0276 17.2723 23.6248 22.6684 21.1904L22.6755 21.1872C28.0734 18.752 33.5404 16.2856 36.577 14.9406C36.3124 12.2172 34.862 9.95143 32.8641 8.55078C25.7846 11.5859 10.168 19.3364 5.30566 21.762C6.09619 23.4856 7.50381 25.3927 9.18737 27.2474Z"
        fill="url(#paint25_linear_4451_12852)"
      />
      <Path
        d="M9.18737 27.2474C11.9463 26.0276 17.2723 23.6248 22.6684 21.1904L22.6755 21.1872C28.0734 18.752 33.5404 16.2856 36.577 14.9406C36.3124 12.2172 34.862 9.95143 32.8641 8.55078C25.7846 11.5859 10.168 19.3364 5.30566 21.762C6.09619 23.4856 7.50381 25.3927 9.18737 27.2474Z"
        fill="url(#paint26_linear_4451_12852)"
      />
      <Path
        d="M9.18737 27.2474C11.9463 26.0276 17.2723 23.6248 22.6684 21.1904L22.6755 21.1872C28.0734 18.752 33.5404 16.2856 36.577 14.9406C36.3124 12.2172 34.862 9.95143 32.8641 8.55078C25.7846 11.5859 10.168 19.3364 5.30566 21.762C6.09619 23.4856 7.50381 25.3927 9.18737 27.2474Z"
        fill="url(#paint27_linear_4451_12852)"
      />
      <Defs>
        <radialGradient
          id="paint0_radial_4451_12852"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.9997 -2.52938) rotate(88.5481) scale(37.8758 39.0029)"
        >
          <Stop stopColor="#F12E52" />
          <Stop offset="0.67675" stopColor="#FF3B79" />
          <Stop offset="1" stopColor="#FF4EC6" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_4451_12852"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28.3626 14.5139) rotate(142.986) scale(30.8572 57.7985)"
        >
          <Stop offset="0.483055" stopColor="#B91D43" stopOpacity="0" />
          <Stop offset="0.833898" stopColor="#B91D43" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_4451_12852"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.7998 22.074) rotate(-158.663) scale(26.766 37.8374)"
        >
          <Stop offset="0.40984" stopColor="#B91D43" stopOpacity="0" />
          <Stop offset="0.833898" stopColor="#B91D43" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_4451_12852"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.5798 17.3993) rotate(90) scale(23.2952 23.2952)"
        >
          <Stop offset="0.671096" stopColor="#FF7AF2" stopOpacity="0" />
          <Stop offset="0.933555" stopColor="#FFBCE6" />
        </radialGradient>
        <linearGradient
          id="paint4_linear_4451_12852"
          x1="22.1508"
          y1="20.1262"
          x2="31.578"
          y2="31.3453"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.694491" stopColor="#FF7AF2" stopOpacity="0" />
          <Stop offset="0.933555" stopColor="#FFBCE6" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_4451_12852"
          x1="20.9822"
          y1="20.5937"
          x2="9.9189"
          y2="33.0593"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.694491" stopColor="#FF7AF2" stopOpacity="0" />
          <Stop offset="0.933555" stopColor="#FFBCE6" />
        </linearGradient>
        <radialGradient
          id="paint6_radial_4451_12852"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.8588 16.3171) rotate(164.618) scale(20.2543 21.3903)"
        >
          <Stop offset="0.802083" stopColor="#FF73A6" stopOpacity="0" />
          <Stop offset="1" stopColor="#FF93BA" stopOpacity="0.6" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_4451_12852"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.1484 11.4451) rotate(-90) scale(13.9531 16.625)"
        >
          <Stop stopColor="#FC0D1A" stopOpacity="0.6" />
          <Stop offset="0.802083" stopColor="#FC1020" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_4451_12852"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.7531 12.0683) rotate(129.227) scale(12.0069 7.92673)"
        >
          <Stop stopColor="#FF93BA" stopOpacity="0.5" />
          <Stop offset="1" stopColor="#FF73A6" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_4451_12852"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.0817 15.3898) rotate(45.5907) scale(3.88546 2.5651)"
        >
          <Stop stopColor="#FF93BA" stopOpacity="0.4" />
          <Stop offset="1" stopColor="#FF73A6" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint10_radial_4451_12852"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28.4107 9.04553) rotate(176.279) scale(3.49103 2.26838)"
        >
          <Stop stopColor="#FF93BA" stopOpacity="0.3" />
          <Stop offset="1" stopColor="#FF73A6" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint11_radial_4451_12852"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.5871 9.1305) rotate(1.07082) scale(3.03104 1.55077)"
        >
          <Stop stopColor="#FF93BA" stopOpacity="0.3" />
          <Stop offset="1" stopColor="#FF73A6" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint12_radial_4451_12852"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.3497 16.636) rotate(-90) scale(6.82574 2.49543)"
        >
          <Stop stopColor="#FA1E3A" stopOpacity="0.7" />
          <Stop offset="1" stopColor="#FA1E3A" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint13_radial_4451_12852"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(29.3784 14.5056) rotate(-151.991) scale(7.48557)"
        >
          <Stop stopColor="#FA1E3A" stopOpacity="0.7" />
          <Stop offset="1" stopColor="#FA1E3A" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint14_linear_4451_12852"
          x1="19.3869"
          y1="7.02734"
          x2="19.3869"
          y2="35.7115"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.351631" stopOpacity="0" />
          <Stop offset="0.476662" stopOpacity="0.5" />
          <Stop offset="0.56221" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_4451_12852"
          x1="22.713"
          y1="17.1807"
          x2="6.37723"
          y2="24.4991"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#E3DEEA" />
          <Stop offset="1" stopColor="#B4A4C6" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_4451_12852"
          x1="2.77477"
          y1="22.2432"
          x2="8.23349"
          y2="22.2432"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#9A94A0" />
          <Stop offset="1" stopColor="#9A94A0" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_4451_12852"
          x1="21.1235"
          y1="15.6879"
          x2="17.4407"
          y2="11.9315"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#BBBBBB" />
          <Stop offset="1" stopColor="#C8C3C9" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_4451_12852"
          x1="14.0582"
          y1="12.4471"
          x2="14.0582"
          y2="14.3989"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D0CBD0" />
          <Stop offset="1" stopColor="#D0CBD0" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_4451_12852"
          x1="35.2151"
          y1="17.1807"
          x2="19.8613"
          y2="23.4679"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#E3DEEA" />
          <Stop offset="1" stopColor="#B4A4C6" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_4451_12852"
          x1="16.9909"
          y1="22.2432"
          x2="21.9803"
          y2="22.2432"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#9A94A0" />
          <Stop offset="1" stopColor="#9A94A0" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_4451_12852"
          x1="25.0271"
          y1="17.2347"
          x2="27.3043"
          y2="20.5492"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.348958" stopColor="#B6A2C7" />
          <Stop offset="0.869792" stopColor="#B6A2C7" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_4451_12852"
          x1="30.7722"
          y1="24.7475"
          x2="30.8756"
          y2="21.0864"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.156141" stopColor="#AB7ADA" />
          <Stop offset="1" stopColor="#AB7ADA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_4451_12852"
          x1="34.4556"
          y1="16.2018"
          x2="11.4015"
          y2="26.8081"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.119792" stopColor="#F1F0F4" />
          <Stop offset="1" stopColor="#B4A4C6" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_4451_12852"
          x1="27.2742"
          y1="22.573"
          x2="25.0646"
          y2="17.6381"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#C4C4C4" />
          <Stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_4451_12852"
          x1="25.6907"
          y1="21.0262"
          x2="24.8436"
          y2="19.0743"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#AB7ADA" />
          <Stop offset="1" stopColor="#AB7ADA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_4451_12852"
          x1="22.6708"
          y1="12.6663"
          x2="23.4073"
          y2="14.2131"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#A2A2A2" />
          <Stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_4451_12852"
          x1="20.9242"
          y1="24.4512"
          x2="4.62523"
          y2="28.5759"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#B4A4C6" stopOpacity="0" />
          <Stop offset="0.956415" stopColor="#A899B9" />
        </linearGradient>
      </Defs>
    </Svg>
  );
};

export default MendingHeartEmoji;
