import { useCallback, useState } from 'react';
import { Preferences } from 'ts-frontend/types/Notifications';
import DailyPromptNotificationScreen from '../components/DailyPromptNotificationScreen';
import useMutationPatchNotificationPreferences from '../hooks/useMutationPatchNotificationPreferences';
import { useCloseModal } from '../../utils/ModalsContextProvider';
import { TEENSPACE } from '../constants';

const categories = [
  { name: 'communityDailyPrompt' as Preferences, push: true, email: false, sms: false },
];

const DailyPromptNotificationScreenContainer = () => {
  const closeModal = useCloseModal();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { mutate: patchNotificationPreferences } = useMutationPatchNotificationPreferences();

  const onPressRemindMe = useCallback(() => {
    setIsLoading(true);
    patchNotificationPreferences(
      { categories },
      {
        onSuccess: () => {
          setIsLoading(false);
          closeModal({ navigateTo: TEENSPACE });
        },
        onError: () => {
          setIsError(true);
          setIsLoading(false);
        },
      }
    );
  }, [setIsLoading, setIsError, patchNotificationPreferences, closeModal]);

  const onClose = useCallback(() => {
    closeModal({ navigateTo: TEENSPACE });
  }, [closeModal]);

  const onPressSkip = useCallback(() => {
    closeModal({ navigateTo: TEENSPACE });
  }, [closeModal]);

  return (
    <DailyPromptNotificationScreen
      isLoading={isLoading}
      isError={isError}
      onPressRemindMe={onPressRemindMe}
      onPressSkip={onPressSkip}
      onClose={onClose}
    />
  );
};

export default DailyPromptNotificationScreenContainer;
