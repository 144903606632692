import { useQuery } from 'react-query';

import { Booking } from 'ts-frontend/types';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { clientBookingsAllRoomsQueryKey } from '../utils/queryKeys';

interface QueryProps {
  clientUserID: number | string;
  includeAsync?: boolean;
}

const fetchBookingsAllRooms =
  ({ clientUserID, includeAsync }: QueryProps) =>
  async () => {
    const {
      data: { data },
    } = await apiWrapper.get(
      `${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/bookings${
        includeAsync ? '?includeAsync=true' : ''
      }`
    );
    return data;
  };

const useQueryBookingsAllRooms = ({ clientUserID, includeAsync }: QueryProps) =>
  useQuery<Booking[], Error>({
    queryKey: clientBookingsAllRoomsQueryKey({ clientUserID, includeAsync }),
    queryFn: fetchBookingsAllRooms({ clientUserID, includeAsync }),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(clientUserID),
  });

export default useQueryBookingsAllRooms;
