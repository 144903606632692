/* eslint-disable import/prefer-default-export */
export function getVWOWidgetsHeight(): number {
  const safeZeroParseInt = (strValue) => {
    const parsed = parseInt(strValue, 10);
    if (Number.isNaN(parsed)) return 0;
    return parsed;
  };

  const computedBodyStyle = getComputedStyle(document.body);
  const marginTop = safeZeroParseInt(
    computedBodyStyle.getPropertyValue('margin-top').replace('px', '')
  );
  const marginBottom = safeZeroParseInt(
    computedBodyStyle.getPropertyValue('margin-bottom').replace('px', '')
  );
  return marginTop + marginBottom;
}
