const AUTH_ROUTES = [
  '/login',
  '/login/sso',
  '/login/sso/callback',
  '/login/sso/callback',
  '/login/sso/register',
  '/login/sso/close',
  '/signup',
  '/forgot-password',
  '/reset-password',
  '/change-password',
  '/oauth',
  '/unauthorized',
];

const isAuthPath = (pathname: string) =>
  AUTH_ROUTES.some((authRoute) => pathname.startsWith(authRoute));

export default isAuthPath;
