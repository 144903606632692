import Svg, { Path } from 'svgs';

const TeenspaceNotificationPrompt = () => {
  const titleText = 'teenspace notification prompt';
  return (
    <Svg
      width="327"
      height="216"
      viewBox="0 0 327 216"
      fill="none"
      aria-label={titleText}
      title={titleText}
    >
      <Path
        d="M230 37.1043C242.152 31.6858 244.578 18.7771 244.273 13C246.393 30.5304 252.768 36.8387 255.691 37.8015C245.414 39.3952 242.03 50.0196 241.622 55.1327C240.399 38.7179 233.364 36.2742 230 37.1043Z"
        fill="#D2E9E6"
      />
      <Path
        d="M82 197.104C94.1521 191.686 96.5784 178.777 96.2726 173C98.3931 190.53 104.768 196.839 107.691 197.802C97.4144 199.395 94.0297 210.02 93.622 215.133C92.3986 198.718 85.3643 196.274 82 197.104Z"
        fill="#D2E9E6"
      />
      <Path
        d="M214.694 107.94L139.808 95.6564L117.099 92.0083L109.309 90.782C109.127 90.7512 108.944 90.7295 108.76 90.717C108.936 90.7644 109.117 90.8015 109.3 90.8278L110.906 91.1063L117.069 92.1281L139.775 95.7887L214.756 107.627C244.022 112.234 270.526 116.428 289.689 119.544C299.268 121.099 307.021 122.38 312.371 123.295C315.053 123.752 317.126 124.117 318.529 124.377C319.254 124.477 319.969 124.627 320.663 124.824C319.936 124.787 319.208 124.704 318.489 124.576C317.074 124.384 314.983 124.076 312.297 123.67C306.927 122.855 299.161 121.641 289.574 120.093C270.381 117.097 243.919 112.751 214.694 107.94Z"
        fill="#D2E9E6"
      />
      <Path
        d="M213.244 112.239L140.921 92.3734L118.97 86.426L112.984 84.835L111.422 84.4344C111.073 84.3508 110.893 84.3096 110.884 84.3111C111.052 84.3775 111.227 84.4338 111.407 84.4793L112.948 84.9195L118.903 86.5701L140.852 92.53L213.37 111.963C241.682 119.53 267.313 126.413 285.835 131.468C295.099 133.997 302.58 136.064 307.74 137.52C310.323 138.245 312.322 138.822 313.671 139.223C314.373 139.399 315.054 139.62 315.705 139.883C314.984 139.772 314.272 139.615 313.581 139.415C312.201 139.077 310.178 138.557 307.578 137.881C302.374 136.521 294.853 134.521 285.584 132.018C267.038 127.019 241.465 120.011 213.244 112.239Z"
        fill="#D2E9E6"
      />
      <Path
        d="M210.782 116.264L142.833 89.2076L122.192 81.0737L116.554 78.8974L115.081 78.339C114.918 78.2722 114.748 78.2138 114.573 78.1645C114.725 78.2462 114.885 78.319 115.054 78.3819L116.5 78.9748L122.086 81.216L142.725 89.3582L210.983 115.999C237.634 126.389 261.746 135.821 279.155 142.716C287.856 146.165 294.887 148.977 299.726 150.942C302.117 151.924 304.018 152.7 305.276 153.233C305.943 153.479 306.577 153.769 307.167 154.098C306.468 153.914 305.79 153.685 305.144 153.414C303.836 152.938 301.923 152.216 299.472 151.28C294.563 149.403 287.463 146.649 278.77 143.227C261.324 136.391 237.293 126.838 210.782 116.264Z"
        fill="#D2E9E6"
      />
      <Path
        d="M207.381 119.908L145.518 86.3438L126.702 76.2333L121.554 73.5069L120.207 72.8051C120.059 72.7206 119.901 72.6453 119.734 72.5803C119.868 72.6752 120.013 72.7627 120.167 72.8418L121.483 73.5731L126.569 76.3499L145.376 86.4719L207.634 119.677C231.956 132.631 253.957 144.377 269.815 152.943C277.744 157.224 284.145 160.707 288.543 163.139C290.741 164.344 292.438 165.303 293.582 165.955C294.191 166.267 294.759 166.615 295.277 166.995C294.619 166.738 293.992 166.442 293.403 166.112C292.232 165.498 290.469 164.606 288.214 163.431C283.729 161.079 277.272 157.653 269.32 153.389C253.407 144.87 231.524 133.018 207.381 119.908Z"
        fill="#D2E9E6"
      />
      <Path
        d="M203.117 123.082L148.789 83.8819L132.251 72.0508L127.714 68.8534L126.526 68.0306C126.387 67.9297 126.24 67.8347 126.084 67.7463C126.196 67.8538 126.321 67.9554 126.457 68.0499L127.583 68.893L132.041 72.1337C135.965 74.9381 141.603 78.9788 148.577 83.9731L203.428 122.881C224.836 138.057 244.192 151.814 258.123 161.825C265.089 166.827 270.702 170.894 274.551 173.721C276.481 175.137 277.931 176.238 278.958 176.992C279.496 177.361 279.986 177.76 280.422 178.185C279.817 177.868 279.255 177.515 278.743 177.13C277.682 176.417 276.135 175.349 274.159 173.968C270.184 171.195 264.514 167.187 257.518 162.203C243.527 152.237 224.305 138.39 203.117 123.082Z"
        fill="#D2E9E6"
      />
      <Path
        d="M198.105 125.717L152.619 81.8601L138.746 68.6099L134.935 65.0029C134.497 64.6031 134.164 64.2971 133.93 64.0755C133.824 63.9652 133.701 63.8623 133.564 63.7688C133.659 63.8825 133.762 63.9934 133.873 64.101L134.83 65.0464L138.56 68.6666L152.419 81.9232L198.452 125.535C216.416 142.539 232.685 157.988 244.362 169.174C250.202 174.776 254.893 179.325 258.107 182.482C259.734 184.061 260.952 185.288 261.774 186.131C262.228 186.545 262.626 186.985 262.962 187.443C262.433 187.069 261.953 186.665 261.527 186.238C260.626 185.434 259.338 184.235 257.654 182.681C254.319 179.579 249.536 175.071 243.668 169.482C231.933 158.329 215.84 142.84 198.105 125.717Z"
        fill="#D2E9E6"
      />
      <Path
        d="M192.464 127.745L156.933 80.3011L146.068 65.9563L143.079 62.049L142.266 61.0577C142.181 60.9415 142.085 60.8287 141.978 60.7201C142.034 60.8422 142.11 60.9608 142.205 61.0738C142.382 61.3208 142.626 61.6598 142.946 62.0969L145.856 66.0132L156.707 80.3643L192.85 127.626C206.971 146.066 219.725 162.787 228.853 174.904C233.42 180.967 237.088 185.883 239.581 189.291C240.834 190.996 241.787 192.323 242.422 193.227C242.782 193.677 243.082 194.146 243.316 194.626C242.868 194.209 242.477 193.768 242.148 193.308C241.43 192.426 240.395 191.125 239.08 189.44C236.468 186.077 232.704 181.189 228.097 175.133C218.89 163.025 206.318 146.264 192.464 127.745Z"
        fill="#D2E9E6"
      />
      <Path
        d="M186.333 129.117C176.708 109.636 167.996 92.0091 161.679 79.2353C158.485 72.8685 155.897 67.7142 154.097 64.1358C153.193 62.3653 152.482 60.9905 151.992 60.037L151.433 58.9738C151.375 58.8517 151.301 58.7323 151.213 58.6169C151.25 58.7411 151.302 58.864 151.366 58.9848L151.872 60.0567C152.329 61.0136 153.015 62.3845 153.883 64.1709L161.446 79.2716C167.929 92.02 176.865 109.622 186.754 129.046C196.642 148.469 205.58 166.063 211.927 178.816C215.106 185.193 217.64 190.36 219.356 193.938C220.211 195.727 220.867 197.119 221.287 198.066C221.547 198.542 221.739 199.03 221.859 199.522C221.509 199.062 221.22 198.585 220.997 198.097C220.484 197.158 219.746 195.791 218.818 194.013C216.935 190.46 214.321 185.31 211.101 178.939C204.672 166.212 195.941 148.586 186.333 129.117Z"
        fill="#D2E9E6"
      />
      <Path
        d="M179.867 129.796C174.761 109.841 170.127 91.7862 166.768 78.6961C165.052 72.1711 163.653 66.8861 162.717 63.2203C162.212 61.4225 161.829 59.9919 161.557 59.0167C161.424 58.5447 161.314 58.1838 161.236 57.9224C161.205 57.7975 161.163 57.6732 161.11 57.5502C161.112 57.6749 161.132 57.8005 161.171 57.925C161.229 58.1876 161.319 58.5498 161.426 59.022C161.673 59.9973 162.026 61.4148 162.488 63.2316L166.539 78.7116L180.317 129.772C185.718 149.701 190.573 167.752 193.968 180.822C195.669 187.357 197.008 192.65 197.889 196.307C198.335 198.138 198.661 199.56 198.865 200.524C199.019 201.015 199.097 201.509 199.097 202C198.851 201.522 198.67 201.033 198.558 200.54C198.256 199.582 197.839 198.163 197.32 196.338C196.284 192.686 194.84 187.403 193.1 180.869C189.607 167.797 184.955 149.739 179.867 129.796Z"
        fill="#D2E9E6"
      />
      <Path
        d="M173.212 129.762C172.736 109.827 172.311 91.7906 171.998 78.7162C171.794 72.193 171.629 66.9089 171.513 63.2434C171.44 61.4232 171.376 60.0086 171.337 59.0315C171.307 58.5627 171.288 58.199 171.275 57.9391C171.273 57.8138 171.257 57.6877 171.226 57.5621C171.204 57.6838 171.196 57.8077 171.205 57.9324C171.205 58.1945 171.205 58.5553 171.209 59.0242C171.223 60.0016 171.254 61.4155 171.29 63.2297C171.399 66.8984 171.557 72.1815 171.749 78.7028C172.24 91.7881 172.918 109.817 173.659 129.785C174.401 149.753 175.08 167.774 175.434 180.838C175.617 187.371 175.724 192.654 175.759 196.303C175.774 198.126 175.765 199.543 175.756 200.504C175.791 200.997 175.753 201.486 175.643 201.964C175.511 201.471 175.444 200.976 175.444 200.484C175.362 199.522 175.269 198.098 175.184 196.272C174.995 192.613 174.78 187.325 174.558 180.79C174.115 167.721 173.665 149.681 173.212 129.762Z"
        fill="#D2E9E6"
      />
      <Path
        d="M166.542 129.017C170.649 109.593 174.367 92.0154 177.058 79.2721C178.36 72.9087 179.409 67.7598 180.142 64.1807C180.492 62.4058 180.764 61.0268 180.953 60.0727C181.043 59.6144 181.113 59.2602 181.163 59.0061C181.195 58.8846 181.209 58.7602 181.205 58.6345C181.171 58.7552 181.147 58.8775 181.132 59.0012C181.082 59.2553 181.012 59.6095 180.884 60.0618C180.695 61.0159 180.379 62.3881 179.997 64.158C179.291 67.7369 178.207 72.8975 176.907 79.2485C174.385 92.0183 170.906 109.634 167.061 129.103C163.216 148.572 159.738 166.184 157.089 178.912C155.766 185.281 154.659 190.429 153.861 193.981C153.458 195.756 153.128 197.135 152.886 198.063C152.806 198.545 152.659 199.017 152.447 199.472C152.423 198.98 152.469 198.492 152.586 198.016C152.728 197.067 152.97 195.679 153.297 193.892C153.952 190.313 154.956 185.132 156.236 178.778C158.718 166.015 162.411 148.429 166.542 129.017Z"
        fill="#D2E9E6"
      />
      <Path
        d="M160.006 127.593C168.611 109.148 176.399 92.4556 182.042 80.3704C184.813 74.3241 187.053 69.4272 188.61 66.0251C189.364 64.3352 189.946 63.0221 190.351 62.115C190.541 61.6767 190.687 61.3344 190.786 61.0923C190.845 60.9785 190.888 60.8601 190.914 60.7387C190.832 60.8445 190.767 60.9565 190.719 61.0731C190.607 61.3132 190.437 61.6474 190.229 62.0828C189.799 62.9818 189.185 64.2899 188.389 65.9646C186.832 69.3667 184.58 74.2616 181.809 80.3079C176.329 92.4489 168.784 109.188 160.433 127.72C152.082 146.253 144.539 162.983 138.945 175.08C136.148 181.131 133.851 186.019 132.243 189.387C131.426 191.072 130.78 192.375 130.329 193.253C130.14 193.713 129.879 194.155 129.552 194.57C129.647 194.091 129.806 193.623 130.029 193.171C130.388 192.266 130.954 190.941 131.691 189.236C133.171 185.831 135.363 180.917 138.124 174.857C143.615 162.722 151.385 146.022 160.006 127.593Z"
        fill="#D2E9E6"
      />
      <Path
        d="M153.782 125.509C166.733 108.517 178.448 93.1389 186.93 81.9904C191.121 76.4083 194.519 71.8956 196.877 68.7613L199.529 65.145C199.817 64.7349 200.04 64.4221 200.201 64.1982C200.284 64.0919 200.355 63.9812 200.415 63.8668C200.311 63.9622 200.219 64.0642 200.142 64.1717C199.968 64.3936 199.727 64.6993 199.41 65.0961C198.767 65.9272 197.845 67.1309 196.661 68.6757L186.696 81.8978C178.363 93.1084 166.87 108.577 154.145 125.665C141.42 142.753 129.953 158.217 121.51 169.367C117.293 174.945 113.847 179.442 111.425 182.54C110.219 184.069 109.262 185.284 108.599 186.086C108.303 186.515 107.938 186.917 107.51 187.285C107.711 186.828 107.979 186.39 108.311 185.976C108.89 185.135 109.76 183.906 110.898 182.333C113.181 179.182 116.535 174.628 120.712 169.052C129.123 157.876 140.82 142.486 153.782 125.509Z"
        fill="#D2E9E6"
      />
      <Path
        d="M148.031 122.825C164.994 107.702 180.342 94.0172 191.464 84.0962L204.538 72.3087L208.049 69.0854L208.953 68.2396C209.063 68.1476 209.159 68.0475 209.238 67.9409C209.109 68.0212 208.994 68.111 208.895 68.209L207.943 69.0344C207.108 69.7621 205.9 70.8392 204.349 72.2146C201.246 75.0038 196.782 79.0264 191.265 83.9918L148.394 123.02C131.678 138.263 116.542 152.054 105.432 161.977C99.8971 166.94 95.366 170.939 92.2397 173.682C90.6762 175.055 89.427 176.113 88.5756 176.825C88.1781 177.209 87.719 177.56 87.2057 177.872C87.5189 177.452 87.8912 177.057 88.3179 176.69C89.1092 175.955 90.2522 174.847 91.7667 173.427C94.7909 170.612 99.2157 166.562 104.723 161.578C115.728 151.626 131.059 137.93 148.031 122.825Z"
        fill="#D2E9E6"
      />
      <Path
        d="M142.879 119.595C163.413 106.71 181.975 95.0431 195.456 86.589C202.142 82.3447 207.556 78.914 211.309 76.5279L215.59 73.765L216.69 73.0361C216.822 72.9597 216.941 72.8733 217.045 72.7783C216.902 72.8469 216.766 72.9216 216.639 73.0022L215.496 73.6816L211.149 76.3909L195.285 86.4417L143.199 119.843C122.888 132.876 104.462 144.675 91.0395 153.126C84.3356 157.363 78.8749 160.769 75.0793 163.106C73.1757 164.271 71.6926 165.172 70.6759 165.772C70.1823 166.098 69.6422 166.392 69.062 166.648C69.4674 166.268 69.9321 165.921 70.4491 165.612C71.4042 164.963 72.825 164.018 74.6663 162.809C78.3756 160.386 83.7355 156.934 90.4177 152.676C103.773 144.137 122.351 132.456 142.879 119.595Z"
        fill="#D2E9E6"
      />
      <Path
        d="M138.456 115.918L198.848 89.4041C206.538 85.9966 212.759 83.2215 217.099 81.3056L222.03 79.0748C222.572 78.8165 222.998 78.6215 223.297 78.4838C223.45 78.4233 223.59 78.3502 223.715 78.266C223.557 78.3188 223.405 78.3781 223.26 78.4435L221.948 78.9975L216.995 81.1775L198.726 89.2689L138.728 116.175C115.333 126.669 94.1199 136.187 78.6949 142.999C70.9796 146.402 64.7106 149.157 60.3657 151.003C58.1824 151.948 56.4854 152.656 55.3255 153.131C54.7625 153.398 54.1572 153.625 53.5181 153.809C54.0178 153.487 54.5654 153.201 55.1538 152.954C56.26 152.423 57.9018 151.668 60.0361 150.677C64.2989 148.723 70.4984 145.927 78.1864 142.498C93.5341 135.652 114.872 126.252 138.456 115.918Z"
        fill="#D2E9E6"
      />
      <Path
        d="M134.868 111.869L201.603 92.497L221.78 86.5575L227.241 84.9128L228.653 84.4735C228.817 84.43 228.973 84.3754 229.118 84.3104C228.945 84.3394 228.779 84.3809 228.622 84.4342C228.274 84.5256 227.795 84.6606 227.179 84.8343L221.697 86.4199L201.516 92.3501L135.102 112.155C109.194 119.898 85.7097 126.881 68.6556 131.864C60.1251 134.358 53.2112 136.354 48.4096 137.698C46.0076 138.377 44.1358 138.89 42.8602 139.23C42.23 139.432 41.565 139.588 40.8752 139.695C41.4479 139.433 42.0629 139.212 42.7114 139.035C43.9467 138.637 45.7719 138.065 48.1375 137.342C52.8632 135.891 59.7322 133.827 68.2418 131.309C85.2281 126.277 108.804 119.416 134.868 111.869Z"
        fill="#D2E9E6"
      />
      <Path
        d="M132.198 107.557L203.652 95.8352L225.283 92.2126L231.151 91.1946L232.666 90.9176C232.843 90.8935 233.015 90.8572 233.178 90.8093C232.996 90.8222 232.817 90.8442 232.642 90.8752L231.103 91.1097L225.209 92.0635L203.598 95.6807L132.378 107.855C104.577 112.62 79.3821 116.911 61.1121 119.937C51.9732 121.454 44.5638 122.654 39.4361 123.458C36.8186 123.854 34.8209 124.154 33.4894 124.352C32.8055 124.476 32.0979 124.552 31.3771 124.578C32.0225 124.385 32.701 124.237 33.4028 124.137C34.7458 123.88 36.6978 123.517 39.242 123.067C44.3213 122.148 51.6905 120.89 60.8156 119.345C79.024 116.27 104.289 112.115 132.198 107.557Z"
        fill="#D2E9E6"
      />
      <Path
        d="M130.523 103.083L205.007 99.3435L227.557 98.138L233.676 97.7782L235.267 97.6721C235.448 97.6641 235.627 97.6462 235.801 97.6187C235.618 97.6085 235.436 97.61 235.257 97.6233L233.655 97.6889L227.517 97.9813L204.966 99.1567L130.613 103.346C101.598 104.999 75.3128 106.467 56.2646 107.446C46.7397 107.94 39.028 108.315 33.6907 108.545C31.0185 108.661 28.9412 108.742 27.5312 108.782C26.8182 108.832 26.0929 108.834 25.3652 108.789C26.0505 108.671 26.7571 108.599 27.4758 108.572C28.8669 108.464 30.9298 108.325 33.5868 108.155C38.8937 107.817 46.5927 107.375 56.11 106.855C75.131 105.85 101.453 104.521 130.523 103.083Z"
        fill="#D2E9E6"
      />
      <Path
        d="M129.892 98.5545L205.51 102.915L228.422 104.16L234.648 104.47L236.27 104.536C236.456 104.548 236.641 104.55 236.824 104.542C236.639 104.516 236.453 104.498 236.266 104.488L234.647 104.379L228.42 104.009L205.504 102.751L129.899 98.8477C100.396 97.3433 73.6744 95.9504 54.3299 94.8639C44.6536 94.3244 36.8234 93.8606 31.4129 93.5115C28.7073 93.339 26.6056 93.1842 25.178 93.0841C24.45 93.0572 23.7204 92.9812 23 92.8572C23.7152 92.8177 24.4409 92.8227 25.1681 92.872C26.5939 92.916 28.6971 92.998 31.3993 93.1227C36.8044 93.3679 44.6361 93.7588 54.311 94.2724C73.6528 95.3069 100.38 96.8339 129.892 98.5545Z"
        fill="#D2E9E6"
      />
      <Path
        d="M130.303 94.0869L205.208 106.309L227.92 109.94L235.703 111.166C235.886 111.194 236.069 111.215 236.253 111.231C236.076 111.183 235.896 111.146 235.713 111.12L234.113 110.842L227.943 109.824L205.233 106.184L130.239 94.4077C100.969 89.8255 74.4617 85.6482 55.2941 82.5533C45.7135 81.0064 37.9605 79.7257 32.6086 78.8187C29.9268 78.3621 27.8528 78.0013 26.4494 77.746C25.7258 77.6401 25.0113 77.4902 24.315 77.2982C25.0426 77.3346 25.7706 77.4161 26.4897 77.5418C27.9054 77.7346 29.9964 78.0423 32.6826 78.4437C38.0535 79.2549 45.8526 80.4655 55.4131 81.9835C74.605 84.9877 101.073 89.3005 130.303 94.0869Z"
        fill="#D2E9E6"
      />
      <Path
        d="M131.747 89.7823L204.083 109.586L226.043 115.517L232.031 117.1L233.593 117.501C233.768 117.553 233.948 117.594 234.131 117.624C233.962 117.56 233.787 117.506 233.607 117.46L232.066 117.02L226.111 115.373L204.151 109.433L131.621 90.0631C103.292 82.5145 77.6571 75.6521 59.1256 70.6126C49.8661 68.0938 42.3769 66.0341 37.2096 64.5848C34.6322 63.8611 32.6323 63.2886 31.2765 62.8904C30.5764 62.711 29.8955 62.4902 29.2422 62.2307C29.9644 62.3381 30.6762 62.4933 31.367 62.6941C32.7477 63.0318 34.7716 63.548 37.3776 64.2246C42.5827 65.581 50.1039 67.577 59.3754 70.0676C77.9309 75.0509 103.515 82.0384 131.747 89.7823Z"
        fill="#D2E9E6"
      />
      <Path
        d="M134.193 85.7766L202.185 112.779L222.852 120.879L228.49 123.055L229.963 123.613C230.128 123.681 230.3 123.74 230.477 123.789C230.323 123.707 230.16 123.634 229.991 123.57L228.544 122.978L222.95 120.744L202.309 112.614L134.003 86.0174C107.336 75.6461 83.207 66.2327 65.7891 59.3494C57.0794 55.9119 50.0481 53.1038 45.2079 51.1433C42.8165 50.1653 40.9087 49.3882 39.6506 48.855C38.9867 48.6076 38.3533 48.3194 37.7593 47.9945C38.4582 48.1784 39.136 48.4059 39.7833 48.674C41.0913 49.1506 43.0043 49.8684 45.4558 50.8042C50.3651 52.6769 57.4659 55.4272 66.1743 58.8387C83.6279 65.6718 107.669 75.2044 134.193 85.7766Z"
        fill="#D2E9E6"
      />
      <Path
        d="M137.592 82.1105L199.53 115.626L218.368 125.723L223.516 128.445C224.103 128.748 224.541 128.98 224.865 129.143C225.016 129.225 225.173 129.302 225.336 129.372C225.202 129.277 225.058 129.189 224.903 129.11L223.587 128.379L218.494 125.605L199.671 115.498L137.34 82.3372C113.001 69.4068 90.9838 57.6753 75.1102 49.1235C67.1635 44.8653 60.7654 41.3707 56.3656 38.9468C54.1613 37.7406 52.4631 36.7859 51.3199 36.1291C50.7001 35.8168 50.1199 35.4679 49.5872 35.0874C50.2461 35.342 50.8738 35.6365 51.4618 35.9667C52.6329 36.5807 54.3952 37.4724 56.6578 38.6448C61.1501 40.9931 67.614 44.4164 75.5741 48.6724C91.5264 57.1851 113.433 69.0194 137.592 82.1105Z"
        fill="#D2E9E6"
      />
      <Path
        d="M141.851 78.9319L196.264 118.093L212.824 129.911L217.362 133.104L218.556 133.928C218.685 134.027 218.827 134.117 218.982 134.197C218.864 134.092 218.737 133.991 218.602 133.896L217.476 133.053L213.004 129.815L196.452 117.99L141.541 79.128C120.11 63.9705 100.726 50.2264 86.7851 40.2304C79.8062 35.2267 74.1851 31.1671 70.3304 28.3393C68.4057 26.928 66.9175 25.8259 65.9235 25.068C65.3822 24.7023 64.8915 24.3041 64.4589 23.8794C65.0605 24.198 65.6224 24.5511 66.1373 24.9341C67.1984 25.6467 68.7458 26.7109 70.7288 28.0884C74.7041 30.8622 80.3759 34.861 87.3848 39.8436C101.396 49.8078 120.635 63.6364 141.851 78.9319Z"
        fill="#D2E9E6"
      />
      <Path
        d="M146.857 76.2918L192.415 120.112L206.316 133.354L210.13 136.94L211.137 137.863C211.242 137.974 211.365 138.077 211.503 138.17C211.411 138.056 211.308 137.947 211.193 137.842L210.237 136.892C209.368 136.056 208.109 134.839 206.499 133.275L192.612 120.032C180.808 108.868 164.509 93.4707 146.509 76.4518C128.508 59.4329 112.202 44.0384 100.527 32.844C94.6671 27.2475 89.9683 22.7059 86.7473 19.5482C85.1208 17.969 83.9028 16.742 83.0735 15.9026C82.6207 15.4887 82.2226 15.0506 81.8845 14.5942C82.4144 14.965 82.8956 15.3659 83.3216 15.7913C84.2218 16.5989 85.5107 17.7941 87.2011 19.3447C90.5426 22.4483 95.3327 26.9527 101.209 32.5346C112.973 43.7043 129.066 59.1942 146.857 76.2918Z"
        fill="#D2E9E6"
      />
      <Path
        d="M152.491 74.2619L188.106 121.676L198.998 136.017L201.998 139.904C202.345 140.337 202.609 140.67 202.794 140.91C202.88 141.026 202.979 141.138 203.09 141.245C203.025 141.123 202.947 141.005 202.856 140.89L202.114 139.871C201.441 138.962 200.46 137.647 199.194 135.974L188.316 121.628C179.036 109.53 166.218 92.8332 152.097 74.3931C137.976 55.9531 125.16 39.2478 116.014 27.1241C111.434 21.0633 107.759 16.1501 105.284 12.7491C104.031 11.044 103.071 9.72011 102.443 8.81352C102.079 8.36298 101.778 7.89403 101.543 7.41322C101.992 7.83121 102.383 8.27405 102.71 8.73534C103.435 9.61408 104.47 10.9155 105.823 12.6062C108.46 15.9726 112.225 20.857 116.839 26.906C126.005 38.9942 138.611 55.748 152.491 74.2619Z"
        fill="#D2E9E6"
      />
      <Path
        d="M158.621 72.8684L183.362 122.729C186.571 129.09 189.165 134.245 190.972 137.82C191.914 139.597 192.593 140.967 193.084 141.916L193.649 142.98C193.709 143.101 193.78 143.22 193.863 143.336C193.829 143.212 193.78 143.09 193.716 142.969C193.592 142.713 193.423 142.36 193.204 141.896C192.746 140.944 192.055 139.567 191.186 137.785L183.592 122.71C177.064 109.963 168.11 92.3835 158.197 72.9521C148.285 53.5206 139.304 35.9457 132.943 23.1993C129.751 16.82 127.211 11.6559 125.487 8.08105C124.626 6.29153 123.97 4.89956 123.544 3.95173C123.292 3.47579 123.1 2.98992 122.971 2.49978C123.329 2.95778 123.618 3.43526 123.833 3.92431C124.352 4.86512 125.09 6.23143 126.025 8.00681C127.908 11.5637 130.541 16.7124 133.768 23.08C140.212 35.7965 148.978 53.4149 158.621 72.8684Z"
        fill="#D2E9E6"
      />
      <Path
        d="M165.085 72.1979L178.257 123.288L182.367 138.764C182.873 140.584 183.259 141.998 183.532 142.973C183.675 143.425 183.775 143.806 183.853 144.063C183.88 144.189 183.925 144.314 183.986 144.436C183.979 144.311 183.956 144.185 183.918 144.06C183.859 143.802 183.776 143.437 183.663 142.963L182.595 138.753C181.626 135.086 180.23 129.789 178.505 123.275L164.648 72.2235C159.207 52.2975 154.326 34.251 150.905 21.1771C149.191 14.6438 147.846 9.34994 146.953 5.69106C146.506 3.8637 146.173 2.44091 145.969 1.47663C145.82 0.985955 145.74 0.491398 145.731 0C145.979 0.479264 146.163 0.96846 146.283 1.46149C146.584 2.41973 147.002 3.83868 147.527 5.66122C148.57 9.31363 150.027 14.5992 151.773 21.1333C155.273 34.1982 159.958 52.2568 165.085 72.1979Z"
        fill="#D2E9E6"
      />
      <Path
        d="M171.723 72.2247C172.232 92.1561 172.697 110.182 173.033 123.273C173.25 129.798 173.421 135.079 173.543 138.75C173.624 140.567 173.687 141.981 173.726 142.958C173.756 143.431 173.78 143.796 173.794 144.056C173.796 144.18 173.812 144.305 173.843 144.429C173.866 144.307 173.873 144.183 173.864 144.058C173.864 143.8 173.858 143.435 173.86 142.967C173.84 141.988 173.809 140.578 173.774 138.76C173.651 135.094 173.486 129.81 173.276 123.285C172.765 110.201 172.056 92.1628 171.275 72.2014C170.494 52.24 169.784 34.2059 169.405 21.1425C169.209 14.6077 169.089 9.32633 169.048 5.67268C169.028 3.84794 169.041 2.43676 169.044 1.47489C169.014 0.982231 169.05 0.4933 169.152 0.014037C169.292 0.505131 169.361 0.999511 169.358 1.4899C169.438 2.45626 169.533 3.87588 169.623 5.70731C169.825 9.36479 170.047 14.6536 170.281 21.1901C170.762 34.261 171.25 52.3033 171.723 72.2247Z"
        fill="#D2E9E6"
      />
      <Path
        d="M178.411 72.9768C174.341 92.4065 170.655 109.989 167.983 122.735C166.725 129.106 165.656 134.256 164.93 137.832C164.58 139.607 164.307 140.986 164.119 141.94C164.028 142.398 163.958 142.752 163.908 143.006C163.875 143.127 163.85 143.249 163.836 143.373C163.888 143.256 163.925 143.136 163.946 143.012C163.995 142.762 164.066 142.404 164.188 141.951C164.376 140.997 164.686 139.624 165.068 137.854C165.775 134.275 166.84 129.111 168.133 122.759C170.655 109.989 174.078 92.3651 177.884 72.8939C181.691 53.4228 185.138 35.8025 187.764 23.0661C189.073 16.6953 190.168 11.545 190.967 7.99338C191.369 6.21807 191.692 4.84274 191.928 3.90903C192.008 3.42651 192.157 2.95512 192.374 2.50146C192.394 2.99314 192.347 3.48045 192.235 3.95737C192.093 4.90588 191.851 6.29404 191.531 8.08217C190.882 11.6616 189.89 16.8453 188.622 23.2012C186.166 35.9643 182.519 53.5489 178.411 72.9768Z"
        fill="#D2E9E6"
      />
      <Path
        d="M184.943 74.3833C176.375 92.8385 168.625 109.537 163.004 121.642L156.46 135.996C155.712 137.687 155.13 139 154.725 139.907C154.54 140.35 154.396 140.688 154.296 140.931C154.238 141.044 154.195 141.163 154.168 141.284C154.25 141.179 154.314 141.066 154.357 140.949C154.476 140.71 154.639 140.374 154.853 139.944C155.277 139.04 155.891 137.732 156.681 136.056C158.238 132.654 160.471 127.756 163.236 121.709C168.686 109.555 176.21 92.791 184.52 74.2694C192.83 55.7478 200.341 38.9862 205.917 26.8819C208.695 20.8282 210.985 15.9392 212.619 12.5749C213.43 10.8897 214.075 9.58653 214.526 8.70819C214.713 8.24614 214.972 7.80318 215.298 7.38623C215.208 7.86627 215.051 8.33485 214.828 8.7857C214.468 9.69134 213.908 11.0209 213.172 12.7221C211.697 16.1328 209.511 21.0469 206.763 27.1097C201.267 39.2352 193.528 55.9436 184.943 74.3833Z"
        fill="#D2E9E6"
      />
      <Path
        d="M191.169 76.4589L158.104 120.016C153.92 125.595 150.532 130.118 148.188 133.254L145.535 136.871C145.247 137.281 145.024 137.593 144.864 137.817C144.781 137.923 144.712 138.035 144.656 138.15C144.762 138.055 144.853 137.953 144.929 137.845L145.655 136.919C146.297 136.088 147.213 134.884 148.397 133.339C150.755 130.2 154.148 125.682 158.332 120.104L190.774 76.3021C203.412 59.1964 214.9 43.7226 223.32 32.5642C227.529 26.9851 230.965 22.4783 233.379 19.383C234.59 17.8337 235.538 16.634 236.201 15.8277C236.5 15.4025 236.863 15.0021 237.284 14.632C237.077 15.0956 236.803 15.5402 236.466 15.9596C235.892 16.8015 235.023 18.0307 233.891 19.6053C231.614 22.7608 228.272 27.3125 224.1 32.8976C215.77 44.0615 204.101 59.4682 191.169 76.4589Z"
        fill="#D2E9E6"
      />
      <Path
        d="M196.932 79.1446C180 94.2768 164.661 107.98 153.576 117.911C148.07 122.887 143.617 126.916 140.526 129.711L137.019 132.939L136.122 133.786C136.012 133.878 135.916 133.978 135.837 134.085C135.963 134.003 136.078 133.913 136.181 133.817C136.406 133.625 136.718 133.352 137.125 132.995C137.96 132.267 139.172 131.169 140.713 129.809L153.774 118.02C164.738 108.014 179.859 94.2031 196.564 78.9449C213.27 63.6867 228.388 49.8885 239.48 39.9542C244.986 34.9785 249.505 30.9731 252.652 28.2251C254.216 26.8475 255.46 25.7846 256.312 25.0724C256.7 24.6897 257.147 24.337 257.644 24.0191C257.333 24.4417 256.961 24.8391 256.531 25.2057C255.74 25.9403 254.603 27.0501 253.095 28.4709C250.076 31.2906 245.657 35.3419 240.162 40.3321C229.177 50.3091 213.869 64.0217 196.932 79.1446Z"
        fill="#D2E9E6"
      />
      <Path
        d="M202.086 82.366L149.577 115.422L133.746 125.499L129.472 128.263L128.379 128.989C128.249 129.07 128.128 129.157 128.017 129.25C128.164 129.185 128.3 129.11 128.423 129.026L129.565 128.347L133.907 125.632L149.749 115.565L201.76 82.1514C222.042 69.0975 240.44 57.3104 253.845 48.8185C260.54 44.5585 266.002 41.1482 269.796 38.82C271.693 37.6538 273.172 36.774 274.2 36.1497C274.688 35.8204 275.228 35.5285 275.813 35.2781C275.413 35.6514 274.956 35.9938 274.448 36.3004C273.498 36.9542 272.078 37.899 270.236 39.1084C266.527 41.5316 261.18 44.9857 254.508 49.2579C241.13 57.7756 222.601 69.4733 202.086 82.366Z"
        fill="#D2E9E6"
      />
      <Path
        d="M206.516 86.0405L146.175 112.626L127.962 120.727L123.037 122.958C122.495 123.217 122.069 123.412 121.769 123.554C121.618 123.614 121.478 123.685 121.352 123.767C121.512 123.716 121.664 123.657 121.807 123.59L123.119 123.036L128.073 120.852L146.3 112.745L206.219 85.7789C229.622 75.2481 250.783 65.7304 266.198 58.9038C273.902 55.49 280.156 52.7458 284.505 50.8787C286.682 49.9326 288.379 49.2246 289.54 48.7458C290.109 48.4797 290.719 48.2522 291.361 48.0659C290.865 48.391 290.319 48.6791 289.73 48.9261C288.624 49.4565 286.989 50.2129 284.853 51.2085C280.59 53.1663 274.402 55.9683 266.726 59.4037C251.391 66.2815 230.078 75.6851 206.516 86.0405Z"
        fill="#D2E9E6"
      />
      <Path
        d="M210.117 90.0871L143.422 109.517L123.261 115.476L117.803 117.134L116.392 117.573C116.225 117.618 116.067 117.673 115.92 117.74C116.092 117.706 116.26 117.665 116.422 117.617L117.865 117.217L123.349 115.623L143.514 109.673L209.881 89.8136C235.775 82.0462 259.238 75.0389 276.282 70.0411C284.808 67.5385 291.716 65.5374 296.514 64.1791C298.916 63.5005 300.782 62.9823 302.064 62.6429C302.694 62.4415 303.359 62.2857 304.049 62.1778C303.477 62.4329 302.868 62.6503 302.228 62.8276C300.999 63.2269 299.173 63.8029 296.813 64.5311C292.087 65.9824 285.223 68.0507 276.719 70.574C259.715 75.6338 236.157 82.4974 210.117 90.0871Z"
        fill="#D2E9E6"
      />
      <Path
        d="M212.788 94.3954L141.323 106.175L119.713 109.818L113.851 110.841L112.349 111.12C112.171 111.147 111.999 111.184 111.835 111.233C112.018 111.22 112.197 111.198 112.372 111.167L113.904 110.931L119.799 109.974L141.42 106.341L212.633 94.1004C240.426 89.3127 265.605 85.0027 283.872 81.9587C293 80.4316 300.404 79.2258 305.539 78.4189C308.1 78.0145 310.099 77.7105 311.455 77.516C312.136 77.388 312.84 77.3061 313.558 77.2714C312.924 77.4677 312.255 77.6186 311.563 77.722C310.221 77.9746 308.268 78.3417 305.729 78.7969C300.65 79.7156 293.279 80.9824 284.159 82.5323C265.932 85.6384 240.683 89.8127 212.788 94.3954Z"
        fill="#D2E9E6"
      />
      <Path
        d="M214.47 98.8701L140.008 102.66L117.468 103.884L111.347 104.252L109.756 104.358C109.574 104.364 109.395 104.382 109.222 104.412C109.407 104.419 109.591 104.416 109.773 104.404L111.368 104.342L117.508 104.041L140.059 102.827L214.393 98.5702C243.401 96.8949 269.677 95.4039 288.723 94.4028C298.243 93.8997 305.956 93.5201 311.294 93.2865C313.961 93.1651 316.038 93.084 317.448 93.0439C318.161 92.9921 318.886 92.9884 319.615 93.0328C318.929 93.151 318.222 93.2252 317.503 93.2545C316.112 93.362 314.048 93.5054 311.398 93.6766C306.084 94.0171 298.396 94.4693 288.877 94.9942C269.852 96.0544 243.539 97.4063 214.47 98.8701Z"
        fill="#D2E9E6"
      />
      <Path
        d="M215.113 103.4L139.496 99.104L116.586 97.8773L110.359 97.5757L108.737 97.5094C108.553 97.4967 108.37 97.4952 108.189 97.5047C108.372 97.5325 108.556 97.5501 108.74 97.5572L110.36 97.6661L116.588 98.0322L139.502 99.2682L215.102 103.098C244.61 104.577 271.332 105.936 290.678 107.014C300.349 107.548 308.18 108.004 313.592 108.349C316.298 108.517 318.399 108.676 319.827 108.772C320.553 108.796 321.281 108.871 322 108.994C321.285 109.037 320.559 109.034 319.831 108.983C318.412 108.94 316.308 108.858 313.605 108.738C308.199 108.497 300.366 108.114 290.697 107.606C271.35 106.596 244.625 105.091 215.113 103.4Z"
        fill="#D2E9E6"
      />
      <rect y="64" width="327" height="92" rx="20" fill="#D2E9E6" />
      <Path
        d="M278.563 118.356C277.37 118.356 276.535 119.271 276.454 120.539H280.592C280.563 119.264 279.765 118.356 278.563 118.356ZM280.563 122.816H281.815C281.596 124.149 280.307 125.131 278.644 125.131C276.461 125.131 275.136 123.615 275.136 121.22C275.136 118.854 276.483 117.25 278.585 117.25C280.644 117.25 281.911 118.744 281.911 121.051V121.535H276.447V121.608C276.447 123.065 277.304 124.025 278.673 124.025C279.64 124.025 280.351 123.534 280.563 122.816Z"
        fill="#2B2A2E"
      />
      <Path
        d="M274.726 117.383L271.928 125H270.624L267.819 117.383H269.159L271.261 123.645H271.291L273.4 117.383H274.726Z"
        fill="#2B2A2E"
      />
      <Path
        d="M265.482 125.002V117.385H266.757V125.002H265.482ZM266.12 116.03C265.68 116.03 265.321 115.671 265.321 115.232C265.321 114.785 265.68 114.434 266.12 114.434C266.566 114.434 266.918 114.785 266.918 115.232C266.918 115.671 266.566 116.03 266.12 116.03Z"
        fill="#2B2A2E"
      />
      <Path d="M262.421 125.002V114.434H263.695V125.002H262.421Z" fill="#2B2A2E" />
      <Path
        d="M252.16 119.484C252.16 118.18 253.31 117.25 254.958 117.25C256.627 117.25 257.741 118.231 257.792 119.579H256.576C256.481 118.781 255.895 118.29 254.958 118.29C254.027 118.29 253.412 118.751 253.412 119.418C253.412 119.945 253.8 120.304 254.65 120.509L255.858 120.802C257.396 121.176 257.953 121.776 257.953 122.868C257.953 124.193 256.701 125.131 255.001 125.131C253.214 125.131 252.101 124.23 251.955 122.802H253.229C253.383 123.673 253.969 124.091 255.001 124.091C256.034 124.091 256.671 123.651 256.671 122.963C256.671 122.414 256.371 122.099 255.5 121.886L254.291 121.593C252.855 121.249 252.16 120.583 252.16 119.484Z"
        fill="#2B2A2E"
      />
      <Path
        d="M249.296 125.002V117.385H250.57V125.002H249.296ZM249.933 116.03C249.494 116.03 249.135 115.671 249.135 115.232C249.135 114.785 249.494 114.434 249.933 114.434C250.38 114.434 250.731 114.785 250.731 115.232C250.731 115.671 250.38 116.03 249.933 116.03Z"
        fill="#2B2A2E"
      />
      <Path
        d="M238.244 124.999V117.382H239.445V118.598H239.474C239.899 117.777 240.712 117.25 241.891 117.25C243.561 117.25 244.528 118.327 244.528 120.055V124.999H243.253V120.282C243.253 119.052 242.675 118.378 241.503 118.378C240.302 118.378 239.518 119.228 239.518 120.517V124.999H238.244Z"
        fill="#2B2A2E"
      />
      <Path
        d="M233.432 125.131C231.322 125.131 229.901 123.615 229.901 121.19C229.901 118.759 231.322 117.25 233.432 117.25C235.534 117.25 236.955 118.759 236.955 121.19C236.955 123.615 235.534 125.131 233.432 125.131ZM233.432 124.01C234.779 124.01 235.658 122.985 235.658 121.19C235.658 119.396 234.779 118.371 233.432 118.371C232.084 118.371 231.198 119.396 231.198 121.19C231.198 122.985 232.084 124.01 233.432 124.01Z"
        fill="#2B2A2E"
      />
      <Path
        d="M227.279 125.002V117.385H228.554V125.002H227.279ZM227.917 116.03C227.477 116.03 227.118 115.671 227.118 115.232C227.118 114.785 227.477 114.434 227.917 114.434C228.363 114.434 228.715 114.785 228.715 115.232C228.715 115.671 228.363 116.03 227.917 116.03Z"
        fill="#2B2A2E"
      />
      <Path
        d="M223.097 115.547H224.372V117.385H225.851V118.433H224.372V122.959C224.372 123.655 224.665 123.97 225.316 123.97C225.463 123.97 225.756 123.948 225.844 123.933V125.002C225.69 125.039 225.338 125.061 225.038 125.061C223.639 125.061 223.097 124.49 223.097 123.025V118.433H221.962V117.385H223.097V115.547Z"
        fill="#2B2A2E"
      />
      <Path
        d="M215.568 119.484C215.568 118.18 216.718 117.25 218.366 117.25C220.036 117.25 221.149 118.231 221.2 119.579H219.984C219.889 118.781 219.303 118.29 218.366 118.29C217.436 118.29 216.82 118.751 216.82 119.418C216.82 119.945 217.208 120.304 218.058 120.509L219.267 120.802C220.805 121.176 221.361 121.776 221.361 122.868C221.361 124.193 220.109 125.131 218.41 125.131C216.623 125.131 215.509 124.23 215.363 122.802H216.637C216.791 123.673 217.377 124.091 218.41 124.091C219.442 124.091 220.08 123.651 220.08 122.963C220.08 122.414 219.779 122.099 218.908 121.886L217.699 121.593C216.264 121.249 215.568 120.583 215.568 119.484Z"
        fill="#2B2A2E"
      />
      <Path
        d="M211.063 118.356C209.87 118.356 209.035 119.271 208.954 120.539H213.092C213.063 119.264 212.265 118.356 211.063 118.356ZM213.063 122.816H214.315C214.096 124.149 212.807 125.131 211.144 125.131C208.961 125.131 207.636 123.615 207.636 121.22C207.636 118.854 208.983 117.25 211.085 117.25C213.144 117.25 214.411 118.744 214.411 121.051V121.535H208.947V121.608C208.947 123.065 209.804 124.025 211.173 124.025C212.14 124.025 212.851 123.534 213.063 122.816Z"
        fill="#2B2A2E"
      />
      <Path
        d="M206.354 117.383V125H205.146V123.762H205.116C204.662 124.641 203.871 125.132 202.699 125.132C201.037 125.132 200.062 124.055 200.062 122.327V117.383H201.337V122.1C201.337 123.337 201.916 124.004 203.095 124.004C204.333 124.004 205.08 123.154 205.08 121.858V117.383H206.354Z"
        fill="#2B2A2E"
      />
      <Path
        d="M194.723 117.25C195.829 117.25 196.701 117.792 197.191 118.671H197.221V117.382H198.437V127.541H197.162V123.725H197.133C196.657 124.596 195.771 125.131 194.694 125.131C192.768 125.131 191.479 123.578 191.479 121.19C191.479 118.795 192.768 117.25 194.723 117.25ZM194.95 124.01C196.254 124.01 197.162 122.89 197.162 121.19C197.162 119.491 196.254 118.371 194.95 118.371C193.617 118.371 192.782 119.447 192.782 121.19C192.782 122.926 193.617 124.01 194.95 124.01Z"
        fill="#2B2A2E"
      />
      <Path
        d="M180.866 124.999V117.382H182.067V118.598H182.096C182.521 117.777 183.334 117.25 184.513 117.25C186.183 117.25 187.15 118.327 187.15 120.055V124.999H185.875V120.282C185.875 119.052 185.297 118.378 184.125 118.378C182.924 118.378 182.14 119.228 182.14 120.517V124.999H180.866Z"
        fill="#2B2A2E"
      />
      <Path
        d="M176.054 125.131C173.944 125.131 172.523 123.615 172.523 121.19C172.523 118.759 173.944 117.25 176.054 117.25C178.156 117.25 179.577 118.759 179.577 121.19C179.577 123.615 178.156 125.131 176.054 125.131ZM176.054 124.01C177.401 124.01 178.28 122.985 178.28 121.19C178.28 119.396 177.401 118.371 176.054 118.371C174.706 118.371 173.82 119.396 173.82 121.19C173.82 122.985 174.706 124.01 176.054 124.01Z"
        fill="#2B2A2E"
      />
      <Path
        d="M169.901 125.002V117.385H171.176V125.002H169.901ZM170.539 116.03C170.099 116.03 169.74 115.671 169.74 115.232C169.74 114.785 170.099 114.434 170.539 114.434C170.985 114.434 171.337 114.785 171.337 115.232C171.337 115.671 170.985 116.03 170.539 116.03Z"
        fill="#2B2A2E"
      />
      <Path
        d="M165.719 115.547H166.994V117.385H168.473V118.433H166.994V122.959C166.994 123.655 167.287 123.97 167.938 123.97C168.085 123.97 168.378 123.948 168.466 123.933V125.002C168.312 125.039 167.96 125.061 167.66 125.061C166.261 125.061 165.719 124.49 165.719 123.025V118.433H164.584V117.385H165.719V115.547Z"
        fill="#2B2A2E"
      />
      <Path
        d="M164.123 119.879H162.877C162.694 119.052 162.05 118.371 160.929 118.371C159.611 118.371 158.717 119.462 158.717 121.19C158.717 122.963 159.625 124.01 160.944 124.01C161.984 124.01 162.665 123.461 162.885 122.545H164.137C163.939 124.062 162.665 125.131 160.937 125.131C158.827 125.131 157.421 123.644 157.421 121.19C157.421 118.788 158.82 117.25 160.922 117.25C162.826 117.25 163.961 118.51 164.123 119.879Z"
        fill="#2B2A2E"
      />
      <Path
        d="M153.158 118.356C151.964 118.356 151.129 119.271 151.049 120.539H155.187C155.158 119.264 154.359 118.356 153.158 118.356ZM155.158 122.816H156.41C156.19 124.149 154.901 125.131 153.239 125.131C151.056 125.131 149.73 123.615 149.73 121.22C149.73 118.854 151.078 117.25 153.18 117.25C155.238 117.25 156.505 118.744 156.505 121.051V121.535H151.042V121.608C151.042 123.065 151.898 124.025 153.268 124.025C154.235 124.025 154.945 123.534 155.158 122.816Z"
        fill="#2B2A2E"
      />
      <Path d="M147.108 125.002V114.434H148.383V125.002H147.108Z" fill="#2B2A2E" />
      <Path
        d="M142.897 125.002V118.433H141.835V117.385H142.897V116.536C142.897 115.071 143.578 114.375 144.984 114.375C145.27 114.375 145.578 114.404 145.761 114.434V115.466C145.607 115.444 145.395 115.422 145.182 115.422C144.494 115.422 144.164 115.789 144.164 116.543V117.385H145.724V118.433H144.171V125.002H142.897Z"
        fill="#2B2A2E"
      />
      <Path
        d="M137.851 118.356C136.657 118.356 135.822 119.271 135.741 120.539H139.879C139.85 119.264 139.052 118.356 137.851 118.356ZM139.85 122.816H141.103C140.883 124.149 139.594 125.131 137.931 125.131C135.749 125.131 134.423 123.615 134.423 121.22C134.423 118.854 135.771 117.25 137.873 117.25C139.931 117.25 141.198 118.744 141.198 121.051V121.535H135.734V121.608C135.734 123.065 136.591 124.025 137.96 124.025C138.927 124.025 139.638 123.534 139.85 122.816Z"
        fill="#2B2A2E"
      />
      <Path
        d="M130.534 124.999V117.382H131.735V118.627H131.764C132.013 117.785 132.716 117.25 133.61 117.25C133.83 117.25 134.02 117.287 134.123 117.301V118.539C134.02 118.502 133.749 118.466 133.441 118.466C132.453 118.466 131.808 119.176 131.808 120.253V124.999H130.534Z"
        fill="#2B2A2E"
      />
      <Path
        d="M120.8 127.849C120.646 127.849 120.39 127.834 120.25 127.805V126.736C120.39 126.765 120.587 126.772 120.749 126.772C121.459 126.772 121.854 126.428 122.147 125.542L122.323 125.007L119.511 117.383H120.873L122.982 123.726H123.004L125.114 117.383H126.454L123.458 125.535C122.807 127.314 122.177 127.849 120.8 127.849Z"
        fill="#2B2A2E"
      />
      <Path d="M117.167 125.002V114.434H118.441V125.002H117.167Z" fill="#2B2A2E" />
      <Path
        d="M114.105 125.002V117.385H115.38V125.002H114.105ZM114.743 116.03C114.303 116.03 113.944 115.671 113.944 115.232C113.944 114.785 114.303 114.434 114.743 114.434C115.189 114.434 115.541 114.785 115.541 115.232C115.541 115.671 115.189 116.03 114.743 116.03Z"
        fill="#2B2A2E"
      />
      <Path
        d="M109.022 124.047C110.224 124.047 111.146 123.205 111.146 122.106V121.483L109.11 121.608C108.085 121.674 107.499 122.128 107.499 122.846C107.499 123.571 108.107 124.047 109.022 124.047ZM108.737 125.131C107.221 125.131 106.203 124.215 106.203 122.846C106.203 121.542 107.206 120.722 108.964 120.619L111.146 120.495V119.857C111.146 118.927 110.531 118.371 109.477 118.371C108.495 118.371 107.88 118.832 107.719 119.579H106.488C106.576 118.261 107.704 117.25 109.506 117.25C111.293 117.25 112.421 118.217 112.421 119.733V124.999H111.212V123.688H111.183C110.729 124.56 109.762 125.131 108.737 125.131Z"
        fill="#2B2A2E"
      />
      <Path
        d="M101.09 125.134C99.1494 125.134 97.8311 123.582 97.8311 121.194C97.8311 118.806 99.1421 117.253 101.061 117.253C102.152 117.253 103.031 117.788 103.485 118.623H103.515V114.434H104.789V125.002H103.573V123.713H103.551C103.061 124.592 102.189 125.134 101.09 125.134ZM101.332 118.374C99.9844 118.374 99.1348 119.473 99.1348 121.194C99.1348 122.922 99.9844 124.014 101.332 124.014C102.643 124.014 103.522 122.9 103.522 121.194C103.522 119.495 102.643 118.374 101.332 118.374Z"
        fill="#2B2A2E"
      />
      <Path
        d="M90.4995 124.999V117.382H91.7007V118.627H91.73C91.979 117.785 92.6821 117.25 93.5757 117.25C93.7954 117.25 93.9858 117.287 94.0884 117.301V118.539C93.9858 118.502 93.7148 118.466 93.4072 118.466C92.4185 118.466 91.7739 119.176 91.7739 120.253V124.999H90.4995Z"
        fill="#2B2A2E"
      />
      <Path
        d="M88.8442 117.383V125H87.6357V123.762H87.6064C87.1523 124.641 86.3613 125.132 85.1895 125.132C83.5269 125.132 82.5527 124.055 82.5527 122.327V117.383H83.8271V122.1C83.8271 123.337 84.4058 124.004 85.585 124.004C86.8228 124.004 87.5698 123.154 87.5698 121.858V117.383H88.8442Z"
        fill="#2B2A2E"
      />
      <Path
        d="M77.7773 125.131C75.668 125.131 74.2471 123.615 74.2471 121.19C74.2471 118.759 75.668 117.25 77.7773 117.25C79.8794 117.25 81.3003 118.759 81.3003 121.19C81.3003 123.615 79.8794 125.131 77.7773 125.131ZM77.7773 124.01C79.125 124.01 80.0039 122.985 80.0039 121.19C80.0039 119.396 79.125 118.371 77.7773 118.371C76.4297 118.371 75.5435 119.396 75.5435 121.19C75.5435 122.985 76.4297 124.01 77.7773 124.01Z"
        fill="#2B2A2E"
      />
      <Path
        d="M71.2515 125.002H69.9331V120.454L66.1831 114.434H67.6846L70.5703 119.18H70.6143L73.5 114.434H75.0015L71.2515 120.454V125.002Z"
        fill="#2B2A2E"
      />
      <Path
        d="M186.908 106.051H201.908V91.0508H186.908V106.051Z"
        fill="url(#pattern0_4019_28638)"
      />
      <Path
        d="M171.908 106.051H186.908V91.0508H171.908V106.051Z"
        fill="url(#pattern1_4019_28638)"
      />
      <Path
        d="M165.36 94.4336H167.558L167.382 101.523H165.536L165.36 94.4336ZM166.459 105.186C165.792 105.186 165.265 104.68 165.265 104.036C165.265 103.391 165.792 102.886 166.459 102.886C167.125 102.886 167.653 103.391 167.653 104.036C167.653 104.68 167.125 105.186 166.459 105.186Z"
        fill="#2B2A2E"
      />
      <Path
        d="M159.97 98.5625C159.032 98.5625 158.358 99.2729 158.285 100.254H161.618C161.574 99.251 160.922 98.5625 159.97 98.5625ZM161.647 102.598H163.61C163.375 104.136 161.969 105.169 160.028 105.169C157.611 105.169 156.161 103.624 156.161 101.111C156.161 98.6064 157.626 96.9805 159.948 96.9805C162.233 96.9805 163.676 98.5259 163.676 100.914V101.565H158.263V101.697C158.263 102.818 158.974 103.587 160.065 103.587C160.849 103.587 161.449 103.199 161.647 102.598Z"
        fill="#2B2A2E"
      />
      <Path
        d="M147.804 105.002V97.1504H149.87V98.5493H149.914C150.331 97.5752 151.159 97.0039 152.367 97.0039C154.11 97.0039 155.092 98.1025 155.092 99.9409V105.002H152.953V100.388C152.953 99.3623 152.47 98.7764 151.51 98.7764C150.551 98.7764 149.943 99.4795 149.943 100.498V105.002H147.804Z"
        fill="#2B2A2E"
      />
      <Path
        d="M144.237 105.001V97.1499H146.376V105.001H144.237ZM145.299 96.0879C144.677 96.0879 144.186 95.5898 144.186 94.9893C144.186 94.3813 144.677 93.8906 145.299 93.8906C145.922 93.8906 146.413 94.3813 146.413 94.9893C146.413 95.5898 145.922 96.0879 145.299 96.0879Z"
        fill="#2B2A2E"
      />
      <Path
        d="M135.478 105.002V94.4336H137.58V98.5498H137.624C138.041 97.561 138.898 97.0044 140.092 97.0044C141.835 97.0044 142.831 98.1323 142.831 99.9487V105.002H140.692V100.403C140.692 99.3921 140.194 98.7769 139.22 98.7769C138.209 98.7769 137.616 99.4873 137.616 100.52V105.002H135.478Z"
        fill="#2B2A2E"
      />
      <Path
        d="M127.575 99.4561C127.575 97.9619 128.864 96.9805 130.863 96.9805C132.877 96.9805 134.123 98.0132 134.167 99.5513H132.196C132.13 98.9141 131.654 98.4819 130.863 98.4819C130.124 98.4819 129.633 98.8188 129.633 99.3389C129.633 99.7417 129.926 100.013 130.717 100.181L132.057 100.467C133.698 100.811 134.372 101.456 134.372 102.605C134.372 104.151 132.958 105.169 130.937 105.169C128.769 105.169 127.516 104.158 127.377 102.613H129.464C129.596 103.301 130.087 103.667 130.937 103.667C131.742 103.667 132.24 103.338 132.24 102.825C132.24 102.408 131.969 102.173 131.2 101.998L129.86 101.719C128.344 101.397 127.575 100.635 127.575 99.4561Z"
        fill="#2B2A2E"
      />
      <Path
        d="M118.712 105.127C116.75 105.127 115.512 103.574 115.512 101.062C115.512 98.5718 116.757 97.0264 118.727 97.0264C119.862 97.0264 120.741 97.627 121.122 98.4985H121.166V94.4336H123.305V105.002H121.195V103.655H121.159C120.778 104.519 119.87 105.127 118.712 105.127ZM119.438 98.7402C118.361 98.7402 117.702 99.6411 117.702 101.077C117.702 102.52 118.361 103.406 119.438 103.406C120.5 103.406 121.181 102.512 121.181 101.077C121.181 99.6484 120.5 98.7402 119.438 98.7402Z"
        fill="#2B2A2E"
      />
      <Path
        d="M107.155 105.002V97.1504H109.22V98.5493H109.264C109.682 97.5752 110.509 97.0039 111.718 97.0039C113.461 97.0039 114.442 98.1025 114.442 99.9409V105.002H112.304V100.388C112.304 99.3623 111.82 98.7764 110.861 98.7764C109.901 98.7764 109.293 99.4795 109.293 100.498V105.002H107.155Z"
        fill="#2B2A2E"
      />
      <Path
        d="M101.969 103.58C102.899 103.58 103.668 102.972 103.668 102.122V101.551L102.013 101.653C101.215 101.712 100.761 102.071 100.761 102.627C100.761 103.213 101.244 103.58 101.969 103.58ZM101.266 105.125C99.772 105.125 98.6367 104.158 98.6367 102.745C98.6367 101.316 99.7354 100.489 101.691 100.372L103.668 100.254V99.7344C103.668 99.002 103.156 98.5771 102.35 98.5771C101.552 98.5771 101.046 98.9727 100.944 99.5513H98.9883C99.0688 98.0278 100.365 96.9805 102.445 96.9805C104.459 96.9805 105.771 98.0205 105.771 99.6025V105H103.705V103.799H103.661C103.222 104.634 102.248 105.125 101.266 105.125Z"
        fill="#2B2A2E"
      />
      <Path
        d="M91.1514 98.5625C90.2139 98.5625 89.54 99.2729 89.4668 100.254H92.7993C92.7554 99.251 92.1035 98.5625 91.1514 98.5625ZM92.8286 102.598H94.7915C94.5571 104.136 93.1509 105.169 91.21 105.169C88.793 105.169 87.3428 103.624 87.3428 101.111C87.3428 98.6064 88.8076 96.9805 91.1294 96.9805C93.4146 96.9805 94.8574 98.5259 94.8574 100.914V101.565H89.4448V101.697C89.4448 102.818 90.1553 103.587 91.2466 103.587C92.0303 103.587 92.6309 103.199 92.8286 102.598Z"
        fill="#2B2A2E"
      />
      <Path
        d="M79.7769 99.4561C79.7769 97.9619 81.0659 96.9805 83.0654 96.9805C85.0796 96.9805 86.3247 98.0132 86.3687 99.5513H84.3984C84.3325 98.9141 83.8564 98.4819 83.0654 98.4819C82.3257 98.4819 81.835 98.8188 81.835 99.3389C81.835 99.7417 82.1279 100.013 82.9189 100.181L84.2593 100.467C85.8999 100.811 86.5737 101.456 86.5737 102.605C86.5737 104.151 85.1602 105.169 83.1387 105.169C80.9707 105.169 79.7183 104.158 79.5791 102.613H81.6665C81.7983 103.301 82.2891 103.667 83.1387 103.667C83.9443 103.667 84.4424 103.338 84.4424 102.825C84.4424 102.408 84.1714 102.173 83.4023 101.998L82.062 101.719C80.5459 101.397 79.7769 100.635 79.7769 99.4561Z"
        fill="#2B2A2E"
      />
      <Path
        d="M76.3418 105.001V97.1499H78.4805V105.001H76.3418ZM77.4038 96.0879C76.7812 96.0879 76.2905 95.5898 76.2905 94.9893C76.2905 94.3813 76.7812 93.8906 77.4038 93.8906C78.0264 93.8906 78.5171 94.3813 78.5171 94.9893C78.5171 95.5898 78.0264 96.0879 77.4038 96.0879Z"
        fill="#2B2A2E"
      />
      <Path
        d="M69.0908 96.1621V99.5312H70.9951C72.1084 99.5312 72.7603 98.894 72.7603 97.8467C72.7603 96.8213 72.0718 96.1621 70.9731 96.1621H69.0908ZM69.0908 101.135V105.002H66.8789V94.4336H71.2075C73.6392 94.4336 75.0308 95.7153 75.0308 97.8027C75.0308 99.1577 74.3276 100.33 73.1118 100.806L75.3384 105.002H72.8335L70.8633 101.135H69.0908Z"
        fill="#2B2A2E"
      />
      <rect x="16" y="91" width="37" height="37" rx="7.11538" fill="#E9F5F3" />
      <Path
        d="M45.203 111.893H42.2035C41.827 111.893 41.5217 112.197 41.5217 112.571V122.411C41.5217 122.786 41.2165 123.09 40.84 123.09H28.1602C27.7837 123.09 27.4784 122.786 27.4784 122.411V116.282C27.4784 115.907 27.1732 115.604 26.7967 115.604H23.7972C23.4207 115.604 23.1155 115.3 23.1155 114.925V98.7958C23.1155 98.421 23.4191 98.1172 23.7956 98.1172H45.205C45.5815 98.1172 45.8847 98.421 45.8847 98.7958V111.214C45.8847 111.589 45.5795 111.893 45.203 111.893Z"
        fill="#005C65"
      />
      <Path
        d="M310.795 96.8906L308.812 104H307.603L305.996 98.4971H305.969L304.369 104H303.159L301.17 96.8906H302.353L303.774 102.674H303.802L305.408 96.8906H306.557L308.17 102.674H308.197L309.619 96.8906H310.795Z"
        fill="#434F64"
      />
      <Path
        d="M297.26 104.121C295.291 104.121 293.965 102.706 293.965 100.443C293.965 98.1738 295.291 96.7656 297.26 96.7656C299.222 96.7656 300.548 98.1738 300.548 100.443C300.548 102.706 299.222 104.121 297.26 104.121ZM297.26 103.075C298.518 103.075 299.338 102.118 299.338 100.443C299.338 98.7686 298.518 97.8115 297.26 97.8115C296.002 97.8115 295.175 98.7686 295.175 100.443C295.175 102.118 296.002 103.075 297.26 103.075Z"
        fill="#434F64"
      />
      <Path
        d="M286.931 103.998V96.8887H288.052V98.0234H288.079C288.476 97.2578 289.234 96.7656 290.335 96.7656C291.894 96.7656 292.796 97.7705 292.796 99.3838V103.998H291.606V99.5957C291.606 98.4473 291.066 97.8184 289.973 97.8184C288.852 97.8184 288.12 98.6113 288.12 99.8145V103.998H286.931Z"
        fill="#434F64"
      />
    </Svg>
  );
};

export default TeenspaceNotificationPrompt;
