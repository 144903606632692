import * as React from 'react';
import { RoomStatus } from 'ts-frontend/entities/Room';
import { TherapistType } from 'chat/redux/constants/chatTypes';
import Routes from './Routes';

const ClientChat: React.FunctionComponent<{
  style?: {};
  therapistType: TherapistType;
  therapistFirstName: string;
  roomStatus: RoomStatus;
  isChatHidden?: boolean;
  hasSeenWelcomeBackInactivityTray?: boolean;
  hasSeenWelcomeBackActivityTray?: boolean;
  applyReactivationTray?: () => void;
}> = (props) => <Routes {...props} />;

export default ClientChat;
