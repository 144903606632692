import { useCallback } from 'react';
import { useHistory, useParams } from '../../core/routerLib';
import ParentalConsentDetailsContainer from '../../parentalConsentDetails/containers/ParentalConsentDetailsContainer';

const OnboardingPageParentalConsentDetails = () => {
  const history = useHistory();
  const { roomID } = useParams<{ roomID: string }>();
  const onComplete = useCallback(() => {
    history.push(`/room/${roomID}/onboarding/next`);
  }, [history, roomID]);

  return <ParentalConsentDetailsContainer onComplete={onComplete} title="Contact information" />;
};

export default OnboardingPageParentalConsentDetails;
