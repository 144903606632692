import { FunctionComponent } from 'react';
import {
  View,
  TextDS,
  BaseButton,
  SpacingView,
  StickyDrawerContextProvider,
  TSAvatar,
  TherapistReviewForm,
} from '@talkspace/react-toolkit';
import { useTranslation } from '@talkspace/i18n';
import { useWizardState } from 'stepWizard';
import { State } from '../reducers/switchWizardState';
import styled from '@/core/styled';
import { extractRoomEntitiesFromState } from '../../utils/extractRoomEntitiesFromState';
import { useMainState } from '../../hooks/mainContext';

const ViewWrapper = styled(View)(({ theme: { spacing } }) => {
  return {
    padding: `${spacing('space300')}px ${spacing('space200')}px`,
  };
});

const FormWrapper = styled(View)(({ theme: { spacing } }) => {
  return {
    marginTop: spacing('space300'),
  };
});

const SkipWrapper = styled(View)(({ theme: { spacing } }) => {
  return {
    marginTop: spacing('space300'),
    textAlign: 'center',
  };
});

const SwitchReview: FunctionComponent<{
  onSubmit: (formData: any) => void;
  onSkip: () => void;
}> = ({ onSubmit, onSkip }) => {
  const { t: tSwitchWizard } = useTranslation('switchWizard');
  const { roomID } = useWizardState<State>();
  const mainState = useMainState();
  const { therapist } = extractRoomEntitiesFromState(mainState, roomID);
  if (!therapist?.id) {
    return null;
  }

  return (
    <StickyDrawerContextProvider>
      <ViewWrapper align="center" justify="center">
        <SpacingView itemSpacing="space150" centerAlign>
          <TextDS variant="headingXl">
            {tSwitchWizard(
              'SwitchReview.title',
              `We’d love your feedback. How was ${therapist.firstName}?`,
              { firstName: therapist.firstName }
            )}
          </TextDS>
          <TextDS colorRole="textSubtle">
            {tSwitchWizard(
              'SwitchReview.subtitle',
              `Your feedback will be used for quality assurance and anonymously shared with ${therapist.firstName}`,
              { firstName: therapist.firstName }
            )}
          </TextDS>
          <TSAvatar image={therapist?.imageURL} />
        </SpacingView>
        <FormWrapper>
          <TherapistReviewForm
            therapistId={therapist?.id}
            submitButtonText="Next"
            handleOnSubmit={onSubmit}
            isReviewTextRequired={false}
            isLoading={false}
            formerRating={undefined}
            formerReview={undefined}
            roomID={roomID}
            dataQa="switchWizardV2ProviderReview"
          />
        </FormWrapper>
        <SkipWrapper>
          <TextDS>
            {tSwitchWizard(
              'SwitchReview.skipButtonPropt',
              `Haven’t interacted enough with ${therapist.firstName} to rate them?`,
              { firstName: therapist.firstName }
            )}
          </TextDS>
          <BaseButton style={{ padding: 30 }} onPress={onSkip} dataQa="switchReviewSkipReview">
            <TextDS colorRole="textBrandDefault" variant="headingMd">
              {tSwitchWizard('SwitchReview.skipButtonText', `Skip review`)}
            </TextDS>
          </BaseButton>
        </SkipWrapper>
      </ViewWrapper>
    </StickyDrawerContextProvider>
  );
};

export default SwitchReview;
