import { useCallback, useEffect, useRef, useState, forwardRef } from 'react';

import { TextDS, TouchableView, useEmotionTheme, View } from '@talkspace/react-toolkit';

import { elevation1Style } from '@talkspace/react-toolkit/src/designSystems/styles/elevation';
import useHandleClickOutsideContainer from '@talkspace/react-toolkit/src/hooks/useHandleClickOutsideContainer';

import { SmileHeart } from '@talkspace/react-toolkit/src/designSystems/icons';
import EmojiReaction from './EmojiReaction';

import { ReactionsOrder } from '../constants';
import { EmojiSize, ReactionsEnum } from '../types';

const ANIMATION_TIME = 300;

const getEmojiAnimationStyle = (emojiIndex: number) => {
  return {
    transform: `scale(0.5) translateY(-80px) translateX(${-78 * emojiIndex}px)`,
    transition: `transform ${ANIMATION_TIME}ms`,
  };
};

interface Props {
  reactions: { [key in ReactionsEnum]: number };
  myReactions: ReactionsEnum[] | undefined;
  onAddReaction: (reaction: ReactionsEnum) => () => void;
}

interface ReactCTAProps {
  isSelected: boolean;
  handleClick: () => void;
}

const ReactCTA: React.FC<ReactCTAProps> = ({ isSelected, handleClick }) => {
  const { spacing } = useEmotionTheme();

  return (
    <View row align="center" style={{ height: 28 }}>
      <TextDS
        onPress={handleClick}
        dataQa="emojiReactionTextButton"
        variant="headingMd"
        colorRole={isSelected ? 'textSubtle' : 'textSubtlest'}
        style={{ marginLeft: spacing('space050') }}
      >
        React
      </TextDS>
    </View>
  );
};

const ReactionsPreview = ({
  reactions,
  myReactions,
  toggleSelector,
}: Pick<Props, 'reactions' | 'myReactions'> & { toggleSelector: () => void }) => {
  const { colorRoles, spacing } = useEmotionTheme();
  const [isSelected, setIsSelected] = useState(false);
  const reactionsCount = Object.values(reactions).reduce((acc, count) => acc + count, 0);
  const shiftLeftPx = 8;
  const noMyReactions = myReactions?.length === 0;
  const noReactions = reactionsCount === 0;
  const handleClick = () => {
    setIsSelected(true);
  };

  useEffect(() => {
    const handleClickAway = (event) => {
      if (event.target.closest('.selectable-area') === null) {
        setIsSelected(false);
      }
    };
    document.addEventListener('click', handleClickAway);

    return () => {
      document.removeEventListener('click', handleClickAway);
    };
  }, []);

  return (
    <TouchableView row align="center" onPress={toggleSelector}>
      <View
        row
        data-qa="emojiReactions"
        align="center"
        style={{ marginRight: spacing('space050') }}
      >
        {noReactions ? (
          <View onClick={handleClick} data-qa="reaction-smile-heart">
            <SmileHeart size="major" colorType={isSelected ? 'subtle' : 'subtlest'} />
          </View>
        ) : (
          <>
            {ReactionsOrder.filter((reaction) => Boolean(reactions[reaction])).map(
              (reactionName, i) => (
                <EmojiReaction
                  key={`reaction-${reactionName}`}
                  reactionName={reactionName}
                  size={EmojiSize.SMALL}
                  circleColor={colorRoles.surfaces.defaultSubtleDefault}
                  style={{
                    marginLeft: i !== 0 ? -shiftLeftPx : undefined,
                    zIndex: ReactionsOrder.length - i,
                  }}
                />
              )
            )}
            <TextDS>{reactionsCount}</TextDS>
          </>
        )}
      </View>
      {noMyReactions && <ReactCTA isSelected={isSelected} handleClick={handleClick} />}
    </TouchableView>
  );
};

const ReactionsSelector = forwardRef<
  HTMLDivElement,
  Omit<Props, 'reactions'> & { toggleSelector: () => void; isClosing: boolean }
>(({ myReactions, onAddReaction, toggleSelector, isClosing }, ref) => {
  const { colorRoles, spacing } = useEmotionTheme();
  const [clickedReaction, setClickedReaction] = useState<ReactionsEnum>();

  const onPressReaction = useCallback(
    (reactionName: ReactionsEnum) => () => {
      setClickedReaction(reactionName);
      onAddReaction(reactionName)();
      toggleSelector();
    },
    [onAddReaction, toggleSelector]
  );

  return (
    <View
      ref={ref}
      row
      style={{
        position: 'absolute',
        bottom: -54,
        borderRadius: 28,
        backgroundColor: colorRoles.surfaces.defaultSubtleDefault,
        padding: `${spacing('space050')}px ${spacing('space075')}px`,
        ...elevation1Style,
        ...(isClosing
          ? {
              opacity: 0,
              transition: `opacity ${ANIMATION_TIME}ms`,
            }
          : {}),
        zIndex: 1,
      }}
    >
      {ReactionsOrder.map((reactionName, i) => {
        const shouldAnimate =
          reactionName === clickedReaction && isClosing && !myReactions?.includes(reactionName);
        return (
          <EmojiReaction
            key={`reaction-${reactionName}`}
            reactionName={reactionName}
            size={EmojiSize.LARGE}
            onPress={onPressReaction(reactionName)}
            circleColor={
              myReactions?.includes(reactionName) && !isClosing
                ? colorRoles.surfaces.successDefault
                : undefined
            }
            style={shouldAnimate ? getEmojiAnimationStyle(i) : {}}
          />
        );
      })}
    </View>
  );
});

const ReactionsToolbar = ({ reactions, myReactions, onAddReaction }: Props) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isClosing, setIsClosing] = useState<boolean>(false);

  const timerRef = useRef<NodeJS.Timeout>();

  const closeSelector = useCallback(() => {
    setIsClosing(true);
    const timerID = setTimeout(() => {
      setIsOpened(false);
      setIsClosing(false);
    }, ANIMATION_TIME);
    timerRef.current = timerID;
  }, []);

  const openSelector = useCallback(() => {
    setIsOpened(true);
  }, []);

  const toggleSelector = () => {
    if (isOpened) {
      closeSelector();
    } else {
      openSelector();
    }
  };

  const selectorRef = useRef<HTMLDivElement>(null);

  // clear the timeout if exists
  useEffect(
    () => () => {
      timerRef.current ?? clearTimeout(timerRef.current);
    },
    []
  );

  useHandleClickOutsideContainer({ containerRef: selectorRef, callback: closeSelector });

  return (
    <View style={{ position: 'relative' }}>
      <ReactionsPreview
        myReactions={myReactions}
        reactions={reactions}
        toggleSelector={toggleSelector}
      />
      {isOpened && (
        <ReactionsSelector
          ref={selectorRef}
          myReactions={myReactions}
          onAddReaction={onAddReaction}
          toggleSelector={toggleSelector}
          isClosing={isClosing}
        />
      )}
    </View>
  );
};

export default ReactionsToolbar;
