import { FunctionComponent, MouseEventHandler, useState, useEffect, useRef } from 'react';
import { commonStyles, View, Image, TouchableView } from '@talkspace/react-toolkit';
import ErrorModal from 'chat/components/ErrorModal';
import { withRouter, RouteComponentProps } from '../core/routerLib';
import styled from '../core/styled';
import { braze, trackEvent } from '../utils/analytics/eventTracker';
import { BrazeContentCard, BrazeContentCards } from '../utils/analytics/trackerTypes';
import GenericModal from '../components/GenericModal';
import { useOpenModal } from '../utils/ModalsContextProvider';

const { crossBrowserFocusRing } = commonStyles;

const REFRESH_INTERVAL = 5 * 60 * 1000;

const Wrapper = styled(View)({
  flex: 1,
  flexDirection: 'row',
  overflowY: 'hidden',
  /* hide scrollbar on chrome, firefox and edge */
  WebkitScrollbar: {
    display: 'none',
  },
  MsOverflowStyle: 'none',
  scrollbarWidth: 'none',
  // prevents the focus outline of the offer from getting cut off
  ':focus-within': {
    overflowY: 'visible',
  },
});

const OfferButton = styled(TouchableView)({
  ':focus': {
    outline: 'none',
  },
});

const OfferImage = styled(Image)<{ isFocused: boolean }>(({ isFocused }) => {
  const outlineStyle = isFocused ? crossBrowserFocusRing : { outline: 'none' };
  return {
    width: 345,
    height: 160,
    marginLeft: 15,
    ...outlineStyle,
  };
});

interface InfoMessage {
  buttonText?: string;
  description: string;
  iconURL?: string;
  onButtonPress: MouseEventHandler<HTMLDivElement>;
}

const BrazeContentCardsContainer: FunctionComponent<
  RouteComponentProps<{
    roomID: number;
  }> & {
    renderHeader?: () => JSX.Element;
    feed: 'dashboard';
    cardsToDisplay?: number;
  }
> = ({ feed, renderHeader, history, cardsToDisplay = 1 }) => {
  const openModal = useOpenModal();
  const contentCardsDisplayedEvent = useRef<boolean>(false);
  const displayedCardsID = useRef<string[]>([]);
  const [contentCards, setContentCards] = useState<BrazeContentCard[]>();
  const [error, setError] = useState<string | undefined>();
  const [infoMessage, setInfoMessage] = useState<InfoMessage | undefined>();

  const [isFocused, setIsFocused] = useState(false);
  const onFocus = () => {
    setIsFocused(true);
  };
  const onBlur = () => {
    setIsFocused(false);
  };

  // cards interval refreshing
  useEffect(() => {
    const refreshCards = () => braze.requestContentCardsRefresh();

    const interval = setInterval(refreshCards, REFRESH_INTERVAL);

    refreshCards();
    return () => {
      clearInterval(interval);
    };
  }, [cardsToDisplay, feed]);

  // cards subscriber
  useEffect(() => {
    let subscriptionID;
    let mounted = true;
    braze
      .subscribeToContentCardsUpdates((cachedCards: BrazeContentCards) => {
        const filteredCards = cachedCards.cards.filter(
          (card) => card.extras && card.extras.feed_type === feed
        );
        if (filteredCards.length) {
          const slicedCards = filteredCards.slice(0, cardsToDisplay); // we are showing only the first one for now
          setContentCards(slicedCards);
        }
      })
      .then((subID) => {
        subscriptionID = subID;
        if (!mounted) {
          braze.removeSubscription(subscriptionID);
          subscriptionID = undefined;
        }
      });
    return () => {
      if (subscriptionID) braze.removeSubscription(subscriptionID);
      mounted = false;
    };
  }, [cardsToDisplay, feed]);

  // cards analytics
  useEffect(() => {
    // report only once content cards displayed
    if (contentCards && !contentCardsDisplayedEvent.current) {
      braze.logContentCardsDisplayed();
      contentCardsDisplayedEvent.current = true;
    }

    // report CardImpressions on New cards only
    if (contentCards) {
      const newCards = contentCards.filter((card) => !displayedCardsID.current.includes(card.id));
      if (newCards.length) {
        braze.logCardImpressions(newCards);
        displayedCardsID.current = contentCards.map((card) => card.id);
      }
    }
  }, [contentCards]);

  function getModalPath(openURL?: string): string | null {
    const VALID_URL = '/dl/?to=';
    const sliceIdx = openURL ? openURL.indexOf(VALID_URL) : -1;

    if (sliceIdx > -1 && openURL)
      return decodeURIComponent(openURL.slice(sliceIdx + VALID_URL.length));

    return null;
  }

  function handleCardOnPress(card: BrazeContentCard) {
    let buttonPressHandler: Function | undefined;
    const { extras } = card;

    const { campaign_name: Campaign, variant: Variation } = extras;
    if (Campaign && Variation) {
      trackEvent('Clicked on Content Card', { Campaign, Variation }, ['mixpanel']);
    }
    if (card.extras && card.extras.action) {
      switch (card.extras.action) {
        case 'showInformation':
          buttonPressHandler = () => undefined;
          break;
        case 'applyVoucherCouponToAccount':
          {
            const { code, roomID } = extras;
            if (code && roomID) {
              buttonPressHandler = () => {
                history.push(`/room/${roomID}?action=apply-coupon&code=${code}`);
              };
            }
          }
          break;
        case 'openAuthenticatedWebView':
          {
            const { openURL } = extras;
            const modalPath = getModalPath(openURL);

            if (modalPath) {
              buttonPressHandler = () => {
                openModal(modalPath);
              };
            }
          }
          break;
        case 'openFriendReferral':
          buttonPressHandler = () => {
            openModal('/friend-referral');
          };
          break;
        case 'openInBrowser':
          buttonPressHandler = () => {
            const { openURL } = extras;
            window.open(openURL, '_blank');
          };
          break;
        default:
          break;
      }

      if (buttonPressHandler) {
        // if we have description we show it in an Info modal before the action then dismiss it
        if (extras.description) {
          setInfoMessage({
            description: extras.description,
            buttonText: extras.buttonText,
            iconURL: extras.iconURL,
            onButtonPress: () => {
              setInfoMessage(undefined);
              if (buttonPressHandler) buttonPressHandler();
            },
          });
        } else {
          buttonPressHandler();
        }
        braze.logCardClick(card);
        return;
      }
    }
    setError('Something went wrong. Please try again later.');
  }

  const onModalClosePress = () => {
    setError(undefined);
    setInfoMessage(undefined);
  };

  if (!contentCards) return null;

  return (
    <>
      {error && <ErrorModal error={error} onClosePress={onModalClosePress} />}
      {infoMessage && (
        <GenericModal
          description={infoMessage.description}
          buttonText={infoMessage.buttonText}
          iconURL={infoMessage.iconURL}
          onButtonPress={infoMessage.onButtonPress}
          onClosePress={onModalClosePress}
        />
      )}
      <View style={{ marginBottom: 29, marginTop: 6 }}>
        <Wrapper>
          <View row style={{ paddingRight: 14 }}>
            {contentCards.map((card) => (
              <OfferButton
                aria-label={card.extras.cardAccessibilityText}
                key={card.id}
                onPress={() => handleCardOnPress(card)}
                onFocus={onFocus}
                onBlur={onBlur}
              >
                <OfferImage
                  isFocused={isFocused}
                  src={card.imageUrl}
                  width={345}
                  height={160}
                  style={{ marginLeft: 15 }}
                />
              </OfferButton>
            ))}
          </View>
        </Wrapper>
      </View>
    </>
  );
};
export default withRouter(BrazeContentCardsContainer);
