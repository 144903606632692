import Svg, { G, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const Timezone = (props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'globe';
  return (
    <Svg
      width="16px"
      height="18px"
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 16 18"
      {...props}
    >
      <G fill={colors.prezi} fillRule="nonzero" stroke="none" strokeWidth={1}>
        <Path
          d="M14.4 8.29c.073.365.145.8.145 1.165 0 1.018-.218 2.036-.654 2.909-1.455.8-2.11.363-2.764-.146-.436-.363-1.09-.8-1.89-.509-.8.291-1.382.946-1.746 1.746-.291.727-.364 1.672-.218 2.545a6.129 6.129 0 01-3.71-1.745c.51-1.091.51-2.182-.145-3.128-.145-.29-.363-.509-.582-.727-.363-.364-.218-1.018.219-1.236C3.636 8.873 4.29 8.582 4.727 8c.873-1.31-.145-3.2-.363-3.564-.073-.072-.146-.145-.219-.29a7.189 7.189 0 012.4-1.091 4.7 4.7 0 01.437-1.528C3.055 2.037 0 5.382 0 9.455c0 4.436 3.564 8 8 8s8-3.564 8-8c0-.873-.145-1.746-.436-2.619-.364.51-.728 1.019-1.164 1.455z"
          transform="translate(-430 -593) translate(430 592) translate(0 1)"
        />
        <Path
          d="M11.636 0C9.818 0 8 1.382 8 3.636c0 2.037 2.836 4.655 3.127 4.873a.66.66 0 00.51.218.66.66 0 00.508-.218c.291-.218 3.128-2.836 3.128-4.873C15.273 1.382 13.455 0 11.636 0zm0 5.09c-.8 0-1.454-.654-1.454-1.454 0-.8.654-1.454 1.454-1.454.8 0 1.455.654 1.455 1.454 0 .8-.655 1.455-1.455 1.455z"
          transform="translate(-430 -593) translate(430 592) translate(0 1)"
        />
      </G>
    </Svg>
  );
};

export default Timezone;
