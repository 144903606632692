import { FunctionComponent, useState } from 'react';
import {
  View,
  Standard,
  Large,
  Image,
  ResponsiveLayoutWithHeader,
  PanelHeader,
  useUniqueID,
  HiddenText,
} from '@talkspace/react-toolkit';
import { withRouter, RouteComponentProps } from '../core/routerLib';
import styled from '../core/styled';
import MenuNavLink from '../components/MenuNavLink';
import magnifyingGlassQuestionMark from '../assets/magnifyingGlassQuestionMark.png';
import SpeechBubbleQuestionMark from '../components/icons/SpeechBubbleQuestionMark';
import Envelope from '../components/icons/Envelope';
import RoundedOverlappingSquares from '../components/icons/RoundedOverlappingSquares';
import ssoHelper, { ZendeskPath } from '../utils/sso';
import {
  ID_ACCOUNT_MENU_DROPDOWN_BUTTON,
  ID_CHAT_INPUT,
  ID_CUSTOMER_SUPPORT_PANEL,
} from '../utils/IDConstants';

// note: These behave like links. I tried as="a" so a screen reader would announce them as links. this succeeded in rendering
// them as <a> tags in the dom but screen reader still announced them as buttons. might have to do with the fact that they have no href or target attributes
const HoverItem = styled(MenuNavLink)(({ theme: { window } }) => {
  return {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: window.isLarge && window.width > 966 ? 503 : 335,
    height: 38,
    paddingLeft: 10,
    paddingRight: 10,
  };
});

type Props = RouteComponentProps<{ roomID: string }> & {
  isChatHidden?: boolean;
  isMobile?: boolean;
};

const CustomerSupport: FunctionComponent<Props> = ({ history, match, isChatHidden, isMobile }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const goToZendesk = (path: ZendeskPath) => {
    setIsLoading(true);
    ssoHelper.openZendesk(path).finally(() => {
      setIsLoading(false);
    });
  };

  const handleOnRoomPanelClosePress = () => {
    history.push(`/room/${match.params.roomID}`);
  };

  const skipNavs = [
    {
      buttonText: 'Return to dashboard',
      returnID: ID_ACCOUNT_MENU_DROPDOWN_BUTTON,
    },
    {
      buttonText: 'Enter Chat',
      returnID: ID_CHAT_INPUT,
    },
  ];

  const newTabInfoId = useUniqueID('newTabInfoId');
  const titleId = useUniqueID('titleId');
  return (
    <ResponsiveLayoutWithHeader
      titleId={titleId}
      panelId={ID_CUSTOMER_SUPPORT_PANEL}
      renderHeader={() => (
        <PanelHeader
          title="Customer support"
          titleId={titleId}
          onRightPress={handleOnRoomPanelClosePress}
          skipNavs={skipNavs}
          isChatHidden={isChatHidden}
          isMobile={isMobile}
          dataQa="customerSupportCloseButton"
        />
      )}
    >
      <View align="center">
        <HiddenText id={newTabInfoId}>Opens in new tab</HiddenText>
        <View style={{ marginTop: 30, marginBottom: 30 }}>
          <Image
            src={magnifyingGlassQuestionMark}
            height={89}
            width={86}
            alt="magnifying glass question mark"
          />
        </View>

        <Large style={{ width: 290, textAlign: 'center', marginBottom: 30 }}>
          View our Help Center, create a ticket or manage existing tickets.
        </Large>
        <HoverItem
          onPress={() => goToZendesk(ssoHelper.ZendeskPath.KNOWLEDGE_BASE as ZendeskPath)}
          disabled={isLoading}
          aria-describedby={newTabInfoId}
          dataQa="customerSupportHelpCenterButton"
        >
          <SpeechBubbleQuestionMark aria-hidden="true" style={{ marginTop: 5 }} />
          <Standard style={{ marginLeft: 17, alignSelf: 'center' }}>Help Center</Standard>
        </HoverItem>
        <HoverItem
          onPress={() => goToZendesk(ssoHelper.ZendeskPath.CONTACT_US as ZendeskPath)}
          disabled={isLoading}
          aria-describedby={newTabInfoId}
          dataQa="customerSupportContactUsButton"
        >
          <Envelope aria-hidden="true" style={{ marginTop: 5 }} />
          <Standard style={{ marginLeft: 17, alignSelf: 'center' }}>Contact Us</Standard>
        </HoverItem>
        <HoverItem
          onPress={() => goToZendesk(ssoHelper.ZendeskPath.MY_TICKETS as ZendeskPath)}
          disabled={isLoading}
          aria-describedby={newTabInfoId}
          dataQa="customerSupportMyTicketsButton"
        >
          <RoundedOverlappingSquares aria-hidden="true" style={{ marginTop: 5 }} />
          <Standard style={{ marginLeft: 17, alignSelf: 'center' }}>My tickets</Standard>
        </HoverItem>
      </View>
    </ResponsiveLayoutWithHeader>
  );
};

export default withRouter(CustomerSupport);
