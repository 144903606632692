import { Auth, CognitoUser } from '@aws-amplify/auth';
import { AuthResultCode, MFA_TYPE } from './types';

type ConfirmSignInParameters = {
  cognitoUser: CognitoUser;
  code: string;
  mfaType: MFA_TYPE;
};

type ConfirmSignInResult = {
  idToken?: string;
  result: AuthResultCode;
};

/*
  ❗ Read https://github.com/aws-amplify/amplify-js/issues/8469, confirmSignIn is throwing an InvalidParameterException
  because we are using USER_PASSWORD_AUTH which does not accept any parameter named device key. Therefore we
  are returning a result if no other exceptions are caught.
*/
const confirmSignIn = async ({
  cognitoUser,
  code,
  mfaType,
}: ConfirmSignInParameters): Promise<ConfirmSignInResult> => {
  let user;
  let idToken;
  try {
    user = await Auth.confirmSignIn(cognitoUser, code, mfaType);
    idToken = user.signInUserSession?.idToken?.jwtToken;
    return { idToken, result: AuthResultCode.SUCCESS };
  } catch (e) {
    const error = e as { code: string };

    if (error.code === 'NetworkError') {
      return { result: AuthResultCode.NETWORK_ERROR };
    }

    if (error.code === 'CodeMismatchException') {
      return { result: AuthResultCode.CODE_MISMATCH };
    }

    if (error.code === 'InvalidParameterException') {
      user = await Auth.currentAuthenticatedUser();
      idToken = user.signInUserSession?.idToken?.jwtToken;
      return { idToken, result: AuthResultCode.SUCCESS };
    }
    throw error;
  }
};

export default confirmSignIn;
