import { FunctionComponent, useEffect } from 'react';
import { View } from '@talkspace/react-toolkit';
import ActionStatus from '@/components/ActionStatus/ActionStatus';
import styled from '../../core/styled';
import useFriendReferralInfo from '../hooks/useFriendReferralInfo';
import FriendReferral from '../components/FriendReferral';
import FriendReferralApiHelper from '../utils/friendReferralApiHelper';

const Container = styled(View)({
  minHeight: '100%',
  paddingBottom: 20,
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 20,
});

const FriendReferralContainer: FunctionComponent = () => {
  const [state, { getFriendReferralInfo }] = useFriendReferralInfo();
  const { isLoading, error, friendReferralInfo } = state;

  useEffect(() => {
    getFriendReferralInfo();

    return () => {
      FriendReferralApiHelper.reset();
    };
  }, [getFriendReferralInfo]);

  return (
    <>
      {(isLoading || error) && <ActionStatus isLoading={isLoading} isError={!!error} />}
      {friendReferralInfo && Object.keys(friendReferralInfo).length ? (
        <Container justify="center" align="center">
          <FriendReferral friendReferralInfo={friendReferralInfo} />
        </Container>
      ) : null}
    </>
  );
};

export default FriendReferralContainer;
