import { FunctionComponent } from 'react';
import {
  TouchableView,
  View,
  Large,
  Big,
  useWindowWidthState,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { Svg, Polyline } from 'svgs';
import { PlanData, BillingCycleUnit, Currency } from 'ts-frontend/types';
import { formatCurrency } from 'ts-frontend/helpers/billingUtils';
import styled, { EmotionStyle } from '@/core/styled';

const StyledButton = styled(View)<{ selected?: boolean; disabled?: boolean }>(
  ({ selected, disabled, theme: { colors } }) => {
    const backgroundColor = disabled ? colors.cobaltGrey : colors.green;
    return {
      backgroundColor: selected ? backgroundColor : colors.white,
      borderRadius: '50%',
      border: selected ? `0px solid ${colors.green}` : `1px solid ${colors.loblollyGrey}`,
      width: 26,
      height: 26,
      marginTop: 8,
    };
  }
);

const SavingStringWrapper = styled(Large)(({ theme: { window } }) => {
  const { isMobile } = window;
  return {
    marginBottom: 2,
    marginLeft: isMobile ? 0 : 5,
  };
});

const CheckIcon = () => (
  <Svg width="11px" height="11px" viewBox="0 0 11 11">
    <Polyline
      stroke="#FFFFFF"
      strokeWidth="2.5"
      strokeLinecap="round"
      fill="transparent"
      points="1.66666667 5.8079756 5.0802873 8.4666667 9.4666667 1.53333333"
    />
  </Svg>
);

const Radio: FunctionComponent<{
  isSelected: boolean;
  isDisabled: boolean;
}> = ({ isSelected, isDisabled }) => (
  <StyledButton
    aria-hidden="true"
    selected={isSelected}
    disabled={isDisabled}
    aria-checked={isSelected}
    align="center"
    justify="center"
  >
    <CheckIcon />
  </StyledButton>
);

const Container = styled(TouchableView)({ marginBottom: 20, borderRadius: 5 });

export type BillingFrequencyVariant = 'horizontal' | 'vertical';

interface BillingFrequencyProps {
  plan: PlanData;
  onPress: () => void;
  savingString?: string;
  isCurrentPlan: boolean;
  isSelected: boolean;
  style?: EmotionStyle;
  disabled?: boolean;
  planExpanded?: boolean;
  className?: string;
  variant: BillingFrequencyVariant;
  oopPromoExperiment?: boolean;
}

function getCycleDisplayName(unit: BillingCycleUnit, cycleValue?: number) {
  if (unit === 'month' && cycleValue === 1) return 'Monthly';
  if (unit === 'month' && cycleValue === 3) return 'Quarterly';
  if (unit === 'month' && cycleValue === 6) return 'Biannually';

  // There aren't any current plans that have this setup but this at least
  // provides a reasonable fallback
  return `Every ${cycleValue} ${unit}${(cycleValue || 0) > 1 ? 's' : ''}`;
}

const formatDisplayPaymentPeriod = (displayPaymentPeriod: string) => {
  switch (displayPaymentPeriod) {
    case 'Paid Monthly':
      return 'Monthly';
    case 'Paid Every 3 Months':
      return 'Quarterly';
    case 'Paid Every 6 Months':
      return 'Biannually';
    default:
      return displayPaymentPeriod;
  }
};

function HorizontalStyle({
  unit,
  cycleValue,
  discountPercent,
  offerAmountString,
}: {
  unit: BillingCycleUnit;
  cycleValue?: number;
  discountPercent?: string;
  offerAmountString: string;
}) {
  return (
    <View align="center">
      <Large variant="largeBoldWide" inline style={{ marginBottom: 2 }}>
        {getCycleDisplayName(unit, cycleValue)}
      </Large>
      <Large variant={discountPercent ? 'largeBoldBrightGreen' : 'largeDarkGrey'} inline>
        {offerAmountString}
      </Large>
      {discountPercent && (
        <Big variant="bigDarkGreen" inline>
          Save {discountPercent.replace('off', '')}
        </Big>
      )}
    </View>
  );
}

function VerticalStyle({
  unit,
  cycleValue,
  discountPercent,
  offerAmountString,
  displayPaymentPeriod,
  isCurrentPlan,
  billAmount,
  savingString,
  currency,
  oopPromoExperiment,
}: {
  unit: BillingCycleUnit;
  cycleValue?: number;
  discountPercent?: string;
  offerAmountString: string;
  displayPaymentPeriod: string;
  isCurrentPlan: boolean;
  billAmount: number;
  savingString?: string;
  currency: Currency;
  oopPromoExperiment?: boolean;
}) {
  const billingAmountString = `(${formatCurrency(billAmount, currency)} ${getCycleDisplayName(
    unit,
    cycleValue
  ).toLowerCase()})`;
  const { isMobile } = useWindowWidthState();
  const updateDesign = oopPromoExperiment;
  return (
    <View>
      {!updateDesign && (
        <>
          <View row align="center">
            <Large variant="largeBoldWide" inline style={{ marginBottom: 2 }}>
              {displayPaymentPeriod}
            </Large>
            {discountPercent && (
              <Big variant="bigDarkGreen" inline style={{ marginBottom: 2, marginLeft: 6 }}>
                -{discountPercent.replace('off', '')}
              </Big>
            )}
            {isCurrentPlan && (
              <Big inline style={{ marginBottom: 2, marginLeft: 6, color: '#133FAB' }}>
                - Your plan
              </Big>
            )}
          </View>
          <View row>
            <Large variant="largeDarkGrey" inline>
              {offerAmountString} {billingAmountString || ''}
            </Large>
            {!isMobile && (
              <SavingStringWrapper variant="largeBoldBrightGreen">
                {savingString}
              </SavingStringWrapper>
            )}
          </View>
          {isMobile && (
            <View row>
              <SavingStringWrapper variant="largeBoldBrightGreen">
                {savingString}
              </SavingStringWrapper>
            </View>
          )}
        </>
      )}

      {updateDesign && (
        <View row align="center">
          {/* Frequency name */}
          <Large inline style={{ marginBottom: 2 }}>
            {formatDisplayPaymentPeriod(displayPaymentPeriod)}
          </Large>
          {/* Save % */}
          {discountPercent && (
            <Large
              variant="largeBoldBrightGreen"
              inline
              style={{
                marginBottom: 2,
                marginLeft: 6,
              }}
            >
              Save {discountPercent.replace('off', '')}
            </Large>
          )}
          {isCurrentPlan && (
            <Big inline style={{ marginBottom: 2, marginLeft: 6, color: '#133FAB' }}>
              - Your plan
            </Big>
          )}
        </View>
      )}
    </View>
  );
}

const BillingFrequency: FunctionComponent<BillingFrequencyProps> = ({
  isCurrentPlan,
  savingString,
  isSelected,
  onPress,
  style,
  className,
  disabled = false,
  planExpanded,
  plan: {
    displayPaymentPeriod,
    discountPercent,
    offerPrice,
    billingPrice: { amount: billAmount, cycleValue, unit, currency },
  },
  variant,
  oopPromoExperiment,
}) => {
  const offerAmountString = offerPrice
    ? `${formatCurrency(offerPrice.amount, offerPrice.currency)}${offerPrice.unit}`
    : '';
  const { colors } = useEmotionTheme();

  return (
    <Container
      role="radio"
      onPress={onPress}
      onFocus={onPress}
      tabIndex={isSelected && planExpanded ? 0 : -1}
      disabled={disabled}
      aria-checked={isSelected || disabled}
      justify="space-between"
      align="center"
      style={style}
      className={className}
      row={variant === 'vertical'}
      roundedFocusStyle
      primaryColor={colors.green}
    >
      {variant === 'horizontal' ? (
        <HorizontalStyle
          unit={unit}
          cycleValue={cycleValue}
          discountPercent={discountPercent}
          offerAmountString={offerAmountString}
        />
      ) : (
        <VerticalStyle
          unit={unit}
          cycleValue={cycleValue}
          discountPercent={discountPercent}
          offerAmountString={offerAmountString}
          displayPaymentPeriod={displayPaymentPeriod}
          isCurrentPlan={isCurrentPlan}
          billAmount={billAmount}
          savingString={savingString}
          currency={currency}
          oopPromoExperiment={oopPromoExperiment}
        />
      )}
      <Radio isSelected={isSelected || disabled} isDisabled={disabled} />
    </Container>
  );
};

export default BillingFrequency;
