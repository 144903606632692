import { FunctionComponent, useState, useEffect } from 'react';
import { View, Modal, Spinner, CloseButton, useEmotionTheme } from '@talkspace/react-toolkit';
import styled, { EmotionStyle } from '../../core/styled';
import { ID_MAIN_PANEL } from '../../utils/IDConstants';

const ContentWrapper = styled(View)<{ hasMinHeight?: boolean }>(
  ({
    theme: {
      window: { isMobile },
      colors,
    },
    hasMinHeight,
  }) => {
    return {
      backgroundColor: colors.white,
      minHeight: hasMinHeight ? 450 : 0,
      height: isMobile ? '100%' : 'auto',
      width: isMobile ? '100%' : 500,
      borderRadius: isMobile ? 0 : 10,
    };
  }
);

const Content = styled(View)<{ hasPaddingTop?: boolean }>(
  ({
    theme: {
      window: { isMobile },
    },
    hasPaddingTop,
  }) => {
    return {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      paddingTop: hasPaddingTop ? 20 : 0,
      paddingLeft: isMobile ? 18 : 70,
      paddingRight: isMobile ? 18 : 70,
    };
  }
);

interface Props {
  onClosePress: () => void;
  isLoading: boolean;
  titleText?: string;
  focusMainOnClose?: boolean;
  isCancel?: boolean;
  hasPaddingTop?: boolean;
  headerStyle?: EmotionStyle;
  hasMinHeight?: boolean;
  underlayStyle?: EmotionStyle;
  dataQa?: string;
}

const BaseModal: FunctionComponent<Props> = ({
  onClosePress,
  isLoading,
  titleText,
  focusMainOnClose,
  isCancel,
  hasPaddingTop,
  headerStyle,
  hasMinHeight = true,
  children,
  underlayStyle,
  dataQa,
}) => {
  const spinnerStyles = {
    width: 68,
    height: 68,
  };
  const { colors } = useEmotionTheme();
  const [isFocusTrapPaused, setIsFocusTrapPaused] = useState<boolean>(false);

  const handleOnClosePress = () => {
    if (focusMainOnClose) {
      setIsFocusTrapPaused(true);
    } else {
      onClosePress();
    }
  };

  useEffect(() => {
    if (isCancel) {
      setIsFocusTrapPaused(true);
    }
  }, [isCancel]);

  useEffect(() => {
    if (isFocusTrapPaused) {
      onClosePress();
      const mainPanel = document.getElementById(ID_MAIN_PANEL);
      if (mainPanel) {
        mainPanel.focus();
      }
    }
  }, [isFocusTrapPaused, onClosePress]);
  return (
    <Modal
      isMobileFullscreen
      titleText={isLoading ? 'loading' : titleText}
      onBackdropPress={handleOnClosePress}
      isVisible
      focusTrapPaused={isFocusTrapPaused}
      underlayStyle={underlayStyle}
      dataQa={dataQa}
    >
      <ContentWrapper hasMinHeight={hasMinHeight}>
        <View row justify="end" style={headerStyle || { padding: 16 }}>
          {!isLoading && <CloseButton onPress={handleOnClosePress} />}
        </View>
        <Content hasPaddingTop={hasPaddingTop}>
          {isLoading ? (
            <View style={{ paddingTop: 54 }}>
              <Spinner
                primaryColor={colors.periwinkleGrey}
                style={spinnerStyles}
                containerStyle={spinnerStyles}
              />
            </View>
          ) : (
            children
          )}
        </Content>
      </ContentWrapper>
    </Modal>
  );
};

export default BaseModal;
