import moment from 'moment';

import { Booking } from 'ts-frontend/types';
import { VideoCallAPIResponse } from 'chat/types/videoCallTypes';

export const isBookingActive = (booking: Booking, activeSessionData?: VideoCallAPIResponse) =>
  (booking.roomID === activeSessionData?.roomID &&
    activeSessionData?.booking.startTime === booking.startTime) ||
  booking.modality === 'messaging';
export const isBookingTentative = (booking: Booking) => booking.timekitBookingState === 'tentative';
export const isBookingScheduledByProvider = (booking: Booking) =>
  booking.scheduledByUserType === 'provider';
export const isBookingScheduledByClient = (booking: Booking) =>
  booking.scheduledByUserType === 'client';
export const isBookingUpcoming = (booking: Booking) => moment(booking.startTime).isAfter(moment());
export const isBookingConfirmed = (booking: Booking) => booking.timekitBookingState === 'confirmed';
