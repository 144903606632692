import sessionStorage from '@/core/storage/sessionStorage';
import getParamByName from '@/utils/queryString';

// on mobile, this will exist in a special web view that doesn't have the ReactFrameService set up
const isInWebView = () => {
  const valueFromQueryParameter = getParamByName('isWebView');

  if (valueFromQueryParameter) {
    sessionStorage.setItem('isWebView', valueFromQueryParameter);
    return valueFromQueryParameter === 'true';
  }

  return sessionStorage.getItem('isWebView') === 'true';
};

export default isInWebView;
