import Svg, { Defs, Path, G, Mask, Use } from 'svgs';

const Psychiatry = ({ size = 87, ...otherProps }) => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 67 67" {...otherProps}>
    <Defs>
      <Path
        d="M11.916 7.43C26.755-3.269 49.237-2.705 60.462 11.024c6.08 7.437 7.634 16.785 5.836 27.252-1.585 9.222-7.031 16.937-13.22 22.267-13.505 11.63-34.066 6.533-45.29-7.196C-3.439 39.618-2.923 18.13 11.915 7.43z"
        id="a"
      />
    </Defs>
    <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <Mask fill="#fff">
        <Use xlinkHref="#a" />
      </Mask>
      <Use fill="#D46953" xlinkHref="#a" />
      <G fill="#FFF" fillRule="nonzero">
        <Path
          d="M20.575 0a5.862 5.862 0 015.862 5.862v14.852a5.862 5.862 0 01-5.862 5.863H6.505a5.862 5.862 0 01-5.863-5.863V5.862A5.862 5.862 0 016.505 0h14.07zm0 2.345H6.505a3.517 3.517 0 00-3.518 3.517v14.852a3.517 3.517 0 003.518 3.518h14.07a3.517 3.517 0 003.517-3.518V5.862a3.517 3.517 0 00-3.517-3.517zm15.917 2.637a2.343 2.343 0 011.112 1.995v12.621a2.345 2.345 0 01-3.393 2.098l-5.339-2.669a1.172 1.172 0 011.048-2.097l5.339 2.668V6.978l-5.339 2.67a1.172 1.172 0 11-1.048-2.098l5.337-2.668a2.343 2.343 0 012.283.1z"
          transform="translate(14.656 18.844)"
        />
      </G>
      <Path
        d="M26.668 25c1.011 0 1.81.236 2.398.709.812.645 1.217 1.474 1.217 2.488s-.405 1.843-1.217 2.489a3.01 3.01 0 01-1.264.604l-.182.035 2.533 2.837 1.567-1.916a.194.194 0 01.15-.07h1.497a.324.324 0 01.243.538l-2.364 2.675 2.66 3.075a.324.324 0 01-.252.536l-1.434-.035a.194.194 0 01-.144-.07l-1.923-2.28-1.926 2.281a.194.194 0 01-.144.07l-1.43.034a.324.324 0 01-.252-.537l2.595-2.988-3.475-4.08h-.757v3.558a.873.873 0 01-.882.864.875.875 0 01-.878-.78L23 34.952V26.28c0-.675.533-1.228 1.209-1.276l.097-.004h2.362zm-.088 1.642h-1.816v3.11h1.816c.682 0 1.175-.15 1.481-.449.306-.3.459-.668.459-1.106 0-.438-.153-.806-.459-1.106-.28-.275-.718-.423-1.315-.446l-.166-.003z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </G>
  </Svg>
);

export default Psychiatry;
