import { useEffect, useCallback, useState } from 'react';
import { RoomStatus } from 'ts-frontend/entities/Room';
import { useFlag } from 'launchDarkly/FlagsProvider';
import { getUserData } from '@/auth/helpers/token';
import { useMainActions, useMainState } from '../../hooks/mainContext';
import { useParams, useHistory } from '@/core/routerLib';
import { trackWizardEvent } from '@/utils/analytics/events';
import PlansList from '../components/PlansList';
import { ACTIVE_PLANS } from '../../utils/subscriptionsHelper';

const ChangeProviderContainer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const b2BMemberInactivity = useFlag('b2BMemberInactivity');
  const { id } = getUserData();
  const { roomID } = useParams<{ roomID: string }>();
  const { getAllRooms, getLVSSubscriptions } = useMainActions();
  const { roomsByID, therapistsByID, subscriptionsByID } = useMainState();

  const history = useHistory();
  const hasB2bRoomsWithInactiveStatus = Object.values(roomsByID).some((room) => {
    const { subscription } = subscriptionsByID?.[room.roomID] || {};
    return (
      RoomStatus.CLOSED === room.status &&
      ['bh', 'eap', 'dte'].includes(room.accountType) &&
      !!subscription?.isClosedInactive
    );
  });

  useEffect(() => {
    const getInitialData = async () => {
      setIsLoading(true);
      await Promise.all([getAllRooms(id), getLVSSubscriptions()]);
      setIsLoading(false);
    };
    getInitialData();
  }, [getAllRooms, getLVSSubscriptions, id]);

  const handlePress = useCallback(
    ({ roomID: changeProviderRoomID, therapistID, accountType, status }) => {
      trackWizardEvent('Change Therapist', 'Self Serve Switch', {
        'User ID': id,
        label: 'Change Therapist',
        Application: 'Self Serve Switch',
        eventPropertyValue: therapistID,
        accountType,
        roomStatus: status,
        Source: 'Account Settings',
      });

      if (changeProviderRoomID) {
        const { subscription } = subscriptionsByID?.[changeProviderRoomID] || {};
        const source =
          b2BMemberInactivity &&
          status === RoomStatus.CLOSED &&
          ['bh', 'eap', 'dte'].includes(accountType) &&
          !!subscription?.isClosedInactive
            ? { source: 'b2bInactivity' }
            : null;
        history.push(`/switch-provider/room/${changeProviderRoomID}`, {
          source,
          roomID: Number(changeProviderRoomID),
        });
      }
    },
    [b2BMemberInactivity, history, id, subscriptionsByID]
  );

  if (b2BMemberInactivity && hasB2bRoomsWithInactiveStatus) {
    return (
      <>
        <PlansList
          type="custom"
          isLoading={isLoading}
          roomStatusToFilter={[RoomStatus.CLOSED, ...ACTIVE_PLANS]}
          closeButtonDataQa="changeProviderCloseButton"
          headline="Please choose which plan you’d like to change provider for"
          handlePress={handlePress}
          rooms={
            roomID in roomsByID
              ? {
                  [roomID]: roomsByID[roomID],
                }
              : roomsByID
          }
          providers={therapistsByID}
          subscriptions={subscriptionsByID}
        />
      </>
    );
  }

  return (
    <PlansList
      type="active"
      isLoading={isLoading}
      closeButtonDataQa="changeProviderCloseButton"
      headline="Please choose which plan you’d like to change provider for"
      footerText="That's because you can only change providers for active rooms. To see your inactive rooms, go to the Payment and plans section in Settings."
      handlePress={handlePress}
      rooms={
        roomID in roomsByID
          ? {
              [roomID]: roomsByID[roomID],
            }
          : roomsByID
      }
      providers={therapistsByID}
      subscriptions={subscriptionsByID}
    />
  );
};

export default ChangeProviderContainer;
