import { FunctionComponent } from 'react';
import Svg, { Circle, G, Rect } from 'svgs';
import Avatar from '../Avatar';
import View from '../View';
import { RoundBorderedBookingIcon } from '../icons';
import { useEmotionTheme } from '../../core/styled';

const AvatarFloatingGraphic1 = (props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'avatar';
  return (
    <Svg
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
      title={titleText}
      aria-label={titleText}
      {...props}
    >
      <Circle
        cx={651}
        cy={187}
        r={4}
        transform="translate(-644 -180)"
        stroke={colors.permaConiferGreen}
        strokeWidth={5}
        fill="none"
        fillRule="evenodd"
      />
    </Svg>
  );
};

const AvatarFloatingGraphic2 = (props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'avatar';
  return (
    <Svg
      width="15px"
      height="15px"
      viewBox="0 0 15 15"
      title={titleText}
      aria-label={titleText}
      {...props}
    >
      <G
        transform="translate(-554 -212) rotate(-21 856.425 -1381.058)"
        fill={colors.permaZiggurat}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      >
        <Rect x={5} y={-4.54747351e-13} width={5} height={15} rx={2.5} />
        <Rect transform="rotate(90 7.5 7.5)" x={5} y={0} width={5} height={15} rx={2.5} />
      </G>
    </Svg>
  );
};

const AvatarFloatingGraphic3 = (props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'avatar';
  return (
    <Svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      title={titleText}
      aria-label={titleText}
      {...props}
    >
      <Circle
        cx={140}
        cy={136}
        r={7}
        transform="translate(-130 -126)"
        stroke={colors.permaLavenderBlue}
        strokeWidth={6}
        fill="none"
        fillRule="evenodd"
      />
    </Svg>
  );
};

const AvatarWithFloatingShapes: FunctionComponent<{
  imageURL: string;
  showBookingIcon?: boolean;
}> = ({ imageURL, showBookingIcon }) => (
  <View align="center" justify="center" style={{ position: 'relative', height: 120, width: 120 }}>
    <Avatar image={imageURL} height={85} width={85} />
    <AvatarFloatingGraphic1 style={{ position: 'absolute', top: 42, left: 103 }} />
    <AvatarFloatingGraphic2 style={{ position: 'absolute', top: 72, left: 12 }} />
    <AvatarFloatingGraphic3 style={{ position: 'absolute', top: 15, left: 3 }} />
    {showBookingIcon && (
      <RoundBorderedBookingIcon style={{ position: 'absolute', top: 69, left: 77 }} />
    )}
  </View>
);

export default AvatarWithFloatingShapes;
