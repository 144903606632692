import { useCallback, useMemo } from 'react';
import moment from 'moment';

import ChatApiHelpers from 'chat/redux/helpers/ChatApiHelpersClass';

import { RoomStatus } from 'ts-frontend/entities/Room';
import { DailyPrompt } from '../types';
import useQueryGetAllRooms from '../../hooks/useQueryGetAllRooms';
import { trackCommunitySharePostWithTherapist } from '../utils/analytics';

interface Props {
  userID: string;
  responseText: string;
  dailyPrompt: DailyPrompt | undefined;
}
const useSharePostWithTherapist = ({ userID, dailyPrompt, responseText }: Props) => {
  const { data: roomsData, isLoading: isLoadingRooms } = useQueryGetAllRooms({
    clientUserID: userID,
  });

  const lastActiveRoom = useMemo(
    () =>
      roomsData?.data
        .filter((room) => room.status === RoomStatus.ACTIVE)
        .sort((room1, room2) =>
          moment(room2.lastTherapistChange).diff(room1.lastTherapistChange)
        )[0],
    [roomsData]
  );

  const therapistFirstName = useMemo(
    () =>
      roomsData?.included.therapistInfo.find((t) => t.id === lastActiveRoom?.therapistID)
        ?.firstName,
    [lastActiveRoom?.therapistID, roomsData?.included.therapistInfo]
  );

  const sharePostWithTherapist = useCallback(async () => {
    if (dailyPrompt && lastActiveRoom) {
      await new ChatApiHelpers().postMessage({
        roomID: lastActiveRoom.roomID,
        message: [
          `${therapistFirstName}, I answered a reflection question and wanted to share it with you.`,
          `Daily question: ${dailyPrompt.text}`,
          `Answer: ${responseText}`,
        ].join('\n\n'),
      });
      trackCommunitySharePostWithTherapist();
    }
  }, [dailyPrompt, lastActiveRoom, therapistFirstName, responseText]);

  const isLoading = isLoadingRooms;
  return { isLoading, sharePostWithTherapist };
};

export default useSharePostWithTherapist;
