import { useReducer, useCallback, useEffect } from 'react';
import AdminConfigAPI from '@/utils/adminConfig';
import { getUserData } from '@/auth/helpers/token';
import {
  subscriptionsReducer,
  initialState,
  APIResponse,
  State,
  ModalType,
} from '../reducers/subscriptions';
import API from '../utils/myAccountApiHelper';

export default function useSubscriptions(): [
  State,
  {
    dispatchOpenModal;
    dispatchCloseModal;
    getSubscriptions;
    pauseSubscription;
    resumeSubscription;
    undoCancelSubscription;
    undoCancelNonSubscription;
  }
] {
  const [state, dispatch] = useReducer(subscriptionsReducer, initialState);

  useEffect(() => {
    dispatch({ type: 'requestAdminOptionSuperbills' });
    AdminConfigAPI.getAdminOptionByName('superbills')
      .then((res) => {
        dispatch({
          type: 'receiveAdminOptionSuperbills',
          payload: {
            superbillsFlag: !!res?.data?.data,
          },
        });
      })
      .catch(() => {
        // swallow error for now
      });
  }, []);

  function dispatchOpenModal(modalType: ModalType) {
    dispatch({
      type: 'openModal',
      payload: {
        openModalType: modalType,
      },
    });
  }

  function dispatchCloseModal() {
    dispatch({
      type: 'closeModal',
    });
  }

  function dispatchSetSuccessModalText(text: string) {
    dispatch({
      type: 'setSuccessModalText',
      payload: {
        successModalText: text,
      },
    });
  }

  function getSubscriptions(): void {
    dispatch({ type: 'requestGetSubscriptions' });
    const { id } = getUserData();
    API.getSubscriptions({
      clientUserID: id,
      roomID: undefined,
      includePaymentDetails: true,
      includePlanDetails: true,
    })
      .then((res) => {
        const { data: subscriptionData } = res.data as APIResponse;
        dispatch({
          type: 'receiveGetSubscriptions',
          payload: {
            subscriptions: subscriptionData,
          },
        });
      })
      .catch((error) => {
        dispatch({ type: 'setError', error });
      });
  }

  function pauseSubscription(privateTalkID: number, period?: number): void {
    dispatch({ type: 'requestPauseSubscription' });
    API.pauseSubscription(privateTalkID, period)
      .then((res) => {
        if (res?.data?.success) {
          getSubscriptions();
          dispatchCloseModal();
        } else {
          dispatch({ type: 'setError' });
        }
      })
      .catch((error) => {
        dispatch({ type: 'setError', error: error.data?.error?.message });
      });
  }

  function resumeSubscription(privateTalkID: number): void {
    dispatch({ type: 'requestResumeSubscription' });
    API.resumeSubscription(privateTalkID)
      .then((res) => {
        if (res?.data?.success) {
          getSubscriptions();
          dispatchSetSuccessModalText('Welcome back!\nYour subscription is now active.');
        }
      })
      .catch((error) => {
        dispatch({ type: 'setError', error });
      });
  }

  function undoCancelSubscription(privateTalkID: number): void {
    dispatch({ type: 'requestUndoCancelSubscription' });
    API.undoCancelSubscription(privateTalkID)
      .then(() => {
        getSubscriptions();
        dispatchSetSuccessModalText('Your subscription renewal\nhas been reactivated.');
      })
      .catch((error) => {
        dispatch({ type: 'setError', error });
      });
  }

  function undoCancelNonSubscription(roomID: number): void {
    dispatch({ type: 'requestUndoCancelNonSubscription' });
    API.undoCancelNonSubscription(roomID)
      .then(() => {
        getSubscriptions();
        dispatchSetSuccessModalText('Your plan has been\nsuccessfully reactivated.');
      })
      .catch((error) => {
        dispatch({ type: 'setError', error });
      });
  }

  return [
    state,
    {
      dispatchOpenModal: useCallback(dispatchOpenModal, []),
      dispatchCloseModal: useCallback(dispatchCloseModal, []),
      getSubscriptions: useCallback(getSubscriptions, []),
      pauseSubscription: useCallback(pauseSubscription, []),
      resumeSubscription: useCallback(resumeSubscription, []),
      undoCancelSubscription: useCallback(undoCancelSubscription, []),
      undoCancelNonSubscription: useCallback(undoCancelNonSubscription, []),
    },
  ];
}
