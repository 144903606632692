import { FunctionComponent } from 'react';
import Svg, { Path, G } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

interface CheckmarkIconProps {
  color?: string;
  width?: number;
  height?: number;
  style?: object;
}

const CheckmarkIcon: FunctionComponent<CheckmarkIconProps> = ({
  color,
  width = 13,
  height = 13,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'check mark';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 13 13"
      {...otherProps}
    >
      <G
        transform="translate(1.5 2)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <Path
          d="M0 3.33057219L4.10742959 7.25343018 9.62257338 0"
          stroke={color || colors.white}
          strokeWidth={2.5}
        />
      </G>
    </Svg>
  );
};

export default CheckmarkIcon;
