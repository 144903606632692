import Svg, { Path, Defs, G, Circle } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const PausedPlan = ({ width = 34, height = 34, ...props }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'paused plan';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <Defs>
        <Path
          id="b"
          d="M0 10.01C0 4.48 4.475 0 10.002 0h479.996C495.522 0 500 4.486 500 10.01v519.98c0 5.529-4.475 10.01-10.002 10.01H10.002C4.478 540 0 535.514 0 529.99V10.01z"
        />
        <filter
          id="a"
          width="104.4%"
          height="104.1%"
          x="-2.2%"
          y="-1.3%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology in="SourceAlpha" radius="2" result="shadowSpreadOuter1" />
          <feOffset dy="4" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0.156418748 0 0 0 0 0.255632926 0 0 0 0.4 0"
          />
        </filter>
      </Defs>
      <G
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-451 -457)"
      >
        <Path fill={colors.fordGrey} d="M0 0H1200V800H0z" />
        <G transform="translate(350 130)">
          <use fill={colors.black} filter="url(#a)" xlinkHref="#b" />
          <Path
            fill={colors.white}
            stroke={colors.a11yLinkWaterGrey}
            strokeLinejoin="round"
            d="M.5 10.01v519.98c0 5.25 4.256 9.51 9.502 9.51h479.996c5.25 0 9.502-4.256 9.502-9.51V10.01c0-5.25-4.256-9.51-9.502-9.51H10.002C4.752.5.5 4.756.5 10.01z"
          />
        </G>
        <Path
          fill={colors.white}
          stroke={colors.periwinkleGrey}
          strokeWidth="0.5"
          d="M432.75 446.992c0-5.655 4.593-10.242 10.253-10.242h314.994c5.658 0 10.253 4.593 10.253 10.242v50.016c0 5.655-4.593 10.242-10.253 10.242H443.003c-5.658 0-10.253-4.593-10.253-10.242v-50.016z"
        />
        <G transform="translate(452 458) translate(.204 .388)">
          <G stroke={colors.cobaltGrey} strokeLinecap="round" strokeWidth="2.7">
            <Path
              d="M12.19.612h8.606c2.761 0 5 2.399 5 5.357v14.286c0 2.959-2.239 5.357-5 5.357h-15c-2.762 0-5-2.398-5-5.357v-8.643"
              transform="rotate(180 13.296 13.112)"
            />
            <Path
              strokeDasharray="28.386"
              d="M13.262 18.741A5.322 5.322 0 107.94 13.42"
              transform="rotate(-118 13.262 13.419)"
            />
          </G>
          <Path
            fill={colors.lightCobaltGrey}
            d="M19.796 7.149v5.01c0 .25-.203.453-.453.453h-5.067a.453.453 0 01-.318-.774l5.067-5.011a.453.453 0 01.77.322z"
          />
          <Circle cx="25.296" cy="25.112" r="7" fill={colors.purple} />
          <Path
            fill={colors.white}
            fillRule="nonzero"
            d="M23.546 21.862a.75.75 0 01.75.75v5a.75.75 0 01-.75.75h-.5a.75.75 0 01-.75-.75v-5a.75.75 0 01.75-.75zm4 0a.75.75 0 01.75.75v5a.75.75 0 01-.75.75h-.5a.75.75 0 01-.75-.75v-5a.75.75 0 01.75-.75z"
          />
        </G>
      </G>
    </Svg>
  );
};

export default PausedPlan;
