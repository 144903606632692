/* eslint-disable import/prefer-default-export */
import { Subscription } from '../types';

export const getIsMessagingEnabled = ({ subscription }: { subscription: Subscription }): boolean =>
  subscription.planDetail?.planModality !== 'liveOnly';

export const isEAP = ({ subscription }: { subscription: Subscription }): boolean =>
  subscription.planDetail?.accountType.toLowerCase() === 'eap';
export const isDTE = ({ subscription }: { subscription: Subscription }): boolean =>
  subscription.planDetail?.accountType.toLowerCase() === 'dte';
