import { ExtraHuge, View } from '@talkspace/react-toolkit';
import styled from '@/core/styled';

export const Headline = styled(ExtraHuge)({
  marginBottom: 30,
  marginTop: 30,
});

const sharedContentProperties = {
  display: 'flex',
  alignItems: 'center',
};

export const ContentBodyWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      ...sharedContentProperties,
      width: isMobile ? '95%' : 400,
      padding: isMobile ? 20 : 0,
    };
  }
);

export const Content = styled(View)<{ height: number }>(
  ({
    theme: {
      window: { isMobile },
    },
    height,
  }) => {
    return {
      ...sharedContentProperties,
      width: isMobile ? '100%' : 550,
      height,
    };
  }
);
