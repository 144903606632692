import { UseMutateFunction } from 'react-query';
import { VoidFunctionComponent, useState } from 'react';
import {
  Button,
  Large,
  RadioButton,
  Standard,
  TabRadioGroup,
  View,
} from '@talkspace/react-toolkit';

import { useCloseModal } from '@/utils/ModalsContextProvider';
import styled from '@/core/styled';
import { ContentBodyWrapper, Headline, Content } from '../StyledComponents';
import { RenewVoucherVariables } from '../../hooks/useMutationRenewVoucher';

const RadioButtonWrapper = styled(View)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
});

const StyledRadioButton = styled(RadioButton)({
  padding: 5,
});

const RadioButtonText = styled(Standard)({ textAlign: 'left', fontWeight: 'normal' });
interface Props {
  orgName: string;
  roomID: number;
  renewVoucher: UseMutateFunction<void, Error, RenewVoucherVariables>;
  setShouldClose: (value: boolean) => void;
}

const DTERenewalForm: VoidFunctionComponent<Props> = ({
  orgName,
  roomID,
  renewVoucher,
  setShouldClose,
}) => {
  const [shouldRenew, setShouldRenew] = useState<boolean | null>(null);
  const closeModal = useCloseModal();

  const handleSubmit = () => {
    if (shouldRenew !== null) {
      renewVoucher(
        {
          roomID,
          shouldRenew,
        },
        {
          onSuccess: () => {
            setShouldClose(true);
            closeModal();
          },
        }
      );
    }
  };

  return (
    <Content height={500}>
      <Headline>Benefit Renewal</Headline>
      <ContentBodyWrapper>
        <Large style={{ marginBottom: 50, fontWeight: 'normal' }} variant="largeMediumTSBlack">
          {`To continue receiving this coverage, please attest that you are still an active member of
${orgName}, and therefore eligible for this benefit.`}
        </Large>
        <TabRadioGroup
          style={{
            width: '100%',
            minInlineSize: 'fit-content',
          }}
        >
          <RadioButtonWrapper style={{ marginBottom: 15 }}>
            <StyledRadioButton
              tabFirstOption
              isActive={!!shouldRenew}
              onPress={() => setShouldRenew(true)}
            />
            <View style={{ width: 350 }}>
              <RadioButtonText variant="standardDarkGrey">
                {`I attest that I am eligible for continued coverage through ${orgName}`}
              </RadioButtonText>
            </View>
          </RadioButtonWrapper>
          <RadioButtonWrapper style={{ marginBottom: 30 }}>
            <StyledRadioButton
              tabFirstOption
              isActive={shouldRenew === false}
              onPress={() => setShouldRenew(false)}
            />
            <View style={{ width: 350 }}>
              <RadioButtonText variant="standardDarkGrey">
                I am no longer eligible for this benefit, but wish to view other coverage options
              </RadioButtonText>
            </View>
          </RadioButtonWrapper>
        </TabRadioGroup>
        <Button onPress={handleSubmit}>Submit</Button>
      </ContentBodyWrapper>
    </Content>
  );
};

export default DTERenewalForm;
