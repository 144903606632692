import apiHelper from '../../core/api/apiHelper';
import apiWrapper from '../../core/api/apiWrapper';

const getSuperbills = (roomID: number) => async () => {
  const { data } = await apiWrapper.get(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/superbills`);
  return data.data;
};

const superbillsApiHelper = { getSuperbills };

export default superbillsApiHelper;
