import { WizardScheme } from 'stepWizard';
import { TFAdultOnboarding } from '@talkspace/i18n/types';

const emergencyContactScheme: (tAdultOnboarding: TFAdultOnboarding) => WizardScheme = (
  tAdultOnboarding
) => {
  return {
    wizardModalTitle: 'emergency contact',
    wizardVersion: 1,
    wizardType: 'emergencyContact',
    disablePersist: true,
    steps: [
      {
        title: tAdultOnboarding('name.title', 'What is your name?'),
        inputType: 'form',
        inputState: 'name',
        inputFields: [
          {
            inputType: 'text',
            inputState: 'firstName',
            inputTypePlaceholder: tAdultOnboarding('name.first', 'First Name'),
            validationRequired: true,
          },
          {
            inputType: 'text',
            inputState: 'middleName',
            inputTypePlaceholder: tAdultOnboarding('name.middle', 'Middle Name'),
            validationRequired: false,
          },
          {
            inputType: 'text',
            inputState: 'lastName',
            inputTypePlaceholder: tAdultOnboarding('name.last', 'Last Name'),
            validationRequired: true,
          },
        ],
        nextButton: {
          displayText: tAdultOnboarding('name.next', 'Next'),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        name: 'name',
        displayCondition: 'displayEmergencyContactName',
      },
      {
        title: tAdultOnboarding('homeAddress.title', 'What is your home address?'),
        inputType: 'address',
        inputState: 'address',
        nextButton: {
          displayText: tAdultOnboarding('homeAddress.next', 'Next'),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        name: 'address',
        backOption: 'back',
        displayCondition: 'displayEmergencyContactAddress',
      },
      {
        title: tAdultOnboarding('phoneNumber.title', 'What is your phone number?'),
        inputType: 'phone',
        inputTypePlaceholder: tAdultOnboarding('phoneNumber.enterNumber', 'Enter phone number'),
        inputState: 'phone',
        nextButton: {
          displayText: tAdultOnboarding('phoneNumber.next', 'Next'),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        secondaryButton: {
          displayText: tAdultOnboarding('phoneNumber.skip', 'Skip'),
          actionDispatch: 'next',
          defaultValue: null,
        },
        name: 'phone',
        backOption: 'back',
        displayCondition: 'displayEmergencyContactPhone',
      },
      {
        title: tAdultOnboarding(
          'whoEmergencyContact.title',
          'Who would you like to designate as your emergency contact?'
        ),
        inputType: 'text',
        inputTypePlaceholder: tAdultOnboarding('whoEmergencyContact.fullName', 'Full name'),
        inputState: 'contactName',
        nextButton: {
          displayText: tAdultOnboarding('whoEmergencyContact.next', 'Next'),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        name: 'contactName',
        backOption: 'back',
      },
      {
        title: tAdultOnboarding('whatRelationship.title', 'What is their relationship to you?'),
        inputType: 'select',
        inputTypePlaceholder: tAdultOnboarding('whatRelationship.select', 'Select'),
        inputState: 'relationship',
        inputOptions: 'relationshipOptions',
        nextButton: {
          displayText: tAdultOnboarding('whatRelationship.next', 'Next'),
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        displayCondition: 'contactName',
        name: 'relationship',
        backOption: 'back',
      },
      {
        title: tAdultOnboarding('reachEmergency.title', 'How can we reach your emergency contact?'),
        inputType: 'phone',
        inputState: 'contactPhone',
        nextButton: {
          displayText: tAdultOnboarding('reachEmergency.next', 'Next'),
          actionDispatch: (_, wizardContext) => {
            if (wizardContext.source === 'onboarding') {
              return `/room/${wizardContext.roomID}/onboarding/emergency-contact/source/onboarding/submit`;
            }
            return '/emergency-contact-wizard/submit';
          },
          whenVisible: 'onSelect',
        },
        displayCondition: 'contactName',
        name: 'contactPhone',
        backOption: 'back',
        validationRequired: true,
      },
    ],
  };
};

export default emergencyContactScheme;
