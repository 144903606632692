import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

const getIntakeStatus = (roomID: string) =>
  apiWrapper
    .get(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/intake-available?type=treatment`)
    .then((res) => res.data.data);

export interface MentalHealthPayload {
  sleepQuality: string;
  sleepAidUse?: string;
  socialSupportSystem?: string;
  previousMentalHealthTreatment?: null | string;
  previousMentalHealthIssues: number[];
  hospitalizationHistory: null | string;
  previousRelativesMentalHealthIssues?: number[];
  traumaticExperience: boolean;
  traumaticFlashbacks: null | boolean;
  guilt: null | boolean;
  isolation: null | boolean;
  suicideIdeation: string;
  suicidePlanning: null | string;
  homicidalIdeation?: null | string;
  homicidalPlanning?: null | string;
  angerAggression?: null | boolean;
  experiencingSchoolCommunity?: number[];
  experiencingSocialMedia?: number[];
  isOnboarding: boolean;
}

const submitMentalHealthResults = (
  roomID: number,
  clientUserID: number,
  data: MentalHealthPayload
): Promise<{}> =>
  apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v3/rooms/${roomID}/treatment-clinical-intake/${clientUserID}`,
    { data }
  );

export interface MedicalHealthPayload {
  isPregnant?: null | boolean;
  medicalIssues?: number[];
  hasChronicPain?: null | boolean;
  medications?: (string | number)[];
  medicationsCustom?: (string | number)[];
  otcMedications?: null | string;
  drugAllergies?: null | string;
  pharmacyAddress?: null | string;
  controlledSubstances: number[];
  isOnboarding: boolean;
}

const submitMedicalHealthResults = (
  roomID: number,
  clientUserID: number,
  data: MedicalHealthPayload
): Promise<{}> =>
  apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v3/rooms/${roomID}/treatment-medical-intake/${clientUserID}`,
    { data }
  );

export interface MatchingIntakePayload {
  firstName: string;
  middleName: string | null;
  lastName: string;
  address: string | null;
  address2?: string | null;
  country?: string | null;
  city: string | null;
  state: string | null;
  zipcode: string | null;
  phone: string | null;
  contactName: string | null;
  relationship: string | null;
  contactPhone: string | null;
  isOnboarding: boolean;
  consenterLanguage?: string | null;
}

const updateEmergencyContact = (
  roomID: number,
  clientUserID: number,
  data: MatchingIntakePayload
): Promise<{}> =>
  apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v3/rooms/${roomID}/emergency-contact/${clientUserID}`,
    { data }
  );
export interface IntakeCustomerInformationPayload {
  ethnicity: string | null;
  dateOfBirth: string;
  gender: string;
  school: string | null;
  graduationYear: number | null;
}
const updateCustomerInformation = (
  clientUserID: number,
  data: IntakeCustomerInformationPayload
): Promise<{}> =>
  apiWrapper.patch(`${apiHelper().apiEndpoint}/v3/clients/${clientUserID}/customer-information`, {
    data,
  });

const treatmentIntakeApiHelper = {
  submitMentalHealthResults,
  submitMedicalHealthResults,
  updateEmergencyContact,
  updateCustomerInformation,
  getIntakeStatus,
};
export default treatmentIntakeApiHelper;
