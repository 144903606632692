import { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { View, Text, useEmotionTheme } from '@talkspace/react-toolkit';

import ActionButtons from '../ActionButtons';

interface AccountPanelHeadingProps {
  title: string;
  isEditing?: boolean;
  isLoading?: boolean;
  shouldShowEditButton?: boolean;
  titleStyles?: {};
  setIsEditing?: (editing: boolean) => void;
  saveChanges?: () => void;
}
const Heading = styled(View)({
  paddingTop: 25,
  paddingLeft: 15,
  paddingRight: 15,
  paddingBottom: 10,
  backgroundColor: '#F8F8F8',
});
const HeadingText = styled(Text)<{ titleStyles: {} }>(({ titleStyles }) => {
  return {
    fontSize: 14,
    fontWeight: 500,
    color: '#4A4A4A',
    ...titleStyles,
  };
});

const AccountPanelHeading: FunctionComponent<AccountPanelHeadingProps> = (props) => {
  const {
    saveChanges,
    title,
    setIsEditing,
    isLoading,
    isEditing = false,
    shouldShowEditButton = true,
    titleStyles = {},
  } = props;

  const handleOnEditClick = () => {
    if (setIsEditing) setIsEditing(!isEditing);
  };

  const handleOnCancel = () => {
    if (setIsEditing) setIsEditing(false);
  };

  const handleOnSave = () => {
    if (saveChanges) saveChanges();
  };

  const { colors } = useEmotionTheme();
  return (
    <Heading row justify="space-between">
      <HeadingText titleStyles={titleStyles}>{title}</HeadingText>
      {shouldShowEditButton && (
        <View row align="center">
          {isEditing ? (
            <ActionButtons isLoading={isLoading} onCancel={handleOnCancel} onSave={handleOnSave} />
          ) : (
            <Text
              onClick={handleOnEditClick}
              style={{ color: colors.green, fontSize: 14, cursor: 'pointer' }}
            >
              Edit
            </Text>
          )}
        </View>
      )}
    </Heading>
  );
};

export default AccountPanelHeading;
