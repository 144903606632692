import groupBy from 'lodash/groupBy';
import { SurveyResult } from 'ts-frontend/types';

function extractRoomSurveyResultsByLabel(
  surveyResultsByRoomID: { [key: number]: SurveyResult[] },
  roomID: number
): { [key: string]: SurveyResult[] } {
  const allSurveyResultsForRoom = surveyResultsByRoomID[roomID] || [];
  const resultsMostRecentFirst = allSurveyResultsForRoom.sort(
    (a, b) => new Date(b.completedAt).valueOf() - new Date(a.completedAt).valueOf()
  );
  const resultsByLabel = groupBy(resultsMostRecentFirst, 'surveyLabel');
  return resultsByLabel;
}

export default extractRoomSurveyResultsByLabel;
