import { TextDS } from '@talkspace/react-toolkit';
import { MatchPreferenceName } from '@/types';

interface MatchPreferenceRowProps {
  preferenceName: MatchPreferenceName;
  value: string;
}
const MatchPreferenceRow = ({ preferenceName, value }: MatchPreferenceRowProps) => {
  switch (preferenceName) {
    case 'gender':
      return (
        <TextDS variant="bodySm">
          identify as{' '}
          <TextDS as="span" variant="headingSm">
            {value}
          </TextDS>
        </TextDS>
      );
    case 'presentingProblem':
      return (
        <TextDS variant="bodySm">
          have expertise with{' '}
          <TextDS as="span" variant="headingSm">
            {value}
          </TextDS>
        </TextDS>
      );
    case 'language':
      return (
        <TextDS variant="bodySm">
          speaks{' '}
          <TextDS as="span" variant="headingSm">
            {value}
          </TextDS>
        </TextDS>
      );
    case 'yearsOfExperience':
      return (
        <TextDS variant="bodySm">
          <TextDS as="span" variant="headingSm">
            {value}
          </TextDS>{' '}
          of experience
        </TextDS>
      );
    default:
      return null;
  }
};

export default MatchPreferenceRow;
