import apiHelper from '../../core/api/apiHelper';
import apiWrapper from '../../core/api/apiWrapper';

interface ApiResponse {
  data?: any;
}

export interface MatchingIntakePayload {
  gender: string;
  dateOfBirth: string;
  country: string;
  state?: string | null;
  relationshipStatus?: string;
  therapistGenderPreference?: string;
  presentingProblems: number[];
  treatmentGoal: string;
  therapyBefore: boolean;
  attributionSource: string;
  // TODO: remove educationLevel, workStatus, therapistEthnicityPreferences when backward compat no longer required
  educationLevel?: string;
  workStatus?: string;
  therapistEthnicityPreference?: string;
}

const submitMatchingIntakeResults = (
  roomID: number,
  clientUserID: number,
  data: MatchingIntakePayload
): Promise<ApiResponse> =>
  apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v3/rooms/${roomID}/matching-intake/${clientUserID}`,
    { data }
  );

const matchingIntakeWizardApiHelper = { submitMatchingIntakeResults };

export default matchingIntakeWizardApiHelper;
