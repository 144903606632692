import { EmotionStyle, TextDS, View, spacing } from '@talkspace/react-toolkit';

import FaceSmile from '../assets/icons/FaceSmile';

const { space100, space600 } = spacing;

interface Props {
  style?: EmotionStyle;
}
const CommunityFeedEnd = ({ style }: Props) => (
  <View align="center" style={{ margin: `${space600}px 0`, ...style }}>
    <FaceSmile />
    <TextDS variant="body" colorRole="textSubtle" style={{ marginTop: space100 }}>
      You're all caught up!
    </TextDS>
  </View>
);

export default CommunityFeedEnd;
