import { FunctionComponent } from 'react';
import { BaseButton, Standard, Large, useEmotionTheme } from '@talkspace/react-toolkit';
import EmailEnvelope from '../../../emailVerification/assets/EmailEnvelope';
import styled from '../../../core/styled/styled';

const CancelButton = styled(BaseButton)(({ theme: { colors } }) => {
  return { marginTop: 20, marginBottom: 20, padding: 0, backgroundColor: colors.white };
});

interface Props {
  email: string;
  onCancelPress: () => void;
}

const EmailVerificationModal: FunctionComponent<Props> = ({ email, onCancelPress }) => {
  const { colors } = useEmotionTheme();
  return (
    <>
      <EmailEnvelope />
      <Large
        variant="largeDarkGrey"
        style={{ paddingTop: 30 }}
        dataQa="emailVerificationDescription"
      >
        We just sent a verification email to{' '}
      </Large>
      <Large variant="largeDarkGrey" style={{ fontWeight: 'bold' }} dataQa="emailVerificationEmail">
        {email}
      </Large>
      <Large variant="largeDarkGrey" style={{ paddingTop: 20 }}>
        Please click the link in the email to finish updating your email address.
      </Large>
      <CancelButton style={{ paddingTop: 20 }} onPress={onCancelPress}>
        <Standard style={{ color: colors.greenText }}>Ok</Standard>
      </CancelButton>
    </>
  );
};

export default EmailVerificationModal;
