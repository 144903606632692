import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { ResponsiveLayoutWithHeader, PanelHeader, Spinner } from '@talkspace/react-toolkit';
import { RoomInvite } from 'ts-frontend/entities/RoomInvite';
import { useNewMemberNav } from 'launchDarkly/hooks';
import RoomInviteForm from '../components/RoomInviteForm';
import { RouteComponentProps } from '../../core/routerLib/routerLib';
import MobileLeftHeader from '../../components/MobileLeftHeader';
import ReactFrameService from '../../auth/reactFrame/ReactFrameService';
import InvitationLinkExpired from '../components/InvitationLinkExpired';
import InvitationRevokedSuccess from '../components/InvitationRevokedSuccess';
import InvitationSentSuccess from '../components/InvitationSentSuccess';
import {
  useRoomInvitesActions,
  useRoomInviteState,
  RoomInviteStatus,
} from '../hooks/useRoomInvites';

import { useCloseModal } from '@/utils/ModalsContextProvider';

const RenderInviteContent: FunctionComponent<{
  status: RoomInviteStatus;
  currentInvitation: RoomInvite;
  roomID: number;
  handleClosePanel: () => void;
  setInviteScreen: (screen: RoomInviteStatus) => void;
}> = ({ status, currentInvitation, roomID, handleClosePanel, setInviteScreen }) => {
  switch (status) {
    case RoomInviteStatus.HAS_NO_INVITES:
      return <RoomInviteForm roomID={roomID} />;
    case RoomInviteStatus.HAS_ACTIVE_INVITE:
      return (
        <InvitationSentSuccess
          roomID={roomID}
          inviteEmail={currentInvitation.invitationSentToEmail}
          inviteID={currentInvitation.privateTalkInvitationID}
          handleClosePanel={handleClosePanel}
        />
      );
    case RoomInviteStatus.HAS_EXPIRED_INVITE:
      return (
        <InvitationLinkExpired
          roomID={roomID}
          inviteEmail={currentInvitation.invitationSentToEmail}
          setInviteScreen={setInviteScreen}
        />
      );
    case RoomInviteStatus.HAS_REVOKED_INVITE:
      return (
        <InvitationRevokedSuccess
          inviteEmail={currentInvitation.invitationSentToEmail}
          setInviteScreen={setInviteScreen}
          handleClosePanel={handleClosePanel}
        />
      );
    default:
      return <Spinner />;
  }
};

const RoomInvitesContainer: FunctionComponent<
  RouteComponentProps<{
    roomID: string;
  }>
> = ({ match, history }) => {
  const { roomID } = match.params;
  const numRoomID = Number(roomID);
  const { fetchRoomInvites } = useRoomInvitesActions();
  const inviteStateByRoom = useRoomInviteState();
  const inviteState = inviteStateByRoom[roomID];
  const { roomInviteStatus, currentInvitation } = inviteState || {};
  const [inviteScreen, setInviteScreen] = useState<RoomInviteStatus>(roomInviteStatus);
  const closeModal = useCloseModal();
  const useNewNav = useNewMemberNav();

  useEffect(() => {
    fetchRoomInvites(numRoomID);
  }, [fetchRoomInvites, numRoomID]);

  useEffect(() => {
    setInviteScreen(roomInviteStatus);
  }, [roomInviteStatus]);

  const closeFrameIfExists = () => {
    if (ReactFrameService.instance().isInFrame()) {
      ReactFrameService.instance().closePopup();
      return true;
    }
    return false;
  };

  const onBackButtonPress = () => {
    if (closeFrameIfExists()) return;
    history.goBack();
  };

  const handleClosePanel = useCallback(() => {
    if (useNewNav) {
      closeModal();
      return;
    }
    if (closeFrameIfExists()) return;
    history.push(`/room/${roomID}`);
  }, [useNewNav, closeModal, history, roomID]);

  return (
    <ResponsiveLayoutWithHeader
      renderHeader={() => (
        <PanelHeader
          renderLeft={() => (
            <MobileLeftHeader
              centerTitle
              title="Add your partner"
              onBackButtonPress={onBackButtonPress}
            />
          )}
          onRightPress={handleClosePanel}
        />
      )}
    >
      <RenderInviteContent
        roomID={numRoomID}
        currentInvitation={currentInvitation}
        status={inviteScreen}
        handleClosePanel={handleClosePanel}
        setInviteScreen={setInviteScreen}
      />
    </ResponsiveLayoutWithHeader>
  );
};

export default RoomInvitesContainer;
