import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { History } from 'history';
import { StepWizardProvider } from 'stepWizard';
import { MainContextProvider } from '@/hooks/mainContext';
import { getUserData } from '@/auth/helpers/token';
import { RouteComponentProps } from '@/core/routerLib';
import { useGenericWizardActions } from '@/utils/genericWizardActions';
import b2bCancellationWizardActions from './hooks/b2bCancellationWizardActions';
import { getInitialState, State } from './reducers/b2bCancellationWizardState';
import Routes from './Routes';

interface B2BCancellationRouteParams {
  roomID: number;
  source: string;
  contextID?: string;
  history: History;
}

const B2BCancellationWizard: FunctionComponent<RouteComponentProps<B2BCancellationRouteParams>> = ({
  match: {
    params: { roomID, source, contextID = '' },
  },
}) => {
  const { id } = getUserData();
  const getFirstState = useCallback(getInitialState, [roomID, source, contextID]);
  const [initialState, setInitialState] = useState<State | undefined>();
  const genericCancellationWizardActions = useGenericWizardActions(b2bCancellationWizardActions);

  useEffect(() => {
    setInitialState(getFirstState(id, roomID, source, contextID));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {initialState ? (
        <MainContextProvider>
          <StepWizardProvider
            initialState={initialState}
            genericActions={genericCancellationWizardActions}
          >
            <Routes />
          </StepWizardProvider>
        </MainContextProvider>
      ) : null}
    </>
  );
};

export default B2BCancellationWizard;
