import { FunctionComponent } from 'react';
import Svg, { G, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const SymptomIcon: FunctionComponent<{
  color?: string;
  secondaryColor?: string;
  height?: number;
  width?: string;
  style?: object;
}> = ({ width = 28, height = 30, color, secondaryColor, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg width={width} height={height} viewBox="0 0 28 30" {...otherProps}>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M22.97 13.5h-.058a1.5 1.5 0 01-1.5-1.5V6.105a3.056 3.056 0 00-3.06-3.052H6.119a3.056 3.056 0 00-3.06 3.052v15.263a3.056 3.056 0 003.06 3.053H9.97a1.5 1.5 0 011.5 1.5v.053a1.5 1.5 0 01-1.5 1.5H6.118C2.739 27.474 0 24.74 0 21.368V6.105C0 2.733 2.739 0 6.118 0h12.235c3.379 0 6.118 2.733 6.118 6.105V12a1.5 1.5 0 01-1.5 1.5zM7.648 7.632h9.177c.844 0 1.529.683 1.529 1.526s-.685 1.526-1.53 1.526H7.648c-.845 0-1.53-.683-1.53-1.526s.685-1.526 1.53-1.526zm0 4.579h3.059c.845 0 1.53.683 1.53 1.526s-.685 1.526-1.53 1.526H7.647c-.845 0-1.53-.683-1.53-1.526s.685-1.526 1.53-1.526z"
          fill={secondaryColor || colors.lightCobaltGrey}
          fillRule="nonzero"
        />
        <Path
          d="M7.5 0a7.5 7.5 0 110 15 7.5 7.5 0 010-15zm2.114 4.286L6.852 8.561 5.106 7.23a1 1 0 10-1.212 1.59l3.465 2.644 3.935-6.091a1 1 0 10-1.68-1.086z"
          transform="translate(13 15)"
          fill={color || colors.emerald}
        />
      </G>
    </Svg>
  );
};

export default SymptomIcon;
