import Svg, { Defs, Rect, G, Use, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const VerifyEmail = ({ width = 74, height = 74, ...props }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'email';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 74 74"
      {...props}
    >
      <Defs>
        <Rect id="b" x={0} y={0} width={58} height={58} rx={29} />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(-666 -333) translate(654 318) translate(20 20)">
          <Use fill={colors.trueBlack} href="#b" />
          <Use fill={colors.white} href="#b" />
        </G>
        <Path
          d="M15.204 21H5c-2.761 0-5-2.398-5-5.357V5.357C0 2.398 2.239 0 5 0h19c2.761 0 5 2.398 5 5.357v3.03"
          stroke={colors.cobaltGrey}
          strokeWidth={2.7}
          strokeLinecap="round"
          transform="translate(-666 -333) translate(654 318) translate(20 20) translate(13 19)"
        />
        <Path
          d="M1 3l11.85 7.896a3 3 0 003.308.013L28.23 3h0"
          stroke={colors.cobaltGrey}
          strokeWidth={2.7}
          strokeLinecap="square"
          transform="translate(-666 -333) translate(654 318) translate(20 20) translate(13 19)"
        />
        <Path
          d="M26.204 28.388a.898.898 0 01-.436-.112c-4.784-2.678-6.564-5.412-6.564-10.089v-3.4c0-.34.208-.648.53-.78l6.125-2.55a.902.902 0 01.692 0l6.125 2.55c.32.132.528.44.528.78v3.4c0 4.684-1.78 7.418-6.564 10.089a.898.898 0 01-.436.112z"
          fill={colors.purple}
          fillRule="nonzero"
          transform="translate(-666 -333) translate(654 318) translate(20 20) translate(13 19)"
        />
        <Path
          d="M25.83 22.888h-.037a.882.882 0 01-.692-.381l-1.75-2.573a.846.846 0 01.243-1.188.886.886 0 011.213.238l1.087 1.597 2.751-3.372a.89.89 0 011.23-.134.847.847 0 01.137 1.206l-3.5 4.288a.89.89 0 01-.683.319z"
          fill={colors.white}
          fillRule="nonzero"
          transform="translate(-666 -333) translate(654 318) translate(20 20) translate(13 19)"
        />
      </G>
    </Svg>
  );
};
export default VerifyEmail;
