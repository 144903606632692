/* eslint-disable camelcase */
import { PaymentDetails, Provider, CardType, AppSource } from 'ts-frontend/types';
import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';
import paymentApiHelperInner from '@/utils/payment/paymentApiHelperInner';

export interface BillingInfo {
  is_link: boolean;
  last_four: string;
  card_type: CardType;
  year: number;
  month: number;
  provider: Provider;
}

export interface SetupIntent {
  clientSecret: string | null;
  provider: Provider;
}

interface GetPaymentDetailsResponseIntermediate {
  paymentMethod?: BillingInfo;
  email: string;
  nickname: string;
}

interface GetPaymentDetailsResponse {
  billingInfo?: PaymentDetails;
  email: string;
  nickname: string;
}

function transformPaymentDetails(
  data: GetPaymentDetailsResponseIntermediate
): GetPaymentDetailsResponse {
  return {
    ...data,
    billingInfo: data.paymentMethod
      ? {
          cardType: data.paymentMethod.card_type,
          fourLastDigits: data.paymentMethod.last_four,
          expirationMonth: data.paymentMethod.month,
          expirationYear: data.paymentMethod.year,
          provider: data.paymentMethod.provider,
          isLink: data.paymentMethod.is_link,
        }
      : undefined,
  };
}

function getPaymentDetails(userID: number): Promise<GetPaymentDetailsResponse> {
  if (paymentApiHelperInner.getSource() === AppSource.therapist) {
    throw new Error("Don't use this on therapist-web");
  }

  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v2/payments/users/${userID}/payment-methods`)
    .then((res) => transformPaymentDetails(res.data.data));
}

function changePaymentMethod(
  userID: number,
  cardToken: string,
  provider: Provider
): Promise<BillingInfo> {
  if (paymentApiHelperInner.getSource() === AppSource.therapist) {
    throw new Error("Don't use this on therapist-web");
  }

  return apiWrapper
    .put(`${apiHelper().apiEndpoint}/v2/payments/users/${userID}/payment-methods`, {
      cardToken,
      provider,
    })
    .then((res) => res.data.data);
}

async function getSetupIntent({
  userID,
  planID,
  currency,
}: {
  userID?: any;
  planID?: number;
  currency?: string;
}) {
  const isNaturalNumber = !!(userID && !isNaN(Number(userID)));
  const res = await paymentApiHelperInner.getSetupIntentInner({
    userID: isNaturalNumber ? userID : undefined,
    planID,
    currency,
  });
  return res;
}

export interface ValidateCouponResponse {
  data: {
    couponCode: string;
    discountAmount: number;
    validCoupon: boolean;
    isRecurring: boolean;
  };
}

async function validateCoupon(couponCode: string, planID?: number) {
  if (paymentApiHelperInner.getSource() === AppSource.therapist) {
    throw new Error("Don't use this on therapist-web");
  }

  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v2/payments/coupons/${couponCode}/validate`, {
      planID,
    })
    .then((res) => res.data.data);
}

async function validateCouponQM(
  couponCode: string,
  planID?: number
): Promise<ValidateCouponResponse['data']> {
  if (paymentApiHelperInner.getSource() === AppSource.therapist) {
    throw new Error("Don't use this on therapist-web");
  }
  return paymentApiHelperInner.validateCouponQM(couponCode, planID);
}

async function applyCouponByType(token: string): Promise<any> {
  if (paymentApiHelperInner.getSource() === AppSource.therapist) {
    throw new Error("Don't use this on therapist-web");
  }

  const res = await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/payments/coupons/apply-by-type`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}

const paymentApiHelper = {
  getPaymentDetails,
  changePaymentMethod,
  getSetupIntent,
  validateCoupon,
  validateCouponQM,
  applyCouponByType,
};

export default paymentApiHelper;
