import Svg, { Path, Defs, Rect, G, Use, Circle } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const RoundedPausedPlan = ({ width = 74, height = 74, ...props }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'paused plan';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <Defs>
        <Rect id="b" width="58" height="58" x="0" y="0" rx="29" />
        <filter
          id="a"
          width="146.6%"
          height="146.6%"
          x="-23.3%"
          y="-18.1%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology in="SourceAlpha" radius="1.5" result="shadowSpreadOuter1" />
          <feOffset dy="3" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5.5" />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.062745098 0 0 0 0 0.427620878 0 0 0 0 0.584313725 0 0 0 0.3 0"
          />
        </filter>
      </Defs>
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <Path
          fill={colors.permaLinkWaterGrey}
          d="M10 0h451c5.523 0 10 4.477 10 10v139c0 5.523-4.477 10-10 10H10c-5.523 0-10-4.477-10-10V10C0 4.477 4.477 0 10 0z"
          transform="translate(-87 -249) translate(74 234)"
        />
        <G transform="translate(-87 -249) translate(74 234) translate(21 20)">
          <use fill={colors.trueBlack} filter="url(#a)" xlinkHref="#b" />
          <Use fill={colors.white} xlinkHref="#b" href="#b" />
        </G>
        <G transform="translate(-87 -249) translate(74 234) translate(21 20) translate(14.204 15.388)">
          <G stroke={colors.cobaltGrey} strokeLinecap="round" strokeWidth="2.7">
            <Path
              d="M12.19.612h8.606c2.761 0 5 2.399 5 5.357v14.286c0 2.959-2.239 5.357-5 5.357h-15c-2.762 0-5-2.398-5-5.357v-8.643"
              transform="rotate(180 13.296 13.112)"
            />
            <Path
              strokeDasharray="28.386"
              d="M13.262 18.741A5.322 5.322 0 107.94 13.42"
              transform="rotate(-118 13.262 13.419)"
            />
          </G>
          <Path
            fill={colors.lightCobaltGrey}
            d="M19.796 7.149v5.01c0 .25-.203.453-.453.453h-5.067a.453.453 0 01-.318-.774l5.067-5.011a.453.453 0 01.77.322z"
          />
          <Circle cx="25.296" cy="25.112" r="7" fill={colors.purple} />
          <Path
            fill={colors.white}
            fillRule="nonzero"
            d="M23.546 21.862a.75.75 0 01.75.75v5a.75.75 0 01-.75.75h-.5a.75.75 0 01-.75-.75v-5a.75.75 0 01.75-.75zm4 0a.75.75 0 01.75.75v5a.75.75 0 01-.75.75h-.5a.75.75 0 01-.75-.75v-5a.75.75 0 01.75-.75z"
          />
        </G>
      </G>
    </Svg>
  );
};

export default RoundedPausedPlan;
