import Svg, { G, Ellipse, Path, Rect } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const SparklingCalendar = ({ width = 84, height = 75, ...otherProps }) => {
  const titleText = 'Sparkling Calendar';
  const { colors } = useEmotionTheme();

  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 84 74"
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <G transform="translate(2 -1)" fill="none" fillRule="evenodd">
        <Ellipse
          stroke="#EBEBED"
          strokeWidth={1.119}
          fill="#FFF"
          cx={40.027}
          cy={37.959}
          rx={36.364}
          ry={36.054}
        />
        <Path
          d="M31.02 38.148v-2.07c0-.406.332-.739.739-.739h2.07c.406 0 .738.333.738.74v2.069c0 .406-.332.739-.739.739h-2.07a.741.741 0 01-.738-.74zm5.543.739h2.07c.406 0 .739-.333.739-.74v-2.069a.741.741 0 00-.74-.739h-2.069a.741.741 0 00-.74.74v2.069c0 .406.334.739.74.739zm4.804 0h2.07c.406 0 .74-.333.74-.74v-2.069a.741.741 0 00-.74-.739h-2.07a.741.741 0 00-.739.74v2.069c0 .406.333.739.74.739zm-4.804 4.693h2.07c.406 0 .739-.332.739-.739v-2.07a.741.741 0 00-.74-.738h-2.069a.741.741 0 00-.74.739v2.07c0 .406.334.738.74.738zm4.804 0h2.07c.406 0 .74-.332.74-.739v-2.07a.741.741 0 00-.74-.738h-2.07a.741.741 0 00-.739.739v2.07c0 .406.333.738.74.738zm4.805 0h2.07c.406 0 .738-.332.738-.739v-2.07a.741.741 0 00-.739-.738h-2.07a.741.741 0 00-.738.739v2.07c0 .406.332.738.739.738zm0 4.657h2.07c.406 0 .738-.333.738-.74v-2.069a.741.741 0 00-.739-.739h-2.07a.741.741 0 00-.738.74v2.069c0 .443.332.739.739.739zM31.759 43.58h2.07c.406 0 .738-.332.738-.739v-2.07a.741.741 0 00-.739-.738h-2.07a.741.741 0 00-.738.739v2.07c0 .406.332.738.739.738zm9.608 4.657h2.07c.406 0 .74-.333.74-.74v-2.069a.741.741 0 00-.74-.739h-2.07a.741.741 0 00-.739.74v2.069c0 .443.333.739.74.739zm-4.804 0h2.07c.406 0 .739-.333.739-.74v-2.069a.741.741 0 00-.74-.739h-2.069a.741.741 0 00-.74.74v2.069c0 .443.334.739.74.739zm20.104-21.25v24.465a2.516 2.516 0 01-2.513 2.513H25.846a2.516 2.516 0 01-2.513-2.513V26.987a2.516 2.516 0 012.513-2.513h3.51v1.515a2.634 2.634 0 002.624 2.624h1.627a2.634 2.634 0 002.623-2.624v-1.515h7.613v1.515a2.634 2.634 0 002.624 2.624h1.626a2.634 2.634 0 002.624-2.624v-1.515h3.511c1.33 0 2.44 1.109 2.44 2.513zm-2.882 5.063h-27.57v19.033h27.57V32.05zm-21.842-4.915h1.627c.665 0 1.182-.518 1.182-1.183v-3.51c0-.666-.517-1.183-1.182-1.183h-1.627c-.665 0-1.182.517-1.182 1.182v3.548c.037.628.554 1.146 1.182 1.146zm14.487 0h1.627c.665 0 1.182-.518 1.182-1.183v-3.51c0-.666-.517-1.183-1.182-1.183H46.43c-.665 0-1.182.517-1.182 1.182v3.548c0 .628.517 1.146 1.182 1.146z"
          fill={colors.periwinkleGrey}
          fillRule="nonzero"
        />
        <G fill="#C0E3E0">
          <Rect width={2.797} height={8.32} rx={1.399} transform="rotate(65 3.594 11.579)" />
          <Rect width={2.797} height={8.32} rx={1.399} transform="rotate(155 5.14 6.193)" />
        </G>
        <Ellipse
          stroke="#ADE953"
          strokeWidth={2.797}
          cx={2.517}
          cy={59.869}
          rx={2.517}
          ry={2.496}
        />
        <Ellipse
          stroke={colors.a11yLavendarBlue}
          strokeWidth={3.357}
          cx={75.271}
          cy={56.264}
          rx={4.476}
          ry={4.437}
        />
      </G>
    </Svg>
  );
};

export default SparklingCalendar;
