import { useQuery } from 'react-query';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import clientChatKeys from '../utils/queryKeys';

interface CanRematchResponse {
  available: boolean;
}

const getCanRematch = (roomID: number) => (): Promise<CanRematchResponse> =>
  apiWrapper
    .get(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/can-rematch`)
    .then((res) => res.data.data);

const useQueryCanRematch = ({
  roomID,
  isClosedInactive,
  enableCanRematch,
}: {
  roomID: number;
  isClosedInactive: boolean;
  enableCanRematch?: boolean;
}) =>
  useQuery<CanRematchResponse, Error>({
    queryKey: clientChatKeys.canRematch(roomID),
    queryFn: getCanRematch(roomID),
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!(isClosedInactive || enableCanRematch),
  });

export default useQueryCanRematch;
