import { useFlags } from 'launchDarkly/FlagsProvider';
import { Screen, View, LanguageSelector } from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import ParentalConsentContainer from '../containers/ParentalConsentContainer';

const LeftHeaderSpacer = styled(View)({ width: 40, height: 40 });

const ParentalConsentScreen = () => {
  const { localizationParentalConsent } = useFlags();
  return (
    <Screen
      showBackButton={false}
      showCloseButton={false}
      renderHeaderRight={
        localizationParentalConsent
          ? () => <LanguageSelector dataQa="parentalConsentSwitchLanguage" />
          : undefined
      }
      renderHeaderLeft={localizationParentalConsent ? () => <LeftHeaderSpacer /> : undefined}
    >
      <ParentalConsentContainer />
    </Screen>
  );
};

export default ParentalConsentScreen;
