import { History, Location } from 'history';
import { match } from 'react-router';

const pushOfferRoute = (
  routeMatch: match<{ offerID: string; planID: string; roomID: string } | any>,
  history: History,
  location: Location,
  path = '',
  state?: any
) => {
  const {
    params: { offerID, planID, roomID },
  } = routeMatch;

  const urlParams = new URLSearchParams(location.search);
  const couponCode = urlParams.get('coupon') || undefined;
  const showPlans = urlParams.get('show_plans') || undefined;
  const isNoMatches = urlParams.get('isNoMatches') || undefined;

  let basePath = '/room-offer/:roomID/offer/:offerID'
    .replace(':roomID', roomID)
    .replace(':offerID', offerID);
  if (planID) {
    basePath += '/plan/:planID'.replace(':planID', planID);
  }
  const newQueryString = new URLSearchParams();

  if (couponCode) newQueryString.set('coupon', couponCode);
  if (showPlans) newQueryString.set('show_plans', showPlans);
  if (isNoMatches) newQueryString.set('isNoMatches', isNoMatches);

  history.push(`${basePath}${path}?${newQueryString.toString()}`, state);
};

export default pushOfferRoute;
