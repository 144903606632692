import { useEffect } from 'react';
import moment from 'moment';

import { Button, ExtraHuge, Large, View, useWindowWidthState } from '@talkspace/react-toolkit';
import { useParams } from '@/core/routerLib';
import styled from '@/core/styled';
import { trackEvent } from '@/utils/analytics/eventTracker';

import PostAsyncPromptBaseScreen from './PostAsyncPromptBaseScreen';
import MessagingSessionPerson from '../../assets/MessagingSessionPerson';

const Styled = {
  Title: styled(ExtraHuge)({ textAlign: 'center', marginTop: 24 }),
  FreeSessionsContainer: styled(View)(({ theme: { colors } }) => {
    return {
      marginTop: 24,
      backgroundColor: colors.aquaSqueeze,
      padding: '8px 16px',
      borderRadius: 8,
    };
  }),
  FreeSessionsText: styled(Large)(({ theme: { colors } }) => {
    return {
      color: colors.accessibilityGreenDark,
      fontWeight: 700,
    };
  }),
  BodyText: styled(Large)({
    marginTop: 24,
    textAlign: 'center',
  }),
  Button: styled(Button)<{ isMobile: boolean }>(({ isMobile, theme: { colors } }) => {
    return {
      marginTop: isMobile ? 128 : 100,
      backgroundColor: colors.permaTalkspaceDarkGreen,
      width: '100%',
    };
  }),
};

interface Props {
  providerName: string;
  sessionEndDate: string;
  sessionsRemaining: number;
  onContinue: () => void;
}

const PostAsyncPromptCTAScreen = ({
  providerName,
  sessionEndDate,
  sessionsRemaining,
  onContinue,
}: Props) => {
  const sessionEndDateFormatted = moment(sessionEndDate).format('MMMM D, YYYY');
  const { isMobile } = useWindowWidthState();
  const { userPromptID, roomID } = useParams<{ userPromptID: string; roomID: string }>();

  useEffect(() => {
    trackEvent('Post Async Prompt', { sessionsRemaining });
  }, [roomID, sessionsRemaining, userPromptID]);

  return (
    <PostAsyncPromptBaseScreen>
      <MessagingSessionPerson />
      <Styled.Title>You finished your first messaging session with {providerName}!</Styled.Title>
      <Styled.FreeSessionsContainer>
        <Styled.FreeSessionsText>
          You have {sessionsRemaining} free {sessionsRemaining === 1 ? 'session' : 'sessions'} left
        </Styled.FreeSessionsText>
      </Styled.FreeSessionsContainer>
      <Styled.BodyText>
        Your session ended on {sessionEndDateFormatted}. Start your next free messaging session to
        continue making progress.
      </Styled.BodyText>
      <Styled.Button
        text="Continue messaging"
        onPress={onContinue}
        dataQa="continueMessaging"
        isMobile={isMobile}
      />
    </PostAsyncPromptBaseScreen>
  );
};

export default PostAsyncPromptCTAScreen;
