import React from 'react';

import { RouteComponentProps, withRouter } from '@/core/routerLib';

import OnboardingPage from './OnboardingPage';
import InformedConsent from '../assets/InformedConsent';

const OnboardingPageInformedConsent = ({
  match: {
    params: { roomID },
  },
  history,
  totalSteps,
  currentStep,
}: RouteComponentProps<{ roomID: number }> & { totalSteps: number; currentStep: number }) => (
  <OnboardingPage
    totalSteps={totalSteps}
    currentStep={currentStep}
    illustration={<InformedConsent />}
    title="Give your consent to treatment"
    description="Mental healthcare requires consent before working with a provider"
    ctaText="Continue"
    onPress={() => history.push(`/room/${roomID}/onboarding/informed-consent`)}
  />
);
export default withRouter(OnboardingPageInformedConsent);
