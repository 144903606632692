import { FunctionComponent } from 'react';
import { View, TextDS } from '@talkspace/react-toolkit';
import styled from '../../../core/styled';

const Wrapper = styled(View)({
  width: 'calc(100% - 50px)',
});

const Subtitle = styled(View)<{ isMobile: boolean; height: string | number }>(
  ({ isMobile, height }) => {
    return {
      width: 'auto',
      maxWidth: isMobile ? 278 : 409,
      height,
    };
  }
);

interface Props {
  title: string;
  subTitle: string;
  isMobile: boolean;
  isOpen?: boolean;
  height?: number | string;
}

const NotificationTitle: FunctionComponent<Props> = ({
  title,
  subTitle,
  isMobile,
  isOpen = false,
  height = 'auto',
}) => (
  <Wrapper>
    <TextDS variant="headingMd">{title}</TextDS>
    <Subtitle isMobile={isMobile} height={height}>
      {isOpen ? (
        <TextDS variant="bodySm" colorRole="textSubtle">
          {subTitle}
        </TextDS>
      ) : (
        <TextDS variant="bodySm" colorRole="textSubtle">
          {subTitle}
        </TextDS>
      )}
    </Subtitle>
  </Wrapper>
);

export default NotificationTitle;
