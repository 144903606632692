import { useCallback } from 'react';
import { History } from 'history';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { useHistory } from '@/core/routerLib';

interface UseNavHelpersOptions {
  roomID?: number | string;
  history?: History;
}
const useNavHelpers = ({ roomID: roomIDParam, history: paramHistory }: UseNavHelpersOptions) => {
  const useNewNav = useNewMemberNav();
  const hookHistory = useHistory();
  const history = paramHistory || hookHistory;
  const roomID = Number(roomIDParam); // can be NaN or 0

  const navigateToDefault = useCallback(() => {
    if (useNewNav) {
      history.push('/home');
    } else if (roomID) {
      history.replace(`/room/:${roomID}`);
    } else {
      history.push('/rooms');
    }
  }, [useNewNav, history, roomID]);
  return {
    navigateToDefault,
  };
};

export default useNavHelpers;
