import * as React from 'react';
import Svg, { G, Circle, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

interface CircleClockProps {
  primaryColor?: string;
  secondaryColor?: string;
  height?: number;
  width?: number;
  style?: object;
}

const CircleClock: React.FunctionComponent<CircleClockProps> = ({
  primaryColor,
  secondaryColor,
  height = 21,
  width = 21,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'clock';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 21 21"
      {...otherProps}
    >
      <G
        transform="translate(3 3)"
        stroke={secondaryColor || colors.white}
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Circle strokeWidth={3} fill={primaryColor || colors.amber} cx={7.5} cy={7.5} r={9} />
        <Path
          strokeWidth={2}
          strokeLinecap="round"
          d="M9.8984197 10.3184851L7.47687682 8.02458914 7.5 4.10305519"
        />
      </G>
    </Svg>
  );
};

export default CircleClock;
