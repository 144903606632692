import { WizardScheme } from 'stepWizard';

const noMatchesCancellationWizardScheme: WizardScheme = {
  wizardVersion: 1,
  wizardType: 'noMatchesCancellation',
  wizardModalTitle: 'cancel plan',
  steps: [
    {
      title: 'Please tell us why you are cancelling your plan today.',
      inputType: 'select',
      inputState: 'cancellationReason',
      inputOptions: 'noMatchesReasonForCancellation',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      name: 'Reason for cancelling',
      backOption: 'back',
      inputDataQa: 'noMatchCancellationReasonInput',
    },
    {
      title: `We're sorry to hear that you are canceling your plan.`,
      subtitle: `If there is anything we can do to improve your experience, please reach out to our customer support team.`,
      inputState: '',
      nextButton: {
        displayText: 'Cancel my plan',
        actionDispatch: '/cancel-non-subscription/cancel',
        whenVisible: 'always',
      },
      name: 'Sure you want to cancel',
      backOption: 'back',
    },
  ],
};

export default noMatchesCancellationWizardScheme;
