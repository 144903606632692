import { Button, styled } from '@talkspace/react-toolkit';

const TeenspaceContinueButton = styled(Button)(
  ({
    theme: {
      spacing,
      window: { isMobile, isTablet },
    },
  }) => {
    let buttonMaxWidth = '384px';
    if (isMobile) {
      buttonMaxWidth = '375px';
    } else if (isTablet) {
      buttonMaxWidth = '522px';
    }
    return {
      marginBottom: spacing('space400'),
      padding: '18px 24px',
      maxWidth: buttonMaxWidth,
      minWidth: '100px',
      borderRadius: '12px',
    };
  }
);

export default TeenspaceContinueButton;
