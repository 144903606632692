import { FunctionComponent } from 'react';
import { View, ClinicalProgressGaugeChart, Big, Large } from '@talkspace/react-toolkit';
import { SurveyResult } from 'ts-frontend/types';
import styled from '../../../core/styled';
import { EmotionStyle } from '../../../core/styled/styled';

interface SurveyResultViewProps {
  surveyResult: SurveyResult;
  prevSurveyResult?: SurveyResult;
  style?: EmotionStyle;
}

const Wrapper = styled(View)({});

const SurveyResultView: FunctionComponent<SurveyResultViewProps> = ({
  surveyResult: { score, scoreRanges, maximum, surveyLabel, progressSummary, isScoreReversed },
  prevSurveyResult,
  style,
}) => (
  <Wrapper style={{ width: 340, ...style }}>
    <ClinicalProgressGaugeChart
      ariaHidden
      score={score}
      prevScore={prevSurveyResult && prevSurveyResult.score}
      scoreRanges={scoreRanges}
      max={maximum}
      isScoreReversed={isScoreReversed}
      surveyLabel={surveyLabel}
    />
    <Big as="h1" style={{ marginBottom: 12, marginTop: 24 }}>
      Your {surveyLabel} score is {score}
      <Big inline aria-label="out of">
        /
      </Big>
      {maximum}
    </Big>
    <Large as="h2" variant="largeDarkGrey">
      {progressSummary}
    </Large>
  </Wrapper>
);

export default SurveyResultView;
