import { FunctionComponent, useMemo } from 'react';
import * as React from 'react';
import {
  useWindowWidth,
  useUniqueID,
  View,
  useEmotionTheme,
  GraphicCardWithText,
  ExtraHuge,
  Standard,
} from '@talkspace/react-toolkit';
import { getBrowserInfo } from 'ts-frontend/helpers';
import emergencyContactBackground from '@/assets/EmergencyContactBackgroung.png';
import mentalHealthBackground from '@/assets/MentalHealthBackground.png';
import medicalHealthBackground from '@/assets/MedicalHealthBackground.png';
import styled from '@/core/styled';
import { IntakeStatusStates } from '@/treatmentIntake/treatmentIntakeLauncher/types';
import ButtonForLauncher from './ButtonForLauncher';
import StepsNavigator from './StepsNavigator';

interface Props {
  roomID: string;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  historyPush: (url: string) => void;
  onClosePress: () => void;
  intakeStatusState: IntakeStatusStates;
  intakeStatus: keyof IntakeStatusStates;
  onCheckAnimationEnd?: () => void;
  freezeDueAnimation: boolean;
  initStep?: number;
}

const GraphicCardWithTextWrapper = styled(View)<{ isMobile: boolean }>(({ isMobile }) => {
  return {
    transition: 'all .5s',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: isMobile ? 440 : 343,
    position: 'relative',
  };
});

const DoneScreenWrapper = styled(View)<{ isLowOpacity: boolean }>(({ isLowOpacity }) => {
  return {
    width: '100%',
    transition: 'all .5s',
    opacity: isLowOpacity ? 0 : 1,
    padding: 20,
  };
});

const doesBrowserSupportLowOpacity = () => {
  const browserInfo = getBrowserInfo();
  return browserInfo.name === 'Safari' && browserInfo.version === '13';
};

const getCompletedByStep = (
  step: number,
  intakeStatusState: IntakeStatusStates,
  intakeStatus: keyof IntakeStatusStates
) => step < Object.keys(intakeStatusState).indexOf(intakeStatus);

const TreatmentIntakeLauncherModalSteps: FunctionComponent<Props> = ({
  roomID,
  step,
  setStep,
  historyPush,
  intakeStatusState,
  intakeStatus,
  onClosePress,
  onCheckAnimationEnd,
  freezeDueAnimation,
  initStep,
}) => {
  const { isMobile } = useWindowWidth();
  const { colors } = useEmotionTheme();
  const flowDescriptionId = useUniqueID('flowDescriptionId');
  const isSafari13 = useMemo(() => doesBrowserSupportLowOpacity(), []);

  const stepsCompleted = useMemo(
    () => [
      getCompletedByStep(0, intakeStatusState, intakeStatus),
      getCompletedByStep(1, intakeStatusState, intakeStatus),
      getCompletedByStep(2, intakeStatusState, intakeStatus),
    ],
    [intakeStatusState, intakeStatus]
  );

  return (
    <View style={{ alignItems: 'center' }}>
      <StepsNavigator
        step={step}
        setStep={setStep}
        intakeStatusState={intakeStatusState}
        intakeStatus={intakeStatus}
        freezeDueAnimation={freezeDueAnimation}
      />
      <GraphicCardWithTextWrapper style={{ left: `-${step * 100}%` }} isMobile={isMobile}>
        <GraphicCardWithText
          isLowOpacity={step !== 0 && !isSafari13}
          cardHeight={218}
          cardWidth={320}
          firstHeadlineRowText="Emergency"
          secondHeadlineRowText="contact"
          subHeadlineText="6 questions - 1min"
          description="While we don’t expect to need it we want to ensure everyone is safe and supported during an emergency."
          imageURL={emergencyContactBackground}
          graphicCardStyles={{ backgroundColor: colors.bermuda }}
          isCompleted={stepsCompleted[0]}
          flowDescriptionId={flowDescriptionId}
          isMobile={isMobile}
          imageWidth={180}
          imageHeight={220}
          imageXPos={140}
          imageYPos={0}
          imageMobileXPos={40}
          imageMobileYPos={0}
          imageMobileWidth={240}
          imageMobileHeight={300}
          onCheckAnimationEnd={onCheckAnimationEnd}
          isAnimatingCheckmark={initStep === 0}
        />
        <GraphicCardWithText
          isLowOpacity={step !== 1 && !isSafari13}
          cardHeight={218}
          cardWidth={320}
          firstHeadlineRowText="Mental"
          secondHeadlineRowText="health"
          subHeadlineText="7 questions - 2min"
          description="To ensure the best care possible your therapist would like to learn more about your past mental health."
          imageURL={mentalHealthBackground}
          graphicCardStyles={{ backgroundColor: colors.indigo }}
          isCompleted={stepsCompleted[1]}
          flowDescriptionId={flowDescriptionId}
          isMobile={isMobile}
          imageWidth={160}
          imageHeight={150}
          imageXPos={150}
          imageYPos={60}
          imageMobileXPos={80}
          imageMobileYPos={140}
          isDisabled={!stepsCompleted[0]}
          onCheckAnimationEnd={onCheckAnimationEnd}
          isAnimatingCheckmark={initStep === 1}
        />
        <GraphicCardWithText
          isLowOpacity={step !== 2 && !isSafari13}
          cardHeight={218}
          cardWidth={320}
          firstHeadlineRowText="Medical"
          secondHeadlineRowText="history"
          subHeadlineText="7 questions - 2min"
          description="If you need psychiatric services at any time we need to learn more about your medical history."
          imageURL={medicalHealthBackground}
          graphicCardStyles={{ backgroundColor: colors.pacificBlue }}
          isCompleted={stepsCompleted[2]}
          flowDescriptionId={flowDescriptionId}
          isMobile={isMobile}
          imageWidth={265}
          imageHeight={218}
          imageXPos={60}
          imageYPos={0}
          imageMobileXPos={-33}
          imageMobileYPos={0}
          imageMobileWidth={365}
          imageMobileHeight={300}
          isDisabled={!stepsCompleted[1]}
          onCheckAnimationEnd={onCheckAnimationEnd}
          isAnimatingCheckmark={initStep === 2}
        />
        <DoneScreenWrapper isLowOpacity={step !== 3 && !isSafari13}>
          <ExtraHuge style={{ marginBottom: 60 }}>You're all done!</ExtraHuge>

          <Standard variant="standardDarkGrey">
            Thank you. You've completed all the intake questions needed to begin treatment.
          </Standard>
        </DoneScreenWrapper>
      </GraphicCardWithTextWrapper>
      <ButtonForLauncher
        roomID={roomID}
        step={step}
        historyPush={historyPush}
        onClosePress={onClosePress}
        isMobile={isMobile}
        intakeStatusState={intakeStatusState}
        intakeStatus={intakeStatus}
      />
    </View>
  );
};

export default TreatmentIntakeLauncherModalSteps;
