import { useEffect } from 'react';
import { useHistory } from 'core/routerLib';

import { useClientAuthActions, useClientAuthState } from '../../hooks/clientAuthContext';
import LoadingScreen from '../../screens/LoadingScreen';
import { HAS_LOGGED_IN_BEFORE } from '../../login/utils/twoFactorAuthenticationApiHelper';

const TwoFactorAuthenticationReminder = () => {
  const { phoneNumberLast2Digits, twoFAStatus } = useClientAuthState();
  const { get2FAStatusAction } = useClientAuthActions();
  const history = useHistory();

  useEffect(() => {
    const hasLoggedInBefore = localStorage.getItem(HAS_LOGGED_IN_BEFORE);
    if (!twoFAStatus) {
      get2FAStatusAction();
    } else if (twoFAStatus === 'suggested' && !hasLoggedInBefore) {
      history.push('/login-success');
    } else if (phoneNumberLast2Digits) {
      history.push('/2fa/reminder/verify-number');
    } else if (hasLoggedInBefore) {
      history.push('/2fa/reminder/change-number');
    } else {
      history.push('/login-success');
    }
  }, [phoneNumberLast2Digits, twoFAStatus, get2FAStatusAction, history]);

  return <LoadingScreen />;
};
export default TwoFactorAuthenticationReminder;
