import Svg, { G, Rect, Path } from 'svgs';

const MiniSuitcase = ({ width = 17, height = 18, color = '#545A6F', ...props }) => (
  <Svg width={width} height={height} viewBox="0 0 17 18" {...props}>
    <G
      transform="translate(-728 -1093) translate(728 1086) translate(0 7)"
      fill={color}
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    >
      <Rect x={0} y={4.11909651} width={17} height={13} rx={2.20000005} />
      <Path
        d="M8 2a2 2 0 00-2 2v4a2 2 0 002 2h1a2 2 0 002-2V4a2 2 0 00-2-2H8zm0-2h1a4 4 0 014 4v4a4 4 0 01-4 4H8a4 4 0 01-4-4V4a4 4 0 014-4z"
        fillRule="nonzero"
      />
    </G>
  </Svg>
);

export default MiniSuitcase;
