import { formatCurrency } from 'ts-frontend/helpers/billingUtils';
import { RoomStatus } from 'ts-frontend/entities/Room';
import { trackWizardEvent } from '@/utils/analytics/events';
import {
  State,
  REFUND_REASONS_SUBSCRIPTION,
  REFUND_REASONS_LVS,
} from '../reducers/refundWizardState';
import refundWizardApiHelper from '../utils/refundWizardApiHelper';
import { Charge } from '../utils/refundWizardTypes';
import AdminConfigAPI from '../../utils/adminConfig';
import { keyValueToOptionType } from '../utils/index';
import { OtherWizardGenericActions } from '../../utils/genericWizardActions';

const DEFAULT_CX_RESPONSE_TIME_IN_DAYS = 10;

const initState = async (api, state) => {
  const result = await refundWizardApiHelper.getAvailableRefunds().catch(() => {
    return { data: [] };
  });

  const supportResponseInDays = await AdminConfigAPI.getAdminOptionByName(
    'cx_response_time_in_days'
  )
    .then((adminConfig) => adminConfig.data && Number(adminConfig.data.data))
    .catch(() => DEFAULT_CX_RESPONSE_TIME_IN_DAYS);

  const charges = result && (result.data as Charge[]);
  const refundableChargesDisplay = keyValueToOptionType(charges);
  if (charges.length) {
    refundableChargesDisplay.push({
      label: 'None of the above',
      value: charges.length,
    });
  }
  const isDisplayRefundsFlow = Boolean(charges.length);
  const isDontDisplayRefundsFlow = !isDisplayRefundsFlow;

  trackWizardEvent('Enter Refund Wizard', state.eventCategory, {
    'User ID': state.clientUserID,
    'Eligible Invoice': isDisplayRefundsFlow,
    label: 'Eligible Invoice',
    Application: state.eventCategory,
    eventProperty: isDisplayRefundsFlow,
    eventPropertyValue: 0.0,
  });

  const setSelectedRefundableCharge = (selectedDropdownChargeIndex, context) => {
    const partialState: Partial<State> = {};
    const index = Number(selectedDropdownChargeIndex);
    const charge = index < charges.length ? charges[index] : undefined;
    // selected refund
    partialState.isRefundSelectedFlow = Boolean(charge);
    partialState.isRefundNotSelectedFlow = !charge;
    if (charge) {
      const { refundableAmount, currency, roomID } = charge;
      partialState.roomID = roomID;
      partialState.selectedRefundableCharge = charge;
      partialState.selectedFormattedRefundableAmount = formatCurrency(refundableAmount, currency);
      const isRoomStatusCancelledOrNonRenewing =
        charge.roomStatus === RoomStatus.NOT_RENEWING || charge.roomStatus === RoomStatus.CANCELED;
      // subscription
      const isSubscriptionFlow = charge.chargeType === 'subscription';
      const isCancelledSubscriptionFlow = isSubscriptionFlow && isRoomStatusCancelledOrNonRenewing;
      partialState.isNonCancelledSubscriptionFlow =
        isSubscriptionFlow && !isRoomStatusCancelledOrNonRenewing;
      partialState.isRefundableSubscriptionFlow =
        isCancelledSubscriptionFlow && charge.refundableAmount > 0;
      partialState.isNonRefundableSubscriptionFlow =
        isCancelledSubscriptionFlow && charge.refundableAmount <= 0;
      // lvs
      const isLVSFlow = charge.chargeType === 'videoCredit';
      partialState.isRedeemedLVSFlow = isLVSFlow && charge.refundableAmount <= 0;
      partialState.isNonRedeemedLVSFlow = isLVSFlow && charge.refundableAmount > 0;
      // copay
      const isCopayFlow = charge.chargeType === 'session';
      partialState.isRefundableCopayFlow = isCopayFlow && charge.refundableAmount > 0;
      partialState.isNonRefundableCopayFlow = isCopayFlow && charge.refundableAmount <= 0;
    }
    context.setState(partialState);
  };

  const setRefundReasonSubscription = (selectedRefundReasonSubscriptionIndex, context) => {
    const partialState: Partial<State> = {};
    const { setState, wizardContext } = context;
    const index = Number(selectedRefundReasonSubscriptionIndex);
    partialState.iWantToCancelRefundReasonSubscription =
      wizardContext.isNonCancelledSubscriptionFlow &&
      [
        REFUND_REASONS_SUBSCRIPTION.I_WANT_TO_CANCEL.value,
        REFUND_REASONS_SUBSCRIPTION.I_AM_APPLYING_INSURANCE.value,
      ].includes(index);
    partialState.videoIssuesRefundReasonSubscription =
      wizardContext.isNonCancelledSubscriptionFlow &&
      index === REFUND_REASONS_SUBSCRIPTION.I_HAD_ISSUES_WITH_VIDEO.value;
    partialState.otherRefundReasonSubscription =
      wizardContext.isNonCancelledSubscriptionFlow &&
      index === REFUND_REASONS_SUBSCRIPTION.OTHER.value;
    setState(partialState);
  };

  const setRefundReasonLVS = (selectedRefundReasonLVSIndex, context) => {
    const partialState: Partial<State> = {};
    const { setState, wizardContext } = context;
    partialState.isNotSelectedRefundReasonLVS =
      wizardContext.isNonRedeemedLVSFlow &&
      Number(selectedRefundReasonLVSIndex) === REFUND_REASONS_LVS.OTHER.value;
    setState(partialState);
  };

  return {
    charges,
    refundableChargesDisplay,
    supportResponseInDays,
    isDisplayRefundsFlow,
    isDontDisplayRefundsFlow,
    setSelectedRefundableCharge,
    setRefundReasonSubscription,
    setRefundReasonLVS,
  };
};

const persist = async (api, state, wizardCompleted) => {
  // save only if wizard completed
  // eslint-disable-next-line no-empty
  if (wizardCompleted) {
  }
};

const refundWizardActions: OtherWizardGenericActions<State> = {
  initState,
  persist,
};

export default refundWizardActions;
