import Svg, { G, Path, Rect } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const ToggleDetailsIcon = (props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'details';
  return (
    <Svg
      aria-hidden="true"
      width="33px"
      height="31px"
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 33 31"
      stroke={colors.green}
      {...props}
    >
      <G
        transform="translate(-698 -18) translate(700 19)"
        strokeWidth={1.72800007}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <Path d="M19.56 17.4h9.184c.632 0 1.148.652 1.148 1.45v8.7c0 .797-.516 1.45-1.148 1.45H19.56c-.631 0-1.148-.652-1.148-1.45v-8.7c0-.798.517-1.45 1.148-1.45z" />
        <Path d="M29.892445 19.72L24.1517043 23.78 18.4109635 19.72" />
        <Path d="M18.411 3.48L24.151 0l5.741 3.48v8.004c0 .705-.57 1.276-1.275 1.276h-8.93a1.276 1.276 0 01-1.276-1.276V3.48z" />
        <Path d="M22.238124 12.76L22.238124 6.38 26.0652845 6.38 26.0652845 12.76" />
        <Rect x={2.33688946} y={17.516} width={11.4814815} height={11.484} rx={1.26720005} />
        <Path d="M12.551 1.16c.7 0 1.267.567 1.267 1.267v8.95c0 .7-.567 1.267-1.267 1.267H3.604c-.7 0-1.267-.567-1.267-1.267l-.001-2.074L0 6.943l2.336-2.361V2.427c0-.7.568-1.267 1.268-1.267h8.947z" />
        <Path d="M10.6290705 16.24L10.6290705 18.792" />
        <Path d="M5.52618987 16.24L5.52618987 18.792" />
        <Path d="M2.33688946 21.344L13.8183709 21.344" />
      </G>
    </Svg>
  );
};

export default ToggleDetailsIcon;
