import React from 'react';
import { useTranslation } from '@talkspace/i18n';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { ExtraHuge, RedX, Large, A11Y_COLORS } from '@talkspace/react-toolkit';

const ConsentReceived = ({ error, title }: { error: string; title?: string }) => {
  const { localizationParentalConsent } = useFlags();
  const { t: tParentalConsent } = useTranslation('parentalConsentV2', {
    lng: localizationParentalConsent ? undefined : 'en',
  });
  const errorTitle = localizationParentalConsent
    ? tParentalConsent('errors.somethingFailed')
    : 'Something failed';
  return (
    <>
      <RedX style={{ marginBottom: 26 }} />
      <ExtraHuge style={{ marginBottom: 20 }}>{title || errorTitle}</ExtraHuge>
      <Large style={{ color: A11Y_COLORS.permaGrey }}>{error}</Large>
    </>
  );
};

export default ConsentReceived;
