import { Card, CardVariant, SkeletonLoader, View, spacing } from '@talkspace/react-toolkit';

const { space100, space200 } = spacing;

const CardLoader = ({ cardVariant, hasTag }: { cardVariant?: CardVariant; hasTag?: boolean }) => {
  const textSkeletonStyle = { marginTop: space100 };
  return (
    <Card variant={cardVariant}>
      <View row>
        <SkeletonLoader variant="Avatar" />
        <View style={{ marginLeft: space200, flex: 1 }}>
          {hasTag && <SkeletonLoader variant="StatusTag" />}
          <SkeletonLoader
            variant="headingMd"
            style={{ ...textSkeletonStyle, maxWidth: 169, marginTop: hasTag ? space100 : 0 }}
          />
          <SkeletonLoader variant="bodySm" style={textSkeletonStyle} />
          <SkeletonLoader variant="bodySm" style={textSkeletonStyle} />
        </View>
      </View>
    </Card>
  );
};

export default CardLoader;
