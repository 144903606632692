import { FunctionComponent } from 'react';
import { Button, View, Big, Large, Huge, ExtraHuge } from '@talkspace/react-toolkit';

import { SuccessCheckmark } from '../../../components/icons';
import styled from '../../../core/styled';

const VoucherType = styled(Huge)(({ theme: { colors } }) => {
  return { marginBottom: 10, color: colors.emerald };
});

const DoneButton = styled(Button)({
  marginTop: 60,
  marginBottom: 60,
});

interface Props {
  couponDetailsText: string;
  billingDetailsText: JSX.Element;
  onClosePress: () => void;
}

const Success: FunctionComponent<Props> = (props) => {
  const { couponDetailsText, billingDetailsText, onClosePress } = props;

  return (
    <>
      <View style={{ marginBottom: 20 }}>
        <SuccessCheckmark />
      </View>
      <ExtraHuge style={{ marginBottom: 20 }} dataQa="couponSuccessTitle">
        Coupon redeemed!
      </ExtraHuge>
      <VoucherType dataQa="couponSuccessVoucherType">{couponDetailsText}</VoucherType>
      <Large dataQa="couponSuccessDetails">{billingDetailsText}</Large>
      <DoneButton onPress={onClosePress} dataQa="couponSuccessDoneButton">
        <Big variant="bigWide">Done</Big>
      </DoneButton>
    </>
  );
};

export default Success;
