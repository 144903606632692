import { FunctionComponent } from 'react';
import { Large, View, ExtraHuge } from '@talkspace/react-toolkit';
import { OfferPrice, Currency } from 'ts-frontend/types';
import { formatCurrency } from 'ts-frontend/helpers/billingUtils';
import styled, { EmotionStyle } from '@/core/styled';

interface OfferPriceProps {
  offerPrice: OfferPrice;
  style?: EmotionStyle;
}

const HugePrice = styled(ExtraHuge)<{ strikethrough: boolean }>(
  ({ strikethrough, theme: { colors } }) =>
    strikethrough
      ? {
          color: colors.extraLightGrey,
          textDecoration: 'line-through',
        }
      : {}
);

const Savings = ({
  newPrice,
  originalPrice,
  currency,
  discountDurationWeeks,
}: {
  newPrice: number;
  originalPrice: number;
  currency: Currency;
  discountDurationWeeks?: number;
}) => {
  if (discountDurationWeeks) {
    return (
      <Large variant="largeBoldBrightGreen">
        {formatCurrency(newPrice, currency)}/week for the first {discountDurationWeeks} weeks
      </Large>
    );
  }
  if (originalPrice === newPrice) {
    return (
      <Large variant="largeBoldBrightGreen">Your price: {formatCurrency(newPrice, currency)}</Large>
    );
  }
  return (
    <Large variant="largeBoldBrightGreen">
      Your price: {formatCurrency(newPrice, currency)} (you save{' '}
      {formatCurrency(originalPrice - newPrice, currency)})
    </Large>
  );
};

const PlanOfferPrice: FunctionComponent<OfferPriceProps> = ({
  offerPrice: { amount, unit, originalPrice, currency, discountDurationWeeks },
  style,
}) => (
  <View style={style}>
    <View row align="end">
      <HugePrice inline strikethrough={!!originalPrice && originalPrice !== amount}>
        {formatCurrency(originalPrice || amount, currency)}
      </HugePrice>
      <Large inline variant="largeDarkGrey" style={{ lineHeight: '29px' }}>
        {unit}
      </Large>
    </View>
    {originalPrice && originalPrice !== amount && (
      <Savings
        newPrice={amount}
        originalPrice={originalPrice}
        currency={currency}
        discountDurationWeeks={discountDurationWeeks}
      />
    )}
  </View>
);

export default PlanOfferPrice;
