import { useEffect, VoidFunctionComponent } from 'react';
import { Modal } from '@talkspace/react-toolkit';
import { useMeetYourProviderState } from '../hooks/meetYourProviderContext';
import { useHistory } from '../../core/routerLib';
import { trackBookFirstSessionPrompt } from '../../utils/analytics/events';
import BookAsyncSessionView from '../components/BookAsyncSessionView';

const titleText = 'Book Session';

const BookAsyncSessionScreen: VoidFunctionComponent = () => {
  const { roomID, therapistID } = useMeetYourProviderState();
  const history = useHistory();

  useEffect(() => {
    if (roomID && therapistID) {
      trackBookFirstSessionPrompt({
        roomID,
        providerID: therapistID,
        modalityNudge: 'Messaging',
      });
    }
  }, [roomID, therapistID]);

  const handleStartMessagingSessionClick = () => {
    if (!roomID) {
      return;
    }
    history.push(`/in-room-scheduling/room/${roomID}/messaging-session`, { asyncOnboarding: true });
  };

  const handleBookLiveSessionClick = () => {
    if (!roomID) {
      return;
    }
    history.push(`/in-room-scheduling/room/${roomID}`);
  };

  return (
    <Modal isVisible titleText={titleText}>
      <BookAsyncSessionView
        roomID={roomID}
        handleBookLiveSessionClick={handleBookLiveSessionClick}
        handleStartMessagingSessionClick={handleStartMessagingSessionClick}
      />
    </Modal>
  );
};

export default BookAsyncSessionScreen;
