import { useTranslation } from '@talkspace/i18n';
import { useEffect } from 'react';
import { RouteComponentProps, withRouter } from '@/core/routerLib';
import { trackEvent } from '@/utils/analytics/eventTracker';

import OnboardingPage from './OnboardingPage';
import MedicalHistory from '../assets/MedicalHistory';

const OnboardingPageMedicalHistory = ({
  match: {
    params: { roomID },
  },
  history,
  totalSteps,
  currentStep,
}: RouteComponentProps<{ roomID: number }> & { totalSteps: number; currentStep: number }) => {
  const { t: tAdultOnboarding } = useTranslation('adultOnboarding');

  useEffect(() => {
    trackEvent('View Complete Your Intake Screen', { roomID }, ['mixpanel']);
  }, [roomID]);

  return (
    <OnboardingPage
      totalSteps={totalSteps}
      currentStep={currentStep}
      illustration={<MedicalHistory />}
      title={tAdultOnboarding('completeIntake.title', 'Complete your intake')}
      description={tAdultOnboarding(
        'completeIntake.description',
        'To give you the best support we need to get to know you. We’ll ask you questions about your physical, mental, and emotional health.'
      )}
      ctaText={tAdultOnboarding('completeIntake.startNow', 'Start now')}
      onPress={() => {
        trackEvent(
          'Start Medical History',
          {
            actionName: 'onboardingStep',
            roomID,
          },
          ['tsAnalytics']
        );
        history.push(`/room/${roomID}/onboarding/mental-health/source/onboarding`);
      }}
    />
  );
};

export default withRouter(OnboardingPageMedicalHistory);
