import { FunctionComponent, useEffect, useCallback } from 'react';
import { View, Huge, Button, Large } from '@talkspace/react-toolkit';
import { persistData, useWizardActions, useWizardState } from 'stepWizard';
import { trackWizardEvent } from '@/utils/analytics/events';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { RouteComponentProps, withRouter } from '../../core/routerLib/routerLib';
import ActionStatus from '../../components/ActionStatus/ActionStatus';
import styled from '../../core/styled/index';
import useMatchingIntake from '../hooks/useMatchingIntake';

const Wrapper = styled(View)({
  alignSelf: 'center',
  width: 340,
});

const TitlesView = styled(View)({
  minHeight: 156,
  textAlign: 'center',
  width: 'auto',
});

const StyledHuge = styled(Huge)({
  marginTop: 24,
  marginBottom: 24,
  fontSize: 32,
});

const StyledLarge = styled(Large)({
  fontSize: 17,
  marginBottom: 150,
});

const SubmitMatchingIntake: FunctionComponent<RouteComponentProps<{}>> = () => {
  const [state, { submitIntakeResults }] = useMatchingIntake();
  const { error, isSuccess, isLoading } = state;
  const { setState, onExitClick } = useWizardActions();
  const {
    roomID,
    clientUserID,
    responses,
    seekHelpReason,
    alreadySeenTherapist,
    treatmentGoal,
    countryState,
    providerGender,
    therapistEthnicity,
    clientGender,
    relationshipStatus,
    educationLevel,
    workStatus,
    hearAboutUs,
    dateOfBirth,
    eventCategory,
  } = useWizardState();
  const closeModal = useCloseModal();

  const submitResults = useCallback(() => {
    const [country, USState] = countryState.split('-');
    const payload = {
      gender: clientGender,
      dateOfBirth,
      country,
      state: USState || null,
      therapistGenderPreference: providerGender,
      relationshipStatus,
      presentingProblems: seekHelpReason,
      treatmentGoal,
      therapyBefore: alreadySeenTherapist.toUpperCase() === 'YES',
      attributionSource: hearAboutUs,
      educationLevel,
      workStatus,
      therapistEthnicityPreference: therapistEthnicity,
    };
    submitIntakeResults(roomID, clientUserID, payload);
  }, [
    alreadySeenTherapist,
    clientGender,
    clientUserID,
    countryState,
    dateOfBirth,
    educationLevel,
    hearAboutUs,
    relationshipStatus,
    roomID,
    seekHelpReason,
    submitIntakeResults,
    therapistEthnicity,
    providerGender,
    treatmentGoal,
    workStatus,
  ]);

  const returnToChat = () => {
    closeModal({
      navigateTo: 'room',
      metadata: { roomID },
    });
  };

  useEffect(() => {
    submitResults();
  }, [submitResults]);

  useEffect(() => {
    const onSuccess = () => {
      if (isSuccess || error) {
        if (isSuccess) {
          trackWizardEvent('Matching intake submitted', eventCategory, {
            'User ID': clientUserID ? clientUserID.toString() : '',
            label: 'Matching intake submitted',
            Application: eventCategory,
            eventPropertyValue: 0.0,
          });
        }
        persistData(responses, setState, 'Matching intake submitted', error, {}, isSuccess);
      }
    };
    onSuccess();
  }, [isSuccess, error]); // eslint-disable-line

  return (
    <Wrapper>
      {error || isLoading ? (
        <ActionStatus
          isLoading={isLoading}
          isError={!!error}
          errorTitle="Something Went Wrong"
          errorSubTitle="Please check your internet connection and try again."
          errorButtonText="Close"
          errorButtonAction={onExitClick}
          titleStyles={{ fontWeight: 800, fontSize: 29, marginTop: 25 }}
          subTitleStyles={{
            fontSize: 16,
            color: '#454A5E',
            marginTop: 13,
            marginBottom: 42,
            width: 200,
            textAlign: 'center',
          }}
        />
      ) : null}
      {isSuccess && (
        <View>
          <TitlesView>
            <StyledHuge>You're all done!</StyledHuge>
            {/* TODO: Verify this variant is accurate */}
            <StyledLarge variant="largeDarkGrey">
              Thank you for completing the matching intake. These answers will help us provide you
              with the right therapist matches to choose from.
            </StyledLarge>
          </TitlesView>
          <Button text="Return to chat" onPress={returnToChat} style={{ width: 320 }} />
        </View>
      )}
    </Wrapper>
  );
};

export default withRouter(SubmitMatchingIntake);
