import { isPhonePhoneNumberEmpty, isPhoneNumberValid } from '@talkspace/react-toolkit';
import { WizardGenericActions } from 'stepWizard';
import { OtherWizardGenericActions } from '@/utils/genericWizardActions';
import ApiHelper from '@/utils/ApiHelper';
import { TeenEmergencyContactWizardState } from '../reducers/teenEmergencyContactWizardState';

const fixPhoneValue = (value: string | null) => {
  if (!value) return value;
  if (!isPhonePhoneNumberEmpty(value)) {
    return value;
  }
  if (value[0] === '+') {
    return value;
  }
  const valueWithPlus = `+${value}`;
  const valueWithCode = `+1${value}`;
  if (value[0] === '1' && value.length === 11) {
    if (isPhoneNumberValid(valueWithPlus)) {
      return valueWithPlus;
    }
  }
  if (value.length === 10) {
    if (isPhoneNumberValid(valueWithCode)) {
      return valueWithCode;
    }
  }
  if (isPhoneNumberValid(valueWithCode)) {
    return valueWithCode;
  }
  if (isPhoneNumberValid(valueWithPlus)) {
    return valueWithPlus;
  }

  return value;
};

const initState: WizardGenericActions<
  TeenEmergencyContactWizardState,
  ApiHelper
>['initState'] = async (api: ApiHelper, state) => {
  const { clientUserID } = state;
  if (!clientUserID) {
    return {};
  }
  const [emergencyContactResponse, customerInformationResponse] = await Promise.all([
    api.getEmergencyContactV3(clientUserID),
    api.getCustomerInformationV3(clientUserID),
  ]);
  const {
    data: { data },
  } = emergencyContactResponse || {};

  const {
    data: { data: customerInformation },
  } = customerInformationResponse || {};
  const { ethnicity } = customerInformation;

  const {
    firstName,
    lastName,
    middleName,
    address,
    address2,
    state: addressState,
    country,
    zipcode,
    city,
    contactName,
    contactPhone,
    phone,
    relationship,
  } = data;

  const name = {
    firstName: firstName || '',
    lastName: lastName || '',
    middleName: middleName || '',
  };
  const addressExists = address && addressState && country && zipcode && city;

  const addressFromApi = addressExists
    ? {
        ...state.address.addressFromApi,
        fullAddress: `${address}, ${city}, ${addressState} ${zipcode}, ${country}`,
        address,
        state: addressState,
        zipcode,
        city,
        country,
      }
    : state.address.addressFromApi;
  const newState = {
    ...state,
    name,
    address: {
      ...state.address,
      addressFromApi,
      addressString: address2,
    },
    contactName,
    contactPhone: fixPhoneValue(contactPhone),
    phone: fixPhoneValue(phone),
    relationship,
    raceOrEthnicity: ethnicity,
  };
  return newState;
};

const persist = async () => {};

const teenEmergencyContactActions: OtherWizardGenericActions<TeenEmergencyContactWizardState> = {
  initState,
  persist,
};

export default teenEmergencyContactActions;
