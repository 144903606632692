import { useEmotionTheme, View, Screen } from '@talkspace/react-toolkit';
import { WizardScreen, useWizardActions } from 'stepWizard';
import { useTranslation } from '@talkspace/i18n';
import { Route, Switch, useHistory } from '@/core/routerLib';
import useWindowWidth from '../../clientChat/hooks/useWindowWidth';
import emergencyContactScheme from './emergencyContactScheme';
import SubmitEmergencyContact from './containers/submitEmergencyContact';
import useQueryEmergencyContact from '@/hooks/useQueryEmergencyContact';
import { getUserData } from '@/auth/helpers/token';

const Routes = () => {
  const { onExitClick } = useWizardActions();
  const { isMobile } = useWindowWidth();
  const { colors } = useEmotionTheme();
  const { t: tAdultOnboarding } = useTranslation('adultOnboarding');
  const { id: userID } = getUserData();
  const {
    location: { pathname },
  } = useHistory();

  const { data: emergencyContact } = useQueryEmergencyContact({ userID });
  const isOnboarding = pathname.includes('/onboarding');

  const showCloseButton = !isOnboarding;
  const scheme = emergencyContactScheme(tAdultOnboarding);
  const wizardScheme = {
    ...scheme,
    ...(isOnboarding ? { hideProgressIndicator: true } : {}),
  };

  const showEmergencyContactTitle = !!(
    emergencyContact?.firstName &&
    emergencyContact?.lastName &&
    emergencyContact?.address &&
    emergencyContact?.phone
  );

  const title = showEmergencyContactTitle
    ? tAdultOnboarding('emergencyContact.navBarTitle', 'Emergency contact intake')
    : tAdultOnboarding('emergencyContact.navBarTitle2', 'Contact information');

  return (
    <Switch>
      <View style={{ background: colors.white }}>
        <Screen
          safeAreaVariant="setHeightAndAddTop"
          onCloseButtonClick={onExitClick}
          title={isMobile ? title : ''}
          showCloseButton={showCloseButton}
          scrollViewStyles={{ padding: !isMobile ? '0 22px 22px 22px' : 0 }}
        >
          <Route
            exact
            path={[
              '/emergency-contact-wizard/submit',
              '/room/:roomID/onboarding/emergency-contact/source/:source/submit',
            ]}
            component={SubmitEmergencyContact}
          />
          <Route
            exact
            path={[
              '/emergency-contact-wizard/room/:roomID/source/:source',
              '/room/:roomID/onboarding/emergency-contact/source/:source',
            ]}
            render={() => <WizardScreen wizardScheme={wizardScheme} />}
          />
        </Screen>
      </View>
    </Switch>
  );
};

export default Routes;
