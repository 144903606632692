import { FunctionComponent } from 'react';
import moment from 'moment';
import { useEmotionTheme, useModalActions } from '@talkspace/react-toolkit';
import { GenericWizardWelcomeScreen, useWizardState } from 'stepWizard';
import peopleImage from '../../assets/peopleImage.png';

const WelcomeScreen: FunctionComponent<{
  handleStartButton: () => void;
  completedAtDate?: string;
  isCompleted?: boolean;
}> = ({ handleStartButton, completedAtDate, isCompleted = false }) => {
  const firstHeadlineRowText = 'Finding the';
  const secondHeadlineRowText = 'right match';
  const { setModalTitle } = useModalActions();
  const { roomType } = useWizardState();
  const isPsych = roomType === 'psychiatry_room';
  setModalTitle(`${firstHeadlineRowText} ${secondHeadlineRowText}`);
  const { colors } = useEmotionTheme();
  const nSteps = isPsych ? 9 : 10;
  const description = isPsych
    ? 'Finding a great provider begins with learning about what brings you to Psychiatry and some basic information about you.'
    : "Finding a great therapist begins with learning about what brings you to therapy and some basic information about you and what you're looking to achieve.";
  return (
    <GenericWizardWelcomeScreen
      firstHeadlineRowText={firstHeadlineRowText}
      secondHeadlineRowText={secondHeadlineRowText}
      subHeadlineText={`${nSteps} questions - 2min`}
      description={description}
      onButtonPress={handleStartButton}
      buttonText={
        isCompleted ? `Completed ${moment(completedAtDate).format('MMM DD, YYYY')}` : 'Begin'
      }
      imageURL={peopleImage}
      imageHeight={206}
      imageWidth={226}
      imageXPos={161}
      imageYPos={56}
      imageMobileXPos={34}
      imageMobileYPos={120}
      backgroundColor={colors.welcomeBlue}
      isCompleted={isCompleted}
    />
  );
};

export default WelcomeScreen;
