import sessionStorage from '../../../core/storage/sessionStorage';

const storageKey = 'ssoRetryOriginalHistoryLength';

export const ssoSetStartHistoryLength = (): void => {
  sessionStorage.setItem(storageKey, window.history.length.toString());
};

export const ssoRetry = () => {
  const origHistoryLength = sessionStorage.getItem(storageKey);
  sessionStorage.removeItem(storageKey);
  if (origHistoryLength) {
    const backCount = window.history.length - Number(origHistoryLength);
    if (backCount > 0) {
      window.history.go(-backCount);
      return;
    }
  }
  window.location.replace('/login/sso');
};
