import { FunctionComponent, useState } from 'react';
import {
  useUniqueID,
  View,
  Button,
  ExtraHuge,
  TSInput,
  Label,
  AnimatedError,
  useEmotionTheme,
  SpacingView,
} from '@talkspace/react-toolkit';
import { checkEmailValidity } from 'ts-frontend/helpers';
import styled from '../../../core/styled/styled';

const Container = styled(View)({ width: 336 });

const HeaderWrapper = styled(View)({
  textAlign: 'left',
  marginBottom: 30,
  paddingLeft: 4,
});

const InputWrapper = styled(View)({
  marginBottom: 10,
});
interface Props {
  updateEmail: (email: string) => void;
  onCancelPress: () => void;
}

const ChangeEmailModal: FunctionComponent<Props> = ({ updateEmail, onCancelPress }) => {
  const [email, setEmail] = useState('');
  const [confirmationEmail, setConfirmationEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [confirmationEmailError, setConfirmationEmailError] = useState('');

  const handleEmailChange = (value: string) => {
    setEmail(value);
    setEmailError('');
  };

  const handleConfirmationEmailChange = (value: string) => {
    setConfirmationEmail(value);
    setConfirmationEmailError('');
  };

  const validateInput = () => {
    if (!email) {
      setEmailError('Please enter new email address.');
      return false;
    }

    if (!checkEmailValidity(email)) {
      setEmailError('Please enter a valid email address.');
      return false;
    }

    if (!confirmationEmail) {
      setConfirmationEmailError('Please confirm new email address.');
      return false;
    }

    if (email !== confirmationEmail) {
      setConfirmationEmailError("The email addresses you entered don't match.");
      return false;
    }

    return true;
  };

  const handleSavePress = () => {
    setEmailError('');
    setConfirmationEmailError('');
    const isValidInput = validateInput();
    if (isValidInput) {
      updateEmail(email);
    }
  };

  const newEmailInputID = useUniqueID('newEmailInputID');
  const confirmEmailInputID = useUniqueID('confirmEmailInputID');
  const newEmailError = useUniqueID('newEmailError');
  const confirmEmailError = useUniqueID('confirmEmailError');
  const { colors } = useEmotionTheme();
  return (
    <Container>
      <HeaderWrapper>
        <ExtraHuge>Change email</ExtraHuge>
      </HeaderWrapper>
      <InputWrapper>
        <Label htmlFor={newEmailInputID}>New email address</Label>
        <TSInput
          id={newEmailInputID}
          ariaDescribedBy={emailError ? newEmailError : undefined}
          onChange={handleEmailChange}
          value={email}
          placeholder="Enter new email address"
          style={{ fontSize: 16, borderColor: emailError && colors.red }}
          shouldFocus={!!emailError}
          dataQa="myAccountNewEmailInput"
        />
        <AnimatedError id={newEmailError} shouldAnimate={Boolean(emailError)}>
          {emailError}
        </AnimatedError>
      </InputWrapper>
      <InputWrapper>
        <Label htmlFor={confirmEmailInputID}>Confirm new email address</Label>
        <TSInput
          id={confirmEmailInputID}
          ariaDescribedBy={confirmationEmailError ? confirmEmailError : undefined}
          onChange={handleConfirmationEmailChange}
          value={confirmationEmail}
          placeholder="Confirm new email address"
          style={{ fontSize: 16, borderColor: confirmationEmailError && colors.red }}
          shouldFocus={!!(confirmationEmailError && !emailError)}
          dataQa="myAccountConfirmNewEmailInput"
        />
        <AnimatedError id={confirmEmailError} shouldAnimate={Boolean(confirmationEmailError)}>
          {confirmationEmailError}
        </AnimatedError>
      </InputWrapper>
      <SpacingView>
        <Button
          text="Save"
          onPress={handleSavePress}
          dataQa="myAccountChangeEmailSaveButton"
          style={{ flex: 1 }}
        />
        <Button
          text="Cancel"
          variant="secondary"
          onPress={onCancelPress}
          dataQa="myAccountChangeEmailCancelButton"
          style={{ flex: 1 }}
        />
      </SpacingView>
    </Container>
  );
};

export default ChangeEmailModal;
