import { FunctionComponent, useState, useEffect, useCallback, useMemo } from 'react';
import { History } from 'history';
import { RouteComponentProps } from '@/core/routerLib/routerLib';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import {
  IntakeStatusStates,
  StepCompletedAtDatesFromApi,
} from '@/treatmentIntake/treatmentIntakeLauncher/types';
import TreatmentIntakeLauncherModal from '../components';

import treatmentIntakeApiHelper from '../../treatmentIntakeApiHelper';

type Props = RouteComponentProps<
  { roomID: string; code: string; history: History },
  unknown,
  { initStep?: number }
>;

const initIntakeStatusState: IntakeStatusStates = {
  emergency_contact_requested: { step: 0, completedAt: null },
  clinical_requested: { step: 1, completedAt: null },
  medical_requested: { step: 2, completedAt: null },
  submitted: { step: 3 },
};

const setStepCompletedAtDatesHelper = (
  res: StepCompletedAtDatesFromApi & { [key: string]: unknown },
  setInitIntakeStatusStates: (initIntakeStatusStates: IntakeStatusStates) => void
) => {
  if (res) {
    const { emergencyContactCompletedAt, clinicalCompletedAt, medicalCompletedAt } = res;

    setInitIntakeStatusStates({
      emergency_contact_requested: { step: 0, completedAt: emergencyContactCompletedAt },
      clinical_requested: { step: 1, completedAt: clinicalCompletedAt },
      medical_requested: { step: 2, completedAt: medicalCompletedAt },
      submitted: { step: 3 },
    });
  }
};

const TreatmentIntakeLauncherContainer: FunctionComponent<Props> = ({ history, match }) => {
  const { roomID } = match.params;

  const closeModal = useCloseModal();
  const [isError, setIsError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [intakeStatusState, setIntakeStatusState] = useState(initIntakeStatusState);
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(true);
  const [intakeStatus, setIntakeStatus] = useState('');
  const [freezeDueAnimation, setFreezeDueAnimation] = useState(false);

  const initStep = useMemo(
    () => history && history.location && history.location.state && history.location.state.initStep,
    [history]
  );

  const [step, setStep] = useState(initStep || 0);

  const onMount = useCallback(() => {
    setLoading(true);
    treatmentIntakeApiHelper
      .getIntakeStatus(roomID)
      .then((res) => {
        if (history?.location?.state?.initStep || history?.location?.state?.initStep === 0) {
          setLoading(false);
        }
        setStepCompletedAtDatesHelper(res, setIntakeStatusState);
        const newIntakeStatus = res.intakeStatus;
        setIntakeStatus(newIntakeStatus);

        if (newIntakeStatus !== 'emergency_contact_requested') {
          setShowWelcomeScreen(false);
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        setIsError(true);
        setLoading(false);
      });
  }, [history, roomID]);

  const onCheckAnimationEnd = () => {
    setFreezeDueAnimation((currentFreezeDueAnimation) => {
      if (currentFreezeDueAnimation) {
        const currentStep = intakeStatusState[intakeStatus].step;
        setStep(currentStep);
      }
      return false;
    });
  };

  const updateStep = useCallback(() => {
    if (history?.location?.state?.initStep || history?.location?.state?.initStep === 0) {
      setFreezeDueAnimation(true);
    } else if (intakeStatus) {
      setStep(intakeStatusState[intakeStatus].step);
      setLoading(false);
    }
  }, [history, intakeStatus, intakeStatusState]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onMount, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateStep, [showWelcomeScreen, intakeStatus]);

  return (
    <TreatmentIntakeLauncherModal
      onCheckAnimationEnd={freezeDueAnimation ? onCheckAnimationEnd : () => {}}
      freezeDueAnimation={freezeDueAnimation}
      roomID={roomID}
      showWelcomeScreen={showWelcomeScreen}
      setShowWelcomeScreen={setShowWelcomeScreen}
      step={step}
      setStep={setStep}
      isError={isError}
      isLoading={isLoading}
      onClosePress={() => closeModal()}
      historyPush={(url) => history.push(url)}
      intakeStatusState={intakeStatusState}
      intakeStatus={intakeStatus as keyof IntakeStatusStates}
      initStep={initStep}
    />
  );
};

export default TreatmentIntakeLauncherContainer;
