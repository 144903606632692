import { FunctionComponent } from 'react';
import { Large, View, Button } from '@talkspace/react-toolkit';

import { useClientAuthActions } from '../../hooks/clientAuthContext';
import styled from '../../core/styled';

const ContentWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
      colors,
    },
  }) => {
    return {
      padding: 20,
      backgroundColor: colors.white,
      minHeight: 200,
      height: isMobile ? '100%' : 'auto',
      width: isMobile ? '100%' : 500,
      borderRadius: isMobile ? 0 : 10,
    };
  }
);

const LogoutAetnaPopup: FunctionComponent = () => {
  const { logoutAction } = useClientAuthActions();

  const handleReloadPress = () => {
    logoutAction('https://www.resourcesforliving.com/');
  };

  return (
    <ContentWrapper>
      <View flex={1} align="center" justify="center">
        <Large variant="largeDarkGrey" style={{ marginBottom: 20 }}>
          You are logging out of Talkspace.com only,
          <br />
          to complete log out process please log out of your RFL account here
        </Large>
        <Button text="Continue" onPress={handleReloadPress} />
      </View>
    </ContentWrapper>
  );
};

export default LogoutAetnaPopup;
