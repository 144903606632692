import { AddressInputValue, OptionType } from '@talkspace/react-toolkit';
import {
  isPregnantOptions,
  medicalIssues,
  hasChronicPainOptions,
  medications,
  controlledSubstances,
} from '@talkspace/configs';

import { ValueType } from 'react-select';
import {
  BaseWizardState,
  baseWizardInitialState,
  keyValueToValueType,
  WizardPostAction,
} from 'stepWizard';
import { TFAdultOnboarding } from '@talkspace/i18n/types';

export interface MedicalHealthWizardState extends BaseWizardState {
  isPregnant: null | boolean;
  medicalIssues: number[];
  hasChronicPain: null | boolean;
  medications: (number | string)[];
  medicationsCustom: (number | string)[];
  otcMedications: null | string;
  drugAllergies: null | string;
  pharmacyAddress: AddressInputValue;
  controlledSubstances: number[];

  selectedIsPregnant: boolean | null;
  selectedHasChronicPain: boolean | null;
  isPregnantPossible: boolean | null;

  isPregnantOptions: ValueType<OptionType, true>;
  hasChronicPainOptions: ValueType<OptionType, true>;
  medicalIssuesOptions: ValueType<OptionType, true>;
  medicationsOptions: ValueType<OptionType, true>;
  controlledSubstancesOptions: ValueType<OptionType, true>;

  setSelectedHasChronicPain?: WizardPostAction<MedicalHealthWizardState, void>;
  setSelectedIsPregnant?: WizardPostAction<MedicalHealthWizardState, void>;
}

export const getInitialState = (
  clientUserID: number,
  roomID: number,
  source: string,
  contextID: string,
  tAdultOnboarding: TFAdultOnboarding
): MedicalHealthWizardState => {
  return {
    ...baseWizardInitialState(source, contextID),
    roomID,
    clientUserID,
    isPregnant: null,
    medicalIssues: [],
    hasChronicPain: null,
    medications: [],
    medicationsCustom: [],
    otcMedications: null,
    drugAllergies: '',
    pharmacyAddress: {
      addressFromApi: {
        address: '',
        city: '',
        state: '',
        fullAddress: '',
        zipcode: '',
      },
      addressString: '',
    },
    controlledSubstances: [],

    selectedIsPregnant: null,
    selectedHasChronicPain: null,
    isPregnantPossible: null,
    isPregnantOptions: keyValueToValueType(isPregnantOptions).map((option) => {
      return {
        ...option,
        label: tAdultOnboarding(`pregnant.${option.value}`, option.label),
      };
    }),
    hasChronicPainOptions: keyValueToValueType(hasChronicPainOptions).map((option) => {
      return {
        ...option,
        label: tAdultOnboarding(`chronic.${option.value}`, option.label),
      };
    }),
    medicalIssuesOptions: keyValueToValueType(medicalIssues).map((option) => {
      return {
        ...option,
        label: tAdultOnboarding(`currentTreatment.${option.value}`, option.label),
      };
    }),
    medicationsOptions: keyValueToValueType(medications).map((option) => {
      return {
        ...option,
        label: tAdultOnboarding(`medication.${option.value}`, option.label),
      };
    }),
    controlledSubstancesOptions: keyValueToValueType(controlledSubstances).map((option) => {
      return {
        ...option,
        label: tAdultOnboarding(`previousSubstance.${option.value}`, option.label),
      };
    }),
  };
};
