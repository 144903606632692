type ModeOptions<T extends string, K extends Function> = {
  [key in T]: K;
};

function callBasedOnMode<T extends string, K extends () => unknown>(
  mode: T,
  options: ModeOptions<T, K>
): ReturnType<K> {
  return options[mode]() as ReturnType<K>;
}

export default callBasedOnMode;
