import { FunctionComponent } from 'react';
import Svg, { Path, Defs, G, Use, Text, TSpan } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

interface RoundCheckmarkProps {
  color?: string;
  width?: number;
  height?: number;
  style?: {};
}

const RoundCheckmark: FunctionComponent<RoundCheckmarkProps> = ({
  width = 74,
  height = 74,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'check mark';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...otherProps}
    >
      <Defs>
        <Path
          id="b"
          d="M0 10.002C0 4.478 4.475 0 10.002 0h479.996C495.522 0 500 4.481 500 10.002v484.996c0 5.524-4.475 10.002-10.002 10.002H10.002C4.478 505 0 500.519 0 494.998V10.002z"
        />
        <filter
          id="a"
          width="104.4%"
          height="104.4%"
          x="-2.2%"
          y="-1.4%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology in="SourceAlpha" radius="2" result="shadowSpreadOuter1" />
          <feOffset dy="4" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0.156418748 0 0 0 0 0.255632926 0 0 0 0.4 0"
          />
        </filter>
      </Defs>
      <G
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-564 -237)"
      >
        <Path
          fill={colors.permaLinkWaterGrey}
          d="M394 130.003c0-11.047 8.951-20.003 20.004-20.003h265.992c11.048 0 20.004 8.957 20.004 20.003v99.994c0 11.047-8.951 20.003-20.004 20.003H398.993a5 5 0 01-4.993-5.003V130.003z"
        />
        <G transform="translate(394 252)">
          <Path
            fill={colors.permaLinkWaterGrey}
            d="M0 19.993C0 8.951 8.95 0 19.997 0H255.01A4.995 4.995 0 01260 5.01v26.997C260 43.049 251.05 52 240.003 52H19.997C8.953 52 0 43.046 0 32.007V19.993z"
            transform="matrix(-1 0 0 1 260 0)"
          />
          <Path
            stroke={colors.heatherGrey}
            strokeLinecap="round"
            strokeWidth="6.3"
            d="M50 25.5L238 25.5"
          />
          <Text
            fill={colors.slateGrey}
            fontFamily="Helvetica"
            fontSize="11"
            fontWeight="normal"
            letterSpacing="0.2"
          >
            <TSpan x="219.791" y="41">
              4:40
            </TSpan>
          </Text>
        </G>
        <Path fill={colors.fordGrey} d="M0 0H1200V850H0z" />
        <G transform="translate(361 175)">
          <Use fill={colors.trueBlack} xlinkHref="#b" href="#b" />
          <Path
            fill={colors.white}
            stroke={colors.permaLinkWaterGrey}
            strokeLinejoin="round"
            d="M.5 10.002v484.996c0 5.246 4.255 9.502 9.502 9.502h479.996a9.5 9.5 0 009.502-9.502V10.002c0-5.246-4.255-9.502-9.502-9.502H10.002A9.5 9.5 0 00.5 10.002z"
          />
        </G>
        <G stroke={color || colors.green} strokeLinecap="round" transform="translate(566 239)">
          <Path
            strokeDasharray="194.7"
            strokeWidth="4.4"
            d="M55.862 8.282C41.086-3.248 19.75-.631 8.204 14.125-3.34 28.88-.719 50.189 14.057 61.718c14.777 11.53 36.114 8.913 47.658-5.843C73.26 41.12 70.64 19.811 55.862 8.282h0z"
          />
          <Path
            strokeWidth="5.5"
            d="M15.4750759 31.6284413L31.5356834 49.7710816 62.5339493 14.3722198"
          />
        </G>
      </G>
    </Svg>
  );
};

export default RoundCheckmark;
