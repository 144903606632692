import { useMemo, useRef } from 'react';

import {
  SpacingView,
  Spinner,
  Tabs,
  View,
  spacing,
  useEmotionTheme,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import useInfiniteScroll from '@talkspace/react-toolkit/src/hooks/useInfiniteScroll';

import { CommunityFeedTypeEnum, DailyPrompt } from '../types';
import CommunityFeedEmptyPosts from './CommunityFeedEmptyPosts';
import CommunityFeedConnectionError from './CommunityFeedConnectionError';
import CommunityPost from './CommunityPost';
import MoreResultsLoader from './MoreResultsLoader';
import CommunityFeedEnd from './CommunityFeedEnd';
import OpenPostCreatorButtons from './OpenPostCreatorButtons';
import WeightedSentence from './WeightedSentence';

const { space100, space200, space300, space600 } = spacing;
const HIDE_POSTS_WITH_MIN_FLAG_COUNT = 2;

interface Props {
  feedType: CommunityFeedTypeEnum;
  handleSelectFeedType: (feedType: CommunityFeedTypeEnum) => void;
  dailyPrompt: DailyPrompt | undefined;
  posts: Amity.Post[];
  hasMore: boolean;
  onLoadMore: () => void;
  isLoadingDailyPrompt: boolean;
  isLoadingPosts: boolean;
  isConnected: boolean;
  isConnectionError: boolean;
}

const CommunityFeed = ({
  feedType,
  handleSelectFeedType,
  dailyPrompt,
  posts,
  hasMore,
  onLoadMore,
  isLoadingDailyPrompt,
  isLoadingPosts,
  isConnected,
  isConnectionError,
}: Props) => {
  const { isMobile } = useWindowWidthState();
  const { colorRoles } = useEmotionTheme();
  const hasNoPosts = useMemo(() => !isLoadingPosts && posts.length === 0, [isLoadingPosts, posts]);

  const isInitialLoading = useMemo(
    () => isLoadingPosts && posts.length === 0,
    [isLoadingPosts, posts]
  );
  const isLoadingMore = useMemo(() => isLoadingPosts && posts.length > 0, [isLoadingPosts, posts]);

  const lastPostRef = useRef<HTMLDivElement | null>(null);

  const showFeedEnd = !hasNoPosts && !isLoadingPosts && !hasMore;
  const showPromptSection = feedType === 'today';
  const showPostsSection = isConnected && !isConnectionError;

  useInfiniteScroll({ bottomElementRef: lastPostRef, hasMore, onLoadMore });

  return (
    <View>
      <Tabs
        variant="hug"
        options={[
          {
            label: 'Community feed',
            onPress: () => handleSelectFeedType(CommunityFeedTypeEnum.TODAY),
            dataQa: 'communityFeedTab',
          },
          {
            label: 'My posts',
            onPress: () => handleSelectFeedType(CommunityFeedTypeEnum.USER),
            dataQa: 'myPostsTab',
          },
        ]}
      />

      {showPromptSection && (
        <View
          align="center"
          style={{
            paddingTop: space300,
          }}
        >
          {isLoadingDailyPrompt || !dailyPrompt ? (
            <Spinner style={{ height: 30, width: 30, marginBottom: space200 }} />
          ) : (
            <>
              <WeightedSentence
                sentence={dailyPrompt.text}
                style={{ marginBottom: isMobile ? space200 : space300 }}
              />
            </>
          )}
          <OpenPostCreatorButtons />
          <View
            style={{
              marginTop: space300,
              height: space100,
              backgroundColor: colorRoles.surfaces.neutralDefault,
              width: '100%',
            }}
          />
        </View>
      )}
      {showPostsSection && (
        <>
          {isInitialLoading && <Spinner style={{ marginTop: space600 }} />}
          {hasNoPosts && <CommunityFeedEmptyPosts feedType={feedType} />}
          <View>
            <SpacingView>
              {posts.map((post, i) => {
                if (post.flagCount >= HIDE_POSTS_WITH_MIN_FLAG_COUNT) return null;
                const isLast = i === posts.length - 1;
                return (
                  <CommunityPost
                    ref={isLast ? lastPostRef : undefined}
                    key={`post-${post.postId}`}
                    post={post}
                  />
                );
              })}
            </SpacingView>
            {isLoadingMore && <MoreResultsLoader text="More posts incoming..." />}
          </View>
          {showFeedEnd && <CommunityFeedEnd />}
        </>
      )}
      {isConnectionError && <CommunityFeedConnectionError />}
    </View>
  );
};

export default CommunityFeed;
