import { useEffect, useState } from 'react';

import { useHistory } from '@/core/routerLib';

import CommunityCreatePost from '../components/CommunityCreatePost';
import { createTextPost } from '../utils/amity';
import { getCreatePostTags, getCreatePostMetadata } from '../utils/helpers';
import { useCommunityState } from '../hooks/CommunityContext';
import useCommunityUser from '../hooks/useCommunityUser';
import { TEENSPACE_DAY_END_SHIFT_HOURS } from '../constants';
import { useOpenModal } from '../../utils/ModalsContextProvider';
import { trackCommunityPostComposerOpened, trackCommunityPostDrafted } from '../utils/analytics';

const TRACK_POST_DRAFTED_MIN_CHARACTERS = 3;

const CommunityCreatePostContainer = () => {
  const { communityID, userID, clientTimezone, dailyPrompt } = useCommunityState();
  const history = useHistory();
  const [responseText, setResponseText] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isTrackedPostDrafted, setIsTrackedPostDrafted] = useState<boolean>(false);
  const openModal = useOpenModal();

  const metadata = dailyPrompt
    ? getCreatePostMetadata({ dailyPromptText: dailyPrompt.text })
    : undefined;
  const tags = getCreatePostTags({
    userID,
    timezone: clientTimezone,
    shiftHours: TEENSPACE_DAY_END_SHIFT_HOURS,
  });

  const { user: communityUser } = useCommunityUser({ userID });
  const isLoadingPage = !dailyPrompt || !communityID;

  useEffect(() => {
    if (!isLoadingPage) {
      trackCommunityPostComposerOpened();
    }
  }, [isLoadingPage]);

  return (
    <CommunityCreatePost
      communityUser={communityUser}
      dailyPrompt={dailyPrompt}
      responseText={responseText}
      onChangeResponseText={(value) => {
        setResponseText(value);
        if (!isTrackedPostDrafted && value.length >= TRACK_POST_DRAFTED_MIN_CHARACTERS) {
          trackCommunityPostDrafted();
          setIsTrackedPostDrafted(true);
        }
      }}
      onPressPost={() => {
        if (!isLoadingPage) {
          setIsProcessing(true);
          createTextPost({ communityID, text: responseText, metadata, tags })
            .then(() => {
              openModal('/community/teenspace/create-post/success', { responseText });
            })
            .finally(() => {
              setIsProcessing(false);
            });
        }
      }}
      onPressBack={() => history.goBack()}
      isProcessing={isProcessing}
      isLoadingDailyPrompt={!dailyPrompt}
    />
  );
};

export default CommunityCreatePostContainer;
