import { useWindowWidth } from '@talkspace/react-toolkit';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { useHistory } from 'react-router';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';

type AccountSubPath = '/account-details';

const useAccountModalDeepRoute = () => {
  const history = useHistory();
  const { action } = (history.location.state || {}) as { action?: string };
  const { isMobile } = useWindowWidth();
  const useNewNav = useNewMemberNav();
  const isInFrame = ReactFrameService.instance().isInFrame();

  const shouldModalDeepRoute = action && useNewNav && isMobile && !isInFrame;

  const routeToDeepModal = (accountSubpagePath: AccountSubPath) =>
    history.push('/home?action=account', { accountSubpagePath });

  return { shouldModalDeepRoute, routeToDeepModal };
};

export default useAccountModalDeepRoute;
