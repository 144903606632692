import { Auth } from '@aws-amplify/auth';

type RegistrationParameters = {
  email: string;
  password: string;
  authToken: string;
  phoneNumber?: string;
};

const register = async ({
  email,
  password,
  phoneNumber,
  authToken,
}: RegistrationParameters): Promise<void> => {
  await Auth.signUp({
    username: email,
    password,
    attributes: {
      ...(phoneNumber
        ? {
            phone_number: phoneNumber,
          }
        : {}),
    },
    clientMetadata: {
      authToken,
    },
  });
};

export default register;
