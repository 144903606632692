import { Route, Switch } from 'react-router';
import FriendReferralScreen from './screens/FriendReferral';

const Routes = () => (
  <Switch>
    <Route path="/friend-referral" component={FriendReferralScreen} />
  </Switch>
);

export default Routes;
