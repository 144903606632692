import { useCallback } from 'react';
import { useWizardState } from 'stepWizard';
import InRoomSchedulingWrapper from '../../components/InRoomSchedulingWrapper';
import { State } from '../reducers/switchWizardState';
import useSwitchWizard from '../hooks/useSwitchWizard';

const SwitchConfirmBookingContainer = () => {
  const [, { switchTherapist }] = useSwitchWizard();
  const { roomID, newTherapistMatch, informedConsentGiven, isMessageHistoryShared } =
    useWizardState<State>();

  const preBookingAction = useCallback(async () => {
    if (newTherapistMatch) {
      return switchTherapist(
        roomID,
        newTherapistMatch?.therapistID,
        informedConsentGiven,
        isMessageHistoryShared
      );
    }
    return true;
  }, [informedConsentGiven, isMessageHistoryShared, newTherapistMatch, roomID, switchTherapist]);

  return (
    <InRoomSchedulingWrapper
      hideHeader
      noContextProvider
      bookWithIntroSession={false}
      preBookingAction={preBookingAction}
      flowVariant="switchWizard"
      futureTherapistID={newTherapistMatch?.therapistID}
    />
  );
};

export default SwitchConfirmBookingContainer;
