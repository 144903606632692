import Svg, { Path, Defs, Rect, G, Use } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const PastDuePlan = ({ width = 74, height = 74, ...props }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'past due plan';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <Defs>
        <Rect id="b" width="58" height="58" x="0" y="0" rx="29" />
        <filter
          id="a"
          width="146.6%"
          height="146.6%"
          x="-23.3%"
          y="-18.1%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology in="SourceAlpha" radius="1.5" result="shadowSpreadOuter1" />
          <feOffset dy="3" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5.5" />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.062745098 0 0 0 0 0.427620878 0 0 0 0 0.584313725 0 0 0 0.3 0"
          />
        </filter>
      </Defs>
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <Path
          fill={colors.permaLinkWaterGrey}
          d="M10 0h451c5.523 0 10 4.477 10 10v139c0 5.523-4.477 10-10 10H10c-5.523 0-10-4.477-10-10V10C0 4.477 4.477 0 10 0z"
          transform="translate(-2644 -249) translate(2632 234)"
        />
        <G transform="translate(-2644 -249) translate(2632 234) translate(20 20)">
          <use fill={colors.trueBlack} filter="url(#a)" xlinkHref="#b" />
          <Use fill={colors.white} xlinkHref="#b" href="#b" />
        </G>
        <G transform="translate(-2644 -249) translate(2632 234) translate(20 20) translate(11.204 17.388)">
          <Path
            stroke={colors.cobaltGrey}
            strokeLinecap="round"
            strokeWidth="2.7"
            d="M18.401 21.612H5.796c-2.762 0-5-2.398-5-5.357V5.97c0-2.958 2.238-5.357 5-5.357h19c2.761 0 5 2.399 5 5.357v4.643"
          />
          <Path fill={colors.cobaltGrey} d="M2 5H29V10H2z" />
          <Rect width="6" height="3" x="5" y="14.612" fill={colors.cobaltGrey} rx="1.5" />
          <Rect width="3" height="3" x="12" y="14.612" fill={colors.cobaltGrey} rx="1.5" />
          <circle cx="29.296" cy="21.112" r="7" fill={colors.heartRed} />
          <Path
            fill={colors.white}
            fillRule="nonzero"
            d="M29.546 17.362a.75.75 0 01.75.75v2h2a.75.75 0 01.743.648l.007.102v.5a.75.75 0 01-.75.75h-2v2a.75.75 0 01-.648.743l-.102.007h-.5a.75.75 0 01-.75-.75v-2h-2a.75.75 0 01-.743-.648l-.007-.102v-.5a.75.75 0 01.75-.75h2v-2a.75.75 0 01.648-.743l.102-.007z"
            transform="rotate(45 29.296 21.112)"
          />
        </G>
      </G>
    </Svg>
  );
};

export default PastDuePlan;
