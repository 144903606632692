import { forwardRef } from 'react';
import { View, TouchableView, Large, Tiny, useEmotionTheme } from '@talkspace/react-toolkit';
import PlayButton from '../icons/PlayButton';
import styled from '../../core/styled';
import { ECMSItem } from '../../entities/CMSItem';

export interface HelpfulTipCardProps {
  item: ECMSItem;
  onPress: (any) => void;
}

const Wrapper = styled(View)({
  width: 215,
  height: 181,
  marginLeft: 6,
  marginRight: 6,
  maxHeight: 181,
});

const ModifiedTiny = styled(Tiny)(({ theme: { colors } }) => {
  return {
    fontSize: 10,
    fontWeight: 700,
    color: colors.white,
  };
});

const ModifiedBoldTiny = styled(Tiny)(({ theme: { colors } }) => {
  return { fontSize: 10, fontWeight: 1000, color: colors.white };
});

const HelpfulTipCard = forwardRef<HTMLDivElement, HelpfulTipCardProps>(({ item, onPress }, ref) => {
  const { isHighContrast } = useEmotionTheme();
  return (
    <Wrapper ref={ref}>
      <TouchableView
        onPress={onPress}
        aria-label={item.itemData.title}
        styles={{
          marginTop: 3,
          backgroundImage: `url(${item.itemData.thumbnailURL})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '215px 175px',
          borderRadius: 12,
          opacity: item.isViewed && !isHighContrast ? 0.4 : undefined,
          height: 175,
          // dark background tint in high contrast mode to allow for text to meet contrast minimums
          backgroundColor: isHighContrast ? 'rgba(0,0,0,.3)' : undefined,
          backgroundBlendMode: isHighContrast ? 'multiply' : undefined,
        }}
      >
        <View
          style={{
            position: 'relative',
            left: 15,
            bottom: -20,
          }}
        >
          <PlayButton />
          <View>
            <View
              justify="end"
              style={{
                maxWidth: 185,
                whiteSpace: 'pre-wrap',
                height: 85,
              }}
            >
              <Large variant="largeBoldWhite">{item.itemData.title}</Large>
            </View>
            <View row justify="space-between" style={{ marginTop: 11, width: 185 }}>
              <View row>
                <ModifiedTiny>{item.itemData.subtitle}</ModifiedTiny>
                {item.isViewed && (
                  <ModifiedBoldTiny
                    style={{
                      marginLeft: 6,
                    }}
                  >
                    Watched
                  </ModifiedBoldTiny>
                )}
              </View>
              <ModifiedTiny>{item.itemData.duration}</ModifiedTiny>
            </View>
          </View>
        </View>
      </TouchableView>
    </Wrapper>
  );
});

export default HelpfulTipCard;
