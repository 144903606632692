import moment from 'moment';

const dateFormat = 'MMM D, YYYY';

const formatDate = (date) => moment(date).format(dateFormat);

export const createDateLabel = (startDate, endDate) =>
  `${formatDate(startDate)} ${endDate ? `- ${formatDate(endDate)}` : ''}`;

const superbillsUtils = {
  createDateLabel,
};

export default superbillsUtils;
