import { VoidFunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { handleCrossPlatformPDFDownload, PDFViewer } from 'ts-pdf';
import { Spinner, useWindowWidth } from '@talkspace/react-toolkit';
import { useHistory, useParams } from '@/core/routerLib';
import UserEventsSocketService from '@/clientChat/utils/UserEventsSocketService';
import { SuperbillDocCreated } from '@/utils/socket/socketTypes';
import { useModalFocusTrap } from '@/utils/ModalsContextProvider';
import { useSuperbillsState } from '../hooks/superbillsContext';
import { createDateLabel } from '../utils';
import useMutationCreateSuperbill from '../hooks/useMutationCreateSuperbill';

const API_CALL_TIMEOUT = 10 * 1000;

interface Props {
  handleTitleChange: (title: string) => void;
}

const PDFViewerScreen: VoidFunctionComponent<Props> = ({ handleTitleChange }) => {
  const { roomID } = useParams<{ roomID: string }>();
  const history = useHistory();
  const { currentSuperbill } = useSuperbillsState();
  const fileName = currentSuperbill
    ? createDateLabel(currentSuperbill.startDate, currentSuperbill.endDate)
    : '';
  const { isMobile } = useWindowWidth();
  const socketTimeout = useRef<undefined | ReturnType<typeof setTimeout>>();
  const { pauseModalFocusTrap, resumeModalFocusTrap } = useModalFocusTrap();

  const [isWaitingForSocketAnswer, setIsWaitingForSocketAnswer] = useState(false);
  const [url, setUrl] = useState(currentSuperbill?.documentURL || '');

  const { mutate: createSuperbill, isLoading, isError } = useMutationCreateSuperbill();

  const handleDownloadPress = () => {
    handleCrossPlatformPDFDownload({
      url,
      fileName,
      pauseModalFocusTrap,
      resumeModalFocusTrap,
    });
  };

  const superbillDocCreated = useCallback(
    ({ documentURL, superbillID }: SuperbillDocCreated) => {
      if (superbillID === currentSuperbill?.id) {
        if (socketTimeout.current) {
          clearTimeout(socketTimeout.current);
        }
        setIsWaitingForSocketAnswer(false);
        setUrl(documentURL);
      }
    },
    [currentSuperbill]
  );

  useEffect(() => {
    handleTitleChange(fileName);
  }, [handleTitleChange, fileName]);

  useEffect(() => {
    const userEventsSocketService = new UserEventsSocketService();
    userEventsSocketService.on('superbillDocCreated', superbillDocCreated);

    return () => {
      if (socketTimeout.current) {
        clearTimeout(socketTimeout.current);
      }
      userEventsSocketService.off('superbillDocCreated', superbillDocCreated);
      userEventsSocketService.unmount(false);
    };
  }, [history, roomID, superbillDocCreated]);

  useEffect(() => {
    if (!url && currentSuperbill) {
      setIsWaitingForSocketAnswer(true);
      createSuperbill({
        roomID,
        superbillID: `${currentSuperbill.id}`,
      });
      socketTimeout.current = setTimeout(() => {
        history.push(`/superbills/room/${roomID}/error`);
      }, API_CALL_TIMEOUT);
    }
  }, [createSuperbill, currentSuperbill, history, roomID, url]);

  useEffect(() => {
    if (isError) {
      history.push(`/superbills/room/${roomID}/error`);
    }
  }, [history, isError, roomID]);

  return isLoading || isWaitingForSocketAnswer ? (
    <Spinner />
  ) : (
    <PDFViewer
      url={url}
      fileName={fileName}
      showStar={false}
      onDownloadPress={handleDownloadPress}
      showHeader={false}
      isDownloadOnBottom={isMobile}
      iFrameDataQa="superbillsPDFView"
    />
  );
};

export default PDFViewerScreen;
