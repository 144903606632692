import { isValidPhoneNumber, getCountryCallingCode } from '@talkspace/react-toolkit/src/utils';

const formatPhoneNumber = (
  phoneNumber: string,
  countryCode: string | undefined
): string | undefined => {
  const countryCallingCode = countryCode ? getCountryCallingCode(countryCode) : undefined;
  if (countryCallingCode) {
    const formattedPhoneNumber = `+${countryCallingCode}${phoneNumber}`;
    return isValidPhoneNumber(formattedPhoneNumber) ? formattedPhoneNumber : undefined;
  }
  return undefined;
};

export default formatPhoneNumber;
