import { useEffect, useCallback } from 'react';
import { useMainState } from './mainContext';

type MaybeCleanUpFn = void | (() => void);

/*
    This hook is essentially useEffect, the main difference being it
    provides the roomIDs as the first argument to the effect callback
    while guaranteeing only a change in mainState's roomsByID object keys
    OR a change in other dependencies will reinvoke the effect callback.
*/
const useRoomsChangedEffect = (fun: (ids: number[]) => MaybeCleanUpFn, deps: any[]) => {
  const { roomsByID } = useMainState();
  const ids = Object.keys(roomsByID).sort().join(',');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const func = useCallback(fun, deps);
  useEffect(() => func(ids.split(',').map(Number)), [func, ids]);
};

export default useRoomsChangedEffect;
