import { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { View, Text } from '@talkspace/react-toolkit';

interface AccountPanelRowProps {
  label: string;
  description?: string;
  actionComponent?: JSX.Element;
}

const AccountRow = styled(View)`
  padding: 15px;
  flex-direction: row;
  @media (max-width: 375px) {
    flex-direction: column;
    padding: 7px;
  }
`;

const LabelColumn = styled(Text)({
  fontSize: 14, // 16
  fontWeight: 400,
  color: '#4A4A4A', // "#39424A"
  flex: 2,
  paddingLeft: 5,
  paddingRight: 5,
  marginBottom: 10,
});
const DescriptionColumn = styled(Text)({
  fontSize: 14, // 16
  fontWeight: 300,
  color: '#8d9093', // "#7D8993",
  flex: 7,
  paddingLeft: 5,
  marginBottom: 10,
});
const ActionColumn = styled(View)({
  color: '#8d9093', // "#7D8993",
  flex: 3,
  paddingLeft: 0,
  alignItems: 'flex-end',
});

const AccountPanelRow: FunctionComponent<AccountPanelRowProps> = ({
  label,
  description,
  actionComponent,
}) => (
  <AccountRow>
    <LabelColumn>{label}</LabelColumn>
    <DescriptionColumn>{description}</DescriptionColumn>
    <ActionColumn>{actionComponent}</ActionColumn>
  </AccountRow>
);

export default AccountPanelRow;
