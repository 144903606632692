import * as React from 'react';
import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const ArrowUpRight: React.FunctionComponent<{
  color?: string;
  height?: number;
  width?: number;
  style?: object;
}> = ({ color, width = 12, height = 12, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'upwards right arrow';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 12 12"
      {...otherProps}
    >
      <Path
        d="M9.856 4.762L7.753 2.57a.946.946 0 010-1.298l.17-.178a.855.855 0 011.245 0l3.87 4.036a.938.938 0 01.258.649v.078a.938.938 0 01-.257.649l-3.871 4.036a.855.855 0 01-1.245 0l-.17-.178a.946.946 0 010-1.297l2.103-2.193H-.78a.862.862 0 01-.622-.269.938.938 0 01-.258-.649V5.68c0-.244.093-.477.258-.65a.862.862 0 01.622-.268H9.856z"
        fill={color || colors.pink}
        transform="rotate(-45 5.818 5.818)"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default ArrowUpRight;
