import { FunctionComponent } from 'react';
import {
  View,
  TouchableView,
  Button,
  Large,
  Big,
  Tiny,
  RadioButton,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import styled from '../../../core/styled';
import { withRouter, RouteComponentProps } from '../../../core/routerLib';
import { InfoCircle } from '../icons/index';
import { webOnlyStyle } from '../../../core/styled/styleHelpers';
import { VideoCreditsAPIResponse } from '../../reducers/schedulerReducer';

const CreditOptionWrapper = styled(TouchableView)<{
  count: number;
  index: number;
}>(({ count, index, theme: { colors } }) => {
  return {
    width: 335,
    height: 100,
    borderRadius: 10,
    justifyContent: 'space-between',
    marginTop: index !== 0 ? 10 : 0,
    backgroundColor: count ? 'default' : colors.permaLinkWaterGrey,
    ...webOnlyStyle({
      border: `1px solid ${colors.a11yLinkWaterGrey}`,
    }),
  };
});

const CreditOption: FunctionComponent<{
  duration: number;
  count: number;
  isActive: boolean;
  index: number;
  handleChooseCredit: (creditDuration: number) => void;
}> = ({ duration, count, isActive, handleChooseCredit, index }) => {
  const { colors } = useEmotionTheme();
  return (
    <CreditOptionWrapper
      row
      count={count}
      index={index}
      onPress={() => {
        if (count) handleChooseCredit(duration);
      }}
    >
      <View style={{ margin: 30 }}>
        <Big>{duration} minute live session</Big>
        <Tiny>{count ? 'Session credit available' : 'No available credits'}</Tiny>
      </View>
      <View
        row
        align="center"
        justify="center"
        style={{
          margin: 30,
          backgroundColor: count ? 'default' : colors.a11yLinkWaterGrey,
        }}
      >
        {count ? <RadioButton isActive={isActive} /> : <InfoCircle />}
      </View>
    </CreditOptionWrapper>
  );
};

const CreditOptionsWrapper: FunctionComponent<{
  videoCredits: VideoCreditsAPIResponse | undefined;
  creditType: string;
  selectedCreditDuration: number | undefined;
  handleChooseCredit: (creditDuration: number) => void;
}> = ({ videoCredits, creditType, selectedCreditDuration, handleChooseCredit }) => (
  <View style={{ marginTop: 40 }}>
    {videoCredits &&
      Object.entries(videoCredits)
        .filter(([creditString]) => creditString.indexOf(creditType) >= 0)
        .map(([creditString, count], index) => {
          const duration: number = parseInt(creditString.split(' ')[0], 10);

          return (
            <CreditOption
              duration={duration}
              count={count}
              index={index}
              key={creditString}
              isActive={duration === selectedCreditDuration}
              handleChooseCredit={handleChooseCredit}
            />
          );
        })}
  </View>
);

const SchedulerSelectCredit: FunctionComponent<
  {
    videoCredits: VideoCreditsAPIResponse | undefined;
    selectedCreditDuration: number | undefined;
    handleChooseCredit: (creditDuration: number) => void;
  } & RouteComponentProps
> = ({ videoCredits, selectedCreditDuration, handleChooseCredit, history }) => (
  <View justify="center" align="center">
    <Large variant="largeDarkGrey" style={{ width: 366, textAlign: 'center', marginTop: 3 }}>
      Based on your available live session credits, please select the duration you would like to
      have.
    </Large>
    <CreditOptionsWrapper
      videoCredits={videoCredits}
      creditType="psychiatry"
      selectedCreditDuration={selectedCreditDuration}
      handleChooseCredit={handleChooseCredit}
    />
    <Button
      onPress={() => history.push('/scheduler/select-timeslot')}
      style={{ width: 335, marginTop: 81, fontWeight: 'bold' }}
      text="Continue"
    />
  </View>
);

export default withRouter(SchedulerSelectCredit);
