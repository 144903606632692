import { useEffect } from 'react';
import {
  View,
  ExtraHuge,
  Large,
  Button,
  HandEnvelope,
  FullScreenWrapper,
  Spinner,
} from '@talkspace/react-toolkit';
import { routePromise } from 'ts-frontend/helpers/routePromise';
import { useFlags } from 'launchDarkly/FlagsProvider';
import moment from 'moment';
import useQueryTherapistDetails from 'ts-frontend/hooks/useQueryTherapistDetails';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import styled from '../../core/styled';
import { useHistory, useLocation, useParams } from '../../core/routerLib';
import { getUserData } from '../../auth/helpers/token';
import { trackAsyncSessionStartedPrompt } from '../../utils/analytics/events';

const StyledView = styled(View)({
  maxWidth: 335,
  textAlign: 'center',
  justifyContent: 'space-between',
  align: 'center',
});

const AsyncSessionStartedContainer = () => {
  const { state } = useLocation<{ startDate: string; therapistID: number }>();
  const startDate = moment(state?.startDate).format('MMM DD, YYYY');
  const remainingDays = moment(startDate).add(8, 'days').diff(new Date(), 'days');
  const history = useHistory();
  const { roomID } = useParams<{ roomID: string | undefined }>();
  const { brandRefreshChanges } = useFlags();

  const { data: therapistDetails } = useQueryTherapistDetails(state?.therapistID);

  const { id: userID } = getUserData();
  const { data: businessLine, isLoading: isLoadingBusinessLine } = useQueryBusinessLine(
    Number(roomID),
    userID
  );

  useEffect(() => {
    if (userID && roomID) {
      const dateViewed = new Date();

      trackAsyncSessionStartedPrompt({
        userID,
        roomID: Number(roomID),
        daysSinceStartOfSession: moment(dateViewed).diff(startDate, 'days'),
      });
    }
  }, [userID, roomID, startDate]);

  const navigateToTheRoom = async () => {
    // await queryClient.refetchQueries(onboardingQueryKey({ roomID, userID }), { exact: true });
    // TODO use invalidate queries instead, and make sure to clear useNavShellData onboarding data
    // so we dont get stuck in an onboarding loop
    routePromise(`/room/${roomID}`);
  };

  const handleMessageMyProviderClick = () => {
    navigateToTheRoom();
  };

  const handleCloseButtonClick = () => {
    navigateToTheRoom();
  };

  if (isLoadingBusinessLine) return <Spinner />;

  let content =
    remainingDays <= 3 ? (
      <>
        <ExtraHuge variant="extraHugeBranded" style={{ marginBottom: 12 }}>
          Your first Messaging Session is in progress
        </ExtraHuge>
        <Large variant="largeDarkGrey" style={{ marginBottom: 30 }}>
          Your session started when you were matched with {therapistDetails?.info.first_name} on{' '}
          {startDate}. You have {remainingDays} days remaining in this messaging session.
        </Large>
        <Large variant="largeDarkGrey" style={{ marginBottom: 30 }}>
          You can start your next messaging session anytime after this session ends.
        </Large>
      </>
    ) : (
      <>
        <ExtraHuge variant="extraHugeBranded" style={{ marginBottom: 12 }}>
          Welcome to your first Messaging Session
        </ExtraHuge>
        <Large variant="largeDarkGrey" style={{ marginBottom: 30 }}>
          Your session started when you were matched with {therapistDetails?.info.first_name} on{' '}
          {startDate}. You have {remainingDays} days remaining in this messaging session.
        </Large>
      </>
    );

  if (businessLine?.isBH) {
    content = (
      <>
        <ExtraHuge variant="extraHugeBranded" style={{ marginBottom: 12 }}>
          Welcome to your first Messaging Session
        </ExtraHuge>
        <Large variant="largeDarkGrey" style={{ marginBottom: 30 }}>
          Your session started when you were matched with {therapistDetails?.info.first_name} on{' '}
          {startDate}. A messaging session usually consists of about a week of back-and-forth
          messaging with your provider.
        </Large>
      </>
    );
  }

  return (
    <FullScreenWrapper
      goBack={history.goBack}
      showBackButton={false}
      showCloseButton
      onCloseButtonClick={handleCloseButtonClick}
      newLogo={brandRefreshChanges}
    >
      <StyledView>
        <View align="center" style={{ marginBottom: 60 }}>
          <HandEnvelope />
        </View>
        {content}
        <Button
          dataQa="startAMessagingSessionButton"
          disabled={!roomID}
          text="Message my provider"
          onClick={handleMessageMyProviderClick}
          style={{ width: '100%', marginBottom: 24 }}
        />
      </StyledView>
    </FullScreenWrapper>
  );
};

export default AsyncSessionStartedContainer;
