import { FunctionComponent, useState } from 'react';
import { useStripeTs } from 'stripe/stripeContext';
import { View, ExtraHuge, BaseButton, Standard } from '@talkspace/react-toolkit';
import { StripeLinkCheckoutForm } from 'checkout';
import styled from '../../../core/styled/styled';

interface Props {
  isEditMode: boolean;
  email: string;
  updatePaymentDetails: (cardToken: string) => void;
  onLoadStripeLink: () => void;
  dispatchPaymentMethodError: (error: any) => void;
  onCancelPress: () => void;
  isLinkLoaded: boolean;
}

const HeaderWrapper = styled(View)({
  textAlign: 'left',
  marginBottom: 20,
  paddingLeft: 4,
});

const CancelButton = styled(BaseButton)(({ theme: { colors } }) => {
  return { marginTop: 10, marginBottom: 50, padding: 0, backgroundColor: colors.white };
});

const UpdatePaymentDetailsModalWithLink: FunctionComponent<Props> = ({
  isEditMode,
  email,
  updatePaymentDetails,
  onLoadStripeLink,
  isLinkLoaded,
  dispatchPaymentMethodError,
  onCancelPress,
}) => {
  const stripe = useStripeTs();
  const [linkElementsReady, setLinkElementsReady] = useState(false);
  const action = isEditMode ? 'Update' : 'Add';

  const stripeElementFinished = () => {
    setLinkElementsReady(true);
  };

  return (
    <View>
      {isLinkLoaded && linkElementsReady && (
        <HeaderWrapper>
          <ExtraHuge>{action} payment details</ExtraHuge>
        </HeaderWrapper>
      )}
      <StripeLinkCheckoutForm
        onError={dispatchPaymentMethodError}
        onSubmit={updatePaymentDetails}
        onLoadStripeLink={onLoadStripeLink}
        email={email}
        stripe={stripe}
        submitText="Save"
        isLinkLoaded={isLinkLoaded}
        isProcessing={false}
        stripeElementFinished={stripeElementFinished}
      />
      {isLinkLoaded && linkElementsReady && (
        <CancelButton onPress={onCancelPress}>
          <Standard variant="standardDarkGrey">Cancel</Standard>
        </CancelButton>
      )}
    </View>
  );
};

export default UpdatePaymentDetailsModalWithLink;
