import { FunctionComponent, useCallback } from 'react';
import { useWizardState } from 'stepWizard';
import { RouteComponentProps, withRouter } from '@/core/routerLib';
import SwitchSuccess from '../components/SwitchSuccess';
import { State } from '../reducers/switchWizardState';

const SwitchSuccessContainer: FunctionComponent<RouteComponentProps> = ({ history }) => {
  const { roomID } = useWizardState<State>();
  const handleOnContinuePress = useCallback(() => {
    history.push('/switch-provider/review', { roomID });
  }, [roomID, history]);

  return <SwitchSuccess onPress={handleOnContinuePress} />;
};

export default withRouter(SwitchSuccessContainer);
