import { FunctionComponent, useEffect } from 'react';
import WelcomeBack from '../components/WelcomeBack/WelcomeBack';
import roomReactivationData from '../utils/previousPrimaryTherapistsAvailable';
import { getUserData } from '../../auth/helpers/token';
import { trackTalkspacePageView } from '../../utils/analytics/events';

const WelcomeBackContainer: FunctionComponent<{ roomID?: number; isCTRoom?: boolean }> = ({
  roomID,
  isCTRoom,
}) => {
  const {
    lastActiveRoomID,
    lastActiveRoomType,
    previousPrimaryTherapistsAvailable,
    skipPayment,
    authProvider,
    hasOnlyPlaceholderProviders,
  } = roomReactivationData.ReadCache(getUserData().id, roomID);

  useEffect(() => {
    const flow = isCTRoom ? 'CT' : 'PT';
    trackTalkspacePageView('Reactivation', flow, { Flow: flow });
  }, [isCTRoom]);

  return (
    <WelcomeBack
      previousPrimaryTherapistsAvailable={previousPrimaryTherapistsAvailable}
      hasOnlyPlaceholderProviders={hasOnlyPlaceholderProviders}
      lastActiveRoomID={lastActiveRoomID}
      lastActiveRoomType={lastActiveRoomType}
      roomID={roomID}
      isCTRoom={isCTRoom}
      skipPayment={skipPayment}
      authProvider={authProvider}
    />
  );
};

export default WelcomeBackContainer;
