import { View } from '@talkspace/react-toolkit';
import styled from '@/core/styled';

interface StickyProps {
  ref?: React.Ref<React.ReactNode>;
  stickHeader?: boolean;
  style?: {};
}

const StickyHeader = styled(View)<StickyProps>(
  ({ theme: { window, colors }, stickHeader, style }) => {
    const { isMobile } = window;
    return {
      position: 'sticky',
      top: isMobile ? 0 : -22,
      marginLeft: isMobile ? 0 : -22,
      marginRight: isMobile ? 0 : -22,
      alignSelf: 'stretch',
      alignItems: 'center',
      backgroundColor: colors.white,
      paddingTop: 20,
      paddingBottom: 20,
      boxShadow: stickHeader ? '0 1px 0 0 rgba(0,0,0,0.06)' : 'none',
      zIndex: 3,
      ...style,
    };
  }
);

export default StickyHeader;
