import * as React from 'react';
import Svg, { Path } from 'svgs';

import { useEmotionTheme } from '../../core/styled';

function SearchSpyglass() {
  const { colorRoles } = useEmotionTheme();
  const titleText = 'Phone with notification';
  return (
    <Svg
      width={40}
      height={42}
      viewBox="0 0 40 42"
      fill="none"
      title={titleText}
      aria-label={titleText}
    >
      <Path
        d="M11.874 3.005c-1.744.714-2.05.836-2.142.856-7.582 4.22-8.6 15.077-1.588 20.306 7.95 5.954 19.405.135 19.23-9.863-.15-7.949-7.86-13.624-15.5-11.299zM8.726 23.387C2.137 18.473 3.098 8.406 10.17 4.745l2.027-.823C19.107 1.83 26.262 6.957 26.4 14.32c.16 9.203-10.377 14.533-17.675 9.066z"
        fill={colorRoles.icons.iconBrandDefault}
      />
      <Path
        d="M39.388 37.863c-3.237-3.583-6.977-7.522-10.154-11.041a.476.476 0 00-.255-.14c.198-.602.288-1.27.22-1.828-.116-.956-.818-1.732-1.656-1.874C34.242 13.237 27.11-.239 15.165.003a14.668 14.668 0 00-13.48 9.498C.367 12.296-.11 15.491.457 18.534c.933 5.005 4.648 9.396 9.428 11.146 3.95 1.446 8.507 1.04 12.196-.96.358.839 1.347 1.24 2.273 1.281.444.61 1.123 1.31 1.44 1.633 1.744 1.773 3.19 3.718 4.935 5.491.773.786 1.518 1.6 2.318 2.357 2.33 2.202 3.428 2.62 4.618 2.5.745-.076.932-.232.804-.426a1.46 1.46 0 00.458-.283c.731-.702 1.239-2.547.46-3.41zM15.182.976C22.642.816 29 6.798 29.145 14.437c.185 10.204-10.401 16.731-18.974 12.92C1.204 23.384-.65 12.924 4.669 6.178 8.995.72 15.247.96 15.182.977zm23.072 39.595c-.35.338-1.6.156-2.01-.298-3.095-3.429-6.631-7.283-9.794-10.783.87-.493 1.61-1.096 2.129-1.942 3.19 3.531 6.909 7.448 10.087 10.968.315.349.065 1.597-.412 2.055zM28.268 3.925l1.74-2.496a.486.486 0 10-.798-.556l-1.74 2.496a.486.486 0 10.799.556z"
        fill={colorRoles.icons.iconBrandDefault}
      />
      <Path
        d="M30.378 6.006l2.404-1.935a.486.486 0 10-.61-.757l-2.405 1.935a.487.487 0 00.61.757zM31.073 8.821l1.954-.109a.487.487 0 00.459-.512.497.497 0 00-.513-.46l-1.955.11a.487.487 0 00.055.971zM15.217 11.778c1.496 0 3.145-.968 3.654-2.394.448-1.25.187-2.478-.713-3.368-1.987-1.963-6.022-1.511-6.321 2.17-.143 1.762 1.354 3.592 3.38 3.592zm-2.41-3.513c.326-4.037 6.33-2.516 5.148.791-.395 1.104-1.891 1.918-3.122 1.72-1.172-.186-2.119-1.36-2.026-2.51zM7.74 20.35l14.774.107c.374 0 .589-.412.497-.7-.488-1.54-1.343-3.664-2.648-5.041-2.938-3.102-7.461-2.34-10.138.187-1.28 1.209-2.298 2.87-2.942 4.807a.487.487 0 00.458.64zm11.916-4.966c.832.878 1.617 2.319 2.237 4.096l-13.457-.098c2.398-6.125 8.248-7.134 11.22-3.998z"
        fill={colorRoles.icons.iconBrandDefault}
      />
    </Svg>
  );
}

export default SearchSpyglass;
