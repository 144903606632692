import { useEffect, useState } from 'react';
import { View, Button, ExtraHuge, useEmotionTheme, Link } from '@talkspace/react-toolkit';
import apiHelper from '@/core/api/apiHelper';

const FLOW_133_ISRAEL = 133;

const IsraelSupportContainer = ({ ...props }) => {
  const [voucherCode, setVoucherCode] = useState('');
  const { colors } = useEmotionTheme();

  const getVoucher = async () => {
    const response = await fetch(
      `${apiHelper().quickMatchApiEndpoint}/api/v2/accessCodeBy/keywordMetadata`,
      {
        method: 'post',
        body: JSON.stringify({
          keyword: 'ilsupport2023',
          email: 'dummyemail@mailinator.com',
          flowId: FLOW_133_ISRAEL,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const responseData = await response.json();
    setVoucherCode(responseData?.data?.accessCode);
  };

  useEffect(() => {
    getVoucher();
  }, []);

  const redirectTo133 = () => {
    window.location.href = `${
      apiHelper().quickMatchWebEndpoint
    }/flow/133?qmPartnerCode=${voucherCode}`;
  };

  const SubmitButton = ({ ...submitProps }) => (
    <Button
      style={{
        fontFamily: 'Roboto',
        backgroundColor: colors.green,
        fontSize: 19,
        fontWeight: 'bold',
        width: 335,
        minHeight: 55,
        borderRadius: 10,
        display: 'flex',
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.white,
        letterSpacing: 0.74,
        textAlign: 'center',
      }}
      onPress={redirectTo133}
      text={submitProps.text}
      isLoading={!voucherCode}
    />
  );

  return (
    <View align="center">
      <ExtraHuge style={{ marginBottom: 10, textAlign: 'center' }}>
        תמיכה נפשית לישראלים בחינם
      </ExtraHuge>
      <div style={{ textAlign: 'center', direction: 'rtl', width: '70%', margin: '3%' }}>
        <div>
          טוקספייס (Talkspace), חברה אמריקאית-ישראלית העוסקת מזה שנים בתמיכה נפשית אונליין, מעמידה
          לרשות הציבור ‏הרחב שירות של ייעוץ ותמיכה נפשית על ידי מומחים בחינם. ‏
        </div>
        <br />
        <div>
          ישראלים החווים קושי נפשי נוכח המצב, מוזמנים להירשם בחינם למערכת הייעודית שפותחה על ידי
          ‏טוקספייס במיוחד עבורם. המערכת תקצה לכל משתמש איש או אשת מקצוע מתחומי הפסיכיאטריה,
          ‏הפסיכולוגיה, העבודה הסוציאלית וטיפול באמצעות אומנות, אשר יעניקו לו שירות אישי ודיסקרטי
          <b> בעברית בחינם</b>.
        </div>
        <br />
        <div>
          ‏התאמת איש או אשת המקצוע תבוצע בתוך מספר שעות מההרשמה, לפי נתוני וצרכי המשתמש, והשירות
          ‏יכלול עד <b>ארבעה</b> מפגשים שיתקיימו באמצעות הודעות כתובות, שיחות וידאו או שיחות קוליות
          – לפי צרכי ‏המשתמש והעדפתו.
        </div>
        <br />
        <div> ‏ לתמיכה טכנית ניתן לשלוח מייל לכתובת</div>
        <div>
          <Link
            href="mailto:support-il@talkspace.com"
            style={{ textDecoration: 'none' }}
            target="_blank"
            stopPropagation
            roundedFocusStyle
            primaryColor={colors.green}
          >
            support-il@talkspace.com
          </Link>
        </div>
      </div>
      <SubmitButton text="התחל כאן" />
    </View>
  );
};

export default IsraelSupportContainer;
