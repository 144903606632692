import { ExtraBig, Large, Link, useEmotionTheme, View } from '@talkspace/react-toolkit';
import { FunctionComponent } from 'react';
import styled from '@/core/styled';

const Container = styled(View)({
  width: 355,
  padding: 25,
  margin: 30,
  display: 'flex',
  flexDirection: 'row',
  background: '#FFFFFF',
  boxShadow: '0 3px 11px -3px rgba(16,109,149,0.30)',
  borderRadius: '12px',
});

const ImageContainer = styled(View)({
  width: 91,
  height: 91,
  marginRight: 22,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const TextContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const SSOLoginProviderButton: FunctionComponent<{
  href: string;
  Logo: FunctionComponent<any>;
  name: string;
}> = ({ href, Logo, name }) => {
  const { colors } = useEmotionTheme();

  return (
    <Container>
      <ImageContainer>
        <Logo />
      </ImageContainer>
      <TextContainer>
        <ExtraBig>{name}</ExtraBig>
        <Link href={href} style={{ marginLeft: 0, marginRight: 0, marginTop: 5 }}>
          <Large inline style={{ color: colors.green, fontWeight: 700 }}>
            Continue
          </Large>
        </Link>
      </TextContainer>
    </Container>
  );
};

export default SSOLoginProviderButton;
