import Svg, { G, Rect, Circle, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const TimeOff = (props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'time off';
  return (
    <Svg
      width="18px"
      height="19px"
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 18 19"
      {...props}
    >
      <G
        transform="translate(-430 -623) translate(430 621) translate(0 2)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Rect stroke={colors.pink} strokeWidth={2} x={1} y={3} width={13} height={12} rx={3.5} />
        <Circle stroke={colors.white} strokeWidth={1.5} fill={colors.pink} cx={12} cy={13} r={5} />
        <Path
          d="M4 1a.5.5 0 00-.5.5v3a.5.5 0 001 0v-3A.5.5 0 004 1zm7 0a.5.5 0 00-.5.5v3a.5.5 0 101 0v-3A.5.5 0 0011 1z"
          stroke={colors.pink}
        />
        <Path
          d="M13.297 13l.684.684a.917.917 0 11-1.297 1.297L12 14.297l-.684.684a.917.917 0 11-1.297-1.297l.684-.684-.684-.684a.917.917 0 111.297-1.297l.684.684.684-.684a.917.917 0 111.297 1.297l-.684.684z"
          fill={colors.white}
        />
      </G>
    </Svg>
  );
};

export default TimeOff;
