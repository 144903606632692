import { Col, Grid, Row, Spinner, View } from '@talkspace/react-toolkit';
import TeenspaceCommunity from '../../community/containers/TeenspaceCommunity';
import useTeenspaceOnboarding from '../../community/hooks/useTeenspaceOnboarding';

const TeenspaceScreen = () => {
  const { isLoading } = useTeenspaceOnboarding();

  return (
    <Grid fluid rowSpacing="space200">
      <Row>
        <Col xs={4} sm={8}>
          {!isLoading ? (
            <TeenspaceCommunity />
          ) : (
            <View align="center" justify="center" style={{ height: '100vh' }}>
              <Spinner />
            </View>
          )}
        </Col>
      </Row>
    </Grid>
  );
};

export default TeenspaceScreen;
