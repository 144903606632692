import { Fragment, FunctionComponent } from 'react';
import { ERoom } from 'ts-frontend/entities/Room';
import RoomSummaryCard from '../RoomSummaryCard';
import { useMainState } from '../../hooks/mainContext';

const sortRoomsByLastMessageDate = (a: ERoom, b: ERoom): number => {
  if (!a.lastMessage) {
    return 1;
  }
  if (!b.lastMessage) {
    return -1;
  }
  return +b.lastMessage.createdAt - +a.lastMessage.createdAt;
};

const RoomSummaryList: FunctionComponent<{
  rooms: ERoom[];
  activeRoomID: number;
  isAcking: boolean;
  isChatHidden?: boolean;
}> = ({ rooms, activeRoomID, isAcking, isChatHidden }) => {
  const { therapistsByID } = useMainState();
  const roomList = rooms.sort(sortRoomsByLastMessageDate).map((room, idx) => {
    const { therapistID } = room;
    const therapist = therapistsByID[therapistID];
    return (
      <Fragment key={room.roomID}>
        <RoomSummaryCard
          isChatHidden={isChatHidden}
          room={room}
          therapist={therapist}
          isActive={room.roomID === activeRoomID && rooms.length > 1}
          isAcking={isAcking}
          hasBottomBorder={idx !== rooms.length - 1 && rooms.length > 1}
        />
      </Fragment>
    );
  });
  return <>{roomList}</>;
};

export default RoomSummaryList;
