const isSubArray = (subArray: string[], array: string[]) => {
  if (subArray.length > array.length) return false;

  for (let i = 0; i < array.length; i += 1) {
    for (let j = 0; j < subArray.length; j += 1) {
      if (j === subArray.length - 1 && array[i + j] === subArray[j]) {
        // match
        return true;
      }

      if (array[i + j] !== subArray[j]) {
        break;
      }
    }
  }
  return false;
};

export default isSubArray;
