import { TalkspaceIonic, NativeDispatcherData } from '@talkspace/ionic-talkspace';
import { useEffect, useRef, useState } from 'react';
import { useIonicEffect } from 'ts-ionic';
import { getMixpanelDistinctID } from '../utils/analytics/eventTracker';
import { getFlowData, getRegistrationCookie } from '../utils/attributionHelper';
import useQueryEntrypoint from './useQueryEntrypoint';

const attributionPathWhitelist = [
  '/',
  '/login',
  '/signup/?.*',
  '/login/sso/callback',
  '/login/sso/callback/*',
];

function passesPathnameWhitelist(path: string) {
  for (const whitelistedPathname of attributionPathWhitelist) {
    if (path.match(new RegExp(`^${whitelistedPathname}$`))) {
      return true;
    }
  }
  return false;
}

// Prefer named exports for hooks
// eslint-disable-next-line import/prefer-default-export
export function useAttribution() {
  // Use ref to prevent it from changing on route changes.
  const pathnameRef = useRef(window.location.pathname);
  const entrypointParamsRef = useRef(
    getFlowData({ removeCurrentReferrer: true, ignoreCookie: true })
  );
  const [distinctID, setDistinctID] = useState<string | undefined>(undefined);
  const [nativeAttribution, setNativeAttribution] = useState<NativeDispatcherData>({});
  useEffect(() => {
    getMixpanelDistinctID().then(setDistinctID);
  }, []);

  useIonicEffect(() => {
    TalkspaceIonic.getEntrypointAttributes()
      .then(setNativeAttribution)
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.warn('Failed to get native entrypoint attributes', e);
      });
  }, []);

  const enableQuery = !!(
    passesPathnameWhitelist(pathnameRef.current) &&
    distinctID &&
    // Don't attribute the user if they already have a registration cookie
    !getRegistrationCookie()?.flowData
  );
  return useQueryEntrypoint(
    { attribution: entrypointParamsRef.current, distinctID, nativeAttribution },
    enableQuery
  );
}
