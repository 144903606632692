import {
  Big,
  Button,
  Small,
  SparklingCalendar,
  Spinner,
  View,
  useEmotionTheme,
} from '@talkspace/react-toolkit';

import { useLocation } from 'react-router';
import { useTranslation, Trans } from '@talkspace/i18n';
import BaseWrapper from './BaseWrapper';

interface Props {
  isLoading?: boolean;
  formattedStartTime?: string;
  isBH?: boolean;
  isEAP?: boolean;
  isBookingTooSoon?: boolean;
  isBookingConfirmed?: boolean;
  onPressCancelSession: () => void;
  onPressRescheduleSession: () => void;
}
const CancelSession = ({
  isLoading,
  formattedStartTime,
  isBH,
  isEAP,
  isBookingTooSoon,
  isBookingConfirmed,
  onPressCancelSession,
  onPressRescheduleSession,
}: Props) => {
  const { colors } = useEmotionTheme();
  const { pathname } = useLocation();

  const canReschedule =
    !pathname.includes('ineligible') && (!isBookingTooSoon || !isBookingConfirmed);

  const { t: tBookingScreen } = useTranslation('bookingScreen');

  return (
    <BaseWrapper>
      <>
        <SparklingCalendar />
        <Big style={{ marginTop: 24 }}>
          {isLoading ? (
            <Spinner style={{ margin: '10px 0' }} />
          ) : (
            <Trans>Are you sure you want to cancel your session for {formattedStartTime}?</Trans>
          )}
        </Big>
        <View style={{ marginTop: 16 }}>
          {isLoading ? (
            <Spinner style={{ margin: '30px 0' }} />
          ) : (
            <>
              {isBookingConfirmed && (
                <>
                  {isBH && isBookingTooSoon && (
                    <>
                      <Trans>
                        <Small>
                          <Small inline style={{ fontWeight: 'bold' }}>
                            Cancelling now is considered a late cancellation
                          </Small>{' '}
                          (24 hours or less) and will result in a fee for the cost of service, which
                          can be{' '}
                          <Small inline style={{ fontWeight: 'bold' }}>
                            up to $175.
                          </Small>{' '}
                          If you use credits for sessions, cancelling now will also result in a{' '}
                          <Small inline style={{ fontWeight: 'bold' }}>
                            redeemed credit.
                          </Small>
                        </Small>
                        <Small style={{ marginTop: 12 }}>
                          Keep in mind for next time that you can cancel or reschedule for free up
                          until 24 hours before your scheduled session start time.
                        </Small>
                      </Trans>
                    </>
                  )}
                  {isBH && !isBookingTooSoon && (
                    <>
                      <Small>
                        {tBookingScreen(
                          'cancellation.youCan',
                          'You can cancel or reschedule for free up until 24 hours before your scheduled appointment start time.'
                        )}
                      </Small>
                      <Small style={{ marginTop: 12 }}>
                        {tBookingScreen(
                          'cancellation.keep',
                          'Keep in mind that late cancellations (24 hours or less) and no-shows result in a fee for the cost of service, which can be up to $175. If you use credits for sessions, late cancellations and no-shows will also result in a redeemed credit.'
                        )}
                      </Small>
                    </>
                  )}
                  {!isBH && !isEAP && isBookingTooSoon && (
                    <>
                      <Trans>
                        <Small>
                          <Small inline style={{ fontWeight: 'bold' }}>
                            Cancelling now is considered a late cancellation
                          </Small>{' '}
                          (24 hours or less) and will result in a{' '}
                          <Small inline style={{ fontWeight: 'bold' }}>
                            redeemed credit.
                          </Small>
                        </Small>
                        <Small style={{ marginTop: 12 }}>
                          Keep in mind for next time that you can cancel or reschedule your session
                          for free up until 24 hours before your scheduled session start time.
                        </Small>
                      </Trans>
                    </>
                  )}
                  {!isBH && !isEAP && !isBookingTooSoon && (
                    <>
                      <Small>
                        {tBookingScreen(
                          'cancellation.noWorries',
                          'No worries. You can cancel or reschedule for free up until 24 hours before your scheduled session start time.'
                        )}
                      </Small>
                      <Small style={{ marginTop: 12 }}>
                        {tBookingScreen(
                          'cancellation.redeemed',
                          'Keep in mind that late cancellations (24 hours or less) and no-shows result in a redeemed credit.'
                        )}
                      </Small>
                    </>
                  )}
                  {isEAP && (
                    <Small style={{ marginTop: 12 }}>
                      {tBookingScreen(
                        'cancellation.weAsk',
                        "No worries. You can cancel or reschedule for free anytime before your scheduled session start time. We ask that you please keep in mind that your provider's time is valuable and they are not paid for late cancellations."
                      )}
                    </Small>
                  )}
                </>
              )}
              {!isBookingConfirmed && (
                <>
                  <Small>
                    {tBookingScreen(
                      'cancellation.since',
                      'Since your session has not been confirmed, you can cancel or reschedule this session for free.'
                    )}
                  </Small>
                </>
              )}
            </>
          )}
        </View>
        <Button
          text={tBookingScreen('titles.cancelSession', 'Cancel session')}
          dataQa="cancelSession"
          onPress={onPressCancelSession}
          style={{
            marginTop: 40,
            backgroundColor:
              isBookingTooSoon && isBookingConfirmed && !isEAP
                ? colors.torchRed
                : colors.permaTalkspaceDarkGreen,
          }}
        />
        {canReschedule && (
          <Button
            isSecondary
            text={tBookingScreen('titles.reschedule', 'Reschedule session')}
            dataQa="rescheduleSession"
            onPress={onPressRescheduleSession}
            style={{
              marginTop: 12,
              color: colors.permaTalkspaceDarkGreen,
              backgroundColor: undefined,
            }}
          />
        )}
      </>
    </BaseWrapper>
  );
};

export default CancelSession;
