import { FlowVariant } from 'ts-frontend/types';
import { View, useWindowWidthState } from '@talkspace/react-toolkit';

import InRoomSchedulingWrapper from '../../components/InRoomSchedulingWrapper';
import useAllowClosing from '../hooks/useAllowClosing';

interface Props {
  dismissOnboarding: () => void;
  fetchOnboarding?: () => Promise<any>;
  flowVariant?: FlowVariant;
}

const BookSession = ({
  dismissOnboarding,
  fetchOnboarding = () => Promise.resolve(),
  flowVariant,
}: Props) => {
  const { isMobile } = useWindowWidthState();
  const allowClosing = useAllowClosing();

  return (
    <View
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: isMobile ? 75 : 0,
        left: 0,
      }}
    >
      <View style={{ flex: 1, overflowY: 'auto' }}>
        <InRoomSchedulingWrapper
          isOnboarding
          isCloseable={allowClosing}
          dismissOnboarding={dismissOnboarding}
          fetchOnboarding={fetchOnboarding}
          flowVariant={flowVariant}
        />
      </View>
    </View>
  );
};
export default BookSession;
