import { ErrorScreen } from '@talkspace/react-toolkit/src/designSystems/organisms';
import { CalendarError } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import { useIsModal } from '../../utils/ModalsContextProvider';

interface Props {
  onPressUpdatePayment: (roomID: number) => void;
  onPressClose: () => void;
  roomID: number;
}
const ScheduleChooseProviderUpdatePayment = ({
  roomID,
  onPressClose,
  onPressUpdatePayment,
}: Props) => {
  const isModal = useIsModal();
  return (
    <ErrorScreen
      headerTitle="Book a session"
      illustration={<CalendarError />}
      title="Update your payment details"
      description="Your credit card has expired. Once you update your payment details, you’ll be able to book your next session."
      buttonText="Update payment details"
      onClose={onPressClose}
      onPressButton={() => {
        onPressUpdatePayment(roomID);
      }}
      safeAreaVariant={isModal ? 'none' : 'setHeightAndAddTop'}
    />
  );
};
export default ScheduleChooseProviderUpdatePayment;
