import { FunctionComponent } from 'react';
import Svg, { G, Path, Rect } from 'svgs';

const ObjectivesIcon: FunctionComponent<{
  color?: string;
  height?: number;
  width?: string;
  style?: object;
}> = ({ width = 30, height = 30, color = '#6569E8', ...otherProps }) => (
  <Svg width={width} height={height} viewBox="0 0 30 30" {...otherProps}>
    <G
      transform="translate(-7 -7) rotate(-45 35.485 13.586)"
      fill={color}
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    >
      <Path
        d="M14 15.5V6H4.5a1.5 1.5 0 010-3H17v12.5a1.5 1.5 0 01-3 0z"
        transform="rotate(45 10 10)"
      />
      <Path
        d="M24 15.5V6h-9.5a1.5 1.5 0 010-3H27v12.5a1.5 1.5 0 01-3 0z"
        transform="rotate(45 20 10)"
      />
      <Path
        d="M34 15.5V6h-9.5a1.5 1.5 0 010-3H37v12.5a1.5 1.5 0 01-3 0z"
        transform="rotate(45 30 10)"
      />
      <Rect x={1} y={8.5} width={17} height={3} rx={1.5} />
    </G>
  </Svg>
);

export default ObjectivesIcon;
