import { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Button,
  BaseButton,
  TextDS,
  View,
  useEmotionTheme,
  languagesList,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import {
  FullWidthContainer,
  ColumnContainer,
  TitlesView,
  StickyBottomContainer,
} from 'stepWizard/components/StepWizard/StepWizardStyles';
import { useHistory } from 'react-router';
import { ThumbsUp as ThumbsUpIllustration } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import CircleUnderlay from '@talkspace/react-toolkit/src/designSystems/components/CircleUnderlay';
import { useWizardState } from 'stepWizard';
import { states } from '@talkspace/configs';
import capitalize from 'lodash/capitalize';
import useQueryVideoCreditOffers from 'inRoomScheduling/hooks/useQueryVideoCreditOffers';
import { useTranslation } from '@talkspace/i18n';
import { trackCTAClick } from 'ts-analytics/mixpanel/events';
import { useInRoomSchedulingState, useInRoomSchedulingActions } from 'inRoomScheduling';
import MatchPreferenceRow from './MatchPreferenceRow';
import { State } from '../reducers/switchWizardState';

const MatchPreferenceSummary: FunctionComponent<{
  handleStartButton: () => void;
}> = ({ handleStartButton }) => {
  const { spacing } = useEmotionTheme();
  const history = useHistory();
  const titlesView = useRef<HTMLDivElement>(null);
  const { isMobile } = useWindowWidthState();

  const { t: tSwitchWizard } = useTranslation('switchWizard');

  const {
    roomID,
    countryState,
    presentingProblemsOptions,
    existingPreferences,
    clientUserID,
    therapistGender,
  } = useWizardState<State>();
  const { data: videoCreditOffers } = useQueryVideoCreditOffers({ roomID });
  const {
    dispatchGetSubscriptionsAndCreditOptions,
    dispatchSetSelectedBookingDuration,
    dispatchSetSelectedCreditOption,
  } = useInRoomSchedulingActions();
  const { creditOptions } = useInRoomSchedulingState();

  useEffect(() => {
    dispatchGetSubscriptionsAndCreditOptions(clientUserID, roomID);
  }, [dispatchGetSubscriptionsAndCreditOptions, clientUserID, roomID]);

  // Set default values for inRoomScheduling
  useEffect(() => {
    if (creditOptions?.length) {
      dispatchSetSelectedBookingDuration(creditOptions[0].creditMinutes);
      dispatchSetSelectedCreditOption(creditOptions[0]);
    }
  }, [creditOptions, dispatchSetSelectedBookingDuration, dispatchSetSelectedCreditOption]);

  const onPressConfirmPreferences = useCallback(() => {
    trackCTAClick('Review Match Preferences Skip', 'switch-wizard', { roomID });
    history.push('/switch-provider/therapist-matches', { roomID: Number(roomID) });
  }, [roomID, history]);

  const onPressUpdatePreferences = useCallback(() => {
    trackCTAClick('Review Match Preferences Update', 'switch-wizard', { roomID });
    handleStartButton();
  }, [handleStartButton, roomID]);

  const [, clientState] = countryState?.split('-') || ['', ''];
  const preferenceClientState: string = states[clientState];

  const preferenceFocusAreas = useMemo(() => {
    if (!existingPreferences || !presentingProblemsOptions?.length) return '';
    const focusAreaArr = (existingPreferences.fieldsOfExpertise as number[]).map((field) => {
      const option = presentingProblemsOptions.find(
        (presentingProblem) => presentingProblem.value === field
      );
      return option ? option?.label : '';
    });
    return focusAreaArr.join('<br>');
  }, [existingPreferences, presentingProblemsOptions]);

  const preferenceGender = capitalize(therapistGender) || 'No preference';

  const preferenceSessionType = useMemo(() => {
    if (existingPreferences?.sessionModality === 'messaging') {
      return 'Messaging';
    }

    if (!videoCreditOffers || !videoCreditOffers?.liveSessions?.length) return 'None';
    return videoCreditOffers.liveSessions[0].displayName
      .replace('minute', 'min')
      .replace('session', 'video');
  }, [videoCreditOffers, existingPreferences]);

  const preferenceLanguage = useMemo(() => {
    if (existingPreferences?.languages?.length) {
      return existingPreferences?.languages
        .map((langID) => {
          const foundLang = languagesList.find((langOption) => langOption.id === langID);
          return foundLang ? `${foundLang.name} only` : null;
        })
        .filter(Boolean)[0];
    }
    if (existingPreferences?.languagesSoft?.length) {
      return existingPreferences?.languagesSoft
        .map((langID) => {
          const foundLang = languagesList.find((langOption) => langOption.id === langID);
          return foundLang ? `${foundLang.name}` : null;
        })
        .filter(Boolean)
        .join(', ');
    }
    return null;
  }, [existingPreferences]);

  return (
    <FullWidthContainer align="center" justify="center" tabIndex={-1} as="main">
      <ColumnContainer
        align="center"
        style={{ paddingTop: spacing('space150'), paddingBottom: 180 }}
      >
        <CircleUnderlay size={64} colorRole="brandSubtleDefault">
          <ThumbsUpIllustration />
        </CircleUnderlay>
        <View style={{ position: 'relative', paddingTop: spacing('space150') }}>
          <TitlesView ref={titlesView}>
            <TextDS
              variant="headingXl"
              style={{ paddingTop: spacing('space150'), paddingBottom: spacing('space150') }}
            >
              {tSwitchWizard(
                'MatchPreferenceSummary.titleText',
                'Take a moment to review your preferences'
              )}
            </TextDS>
            {preferenceFocusAreas && (
              <MatchPreferenceRow
                preferenceCategory={tSwitchWizard(
                  'MatchPreferenceSummary.preferenceCategoryFocusArea',
                  preferenceFocusAreas.length === 1 ? 'Focus area' : 'Focus areas',
                  { count: preferenceFocusAreas.length }
                )}
                preferenceResponse={preferenceFocusAreas}
              />
            )}
            {preferenceLanguage && (
              <MatchPreferenceRow
                preferenceCategory={tSwitchWizard(
                  'MatchPreferenceSummary.preferenceLanguage',
                  'Language'
                )}
                preferenceResponse={preferenceLanguage}
              />
            )}
            <MatchPreferenceRow
              preferenceCategory={tSwitchWizard(
                'MatchPreferenceSummary.preferenceCategoryState',
                'State you live in'
              )}
              preferenceResponse={preferenceClientState}
            />
            <MatchPreferenceRow
              preferenceCategory={tSwitchWizard(
                'MatchPreferenceSummary.preferenceCategoryGender',
                'Provider gender'
              )}
              preferenceResponse={preferenceGender}
            />
            {preferenceSessionType !== 'None' && (
              <MatchPreferenceRow
                preferenceCategory={tSwitchWizard(
                  'MatchPreferenceSummary.preferenceCategorySessionType',
                  'Session type'
                )}
                preferenceResponse={preferenceSessionType}
              />
            )}
          </TitlesView>
          <StickyBottomContainer
            noBorder={false}
            isSticky
            contentWrapperStyle={{ padding: '16px 16px' }}
          >
            <Button
              stretch
              sizeDS="large"
              isLoading={false}
              onPress={onPressConfirmPreferences}
              dataQa="MatchPreferenceSummaryPrimaryButton"
              roundedFocusStyle
              aria-describedby="Find new provider"
              text={tSwitchWizard('MatchPreferenceSummary.primaryButtonText', 'Looks good')}
              variant="primary"
              style={{ margin: 0, width: isMobile ? '100%' : 336 }}
            />
            <BaseButton
              onPress={onPressUpdatePreferences}
              dataQa="MatchPreferenceSummarySecondaryButton"
              style={{ padding: 18 }}
            >
              <TextDS variant="headingMd" colorRole="buttonBrandSecondary">
                {tSwitchWizard(
                  'MatchPreferenceSummary.secondaryButtonText',
                  'Update my preferences'
                )}
              </TextDS>
            </BaseButton>
          </StickyBottomContainer>
        </View>
      </ColumnContainer>
    </FullWidthContainer>
  );
};

export default MatchPreferenceSummary;
