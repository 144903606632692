import { Spinner } from '@talkspace/react-toolkit';
import { useTranslation } from '@talkspace/i18n';
import { RouteComponentProps, withRouter } from '@/core/routerLib';

import OnboardingPage from './OnboardingPage';
import EmergencyContact from '../assets/EmergencyContact';
import useQueryEmergencyContact from '@/hooks/useQueryEmergencyContact';
import { getUserData } from '@/auth/helpers/token';

const OnboardingPageEmergencyContact = ({
  match: {
    params: { roomID },
  },
  history,
  totalSteps,
  currentStep,
}: RouteComponentProps<{ roomID: number }> & { totalSteps: number; currentStep: number }) => {
  const { t: tAdultOnboarding } = useTranslation('adultOnboarding');

  const { id: userID } = getUserData();
  const { data: emergencyContact, isLoading } = useQueryEmergencyContact({ userID });

  const showEmergencyContactTitle = !!(
    emergencyContact?.firstName &&
    emergencyContact?.lastName &&
    emergencyContact?.address &&
    emergencyContact?.phone
  );

  const title = showEmergencyContactTitle
    ? tAdultOnboarding('emergencyContact.title', 'Fill out your emergency contact')
    : tAdultOnboarding('emergencyContact.title2', 'Fill out your contact information');

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <OnboardingPage
      totalSteps={totalSteps}
      currentStep={currentStep}
      illustration={<EmergencyContact />}
      title={title}
      description={tAdultOnboarding(
        'emergencyContact.description',
        'We need to ensure that everyone is safe and supported in case of an emergency'
      )}
      ctaText={tAdultOnboarding('emergencyContact.continue', 'Continue')}
      onPress={() => history.push(`/room/${roomID}/onboarding/emergency-contact/source/onboarding`)}
    />
  );
};

export default withRouter(OnboardingPageEmergencyContact);
