import camelCase from 'lodash/camelCase';

import {
  Button,
  CalendarXOutlineIcon,
  CalendarVOutlineIcon,
  ChatSquareOutlineIcon,
  GreenCircleCheckMarkV2,
  Huge,
  HumanCheckmarkOutlineIcon,
  ResponsiveLayoutWithHeader,
  SimpleHeader,
  View,
  useEmotionTheme,
  useWindowWidthState,
} from '@talkspace/react-toolkit';

import EligibilityWarningInfoItem from './EligibilityWarningInfoItem';
import EligibilityWarningFAQLink from './EligibilityWarningFAQLink';

const NewRoom = () => (
  <EligibilityWarningInfoItem
    icon={<ChatSquareOutlineIcon />}
    title="New room"
    text="You have a new room but you can still access your old messages in your previous room."
  />
);

const NewProvider = () => (
  <EligibilityWarningInfoItem
    icon={<HumanCheckmarkOutlineIcon />}
    title="New provider"
    text="Unfortunately, due to this update, your previous provider is unavailable. We’ll match you to a new one shortly."
  />
);

const SameProvider = () => (
  <EligibilityWarningInfoItem
    icon={<HumanCheckmarkOutlineIcon />}
    title="Same provider"
    text="You’ll be able to continue care with your same provider."
  />
);

const BookNextSession = () => (
  <EligibilityWarningInfoItem
    icon={<CalendarVOutlineIcon />}
    title="Book next session"
    text="You’ll be able to start booking sessions with your provider in your new room."
  />
);

const SessionsCanceled = () => (
  <EligibilityWarningInfoItem
    icon={<CalendarXOutlineIcon />}
    title="Sessions canceled"
    text="We couldn’t keep your sessions with your updated coverage."
  />
);

const ScheduledSessions = () => (
  <EligibilityWarningInfoItem
    icon={<CalendarVOutlineIcon />}
    title="Scheduled sessions"
    text="Your upcoming scheduled sessions can be accessed in your new room."
  />
);

interface Props {
  sessionsTransferred: boolean;
  sessionsCanceled: boolean;
  isNewProvider: boolean;
  ctaText: string;
  onPressCTA: () => void;
  onClose: () => void;
}
const EligibilityWarningSuccess = ({
  sessionsTransferred,
  sessionsCanceled,
  isNewProvider,
  ctaText,
  onPressCTA,
  onClose,
}: Props) => {
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  return (
    <ResponsiveLayoutWithHeader
      renderHeader={() => <SimpleHeader onClose={onClose} />}
      noHeaderLine
      containerStyle={{ width: '100%', alignItems: 'center' }}
      contentWrapperStyle={{
        width: isMobile ? '100%' : 394,
        alignItems: 'center',
        padding: '0 24px 34px',
        justifyContent: isMobile ? 'space-between' : undefined,
      }}
    >
      <View align="center">
        <View align="center">
          <GreenCircleCheckMarkV2 />
          <Huge
            style={{
              marginTop: 16,
              marginBottom: isMobile ? 16 : undefined,
              fontSize: 26,
              lineHeight: '34px',
            }}
          >
            Your account is updated!
          </Huge>
        </View>
        <NewRoom />
        {isNewProvider ? <NewProvider /> : <SameProvider />}
        {sessionsCanceled && !sessionsTransferred && <SessionsCanceled />}
        {sessionsTransferred && <ScheduledSessions />}
        {!sessionsTransferred && !sessionsCanceled && <BookNextSession />}
      </View>
      <View align="center" style={{ width: '100%' }}>
        <Button
          dataQa={`${camelCase(ctaText)}Button`}
          text={ctaText}
          onPress={onPressCTA}
          style={{
            marginTop: isMobile ? 16 : 64,
            width: '100%',
            backgroundColor: colors.permaTalkspaceDarkGreen,
          }}
        />
        <EligibilityWarningFAQLink />
      </View>
    </ResponsiveLayoutWithHeader>
  );
};

export default EligibilityWarningSuccess;
