import { WizardScheme } from 'stepWizard';
import { OptionType } from '@talkspace/react-toolkit';
import { ValueType } from 'react-select';
import MatchPreferenceSummary from './components/MatchPreferenceSummary';
import { actionDispatchGetLanguages } from './utils/switchWizardStepActions';
import SelectModalityContainer from './containers/SelectModalityContainer';
import SelectCreditContainer from './containers/SelectCreditContainer';

export const ENGLISH_ID = 15;

const switchWizardSchemeV2: WizardScheme = {
  wizardVersion: 2,
  wizardType: 'switchTherapist',
  wizardModalTitle: 'change provider',
  // TODO: clean up when fixing step wizard design theme 2.0
  themeVersion: '1.0.0',
  welcomeScreen: (handleStartButtonClick) => (
    <MatchPreferenceSummary handleStartButton={handleStartButtonClick} />
  ),
  steps: [
    {
      title: 'What would you like your therapist to focus on?',
      inputType: 'searchableMultiSelect',
      inputState: 'newTherapistFocus',
      inputOptions: 'presentingProblemsOptions',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      name: 'Focus on',
      backOption: 'back',
    },
    {
      inputType: 'stateCountrySelector',
      inputState: 'countryState',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'autoAdvance',
      },
      name: 'State country',
      backOption: 'back',
    },
    {
      title: 'What gender would you prefer in a provider?',
      inputType: 'select',
      inputState: 'therapistGender',
      inputOptions: 'therapistGenderOptions',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: actionDispatchGetLanguages('next'),
        whenVisible: 'onSelect',
      },
      secondaryButton: {
        displayText: 'No preference',
        actionDispatch: actionDispatchGetLanguages('next'),
        defaultValue: null,
      },
      name: 'Therapist gender',
      backOption: 'back',
    },
    {
      title: 'What’s your preferred language?',
      subtitle: 'Based on the state you selected, there are providers who speak these languages:',
      inputType: 'select',
      inputState: 'preferredLanguage',
      inputOptions: 'preferredLanguageOptions',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      displayCondition: 'preferredLanguageOptions',
      postAction: 'setDisplayPreferredLanguageFilter',
      name: 'Therapist language',
      backOption: 'back',
    },
    {
      title: (additionalInfo: object, wizardContext: any) => {
        const { preferredLanguage, preferredLanguageOptions } = wizardContext;
        const option = preferredLanguageOptions.find(
          (opt: ValueType<OptionType, false>) => opt?.value === preferredLanguage
        );
        return <>Are you willing to wait for a {option.label}-speaking provider?</>;
      },
      subtitle: (additionalInfo: object, wizardContext: any) => {
        const { preferredLanguage, preferredLanguageOptions } = wizardContext;
        const option = preferredLanguageOptions.find(
          (opt: ValueType<OptionType, false>) => opt?.value === preferredLanguage
        );
        return (
          <>
            The majority of our providers speak English, which means it may take longer to connect
            with a {option.label}-speaking provider
          </>
        );
      },
      inputType: 'select',
      inputState: 'preferredLanguageFilter',
      inputOptions: 'preferredLanguageFilterOptions',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      displayCondition: 'displayPreferredLanguageFilter',
      name: 'Therapist language filter type',
      backOption: 'back',
    },
    {
      title: 'What type of sessions would you like to have?',
      inputState: 'modality',
      inputType: 'custom',
      nextButton: {
        displayText: 'Continue',
        whenVisible: 'always',
        actionDispatch: (value) => {
          if (value === 'messaging') return '/switch-provider/therapist-matches';
          return 'next';
        },
      },
      name: 'Select Modality',
      backOption: 'back',
      bodyComponent: () => <SelectModalityContainer />,
    },
    {
      title: 'How long would you like your sessions to be?',
      inputState: 'selectedCredit',
      inputType: 'custom',
      nextButton: {
        displayText: 'Continue',
        whenVisible: 'always',
        actionDispatch: '/switch-provider/therapist-matches',
      },
      displayCondition: 'isLiveModality',
      name: 'Select Session Length',
      backOption: 'back',
      bodyComponent: () => <SelectCreditContainer />,
    },
  ],
};

export default switchWizardSchemeV2;
