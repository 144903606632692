import { FunctionComponent } from 'react';
import {
  View,
  TouchableView,
  Large,
  Standard,
  Small,
  Link,
  useEmotionTheme,
} from '@talkspace/react-toolkit';

import Error from '../../../clientChat/assets/Error';

interface Props {
  goBack: () => void;
  error: string;
}

const ErrorMessage: FunctionComponent<Props> = ({ goBack, error }) => {
  const { colors } = useEmotionTheme();

  return (
    <View
      style={{
        paddingLeft: 53,
        paddingRight: 53,
        paddingTop: 26,
        paddingBottom: 20,
      }}
    >
      <View align="center">
        <Error />
        <Large
          variant="largeDarkGrey"
          style={{ marginTop: 20, textAlign: 'center', maxWidth: 450 }}
        >
          {error}
        </Large>

        <TouchableView onPress={goBack}>
          <Standard
            variant="standardDarkGrey"
            style={{ marginTop: 41, marginBottom: 60, alignSelf: 'center' }}
          >
            Cancel
          </Standard>
        </TouchableView>
      </View>
      <View style={{ marginTop: 'auto' }}>
        <Small style={{ alignSelf: 'center', lineHeight: 1.8 }}>Need further assistance?</Small>
        <View
          row
          justify="space-around"
          style={{ alignSelf: 'center', flexWrap: 'wrap', marginBottom: 17 }}
        >
          <Link
            href="mailto:partners-support@talkspace.com"
            style={{ textDecoration: 'none' }}
            target="_blank"
            stopPropagation
          >
            <Large
              variant="largeBoldWideGreen"
              style={{
                borderRight: `1px solid ${colors.a11yLinkWaterGrey}`,
                paddingRight: 12,
                whiteSpace: 'nowrap',
              }}
            >
              partners-support@talkspace.com
            </Large>
          </Link>
          <Link
            href="https://redemption.talkspace.com/faq-segments/bh"
            style={{ textDecoration: 'none' }}
            target="_blank"
            stopPropagation
          >
            <Large variant="largeBoldWideGreen" style={{ paddingLeft: 3, whiteSpace: 'nowrap' }}>
              Visit the FAQ
            </Large>
          </Link>
        </View>
      </View>
    </View>
  );
};

export default ErrorMessage;
