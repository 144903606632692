import { Card, SkeletonLoader, TextDS, View, spacing } from '@talkspace/react-toolkit';
import { ChevronRight } from '@talkspace/react-toolkit/src/designSystems/icons';
import CircleUnderlay from '@talkspace/react-toolkit/src/designSystems/components/CircleUnderlay';
import { CalendarPlus } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import { getTransBookSessionCTAText } from 'ts-frontend/utils/displayStrings';
import { getIsMessagingEnabled } from 'ts-frontend/helpers/subscription';
import { ClientUsageStats, Subscription } from 'ts-frontend/types';
import { useTranslation } from '@talkspace/i18n';

const { space050, space075, space100, space200 } = spacing;

interface Props {
  clientUsageStats: ClientUsageStats | undefined;
  subscriptions: Subscription[];
  isLoading: boolean;
  onPress: () => void;
}
const BookSessionCard = ({ clientUsageStats, subscriptions, isLoading, onPress }: Props) => {
  const { completedSessionsTotal } = clientUsageStats || { completedSessionsTotal: 0 };
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  const isMessagingEnabled = !!subscriptions.find((subscription) =>
    getIsMessagingEnabled({ subscription })
  );

  const titleText = getTransBookSessionCTAText({ isMessagingEnabled, tnewMemberNav });

  const bodyTextLessThan12 = tnewMemberNav(
    'schedule.letsShort',
    "Let's keep up your momentum! It takes about 12 sessions to start feeling better."
  );

  const bodyTextMoreThan12 = tnewMemberNav(
    'schedule.letsShortAfter12Sessions',
    'Let’s keep your momentum going! Book another session to continue your progress.'
  );

  const bodyText = completedSessionsTotal < 12 ? bodyTextLessThan12 : bodyTextMoreThan12;

  const contentStyle = {
    margin: `${space075}px ${space100}px ${space075}px ${space200}px`,
    flex: 1,
  };
  return (
    <Card onPress={onPress} dataQa="bookSessionCardOnPressBookSession">
      {isLoading ? (
        <View row>
          <SkeletonLoader variant="Avatar" />
          <View style={contentStyle}>
            <SkeletonLoader variant="headingMd" style={{ width: 111 }} />
            <SkeletonLoader
              variant="headingSm"
              style={{ maxWidth: 484, marginTop: space100 + space050 }}
            />
          </View>
        </View>
      ) : (
        <View row align="center" justify="space-between">
          <View row style={{ flex: 1 }}>
            <CircleUnderlay>
              <CalendarPlus />
            </CircleUnderlay>
            <View style={contentStyle}>
              <TextDS variant="headingMd">{titleText}</TextDS>
              <TextDS variant="bodySm" style={{ marginTop: space100 }}>
                {bodyText}
              </TextDS>
            </View>
          </View>
          <ChevronRight />
        </View>
      )}
    </Card>
  );
};

export default BookSessionCard;
