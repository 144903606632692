import { useEffect, useState } from 'react';

interface SpinCountProps {
  value: number;
  decorator?: string;
  format?: boolean;
}

// The number of ticks that will occur before the final result is displayed.
// We update the counter at 10ms intervals, which means that the spinner will
// spin for ~500ms.
const desiredTicks = 50;

/**
 * @param props.decorator - A string to display after the number (e.g. %)
 * @param props.value     - The value to be displayed.
 * @param props.format    - Whether to format the displayed number (default: true)
 */
function SpinCount({ decorator, value, format = true }: SpinCountProps) {
  const [i, setI] = useState(0);
  const x = Math.ceil(value / desiredTicks);

  useEffect(() => {
    setTimeout(() => {
      if (i < value) {
        if (i + x > value) {
          setI(value);
        } else {
          setI(i + x);
        }
      }
    }, 10);
  }, [i, value, x]);

  return (
    <span>
      {format ? i.toLocaleString() : i}
      {decorator}
    </span>
  );
}

export default SpinCount;
