import { Stripe } from '@stripe/stripe-js';

const STRIPE_BASE_URL = 'https://js.stripe.com/v3';

const injectScript = (): HTMLScriptElement => {
  const script = document.createElement('script');
  script.src = STRIPE_BASE_URL;

  const headOrBody = document.head || document.body;

  if (!headOrBody) {
    throw new Error('Expected document.body not to be null. Stripe.js requires a <body> element.');
  }

  headOrBody.appendChild(script);

  return script;
};

// Execute our own script injection after a tick to give users time to
// do their own script injection.
const stripePromise = async (): Promise<Stripe | null> => {
  if (typeof window === 'undefined') {
    // Resolve to null when imported server side. This makes the module
    // safe to import in an isomorphic code base.
    return null;
  }

  if ((window as any).Stripe) {
    return (window as any).Stripe;
  }

  const script: HTMLScriptElement =
    document.querySelector(`script[src="${STRIPE_BASE_URL}"], script[src="${STRIPE_BASE_URL}/"]`) ||
    injectScript();

  if (!script) throw new Error('Stripe script tag failed');

  return new Promise((resolve, reject) => {
    script.addEventListener('load', () => {
      if ((window as any).Stripe) {
        resolve((window as any).Stripe);
      } else {
        reject(new Error('Stripe.js not available'));
      }
    });

    script.addEventListener('error', () => {
      if (script.parentNode) script.parentNode.removeChild(script);
      reject(new Error('Failed to load Stripe.js'));
    });
  });
};

const loadStripeTs = (publicKey: string): Promise<Stripe | null> =>
  stripePromise().then((MaybeStripe) => {
    if (MaybeStripe) {
      // @ts-ignore: MaybeStripe has a type of Stripe which is an interface so it's not callable
      return MaybeStripe(publicKey);
    }
    return null;
  });

export default loadStripeTs;
