// DO NOT IMPORT THIS FILE DIRECTLY (use eventTracker)
/* eslint-disable */
import configs from '../../configs';
import * as IonicMixpanel from 'ts-ionic/plugins/mixpanel';
import { NativeFeaturesEnum, shouldShowNativeFeature } from 'ts-ionic';
import { getIsIonic } from 'ts-ionic/ionicUtils';

const mixpanelConfig = configs.analytics.mixpanel;
if (getIsIonic()) {
  window.subScope = window.subScope || {};
}

const promisifyWithTimeout =
  (fun, fnScope, config = {}) =>
  (...args) =>
    new Promise((resolve) => {
      const { timeout = 1000 } = config;
      let timer = null;
      if (timeout) {
        timer = window.setTimeout(() => {
          timer = null;
          resolve();
        });
      }
      fun.apply(fnScope || this, [
        ...args,
        () => {
          if (timer) {
            window.clearTimeout(timer);
            resolve();
          }
        },
      ]);
    });

const scope = getIsIonic() ? window.subScope : window; // Use window for now. Ideally we shouldn't add keys to window

const injectScript = function (c, a, b) {
  if (!a.__SV) {
    try {
      var d,
        m,
        j,
        k = b.location,
        f = k.hash;
      d = function (a, b) {
        return (m = a.match(RegExp(b + '=([^&]*)'))) ? m[1] : null;
      };
      f &&
        d(f, 'state') &&
        ((j = JSON.parse(decodeURIComponent(d(f, 'state')))),
        'mpeditor' === j.action &&
          (b.sessionStorage.setItem('_mpcehash', f),
          history.replaceState(j.desiredHash || '', c.title, k.pathname + k.search)));
    } catch (n) {}
    var l, h;
    b.mixpanel = a;
    a._i = [];
    a.init = function (b, d, g) {
      function c(b, i) {
        var a = i.split('.');
        2 == a.length && ((b = b[a[0]]), (i = a[1]));
        b[i] = function () {
          b.push([i].concat(Array.prototype.slice.call(arguments, 0)));
        };
      }
      var e = a;
      'undefined' !== typeof g ? (e = a[g] = []) : (g = 'mixpanel');
      e.people = e.people || [];
      e.toString = function (b) {
        var a = 'mixpanel';
        'mixpanel' !== g && (a += '.' + g);
        b || (a += ' (stub)');
        return a;
      };
      e.people.toString = function () {
        return e.toString(1) + '.people (stub)';
      };
      l =
        'disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove'.split(
          ' '
        );
      for (h = 0; h < l.length; h++) c(e, l[h]);
      var f = 'set set_once union unset remove delete'.split(' ');
      e.get_group = function () {
        function a(c) {
          b[c] = function () {
            call2_args = arguments;
            call2 = [c].concat(Array.prototype.slice.call(call2_args, 0));
            e.push([d, call2]);
          };
        }
        for (
          var b = {}, d = ['get_group'].concat(Array.prototype.slice.call(arguments, 0)), c = 0;
          c < f.length;
          c++
        )
          a(f[c]);
        return b;
      };
      a._i.push([b, d, g]);
    };
    a.__SV = 1.2;
    b = c.createElement('script');
    b.type = 'text/javascript';
    b.async = !0;
    b.src = mixpanelConfig.librarySRC
      ? mixpanelConfig.librarySRC
      : 'https://cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js';

    d = c.getElementsByTagName('script')[0];
    d.parentNode.insertBefore(b, d);
  }
};

if (!getIsIonic()) injectScript(document, scope.mixpanel || [], scope);

export const initMixpanel = (onFinishLoading) => {
  if (shouldShowNativeFeature(NativeFeaturesEnum.MIXPANEL)) {
    return IonicMixpanel.initMixpanel(mixpanelConfig.token, onFinishLoading);
  }
  return scope.mixpanel.init(mixpanelConfig.token, {
    loaded: onFinishLoading,
    ip: !!!mixpanelConfig.apiHost,
    ...(mixpanelConfig.apiHost ? { api_host: mixpanelConfig.apiHost } : {}),
    ignore_dnt: true,
  });
};

export const track = (eventName, properties, settings) => {
  if (shouldShowNativeFeature(NativeFeaturesEnum.MIXPANEL)) {
    return IonicMixpanel.getMixpanel().track(eventName, properties);
  }
  return promisifyWithTimeout(scope.mixpanel.track, scope.mixpanel)(
    eventName,
    properties,
    settings
  );
};

export const peopleSet = (properties) => {
  if (shouldShowNativeFeature(NativeFeaturesEnum.MIXPANEL)) {
    return IonicMixpanel.getMixpanelPeople().set(properties);
  }
  return promisifyWithTimeout(scope.mixpanel.people.set, scope.mixpanel.people)(properties);
};

export const alias = (alias) => {
  if (shouldShowNativeFeature(NativeFeaturesEnum.MIXPANEL)) {
    return IonicMixpanel.getMixpanel().alias(alias);
  }
  return scope.mixpanel.alias(alias);
};

export const getDistinctId = () => {
  if (shouldShowNativeFeature(NativeFeaturesEnum.MIXPANEL)) {
    return IonicMixpanel.getMixpanel().distinctId();
  }
  return scope.mixpanel.get_distinct_id();
};

export const identify = (id) => {
  if (shouldShowNativeFeature(NativeFeaturesEnum.MIXPANEL)) {
    return IonicMixpanel.getMixpanel().identify(id);
  }
  return scope.mixpanel.identify(id);
};

export const getMixpanel = () => {
  if (shouldShowNativeFeature(NativeFeaturesEnum.MIXPANEL)) {
    return IonicMixpanel.getMixpanel();
  }
  return scope.mixpanel;
};
