import { FunctionComponent } from 'react';
import {
  View,
  Button,
  Big,
  Small,
  useEmotionTheme,
  StatusAlertDot,
} from '@talkspace/react-toolkit';
import styled from 'core/styled';
import moment from 'moment';
import ClinicalProgressCard from '../ClinicalProgressCard/ClinicalProgressCard';
import useWindowWidth from '../../../clientChat/hooks/useWindowWidth';
import { UpcomingSurvey } from '../../reducers/surveys';
import SymptomIcon from '../icons/SymptomIcon';
import CircleClock from '../../../components/icons/CircleClock';

interface UpcomingSurveysCardProps {
  upcomingSurvey: UpcomingSurvey;
  launchSurvey: () => void;
}

const IconCircle = styled(View)(({ theme: { colors } }) => {
  return {
    paddingTop: 2,
    paddingLeft: 2,
    backgroundColor: colors.white,
    boxShadow: '0 3px 11px -3px rgba(16,109,149,0.30)',
    height: 58,
    width: 58,
    borderRadius: 29,
    justifyContent: 'center',
    alignItems: 'center',
  };
});

const LEFT_WIDTH = 75;

const UpcomingSurveysCard: FunctionComponent<UpcomingSurveysCardProps> = ({
  upcomingSurvey,
  launchSurvey,
}) => {
  const { isMobile } = useWindowWidth();
  const { colors } = useEmotionTheme();
  return (
    <ClinicalProgressCard
      style={{ marginTop: 21, marginBottom: 20, paddingTop: 18, flex: 'none' }}
      disabled
    >
      <View row>
        <View style={{ width: LEFT_WIDTH }}>
          <IconCircle>
            <SymptomIcon />
          </IconCircle>
        </View>
        <View flex={1} style={{ marginTop: 7, marginBottom: 5 }}>
          <Big as="h2">
            Assessment due
            <StatusAlertDot
              as="span"
              style={{ display: 'inline-block', margin: '4px 4px 1px 10px' }}
            />
          </Big>

          <Small>Complete your assessment to track your symptoms.</Small>
          <View row align="end">
            <CircleClock
              primaryColor="transparent"
              secondaryColor={colors.grey}
              width={13}
              height={13}
              style={{ marginBottom: 3, marginRight: 6 }}
            />
            <Small style={{ marginTop: 10 }}>
              Due: {moment(upcomingSurvey.dueAt).format('dddd, MMMM D')}
            </Small>
          </View>
        </View>
      </View>
      <Button
        text="Start assessment"
        style={{
          marginLeft: isMobile ? 0 : LEFT_WIDTH,
          marginTop: 7,
          width: isMobile ? 305 : 205,
          maxWidth: '100%',
        }}
        onPress={launchSurvey}
      />
    </ClinicalProgressCard>
  );
};

export default UpcomingSurveysCard;
