import { useMemo } from 'react';
import moment from 'moment';

import {
  Button,
  Huge,
  ResponsiveLayoutWithHeader,
  SimpleHeader,
  View,
  useWindowWidthState,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { TherapistInfo } from 'ts-frontend/entities/Therapist';
import { ServiceType, InsuranceStatus } from 'ts-frontend/types';

import EligibilityWarningSection from './EligibilityWarningSection';
import EligibilityWarningInfo from './EligibilityWarningInfo';
import EligibilityWarningInfoOutOfGracePeriod from './EligibilityWarningInfoOutOfGracePeriod';
import EligibilityWarningFAQLink from './EligibilityWarningFAQLink';

interface Props {
  serviceType: ServiceType;
  isWithinGracePeriod: boolean;
  therapistInfo: TherapistInfo;
  insuranceStatus: InsuranceStatus;
  onClose: () => void;
  onContinue: () => void;
}
const EligibilityWarning = ({
  serviceType,
  isWithinGracePeriod,
  therapistInfo,
  insuranceStatus,
  onClose,
  onContinue,
}: Props) => {
  const { isMobile } = useWindowWidthState();
  const { colors } = useEmotionTheme();
  const { payerName, lastEligibilityCheckDate, gracePeriod } = insuranceStatus;
  const updateDeadline = useMemo(
    () => moment(lastEligibilityCheckDate).add(gracePeriod, 'hours').startOf('day').add(1, 'day'),
    [lastEligibilityCheckDate, gracePeriod]
  );
  const title = isWithinGracePeriod
    ? `Update your account before ${updateDeadline.format('dddd, MMM DD')}`
    : 'Update your account to continue care';
  return (
    <ResponsiveLayoutWithHeader
      renderHeader={() => <SimpleHeader onClose={onClose} />}
      noHeaderLine
      containerStyle={{ width: '100%' }}
      contentWrapperStyle={{
        paddingBottom: 40,
      }}
    >
      <View
        justify="space-between"
        style={{
          width: isMobile ? 335 : 394,
          height: isMobile ? '100%' : undefined,
          alignItems: 'center',
          alignSelf: 'center',
        }}
      >
        <View>
          <EligibilityWarningSection
            serviceType={serviceType}
            payerName={payerName}
            therapistFirstName={therapistInfo.firstName}
          />
          <Huge
            style={{
              marginTop: isMobile ? 24 : 40,
              textAlign: 'center',
              fontSize: 26,
              lineHeight: '34px',
            }}
          >
            {title}
          </Huge>
          {isWithinGracePeriod ? (
            <EligibilityWarningInfo updateDeadline={updateDeadline} />
          ) : (
            <EligibilityWarningInfoOutOfGracePeriod />
          )}
        </View>

        <View align="center" style={{ width: '100%', marginTop: isMobile ? undefined : 60 }}>
          <Button
            dataQa="continueButton"
            style={{
              width: '100%',
              backgroundColor: colors.permaTalkspaceDarkGreen,
            }}
            onClick={onContinue}
          >
            Continue
          </Button>
          <EligibilityWarningFAQLink />
        </View>
      </View>
    </ResponsiveLayoutWithHeader>
  );
};

export default EligibilityWarning;
