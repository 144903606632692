import { FunctionComponent, Dispatch, SetStateAction } from 'react';
import { BaseButton, View, Large, Huge } from '@talkspace/react-toolkit';
import styled from '../../../core/styled/styled';
import { SuccessCheckmark } from '../../../components/icons';

const DoneButton = styled(BaseButton)(({ theme: { colors } }) => {
  return { marginTop: 40, marginBottom: 60, backgroundColor: colors.white };
});

interface Props {
  message: string;
  title?: string;
  onCancelPress: () => void;
  setIsCancel?: Dispatch<SetStateAction<boolean>>;
}

const SuccessModal: FunctionComponent<Props> = (props) => {
  const { title, message, onCancelPress, setIsCancel } = props;
  const onPress = () => {
    // prevents keyboard user from getting "lost" when succss modal closes
    if (setIsCancel) setIsCancel(true);
    else {
      onCancelPress();
    }
  };
  return (
    <>
      <View style={{ marginBottom: 20 }}>
        <SuccessCheckmark />
      </View>
      {title && <Huge style={{ whiteSpace: 'pre-wrap', marginBottom: 20 }}>{title}</Huge>}
      <Large variant="largeDarkGrey" style={{ whiteSpace: 'pre-wrap' }}>
        {message}
      </Large>
      <DoneButton onPress={onPress} data-qa="myAccountSuccessModalDoneButton">
        <Large variant="largeBoldWideGreen">Done</Large>
      </DoneButton>
    </>
  );
};

export default SuccessModal;
