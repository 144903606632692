import Svg, { G, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

function CalendarSmallIcon(props) {
  const { colors } = useEmotionTheme();
  return (
    <Svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M20.4 22.8H3.6a2.392 2.392 0 01-1.697-.703A2.392 2.392 0 011.2 20.4V6c0-.663.269-1.263.703-1.697A2.392 2.392 0 013.6 3.6h16.8c.663 0 1.263.269 1.697.703.434.434.703 1.034.703 1.697v14.4c0 .663-.269 1.263-.703 1.697a2.392 2.392 0 01-1.697.703z"
          stroke={colors.heatherGrey}
          strokeWidth={2.4000001}
          fill={colors.white}
          transform="translate(-59 -341) translate(59 341)"
        />
        <Path
          d="M12.6 12h6a.6.6 0 01.6.6v6a.6.6 0 01-.6.6h-6a.6.6 0 01-.6-.6v-6a.6.6 0 01.6-.6zM16.8 0A1.2 1.2 0 0118 1.2V6a1.2 1.2 0 11-2.4 0V1.2A1.2 1.2 0 0116.8 0zM7.2 0a1.2 1.2 0 011.2 1.2V6A1.2 1.2 0 016 6V1.2A1.2 1.2 0 017.2 0z"
          fill={colors.heatherGrey}
          transform="translate(-59 -341) translate(59 341)"
        />
      </G>
    </Svg>
  );
}

export default CalendarSmallIcon;
