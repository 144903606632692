import { SurveyResult } from 'ts-frontend/types';

export interface UpcomingSurvey {
  deliverAt: Date;
  dueAt: Date;
  roomID: number;
  userRoomSurveyID: number;
  questionCount: number;
  isDue?: boolean;
}

export interface State {
  upcomingSurveys: UpcomingSurvey[];
  surveyResultsByRoomID: {
    [key: string]: SurveyResult[];
  };
  isLoading: boolean;
  error: string;
}

export type Action =
  | {
      type: 'receiveGetSurveys';
      payload: Pick<State, 'upcomingSurveys' | 'surveyResultsByRoomID'>;
    }
  | { type: 'requestGetSurveys' }
  | { type: 'setError'; error?: string }
  | { type: 'clearError' };

export const initialState: State = {
  upcomingSurveys: [],
  surveyResultsByRoomID: {},
  isLoading: false,
  error: '',
};

export const surveysReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'requestGetSurveys':
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case 'receiveGetSurveys':
      return {
        ...state,
        isLoading: false,
        error: '',
        ...action.payload,
      };
    case 'setError':
      return {
        ...state,
        error: action.error || 'Something went wrong. Please try again later',
        isLoading: false,
      };
    case 'clearError':
      return {
        ...state,
        error: '',
      };
    default:
      return state;
  }
};
