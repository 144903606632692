import { Auth } from '@aws-amplify/auth';
import resendConfirmation from './resendConfirmation';
import { SMS_MFA, AuthResultCode, SignInErrorCodes, SignInParameters, SignInResult } from './types';

const signInErrorCodesMapping = {
  UserNotConfirmedException: AuthResultCode.NO_MATCH,
  InvalidPasswordException: AuthResultCode.NO_MATCH,
  NotAuthorizedException: AuthResultCode.NO_MATCH,
  TooManyFailedAttemptsException: AuthResultCode.TOO_MANY_ATTEMPTS,
  PasswordResetRequiredException: AuthResultCode.RESET_REQUIRED,
  NetworkError: AuthResultCode.NETWORK_ERROR,
};

const signIn = async ({ email, password }: SignInParameters): Promise<SignInResult> => {
  try {
    const user = await Auth.signIn({ password, username: email });
    const idToken = user.signInUserSession?.idToken?.jwtToken;
    const phoneNumber = user.challengeParam?.CODE_DELIVERY_DESTINATION;

    return {
      idToken,
      result: AuthResultCode.SUCCESS,
      is2FA: user.challengeName === SMS_MFA && !user.signInUserSession,
      userObject: user,
      phoneNumber,
    };
  } catch (e) {
    const error = e as { code: SignInErrorCodes };

    if (error.code === 'UserNotConfirmedException') {
      await resendConfirmation(email);
    }

    if (Object.keys(signInErrorCodesMapping).includes(error.code)) {
      return { result: signInErrorCodesMapping[error.code] };
    }

    throw error;
  }
};

export default signIn;
