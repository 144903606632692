import { MemoryRouter } from 'react-router';
import ModalRoutes from '../ModalRoutes';

interface FullScreenModalProps {
  initialModalRoute: string;
  initialModalStateParams?: unknown;
}

/**
 * Allows modals to be turned into full screen routes
 */
const FullScreenModal = ({ initialModalRoute, initialModalStateParams }: FullScreenModalProps) => (
  <MemoryRouter initialEntries={[{ pathname: initialModalRoute, state: initialModalStateParams }]}>
    <ModalRoutes fullScreen transparent={false} />
  </MemoryRouter>
);

export default FullScreenModal;
