import { TextDS, View, DSIcon, styled } from '@talkspace/react-toolkit';

type GuideLineItem = {
  title: string | undefined;
  description: string | JSX.Element;
  icon: DSIcon | undefined;
};

type GuideLines = {
  guideline: GuideLineItem;
};

const Description = styled(TextDS)(({ theme: { spacing } }) => {
  return {
    width: '100%',
    maxWidth: undefined,
    marginLeft: spacing('space300'),
    marginBottom: spacing('space200'),
  };
});

const Title = styled(TextDS)(({ theme: { spacing } }) => {
  return {
    marginLeft: spacing('space150'),
    marginTop: spacing('space075'),
  };
});

const IconWrapper = styled(TextDS)(({ theme: { spacing } }) => {
  return {
    marginTop: spacing('space100'),
  };
});

const IconView = styled(View)(({ theme: { spacing } }) => {
  return {
    position: 'relative',
    marginLeft: -spacing('space100'),
  };
});

const GuidelineItem = ({ guideline }: GuideLines): JSX.Element => {
  const { title, icon: Icon } = guideline;
  return (
    <View>
      <View flex={1} row>
        <IconView justify="space-between">
          <IconWrapper>{Icon && <Icon size="standard" colorType="subtlest" />}</IconWrapper>
        </IconView>
        <Title variant="headingMd">{title || undefined}</Title>
      </View>
      <Description colorRole="textSubtle" variant="bodySm">
        {guideline.description}
      </Description>
    </View>
  );
};
export default GuidelineItem;
