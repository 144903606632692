import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

interface isEligibleApiResponse {
  data: {
    isEligible: boolean;
    possibleUserAnswers: Record<string, Record<string, string>>;
  };
}

interface GetFinancialAidEligibilityTokenApiResponse {
  data: {
    token: string;
    discountAmountInPercent: number;
  };
}

async function isEligible(
  roomID: number,
  getPossibleUserAnswers?: boolean
): Promise<isEligibleApiResponse> {
  const queryParam = getPossibleUserAnswers ? '?getPossibleUserAnswers=true' : '';
  const res = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/financial-aid/is-eligible${queryParam}`
  );
  return res.data;
}

async function getFinancialAidEligibilityToken(
  roomID: number,
  userAnswers: Record<string, unknown>
): Promise<GetFinancialAidEligibilityTokenApiResponse> {
  const res = await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/financial-aid/eligibility-token`,
    userAnswers
  );
  return res.data;
}

const financialAidWizardApiHelper = {
  isEligible,
  getFinancialAidEligibilityToken,
};

export default financialAidWizardApiHelper;
