const getParamByName = (paramName: string) => {
  const { search, hash } = window.location;

  const queryParams = new URLSearchParams(search);
  const hashParams = new URLSearchParams(hash.replace('#', '?'));

  return hashParams.get(paramName) || queryParams.get(paramName);
};

export default getParamByName;
