import { FunctionComponent, MouseEvent } from 'react';
import {
  useUniqueID,
  HiddenText,
  Input,
  TouchableView,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import styled from '../../../core/styled';

interface ToggleSwitchViewProps {
  isOn: boolean;
  name: string;
  onValueChange?: (value: boolean) => void;
  onBackgroundColor?: string;
  offBackgroundColor?: string;
  switchWidth?: number;
  switchHeight?: number;
  label?: string;
  info?: string;
  switchIcon?: JSX.Element;
  circleSize?: number;
  onPos?: number;
  offPos?: number;
  dataQa?: string;
}
const Span = styled.span({});
const Label = styled.label();

const ToggleSwitchView: FunctionComponent<ToggleSwitchViewProps> = ({
  isOn,
  name,
  label,
  info,
  onValueChange,
  onBackgroundColor,
  offBackgroundColor,
  switchWidth = 50,
  switchHeight = 30,
  switchIcon,
  circleSize,
  onPos,
  offPos,
  dataQa,
}) => {
  const { colors } = useEmotionTheme();
  const onChange = () => {
    if (onValueChange) {
      onValueChange(!isOn);
    }
  };
  const togglePressHandler = (e: MouseEvent<HTMLDivElement | MouseEvent> | KeyboardEvent) => {
    // The space bar is expected to toggle a switch. By default space press scrolls down the page hence preventDefault
    e.preventDefault();
    onChange();
  };
  const toggleLabelID = useUniqueID('toggleLabelID');
  const infoID = useUniqueID('infoID');
  let circlePos = {};
  if (onPos && isOn) {
    circlePos = { position: 'absolute', left: onPos };
  } else if (offPos && !isOn) {
    circlePos = { position: 'absolute', left: offPos };
  }
  return (
    <>
      <Label htmlFor={`react-switch-new-${name}`}>
        <HiddenText id={toggleLabelID}>{label}</HiddenText>
      </Label>
      {info && <HiddenText id={infoID}>{info}</HiddenText>}
      <Input
        aria-labelledby={toggleLabelID}
        type="checkbox"
        id={`react-switch-new-${name}`}
        className="react-switch-checkbox"
        checked={isOn}
        onChangeValue={onChange}
      />
      <TouchableView
        dataQa={dataQa}
        aria-labelledby={toggleLabelID}
        aria-describedby={info ? infoID : undefined}
        onPress={togglePressHandler}
        role="switch"
        aria-checked={isOn}
        className="react-switch-label"
        style={{
          background: isOn
            ? onBackgroundColor || colors.green
            : offBackgroundColor || colors.lightFordGrey,
          height: switchHeight,
          width: switchWidth,
          justifyContent: 'center',
        }}
      >
        <Span
          aria-hidden="true"
          className="react-switch-button"
          style={{
            width: circleSize || switchHeight - 2,
            height: circleSize || switchHeight - 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...circlePos,
          }}
        >
          {switchIcon}
        </Span>
      </TouchableView>
    </>
  );
};

export default ToggleSwitchView;
