import { FunctionComponent, useCallback, useEffect } from 'react';
import moment from 'moment';
import { persistData, useWizardState, useWizardActions, WizardActionStatus } from 'stepWizard';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { trackWizardEvent } from '@/utils/analytics/events';
import { getUserData } from '@/auth/helpers/token';
import V2FinalScreen from '@/cancellationWizardsShared/components/V2FinalScreen';
import useCancellationWizard from '../hooks/useCancellationWizard';
import { RouteComponentProps, withRouter } from '../../core/routerLib/routerLib';

const SubscriptionPausedContainer: FunctionComponent<RouteComponentProps> = ({ location }) => {
  const {
    roomID,
    isVerifyExitModalOpen,
    responses,
    therapistInfo,
    flow,
    experiment,
    experimentID,
  } = useWizardState();
  const closeModal = useCloseModal();
  const [state, { pauseTherapy, exitAction }] = useCancellationWizard();
  const { setState, closeWizard } = useWizardActions();
  const { error, isSuccess } = state;
  const { id: userID } = getUserData();

  const DATE_FORMAT_STRING = 'MMM DD, YYYY';
  const resumeDate = moment().add(30, 'days').format(DATE_FORMAT_STRING);
  const lastStepName = 'Pause Subscription';

  const handlePersist = useCallback(() => {
    persistData(responses, setState, lastStepName, error, { subscriptionPaused: true }, isSuccess);
  }, [error, isSuccess, responses, setState]);

  useEffect(() => {
    pauseTherapy(roomID, 30);
  }, []); // eslint-disable-line

  const handleExitModal = () => {
    exitAction();
    if (flow === 'feelingBetter') {
      closeWizard(undefined, undefined, { actionType: 'openAppReview' });
    } else {
      closeModal();
    }
  };

  useEffect(() => {
    if (isVerifyExitModalOpen) {
      handleExitModal();
    }
  }, [isVerifyExitModalOpen]); // eslint-disable-line

  useEffect(() => {
    // persisting the data on error or success
    const handleRequestStatus = async () => {
      if (isSuccess || error) {
        if (isSuccess) {
          trackWizardEvent(lastStepName, 'Cancellation Wizard', {
            'User ID': userID,
            Application: 'Cancellation Wizard',
            label: lastStepName,
            eventPropertyValue: therapistInfo && therapistInfo.id,
            experiment,
            experimentID,
          });
        }
        handlePersist();
      }
    };
    handleRequestStatus();
  }, [isSuccess, error, location]); // eslint-disable-line

  return flow ? (
    <V2FinalScreen
      flow={flow}
      actionHookState={state}
      onClick={handleExitModal}
      date={resumeDate}
    />
  ) : (
    <WizardActionStatus
      successTitle="Your subscription has been paused."
      successSubTitle={`Therapy will automatically resume on ${resumeDate}`}
      successButtonText="Done"
      showCustomErrorSubtitle
      handleAction={handleExitModal}
      actionHookState={state}
    />
  );
};

export default withRouter(SubscriptionPausedContainer);
