import {
  BackNextButton,
  CancellationPolicy,
  PanelHeader,
  ResponsiveLayoutWithHeader,
  View,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { useHistory, useParams } from '@/core/routerLib';

const BookAsyncSessionCancellationPolicyContainer = () => {
  const { roomID } = useParams<{ roomID: string }>();
  const history = useHistory();
  const { colors } = useEmotionTheme();
  return (
    <ResponsiveLayoutWithHeader
      contentWrapperStyle={{ alignItems: 'center' }}
      renderHeader={() => (
        <PanelHeader
          renderLeft={() => (
            <View row align="center">
              <BackNextButton
                style={{ display: 'inline-block', borderRadius: 5 }}
                roundedFocusStyle
                primaryColor={colors.green}
                onPress={() => {
                  history.push(`/room/${roomID}/onboarding/book-async-session/messaging-session`);
                }}
              />
            </View>
          )}
        />
      )}
    >
      <CancellationPolicy variant="async" />
    </ResponsiveLayoutWithHeader>
  );
};

export default BookAsyncSessionCancellationPolicyContainer;
