import { Route, Switch, useHistory, useParams } from 'core/routerLib';
import { useState, VoidFunctionComponent } from 'react';
import { useWindowWidth, Screen } from '@talkspace/react-toolkit';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import ErrorScreen from './screens/ErrorScreen';
import InitialErrorScreen from './screens/InitialErrorScreen';
import SuperbillsScreen from './screens/SuperbillsScreen';
import NoSuperbills from './screens/NoSuperbillsScreen';
import PDFViewerScreen from './screens/PDFViewerScreen';
import VerifyNameScreen from './screens/VerifyNameScreen';
import NoNameScreen from './screens/NoNameScreen';
import { SuperbillsProvider } from './hooks/superbillsContext';

const BASE_PATH = '/superbills/room/:roomID';

const Superbills: VoidFunctionComponent = () => {
  const [title, setTitle] = useState('View superbills');
  const handleTitleChange = (newTitle: string) => setTitle(newTitle);
  const closeModal = useCloseModal();
  const { roomID } = useParams<{ roomID: string }>();
  const history = useHistory();
  const { isMobile } = useWindowWidth();

  const isPDFView = history.location.pathname.includes('view-superbill');
  const handlePDFViewExit = () => {
    history.push(`/superbills/room/${roomID}`);
  };
  const handleExit = () => {
    closeModal({
      navigateTo: 'manageSubscription',
      metadata: { roomID: Number(roomID) },
    });
  };
  const showBackButton = isPDFView && isMobile;

  return (
    <SuperbillsProvider>
      <Switch>
        <Screen
          title={title}
          showBackButton={showBackButton}
          showCloseButton={!showBackButton}
          closeButtonDataQa="superBillsCloseButton"
          backButtonDataQa="superBillsBackButton"
          onCloseButtonClick={isPDFView ? handlePDFViewExit : handleExit}
          onBackButtonClick={handlePDFViewExit}
        >
          <Route exact path={`${BASE_PATH}/`} component={SuperbillsScreen} />
          <Route exact path={`${BASE_PATH}/no-superbills`} component={NoSuperbills} />
          <Route
            exact
            path={`${BASE_PATH}/view-superbill`}
            render={() => <PDFViewerScreen handleTitleChange={handleTitleChange} />}
          />
          <Route exact path={`${BASE_PATH}/no-name`} component={NoNameScreen} />
          <Route exact path={`${BASE_PATH}/verify-name`} component={VerifyNameScreen} />
          <Route exact path={`${BASE_PATH}/error`} component={ErrorScreen} />
          <Route exact path={`${BASE_PATH}/initial-error`} component={InitialErrorScreen} />
        </Screen>
      </Switch>
    </SuperbillsProvider>
  );
};

export default Superbills;
