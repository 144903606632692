import { OptionType } from '@talkspace/react-toolkit';
import { BaseWizardState, baseWizardInitialState } from 'stepWizard';

interface CheckInPayload {
  rating: number;
  bricks: string[];
  otherText: string;
}

export interface State extends BaseWizardState {
  therapistInfo: any;
  roomID: number;
  clientUserID: number;
  source: string;
  eventCategory: string;
  brickOptions?: OptionType[];
  periodicCheckIn: boolean;
  postNotIntroLVS: boolean;
  postLVS: boolean;
  checkInSource?: string;
  videoCallID?: number;
  checkInData: CheckInPayload;
  userPromptID?: number;
  postLVSSubtitle?: string;
  changeProviderSubtitle: string;
  shouldShowChangeTherapist: boolean;
  nextBookingStartTime?: string;
}

const getBrickOptionsByRoomType = (checkInType: string) => {
  switch (checkInType) {
    case 'check-in':
      return [
        { label: 'Responsiveness', value: 'responsiveness' },
        { label: 'Insightfulness', value: 'insightfulness' },
        { label: 'Focus on goals', value: 'focusOnGoals' },
        { label: 'Treatment style', value: 'treatmentStyle' },
        { label: 'Level of effort', value: 'effortLevel' },
        { label: 'Professionalism', value: 'professionalism' },
        {
          label: 'Feeling of support',
          value: 'feelingOfSupport',
        },
        { label: 'Other', value: 'other' },
      ];
    case 'live-chat':
    case 'lvs':
      return [
        { label: 'Engagement', value: 'engagement' },
        { label: 'Insightfulness', value: 'insightfulness' },
        { label: 'Focus on goals', value: 'focusOnGoals' },
        { label: 'Treatment style', value: 'treatmentStyle' },
        { label: 'Level of effort', value: 'effortLevel' },
        { label: 'Professionalism', value: 'professionalism' },
        {
          label: 'Feeling of support',
          value: 'feelingOfSupport',
        },
        { label: 'Other', value: 'other' },
      ];
    case 'intro-lvs':
      return [];
    case 'psychiatry-lvs':
      return [
        { label: 'Professionalism', value: 'professionalism' },
        { label: 'Treatment style', value: 'treatmentStyle' },
        {
          label: 'Meeting my medication needs',
          value: 'meetingMedicationNeeds',
        },
        { label: 'Other', value: 'other' },
      ];
    default:
      return [];
  }
};

const getSessionStepSubtitle = (checkInSource: string) => {
  switch (checkInSource) {
    case 'intro-lvs':
      return 'Consider if this provider and their approach seemed like a good fit. ';
    case 'live-chat':
    case 'lvs':
      return 'Consider whether you felt understood and worked on goals you wanted to focus on. ';
    case 'psychiatry-lvs':
      return 'Consider if your medication needs were met and if you felt the process went well.';
    default:
      return '';
  }
};

const getChangeProviderSubtitle = (isIntroCreditEligible: boolean) => {
  if (isIntroCreditEligible) {
    return 'If you decide to change, you’ll get another 10 minute session to connect with your new provider. You’ll also have the option to share your message history so you can keep building on your progress.';
  }
  return 'If you decide to change, you’ll have the option to share your message history so you can keep building on your progress';
};

// eslint-disable-next-line import/prefer-default-export
export const getInitialState = (
  clientUserID,
  roomID,
  source,
  contextID,
  userPromptID,
  isIntroCreditEligible: boolean,
  checkInSource = '',
  videoCallID?: number
): State => {
  return {
    ...baseWizardInitialState(source, contextID, {
      videoCallID,
      checkInSource,
      userPromptID,
    }),
    therapistInfo: {},
    roomID,
    clientUserID,
    brickOptions: getBrickOptionsByRoomType(checkInSource),
    periodicCheckIn: checkInSource === 'check-in',
    eventCategory: 'Therapist Check-In',
    postNotIntroLVS: checkInSource === 'lvs' || checkInSource === 'psychiatry-lvs',
    postLVS: checkInSource !== 'check-in',
    checkInSource,
    videoCallID,
    checkInData: {
      rating: 0,
      otherText: '',
      bricks: [],
    },
    userPromptID,
    postLVSSubtitle: getSessionStepSubtitle(checkInSource),
    changeProviderSubtitle: getChangeProviderSubtitle(isIntroCreditEligible),
    shouldShowChangeTherapist: false,
    nextBookingStartTime: undefined,
  };
};
