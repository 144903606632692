import Svg, { Path } from 'svgs';
import { View, Tiny } from '@talkspace/react-toolkit';
import styled from '@/core/styled';

const Container = styled(View)<{ isDefaultBadge: boolean }>(({ isDefaultBadge }) => {
  const containerStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
  };

  if (isDefaultBadge) {
    return {
      ...containerStyle,
      paddingLeft: 33,
      height: 25,
    };
  }
  return {
    ...containerStyle,
    height: 32,
    width: '100%',
  };
});

const BadgeText = styled(Tiny)<{ isDefaultBadge: boolean }>(
  ({ isDefaultBadge, theme: { colors } }) => {
    if (isDefaultBadge) {
      return {
        background: colors.bannerBlue,
        borderTopRightRadius: 15,
        paddingRight: 18,
        paddingTop: 4,
        paddingLeft: 1,
      };
    }
    return {
      background: colors.bannerBlue,
      borderTopRightRadius: 15,
      borderTopLeftRadius: 15,
      paddingTop: 7,
      paddingLeft: 12,
      width: '100%',
    };
  }
);

const BookEnd = (props) => (
  // COMM-2961: The actual shape is 31px wide but it was showing up with a 1px gap on iOS
  // I haven't been able to track down the cause of the gap, but extending the SVG and then
  // shifting it 1px fixes it
  <View style={{ position: 'relative', left: 1 }}>
    <Svg width="32px" height="25px" viewBox="0 0 32 25" {...props}>
      <Path
        d="M31.696 25H144V15c0-8.284-6.716-15-15-15H0c3.544 0 8.402 5.112 14.573 15.336A20 20 0 0031.696 25z"
        stroke="none"
        strokeWidth={1}
        fill="#0D2DA0"
        fillRule="evenodd"
      />
    </Svg>
  </View>
);

interface BadgeProps {
  isDefaultBadge?: boolean;
  text?: string;
}

const Badge = ({ isDefaultBadge = true, text }: BadgeProps) => (
  <Container isDefaultBadge={isDefaultBadge} row>
    {isDefaultBadge && <BookEnd />}
    <BadgeText
      isDefaultBadge={isDefaultBadge}
      variant={isDefaultBadge ? 'tinyBoldWhite' : 'tinyWhite'}
    >
      {text}
    </BadgeText>
  </Container>
);

export default Badge;
