import { Booking } from 'ts-frontend/types';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

export interface ApiResponse<T = unknown> {
  data?: T;
}

function getClientBookings(roomID: number): Promise<ApiResponse<Booking[]>> {
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/bookings`)
    .then((res) => res.data);
}

const checkInWizardApiHelper = {
  getClientBookings,
};

export default checkInWizardApiHelper;
