import {
  EmotionThemeProvider,
  Grid,
  Row,
  Col,
  View,
  PanelHeader,
  BackNextButton,
  ResponsiveLayoutWithHeader,
  useWindowWidthState,
  useEmotionTheme,
  TextDS,
  TouchableView,
  styled,
  spacing,
} from '@talkspace/react-toolkit';
import { XMarkLarge } from '@talkspace/react-toolkit/src/designSystems/icons';
import { Route, Switch, useHistory } from '../../core/routerLib';
import { useCloseModal } from '../../utils/ModalsContextProvider';
import TeenspaceProfileSetupContainer from './containers/TeenspaceProfileSetupContainer';
import TeenspaceWelcomeScreen from './components/TeenspaceWelcomeScreen';
import Guidelines from './components/TeenspaceCommunityGuidelines';
import DailyQuestion from './components/DailyQuestion';
import { useCommunityState } from '../hooks/CommunityContext';

const { space025, space050 } = spacing;

const BackArrow = styled(BackNextButton)(() => {
  return {
    display: 'inline-block',
    borderRadius: space050,
    marginBottom: space025,
  };
});

const Routes = () => {
  const { isMobile, isTablet } = useWindowWidthState();
  const { colorRoles } = useEmotionTheme();
  const { displayName, isLoading } = useCommunityState();
  const closeModal = useCloseModal();
  const history = useHistory();
  const handleBackButtonClick = () => {
    history.goBack();
  };
  const columnParams = { xs: 4, sm: 6, lg: 4 };
  const isInWelcomeScreen = history.location.pathname === '/community/teenspace/onboarding/welcome';
  const showBackButton = !isInWelcomeScreen
    ? history.location.pathname.includes('/community/teenspace/onboarding')
    : false;
  return (
    <EmotionThemeProvider version="2.0.0">
      <ResponsiveLayoutWithHeader
        dataQa="teenspaceOnboardingHeader"
        renderHeader={() => (
          <PanelHeader
            useNewNavHeader
            renderLeft={() => (
              <>
                {showBackButton ? (
                  <BackArrow
                    onPress={handleBackButtonClick}
                    hidden
                    primaryColor={colorRoles.icons.iconBrandDefault}
                    caretColor={colorRoles.icons.iconBrandDefault}
                    dataQa="teenspaceOnboardingHeaderBackButton"
                  />
                ) : (
                  <View />
                )}
                <View flex={1} align="center" justify="center">
                  <TextDS variant="headingMd">Teenspace</TextDS>
                </View>
              </>
            )}
            renderRight={() => (
              <TouchableView
                align="end"
                onPress={() => closeModal({ navigateTo: 'home', metadata: { path: '/' } })}
                data-qa="teenspaceOnboardingHeaderCloseButton"
                flex={1}
                justify="end"
                style={{ padding: `${space050}px` }}
              >
                <XMarkLarge size="major" colorType="brand" />
              </TouchableView>
            )}
          />
        )}
      >
        <Grid style={{ height: '100%' }}>
          <Row style={{ height: '100%' }}>
            {(!isMobile || isTablet) && (
              <Col xs={0} sm={1} lg={4}>
                {null}
              </Col>
            )}
            <Col {...columnParams}>
              <Switch>
                <Route
                  exact
                  path={
                    displayName && !isLoading
                      ? '/community/teenspace/onboarding/daily-question'
                      : '/community/teenspace/onboarding/welcome'
                  }
                  component={displayName && !isLoading ? DailyQuestion : TeenspaceWelcomeScreen}
                />
                <Route
                  exact
                  path="/community/teenspace/onboarding/sign-up"
                  component={TeenspaceProfileSetupContainer}
                />
                <Route
                  exact
                  path="/community/teenspace/onboarding/daily-question"
                  component={DailyQuestion}
                />
                <Route
                  exact
                  path="/community/teenspace/onboarding/guidelines/:option"
                  component={Guidelines}
                />
              </Switch>
            </Col>
          </Row>
        </Grid>
      </ResponsiveLayoutWithHeader>
    </EmotionThemeProvider>
  );
};

export default Routes;
