import { useCallback, useEffect } from 'react';

interface Props {
  bottomElementRef: React.RefObject<Element>;
  hasMore: boolean;
  onLoadMore: () => void;
}
const useInfiniteScroll = ({ bottomElementRef, hasMore, onLoadMore }: Props) => {
  const onReachedBottom = useCallback(() => {
    if (hasMore) {
      onLoadMore();
    }
  }, [hasMore, onLoadMore]);

  useEffect(() => {
    const bottomElement = bottomElementRef.current;

    const bottomElementObserver = new IntersectionObserver(
      (entries, observer) => {
        if (entries[0].isIntersecting && hasMore) {
          observer.unobserve(entries[0].target);
          onReachedBottom();
        }
      },
      { threshold: 1 }
    );

    if (bottomElement) {
      if (hasMore) {
        bottomElementObserver.observe(bottomElementRef.current);
      }
    }
    return () => {
      if (bottomElement) {
        bottomElementObserver.unobserve(bottomElement);
      }
    };
  }, [bottomElementRef, onReachedBottom, hasMore]);
};

export default useInfiniteScroll;
