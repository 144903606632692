import { useQuery } from 'react-query';
import ApiHelper, { GetCustomerInformationMessage } from '../utils/ApiHelper';

import { customerInformationQueryKey } from '../utils/queryKeys';

interface QueryProps {
  userID: number;
}

const fetchCustomerInformation =
  ({ userID }: QueryProps) =>
  async () => {
    const api = new ApiHelper();
    const {
      data: { data },
    } = await api.getCustomerInformationV3(userID);
    return data;
  };

const useQueryCustomerInformation = ({ userID }: QueryProps) =>
  useQuery<GetCustomerInformationMessage['data'], Error>({
    queryKey: customerInformationQueryKey({ userID }),
    queryFn: fetchCustomerInformation({ userID }),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(userID),
  });

export default useQueryCustomerInformation;
