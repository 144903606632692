import { FunctionComponent } from 'react';
import * as React from 'react';
import { Tiny, useEmotionTheme, View } from '@talkspace/react-toolkit';
import { IntakeStatusStates } from '@/treatmentIntake/treatmentIntakeLauncher/types';
import styled from '@/core/styled';
import ArrowLeft from '@/assets/ArrowLeft';
import { webOnlyStyle } from '@/core/styled/styleHelpers';

const ArrowRight = styled(ArrowLeft)({
  ...webOnlyStyle({ transform: 'rotate(180deg) scale(0.86)' }),
});

type Direction = 1 | -1;

interface Props {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  intakeStatusState: IntakeStatusStates;
  intakeStatus: string;
  freezeDueAnimation: boolean;
}

const StepsViewStyled = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: 10,
  marginTop: -30,
  marginBottom: 20,
});

const ArrowWrapper = styled(View)({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 30,
});

const StepTextWrapper = styled(ArrowWrapper)({
  width: 70,
});

const StepsNavigator: FunctionComponent<Props> = ({
  step,
  setStep,
  intakeStatusState,
  intakeStatus,
  freezeDueAnimation,
}) => {
  const stepCompletedAtDatesLength = Object.keys(intakeStatusState).length;
  const { colors } = useEmotionTheme();

  const handleClick = (direction: Direction) => {
    setStep((prevStep) => prevStep + direction);
  };

  const shouldShowLeftArrow = step > 0;
  const shouldShowRightArrow = step < intakeStatusState[intakeStatus].step;

  const textToShow =
    step === 3 ? 'Completed!' : `Step  ${step + 1} / ${stepCompletedAtDatesLength - 1}`;

  return (
    <StepsViewStyled>
      <ArrowWrapper
        style={{ visibility: shouldShowLeftArrow ? 'visible' : 'hidden' }}
        onClick={() => !freezeDueAnimation && handleClick(-1)}
      >
        <ArrowLeft color={colors.green} />
      </ArrowWrapper>
      <StepTextWrapper>
        <Tiny variant="tinyBoldGrey" style={{ fontSize: 12 }}>
          {textToShow}
        </Tiny>
      </StepTextWrapper>
      <ArrowWrapper
        style={{ visibility: shouldShowRightArrow ? 'visible' : 'hidden' }}
        onClick={() => !freezeDueAnimation && shouldShowRightArrow && handleClick(1)}
      >
        <ArrowRight color={colors.green} />
      </ArrowWrapper>
    </StepsViewStyled>
  );
};

export default StepsNavigator;
