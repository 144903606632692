import Svg, { Circle, Defs, Ellipse, Path, Rect, Stop } from 'svgs';

const BookFirstSession = () => (
  <Svg width="269" height="320" viewBox="0 0 269 320" fill="none">
    <Circle r="120" transform="matrix(-1 0 0 1 138 169)" fill="url(#paint0_linear_408_46006)" />
    <Path
      d="M139.229 69.0768C154.423 75.5441 188.965 64.7174 204.337 58.4957C190.676 56.6556 187.496 47.5304 180.5 34.9213C173.504 22.3121 167.529 17.6674 157.244 14.743C146.959 11.8187 129.868 20.8353 125.212 30.2626C120.557 39.6899 120.237 60.9927 139.229 69.0768Z"
      fill="url(#paint1_linear_408_46006)"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M132.522 35.6369C132.465 35.3691 132.631 35.1115 132.891 35.0614L167.203 28.459C167.464 28.4089 167.721 28.5853 167.777 28.8531C167.833 29.1208 167.668 29.3784 167.408 29.4285L133.095 36.0309C132.835 36.081 132.578 35.9046 132.522 35.6369Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.141 49.1466C136.084 48.8789 136.25 48.6213 136.51 48.5712L170.823 41.9688C171.083 41.9187 171.34 42.0951 171.396 42.3628C171.452 42.6306 171.287 42.8882 171.027 42.9383L136.714 49.5407C136.454 49.5908 136.197 49.4144 136.141 49.1466Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M137.627 56.2418C137.571 55.9741 137.736 55.7165 137.997 55.6664L161.439 51.1555C161.7 51.1054 161.957 51.2819 162.013 51.5496C162.069 51.8173 161.904 52.075 161.644 52.125L138.201 56.6359C137.941 56.686 137.684 56.5096 137.627 56.2418Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M134.259 42.3346C134.203 42.0669 134.368 41.8093 134.628 41.7592L167.643 35.4065C167.903 35.3564 168.16 35.5328 168.217 35.8006C168.273 36.0683 168.108 36.3259 167.847 36.376L134.833 42.7287C134.572 42.7788 134.316 42.6023 134.259 42.3346Z"
      fill="white"
    />
    <Rect
      x="188.477"
      y="64"
      width="46.6335"
      height="48.6222"
      transform="rotate(16.0917 188.477 64)"
      fill="#6159D1"
    />
    <Ellipse
      cx="205.864"
      cy="92.4732"
      rx="13.0676"
      ry="12.9153"
      transform="rotate(16.0917 205.864 92.4732)"
      fill="#FF0075"
    />
    <Path
      d="M226.331 94.5557L258.432 88.6613L248.395 123.456L223.242 105.262L226.331 94.5557Z"
      fill="#6159D1"
    />
    <Ellipse
      cx="254.767"
      cy="106.449"
      rx="8.07119"
      ry="18.3599"
      transform="rotate(16.0917 254.767 106.449)"
      fill="url(#paint2_linear_408_46006)"
    />
    <Path
      d="M38.655 120.632C34.9215 119.537 28.7516 111.092 26.1333 107.006L4.27683 125.19C5.46851 129.232 9.8426 137.826 17.8056 139.863C25.7686 141.899 33.0501 138.453 35.6955 136.475C41.7829 133.004 56.2054 124.543 65.1967 118.469C76.4359 110.877 81.039 107.346 85.2834 98.6398C88.6789 91.6749 85.9921 81.5107 84.2243 77.2991L58.5747 93.0553C59.6362 94.556 61.9327 98.8851 62.6269 104.196C63.4948 110.835 56.7367 114.672 52.8851 116.922C49.0335 119.172 43.3219 122.002 38.655 120.632Z"
      fill="url(#paint3_linear_408_46006)"
    />
    <Path
      d="M10.7909 112.268C15.3837 108.452 23.8393 104.268 26.2704 106.859C27.6945 108.403 26.1011 113.193 20.5229 118.128C14.9446 123.063 6.50408 126.876 4.18007 124.88C1.85606 122.884 6.19812 116.084 10.7909 112.268Z"
      fill="#FFC200"
    />
    <Path
      d="M71.8032 79.8033C77.2786 77.1384 82.6393 74.0642 84.402 76.6939C85.9078 78.9196 79.7152 84.9885 74.5019 88.1998C69.2887 91.4111 60.6978 94.617 58.5466 92.6714C56.3955 90.7257 66.3279 82.4683 71.8032 79.8033Z"
      fill="#FFC200"
    />
    <Path
      d="M74.7244 27.9932C81.6128 27.3189 88.1748 31.8708 90.5948 34.2311C89.5818 24.4528 92.9585 19.3951 99.2053 15.3489C105.452 11.3027 118.115 11.8085 122.336 22.2611C125.712 30.6233 123.18 48.3365 121.491 56.1479L114.738 87C108.773 85.0331 93.6338 79.7169 80.8024 74.1871C64.7632 67.2749 58.3475 57.328 58.0098 45.3581C57.6721 33.3881 66.1138 28.8362 74.7244 27.9932Z"
      fill="url(#paint4_linear_408_46006)"
    />
    <Path
      d="M78.9885 132.006C82.7609 132.617 94.8341 137.273 100.399 139.525L102.438 152.906C95.3014 151.377 85.2333 144.877 81.6648 142.328C78.0964 139.78 74.6554 138.887 72.1065 138.505C69.5576 138.123 66.1166 136.848 61.911 134.554C58.5464 132.719 50.5684 130.476 47 129.584L49.8038 128.437C50.0587 127.29 47 125.761 47 125.124C47 124.486 48.5293 124.869 49.5489 125.761C50.5684 126.653 53.3722 127.163 56.176 127.418C58.9798 127.672 65.2245 129.329 68.793 130.476C72.3614 131.623 74.2731 131.241 78.9885 132.006Z"
      fill="#E15830"
    />
    <Path
      d="M187.698 124.995C181.275 127.85 169.813 133.492 164.886 135.956L162.464 150.229C165.608 147.978 172.379 143.093 174.316 141.563C176.738 139.652 178.65 138.759 182.728 137.358C186.806 135.956 189.355 133.024 192.668 129.838C195.982 126.652 199.678 124.995 203.501 123.211C206.56 121.784 208.174 120.322 208.599 119.77L204.903 118.496C205.54 117.986 205.923 116.966 205.923 116.329C205.923 115.692 207.579 115.692 207.707 114.672C207.834 113.653 207.579 113.908 205.923 114.672C204.266 115.437 203.629 116.712 202.354 117.986C201.08 119.26 195.727 121.427 187.698 124.995Z"
      fill="#E15830"
    />
    <Path
      d="M140.799 261.743C140.085 258.787 139.737 256.093 139.652 255.116L151.504 253.077C151.504 254.989 150.739 257.793 150.739 264.292C150.739 270.792 152.141 271.684 152.523 277.037C152.906 282.389 151.759 288.889 151.504 293.732C151.249 298.575 151.886 299.722 152.141 302.143C152.396 304.565 150.357 307.114 149.337 308.77C148.318 310.427 146.151 315.652 146.024 316.417C145.896 317.182 145.132 319.858 144.24 319.603C143.769 319.469 143.716 318.958 143.795 318.479C143.627 318.878 143.354 319.223 142.965 319.093C142.52 318.945 142.498 318.473 142.584 318.079C142.449 318.384 142.231 318.635 141.946 318.376C141.545 318.013 141.768 317.2 141.937 316.819C141.761 317.205 141.327 317.865 140.926 317.564C140.518 317.258 140.756 316.502 140.926 316.162C140.799 316.374 140.467 316.723 140.161 316.417C139.779 316.035 140.289 315.652 140.544 315.143C140.799 314.633 142.455 311.574 143.985 307.496C145.514 303.418 145.004 299.085 143.985 293.604C142.965 288.124 142.328 274.233 142.455 271.047C142.583 267.861 141.691 265.439 140.799 261.743Z"
      fill="#E15830"
    />
    <Path
      d="M180.561 252.058C177.808 249.713 176.015 246.833 175.463 245.686L184.512 237.402C186.423 240.376 191.649 246.961 197.256 249.51C204.266 252.696 211.912 262.636 214.334 265.44C216.755 268.244 220.451 269.901 224.019 271.048C227.588 272.195 228.225 275.763 228.48 277.038C228.735 278.312 229.5 280.351 231.284 282.773C233.068 285.194 233.323 286.596 232.813 287.106C232.422 287.496 231.844 287.06 231.572 286.761C231.957 287.204 232.543 288.14 232.048 288.635C231.539 289.145 231.071 288.762 230.901 288.507C231.199 288.975 231.692 289.986 231.284 290.292C230.876 290.598 230.434 290.249 230.264 290.037C230.477 290.462 230.799 291.413 230.392 291.821C230.033 292.18 229.583 291.776 229.335 291.443C229.562 291.807 229.748 292.368 229.117 292.458C228.225 292.586 226.696 288.507 225.549 286.086C224.402 283.665 221.471 280.096 217.52 276.655C213.569 273.214 193.433 261.234 192.286 260.342C191.139 259.45 184.002 254.99 180.561 252.058Z"
      fill="#E15830"
    />
    <Path
      d="M133.279 232.432C132.668 227.64 130.73 212.763 129.838 205.924L167.18 198.787C172.065 208.557 182.04 228.787 182.855 231.54C183.671 234.293 185.234 236.765 185.914 237.657C180.918 243.469 174.826 246.536 172.405 247.343C167.817 243.061 158.598 228.226 154.563 221.344L156.474 253.333C156.092 253.29 154.41 253.46 150.739 254.48C147.069 255.499 139.864 255.584 136.72 255.499C135.828 249.807 133.891 237.224 133.279 232.432Z"
      fill="url(#paint5_linear_408_46006)"
    />
    <Path
      d="M120.024 119.134C119.209 120.867 120.194 121.81 120.789 122.065L125.122 129.075L132.896 137.358C133.831 134.682 135.369 128.081 134.043 123.085C132.387 116.84 121.044 116.967 120.024 119.134Z"
      fill="#222F2D"
    />
    <Path
      d="M134.681 140.035C135.497 142.074 137.995 145.727 139.142 147.299C135.063 152.295 128.096 150.4 125.123 148.828C125.293 147.214 125.531 143.527 125.123 141.691C124.613 139.397 122.574 138.888 119.898 137.741C117.221 136.594 117.476 135.192 117.476 134.554C117.476 134.045 117.646 131.029 117.731 129.584C117.561 129.542 117.119 129.355 116.711 128.947C116.202 128.437 117.349 127.29 118.496 126.398C119.643 125.506 119.515 124.869 119.77 123.722C119.974 122.804 120.535 122.235 120.79 122.065C122.625 122.575 124.018 122.107 124.486 121.81C124.74 126.271 127.417 126.143 128.181 125.761C128.946 125.379 131.113 124.614 131.495 126.908C131.877 129.202 130.603 129.839 130.603 131.368C130.603 132.898 132.005 134.555 132.642 135.192C133.279 135.829 133.661 137.486 134.681 140.035Z"
      fill="#E15830"
    />
    <Path
      d="M121.809 130.858C120.28 131.368 118.453 131.325 117.731 131.24C117.323 131.954 117.391 133.067 117.476 133.534C119.515 133.84 121.214 131.877 121.809 130.858Z"
      fill="#BF4724"
    />
    <Path
      d="M131.623 147.808C124.282 148.114 107.238 141.478 99.6343 138.122C100.144 146.279 101.376 153.925 101.928 156.729C104.859 157.111 111.869 159.864 116.457 167.817C121.045 175.769 125.93 197.554 127.799 207.452C130.093 205.795 137.613 205.795 150.23 203.374C160.323 201.436 169.304 198.743 172.532 197.639C163.356 179.032 160.043 165.65 160.17 159.278C160.272 154.18 165.735 150.187 168.454 148.827L165.141 134.936C157.027 139.099 138.964 147.502 131.623 147.808Z"
      fill="url(#paint6_linear_408_46006)"
    />
    <Path
      d="M15 59.4852C25.88 56.4911 29.4753 47.2475 29.913 43C34.19 59.1822 39.0735 62.812 40.9806 62.604C30.2507 65.0991 27.5057 76.1783 27.4744 81.4061C25.5235 67.1486 18.3453 60.8515 15 59.4852Z"
      fill="#BCCCFF"
    />
    <Path
      d="M210 32.7978C219.891 28.3114 222.364 17.73 222.364 13C222.995 26.2552 227.186 31.7215 229.203 32.7978C222.399 35.9927 220.171 46.5345 219.908 51.4061C218.435 37.6071 212.689 33.251 210 32.7978Z"
      fill="#BCCCFF"
    />
    <Defs>
      <linearGradient
        id="paint0_linear_408_46006"
        x1="-1.19209e-07"
        y1="120"
        x2="240"
        y2="120"
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#DBF4F2" />
        <Stop offset="1" stopColor="#E3E8FB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_408_46006"
        x1="144.498"
        y1="15.2607"
        x2="172.839"
        y2="73.7565"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset="0.536458" stopColor="#0FC1A7" />
        <Stop offset="1" stopColor="#009B8E" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_408_46006"
        x1="254.767"
        y1="88.0891"
        x2="254.711"
        y2="113.29"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset="0.375" stopColor="#BCCCFF" />
        <Stop offset="1" stopColor="#8EA8FD" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_408_46006"
        x1="57.3544"
        y1="69.4141"
        x2="35.1822"
        y2="144.971"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset="0.460938" stopColor="#F5A623" />
        <Stop offset="1" stopColor="#C46F31" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_408_46006"
        x1="91"
        y1="13"
        x2="91"
        y2="87"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset="0.479167" stopColor="#FF0075" />
        <Stop offset="1" stopColor="#CF005F" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_408_46006"
        x1="134.554"
        y1="240.461"
        x2="174.826"
        y2="202.228"
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#007697" />
        <Stop offset="1" stopColor="#005368" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_408_46006"
        x1="104.732"
        y1="182.345"
        x2="176.101"
        y2="160.17"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset="0.322917" stopColor="#60B0BB" />
        <Stop offset="0.635417" stopColor="#09B198" />
        <Stop offset="1" stopColor="#007697" />
      </linearGradient>
    </Defs>
  </Svg>
);

export default BookFirstSession;
