import { FunctionComponent } from 'react';
import ModalClosingScreen from '../../2storybook/components/ModalClosingScreen';
import EmailSentContainer from '../containers/EmailSent';

const EmailSent: FunctionComponent<{
  email: string;
  roomID: number;
  clickFromRoomBanner: boolean;
}> = ({ email, roomID, clickFromRoomBanner = false }) => (
  <ModalClosingScreen showCloseButton={clickFromRoomBanner}>
    <EmailSentContainer email={email} roomID={roomID} clickFromRoomBanner={clickFromRoomBanner} />
  </ModalClosingScreen>
);

export default EmailSent;
