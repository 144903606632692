import { SkipNavigation } from '@talkspace/react-toolkit';
import { History } from 'history';
import { ID_ROOM_DETAILS_BUTTON, ID_LVS_DASHBOARD_BUTTON } from '../utils/IDConstants';

export const useA11y = (history: History) => {
  const cameFromRoomDashBoard =
    history.location.state && history.location.state.from
      ? history.location.state.from === 'dashboard'
      : false;
  const cameFromChat =
    history.location.state && history.location.state.from
      ? history.location.state.from === 'chat'
      : false;

  let skipNavs: SkipNavigation[] | undefined;
  if (cameFromRoomDashBoard) {
    skipNavs = [
      {
        buttonText: 'Return to dashboard',
        returnID: ID_LVS_DASHBOARD_BUTTON,
      },
    ];
  } else if (cameFromChat) {
    skipNavs = [
      {
        buttonText: 'Return to chat room',
        returnID: ID_ROOM_DETAILS_BUTTON,
      },
    ];
  }

  return { skipNavs };
};

export default useA11y;
