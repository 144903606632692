import { FunctionComponent, useEffect, useState } from 'react';
import { TabRadioGroup } from '@talkspace/react-toolkit';
import { trackUserSettingsEvent } from '@/utils/analytics/events';
import { getUserData } from '@/auth/helpers/token';
import styled from '../../../core/styled';
import { NavLink, RouteComponentProps, withRouter } from '../../../core/routerLib/routerLib';
import { webOnlyStyle } from '../../../core/styled/styleHelpers';
import { ID_TAB_PANEL } from '../../../utils/IDConstants';

function checkIsActive(routeName: string, location: { pathname: string }) {
  if (location.pathname.includes(routeName)) return true;
  return false;
}

const activeStyle = {
  backgroundColor: '#EFF3FA',
  borderRadius: 5,
};

const Link = styled(NavLink)({
  color: 'black',
  fontFamily: 'roboto',
  fontSize: 15,
  fontWeight: 400,
  height: 38,
  width: 184,
  paddingTop: 8,
  paddingBottom: 10,
  paddingLeft: 20,
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  ...webOnlyStyle({
    '&:focus, &:hover': {
      textDecoration: 'none',
      color: 'black',
    },
    '&:focus:hover': {
      outline: 'none',
    },
  }),
});

interface MyAccountOptionProps {
  toUrl: string;
  displayText: string;
  path: string;
  onClick?: () => void;
}

// these NavLinks are acting as tab options in a tablist so we provide the necessary aria-attributes: role="tab", aria-selected, aria-controls
// only the selected option should be part of the tab order
const MyAccountOption: FunctionComponent<MyAccountOptionProps> = ({
  toUrl,
  displayText,
  path,
  onClick,
}) => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    if (path.includes(toUrl)) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [path, toUrl]);

  return (
    <Link
      role="tab"
      aria-selected={active}
      aria-controls={ID_TAB_PANEL}
      to={toUrl}
      activeStyle={activeStyle}
      isActive={(_, location) => checkIsActive(`${toUrl}`, location)}
      tabIndex={active ? 0 : -1}
      onClick={onClick}
    >
      {displayText}
    </Link>
  );
};

// isTabList prop ensures that the container of the tab options gets role="tablist"
// for this particular grouping one of the tab options will be selected on render so we give the prop intialSelection so that only the selected tab has tabindex="0"
const MyAccountMenu: FunctionComponent<RouteComponentProps> = ({ match }) => {
  const { id: userID } = getUserData();
  return (
    <TabRadioGroup initialSelection isTabList legendText="Choose an account option">
      <MyAccountOption
        path={match.path}
        toUrl="personal-information"
        displayText="Personal information"
        onClick={() => {
          trackUserSettingsEvent(userID, 'View Personal Information', 'Personal Information');
        }}
      />
      <MyAccountOption
        path={match.path}
        toUrl="manage-subscription"
        displayText="Payment and plan"
        onClick={() => {
          trackUserSettingsEvent(userID, 'View Payment Plan', 'Personal Information');
        }}
      />
      <MyAccountOption
        path={match.path}
        toUrl="account-details"
        displayText="Login and security"
        onClick={() => {
          trackUserSettingsEvent(userID, 'View Login Security', 'Personal Information');
        }}
      />
      <MyAccountOption
        path={match.path}
        toUrl="notifications"
        displayText="Notifications"
        onClick={() => {
          trackUserSettingsEvent(userID, 'View Notifications', 'Personal Information');
        }}
      />
    </TabRadioGroup>
  );
};

export default withRouter(MyAccountMenu);
