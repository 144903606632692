import { BackNextButton, useEmotionTheme } from '@talkspace/react-toolkit';
import { useHistory } from 'react-router';

const BackButton = () => {
  const history = useHistory();
  const { colors } = useEmotionTheme();

  return (
    <BackNextButton
      onPress={history.goBack}
      style={{
        display: 'inline-block',
        borderRadius: 5,
        marginBottom: 2,
      }}
      roundedFocusStyle
      primaryColor={colors.permaTalkspaceDarkGreen}
      caretColor={colors.permaTalkspaceDarkGreen}
      dataQa="parentalConsentDetailsBackButtonDataQa"
    />
  );
};

export default BackButton;
