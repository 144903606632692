/**
 * This function returns a modified version of str
 * with the match right after marker converted in the shape of
 * <replace>.
 * @example
 * // returns "/room-offer/<roomID>/offer/1"
 * replaceMatch("/room-offer/<roomID>/offer/1", "room-offer", "roomID");
 * @param str Base string to run .replace on.
 * @param marker The path that we're looking for before the match.
 * @param replace The string to replace with.
 * @returns {Stirng} modified version of str.
 */
const replaceMatch = (str: string, marker: string, replace: string) => {
  const regex = new RegExp(`(\\/${marker}\\/)(?:\\d+)`, 'g');
  return str.replace(regex, `$1<${replace}>`);
};

const anonymizePath = (path: string) => {
  let anonymizedPath = path;
  if (path.startsWith('/room/')) {
    anonymizedPath = replaceMatch(anonymizedPath, 'room', 'roomID');
  }
  if (path.startsWith('/room-offer/')) {
    anonymizedPath = replaceMatch(anonymizedPath, 'room-offer', 'roomID');
  }
  if (path.startsWith('/room-reactivation/')) {
    anonymizedPath = replaceMatch(anonymizedPath, 'room-reactivation', 'roomID');
  }
  return anonymizedPath;
};

export default anonymizePath;
