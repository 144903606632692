import {
  BaseButton,
  Button,
  ExtraHuge,
  Large,
  SparklingEnvelope,
  Standard,
  View,
} from '@talkspace/react-toolkit';
import { useEffect } from 'react';

import { useTranslation } from '@talkspace/i18n';
import { trackBookFirstSessionPrompt } from '../../utils/analytics/events';
import MeetYourProviderWrapper from './MeetYourProviderWrapper';
import useAllowClosing from '@/onboarding/hooks/useAllowClosing';

interface Props {
  roomID: number | null;
  handleStartMessagingSessionClick: () => void;
  handleBookLiveSessionClick: () => void;
}

const BookAsyncSessionView = ({
  roomID,
  handleBookLiveSessionClick,
  handleStartMessagingSessionClick,
}: Props) => {
  useEffect(() => {
    if (roomID) {
      trackBookFirstSessionPrompt({
        roomID,
        modalityNudge: 'Match Based on Availability',
      });
    }
  }, [roomID]);
  const { t: tBookingScreen } = useTranslation('bookingScreen');
  const allowClosing = useAllowClosing();
  return (
    <MeetYourProviderWrapper
      showCloseButton={allowClosing}
      preventReopening
      roomID={roomID}
      titleText={tBookingScreen('titles.bookSession', 'Book session')}
    >
      <View align="center" justify="space-between" style={{ maxWidth: 335, textAlign: 'center' }}>
        <View style={{ marginBottom: 40, marginTop: 20 }}>
          <SparklingEnvelope />
        </View>
        <ExtraHuge style={{ marginBottom: 12 }}>
          {tBookingScreen('bookMessaging.title', 'Book your first messaging session')}
        </ExtraHuge>
        <Large variant="largeDarkGrey" style={{ marginBottom: 30 }}>
          {tBookingScreen(
            'bookMessaging.description',
            'Due to a nationwide shortage of Providers and live availability, the fastest path to getting help is messaging therapy'
          )}
        </Large>
        <Button
          dataQa="startAMessagingSessionButton"
          disabled={!roomID}
          text={tBookingScreen('bookMessaging.start', 'Start a messaging session')}
          onClick={handleStartMessagingSessionClick}
          style={{ width: '100%', marginBottom: 24 }}
        />
        <BaseButton
          dataQa="bookALiveSessionButton"
          disabled={!roomID}
          onClick={handleBookLiveSessionClick}
          style={{ width: '100%' }}
        >
          <Standard variant="standardBoldGreen">
            {tBookingScreen('bookMessaging.bookLive', 'Book a live session')}
          </Standard>
        </BaseButton>
      </View>
    </MeetYourProviderWrapper>
  );
};

export default BookAsyncSessionView;
