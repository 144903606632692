/* eslint-disable camelcase */
import { AppSource } from 'ts-frontend/types';
import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';

function getSetupIntentInner({
  userID,
  planID,
  currency,
}: {
  userID?: any;
  planID?: number;
  currency?: string;
}) {
  if (userID) {
    return apiWrapper
      .post(`${apiHelper().apiEndpoint}/v2/payments/users/${userID}/setup-intent`, {
        planID,
        currency,
      })
      .then((res) => res?.data?.data);
  }

  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v2/payments/setup-intent`, { planID, currency })
    .then((res) => res?.data?.data);
}

async function validateCouponQM(couponCode: string, planID?: number): Promise<any> {
  throw new Error('validateCouponQM should be called only on qm');
}

const paymentApiHelperInner = {
  validateCouponQM,
  getSetupIntentInner,
  getSource: () => AppSource.client,
};

export default paymentApiHelperInner;
