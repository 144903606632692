import { View, Small } from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import MoneyInHand from './MoneyInHand';

const Responsive = styled(View)<{ mobile?: boolean }>(({ mobile }) => {
  return {
    display: mobile ? 'none' : 'flex',
    '@media only screen and (max-width: 494px)': {
      display: mobile ? 'flex' : 'none',
    },
  };
});

export default function FinancialAid() {
  return (
    <>
      <Responsive row align="center">
        <MoneyInHand style={{ marginRight: 6 }} />
        <Small>Can’t afford therapy? Apply for financial aid at checkout</Small>
      </Responsive>

      <Responsive mobile align="center">
        <MoneyInHand style={{ marginBottom: 7 }} />
        <Small>Can't afford therapy?</Small>
        <Small>Apply for financial aid at checkout</Small>
      </Responsive>
    </>
  );
}
