import { VoidFunctionComponent } from 'react';
import { Heading1, Caption, Button } from '@talkspace/react-toolkit';
import styled from '../../../core/styled';
import NotepadIcon from '../../../assets/icons/Notepad';
import FullscreenWrapper from '../../../components/FullscreenWrapper';
import { useHistory } from '../../../core/routerLib/routerLib';
import useScreenSize, { screenSizes, isResponsive } from '../../../core/hooks/useScreenSize';
import { trackEvent } from '../../../utils/analytics/eventTracker';

const Description = styled(Caption)<{ screenSize: screenSizes }>(({ screenSize }) => {
  return {
    marginTop: 10,
    textAlign: 'center',
    paddingLeft: isResponsive(screenSize) ? 10 : 0,
    paddingRight: isResponsive(screenSize) ? 10 : 0,
  };
});

const Title = styled(Heading1)<{ screenSize: screenSizes }>(({ screenSize }) => {
  return {
    textAlign: 'center',
    fontSize: 38,
    lineHeight: 'inherit',
    ...(isResponsive(screenSize)
      ? {
          fontSize: 32,
          fontWeight: 500,
          letterSpacing: -0.4,
          lineHeight: '30px',
          width: 'auto',
        }
      : {}),
  };
});

const ContinueButton = styled(Button)({
  marginTop: 25,
});

const WelcomeBackCTReactivation: VoidFunctionComponent<{
  roomID: number;
  lastActiveRoomID: number;
  lastActiveRoomType: string;
}> = ({ roomID, lastActiveRoomID, lastActiveRoomType }) => {
  const screenSize = useScreenSize();
  const history = useHistory();

  const onContinuePress = () => {
    trackEvent('User Clicks Continue', {
      eventCategory: 'CT Reactivation',
      eventCategoryKey: 'Funnel Name',
    });

    history.push('/eligibility-widget?source=ctReactivation', {
      roomID,
      roomType: roomID === lastActiveRoomID ? lastActiveRoomType : 'privateRoom',
    });
  };

  return (
    <FullscreenWrapper
      style={{
        maxWidth: 510,
        minHeight: 575,
      }}
    >
      <NotepadIcon />
      <Title screenSize={screenSize}>Let's get started</Title>
      <Description screenSize={screenSize}>
        Check your coverage to get matched with a Talkspace provider. You’ll need to answer a few
        questions so we can find the right fit. Ready?
      </Description>

      <ContinueButton
        dataQa="welcomeBackContinueButton"
        text="Continue"
        onPress={onContinuePress}
      />
    </FullscreenWrapper>
  );
};

export default WelcomeBackCTReactivation;
