import { useEffect, useRef } from 'react';
import { useMainActions } from '../../hooks/mainContext';

export const useA11y = () => {
  const { setDropdownMenuVisible } = useMainActions();
  const touchableRef = useRef<HTMLDivElement>(null);
  // allows for down arrow press to open account menu dropdown
  useEffect(() => {
    const eventHandler = (e: KeyboardEvent) => {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setDropdownMenuVisible(true);
      }
    };
    let currentRef;
    if (touchableRef.current) currentRef = touchableRef.current;
    if (currentRef) {
      currentRef.addEventListener('keydown', eventHandler);
    }

    return () => {
      if (touchableRef && currentRef) currentRef.removeEventListener('keydown', eventHandler);
    };
  }, [setDropdownMenuVisible]);

  return { touchableRef };
};

export default useA11y;
