import * as React from 'react';
import Svg, { G, Circle, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

interface CircleCheckmarkProps {
  primaryColor?: string;
  secondaryColor?: string;
  height?: number;
  width?: number;
}

const CircleCheckmark: React.FunctionComponent<CircleCheckmarkProps> = ({
  primaryColor,
  secondaryColor,
  height = 21,
  width = 21,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'check mark';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 21 21"
      {...otherProps}
    >
      <G
        transform="translate(3 3)"
        stroke={secondaryColor || colors.white}
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Circle strokeWidth={3} fill={primaryColor || colors.emerald} cx={7.5} cy={7.5} r={9} />
        <Path
          strokeWidth={2}
          strokeLinecap="round"
          d="M4.5 8.02458914L7.10562261 10.012136 10.4537536 4.82901226"
        />
      </G>
    </Svg>
  );
};

export default CircleCheckmark;
