import { useReducer, useCallback } from 'react';
import { toast } from '@talkspace/react-toolkit';
import { Preferences } from 'ts-frontend/types/Notifications';
import { NotificationPreferencesByName } from '../reducers/notificationPreferences';
import API from '../utils/marketingCommunicationsApiHelper';
import { getUserData } from '../../auth/helpers/token';
import {
  marketingCommunicationsReducer,
  initialState,
  APINotificationPreference,
  MarketingCommunicationsByName,
  APIResponse,
  State,
} from '../reducers/marketingCommunications';

const arrayToNotificationPreferencesByName = (
  arr: APINotificationPreference[]
): MarketingCommunicationsByName =>
  arr.reduce((prev, { name = '', email = false, sms = false, push = false }) => {
    return {
      ...prev,
      [name as Preferences]: { email, sms, push },
    };
  }, {}) as MarketingCommunicationsByName;

export default function useMarketingCommunications(): [
  State,
  {
    getMarketingCommunications;
    patchMarketingCommunications;
    setIsEditing;
  }
] {
  const [state, dispatch] = useReducer(marketingCommunicationsReducer, initialState);

  function getMarketingCommunications(): void {
    dispatch({ type: 'getMarketingCommunications' });
    const { id } = getUserData();
    API.getMarketingCommunications(id)
      .then((res) => {
        const { data } = res.data as APIResponse;
        dispatch({
          type: 'receiveGetMarketingCommunications',
          payload: {
            marketingCommunicationsByName: arrayToNotificationPreferencesByName(data.categories),
          },
        });
      })
      .catch((error) => {
        const errorMessage = Number.isInteger(+error.message)
          ? 'There was an error. Please try again.'
          : error.message;
        toast(errorMessage);
        dispatch({ type: 'setIsError' });
      });
  }

  function patchMarketingCommunications(
    payload: APINotificationPreference[],
    formState: NotificationPreferencesByName
  ): void {
    const { id } = getUserData();
    dispatch({ type: 'updateMarketingCommunications' });
    if (payload.length) {
      API.patchMarketingCommunications(id, { categories: payload })
        .then(() => {
          dispatch({
            type: 'receiveUpdateMarketingCommunications',
            payload: {
              marketingCommunicationsByName: formState,
              isEditing: false,
            },
          });
        })
        .catch((error) => {
          const errorMessage = Number.isInteger(+error.message)
            ? 'There was an error. Please try again.'
            : error.message;
          toast(errorMessage);
          dispatch({ type: 'setIsError' });
        });
    }
  }

  function setIsEditing(isEditing: boolean): void {
    dispatch({ type: 'setIsEditing', payload: { isEditing } });
  }

  return [
    state,
    {
      getMarketingCommunications: useCallback(getMarketingCommunications, []),
      patchMarketingCommunications: useCallback(patchMarketingCommunications, []),
      setIsEditing: useCallback(setIsEditing, []),
    },
  ];
}
