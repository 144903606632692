import { useReducer, useRef, useCallback } from 'react';
import { wizardActionReducer, wizardActionInitialState, WizardActionState } from 'stepWizard';

import treatmentIntakeApiHelper, {
  MentalHealthPayload,
  MedicalHealthPayload,
} from '../../treatmentIntakeApiHelper';

export default function useMentalHealth(): [
  WizardActionState,
  {
    submitIntakeResults: (roomId: number, clientUserID, payload: MentalHealthPayload) => void;
  }
] {
  const [state, dispatch] = useReducer(wizardActionReducer, wizardActionInitialState());
  const apiRef = useRef(treatmentIntakeApiHelper);

  function submitMatchingIntake(
    roomId: number,
    clientUserID,
    payload: MentalHealthPayload & Pick<MedicalHealthPayload, 'controlledSubstances'>
  ) {
    const { controlledSubstances, ...mentalHealthPayload } = payload;
    dispatch({
      type: 'requestAction',
    });
    apiRef.current
      .submitMentalHealthResults(roomId, clientUserID, mentalHealthPayload)
      .then(() =>
        apiRef.current.submitMedicalHealthResults(roomId, clientUserID, {
          controlledSubstances,
          isOnboarding: true,
        })
      )
      .then(() => {
        dispatch({ type: 'receiveAction' });
      })
      .catch((error) => {
        dispatch({
          type: 'setError',
          error: (error && error.message) || '',
        });
      });
  }

  return [
    state as WizardActionState,
    {
      submitIntakeResults: useCallback(submitMatchingIntake, []),
    },
  ];
}
