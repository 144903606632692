import { useCallback, FunctionComponent } from 'react';
import { Modal, View, Spinner } from '@talkspace/react-toolkit';
import { RouteComponentProps } from '../../core/routerLib';

import { useCloseModal } from '../../utils/ModalsContextProvider';
import MeetYourProviderWrapper from '../components/MeetYourProviderWrapper';
import {
  useMeetYourProviderActions,
  useMeetYourProviderState,
} from '../hooks/meetYourProviderContext';
import InformedConsentActionView from '../../components/InformedConsentAction/InformedConsentActionView';
import useQueryOnboarding from '../../onboarding/hooks/useQueryOnboarding';
import { getUserData } from '../../auth/helpers/token';
import { getAgreedDate } from '../../utils/informedConsent/informedConsentHelpers';

const InformedConsentScreen: FunctionComponent<RouteComponentProps> = ({ history }) => {
  const { informedConsent, informedConsentV2, therapistID, roomID } = useMeetYourProviderState();
  const { putAgreeToInformedConsentV2, setAgreedToInformedConsentAction } =
    useMeetYourProviderActions();
  const closeModal = useCloseModal();
  const { id: userID } = getUserData();
  const { data: onboardingData, isLoading } = useQueryOnboarding({
    roomID: roomID || undefined,
    userID,
  });

  const handleOnAgreePress = useCallback(() => {
    if (roomID)
      putAgreeToInformedConsentV2(userID).then(() => {
        if (onboardingData?.steps.some((it) => it.step === 'BOOK_LIVE_SESSION')) {
          setAgreedToInformedConsentAction();
          history.push('/meet-your-provider/book-session');
        } else if (onboardingData?.steps.some((it) => it.step === 'BOOK_ASYNC_SESSION')) {
          setAgreedToInformedConsentAction();
          history.push('/meet-your-provider/book-async-session');
        } else if (onboardingData?.steps.some((it) => it.step === 'ASYNC_SESSION_STARTED')) {
          const asyncSessionStep = onboardingData?.steps.find(
            (it) => it.step === 'ASYNC_SESSION_STARTED'
          );
          const startDate = asyncSessionStep?.metadata?.startDate;

          setAgreedToInformedConsentAction();
          history.push('/meet-your-provider/async-session-started', { startDate });
        } else {
          closeModal();
        }
      });
  }, [
    roomID,
    putAgreeToInformedConsentV2,
    userID,
    onboardingData?.steps,
    setAgreedToInformedConsentAction,
    history,
    closeModal,
  ]);

  if (!informedConsent && !informedConsentV2?.hasV2) {
    closeModal();
    return null;
  }

  const agreedDate = getAgreedDate(informedConsent, informedConsentV2);
  const hasConsented = !informedConsentV2?.shouldSignConsent;

  return (
    <Modal isVisible titleText="Informed consent">
      <MeetYourProviderWrapper titleText="Informed consent">
        <View style={{ maxWidth: 720, alignSelf: 'center' }}>
          {!isLoading && roomID ? (
            <InformedConsentActionView
              hasV1InformedConsent={!!informedConsent}
              hasV2InformedConsent={informedConsentV2?.hasV2}
              onAgreePress={handleOnAgreePress}
              error=""
              hasReturnButton={false}
              therapistID={therapistID}
              agreedDate={agreedDate}
              hasConsented={hasConsented}
            />
          ) : (
            <Spinner />
          )}
        </View>
      </MeetYourProviderWrapper>
    </Modal>
  );
};

export default InformedConsentScreen;
