import { FunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

interface DownCaretIconProps {
  color?: string;
  width?: string;
  height?: string;
  style?: object;
}

const DownCaretIcon: FunctionComponent<DownCaretIconProps> = ({
  color,
  width = '12',
  height = '8',
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'down arrow';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...otherProps}
    >
      <Path
        d="M348.781 30.281a1.102 1.102 0 01-.005-1.557l-.052.052a1.098 1.098 0 011.547-.005L356 34.5l-5.729 5.729a1.095 1.095 0 01-1.547-.005l.052.052a1.096 1.096 0 01.005-1.557L353 34.5l-4.219-4.219z"
        transform="translate(-346 -31) rotate(90 352 34.5)"
        fill={color || colors.heatherGrey}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
};
export default DownCaretIcon;
