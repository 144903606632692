import React from 'react';

import { EmotionStyle, TouchableView, View } from '@talkspace/react-toolkit';

import { ReactionsEnum, EmojiSize } from '../types';

import CryingFaceEmoji from '../assets/emojis/CryingFaceEmoji';
import RaisingHandsEmoji from '../assets/emojis/RaisingHandsEmoji';
import MendingHeartEmoji from '../assets/emojis/MendingHeartEmoji';
import SmilingFaceWithOpenHandsEmoji from '../assets/emojis/SmilingFaceWithOpenHandsEmoji';
import FaceHoldingBackTearsEmoji from '../assets/emojis/FaceHoldingBackTearsEmoji';

const ReactionNameToComponent: Record<
  ReactionsEnum,
  ({ size }: { size: EmojiSize }) => JSX.Element
> = {
  [ReactionsEnum.CRYING_FACE]: CryingFaceEmoji,
  [ReactionsEnum.RAISING_HANDS]: RaisingHandsEmoji,
  [ReactionsEnum.MENDING_HEART]: MendingHeartEmoji,
  [ReactionsEnum.SMILING_FACE_WITH_OPEN_HANDS]: SmilingFaceWithOpenHandsEmoji,
  [ReactionsEnum.FACE_HOLDING_BACK_TEARS]: FaceHoldingBackTearsEmoji,
};

interface Props {
  reactionName: ReactionsEnum;
  size: EmojiSize;
  circleColor?: string;
  onPress?: () => void;
  style?: EmotionStyle;
}
const EmojiReaction = ({ reactionName, size, circleColor, onPress, style }: Props) => {
  const isClickable = Boolean(onPress);
  const ReactionView = ReactionNameToComponent[reactionName];
  const circleWidth = 4;
  return (
    <View
      align="center"
      justify="center"
      style={{
        borderRadius: '50%',
        width: size + circleWidth,
        height: size + circleWidth,
        backgroundColor: circleColor,
        ...style,
      }}
    >
      {isClickable ? (
        <TouchableView dataQa={`reaction-${reactionName}`} onPress={onPress}>
          <ReactionView size={size} />
        </TouchableView>
      ) : (
        <View data-qa={`reaction-${reactionName}`}>
          <ReactionView size={size} />
        </View>
      )}
    </View>
  );
};

export default EmojiReaction;
