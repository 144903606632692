import { VoidFunctionComponent } from 'react';
import { View, ExtraHuge, Large } from '@talkspace/react-toolkit';
import styled from '../../core/styled';
import { SuperbillsIconBig } from '../../components/icons';
import Description from '../components/Description';
import SuperbillsWrapper from '../components/SuperbillsWrapper';

const MarginedWrapper = styled(View)({
  marginBottom: 22,
});

const NoSuperbillsScreen: VoidFunctionComponent = () => (
  <SuperbillsWrapper>
    <MarginedWrapper>
      <SuperbillsIconBig />
    </MarginedWrapper>
    <MarginedWrapper>
      <ExtraHuge style={{ marginButton: 20 }}>Your superbills aren’t available yet.</ExtraHuge>
    </MarginedWrapper>
    <Description>
      <Large variant="largeDarkGrey">
        Superbills become available 3 days after the end of each billing cycle or session and
        contain all of the information necessary for you to file an out of network insurance claim.
        Superbills require a diagnosis code, so they will only be available once your provider has
        submitted a diagnosis.
      </Large>
    </Description>
  </SuperbillsWrapper>
);

export default NoSuperbillsScreen;
