import { FunctionComponent } from 'react';
import AccountPanel from '../AccountPanel';
import AccountPanelRow from '../AccountPanelRow';
import { MarketingCommunicationsByName } from '../../reducers/marketingCommunications';

const MarketingCommunications: FunctionComponent<{
  marketingCommunicationsByName: MarketingCommunicationsByName;
}> = ({ marketingCommunicationsByName }) => {
  const { marketingNews, marketingPromotions, marketingBlog, marketingEducation } =
    marketingCommunicationsByName;

  return (
    <AccountPanel>
      <AccountPanelRow label="Promotions" description={marketingPromotions.email ? 'On' : 'Off'} />
      <AccountPanelRow label="Product news" description={marketingNews.email ? 'On' : 'Off'} />
      <AccountPanelRow
        label="Product education"
        description={marketingEducation.email ? 'On' : 'Off'}
      />
      <AccountPanelRow label="Blog updates" description={marketingBlog.email ? 'On' : 'Off'} />
    </AccountPanel>
  );
};

export default MarketingCommunications;
