import { FunctionComponent } from 'react';
import * as React from 'react';
import {
  Avatar,
  View,
  Spinner,
  BaseButton,
  Button,
  Large,
  Big,
  Standard,
  Small,
  BookingIcon,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import moment from 'moment';
import styled from '@/core/styled';
import apiHelper from '@/core/api/apiHelper';
import { withRouter, RouteComponentProps } from '@/core/routerLib';
import { webOnlyStyle } from '@/core/styled/styleHelpers';
import { BookingErrorIcon } from '../icons/index';
import { TherapistTimeslot, BookingScreenType } from '../../reducers/schedulerReducer';

const ConfirmBookingSection: FunctionComponent<{
  selectedTimeslot: TherapistTimeslot;
  selectedCreditDuration: number;
  isSubmitting: boolean;
  handleConfirmAppointment: () => void;
  onBack: (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Event>) => void;
}> = ({
  isSubmitting,
  handleConfirmAppointment,
  selectedTimeslot,
  selectedCreditDuration,
  onBack,
}) => (
  <>
    <Large variant="largeDarkGrey" style={{ textAlign: 'center', maxWidth: 410, marginTop: 17 }}>
      You are about to schedule a live video session on{' '}
      {moment(selectedTimeslot.start).format('dddd, MMM D')} from{' '}
      {moment(selectedTimeslot.start).format('h:mm A')} to{' '}
      {moment(selectedTimeslot.start).add(selectedCreditDuration, 'minutes').format('h:mm A')}.
      Please be ready for your appointment at least 10 minutes prior to the scheduled time.
    </Large>

    <Large variant="largeDarkGrey" style={{ textAlign: 'center', marginTop: 20, maxWidth: 410 }}>
      If you need to reschedule or cancel a session, please do so{' '}
      <span style={{ fontWeight: 'bold' }}>at least 12 hours in advance of your appointment</span>.
      If you don't show up or are unable to cancel/reschedule your appointment in advance with at
      least 12 hours notice, you will lose that session credit.
    </Large>
    <Button
      onPress={() => handleConfirmAppointment()}
      style={{ marginTop: 30, fontWeight: 'bold' }}
      text="Confirm appointment"
    />
    <BaseButton
      style={{ marginTop: 20 }}
      onPress={(e) => {
        if (!isSubmitting) onBack(e);
      }}
    >
      <Standard variant="standardDarkGrey">Book another time</Standard>
    </BaseButton>
  </>
);

const FormSubmittedSection: FunctionComponent<{
  bookingScreen: BookingScreenType;
  isSubmitting: boolean;
  onBack: () => void;
  onClose: () => void;
}> = ({ isSubmitting, bookingScreen, onBack, onClose }) => {
  const { colors } = useEmotionTheme();
  return (
    <>
      <Large
        style={{
          width: 284,
          marginTop: 20,
          marginBottom: 55,
          textAlign: 'center',
        }}
        variant="largeDarkGrey"
      >
        {bookingScreen === 'formSubmitSuccess' && 'Your session has been successfully scheduled!'}
        {bookingScreen === 'formSubmitError' &&
          'There was an issue booking your session. Please try again.'}
      </Large>
      {!!isSubmitting && (
        <Spinner style={{ width: 50, height: 50 }} containerStyle={{ width: 60 }} />
      )}
      {bookingScreen === 'formSubmitSuccess' && (
        <Standard onPress={onClose} variant="standardDarkGrey">
          Close
        </Standard>
      )}
      {bookingScreen === 'formSubmitError' && (
        <Large onPress={onBack} variant="largeBoldWide" style={{ color: colors.green }}>
          Back
        </Large>
      )}
    </>
  );
};

const CurrentTherapistAvatar = styled(Avatar)<{
  bookingScreen?: BookingScreenType;
}>(({ bookingScreen }) => {
  return {
    zIndex: 1,
    marginRight: -16,
    borderRight: bookingScreen === 'confirmScreen' ? '6px solid white' : '0px solid transparent',
    borderRadius: '50%',
    marginLeft: bookingScreen === 'confirmScreen' ? 75 : 25,
    ...webOnlyStyle({
      transition:
        '.5s cubic-bezier(0.305, 0.03, 0.515, 0.955), margin-left .5s cubic-bezier(0.55, 0.055, 0.675, 0.19) ',
    }),
  };
});

const BookingCircleContainer = styled(View)({
  position: 'absolute',
  marginLeft: 90,
  marginTop: -20,
  width: 120,
  height: 120,
});

const BookingCircle = styled(View)<{ bookingScreen?: BookingScreenType }>(
  ({ bookingScreen, theme: { colors } }) => {
    return {
      zIndex: 2,
      height: bookingScreen !== 'confirmScreen' ? 120 : 0,
      width: bookingScreen !== 'confirmScreen' ? 120 : 0,
      borderRadius: '50%',
      backgroundColor: colors.white,
      opacity: bookingScreen !== 'confirmScreen' ? 1 : 0,
      ...webOnlyStyle({
        boxShadow: '0 7px 12px rgba(84, 97, 136, 0.15)',
        transition: 'all .7s cubic-bezier(0.68, -0.55, 0.265, 1.55)',
      }),
    };
  }
);

const AvatarRow: FunctionComponent<{
  therapistUserID: number;
  bookingScreen: BookingScreenType | undefined;
  selectedTimeslot: TherapistTimeslot;
}> = ({ therapistUserID, bookingScreen, selectedTimeslot }) => {
  const isBookingError = bookingScreen === 'formSubmitError';

  return (
    <View
      align="center"
      style={{
        width: 300,
        marginTop: bookingScreen === 'confirmScreen' ? 15 : 48,
        transition: '.4s',
      }}
      row
    >
      <CurrentTherapistAvatar
        bookingScreen={bookingScreen}
        image={`${apiHelper().cdnEndpoint}/images/application/therapist/220/${therapistUserID}.jpg`}
        height={76}
        width={76}
      />
      <View
        style={{
          width: bookingScreen === 'confirmScreen' ? 0 : 110,
          ...webOnlyStyle({
            transition: 'width .7s cubic-bezier(0.68, -0.55, 0.265, 1.55)',
          }),
        }}
      />
      <BookingCircleContainer align="center" justify="center">
        <BookingCircle align="center" bookingScreen={bookingScreen}>
          {isBookingError && <BookingErrorIcon style={{ marginTop: 30 }} />}
          {!isBookingError && <BookingIcon style={{ marginTop: 21 }} />}
          {!isBookingError && bookingScreen !== 'confirmScreen' && (
            <Big style={{ marginTop: 6 }}>{moment(selectedTimeslot.start).format('MMM D')}</Big>
          )}
          {!isBookingError && bookingScreen !== 'confirmScreen' && (
            <Small>{moment(selectedTimeslot.start).format('h:mmA')}</Small>
          )}
        </BookingCircle>
      </BookingCircleContainer>
      <Avatar image="" height={76} width={76} />
    </View>
  );
};

const SchedulerConfirmBooking: FunctionComponent<
  {
    isSubmitting: boolean;
    selectedTimeslot?: TherapistTimeslot;
    selectedCreditDuration?: number;
    therapistUserID?: number;
    bookingScreen?: BookingScreenType;
    handleConfirmAppointment: () => void;
  } & RouteComponentProps
> | null = ({
  isSubmitting,
  bookingScreen,
  selectedTimeslot,
  selectedCreditDuration,
  therapistUserID,
  handleConfirmAppointment,
  history,
}) => {
  if (!therapistUserID || !selectedTimeslot || !selectedCreditDuration || !bookingScreen)
    return null;
  return (
    <View align="center">
      <AvatarRow
        therapistUserID={therapistUserID}
        selectedTimeslot={selectedTimeslot}
        bookingScreen={bookingScreen}
      />
      {bookingScreen === 'confirmScreen' ? (
        <ConfirmBookingSection
          isSubmitting={isSubmitting}
          handleConfirmAppointment={handleConfirmAppointment}
          selectedTimeslot={selectedTimeslot}
          selectedCreditDuration={selectedCreditDuration}
          onBack={() => history.push('/scheduler/select-timeslot')}
        />
      ) : (
        <FormSubmittedSection
          isSubmitting={isSubmitting}
          bookingScreen={bookingScreen}
          onClose={() => history.push('/')}
          onBack={() => history.push('/scheduler/select-timeslot')}
        />
      )}
    </View>
  );
};

export default withRouter(SchedulerConfirmBooking);
