/* eslint-disable import/prefer-default-export */

import {
  AllJSONLaunchDarklyExperimentNames,
  AllLaunchDarklyExperimentNames,
  AllLaunchDarklyExperiments,
} from 'ts-frontend/types';
import isTreatmentExtraLogic, {
  IsExtraTreatmentContext,
} from './experiments/isTreatmentExtraLogic';

export const convertValueType = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (err) {
    return value;
  }
};

export const getLDExperimentEventInformation = (
  flag: AllLaunchDarklyExperiments[AllLaunchDarklyExperimentNames],
  flagKey: string
): { experimentName: string; variantName: string } | null => {
  if (typeof flag === 'boolean' || typeof flag === 'string') {
    return {
      experimentName: flagKey,
      variantName: flag.toString(),
    };
  }
  if (flag?.experimentActive)
    return {
      experimentName: flag?.experimentName || flagKey,
      variantName: flag?.variant,
    };
  return null;
};

/**
 * Check if the flag is active and the variant is any non-control (treatment) variant
 * Only works for experiments using LaunchDarklyJSONExperimentMultiVariant
 * @returns boolean
 */
export const isLDExperimentTreatment = (
  flags: AllLaunchDarklyExperiments,
  flag: AllJSONLaunchDarklyExperimentNames,
  context?: IsExtraTreatmentContext
): boolean => {
  const experiment = flags[flag];
  if (typeof experiment !== 'object') return false;
  if ('experimentActive' in experiment) {
    if (!experiment.experimentActive) return false;
    if (context && !isTreatmentExtraLogic[flag]?.(context)) return false;
    return experiment.variant !== 'control';
  }
  return false;
};
