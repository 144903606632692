import { FunctionComponent } from 'react';
import * as React from 'react';
import { Modal, View, TouchableView, AnimatedSpinner } from '@talkspace/react-toolkit';
import { COLORS } from 'chat/utils/design';
import styled from '../../../core/styled';
import { webOnlyStyle } from '../../../core/styled/styleHelpers';
import CloseButton from '../../assets/CloseButton';
import { EmotionStyle } from '../../../core/styled/styled';

const fullScreenStyles = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: 'initial',
  height: 'initial',
  borderRadius: 0,
};

const Wrapper = styled(TouchableView)<{ height?: number }>(({ theme: { window }, height }) => {
  return {
    borderRadius: 10,
    backgroundColor: COLORS.white,
    position: 'relative',
    cursor: 'initial',
    alignItems: 'stretch',
    width: 500,
    minHeight: 390,
    height,
    maxWidth: 750,
    ...webOnlyStyle({
      boxShadow: '0 4px 10px -4px rgba(0,40,65,0.40)',
      transition: '.2s',
      ...(window.isMobile ? fullScreenStyles : {}),
    }),
  };
});

const ChatModal: FunctionComponent<{
  isVisible: boolean;
  isLoading?: boolean;
  height?: number;
  onBackdropPress?: (e: React.MouseEvent<Element, MouseEvent>) => void;
  wrapperStyle?: EmotionStyle;
  titleText?: string;
}> = ({ children, isLoading, onBackdropPress, wrapperStyle, height, titleText, ...otherProps }) => (
  <Modal onBackdropPress={onBackdropPress} titleText={titleText} {...otherProps}>
    {/* Wrapper onPress prevents modal press from reaching blanket underneath */}
    <Wrapper
      // should not be recognized by SRs as button or be in the tab order
      elementType="div"
      tabIndex={-1}
      height={height}
      style={wrapperStyle}
      onPress={(e) => e.stopPropagation()}
    >
      <View row justify="end" align="center" style={{ height: 67, paddingRight: 16 }}>
        <CloseButton onPress={onBackdropPress} />
      </View>
      {isLoading ? <AnimatedSpinner style={{ paddingBottom: 67 }} /> : children}
    </Wrapper>
  </Modal>
);

export default ChatModal;
