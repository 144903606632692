import moment from 'moment';
import { FunctionComponent } from 'react';
import { Button, View } from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import { IntakeStatusStates } from '@/treatmentIntake/treatmentIntakeLauncher/types';

const LAST_STEP = 3;

const ButtonForLauncherStyled = styled(Button)({
  alignSelf: 'center',
  width: '100%',
  transition: 'all 1s',
});
const ButtonWrapper = styled(View)<{ isMobile }>(({ isMobile }) => {
  return {
    width: isMobile ? 260 : 320,
  };
});

const wizardPaths = (roomID) => [
  `/emergency-contact-wizard/room/${roomID}/source/chat`,
  `/mental-health-wizard/room/${roomID}/source/chat`,
  `/medical-health-wizard/room/${roomID}/source/chat`,
];

interface Props {
  roomID: string;
  step: number;
  historyPush: (url: string) => void;
  onClosePress: () => void;
  isMobile: boolean;
  intakeStatusState: IntakeStatusStates;
  intakeStatus: string;
}

const ButtonForLauncher: FunctionComponent<Props> = ({
  roomID,
  step,
  historyPush,
  onClosePress,
  isMobile,
  intakeStatusState,
  intakeStatus,
}) => {
  let buttonText = 'Begin';

  const currentStepKey = Object.keys(intakeStatusState).find(
    (key) => intakeStatusState[key].step === step
  );

  if (currentStepKey === 'submitted') {
    buttonText = 'Return to chat';
  } else if (currentStepKey && currentStepKey !== intakeStatus) {
    buttonText = `Completed ${moment(intakeStatusState[currentStepKey].completedAt).format(
      'MMM D, YYYY'
    )}`;
  }

  const handleClick = () => {
    if (step === LAST_STEP) {
      onClosePress();
    } else {
      historyPush(wizardPaths(roomID)[step]);
    }
  };

  return (
    <ButtonWrapper isMobile={isMobile}>
      <ButtonForLauncherStyled disabled={currentStepKey !== intakeStatus} onClick={handleClick}>
        {buttonText}
      </ButtonForLauncherStyled>
    </ButtonWrapper>
  );
};

export default ButtonForLauncher;
