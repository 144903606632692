import { Button, EmotionStyle, Huge, Large, View, useEmotionTheme } from '@talkspace/react-toolkit';
import { useHistory } from '@/core/routerLib';

type Props = {
  roomID: number;
  style?: EmotionStyle;
};
const SwitchProviderBox = ({ roomID, style }: Props) => {
  const { colors } = useEmotionTheme();
  const history = useHistory();
  return (
    <View
      style={{
        width: 310,
        backgroundColor: colors.permaSolitudeNew,
        borderRadius: 12,
        padding: 24,
        ...style,
      }}
    >
      <Huge>Would you like to switch providers?</Huge>
      <Large style={{ marginTop: 8 }}>
        We want to make sure you're getting the most out of Talkspace
      </Large>
      <Button
        style={{ marginTop: 24, width: '100%', backgroundColor: colors.permaTalkspaceDarkGreen }}
        onPress={() => history.push(`/switch-provider/room/${roomID}`)}
      >
        Switch providers
      </Button>
    </View>
  );
};

export default SwitchProviderBox;
