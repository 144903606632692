import { useQuery } from 'react-query';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { EMe } from '../entities/Me';
import { getClientQueryKey } from '../utils/queryKeys';

interface QueryProps {
  clientUserID: string | number;
  disabled?: boolean;
}
type QueryData = EMe;

const fetchClient =
  ({ clientUserID }: QueryProps) =>
  async () => {
    const {
      data: { data },
    } = await apiWrapper.get(`${apiHelper().apiEndpoint}/v2/clients/${clientUserID}`);
    const me = new EMe(data);
    return me;
  };

const useQueryGetClient = ({ clientUserID, disabled }: QueryProps) =>
  useQuery<QueryData, Error>({
    queryKey: getClientQueryKey({ clientUserID }),
    queryFn: fetchClient({ clientUserID }),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(clientUserID) && !disabled,
  });

export default useQueryGetClient;
