import { History } from 'history';
import { Route, Switch } from 'react-router';
import { useWizardActions, WizardScreen } from 'stepWizard';
import { Screen } from '@talkspace/react-toolkit';
import useWindowWidth from '../hooks/useWindowWidth';
import FinancialAidEligibilityContainer from './containers/FinancialAidEligibilityContainer';
import ApplyFinancialAidContainer from './containers/ApplyFinancialAidContainer';
import financialAidWizardScheme from './financialAidWizardScheme';

const Routes = ({ history }: { history: History }) => {
  const { isMobile } = useWindowWidth();
  const { onExitClick } = useWizardActions();
  const showBackButton = history.location.pathname.includes('/financial-aid-eligibility');
  return (
    <Switch>
      <Screen
        showBackButton={showBackButton}
        onCloseButtonClick={onExitClick}
        title={isMobile ? ' ' : ''}
      >
        <Route
          exact
          path="/financial-aid/financial-aid-eligibility"
          component={FinancialAidEligibilityContainer}
        />
        <Route
          exact
          path="/financial-aid/apply-financial-aid"
          component={ApplyFinancialAidContainer}
        />
        <Route
          exact
          path={[
            '/financial-aid/room/:roomID/source/:source/context-id/:contextID',
            '/financial-aid/room/:roomID/source/:source',
          ]}
          render={() => <WizardScreen wizardScheme={financialAidWizardScheme} />}
        />
      </Screen>
    </Switch>
  );
};

export default Routes;
