import { useEffect } from 'react';

import { Spinner } from '@talkspace/react-toolkit';

import { WizardScreen, useWizardActions } from 'stepWizard';
import { useParams } from '@/core/routerLib';

import useUserPrompts from '../../clientChat/hooks/useUserPrompts';
import NPSWizardScheme from '../NPSWizardScheme';

const NPSWizardContainer = () => {
  const { setState } = useWizardActions();
  const { roomID } = useParams<{ roomID: string }>();
  const [{ userPrompts }, { getUserPrompts }] = useUserPrompts();

  useEffect(() => {
    getUserPrompts(Number(roomID));
  }, [getUserPrompts, roomID]);

  const isLoading = !userPrompts || userPrompts.length === 0;

  useEffect(() => {
    if (!isLoading) {
      setState({
        daysDelay: userPrompts.find((prompt) => prompt.eventType === 'nps')?.metadata?.daysDelay,
      });
    }
  }, [setState, isLoading, userPrompts]);

  if (isLoading) return <Spinner />;
  return <WizardScreen wizardScheme={NPSWizardScheme} />;
};

export default NPSWizardContainer;
