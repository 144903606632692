import {
  NAV_SHELL_HEADER_HEIGHT,
  NAV_SHELL_MOBILE_HEADER_HEIGHT,
  NAV_SHELL_MOBILE_FOOTER_HEIGHT,
  NAV_SHELL_TABLET_SIDEBAR_WIDTH,
  NAV_SHELL_DESKTOP_SIDEBAR_WIDTH,
} from 'ts-frontend/constants';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { useWindowWidthState, getScreenSafeAreaInsets } from '@talkspace/react-toolkit';
import { useKeyboardStatus } from 'ts-ionic/plugins/keyboard';
import { useLocation } from '@/core/routerLib/routerLib';
import useVideoCallMinimized from './useVideoCallMinimized';

/*
 * designed to pass props to ResponsiveLayoutWithHeader
 */

const HACK_AVOID_DOUBLE_SCROLL = 2;

const useNavShellWindowHeight = () => {
  const location = useLocation();
  const { isMobile, isTablet } = useWindowWidthState();
  const isVideoCallMinimized = useVideoCallMinimized();
  const useNewNav = useNewMemberNav();
  const { isKeyboardOpen } = useKeyboardStatus();

  if (!useNewNav) {
    return {
      useNewNav,
      outsideHeaderHeight: undefined,
      outsideFooterHeight: undefined,
    };
  }
  const safeAreaInsets = getScreenSafeAreaInsets();

  let sidebarWidth = NAV_SHELL_DESKTOP_SIDEBAR_WIDTH;
  if (isTablet) sidebarWidth = NAV_SHELL_TABLET_SIDEBAR_WIDTH;
  if (isMobile) sidebarWidth = 0;

  const isInMobileVideoCall =
    isMobile && location.pathname.includes('/modal/video-call') && !isVideoCallMinimized;
  const hideMobileHeader = isInMobileVideoCall;
  const hideMobileFooter = isInMobileVideoCall || isKeyboardOpen;
  const mobileHeaderHeight = hideMobileHeader
    ? 0
    : NAV_SHELL_MOBILE_HEADER_HEIGHT + safeAreaInsets.top + HACK_AVOID_DOUBLE_SCROLL;
  const mobileFooterHeight = hideMobileFooter
    ? 0
    : NAV_SHELL_MOBILE_FOOTER_HEIGHT + safeAreaInsets.bottom;
  const newNavHeaderHeight = isMobile ? mobileHeaderHeight : NAV_SHELL_HEADER_HEIGHT;
  const newNavFooterHeight = isMobile ? mobileFooterHeight : 0;
  return {
    useNewNav,
    outsideHeaderHeight: newNavHeaderHeight,
    outsideFooterHeight: newNavFooterHeight,
    sidebarWidth,
  };
};

export default useNavShellWindowHeight;
