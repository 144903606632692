import apiHelper from '../../core/api/apiHelper';
import apiWrapper from '../../core/api/apiWrapper';
import { Charge } from './refundWizardTypes';

interface GetAvailableRefundsApiResponse {
  data: Charge[];
}

interface ApplyRefundApiResponse {
  amount: number;
}

interface SubmitSupportRequestApiResponse {
  data: object;
}

async function getAvailableRefunds(): Promise<GetAvailableRefundsApiResponse> {
  const res = await apiWrapper.get(`${apiHelper().apiEndpoint}/v2/payments/refundable-charges`);
  return res.data;
}

async function applyRefund(charge: Charge): Promise<ApplyRefundApiResponse | undefined> {
  const res = await apiWrapper.post(`${apiHelper().apiEndpoint}/v2/payments/refund`, charge);
  return res.data;
}

async function submitSupportRequest(
  userID: number,
  bodyParams: object
): Promise<SubmitSupportRequestApiResponse | undefined> {
  const res = await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/clients/${userID}/contact-support`,
    bodyParams
  );
  return res.data;
}

const refundWizardApiHelper = {
  applyRefund,
  submitSupportRequest,
  getAvailableRefunds,
};

export default refundWizardApiHelper;
