import { Booking } from 'ts-frontend/types';
import { State } from '../reducers/checkInWizardState';
import therapistApi from '../../therapistReview/utils/therapistReviewApiHelper';
import ApiHelper from '../../utils/ApiHelper';
import { OtherWizardGenericActions } from '../../utils/genericWizardActions';
import checkInWizardApiHelper from '../utils/checkInWizardApiHelper';

const getNextBookingStartTime = (bookings: Booking[], roomID: number) =>
  bookings
    .filter(
      (booking: Booking) =>
        (booking.timekitBookingState === 'confirmed' ||
          booking.timekitBookingState === 'tentative') &&
        booking.roomID === Number(roomID) &&
        booking.status === 'active'
    )
    .map((booking: Booking) => (booking.startTime ? booking.startTime : ''))
    .sort((a, b) => (a < b ? -1 : 1))[0];

const initState = async (api, state) => {
  const therapistInfo = await therapistApi.getTherapistInfo(state.roomID);
  const bookingsResponse = await checkInWizardApiHelper.getClientBookings(state.roomID);
  const { data: bookings } = bookingsResponse;
  const ratingPostAction = (rating: number, context) => {
    const partialState: Partial<State> = {};
    partialState.checkInData = {
      rating,
      bricks: [],
      otherText: '',
    };
    context.setState(partialState);
  };
  const bricksPostAction = (checkInData, context) => {
    const partialState: Partial<State> = {};
    if (checkInData && checkInData.rating) {
      partialState.shouldShowChangeTherapist = checkInData.rating <= 3;
    }
    context.setState(partialState);
  };
  const updatedResponses = {
    ...state.responses,
    therapistID: therapistInfo.id,
  };
  return {
    therapistInfo,
    ratingPostAction,
    bricksPostAction,
    responses: updatedResponses,
    nextBookingStartTime: bookings && getNextBookingStartTime(bookings, state.roomID),
  };
};

const persist = async (api, state) => {
  if (state.userPromptID) {
    await api.updateUserPromptToDone(state.roomID, state.userPromptID);
  }
  // eslint-disable-next-line no-empty
  if (state.videoAudioStarsRating && state.checkInSource !== 'check-in') {
    await api.rateVideoCall(state.roomID, state.videoAudioStarsRating, state.videoCallID);
  }
};

const checkInWizardActions: OtherWizardGenericActions<State, ApiHelper> = {
  initState,
  persist,
};

export default checkInWizardActions;
