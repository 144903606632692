import Svg, { Path } from 'svgs';

const MessagingSessionPerson = (props) => {
  const titleText = 'person messaging';
  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width="155"
      height="150"
      viewBox="0 0 155 150"
      fill="none"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.797 144.425H120.219V3.52639H55.797V144.425ZM55.4855 144.737H120.531V3.21484H55.4855V144.737Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.85 142.969H60.1651C58.4784 142.969 57.1111 141.602 57.1111 139.915V8.59516C57.1111 6.90815 58.4784 5.54079 60.1651 5.54079H71.9935H74.5954H79.0954L87.5954 5.5H95.0954H99.5954L104.022 5.54079H115.85C117.537 5.54079 118.905 6.90815 118.905 8.59516V139.915C118.905 141.602 117.537 142.969 115.85 142.969ZM115.602 0C120.148 0 123.834 3.68555 123.834 8.2319V140.278C123.834 144.824 120.148 148.509 115.602 148.509H60.4134C55.8671 148.509 52.1815 144.824 52.1815 140.278V8.2319C52.1815 3.68555 55.8671 0 60.4134 0H115.602Z"
        fill="#68768D"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.117 19.2179H25.6733C23.2788 19.2179 21.3379 21.1588 21.3379 23.5533V50.2426C21.3379 52.6371 23.2788 54.578 25.6733 54.578H27.9865L27.866 54.6422L33.1619 59.9381C34.2077 60.984 35.9038 60.984 36.9499 59.9381L42.2458 54.6422L42.125 54.578H101.117C103.511 54.578 105.452 52.6371 105.452 50.2426V23.5533C105.452 21.1588 103.511 19.2179 101.117 19.2179Z"
        fill="#678C8E"
      />
      <Path d="M28.1906 27.6389H98.6008" stroke="#9AB3B4" strokeWidth="3" strokeLinecap="round" />
      <Path d="M28.1874 33.5709H81.4097" stroke="#9AB3B4" strokeWidth="3" strokeLinecap="round" />
      <Path d="M28.1874 40.1215H54.3891" stroke="#9AB3B4" strokeWidth="3" strokeLinecap="round" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.6257 64.6567H150.069C152.464 64.6567 154.405 66.5977 154.405 68.9922V95.6814C154.405 98.0759 152.464 100.017 150.069 100.017H147.756L147.877 100.081L142.581 105.377C141.535 106.423 139.839 106.423 138.793 105.377L133.497 100.081L133.618 100.017H74.6257C72.2312 100.017 70.2903 98.0759 70.2903 95.6814V68.9922C70.2903 66.5977 72.2312 64.6567 74.6257 64.6567Z"
        fill="#D1E2E0"
      />
      <Path d="M147.552 73.0778H77.1414" stroke="#EFF3F3" strokeWidth="3" strokeLinecap="round" />
      <Path
        d="M147.735 79.4241L93.6934 79.4241"
        stroke="#EFF3F3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <Path
        d="M147.735 85.1556L115.801 85.1556"
        stroke="#EFF3F3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.784 119.5C97.784 124.832 93.4615 129.155 88.1294 129.155C82.7973 129.155 78.4747 124.832 78.4747 119.5C78.4747 114.168 82.7973 109.845 88.1294 109.845C93.4615 109.845 97.784 114.168 97.784 119.5Z"
        fill="#005C65"
      />
      <Path
        d="M92.3016 115.932C92.574 115.642 93.03 115.627 93.32 115.9C93.6058 116.168 93.6238 116.615 93.3635 116.905L93.3518 116.918L87.1296 123.542L82.9888 120.399C82.6719 120.159 82.6099 119.707 82.8505 119.39C83.0876 119.077 83.5303 119.013 83.8465 119.242L83.86 119.252L86.9675 121.61L92.3016 115.932Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.8669 148.054C54.9747 147.332 51.1081 146.524 47.921 143.678C44.7343 140.83 44.352 143.125 43.5872 143.168C42.8223 143.21 39.5985 143.405 39.5985 143.405C39.5985 143.405 39.9035 144.411 38.7723 146.066C38.7723 146.066 38.7526 146.404 38.5835 146.787C39.8979 146.652 45.2443 146.864 45.2443 146.864C55.1445 150.094 56.7592 148.776 55.8669 148.054ZM24.7264 146.789C24.0425 146.58 23.8899 145.378 23.8899 145.378L24.7276 145.658L24.7264 145.651L17.3609 143.167C16.8876 143.369 16.306 143.751 15.5779 144.401C12.3911 147.248 8.52458 148.055 7.632 148.778C6.73975 149.5 8.35447 150.817 18.2547 147.588C18.2547 147.588 23.8372 147.366 24.9977 147.519L24.9871 147.447C24.7613 147.058 24.7264 146.789 24.7264 146.789Z"
        fill="#95BAB5"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.8367 142.982C39.8327 142.987 39.8302 142.991 39.8264 142.997C40.1657 142.356 40.3561 141.78 40.4265 141.281C40.348 141.988 40.0779 142.661 39.8367 142.982ZM59.6287 127.87C56.0186 125.507 49.812 128.488 45.0928 131.185L45.3189 128.872C45.3644 128.405 44.9971 128 44.5273 128H18.5811C18.186 128 17.8817 128.349 17.9359 128.74L18.1452 130.258L18.1474 130.315C13.4618 127.659 7.41321 124.826 3.86847 127.146C-1.65113 130.76 0.757404 135.979 4.16973 138.036C7.58206 140.093 30.7658 147.52 33.7264 148.875C36.6873 150.23 37.6407 147.721 37.6407 147.721C38.7052 147.304 38.7716 146.066 38.7716 146.066C39.9025 144.41 39.5978 143.405 39.5978 143.405C39.6408 143.334 39.6747 143.266 39.7143 143.197C39.6099 143.511 40.118 143.405 40.118 143.405L42.6278 143.223C43.6516 143.163 43.9587 142.987 43.9587 142.987L44.7756 142.369C45.9704 141.854 47.568 143.332 47.568 143.332C52.9979 141.426 57.8981 139.621 59.3275 138.759C62.7401 136.702 65.1487 131.483 59.6287 127.87ZM23.8892 145.377C23.8892 145.377 24.0415 146.58 24.7256 146.789C24.7256 146.789 24.8263 147.594 25.8565 148.444C25.8565 148.444 26.8102 150.953 29.7708 149.599C30.2471 149.381 31.2469 149.005 32.6033 148.521L25.4023 145.884L23.8892 145.377Z"
        fill="#006A6D"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.5984 143.561C39.571 143.561 39.5432 143.554 39.518 143.538C39.4445 143.494 39.4208 143.398 39.465 143.325C40.2993 141.943 40.5274 140.697 40.0909 139.906C39.8538 139.476 39.4414 139.234 38.9298 139.224C38.0076 139.207 26.8357 134.983 23.9832 133.814C23.9038 133.781 23.8655 133.69 23.8982 133.611C23.9309 133.531 24.0222 133.494 24.1013 133.526C27.2815 134.829 38.1051 138.897 38.9357 138.913C39.5541 138.925 40.0747 139.232 40.3638 139.755C40.8554 140.646 40.6252 142.006 39.732 143.485C39.7024 143.534 39.651 143.561 39.5984 143.561Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.3541 149.433C34.8345 149.433 34.269 149.294 33.6631 149.017C32.54 148.503 28.431 147.09 23.6732 145.453C21.2621 144.624 18.7688 143.767 16.3192 142.909C16.2382 142.881 16.1952 142.792 16.2239 142.711C16.2522 142.63 16.3413 142.587 16.422 142.615C18.8707 143.473 21.3637 144.33 23.7747 145.159C28.5401 146.798 32.6556 148.213 33.7927 148.733C34.723 149.159 35.5383 149.234 36.2171 148.958C37.1349 148.583 37.4929 147.674 37.4963 147.665C37.5275 147.585 37.6175 147.544 37.6976 147.575C37.7779 147.606 37.8181 147.696 37.7876 147.776C37.7717 147.818 37.3823 148.816 36.3386 149.244C36.0324 149.37 35.7037 149.433 35.3541 149.433Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.5068 145.101C39.4871 145.101 39.4669 145.098 39.4476 145.089C39.3678 145.057 39.3301 144.966 39.3628 144.886C39.7068 144.05 39.5448 143.328 38.8818 142.737C38.8176 142.68 38.812 142.581 38.869 142.517C38.9267 142.453 39.0251 142.447 39.089 142.504C40.0513 143.361 39.9255 144.337 39.6507 145.005C39.6261 145.065 39.5678 145.101 39.5068 145.101Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.1256 147.829C36.782 147.829 36.5309 147.735 36.5256 147.733C36.4455 147.702 36.4056 147.613 36.4365 147.532C36.4676 147.452 36.5576 147.412 36.6377 147.442C36.6751 147.457 37.5611 147.782 38.1761 147.011C38.2297 146.944 38.3275 146.932 38.3948 146.986C38.4624 147.04 38.4733 147.138 38.4197 147.205C38.0172 147.71 37.5125 147.829 37.1256 147.829Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.5016 146.951C38.4847 146.951 38.4679 146.949 38.4511 146.943C38.3698 146.915 38.3265 146.827 38.3542 146.745C38.7963 145.452 38.1866 144.87 38.1604 144.846C38.0975 144.787 38.0928 144.689 38.1508 144.626C38.2081 144.562 38.3056 144.557 38.3695 144.614C38.4019 144.644 39.1611 145.348 38.6489 146.846C38.6268 146.911 38.5664 146.951 38.5016 146.951Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.8173 148.957C52.7391 148.957 52.6715 148.898 52.6624 148.818C52.5126 147.463 53.6372 146.931 53.6485 146.925C53.7263 146.89 53.8186 146.924 53.8547 147.002C53.8908 147.079 53.8566 147.172 53.779 147.208C53.7407 147.226 52.8475 147.656 52.9724 148.784C52.9818 148.87 52.9201 148.946 52.8347 148.956C52.8288 148.956 52.8229 148.957 52.8173 148.957Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.7261 137.142C32.6628 137.142 32.6033 137.104 32.5803 137.041C32.5501 136.96 32.5909 136.871 32.6716 136.841C35.3773 135.83 38.0803 134.789 39.3971 134.249C40.0688 133.974 41.1567 133.326 42.5341 132.506C44.0042 131.631 45.8336 130.542 47.7434 129.569C47.82 129.529 47.9138 129.56 47.9527 129.637C47.992 129.714 47.9615 129.807 47.8848 129.846C45.9841 130.815 44.1597 131.901 42.6936 132.774C41.305 133.6 40.2084 134.254 39.5152 134.538C38.1955 135.078 35.4892 136.121 32.7806 137.132C32.7625 137.139 32.7442 137.142 32.7261 137.142Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.7725 149.754C29.7133 149.754 29.6569 149.721 29.6307 149.663C29.5949 149.585 29.6295 149.493 29.7077 149.457C30.1532 149.253 31.0838 148.899 32.5527 148.374C32.6331 148.345 32.7228 148.388 32.7518 148.469C32.7807 148.55 32.7384 148.639 32.6574 148.668C31.1972 149.189 30.2747 149.54 29.8373 149.74C29.8161 149.75 29.794 149.754 29.7725 149.754Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3721 148.556C26.146 148.556 25.879 148.514 25.6135 148.376C25.1148 148.117 24.7655 147.595 24.5755 146.826C24.5549 146.743 24.606 146.658 24.6895 146.638C24.7736 146.618 24.8574 146.668 24.878 146.752C25.045 147.428 25.34 147.881 25.7544 148.098C26.288 148.378 26.8553 148.168 26.861 148.166C26.9407 148.135 27.0311 148.176 27.0619 148.256C27.0927 148.336 27.0532 148.426 26.9731 148.457C26.9563 148.463 26.7092 148.556 26.3721 148.556Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6812 149.68C10.6756 149.68 10.6697 149.68 10.6638 149.679C10.5784 149.67 10.5167 149.593 10.5261 149.507C10.651 148.38 9.75752 147.949 9.71951 147.932C9.64193 147.895 9.60766 147.803 9.6438 147.725C9.67963 147.647 9.77247 147.614 9.85004 147.649C9.86126 147.654 10.9859 148.186 10.8361 149.542C10.827 149.621 10.7594 149.68 10.6812 149.68Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.7308 89.9551C35.7308 89.9551 35.1898 92.3052 31.9439 92.4638C28.6979 92.6224 27.1265 90.1553 27.1265 90.1553C27.1265 90.1553 22.7605 89.1767 18.3195 91.6609C13.8787 94.1446 2.5878 111.006 5.59855 117.328C8.60961 123.651 16.6247 122.847 16.6247 122.847C16.6247 122.847 14.3505 128.398 18.7915 128.925C23.2326 129.452 32.9427 128.925 32.9427 128.925C32.9427 128.925 40.0936 128.775 41.3731 128.775C42.6526 128.775 47.5454 129.904 48.223 125.99C48.9003 122.076 48.9554 119.21 48.9554 119.21C48.9554 119.21 55.2781 120.565 56.7084 115.672C58.1384 110.78 56.4071 102.575 50.3102 96.2525C44.213 89.9294 39.4191 89.6289 35.7308 89.9551Z"
        fill="#EFF3F3"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.8359 90.0825L27.1261 90.1551H29.8359V90.0825Z"
        fill="#FCFCFD"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.3648 103.884C42.3648 103.6 40.2535 103.371 37.649 103.371C35.0442 103.371 32.9329 103.6 32.9329 103.884C32.9329 103.891 32.9407 104.5 32.9438 104.508L32.9329 111.45C32.9313 112.491 33.7743 113.335 34.8152 113.335H40.2856C41.3043 113.335 42.138 112.525 42.167 111.506L42.3648 104.488L42.3633 103.887C42.3633 103.886 42.3648 103.885 42.3648 103.884Z"
        fill="#EC9E7F"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.3562 112.838C42.2761 112.791 42.1964 112.743 42.1159 112.693C42.0373 112.643 42.0145 112.538 42.0648 112.458C42.1151 112.377 42.2134 112.348 42.2981 112.402C44.3402 113.814 45.9961 112.461 47.2152 111.5C48.4342 110.538 48.9038 109.071 48.4614 107.819C47.8311 106.035 45.8168 106.954 45.7053 106.984C45.6148 107.008 45.5232 106.954 45.5005 106.863C45.478 106.773 45.5328 106.679 45.6231 106.654C45.6535 106.646 48.0465 105.625 48.7797 107.699C49.2713 109.09 48.8436 110.538 47.4251 111.763C46.1445 112.814 44.3402 114.223 42.3562 112.838Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.0371 106.677C47.0315 106.677 47.0262 106.677 47.0206 106.676C46.9355 106.667 46.8739 106.591 46.8816 106.507C46.8829 106.489 46.9212 106.568 45.9888 104.201C45.957 104.121 45.9966 104.03 46.0766 103.999C46.157 103.967 46.247 104.007 46.2785 104.087C47.2599 106.578 47.1951 106.507 47.1919 106.538C47.1832 106.618 47.1156 106.677 47.0371 106.677Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.0656 109.373C38.0656 109.373 39.4925 107.936 39.0691 107.475C38.646 107.014 37.2653 107.915 37.2653 107.915L37.5036 107.418C37.7943 106.464 37.5282 106.488 36.0814 107.182C34.6343 107.876 32.9448 108.3 32.9448 108.3V106.422C31.1771 106.548 30.7173 107.552 29.7857 109.821C28.8539 112.09 27.371 112.431 27.371 112.431C27.6912 115.712 28.6819 117.856 28.6819 117.856C29.5602 116.578 32.5214 115.594 32.5214 115.594C35.5082 115.239 38.6177 113.038 38.9753 112.091C39.2358 111.401 38.303 111.523 37.7753 111.642C38.1055 111.499 38.66 111.122 39.3704 110.122C40.5495 108.461 38.0656 109.373 38.0656 109.373ZM44.6572 104.733C44.6572 104.733 43.3157 102.203 42.5126 102.796C41.7091 103.387 42.7397 103.27 42.7275 105.014C42.7269 105.112 42.735 105.194 42.7497 105.265C42.6506 105.503 42.2017 105.885 40.5714 106.308C40.3455 106.367 40.1442 106.515 40.0551 106.731C39.9741 106.927 39.9869 107.161 40.3632 107.309C40.3632 107.309 39.0532 106.985 39.3202 107.742C39.5872 108.5 41.358 108.417 42.5484 108.418C42.5484 108.418 41.3895 108.659 40.3632 108.644C39.3367 108.63 39.4479 109.463 41.0807 109.679C42.7135 109.896 43.6799 109.821 43.6799 109.821C43.6799 109.821 41.7614 110.43 40.5661 110.276C40.5661 110.276 39.9065 110.379 40.4692 110.83C41.0315 111.282 44.3392 112.288 45.5012 110.73C46.663 109.172 47.6259 105.962 44.6572 104.733ZM31.9435 92.4639C34.9259 92.4174 35.8025 89.9045 35.8025 89.9045C35.8025 89.9045 35.0096 89.9045 33.4239 89.9045V87.3452H29.2088V90.155H27.1261C27.1261 90.155 27.2015 92.4639 31.3163 92.4639"
        fill="#C46F31"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.1699 103.86C33.5958 104.024 35.2062 104.217 37.6496 104.217C40.093 104.217 41.7031 104.024 42.1293 103.86C41.7031 103.696 40.093 103.503 37.6496 103.503C35.2062 103.503 33.5958 103.696 33.1699 103.86ZM37.6496 104.529C36.5066 104.529 32.7777 104.481 32.7777 103.86C32.7777 103.24 36.5066 103.191 37.6496 103.191C38.7924 103.191 42.5212 103.24 42.5212 103.86C42.5212 104.481 38.7924 104.529 37.6496 104.529Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.357 110.027C34.2975 110.027 34.2408 109.993 34.2149 109.935C34.1794 109.857 34.2146 109.765 34.2931 109.729C34.3177 109.718 36.763 108.607 37.3621 107.351C37.3992 107.273 37.492 107.24 37.5699 107.277C37.6475 107.314 37.6802 107.407 37.6434 107.485C36.9948 108.844 34.5255 109.966 34.4208 110.014C34.3999 110.023 34.3785 110.027 34.357 110.027Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.899 111.393C34.8367 111.393 34.7778 111.355 34.7538 111.294C34.7227 111.214 34.7622 111.123 34.8423 111.092C34.8666 111.082 37.2729 110.133 38.1359 109.07C38.1904 109.003 38.2886 108.994 38.3552 109.048C38.4219 109.102 38.4322 109.2 38.378 109.267C37.4627 110.393 35.0576 111.342 34.9557 111.382C34.937 111.389 34.9177 111.393 34.899 111.393Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.5322 112.918C35.464 112.918 35.4014 112.873 35.3821 112.804C35.3593 112.721 35.4079 112.635 35.4908 112.612C35.507 112.608 37.1214 112.153 38.1679 111.22C38.2321 111.163 38.3305 111.169 38.3878 111.233C38.4448 111.297 38.4392 111.396 38.3751 111.453C37.2737 112.435 35.6425 112.893 35.5737 112.912C35.56 112.916 35.5459 112.918 35.5322 112.918Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.5223 115.749C32.4444 115.749 32.3771 115.691 32.3678 115.612C32.3578 115.527 32.4186 115.449 32.5042 115.439C35.5533 115.077 38.5179 112.864 38.8307 112.036C38.8712 111.928 38.8778 111.847 38.8482 111.818C38.7183 111.692 38.0824 111.833 37.5615 112.045C37.5481 112.051 37.5388 112.055 37.5338 112.056C37.454 112.084 37.3671 112.043 37.3375 111.964C37.3076 111.885 37.3459 111.797 37.4235 111.765C37.4288 111.763 37.4344 111.76 37.44 111.758C37.5085 111.726 38.0459 111.443 39.2529 110.021C39.6526 109.55 39.5847 109.416 39.5756 109.402C39.47 109.241 38.7195 109.337 38.1123 109.523C38.0438 109.544 37.9702 109.515 37.9335 109.454C37.8964 109.393 37.9058 109.315 37.9562 109.264C38.5102 108.705 39.166 107.809 38.9557 107.581C38.7257 107.33 37.8793 107.702 37.3515 108.045C37.2929 108.083 37.216 108.078 37.1637 108.032C37.111 107.986 37.0957 107.91 37.126 107.848L37.364 107.351C37.4886 106.936 37.4518 106.852 37.4428 106.839C37.3151 106.764 36.4982 107.156 36.1496 107.323C36.072 107.36 35.9792 107.327 35.9418 107.25C35.9047 107.172 35.9374 107.079 36.015 107.042C37.0294 106.556 37.4282 106.416 37.6472 106.604C37.8241 106.756 37.7808 107.046 37.6534 107.463L37.6232 107.527C38.1117 107.274 38.8413 106.996 39.185 107.37C39.5516 107.769 38.9722 108.585 38.5382 109.091C39.0198 108.99 39.6389 108.932 39.8358 109.231C40.0302 109.526 39.7317 109.939 39.4903 110.223C38.984 110.819 38.5908 111.223 38.2939 111.497C38.6024 111.44 38.9058 111.44 39.065 111.594C39.1535 111.68 39.2354 111.846 39.1223 112.146C38.7504 113.131 35.644 115.38 32.541 115.748C32.5348 115.749 32.5285 115.749 32.5223 115.749Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.4684 110.986C40.4342 110.986 40.3999 110.975 40.3709 110.952C40.2868 110.884 40.0304 110.678 40.0958 110.433C40.16 110.193 40.4784 110.132 40.5417 110.122C41.0803 110.122 41.7028 110.045 42.248 109.955C41.9062 109.931 41.5084 109.893 41.0598 109.834C39.9787 109.69 39.5201 109.282 39.5453 108.94C39.5615 108.717 39.7834 108.48 40.3606 108.489C39.7777 108.394 39.3145 108.197 39.1727 107.794C39.0771 107.523 39.1537 107.359 39.2347 107.27C39.3799 107.11 39.6447 107.076 39.888 107.084C39.8419 106.959 39.8494 106.82 39.9108 106.671C40.0126 106.424 40.2448 106.232 40.5317 106.158C40.6149 106.136 40.6999 106.186 40.7217 106.269C40.7432 106.352 40.6934 106.437 40.6102 106.459C40.4174 106.509 40.2634 106.633 40.1986 106.79C40.1666 106.868 40.1616 106.933 40.1837 106.985C40.2204 107.071 40.3323 107.13 40.4198 107.164C40.4959 107.194 40.536 107.278 40.5111 107.356C40.4862 107.435 40.4049 107.48 40.3254 107.46C40.0475 107.392 39.5824 107.351 39.4653 107.479C39.4304 107.518 39.4307 107.589 39.4665 107.691C39.6734 108.277 41.146 108.269 42.2215 108.263L42.548 108.262C42.6277 108.262 42.6947 108.323 42.7028 108.402C42.7109 108.481 42.6577 108.554 42.5798 108.57C42.5315 108.58 41.3934 108.816 40.3603 108.8C40.0214 108.796 39.8612 108.893 39.8559 108.963C39.846 109.1 40.2008 109.406 41.1009 109.525C42.695 109.737 43.658 109.666 43.6674 109.666C43.7487 109.659 43.82 109.716 43.8331 109.796C43.8462 109.876 43.796 109.952 43.7175 109.972C43.6431 109.991 41.8673 110.429 40.5653 110.432C40.5012 110.444 40.408 110.489 40.3968 110.514C40.3968 110.514 40.4015 110.576 40.566 110.709C40.6333 110.762 40.6438 110.861 40.5899 110.928C40.5591 110.966 40.5139 110.986 40.4684 110.986Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.7755 107.637C40.5038 107.637 40.1087 107.355 40.0592 107.315C39.9925 107.261 39.9819 107.163 40.0361 107.096C40.0894 107.03 40.186 107.019 40.253 107.071C40.2676 107.081 40.8433 107.474 42.9051 107.263C42.9905 107.254 43.0671 107.316 43.0759 107.402C43.0846 107.488 43.0223 107.564 42.9369 107.573C42.4861 107.619 42.102 107.637 41.7755 107.637Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.118 108.575C42.0339 108.575 41.9647 108.508 41.9622 108.424C41.96 108.338 42.0276 108.266 42.1136 108.264L42.9523 108.241C43.0408 108.239 43.1103 108.306 43.1124 108.392C43.1149 108.478 43.047 108.55 42.961 108.552L42.1223 108.575C42.1211 108.575 42.1195 108.575 42.118 108.575Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3824 122.809C11.1325 122.809 7.03289 120.958 5.45555 117.391C5.42066 117.313 5.45618 117.221 5.535 117.186C5.61351 117.151 5.70572 117.187 5.74062 117.266C9.93554 126.751 30.8799 120.406 31.0912 120.341C31.1731 120.316 31.2607 120.362 31.2859 120.444C31.3114 120.526 31.2653 120.613 31.1831 120.639C31.1298 120.655 25.7893 122.289 19.9915 122.711C19.0846 122.777 18.2139 122.809 17.3824 122.809Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6533 111.839C13.5916 111.839 13.5333 111.802 13.5087 111.741C13.4766 111.661 13.5153 111.571 13.595 111.538C13.6324 111.524 17.4108 110.026 23.2314 109.814C23.3173 109.812 23.3896 109.879 23.3927 109.965C23.3959 110.05 23.3286 110.123 23.2426 110.126C17.4806 110.335 13.7483 111.813 13.7115 111.828C13.6922 111.835 13.6726 111.839 13.6533 111.839Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2322 110.526C17.2188 110.526 17.205 110.524 17.1913 110.52C17.1131 110.498 17.068 110.416 17.0901 110.338C17.1371 110.174 18.2602 106.309 20.2632 104.449C20.3227 104.394 20.4158 104.397 20.4716 104.457C20.527 104.517 20.5236 104.61 20.4638 104.666C18.5216 106.469 17.3851 110.38 17.3739 110.419C17.3555 110.484 17.2963 110.526 17.2322 110.526Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.9931 120.652C30.0146 120.652 29.1933 119.866 28.5497 118.314C28.2201 117.518 27.9294 116.502 27.6852 115.292C27.3147 113.457 27.1219 111.547 26.9945 110.284L26.9702 110.043C26.9627 109.97 27.0069 109.902 27.0764 109.879C27.1459 109.856 27.2222 109.885 27.2593 109.948C27.2593 109.948 27.568 110.471 28.0226 111.284C28.0646 111.359 28.0375 111.454 27.9624 111.496C27.8874 111.538 27.7927 111.511 27.7506 111.436C27.6001 111.167 27.4655 110.93 27.3534 110.733C27.4802 111.966 27.6674 113.63 27.9908 115.231C28.2307 116.421 28.5157 117.418 28.8376 118.194C29.4572 119.689 30.2277 120.41 31.1237 120.335C31.2221 120.327 31.286 120.29 31.3312 120.215C31.4576 120.008 31.5343 119.335 30.6283 117.164C30.5953 117.085 30.6327 116.993 30.7121 116.96C30.7916 116.927 30.8828 116.965 30.9159 117.044C31.6577 118.821 31.8804 119.912 31.5975 120.377C31.5009 120.536 31.3461 120.629 31.1495 120.645C31.0969 120.65 31.0448 120.652 30.9931 120.652Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.6211 119.467C49.6111 119.467 49.6005 119.466 49.5903 119.464C45.5676 118.657 42.188 112.982 42.0462 112.741C42.0023 112.666 42.0272 112.571 42.1014 112.527C42.1758 112.484 42.2709 112.508 42.3145 112.583C42.3487 112.641 45.7657 118.379 49.6516 119.159C49.7361 119.175 49.7906 119.257 49.7737 119.342C49.7588 119.416 49.6937 119.467 49.6211 119.467Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.948 108.929C50.9231 108.923 50.8995 108.911 50.8793 108.892C48.2575 106.488 46.6822 106.71 46.6665 106.713C46.5822 106.726 46.5017 106.669 46.4877 106.585C46.4734 106.5 46.5295 106.42 46.6138 106.406C46.683 106.393 48.3374 106.139 51.0898 108.663C51.1531 108.721 51.1576 108.819 51.0994 108.883C51.0596 108.926 51.0013 108.942 50.948 108.929Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9763 90.2006C26.9616 90.1536 26.9706 90.1022 26.9999 90.0626C27.0292 90.0231 27.0756 89.9997 27.1252 89.9997H29.0561V88.6498C29.0561 88.5638 29.1259 88.494 29.2119 88.494C29.2979 88.494 29.3677 88.5638 29.3677 88.6498C29.3677 88.9844 29.3677 89.2353 29.3677 89.4026C29.3677 89.5699 29.3677 89.8209 29.3677 90.1555C29.3677 90.2415 29.2979 90.3112 29.2119 90.3112H27.3601C27.6361 90.8574 28.6776 92.3079 31.9426 92.3079C34.6817 92.3079 35.5699 90.1128 35.5861 90.0137C35.5998 89.929 35.6795 89.8713 35.7643 89.8847C35.8493 89.8985 35.907 89.9779 35.8936 90.0626C35.8892 90.0907 35.6733 90.7482 35.017 91.4124C34.5721 91.8625 34.11 92.4639 31.9426 92.6195C27.7604 92.6195 27.0062 90.2994 26.9763 90.2006Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.0363 81.8515C38.1638 85.3798 34.6657 88.3073 31.269 88.3073C27.8722 88.3073 24.4266 85.7763 24.2786 82.1706C24.1337 78.643 26.6856 74.6836 29.2365 74.3418C34.4315 73.6452 37.8806 77.5342 38.0363 81.8515Z"
        fill="black"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.2693 87.9957C28.1598 87.9957 24.7338 85.6501 24.5901 82.158C24.4537 78.8332 26.8635 74.9741 29.2782 74.6504C29.6667 74.5984 30.0546 74.5719 30.4309 74.5719C34.9978 74.5719 37.5977 78.3285 37.7254 81.8626C37.7768 83.2901 37.1905 84.7136 36.0745 85.8709C34.791 87.2012 32.9946 87.9957 31.2693 87.9957Z"
        fill="#C46F31"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.004 72.1954C33.6521 72.1954 35.7992 70.4258 35.7992 68.2431C35.7992 66.0605 33.6521 64.2909 31.004 64.2909C28.3555 64.2909 26.2087 66.0605 26.2087 68.2431C26.2087 70.4258 28.3555 72.1954 31.004 72.1954ZM31.4058 72.1954C17.6783 72.1954 23.0845 81.2747 16.926 81.2747C13.5169 81.2747 11.9805 83.4985 11.2881 85.4841C10.7232 87.1042 12.1172 88.532 13.833 88.532C14.6278 88.532 15.2921 87.9293 15.3791 87.1393C15.4559 86.4418 15.539 85.7581 15.5626 85.8291C16.0505 87.2895 17.023 88.532 18.5627 88.532H26.6648C27.4137 88.532 28.2202 88.1427 27.9811 87.433L26.7384 86.6304C26.7384 86.6304 27.5977 84.0735 26.5166 82.898C25.4352 81.7229 26.6106 79.3883 26.6106 79.3883C26.6106 79.3883 29.5724 79.8226 31.4058 74.9746C31.4058 74.9746 32.4467 78.2405 37.3083 79.073C37.5644 79.7584 37.8613 80.4998 37.8613 81.2747C37.8613 84.2377 37.0516 86.2082 33.8223 87.784C33.8223 88.1576 34.1244 88.4609 34.498 88.4623L46.7655 88.5096C50.0172 88.5221 52.9367 85.6122 50.9673 83.0248C50.0124 81.7703 48.5037 80.7986 46.1206 80.7986C39.3979 80.7986 45.1334 72.1954 31.4058 72.1954Z"
        fill="#222F2D"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.9801 80.7336C35.9249 80.6679 35.8271 80.6585 35.7607 80.7143C35.7109 80.7557 34.5301 81.719 33.5344 80.7236C33.4734 80.6626 33.3749 80.6626 33.3142 80.7236C33.2531 80.7844 33.2531 80.8828 33.3142 80.9439C33.7176 81.3476 34.1482 81.4819 34.542 81.4819C35.3177 81.4819 35.9514 80.961 35.961 80.9529C36.0271 80.8978 36.0355 80.7996 35.9801 80.7336ZM31.0125 80.7236C30.9518 80.6626 30.853 80.6626 30.7922 80.7236C29.7969 81.7193 28.6155 80.7557 28.5656 80.7143C28.4996 80.6588 28.4015 80.6682 28.3463 80.7336C28.2912 80.7996 28.2996 80.8978 28.3656 80.9529C28.375 80.961 29.0087 81.4819 29.7847 81.4819C30.1782 81.4819 30.6087 81.3476 31.0125 80.9439C31.0733 80.8828 31.0733 80.7844 31.0125 80.7236ZM34.0089 84.2742C32.2752 86.0076 30.3913 84.3406 30.3122 84.2689C30.2489 84.2116 30.1498 84.2159 30.0922 84.2798C30.0343 84.3434 30.0392 84.4418 30.1028 84.4998C30.1171 84.5128 31.0751 85.3646 32.2873 85.3646C32.8998 85.3646 33.5765 85.1471 34.2292 84.4945C34.2899 84.4337 34.2899 84.335 34.2292 84.2742C34.1684 84.2135 34.0697 84.2135 34.0089 84.2742Z"
        fill="black"
      />
    </Svg>
  );
};

export default MessagingSessionPerson;
