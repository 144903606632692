import { Mixpanel as mixpanel, MixpanelPeople } from '@awesome-cordova-plugins/mixpanel';
import { getIsIonic } from '../../ionicUtils';

export const initMixpanel = async (token: string, onFinishLoading: (success?: boolean) => void) => {
  if (!getIsIonic()) {
    onFinishLoading(false);
    return Promise.resolve();
  }
  try {
    await mixpanel.init(token);
    return onFinishLoading(true);
  } catch {
    return onFinishLoading(false);
  }
};

export const getMixpanel = () => mixpanel;
export const getMixpanelPeople = () => MixpanelPeople;
