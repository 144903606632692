import { keyValueToValueType } from 'stepWizard';
import { OtherWizardGenericActions } from '../../utils/genericWizardActions';
import { State } from '../reducers/finanacialAidWizardState';
import financialAidWizardApiHelper from '../utils/financialAidWizardApiHelper';

const initState = async (api, state) => {
  const {
    data: { isEligible, possibleUserAnswers },
  } = await financialAidWizardApiHelper.isEligible(state.roomID, true).catch(() => {
    return {
      data: {
        isEligible: false,
        possibleUserAnswers: {
          employmentStatus: {},
          spouseEmploymentStatus: {},
          yearlyFamilyEarnings: {},
          federalProgramBenefits: {},
        },
      },
    };
  });
  if (!isEligible) {
    throw new Error('User not eligible for financial aid');
  }
  const { employmentStatus, spouseEmploymentStatus, yearlyFamilyEarnings, federalProgramBenefits } =
    possibleUserAnswers;
  const employmentStatusOptions = keyValueToValueType(employmentStatus);
  const spouseEmploymentStatusOptions = keyValueToValueType(spouseEmploymentStatus);
  const householdEarningsOptions = keyValueToValueType(yearlyFamilyEarnings);
  const benefitsReceivedOptions = keyValueToValueType(federalProgramBenefits);

  return {
    employmentStatusOptions,
    spouseEmploymentStatusOptions,
    householdEarningsOptions,
    benefitsReceivedOptions,
  };
};

const financialAidWizardActions: OtherWizardGenericActions<State> = {
  initState,
};

export default financialAidWizardActions;
