import { Spinner, TextDS, TouchableView, View, spacing } from '@talkspace/react-toolkit';

import { useHistory } from 'react-router';
import { truncateTextMaxLinesStyle } from '@talkspace/react-toolkit/src/utils/styleUtils';
import CommentBubble from './CommentBubble';
import useCommunityComment from '../hooks/useCommunityComment';
import useCommunityUser from '../hooks/useCommunityUser';

const { space050, space100 } = spacing;

interface Props {
  post: Amity.Post;
}
const CommunityPostCommentPreview = ({ post }: Props) => {
  const { postId: postID, commentsCount, comments } = post;
  const lastCommentID = comments[comments.length - 1];
  const history = useHistory();

  const {
    comment,
    isLoading: isLoadingComment,
    isError: isErrorComment,
  } = useCommunityComment({ commentID: lastCommentID });
  const linkText = commentsCount === 1 ? 'View comment' : `View all ${commentsCount} comments`;

  const {
    user: commenterUser,
    isLoading: isLoadingCommenterUser,
    isError: isErrorUser,
  } = useCommunityUser({ userID: comment?.userId });

  const isLoading = isLoadingComment || isLoadingCommenterUser;
  const isError = isErrorComment || isErrorUser;
  const commentText = (comment as Amity.Comment<'text'>)?.data?.text;
  if (isError) return null;
  return (
    <View style={{ paddingBottom: space050 }}>
      {isLoading ? (
        <Spinner />
      ) : (
        <CommentBubble>
          <View row>
            <TextDS style={{ ...truncateTextMaxLinesStyle(1) }}>
              <TextDS variant="headingMd" inline>
                {commenterUser?.displayName}
              </TextDS>
              &nbsp;
              {commentText}
            </TextDS>
          </View>
        </CommentBubble>
      )}
      <TouchableView
        onPress={() => history.push(`/home/teenspace/post/${postID}`)}
        dataQa="viewAllCommentsLink"
        style={{ padding: `${space100}px 0` }}
      >
        <TextDS>{linkText}</TextDS>
      </TouchableView>
    </View>
  );
};

export default CommunityPostCommentPreview;
