import { FunctionComponent, useRef, useState } from 'react';
import {
  View,
  ExtraHuge,
  Large,
  Button,
  BaseButton,
  Text,
  useEmotionTheme,
  useModalActions,
  useUniqueID,
  Modal,
} from '@talkspace/react-toolkit';
import SwitchTherapistApiHelper from '../../../therapistMatches/utils/switchTherapistApiHelper';
import { RouteComponentProps } from '../../../core/routerLib';
import { useCloseModal } from '../../../utils/ModalsContextProvider';
import styled from '../../../core/styled/styled';

const CancelButton = styled(BaseButton)(({ theme: { colors } }) => {
  return {
    color: colors.slateGrey,
    backgroundColor: colors.white,
    marginBottom: 80,
    marginTop: 10,
  };
});

const SuggestSwitchTherapist: FunctionComponent<
  RouteComponentProps<{}, {}, { text: string; roomID: number }>
> = ({ location }) => {
  const { colors } = useEmotionTheme();
  const apiRef = useRef(SwitchTherapistApiHelper);
  const { current: api } = apiRef;
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    state: { text, roomID },
  } = location;

  const closeModal = useCloseModal();

  const dismissModal = () => closeModal();

  const onSwitchPress = () => {
    setIsLoading(true);
    api
      .postTransferMatchingAgent(roomID)
      .then(() => {
        setIsLoading(false);
        dismissModal();
      })
      .catch(() => {
        setError('A problem occurred!');
        setIsLoading(false);
      });
  };

  const waitOptionId = useUniqueID('waitOptionId');
  const switchOptionId = useUniqueID('switchOptionId');
  // ideally we would use setModalTitleId and set aria-labelledby of the modal dialog to point to the pre-existing title but for whatever reason VoiceOver/Chrome failed to announce the title when i tried it
  const { setModalTitle } = useModalActions();
  const titleText = 'Matching agent not available';
  setModalTitle(titleText);
  return (
    <Modal.Panel onBackdropPress={() => dismissModal()} contentViewStyle={{ paddingBottom: 90 }}>
      <View style={{ maxWidth: 375 }}>
        <ExtraHuge as="h1" style={{ marginBottom: 18 }}>
          {titleText}
        </ExtraHuge>
        <Large variant="largeDarkGrey" style={{ marginBottom: 40 }}>
          {text}
        </Large>
        <View>
          <Button
            text="Switch"
            disabled={isLoading}
            isLoading={isLoading}
            aria-labelledby={switchOptionId}
            style={{ marginBottom: 10, width: '100%' }}
            onPress={onSwitchPress}
          />

          <CancelButton
            disabled={isLoading}
            aria-labelledby={waitOptionId}
            style={{ marginBottom: 10 }}
            onPress={dismissModal}
          >
            <Large variant="largeDarkGrey">Wait</Large>
          </CancelButton>
          <View align="center" style={{ marginTop: 10 }}>
            {error && <Text style={{ color: colors.red }}>{error}</Text>}
          </View>
        </View>
      </View>
    </Modal.Panel>
  );
};

export default SuggestSwitchTherapist;
