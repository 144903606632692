import { useQueryBookings } from 'inRoomScheduling';

import useQuerySubscriptions from 'ts-frontend/hooks/useQuerySubscriptions';
import { Booking } from 'ts-frontend/types';
import { filterBookingsAfterDate } from '@/utils/bookingsHelper';

const useBookingsOutsideBillingCycle = (
  userID: number,
  roomID: number
): { isLoading: boolean; bookings: Booking[]; billingCycleEnd: Date | undefined } => {
  const { data: subscriptions, isLoading: isLoadingSubscriptions } = useQuerySubscriptions({
    clientUserID: userID,
    roomID,
    disabled: !userID || !roomID,
  });

  const { data: bookings, isLoading: isLoadingBookings } = useQueryBookings({ roomID });

  const currentSubscription = subscriptions?.find(({ id }) => id === Number(roomID));
  const billingCycleEnd =
    currentSubscription?.subscription &&
    new Date(currentSubscription.subscription.renewalDate as string);
  return {
    isLoading: isLoadingBookings || isLoadingSubscriptions,
    bookings:
      (bookings && billingCycleEnd && filterBookingsAfterDate(bookings, billingCycleEnd)) || [],
    billingCycleEnd,
  };
};

export default useBookingsOutsideBillingCycle;
