import apiHelper from '../../core/api/apiHelper';
import apiWrapper from '../../core/api/apiWrapper';

export interface ApiResponse {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

function getMarketingCommunications(clientUserID: number): Promise<ApiResponse> {
  return apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/marketing-communication-preferences`
  );
}

function patchMarketingCommunications(
  clientUserID: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
): Promise<ApiResponse> {
  return apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/marketing-communication-preferences`,
    payload
  );
}

const marketingCommunicationsApiHelper = {
  getMarketingCommunications,
  patchMarketingCommunications,
};

export default marketingCommunicationsApiHelper;
