import { ERoom } from 'ts-frontend/entities/Room';
import { ETherapistInfo } from 'ts-frontend/entities/Therapist';
import { Subscription } from 'ts-frontend/types';
import { State } from '../reducers/mainReducer';

export const extractRoomEntitiesFromState = (
  state: State,
  roomID: number
): { therapist: ETherapistInfo; room: ERoom; subscription: Subscription } => {
  const { roomsByID, therapistsByID, subscriptionsByID } = state;
  const room = roomsByID[roomID];
  const subscription = subscriptionsByID[roomID];
  let therapist;
  try {
    const { therapistID } = room;
    therapist = therapistsByID[therapistID];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Are you using this helper outside the client-web mainContainer?');
  }
  return { therapist, room, subscription };
};

export const sortRoomsByLastMessageDate = (a: ERoom, b: ERoom): number => {
  if (!a.lastMessage) {
    return 1;
  }
  if (!b.lastMessage) {
    return -1;
  }
  return +b.lastMessage.createdAt - +a.lastMessage.createdAt;
};

export default extractRoomEntitiesFromState;
