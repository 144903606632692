import { FunctionComponent } from 'react';
import { View, Spinner } from '@talkspace/react-toolkit';
import styled from '../../../core/styled';
import { COLORS } from '../../../utils/design';
import getPanelWidth from '../../../myAccount/utils/design';
import { EmotionStyle } from '../../../core/styled/styled';

const LoadingPanelContainer = styled(View)(({ theme: { window }, style }) => {
  const { isMobile } = window;
  return {
    backgroundColor: COLORS.a11yLinkWaterGrey,
    borderRadius: isMobile ? 0 : 10,
    marginBottom: isMobile ? 30 : 15,
    minWidth: 335,
    maxWidth: getPanelWidth(window),
    height: 350,
    ...style,
  };
});

interface LoadingPanelProps {
  style?: EmotionStyle;
  spinnerStyle?: EmotionStyle;
}

const LoadingPanel: FunctionComponent<LoadingPanelProps> = ({ style, spinnerStyle }) => (
  <LoadingPanelContainer style={style}>
    <Spinner primaryColor={COLORS.white} style={spinnerStyle} />
  </LoadingPanelContainer>
);

export default LoadingPanel;
