const services = [
  {
    name: 'Individual Therapy',
    value: 'psychotherapy',
    description: 'Ongoing support from a licensed therapist.',
  },
  {
    name: 'Psychiatry',
    value: 'psychiatry',
    description: 'Evaluations and medication management.',
  },
  {
    name: 'Couples Therapy',
    value: 'therapyCouples',
    description: 'Relationship-centered therapy that connects you and your partner.',
  },
  {
    name: 'Teen Therapy',
    value: 'therapyTeen',
    description: 'Specialized therapy for ages 13-17.',
  },
];

export default services;
