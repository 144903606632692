/* eslint-disable import/prefer-default-export */
import { GDPR_COUNTRIES } from '../constants/gdpr';
import { upsertSession } from '../utils';
import localStorage from '@/core/storage/localStorage';

const LOCAL_STORAGE_GDPR_KEY = 'GDPRProps';

export const isGDPRCountry = ({ countryCode }: { countryCode: string }) =>
  GDPR_COUNTRIES.includes(countryCode);

/*
  NOTE:
    OneTrust SDK is currently only loaded for the quickmatch app,
    to use this in other apps make sure to add OneTrust script tags to the corresponding index.html
*/
export const getGeolocationFromOneTrustSDK = (): {
  countryCode: string | undefined;
  stateCode: string | undefined;
} => {
  const { country, state } = window?.OneTrust?.getGeolocationData() || {};
  return { countryCode: country, stateCode: state };
};

export const getGDPRProps = (): Record<string, any> =>
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_GDPR_KEY) || '{}');

export const storeGDPRProps = ({
  isGDPR,
  consentGDPRMarketing,
  consentGDPRProcessing,
  consentGDPRTransferring,
}: {
  isGDPR?: boolean;
  consentGDPRMarketing?: boolean;
  consentGDPRProcessing?: boolean;
  consentGDPRTransferring?: boolean;
}) => {
  const valuesToUpsert = {
    ...(isGDPR === undefined ? {} : { isGDPR }),
    ...(consentGDPRMarketing === undefined ? {} : { consentGDPRMarketing }),
    ...(consentGDPRProcessing === undefined ? {} : { consentGDPRProcessing }),
    ...(consentGDPRTransferring === undefined ? {} : { consentGDPRTransferring }),
  };

  // NOTE: setting in local storage because session storage is cleared on purchase and we need to persist these values throughout the QM experience
  const oldLocalStorageValues = getGDPRProps();
  localStorage.setItem(
    LOCAL_STORAGE_GDPR_KEY,
    JSON.stringify({
      ...oldLocalStorageValues,
      ...valuesToUpsert,
    })
  );

  // NOTE: also storing in session storage for submission to backend on purchase
  upsertSession('TSQM_RegistrationInformation', valuesToUpsert);
};
