import { useEffect } from 'react';
import { History } from 'history';
import { useWizardActions, WizardStepWrapper } from 'stepWizard';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import NPSWizard from './containers/NPSWizardContainer';
import NPSDone from './containers/NPSDoneContainer';
import { Route, Switch } from '../core/routerLib';

const Routes = ({ history }: { history: History }) => {
  const { onExitClick, setState } = useWizardActions();
  const isLightBox = !ReactFrameService.isMobile();
  useEffect(() => {
    setState({ isLightBox });
  }, [isLightBox, setState]);
  return (
    <WizardStepWrapper onCloseButtonClick={onExitClick} title=" " isLightBox={isLightBox}>
      <Switch>
        <Route
          exact
          path={['/nps/room/:roomID/source/:source/user-prompt/:userPromptID']}
          component={NPSWizard}
        />
        <Route exact path="/nps/done/:source" component={NPSDone} />
      </Switch>
    </WizardStepWrapper>
  );
};

export default Routes;
