import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const TeenspaceLeftWing = ({
  width = 33,
  height = 180,
  color,
  ...props
}: {
  width?: number;
  height?: number;
  color?: string;
}) => {
  const { colorRoles } = useEmotionTheme();
  return (
    <Svg width={width} height={height} viewBox="0 0 33 180" {...props}>
      <Path
        d="M6 0H33V180H22C18.6863 180 16 177.314 16 174V151C16 147.686 13.3137 145 10 145H6C2.68629 145 0 142.314 0 139V6C0 2.68629 2.68629 0 6 0Z"
        fill={colorRoles.surfaces.decorativeLiftedLemon}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default TeenspaceLeftWing;
