import { FunctionComponent, useEffect } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useOpenModal } from '@/utils/ModalsContextProvider';
import UpcomingSurveysCard from '../components/UpcomingSurveysCard';
import { useSurveysState, useSurveysActions } from '../hooks/surveysContext';
import { withRouter, RouteComponentProps } from '../../core/routerLib';
import { trackCTAClick } from '../../utils/analytics/events';

const UpcomingSurveysContainer: FunctionComponent<
  RouteComponentProps<{
    roomID: string;
  }>
> = ({ match }) => {
  const { upcomingSurveys } = useSurveysState();
  const { dispatchGetSurveys } = useSurveysActions();
  const openModal = useOpenModal();
  const { memberNewNav } = useFlags();

  useEffect(() => {
    dispatchGetSurveys();
  }, [dispatchGetSurveys]);

  const upcomingSurvey = upcomingSurveys
    .filter((s) => s.roomID === Number(match.params.roomID))
    .find((s) => s.isDue);

  const handleLaunchSurvey = () => {
    if (memberNewNav && memberNewNav !== 'disabled') {
      trackCTAClick('Start assessment', 'clinical-progress', { roomID: match.params.roomID });
    }
    openModal(
      'survey',
      {
        userRoomSurveyID: upcomingSurvey ? upcomingSurvey.userRoomSurveyID : 0,
        roomID: match.params.roomID,
      },
      true,
      false
    );
  };

  return upcomingSurvey && upcomingSurvey.isDue ? (
    <UpcomingSurveysCard upcomingSurvey={upcomingSurvey} launchSurvey={handleLaunchSurvey} />
  ) : null;
};

export default withRouter(UpcomingSurveysContainer);
