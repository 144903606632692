import { FunctionComponent } from 'react';
import { Formik, Form } from 'formik';
import { TSInputFormik, View, Large, Button } from '@talkspace/react-toolkit';
import * as yup from 'yup';
import styled from '../../core/styled';
import { useRoomInvitesActions } from '../hooks/useRoomInvites';

const initialValues = { email: '', confirmEmail: '' };

const Wrapper = styled(View)({
  marginTop: 30,
  alignSelf: 'center',
  alignItems: 'center',
  maxWidth: 600,
  paddingLeft: 30,
  paddingRight: 30,
  width: '100%',
});

const FullWidthForm = styled(Form)({
  width: '100%',
});

const TextWrapper = styled(View)({
  textAlign: 'center',
  marginBottom: 28,
});

const invitePartnerSchema = yup.object().shape({
  email: yup.string().email('Please enter a valid email address.').required('Email is required'),
  confirmEmail: yup
    .string()
    .required('Must confirm your email')
    .oneOf([yup.ref('email'), null], 'The email addresses you entered don’t match.'),
});

const RoomInviteForm: FunctionComponent<{ roomID: number }> = ({ roomID }) => {
  const { createInvite } = useRoomInvitesActions();
  const onSubmit = (
    values: { email: string },
    actions: { setSubmitting: (submitting: boolean) => void }
  ) => {
    actions.setSubmitting(true);
    createInvite(roomID, values.email);
  };
  return (
    <Wrapper>
      <TextWrapper>
        <Large variant="largeDarkGrey">
          Enter your partner’s email address, and we’ll send them an invitation to access your room.
        </Large>
      </TextWrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={invitePartnerSchema}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <FullWidthForm>
            <TSInputFormik
              fieldName="email"
              label="Partner’s email address"
              placeholder="Enter email address"
              required
            />
            <TSInputFormik
              fieldName="confirmEmail"
              label="Confirm partner’s email address"
              placeholder="Confirm email address"
              required
            />
            <Button
              type="submit"
              stretch
              disabled={isSubmitting || !isValid || !dirty}
              isLoading={isSubmitting}
            >
              Invite Partner
            </Button>
          </FullWidthForm>
        )}
      </Formik>
    </Wrapper>
  );
};

export default RoomInviteForm;
