import { FunctionComponent, useEffect, useCallback, useState } from 'react';
import { History } from 'history';
import { StepWizardProvider } from 'stepWizard';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useTranslation } from '@talkspace/i18n';
import { RouteComponentProps } from '@/core/routerLib';
import { MainContextProvider } from '@/hooks/mainContext';
import { useGenericWizardActions } from '@/utils/genericWizardActions';
import { getUserData } from '@/auth/helpers/token';
import Routes from './Routes';
import {
  getInitialState,
  TeenMentalHealthWizardState,
} from './reducers/teenMentalHealthWizardState';
import mentalHealthActions from './hooks/teenMentalHealthActions';

interface MatchingIntakeRouteParams {
  roomID: number;
  source: string;
  contextID?: string;
  history: History;
}

const TeenMentalHealthWizard: FunctionComponent<RouteComponentProps<MatchingIntakeRouteParams>> = ({
  match: {
    params: { roomID, source, contextID = '' },
  },
}) => {
  const { id } = getUserData();
  const { localizationClientWeb } = useFlags();
  const getFirstState = useCallback(getInitialState, [roomID, source, contextID]);

  const [initialState, setInitialState] = useState<TeenMentalHealthWizardState | undefined>();

  const genericMatchingIntakeActions = useGenericWizardActions(mentalHealthActions);

  const { t: tTeenOnboarding, i18n } = useTranslation('teenOnboarding');

  useEffect(() => {
    // TODO: Provide fallback for teenOnboarding namsepace
    if (i18n.hasLoadedNamespace('teenOnboarding') || !localizationClientWeb) {
      setInitialState(getFirstState(id, roomID, source, contextID, tTeenOnboarding));
    }
  }, [
    contextID,
    id,
    roomID,
    source,
    getFirstState,
    tTeenOnboarding,
    i18n,
    i18n.language,
    localizationClientWeb,
  ]);

  return (
    <>
      {initialState ? (
        <MainContextProvider>
          <StepWizardProvider
            initialState={initialState}
            genericActions={genericMatchingIntakeActions}
          >
            <Routes />
          </StepWizardProvider>
        </MainContextProvider>
      ) : null}
    </>
  );
};

export default TeenMentalHealthWizard;
