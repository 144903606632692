import { useState, useEffect } from 'react';

export default function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function handleResize() {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isSmall = windowWidth <= 375;
  const isMedium = windowWidth > 375 && windowWidth <= 750;
  const isLarge = windowWidth > 750 && windowWidth <= 1125;
  const isDesktop = windowWidth > 1125;

  return {
    isSmall,
    isMedium,
    isLarge,
    isDesktop,
    isMobile: isSmall || isMedium,
    width: windowWidth,
  };
}
