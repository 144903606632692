import { FunctionComponent } from 'react';
import { View, Large, Button, RedX } from '@talkspace/react-toolkit';
import ReactFrameService from '../auth/reactFrame/ReactFrameService';
import { RouteComponentProps } from '../core/routerLib/routerLib';

interface LocationState {
  inModal?: boolean;
}

const StripeErrorScreen: FunctionComponent<RouteComponentProps<{}, {}, LocationState>> = ({
  location: { state },
}) => {
  const { inModal } = state;

  const handleReloadPress = () => {
    const reactFrameService = ReactFrameService.instance();
    if (reactFrameService.isInFrame()) reactFrameService.closePopup();
    else if (inModal) window.location.reload();
    else window.location.href = '/rooms';
  };

  return (
    <View style={{ height: '100vh' }}>
      <View flex={1} align="center" justify="center">
        <View style={{ marginBottom: 20 }}>
          <RedX size={74} />
        </View>
        <Large variant="largeDarkGrey" style={{ marginBottom: 20 }}>
          Something went wrong. Please try again later.
        </Large>
        <Button text="Reload" onPress={handleReloadPress} />
      </View>
    </View>
  );
};

export default StripeErrorScreen;
