import type { PromiseMessageCallback, PromiseMessageHandler } from 'ts-promise-message';
import { PromiseMessageTypeNames, getPromiseMessageTypedCallbackAndData } from 'ts-promise-message';
import { PromiseMessageTypes } from './promiseMessageEvents';

const DEBUG = false; // This should always be `false` on PRs.

const promiseMessageHandlers: PromiseMessageHandler = async (event, callbackArg) => {
  const innerType = event.data.data.innerType as PromiseMessageTypeNames;
  const callback = callbackArg as PromiseMessageCallback<PromiseMessageTypeNames>;
  switch (innerType) {
    case PromiseMessageTypeNames.quickmatchSubscribe: {
      const [data, cb] = getPromiseMessageTypedCallbackAndData<typeof innerType>(event, callback);
      if (DEBUG)
        // eslint-disable-next-line no-console
        console.log('CW - received quickmatchSubscribe', data.innerData, data.innerType);
      const responseData: PromiseMessageTypes[typeof innerType]['responseData'] = {
        success: true,
        errors: null,
      };
      cb(responseData);
      return true;
    }
    case PromiseMessageTypeNames.analytics: {
      const [data, cb] = getPromiseMessageTypedCallbackAndData<typeof innerType>(event, callback);
      // eslint-disable-next-line no-console
      if (DEBUG) console.log('CW - received analytics', data.innerData);
      const responseData: PromiseMessageTypes[typeof innerType]['responseData'] = undefined;
      cb(responseData);
      return true;
    }
    default: {
      if (DEBUG)
        // eslint-disable-next-line no-console
        console.log(`CW - No handler for event ${event.data.data.innerType}`);
      return false;
    }
  }
};

export default promiseMessageHandlers;
