import { Button, useWindowWidthState, spacing, useEmotionTheme } from '@talkspace/react-toolkit';
import { PenToSquare } from '@talkspace/react-toolkit/src/designSystems/icons';
import IconButton from '@talkspace/react-toolkit/src/designSystems/components/IconButton';
import { elevation1Style } from '@talkspace/react-toolkit/src/designSystems/styles/elevation';
import { useHistory } from '@/core/routerLib';

import useNavShellWindowHeight from '../../hooks/useNavShellWindowHeight';
import { useCommunityState } from '../hooks/CommunityContext';
import ViewPostButton from './ViewPostButton';
import { formatPlaceholder } from '../utils/helpers';

const { space200 } = spacing;

const OpenPostCreatorButtons = () => {
  const history = useHistory();
  const { colorRoles } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  const { outsideFooterHeight } = useNavShellWindowHeight();

  const { dailyPrompt, userPostFromToday, isLoading } = useCommunityState();

  const { placeholders = [] } = dailyPrompt || {};

  const onPress = () => {
    history.push('/community/teenspace/create-post');
  };

  const onViewPostPress = () => {
    userPostFromToday?.postId && history.push(`/home/teenspace/post/${userPostFromToday.postId}`);
  };

  return (
    <>
      {!userPostFromToday ? (
        <>
          <Button
            onPress={onPress}
            dataQa="openPostCreatorButton"
            sizeDS={isMobile ? 'large' : 'medium'}
            text={formatPlaceholder(placeholders[0])}
            Icon={() => <PenToSquare colorType="inverse" />}
            disabled={isLoading}
            isLoading={isLoading}
          />
          {isMobile && ( // NOTE: extra floating button on mobile
            <IconButton
              onPress={onPress}
              dataQa="openPostCreatorCircleButton"
              Icon={<PenToSquare size="huge" colorType="inverse" />}
              iconWrapperStyle={{
                width: 'fit-content',
                background: colorRoles.button.brandPrimarySurfaceDefault,
              }}
              style={{
                position: 'fixed',
                bottom: (outsideFooterHeight || 0) + space200,
                right: space200,
                borderRadius: '50%',
                width: 64,
                height: 64,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: colorRoles.button.brandPrimarySurfaceDefault,
                ...elevation1Style,
                zIndex: 1,
              }}
              disabled={isLoading}
            />
          )}
        </>
      ) : (
        <ViewPostButton
          dailyPrompt={dailyPrompt}
          isMobile={isMobile}
          onViewPostPress={onViewPostPress}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default OpenPostCreatorButtons;
