import { useEffect, useState } from 'react';
import {
  A11yPhoneInput,
  ExtraHuge,
  Large,
  Modal,
  View,
  Button,
  useEmotionTheme,
  BaseButton,
  Standard,
  Tiny,
  Link,
  TwoFactorAuthenticationIcon,
  isPhoneNumberValid,
  Spinner,
} from '@talkspace/react-toolkit';
import { useHistory } from 'core/routerLib';
import { getUserData } from '@/auth/helpers/token';
import useIsTeenFunnelUser from '../hooks/useIsTeenFunnelUser';
import { trackClient2FAVerification } from '../utils/analytics';
import TwoFactorAuthenticationWrapper from '../containers/TwoFactorAuthenticationWrapper';
import { useClientAuthActions, useClientAuthState } from '../../hooks/clientAuthContext';
import useMutationChangeNumber from '../hooks/useMutationChangeNumber';

const TwoFactorAuthenticationChangeNumber = () => {
  const { colors } = useEmotionTheme();
  const history = useHistory();
  const { id: userID } = getUserData();
  const { isTeenFunnelUser, isLoadingTeenFunnelUser } = useIsTeenFunnelUser(userID);

  const { isUpdating, isError, twoFAStatus } = useClientAuthState();
  const { update2faReminderAction, setOTPTokenAction, setErrorAction } = useClientAuthActions();

  const [isPhoneError, setIsPhoneError] = useState<boolean>(false);
  const [phoneNumberInput, setPhoneNumberInput] = useState<string>('');
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState<string>('US');

  const {
    mutate: changeNumber,
    isLoading: isChangeNumberLoading,
    isSuccess: isSendingCodeSuccess,
  } = useMutationChangeNumber();

  useEffect(() => {
    trackClient2FAVerification('Change number flow');
  }, []);

  useEffect(() => {
    if (isError) history.push('/2fa/close');
  }, [isError, history]);

  useEffect(() => {
    if (isSendingCodeSuccess) history.push('/2fa/verify');
  }, [isSendingCodeSuccess, history]);

  const handleChangeNumber = () => {
    trackClient2FAVerification('Continue enable 2FA');
    if (!isPhoneNumberValid(phoneNumberInput)) {
      setIsPhoneError(true);
    } else {
      changeNumber(
        { phoneNumberCountryCode, phoneNumber: phoneNumberInput },
        {
          onSuccess: (data) => {
            if (data) {
              const { otpToken: currentOtpToken, phoneNumber } = data;
              setOTPTokenAction(currentOtpToken, phoneNumber);
            }
          },
          onError: (err) => {
            if (err?.data?.data?.error === 'Phone number is invalid') {
              setIsPhoneError(true);
            } else {
              setErrorAction('Error changing number');
            }
          },
        }
      );
    }
  };

  const handleReminderLater = () => {
    trackClient2FAVerification('I’ll do this later enable 2FA');
    update2faReminderAction();
  };

  return (
    <Modal isVisible isMobileFullscreen titleText="Enable two-factor authentication">
      <TwoFactorAuthenticationWrapper>
        <View flex={1} align="center" style={{ marginTop: 47, maxWidth: 335, textAlign: 'center' }}>
          <TwoFactorAuthenticationIcon />
          <ExtraHuge>Enable two-factor authentication</ExtraHuge>
          <Large variant="largeDarkGrey" style={{ maxWidth: 309, marginTop: 10 }}>
            To keep your account extra secure, we’ll send a 6-digit verification code to your phone
            number when you log in to make sure it’s you.
          </Large>

          <A11yPhoneInput
            containerStyle={{ marginTop: 30 }}
            style={{ width: 335 }}
            value={phoneNumberInput}
            handleInputValueChange={setPhoneNumberInput}
            handleCountryChange={setPhoneNumberCountryCode}
            dataQa="2faReminderPhoneInput"
            isError={isPhoneError}
          />
          <View style={{ width: '100%' }}>
            <Button
              dataQa="2faReminderContinueButton"
              disabled={isUpdating}
              isLoading={isUpdating || isChangeNumberLoading}
              text="Continue"
              onPress={handleChangeNumber}
              primaryColor={colors.green}
              style={{ width: '100%', marginTop: 30 }}
              roundedFocusStyle
            />
            {isLoadingTeenFunnelUser ? (
              <Spinner containerStyle={{ height: 'auto', padding: 20 }} />
            ) : (
              <>
                {twoFAStatus === 'suggested' && (
                  <BaseButton
                    data-qa="2faReminderLaterButton"
                    onPress={handleReminderLater}
                    style={{ marginTop: 20, borderRadius: 5 }}
                    primaryColor={colors.green}
                    roundedFocusStyle
                  >
                    <Standard variant="standardBoldGreen">
                      {isTeenFunnelUser ? 'Skip' : 'I’ll do this later'}
                    </Standard>
                  </BaseButton>
                )}
              </>
            )}
          </View>

          <Tiny style={{ marginTop: 'auto', marginBottom: 60 }}>
            Message and data rates may apply. For more information, see our{' '}
            <Link
              dataQa="2faReminderTermsLink"
              stopPropagation
              target="_blank"
              href="https://www.talkspace.com/public/terms"
              style={{ marginLeft: 0, marginRight: 0 }}
              primaryColor={colors.green}
              roundedFocusStyle
            >
              <Tiny variant="tinyBoldGreen" inline>
                Terms of use{' '}
              </Tiny>
            </Link>
            and{' '}
            <Link
              dataQa="2faReminderPrivacyLink"
              stopPropagation
              target="_blank"
              href="https://www.talkspace.com/public/privacy-policy"
              style={{ marginLeft: 0, marginRight: 0 }}
              primaryColor={colors.green}
              roundedFocusStyle
            >
              <Tiny variant="tinyBoldGreen" inline>
                Privacy policy.
              </Tiny>
            </Link>
          </Tiny>
        </View>
      </TwoFactorAuthenticationWrapper>
    </Modal>
  );
};

export default TwoFactorAuthenticationChangeNumber;
