import { FunctionComponent, useEffect, useCallback, useState } from 'react';
import { History } from 'history';
import { StepWizardProvider } from 'stepWizard';
import { Spinner } from '@talkspace/react-toolkit';
import { useTranslation } from '@talkspace/i18n';
import { RouteComponentProps } from '@/core/routerLib';
import { MainContextProvider } from '@/hooks/mainContext';
import { useGenericWizardActions } from '@/utils/genericWizardActions';
import { getUserData } from '@/auth/helpers/token';
import Routes from './Routes';
import { getInitialState, State } from './reducers/emergencyContactWizardState';
import emergencyContactActions from './hooks/emergencyContactActions';
import useQueryEmergencyContact from '@/hooks/useQueryEmergencyContact';

interface EmergencyContactRouteParams {
  roomID: number;
  source: string;
  contextID?: string;
  history: History;
}

const EmergencyContactWizard: FunctionComponent<
  RouteComponentProps<EmergencyContactRouteParams>
> = ({
  match: {
    params: { roomID, source, contextID = '' },
  },
}) => {
  const { id } = getUserData();

  const { data: emergencyContact, isLoading, isFetched } = useQueryEmergencyContact({ userID: id });

  const getFirstState = useCallback(getInitialState, [roomID, source, contextID]);

  const [initialState, setInitialState] = useState<State | undefined>();

  const genericMatchingIntakeActions = useGenericWizardActions(emergencyContactActions);

  const { t: tAdultOnboarding } = useTranslation('adultOnboarding');

  useEffect(() => {
    if (isFetched && !isLoading) {
      setInitialState(
        getFirstState(id, roomID, source, contextID, tAdultOnboarding, emergencyContact)
      );
    }
  }, [
    contextID,
    getFirstState,
    id,
    roomID,
    source,
    tAdultOnboarding,
    emergencyContact,
    isFetched,
    isLoading,
  ]);

  if (isLoading) {
    return <Spinner isLoading />;
  }

  return (
    <MainContextProvider>
      {initialState ? (
        <StepWizardProvider
          initialState={initialState}
          genericActions={genericMatchingIntakeActions}
        >
          <Routes />
        </StepWizardProvider>
      ) : null}
    </MainContextProvider>
  );
};

export default EmergencyContactWizard;
