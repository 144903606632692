import { useQuery } from 'react-query';

import { SessionStatus } from '../../chat/redux/constants/chatTypes';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/utils/api';
import { sessionStatusQueryKey } from './queryKeys';

export interface SessionStatusResponse extends SessionStatus {}

const fetchSessionStatus = (roomID: number) => async () => {
  const {
    data: { data },
  } = await apiWrapper.get(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/session-status`);
  return data;
};

const useQuerySessionStatus = (roomID: number) =>
  useQuery<SessionStatusResponse, Error>({
    queryKey: sessionStatusQueryKey(roomID),
    queryFn: fetchSessionStatus(roomID),
    enabled: Boolean(roomID),
  });

export default useQuerySessionStatus;
