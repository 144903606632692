import { Controller, useFormContext } from 'react-hook-form';

import {
  A11yFullAddress,
  Button,
  ExtraHuge,
  Input,
  Modal,
  ScrollView,
  View,
  EmotionTheme,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { countries, states } from '@talkspace/configs';
import styled from '@/core/styled';

const optionsFromConfig = (configData: Record<string, string>) =>
  Object.entries(configData).map(([value, label]) => {
    return {
      value,
      label,
    };
  });

const stateOptions = optionsFromConfig(states);
const countryOptions = optionsFromConfig(countries);

const Title = styled(ExtraHuge)({
  marginBottom: 8,
});

const baseInputStyle = (colors: EmotionTheme['colors'], isError: boolean) => {
  return {
    containerStyle: {
      width: '100%',
      marginTop: 12,
    },
    labelStyle: {
      marginBottom: 0,
    },
    inputStyle: {
      width: '100%',
      borderColor: isError ? colors.torchRed : colors.permaLividBlueNew,
      color: colors.black,
      '::placeholder': { color: colors.placeholderGrey },
    },
  };
};

const halfInputStyle = { maxWidth: 165 };

interface Props {
  isLoading: boolean;
  onClose: () => void;
}
const PersonalInformationEditMyInformationModal = ({ onClose, isLoading }: Props) => {
  const {
    colors,
    window: { height, isMobile },
  } = useEmotionTheme();
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <Modal.Panel isLoading={isLoading} onBackdropPress={onClose} contentViewStyle={{ padding: 0 }}>
      <ScrollView
        style={{
          maxHeight: height - 100,
          paddingLeft: 16,
          paddingRight: 16,
          paddingBottom: isMobile ? 30 : 60,
        }}
      >
        <View style={{ width: 336 }}>
          <Title as="h1">Personal details</Title>
          <View>
            <View row justify="space-between" align="end">
              <View style={halfInputStyle}>
                <Input
                  dataQa="firstNameInput"
                  wrappedInputProps={{
                    ...baseInputStyle(colors, !!errors.firstName),
                    label: 'Name',
                    isError: !!errors.firstName,
                    errorMessage: errors.firstName?.message,
                  }}
                  placeholder="First name"
                  placeHolderStyle={{ color: colors.permaWaikawaGreyNew }}
                  {...register('firstName')}
                />
              </View>
              <View style={{ ...halfInputStyle }}>
                <Input
                  dataQa="lastNameInput"
                  wrappedInputProps={{
                    ...baseInputStyle(colors, !!errors.lastName),
                    isError: !!errors.lastName,
                    errorMessage: errors.lastName?.message,
                  }}
                  placeholder="Last name"
                  placeHolderStyle={{ color: colors.permaWaikawaGreyNew }}
                  {...register('lastName')}
                />
              </View>
            </View>
            <Controller
              control={control}
              name="dateOfBirth"
              render={({
                field,
                formState: {
                  errors: { dateOfBirth: dobError },
                },
              }) => (
                <Input
                  dataQa="dateOfBirthInput"
                  date-format="MM/dd/yyyy"
                  maskType="date"
                  placeholder="MM/DD/YYYY"
                  placeHolderStyle={{ color: colors.permaWaikawaGreyNew }}
                  wrappedInputProps={{
                    ...baseInputStyle(colors, !!dobError),
                    label: 'Date of birth',
                    isError: !!dobError,
                    errorMessage: dobError?.message,
                  }}
                  {...field}
                />
              )}
            />
          </View>
          <View style={{ marginTop: 12, width: '100%' }}>
            <A11yFullAddress
              addressLine1Name="address"
              addressLine2Name="address2"
              states={stateOptions}
              countries={countryOptions}
            />
          </View>
          <Button
            text="Save"
            style={{
              width: '100%',
              marginTop: 30,
              backgroundColor: colors.permaTalkspaceDarkGreen,
            }}
            dataQa="saveButton"
          />
          <Button
            dataQa="cancelButton"
            text="Cancel"
            onPress={onClose}
            style={{
              color: colors.permaTalkspaceDarkGreen,
              backgroundColor: colors.white,
              width: '100%',
            }}
          />
        </View>
      </ScrollView>
    </Modal.Panel>
  );
};

export default PersonalInformationEditMyInformationModal;
