import { FunctionComponent, useEffect, useState } from 'react';
import { View } from '@talkspace/react-toolkit';
import { isStripeUsingLink, useStripeTs } from 'stripe/stripeContext';
import { PaymentDetails } from 'checkout';
import { RouteComponentProps, withRouter } from '../../core/routerLib';
import { useCloseModal } from '../../utils/ModalsContextProvider';
import {
  useInsuranceEligibilityActions,
  useInsuranceEligibilityState,
} from '../hooks/InsuranceEligibilityContext';
import useAccountDetails from '../../myAccount/hooks/useAccountDetails';

const PaymentDetailsContainer: FunctionComponent<RouteComponentProps<{ roomID: string }>> = ({
  match,
  history,
}) => {
  const [useStripeLink, setUseStripeLink] = useState(true);

  const roomID = Number(match.params.roomID);

  const { eligibilityInfo, planInfo, savings, purchaseStatus, purchaseError } =
    useInsuranceEligibilityState();
  const { purchaseBHSession, resetError } = useInsuranceEligibilityActions();
  const [{ paymentDetails }, { onLoadStripeLink, dispatchPaymentMethodError }] =
    useAccountDetails();

  const closeModal = useCloseModal();

  const stripe = useStripeTs();

  useEffect(() => {
    if (purchaseStatus === 'success') {
      closeModal({ navigateTo: 'room', metadata: { roomID } });
    }

    setUseStripeLink(isStripeUsingLink(stripe));
  }, [closeModal, purchaseStatus, roomID, stripe]);

  if (!eligibilityInfo) {
    history.push(`/insurance-eligibility/room/${roomID}/checkout`);
  }

  const handleSubmit = (token: string) => {
    purchaseBHSession(roomID, token);
  };

  return eligibilityInfo ? (
    <View align="center" style={{ marginTop: 30, paddingLeft: 16, paddingRight: 16 }}>
      <PaymentDetails
        onSubmit={handleSubmit}
        resetError={resetError}
        onLoadStripeLink={onLoadStripeLink}
        onStripeLinkError={dispatchPaymentMethodError}
        provider={paymentDetails?.provider || 'Stripe'}
        currency={planInfo?.billingPrice.currency || 'USD'}
        submitText="Complete purchase"
        total={eligibilityInfo.copayCents / 100}
        savings={savings}
        isProcessing={purchaseStatus === 'loading'}
        totalsVariant="standard"
        errorMessage={purchaseError}
        stripe={stripe}
        useStripeLink={useStripeLink}
      />
    </View>
  ) : null;
};

export default withRouter(PaymentDetailsContainer);
