import { useWizardState } from 'stepWizard';
import { FunctionComponent } from 'react';
import {
  Button,
  View,
  Text,
  BaseButton,
  Huge,
  Large,
  Small,
  Big,
  useEmotionTheme,
  A11Y_COLORS,
} from '@talkspace/react-toolkit';
import { History } from 'history';
import styled from '@/core/styled';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { safeRouteNavigateOrPush } from '@/utils/analytics/helpers';

const Wrapper = styled(View)<{ source: string }>(({ source }) => {
  return {
    alignSelf: 'center',
    width: 340,
    alignItems: 'center',
    height: 300,
    textAlign: 'center',
    margin: '40px',
    ...(source !== 'onboarding' && {
      justifyContent: 'space-between',
    }),
  };
});

const RedText = styled(Text)<{ colors: typeof A11Y_COLORS }>(({ colors }) => {
  return {
    color: colors.red,
    display: 'inline',
    fontWeight: 'bold',
  };
});

const BaseButtonStyled = styled(BaseButton)({
  background: 'white',
});

const EmergencyResourcesModal: FunctionComponent<{ history: History }> = ({ history }) => {
  const closeModal = useCloseModal();
  const { source, roomID } = useWizardState<{ source: string; roomID: number }>();
  const { colors } = useEmotionTheme();

  return (
    <Wrapper source={source}>
      <Huge>If you’re in immediate danger, don’t use this app. Instead, use these resources:</Huge>
      <Large style={{ marginTop: 32 }}>
        Call{' '}
        <RedText inline colors={colors}>
          988
        </RedText>{' '}
        for immediate crisis support.{' '}
      </Large>
      <br />
      <Large style={{ marginTop: 12 }}>
        If you’re feeling suicidal but are not in immediate danger, contact someone immediately such
        as your doctor, a friend, a relative, or someone you can trust.
      </Large>
      <Button
        dataQa="viewEmergencyResourcesButton"
        style={{
          ...(source === 'onboarding' && { marginTop: 26 }),
          backgroundColor: colors.permaTalkspaceDarkGreen,
        }}
        onClick={() => {
          safeRouteNavigateOrPush(history, 'https://helpnow.talkspace.com/', true);
        }}
      >
        View Emergency Resources
      </Button>
      {source === 'onboarding' ? (
        <BaseButtonStyled
          dataQa="continueToMedicalHistoryButton"
          onClick={() =>
            history.push(`/room/${roomID}/onboarding/medical-health/source/onboarding`)
          }
        >
          <Big variant="bigBlueStoneNew" style={{ paddingTop: 17 }}>
            Continue
          </Big>
        </BaseButtonStyled>
      ) : (
        <BaseButtonStyled dataQa="returnToChatButton" onClick={() => closeModal()}>
          <Small style={{ color: colors.altoGrey }}>Return to chat</Small>
        </BaseButtonStyled>
      )}
    </Wrapper>
  );
};

export default EmergencyResourcesModal;
