import { FunctionComponent, useState } from 'react';
import { View, Button, Large, ExtraHuge, InGoodHands, Screen } from '@talkspace/react-toolkit';
import { useLocation } from 'react-router';
import styled from '../../core/styled/index';
import { useCloseModal } from '@/utils/ModalsContextProvider';

const Title = styled(ExtraHuge)({ maxWidth: 300, textAlign: 'center', fontSize: 29 });

const SelectButton = styled(Button)({ marginTop: 40 });

const StyledLine = styled(Large)({ maxWidth: 320, textAlign: 'center', marginTop: 10 });

const FindingProvider: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const closeModal = useCloseModal();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const hasRemainingSessions = queryParams.get('hasRemainingSessions') === 'true';

  const onPressHandler = () => {
    setIsLoading(true);

    closeModal({
      navigateTo: 'room',
      metadata: {
        roomID: Number(queryParams.get('room')),
      },
    });
  };

  return (
    <Screen onCloseButtonClick={onPressHandler} safeAreaVariant="setHeightAndAddTop">
      <View align="center" style={{ marginTop: 23, marginBottom: 23 }}>
        <View style={{ marginBottom: 37 }}>
          <InGoodHands />
        </View>
        <Title>{`You're in good hands. \n We're busy finding you a provider.`}</Title>
        <StyledLine variant="largeDarkGrey">
          This process typically takes a few days. You’ll receive an email when you’re matched.
        </StyledLine>
        <SelectButton
          text={hasRemainingSessions ? 'Finish services in my old room' : 'Go back to my room'}
          isLoading={isLoading}
          onPress={onPressHandler}
          dataQa="findingProviderButtonPress"
          disabled={isLoading}
        />
      </View>
    </Screen>
  );
};

export default FindingProvider;
