import moment from 'moment';

import { Booking, SessionModalityToBookingName } from 'ts-frontend/types';
import { TagColorName } from '@talkspace/react-toolkit/src/designSystems/components/Tag';
import {
  Button,
  Card,
  Tag,
  TextDS,
  View,
  spacing,
  useEmotionTheme,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { Envelope, Phone, Video } from '@talkspace/react-toolkit/src/designSystems/icons';
import { useTranslation } from '@talkspace/i18n';
import {
  isBookingActive,
  isBookingTentative,
  isBookingScheduledByProvider,
} from '../../utils/booking';
import { UseNavShellData } from '../../hooks/useNavShellData';
import useJoinSession from '../../hooks/useJoinSession';
import BookingsListControlButtons from './BookingsListControlButtons';
import { trackCTAClick } from '../../utils/analytics/events';

const { space050, space100, space200 } = spacing;

const DateIcon = ({ booking: { startTime } }: { booking: Booking }) => {
  const { colorRoles } = useEmotionTheme();
  const dateMoment = moment(startTime);
  const monthText = dateMoment.format('MMM').toUpperCase();
  const dayText = dateMoment.format('D');
  return (
    <View
      justify="center"
      align="center"
      style={{
        width: 60,
        height: 60,
        borderRadius: 10,
        padding: '12px 8px',
        backgroundColor: colorRoles.surfaces.decorativeNeutral300,
        marginRight: space200,
      }}
    >
      <TextDS colorRole="textSubtle" variant="headingXs">
        {monthText}
      </TextDS>
      <TextDS colorRole="textSubtle" variant="headingXl">
        {dayText}
      </TextDS>
    </View>
  );
};

const BookingModalityIcon = ({ booking: { modality } }: { booking: Booking }) => {
  // TODO: add way to pass color role to icons and use iconSubtleDefault for these
  switch (modality) {
    case 'video':
      return <Video colorType="subtle" />;
    case 'chat':
    case 'messaging':
      return <Envelope colorType="subtle" />;
    case 'audio':
      return <Phone colorType="subtle" />;
    default:
      return null;
  }
};

const ModalityRow = ({ booking }: { booking: Booking }) => (
  <View row>
    <BookingModalityIcon booking={booking} />
    <TextDS colorRole="textSubtle" variant="bodySm" style={{ marginLeft: space050 }}>
      {SessionModalityToBookingName[booking.modality]}
    </TextDS>
  </View>
);

const DateTimeRow = ({ booking }: { booking: Booking }) => {
  const { isMobile } = useWindowWidthState();
  const isMessaging = booking.modality === 'messaging';
  const start = moment(booking.startTime);
  const end = moment(booking.startTime).add(booking.creditMinutes, 'minutes');
  const dateText = isMessaging
    ? `Started on ${start.format('dddd, MMM D')}`
    : start.format('dddd, MMM D');
  const durationText = isMessaging
    ? undefined
    : `${start.format('h:mma')} - ${end.format('h:mma')}`;
  return (
    <View row={!isMobile}>
      <TextDS variant="headingMd">{dateText}</TextDS>
      {!isMobile && <>&nbsp;</>}
      <TextDS>{durationText}</TextDS>
    </View>
  );
};

const StatusRow = ({
  activeSessionData,
  booking,
}: Pick<UseNavShellData, 'activeSessionData'> & {
  booking: Booking;
}) => {
  let tagColor: TagColorName | undefined;
  let tagText: string | undefined;
  if (isBookingActive(booking, activeSessionData)) {
    tagColor = 'successDefault';
    tagText = 'In progress';
  } else if (isBookingTentative(booking) && isBookingScheduledByProvider(booking)) {
    tagColor = 'warningDefault';
    tagText = 'Awaiting your confirmation';
  }
  if (!tagColor || !tagText) return null;

  return <Tag color={tagColor} text={tagText} style={{ marginTop: space100 }} />;
};

const CTARow = ({
  activeSessionData,
  booking,
  isMobile,
  isBH,
}: Pick<UseNavShellData, 'activeSessionData'> & {
  booking: Booking;
  isMobile?: boolean;
  isBH: boolean;
}) => {
  const { roomID } = booking;
  const isMessaging = booking.modality === 'messaging';
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  const { onClickJoinSession, isDisabled, isTentative } = useJoinSession({
    roomID,
    activeSession: activeSessionData,
    booking,
    useRedirect: false,
    isBH,
  });

  if (!isBookingActive(booking, activeSessionData)) return null;

  let buttonText = '';
  if (isMessaging) {
    buttonText = tnewMemberNav('schedule.continue', 'Continue messaging');
  } else if (isTentative) {
    buttonText = tnewMemberNav('schedule.continueTo', 'Continue to session');
  } else {
    buttonText = tnewMemberNav('schedule.join', 'Join session');
  }

  return (
    <View style={{ marginTop: space200 }}>
      <Button
        text={buttonText}
        onPress={(event) => {
          trackCTAClick(buttonText, 'schedule', { roomID });
          onClickJoinSession(event);
        }}
        disabled={isDisabled}
        style={{ ...(isMobile ? { width: 'unset', maxWidth: 'unset' } : {}) }}
      />
    </View>
  );
};

interface Props extends Pick<UseNavShellData, 'activeSessionData' | 'dataByRoomID'> {
  booking: Booking;
  isLast: boolean;
}
const BookingsListItem = ({ activeSessionData, booking, dataByRoomID, isLast }: Props) => {
  const { isMobile } = useWindowWidthState();
  const therapistName = dataByRoomID?.[booking.roomID].therapistInfo?.firstName;
  const isBH = dataByRoomID?.[booking.roomID].subscription?.subscription.isBH;
  const bookingTimeA11y = moment(booking.startTime).format('MMMM D hh mm a');
  const a11yLabel =
    booking.modality === 'messaging'
      ? `messaging session with ${therapistName} started on ${bookingTimeA11y}`
      : `Upcoming ${booking.modality} session with ${therapistName} starting at ${bookingTimeA11y}`;
  return (
    <Card
      aria-label={a11yLabel}
      tabIndex={0}
      key={booking.id}
      variant="topBottomBorder"
      removeTopBorder
      removeBottomBorder={isLast}
      dataQa="liveSessionBookingCard"
    >
      <View row justify="space-between" style={{ width: '100%' }}>
        <View row>
          <DateIcon booking={booking} />
          <View>
            <ModalityRow booking={booking} />
            <DateTimeRow booking={booking} />
            {therapistName && (
              <TextDS variant="bodySm" colorRole="textSubtle">
                with {therapistName}
              </TextDS>
            )}
            <StatusRow activeSessionData={activeSessionData} booking={booking} />
            {!isMobile && (
              <CTARow isBH={!!isBH} activeSessionData={activeSessionData} booking={booking} />
            )}
            {isMobile && (
              <BookingsListControlButtons booking={booking} activeSessionData={activeSessionData} />
            )}
          </View>
        </View>
        {!isMobile && (
          <BookingsListControlButtons booking={booking} activeSessionData={activeSessionData} />
        )}
      </View>
      {isMobile && (
        <CTARow
          isBH={!!isBH}
          activeSessionData={activeSessionData}
          booking={booking}
          isMobile={isMobile}
        />
      )}
    </Card>
  );
};

export default BookingsListItem;
