import { FunctionComponent, PropsWithChildren, useEffect, useRef } from 'react';
import {
  CaretLeft,
  PanelHeader,
  ResponsiveLayoutWithHeader,
  SkipNavigation,
  TSLogo,
  TouchableView,
  View,
  useComponentSize,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { TherapistInfo } from 'ts-frontend/entities/Therapist';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { useHistory, useRouteMatch, useLocation, Route, Switch, Redirect } from '@/core/routerLib';
import PendingInvoicesList from '../components/PendingInvoicesList';
import PendingInvoicesForm from '../components/PendingInvoicesForm';
import PaymentWizardSuccess from '../components/PaymentWizardSuccess';
import PaymentWizardFork from '../components/PaymentWizardFork';

export type PaymentWizardContainerProps = {
  therapist: TherapistInfo;
  clientUserID: number;
  onLoadStripeLink?: () => void;
  onStripeLinkError?: () => void;
};

const BackButton = ({
  hidden,
  onPress,
  zeroHeight,
}: {
  hidden?: boolean;
  zeroHeight?: boolean;
  onPress: () => void;
}) => {
  const { colors } = useEmotionTheme();
  if (hidden) {
    return null;
  }
  return (
    <View
      row
      alignSelf="start"
      justify="space-between"
      style={zeroHeight ? { height: 0, overflow: 'visible' } : undefined}
    >
      <TouchableView
        dataQa="inRoomSchedulerBackButton"
        onPress={onPress}
        style={{ display: 'inline', ...(zeroHeight ? { margin: 10, padding: 20 } : {}) }}
        id="back"
        aria-label="back"
      >
        <CaretLeft color={colors.green} style={zeroHeight ? { marginTop: -30 } : undefined} />
      </TouchableView>
    </View>
  );
};
const ClientHeader = ({
  skipNavs,
  children,
  handleBackClick,
  shouldHideBackArrow,
  handleOnRoomPanelClosePress,
  isMobile,
}: PropsWithChildren<{
  skipNavs?: SkipNavigation[];
  shouldHideBackArrow: boolean;
  handleBackClick: () => void;
  handleOnRoomPanelClosePress?: () => void | undefined;
  isMobile: boolean;
}>) => {
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);
  const { brandRefreshChanges } = useFlags();

  useEffect(() => {
    if (containerRef?.current && location?.pathname) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [containerRef, location?.pathname]);
  const renderLeft = () => (
    <>
      {isMobile && (
        <View style={{ width: 13 }}>
          <BackButton onPress={handleBackClick} hidden={shouldHideBackArrow || !isMobile} />
        </View>
      )}
      <View flex={1} align={isMobile ? 'center' : 'start'}>
        <TSLogo variant={brandRefreshChanges ? '2024' : 'old'} />
      </View>
    </>
  );
  return (
    <ResponsiveLayoutWithHeader
      safeAreaVariant="setHeightAndAddTop"
      ref={containerRef}
      renderHeader={() => (
        <PanelHeader
          renderLeft={renderLeft}
          onRightPress={handleOnRoomPanelClosePress}
          skipNavs={skipNavs}
          isMobile={isMobile}
          dataQa="closeInRoomScheduler"
        />
      )}
    >
      {children}
    </ResponsiveLayoutWithHeader>
  );
};

const PaymentWizardContainer: FunctionComponent<PaymentWizardContainerProps> = ({
  therapist,
  clientUserID,
  onLoadStripeLink = () => {},
  onStripeLinkError = () => {},
}) => {
  const [containerRef, { isMobile }] = useComponentSize<HTMLDivElement>();
  const closeModal = useCloseModal();

  const match = useRouteMatch<{ roomID: string }>();
  const history = useHistory();
  const location = useLocation<{ roomID: number } | undefined>();

  const shouldHideBackArrow = !!location.pathname.includes('pending-invoices/success');
  const useNewNav = useNewMemberNav();

  const handleCloseClick = () => {
    const search = new URLSearchParams(location.search);
    if (useNewNav && search.get('source') === 'updatePaymentMethod') {
      closeModal({
        navigateTo: 'manageSubscription',
        metadata: { roomID: Number(match.params.roomID) },
      });
    } else {
      closeModal();
    }
  };

  const handleBackClick = () => {
    history.goBack();
  };

  return (
    <ClientHeader
      isMobile={isMobile}
      handleBackClick={handleBackClick}
      handleOnRoomPanelClosePress={handleCloseClick}
      shouldHideBackArrow={shouldHideBackArrow || !isMobile}
    >
      <View
        ref={containerRef}
        flex={1}
        style={{ padding: '0 20px', paddingBottom: 69 }}
        align="center"
      >
        <BackButton
          hidden={shouldHideBackArrow || (useNewNav && isMobile)}
          onPress={handleBackClick}
          zeroHeight
        />
        <View flex={1} style={{ maxWidth: 375, paddingTop: 16 }}>
          <Switch>
            <Route
              exact
              path={`${match.path}/pending-invoices`}
              render={() => (
                <PendingInvoicesList clientUserID={clientUserID} therapist={therapist} />
              )}
            />
            <Route
              path={`${match.path}/pending-invoices/pay`}
              render={() => (
                <PendingInvoicesForm
                  clientUserID={clientUserID}
                  onLoadStripeLink={onLoadStripeLink}
                  onStripeLinkError={onStripeLinkError}
                  therapist={therapist}
                />
              )}
            />
            <Route
              path={`${match.path}/pay`}
              render={() => (
                <PendingInvoicesForm
                  clientUserID={clientUserID}
                  onLoadStripeLink={onLoadStripeLink}
                  onStripeLinkError={onStripeLinkError}
                  therapist={therapist}
                />
              )}
            />
            <Route
              path={[`${match.path}/pending-invoices/success`, `${match.path}/success`]}
              render={() => (
                <PaymentWizardSuccess clientUserID={clientUserID} therapist={therapist} />
              )}
            />
            <Route
              exact
              path={match.path}
              render={() => <PaymentWizardFork clientUserID={clientUserID} />}
            />
            <Redirect to={{ pathname: match.path }} />
          </Switch>
        </View>
      </View>
    </ClientHeader>
  );
};

export default PaymentWizardContainer;
