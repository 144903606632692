import { WizardGenericActions } from 'stepWizard';
import { State } from '../reducers/surveyWizardState';
import SurveyWizardApiHelper from '../utils/surveyWizardApiHelper';

const initState: WizardGenericActions<State, SurveyWizardApiHelper>['initState'] = async (api) => {
  return {
    submitSurveyAnswerPostAction: (value, context) => {
      const { step, isRepeatedAnswer, wizardContext, setState } = context;
      const { userRoomSurveyID, surveySections, onSurveyProgress, currentStepNumber } =
        wizardContext;
      const sectionIndex = surveySections.findIndex((s) => s.prompt === step.name);
      const section = surveySections[sectionIndex];
      const responseOption = section?.responseOptions?.find((o) => o.id === value);
      if (!responseOption || !section || isRepeatedAnswer) {
        return;
      }

      const lastAnswerableIndex = surveySections.length - 2; // -1 for index, and another -1 to remove the last step, which is always an INSTRUCTIONS step
      const stepNumberWithWelcomeScreen = currentStepNumber + 1; // currentStepNumber + 1 to account for welcome screen not being a step

      if (sectionIndex === lastAnswerableIndex) setState({ isSubmittingLastAnswer: true });
      api
        .postSurveyUserResponse(
          {
            sectionID: section.id,
            isCancellation: false,
            responseOptionID: responseOption.id,
            responseOptionPrompt: responseOption.prompt,
            responseOptionSetID: section.responseOptionSetID,
          },
          userRoomSurveyID
        )
        .then(() => {
          if (sectionIndex === lastAnswerableIndex)
            setState({
              isSubmittingLastAnswer: false,
            });
          if (onSurveyProgress) {
            onSurveyProgress(section.prompt, responseOption, stepNumberWithWelcomeScreen, false);
          }
        });
    },
    instructionsButtonPostAction: (value, context) => {
      const { step, wizardContext } = context;
      const { surveySections, onSurveyProgress, currentStepNumber } = wizardContext;
      const stepNumberWithWelcomeScreen = currentStepNumber + 1; // currentStepNumber + 1 to account for welcome screen not being a step
      const section = surveySections.find((s) => s.prompt === step.title);

      if (!section || !onSurveyProgress) {
        return;
      }
      onSurveyProgress(
        section.prompt,
        undefined,
        stepNumberWithWelcomeScreen,
        step.nextButton.actionDispatch !== 'next'
      );
    },
  };
};

const surveyWizardActions: Omit<
  WizardGenericActions<State, SurveyWizardApiHelper>,
  'closeWizardAction'
> = {
  initState,
};

export default surveyWizardActions;
