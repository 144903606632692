import { useParams } from '@/core/routerLib';

import CommunityPostDetails from '../components/CommunityPostDetails';

import useCommunityPost from '../hooks/useCommunityPost';
import { getPostCommentingEndedAt } from '../utils/helpers';
import { TEENSPACE_DAY_END_SHIFT_HOURS } from '../constants';
import { useCommunityState } from '../hooks/CommunityContext';

const CommunityPostDetailsContainer = () => {
  const { postID } = useParams<{ postID: string }>();
  const { clientTimezone } = useCommunityState();
  const { post, isLoading: isLoadingPost } = useCommunityPost({ postID });

  const commentingEndedAt = getPostCommentingEndedAt({
    post,
    timezone: clientTimezone,
    shiftHours: TEENSPACE_DAY_END_SHIFT_HOURS,
  });
  return (
    <CommunityPostDetails
      post={post}
      isLoadingPost={isLoadingPost}
      commentingEndedAt={commentingEndedAt}
    />
  );
};

export default CommunityPostDetailsContainer;
