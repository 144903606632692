import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

function FacebookRounded({
  width = 42,
  height = 42,
  fill,
  ...props
}: {
  width?: number;
  height?: number;
  fill?: string;
}) {
  const { colors } = useEmotionTheme();
  const titleText = 'Facebook';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <Path
        fill={fill || colors.chambray}
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        d="M21.003 0C32.597 0 42 9.403 42 21.003 42 32.597 32.597 42 21.003 42 9.403 42 0 32.597 0 21.003 0 9.403 9.403 0 21.003 0zm8.912 10.64H11.849c-.62 0-.929.758-.929 1.377v18.066c0 .62.31 1.023.93 1.023h9.58v-7.744h-2.766v-3.319h2.766v-2.03c0-2.622 1.77-4.049 4.104-4.049 1.123 0 1.98.083 2.533.122v2.639H26.26c-1.273 0-1.51.708-1.51 1.593v1.725h3.025l-.398 3.32h-2.627v7.743h5.166c.614 0 .918-.404.918-1.023V12.017c0-.62-.304-1.377-.918-1.377z"
        transform="translate(-671 -458) translate(433 454) translate(238 4)"
      />
    </Svg>
  );
}

export default FacebookRounded;
