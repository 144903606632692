import { useQuery } from 'react-query';
import { RoomsAPIResponse } from '@/reducers/mainReducer';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

import { getAllRoomsQueryKey } from '../utils/queryKeys';

interface QueryProps {
  clientUserID: string | number;
}
type QueryData = RoomsAPIResponse;

const fetchGetAllRooms =
  ({ clientUserID }: QueryProps) =>
  async () => {
    const resp = await apiWrapper.get(
      `${
        apiHelper().apiEndpoint
      }/v2/clients/${clientUserID}/rooms?include=clients,therapistInfo,cmsItems`
    );
    const {
      data: { data, included },
    } = resp;
    return { data, included };
  };

const useQueryGetAllRooms = ({ clientUserID }: QueryProps) =>
  useQuery<QueryData, Error>({
    queryKey: getAllRoomsQueryKey({ clientUserID }),
    queryFn: fetchGetAllRooms({ clientUserID }),
    cacheTime: 5 * 1000,
    staleTime: 0,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: true,
    enabled: Boolean(clientUserID),
  });

export default useQueryGetAllRooms;
