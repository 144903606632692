import { FunctionComponent } from 'react';
import { View, Button, Huge, Large } from '@talkspace/react-toolkit';
import ScrollViewComponent from 'chat/components/ScrollViewComponent';
import styled from '../../core/styled';

interface RefundPolicyComponentProps {
  buttonHandler: (e?) => void;
  buttonText: string;
}

const FadeOut = styled(View)({
  backgroundImage: 'linear-gradient(to top, rgba(255,255,255,1),rgba(255,255,255,0))',
  backgroundRepeat: 'repeat-x',
  height: 100,
  pointerEvents: 'none',
  width: '90%',
  marginTop: -98,
  zIndex: 1,
});

const StyledLarge = styled(Large)({
  paddingTop: 20,
});

const ScrollView = styled(ScrollViewComponent)<{ scrollHeight?: number }>(
  ({
    scrollHeight,
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      paddingLeft: 14,
      alignItems: 'center',
      height: scrollHeight && !isMobile ? scrollHeight : (11 * window.innerHeight) / 16,
    };
  }
);

const RefundPolicyComponent: FunctionComponent<RefundPolicyComponentProps> = ({
  buttonHandler,
  buttonText,
}) => (
  <View style={{ marginTop: 15 }}>
    <ScrollView style={{ alignItems: 'flex-start', flexDirection: 'column' }}>
      <View>
        <Huge>Refund information</Huge>
        <StyledLarge variant="largeDarkGrey">
          If you’ve cancelled your service before the end of your subscription period, your refund
          amount is based on when in your billing cycle you cancelled.
        </StyledLarge>
      </View>
      <View style={{ paddingLeft: 20, paddingTop: 20, paddingBottom: 20 }}>
        <StyledLarge variant="largeDarkGrey">
          For cancellations within 48 hours, we issue a full refund.
        </StyledLarge>
        <StyledLarge variant="largeDarkGrey">
          For cancellations of monthly subscriptions after 48 hours but within 7 days, the refund
          amount is equal to 3 weeks of service.
        </StyledLarge>
        <StyledLarge variant="largeDarkGrey">
          For cancellations of monthly subscriptions 7-13 days after payment, the refund amount is
          equal to 2 weeks of service.
        </StyledLarge>
        <StyledLarge variant="largeDarkGrey">
          For cancellations of monthly subscriptions 14-20 days after payment, the refund amount is
          equal to 1 week of service.
        </StyledLarge>
        <StyledLarge variant="largeDarkGrey">
          For cancellations of monthly subscriptions 3 weeks after payment, a refund will not be
          issued.
        </StyledLarge>
        <StyledLarge variant="largeDarkGrey">
          Any client with a 3-month or 6-month subscription who has cancelled before the end of
          their subscription period will be eligible for a refund based on the number of unused
          months of service.
        </StyledLarge>
      </View>
    </ScrollView>
    <View align="center">
      <FadeOut />
      <Button onClick={buttonHandler} text={buttonText} />
    </View>
  </View>
);

export default RefundPolicyComponent;
