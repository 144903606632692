import { AllJSONLaunchDarklyExperimentNames } from 'ts-frontend/types';

interface TeenspaceCROptimizationExtraContext {
  flowID: number;
  teenspaceFlowID: number;
}

interface InsuranceConfirmationStepExtraContext {
  isUpdateCoverage: boolean;
}

type ExtraLogicContextFunction<T = unknown> = (context: T) => boolean;

// Add all other contexts here with the `|` separator
export type IsExtraTreatmentContext =
  | TeenspaceCROptimizationExtraContext
  | InsuranceConfirmationStepExtraContext;

export const isTeenspaceCROptimizationTreatment: ExtraLogicContextFunction<
  TeenspaceCROptimizationExtraContext
> = (context) => context.flowID === context.teenspaceFlowID;

export const isInsuranceConfirmationStepTreatment: ExtraLogicContextFunction<
  InsuranceConfirmationStepExtraContext
> = (context) => !!context.isUpdateCoverage;

const experimentToLogicMap: Partial<
  Record<AllJSONLaunchDarklyExperimentNames, ExtraLogicContextFunction>
> = {
  teenspaceCrOptimization: isTeenspaceCROptimizationTreatment,
  insuranceConfirmationStep: isInsuranceConfirmationStepTreatment,
};

export default experimentToLogicMap;
