import { ReactNode } from 'react';

import { Large, Small, View, useEmotionTheme, useWindowWidthState } from '@talkspace/react-toolkit';

interface Props {
  icon: ReactNode;
  title: string;
  text: string;
}
const EligibilityWarningInfoItem = ({ icon, title, text }: Props) => {
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  return (
    <View align={isMobile ? undefined : 'center'} style={{ marginTop: isMobile ? 24 : 42 }}>
      <View row align="center">
        <View>{icon}</View>
        <Large style={{ marginLeft: 8, fontWeight: 700 }}>{title}</Large>
      </View>
      <View style={{ marginLeft: isMobile ? 32 : undefined, width: isMobile ? 295 : 395 }}>
        <Small
          style={{ marginTop: 6, color: colors.black, textAlign: isMobile ? undefined : 'center' }}
        >
          {text}
        </Small>
      </View>
    </View>
  );
};

export default EligibilityWarningInfoItem;
