import { useQuery } from 'react-query';

import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/utils/api';
import { clientDetailsQueryKey } from './queryKeys';

export interface ClientDetailsResponse {
  userID: number;
  preferredName?: string;
  gender?: string;
  yearOfBirth?: number;
  countryState?: string;
  maritalStatus?: string;
  levelOfEducation?: string;
  ethnicity?: string;
  therapistGenderPreference?: string;
  dateOfBirth?: string;
}

const fetchClientDetails = (userID: number | string) => async () => {
  const { data } = await apiWrapper.get(`${apiHelper().apiEndpoint}/api/v1/clients/${userID}`);
  return data.data;
};

const useQueryClientDetails = (userID: number | string) =>
  useQuery<ClientDetailsResponse, Error>({
    queryKey: clientDetailsQueryKey(userID),
    queryFn: fetchClientDetails(userID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: Boolean(userID),
  });

export default useQueryClientDetails;
