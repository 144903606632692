import { useQuery } from 'react-query';

import { getUserPostsQueryKey } from '../../utils/queryKeys';
import { findTodaysPostByUser } from '../utils/helpers';

interface QueryProps {
  accessToken: string | undefined;
  communityID: string | undefined;
  userID: string | undefined;
  timezone: string | undefined;
}

const fetchUserPostFromToday =
  ({ accessToken, communityID, userID, timezone }: QueryProps) =>
  async (): Promise<Amity.Post> => {
    const response = await fetch(
      `https://beta.amity.services/api/v3/search/posts?targetType=community&targetIds[]=${communityID}&postedUserId=${userID}&options[orderBy]=desc`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const {
      data: { posts },
    } = await response.json();
    return timezone && findTodaysPostByUser({ posts, timezone });
  };

// NOTE: intentionally single purpose. Minimize usage of the Amity beta API, use SDK methods instead when possible
const useQueryGetUserPostFromToday = ({
  communityID,
  accessToken,
  userID,
  timezone,
  disabled,
}: QueryProps & { disabled?: boolean }) =>
  useQuery<Amity.Post, Error>({
    queryKey: getUserPostsQueryKey({ communityID, userID }),
    queryFn: fetchUserPostFromToday({ accessToken, communityID, userID, timezone }),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: [userID, communityID, accessToken].every((e) => Boolean(e)) && !disabled,
  });

export default useQueryGetUserPostFromToday;
