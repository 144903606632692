import { FunctionComponent, useEffect } from 'react';
import {
  useUniqueID,
  View,
  Button,
  Large,
  ExtraHuge,
  usePasswordValidation,
  Password,
  SpacingView,
} from '@talkspace/react-toolkit';
import { PasswordInputMode } from 'ts-frontend/types';
import styled from '../../../core/styled/styled';

const Container = styled(View)({ width: 336 });

const HeaderWrapper = styled(View)({
  textAlign: 'left',
  marginBottom: 20,
  paddingLeft: 4,
});

const Disclaimer = styled(View)({
  textAlign: 'left',
  paddingLeft: 4,
});

const InputWrapper = styled(View)({
  marginTop: 20,
});
interface Props {
  errorFromAPI: string;
  onContinuePress: (password: string) => void;
  onCancelPress: () => void;
  clearError: () => void;
}

const PasswordRequiredModal: FunctionComponent<Props> = ({
  errorFromAPI,
  onContinuePress,
  onCancelPress,
  clearError,
}) => {
  const [{ password, setPasswordError, onSubmitPassword }, { onChangeText, ...passwordProps }] =
    usePasswordValidation({
      passwordInputMode: PasswordInputMode.LOGIN,
    });

  useEffect(() => {
    if (errorFromAPI) setPasswordError(errorFromAPI, true);
  }, [errorFromAPI, setPasswordError]);

  const validateInput = () => {
    if (!onSubmitPassword()) {
      return false;
    }

    return true;
  };

  const handleContinuePress = () => {
    const isValidInput = validateInput();
    if (isValidInput) {
      onContinuePress(password);
    }
  };

  const personalInfo = useUniqueID('personalInfo');

  return (
    <Container>
      <HeaderWrapper>
        <ExtraHuge>Password required</ExtraHuge>
      </HeaderWrapper>
      <Disclaimer>
        <Large id={personalInfo} variant="largeDarkGrey">
          To protect your personal information, please verify your password.
        </Large>
      </Disclaimer>
      <InputWrapper>
        <Password
          hideLabel
          {...passwordProps}
          onChangeText={(...params) => {
            onChangeText?.(...params);
            clearError();
          }}
          placeholder="Enter current password"
          onSubmitEditing={handleContinuePress}
          dataQa="myAccountPasswordRequiredPasswordInput"
        />
      </InputWrapper>
      <SpacingView>
        <Button
          onPress={handleContinuePress}
          dataQa="myAccountPasswordRequiredSaveButton"
          text="Continue"
          style={{ flex: 1 }}
        />
        <Button
          variant="secondary"
          onPress={onCancelPress}
          dataQa="myAccountPasswordRequiredCancelButton"
          text="Cancel"
          style={{ flex: 1 }}
        />
      </SpacingView>
    </Container>
  );
};

export default PasswordRequiredModal;
