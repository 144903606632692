import { Defs, Ellipse, G, Path, Rect, Stop, Svg } from 'svgs';

import { EmojiSize } from '../../types';

const RaisingHandsEmoji = ({ size }: { size: EmojiSize }) => {
  const title = 'raising hands emoji';
  return (
    <Svg
      title={title}
      aria-label={title}
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
    >
      <G clipPath="url(#clip0_4451_12834)">
        <Path
          d="M30.0743 10.2437C28.8922 10.2894 28.1936 11.2572 28.1993 12.4312C27.7535 11.8829 27.0807 11.4221 26.3243 11.4937C25.3966 11.5816 24.3354 12.4785 24.4493 13.6812L25.0743 19.3062C24.4493 17.4312 23.1993 17.4312 22.5743 17.4312C21.3243 17.4312 19.9671 19.2476 21.2171 21.1226C21.2171 21.1226 24.0254 25.1105 25.0743 29.3062C25.6816 31.7355 28.1993 33.6812 30.6993 33.6812C34.3636 33.6812 36.9493 30.5562 37.5743 27.4312L38.8243 18.0562C38.9474 17.028 38.3649 15.894 36.9161 15.7205C36.3807 15.6564 36.0823 15.8601 35.6993 16.1812V14.3062C35.7508 13.272 34.8263 12.5568 33.792 12.5053C33.1581 12.4737 32.582 12.7608 32.2192 13.226V12.4312C32.1726 11.224 31.2815 10.1971 30.0743 10.2437Z"
          fill="url(#paint0_radial_4451_12834)"
        />
        <Path
          d="M30.0743 10.2437C28.8922 10.2894 28.1936 11.2572 28.1993 12.4312C27.7535 11.8829 27.0807 11.4221 26.3243 11.4937C25.3966 11.5816 24.3354 12.4785 24.4493 13.6812L25.0743 19.3062C24.4493 17.4312 23.1993 17.4312 22.5743 17.4312C21.3243 17.4312 19.9671 19.2476 21.2171 21.1226C21.2171 21.1226 24.0254 25.1105 25.0743 29.3062C25.6816 31.7355 28.1993 33.6812 30.6993 33.6812C34.3636 33.6812 36.9493 30.5562 37.5743 27.4312L38.8243 18.0562C38.9474 17.028 38.3649 15.894 36.9161 15.7205C36.3807 15.6564 36.0823 15.8601 35.6993 16.1812V14.3062C35.7508 13.272 34.8263 12.5568 33.792 12.5053C33.1581 12.4737 32.582 12.7608 32.2192 13.226V12.4312C32.1726 11.224 31.2815 10.1971 30.0743 10.2437Z"
          fill="url(#paint1_radial_4451_12834)"
        />
        <Path
          d="M30.0743 10.2437C28.8922 10.2894 28.1936 11.2572 28.1993 12.4312C27.7535 11.8829 27.0807 11.4221 26.3243 11.4937C25.3966 11.5816 24.3354 12.4785 24.4493 13.6812L25.0743 19.3062C24.4493 17.4312 23.1993 17.4312 22.5743 17.4312C21.3243 17.4312 19.9671 19.2476 21.2171 21.1226C21.2171 21.1226 24.0254 25.1105 25.0743 29.3062C25.6816 31.7355 28.1993 33.6812 30.6993 33.6812C34.3636 33.6812 36.9493 30.5562 37.5743 27.4312L38.8243 18.0562C38.9474 17.028 38.3649 15.894 36.9161 15.7205C36.3807 15.6564 36.0823 15.8601 35.6993 16.1812V14.3062C35.7508 13.272 34.8263 12.5568 33.792 12.5053C33.1581 12.4737 32.582 12.7608 32.2192 13.226V12.4312C32.1726 11.224 31.2815 10.1971 30.0743 10.2437Z"
          fill="url(#paint2_radial_4451_12834)"
        />
        <Path
          d="M30.0743 10.2437C28.8922 10.2894 28.1936 11.2572 28.1993 12.4312C27.7535 11.8829 27.0807 11.4221 26.3243 11.4937C25.3966 11.5816 24.3354 12.4785 24.4493 13.6812L25.0743 19.3062C24.4493 17.4312 23.1993 17.4312 22.5743 17.4312C21.3243 17.4312 19.9671 19.2476 21.2171 21.1226C21.2171 21.1226 24.0254 25.1105 25.0743 29.3062C25.6816 31.7355 28.1993 33.6812 30.6993 33.6812C34.3636 33.6812 36.9493 30.5562 37.5743 27.4312L38.8243 18.0562C38.9474 17.028 38.3649 15.894 36.9161 15.7205C36.3807 15.6564 36.0823 15.8601 35.6993 16.1812V14.3062C35.7508 13.272 34.8263 12.5568 33.792 12.5053C33.1581 12.4737 32.582 12.7608 32.2192 13.226V12.4312C32.1726 11.224 31.2815 10.1971 30.0743 10.2437Z"
          fill="url(#paint3_radial_4451_12834)"
        />
        <Path
          d="M30.0743 10.2437C28.8922 10.2894 28.1936 11.2572 28.1993 12.4312C27.7535 11.8829 27.0807 11.4221 26.3243 11.4937C25.3966 11.5816 24.3354 12.4785 24.4493 13.6812L25.0743 19.3062C24.4493 17.4312 23.1993 17.4312 22.5743 17.4312C21.3243 17.4312 19.9671 19.2476 21.2171 21.1226C21.2171 21.1226 24.0254 25.1105 25.0743 29.3062C25.6816 31.7355 28.1993 33.6812 30.6993 33.6812C34.3636 33.6812 36.9493 30.5562 37.5743 27.4312L38.8243 18.0562C38.9474 17.028 38.3649 15.894 36.9161 15.7205C36.3807 15.6564 36.0823 15.8601 35.6993 16.1812V14.3062C35.7508 13.272 34.8263 12.5568 33.792 12.5053C33.1581 12.4737 32.582 12.7608 32.2192 13.226V12.4312C32.1726 11.224 31.2815 10.1971 30.0743 10.2437Z"
          fill="url(#paint4_radial_4451_12834)"
        />
        <Path
          d="M22.5743 17.4297C23.2036 17.4297 24.4493 17.4297 25.0743 19.3047C25.2374 19.7939 25.377 20.164 25.4845 20.6328C26.1681 24.5 25.578 26.153 24.5254 27.5163C23.2748 24.0432 21.2171 21.1211 21.2171 21.1211C19.9671 19.2461 21.3243 17.4297 22.5743 17.4297Z"
          fill="url(#paint5_radial_4451_12834)"
        />
        <Path
          d="M22.5743 17.4297C23.2036 17.4297 24.4493 17.4297 25.0743 19.3047C25.2374 19.7939 25.377 20.164 25.4845 20.6328C26.1681 24.5 25.578 26.153 24.5254 27.5163C23.2748 24.0432 21.2171 21.1211 21.2171 21.1211C19.9671 19.2461 21.3243 17.4297 22.5743 17.4297Z"
          fill="url(#paint6_radial_4451_12834)"
        />
        <Path
          d="M22.5743 17.4297C23.2036 17.4297 24.4493 17.4297 25.0743 19.3047C25.2374 19.7939 25.377 20.164 25.4845 20.6328C26.1681 24.5 25.578 26.153 24.5254 27.5163C23.2748 24.0432 21.2171 21.1211 21.2171 21.1211C19.9671 19.2461 21.3243 17.4297 22.5743 17.4297Z"
          fill="url(#paint7_linear_4451_12834)"
        />
        <Path
          d="M22.5743 17.4297C23.2036 17.4297 24.4493 17.4297 25.0743 19.3047C25.2374 19.7939 25.377 20.164 25.4845 20.6328C26.1681 24.5 25.578 26.153 24.5254 27.5163C23.2748 24.0432 21.2171 21.1211 21.2171 21.1211C19.9671 19.2461 21.3243 17.4297 22.5743 17.4297Z"
          fill="url(#paint8_radial_4451_12834)"
        />
        <Path
          d="M22.5743 17.4297C23.2036 17.4297 24.4493 17.4297 25.0743 19.3047C25.2374 19.7939 25.377 20.164 25.4845 20.6328C26.1681 24.5 25.578 26.153 24.5254 27.5163C23.2748 24.0432 21.2171 21.1211 21.2171 21.1211C19.9671 19.2461 21.3243 17.4297 22.5743 17.4297Z"
          fill="url(#paint9_radial_4451_12834)"
        />
        <Path
          d="M22.5743 17.4297C23.2036 17.4297 24.4493 17.4297 25.0743 19.3047C25.2374 19.7939 25.377 20.164 25.4845 20.6328C26.1681 24.5 25.578 26.153 24.5254 27.5163C23.2748 24.0432 21.2171 21.1211 21.2171 21.1211C19.9671 19.2461 21.3243 17.4297 22.5743 17.4297Z"
          fill="url(#paint10_radial_4451_12834)"
        />
        <Path
          d="M22.5743 17.4297C23.2036 17.4297 24.4493 17.4297 25.0743 19.3047C25.2374 19.7939 25.377 20.164 25.4845 20.6328C26.1681 24.5 25.578 26.153 24.5254 27.5163C23.2748 24.0432 21.2171 21.1211 21.2171 21.1211C19.9671 19.2461 21.3243 17.4297 22.5743 17.4297Z"
          fill="url(#paint11_radial_4451_12834)"
        />
        <Path
          d="M22.5743 17.4297C23.2036 17.4297 24.4493 17.4297 25.0743 19.3047C25.2374 19.7939 25.377 20.164 25.4845 20.6328C26.1681 24.5 25.578 26.153 24.5254 27.5163C23.2748 24.0432 21.2171 21.1211 21.2171 21.1211C19.9671 19.2461 21.3243 17.4297 22.5743 17.4297Z"
          fill="url(#paint12_radial_4451_12834)"
        />
        <Path
          d="M28.1998 12.4332C27.754 11.8848 27.0812 11.424 26.3248 11.4957C25.3971 11.5835 24.3359 12.4804 24.4498 13.6832L25.0516 18.9468C25.2664 21.0366 27.6041 20.7339 28.6491 18.9468C28.2877 17.2281 28.2584 15.4214 28.1998 12.4332Z"
          fill="url(#paint13_radial_4451_12834)"
        />
        <Path
          d="M28.1998 12.4332C27.754 11.8848 27.0812 11.424 26.3248 11.4957C25.3971 11.5835 24.3359 12.4804 24.4498 13.6832L25.0516 18.9468C25.2664 21.0366 27.6041 20.7339 28.6491 18.9468C28.2877 17.2281 28.2584 15.4214 28.1998 12.4332Z"
          fill="url(#paint14_linear_4451_12834)"
        />
        <Path
          d="M28.1998 12.4332C27.754 11.8848 27.0812 11.424 26.3248 11.4957C25.3971 11.5835 24.3359 12.4804 24.4498 13.6832L25.0516 18.9468C25.2664 21.0366 27.6041 20.7339 28.6491 18.9468C28.2877 17.2281 28.2584 15.4214 28.1998 12.4332Z"
          fill="url(#paint15_radial_4451_12834)"
        />
        <Path
          d="M28.1998 12.4332C27.754 11.8848 27.0812 11.424 26.3248 11.4957C25.3971 11.5835 24.3359 12.4804 24.4498 13.6832L25.0516 18.9468C25.2664 21.0366 27.6041 20.7339 28.6491 18.9468C28.2877 17.2281 28.2584 15.4214 28.1998 12.4332Z"
          fill="url(#paint16_radial_4451_12834)"
        />
        <Ellipse
          cx="1.04999"
          cy="1.46364"
          rx="1.04999"
          ry="1.46364"
          transform="matrix(-0.926391 0.376563 0.376563 0.926391 27.207 11.4883)"
          fill="url(#paint17_radial_4451_12834)"
          fillOpacity="0.6"
        />
        <Path
          d="M30.0733 10.2437C28.8912 10.2894 28.1926 11.2572 28.1983 12.4312C28.2124 15.3222 28.335 17.607 28.6475 18.9547C29.4483 20.3804 31.0303 20.1461 31.8409 19.4332C31.6651 18.5054 31.5287 16.6858 31.709 15.063C31.8213 14.0523 31.9971 13.5836 32.2182 13.226V12.4312C32.1716 11.224 31.2805 10.1971 30.0733 10.2437Z"
          fill="url(#paint18_radial_4451_12834)"
        />
        <Path
          d="M30.0733 10.2437C28.8912 10.2894 28.1926 11.2572 28.1983 12.4312C28.2124 15.3222 28.335 17.607 28.6475 18.9547C29.4483 20.3804 31.0303 20.1461 31.8409 19.4332C31.6651 18.5054 31.5287 16.6858 31.709 15.063C31.8213 14.0523 31.9971 13.5836 32.2182 13.226V12.4312C32.1716 11.224 31.2805 10.1971 30.0733 10.2437Z"
          fill="url(#paint19_linear_4451_12834)"
        />
        <Path
          d="M30.0733 10.2437C28.8912 10.2894 28.1926 11.2572 28.1983 12.4312C28.2124 15.3222 28.335 17.607 28.6475 18.9547C29.4483 20.3804 31.0303 20.1461 31.8409 19.4332C31.6651 18.5054 31.5287 16.6858 31.709 15.063C31.8213 14.0523 31.9971 13.5836 32.2182 13.226V12.4312C32.1716 11.224 31.2805 10.1971 30.0733 10.2437Z"
          fill="url(#paint20_radial_4451_12834)"
        />
        <Path
          d="M30.0733 10.2437C28.8912 10.2894 28.1926 11.2572 28.1983 12.4312C28.2124 15.3222 28.335 17.607 28.6475 18.9547C29.4483 20.3804 31.0303 20.1461 31.8409 19.4332C31.6651 18.5054 31.5287 16.6858 31.709 15.063C31.8213 14.0523 31.9971 13.5836 32.2182 13.226V12.4312C32.1716 11.224 31.2805 10.1971 30.0733 10.2437Z"
          fill="url(#paint21_radial_4451_12834)"
        />
        <Path
          d="M30.0733 10.2437C28.8912 10.2894 28.1926 11.2572 28.1983 12.4312C28.2124 15.3222 28.335 17.607 28.6475 18.9547C29.4483 20.3804 31.0303 20.1461 31.8409 19.4332C31.6651 18.5054 31.5287 16.6858 31.709 15.063C31.8213 14.0523 31.9971 13.5836 32.2182 13.226V12.4312C32.1716 11.224 31.2805 10.1971 30.0733 10.2437Z"
          fill="url(#paint22_radial_4451_12834)"
        />
        <Path
          d="M35.6981 16.1822V14.3072C35.7496 13.273 34.8251 12.5578 33.7908 12.5063C33.1569 12.4747 32.5808 12.7618 32.218 13.2269C31.9082 13.6241 31.7253 14.616 31.6649 15.6451C31.284 22.1295 33.6055 22.0638 34.4676 21.2017C34.536 20.5377 34.7672 18.3405 35.0145 17.6568C35.1903 16.8755 35.3954 16.4947 35.6981 16.1822Z"
          fill="url(#paint23_radial_4451_12834)"
        />
        <Path
          d="M35.6981 16.1822V14.3072C35.7496 13.273 34.8251 12.5578 33.7908 12.5063C33.1569 12.4747 32.5808 12.7618 32.218 13.2269C31.9082 13.6241 31.7253 14.616 31.6649 15.6451C31.284 22.1295 33.6055 22.0638 34.4676 21.2017C34.536 20.5377 34.7672 18.3405 35.0145 17.6568C35.1903 16.8755 35.3954 16.4947 35.6981 16.1822Z"
          fill="url(#paint24_linear_4451_12834)"
        />
        <Path
          d="M35.6981 16.1822V14.3072C35.7496 13.273 34.8251 12.5578 33.7908 12.5063C33.1569 12.4747 32.5808 12.7618 32.218 13.2269C31.9082 13.6241 31.7253 14.616 31.6649 15.6451C31.284 22.1295 33.6055 22.0638 34.4676 21.2017C34.536 20.5377 34.7672 18.3405 35.0145 17.6568C35.1903 16.8755 35.3954 16.4947 35.6981 16.1822Z"
          fill="url(#paint25_radial_4451_12834)"
        />
        <Path
          d="M35.6981 16.1822V14.3072C35.7496 13.273 34.8251 12.5578 33.7908 12.5063C33.1569 12.4747 32.5808 12.7618 32.218 13.2269C31.9082 13.6241 31.7253 14.616 31.6649 15.6451C31.284 22.1295 33.6055 22.0638 34.4676 21.2017C34.536 20.5377 34.7672 18.3405 35.0145 17.6568C35.1903 16.8755 35.3954 16.4947 35.6981 16.1822Z"
          fill="url(#paint26_radial_4451_12834)"
        />
        <Path
          d="M35.6981 16.1822V14.3072C35.7496 13.273 34.8251 12.5578 33.7908 12.5063C33.1569 12.4747 32.5808 12.7618 32.218 13.2269C31.9082 13.6241 31.7253 14.616 31.6649 15.6451C31.284 22.1295 33.6055 22.0638 34.4676 21.2017C34.536 20.5377 34.7672 18.3405 35.0145 17.6568C35.1903 16.8755 35.3954 16.4947 35.6981 16.1822Z"
          fill="url(#paint27_radial_4451_12834)"
        />
        <Path
          d="M38.8233 18.0596C38.9464 17.0314 38.4703 15.888 37.0215 15.7145C36.4861 15.6504 36.0812 15.8634 35.6983 16.1846C34.9034 16.8512 34.585 19.8174 34.4678 21.4775C34.9399 23.3354 35.9717 24.8565 37.8295 25.513L38.8233 18.0596Z"
          fill="url(#paint28_radial_4451_12834)"
        />
        <Path
          d="M38.8233 18.0596C38.9464 17.0314 38.4703 15.888 37.0215 15.7145C36.4861 15.6504 36.0812 15.8634 35.6983 16.1846C34.9034 16.8512 34.585 19.8174 34.4678 21.4775C34.9399 23.3354 35.9717 24.8565 37.8295 25.513L38.8233 18.0596Z"
          fill="url(#paint29_linear_4451_12834)"
        />
        <Path
          d="M38.8233 18.0596C38.9464 17.0314 38.4703 15.888 37.0215 15.7145C36.4861 15.6504 36.0812 15.8634 35.6983 16.1846C34.9034 16.8512 34.585 19.8174 34.4678 21.4775C34.9399 23.3354 35.9717 24.8565 37.8295 25.513L38.8233 18.0596Z"
          fill="url(#paint30_radial_4451_12834)"
        />
        <Path
          d="M38.8233 18.0596C38.9464 17.0314 38.4703 15.888 37.0215 15.7145C36.4861 15.6504 36.0812 15.8634 35.6983 16.1846C34.9034 16.8512 34.585 19.8174 34.4678 21.4775C34.9399 23.3354 35.9717 24.8565 37.8295 25.513L38.8233 18.0596Z"
          fill="url(#paint31_radial_4451_12834)"
        />
        <Path
          d="M38.8233 18.0596C38.9464 17.0314 38.4703 15.888 37.0215 15.7145C36.4861 15.6504 36.0812 15.8634 35.6983 16.1846C34.9034 16.8512 34.585 19.8174 34.4678 21.4775C34.9399 23.3354 35.9717 24.8565 37.8295 25.513L38.8233 18.0596Z"
          fill="url(#paint32_radial_4451_12834)"
        />
        <Path
          d="M38.8233 18.0596C38.9464 17.0314 38.4703 15.888 37.0215 15.7145C36.4861 15.6504 36.0812 15.8634 35.6983 16.1846C34.9034 16.8512 34.585 19.8174 34.4678 21.4775C34.9399 23.3354 35.9717 24.8565 37.8295 25.513L38.8233 18.0596Z"
          fill="url(#paint33_radial_4451_12834)"
        />
        <Path
          d="M9.9257 10.2437C11.1078 10.2894 11.8064 11.2572 11.8007 12.4312C12.2465 11.8829 12.9193 11.4221 13.6757 11.4937C14.6034 11.5816 15.6646 12.4785 15.5507 13.6812L14.9257 19.3062C15.5507 17.4312 16.8007 17.4312 17.4257 17.4312C18.6757 17.4312 20.0329 19.2476 18.7829 21.1226C18.7829 21.1226 15.9746 25.1105 14.9257 29.3062C14.3184 31.7355 11.8007 33.6812 9.30069 33.6812C5.63641 33.6812 3.05069 30.5562 2.42569 27.4312L1.17569 18.0562C1.05259 17.028 1.63508 15.894 3.08388 15.7205C3.61929 15.6564 3.91775 15.8601 4.30069 16.1812V14.3062C4.2492 13.272 5.17372 12.5568 6.20798 12.5053C6.84191 12.4737 7.41795 12.7608 7.78077 13.226V12.4312C7.82739 11.224 8.71848 10.1971 9.9257 10.2437Z"
          fill="url(#paint34_radial_4451_12834)"
        />
        <Path
          d="M9.9257 10.2437C11.1078 10.2894 11.8064 11.2572 11.8007 12.4312C12.2465 11.8829 12.9193 11.4221 13.6757 11.4937C14.6034 11.5816 15.6646 12.4785 15.5507 13.6812L14.9257 19.3062C15.5507 17.4312 16.8007 17.4312 17.4257 17.4312C18.6757 17.4312 20.0329 19.2476 18.7829 21.1226C18.7829 21.1226 15.9746 25.1105 14.9257 29.3062C14.3184 31.7355 11.8007 33.6812 9.30069 33.6812C5.63641 33.6812 3.05069 30.5562 2.42569 27.4312L1.17569 18.0562C1.05259 17.028 1.63508 15.894 3.08388 15.7205C3.61929 15.6564 3.91775 15.8601 4.30069 16.1812V14.3062C4.2492 13.272 5.17372 12.5568 6.20798 12.5053C6.84191 12.4737 7.41795 12.7608 7.78077 13.226V12.4312C7.82739 11.224 8.71848 10.1971 9.9257 10.2437Z"
          fill="url(#paint35_radial_4451_12834)"
        />
        <Path
          d="M9.9257 10.2437C11.1078 10.2894 11.8064 11.2572 11.8007 12.4312C12.2465 11.8829 12.9193 11.4221 13.6757 11.4937C14.6034 11.5816 15.6646 12.4785 15.5507 13.6812L14.9257 19.3062C15.5507 17.4312 16.8007 17.4312 17.4257 17.4312C18.6757 17.4312 20.0329 19.2476 18.7829 21.1226C18.7829 21.1226 15.9746 25.1105 14.9257 29.3062C14.3184 31.7355 11.8007 33.6812 9.30069 33.6812C5.63641 33.6812 3.05069 30.5562 2.42569 27.4312L1.17569 18.0562C1.05259 17.028 1.63508 15.894 3.08388 15.7205C3.61929 15.6564 3.91775 15.8601 4.30069 16.1812V14.3062C4.2492 13.272 5.17372 12.5568 6.20798 12.5053C6.84191 12.4737 7.41795 12.7608 7.78077 13.226V12.4312C7.82739 11.224 8.71848 10.1971 9.9257 10.2437Z"
          fill="url(#paint36_radial_4451_12834)"
        />
        <Path
          d="M9.9257 10.2437C11.1078 10.2894 11.8064 11.2572 11.8007 12.4312C12.2465 11.8829 12.9193 11.4221 13.6757 11.4937C14.6034 11.5816 15.6646 12.4785 15.5507 13.6812L14.9257 19.3062C15.5507 17.4312 16.8007 17.4312 17.4257 17.4312C18.6757 17.4312 20.0329 19.2476 18.7829 21.1226C18.7829 21.1226 15.9746 25.1105 14.9257 29.3062C14.3184 31.7355 11.8007 33.6812 9.30069 33.6812C5.63641 33.6812 3.05069 30.5562 2.42569 27.4312L1.17569 18.0562C1.05259 17.028 1.63508 15.894 3.08388 15.7205C3.61929 15.6564 3.91775 15.8601 4.30069 16.1812V14.3062C4.2492 13.272 5.17372 12.5568 6.20798 12.5053C6.84191 12.4737 7.41795 12.7608 7.78077 13.226V12.4312C7.82739 11.224 8.71848 10.1971 9.9257 10.2437Z"
          fill="url(#paint37_radial_4451_12834)"
        />
        <Path
          d="M9.9257 10.2437C11.1078 10.2894 11.8064 11.2572 11.8007 12.4312C12.2465 11.8829 12.9193 11.4221 13.6757 11.4937C14.6034 11.5816 15.6646 12.4785 15.5507 13.6812L14.9257 19.3062C15.5507 17.4312 16.8007 17.4312 17.4257 17.4312C18.6757 17.4312 20.0329 19.2476 18.7829 21.1226C18.7829 21.1226 15.9746 25.1105 14.9257 29.3062C14.3184 31.7355 11.8007 33.6812 9.30069 33.6812C5.63641 33.6812 3.05069 30.5562 2.42569 27.4312L1.17569 18.0562C1.05259 17.028 1.63508 15.894 3.08388 15.7205C3.61929 15.6564 3.91775 15.8601 4.30069 16.1812V14.3062C4.2492 13.272 5.17372 12.5568 6.20798 12.5053C6.84191 12.4737 7.41795 12.7608 7.78077 13.226V12.4312C7.82739 11.224 8.71848 10.1971 9.9257 10.2437Z"
          fill="url(#paint38_radial_4451_12834)"
        />
        <Path
          d="M17.4257 17.4297C16.8007 17.4297 15.5507 17.4297 14.9257 19.3047C14.7626 19.7939 14.623 20.164 14.5155 20.6328C13.8319 24.5 14.422 26.153 15.4746 27.5163C16.7252 24.0432 18.7829 21.1211 18.7829 21.1211C20.0329 19.2461 18.6757 17.4297 17.4257 17.4297Z"
          fill="url(#paint39_radial_4451_12834)"
        />
        <Path
          d="M17.4257 17.4297C16.8007 17.4297 15.5507 17.4297 14.9257 19.3047C14.7626 19.7939 14.623 20.164 14.5155 20.6328C13.8319 24.5 14.422 26.153 15.4746 27.5163C16.7252 24.0432 18.7829 21.1211 18.7829 21.1211C20.0329 19.2461 18.6757 17.4297 17.4257 17.4297Z"
          fill="url(#paint40_radial_4451_12834)"
        />
        <Path
          d="M17.4257 17.4297C16.8007 17.4297 15.5507 17.4297 14.9257 19.3047C14.7626 19.7939 14.623 20.164 14.5155 20.6328C13.8319 24.5 14.422 26.153 15.4746 27.5163C16.7252 24.0432 18.7829 21.1211 18.7829 21.1211C20.0329 19.2461 18.6757 17.4297 17.4257 17.4297Z"
          fill="url(#paint41_linear_4451_12834)"
        />
        <Path
          d="M17.4257 17.4297C16.8007 17.4297 15.5507 17.4297 14.9257 19.3047C14.7626 19.7939 14.623 20.164 14.5155 20.6328C13.8319 24.5 14.422 26.153 15.4746 27.5163C16.7252 24.0432 18.7829 21.1211 18.7829 21.1211C20.0329 19.2461 18.6757 17.4297 17.4257 17.4297Z"
          fill="url(#paint42_radial_4451_12834)"
        />
        <Path
          d="M17.4257 17.4297C16.8007 17.4297 15.5507 17.4297 14.9257 19.3047C14.7626 19.7939 14.623 20.164 14.5155 20.6328C13.8319 24.5 14.422 26.153 15.4746 27.5163C16.7252 24.0432 18.7829 21.1211 18.7829 21.1211C20.0329 19.2461 18.6757 17.4297 17.4257 17.4297Z"
          fill="url(#paint43_radial_4451_12834)"
        />
        <Path
          d="M17.4257 17.4297C16.8007 17.4297 15.5507 17.4297 14.9257 19.3047C14.7626 19.7939 14.623 20.164 14.5155 20.6328C13.8319 24.5 14.422 26.153 15.4746 27.5163C16.7252 24.0432 18.7829 21.1211 18.7829 21.1211C20.0329 19.2461 18.6757 17.4297 17.4257 17.4297Z"
          fill="url(#paint44_radial_4451_12834)"
        />
        <Path
          d="M17.4257 17.4297C16.8007 17.4297 15.5507 17.4297 14.9257 19.3047C14.7626 19.7939 14.623 20.164 14.5155 20.6328C13.8319 24.5 14.422 26.153 15.4746 27.5163C16.7252 24.0432 18.7829 21.1211 18.7829 21.1211C20.0329 19.2461 18.6757 17.4297 17.4257 17.4297Z"
          fill="url(#paint45_radial_4451_12834)"
        />
        <Path
          d="M17.4257 17.4297C16.8007 17.4297 15.5507 17.4297 14.9257 19.3047C14.7626 19.7939 14.623 20.164 14.5155 20.6328C13.8319 24.5 14.422 26.153 15.4746 27.5163C16.7252 24.0432 18.7829 21.1211 18.7829 21.1211C20.0329 19.2461 18.6757 17.4297 17.4257 17.4297Z"
          fill="url(#paint46_radial_4451_12834)"
        />
        <Path
          d="M11.8002 12.4332C12.246 11.8848 12.9188 11.424 13.6752 11.4957C14.6029 11.5835 15.6641 12.4804 15.5502 13.6832L14.9484 18.9468C14.7336 21.0366 12.3959 20.7339 11.3509 18.9468C11.7123 17.2281 11.7416 15.4214 11.8002 12.4332Z"
          fill="url(#paint47_radial_4451_12834)"
        />
        <Path
          d="M11.8002 12.4332C12.246 11.8848 12.9188 11.424 13.6752 11.4957C14.6029 11.5835 15.6641 12.4804 15.5502 13.6832L14.9484 18.9468C14.7336 21.0366 12.3959 20.7339 11.3509 18.9468C11.7123 17.2281 11.7416 15.4214 11.8002 12.4332Z"
          fill="url(#paint48_linear_4451_12834)"
        />
        <Path
          d="M11.8002 12.4332C12.246 11.8848 12.9188 11.424 13.6752 11.4957C14.6029 11.5835 15.6641 12.4804 15.5502 13.6832L14.9484 18.9468C14.7336 21.0366 12.3959 20.7339 11.3509 18.9468C11.7123 17.2281 11.7416 15.4214 11.8002 12.4332Z"
          fill="url(#paint49_radial_4451_12834)"
        />
        <Path
          d="M11.8002 12.4332C12.246 11.8848 12.9188 11.424 13.6752 11.4957C14.6029 11.5835 15.6641 12.4804 15.5502 13.6832L14.9484 18.9468C14.7336 21.0366 12.3959 20.7339 11.3509 18.9468C11.7123 17.2281 11.7416 15.4214 11.8002 12.4332Z"
          fill="url(#paint50_radial_4451_12834)"
        />
        <Path
          d="M9.92672 10.2437C11.1088 10.2894 11.8074 11.2572 11.8017 12.4312C11.7876 15.3222 11.665 17.607 11.3525 18.9547C10.5517 20.3804 8.96969 20.1461 8.15914 19.4332C8.33492 18.5054 8.47129 16.6858 8.29098 15.063C8.17867 14.0523 8.00289 13.5836 7.7818 13.226V12.4312C7.82841 11.224 8.7195 10.1971 9.92672 10.2437Z"
          fill="url(#paint51_radial_4451_12834)"
        />
        <Path
          d="M9.92672 10.2437C11.1088 10.2894 11.8074 11.2572 11.8017 12.4312C11.7876 15.3222 11.665 17.607 11.3525 18.9547C10.5517 20.3804 8.96969 20.1461 8.15914 19.4332C8.33492 18.5054 8.47129 16.6858 8.29098 15.063C8.17867 14.0523 8.00289 13.5836 7.7818 13.226V12.4312C7.82841 11.224 8.7195 10.1971 9.92672 10.2437Z"
          fill="url(#paint52_linear_4451_12834)"
        />
        <Path
          d="M9.92672 10.2437C11.1088 10.2894 11.8074 11.2572 11.8017 12.4312C11.7876 15.3222 11.665 17.607 11.3525 18.9547C10.5517 20.3804 8.96969 20.1461 8.15914 19.4332C8.33492 18.5054 8.47129 16.6858 8.29098 15.063C8.17867 14.0523 8.00289 13.5836 7.7818 13.226V12.4312C7.82841 11.224 8.7195 10.1971 9.92672 10.2437Z"
          fill="url(#paint53_radial_4451_12834)"
        />
        <Path
          d="M9.92672 10.2437C11.1088 10.2894 11.8074 11.2572 11.8017 12.4312C11.7876 15.3222 11.665 17.607 11.3525 18.9547C10.5517 20.3804 8.96969 20.1461 8.15914 19.4332C8.33492 18.5054 8.47129 16.6858 8.29098 15.063C8.17867 14.0523 8.00289 13.5836 7.7818 13.226V12.4312C7.82841 11.224 8.7195 10.1971 9.92672 10.2437Z"
          fill="url(#paint54_radial_4451_12834)"
        />
        <Path
          d="M9.92672 10.2437C11.1088 10.2894 11.8074 11.2572 11.8017 12.4312C11.7876 15.3222 11.665 17.607 11.3525 18.9547C10.5517 20.3804 8.96969 20.1461 8.15914 19.4332C8.33492 18.5054 8.47129 16.6858 8.29098 15.063C8.17867 14.0523 8.00289 13.5836 7.7818 13.226V12.4312C7.82841 11.224 8.7195 10.1971 9.92672 10.2437Z"
          fill="url(#paint55_radial_4451_12834)"
        />
        <Path
          d="M4.3019 16.1822V14.3072C4.2504 13.273 5.17493 12.5578 6.20919 12.5063C6.84312 12.4747 7.41917 12.7618 7.78198 13.2269C8.0918 13.6241 8.27467 14.616 8.33511 15.6451C8.71595 22.1295 6.39448 22.0638 5.53238 21.2017C5.46402 20.5377 5.23283 18.3405 4.98549 17.6568C4.80971 16.8755 4.60464 16.4947 4.3019 16.1822Z"
          fill="url(#paint56_radial_4451_12834)"
        />
        <Path
          d="M4.3019 16.1822V14.3072C4.2504 13.273 5.17493 12.5578 6.20919 12.5063C6.84312 12.4747 7.41917 12.7618 7.78198 13.2269C8.0918 13.6241 8.27467 14.616 8.33511 15.6451C8.71595 22.1295 6.39448 22.0638 5.53238 21.2017C5.46402 20.5377 5.23283 18.3405 4.98549 17.6568C4.80971 16.8755 4.60464 16.4947 4.3019 16.1822Z"
          fill="url(#paint57_linear_4451_12834)"
        />
        <Path
          d="M4.3019 16.1822V14.3072C4.2504 13.273 5.17493 12.5578 6.20919 12.5063C6.84312 12.4747 7.41917 12.7618 7.78198 13.2269C8.0918 13.6241 8.27467 14.616 8.33511 15.6451C8.71595 22.1295 6.39448 22.0638 5.53238 21.2017C5.46402 20.5377 5.23283 18.3405 4.98549 17.6568C4.80971 16.8755 4.60464 16.4947 4.3019 16.1822Z"
          fill="url(#paint58_radial_4451_12834)"
        />
        <Path
          d="M4.3019 16.1822V14.3072C4.2504 13.273 5.17493 12.5578 6.20919 12.5063C6.84312 12.4747 7.41917 12.7618 7.78198 13.2269C8.0918 13.6241 8.27467 14.616 8.33511 15.6451C8.71595 22.1295 6.39448 22.0638 5.53238 21.2017C5.46402 20.5377 5.23283 18.3405 4.98549 17.6568C4.80971 16.8755 4.60464 16.4947 4.3019 16.1822Z"
          fill="url(#paint59_radial_4451_12834)"
        />
        <Path
          d="M4.3019 16.1822V14.3072C4.2504 13.273 5.17493 12.5578 6.20919 12.5063C6.84312 12.4747 7.41917 12.7618 7.78198 13.2269C8.0918 13.6241 8.27467 14.616 8.33511 15.6451C8.71595 22.1295 6.39448 22.0638 5.53238 21.2017C5.46402 20.5377 5.23283 18.3405 4.98549 17.6568C4.80971 16.8755 4.60464 16.4947 4.3019 16.1822Z"
          fill="url(#paint60_radial_4451_12834)"
        />
        <Path
          d="M1.17674 18.0565C1.05364 17.0283 1.51995 15.884 2.96875 15.7106C3.50416 15.6465 3.91879 15.8604 4.30174 16.1815C5.09661 16.8482 5.41504 19.8143 5.53223 21.4745C5.06006 23.3323 4.02832 24.8534 2.17053 25.51L1.17674 18.0565Z"
          fill="url(#paint61_radial_4451_12834)"
        />
        <Path
          d="M1.17674 18.0565C1.05364 17.0283 1.51995 15.884 2.96875 15.7106C3.50416 15.6465 3.91879 15.8604 4.30174 16.1815C5.09661 16.8482 5.41504 19.8143 5.53223 21.4745C5.06006 23.3323 4.02832 24.8534 2.17053 25.51L1.17674 18.0565Z"
          fill="url(#paint62_linear_4451_12834)"
        />
        <Path
          d="M1.17674 18.0565C1.05364 17.0283 1.51995 15.884 2.96875 15.7106C3.50416 15.6465 3.91879 15.8604 4.30174 16.1815C5.09661 16.8482 5.41504 19.8143 5.53223 21.4745C5.06006 23.3323 4.02832 24.8534 2.17053 25.51L1.17674 18.0565Z"
          fill="url(#paint63_radial_4451_12834)"
        />
        <Path
          d="M1.17674 18.0565C1.05364 17.0283 1.51995 15.884 2.96875 15.7106C3.50416 15.6465 3.91879 15.8604 4.30174 16.1815C5.09661 16.8482 5.41504 19.8143 5.53223 21.4745C5.06006 23.3323 4.02832 24.8534 2.17053 25.51L1.17674 18.0565Z"
          fill="url(#paint64_radial_4451_12834)"
        />
        <Path
          d="M1.17674 18.0565C1.05364 17.0283 1.51995 15.884 2.96875 15.7106C3.50416 15.6465 3.91879 15.8604 4.30174 16.1815C5.09661 16.8482 5.41504 19.8143 5.53223 21.4745C5.06006 23.3323 4.02832 24.8534 2.17053 25.51L1.17674 18.0565Z"
          fill="url(#paint65_radial_4451_12834)"
        />
        <Path
          d="M1.17674 18.0565C1.05364 17.0283 1.51995 15.884 2.96875 15.7106C3.50416 15.6465 3.91879 15.8604 4.30174 16.1815C5.09661 16.8482 5.41504 19.8143 5.53223 21.4745C5.06006 23.3323 4.02832 24.8534 2.17053 25.51L1.17674 18.0565Z"
          fill="url(#paint66_radial_4451_12834)"
        />
        <Path
          d="M19.5097 7.1532L18.6128 4.84265C18.4663 4.46517 18.7551 4.0625 19.1724 4.0625H20.9662C21.3835 4.0625 21.6723 4.46517 21.5258 4.84265L20.6289 7.1532C20.435 7.65275 19.7036 7.65275 19.5097 7.1532Z"
          fill="#1A76E3"
        />
        <Path
          d="M19.5097 7.1532L18.6128 4.84265C18.4663 4.46517 18.7551 4.0625 19.1724 4.0625H20.9662C21.3835 4.0625 21.6723 4.46517 21.5258 4.84265L20.6289 7.1532C20.435 7.65275 19.7036 7.65275 19.5097 7.1532Z"
          fill="url(#paint67_linear_4451_12834)"
        />
        <Path
          d="M19.5097 7.1532L18.6128 4.84265C18.4663 4.46517 18.7551 4.0625 19.1724 4.0625H20.9662C21.3835 4.0625 21.6723 4.46517 21.5258 4.84265L20.6289 7.1532C20.435 7.65275 19.7036 7.65275 19.5097 7.1532Z"
          fill="url(#paint68_linear_4451_12834)"
        />
        <Path
          d="M19.5097 7.1532L18.6128 4.84265C18.4663 4.46517 18.7551 4.0625 19.1724 4.0625H20.9662C21.3835 4.0625 21.6723 4.46517 21.5258 4.84265L20.6289 7.1532C20.435 7.65275 19.7036 7.65275 19.5097 7.1532Z"
          fill="url(#paint69_linear_4451_12834)"
        />
        <Path
          d="M24.1206 7.84413L24.9043 5.43224C25.033 5.03592 25.518 4.89022 25.8439 5.14994L27.2366 6.25993C27.5624 6.51966 27.5286 7.02491 27.171 7.23884L24.9946 8.54074C24.5214 8.82385 23.9502 8.36861 24.1206 7.84413Z"
          fill="#1A76E4"
        />
        <Path
          d="M24.1206 7.84413L24.9043 5.43224C25.033 5.03592 25.518 4.89022 25.8439 5.14994L27.2366 6.25993C27.5624 6.51966 27.5286 7.02491 27.171 7.23884L24.9946 8.54074C24.5214 8.82385 23.9502 8.36861 24.1206 7.84413Z"
          fill="url(#paint70_linear_4451_12834)"
        />
        <Path
          d="M24.1206 7.84413L24.9043 5.43224C25.033 5.03592 25.518 4.89022 25.8439 5.14994L27.2366 6.25993C27.5624 6.51966 27.5286 7.02491 27.171 7.23884L24.9946 8.54074C24.5214 8.82385 23.9502 8.36861 24.1206 7.84413Z"
          fill="url(#paint71_linear_4451_12834)"
        />
        <Path
          d="M24.1206 7.84413L24.9043 5.43224C25.033 5.03592 25.518 4.89022 25.8439 5.14994L27.2366 6.25993C27.5624 6.51966 27.5286 7.02491 27.171 7.23884L24.9946 8.54074C24.5214 8.82385 23.9502 8.36861 24.1206 7.84413Z"
          fill="url(#paint72_linear_4451_12834)"
        />
        <Path
          d="M16.0995 7.74901L14.9434 5.36391C14.7575 4.98028 14.2456 4.90046 13.9517 5.20926L12.6685 6.55749C12.3746 6.86629 12.4796 7.37359 12.8719 7.54037L15.3112 8.57724C15.8304 8.79795 16.3455 8.2567 16.0995 7.74901Z"
          fill="#1B76E3"
        />
        <Path
          d="M16.0995 7.74901L14.9434 5.36391C14.7575 4.98028 14.2456 4.90046 13.9517 5.20926L12.6685 6.55749C12.3746 6.86629 12.4796 7.37359 12.8719 7.54037L15.3112 8.57724C15.8304 8.79795 16.3455 8.2567 16.0995 7.74901Z"
          fill="url(#paint73_linear_4451_12834)"
        />
        <Path
          d="M16.0995 7.74901L14.9434 5.36391C14.7575 4.98028 14.2456 4.90046 13.9517 5.20926L12.6685 6.55749C12.3746 6.86629 12.4796 7.37359 12.8719 7.54037L15.3112 8.57724C15.8304 8.79795 16.3455 8.2567 16.0995 7.74901Z"
          fill="url(#paint74_linear_4451_12834)"
        />
        <Path
          d="M16.0995 7.74901L14.9434 5.36391C14.7575 4.98028 14.2456 4.90046 13.9517 5.20926L12.6685 6.55749C12.3746 6.86629 12.4796 7.37359 12.8719 7.54037L15.3112 8.57724C15.8304 8.79795 16.3455 8.2567 16.0995 7.74901Z"
          fill="url(#paint75_linear_4451_12834)"
        />
      </G>
      <Defs>
        <radialGradient
          id="paint0_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(30.6372 13.8375) rotate(84.5768) scale(24.7985 14.7329)"
        >
          <Stop stopColor="#FBB848" />
          <Stop offset="0.325134" stopColor="#F8BA29" />
          <Stop offset="0.663167" stopColor="#FFBF49" />
          <Stop offset="0.796815" stopColor="#FFA15B" />
          <Stop offset="0.855766" stopColor="#F98B73" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(34.2974 21.4434) rotate(80.5377) scale(2.37608 1.19858)"
        >
          <Stop stopColor="#F4AD1C" />
          <Stop offset="1" stopColor="#F4AD1C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.0474 16.5215) rotate(98.7077) scale(21.5768 15.741)"
        >
          <Stop offset="0.75395" stopColor="#F88485" stopOpacity="0" />
          <Stop offset="0.830609" stopColor="#F88485" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.3442 28.1621) rotate(66.1012) scale(14.2704 5.60037)"
        >
          <Stop stopColor="#E09437" />
          <Stop offset="1" stopColor="#E09437" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.563 25.8184) rotate(104.365) scale(6.61299 1.93083)"
        >
          <Stop stopColor="#FFCF5C" />
          <Stop offset="1" stopColor="#FFCD5A" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.2492 21.0234) rotate(62.6969) scale(25.0132 4.49187)"
        >
          <Stop stopColor="#F9C043" />
          <Stop offset="0.259984" stopColor="#FFBD3C" />
          <Stop offset="0.674402" stopColor="#F6A731" />
          <Stop offset="0.740906" stopColor="#E2924D" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.8479 24.6953) rotate(-28.6861) scale(2.35997 4.56556)"
        >
          <Stop stopColor="#F98B73" />
          <Stop offset="1" stopColor="#F98B73" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint7_linear_4451_12834"
          x1="25.7777"
          y1="19.7734"
          x2="24.1223"
          y2="20.4208"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D28A03" />
          <Stop offset="1" stopColor="#D28A03" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint8_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.7229 21.0625) rotate(154.231) scale(1.25791 4.50359)"
        >
          <Stop stopColor="#FFCD6C" />
          <Stop offset="1" stopColor="#FFC149" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.1724 23.0449) rotate(97.2302) scale(5.27633 2.22829)"
        >
          <Stop offset="0.52217" stopColor="#FDBD3D" />
          <Stop offset="1" stopColor="#FDBD3D" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint10_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.7192 25.7402) rotate(65.6646) scale(17.0629 4.79922)"
        >
          <Stop stopColor="#E09437" />
          <Stop offset="1" stopColor="#E09437" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint11_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.9692 19.9199) rotate(67.6199) scale(5.02709 1.15567)"
        >
          <Stop stopColor="#F9C145" />
          <Stop offset="1" stopColor="#F9C145" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint12_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.1099 26.7168) rotate(-121.766) scale(12.9106 5.66818)"
        >
          <Stop offset="0.742999" stopColor="#CA9A36" stopOpacity="0" />
          <Stop offset="0.835894" stopColor="#CA9A36" />
        </radialGradient>
        <radialGradient
          id="paint13_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.9536 15.9451) rotate(85.8355) scale(23.3466 5.42151)"
        >
          <Stop offset="0.112047" stopColor="#FEBD55" />
          <Stop offset="0.30021" stopColor="#EDAA29" />
          <Stop offset="0.482587" stopColor="#D99A32" />
        </radialGradient>
        <linearGradient
          id="paint14_linear_4451_12834"
          x1="27.2271"
          y1="20.4019"
          x2="26.8326"
          y2="18.2053"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.295306" stopColor="#F9BB2B" />
          <Stop offset="1" stopColor="#FDC03E" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint15_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.813 17.3843) rotate(75.7903) scale(9.38882 5.43406)"
        >
          <Stop offset="0.528116" stopColor="#C79A32" stopOpacity="0" />
          <Stop offset="0.725133" stopColor="#C79A32" />
        </radialGradient>
        <radialGradient
          id="paint16_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(29.0239 16.4858) rotate(81.4692) scale(9.47988 1.37666)"
        >
          <Stop offset="0.42665" stopColor="#C58417" />
          <Stop offset="1" stopColor="#EFA31E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint17_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0.979986 1.55779) rotate(62.683) scale(1.34871 1.1229)"
        >
          <Stop stopColor="#FECF68" />
          <Stop offset="1" stopColor="#F7C658" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint18_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.2109 12.9668) rotate(88.8196) scale(28.4435 6.88202)"
        >
          <Stop stopColor="#FFD235" />
          <Stop offset="0.221428" stopColor="#FFBD58" />
          <Stop offset="0.378238" stopColor="#F3AB28" />
        </radialGradient>
        <linearGradient
          id="paint19_linear_4451_12834"
          x1="30.2082"
          y1="20.2367"
          x2="29.9465"
          y2="17.0183"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#F9BB2A" />
          <Stop offset="1" stopColor="#FDC03E" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint20_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.2109 17.1074) rotate(76.7308) scale(7.08397 4.39592)"
        >
          <Stop offset="0.748434" stopColor="#CA9A37" stopOpacity="0" />
          <Stop offset="1" stopColor="#CA9A37" />
        </radialGradient>
        <radialGradient
          id="paint21_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.272 11.7208) rotate(97.9288) scale(2.89522 2.87513)"
        >
          <Stop stopColor="#FFE960" />
          <Stop offset="1" stopColor="#FFCF2D" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint22_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(32.2192 16.4043) rotate(92.7056) scale(4.81005 1.61274)"
        >
          <Stop offset="0.481663" stopColor="#E39400" />
          <Stop offset="1" stopColor="#EFA31E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint23_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(34.993 15.2665) rotate(92.8772) scale(26.5238 6.96886)"
        >
          <Stop stopColor="#FFD235" />
          <Stop offset="0.235036" stopColor="#FFBD58" />
          <Stop offset="0.437168" stopColor="#F3AB28" />
        </radialGradient>
        <linearGradient
          id="paint24_linear_4451_12834"
          x1="33.6616"
          y1="21.835"
          x2="33.4365"
          y2="18.8272"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#F9BB2A" />
          <Stop offset="1" stopColor="#FDC03E" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint25_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(34.8823 18.3975) rotate(78.2574) scale(7.10175 4.75713)"
        >
          <Stop offset="0.748434" stopColor="#EDAF1F" stopOpacity="0" />
          <Stop offset="1" stopColor="#EDAF1F" />
        </radialGradient>
        <radialGradient
          id="paint26_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(34.7405 13.8843) rotate(98.6034) scale(2.70766 2.91102)"
        >
          <Stop stopColor="#FFE960" />
          <Stop offset="1" stopColor="#FFCF2D" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint27_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.4292 18.9443) rotate(98.5046) scale(4.22616 1.89165)"
        >
          <Stop offset="0.508757" stopColor="#EFA31E" />
          <Stop offset="1" stopColor="#EFA31E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint28_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.3591 18.7912) rotate(96.5335) scale(29.2218 7.65144)"
        >
          <Stop stopColor="#FFD32F" />
          <Stop offset="0.239297" stopColor="#FFC85D" />
          <Stop offset="0.467261" stopColor="#FDB330" />
        </radialGradient>
        <linearGradient
          id="paint29_linear_4451_12834"
          x1="36.6551"
          y1="25.236"
          x2="36.1327"
          y2="21.0176"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#FDBF41" />
          <Stop offset="1" stopColor="#FDBF41" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint30_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.0076 21.5254) rotate(82.1775) scale(12.3403 7.01287)"
        >
          <Stop offset="0.3624" stopColor="#FAB721" stopOpacity="0" />
          <Stop offset="0.600783" stopColor="#F5AC25" />
        </radialGradient>
        <radialGradient
          id="paint31_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.8123 17.1898) rotate(90) scale(2.42166 2.59353)"
        >
          <Stop stopColor="#FFE960" />
          <Stop offset="1" stopColor="#FFCF2D" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint32_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(34.4678 22.1894) rotate(61.2142) scale(1.91635 1.01724)"
        >
          <Stop stopColor="#F4AD1C" />
          <Stop offset="1" stopColor="#F4AD1C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint33_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.6551 25.275) rotate(103.29) scale(6.18062 1.65585)"
        >
          <Stop stopColor="#FFCF5C" />
          <Stop offset="1" stopColor="#FFCD5A" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint34_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.3628 13.8375) rotate(95.4232) scale(24.7985 14.7329)"
        >
          <Stop stopColor="#FBB848" />
          <Stop offset="0.325134" stopColor="#F8BA29" />
          <Stop offset="0.663167" stopColor="#FFBF49" />
          <Stop offset="0.796815" stopColor="#FFA15B" />
          <Stop offset="0.855766" stopColor="#F98B73" />
        </radialGradient>
        <radialGradient
          id="paint35_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.70264 21.4434) rotate(99.4623) scale(2.37608 1.19858)"
        >
          <Stop stopColor="#F4AD1C" />
          <Stop offset="1" stopColor="#F4AD1C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint36_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.95264 16.5215) rotate(81.2923) scale(21.5768 15.741)"
        >
          <Stop offset="0.75395" stopColor="#F88485" stopOpacity="0" />
          <Stop offset="0.830609" stopColor="#F88485" />
        </radialGradient>
        <radialGradient
          id="paint37_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.6558 28.1621) rotate(113.899) scale(14.2704 5.60037)"
        >
          <Stop stopColor="#E09437" />
          <Stop offset="1" stopColor="#E09437" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint38_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(3.43701 25.8184) rotate(75.6354) scale(6.61299 1.93083)"
        >
          <Stop stopColor="#FFCF5C" />
          <Stop offset="1" stopColor="#FFCD5A" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint39_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.7508 21.0234) rotate(117.303) scale(25.0132 4.49187)"
        >
          <Stop stopColor="#F9C043" />
          <Stop offset="0.259984" stopColor="#FFBD3C" />
          <Stop offset="0.674402" stopColor="#F6A731" />
          <Stop offset="0.740906" stopColor="#E2924D" />
        </radialGradient>
        <radialGradient
          id="paint40_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.1521 24.6953) rotate(-151.314) scale(2.35997 4.56556)"
        >
          <Stop stopColor="#F98B73" />
          <Stop offset="1" stopColor="#F98B73" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint41_linear_4451_12834"
          x1="14.2223"
          y1="19.7734"
          x2="15.8777"
          y2="20.4208"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D28A03" />
          <Stop offset="1" stopColor="#D28A03" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint42_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.2771 21.0625) rotate(25.7693) scale(1.25791 4.50359)"
        >
          <Stop stopColor="#FFCD6C" />
          <Stop offset="1" stopColor="#FFC149" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint43_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.8276 23.0449) rotate(82.7698) scale(5.27633 2.22829)"
        >
          <Stop offset="0.52217" stopColor="#FDBD3D" />
          <Stop offset="1" stopColor="#FDBD3D" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint44_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.2808 25.7402) rotate(114.335) scale(17.0629 4.79922)"
        >
          <Stop stopColor="#E09437" />
          <Stop offset="1" stopColor="#E09437" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint45_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.0308 19.9199) rotate(112.38) scale(5.02709 1.15567)"
        >
          <Stop stopColor="#F9C145" />
          <Stop offset="1" stopColor="#F9C145" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint46_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.8901 26.7168) rotate(-58.2335) scale(12.9106 5.66818)"
        >
          <Stop offset="0.742999" stopColor="#CA9A36" stopOpacity="0" />
          <Stop offset="0.835894" stopColor="#CA9A36" />
        </radialGradient>
        <radialGradient
          id="paint47_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.0464 15.9451) rotate(94.1645) scale(23.3466 5.42151)"
        >
          <Stop offset="0.112047" stopColor="#FEBD55" />
          <Stop offset="0.30021" stopColor="#EDAA29" />
          <Stop offset="0.482587" stopColor="#D99A32" />
        </radialGradient>
        <linearGradient
          id="paint48_linear_4451_12834"
          x1="12.7729"
          y1="20.4019"
          x2="13.1674"
          y2="18.2053"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.295306" stopColor="#F9BB2B" />
          <Stop offset="1" stopColor="#FDC03E" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint49_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.187 17.3843) rotate(104.21) scale(9.38882 5.43406)"
        >
          <Stop offset="0.528116" stopColor="#C79A32" stopOpacity="0" />
          <Stop offset="0.725133" stopColor="#C79A32" />
        </radialGradient>
        <radialGradient
          id="paint50_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.9761 16.4858) rotate(98.5308) scale(9.47988 1.37666)"
        >
          <Stop offset="0.42665" stopColor="#C58417" />
          <Stop offset="1" stopColor="#EFA31E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint51_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.78906 12.9668) rotate(91.1804) scale(28.4435 6.88202)"
        >
          <Stop stopColor="#FFD235" />
          <Stop offset="0.221428" stopColor="#FFBD58" />
          <Stop offset="0.378238" stopColor="#F3AB28" />
        </radialGradient>
        <linearGradient
          id="paint52_linear_4451_12834"
          x1="9.79176"
          y1="20.2367"
          x2="10.0535"
          y2="17.0183"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#F9BB2A" />
          <Stop offset="1" stopColor="#FDC03E" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint53_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.78906 17.1074) rotate(103.269) scale(7.08397 4.39592)"
        >
          <Stop offset="0.748434" stopColor="#CA9A37" stopOpacity="0" />
          <Stop offset="1" stopColor="#CA9A37" />
        </radialGradient>
        <radialGradient
          id="paint54_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.728 11.7208) rotate(82.0712) scale(2.89522 2.87513)"
        >
          <Stop stopColor="#FFE960" />
          <Stop offset="1" stopColor="#FFCF2D" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint55_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7.78076 16.4043) rotate(87.2944) scale(4.81005 1.61274)"
        >
          <Stop offset="0.481663" stopColor="#E39400" />
          <Stop offset="1" stopColor="#EFA31E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint56_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.007 15.2665) rotate(87.1228) scale(26.5238 6.96886)"
        >
          <Stop stopColor="#FFD235" />
          <Stop offset="0.235036" stopColor="#FFBD58" />
          <Stop offset="0.437168" stopColor="#F3AB28" />
        </radialGradient>
        <linearGradient
          id="paint57_linear_4451_12834"
          x1="6.33838"
          y1="21.835"
          x2="6.56353"
          y2="18.8272"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#F9BB2A" />
          <Stop offset="1" stopColor="#FDC03E" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint58_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.11768 18.3975) rotate(101.743) scale(7.10175 4.75713)"
        >
          <Stop offset="0.748434" stopColor="#EDAF1F" stopOpacity="0" />
          <Stop offset="1" stopColor="#EDAF1F" />
        </radialGradient>
        <radialGradient
          id="paint59_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.2595 13.8843) rotate(81.3966) scale(2.70766 2.91102)"
        >
          <Stop stopColor="#FFE960" />
          <Stop offset="1" stopColor="#FFCF2D" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint60_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(4.5708 18.9443) rotate(81.4954) scale(4.22616 1.89165)"
        >
          <Stop offset="0.508757" stopColor="#EFA31E" />
          <Stop offset="1" stopColor="#EFA31E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint61_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(1.64062 18.7876) rotate(83.4666) scale(29.2243 7.65193)"
        >
          <Stop stopColor="#FFD32F" />
          <Stop offset="0.239297" stopColor="#FFC85D" />
          <Stop offset="0.467261" stopColor="#FDB330" />
        </radialGradient>
        <linearGradient
          id="paint62_linear_4451_12834"
          x1="3.34473"
          y1="25.2329"
          x2="3.86719"
          y2="21.0142"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#FDBF41" />
          <Stop offset="1" stopColor="#FDBF41" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint63_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(1.99219 21.522) rotate(97.8223) scale(12.3414 7.01332)"
        >
          <Stop offset="0.3624" stopColor="#FAB721" stopOpacity="0" />
          <Stop offset="0.600783" stopColor="#F5AC25" />
        </radialGradient>
        <radialGradient
          id="paint64_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(2.1875 17.186) rotate(90) scale(2.42187 2.5937)"
        >
          <Stop stopColor="#FFE960" />
          <Stop offset="1" stopColor="#FFCF2D" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint65_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.53223 22.186) rotate(118.785) scale(1.91651 1.01731)"
        >
          <Stop stopColor="#F4AD1C" />
          <Stop offset="1" stopColor="#F4AD1C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint66_radial_4451_12834"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(3.34473 25.272) rotate(76.7102) scale(6.18116 1.65596)"
        >
          <Stop stopColor="#FFCF5C" />
          <Stop offset="1" stopColor="#FFCD5A" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint67_linear_4451_12834"
          x1="20.0693"
          y1="4.0625"
          x2="20.0693"
          y2="7.52786"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#1C84CB" />
          <Stop offset="0.146539" stopColor="#1C84CB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint68_linear_4451_12834"
          x1="18.9453"
          y1="5.79468"
          x2="20.0696"
          y2="5.44922"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.117944" stopColor="#1A5BD8" />
          <Stop offset="0.684892" stopColor="#1A5BD8" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint69_linear_4451_12834"
          x1="21.1719"
          y1="5.79468"
          x2="20.0696"
          y2="5.44922"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.117944" stopColor="#1C84FF" />
          <Stop offset="0.684892" stopColor="#1C84FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint70_linear_4451_12834"
          x1="26.4653"
          y1="7.65625"
          x2="25.8232"
          y2="6.61139"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.117944" stopColor="#1B70FF" />
          <Stop offset="0.547589" stopColor="#1B70FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint71_linear_4451_12834"
          x1="26.5239"
          y1="5.625"
          x2="25.731"
          y2="6.60452"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.117944" stopColor="#1B90E0" />
          <Stop offset="0.491359" stopColor="#1B90E0" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint72_linear_4451_12834"
          x1="24.4927"
          y1="6.36719"
          x2="25.7579"
          y2="6.75683"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.117944" stopColor="#185EBA" />
          <Stop offset="0.491359" stopColor="#185EBA" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint73_linear_4451_12834"
          x1="13.2813"
          y1="5.89844"
          x2="14.043"
          y2="6.62109"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#196AA3" />
          <Stop offset="0.402439" stopColor="#196AA3" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint74_linear_4451_12834"
          x1="13.4766"
          y1="8.00781"
          x2="14.043"
          y2="6.62109"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#1B58FB" />
          <Stop offset="0.635498" stopColor="#1B58FB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint75_linear_4451_12834"
          x1="15.3516"
          y1="6.23047"
          x2="13.9063"
          y2="6.95313"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.115717" stopColor="#1D8DEE" />
          <Stop offset="0.583784" stopColor="#1B58FB" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_4451_12834">
          <Rect width="40" height="40" fill="white" />
        </clipPath>
      </Defs>
    </Svg>
  );
};

export default RaisingHandsEmoji;
