import { useEffect, useState } from 'react';

import { PostRepository, subscribeTopic, getPostTopic } from '@amityco/ts-sdk';

import { useCommunityState } from './CommunityContext';

const useCommunityPost = ({
  postID,
}: {
  postID: string;
}): { post: Amity.Post; isLoading: boolean; isError: boolean } => {
  const [post, setPost] = useState<Amity.Post>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const { isConnected } = useCommunityState();

  useEffect(() => {
    const disposers: Amity.Unsubscriber[] = [];
    if (isConnected) {
      const unsubscribePost = PostRepository.getPost(postID, ({ data, loading, error }) => {
        if (data) {
          disposers.push(subscribeTopic(getPostTopic(data)));
          setPost(data);
          setIsLoading(loading);
          setIsError(error);
        }
      });

      disposers.push(unsubscribePost);
    }
    return () => {
      disposers.forEach((unsubscribe) => {
        unsubscribe();
      });
    };
  }, [postID, isConnected]);

  return { post, isLoading, isError };
};

export default useCommunityPost;
