import { FunctionComponent } from 'react';
import { View, RedX, BaseButton, ExtraHuge, Large, Standard } from '@talkspace/react-toolkit';
import { withRouter, RouteComponentProps } from '../../../core/routerLib';

import { NoAvailabilityIcon, NoVideoCreditsIcon } from '../icons/index';
import { ErrorScreen } from '../../reducers/schedulerReducer';

const SchedulerError: FunctionComponent<
  {
    errorScreen?: ErrorScreen;
    therapistFirstName?: string;
  } & RouteComponentProps
> = ({ errorScreen, therapistFirstName, history }) => {
  let errorIcon = <RedX style={{ marginTop: 3 }} />;
  let errorTitle = 'Something went wrong';
  let errorDescription = 'Please try again later.';
  if (errorScreen === 'noAvailability') {
    errorIcon = <NoAvailabilityIcon style={{ marginTop: 3, paddingLeft: 15 }} />;
    errorTitle = 'No availability';
    errorDescription = `${therapistFirstName} currently has no available timeslots.`;
  } else if (errorScreen === 'noVideoCredits') {
    errorIcon = <NoVideoCreditsIcon style={{ marginTop: 3, paddingLeft: 15 }} />;
    errorTitle = 'You have no available credits';
    errorDescription = 'Please ask your psychiatrist for a live video session credit offer.';
  }
  return (
    <View style={{ display: 'flex' }} justify="center" align="center">
      {errorIcon}
      <ExtraHuge style={{ marginTop: 30 }}>{errorTitle}</ExtraHuge>
      <Large variant="largeDarkGrey" style={{ width: 284, textAlign: 'center', marginTop: 10 }}>
        {errorDescription}
      </Large>
      <BaseButton style={{ marginTop: 40 }} onPress={() => history.push('/')}>
        <Standard variant="standardDarkGrey">Close</Standard>
      </BaseButton>
    </View>
  );
};

export default withRouter(SchedulerError);
