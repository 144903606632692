import { FunctionComponent, useCallback, useEffect } from 'react';
import { Large, ExtraHuge, useEmotionTheme } from '@talkspace/react-toolkit';
import { persistData, useWizardState, useWizardActions, WizardActionStatus } from 'stepWizard';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { trackWizardEvent } from '@/utils/analytics/events';
import { getUserData } from '@/auth/helpers/token';
import { RouteComponentProps, withRouter } from '../../core/routerLib/routerLib';
import useB2BCancellationWizard from '../hooks/useB2BCancellationWizard';

const CancelSubscriptionContainer: FunctionComponent<RouteComponentProps> = ({ location }) => {
  const { roomID, isVerifyExitModalOpen, responses, experiment, experimentID, flow } =
    useWizardState();
  const { colors } = useEmotionTheme();
  const closeModal = useCloseModal();
  const [state, { cancelNonSubscription, exitAction }] = useB2BCancellationWizard();
  const { setState } = useWizardActions();
  const { error, isSuccess } = state;
  const { id: userID } = getUserData();
  const lastStepName = 'Cancel plan';

  const handlePersist = useCallback(() => {
    persistData(
      responses,
      setState,
      lastStepName,
      error,
      { canceledSubscription: true },
      isSuccess
    );
  }, [error, isSuccess, responses, setState]);

  const handleExitModal = () => {
    exitAction();
    closeModal();
  };

  useEffect(() => {
    if (isVerifyExitModalOpen) {
      handleExitModal();
    }
  }, [isVerifyExitModalOpen]); // eslint-disable-line

  useEffect(() => {
    // persisting the data on error or success
    const handleRequestStatus = async () => {
      if (isSuccess || error) {
        if (isSuccess) {
          trackWizardEvent(lastStepName, 'Cancellation Wizard', {
            'User ID': userID,
            Application: 'Cancellation Wizard',
            label: lastStepName,
            experiment,
            experimentID,
          });
        }
        handlePersist();
      }
    };
    handleRequestStatus();
  }, [isSuccess, error]); // eslint-disable-line

  useEffect(() => {
    if (!roomID) {
      closeModal();
      return;
    }
    const clientMetGoals = flow === 'feelingBetter';
    cancelNonSubscription(roomID, clientMetGoals);
  }, [roomID]); // eslint-disable-line

  const successTitle = flow ? (
    <ExtraHuge>Your cancellation has been processed.</ExtraHuge>
  ) : (
    'Your plan has been cancelled'
  );

  const successSubTitle = flow ? (
    <>
      We wish you the very best. If you decide you would like to try us again, simply return to{' '}
      <Large inline variant="largeBoldWideGreen">
        Payment and plan
      </Large>{' '}
      and undo your cancellation.
    </>
  ) : (
    'Your Talkspace service has ended. If you decide to resume therapy, you can go to Payment and plan to revert your cancellation'
  );
  return (
    <WizardActionStatus
      successCheckMarkExist={Boolean(flow)}
      successTitle={successTitle}
      successSubTitle={successSubTitle}
      successButtonText="Close"
      errorButtonDataQa="cancelSubscriptionCloseButton"
      handleAction={handleExitModal}
      buttonStyles={{
        padding: error ? 0 : 130,
        backgroundColor: 'none',
        color: colors.permaTalkspaceDarkGreen,
      }}
      isBaseButton
      actionHookState={state}
      subTitleStyles={{
        marginTop: flow ? 25 : 13,
      }}
      style={{
        marginTop: flow ? 25 : 80,
      }}
    />
  );
};

export default withRouter(CancelSubscriptionContainer);
