import { FunctionComponent, useCallback, useEffect } from 'react';
import { persistData, useWizardState, useWizardActions, WizardActionStatus } from 'stepWizard';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import V2FinalScreen from '@/cancellationWizardsShared/components/V2FinalScreen';
import { trackWizardEvent } from '@/utils/analytics/events';
import { getUserData } from '@/auth/helpers/token';
import { RouteComponentProps, withRouter } from '../../core/routerLib/routerLib';
import useCancellationWizard from '../hooks/useCancellationWizard';

const ApplyDiscountContainer: FunctionComponent<RouteComponentProps> = ({ location, history }) => {
  const {
    roomID,
    contextID,
    couponCodes,
    couponCodeIndex,
    couponDiscountDisplay,
    isVerifyExitModalOpen,
    responses,
    flow,
    experiment,
    experimentID,
    therapistInfo,
  } = useWizardState();

  const closeModal = useCloseModal();
  const [state, { applyDiscount, exitAction }] = useCancellationWizard();
  const { setState } = useWizardActions();
  const { error, isSuccess, couponAppliedDate } = state;
  const { id: userID } = getUserData();

  const lastStepName = 'Coupon applied';
  const handlePersist = useCallback(() => {
    persistData(responses, setState, lastStepName, error, { couponApplied: true }, isSuccess);
  }, [error, isSuccess, responses, setState]);

  const handleExitModal = () => {
    exitAction();
    closeModal();
  };

  useEffect(() => {
    if (isVerifyExitModalOpen) {
      handleExitModal();
    }
  }, [isVerifyExitModalOpen]); // eslint-disable-line

  useEffect(() => {
    if (!roomID) {
      closeModal();
      return;
    }
    applyDiscount(roomID, couponCodes[couponCodeIndex]);
  }, [roomID]); // eslint-disable-line

  useEffect(() => {
    // persisting the data on error or success
    const handleRequestStatus = async () => {
      if (isSuccess || error) {
        if (isSuccess) {
          trackWizardEvent(lastStepName, 'Cancellation Wizard', {
            'User ID': userID,
            Application: 'Cancellation Wizard',
            label: lastStepName,
            eventPropertyValue: therapistInfo && therapistInfo.id,
            experiment,
            experimentID,
          });
          if (flow === 'dissatisfiedWithProviderDiscount') {
            await handlePersist();
            history.push(
              `/room/${roomID}/my-account/manage-subscription?action=cancellation-switch-exit&source=cancellation-v2&contextID=${contextID}`
            );
          }
        }
        handlePersist();
      }
    };
    handleRequestStatus();
  }, [isSuccess, error, location]); // eslint-disable-line

  return flow && flow !== 'dissatisfiedWithProviderDiscount' ? (
    <V2FinalScreen
      flow={flow}
      credit={`${couponDiscountDisplay}`}
      actionHookState={state}
      date={couponAppliedDate}
      onClick={handleExitModal}
    />
  ) : (
    <WizardActionStatus
      successTitle="Discount applied"
      successSubTitleHeadline={`${couponDiscountDisplay} off your next payment`}
      successSubTitle={`Thanks for giving us another chance! Your coupon will be applied to your next payment on  ${couponAppliedDate}`}
      successButtonText="Done"
      handleAction={handleExitModal}
      actionHookState={state}
      showCustomErrorSubtitle
    />
  );
};

export default withRouter(ApplyDiscountContainer);
