import { TherapistTimeslot } from 'ts-frontend/types';
import apiHelper from '../../core/api/apiHelper';
import apiWrapper from '../../core/api/apiWrapper';
import { MatchResult, MatchPayload } from '../../therapistMatches/matchingTypes';

const patchClientInfo = (clientUserID: number, updatedClientInfo: object) =>
  apiWrapper.patch(`${apiHelper().apiEndpoint}/api/v1/clients/${clientUserID}`, updatedClientInfo);

export const allowReview = async (roomID: number): Promise<{ allow: boolean }> =>
  apiWrapper
    .get(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/allow-review`)
    .then((res) => res.data.data)
    .catch(() => {
      return { allow: false };
    });

interface MatchingLanguage {
  id: number;
  name: string;
}
interface PostLanguagesForMatchingResponse {
  data: {
    languages: MatchingLanguage[];
  };
}

interface PostLanguagesForMatchingPayload {
  clientState?: string;
  clientCountry: string;
}

export interface PostSuggestTherapistBookingsPayload extends MatchPayload {
  sessionLength: number;
}

interface PostSuggestTherapistBookingsResponse {
  data: {
    timeslots: TherapistTimeslot[];
    matches: MatchResult[];
  };
}

export const postLanguagesForMatching = (
  roomID: number,
  payload: PostLanguagesForMatchingPayload
) =>
  apiWrapper.post<PostLanguagesForMatchingResponse>(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/languages-for-matching`,
    payload
  );

export const postSuggestTherapistBookings = (
  roomID: number,
  payload: PostSuggestTherapistBookingsPayload
) => {
  const { sessionLength } = payload;
  // Since timeslots are multiples of 15, we should not send an intro credit length of 10
  let sessionLengthSuggest = sessionLength;
  if (sessionLength === 10) {
    sessionLengthSuggest = 15;
  }
  return apiWrapper
    .post<PostSuggestTherapistBookingsResponse>(
      `${apiHelper().apiEndpoint}/v3/rooms/${roomID}/suggest-therapist-bookings`,
      {
        ...payload,
        sessionLength: sessionLengthSuggest,
      }
    )
    .then((res) => res.data.data);
};

const switchWizardApiHelper = {
  patchClientInfo,
};

export default switchWizardApiHelper;
