import {
  Grid,
  Col,
  View,
  SpacingView,
  Card,
  TextDS,
  useWindowWidth,
  Tag,
  spacing,
} from '@talkspace/react-toolkit';
import { useTranslation } from '@talkspace/i18n';
import { getCrisisSupportCards, getTechnicalSupportCards } from './helpCards';
import ssoHelper from '@/utils/sso';
import HelpCard from './HelpCard';
import useNavShellData from '../../../hooks/useNavShellData';

const { space150 } = spacing;

export interface UserSettings {
  sendMessagesReceipts: boolean;
}

const HelpScreen = () => {
  const { isMobile } = useWindowWidth();
  const { t: tnewMemberNav } = useTranslation('newMemberNav');

  const { subscriptions } = useNavShellData();
  return (
    <Grid fluid rowSpacing={isMobile ? 'space0' : 'space400'}>
      <Col sm={8} lg={8}>
        <SpacingView itemSpacing="space200" row={false}>
          <TextDS variant="heading2xl">{tnewMemberNav('help.help', '')}</TextDS>

          <View>
            <SpacingView itemSpacing="space0" row={false}>
              <View as="span" style={{ display: 'inline' }}>
                <TextDS variant="headingLg" as="span">
                  {tnewMemberNav('help.crisis', 'Crisis support')}
                </TextDS>
                <Tag
                  color="criticalBoldDefault"
                  text={tnewMemberNav('help.sos', 'SOS')}
                  style={{ padding: '1px 8px', marginLeft: space150 }}
                  inline
                />
              </View>

              {getCrisisSupportCards({
                tnewMemberNav,
                isUS: Boolean(subscriptions?.some((s) => s.participantCountry === 'US')),
              }).map(({ dataQa, route, ...props }) => (
                <Card
                  variant="noBorder"
                  padding="slim"
                  onPress={() => {
                    window.open(route, '_blank');
                  }}
                  dataQa={dataQa}
                >
                  <HelpCard {...props} />
                </Card>
              ))}
            </SpacingView>
          </View>

          <View>
            <SpacingView itemSpacing="space0" row={false}>
              <TextDS variant="headingLg">
                {tnewMemberNav('help.technical', 'Technical support')}
              </TextDS>
              {getTechnicalSupportCards(tnewMemberNav).map(
                ({ dataQa, zendeskPath, route, ...props }) => (
                  <Card
                    variant="noBorder"
                    padding="slim"
                    onPress={() => {
                      if (zendeskPath) {
                        ssoHelper.openZendesk(zendeskPath);
                      } else {
                        window.open(route, '_blank');
                      }
                    }}
                    dataQa={dataQa}
                  >
                    <HelpCard {...props} />
                  </Card>
                )
              )}
            </SpacingView>
          </View>
        </SpacingView>
      </Col>
    </Grid>
  );
};

export default HelpScreen;
