import { FunctionComponent, useRef, useState } from 'react';
import { Notepad, Heading1, Caption, Button } from '@talkspace/react-toolkit';
import { QMSessionTypeEnum } from 'ts-frontend/types';
import { PromiseMessageDataHandler, PromiseMessageTypeNames } from 'ts-promise-message';
import styled from '../../../core/styled';
import FullscreenWrapper from '../../../components/FullscreenWrapper';
import { withRouter } from '../../../core/routerLib';
import { RouteComponentProps, useLocation, useHistory } from '../../../core/routerLib/routerLib';
import useScreenSize, { screenSizes, isResponsive } from '../../../core/hooks/useScreenSize';
import { trackEvent } from '../../../utils/analytics/eventTracker';
import { PreviousPrimaryTherapistAvailable } from '../../roomReactivationTypes';
import QuickmatchFrame from '../../../containers/QuickmatchFrame';
import { UserAuthProvider } from '../../../entities/Me';
import { getUserData } from '../../../auth/helpers/token';

const Description = styled(Caption)<{ screenSize: screenSizes }>(({ screenSize }) => {
  return {
    marginTop: 10,
    textAlign: 'center',
    paddingLeft: isResponsive(screenSize) ? 10 : 0,
    paddingRight: isResponsive(screenSize) ? 10 : 0,
  };
});

const Title = styled(Heading1)<{ screenSize: screenSizes }>(({ screenSize }) => {
  return {
    textAlign: 'center',
    fontSize: 38,
    lineHeight: 'inherit',
    ...(isResponsive(screenSize)
      ? {
          fontSize: 32,
          fontWeight: 500,
          letterSpacing: -0.4,
          lineHeight: '30px',
          width: 'auto',
        }
      : {}),
  };
});

const DESCRIPTION = `You're taking an important step in your mental health journey. If you're ready to continue, complete the following questions so we can match you with a provider that fits your needs.`;

interface WelcomeBackProps extends RouteComponentProps {
  previousPrimaryTherapistsAvailable: PreviousPrimaryTherapistAvailable[];
  hasOnlyPlaceholderProviders: boolean;
  lastActiveRoomID: number;
  lastActiveRoomType: string;
  authProvider?: UserAuthProvider;
  roomID?: number;
  isCTRoom?: boolean;
  skipPayment: boolean;
}

const WelcomeBack: FunctionComponent<WelcomeBackProps> = ({
  previousPrimaryTherapistsAvailable,
  hasOnlyPlaceholderProviders,
  authProvider,
  lastActiveRoomID,
  lastActiveRoomType,
  roomID,
  isCTRoom,
  skipPayment, // TODO: Check with product what to do with this.
  // Currently this is only the case for psychiatry rooms with unavailable providers
}) => {
  const screenSize = useScreenSize();
  const location = useLocation();
  const history = useHistory();
  // Use ref to persist
  const couponRef = useRef(new URLSearchParams(location.search)?.get('coupon') || undefined);
  const [isLoading, setIsLoading] = useState(false);

  const onContinuePress = (handleLaunchButton: () => void) => {
    setIsLoading(true);
    trackEvent('User Clicks Continue', {
      eventCategory: 'Reactivation',
      eventCategoryKey: 'Funnel Name',
    });

    if (skipPayment) {
      history.push('/switch-provider', {
        roomID: roomID || lastActiveRoomID,
        source: 'reactivation',
        userID: getUserData().id,
      });
    } else {
      handleLaunchButton();
    }
  };

  const onQMInitData: PromiseMessageDataHandler<PromiseMessageTypeNames.getQMInitData> = (
    _data,
    callback
  ) => {
    const getProviderInfo = (provider: PreviousPrimaryTherapistAvailable) => {
      const {
        firstName,
        lastName,
        therapistID,
        status,
        therapistImage,
        therapistLicenses,
        title,
        therapistType,
      } = provider;
      return {
        firstName,
        lastName,
        status,
        therapistID,
        therapistImage,
        therapistLicenses,
        title,
        therapistType,
      };
    };
    callback({
      currentProvidersInfo: previousPrimaryTherapistsAvailable.map(getProviderInfo),
      hasOnlyPlaceholderProviders,
    });
  };

  return (
    <QuickmatchFrame
      frameTitle="Reactivation"
      sessionType={QMSessionTypeEnum.Reactivation}
      authProvider={authProvider}
      onQMInitData={onQMInitData}
      couponCode={couponRef.current}
      render={({ handleLaunchButton }) => (
        <FullscreenWrapper
          style={{
            maxWidth: 510,
            minHeight: 575,
          }}
        >
          <Notepad />
          <Title screenSize={screenSize}>Welcome back to Talkspace</Title>
          <Description as="h2" screenSize={screenSize}>
            {DESCRIPTION}
          </Description>

          <Button
            style={{
              marginTop: 16,
            }}
            text="Continue"
            onPress={() => onContinuePress(handleLaunchButton)}
            isLoading={isLoading}
            disabled={isLoading}
            dataQa="welcomeBackContinueButton"
          />
        </FullscreenWrapper>
      )}
    />
  );
};

export default withRouter(WelcomeBack);
