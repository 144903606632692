import moment from 'moment';
import { useMemo } from 'react';

import { Subscription } from 'ts-frontend/types';

import useQueryRenewalEligibility from './useQueryRenewalEligibility';

interface Props {
  subscriptions: Subscription[] | undefined;
}
const useNYCTeenRoomToRenew = ({
  subscriptions,
}: Props): { roomID: number | undefined; isLoading: boolean } => {
  const expiredNYCTeenRoomID = useMemo(
    () =>
      subscriptions?.find(
        (subscription) =>
          subscription?.planDetail?.productLine === 'teen' &&
          moment(subscription.subscription.endSubscriptionDate).isBefore(moment())
      )?.id,
    [subscriptions]
  );
  const { data: { isEligibleForRenewal } = {}, isLoading } =
    useQueryRenewalEligibility(expiredNYCTeenRoomID);

  return { roomID: isEligibleForRenewal ? expiredNYCTeenRoomID : undefined, isLoading };
};

export default useNYCTeenRoomToRenew;
