import { hasChronicPainOptions, isPregnantOptions } from '@talkspace/configs';
import { WizardGenericActions, WizardPostActionContext } from 'stepWizard';
import { OtherWizardGenericActions } from '@/utils/genericWizardActions';
import ApiHelper from '@/utils/ApiHelper';
import { MedicalHealthWizardState } from '../reducers/medicalHealthWizardState';

const options = {
  hasChronicPainOptions,
  isPregnantOptions,
};

const setSelectedState = (
  stateName: string,
  optionsName: string,
  context: WizardPostActionContext<MedicalHealthWizardState>
) => {
  const partialState: Partial<MedicalHealthWizardState> = {};
  const { setState, wizardContext } = context;

  const selectedStateName = `selected${stateName.charAt(0).toUpperCase() + stateName.slice(1)}`;

  partialState[selectedStateName] =
    options[optionsName][wizardContext[stateName]] !== options[optionsName].no &&
    options[optionsName][wizardContext[stateName]] !== options[optionsName].never;
  setState(partialState);
};

const initState: WizardGenericActions<MedicalHealthWizardState, ApiHelper>['initState'] = async (
  api: ApiHelper,
  state
) => {
  let res;
  if (state.roomID) {
    res = await api.getIntakesStatus(state.roomID);
  }

  const { showPregnancyQuestion = false } = res?.data?.data || {};

  return {
    setSelectedHasChronicPain: (_, context) => {
      setSelectedState('hasChronicPain', 'hasChronicPainOptions', context);
    },
    setSelectedIsPregnant: (_, context) => {
      setSelectedState('isPregnant', 'isPregnantOptions', context);
    },
    showPregnancyQuestion,
  };
};

const persist = async () => {};

const medicalHealthActions: OtherWizardGenericActions<MedicalHealthWizardState, ApiHelper> = {
  initState,
  persist,
};

export default medicalHealthActions;
