import { FunctionComponent } from 'react';
import { Screen } from '@talkspace/react-toolkit';
import LinkExpiredComponent from '../components/LinkExpired';

const LinkExpired: FunctionComponent<{}> = () => (
  <Screen showCloseButton={false}>
    <LinkExpiredComponent />
  </Screen>
);

export default LinkExpired;
