import { useQuery } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../core/api/apiWrapper';
import { onboardingQueryKey, onboardingV3QueryKey } from '../../utils/queryKeys';

import { Step } from '../types';

interface QueryProps {
  roomID?: number;
  userID?: number;
  disableRefetchOnWindowFocus?: boolean;
}

export interface OnboardingAPIResponse {
  steps: Array<Step>;
}

const USE_V3_ENDPOINT = true;

const fetchOnboarding =
  ({ roomID, userID }: QueryProps) =>
  async () => {
    if (!roomID || !userID) {
      throw new Error('useQueryOnboarding missing room or user id');
    }
    const {
      data: { data },
    } = await apiWrapper.get(
      USE_V3_ENDPOINT
        ? `${apiHelper().apiEndpoint}/v3/clients/${userID}/onboarding?roomID=${roomID}`
        : `${apiHelper().apiEndpoint}/v2/clients/${userID}/onboarding?roomID=${roomID}`
    );
    const roomSteps = roomID && data?.[roomID] ? data[roomID] : [];
    return {
      steps: roomSteps,
    };
  };

const useQueryOnboarding = ({ roomID, userID, disableRefetchOnWindowFocus }: QueryProps) =>
  useQuery<OnboardingAPIResponse, Error>({
    queryKey: USE_V3_ENDPOINT
      ? onboardingV3QueryKey({ roomID, userID })
      : onboardingQueryKey({ roomID, userID }),
    queryFn: fetchOnboarding({ roomID, userID }),
    cacheTime: Infinity,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: !disableRefetchOnWindowFocus,
    enabled: Boolean(roomID && userID),
  });

export default useQueryOnboarding;
