import { useEffect, useRef, useState } from 'react';
import { Client, API_REGIONS } from '@amityco/ts-sdk';
import { getUserData } from '@/auth/helpers/token';

import ApiHelper from '@/utils/ApiHelper';
import appConfig from '@/utils/configs';

import { updateUser } from '../utils/amity';
import { UserProfile } from '../types';
import useJoinCommunity from './useJoinCommunity';

const useAmityLogin = ({
  disabled,
  userProfile,
  communityID,
}: {
  disabled: boolean;
  userProfile: UserProfile | undefined;
  communityID: string | undefined;
}): {
  accessToken: string | undefined;
  authToken: string | undefined;
  isConnected: boolean;
  isConnectionError: boolean;
} => {
  const { id: userID } = getUserData();

  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isConnectionError, setIsConnectionError] = useState<boolean>(false);
  const [authToken, setAuthToken] = useState<string>();

  const clientRef = useRef<Amity.Client | undefined>();

  const { displayName, dateOfBirth, state } = userProfile || {};

  useEffect(() => {
    if (!disabled && !clientRef.current) {
      clientRef.current = Client.createClient(appConfig.amity.apiKey, API_REGIONS.US);
    }
  }, [disabled]);

  useEffect(() => {
    if (!disabled) {
      new ApiHelper()
        .getAmityAuthToken()
        .then((res) => {
          setAuthToken(res.data.token);
        })
        .catch(() => {
          setIsConnectionError(true);
        });
    }
  }, [disabled]);

  useEffect(() => {
    if (!disabled && clientRef.current && userID && displayName && authToken) {
      Client.login(
        {
          userId: String(userID),
          displayName,
          authToken,
        },
        {
          sessionWillRenewAccessToken(renewal: Amity.AccessTokenRenewal) {
            renewal.renew();
          },
        }
      )
        .then((res) => {
          setIsConnected(res);
        })
        .catch(() => setIsConnectionError(true));
    }
  }, [disabled, userID, displayName, authToken]);

  // Sync displayName, state and DOB with amity
  useEffect(() => {
    if (!disabled && isConnected && userID && displayName && state && dateOfBirth) {
      updateUser({
        userID: String(userID),
        params: {
          displayName,
          metadata: { state, dateOfBirth },
        },
      });
    }
  }, [disabled, dateOfBirth, displayName, isConnected, state, userID]);

  // Join community
  useJoinCommunity({ isConnected, communityID });

  return {
    authToken,
    accessToken: clientRef.current?.token?.accessToken, // NOTE: only used for access to Amity Beta API
    isConnected,
    isConnectionError,
  };
};

export default useAmityLogin;
