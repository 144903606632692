import Grid from './Grid';
import Row from './Row';
import Col from './Col';
import { OffsetByBreakpoint } from './gridTypes';
import { BreakpointValues } from '../../../types';

interface Props extends Partial<BreakpointValues>, Partial<OffsetByBreakpoint> {
  children: React.ReactNode;
}

/*
  This components implements simple one column layout
  By default it renders standard DS screen with centered one column of width:
  xs (mobile): 4/4
  sm (smallTablet): 6/8
  md (largeTablet): 6/8
  lg (smallDesktop): 4/12
  xl (largeDesktop): 4/12
*/
const OneColumnLayout = ({
  children,
  xs,
  xsOffset,
  sm,
  smOffset,
  md,
  mdOffset,
  lg,
  lgOffset,
  xl,
  xlOffset,
}: Props) => {
  const offsetToColParams: Partial<BreakpointValues> = {
    xs: xsOffset || undefined,
    sm: smOffset || 1,
    md: mdOffset || undefined,
    lg: lgOffset || 4,
    xl: xlOffset || undefined,
  };
  const colParams: Partial<BreakpointValues> = {
    xs: xs || 4,
    sm: sm || 6,
    md: md || undefined,
    lg: lg || 4,
    xl: xl || undefined,
  };
  return (
    <Grid>
      <Row align="center">
        {/* NOTE: this empty column is a workaround for column offset parameters not working as expected */}
        <Col {...offsetToColParams}>{null}</Col>
        <Col {...colParams}>{children}</Col>
      </Row>
    </Grid>
  );
};

export default OneColumnLayout;
