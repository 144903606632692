import { FunctionComponent } from 'react';
import FriendReferralContainer from '../containers/FriendReferral';
import ModalClosingScreen from '../../2storybook/components/ModalClosingScreen';

const FriendReferralScreen: FunctionComponent = () => (
  <ModalClosingScreen>
    <FriendReferralContainer />
  </ModalClosingScreen>
);

export default FriendReferralScreen;
