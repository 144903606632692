import { useState } from 'react';
import {
  Card,
  View,
  TextDS,
  Button,
  SpacingView,
  StatusTag,
  UnorderedListDS,
  ColorRolesVersion,
  TSAvatar,
  useEmotionTheme,
  BaseButton,
} from '@talkspace/react-toolkit';
import { useTranslation } from '@talkspace/i18n';
import moment from 'moment';
import styled from '@/core/styled';
import { RoomType } from '@/entities/Room';
import { MatchResult } from '../../therapistMatches/matchingTypes';
import MatchPreferenceRow from './MatchPreferenceRow';
import { MatchPreferenceReason } from '@/types';

const TrimmedText = styled(TextDS)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 80px;
  -webkit-line-clamp: 2;
`;

const BioTextWrapper = styled(View)(({ theme: { spacing } }) => {
  return {
    paddingTop: spacing('space050'),
  };
});

const CardBottomWrapper = styled(View)(({ theme: { spacing, colorRoles } }) => {
  return {
    paddingTop: spacing('space200'),
    borderTop: `1px solid ${colorRoles.borders.borderSubtleDefault}`,
  };
});

const ViewTimeslotsButton = styled(Button)(({ theme: { spacing } }) => {
  return {
    width: 'auto',
    padding: `${spacing('space150', true)} ${spacing('space300', true)}`,
  };
});

const getAvatarProps = (colorRoles: ColorRolesVersion, roomType?: RoomType) => {
  if (roomType === 'psychiatry_room')
    return {
      backgroundColor: colorRoles.surfaces.decorativePsych,
    };
  if (roomType === 'couples_room')
    return {
      backgroundColor: colorRoles.surfaces.decorativeCouples,
    };
  return { backgroundColor: colorRoles.surfaces.brandSubtleDefault };
};

const formatTimeSlot = (nextAvailableTimeslot: string, t: Function) => {
  if (moment(nextAvailableTimeslot).isSame(moment().add(1, 'day'), 'day')) {
    return t('switchWizard.tomorrow', 'Tomorrow');
  }
  return moment(nextAvailableTimeslot).format('ddd, MMM D');
};

interface TherapistMatchDirectoryCardProps {
  matchResult: MatchResult;
  matchPreferenceReasons?: MatchPreferenceReason[];
  nextAvailable?: string;
  peopleHelped?: number;
  topSpecialties: string[];
  isRecommended?: boolean;
  isHighlighted?: boolean;
  onPress?: () => void;
  isBooking?: boolean;
  isMessaging?: boolean;
}
const TherapistMatchDirectoryCard = (props: TherapistMatchDirectoryCardProps) => {
  const { colorRoles, spacing } = useEmotionTheme();
  const { t: tSwitchWizard } = useTranslation('switchWizard');
  const {
    matchResult: { details },
    matchPreferenceReasons,
    nextAvailable,
    peopleHelped,
    topSpecialties,
    isRecommended,
    isHighlighted,
    onPress,
    isBooking,
    isMessaging,
  } = props;

  const { image } = details;

  const providerName = [details.title, details.firstName, details.lastName]
    .filter(Boolean)
    .join(' ');

  const avatarProps = getAvatarProps(colorRoles, 'private_room');

  const [isBioOpen, setIsBioOpen] = useState(false);

  const RecommendReasonWrapper = isHighlighted ? Card : View;
  return (
    <Card surfaceRoleVariantName={isHighlighted ? 'brandSubtle' : 'subtle'}>
      <SpacingView fullWidth flex={1} itemSpacing="space200">
        <View row flex={1}>
          <TSAvatar
            avatarColor={colorRoles.element.iconNonInteractiveDefault}
            borderColor="none"
            {...avatarProps}
            size={64}
            image={image}
            titleText={providerName}
            alt={providerName}
          />
          <View style={{ paddingLeft: spacing('space200') }}>
            {isRecommended && <StatusTag variant="recommendedMatch" />}
            <TextDS variant="decorative/headingLg">{providerName}</TextDS>
            {peopleHelped && peopleHelped > 30 ? (
              <TextDS variant="bodyXs">
                {peopleHelped} {tSwitchWizard('MatchResults.peopleHelped', 'people helped')}
              </TextDS>
            ) : null}
          </View>
        </View>
        {matchPreferenceReasons ? (
          <View row flex={1}>
            <RecommendReasonWrapper surfaceRoleVariantName={isHighlighted ? 'subtle' : undefined}>
              <TextDS variant="headingSm">
                {tSwitchWizard(
                  'MatchResults.weRecommend',
                  `We recommend ${details.firstName} because they:`,
                  { firstName: details.firstName }
                )}
              </TextDS>
              <UnorderedListDS>
                {matchPreferenceReasons.map((reason) => (
                  <MatchPreferenceRow
                    preferenceName={reason.matchPreferenceName}
                    value={reason.value}
                  />
                ))}
              </UnorderedListDS>
            </RecommendReasonWrapper>
          </View>
        ) : (
          <View row flex={1}>
            <RecommendReasonWrapper surfaceRoleVariantName={isHighlighted ? 'subtle' : undefined}>
              <TextDS variant="headingSm">
                {tSwitchWizard('MatchResults.topSpecialties', `Top specialties:`)}
              </TextDS>
              <UnorderedListDS>
                {topSpecialties.map((specialtyText) => (
                  <TextDS variant="bodySm">{specialtyText}</TextDS>
                ))}
              </UnorderedListDS>
            </RecommendReasonWrapper>
          </View>
        )}
        <View row flex={1}>
          <View flex={1}>
            <TextDS variant="headingSm">
              {tSwitchWizard('MatchResults.aNoteFor', `A note for ${details.firstName}:`, {
                firstName: details.firstName,
              })}
            </TextDS>
            <BioTextWrapper>
              {isBioOpen ? (
                <TextDS variant="bodySm">{details.bio}</TextDS>
              ) : (
                <TrimmedText variant="bodySm">{details.bio}</TrimmedText>
              )}
            </BioTextWrapper>
            <View
              align="end"
              style={{ padding: `${spacing('space075', true)} ${spacing('space100', true)}` }}
            >
              <BaseButton
                dataQa="therapistMatchDirectoryCardBioOpen"
                onPress={() => {
                  setIsBioOpen(!isBioOpen);
                }}
              >
                <TextDS variant="headingSm" colorRole="buttonBrandSecondary">
                  {isBioOpen ? 'Show less' : 'Read more'}
                </TextDS>
              </BaseButton>
            </View>
          </View>
        </View>

        <CardBottomWrapper flex={1}>
          <View row justify="space-between" flex={1}>
            {nextAvailable ? (
              <View justify="center">
                <TextDS variant="headingSm">
                  {tSwitchWizard('MatchResults.nextAvailable', 'Next available')}
                </TextDS>
                <TextDS variant="bodySm">{formatTimeSlot(nextAvailable, tSwitchWizard)}</TextDS>
              </View>
            ) : (
              <View />
            )}
            <View>
              {isMessaging && (
                <ViewTimeslotsButton
                  onPress={onPress}
                  text={tSwitchWizard(
                    'MatchResults.continueWith',
                    `Continue with ${details.firstName}`,
                    {
                      firstName: details.firstName,
                    }
                  )}
                  dataQa="TherapistMatchDirectoryCardButton"
                />
              )}
              {!isMessaging && isBooking && (
                <ViewTimeslotsButton
                  onPress={onPress}
                  text={tSwitchWizard('MatchResults.viewAvailability', 'View Availability')}
                  dataQa="TherapistMatchDirectoryCardButton"
                />
              )}
              {!isMessaging && !isBooking && (
                <ViewTimeslotsButton
                  onPress={onPress}
                  text={tSwitchWizard('MatchResults.continue', 'Continue')}
                  dataQa="TherapistMatchDirectoryCardButton"
                />
              )}
            </View>
          </View>
        </CardBottomWrapper>
      </SpacingView>
    </Card>
  );
};
export default TherapistMatchDirectoryCard;
