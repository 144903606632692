import { useCallback, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { persistData, useWizardState, useWizardActions, WizardActionStatus } from 'stepWizard';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { trackWizardEvent } from '@/utils/analytics/events';
import useRefundWizard from '../hooks/useRefundWizard';
import { refundTicketTypes } from '../utils/refundWizardTypes';

const SubmitSupportRequestContainer = ({
  isNavigatingBack,
}: { isNavigatingBack: boolean } & RouteComponentProps) => {
  const {
    clientUserID,
    source,
    isVerifyExitModalOpen,
    responses,
    selectedRefundableCharge: charge,
    eventCategory,
    LVSOtherRefundReason,
    SubscriptionOtherRefundReason,
    isDontDisplayRefundsFlow,
    isRefundNotSelectedFlow,
    isRedeemedLVSFlow,
    isNonRefundableCopayFlow,
    videoIssuesRefundReasonSubscription,
    otherRefundReasonSubscription,
    isRefundableSubscriptionFlow,
    isNonRefundableSubscriptionFlow,
    didRefundFail,
  } = useWizardState();
  const closeModal = useCloseModal();
  const [state, { submitSupportRequest, exitAction }] = useRefundWizard();
  const { setState, closeWizard } = useWizardActions();
  const { error, isSuccess } = state;

  const getTicketType = (): refundTicketTypes => {
    if (isDontDisplayRefundsFlow) {
      return 'noRefunds';
    }
    if (isRefundNotSelectedFlow) {
      return 'refundNotSelected';
    }
    if (isRedeemedLVSFlow) {
      return 'redeemedLVS';
    }
    if (isNonRefundableCopayFlow || isNonRefundableSubscriptionFlow) {
      return 'nonRefundableSubscriptionOrCopay';
    }
    if (videoIssuesRefundReasonSubscription) {
      return 'nonCancelledSubscriptionLVS';
    }
    if (otherRefundReasonSubscription) {
      return 'nonCancelledSubscriptionOther';
    }
    if (didRefundFail) {
      return 'refundFailed';
    }
    if (isRefundableSubscriptionFlow) {
      return 'refundWaived';
    }
    return 'generalRefundIssue';
  };

  const bodyParams = {
    freeText: LVSOtherRefundReason || SubscriptionOtherRefundReason || undefined,
    ticketPriority: 'normal',
    ticketType: getTicketType(),
    charge:
      charge && charge.chargeID
        ? {
            chargeID: charge.chargeID,
            invoiceID: charge.invoiceID,
            subscriptionID: charge.subscriptionID,
            sessionID: charge.sessionID,
            creditID: charge.creditID,
            roomID: charge.roomID,
            payedAmount: charge.payedAmount,
            currency: charge.currency,
            refundableAmount: charge.refundableAmount,
            chargeType: charge.chargeType,
            createdAt: charge.createdAt,
          }
        : undefined,
  };

  const handlePersist = useCallback(() => {
    persistData(
      responses,
      setState,
      'Support request submitted',
      error,
      { refundApplied: true },
      isSuccess
    );
  }, [error, isSuccess, responses, setState]);

  const handleExitModal = () => {
    exitAction();
    if (source === 'payment-and-plan') {
      closeWizard();
    } else {
      closeModal({
        navigateTo: 'dashboard',
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      trackWizardEvent('Support Ticket Submitted', eventCategory, {
        'User ID': clientUserID,
        Application: eventCategory,
        eventPropertyValue: 0.0,
      });
      trackWizardEvent('CX Followup', eventCategory, {
        'User ID': clientUserID,
        Application: eventCategory,
        eventPropertyValue: 0.0,
      });
    }
  }, [isSuccess]); // eslint-disable-line

  useEffect(() => {
    if (isVerifyExitModalOpen) {
      handleExitModal();
    }
  }, [isVerifyExitModalOpen]); // eslint-disable-line

  useEffect(() => {
    if (!isNavigatingBack) {
      submitSupportRequest(clientUserID, bodyParams);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    // persisting the data on error or success
    const handleRequestStatus = async () => {
      if (isSuccess || error) {
        handlePersist();
      }
    };
    handleRequestStatus();
  }, [isSuccess, error]); // eslint-disable-line

  return (
    <WizardActionStatus
      successTitle="Your request was successfully submitted."
      successSubTitle="Please expect a follow up email from a member of our customer support team."
      successButtonText="Done"
      handleAction={handleExitModal}
      actionHookState={state}
    />
  );
};

export default withRouter(SubmitSupportRequestContainer);
