import { useCallback, useEffect, useState } from 'react';

interface Props<Option> {
  options: Option[];
  intervalSeconds: number;
}
const useRotateOptionsArray = <Option>({ options, intervalSeconds }: Props<Option>) => {
  const [optionIndex, setOptionIndex] = useState<number>(0);

  const rotateArrayIndex = useCallback(() => {
    setOptionIndex((currentOptionIndex) => {
      if (currentOptionIndex < options.length - 1) {
        return currentOptionIndex + 1;
      }
      return 0;
    });
  }, [options]);

  useEffect(() => {
    const intervalID = setInterval(rotateArrayIndex, 1000 * intervalSeconds);
    return () => clearInterval(intervalID);
  }, [intervalSeconds, rotateArrayIndex]);

  return options[optionIndex];
};

export default useRotateOptionsArray;
