// this file could export more than one component and the export won't have the same name as the file so we disable prefer-default-export
/* eslint-disable import/prefer-default-export */
import { FunctionComponent } from 'react';
import HiddenText from '../HiddenText';

const IBHChartHiddenText: FunctionComponent<{
  data: number[];
  id: string;
}> = ({ id, data }) => {
  const availibilityByTimeSlot = [
    { timeSlot: '12:00 AM to 2:00 AM', availability: 0 },
    { timeSlot: '2:00 AM to 4:00 AM', availability: 0 },
    { timeSlot: '4:00 AM to 6:00 AM', availability: 0 },
    { timeSlot: '6:00 AM to 8:00 AM', availability: 0 },
    { timeSlot: '8:00 AM to 10:00 AM', availability: 0 },
    { timeSlot: '10:00 AM to 12:00 PM', availability: 0 },
    { timeSlot: '12:00 PM to 2:00 PM', availability: 0 },
    { timeSlot: '2:00 PM to 4:00 PM', availability: 0 },
    { timeSlot: '4:00 PM to 6:00 PM', availability: 0 },
    { timeSlot: '6:00 PM to 8:00 PM', availability: 0 },
    { timeSlot: '8:00 PM to 10:00 PM', availability: 0 },
    { timeSlot: '10:00 PM to 12:00 AM', availability: 0 },
  ];

  for (let i = 0; i < data.length; i += 1) {
    availibilityByTimeSlot[i].availability = data[i];
  }

  const threeMostActiveTimes = availibilityByTimeSlot
    .sort((a, b) => b.availability - a.availability)
    .slice(0, 3);

  return (
    <HiddenText id={id}>
      {`This therapist is most active on the selected day during the following time slots: ${threeMostActiveTimes[0].timeSlot}, ${threeMostActiveTimes[1].timeSlot}, ${threeMostActiveTimes[2].timeSlot}`}
    </HiddenText>
  );
};

export { IBHChartHiddenText };
