export interface CustomerInfoAPI {
  userID: number;
  preferredName?: string;
  gender?: string;
  yearOfBirth?: number;
  countryState?: string;
  maritalStatus?: string;
  levelOfEducation?: string;
  ethnicity?: string;
  therapistGenderPreference?: string;
  dateOfBirth?: string;
}

// eslint-disable-next-line import/prefer-default-export
export class ECustomerInfo implements CustomerInfoAPI {
  userID: number;

  preferredName?: string;

  gender?: string;

  yearOfBirth?: number;

  countryState?: string;

  maritalStatus?: string;

  levelOfEducation?: string;

  ethnicity?: string;

  therapistGenderPreference?: string;

  dateOfBirth?: string;

  constructor(customerInfo: CustomerInfoAPI) {
    this.userID = customerInfo.userID;
    this.preferredName = customerInfo.preferredName;
    this.yearOfBirth = customerInfo.yearOfBirth;
    this.gender = customerInfo.gender;
    this.countryState = customerInfo.countryState;
    this.maritalStatus = customerInfo.maritalStatus;
    this.levelOfEducation = customerInfo.levelOfEducation;
    this.ethnicity = customerInfo.ethnicity;
    this.dateOfBirth = customerInfo.dateOfBirth;

    this.therapistGenderPreference =
      customerInfo.therapistGenderPreference && customerInfo.therapistGenderPreference !== 'any'
        ? customerInfo.therapistGenderPreference
        : undefined;
  }
}
