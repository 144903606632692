import { useHistory, useParams } from '@/core/routerLib';
import ManageSubscriptionThanksForStaying from '../components/ManageSubscriptionThanksForStaying';
import { useCloseModal } from '@/utils/ModalsContextProvider';

const ManageSubscriptionThanksForStayingContainer = () => {
  const { roomID } = useParams<{ roomID: string }>();
  const closeModal = useCloseModal();
  const history = useHistory();
  return (
    <ManageSubscriptionThanksForStaying
      onPressClose={() => closeModal()}
      onPressBack={() => history.goBack()}
      onPressGoToRoom={() =>
        closeModal({
          navigateTo: 'room',
          metadata: { roomID: Number(roomID) },
          ignoreReturnTo: true,
        })
      }
    />
  );
};

export default ManageSubscriptionThanksForStayingContainer;
