import { FunctionComponent } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import {
  View,
  ExtraHuge,
  Large,
  useEmotionTheme,
  useWindowWidthState,
  ComplianceBadges,
} from '@talkspace/react-toolkit';
import { PlanData } from 'ts-frontend/types';
import PlanList from './PlanList';
import styled, { EmotionStyle } from '@/core/styled';
import { useRoomOffersState } from '../hooks/roomOfferContext';
import { checkForExperimentalPlanPricing } from '../utils/constants';

const NO_MATCHES_YOU_WONT_BE_CHARGED =
  'You will not be charged until you’ve been matched with a provider. Switch plans or cancel anytime after subscribing.';

const GET_PERSONALIZED_SUPPORT_FROM_LICENSED_THERAPIST =
  'Get private, personalized mental health support from a licensed therapist';

interface OfferProps {
  currentPlan?: PlanData;
  plansMatrix: PlanData[][];
  onPlanSelected: (plan: PlanData, monthlyPlan: PlanData) => void;
  titleId?: string;
  trialOfferPrice?: number;
  trialOfferPriceDisplay?: string;
  style?: EmotionStyle;
}

function TrialMessage({
  trialOfferPriceDisplay,
  cancellationText,
}: {
  trialOfferPriceDisplay: string;
  cancellationText?: string;
}) {
  const { colors } = useEmotionTheme();
  return (
    <View style={{ marginTop: 40, textAlign: 'center' }}>
      <Large variant="largeDarkGrey">{cancellationText || 'Switch plans or cancel anytime.'}</Large>
      <Large variant="largeBoldWide" style={{ color: colors.limaGreen }}>
        Limited time: Start with a {trialOfferPriceDisplay} trial week!
      </Large>
    </View>
  );
}

function SubHeader({
  isSubscriptionOffer,
  trialOfferPriceDisplay,
  removeMargin,
  isNoMatches = false,
  isExperimentalPlanPricing = false,
}: {
  isSubscriptionOffer: boolean;
  trialOfferPriceDisplay?: string;
  removeMargin?: boolean;
  isNoMatches?: boolean;
  isExperimentalPlanPricing?: boolean;
}) {
  const { isMobile } = useWindowWidthState();
  let message = '';

  if (trialOfferPriceDisplay) {
    return (
      <TrialMessage
        cancellationText={isNoMatches ? NO_MATCHES_YOU_WONT_BE_CHARGED : undefined}
        trialOfferPriceDisplay={trialOfferPriceDisplay}
      />
    );
  }

  if (isExperimentalPlanPricing) {
    message = GET_PERSONALIZED_SUPPORT_FROM_LICENSED_THERAPIST;
  } else if (isNoMatches) {
    message = NO_MATCHES_YOU_WONT_BE_CHARGED;
  } else if (isSubscriptionOffer) {
    message = 'Switch plans or cancel anytime after subscribing.';
  }

  if (!message) return null;

  return (
    <View
      alignSelf="stretch"
      align="center"
      style={
        isExperimentalPlanPricing
          ? { width: 327, marginLeft: 10, marginRight: 10 }
          : { maxWidth: isMobile ? 334 : '100%' }
      }
    >
      <Large
        as="h2"
        variant="largeDarkGrey"
        style={{
          marginTop: removeMargin ? undefined : 13,
          width: isExperimentalPlanPricing ? 327 : '100%',
        }}
      >
        {message}
      </Large>
    </View>
  );
}

const Container = styled(View)<{ useMargin?: boolean }>(({ useMargin = false }) => {
  if (useMargin)
    return {
      marginLeft: 10,
      marginRight: 10,
    };
  return {
    paddingLeft: 10,
    paddingRight: 10,
    '&:hover .plan-wrapper .plan-card': {
      boxShadow: 'none !important',
      transition: 'all 200ms ease-in-out',
    },
    '& .plan-wrapper:hover .plan-card': {
      boxShadow: 'rgb(35 60 81 / 25%) 0px 5px 18px !important',
    },
  };
});

const Offer: FunctionComponent<OfferProps> = ({
  plansMatrix,
  onPlanSelected,
  currentPlan,
  titleId,
  trialOfferPrice,
  trialOfferPriceDisplay,
  style = {},
}) => {
  const { isNoMatches, isChangePlan, plansMatrixDisplayReady, flowID } = useRoomOffersState();

  const { experimentalPlanPricing } = useFlags();

  // Experimental plan pricing is available only for Online Therapy (Flow 66) and Teens Therapy (Flow 77).
  const isExperimentalPlanPricing = checkForExperimentalPlanPricing(
    flowID,
    !!experimentalPlanPricing
  );

  let mainTitle: string;
  if (isExperimentalPlanPricing) {
    mainTitle = 'Choose a therapy plan that works for you';
  } else if (isChangePlan) {
    mainTitle = 'Change your plan';
  } else {
    mainTitle = 'Best plan for you';
  }

  return (
    <Container align="center" justify="center" style={style}>
      <ExtraHuge
        id={titleId}
        style={isExperimentalPlanPricing ? { width: 327 } : { alignSelf: 'stretch' }}
        as="h1"
      >
        {mainTitle}
      </ExtraHuge>
      {!isChangePlan && (
        <SubHeader
          isSubscriptionOffer={plansMatrix[0][0].billingPrice.unit !== 'one-time'}
          trialOfferPriceDisplay={trialOfferPriceDisplay}
          isNoMatches={isNoMatches}
          isExperimentalPlanPricing={isExperimentalPlanPricing}
        />
      )}
      <PlanList
        currentPlanID={currentPlan ? currentPlan.id : undefined}
        isTrial={trialOfferPrice !== undefined}
        onPlanSelected={onPlanSelected}
        plansMatrix={plansMatrix}
        plansMatrixDisplayReady={plansMatrixDisplayReady}
        style={{ marginTop: 30 }}
      />

      <ComplianceBadges style={{ alignSelf: 'center', marginTop: 13, marginBottom: 26 }} />

      {isExperimentalPlanPricing && (
        <Large
          as="h2"
          variant="largeDarkGrey"
          style={{
            marginTop: 5,
            marginBottom: 20,
            width: 327,
          }}
        >
          We value your satisfaction. Switch providers or cancel your subscription at any time.
        </Large>
      )}
    </Container>
  );
};

export default Offer;
