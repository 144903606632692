import { useEffect, useRef } from 'react';

import {
  Button,
  ChevronLeft,
  Divider,
  Grid,
  Row,
  Col,
  ResponsiveLayoutWithHeader,
  TextArea,
  TextDS,
  TouchableView,
  View,
  useWindowWidthState,
  SkeletonLoader,
  StickyDrawer,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import UserTag from './UserTag';
import WeightedSentence from './WeightedSentence';

import { DailyPrompt } from '../types';
import useRotateOptionsArray from '../hooks/useRotateOptionsArray';
import { formatPlaceholder } from '../utils/helpers';
import { POST_MAX_LENGTH } from '../constants';

interface Props {
  communityUser: Amity.User | undefined;
  dailyPrompt: DailyPrompt | undefined;
  responseText: string;
  onChangeResponseText: (value: string) => void;
  onPressBack: () => void;
  onPressPost: () => void;
  isProcessing: boolean;
  isLoadingDailyPrompt: boolean;
}
const CommunityCreatePost = ({
  communityUser,
  dailyPrompt,
  responseText,
  onChangeResponseText,
  onPressBack,
  onPressPost,
  isProcessing,
  isLoadingDailyPrompt,
}: Props) => {
  const { isMobile } = useWindowWidthState();
  const { text: dailyPromptText, placeholders } = dailyPrompt || { text: '', placeholders: [''] };

  const placeholder = useRotateOptionsArray<string>({
    options: placeholders,
    intervalSeconds: 3,
  });
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { colorRoles, spacing } = useEmotionTheme();
  const columnProps = { lg: 5 };
  const canPost = !isProcessing && responseText.length > 0;

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, []);

  return (
    <ResponsiveLayoutWithHeader
      safeAreaVariant="addTop"
      headerHeight={58}
      renderHeader={() => (
        <View
          row
          align="center"
          justify="space-between"
          style={{
            width: '100%',
            padding: `${spacing('space150')}px ${spacing('space200')}px`,
          }}
        >
          <TouchableView onPress={onPressBack} dataQa="backButton">
            <ChevronLeft size="major" colorType="brand" />
          </TouchableView>
          <TextDS
            variant="headingMd"
            style={{ width: 'max-content', marginLeft: spacing('space500') }}
          >
            My post
          </TextDS>
          {isMobile ? (
            <Button
              variant="tertiary"
              sizeDS="slim"
              text="Post"
              dataQa="postButton"
              onPress={onPressPost}
              disabled={!canPost}
              style={{ width: 'fit-content' }}
            />
          ) : (
            // empty sibling to keep centering with space-between working
            <View style={{ width: spacing('space600') }} />
          )}
        </View>
      )}
      contentWrapperStyle={{ padding: `${spacing('space600')}px 0` }}
    >
      <Grid>
        <Row style={{ justifyContent: 'center' }}>
          <Col {...columnProps}>
            <View align="center">
              {isLoadingDailyPrompt ? (
                <View style={{ height: isMobile ? 96 : 64, width: isMobile ? 300 : 460 }}>
                  <SkeletonLoader variant="heading2xl" />
                  <SkeletonLoader variant="heading2xl" style={{ marginTop: spacing('space100') }} />
                  {isMobile && (
                    <SkeletonLoader
                      variant="heading2xl"
                      style={{ marginTop: spacing('space100') }}
                    />
                  )}
                </View>
              ) : (
                <WeightedSentence sentence={dailyPromptText} />
              )}
              <TextDS
                variant="bodySm"
                colorRole="textSubtlest"
                style={{ marginTop: spacing('space300') }}
              >
                Remember, your post will be anonymous
              </TextDS>
            </View>
            <Divider
              style={{ margin: `${spacing('space200')}px 0` }}
              color={colorRoles.borders.borderSubtleDefault}
            />
            <UserTag user={communityUser} style={{ padding: `${spacing('space100')}px 0` }} />
            <TextArea
              ref={textAreaRef}
              dataQa="shareYourThoughtsTextArea"
              maxLength={POST_MAX_LENGTH}
              value={responseText}
              onChangeText={onChangeResponseText}
              style={{
                width: '100%',
                marginTop: spacing('space150'),
                borderWidth: 0,
                outline: 'none',
              }}
              placeholder={formatPlaceholder(placeholder)}
            />
          </Col>
        </Row>
        {!isMobile && (
          <Row>
            <StickyDrawer noBorder>
              <Col {...columnProps} style={{ justifyContent: 'center' }}>
                <Button
                  text="Post"
                  dataQa="postButton"
                  onPress={onPressPost}
                  style={{ width: '100%', maxWidth: undefined }}
                  disabled={!canPost}
                />
              </Col>
            </StickyDrawer>
          </Row>
        )}
      </Grid>
    </ResponsiveLayoutWithHeader>
  );
};

export default CommunityCreatePost;
