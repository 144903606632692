import { WizardScreen, WizardScheme } from 'stepWizard';
import { ScreenProvider } from '@talkspace/react-toolkit';
import { Route, Switch } from '@/core/routerLib';
import CompleteSurvey from './containers/CompleteSurvey';
import surveyWizardScheme from './surveyWizardScheme';

const Routes = ({
  welcomeScreen,
  steps,
}: {
  steps: WizardScheme['steps'];
  welcomeScreen: WizardScheme['welcomeScreen'];
}) => (
  <Switch>
    <ScreenProvider>
      <Route path="/survey/complete" component={CompleteSurvey} />
      <Route
        exact
        path="/survey"
        render={() => (
          <WizardScreen wizardScheme={{ ...surveyWizardScheme, welcomeScreen, steps }} />
        )}
      />
    </ScreenProvider>
  </Switch>
);

export default Routes;
