import { useWindowWidthState } from '@talkspace/react-toolkit/src/hooks/windowWidthContext';

import { Screen } from '@talkspace/react-toolkit';
import PaymentDetailsContainer from '../containers/PaymentDetailsContainer';
import { useInsuranceEligibilityState } from '../hooks/InsuranceEligibilityContext';

const PaymentDetailsScreen = () => {
  const { isMobile } = useWindowWidthState();
  const { purchaseStatus } = useInsuranceEligibilityState();

  return (
    <Screen
      showBackButton={purchaseStatus !== 'loading'}
      showCloseButton={purchaseStatus !== 'loading'}
      title={isMobile ? ' ' : undefined}
    >
      <PaymentDetailsContainer />
    </Screen>
  );
};

export default PaymentDetailsScreen;
