import { FunctionComponent, useState, useEffect } from 'react';
import {
  View,
  Big,
  BaseButton,
  Text,
  Button,
  Avatar,
  Heading2,
  COLORS,
  useEmotionTheme,
  TextWithHighlightedMatches,
} from '@talkspace/react-toolkit';
import { ClientMatchPresentingProblem } from 'ts-frontend/types';
import { prepareDataByTimezone } from 'ts-frontend/helpers';
import { TherapistType } from 'ts-frontend/entities/Therapist';
import uniq from 'lodash/uniq';
import useInPlatformMatching from '@/clientChat/hooks/useInPlatformMatching';
import TherapistAvailability from '@/clientChat/components/TherapistAvailability';
import styled, { EmotionStyle } from '../../../core/styled';
import MiniNotepad from '../../../assets/icons/MiniNotepad';
import MiniSuitcase from '../../../assets/icons/MiniSuitcase';
import Heart from '../../../assets/icons/Heart';
import Earth from '../../../assets/icons/Earth';
import RoundedTriangle from '../../../assets/icons/RoundedTriangle';
import useScreenSize, { screenSizes } from '../../../core/hooks/useScreenSize';
import ProfessionalInfo from '../ProfessionalInfo';
import { RouteComponentProps, withRouter } from '../../../core/routerLib/routerLib';
import { MatchResultDetails } from '../../matchingTypes';
import { trackEvent } from '../../../utils/analytics/eventTracker';
import getOfferUrl from '../../utils/getOfferUrl';

enum TabType {
  PROFILE = 'profile',
  AVAILABILITY = 'availability',
}

const Wrapper = styled(View)<{ screenSize: screenSizes }>(
  ({ screenSize }) =>
    new Map([
      [
        [screenSizes.EXTRA_SMALL, screenSizes.SMALL].includes(screenSize),
        {
          flexDirection: 'column',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: COLORS.extraLightGrey,
          borderRadius: 9,
          paddingBottom: 20,
          alignItems: 'center',
        },
      ],
    ]).get(true) || {}
);

const Left = styled(View)({
  paddingTop: 25,
  paddingBottom: 25,
  flex: 2,
});
const Right = styled(View)({
  marginLeft: 25,
  marginRight: 25,
  flex: 6,
  overflow: 'hidden',
});

const SelectButton = styled(Button)(({ theme: { colors } }) => {
  return {
    backgroundColor: colors.permaTalkspaceDarkGreen,
    width: '95%',
  };
});

const InfoCard = styled(View)<{ screenSize: screenSizes }>(
  {
    height: 'auto',
  },
  ({ screenSize }) =>
    new Map([
      [
        [screenSizes.MEDIUM, screenSizes.LARGE].includes(screenSize),
        {
          paddingLeft: 25,
          paddingRight: 25,
          borderColor: '#E7E7E7',
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: 9,
        },
      ],
    ]).get(true) || {}
);
const InfoContent = styled(View)<{ screenSize: screenSizes }>(
  {
    paddingBottom: 25,
  },
  ({ screenSize }) =>
    new Map([
      [
        [screenSizes.EXTRA_SMALL, screenSizes.SMALL].includes(screenSize),
        {
          flexDirection: 'column',
        },
      ],
    ]).get(true) || {}
);
const InfoLeft = styled(View)<{ screenSize: screenSizes }>(
  {
    wordBreak: 'break-word',
    alignItems: 'center',
  },
  ({ screenSize }) =>
    new Map([
      [
        [screenSizes.MEDIUM, screenSizes.LARGE].includes(screenSize),
        {
          flex: 5,
          alignItems: 'start',
        },
      ],
    ]).get(true) || {}
);
const InfoRight = styled(View)<{ screenSize: screenSizes }>(
  {
    marginTop: 5,
  },
  ({ screenSize }) =>
    new Map([
      [
        [screenSizes.MEDIUM, screenSizes.LARGE].includes(screenSize),
        {
          marginLeft: 15,
          marginRight: 15,
          flex: 3,
        },
      ],
    ]).get(true) || {}
);

const Name = styled(Heading2)({});
const License = styled(Text)(({ theme: { colors } }) => {
  return { color: colors.placeholderGrey, fontSize: 15, marginTop: 5 };
});

const TherapistAvatarContainer = styled(View)({
  position: 'relative',
  alignItems: 'center',
});

const PlayVideoController = styled(View)(({ theme: { colors } }) => {
  return {
    backgroundColor: colors.white,
    boxShadow: '4px 4px 10px 0 rgba(0,0,0,0.06)',
    borderRadius: '50%',
    width: 50,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 15,
    right: 10,
  };
});

const BioExcerpt = styled(Text)({
  textAlign: 'left',
  WebkitLineClamp: 8,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  overflowWrap: 'break-word',
  display: '-webkit-box',
  maxHeight: '12.8565em',
  WebkitBoxOrient: 'vertical',
});

const TabButton = styled(BaseButton)<{ isSecondary?: boolean }>(
  ({ isSecondary, theme: { window, colors } }) => {
    const responsiveStyles = {
      borderWidth: 1,
      borderColor: colors.extraLightGrey,
      borderStyle: 'solid',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      padding: 12,
      marginLeft: isSecondary ? 5 : 0,
    };
    return {
      borderRadius: 44,
      marginLeft: isSecondary ? 25 : 0,
      ...(window.isMobile && responsiveStyles),
    };
  }
);

const TabButtonText = styled(Big)<{ isActive: boolean }>(({ theme: { colors }, isActive }) => {
  return {
    color: isActive ? colors.black : colors.ironGrey,
  };
});

const TabsWrapper = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;
  return {
    marginBottom: isMobile ? 22 : 15,
    marginTop: isMobile ? 13 : 25,
    marginRight: 11,
  };
});

const MatchResultCard: FunctionComponent<
  MatchResultDetails & {
    roomID: number;
    roomType: string;
    style: EmotionStyle;
    skipPayment: boolean;
    selectedPresentingProblemsAsExpertise: ClientMatchPresentingProblem[];
    numberOfMatches?: number;
    handleSwitchWizardSelectedTherapist?: (
      therapistID: number,
      therapistFirstName: string,
      therapistImage: string | undefined,
      therapistType: TherapistType
    ) => void;
  } & RouteComponentProps
> = ({
  id,
  firstName,
  lastName,
  licenses,
  bio,
  image,
  yearsExperience,
  yearsWithTalkspace,
  allFieldsOfExpertise,
  expertise,
  additionalLanguages,
  roomID,
  roomType,
  style,
  history,
  skipPayment,
  businessHours,
  businessDays,
  timezone,
  therapistType,
  selectedPresentingProblemsAsExpertise,
  numberOfMatches = 0,
  handleSwitchWizardSelectedTherapist,
}) => {
  const screenSize = useScreenSize();
  const [joinedTalkspace, setJoinedTalkspace] = useState<string>('');
  const [yearsInPractice, setYearsInPractice] = useState<string>('');
  const [isFullDescription, setIsFullDescription] = useState<boolean>(false);
  const [tabSelection, setTabSelection] = useState<TabType>(TabType.PROFILE);

  const [{ showAvailability }] = useInPlatformMatching();

  useEffect(() => {
    if (yearsWithTalkspace) {
      setJoinedTalkspace(
        new Map([
          [yearsWithTalkspace < 1, 'A couple of months ago'],
          [yearsWithTalkspace === 1, '1 year ago'],
          [yearsWithTalkspace > 1, `${yearsWithTalkspace} years ago`],
        ]).get(true) || ''
      );
    }
  }, [yearsWithTalkspace]);

  useEffect(() => {
    setYearsInPractice(
      new Map([
        [yearsExperience < 1, 'A couple of months in practice'],
        [yearsExperience === 1, '1 year in practice'],
        [yearsExperience > 1, `${yearsExperience} years in practice`],
      ]).get(true) || ''
    );
  }, [yearsExperience]);

  function handleTherapistSelected() {
    history.push({
      pathname: '/room-reactivation/match-confirm',
      state: {
        firstName,
        lastName,
        numberOfMatches,
        offerUrl: getOfferUrl({ roomID, roomType }),
        roomID,
        skipPayment,
        therapistID: id,
        therapistLicenses: licenses.map((license) => license.type),
        therapistImage: image,
        therapistType,
      },
    });

    trackEvent('Select a Therapist', {
      eventCategory: 'Reactivation',
      eventCategoryKey: 'Funnel Name',
      eventProperty: 'Therapist ID',
      eventPropertyValue: id,
    });
  }
  const { colors } = useEmotionTheme();

  const renderContent = () => {
    if (tabSelection === TabType.PROFILE) {
      return (
        <>
          <InfoContent row screenSize={screenSize}>
            <InfoLeft screenSize={screenSize}>
              <Name>{`${firstName} ${lastName}`}</Name>
              <License>{uniq(licenses.map((license) => license.type)).join(', ')}</License>
              <View
                style={{
                  marginTop: 10,
                }}
              >
                {isFullDescription ? <Text>{bio}</Text> : <BioExcerpt>{bio}</BioExcerpt>}
              </View>
            </InfoLeft>
            <InfoRight screenSize={screenSize}>
              <ProfessionalInfo icon={<MiniNotepad />} info={yearsInPractice} />

              <ProfessionalInfo
                icon={<MiniSuitcase />}
                title="Joined Talkspace"
                info={joinedTalkspace}
              />

              <ProfessionalInfo icon={<Heart />} title="Focus">
                <TextWithHighlightedMatches
                  highlightTargets={selectedPresentingProblemsAsExpertise}
                  text={isFullDescription ? allFieldsOfExpertise : expertise.mostRelevant}
                  targetKey="expertiseName"
                />
              </ProfessionalInfo>

              {!!additionalLanguages.length && (
                <ProfessionalInfo
                  icon={<Earth />}
                  title="Additional languages"
                  info={additionalLanguages.join(', ')}
                />
              )}
            </InfoRight>
          </InfoContent>
          <View justify="end" align="end">
            <Button
              style={{
                textDecoration: 'none',
                fontWeight: 700,
                color: colors.permaTalkspaceDarkGreen,
                fontSize: '12px',
                backgroundColor: 'white',
                border: 0,
                width: 'auto',
                padding: 0,
                height: 'auto',
                marginBottom: 15,
              }}
              onPress={() => setIsFullDescription(!isFullDescription)}
              dataQa="matchResultCardShowMoreDescription"
            >
              {isFullDescription ? 'Show Less' : 'Show More'}
            </Button>
          </View>
        </>
      );
    }
    if (tabSelection === TabType.AVAILABILITY && businessHours) {
      return (
        <View style={{ paddingBottom: 15 }}>
          <TherapistAvailability
            implicitBusinessHoursByDay={prepareDataByTimezone(businessHours)}
            businessDays={businessDays}
            therapistTimezone={timezone}
          />
        </View>
      );
    }
    return null;
  };

  return (
    <Wrapper row style={style} screenSize={screenSize}>
      <Left justify="start" align="center">
        <TherapistAvatarContainer>
          <Avatar
            image={image}
            width={screenSize === screenSizes.SMALL ? 250 : 180}
            height={screenSize === screenSizes.SMALL ? 250 : 180}
            style={{
              marginBottom: [screenSizes.EXTRA_SMALL, screenSizes.SMALL].includes(screenSize)
                ? 0
                : 15,
            }}
          />
          {/* TODO the original product requirement included Therapist intro video */}
          {false && (
            <PlayVideoController>
              <RoundedTriangle />
            </PlayVideoController>
          )}
        </TherapistAvatarContainer>
        {[screenSizes.MEDIUM, screenSizes.LARGE].includes(screenSize) && (
          <SelectButton
            text="Select"
            isSecondary
            onPress={
              handleSwitchWizardSelectedTherapist
                ? () => handleSwitchWizardSelectedTherapist(id, firstName, image, therapistType)
                : handleTherapistSelected
            }
          />
        )}
      </Left>
      <Right>
        <InfoCard screenSize={screenSize}>
          <TabsWrapper row>
            <TabButton onPress={() => setTabSelection(TabType.PROFILE)}>
              <TabButtonText variant="bigMedium" isActive={tabSelection === TabType.PROFILE}>
                Profile
              </TabButtonText>
            </TabButton>
            {showAvailability && (
              <TabButton onPress={() => setTabSelection(TabType.AVAILABILITY)} isSecondary>
                <TabButtonText variant="bigMedium" isActive={tabSelection === TabType.AVAILABILITY}>
                  Availability
                </TabButtonText>
              </TabButton>
            )}
          </TabsWrapper>
          {renderContent()}
        </InfoCard>
      </Right>
      {[screenSizes.EXTRA_SMALL, screenSizes.SMALL].includes(screenSize) && (
        <SelectButton
          text="Select"
          isSecondary
          onPress={
            handleSwitchWizardSelectedTherapist
              ? () => handleSwitchWizardSelectedTherapist(id, firstName, image, therapistType)
              : handleTherapistSelected
          }
        />
      )}
    </Wrapper>
  );
};

export default withRouter(MatchResultCard);
