import { TextDS, View, useEmotionTheme, styled } from '@talkspace/react-toolkit';
import TeenspaceGroupChat from '../../assets/illustrations/TeenspaceGroupChat';
import useOpenModalSafe from '../../../hooks/useOpenModalSafe';
import { trackCommunityOnboardingCTAPressed } from '../../utils/analytics';
import TeenspaceContinueButton from './TeenspaceContinueButton';

const TitleText = styled(TextDS)(({ theme: { spacing } }) => {
  return {
    marginTop: spacing('space400'),
    marginBottom: spacing('space150'),
  };
});

const IllustrationView = styled(View)(({ theme: { spacing } }) => {
  return {
    marginTop: spacing('space300'),
  };
});

const Wrapper = styled(View)(() => {
  return {
    textAlign: 'center',
    height: '100%',
  };
});

const TeenspaceWelcomeScreen = () => {
  const { colorRoles } = useEmotionTheme();
  const openModal = useOpenModalSafe();

  const onPress = () => {
    trackCommunityOnboardingCTAPressed({ pageName: 'teenspaceWelcome' });
    openModal('/community/teenspace/onboarding/sign-up');
  };
  return (
    <Wrapper align="center" justify="space-between">
      <View align="center">
        <TitleText variant="heading2xl">You belong here</TitleText>
        <TextDS variant="body" style={{ color: colorRoles.typography.textSubtle }}>
          Join a safe community for teens, by teens, and express yourself by answering a new
          question every day!
        </TextDS>
        <IllustrationView>
          <TeenspaceGroupChat />
        </IllustrationView>
      </View>
      <TeenspaceContinueButton
        stretch
        size="large"
        text="Continue"
        onPress={onPress}
        dataQa="teenspaceWelcomeScreenContinueButton"
      />
    </Wrapper>
  );
};

export default TeenspaceWelcomeScreen;
