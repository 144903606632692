import Svg, { Defs, Circle, G, Path, Use, Ellipse } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const NoVideoCreditsIcon = ({ width = 96, height = 81, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} {...otherProps}>
      <Defs>
        <Circle id="b" cx={13} cy={13} r={13} />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M55.762 0a5.16 5.16 0 015.16 5.16v6.033c0 .082-.002.163-.005.243C66.875 13.121 71.242 18.6 71.242 25.1v41.415c0 7.84-6.356 14.197-14.197 14.197H14.197C6.357 80.71 0 74.355 0 66.514V25.099C0 18.6 4.367 13.121 10.327 11.436a4.23 4.23 0 01-.007-.243V5.16a5.16 5.16 0 1110.32 0v5.742h29.962V5.16A5.16 5.16 0 0155.762 0z"
          transform="translate(-565 -300) translate(350 230) translate(60 70) translate(155)"
          fill={colors.purple}
        />
        <Path
          d="M36.766 33.136a4.6 4.6 0 014.6 4.601v16.571a4.6 4.6 0 01-4.6 4.601H19.72a4.6 4.6 0 01-4.601-4.6V37.736a4.6 4.6 0 014.6-4.6h17.046zm17.72 4.196c.153.275.234.584.234.9v14.635a1.84 1.84 0 01-2.74 1.606l-7.83-4.386a1.84 1.84 0 01-.942-1.606v-5.864c0-.666.36-1.28.941-1.606l7.831-4.385a1.84 1.84 0 012.505.706z"
          fill={colors.white}
          transform="translate(-565 -300) translate(350 230) translate(60 70) translate(155)"
        />
        <G>
          <G transform="translate(-565 -300) translate(350 230) translate(60 70) translate(155) translate(58 8)">
            <Use fill={colors.black} href="#b" />
            <Circle
              stroke={colors.white}
              strokeWidth={3}
              fill={colors.periwinkleGrey}
              cx={13}
              cy={13}
              r={14.5}
            />
          </G>
          <G
            transform="translate(-565 -300) translate(350 230) translate(60 70) translate(155) translate(58 8) translate(10.5 5.5)"
            fill={colors.white}
          >
            <Path d="M0.9375 0H4.0625V9.84615385H0.9375z" />
            <Ellipse
              transform="rotate(.5 2.5 13.555)"
              cx={2.5}
              cy={13.5545018}
              rx={1.8749978}
              ry={1.84615604}
            />
          </G>
        </G>
      </G>
    </Svg>
  );
};

export default NoVideoCreditsIcon;
