import React from 'react';

import { CircledCheckMark, View, useEmotionTheme, EmotionStyle } from '@talkspace/react-toolkit';

const Step = ({ isCompleted, isCurrent, isLast }) => {
  const { colors } = useEmotionTheme();
  let backgroundColor = colors.permaSilverSand;
  if (isCompleted) backgroundColor = colors.a11yVidaLocaGreen;
  if (isCurrent) backgroundColor = colors.blueStone;
  let marginRight;
  if (!isLast) marginRight = 5;
  if (!isLast && isCompleted) marginRight = -15;

  return (
    <View
      row
      align="center"
      style={{
        marginRight,
      }}
    >
      <span
        style={{
          height: 5,
          width: 40,
          borderRadius: 4.5,
          backgroundColor,
        }}
      />
      {isCompleted && (
        <div
          style={{
            position: 'relative',
            right: 20,
            backgroundColor: colors.a11yVidaLocaGreen,
            height: 20,
            width: 20,
            borderRadius: '50%',
          }}
        >
          <CircledCheckMark circleFillColor={colors.a11yVidaLocaGreen} />
        </div>
      )}
    </View>
  );
};

interface Props {
  totalSteps: number;
  currentStep: number;
  wrapperStyle?: EmotionStyle;
}
const ProgressBar = ({ totalSteps, currentStep, wrapperStyle }: Props) => {
  if (totalSteps === 1) return null; // no progress bar when one step flow
  return (
    <View row style={wrapperStyle}>
      {[...Array(totalSteps).keys()].map((i) => (
        <Step
          key={`step-${i}`}
          isCurrent={i === currentStep}
          isCompleted={i < currentStep}
          isLast={i === totalSteps}
        />
      ))}
    </View>
  );
};

export default ProgressBar;
