import { WizardScheme } from 'stepWizard';

const cancellationWizardScheme: WizardScheme = {
  wizardVersion: 1,
  wizardType: 'cancellation',
  wizardModalTitle: 'cancel plan',
  steps: [
    {
      title: 'How has your experience with your current Talkspace provider been?',
      subtitle:
        "Consider only your provider's performance in providing care, not any app or site issues you may have encountered. This feedback will be anonymously shared with your provider.",
      inputType: 'therapistReview',
      inputState: 'therapistReview',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'autoAdvance',
      },
      secondaryButton: {
        displayText: 'I have not interacted enough with this provider to rate them',
        defaultValue: 'skip-have-not-interacted-enough',
        actionDispatch: `next`,
        whenVisible: 'always',
        displayStyle: 'secondaryFooter',
      },
      name: 'Therapist Review',
      additionalInfo: ['therapistInfo'],
    },
    {
      title: 'Will you give us a chance to improve your current Talkspace experience?',
      subtitle:
        "We'd like to offer you a <!couponDiscountDisplay> discount toward your next payment.",
      inputState: 'couponApplied',
      nextButton: {
        displayText: 'Yes, apply <!couponDiscountDisplay> discount',
        actionDispatch: '/cancel-subscription/apply-discount',
        whenVisible: 'always',
      },
      secondaryButton: {
        displayText: "No thanks, please don't renew my subscription",
        actionDispatch: 'next',
        whenVisible: 'always',
      },
      displayCondition: 'displayDiscountScreen',
      name: 'Apply discount',
      backOption: 'back',
      additionalInfo: ['couponDiscountDisplay'],
    },
    {
      title: 'Instead of cancelling, would you like to pause your subscription for up to 30 days?',
      subtitle:
        "By pausing, you'll be able to continue working with the same provider when you return.",
      inputState: 'subscriptionPaused',
      nextButton: {
        displayText: 'Yes, pause my subscription',
        actionDispatch: '/cancel-subscription/pause-therapy',
        whenVisible: 'always',
      },
      secondaryButton: {
        displayText: "No, I'm not interested in pausing my subscription",
        actionDispatch: 'next',
        whenVisible: 'always',
      },
      name: 'Pause subscription',
      backOption: 'back',
    },
    {
      title: 'Would you be open to trying a new provider?',
      subtitle:
        'We have over 3,000 licensed therapists in our network and are confident we can find you a better match.',
      inputState: 'providerSwitched',
      nextButton: {
        displayText: 'Yes, work with a new provider',
        actionDispatch:
          '/room/:roomID/my-account/manage-subscription?action=cancellation-switch-exit&source=:source&contextID=:contextID',
        whenVisible: 'always',
        source: 'cancellation',
        fullReload: true,
        persistOnDispatch: true,
      },
      secondaryButton: {
        displayText: "No, I'm not interested in a new provider",
        actionDispatch: 'next',
        whenVisible: 'always',
      },
      name: 'Switch provider',
      backOption: 'back',
    },
    {
      title: 'Would you like to switch to the Talkspace Maintenance plan for <!planPriceDisplay>?',
      subtitle:
        'You may not always need to contact your provider everyday. On this plan, your provider responds 1 day per week to help you stay on track.',
      inputState: 'subscribedToMaintenance',
      nextButton: {
        displayText: 'Switch to a maintenance plan',
        actionDispatch: '/cancel-subscription/switch-to-maintenance-plan',
        whenVisible: 'always',
      },
      secondaryButton: {
        displayText: "I'm not interested in this plan",
        actionDispatch: 'next',
        whenVisible: 'always',
      },
      displayCondition: 'shouldDisplayMaintenanceOffer',
      name: 'Switch to maintenance plan',
      backOption: 'back',
      additionalInfo: ['planPriceDisplay'],
    },
    {
      title: 'Please tell us why you are ending your subscription today.',
      inputType: 'select',
      inputState: 'cancellationReason',
      inputOptions: 'reasonForCancellation',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      name: 'Reason for cancelling',
      backOption: 'back',
      postAction: 'setShowFinancialAidScreen',
    },
    {
      title: 'You may qualify for financial aid. Would you like to check your eligibility?',
      subtitle:
        'If you are facing financial challenges, you may be eligible for discounted counseling costs.',
      inputState: '',
      nextButton: {
        displayText: 'Yes, apply financial aid',
        actionDispatch: '/financial-aid/room/<!roomID>/source/cancellation/context-id/<!contextID>',
        whenVisible: 'always',
        persistOnDispatch: true,
      },
      secondaryButton: {
        displayText: `I'm not interested in financial aid`,
        actionDispatch: 'next',
        whenVisible: 'always',
      },
      displayCondition: 'showFinancialAidScreen',
      name: 'Switch to financial aid',
      backOption: 'back',
      additionalInfo: ['roomID', 'contextID'],
    },
    {
      title: `We're sorry to hear that you are canceling your subscription.`,
      subtitle: `If there is anything we can do to improve your experience, please reach out to our customer support team.`,
      inputState: '',
      nextButton: {
        displayText: 'Cancel subscription renewal',
        actionDispatch: '/cancel-subscription/cancel',
        whenVisible: 'always',
      },
      name: 'Sure you want to cancel',
      backOption: 'back',
    },
  ],
};

export default cancellationWizardScheme;
