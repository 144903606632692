import { FunctionComponent, useEffect } from 'react';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { useHistory } from 'react-router';
import {
  ResponsiveLayoutWithHeader,
  PanelHeader,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { useTranslation } from '@talkspace/i18n';
import { TFNewMemberNav } from '@talkspace/i18n/types';
import { NAV_SHELL_HEADER_HEIGHT } from 'ts-frontend/constants';

import useNotificationPreferences from '../hooks/useNotificationPreferences';
import Notifications from '../components/Notifications';
import MobileHeader from '../../components/MobileLeftHeader';
import MyAccountPanelWrapper from '../components/MyAccountPanelWrapper/MyAccountPanelWrapper';
import ReactFrameService from '../../auth/reactFrame/ReactFrameService';

const getBreadcrumbRoutes = (tnewMemberNav: TFNewMemberNav) => [
  {
    label: tnewMemberNav('accountSettings.account', 'Account'),
    path: '/home/account',
  },
  {
    label: tnewMemberNav('notifications.notifications', 'Notifications'),
    path: '',
  },
];

interface Props {
  onCloseButtonPress: () => void;
  onBackButtonPress: () => void;
}

const NotificationsContainer: FunctionComponent<Props> = ({
  onCloseButtonPress,
  onBackButtonPress,
}) => {
  const [state, { getNotificationPreferences, patchNotificationPreferences }] =
    useNotificationPreferences();

  const isInFrame = ReactFrameService.instance().isInFrame();
  const isiOS = ReactFrameService.isiOS();

  const { isMobile } = useWindowWidthState();

  const useNewNav = useNewMemberNav();

  const history = useHistory();
  const isHomeNav = useNewNav && history.location.pathname.includes('home');

  useEffect(() => {
    getNotificationPreferences();
  }, [getNotificationPreferences]);

  const newNavHeaderHeight = isMobile ? 0 : NAV_SHELL_HEADER_HEIGHT;
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  const breadcrumbRoutes = getBreadcrumbRoutes(tnewMemberNav);

  if (isHomeNav)
    return (
      <ResponsiveLayoutWithHeader
        outsideHeaderHeight={useNewNav ? newNavHeaderHeight : undefined}
        headerHeight={!isMobile ? 0 : undefined}
        renderHeader={() => (
          <PanelHeader
            isNewNav={useNewNav}
            renderLeft={() => (
              <MobileHeader
                centerTitle={isInFrame && isiOS}
                title=""
                onBackButtonPress={() => history.goBack()}
              />
            )}
            onRightPress={isInFrame ? undefined : onCloseButtonPress}
          />
        )}
        noHeaderLine
      >
        <MyAccountPanelWrapper
          alwaysRow={isInFrame}
          title={tnewMemberNav('notifications.notifications', 'Notifications')}
          breadcrumbRoutes={breadcrumbRoutes}
          inNavShell={isHomeNav}
        >
          {state.notificationPreferencesByName && (
            <Notifications
              notificationPreferencesByName={state.notificationPreferencesByName}
              isError={state.isError}
              patchNotificationPreferences={patchNotificationPreferences}
            />
          )}
        </MyAccountPanelWrapper>
      </ResponsiveLayoutWithHeader>
    );

  return (
    <ResponsiveLayoutWithHeader
      renderHeader={() => (
        <PanelHeader
          isNewNav={useNewNav}
          renderLeft={() => (
            <MobileHeader
              centerTitle={isInFrame && isiOS}
              title={tnewMemberNav('notifications.myAccount', 'My Account')}
              mobileTitle={tnewMemberNav('notifications.notifications', 'Notifications')}
              onBackButtonPress={onBackButtonPress}
            />
          )}
          onRightPress={isInFrame ? undefined : onCloseButtonPress}
        />
      )}
    >
      <MyAccountPanelWrapper
        alwaysRow={isInFrame}
        title={tnewMemberNav('notifications.notifications', 'Notifications')}
      >
        {state.notificationPreferencesByName && (
          <Notifications
            notificationPreferencesByName={state.notificationPreferencesByName}
            isError={state.isError}
            patchNotificationPreferences={patchNotificationPreferences}
          />
        )}
      </MyAccountPanelWrapper>
    </ResponsiveLayoutWithHeader>
  );
};

export default NotificationsContainer;
