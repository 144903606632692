import { useWizardState, WizardScheme } from 'stepWizard';
import { AssessmentHead, View } from '@talkspace/react-toolkit';
import { Survey } from 'ts-frontend/types';
import { SurveyWizardData } from './types';
import WelcomeScreen from './components/WelcomeScreen';
import WelcomeScreenCitation from './components/WelcomeScreenCitation';
import { State } from './reducers/surveyWizardState';

const surveyWizardScheme: WizardScheme = {
  wizardVersion: 1,
  wizardType: 'surveyWizard',
  wizardModalTitle: 'survey',
  steps: [],
};

export function extractSurveyData(rawSurveyData: Survey): SurveyWizardData {
  return {
    surveyCitation: rawSurveyData.citation,
    surveySections: rawSurveyData.sections,
  };
}

const WelcomeScreenContainer = ({
  prompt,
  citation,
  onClick,
}: {
  prompt: string;
  citation: string;
  onClick: () => void;
}) => {
  const { onSurveyProgress } = useWizardState<State>();

  return (
    <WelcomeScreen
      handleStartButton={() => {
        if (onSurveyProgress) onSurveyProgress(prompt, undefined, 0, false);
        onClick();
      }}
      prompt={prompt}
      Citation={() => <WelcomeScreenCitation citation={citation} />}
    />
  );
};

export function getWelcomeScreenAndSteps(rawSurveyData: Survey): {
  welcomeScreen: WizardScheme['welcomeScreen'];
  steps: WizardScheme['steps'];
} {
  let welcomeScreen: WizardScheme['welcomeScreen'];
  const finalSteps: WizardScheme['steps'] = [];
  rawSurveyData.sections.forEach((section, index, sections) => {
    const nextQuestion = sections[index + 1];
    const isLastQuestion = !nextQuestion;

    if (section.sectionType === 'INSTRUCTIONS' && !welcomeScreen) {
      welcomeScreen = (onClick) => (
        <WelcomeScreenContainer
          onClick={onClick}
          prompt={section.prompt}
          citation={rawSurveyData.citation}
        />
      );
    } else if (section.sectionType === 'INSTRUCTIONS') {
      finalSteps.push({
        inputState: '',
        backOption: 'back',
        hideProgressIndicator: true,
        IconComponent: () => (
          // There's too much space between the top of the screen and the step title,
          // reduce it with negative margin
          <View style={{ marginTop: -50 }}>
            <AssessmentHead width={118} height={118} />
          </View>
        ),
        // Combined surveys have the completed survey prompt twice in a single survey
        name: section.prompt + index,
        title: section.prompt,
        postAction: 'instructionsButtonPostAction',
        indexInputByCounter: true,
        inputRestoredAnswer: 'surveyRestoredAnswers',
        nextButton: {
          displayText: isLastQuestion ? 'Submit assessment' : 'Continue',
          actionDispatch:
            // If this is the last section, finish the wizard with the loading screen
            isLastQuestion ? '/survey/complete' : 'next',
          actionDispatchWithLocationState: true,
          whenVisible: 'always',
        },
      });
    } else if (section.sectionType === 'MULTIPLE_CHOICE') {
      finalSteps.push({
        inputType: 'question',
        backOption: 'back',
        indexInputByCounter: true,
        inputOptions: 'surveyQuestions',
        inputRestoredAnswer: 'surveyRestoredAnswers',
        postAction: 'submitSurveyAnswerPostAction',
        inputState: `${'surveyResponse_'}${section.prompt}`,
        title: section.prompt,
        name: section.prompt,
        nextButton: {
          displayText: 'Next',
          actionDispatch: 'next',
          whenVisible: 'autoAdvance',
          autoAdvanceDelay: 150,
        },
      });
    }
  });
  return {
    welcomeScreen,
    steps: finalSteps,
  };
}

export default surveyWizardScheme;
