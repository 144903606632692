import { useCallback } from 'react';
import DTERenewalNYCTeen from '../components/DTERenewalNYCTeen';
import useMutationRenewVoucher from '../hooks/useMutationRenewVoucher';
import { useCloseModal } from '../../utils/ModalsContextProvider';
import { useParams } from '@/core/routerLib';

const DTERenewalNYCTeenContainer = () => {
  const { roomID } = useParams<{ roomID: string }>();
  const closeModal = useCloseModal();

  const { mutate: renewVoucher, isLoading } = useMutationRenewVoucher();

  const onPressCTA = useCallback(() => {
    renewVoucher(
      {
        roomID: Number(roomID),
        shouldRenew: true,
      },
      {
        onSuccess: () => {
          closeModal();
        },
      }
    );
  }, [roomID, closeModal, renewVoucher]);

  return <DTERenewalNYCTeen onPressCTA={onPressCTA} isLoading={isLoading} />;
};

export default DTERenewalNYCTeenContainer;
