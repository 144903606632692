import Svg, { Rect, G, Ellipse, Path, Text, TSpan } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const MagnifyingGlass = () => {
  const { colors } = useEmotionTheme();
  return (
    <Svg width={83} height={87} style={{ minHeight: 87 }} viewBox="0 0 83 87">
      <G
        transform="translate(-557.000000, -128.000000) translate(557.000000, 128.000000)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <G transform="translate(0.080292, 0.000000)">
          <Ellipse
            fillOpacity="0.400000006"
            fill="url(#a)"
            cx="35.6586397"
            cy="35.7589286"
            rx="35.6586397"
            ry="35.7589286"
          />
          <Path
            d="M35.659 66.749c17.054 0 30.886-13.871 30.886-30.99S52.713 4.769 35.66 4.769C18.604 4.77 4.772 18.64 4.772 35.76s13.832 30.99 30.887 30.99zm0 4.769C15.965 71.518 0 55.508 0 35.758 0 16.01 15.965 0 35.659 0c19.693 0 35.658 16.01 35.658 35.759S55.352 71.518 35.66 71.518z"
            fill={colors.black}
            fillRule="nonzero"
          />
          <Rect
            fill={colors.black}
            transform="translate(70.269446, 74.440291) rotate(-45.000000) translate(-70.269446, -74.440291) "
            x="65.5149607"
            y="59.3420767"
            width="9.5089706"
            height="30.1964286"
            rx="4.7544853"
          />
          <Path
            d="M56.983 64.494a2.372 2.372 0 0 1-.001-3.363 2.38 2.38 0 0 1 3.363.001l7.873 7.874c.93.929.937 2.428.002 3.363a2.38 2.38 0 0 1-3.364-.001l-7.873-7.874z"
            fill={colors.black}
          />
        </G>
        <Text
          fontFamily="SanFranciscoText-Semibold, San Francisco Text"
          fontSize={52}
          fontWeight="500"
          line-spacing={37}
          letterSpacing="-1.333333"
          fill={colors.green}
        >
          <TSpan x={36} y={55} textAnchor="middle">
            ?
          </TSpan>
        </Text>
      </G>
    </Svg>
  );
};

export default MagnifyingGlass;
