import React, { useEffect } from 'react';

import {
  AnimatedError,
  Button,
  ExtraHuge,
  Large,
  Tiny,
  View,
  useWindowWidthState,
  CalendarThreeDots,
  CheckmarkTextBubble,
  RepeatingArrowsCircle,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import { trackEvent } from '@/utils/analytics/eventTracker';

import PostAsyncPromptBaseScreen from './PostAsyncPromptBaseScreen';

const Styled = {
  Title: styled(ExtraHuge)({ textAlign: 'center', marginTop: 40 }),
  Body: styled(Large)({
    margin: '16px 0',
  }),
  InfoSectionContainer: styled(View)<{ isMobile: boolean }>(({ isMobile }) => {
    return {
      marginTop: 26,
      width: '100%',
      alignItems: isMobile ? undefined : 'center',
    };
  }),
  InfoSectionIconContainer: styled(View)({ marginRight: 10 }),
  InfoSectionTitleContainer: styled(View)({ flexDirection: 'row' }),
  InfoSectionTitle: styled(Large)({
    fontWeight: 700,
  }),
  InfoSectionDescription: styled(Large)<{ isMobile: boolean }>(({ isMobile }) => {
    return {
      marginTop: 6,
      paddingLeft: isMobile ? 30 : 0,
      textAlign: isMobile ? undefined : 'center',
      width: isMobile ? 310 : undefined,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
    };
  }),
  Button: styled(Button)<{ isMobile: boolean }>(({ isMobile, theme: { colors } }) => {
    return {
      marginTop: isMobile ? 46 : 86,
      width: '100%',
      backgroundColor: colors.permaTalkspaceDarkGreen,
    };
  }),
  CancellationPolicyText: styled(Tiny)(({ theme: { colors } }) => {
    return {
      marginTop: 24,
      color: colors.permaLividBlueNew,
      textAlign: 'center',
      lineHeight: '18px',
      letterSpacing: '0.1px',
    };
  }),
  CancellationPolicyLinkText: styled(Tiny)(({ theme: { colors } }) => {
    return {
      color: colors.accessibilityGreenDark,
      fontWeight: 700,
      cursor: 'pointer',
    };
  }),
};

const InfoSection = ({
  title,
  description,
  icon,
}: {
  title: string;
  description: string;
  icon: React.ReactNode;
}) => {
  const { isMobile } = useWindowWidthState();
  return (
    <Styled.InfoSectionContainer isMobile={isMobile}>
      <Styled.InfoSectionTitleContainer>
        <Styled.InfoSectionIconContainer>{icon}</Styled.InfoSectionIconContainer>
        <Styled.InfoSectionTitle>{title}</Styled.InfoSectionTitle>
      </Styled.InfoSectionTitleContainer>
      <Styled.InfoSectionDescription isMobile={isMobile}>
        {description}
      </Styled.InfoSectionDescription>
    </Styled.InfoSectionContainer>
  );
};

const CancellationPolicySection = ({ onPress }: { onPress: () => void }) => (
  <View>
    <Styled.CancellationPolicyText>
      By clicking ‘Start messaging session’ you are agreeing to{' '}
      <Styled.CancellationPolicyLinkText inline onPress={onPress}>
        Talkspace Messaging Cancellation Policy.
      </Styled.CancellationPolicyLinkText>
    </Styled.CancellationPolicyText>
  </View>
);
interface Props {
  isLoading: boolean;
  isError: boolean;
  onContinue: () => void;
  onOpenCancellationPolicy: () => void;
}

const PostAsyncPromptStartMessagingScreen = ({
  isLoading,
  isError,
  onContinue,
  onOpenCancellationPolicy,
}: Props) => {
  const { isMobile } = useWindowWidthState();
  const { colors } = useEmotionTheme();

  useEffect(() => {
    trackEvent('Ready to Start Messaging', {});
  }, []);

  const iconColor = colors.permaFiord;
  return (
    <PostAsyncPromptBaseScreen title="Start a session">
      <Styled.Title>Ready to start your next messaging session?</Styled.Title>
      <Styled.Body>Here are some things to keep in mind:</Styled.Body>
      <InfoSection
        title="Sessions last 7 days"
        description="You can expect responses at least once per day Mon-Fri during business hours. Weekends and holidays off."
        icon={<CalendarThreeDots width={20} height={20} color={iconColor} />}
      />
      <InfoSection
        title="Asynchronous care"
        description="Message with your provider like you would with a friend. Take your time to express yourself, and your provider will get back to you within 24 hours."
        icon={<RepeatingArrowsCircle width={20} height={20} color={iconColor} />}
      />
      <InfoSection
        title="Sessions completion"
        description="Your messaging session will end after 7 days of messaging with your provider."
        icon={<CheckmarkTextBubble width={20} height={20} color={iconColor} />}
      />
      <Styled.Button
        text="Start messaging session"
        onPress={onContinue}
        dataQa="startMessagingSession"
        isMobile={isMobile}
        isLoading={isLoading}
      />
      {isError && <AnimatedError shouldAnimate>Something went wrong, try again</AnimatedError>}
      <CancellationPolicySection onPress={onOpenCancellationPolicy} />
    </PostAsyncPromptBaseScreen>
  );
};

export default PostAsyncPromptStartMessagingScreen;
