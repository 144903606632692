import { FunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

interface RoundedOverlappingSquaresProps {
  color?: string;
  width?: number;
  height?: number;
  style?: object;
}
const RoundedOverlappingSquares: FunctionComponent<RoundedOverlappingSquaresProps> = ({
  color,
  width = 22,
  height = 23,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'rounded overlapping squares';
  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      {...otherProps}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.008.1A2.893 2.893 0 0121.9 2.99v11.017a2.893 2.893 0 01-2.892 2.892H16v-1.8h3.008c.602 0 1.092-.49 1.092-1.092V2.99c0-.602-.49-1.091-1.092-1.091H7.992C7.39 1.9 6.9 2.39 6.9 2.99V7H5.1V2.99A2.893 2.893 0 017.992.1h11.016z"
        fill={color || colors.green}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 8.926A2.927 2.927 0 0014.074 6H2.926A2.927 2.927 0 000 8.926v11.148A2.927 2.927 0 002.926 23h11.148A2.927 2.927 0 0017 20.074V8.926zM2.926 7.821h11.148c.61 0 1.105.496 1.105 1.105v11.148c0 .61-.496 1.105-1.105 1.105H2.926c-.61 0-1.105-.496-1.105-1.105V8.926c0-.61.496-1.105 1.105-1.105z"
        fill={color || colors.green}
      />
    </Svg>
  );
};

export default RoundedOverlappingSquares;
