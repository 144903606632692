import { ExtraHuge } from '@talkspace/react-toolkit';
import { WizardScheme } from 'stepWizard';

const CheckInWizardV3Scheme: WizardScheme = {
  wizardVersion: 3,
  wizardType: 'checkIn',
  wizardModalTitle: 'check in',
  hideProgressIndicator: true,
  steps: [
    {
      title: 'How was your experience with <!therapistInfo.firstName>?',
      titleSize: ExtraHuge,
      subtitle: 'Your rating is anonymous and contributes to your provider’s overall rating',
      inputType: 'starsRating',
      inputState: 'sessionStarsRating',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      name: 'Session Stars Rating',
      displayCondition: 'postLVS',
      postAction: 'ratingPostAction',
      additionalInfo: ['postLVSSubtitle', 'therapistInfo'],
    },
    {
      inputType: 'checkIn',
      inputState: 'checkInData',
      inputOptions: 'brickOptions',
      hideTopRow: true,
      nextButton: {
        displayText: '',
        actionDispatch: (value, wizardContext) => {
          if (
            (value.rating && value.rating > 3) ||
            // NOTE: as of Jan 2024 this flow is not used for psychiatry.
            // Should it be enabled for psych, this check will be useful as this copies the business logic for V1 check in
            wizardContext.checkInSource === 'psychiatry-lvs'
          ) {
            return `/v3/check-in/done/${wizardContext.checkInSource}`;
          }
          return '/v3/check-in/change-provider';
        },
        displayStyle: 'primary',
        fullReload: false,
        source: 'check-in-wizard',
        whenVisible: 'autoAdvance',
        persistOnDispatch: true,
      },
      name: 'Check In',
      postAction: 'bricksPostAction',
    },
  ],
};

export default CheckInWizardV3Scheme;
