import React, { useState } from 'react';
import BannerCard from '@talkspace/react-toolkit/src/designSystems/components/BannerCard/BannerCard';
import { BellFilled } from '@talkspace/react-toolkit/src/designSystems/icons';
import { useIonicEffect } from 'ts-ionic';
import {
  checkPushNotificationAndRegister,
  requestPushNotificationPermission,
} from 'ts-ionic/plugins/pushNotifications';
import { DENIED_PERMISSION } from 'ts-ionic/plugins/secureStorage';

const AccountScreenBannerPrompt = ({ isMobile }) => {
  const [promptForNotificationPermission, setPromptForNotificationPermission] = useState(false);

  const handleDismissNotificationPermission = () => {
    localStorage.setItem(DENIED_PERMISSION, 'dismiss');
  };
  const checkNotificationPermissionAndRegister = async () => {
    const didUserDismissBanner = localStorage.getItem(DENIED_PERMISSION);

    if (didUserDismissBanner) {
      setPromptForNotificationPermission(false);
    } else {
      const showPromptStatus = await checkPushNotificationAndRegister();
      setPromptForNotificationPermission(showPromptStatus);
    }
  };
  const requestNotificationPermission = async () => {
    await requestPushNotificationPermission();
    await checkNotificationPermissionAndRegister();
  };

  useIonicEffect(() => {
    checkNotificationPermissionAndRegister();
  }, []);

  return (
    <>
      {promptForNotificationPermission && (
        <BannerCard
          title="Turn on notifications"
          subtitle="Visit your device settings to enable notifications"
          variant="info"
          size="large"
          primaryButtonAction={() => {
            requestNotificationPermission();
          }}
          primaryButtonTitle="Turn on"
          secondaryButtonAction={() => {
            handleDismissNotificationPermission();
            setPromptForNotificationPermission(false);
          }}
          secondaryButtonTitle="Dismiss"
          icon={<BellFilled size="standard" colorType="info" />}
          isMobile={isMobile}
        />
      )}
    </>
  );
};

export default AccountScreenBannerPrompt;
