import { Path, Rect, Stop, Svg } from 'svgs';

import { EmojiSize } from '../../types';

const CryingFaceEmoji = ({ size }: { size: EmojiSize }) => {
  const title = 'crying face emoji';
  return (
    <Svg
      title={title}
      aria-label={title}
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
    >
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint0_radial_4451_12219)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint1_radial_4451_12219)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint2_radial_4451_12219)"
        fillOpacity="0.6"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint3_radial_4451_12219)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint4_radial_4451_12219)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint5_radial_4451_12219)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint6_radial_4451_12219)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint7_radial_4451_12219)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint8_radial_4451_12219)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint9_radial_4451_12219)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint10_radial_4451_12219)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint11_radial_4451_12219)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint12_radial_4451_12219)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint13_radial_4451_12219)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint14_radial_4451_12219)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint15_radial_4451_12219)"
      />
      <Path
        d="M19.9987 36.6223C31.0833 36.6223 36.6223 29.1797 36.6223 19.9987C36.6223 10.8177 31.0833 3.375 19.9987 3.375C8.91402 3.375 3.375 10.8177 3.375 19.9987C3.375 29.1797 8.91402 36.6223 19.9987 36.6223Z"
        fill="url(#paint16_radial_4451_12219)"
      />
      <Rect
        x="8.125"
        y="23.5625"
        width="4.75"
        height="8.3125"
        rx="2.375"
        fill="url(#paint17_radial_4451_12219)"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4717 18.2187C18.4717 21.0061 16.2121 23.2656 13.4248 23.2656C10.6375 23.2656 8.37793 21.0061 8.37793 18.2187C8.37793 15.4314 10.6375 13.1719 13.4248 13.1719C16.2121 13.1719 18.4717 15.4314 18.4717 18.2187ZM31.5342 18.2187C31.5342 21.0061 29.2746 23.2656 26.4873 23.2656C23.7 23.2656 21.4404 21.0061 21.4404 18.2187C21.4404 15.4314 23.7 13.1719 26.4873 13.1719C29.2746 13.1719 31.5342 15.4314 31.5342 18.2187Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0625 20.5938C15.3742 20.5938 16.4375 19.5304 16.4375 18.2188C16.4375 16.9071 15.3742 15.8438 14.0625 15.8438C12.7508 15.8438 11.6875 16.9071 11.6875 18.2188C11.6875 19.5304 12.7508 20.5938 14.0625 20.5938ZM25.9375 20.5938C27.2492 20.5938 28.3125 19.5304 28.3125 18.2188C28.3125 16.9071 27.2492 15.8438 25.9375 15.8438C24.6258 15.8438 23.5625 16.9071 23.5625 18.2188C23.5625 19.5304 24.6258 20.5938 25.9375 20.5938Z"
        fill="url(#paint18_linear_4451_12219)"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5824 11.6728C9.87833 12.3412 9.45255 13.1454 9.28219 13.6565C9.17849 13.9676 8.84224 14.1357 8.53115 14.032C8.22006 13.9283 8.05193 13.5921 8.15563 13.281C8.38111 12.6046 8.90532 11.6275 9.7648 10.8116C10.6344 9.98605 11.8633 9.3125 13.4689 9.3125C13.7968 9.3125 14.0627 9.57833 14.0627 9.90625C14.0627 10.2342 13.7968 10.5 13.4689 10.5C12.2245 10.5 11.2764 11.014 10.5824 11.6728Z"
        fill="url(#paint19_linear_4451_12219)"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4176 11.6728C30.1217 12.3412 30.5475 13.1454 30.7178 13.6565C30.8215 13.9676 31.1578 14.1357 31.4688 14.032C31.7799 13.9283 31.9481 13.5921 31.8444 13.281C31.6189 12.6046 31.0947 11.6275 30.2352 10.8116C29.3656 9.98605 28.1367 9.3125 26.5311 9.3125C26.2032 9.3125 25.9373 9.57833 25.9373 9.90625C25.9373 10.2342 26.2032 10.5 26.5311 10.5C27.7755 10.5 28.7236 11.014 29.4176 11.6728Z"
        fill="url(#paint20_linear_4451_12219)"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9966 28.3125C18.0235 28.3125 16.6254 29.5946 16.2239 30.1753C15.851 30.7148 15.1113 30.8497 14.5718 30.4768C14.0324 30.1038 13.8974 29.3641 14.2704 28.8247C14.9754 27.805 17.0261 25.9375 19.9966 25.9375C22.9671 25.9375 25.0178 27.805 25.7228 28.8247C26.0958 29.3641 25.9609 30.1038 25.4214 30.4768C24.882 30.8497 24.1423 30.7148 23.7693 30.1753C23.3678 29.5946 21.9697 28.3125 19.9966 28.3125Z"
        fill="url(#paint21_radial_4451_12219)"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9966 28.3125C18.0235 28.3125 16.6254 29.5946 16.2239 30.1753C15.851 30.7148 15.1113 30.8497 14.5718 30.4768C14.0324 30.1038 13.8974 29.3641 14.2704 28.8247C14.9754 27.805 17.0261 25.9375 19.9966 25.9375C22.9671 25.9375 25.0178 27.805 25.7228 28.8247C26.0958 29.3641 25.9609 30.1038 25.4214 30.4768C24.882 30.8497 24.1423 30.7148 23.7693 30.1753C23.3678 29.5946 21.9697 28.3125 19.9966 28.3125Z"
        fill="url(#paint22_radial_4451_12219)"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9966 28.3125C18.0235 28.3125 16.6254 29.5946 16.2239 30.1753C15.851 30.7148 15.1113 30.8497 14.5718 30.4768C14.0324 30.1038 13.8974 29.3641 14.2704 28.8247C14.9754 27.805 17.0261 25.9375 19.9966 25.9375C22.9671 25.9375 25.0178 27.805 25.7228 28.8247C26.0958 29.3641 25.9609 30.1038 25.4214 30.4768C24.882 30.8497 24.1423 30.7148 23.7693 30.1753C23.3678 29.5946 21.9697 28.3125 19.9966 28.3125Z"
        fill="url(#paint23_radial_4451_12219)"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9966 28.3125C18.0235 28.3125 16.6254 29.5946 16.2239 30.1753C15.851 30.7148 15.1113 30.8497 14.5718 30.4768C14.0324 30.1038 13.8974 29.3641 14.2704 28.8247C14.9754 27.805 17.0261 25.9375 19.9966 25.9375C22.9671 25.9375 25.0178 27.805 25.7228 28.8247C26.0958 29.3641 25.9609 30.1038 25.4214 30.4768C24.882 30.8497 24.1423 30.7148 23.7693 30.1753C23.3678 29.5946 21.9697 28.3125 19.9966 28.3125Z"
        fill="url(#paint24_radial_4451_12219)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_4451_12219"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.2813 11.6875) rotate(132.839) scale(44.5352)"
        >
          <Stop stopColor="#FFF478" />
          <Stop offset="0.474827" stopColor="#FFB02E" />
          <Stop offset="1" stopColor="#F70A8D" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_4451_12219"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20 15.8438) rotate(101.31) scale(21.1928 27.1439)"
        >
          <Stop offset="0.787821" stopColor="#F59639" stopOpacity="0" />
          <Stop offset="0.972509" stopColor="#FF7DCE" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_4451_12219"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.375 17.625) rotate(135) scale(48.702)"
        >
          <Stop offset="0.314853" stopOpacity="0" />
          <Stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_4451_12219"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20 21.1875) rotate(77.692) scale(33.4245)"
        >
          <Stop offset="0.507903" stopColor="#7D6133" stopOpacity="0" />
          <Stop offset="1" stopColor="#715B32" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_4451_12219"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.5938 20.5938) rotate(55.7131) scale(15.8098 11.4598)"
        >
          <Stop stopColor="#FFB849" />
          <Stop offset="1" stopColor="#FFB847" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_4451_12219"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.3438 22.375) rotate(9.86581) scale(13.8612)"
        >
          <Stop stopColor="#FFA64B" />
          <Stop offset="0.900412" stopColor="#FFAE46" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_4451_12219"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.7812 18.8125) rotate(43.9708) scale(70.1254)"
        >
          <Stop offset="0.185425" stopOpacity="0" />
          <Stop offset="1" stopOpacity="0.4" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_4451_12219"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.7109 19.3596) rotate(47.4896) scale(4.40477)"
        >
          <Stop offset="0.359039" stopColor="#C84E28" />
          <Stop offset="1" stopColor="#C84528" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_4451_12219"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.0308 17.348) rotate(136.054) scale(4.31858)"
        >
          <Stop offset="0.135653" stopColor="#392108" />
          <Stop offset="1" stopColor="#C87928" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_4451_12219"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.1066 18.7045) rotate(106.798) scale(5.17942)"
        >
          <Stop offset="0.359039" stopColor="#C84E28" />
          <Stop offset="1" stopColor="#C84528" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint10_radial_4451_12219"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.3511 18.7361) rotate(162.77) scale(5.88549)"
        >
          <Stop offset="0.31498" stopColor="#392108" />
          <Stop offset="1" stopColor="#C87928" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint11_radial_4451_12219"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.1493 13.2169) rotate(3.30285) scale(4.59003)"
        >
          <Stop stopColor="#D59A13" />
          <Stop offset="1" stopColor="#D59A13" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint12_radial_4451_12219"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.9777 15.2898) rotate(-144.462) scale(3.62155)"
        >
          <Stop stopColor="#D59A13" />
          <Stop offset="1" stopColor="#D59A13" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint13_radial_4451_12219"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.0347 24.0317) rotate(-54.9683) scale(4.23669)"
        >
          <Stop stopColor="#C1822C" />
          <Stop offset="0.703843" stopColor="#C1822C" stopOpacity="0.17" />
          <Stop offset="1" stopColor="#C1822C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint14_radial_4451_12219"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.4266 10.9636) rotate(27.0562) scale(3.00655 1.55029)"
        >
          <Stop stopColor="#E7A51F" />
          <Stop offset="1" stopColor="#E7A51F" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint15_radial_4451_12219"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(29.8322 12.8009) rotate(42.7454) scale(2.76073 1.3082)"
        >
          <Stop stopColor="#E7A51F" />
          <Stop offset="1" stopColor="#E7A51F" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint16_radial_4451_12219"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.26366 27.6818) rotate(-13.0645) scale(4.45091)"
        >
          <Stop stopColor="#C06A31" />
          <Stop offset="1" stopColor="#C06A31" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint17_radial_4451_12219"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.0833 23.9403) rotate(105.285) scale(9.00903 3.50536)"
        >
          <Stop stopColor="#5C98FF" />
          <Stop offset="0.805982" stopColor="#3D5BF1" />
          <Stop offset="0.962238" stopColor="#6065CA" />
        </radialGradient>
        <linearGradient
          id="paint18_linear_4451_12219"
          x1="20.5195"
          y1="15.0521"
          x2="19.9113"
          y2="20.6406"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#553B3E" />
          <Stop offset="1" stopColor="#3D2432" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_4451_12219"
          x1="9.11474"
          y1="14.5375"
          x2="9.11474"
          y2="12.4"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.0299084" stopColor="#524049" />
          <Stop offset="1" stopColor="#4A2C42" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_4451_12219"
          x1="30.8853"
          y1="14.5375"
          x2="30.8853"
          y2="12.4"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.0299084" stopColor="#524049" />
          <Stop offset="1" stopColor="#4A2C42" />
        </linearGradient>
        <radialGradient
          id="paint21_radial_4451_12219"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.9967 31.7841) rotate(-90) scale(6.45922 8.05915)"
        >
          <Stop offset="0.479167" stopColor="#5B2666" />
          <Stop offset="0.686742" stopColor="#533A4E" />
          <Stop offset="0.817708" stopColor="#533A4E" />
          <Stop offset="0.959686" stopColor="#53261F" />
        </radialGradient>
        <radialGradient
          id="paint22_radial_4451_12219"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.2849 30.4098) rotate(23.4722) scale(1.47798 1.47807)"
        >
          <Stop stopColor="#52265D" />
          <Stop offset="1" stopColor="#52265D" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint23_radial_4451_12219"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.0596 29.1605) rotate(33.0077) scale(0.936663 2.37128)"
        >
          <Stop stopColor="#4E3130" />
          <Stop offset="1" stopColor="#4E3130" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint24_radial_4451_12219"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.445 29.0286) rotate(134.095) scale(1.58019 1.58018)"
        >
          <Stop stopColor="#694F5B" />
          <Stop offset="1" stopColor="#59414E" stopOpacity="0" />
        </radialGradient>
      </defs>
    </Svg>
  );
};

export default CryingFaceEmoji;
