import { ExtraHuge, Image, Large, View, Screen } from '@talkspace/react-toolkit';
import { FunctionComponent, useEffect } from 'react';
import { getIsIonic } from 'ts-ionic';
import styled from '@/core/styled';
import appConfig from '@/utils/configs';
import SSOLoginBackLink from '../SSOLoginBackLink';
import SSOLoginProviderButton from '../SSOLoginProviderButton';
import { ssoSetStartHistoryLength } from '../SSOCallbackError/ssoRetryTracker';
import isInWebView from './isInWebView';

const TitleContainer = styled(View)({
  marginLeft: 17,
  marginRight: 17,
  marginBottom: 15,
  marginTop: 55,
  textAlign: 'center',
});

const SSOLogin: FunctionComponent<{
  mode: 'logIn' | 'register';
}> = ({ mode }) => {
  const isIonic = getIsIonic();

  useEffect(() => {
    ssoSetStartHistoryLength();
  }, []);

  const titleText = mode === 'register' ? 'Register with SSO' : 'Log in with Single Sign-On (SSO)';

  let ssoURL = appConfig.sso.aetna.loginUrl;
  if (isIonic) {
    ssoURL = ssoURL.replace(
      globalThis.document?.location.origin,
      appConfig.deepLinks.universalDeepLinkDomain
    );
  }

  let result = (
    <View flex={1} align="center">
      <TitleContainer>
        <ExtraHuge>{titleText}</ExtraHuge>
      </TitleContainer>
      <Large variant="largeDarkGrey">Select your provider</Large>
      <SSOLoginProviderButton
        href={ssoURL}
        Logo={() => (
          <Image
            src="/images/resources-for-living.png"
            alt="Resources for Living"
            style={{
              width: '100%',
            }}
          />
        )}
        name="Resources for Living"
      />
      <SSOLoginBackLink mode={mode} />
    </View>
  );

  if (!isInWebView()) {
    result = (
      <Screen showBackButton showCloseButton={false}>
        {result}
      </Screen>
    );
  }

  return result;
};

export default SSOLogin;
