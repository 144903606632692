import { WizardScheme } from 'stepWizard';
import RefundDollarTextBoxContainer from './containers/RefundDollarTextBoxContainer';

const refundWizardScheme: WizardScheme = {
  wizardVersion: 1,
  wizardType: 'refund',
  wizardModalTitle: 'refund',
  hideProgressIndicator: true,
  exitModalText:
    'If you leave now, we won’t be able to process your refund and customer service will not be notified to follow up with you.',
  steps: [
    {
      title: 'We didn’t find any payments from the last month that are eligible for refund.',
      subtitle:
        'If you’d like customer support to follow up with you, please submit a support request, and we’ll get back to you as soon as possible.',
      inputState: '',
      nextButton: {
        displayText: 'Submit a support request',
        actionDispatch: '/refund-purchase/support-request-submitted',
        whenVisible: 'always',
      },
      secondaryButton: {
        displayText: 'I no longer need customer support',
        actionDispatch: 'close',
        whenVisible: 'always',
      },
      displayCondition: 'isDontDisplayRefundsFlow',
      name: 'No payments found',
      backOption: 'back',
    },
    {
      title: 'Select which payment you’d like to request to be refunded or partially refunded.',
      inputType: 'select',
      inputState: 'selectedDropdownChargeIndex',
      inputOptions: 'refundableChargesDisplay',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      displayCondition: 'isDisplayRefundsFlow',
      name: 'Select refund',
      backOption: 'back',
      postAction: 'setSelectedRefundableCharge',
    },
    {
      title: "It seems we couldn't find the payment you are looking for.",
      subtitle:
        'If you’d like customer support to follow up with you, please submit a support request, and we’ll get back to you as soon as possible.',
      inputState: '',
      nextButton: {
        displayText: 'Submit a support request',
        actionDispatch: '/refund-purchase/support-request-submitted',
        whenVisible: 'always',
      },
      secondaryButton: {
        displayText: 'I no longer need customer support',
        actionDispatch: 'close',
        whenVisible: 'always',
      },
      displayCondition: 'isRefundNotSelectedFlow',
      name: 'No refund selected',
      backOption: 'back',
    },
    {
      title: 'This credit has already been redeemed for a live session.',
      subtitle:
        'In order to proceed with requesting a refund for a session, please submit a support request. We’ll get back to you as soon as possible.',
      inputState: '',
      nextButton: {
        displayText: 'Submit a support request',
        actionDispatch: '/refund-purchase/support-request-submitted',
        whenVisible: 'always',
      },
      secondaryButton: {
        displayText: 'I no longer need customer support',
        actionDispatch: 'close',
        whenVisible: 'always',
      },
      displayCondition: 'isRedeemedLVSFlow',
      name: 'LVS credit redeemed',
      backOption: 'back',
    },
    {
      title: 'Why are you requesting to refund this payment?',
      inputType: 'select',
      inputState: 'selectedRefundReasonLVSIndex',
      inputOptions: 'refundReasonsLVS',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      displayCondition: 'isNonRedeemedLVSFlow',
      name: 'Select LVS refund Reason',
      backOption: 'back',
      postAction: 'setRefundReasonLVS',
    },
    {
      title: 'Tell us more about why you’re requesting to refund this payment.',
      inputType: 'multilineText',
      inputState: 'LVSOtherRefundReason',
      inputTypePlaceholder: 'Describe what went wrong',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      name: 'Other LVS refund Reason',
      displayCondition: 'isNotSelectedRefundReasonLVS',
      backOption: 'back',
    },
    {
      title: 'Refund eligible',
      subtitle: 'You’re eligible for a refund of <!selectedFormattedRefundableAmount>.',
      inputState: '',
      nextButton: {
        displayText: 'Confirm refund',
        actionDispatch: '/refund-purchase/purchase-refunded',
        whenVisible: 'always',
      },
      displayCondition: 'isNonRedeemedLVSFlow',
      name: 'LVS refund eligible',
      backOption: 'back',
      additionalInfo: ['selectedFormattedRefundableAmount'],
    },
    {
      title: 'According to our refund policy, this payment is not eligible for a refund.',
      subtitle:
        'Based on your service usage, we’ve deemed that this payment can longer be refunded.',
      inputState: '',
      nextButton: {
        displayText: 'Submit a support request',
        actionDispatch: '/refund-purchase/support-request-submitted',
        whenVisible: 'always',
      },
      secondaryButton: {
        displayText: 'I no longer need customer support',
        actionDispatch: 'close',
        whenVisible: 'always',
      },
      displayCondition: 'isNonRefundableCopayFlow',
      name: 'Non refundable copay',
      backOption: 'back',
      footer: () => <RefundDollarTextBoxContainer />,
    },
    {
      title: 'Refund eligible',
      subtitle:
        'You’re eligible for a refund of <!selectedFormattedRefundableAmount>. To provide you this refund, your current session will be cancelled.',
      inputState: '',
      nextButton: {
        displayText: 'Refund and cancel session',
        actionDispatch: '/refund-purchase/purchase-refunded',
        whenVisible: 'always',
      },
      displayCondition: 'isRefundableCopayFlow',
      name: 'Copay refund eligible',
      backOption: 'back',
      additionalInfo: ['selectedFormattedRefundableAmount'],
    },
    {
      title: 'According to our refund policy, this payment is not eligible for a refund.',
      subtitle:
        'Based on your service usage, we’ve deemed that this payment can longer be refunded.',
      inputState: '',
      nextButton: {
        displayText: 'Submit a support request',
        actionDispatch: '/refund-purchase/support-request-submitted',
        whenVisible: 'always',
      },
      secondaryButton: {
        displayText: 'I no longer need customer support',
        actionDispatch: 'close',
        whenVisible: 'always',
      },
      displayCondition: 'isNonRefundableSubscriptionFlow',
      name: 'Non refundable subscription',
      backOption: 'back',
      footer: () => <RefundDollarTextBoxContainer />,
    },
    {
      title: 'Refund eligible',
      subtitle:
        'You’re eligible for a refund of <!selectedFormattedRefundableAmount>. If your subscription has not yet ended, we’ll cancel your remaining service in order to refund you.',
      inputState: '',
      nextButton: {
        displayText: 'Confirm refund',
        actionDispatch: '/refund-purchase/purchase-refunded',
        whenVisible: 'always',
      },
      secondaryButton: {
        displayText: 'Waive this refund and contact support',
        actionDispatch: 'next',
        whenVisible: 'always',
      },
      displayCondition: 'isRefundableSubscriptionFlow',
      name: 'Subscription refund eligible',
      backOption: 'back',
      additionalInfo: ['selectedFormattedRefundableAmount'],
      footer: () => <RefundDollarTextBoxContainer />,
    },
    {
      title: 'Are you sure you want to waive this refund and contact customer support instead',
      subtitle:
        'With current wait times, please understand it may take up to <!supportResponseInDays> days to receive a reply.',
      inputState: '',
      nextButton: {
        displayText: 'Contact customer support',
        actionDispatch: '/refund-purchase/support-request-submitted',
        whenVisible: 'always',
      },
      secondaryButton: {
        displayText: 'Back to refund',
        actionDispatch: 'back',
        whenVisible: 'always',
      },
      displayCondition: 'isRefundableSubscriptionFlow',
      name: 'Go to support when offered refund',
      backOption: 'back',
      additionalInfo: ['supportResponseInDays'],
    },
    {
      title: 'Why are you requesting to refund this payment?',
      inputType: 'select',
      inputState: 'selectedRefundReasonSubscriptionIndex',
      inputOptions: 'refundReasonsSubscription',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      displayCondition: 'isNonCancelledSubscriptionFlow',
      name: 'Select subscription refund Reason',
      backOption: 'back',
      postAction: 'setRefundReasonSubscription',
    },
    {
      title: 'Before we can issue a refund, you must first cancel your subscription renewal.',
      subtitle:
        'Once you have canceled your subscription renewal, start this refund questionnaire again. We’ll then be able to calculate your refund.',
      inputState: '',
      nextButton: {
        displayText: 'Begin cancellation process',
        actionDispatch: '/cancel-subscription/room/:roomID/source/:source/context-id/:contextID',
        fullReload: true,
        whenVisible: 'always',
        roomID: '<!selectedRefundableCharge.roomID>',
        source: 'refund',
        persistOnDispatch: true,
      },
      displayCondition: 'iWantToCancelRefundReasonSubscription',
      name: 'Go to cancellation wizard',
      backOption: 'back',
      additionalInfo: ['selectedRefundableCharge'],
    },
    {
      title: 'Tell us more about why you’re requesting to refund this payment.',
      inputType: 'multilineText',
      inputState: 'SubscriptionOtherRefundReason',
      inputTypePlaceholder: 'Describe what went wrong',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      name: 'Other subscription refund Reason',
      displayCondition: 'otherRefundReasonSubscription',
      backOption: 'back',
    },
    {
      title: 'To request a refund, you’ll need to submit a support request.',
      subtitle:
        'We could not verify whether you are eligible for a refund based on the information provided.',
      inputState: '',
      nextButton: {
        displayText: 'Submit a support request',
        actionDispatch: '/refund-purchase/support-request-submitted',
        whenVisible: 'always',
      },
      secondaryButton: {
        displayText: 'I no longer need customer support',
        actionDispatch: 'close',
        whenVisible: 'always',
      },
      displayCondition: 'isNonCancelledSubscriptionFlow',
      name: 'Non cancelled non refundable subscription',
      backOption: 'back',
    },
  ],
};

export default refundWizardScheme;
