import { Route, Switch } from '../core/routerLib';
import CheckoutScreen from './screens/CheckoutScreen';
import PaymentDetailsScreen from './screens/PaymentDetailsScreen';

const Routes = () => (
  <Switch>
    <Route exact path="/insurance-eligibility/room/:roomID/checkout" component={CheckoutScreen} />
    <Route
      exact
      path="/insurance-eligibility/room/:roomID/payment-details"
      component={PaymentDetailsScreen}
    />
  </Switch>
);

export default Routes;
