import { FunctionComponent } from 'react';
import {
  RedX,
  BaseButton,
  Button,
  View,
  Large,
  ExtraHuge,
  useUniqueID,
} from '@talkspace/react-toolkit';
import styled from '../../../core/styled/styled';

const SecondaryButton = styled(BaseButton)({
  marginTop: 40,
  marginBottom: 60,
  backgroundColor: '#fff',
});

interface Props {
  error?: string;
  errorMessage?: string | JSX.Element;
  buttonText?: string;
  onButtonPress: () => void;
  onSecondaryButtonPress?: () => void;
  showPrimaryButton?: boolean;
  showSecondaryButton?: boolean;
  secondaryButtonText?: string;
  doesErrorIconAppear?: boolean;
  style?: {};
  buttonDataQa?: string;
}

const ErrorModal: FunctionComponent<Props> = (props) => {
  const {
    error,
    errorMessage,
    onButtonPress,
    onSecondaryButtonPress,
    showPrimaryButton = true,
    showSecondaryButton,
    secondaryButtonText,
    buttonText,
    doesErrorIconAppear = true,
    style,
    buttonDataQa,
  } = props;

  const ID_ERROR_MESSAGE = useUniqueID('errorMessageText');

  return (
    <View align="center" style={{ marginBottom: 10, marginTop: 20, ...style }}>
      {doesErrorIconAppear && (
        <View style={{ marginBottom: 26 }}>
          <RedX />
        </View>
      )}
      {/* TODO: Make sure we don't need this */}
      <ExtraHuge style={{ marginBottom: 15 }}>
        {error || 'Something went wrong. Please try again later.'}
      </ExtraHuge>
      {errorMessage && (
        <Large
          variant="largeDarkGrey"
          style={{ maxWidth: 430, textAlign: 'center' }}
          id={ID_ERROR_MESSAGE}
        >
          {errorMessage}
        </Large>
      )}
      {showPrimaryButton && (
        <Button
          text={buttonText || 'Close'}
          onPress={onButtonPress}
          style={{ marginTop: 22 }}
          aria-describedby={ID_ERROR_MESSAGE}
          dataQa={buttonDataQa}
        />
      )}
      {showSecondaryButton && (
        <SecondaryButton onPress={onSecondaryButtonPress} style={{ marginTop: 22 }}>
          <Large>{secondaryButtonText || 'Not now'}</Large>
        </SecondaryButton>
      )}
    </View>
  );
};

export default ErrorModal;
