import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { History } from 'history';
import { StepWizardProvider } from 'stepWizard';
import { useGenericWizardActions } from '@/utils/genericWizardActions';
import { getUserData } from '@/auth/helpers/token';
import { MainContextProvider } from '@/hooks/mainContext';
import Routes from './Routes';
import { withRouter, RouteComponentProps } from '../core/routerLib';
import { getInitialState, State } from './reducers/npsWizardState';
import npsWizardActions from './hooks/npsWizardActions';
import ApiHelper from '../utils/ApiHelper';

interface NPSRouteParams {
  roomID?: number;
  source?: string;
  userPromptID?: number;
  contextID?: string;
  history: History;
}

const NPSWizard: FunctionComponent<RouteComponentProps<NPSRouteParams>> = ({
  history,
  match: {
    params: { roomID, source, contextID = '', userPromptID },
  },
}) => {
  const { id } = getUserData();
  const getFirstState = useCallback(getInitialState, [roomID, source, contextID, userPromptID]);
  const [initialState, setInitialState] = useState<State | undefined>();
  const genericNpsWizardActions = useGenericWizardActions(npsWizardActions);
  useEffect(() => {
    setInitialState(getFirstState(id, roomID, source, contextID, userPromptID));
  }, [contextID, getFirstState, id, roomID, source, userPromptID]);
  return (
    <>
      {initialState ? (
        <MainContextProvider>
          <StepWizardProvider
            ApiHelperOverride={ApiHelper}
            initialState={initialState}
            genericActions={genericNpsWizardActions}
          >
            <Routes history={history} />
          </StepWizardProvider>
        </MainContextProvider>
      ) : null}
    </>
  );
};

export default withRouter(NPSWizard);
