import { useEffect, useRef, useState } from 'react';
import { Spinner, View } from '@talkspace/react-toolkit';
import { StepWizardProvider, WizardGenericActions, WizardScheme } from 'stepWizard';
import Routes from './Routes';
import { extractSurveyData, getWelcomeScreenAndSteps } from './surveyWizardScheme';
import SurveyWizardApiHelper from './utils/surveyWizardApiHelper';
import { getInitialState, State } from './reducers/surveyWizardState';
import surveyWizardActions from './hooks/surveyWizardActions';
import { SurveySource } from './types';

export interface SurveyWizardProps {
  source: SurveySource;
  roomID?: number;
  surveyID: number;
  contextID?: string;
  clientUserID?: number;
  onSurveyProgress?: State['onSurveyProgress'];
  handleSurveysError?: (error: string) => void;
  closeWizardAction: WizardGenericActions<State, SurveyWizardApiHelper>['closeWizardAction'];
}

const SurveyWizard = ({
  source,
  surveyID,
  onSurveyProgress,
  closeWizardAction,
  handleSurveysError,
  clientUserID = 0,
  roomID = 0,
  contextID = '',
}: SurveyWizardProps) => {
  const [initialState, setInitialState] = useState<State | undefined>();
  const stepsRef = useRef<WizardScheme['steps'] | null>(null);
  const welcomeScreenRef = useRef<WizardScheme['welcomeScreen'] | null>(null);
  const surveyWizardApiHelperRef = useRef(new SurveyWizardApiHelper(source));
  useEffect(() => {
    const getSurveyData = async () => {
      try {
        const rawSurvey = await surveyWizardApiHelperRef.current.getSurvey(surveyID);
        const surveyData = extractSurveyData(rawSurvey);
        const data = getWelcomeScreenAndSteps(rawSurvey);

        stepsRef.current = data.steps;
        welcomeScreenRef.current = data.welcomeScreen;
        setInitialState(
          getInitialState(
            clientUserID,
            roomID,
            contextID,
            source,
            rawSurvey.id,
            surveyData,
            onSurveyProgress
          )
        );
      } catch (err) {
        if (handleSurveysError) handleSurveysError(err.message);
      }
    };
    if (surveyID) getSurveyData();
  }, [clientUserID, contextID, handleSurveysError, onSurveyProgress, roomID, source, surveyID]);

  return (
    <>
      {initialState && welcomeScreenRef.current && stepsRef.current ? (
        <StepWizardProvider
          apiHelperInstance={surveyWizardApiHelperRef.current}
          initialState={initialState}
          genericActions={{ ...surveyWizardActions, closeWizardAction }}
        >
          <Routes steps={stepsRef.current} welcomeScreen={welcomeScreenRef.current} />
        </StepWizardProvider>
      ) : (
        <View flex={1}>
          <Spinner isLoading />
        </View>
      )}
    </>
  );
};

export default SurveyWizard;
