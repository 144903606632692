import { Auth } from '@aws-amplify/auth';

type CompleteForgotPasswordParameters = {
  code: string;
  email: string;
  password: string;
};

const completeForgotPassword = async ({
  code,
  email,
  password,
}: CompleteForgotPasswordParameters): Promise<void> => {
  await Auth.forgotPasswordSubmit(email, code, password);
};

export default completeForgotPassword;
