import { spacing } from '@talkspace/react-toolkit';

export const arrowContainerStyles = {
  marginTop: spacing.space100,
};
export const bodyStyle = { paddingBottom: spacing.space200, paddingTop: 0 };
export const titleContainerStyles = {
  paddingBottom: spacing.space200,
  paddingTop: spacing.space200,
};
