import { useQuery } from 'react-query';

import apiHelper from '@/utils/api';
import apiWrapper from '@/core/api/apiWrapper';

export type IneligibleReason = 'benefit_renewed' | 'not_eligible_for_benefit';

interface RenewalEligibilityResponse {
  isEligibleForRenewal: boolean;
  ineligibleReason: null | IneligibleReason;
}

const renewalEligibility = (roomID: number | undefined) => async () => {
  const response = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/payments/room/${roomID}/vouchers/is-eligible-for-renewal`
  );
  return response.data;
};

const useQueryRenewalEligibility = (roomID: number | undefined) =>
  useQuery<RenewalEligibilityResponse, Error>({
    queryFn: renewalEligibility(roomID),
    enabled: Boolean(roomID),
  });

export default useQueryRenewalEligibility;
