import Svg, { G, Circle, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const Earth = ({
  width = 16,
  height = 16,
  color,
  ...props
}: {
  width?: number;
  height?: number;
  color?: string;
}) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg width={width} height={height} viewBox="0 0 16 16" {...props}>
      <G
        transform="translate(-730 -1226) translate(730 1226)"
        stroke={color}
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Circle strokeWidth={1.45454545} cx={8} cy={8} r={7.27272727} />
        <Path
          d="M14.751 6.962a2.777 2.777 0 00-.247.516c-.043.11-.083.214-.131.301-.086.153-.2.221-.23.221-.064 0-.232-.111-.344-.185-.261-.172-.614-.409-1.112-.393-.279.009-.742.479-.694.19.049-.29.06-.584.035-.88-.042-.456-.246-.768-.606-.929-.242-.108-.487-.11-.67-.112a6.147 6.147 0 01-.97-.088c-.012-.001-.056.055-.03-.005a1.03 1.03 0 01.165-.25L11.926 3c1.375 1.038 2.377 2.411 2.825 3.962zM8.598 1c.056 0 .112.003.168.004.096.285.281.52.448.731.176.223.327.415.313.579-.026.312-.648.512-1.148.673L8.336 3a6.86 6.86 0 00-1.77.864c-.296.202-.654.489-.795.907-.236.696.218 1.308.583 1.8l.04.053c.234.319.44.762.246.968-.203.216-.76.097-1.277-.049a.945.945 0 00-.906.238.994.994 0 00-.222 1.129c.192.436.761.977 2.378 1.075.078.004.223.162.23.308.004.114-.152.249-.39.442-.265.213-.563.455-.696.817a.411.411 0 00.135.463c.763.628 1.871.25 2.761-.054.62-.212 1.393-.708 1.347-.281a2.446 2.446 0 01-.561 1.304c-.152.191-.34.43-.394.737-.08.441.15.914.596 1.234l.086.06a8.802 8.802 0 01-1.129.073c-2.61 0-4.927-1.147-6.357-2.907.044-.75.44-1.266.765-1.683l.045-.057c.102-.133.239-.34.233-.6-.007-.297-.19-.506-.324-.658-.025-.029-.05-.057-.073-.086-.322-.406-.101-1.039.27-1.427l.035-.036c.171-.177.43-.444.4-.815-.026-.343-.3-.644-.701-.766-.283-.086-.568-.068-.808-.039A6.86 6.86 0 001 6.235C1.894 3.225 4.96 1 8.598 1z"
          fill={color || colors.earthBlue}
          fillRule="nonzero"
        />
      </G>
    </Svg>
  );
};

export default Earth;
