import { ThemedFlagsProvider } from 'launchDarkly';
import ModalRoutes from '../ModalRoutes';
import { Route } from '../core/routerLib';
import ModalsProvider from '../utils/ModalsContextProvider';
import HomeScreen from './screens/HomeScreen';
import ScheduleScreen from './screens/ScheduleScreen';
import JourneyScreen from './screens/JourneyScreen';
import HelpScreen from './screens/HelpScreen';
import AccountScreen from './screens/AccountScreen';
import PersonalInformationContainer from '../myAccount/containers/PersonalInformation';
import AccountDetailsContainer from '../myAccount/containers/AccountDetails';
import NotificationsContainer from '../myAccount/containers/Notifications';
import UpdateCoverageContainer from '../updatePlans/containers/UpdateCoverageContainer';
import EligibilityWidget from '../eligibilityWidget';
import ChangeProviderContainer from '../updatePlans/containers/ChangeProviderContainer';
import ManageSubscriptionContainer from '../myAccount/containers/ManageSubscription';
import TeenspaceHomeRouter from '../community/HomeRouter';
import CustomerSupportScreen from './screens/CustomerSupportScreen';

const HomeScreenWrapped = () => (
  <ModalsProvider ModalRoutes={ModalRoutes}>
    <HomeScreen />
  </ModalsProvider>
);

const HomeRouter = () => (
  <ThemedFlagsProvider versionKey="homePage">
    <Route exact path={['/home']} component={HomeScreenWrapped} />
    <Route exact path={['/home/schedule']} component={ScheduleScreen} />
    <Route exact path={['/home/journey']} component={JourneyScreen} />
    <Route path={['/home/teenspace']} component={TeenspaceHomeRouter} />
    <Route exact path={['/home/help']} component={HelpScreen} />
    <Route exact path={['/home/account']} component={AccountScreen} />
    <Route
      exact
      path={['/home/account/personal-information']}
      component={PersonalInformationContainer}
    />
    <Route
      exact
      path={['/home/account/account-details']}
      render={() => (
        <AccountDetailsContainer
          inNavShell
          onCloseButtonPress={() => {}}
          onBackButtonPress={() => {}}
        />
      )}
    />
    <Route exact path={['/home/account/notifications']} component={NotificationsContainer} />
    <Route exact path={['/home/account/update-coverage']} component={UpdateCoverageContainer} />
    <Route
      exact
      path={['/home/account/add-new-service/room/:roomID', '/home/account/add-new-service']}
      render={() => <EligibilityWidget qmFlow={90} />}
    />
    <Route exact path={['/home/account/change-provider']} component={ChangeProviderContainer} />
    <Route
      exact
      path={['/home/account/manage-subscription']}
      render={() => (
        <ManageSubscriptionContainer
          inNavShell
          onCloseButtonPress={() => {}}
          onBackButtonPress={() => {}}
        />
      )}
    />
    <Route exact path={['/home/account/customer-support']} component={CustomerSupportScreen} />
  </ThemedFlagsProvider>
);
export default HomeRouter;
