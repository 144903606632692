import { VoidFunctionComponent, useMemo, useState } from 'react';
import { CloseButton, View } from '@talkspace/react-toolkit';

import { COLORS } from '@/utils/design';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import styled from '@/core/styled';
import { useParams } from '@/core/routerLib';
import ActionStatus from '@/components/ActionStatus';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import useQueryRenewalEligibility from '../hooks/useQueryRenewalEligibility';
import useMutationRenewVoucher from '../hooks/useMutationRenewVoucher';
import DTERenewalForm from '../components/DTERenewalForm';
import AlreadySubmittedWarning from '../components/AlreadySubmittedWarning';

const PageWrapper = styled(View)<{ isWebview: boolean }>(({ isWebview }) => {
  return {
    display: 'flex',
    justifyContent: 'space-around',
    height: isWebview ? '100vh' : '100%',
    width: '100%',
    alignItems: 'center',
  };
});

const ContentWrapper = styled(View)<{ isLoading: boolean }>(
  ({
    isLoading,
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      display: 'flex',
      backgroundColor: isLoading ? '' : COLORS.white,
      width: 550,
      height: '100%',
      borderRadius: 5,
      overflow: 'hidden',
      ...(isMobile && {
        height: '100%',
        width: '100%',
        borderRadius: 0,
      }),
    };
  }
);

const CloseButtonWrapper = styled(View)({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  paddingTop: 6,
  paddingRight: 6,
});

const subtitleStyles = {
  fontSize: 16,
  color: COLORS.black,
  marginTop: 13,
  marginBottom: 42,
  width: 200,
  textAlign: 'center',
};

const titleStyles = { fontWeight: 800, fontSize: 29, marginTop: 25 };

const DTERenewalContainer: VoidFunctionComponent = () => {
  const { orgName, roomID } = useParams<{ orgName: string; roomID: string }>();
  const [shouldClose, setShouldClose] = useState(false);

  const isWebview = useMemo(() => ReactFrameService.instance().isInFrame(), []);
  const closeModal = useCloseModal();
  const {
    data: renewalEligibility,
    isLoading: renewalEligibilityLoading,
    isError: renewalEligibilityError,
  } = useQueryRenewalEligibility(Number(roomID));
  const {
    mutate: renewVoucher,
    isLoading: renewVoucherLoading,
    isError: renewVoucherError,
  } = useMutationRenewVoucher();

  const onClosePress = () => {
    closeModal();
  };

  const isLoading = renewalEligibilityLoading || renewVoucherLoading || shouldClose;
  const isError = renewalEligibilityError || renewVoucherError;

  return (
    <PageWrapper isWebview={isWebview}>
      <ContentWrapper isLoading={isLoading}>
        {(() => {
          if (isLoading || isError) {
            return (
              <ActionStatus
                isLoading={isLoading}
                isError={isError}
                errorTitle="Something Went Wrong"
                errorSubTitle="Please check your internet connection and try again."
                errorButtonText="Close"
                errorButtonAction={onClosePress}
                titleStyles={titleStyles}
                subTitleStyles={subtitleStyles}
              />
            );
          }

          if (renewalEligibility?.isEligibleForRenewal) {
            return (
              <>
                <CloseButtonWrapper>
                  <CloseButton onPress={onClosePress} />
                </CloseButtonWrapper>
                <DTERenewalForm
                  roomID={Number(roomID)}
                  orgName={decodeURI(orgName)}
                  renewVoucher={renewVoucher}
                  setShouldClose={setShouldClose}
                />
              </>
            );
          }

          return (
            <>
              <CloseButtonWrapper>
                <CloseButton onPress={onClosePress} />
              </CloseButtonWrapper>
              <AlreadySubmittedWarning
                ineligibleReason={
                  renewalEligibility?.ineligibleReason || 'not_eligible_for_benefit'
                }
              />
            </>
          );
        })()}
      </ContentWrapper>
    </PageWrapper>
  );
};

export default DTERenewalContainer;
