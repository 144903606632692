import { useQuery } from 'react-query';
import { postSuggestTherapistBookingsQueryKey } from '../utils/queryKeys';
import {
  postSuggestTherapistBookings,
  PostSuggestTherapistBookingsPayload,
} from '../utils/switchWizardApiHelper';

export default function useQueryPostSuggestTherapistBookings({
  roomID,
  payload,
  disabled,
}: {
  roomID: number;
  payload: PostSuggestTherapistBookingsPayload;
  disabled?: boolean;
}) {
  return useQuery({
    queryKey: postSuggestTherapistBookingsQueryKey(roomID, payload.sessionLength),
    queryFn: () => postSuggestTherapistBookings(roomID, payload),
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !disabled,
  });
}
