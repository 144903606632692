import { Route, Switch } from '@/core/routerLib';
import ParentalConsentResubmitContainer from './containers/ParentalConsentResubmitContainer';

const Routes = () => (
  <Switch>
    <Route exact path="/parental-consent-resubmit" component={ParentalConsentResubmitContainer} />
  </Switch>
);

export default Routes;
