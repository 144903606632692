import { FunctionComponent } from 'react';
import { View, Huge, Large, Standard, Button, BaseButton } from '@talkspace/react-toolkit';
import { useWizardActions, useWizardState } from 'stepWizard';
import { COLORS } from '@/utils/design';
import { trackWizardEvent } from '@/utils/analytics/events';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import styled from '../../core/styled/index';

const Wrapper = styled(View)({
  alignSelf: 'center',
  width: 340,
});

const TitlesView = styled(View)({
  minHeight: 156,
  textAlign: 'center',
  width: 'auto',
  marginTop: 20,
  marginBottom: 40,
});

const SecondaryButton = styled(BaseButton)({
  marginTop: 20,
  padding: 0,
  backgroundColor: COLORS.white,
});

const Title = styled(Huge)({
  fontSize: 32,
  marginBottom: 20,
});

const SubTitle = styled(Large)({
  fontSize: 17,
});

const UnderAge: FunctionComponent<{ age: string }> = ({ age }) => {
  const { clientUserID, eventCategory, responses } = useWizardState();
  const { setState, onExitClick } = useWizardActions();
  const closeModal = useCloseModal();
  const handleEmergencyResourcesClick = () => {
    trackWizardEvent('Emergency Resources clicked', eventCategory, {
      'User ID': clientUserID,
      label: 'Emergency Resources clicked',
      Application: eventCategory,
      eventProperty: clientUserID,
      eventPropertyValue: 0.0,
    });
    const currentStepResponses = responses;
    currentStepResponses.lastStepName = `Under ${age} disclaimer`;
    setState({
      responses: currentStepResponses,
    });
    closeModal();
    window.open(`https://contact-us-v02.webflow.io/under-${age}`, '_blank');
  };
  return (
    <Wrapper>
      <TitlesView>
        <Title>{`Under ${age} years old?`}</Title>
        {/* TODO: Verify this variant is the right one */}
        <SubTitle variant="largeDarkGrey">
          {`Unfortunately Talkspace cannot provide service to individuals under the age of ${age}. If you or anyone you know are in a crisis or may be in danger, please visit our emergency resources to get immediate help.`}
        </SubTitle>
      </TitlesView>
      <Button
        text="Emergency Resources"
        onPress={handleEmergencyResourcesClick}
        style={{ width: 320 }}
      />
      <SecondaryButton onPress={onExitClick}>
        <Standard variant="standardDarkGrey">Return to chat</Standard>
      </SecondaryButton>
    </Wrapper>
  );
};

export default UnderAge;
