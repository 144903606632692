import { Button, TextDS, View, spacing } from '@talkspace/react-toolkit';

import { CommunityFeedTypeEnum } from '../types';
import FaceSmile from '../assets/icons/FaceSmile';
import FaceSurprise from '../assets/icons/FaceSurprise';
import { useHistory } from '@/core/routerLib';

const { space100, space600 } = spacing;

interface Props {
  feedType: CommunityFeedTypeEnum;
}
const CommunityFeedEmptyPosts = ({ feedType }: Props) => {
  const history = useHistory();
  const icon = feedType === 'today' ? <FaceSmile /> : <FaceSurprise />;
  const noPostsText =
    feedType === 'today' ? 'You could be the first to post!' : 'You haven’t written any posts yet!';
  const ctaText = 'Start your post';
  const onClickCTA = () => {
    history.push('/community/teenspace/create-post');
  };
  return (
    <View align="center" style={{ marginTop: space600 }}>
      {icon}
      <TextDS variant="body" colorRole="textSubtlest" style={{ marginTop: space100 }}>
        {noPostsText}
      </TextDS>
      <Button
        dataQa="communityFeedStartYourPostButton"
        variant="tertiary"
        text={ctaText}
        onClick={onClickCTA}
        style={{ marginTop: space100 }}
      />
    </View>
  );
};
export default CommunityFeedEmptyPosts;
