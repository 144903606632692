import { BaseWizardState, baseWizardInitialState, WizardPostAction } from 'stepWizard';
import { SurveyResponseOption } from 'ts-frontend/types';
import { SurveySource, SurveyWizardData } from '../types';

interface SurveyQuestion {
  value: number;
  label: string;
}
export interface State extends BaseWizardState, SurveyWizardData {
  roomID: number;
  clientUserID: number;
  appSource: SurveySource;
  userRoomSurveyID: number;
  surveyQuestions: SurveyQuestion[][];
  surveyRestoredAnswers: number[];
  onSurveyProgress?: (
    title: string,
    response: SurveyResponseOption | undefined,
    currentStep: number,
    isComplete: boolean
  ) => void;
  submitSurveyAnswerPostAction?: WizardPostAction<State, number>;
  instructionsButtonPostAction?: WizardPostAction<State, void>;
  isSubmittingLastAnswer: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const getInitialState = (
  clientUserID: number,
  roomID: number,
  contextID: string,
  appSource: SurveySource,
  userRoomSurveyID: number,
  surveyData: SurveyWizardData,
  onSurveyProgress?: State['onSurveyProgress']
): State => {
  const surveyRestoredAnswers: number[] = [];
  const wizardSurveyQuestions: SurveyQuestion[][] = [];
  surveyData.surveySections.forEach((section, index, sections) => {
    // For all instruction steps after the first one (welcome screen is not a step)
    if (section.sectionType === 'INSTRUCTIONS' && index !== 0) {
      const nextQuestion = sections.slice(index).find((s) => !!s.responseOptions);
      if (nextQuestion && nextQuestion.currentAnswer) {
        /**
         * This solves for the following rules for restoring survey answers:
         * 1 - If the survey is restored during an instruction,
         *      restore to the instruction after the last step seen
         *      (important when seeing consecutive instruction steps)
         * 2 - If the last step the user *answered* was a multiple choice step
         *      we go to the step after that multiple choice step and mark the
         *      instruction steps in-between as "answered", skipping them
         * The number (1) here can be replaced with any truthy number
         */
        surveyRestoredAnswers.push(1);
      }
      // Need to maintain the stepCounter index of wizardSurveyQuestions
      // even for instruction steps.
      wizardSurveyQuestions.push([]);
      return;
    }
    // Should only apply for the Welcome screen
    if (!section.responseOptions) {
      return;
    }
    // Fill out surveyRestoredAnswers
    if (section.currentAnswer) {
      surveyRestoredAnswers.push(section.currentAnswer.responseOptionID);
    }
    wizardSurveyQuestions.push(
      section.responseOptions.map((response) => {
        return {
          value: response.id,
          label: response.prompt,
        };
      })
    );
  });

  return {
    ...baseWizardInitialState(appSource, contextID),
    userRoomSurveyID,
    // Only if there are restored answers should we skip welcome screen
    currentStepNumber: surveyRestoredAnswers.length === 0 ? -1 : 0,
    surveyQuestions: wizardSurveyQuestions,
    surveyRestoredAnswers,
    appSource,
    roomID,
    clientUserID,
    onSurveyProgress,
    ...surveyData,
    isSubmittingLastAnswer: false,
  };
};
