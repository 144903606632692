import { VoidFunctionComponent } from 'react';
import { View, Large, Small, ExclamationCircle } from '@talkspace/react-toolkit';
import styled from '../../core/styled';
import Description from '../components/Description';
import SuperbillsWrapper from '../components/SuperbillsWrapper';

const WariningView = styled(View)(({ theme: { colors } }) => {
  return {
    flexDirection: 'row',
    background: colors.a11yLinkWaterGrey,
    borderRadius: 10,
    padding: '14px 10px',
    alignItems: 'center',
  };
});
const ExclamationCircleStyled = styled(ExclamationCircle)({
  marginRight: 12,
  width: 30,
});
const SmallStyled = styled(Small)({
  textAlign: 'left',
});

const InitialErrorSuperbillsScreen: VoidFunctionComponent = () => (
  <SuperbillsWrapper>
    <Description>
      <Large variant="largeDarkGrey">
        Superbills become available 3 days after the end of each billing cycle or session and
        contain all of the information necessary for you to file an out of network insurance claim.
        Superbills require a diagnosis code, so they will only be available once your provider has
        submitted a diagnosis.
      </Large>
    </Description>
    <WariningView>
      <ExclamationCircleStyled />
      <SmallStyled>There was an issue loading your superbills. Please try again later.</SmallStyled>
    </WariningView>
  </SuperbillsWrapper>
);

export default InitialErrorSuperbillsScreen;
