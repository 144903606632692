import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const EmailEnvelope = ({ width = 22, height = 17, ...props }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'email envelope';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-24 -360) translate(0 204)"
      >
        <Path
          fill={colors.white}
          stroke={colors.iconLightGrey}
          strokeWidth="0.5"
          d="M-0.25 -0.25H501.25V204.25H-0.25z"
        />
        <Path
          fillRule="nonzero"
          stroke={colors.green}
          strokeWidth="1.5"
          d="M0 1.992C0 .892.898 0 1.99 0h16.02C19.108 0 20 .893 20 1.992v11.016c0 1.1-.898 1.992-1.99 1.992H1.99C.892 15 0 14.107 0 13.008V1.992z"
          transform="translate(24 106) translate(0 48) translate(0 3) translate(1)"
        />
        <Path
          stroke={colors.green}
          strokeLinecap="round"
          strokeWidth="1.8"
          d="M1.7 1l8.605 8.327a1 1 0 001.39 0L20.3 1h0"
          transform="translate(24 106) translate(0 48) translate(0 3)"
        />
        <Path
          fill={colors.green}
          fillRule="nonzero"
          d="M14.358 7.106l6.568 6.356a.9.9 0 11-1.252 1.293L13.085 8.38l1.273-1.273zm-6.92.197l1.273 1.272-6.385 6.18a.9.9 0 01-1.182.061l-.09-.082a.9.9 0 01.02-1.272l6.364-6.159z"
          transform="translate(24 106) translate(0 48) translate(0 3)"
        />
      </g>
    </Svg>
  );
};

export default EmailEnvelope;
