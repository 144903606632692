import { FunctionComponent } from 'react';
import { View, Huge, useEmotionTheme } from '@talkspace/react-toolkit';
import { FriendReferralInfo } from '../../utils/friendReferralApiHelper';
import styled from '../../../core/styled';
import InviteDataRow from '../InviteDataRow/InviteDataRow';

const Wrapper = styled(View)(({ theme: { colors } }) => {
  return {
    align: 'center',
    height: 297,
    width: 345,
    padding: 35,
    marginTop: 25,
    borderColor: colors.periwinkleGrey,
    borderWidth: 0.5,
    borderStyle: 'solid',
    borderRadius: 10,
    backgroundColor: colors.white,
  };
});

const Border = styled(View)(({ theme: { colors } }) => {
  return {
    height: 1,
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: colors.a11yLinkWaterGrey,
  };
});

const InviteSummaryCard: FunctionComponent<{
  friendReferralInfo: FriendReferralInfo;
}> = ({ friendReferralInfo }) => {
  const { colors } = useEmotionTheme();
  return (
    <Wrapper>
      <Huge as="h3">Past invites</Huge>
      <InviteDataRow
        title="Pending"
        subtitle="Registered friends"
        count={friendReferralInfo.registeredReferralCount || 0}
      />
      <Border />
      <InviteDataRow
        title="Claimed"
        subtitle="Subscribed friends"
        count={friendReferralInfo.earnedReferralCount || 0}
        countColor={colors.green}
      />
    </Wrapper>
  );
};

export default InviteSummaryCard;
