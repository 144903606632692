import { View, Large, Massive } from '@talkspace/react-toolkit';
import ErrorIcon from '../../clientChat/assets/Error';

const ValidateEmailError = () => (
  <View justify="center" align="center" style={{ paddingTop: 68, textAlign: 'center' }}>
    <ErrorIcon />
    <Massive style={{ marginTop: 26, marginBottom: 20 }}>Something went wrong</Massive>
    <Large variant="largeDarkGrey">Please try again later.</Large>
  </View>
);

export default ValidateEmailError;
