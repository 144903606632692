import { useMemo, useState } from 'react';

import CommunityFeed from '../components/CommunityFeed';
import useCommunityPosts from '../hooks/useCommunityPosts';
import { CommunityFeedTypeEnum } from '../types';
import { getCommunityFeedFilterTags } from '../utils/helpers';
import { useCommunityState } from '../hooks/CommunityContext';
import { TEENSPACE_DAY_END_SHIFT_HOURS } from '../constants';

const CommunityFeedContainer = () => {
  const {
    userID,
    clientTimezone,
    communityID,
    dailyPrompt,
    isLoadingDailyPrompt,
    isConnected,
    isConnectionError,
  } = useCommunityState();

  const [feedType, setFeedType] = useState<CommunityFeedTypeEnum>(CommunityFeedTypeEnum.TODAY);
  const tags = useMemo(
    () =>
      getCommunityFeedFilterTags({
        userID,
        timezone: clientTimezone,
        feedType,
        shiftHours: TEENSPACE_DAY_END_SHIFT_HOURS,
      }),
    [userID, clientTimezone, feedType]
  );

  const {
    posts,
    hasMore,
    onLoadMore,
    isLoading: isLoadingPosts,
  } = useCommunityPosts({ communityID, tags });
  return (
    <CommunityFeed
      feedType={feedType}
      handleSelectFeedType={(newFeedType: CommunityFeedTypeEnum) => {
        setFeedType(newFeedType);
      }}
      dailyPrompt={dailyPrompt}
      posts={posts}
      hasMore={hasMore}
      onLoadMore={onLoadMore}
      isLoadingDailyPrompt={isLoadingDailyPrompt}
      isLoadingPosts={isLoadingPosts}
      isConnected={isConnected}
      isConnectionError={isConnectionError}
    />
  );
};

export default CommunityFeedContainer;
