import Svg, { Path } from 'svgs';

function TopLeftCornerCurveSvg({ color }: { color: string }) {
  return (
    <Svg width={12} height={12} viewBox="0 0 12 12" fill="none">
      <Path d="M0 0v12C0 5.373 5.373 0 12 0H0z" fill={color} />
    </Svg>
  );
}

export default TopLeftCornerCurveSvg;
