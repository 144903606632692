import { ValueType } from 'react-select';
import {
  cancellationReasons,
  cancellationReasonsV2,
  noMatchesCancellationReasons,
  dissatisfiedWithProviderReasons,
} from '@talkspace/configs';
import { OptionType } from '@talkspace/react-toolkit';
import { BaseWizardState, baseWizardInitialState, keyValueToValueType } from 'stepWizard';
import { Flow } from '@/cancellationWizardsShared/utils/cancellationWizardTypes';

export interface State extends BaseWizardState {
  roomID: number;
  clientUserID: number;
  providerSwitched: boolean;
  canceledSubscription: boolean;
  cancellationReason: string;
  reasonForCancellation: ValueType<OptionType, true>;
  noMatchesReasonForCancellation: ValueType<OptionType, true>;
  cancellationReasonsV2: ValueType<OptionType, true>;
  cancellationReasonV2: string;
  dissatisfiedWithProviderReasons: ValueType<OptionType, true>;
  dissatisfiedWithProviderReason: string;
  isFeelingBetterReason: boolean;
  isDissatisfiedWithProviderReason: boolean;
  isDissatisfiedWithAppReason: boolean;
  isDissatisfiedWithProviderOther: boolean;
  isExpensiveReason: boolean;
  isOtherReason: boolean;
  flow?: Flow;
  experiment: object;
  experimentID: string;
  hasProvider: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const getInitialState = (
  clientUserID: number,
  roomID: number,
  source: string,
  contextID: string
): State => {
  return {
    ...baseWizardInitialState(source, contextID),
    roomID,
    clientUserID,
    providerSwitched: false,
    reasonForCancellation: keyValueToValueType(cancellationReasons),
    noMatchesReasonForCancellation: keyValueToValueType(noMatchesCancellationReasons),
    cancellationReason: '',
    cancellationReasonsV2: keyValueToValueType(cancellationReasonsV2),
    cancellationReasonV2: '',
    isFeelingBetterReason: false,
    isDissatisfiedWithProviderReason: false,
    isDissatisfiedWithAppReason: false,
    isExpensiveReason: false,
    isOtherReason: false,
    dissatisfiedWithProviderReasons: keyValueToValueType(dissatisfiedWithProviderReasons),
    dissatisfiedWithProviderReason: '',
    isDissatisfiedWithProviderOther: false,
    canceledSubscription: false,
    eventCategory: 'Cancellation',
    experiment: {},
    experimentID: '',
    hasProvider: true,
  };
};
