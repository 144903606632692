import { useReducer, useCallback, useRef } from 'react';
import moment from 'moment';
import { wizardActionReducer, wizardActionInitialState, WizardActionState } from 'stepWizard';
import { OfferApiHelper } from 'offer';
import myAccountApiHelper from '../../myAccount/utils/myAccountApiHelper';
import VoucherCouponApiHelper from '../../voucherCoupon/utils/apiHelper';
import cancellationWizardApiHelper, {
  CancelSubscriptionData,
} from '../utils/cancellationWizardApiHelper';

interface CancellationState extends WizardActionState {
  couponAppliedDate?: string;
  endOfBillingCycleDate?: string;
  cancellationReason?: string;
}

export default function useCancellationWizard(): [
  CancellationState,
  {
    pauseTherapy: (roomID: number, period?: number) => void;
    applyDiscount: (roomID: number, couponCode: string) => void;
    subscribeToMaintenancePlan: (roomID: number, maintenancePlanID: number) => void;
    cancelSubscription: (roomID: number, data: CancelSubscriptionData) => void;
    exitAction: () => void;
  }
] {
  const additionalStateProps = {
    couponAppliedDate: undefined,
    endOfBillingCycleDate: undefined,
  };

  const [state, dispatch] = useReducer(
    wizardActionReducer,
    wizardActionInitialState(additionalStateProps)
  );
  const apiRefMyAccount = useRef(myAccountApiHelper);
  const apiRefOffers = useRef(new OfferApiHelper());
  const apiRefCancellationWizard = useRef(cancellationWizardApiHelper);
  const apiRefApplyVoucherCoupon = useRef(VoucherCouponApiHelper);
  const maintenanceOfferID = 5;
  function pauseTherapy(roomID: number, period?: number) {
    dispatch({ type: 'requestAction' });
    apiRefMyAccount.current
      .pauseSubscription(roomID, period)
      .then(() => {
        dispatch({
          type: 'receiveAction',
        });
      })
      .catch((error) => {
        dispatch({
          type: 'setError',
          error: error.data?.error?.message || '',
        });
      });
  }

  function applyDiscount(roomID: number, couponCode: string) {
    dispatch({ type: 'requestAction' });
    apiRefApplyVoucherCoupon
      .current(roomID.toString(), couponCode)
      .then((data) => {
        const renewalDate =
          data && data.billing && data.billing.recurringDates && data.billing.recurringDates[0];
        const couponAppliedDate = moment(renewalDate).format('MMM DD, YYYY');
        dispatch({
          type: 'receiveAction',
          payload: { couponAppliedDate },
        });
      })
      .catch((error) => {
        dispatch({
          type: 'setError',
          error: (error && error.message) || '',
        });
      });
  }

  async function subscribeToMaintenancePlan(roomID: number, maintenancePlanID: number) {
    dispatch({ type: 'requestAction' });
    try {
      await apiRefOffers.current.postSubscribeToPlan({
        params: {
          plan_id: maintenancePlanID,
          offer_id: maintenanceOfferID,
          gid: roomID,
          switchRoom: false,
          freeLVS: false,
        },
      });
      dispatch({
        type: 'receiveAction',
      });
    } catch (error) {
      dispatch({
        type: 'setError',
        error: (error && error.message) || '',
      });
    }
  }

  function cancelSubscription(roomID: number, requestData: CancelSubscriptionData) {
    dispatch({ type: 'requestAction' });
    apiRefCancellationWizard.current
      .cancelSubscription(roomID, requestData)
      .then((data) => {
        const endOfSubscriptionDate = data?.expirationDate ? moment(data.expirationDate) : moment();
        dispatch({
          type: 'receiveAction',
          payload: { endOfBillingCycleDate: endOfSubscriptionDate.format('MMM DD, YYYY') },
        });
      })
      .catch((error) => {
        dispatch({
          type: 'setError',
          error: (error && error.message) || '',
        });
      });
  }

  function exitAction() {
    dispatch({ type: 'exitAction' });
  }

  return [
    state as CancellationState,
    {
      pauseTherapy: useCallback(pauseTherapy, []),
      applyDiscount: useCallback(applyDiscount, []),
      subscribeToMaintenancePlan: useCallback(subscribeToMaintenancePlan, []),
      cancelSubscription: useCallback(cancelSubscription, []),
      exitAction: useCallback(exitAction, []),
    },
  ];
}
