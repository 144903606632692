import { detectEnv } from '@/utils/configs';

type Environments = 'local' | 'test' | 'prod';

const getEnvironment = (): Environments => {
  const env = detectEnv();
  switch (env) {
    case 'canary':
    case 'test':
      return 'test';
    case 'localSSL':
    case 'local':
      return 'local';
    case 'prod':
      return 'prod';
    default:
      return 'local';
  }
};

type ExperimentIDs = string; // Could be better typed to be one of the possible experiments, but for now we have no need for that

export type ExperimentNames = 'OFFER_PLAN_BUILDER_V5' | 'HIDE_FAQ' | 'SIGNUP_WITH_PHONE';

export const allExperimentIDs: Record<
  ExperimentNames,
  { [envName in Environments]: ExperimentIDs }
> = {
  OFFER_PLAN_BUILDER_V5: {
    local: 'VWO-3',
    test: 'VWO-55',
    prod: 'VWO-45',
  },
  HIDE_FAQ: {
    local: 'VWO-4',
    test: 'VWO-57',
    prod: 'VWO-47',
  },
  SIGNUP_WITH_PHONE: {
    local: 'VWO-6',
    test: 'VWO-64',
    prod: 'VWO-54',
  },
};

export const getExperimentID = (name: keyof typeof allExperimentIDs): ExperimentIDs =>
  allExperimentIDs[name][getEnvironment()];

type ValueOf<T> = T[keyof T];

export interface Experiment<T extends number, K extends object> {
  description: string;
  values: Record<T, K>;
}

export interface RoomOfferVariant {
  planBuilder: boolean;
  variant?: number;
}

type RoomOfferExperiment = Experiment<0 | 1, RoomOfferVariant>;

type ShowFAQExperiment = Experiment<0 | 1, { showFAQ: boolean }>;

type collectPhoneOnSignupExperiment = Experiment<0 | 1, { showPhoneInput: boolean }>;

export interface Experiments
  extends Record<
    ExperimentNames,
    RoomOfferExperiment | ShowFAQExperiment | collectPhoneOnSignupExperiment
  > {
  OFFER_PLAN_BUILDER_V5: RoomOfferExperiment;
  HIDE_FAQ: ShowFAQExperiment;
  SIGNUP_WITH_PHONE: collectPhoneOnSignupExperiment;
}

const experiments: Experiments = {
  OFFER_PLAN_BUILDER_V5: {
    description: 'Planbuilder A/B',
    values: {
      0: { planBuilder: false },
      1: { planBuilder: true, variant: 1 },
    },
  },
  HIDE_FAQ: {
    description: 'Hide FAQ on checkout',
    values: {
      0: { showFAQ: true },
      1: { showFAQ: false },
    },
  },
  SIGNUP_WITH_PHONE: {
    description: 'Collect Phone number on signup',
    values: {
      0: { showPhoneInput: false },
      1: { showPhoneInput: true },
    },
  },
};

export type VariantName<T extends ExperimentNames> = keyof Experiments[T]['values'];
export type Variant<T extends ExperimentNames> = ValueOf<Experiments[T]['values']>;

export default experiments;
