/* eslint-disable import/prefer-default-export */
import moment from 'moment';

import { TFNewMemberNav } from '@talkspace/i18n/types';
import { ETherapistInfo, isDummyNoMatchesProvider } from '../entities/Therapist';
import { Subscription, InsuranceStatus } from '../types/Subscription';
import { isEAP, isDTE } from '../helpers/subscription';

export const getTherapistNameDisplayString = ({
  therapistInfo,
}: {
  therapistInfo: ETherapistInfo;
}): string => {
  const { title, firstName, lastName, licenses } = therapistInfo;
  if (isDummyNoMatchesProvider({ therapistName: firstName })) return firstName;
  const titleStr = title ? `${title} ` : '';
  const licenseStr = licenses?.length ? `, ${licenses[0]}` : '';
  return `${titleStr}${firstName} ${lastName}${licenseStr}`;
};

export const getPlanCoverageDisplayString = ({
  subscription,
  insuranceStatus,
}: {
  subscription: Subscription;
  insuranceStatus: InsuranceStatus | undefined;
}): string | undefined => {
  const {
    subscription: { isBH, isB2C },
  } = subscription;
  let result: string | undefined;
  if (isBH) {
    result = insuranceStatus?.payerName ? `Covered by ${insuranceStatus.payerName}` : undefined;
  } else if (isB2C) {
    result = 'Self-pay';
  } else if (isEAP({ subscription }) || isDTE({ subscription })) {
    result = 'Covered by Employer';
  }
  return result;
};

export const getCreditsDisplayString = ({
  subscription,
  useNewNav,
}: {
  subscription: Subscription;
  useNewNav: boolean;
}): string | undefined => {
  const {
    subscription: { endSubscriptionDate },
    planDetail,
  } = subscription;

  if (['eap', 'dte'].includes(planDetail?.accountType || '')) {
    return `Plan expires ${moment(endSubscriptionDate).format('M/D/YYYY')}`;
  }
  const isDTEOrB2CAccount =
    subscription?.planDetail?.accountType === 'dte' || subscription?.subscription.isB2C;
  const hasNoVideoCredits = subscription?.videoCredits?.length === 0;
  if (
    useNewNav &&
    isDTEOrB2CAccount &&
    hasNoVideoCredits &&
    subscription.subscription.renewalDate
  ) {
    return `Credits renew on ${moment(subscription?.subscription.renewalDate).format('M/D/YYYY')}`;
  }
  if (subscription?.videoCredits?.length) return 'Session credits available';

  return '';
};

export const getLastMessageTimeDisplayString = ({
  createdAt,
}: {
  createdAt: Date | undefined;
}): string => {
  if (!createdAt) return '';
  const messageMoment = moment(createdAt);
  const now = moment();
  const daysAgo = now.clone().startOf('day').diff(messageMoment.clone().startOf('day'), 'd');
  if (daysAgo === 0) {
    return messageMoment.format('h:mma');
  }
  if (daysAgo < 7) {
    return messageMoment.format('ddd');
  }
  const isSameYear = messageMoment.year() === now.year();
  if (isSameYear) return messageMoment.format('MMM D');
  return messageMoment.format('MMM D, YYYY');
};

export const getBookSessionCTAText = ({
  isMessagingEnabled,
}: {
  isMessagingEnabled: boolean;
}): string => {
  if (isMessagingEnabled) return 'Book or start a session';
  return 'Book a session';
};

export const getTransBookSessionCTAText = ({
  isMessagingEnabled,
  tnewMemberNav,
}: {
  isMessagingEnabled: boolean;
  tnewMemberNav: TFNewMemberNav;
}): string => {
  if (isMessagingEnabled)
    return tnewMemberNav('schedule.bookOr', 'Book or start a session', undefined);
  return tnewMemberNav('schedule.book', 'Book a session', undefined);
};
