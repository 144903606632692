import { Route, Switch } from '../core/routerLib';
import VoucherCouponContainer from './containers/VoucherCouponContainer';

const Routes = () => (
  <Switch>
    <Route path="/voucher-coupon/:code/room/:roomID" component={VoucherCouponContainer} />
  </Switch>
);

export default Routes;
