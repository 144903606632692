import { EmotionStyle, View, Large, BaseButton } from '@talkspace/react-toolkit';

import { Subscription } from 'ts-frontend/types';
import { useState } from 'react';
import styled from '@/core/styled';
import { getDefaultOfferByRoomType } from '@/utils/offerHelper';
import ManageSubscriptionCard from '../ManageSubscriptionCard';

const ClearButton = styled(BaseButton)(({ disabled, theme: { colors } }) => {
  return {
    padding: 0,
    margin: 0,
    backgroundColor: colors.white,
    ':hover': {
      cursor: disabled ? 'initial' : undefined,
    },
  };
});

const StyledViewAll = styled(Large)<{ isMobile: boolean }>(({ isMobile }) => {
  return {
    fontSize: isMobile ? 14 : 16,
  };
});

const StyledHeaderContainer = styled(View)<{ isMobile: boolean }>(
  ({ isMobile, theme: { colors } }) => {
    return {
      marginBottom: 10,
      minWidth: 335,
      maxWidth: isMobile ? '100%' : 500,
      padding: isMobile ? ' 0 20px' : '16px 0 8px 0',
      borderBottom: 'none',
      alignItems: 'center',
    };
  }
);

interface Props {
  filteredSubscriptions: Subscription[];
  heading: React.ReactNode | string;
  wrapperStyle?: EmotionStyle;
  isMobile: boolean;
  isInactivePlans?: boolean;
  handlePauseButtonPress: (roomId: number) => void;
  handleViewSuperbills: (roomId: number) => void;
  handleChangePlanButtonPress: (roomID: number, therapistID: number, offerID: number) => void;
  handleCancelPlanButtonPress: (
    roomID: number,
    therapistID: number,
    willCancelSessions: boolean
  ) => void;
  handleChangeProviderButtonOnPress: (roomID: number, therapistID: number) => void;
  handleNeedHelpPress?: () => void;
  resumeSubscriptionOnPress: (roomID: number) => void;
  undoCancelSubscriptionOnPress: (roomID: number) => void;
  updatePaymentDetailsOnPress?: () => void;
  handleUpdateCoverageButtonPress?: (roomID: number, previousTherapistId: number) => void;
  currentRoomID: number;
  roomCardRef: React.RefObject<HTMLDivElement>;
  inNavShell?: boolean;
}
const SubscriptionSection = ({
  filteredSubscriptions,
  heading,
  wrapperStyle,
  isMobile,
  isInactivePlans,
  handlePauseButtonPress,
  handleViewSuperbills,
  handleChangePlanButtonPress,
  handleCancelPlanButtonPress,
  handleUpdateCoverageButtonPress,
  handleChangeProviderButtonOnPress,
  handleNeedHelpPress,
  resumeSubscriptionOnPress,
  undoCancelSubscriptionOnPress,
  updatePaymentDetailsOnPress,
  currentRoomID,
  roomCardRef,
  inNavShell,
}: Props) => {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll((current) => !current);
  };

  const getDisplayedSubscriptions = () => {
    if (isInactivePlans && !showAll) {
      return [filteredSubscriptions[0]];
    }

    return filteredSubscriptions;
  };

  if (!filteredSubscriptions.length) {
    return null;
  }

  return (
    <View style={wrapperStyle}>
      <StyledHeaderContainer row justify="space-between" isMobile={isMobile}>
        {heading}
        {isInactivePlans && filteredSubscriptions.length > 1 && (
          <ClearButton
            type="button"
            onPress={toggleShowAll}
            data-qa="subscriptionSectionViewAllButton"
          >
            <StyledViewAll variant="largeBoldWideGreen" isMobile={isMobile}>
              {showAll ? 'View less' : `View all (${filteredSubscriptions.length})`}
            </StyledViewAll>
          </ClearButton>
        )}
      </StyledHeaderContainer>
      {getDisplayedSubscriptions().map((room, index) => {
        const offerID = getDefaultOfferByRoomType(room.roomType);
        return (
          <ManageSubscriptionCard
            key={room.id}
            room={room}
            offerID={offerID}
            isMobile={isMobile}
            handlePauseButtonPress={() => handlePauseButtonPress(room.id)}
            handleViewSuperbills={() => handleViewSuperbills(room.id)}
            handleChangePlanButtonPress={() =>
              handleChangePlanButtonPress(
                room.id,
                room.therapistID,
                getDefaultOfferByRoomType(room.roomType)
              )
            }
            handleCancelPlanButtonPress={handleCancelPlanButtonPress}
            {...(handleUpdateCoverageButtonPress && {
              handleUpdateCoverageButtonPress: () =>
                handleUpdateCoverageButtonPress(room.id, room.therapistID),
            })}
            resumeSubscriptionOnPress={() => resumeSubscriptionOnPress(room.id)}
            undoCancelSubscriptionOnPress={() => undoCancelSubscriptionOnPress(room.id)}
            handleChangeProviderButtonOnPress={() =>
              handleChangeProviderButtonOnPress(room.id, room.therapistID)
            }
            handleNeedHelpPress={handleNeedHelpPress}
            updatePaymentDetailsOnPress={updatePaymentDetailsOnPress}
            isFirst={index === 0}
            cardRef={room.id === currentRoomID ? roomCardRef : undefined}
            index={index}
            inNavShell={inNavShell}
          />
        );
      })}
    </View>
  );
};

export default SubscriptionSection;
