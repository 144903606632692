import { FunctionComponent, useRef, useState, useEffect } from 'react';
import { TouchableView, Spinner, BaseButton, useEmotionTheme } from '@talkspace/react-toolkit';
import { Switch, Route } from 'react-router';
import Svg, { Path, Circle, G } from 'svgs';
import { COLORS } from 'chat/utils/design';
import { RouteComponentProps } from '../../../core/routerLib/routerLib';
import SchedulerSelectCredit from '../SchedulerSelectCredit';
import SchedulerSelectTimeslot from '../SchedulerSelectTimeslot';
import SchedulerConfirmBooking from '../SchedulerConfirmBooking';
import SchedulerError from '../SchedulerError';
import ApiHelper from '../../utils/ApiHelper';
import useScheduler from '../../hooks/useScheduler';
import { BookingScreenType, ErrorScreen, TherapistTimeslot } from '../../reducers/schedulerReducer';
import styled, { EmotionStyle } from '../../../core/styled';
import { webOnlyStyle } from '../../../core/styled/styleHelpers';

const CloseButtonSVG = styled(Svg)(({ theme: { colors } }) => {
  return {
    ...webOnlyStyle({
      display: 'block',
      '&:hover circle': {
        fill: colors.periwinkleGrey,
      },
      '&:hover path': {
        fill: '#546074',
      },
    }),
  };
});

const fullScreenStyles = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  borderRadius: 0,
  height: '100vh',
  width: '100%',
};
const Wrapper = styled(TouchableView)<{
  isInitialLoad: boolean;
  bookingScreen: BookingScreenType;
  errorScreen?: ErrorScreen;
}>(({ isInitialLoad, bookingScreen, errorScreen, theme: { window } }) => {
  const height =
    isInitialLoad ||
    bookingScreen === 'formSubmitSuccess' ||
    bookingScreen === 'formSubmitError' ||
    errorScreen
      ? 390
      : 571;
  return {
    borderRadius: 10,
    backgroundColor: COLORS.white,
    alignItems: 'stretch',
    overflow: 'hidden',
    width: 500,
    margin: 'auto',
    height,
    ...webOnlyStyle({
      transition: '0.2s',
      boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.4)',
      ...(window.isMobile ? fullScreenStyles : {}),
    }),
  };
});

const CloseButton = ({
  onPress,
  width = 35,
  height = 35,
  backgroundColor,
  color,
  style,
  ...props
}: {
  onPress: () => void;
  width?: number;
  height?: number;
  backgroundColor?: string;
  color?: string;
  style?: EmotionStyle;
}) => {
  const { colors } = useEmotionTheme();
  return (
    <BaseButton onPress={onPress} {...props}>
      <CloseButtonSVG width={width} height={height} viewBox="0 0 35 35">
        <G
          transform="translate(-325 -16) translate(325 16)"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <Circle
            fill={backgroundColor || colors.permaLinkWaterGrey}
            cx={17.5}
            cy={17.5}
            r={17.5}
          />
          <Path
            d="M17.5 16.259l5.013-5.004a.87.87 0 011.233.002.875.875 0 01-.002 1.235L18.726 17.5l5.018 5.008c.34.34.342.893.002 1.235a.87.87 0 01-1.233.002L17.5 18.741l-5.013 5.004a.87.87 0 01-1.233-.002.875.875 0 01.002-1.235l5.018-5.008-5.018-5.008a.875.875 0 01-.002-1.235.87.87 0 011.233-.002l5.013 5.004z"
            fill={color || colors.slateGrey}
            fillRule="nonzero"
          />
        </G>
      </CloseButtonSVG>
    </BaseButton>
  );
};

const SchedulerAction: FunctionComponent<
  RouteComponentProps<
    {
      roomID: number;
    },
    {},
    {
      roomID?: number;
      messageSentBy?: number;
      therapistUserID?: number;
      therapistFirstName?: string;
    }
  >
> = ({ history, match, location }) => {
  const [state, { getSchedulerData }] = useScheduler();
  const apiRef = useRef(new ApiHelper());
  const { current: api } = apiRef;
  useEffect(
    () => () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      apiRef.current.cancelAll();
    },
    []
  );
  const [therapistUserID, setTherapistUserID] = useState<number | undefined>(undefined);
  const [roomID, setRoomID] = useState<number | undefined>(undefined);
  const [therapistFirstName, setTherapistFirstName] = useState<string | undefined>(undefined);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  useEffect(() => {
    if (location.state) {
      if (location.state.messageSentBy) getSchedulerData(location.state.messageSentBy, roomID);
      if (location.state.therapistUserID && !therapistUserID)
        setTherapistUserID(location.state.therapistUserID);
      if (location.state.therapistFirstName && !therapistFirstName)
        setTherapistFirstName(location.state.therapistFirstName);
      if (location.state.roomID && !roomID) setRoomID(location.state.roomID);
    }
  }, [
    match.params.roomID,
    getSchedulerData,
    location,
    therapistUserID,
    roomID,
    therapistFirstName,
  ]);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [selectedBookingDuration, setSelectedBookingDuration] = useState<number | undefined>(
    undefined
  );

  const [selectedCreditDuration, setSelectedCreditDuration] = useState<number | undefined>(
    undefined
  );

  const [errorScreen, setErrorScreen] = useState<ErrorScreen | undefined>(undefined);

  const [bookingScreen, setBookingScreen] = useState<BookingScreenType>('confirmScreen');

  const [selectedTimeslot, setSelectedTimeslot] = useState<TherapistTimeslot | undefined>(
    undefined
  );

  useEffect(() => {
    if (
      state.videoCreditDurations &&
      state.videoCreditDurations.length &&
      !selectedCreditDuration
    ) {
      setSelectedBookingDuration(state.videoCreditDurations[0]);
      setSelectedCreditDuration(state.videoCreditDurations[0]);
      history.push('/scheduler/select-credit');
      setIsInitialLoad(false);
    }
  }, [history, selectedCreditDuration, state.videoCreditDurations]);

  useEffect(() => {
    if (
      !selectedTimeslot &&
      selectedBookingDuration &&
      state.therapistTimeslotsByDay &&
      state.therapistTimeslotsByDay[selectedBookingDuration] &&
      state.therapistTimeslotsByDay[selectedBookingDuration][0].timeslots.length
    ) {
      setSelectedTimeslot(state.therapistTimeslotsByDay[selectedBookingDuration][0].timeslots[0]);
    }
  }, [selectedBookingDuration, selectedTimeslot, state.therapistTimeslotsByDay]);

  useEffect(() => {
    if (
      !errorScreen &&
      (state.hasVideoCredits === false ||
        state.hasTimeslotForAtLeastOneDuration === false ||
        state.isError)
    ) {
      if (state.hasVideoCredits === false) {
        setErrorScreen('noVideoCredits');
      } else if (state.hasTimeslotForAtLeastOneDuration === false) {
        setErrorScreen('noAvailability');
      } else if (state.isError) setErrorScreen('error');
      history.push('/scheduler/error');
    }
  }, [
    errorScreen,
    history,
    state.hasTimeslotForAtLeastOneDuration,
    state.hasVideoCredits,
    state.isError,
  ]);

  const handleChooseCredit = (creditDuration) => {
    const bookingDuration = creditDuration;
    setSelectedBookingDuration(bookingDuration);
    setSelectedCreditDuration(creditDuration);
  };

  const handleConfirmAppointment = () => {
    setIsSubmitting(true);
    if (!isSubmitting && selectedTimeslot && roomID) {
      api
        .createBooking(roomID, {
          bookingType: 'psychiatry',
          start: selectedTimeslot.start,
          end: selectedTimeslot.end,
        })
        .then((success) => {
          setBookingScreen(success ? 'formSubmitSuccess' : 'formSubmitError');
          setIsSubmitting(false);
        })
        .catch(api.dismissIfCancelled)
        .catch(() => {
          setBookingScreen('formSubmitError');
          setIsSubmitting(false);
        });
    } else {
      setBookingScreen('formSubmitError');
      setIsSubmitting(false);
    }
  };

  const possibleTimeslots =
    state.therapistTimeslotsByDay && selectedBookingDuration
      ? state.therapistTimeslotsByDay[selectedBookingDuration]
      : [];

  return (
    <Wrapper isInitialLoad={isInitialLoad} bookingScreen={bookingScreen} errorScreen={errorScreen}>
      <CloseButton
        style={{
          width: 35,
          paddingBottom: 16,
          marginRight: 16,
          marginTop: 16,
          marginLeft: 'auto',
          outline: 'none',
        }}
        onPress={() => history.push('/')}
      />
      <Switch>
        <Route
          exact
          path="/scheduler"
          render={() => (
            <Spinner
              style={{ width: 50, height: 50 }}
              containerStyle={{ marginTop: -20 }}
              isLoading
            />
          )}
        />
        <Route
          exact
          path="/scheduler/error"
          render={() => (
            <SchedulerError errorScreen={errorScreen} therapistFirstName={therapistFirstName} />
          )}
        />
        <Route
          exact
          path="/scheduler/select-credit"
          render={() => (
            <SchedulerSelectCredit
              videoCredits={state.videoCredits}
              selectedCreditDuration={selectedCreditDuration}
              handleChooseCredit={handleChooseCredit}
            />
          )}
        />
        <Route
          exact
          path="/scheduler/select-timeslot"
          render={() => (
            <SchedulerSelectTimeslot
              roomID={roomID}
              localTimezone={state.localTimezone}
              setSelectedTimeslot={setSelectedTimeslot}
              selectedTimeslot={selectedTimeslot}
              timeslots={possibleTimeslots}
            />
          )}
        />
        <Route
          exact
          path="/scheduler/confirm-booking"
          render={() => (
            <SchedulerConfirmBooking
              bookingScreen={bookingScreen}
              handleConfirmAppointment={handleConfirmAppointment}
              isSubmitting={isSubmitting}
              selectedTimeslot={selectedTimeslot}
              selectedCreditDuration={selectedCreditDuration}
              therapistUserID={therapistUserID}
            />
          )}
        />
      </Switch>
    </Wrapper>
  );
};

export default SchedulerAction;
