import { AppSource, PaymentDetails, ChangePlanCheckoutInfo, Subscription } from 'ts-frontend/types';
import { callBasedOnMode } from 'ts-frontend/apiHelpers';
import { transformPlan } from 'ts-frontend/helpers/billingUtils';
import { isActive } from 'ts-frontend/entities/Room';
import { getUserData } from '@/auth/helpers/token';
import cancelPromiseHelper from '@/core/api/cancelPromiseHelper';
import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';
import { OfferData, OfferSource } from '../types';
import { transformOffer } from './dataTransforms';
import paymentAPI, { ValidateCouponResponse } from './paymentApiHelper';
import accessTokenHelper from '@/utils/accessTokenHelper';
import API from './switchTherapistApiHelper';

export interface ApiResponse<T = any> {
  data?: T;
  status?: number;
}

function getOffer(offerID: number, planID?: number) {
  return apiWrapper
    .get(
      `${apiHelper().apiEndpoint}/v2/payments/offers/${offerID}${planID ? `?planID=${planID}` : ''}`
    )
    .then((res) => transformOffer(res.data.data));
}

async function getOfferQM(
  offerID: number,
  planID?: number,
  userCountry?: string,
  userState?: string
) {
  const { token } = await accessTokenHelper.getOffersAccessToken();

  const res = await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/payments/offers/qm`,
    {
      offerID,
      planID,
      userCountry,
      userState,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  return transformOffer(res);
}

function getOfferTherapist(offerID: number, planID?: number, roomID?: number) {
  return apiWrapper
    .get(
      `${apiHelper().apiEndpoint}/v2/payments/offers/${offerID}/room/${roomID}${
        planID ? `?planID=${planID}` : ''
      }`
    )
    .then((res) => transformOffer(res.data.data));
}

async function getPlan(planID: number, planName?: string) {
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v2/payments/plans/${planID}`)
    .then((res) => transformPlan(res.data.data, planName));
}

async function getBillingInfo() {
  const { id: userID } = getUserData();
  const response = await paymentAPI.getPaymentDetails(userID);
  if (!response) {
    throw new Error('Error loading payment details.');
  }
  return response.billingInfo;
}

async function postSubscribeToPlan(payload): Promise<{
  success: boolean;
  isFirstPurchase: boolean;
  isTestUser: boolean;
  data?: any;
}> {
  const {
    offer_id: offerID,
    externalProcessorToken: cardToken,
    couponCode,
    boughtFrom,
    gid: roomID,
    plan_id: planID,
    therapistId,
    funnelName,
  } = payload.params;
  const internalPayload = {
    offerID,
    cardToken,
    couponCode,
    boughtFrom,
    targetTherapistID: therapistId,
    funnelName,
  };
  const res = await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/payments/rooms/${roomID}/plan/${planID}/subscribe`,
    internalPayload
  );
  if (therapistId) {
    await API.switchTherapist({
      roomID,
      newTherapistID: therapistId,
      consentSigned: false,
      shareHistory: undefined,
    });
  }
  return {
    success: true,
    isFirstPurchase: res.data.data.isFirstPurchase,
    isTestUser: res.data.data.isTestUser,
  };
}

async function getChangePlanCheckoutInfo(
  roomID: number,
  newPlanID: number
): Promise<ChangePlanCheckoutInfo | undefined> {
  let result;
  const { data: response } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/payments/rooms/${roomID}/plan/${newPlanID}/checkout-info`
  );
  if (response) {
    result = {
      customerBalanceCents: response.data.customerBalanceCents,
      prorationAmountCents: response.data.prorationAmountCents,
      renewDate: response.data.renewDate ? new Date(response.data.renewDate) : undefined,
    };
  }

  return result;
}

async function getSubscriptions(
  clientUserID: number,
  roomID?: number,
  includePaymentDetails?: boolean
): Promise<ApiResponse<{ data: Subscription[] }>> {
  const queryString = new URLSearchParams();
  if (roomID) {
    queryString.set('roomID', roomID.toString());
  }
  // TODO: Deprecate this to use one include query param separated by commas
  if (includePaymentDetails) {
    queryString.set('include', 'paymentDetails');
  }

  return apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/subscriptions?${queryString.toString()}`
  );
}

export default class OfferApiHelper {
  private wrapWithCancel: <T>(req: Promise<T>) => Promise<T>;

  public reset: () => void;

  public dismissIfCancelled: (err: Error) => void;

  public cancelAll: () => void;

  private mode: OfferSource;

  constructor(mode?: OfferSource | null) {
    const { cancelAll, reset, dismissIfCancelled, wrapWithCancel } = cancelPromiseHelper();
    this.wrapWithCancel = wrapWithCancel;
    this.reset = reset;
    this.dismissIfCancelled = dismissIfCancelled;
    this.cancelAll = cancelAll;
    this.mode = mode || AppSource.client;
  }

  private getOffer(
    offerID: number,
    planID?: number,
    roomID?: number,
    userCountry?: string,
    userState?: string
  ): Promise<OfferData> {
    return callBasedOnMode(this.mode, {
      [AppSource.client]: () => this.wrapWithCancel<OfferData>(getOffer(offerID, planID)),
      [AppSource.therapist]: () =>
        this.wrapWithCancel<OfferData>(getOfferTherapist(offerID, planID, roomID)),
      [AppSource.qm]: () =>
        this.wrapWithCancel<OfferData>(getOfferQM(offerID, planID, userCountry, userState)),
    });
  }

  private async getCurrentPlan(roomID: number) {
    if (this.mode !== AppSource.client) return undefined;
    const { id: clientUserID } = getUserData();

    if (!clientUserID) return undefined;

    const response = await this.wrapWithCancel(getSubscriptions(clientUserID, roomID));

    const data = response.data?.data[0] as Subscription;

    if (!isActive(data.status)) {
      return undefined;
    }

    const currentPlanID = data.subscription.planID;
    if (!currentPlanID) {
      return undefined;
    }

    return getPlan(currentPlanID, data.subscription.name);
  }

  public async getOfferData(
    offerID: number,
    planID?: number,
    roomID?: number,
    userCountry?: string,
    userState?: string
  ) {
    const [offer, currentPlan] = await Promise.all([
      this.getOffer(offerID, planID, roomID, userCountry, userState),
      roomID ? this.getCurrentPlan(roomID) : Promise.resolve(undefined),
    ]);
    const trialOfferPrice = offer && offer.trialable ? offer.trialValue : undefined;
    return { offer, currentPlan, trialOfferPrice, id: offer.id };
  }

  public getPaymentDetailsInfo(): Promise<PaymentDetails | undefined> {
    return callBasedOnMode(this.mode, {
      [AppSource.client]: () => this.wrapWithCancel(getBillingInfo()),
      [AppSource.therapist]: () => Promise.resolve(undefined),
      [AppSource.qm]: () => Promise.resolve(undefined),
    });
  }

  public postSubscribeToPlan(payload) {
    return this.wrapWithCancel(postSubscribeToPlan(payload));
  }

  public validateCoupon(
    couponCode: string,
    planID?: number
  ): Promise<ValidateCouponResponse['data']> {
    return callBasedOnMode(this.mode, {
      [AppSource.client]: () => this.wrapWithCancel(paymentAPI.validateCoupon(couponCode, planID)),
      [AppSource.therapist]: () =>
        this.wrapWithCancel(paymentAPI.validateCoupon(couponCode, planID)),
      [AppSource.qm]: () => this.wrapWithCancel(paymentAPI.validateCouponQM(couponCode, planID)),
    });
  }

  public getChangePlanCheckoutInfo(roomID: number, newPlanID: number) {
    return this.wrapWithCancel(getChangePlanCheckoutInfo(roomID, newPlanID));
  }

  public setMode(source: OfferSource | null) {
    this.mode = source || AppSource.client;
  }
}
