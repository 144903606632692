import { useEffect, useState } from 'react';

import { EmotionStyle, ExtraHuge, GreenCircleCheckMarkV2, View } from '@talkspace/react-toolkit';
import { useCloseModal } from '../../utils/ModalsContextProvider';

const TIME_TO_ANIMATE_MS = 300;
const TIME_TO_START_IN_ANIMATION_MS = 400;
const TIME_WITH_CONTENT_FULLY_VISIBLE = 1000;

const TIME_TO_START_OUT_ANIMATION_MS =
  TIME_TO_START_IN_ANIMATION_MS + TIME_TO_ANIMATE_MS + TIME_WITH_CONTENT_FULLY_VISIBLE;
const TIME_TO_CLOSE_MODAL = TIME_TO_START_OUT_ANIMATION_MS + TIME_TO_ANIMATE_MS - 400; // TODO: is there solution without the magic number? The closeModal is slow so starting closing earlier makes it smoother
const TRANSITION = `opacity ease-out ${TIME_TO_ANIMATE_MS / 1000}s`;

const CheckInDoneV3 = () => {
  const closeModal = useCloseModal();

  const [transitionStyle, setTransitionStyle] = useState<EmotionStyle>({});

  useEffect(() => {
    const animateInTimeout = setTimeout(
      () =>
        setTransitionStyle({
          transition: TRANSITION,
          opacity: 1,
        }),
      TIME_TO_START_IN_ANIMATION_MS
    );
    const animateOutTimeout = setTimeout(() => {
      setTransitionStyle({
        transition: TRANSITION,
        opacity: 0,
      });
    }, 1000);

    const closeTimeout = setTimeout(closeModal, TIME_TO_CLOSE_MODAL);

    return () => {
      clearTimeout(closeTimeout);
      clearTimeout(animateInTimeout);
      clearTimeout(animateOutTimeout);
    };
  }, [closeModal]);

  return (
    <View
      align="center"
      style={{
        padding: '132px 32px',
        opacity: 0,
        ...transitionStyle,
      }}
    >
      <GreenCircleCheckMarkV2 />
      <ExtraHuge style={{ marginTop: 24, textAlign: 'center' }}>
        Thank you for your feedback
      </ExtraHuge>
    </View>
  );
};

export default CheckInDoneV3;
