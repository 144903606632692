import { FunctionComponent } from 'react';
import {
  Big,
  PanelHeader,
  ScrollView,
  useScrollIntoInputs,
  View,
  TSLogo,
  BackNextButton,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';

interface Props {
  onScrollChange: (scrollTop: number) => void;
  onCloseButtonClick: () => void;
  showBackButton?: boolean;
  showCloseButton?: boolean;
  title?: string;
}

const ResponsiveScreen: FunctionComponent<Props> = ({
  children,
  onScrollChange,
  showBackButton,
  showCloseButton,
  title,
  onCloseButtonClick,
}) => {
  useScrollIntoInputs();
  const { colors } = useEmotionTheme();
  const { brandRefreshChanges } = useFlags();

  return (
    <ScrollView
      onScrollChange={onScrollChange}
      style={{ height: document.documentElement.clientHeight }}
    >
      <PanelHeader
        style={{ height: 67, flex: 'none', borderBottom: '1px solid #D8D8E0' }}
        renderLeft={() => (
          <>
            {showBackButton && (
              <View row align="center">
                <BackNextButton
                  style={{ display: 'inline-block', borderRadius: 5 }}
                  roundedFocusStyle
                  primaryColor={colors.green}
                />
              </View>
            )}
            {title ? (
              <Big>{title}</Big>
            ) : (
              <TSLogo style={{ margin: 0 }} variant={brandRefreshChanges ? '2024' : 'old'} />
            )}
          </>
        )}
        renderRight={showCloseButton ? undefined : () => <View />}
        onRightPress={showCloseButton ? onCloseButtonClick : undefined}
      />
      <View>{children}</View>
    </ScrollView>
  );
};

export default ResponsiveScreen;
