import { useQuery } from 'react-query';
import { NativeDispatcherData } from '@talkspace/ionic-talkspace';
import apiHelper from '@/utils/api';
import apiWrapper from '../core/api/apiWrapper';
import { entrypointQueryKey } from '../utils/queryKeys';

export interface EntrypointQueryProps {
  attribution: {
    funnelVariation: string;
    roomType: string;
    registrationUrl: string;
    referrerUrl: string;
  };
  distinctID: string;
  nativeAttribution: NativeDispatcherData;
}

interface EntrypointQueryData {
  goto: 'REDIRECT_URL' | 'DEFAULT_SIGNUP' | 'LOGIN';
  params: {
    redirectUrl: string | null;
    sessionID: string;
    accessToken?: string;
  };
}

const fetchEntrypoint =
  ({ attribution, distinctID, nativeAttribution }: Partial<EntrypointQueryProps>) =>
  async (): Promise<EntrypointQueryData> => {
    if (!attribution || !distinctID) {
      // Shouldn't happen
      throw new Error('useQueryEntrypoint got invalid data');
    }
    return apiWrapper
      .post<{ data: EntrypointQueryData }>(
        `${apiHelper().quickMatchApiEndpoint}/api/v3/dispatcher/entrypoint`,
        {
          attrLandingPage: attribution,
          attrMP: {
            distinctID,
          },
          ...nativeAttribution,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => res.data.data);
  };

const useQueryEntrypoint = (
  { attribution, distinctID, nativeAttribution }: Partial<EntrypointQueryProps>,
  enabled: boolean = false
) =>
  useQuery<void | EntrypointQueryData, Error>({
    queryKey: entrypointQueryKey({ attribution, distinctID, nativeAttribution }),
    queryFn: fetchEntrypoint({ attribution, distinctID, nativeAttribution }),
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!(attribution && distinctID && enabled),
  });

export default useQueryEntrypoint;
