import { createContext, useCallback, useContext, useReducer } from 'react';
import {
  initialState,
  personalInformationReducer,
  Action,
  State,
} from '../reducers/personalInformation';
import API from '../utils/myAccountApiHelper';
import { getUserData } from '../../auth/helpers/token';
import { EmergencyContactData, MyInformationData } from '../types';

interface PersonalInformationActions {
  getMyInformation: () => Promise<void>;
  updateMyInformation: (data: MyInformationData) => Promise<void>;
  getEmergencyContact: () => Promise<void>;
  updateEmergencyContact: (data: EmergencyContactData) => Promise<void>;
}
const PersonalInformationStateContext = createContext<State | undefined>(undefined);
const PersonalInformationActionsContext = createContext<PersonalInformationActions | undefined>(
  undefined
);

function PersonalInformationContextProvider({ children }) {
  const [state, dispatch] = useReducer<(prevState: State, action: Action) => State>(
    personalInformationReducer,
    initialState
  );

  const getMyInformation = (): Promise<void> => {
    dispatch({ type: 'requestGetMyInformation' });
    const { id } = getUserData();
    return API.getMyInformation(id)
      .then(({ data: { data } }) => {
        dispatch({ type: 'receiveGetMyInformation', payload: data });
      })
      .catch((err) => {
        dispatch({ type: 'setMyInformationError', error: 'Error getting my information' });
      });
  };

  const updateMyInformation = (data: MyInformationData): Promise<void> => {
    dispatch({ type: 'requestUpdateMyInformation', payload: data });
    const { id } = getUserData();
    return API.patchMyInformation(id, data)
      .then(() => {
        dispatch({ type: 'receiveUpdateMyInformation', payload: data });
      })
      .catch((err) => {
        dispatch({ type: 'setMyInformationError', error: 'Error updating my information' });
      });
  };

  const getEmergencyContact = (): Promise<void> => {
    dispatch({ type: 'requestGetEmergencyContact' });
    const { id } = getUserData();
    return API.getEmergencyContact(id)
      .then(({ data: { data } }) => {
        dispatch({ type: 'receiveGetEmergencyContact', payload: data });
      })
      .catch((err) => {
        dispatch({ type: 'setEmergencyContactError', error: 'Error getting emergency contact' });
      });
  };

  const updateEmergencyContact = (data: EmergencyContactData): Promise<void> => {
    dispatch({ type: 'requestUpdateEmergencyContact', payload: data });
    const { id } = getUserData();
    return API.patchEmergencyContact(id, data)
      .then(() => {
        dispatch({ type: 'receiveUpdateEmergencyContact', payload: data });
      })
      .catch((err) => {
        dispatch({ type: 'setEmergencyContactError', error: 'Error updating emergency contact' });
      });
  };

  const actions = {
    getMyInformation: useCallback(getMyInformation, []),
    getEmergencyContact: useCallback(getEmergencyContact, []),
    updateMyInformation: useCallback(updateMyInformation, []),
    updateEmergencyContact: useCallback(updateEmergencyContact, []),
  };

  return (
    <PersonalInformationStateContext.Provider value={state}>
      <PersonalInformationActionsContext.Provider value={actions}>
        {children}
      </PersonalInformationActionsContext.Provider>
    </PersonalInformationStateContext.Provider>
  );
}

function usePersonalInformationState() {
  const context = useContext(PersonalInformationStateContext);
  if (context === undefined)
    throw new Error(
      'usePersonalInformationState must be used within PersonalInformationStateContext'
    );
  return context;
}

function usePersonalInformationActions() {
  const context = useContext(PersonalInformationActionsContext);
  if (context === undefined)
    throw new Error(
      'usePersonalInformationActions must be used within PersonalInformationActionsContext'
    );
  return context;
}

export {
  PersonalInformationContextProvider,
  usePersonalInformationActions,
  usePersonalInformationState,
};
