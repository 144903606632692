import React from 'react';
import {
  View,
  SpacingView,
  TextDS,
  ChevronRight,
  spacing,
  TouchableViewProps,
} from '@talkspace/react-toolkit';
import styled from '../../../core/styled';

const { space200 } = spacing;

const LeftColumn = styled(View)(() => {
  return {
    width: 40,
    marginRight: space200,
  };
});

export interface HelpCardProps extends Omit<TouchableViewProps, 'style'> {
  Icon?: React.ReactNode;
  titleText?: string | React.ReactNode;
  bodyText?: string | React.ReactNode;
}

const HelpCard = (props: HelpCardProps) => {
  const { Icon, titleText, bodyText } = props;

  return (
    <View row flex={1}>
      <LeftColumn align="center" justify="start">
        {Icon}
      </LeftColumn>
      <View row align="center" justify="space-between" flex={1}>
        <SpacingView itemSpacing="space050">
          <TextDS variant="body" colorRole="textDefault">
            {titleText}
          </TextDS>
          <TextDS variant="bodySm" colorRole="textSubtlest">
            {bodyText}
          </TextDS>
        </SpacingView>
        <View justify="center">
          <ChevronRight colorType="brand" />
        </View>
      </View>
    </View>
  );
};

export default HelpCard;
