import { OptionType } from '@talkspace/react-toolkit';
import { BaseWizardState, baseWizardInitialState } from 'stepWizard';
import { Charge } from '../utils/refundWizardTypes';

/* Reasons are in order of UI appearance,
and values within reasons are in order of addition of new reasons.
Done to preserve analytics history. */

export const REFUND_REASONS_SUBSCRIPTION = {
  I_WANT_TO_CANCEL: {
    label:
      'I want to cancel my subscription today and be refunded for the remaining service I had paid for',
    value: 0,
  },
  I_AM_APPLYING_INSURANCE: {
    label:
      'I am applying insurance coverage to a new subscription and need to cancel my full price subscription',
    value: 3,
  },
  I_HAD_ISSUES_WITH_VIDEO: { label: 'I had issues with a live video session', value: 1 },
  OTHER: { label: 'Other', value: 2 },
};

export const REFUND_REASONS_LVS = {
  IM_NOT_GOING_TO_USE_CREDIT: { label: 'I’m not going to use this session credit', value: 0 },
  OTHER: { label: 'Other', value: 1 },
};

export type RefundWizardSource = 'email' | 'payment-and-plan';

export interface State extends BaseWizardState {
  source: RefundWizardSource;
  charges: Charge[] | undefined;
  refundableChargesDisplay: OptionType<string | number>[];
  clientUserID: number;
  roomID: number | undefined;
  refundApplied: boolean;
  supportResponseInDays: number;
  selectedDropdownChargeIndex: string;
  selectedRefundableCharge: Charge | undefined;
  selectedFormattedRefundableAmount: string;
  selectedRefundReasonLVSIndex: string;
  selectedRefundReasonSubscriptionIndex: string;
  refundReasonsLVS: OptionType<number>[];
  refundReasonsSubscription: OptionType<number>[];
  supportRequestSubmitted: boolean;
  isDisplayRefundsFlow: boolean;
  isDontDisplayRefundsFlow: boolean;
  isRefundSelectedFlow: boolean;
  isRefundNotSelectedFlow: boolean;
  isRedeemedLVSFlow: boolean;
  isNonRedeemedLVSFlow: boolean;
  isNotSelectedRefundReasonLVS: boolean;
  isRefundableSubscriptionFlow: boolean;
  isNonRefundableSubscriptionFlow: boolean;
  isNonCancelledSubscriptionFlow: boolean;
  iWantToCancelRefundReasonSubscription: boolean;
  videoIssuesRefundReasonSubscription: boolean;
  otherRefundReasonSubscription: boolean;
  isRefundableCopayFlow: boolean;
  isNonRefundableCopayFlow: boolean;
  LVSOtherRefundReason: string;
  SubscriptionOtherRefundReason: string;
}

// eslint-disable-next-line import/prefer-default-export
export const getInitialState = (
  clientUserID: number,
  source: RefundWizardSource,
  contextID: string
): State => {
  return {
    ...baseWizardInitialState(source, contextID),
    source,
    clientUserID,
    roomID: undefined,
    charges: undefined,
    refundApplied: false,
    supportResponseInDays: 10,
    selectedDropdownChargeIndex: '',
    selectedRefundableCharge: undefined,
    selectedFormattedRefundableAmount: '',
    selectedRefundReasonLVSIndex: '',
    selectedRefundReasonSubscriptionIndex: '',
    refundableChargesDisplay: [],
    refundReasonsLVS: Object.values(REFUND_REASONS_LVS),
    refundReasonsSubscription: Object.values(REFUND_REASONS_SUBSCRIPTION),
    supportRequestSubmitted: false,
    isDisplayRefundsFlow: false,
    isDontDisplayRefundsFlow: false,
    isRefundSelectedFlow: false,
    isRefundNotSelectedFlow: false,
    isNonCancelledSubscriptionFlow: false,
    iWantToCancelRefundReasonSubscription: false,
    videoIssuesRefundReasonSubscription: false,
    otherRefundReasonSubscription: false,
    isRedeemedLVSFlow: false,
    isNonRedeemedLVSFlow: false,
    isRefundableSubscriptionFlow: false,
    isNonRefundableSubscriptionFlow: false,
    isNotSelectedRefundReasonLVS: false,
    isRefundableCopayFlow: false,
    isNonRefundableCopayFlow: false,
    LVSOtherRefundReason: '',
    SubscriptionOtherRefundReason: '',
    eventCategory: 'Refund',
  };
};
