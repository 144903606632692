import { useEffect, useState } from 'react';
import { View } from '@talkspace/react-toolkit';
import { calculateBillingTotalsWithInsuranceEligibility } from 'ts-frontend/helpers/billingUtils';
import { useStripeTs, isStripeUsingLink } from 'stripe/stripeContext';
import { PaymentDetails } from 'checkout';
import { RouteComponentProps, withRouter } from '@/core/routerLib';
import { useRoomOffersState, useRoomOffersActions } from '../hooks/roomOfferContext';
import { RoomOfferRouteParams } from '../types';

const useTotalsVariant = (isChangePlan?: boolean) => {
  if (isChangePlan) {
    return 'none';
  }
  return 'standard';
};

const PaymentDetailsContainer = ({
  history,
  topRef,
}: RouteComponentProps<RoomOfferRouteParams> & { topRef: React.RefObject<HTMLDivElement> }) => {
  const {
    paymentDetails,
    email,
    isEmailRequired,
    selectedSubscription,
    isChangePlan,
    isNoMatches,
    coupon,
    trialOfferPrice,
    subscriptionErrorMessage,
    isLoadingSubscription,
    insuranceEligibility,
  } = useRoomOffersState();

  const totalsVariant = useTotalsVariant(isChangePlan);

  const { paymentDetailsEnteredAction, loadLinkAction, stripeLinkErrorAction, resetErrorAction } =
    useRoomOffersActions();

  const [useStripeLink, setUseStripeLink] = useState(true);

  const stripe = useStripeTs();

  useEffect(() => {
    setUseStripeLink(isStripeUsingLink(stripe));
  }, [stripe]);

  if (!selectedSubscription) {
    history.goBack();
    return null;
  }
  const validatedCouponArray = coupon.validatedCoupon ? [coupon.validatedCoupon] : undefined;

  const { total, savings } = calculateBillingTotalsWithInsuranceEligibility({
    selectedSubscription,
    validatedCoupons: validatedCouponArray,
    trialOfferPrice,
    insuranceEligibility,
  });

  return (
    <View align="center" style={{ marginTop: 30, paddingLeft: 16, paddingRight: 16 }}>
      <PaymentDetails
        onSubmit={paymentDetailsEnteredAction}
        onLoadStripeLink={loadLinkAction}
        onStripeLinkError={stripeLinkErrorAction}
        resetError={resetErrorAction}
        email={email}
        isEmailRequired={!!isEmailRequired}
        provider={paymentDetails ? paymentDetails.provider : 'Stripe'}
        currency={selectedSubscription.billingPrice.currency}
        submitText={isNoMatches ? 'Authorize my credit card' : 'Complete purchase'}
        total={total}
        savings={savings}
        totalsVariant={totalsVariant}
        errorMessage={subscriptionErrorMessage}
        isProcessing={isLoadingSubscription}
        stripe={stripe}
        isNoMatches={isNoMatches}
        useStripeLink={useStripeLink}
        stickySummary={{ topRef, headerTopClipping: -0.5 }}
      />
    </View>
  );
};

export default withRouter(PaymentDetailsContainer);
