import DTERenewalScreen from './screens/DTERenewalScreen';
import DTERenewalNYCTeenScreen from './screens/DTERenewalNYCTeenScreen';
import { Route, Switch } from '../core/routerLib';

const Routes = () => (
  <Switch>
    <Route exact path="/dte-renewal/room/:roomID/org-name/:orgName" component={DTERenewalScreen} />
    <Route
      exact
      path="/dte-renewal/room/:roomID/nyc-teenspace"
      component={DTERenewalNYCTeenScreen}
    />
  </Switch>
);

export default Routes;
