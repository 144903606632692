import {
  EmotionTheme,
  useEmotionTheme,
  View,
  BaseButton,
  Input,
  TextDS,
  styled,
} from '@talkspace/react-toolkit';
import { useFormContext } from 'react-hook-form';
import { useCallback } from 'react';
import { generateUniqueName } from '../utils/helpers';
import { DISPLAY_NAME_MAX_LENGTH_LIMIT } from '../constants';
import { trackCommunityDisplayNameRefreshed } from '../utils/analytics';

const displayNameFieldName = 'displayName';

interface Props {
  resetMutation: () => void;
  setIsSuggestedName: (isSuggestedName: boolean) => void;
  isInModalScreen?: boolean;
}

const baseInputStyle = (colorRoles: EmotionTheme['colorRoles'], isError: boolean) => {
  return {
    inputStyle: {
      borderColor: isError
        ? colorRoles.typography.textCriticalDefault
        : colorRoles.borders.borderInteractiveDefault,
      color: 'black',
      '::placeholder': { color: colorRoles.typography.textDefault },
    },
  };
};

const Wrapper = styled(View)(({ theme: { spacing } }) => {
  return {
    marginTop: spacing('space400'),
    position: 'relative',
  };
});

const RefreshButtonView = styled(View)(() => {
  return {
    position: 'absolute',
    left: 259,
    top: 35,
  };
});

const TitleText = styled(TextDS)(({ theme: { spacing } }) => {
  return {
    marginBottom: spacing('space025'),
    marginLeft: spacing('space050'),
  };
});

const DisplayNameInputField = ({ resetMutation, setIsSuggestedName, isInModalScreen }: Props) => {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = useFormContext();
  const { colorRoles } = useEmotionTheme();
  const displayName = watch(displayNameFieldName);
  const displayNameField = register(displayNameFieldName);
  const handleDisplayNameChange = useCallback(
    (e) => {
      displayNameField.onChange(e);
      resetMutation();
      clearErrors();
      setIsSuggestedName(false);
    },
    [displayNameField, clearErrors, resetMutation, setIsSuggestedName]
  );
  const modalStart = !isInModalScreen ? 'start' : undefined;
  return (
    <Wrapper>
      <View justify={modalStart} align={modalStart}>
        <TitleText colorRole="textSubtlest" variant="bodyXs">
          Display name
        </TitleText>
        <Input
          maxLength={DISPLAY_NAME_MAX_LENGTH_LIMIT}
          style={{ width: '100%' }}
          dataQa="displayNameInput"
          wrappedInputProps={{
            ...baseInputStyle(colorRoles, !!errors.displayName),
            isError: !!errors.displayName,
            errorMessage: errors.displayName?.message,
          }}
          {...register(displayNameFieldName)}
          onChange={handleDisplayNameChange}
        />
      </View>
      <RefreshButtonView>
        {displayName && (
          <BaseButton
            onPress={(e) => {
              e.preventDefault();
              setValue(displayNameFieldName, generateUniqueName());
              clearErrors();
              setIsSuggestedName(true);
              trackCommunityDisplayNameRefreshed();
            }}
            dataQa="teenspaceProfileDisplayNameRefreshButton"
          >
            <TextDS variant="headingMd" colorRole="textBrandDefault">
              Refresh
            </TextDS>
          </BaseButton>
        )}
      </RefreshButtonView>
    </Wrapper>
  );
};

export default DisplayNameInputField;
