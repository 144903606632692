/* eslint-disable import/prefer-default-export */
import { trackEvent } from '../../utils/analytics/eventTracker';
import { ReactableReference, ReactionsEnum, ReportableReference } from '../types';

export const trackDailyPromptRevealed = () => {
  trackEvent('Community Daily Prompt Revealed', {}, ['mixpanel']);
};

export const trackCommunityPostComposerOpened = () => {
  trackEvent('Community Post Composer Opened', {}, ['mixpanel']);
};

export const trackCommunityPostDrafted = () => {
  trackEvent('Community Post Drafted', {}, ['mixpanel']);
};

export const trackCommunityPostCreated = ({
  text,
  metadata,
}: {
  text: string;
  metadata?: Record<string, string | number>;
}) => {
  const characterCount = text.length;
  const wordCount = text.split(' ').length;
  trackEvent('Community Post Created', { metadata, characterCount, wordCount }, ['mixpanel']);
};

export const trackCommunityPostDeleted = ({ postID }: { postID: string }) => {
  trackEvent('Community Post Deleted', { postID }, ['mixpanel']);
};

export const trackCommunityReactionCreated = ({
  referenceType,
  referenceID,
  reactionType,
}: {
  referenceType: ReactableReference;
  referenceID: string;
  reactionType: ReactionsEnum;
}) => {
  trackEvent('Community Reaction Created', { referenceType, referenceID, reactionType }, [
    'mixpanel',
  ]);
};

export const trackCommunityCommentCreated = ({ postID }: { postID: string }) => {
  trackEvent('Community Comment Created', { postID }, ['mixpanel']);
};

export const trackCommunityCommentDeleted = ({ commentID }: { commentID: string }) => {
  trackEvent('Community Comment Deleted', { commentID }, ['mixpanel']);
};

export const trackCommunityReportCreated = ({
  referenceType,
  referenceID,
}: {
  referenceType: ReportableReference;
  referenceID: string;
}) => {
  trackEvent('Community Report Created', { referenceType, referenceID }, ['mixpanel']);
};

export const trackCommunityDisplayNameChanged = ({
  location,
  isSuggestedName,
}: {
  location: 'teenspaceOnboarding' | 'accountSettings';
  isSuggestedName: boolean;
}) => {
  trackEvent('Community Display Name Changed', { location, isSuggestedName }, ['mixpanel']);
};

export const trackCommunityDisplayNameRefreshed = () => {
  trackEvent('Community Display Name Refreshed', {}, ['mixpanel']);
};

export const trackCommunitySharePostWithTherapist = () => {
  trackEvent('Community Post Shared With Therapist', {}, ['mixpanel']);
};

export const trackCommunityOnboardingCTAPressed = ({
  pageName,
}: {
  pageName:
    | 'teenspaceWelcome'
    | 'teenspaceProfileSetup'
    | 'teenspaceCommunityGuidelines'
    | 'teenspaceDailyQuestionInfo';
}) => {
  trackEvent('Community Onboarding CTA Pressed', { pageName }, ['mixpanel']);
};
