import {
  ExtraHuge,
  View,
  Screen,
  useEmotionTheme,
  COLORS,
  Tiny,
  Standard,
  QuestionnaireIcon,
  ContinueServiceIcon,
  ShieldIcon,
  Button,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { ElementType, useCallback } from 'react';
import { useNewMemberNav } from 'launchDarkly/hooks';

import { useCloseModal } from '@/utils/ModalsContextProvider';
import { useHistory, useLocation } from '@/core/routerLib';
import styled from '../../core/styled';
import { trackUpdateCoverageEvent } from '../../utils/analytics/events';
import useUpdateCoverageParams from '../../hooks/useUpdateCoverageParams';

const Wrapper = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;

  return {
    ...(isMobile && {
      paddingLeft: 20,
      paddingRight: 20,
    }),
  };
});

interface InfoCardProps {
  title: string;
  info?: string;
  Icon: ElementType;
  colors: typeof COLORS;
}

const InfoCard = ({ title, info, Icon, colors }: InfoCardProps) => (
  <View style={{ marginBottom: 16 }} row align="center">
    <View
      style={{ backgroundColor: colors.aquaSqueeze, borderRadius: '50%', height: 56, width: 56 }}
      align="center"
      justify="center"
    >
      <Icon />
    </View>
    <View style={{ marginLeft: 20 }}>
      <Standard style={{ fontSize: 16 }}>{title}</Standard>
      <Tiny style={{ fontSize: 14, marginTop: 4, maxWidth: 263 }}>{info}</Tiny>
    </View>
  </View>
);

const WhatToExpect = () => {
  const closeModal = useCloseModal();

  const location = useLocation();
  const history = useHistory();
  const { colors } = useEmotionTheme();
  const updateCoverageParams = useUpdateCoverageParams();
  const useNewNav = useNewMemberNav();
  const { isMobile } = useWindowWidthState();

  const handleContinuePress = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    const roomID = queryParams.get('updateCoverageRoomID') || '';
    trackUpdateCoverageEvent(+roomID, 'Continue Update Coverage');

    const eligilityQueryString = new URLSearchParams(updateCoverageParams).toString();
    if (useNewNav && isMobile) {
      closeModal({
        navigateTo: 'eligibilityWidget',
        metadata: {
          roomID: +roomID,
          queryString: eligilityQueryString,
        },
      });
    } else {
      history.push(`/eligibility-widget/room/${roomID}?${eligilityQueryString}`);
    }
  }, [history, location, updateCoverageParams, closeModal, useNewNav, isMobile]);

  return (
    <Screen
      showCloseButton
      showBackButton
      backButtonDataQa="whatToExpectBackButton"
      onCloseButtonClick={closeModal}
      closeButtonDataQa="whatToExpectCloseButton"
    >
      <Wrapper align="center" justify="center" style={{ flexDirection: 'column', height: '100%' }}>
        <ExtraHuge style={{ margin: '40px 0' }}>Here's what to expect</ExtraHuge>
        <View style={{ flexGrow: 1 }}>
          <InfoCard
            title="Fill out a quick questionnaire"
            info="Just a few questions to see if anything has changed"
            Icon={() => <QuestionnaireIcon />}
            colors={colors}
          />
          <InfoCard
            title="Choose a new way to pay"
            info="Enter an employer code, switch to insurance, or switch to self-pay"
            Icon={() => <ShieldIcon />}
            colors={colors}
          />
          <InfoCard
            title="Continue service with Talkspace"
            info="Most likely you'll stay with your current provider. If your current provider doesn't accept your new form of payment then we'll connect you with a new provider that matches your needs."
            Icon={() => <ContinueServiceIcon />}
            colors={colors}
          />
        </View>
        <Button
          dataQa="inRoomSchedulerSelectModalityContinue"
          style={{ width: 335, marginBottom: 80 }}
          onPress={handleContinuePress}
          text="Continue"
          primaryColor={colors.green}
        />
      </Wrapper>
    </Screen>
  );
};

export default WhatToExpect;
