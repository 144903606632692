import { View } from '@talkspace/react-toolkit';
import styled from '../../core/styled';

const InfoPanel = styled(View)(({ theme: { colors } }) => {
  return {
    borderStyle: 'solid',
    borderColor: colors.periwinkleGrey,
    borderRadius: 10,
    borderWidth: 0.5,
    padding: 20,
  };
});

export default InfoPanel;
