import { useReducer, useCallback, createContext, useContext } from 'react';
import API from '../utils/clinicalProgressApiHelper';
import {
  treatmentPlannerReducer,
  TreatmentPlannerState,
  initialState,
} from '../reducers/treatmentPlanner';

export interface TreatmentPlannerActions {
  dispatchGetTreatmentPlanner: (roomID: number) => Promise<void>;
}

export const StateContext = createContext<TreatmentPlannerState | undefined>(undefined);
export const ActionsContext = createContext<TreatmentPlannerActions | undefined>(undefined);

function TreatmentPlannerContextProvider({ children }) {
  const [state, dispatch] = useReducer(treatmentPlannerReducer, initialState);

  async function dispatchGetTreatmentPlanner(roomID) {
    dispatch({ type: 'requestGetTreatmentPlanner' });
    try {
      const treatmentPlansData = await API.getTreatmentPlanner(roomID);
      const treatmentPlan =
        treatmentPlansData.data && treatmentPlansData.data.data[0]
          ? treatmentPlansData.data.data[0]
          : null;
      if (!treatmentPlan) {
        return dispatch({
          type: 'receiveGetTreatmentPlanner',
          payload: {},
        });
      }
      const [goalsData, objectivesData] = await Promise.all([
        API.getGoals(roomID, treatmentPlan.id),
        API.getObjectives(roomID, treatmentPlan.id),
      ]);

      if (goalsData.data && objectivesData.data) {
        return dispatch({
          type: 'receiveGetTreatmentPlanner',
          payload: {
            ...treatmentPlan,
            goals: goalsData.data.data,
            objectives: objectivesData.data.data,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: 'setError',
        error: error.message,
      });
    }
    return undefined;
  }

  const actions: TreatmentPlannerActions = {
    dispatchGetTreatmentPlanner: useCallback(dispatchGetTreatmentPlanner, []),
  };

  return (
    <StateContext.Provider value={state}>
      <ActionsContext.Provider value={actions}>{children}</ActionsContext.Provider>
    </StateContext.Provider>
  );
}

function useTreatmentPlannerState(): TreatmentPlannerState {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error(
      'treatmentPlanner state context must be used within TreatmentPlannerContextProvider'
    );
  }
  return context;
}

function useTreatmentPlannerActions() {
  const context = useContext(ActionsContext);
  if (context === undefined) {
    throw new Error(
      'treatmentPlanner actions context must be used within TreatmentPlannerContextProvider'
    );
  }
  return context;
}

export { TreatmentPlannerContextProvider, useTreatmentPlannerState, useTreatmentPlannerActions };
