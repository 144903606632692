import { FunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

interface RightCaretIconProps {
  color?: string;
  width?: number;
  height?: number;
  style?: {};
}

const RightCaretIcon: FunctionComponent<RightCaretIconProps> = ({
  color,
  width = 10,
  height = 10,
  ...otherProps
}: {
  color?: string;
  width?: number;
  height?: number;
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'right arrow';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...otherProps}
    >
      <Path
        d="M97.781 150.781a1.102 1.102 0 01-.005-1.557l-.052.052a1.098 1.098 0 011.547-.005L105 155l-5.729 5.729a1.095 1.095 0 01-1.547-.005l.052.052a1.096 1.096 0 01.005-1.557L102 155l-4.219-4.219z"
        transform="translate(-96 -148)"
        fill={color || colors.heatherGrey}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default RightCaretIcon;
