import { useFlag } from 'launchDarkly/FlagsProvider';
import { Redirect } from '@/core/routerLib';
import WhatToExpect from '../components/WhatToExpect';

const WhatToExpectContainer = () => {
  const flagACKP = useFlag('addCoverageKeepProvider');
  if (!flagACKP) {
    return <Redirect to="/eligibility-widget" />;
  }
  return <WhatToExpect />;
};

export default WhatToExpectContainer;
