import { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { View } from '@talkspace/react-toolkit';

const Wrapper = styled(View)({
  background: '#FFFFFF',
  border: '1px solid #E9E9E9', // TODO: #E8E8EA" in new myAccount
  // boxShadow: "0 2px 2px 0 rgba(0,0,0,0.05)", TODO: in new myAccount
  borderRadius: 5, // TODO: change to 6 in new myAccount
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: '8%',
});

const AccountPanel: FunctionComponent<{}> = ({ children }) => <Wrapper>{children}</Wrapper>;

export default AccountPanel;
