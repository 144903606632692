import { FunctionComponent } from 'react';
import { AppSource } from 'ts-frontend/types';
import { Route, Switch } from 'react-router';
import { PromiseMessageOffersInfo, HandleQuickmatchSubscribeResponse } from 'ts-promise-message';
import { RoomOfferProvider } from '../hooks/roomOfferContext';
import PaymentDetailsScreen from '../screens/PaymentDetailsScreen';
import OfferScreen from '../screens/OfferScreen';
import CheckoutScreen from '../screens/CheckoutScreen';
import SuccessScreen from '../screens/SuccessScreen';

const BASE_PATH = '/room-offer/:roomID/offer/:offerID/(plan/:planID)?';

interface Props {
  handleQuickmatchSubscribe?: (data) => Promise<HandleQuickmatchSubscribeResponse | null>;
  getOffersInfo?: () => PromiseMessageOffersInfo | null;
  source?: AppSource;
  onChangeTherapist?: () => any;
}

const RoomOffer: FunctionComponent<Props> = ({
  handleQuickmatchSubscribe,
  getOffersInfo,
  source,
  onChangeTherapist,
}) => (
  <Switch>
    <Route path={`${BASE_PATH}/`}>
      <RoomOfferProvider
        handleQuickmatchSubscribe={handleQuickmatchSubscribe}
        getOffersInfo={getOffersInfo}
        source={source}
        onChangeTherapist={onChangeTherapist}
      >
        <Switch>
          <Route path={`${BASE_PATH}/checkout`} component={CheckoutScreen} />
          <Route path={`${BASE_PATH}/payment-details`} component={PaymentDetailsScreen} />
          <Route path={`${BASE_PATH}/success`} component={SuccessScreen} />
          <Route path={`${BASE_PATH}/`} component={OfferScreen} />
        </Switch>
      </RoomOfferProvider>
    </Route>
  </Switch>
);

export default RoomOffer;
