import { Moment } from 'moment';
import { stringifyWithExpiration, isItemExpired, ValueType, ItemWithExpiration } from './utils';

type StorageType = 'localStorage' | 'sessionStorage' | 'memoryStorage';

class StoragePolyfill implements Storage {
  memory: { [key: string]: string | null } = {};

  get length() {
    return Object.keys(this.memory).length;
  }

  clear(): void {
    this.memory = {};
  }

  getItem(key: string): string | null {
    // eslint-disable-next-line no-prototype-builtins
    if (this.memory.hasOwnProperty(key)) return this.memory[key];
    return null;
  }

  key(index: number): string | null {
    return (Object.keys(this.memory)[index] as string) || null;
  }

  removeItem(key: string): void {
    delete this.memory[key];
  }

  setItem(key: string, value: string): void {
    this.memory[key] = value;
  }

  setLocalItemWithExpiration(key: string, value: ValueType, expiration: Moment | Date): void {
    const item = stringifyWithExpiration(value, expiration);
    this.setItem(key, item);
  }

  getLocalItemWithExpiration(key: string): ValueType | null {
    let item: ItemWithExpiration | null = null;
    try {
      item = JSON.parse(this.getItem(key) || '');
      if (!item || isItemExpired(item)) {
        this.removeItem(key);
        return null;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return null;
    }
    return item.value;
  }
}

const polyfills: { [key in StorageType]: StoragePolyfill } = {
  localStorage: new StoragePolyfill(),
  sessionStorage: new StoragePolyfill(),
  memoryStorage: new StoragePolyfill(),
};

export const polyfillIfUnavailable = (storageType: StorageType) => {
  try {
    const storage = (window as { [key: string]: any })[storageType] as Storage;
    storage.setItem('pls work', 'pls pls work');
    storage.removeItem('pls work');
    return storage;
  } catch (error) {
    return polyfills[storageType];
  }
};

export default polyfills;
