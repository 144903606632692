import {
  createContext,
  useContext,
  useReducer,
  useRef,
  useEffect,
  useCallback,
  PropsWithChildren,
} from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { PromiseMessageOffersInfo, HandleQuickmatchSubscribeResponse } from 'ts-promise-message';
import { AppSource, PaymentDetails, PlanData } from 'ts-frontend/types';
import { toast } from '@talkspace/react-toolkit';
import { formatCurrency, getOfferPriceFormatted } from 'ts-frontend/helpers/billingUtils';
import { getParamByName } from 'ts-frontend/utils/queryString';
import { Location } from 'history';
import paymentAPI from '../utils/paymentApiHelper';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { withRouter, RouteComponentProps, useLocation } from '@/core/routerLib';
import {
  RoomOfferState,
  SubscribePayload,
  SubscribeAnalyticsData,
  RoomOfferRouteParams,
  CouponInputMode,
  CompletePurchaseFunnelName,
  BoughtFrom,
  OfferSource,
  HandleQuickmatchSubscribeData,
} from '../types';
import { OfferApiHelper } from '../utils';
import { getUserData } from '@/auth/helpers/token';
import pushOfferRoute from '../utils/pushOfferRoute';
import {
  createPlansMatrix,
  createPlanMatrixWithInsuranceDiscount,
  createPlanMatrixForOutOfPocketPromo,
} from '../utils/dataTransforms';
import analyticsHelper from '../utils/offerAnalytics';

const DEBUG = false; // IF YOU SEE THIS AS TRUE IN A PR, REQUEST TO CHANGE IT TO FALSE

const boughtFromToString = (boughtFrom: BoughtFrom | undefined): CompletePurchaseFunnelName =>
  boughtFrom === BoughtFrom.reactivation
    ? CompletePurchaseFunnelName.REACTIVATION
    : CompletePurchaseFunnelName.ACCOUNT_SETTINGS;

export const RoomOfferInitialState: RoomOfferState = {
  email: undefined,
  offer: undefined,
  plansMatrix: undefined,
  plansMatrixDisplayReady: false,
  currentPlan: undefined,
  therapistInfo: {
    firstName: '',
    lastName: '',
    therapistID: 0,
    therapistLicenses: [],
    therapistImage: '',
    therapistType: undefined,
  },
  subscriptionErrorMessage: '',
  isErrorSubscription: false,
  isLoadingSubscription: false,
  isErrorRoomOffer: false,
  isLoadingRoomOffer: true,
  hasFetchedFrameData: false,
  showCloseButton: true,
  coupon: {
    status: 'ready',
  },
  experimentRouteReady: false,
  bookWithIntroSession: false,
  goToUrl: undefined,
  couponInputMode: CouponInputMode.default,
  isNoMatches: false,
  flowID: 0,
  isLoggedInUser: false,
};

interface Actions {
  setRoomOfferStateAction: (payload: Partial<RoomOfferState>) => void;
  loadRoomOfferAction: () => void;
  planSelectedAction: (plan: PlanData, monthlyPlan: PlanData) => void;
  paymentDetailsEnteredAction: (
    token: string,
    paymentDetails?: PaymentDetails,
    email?: string
  ) => void;
  loadLinkAction: () => void;
  changeTherapistAction: () => void;
  stripeLinkErrorAction: (error: any) => void;
  resetErrorAction: () => void;
  validateCouponAction: (couponCode: string) => void;
  checkoutConfirmedAction: () => void;
  trackPlanExpendAction: (displayName: string) => void;
}

const StateContext = createContext<RoomOfferState>(RoomOfferInitialState);
export { StateContext as RoomOfferStateContext };

const ActionsContext = createContext<Actions | undefined>(undefined);

function RoomOfferReducer(
  currentState: RoomOfferState,
  action: { type: string; payload?: Partial<RoomOfferState> }
): RoomOfferState {
  // eslint-disable-next-line no-console
  if (DEBUG) console.log(action.type, { ...currentState, ...action.payload });
  return { ...currentState, ...action.payload };
}

function getDiscountPercent(plan: PlanData) {
  return plan.discountPercent ? Number(plan.discountPercent.replace(/[^\d]/g, '')) : 0;
}

type RoomOfferProviderProps = RouteComponentProps<RoomOfferRouteParams>;

const getAnalyticsState = (state: RoomOfferState) => {
  const topMostPlanPrice = getOfferPriceFormatted(state.plansMatrix?.[0][0]?.offerPrice);

  const planPrices =
    state.plansMatrix?.map((matrix) => getOfferPriceFormatted(matrix[0]?.offerPrice)) || [];
  const currency = state.offer?.currency || 'USD';
  return {
    topMostPlanPrice,
    planPrices,
    currency,
    offerID: state.offerID,
  };
};

interface RoomOfferProviderFullProps extends RoomOfferProviderProps {
  handleQuickmatchSubscribe?: (
    data: HandleQuickmatchSubscribeData
  ) => Promise<HandleQuickmatchSubscribeResponse | null>;
  getOffersInfo?: () => PromiseMessageOffersInfo | null;
  source?: AppSource;
  onChangeTherapist?: () => any;
}

const RoomOfferProvider = withRouter(
  ({
    children,
    location,
    match,
    history,
    handleQuickmatchSubscribe = async () => null,
    getOffersInfo = () => null,
    source = (getParamByName('source') as OfferSource | null) || AppSource.client,
    onChangeTherapist,
  }: PropsWithChildren<RoomOfferProviderFullProps>) => {
    const roomID = +match.params.roomID || undefined;
    const requestOfferID = +match.params.offerID;
    const offerPlanID = match.params.planID ? +match.params.planID : undefined;
    const { experimentalPlanPricing } = useFlags();
    const closeModal = useCloseModal();
    const [state, dispatch] = useReducer(RoomOfferReducer, RoomOfferInitialState);
    const {
      email,
      offer,
      plansMatrix,
      coupon: { validatedCoupon },
      selectedSubscription,
      therapistInfo,
      boughtFrom,
      paymentToken,
      isChangePlan,
      experimentRouteReady,
      isLoadingRoomOffer,
      trialOfferPrice,
      paymentDetails,
      insuranceEligibility,
      plansMatrixDisplayReady,
      hasFetchedFrameData,
      goToUrl,
      bookWithIntroSession,
      offerID,
      promoFromBanner,
      isLoggedInUser,
    } = state;

    const currentLocation: Location = useLocation();

    const getConfParam = useCallback(
      (paramName: string) => getParamByName(paramName, currentLocation),
      [currentLocation]
    );

    // Used for pieces of state that change through API calls/state updates but are needed for analytics immediately.
    const analyticsStateRef = useRef(getAnalyticsState(state));

    useEffect(() => {
      const isNoMatches = getConfParam('isNoMatches') === 'true';
      dispatch({
        type: 'initIsNoMatchesState',
        payload: {
          isNoMatches,
        },
      });

      const flow = getConfParam('flow');
      if (flow) {
        dispatch({
          type: 'initFlowID',
          payload: {
            flowID: +flow,
          },
        });
      }
    }, [getConfParam]);

    useEffect(() => {
      analyticsStateRef.current = getAnalyticsState(state);
    });

    const apiRef = useRef(new OfferApiHelper(source as OfferSource));
    const { current: api } = apiRef;

    const initPlansMatrixDisplay = useCallback(async () => {
      if (!experimentRouteReady) return;

      const waitForFrameData = source === AppSource.qm ? hasFetchedFrameData : true;
      if (plansMatrix && !plansMatrixDisplayReady && waitForFrameData) {
        // artificial delay presenting the offers, we need are giving time for VWO
        // to react to the route and see if there is an experiment.
        let finalPlansMatrix = plansMatrix;
        if (
          source === AppSource.qm &&
          insuranceEligibility &&
          (insuranceEligibility.isEligible || insuranceEligibility.ineligiblePromo)
        ) {
          // Handles isEligible, or with ineligiblePromo
          finalPlansMatrix = createPlanMatrixWithInsuranceDiscount(
            plansMatrix,
            insuranceEligibility
          );
        } else if (source === AppSource.qm && promoFromBanner?.billingCycleExperimentActive) {
          // Handles Out Of Pocket
          // validate coupon from VWO banner
          const { promoCode, showOnOffersPage, shownAdditionalBillingCycles } = promoFromBanner;
          // double check: promoFromBanner should be passed in only if 'displayed'
          if (showOnOffersPage === 'displayed' && promoCode && plansMatrix?.[0]?.[0]?.id) {
            try {
              const res = await paymentAPI.validateCouponQM(promoCode, plansMatrix[0][0].id);
              const { validCoupon, discountAmount } = res;
              if (validCoupon) {
                dispatch({
                  type: 'setPromoToDisplay',
                  payload: {
                    promoToDisplay: {
                      promoAmount: discountAmount,
                      promoWeeks: 4,
                    },
                  },
                });

                finalPlansMatrix = createPlanMatrixForOutOfPocketPromo(
                  plansMatrix,
                  {
                    promoAmount: discountAmount,
                    promoWeeks: 4,
                  },
                  shownAdditionalBillingCycles === 'show_additional'
                );
              }
            } catch (e) {
              // ignore and use default plansMatrix
            }
          } else if (plansMatrix?.[0]?.[0]?.id && shownAdditionalBillingCycles) {
            // Handles additional billing cycles with no OOP promo
            finalPlansMatrix = createPlanMatrixForOutOfPocketPromo(
              plansMatrix,
              undefined,
              shownAdditionalBillingCycles === 'show_additional'
            );
          }
        }
        setTimeout(() => {
          dispatch({
            type: 'setPlansMatrixDisplayReady',
            payload: {
              plansMatrixDisplayReady: true,
              plansMatrix: finalPlansMatrix,
            },
          });
        }, 400);
      }
    }, [
      experimentRouteReady,
      hasFetchedFrameData,
      insuranceEligibility,
      plansMatrix,
      plansMatrixDisplayReady,
      promoFromBanner,
      source,
    ]);

    useEffect(() => {
      initPlansMatrixDisplay();
    }, [initPlansMatrixDisplay]);

    useEffect(() => {
      (async () => {
        if (source !== AppSource.qm) return;

        const info = getOffersInfo();

        dispatch({
          type: 'receiveTherapistInfo',
          payload: {
            therapistInfo: info?.therapistInfo,
            insuranceEligibility: info?.insuranceEligibility,
            hasFetchedFrameData: true,
            promoFromBanner: info?.promoFromBanner,
          },
        });
        if (info?.registrationInfo.isLoggedInUser) {
          dispatch({
            type: 'receiveLoginStatus',
            payload: {
              isLoggedInUser: info.registrationInfo.isLoggedInUser,
            },
          });
        }
        if (info?.registrationInfo.email) {
          dispatch({
            type: 'receiveRegistrationInfo',
            payload: {
              email: info.registrationInfo.email,
            },
          });
        }
      })();
    }, [source, getOffersInfo]);

    useEffect(() => {
      // We indicate to VWO that this page is AB tested using a fake virtual page view event
      if (!isLoadingRoomOffer && offer && !experimentRouteReady) {
        const queryString = new URLSearchParams();

        const isTrial = trialOfferPrice !== undefined;
        const isReactivation = boughtFrom === BoughtFrom.reactivation;

        queryString.set('isChangePlan', isChangePlan ? '1' : '0');
        queryString.set('isTrial', isTrial ? '1' : '0');
        queryString.set('isReactivation', isReactivation ? '1' : '0');
        queryString.set('location', encodeURIComponent(offer.location || 'US'));
        queryString.set('source', source);

        if (source === AppSource.qm) {
          const coupon = getConfParam('coupon');
          if (coupon) {
            // break experiments on flow 48
            queryString.set('flow', `${coupon}-${getConfParam('flow')}`);
          } else {
            queryString.set('flow', getConfParam('flow') || '0');
          }
          const utmMedium = getConfParam('utm_medium');
          if (utmMedium) {
            // keep utm_medium last in the virtual tracking please
            queryString.set('utm_medium', utmMedium);
          }
        }

        analyticsHelper.trackVirtualPageView(
          source,
          `/room-offer/<roomID>/offer/${offer.id}?${queryString.toString()}`
        );

        dispatch({
          type: 'setExperimentRouteReady',
          payload: { experimentRouteReady: true },
        });
      }
    }, [
      isChangePlan,
      offer,
      experimentRouteReady,
      isLoadingRoomOffer,
      trialOfferPrice,
      boughtFrom,
      source,
      getConfParam,
    ]);

    useEffect(() => {
      if (isLoadingRoomOffer) return;
      analyticsHelper.trackPageView(source, 'Offers', boughtFromToString(boughtFrom), {
        offerId: offerID,
      });
    }, [isLoadingRoomOffer, boughtFrom, offerID, source]);

    useEffect(
      () => () => {
        api.cancelAll();
      },
      [api]
    );

    function setRoomOfferState(payload: Partial<RoomOfferState>): void {
      dispatch({
        type: 'setRoomOfferState',
        payload,
      });
    }

    function loadRoomOffer() {
      dispatch({
        type: 'loadRoomOffer',
        payload: { isLoadingRoomOffer: true },
      });

      const userCountry = getConfParam('userCountry') || undefined;
      const userState = getConfParam('userState') || undefined;

      Promise.all([
        api
          .getOfferData(requestOfferID, offerPlanID, roomID, userCountry, userState)
          .then((data) => {
            const currentPlan = data.currentPlan || undefined;
            const currentTrialOfferPrice = data.trialOfferPrice as number;
            const trialOfferPriceDisplay =
              currentTrialOfferPrice !== undefined
                ? formatCurrency(currentTrialOfferPrice, data.offer.currency)
                : undefined;

            dispatch({
              type: 'getOfferSuccess',
              payload: {
                offerID: data.id || requestOfferID,
                offer: data.offer,
                plansMatrix: createPlansMatrix(
                  data.offer.discountGroups,
                  getConfParam('show_plans')
                ),
                currentPlan,
                trialOfferPrice: currentTrialOfferPrice,
                trialOfferPriceDisplay,

                // If user is subscribed to a plan, and the offer contains non-one-time plans,
                // then we're in Change Plan. This of course assumes there's not a mix of one-time
                // and subscription plans in the offer
                isChangePlan:
                  !!currentPlan &&
                  data.offer &&
                  data.offer.discountGroups[0].plans[0].billingPrice.unit !== 'one-time',
              },
            });
          }),
        api.getPaymentDetailsInfo().then((billingInfo) => {
          dispatch({
            type: 'getPaymentDetailsSuccess',
            payload: { paymentDetails: billingInfo },
          });
        }),
      ])
        .then(() => {
          dispatch({
            type: 'roomOfferSuccess',
            payload: { isLoadingRoomOffer: false },
          });
          const offersInfo = getOffersInfo();

          if (offersInfo?.promoFromBanner?.shownAdditionalBillingCycles) {
            analyticsHelper.seeAvailableOffers({
              source,
              offerID: analyticsStateRef.current.offerID || requestOfferID,
              requestedOfferID: requestOfferID,
              currency: analyticsStateRef.current.currency,
              planPrices: analyticsStateRef.current.planPrices,
              topMostPlanPrice: analyticsStateRef.current.topMostPlanPrice,
              Successful: true,
              funnelName: boughtFromToString(boughtFrom),
              experimentName: 'oop-promo-additional-billing-cycles',
              experimentVariant: offersInfo?.promoFromBanner?.shownAdditionalBillingCycles,
            });
          } else if (typeof experimentalPlanPricing === 'boolean') {
            analyticsHelper.seeAvailableOffers({
              source,
              offerID: analyticsStateRef.current.offerID || requestOfferID,
              requestedOfferID: requestOfferID,
              currency: analyticsStateRef.current.currency,
              planPrices: analyticsStateRef.current.planPrices,
              topMostPlanPrice: analyticsStateRef.current.topMostPlanPrice,
              Successful: true,
              funnelName: boughtFromToString(boughtFrom),
              experimentName: 'experimental-plan-pricing',
              experimentVariant: experimentalPlanPricing ? 'enabled' : 'disabled',
            });
          } else {
            analyticsHelper.seeAvailableOffers({
              source,
              offerID: analyticsStateRef.current.offerID || requestOfferID,
              requestedOfferID: requestOfferID,
              currency: analyticsStateRef.current.currency,
              planPrices: analyticsStateRef.current.planPrices,
              topMostPlanPrice: analyticsStateRef.current.topMostPlanPrice,
              Successful: true,
              funnelName: boughtFromToString(boughtFrom),
            });
          }
        })
        .catch(api.dismissIfCancelled)
        .catch((error) => {
          const errorMessage = Number.isInteger(+error.message)
            ? 'There was an error. Please try again.'
            : error.message;
          if (errorMessage === 'customer has not opened the offer yet') {
            dispatch({
              type: 'roomOfferExperimentError',
              payload: { experimentError: errorMessage },
            });
          } else {
            toast(errorMessage);
            // eslint-disable-next-line no-console
            console.error(error);
          }
          analyticsHelper.seeAvailableOffers({
            source,
            offerID: analyticsStateRef.current.offerID || requestOfferID,
            requestedOfferID: requestOfferID,
            currency: analyticsStateRef.current.currency,
            planPrices: analyticsStateRef.current.planPrices,
            topMostPlanPrice: analyticsStateRef.current.topMostPlanPrice,
            Successful: true,
            funnelName: boughtFromToString(boughtFrom),
            errorMessage,
          });
        });
    }
    const handleSubscribeToPlan = async (
      payload: SubscribePayload,
      analyticsData: SubscribeAnalyticsData
    ): Promise<void> => {
      const priceAmount = analyticsData.billingPrice ? analyticsData.billingPrice.amount || 0 : 0;
      const discountAmount = analyticsData.discount || 0;
      try {
        dispatch({
          type: 'subscribe',
          payload: {
            isLoadingSubscription: true,
            subscriptionErrorMessage: undefined,
          },
        });

        const { success, isFirstPurchase, isTestUser, data } = await api.postSubscribeToPlan(
          payload
        );

        if (success) {
          dispatch({
            type: 'subscribeWithSuccess',
            payload: {
              isErrorSubscription: false,
              isLoadingSubscription: false,
              subscriptionErrorMessage: '',
            },
          });

          analyticsHelper.purchaseEvent({
            source,
            roomID: payload.params.gid,
            planID: payload.params.plan_id,
            offerID: payload.params.offer_id,
            requestedOfferID: analyticsData.requestOfferID,
            eventCategory: boughtFromToString(payload.params.boughtFrom),
            currency: analyticsData.currency,
            discountPercent: analyticsData.discountPercent,
            revenue: priceAmount - discountAmount,
            price: priceAmount,
            planDisplayName: analyticsData.displayName || '',
            billingCycle:
              (analyticsData.billingPrice && analyticsData.billingPrice.cycleValue) || 0,
            isTestUser,
            couponCode: payload.params.couponCode,
            isFirstPurchase,
          });

          if (isChangePlan) {
            pushOfferRoute(match, history, location, '/success', {
              goToUrl,
              bookWithIntroSession,
            });
          } else if (goToUrl) {
            // Client resubscribed with a new provider and must be redirected
            history.replace(goToUrl, { ...(bookWithIntroSession && { bookWithIntroSession }) });
          } else {
            // we are issuing an artificial delay with the close modal in order to give
            // time for the analytics tools to attribute before killing the frame
            setTimeout(() => {
              closeModal({ actionPerformed: 'subscribedToPlan' });
            }, 1000);
          }
        } else {
          // subscribe to plan might return human readable errors from mainsite
          const errorMessage = data?.error ? data.error : 'There was an error. Please try again.';

          // keep this for analytics purposes
          const returnCode = success ? 1 : 106;

          analyticsHelper.purchaseEvent({
            source,
            roomID: payload.params.gid,
            planID: payload.params.plan_id,
            offerID: payload.params.offer_id,
            requestedOfferID: analyticsData.requestOfferID,
            eventCategory: boughtFromToString(payload.params.boughtFrom),
            currency: analyticsData.currency,
            discountPercent: analyticsData.discountPercent,
            revenue: priceAmount - discountAmount,
            price: priceAmount,
            planDisplayName: analyticsData.displayName || '',
            billingCycle:
              (analyticsData.billingPrice && analyticsData.billingPrice.cycleValue) || 0,
            isTestUser: false,
            couponCode: payload.params.couponCode,
            isFirstPurchase: false,
            successful: false,
            errors: [returnCode, ...(errorMessage ? [errorMessage] : [])],
          });

          dispatch({
            type: 'setErrorSubscription',
            payload: {
              isErrorSubscription: true,
              isLoadingSubscription: false,
              subscriptionErrorMessage: errorMessage,
            },
          });
        }
      } catch (error) {
        const errorMessage = Number.isInteger(+error.message)
          ? 'There was an error. Please try again.'
          : error.message;

        analyticsHelper.purchaseEvent({
          source,
          roomID: payload.params.gid,
          planID: payload.params.plan_id,
          offerID: payload.params.offer_id,
          requestedOfferID: analyticsData.requestOfferID,
          eventCategory: boughtFromToString(payload.params.boughtFrom),
          currency: analyticsData.currency,
          discountPercent: analyticsData.discountPercent,
          revenue: priceAmount - discountAmount,
          price: priceAmount,
          planDisplayName: analyticsData.displayName || '',
          billingCycle: (analyticsData.billingPrice && analyticsData.billingPrice.cycleValue) || 0,
          isTestUser: false,
          couponCode: payload.params.couponCode,
          isFirstPurchase: false,
          successful: false,
          errors: errorMessage ? [errorMessage] : [],
        });

        dispatch({
          type: 'setErrorSubscription',
          payload: {
            isErrorSubscription: true,
            isLoadingSubscription: false,
            subscriptionErrorMessage: errorMessage,
          },
        });
      }
    };

    const subscribeToPlan = useCallback(handleSubscribeToPlan, [
      api,
      source,
      isChangePlan,
      goToUrl,
      match,
      history,
      location,
      bookWithIntroSession,
      closeModal,
    ]);

    const subscribeToPlanQM = useCallback(
      async (payload: HandleQuickmatchSubscribeData) => {
        dispatch({
          type: 'subscribe',
          payload: {
            isLoadingSubscription: true,
            subscriptionErrorMessage: undefined,
          },
        });

        const response = await handleQuickmatchSubscribe(payload);

        if (response && response.success) {
          dispatch({
            type: 'subscribeWithSuccess',
            payload: {
              isErrorSubscription: false,
              subscriptionErrorMessage: '',
            },
          });

          // We indicate to VWO a conversion using a virtual route
          analyticsHelper.trackVirtualPageView(source, `${location.pathname}?complete-purchase=1`);

          // this page will be closed by a redirect on QM
        } else {
          dispatch({
            type: 'setErrorSubscription',
            payload: {
              isErrorSubscription: true,
              isLoadingSubscription: false,
              subscriptionErrorMessage: response?.errors || 'There was an error. Please try again',
            },
          });
        }
      },
      [handleQuickmatchSubscribe, location.pathname, source]
    );

    const validateCoupon = useCallback(
      (couponCode: string) => {
        // Change Plan doesn't support coupons
        if (isChangePlan) return;

        if (!state.selectedSubscription) {
          dispatch({
            type: 'setErrorValidateCoupon',
            payload: {
              coupon: {
                status: 'error',
                errorMessage: 'There was an error. Please try again.',
              },
            },
          });

          return;
        }

        const { id: planID } = state.selectedSubscription;

        dispatch({
          type: 'validateCoupon',
          payload: {
            coupon: { status: 'validating', errorMessage: undefined },
          },
        });
        api
          .validateCoupon(couponCode, planID)
          .then((response) => {
            if (!response || !response.validCoupon) {
              dispatch({
                type: 'couponInvalid',
                payload: {
                  coupon: {
                    status: 'error',
                    errorMessage: 'The coupon is invalid.',
                  },
                },
              });
              return;
            }
            dispatch({
              type: 'couponValid',
              payload: {
                coupon: {
                  status: 'valid',
                  validatedCoupon: {
                    amount: response.discountAmount,
                    code: response.couponCode,
                    isRecurring: response.isRecurring,
                  },
                },
              },
            });
          })
          .catch(api.dismissIfCancelled)
          .catch((error) => {
            const errorMessage = Number.isInteger(+error.message)
              ? 'There was an error. Please try again.'
              : error.message;

            dispatch({
              type: 'setErrorValidateCoupon',
              payload: {
                coupon: {
                  status: 'error',
                  errorMessage,
                },
              },
            });
          });
      },
      [api, isChangePlan, state.selectedSubscription]
    );

    const doSubscribe = useCallback(
      (plan: PlanData, token?: string, newEmail?: string) => {
        if (source === AppSource.therapist) {
          toast('Provider cannot purchase');
          return;
        }
        if (source === AppSource.qm) {
          subscribeToPlanQM({
            email: (newEmail || email) as string,
            paymentToken: token || paymentToken,
            planId: plan.id,
            promoCode: validatedCoupon && validatedCoupon.code,
            offerID: offerID || requestOfferID,
            attribution: {
              discountPercent: getDiscountPercent(plan),
              promoValue: (validatedCoupon && validatedCoupon.amount) || 0,
              planName: plan.displayName,
              billingFrequency: (plan.billingPrice && plan.billingPrice.cycleValue) || 0,
              price: plan.billingPrice.amount,
              requestedOfferID: requestOfferID,
              currency: offer?.currency || 'USD',
            },
          });
          return;
        }

        if (!roomID) {
          // eslint-disable-next-line no-console
          console.error('Room ID is required when source is not QM');
          return;
        }

        const subscribeAnalyticsData: SubscribeAnalyticsData = {
          requestOfferID,
          discount: validatedCoupon && validatedCoupon.amount,
          billingPrice: plan.billingPrice,
          displayName: plan.displayName,
          discountPercent: getDiscountPercent(plan),
          currency: offer?.currency || 'USD',
        };

        const validatedCouponCode = validatedCoupon && validatedCoupon.code;

        const payload: SubscribePayload = {
          params: {
            plan_id: plan.id,
            offer_id: offerID || requestOfferID,
            gid: roomID,
            therapistId:
              therapistInfo && therapistInfo.therapistID > 0
                ? therapistInfo.therapistID
                : undefined,
            switchRoom: !!therapistInfo,
            boughtFrom,
            funnelName: boughtFromToString(boughtFrom),
            couponCode: validatedCouponCode,
            externalProcessorToken: token || paymentToken,
          },
        };

        subscribeToPlan(payload, subscribeAnalyticsData);
      },
      [
        boughtFrom,
        email,
        offerID,
        paymentToken,
        roomID,
        source,
        subscribeToPlan,
        subscribeToPlanQM,
        therapistInfo,
        validatedCoupon,
        requestOfferID,
        offer?.currency,
      ]
    );

    /**
     * Despite the name, this function is called when the user clicks the "Continue to Checkout" button.
     */
    const checkoutConfirmed = useCallback(() => {
      const plan = selectedSubscription;
      // In theory, `plan` should always be truthy
      if (plan) {
        const priceAmount = plan.billingPrice ? plan.billingPrice.amount || 0 : 0;
        analyticsHelper.continueToCheckout({
          source,
          displayName: plan.displayName,
          priceAmount,
          billingFrequency: (plan.billingPrice && plan.billingPrice.cycleValue) || 0,
          discountPercent: getDiscountPercent(plan),
          funnelName: boughtFromToString(boughtFrom),
        });
      }

      if (!paymentDetails) {
        pushOfferRoute(match, history, location, '/payment-details');
        return;
      }

      if (selectedSubscription) {
        doSubscribe(selectedSubscription);
        return;
      }

      throw new Error('Invalid state');
    }, [
      selectedSubscription,
      paymentDetails,
      source,
      boughtFrom,
      match,
      history,
      location,
      doSubscribe,
    ]);

    /**
     * This function is called when the user selects a plan and clicks the "Continue" button.
     */
    const planSelected = useCallback(
      (plan: PlanData, monthlyPlan: PlanData) => {
        const priceAmount = plan.billingPrice ? plan.billingPrice.amount || 0 : 0;

        if (promoFromBanner?.shownAdditionalBillingCycles) {
          analyticsHelper.planSelectedEvent({
            source,
            funnelName: boughtFromToString(boughtFrom),
            displayName: plan.displayName,
            priceAmount,
            billingFrequency: (plan.billingPrice && plan.billingPrice.cycleValue) || 0,
            discountPercent: getDiscountPercent(plan),
            experimentName: 'oop-promo-additional-billing-cycles',
            experimentVariant: promoFromBanner?.shownAdditionalBillingCycles,
            isLoggedInUser,
          });
        } else if (experimentalPlanPricing) {
          analyticsHelper.planSelectedEvent({
            source,
            funnelName: boughtFromToString(boughtFrom),
            displayName: plan.displayName,
            priceAmount,
            billingFrequency: (plan.billingPrice && plan.billingPrice.cycleValue) || 0,
            discountPercent: getDiscountPercent(plan),
            experimentName: 'experimental-plan-pricing',
            experimentVariant: 'enabled',
            isLoggedInUser,
          });
        } else {
          analyticsHelper.planSelectedEvent({
            source,
            funnelName: boughtFromToString(boughtFrom),
            displayName: plan.displayName,
            priceAmount,
            billingFrequency: (plan.billingPrice && plan.billingPrice.cycleValue) || 0,
            discountPercent: getDiscountPercent(plan),
            isLoggedInUser,
          });
        }

        setRoomOfferState({
          coupon: { status: 'ready' }, // reset coupon state to be recalculated on the next step
          selectedSubscription: plan,
          selectedPlanSavings: plan.savings || 0,
        });

        if (isChangePlan && roomID) {
          dispatch({
            type: 'loadingChangePlanCheckoutInfo',
            payload: { changePlanCheckoutInfo: { loading: true } },
          });

          api
            .getChangePlanCheckoutInfo(roomID, plan.id)
            .then((changePlanCheckoutInfo) => {
              dispatch({
                type: 'loadedChangePlanCheckoutInfo',
                payload: {
                  changePlanCheckoutInfo: changePlanCheckoutInfo && {
                    loading: false,
                    ...changePlanCheckoutInfo,
                  },
                },
              });
            })
            .catch(api.dismissIfCancelled)
            .catch((error) => {
              const errorMessage = Number.isInteger(+error.message)
                ? 'There was an error. Please try again.'
                : error.message;
              toast(errorMessage);
              // eslint-disable-next-line no-console
              console.error(error);
              dispatch({
                type: 'changePlanCheckoutInfoError',
                payload: { changePlanCheckoutInfo: undefined },
              });
            });
        }

        // Otherwise, go to the next page
        pushOfferRoute(match, history, location, '/checkout');
      },
      [
        api,
        boughtFrom,
        history,
        isChangePlan,
        location,
        match,
        promoFromBanner?.shownAdditionalBillingCycles,
        roomID,
        source,
        experimentalPlanPricing,
        isLoggedInUser,
      ]
    );

    const paymentDetailsEntered = useCallback(
      (token: string, newPaymentDetails?: PaymentDetails, newEmail?: string) => {
        dispatch({
          type: 'resetErrorSubscription',
          payload: {
            isErrorSubscription: false,
            subscriptionErrorMessage: '',
          },
        });

        setRoomOfferState({
          email: newEmail,
          paymentToken: token,
          ...(newPaymentDetails ? { paymentDetails: newPaymentDetails } : {}),
        });

        if (!selectedSubscription) {
          throw new Error('Invalid state');
        }

        doSubscribe(selectedSubscription, token, newEmail);
      },
      [selectedSubscription, doSubscribe]
    );

    const changeTherapist = useCallback(async () => {
      if (onChangeTherapist) {
        if (source === AppSource.client) {
          history.push(`/room-reactivation/${roomID}`);
        } else {
          onChangeTherapist();
        }
      }
    }, [history, onChangeTherapist, roomID, source]);

    const loadLink = useCallback(async () => {
      let planID;
      if (selectedSubscription) {
        const { id: tempPlanID } = selectedSubscription;
        planID = tempPlanID;
      }
      const { id } = getUserData();
      const res = await paymentAPI.getSetupIntent({ userID: id, planID });
      return res;
    }, [selectedSubscription]);

    const linkError = useCallback(async (error: any) => {
      dispatch({
        type: 'setErrorSubscription',
        payload: {
          isErrorSubscription: true,
          isLoadingSubscription: false,
          subscriptionErrorMessage: error.message,
        },
      });
    }, []);

    const resetError = useCallback(async () => {
      dispatch({
        type: 'resetError',
        payload: {
          isErrorSubscription: false,
          subscriptionErrorMessage: undefined,
        },
      });
    }, []);

    const trackPlanExpend = useCallback(
      (displayName: string) => {
        if (offerID) {
          analyticsHelper.planExpend(source, displayName, offerID, boughtFromToString(boughtFrom));
        }
      },
      [boughtFrom, offerID, source]
    );

    const actions: Actions = {
      setRoomOfferStateAction: useCallback(setRoomOfferState, []),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      loadRoomOfferAction: useCallback(loadRoomOffer, []),
      planSelectedAction: planSelected,
      paymentDetailsEnteredAction: paymentDetailsEntered,
      loadLinkAction: loadLink,
      changeTherapistAction: changeTherapist,
      stripeLinkErrorAction: linkError,
      resetErrorAction: resetError,
      validateCouponAction: validateCoupon,
      checkoutConfirmedAction: checkoutConfirmed,
      trackPlanExpendAction: trackPlanExpend,
    };

    return (
      <StateContext.Provider value={state}>
        <ActionsContext.Provider value={actions}>{children}</ActionsContext.Provider>
      </StateContext.Provider>
    );
  }
);

export { RoomOfferProvider };

export function useRoomOffersState(): RoomOfferState {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error('StateContext must be used within a ContextProvider');
  }
  return context;
}

export function useRoomOffersActions() {
  const context = useContext(ActionsContext);
  if (context === undefined) {
    throw new Error('ActionsContext must be used within a ContextProvider');
  }
  return context;
}
