/* eslint-disable import/prefer-default-export */
import { TagColorName } from '@talkspace/react-toolkit/src/designSystems/components/Tag';

import { RoomTypeCamelCase } from '../entities/Room';

export const roomTypeToRoomTagData: {
  [key in RoomTypeCamelCase]: {
    text: string;
    color: TagColorName;
  };
} = {
  privateRoom: {
    text: 'Therapy',
    color: 'brandDefault',
  },
  psychiatryRoom: {
    text: 'Psychiatry',
    color: 'decorativePsych',
  },
  couplesRoom: {
    text: 'Couples Therapy',
    color: 'decorativeCouples',
  },
};
