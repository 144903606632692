import React from 'react';
import { Trans, useTranslation } from '@talkspace/i18n';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { Link, A11Y_COLORS, Tiny } from '@talkspace/react-toolkit';
import styled from '../../core/styled';

const informedConsentLink = 'https://www.talkspace.com/public/telemedicine';

const privacyPracticesLink = 'https://www.talkspace.com/notice-of-privacy-practices';

const privacyPolicyLink = 'https://www.talkspace.com/public/privacy-policy';

const AcceptTermsWrapper = styled.div({
  lineHeight: '18px',
  marginLeft: 15,
  color: A11Y_COLORS.darkGray,
});

const AcceptTerms = ({ userFirstName }: { userFirstName: string }) => {
  const { localizationParentalConsent } = useFlags();
  const { t: tParentalConsent } = useTranslation('parentalConsentV2', {
    lng: localizationParentalConsent ? undefined : 'en',
  });
  return (
    <AcceptTermsWrapper>
      <Tiny variant="tinyDarkGray">
        <Trans t={tParentalConsent} i18nKey="consentPage.termsBody">
          I have read and understood Talkspace’s{' '}
          <Link
            href={informedConsentLink}
            style={{ color: A11Y_COLORS.green, fontWeight: 700, margin: 0 }}
          >
            Informed Consent
          </Link>
          , the{' '}
          <Link
            href={privacyPracticesLink}
            style={{ color: A11Y_COLORS.green, fontWeight: 700, margin: 0 }}
          >
            Notice of Privacy Practices
          </Link>{' '}
          and the{' '}
          <Link
            href={privacyPolicyLink}
            style={{ color: A11Y_COLORS.green, fontWeight: 700, margin: 0 }}
          >
            Privacy Policy
          </Link>{' '}
          and give my consent for Talkspace providers to provide treatment services to{' '}
          {{ first_name: userFirstName }}.
        </Trans>
      </Tiny>
    </AcceptTermsWrapper>
  );
};

export default AcceptTerms;
