import React from 'react';
import { Trans, useTranslation } from '@talkspace/i18n';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { Link, View, TextDS } from '@talkspace/react-toolkit';
import {
  informedConsentLink,
  noticeOfPrivatePolicy,
  generalPrivacyPolicyLink,
} from 'ts-frontend/constants';
import styled from '../../core/styled';

const teenspacePrivacyPolicyLink = 'https://www.talkspace.com/public/privacy-policy';
const consentCopy = 'and give my consent for Talkspace providers to provide treatment services to ';

const NYCAcceptTermsWrapper = styled(View)(() => {
  return {
    marginLeft: 15,
    display: 'inline',
  };
});

const StyledLink = styled(Link)<{ shouldUnderLine?: boolean }>(
  ({ theme: { colorRoles }, shouldUnderLine }) => {
    return {
      color: colorRoles.typography.textBrandDefault,
      fontWeight: 600,
      margin: 0,
      textDecoration: shouldUnderLine && 'underline',
    };
  }
);

const NYCAcceptTerms = ({ userFirstName }: { userFirstName: string }) => {
  const { localizationParentalConsent } = useFlags();
  const { t: tParentalConsent } = useTranslation('parentalConsentV2', {
    lng: localizationParentalConsent ? undefined : 'en',
  });
  return (
    <NYCAcceptTermsWrapper flex={1}>
      <TextDS variant="bodySm">
        <Trans t={tParentalConsent} i18nKey="consentPage.nycTermsBody">
          I have read and understood Talkspace’s{' '}
          <StyledLink href={informedConsentLink} text="Informed Consent" />,{' '}
          <StyledLink href={noticeOfPrivatePolicy} text="Notice of Privacy Practices" />,{' '}
          <StyledLink
            shouldUnderLine
            href={teenspacePrivacyPolicyLink}
            text="NYC Teenspace Privacy Policy"
          />
          , and{' '}
          <StyledLink
            shouldUnderLine
            href={generalPrivacyPolicyLink}
            text="Rules for using the Talkspace App"
          />{' '}
          {consentCopy}
          {{ first_name: userFirstName }}.
        </Trans>
      </TextDS>
    </NYCAcceptTermsWrapper>
  );
};

export default NYCAcceptTerms;
