import { Card, SkeletonLoader, SpacingView, View, spacing } from '@talkspace/react-toolkit';

const { space025, space050, space075, space150 } = spacing;

const ListItemLoadingSkeleton = () => (
  <Card>
    <View row>
      <SkeletonLoader variant="Icon64" style={{ borderRadius: 30 }} />
      <View style={{ marginLeft: space150 }}>
        <SkeletonLoader variant="StatusTag" />
        <SkeletonLoader
          variant="body"
          style={{ marginTop: space075, width: 167 }}
          wrapperStyle={{ padding: `${space050}px 0` }}
        />
        <SkeletonLoader
          variant="body"
          wrapperStyle={{ paddingTop: space050 }}
          style={{ marginTop: space025, width: 128 }}
        />
      </View>
    </View>
  </Card>
);

const ScheduleChooseProviderListLoadingSkeleton = () => (
  <SpacingView itemSpacing="space150" flex={1}>
    <ListItemLoadingSkeleton />
    <ListItemLoadingSkeleton />
  </SpacingView>
);

export default ScheduleChooseProviderListLoadingSkeleton;
