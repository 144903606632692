import { FunctionComponent, useEffect, useState } from 'react';
import { PanelManager } from '@talkspace/react-toolkit';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { RouteComponentProps, withRouter } from '../core/routerLib';
import ClientWebPanelManager from '../components/ClientWebPanelManager';
import { getUserData } from '../auth/helpers/token';
import { useMainActions, useMainState } from '../hooks/mainContext';
import useDashboardSocket from '../hooks/useDashboardSocket';
import useRoomSubscriptionSocket from '../hooks/useRoomSubscriptionSocket';
import DropdownMenu from '../components/DropdownMenu';
import LoadingScreen from '../screens/LoadingScreen';
import { useRoomInvitesActions, getIsInviteRoomWithMissingPartner } from '../roomInvites';

type PageStates = 'LOADING' | 'READY';

const Main: FunctionComponent<
  RouteComponentProps<{
    roomID?: string;
  }>
> = (props) => {
  const { history, match, location } = props;
  const { roomID } = match.params;
  const { getAllRooms, getClient, getLastMessages, getLVSSubscriptions } = useMainActions();
  const { roomsByID, me, clientsByRoomID } = useMainState();
  const { id: clientUserID } = getUserData();
  const [isReady, setIsReady] = useState(false);
  const shouldRenderRoom = me && Object.keys(roomsByID).length;
  const { fetchRoomInvites } = useRoomInvitesActions();

  const useNewNav = useNewMemberNav();

  const [pageState, setPageState] = useState<PageStates>('LOADING');

  useDashboardSocket();
  useRoomSubscriptionSocket({ getLVSSubscriptions });

  useEffect(() => {
    getLastMessages(clientUserID);
    Promise.all([getAllRooms(clientUserID), getClient(clientUserID)])
      .then(() => {
        setIsReady(true);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        history.push(`/unauthorized`);
      });
  }, [getAllRooms, getClient, clientUserID, getLastMessages, history]);

  useEffect(() => {
    const fixMissingRoomID = () => {
      const otherPath = location.pathname.replace(/\/room\/?(.*)/g, '$1');
      const addSlash = otherPath[0] !== '/' && otherPath.length ? '/' : '';
      history.replace(`/rooms${addSlash}${otherPath}`);
    };
    if (isReady) {
      if (shouldRenderRoom) {
        if (roomID) {
          const activeRoom = roomsByID[roomID];
          if (activeRoom) {
            setPageState('READY');
            if (getIsInviteRoomWithMissingPartner(activeRoom, clientsByRoomID[roomID])) {
              fetchRoomInvites(Number(roomID));
            }
          } else {
            history.replace('/rooms');
          }
        } else if (!location.pathname.startsWith('/rooms')) {
          fixMissingRoomID();
        } else {
          setPageState('READY');
        }
      } else {
        history.push('/eligibility-widget');
      }
    }
  }, [
    isReady,
    shouldRenderRoom,
    roomID,
    history,
    roomsByID,
    location.pathname,
    fetchRoomInvites,
    clientsByRoomID,
  ]);

  switch (pageState) {
    case 'READY':
      return (
        <PanelManager enableCompactView={useNewNav} forceLargeRightPanel={useNewNav}>
          <ClientWebPanelManager {...props} />
          <DropdownMenu />
        </PanelManager>
      );
    case 'LOADING':
    default:
      return <LoadingScreen />;
  }
};

export default withRouter(Main);
