import { useEmotionTheme } from '@talkspace/react-toolkit';
import { useEffect, useRef, useState, VFC } from 'react';
import { QMSessionTypeEnum } from 'ts-frontend/types';
import {
  PromiseMessageDataHandler,
  PromiseMessageTypeNames,
  usePromiseMessageContextActions,
} from 'ts-promise-message';
import { LDClient, useLDClient } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'core/routerLib';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { getUserData } from '../auth/helpers/token';
import ReactFrameService from '../auth/reactFrame/ReactFrameService';
import { ClosePopupAction } from '../auth/reactFrame/ReactFrameTypes';
import ActionStatus from '../components/ActionStatus';
import IFrameModal from '../components/IFrameModal';
import apiHelper from '../core/api/apiHelper';
import ApiHelper from '../utils/ApiHelper';
import { useCloseModal } from '../utils/ModalsContextProvider';
import { UserAuthProvider } from '../entities/Me';

const PENDING_SUBSCRIPTION_ERROR_MESSAGE = `We're busy finding you a provider.`;

const providerFlowMap = {
  [UserAuthProvider.AETNA]: '/flow/44',
};

const DISPATCHER_PATH = '/dispatcher';

interface QuickmatchFrameRenderProps {
  handleLaunchButton: () => void;
}

interface QuickmatchFrameProps {
  frameTitle: string;
  authProvider?: UserAuthProvider;
  sessionType?: QMSessionTypeEnum;
  showCloseButton?: boolean;
  qmFlowPath?: string;
  autoStart?: boolean;
  couponCode?: string;
  render: VFC<QuickmatchFrameRenderProps>;
  onLaunchPress?: () => void;
  onQMInitData?: PromiseMessageDataHandler<PromiseMessageTypeNames.getQMInitData>;
  anonToken?: string;
  anonID?: string;
  onClosePress?: () => void;
}

const convertLDUserDataToParams = (ldClient?: LDClient, isFirstQueryParam = false) => {
  let ldFlagParams = '';
  if (ldClient) {
    const { key, ...custom } = ldClient.getContext();
    ldFlagParams += `${isFirstQueryParam ? '?' : '&'}ld_key=${key}`;
    if (custom) {
      const keys = Object.keys(custom);
      for (let i = 0; i < keys.length; i += 1) {
        const k = keys[i];
        const value = custom[k];
        ldFlagParams += `&ld_${k}=${JSON.stringify(value)}`;
      }
    }
  }
  return encodeURI(ldFlagParams);
};

const getModalPath = (data: ClosePopupAction) => {
  if (!data || data?.navigateTo !== 'modal') return null;
  const { metadata } = data;

  switch (metadata.modalName) {
    case 'findingProvider':
      return `/finding-provider?room=${metadata.roomID}&hasRemainingSessions=${metadata.hasRemainingSessions}`;
    case 'eligibilityWarningSuccess':
      return `/eligibility-warning/${metadata.roomID}/success?${new URLSearchParams({
        isNewProvider: String(metadata.isNewProvider),
        newRoomID: String(metadata.newRoomID),
        sessionsCanceled: String(metadata.sessionsCanceled),
        sessionsTransferred: String(metadata.sessionsTransferred),
      }).toString()}`;
    default:
      return null;
  }
};

const QuickmatchFrame = ({
  render,
  onLaunchPress,
  frameTitle,
  authProvider,
  couponCode,
  showCloseButton = true,
  sessionType = QMSessionTypeEnum.Eligibility,
  qmFlowPath = authProvider && providerFlowMap[authProvider]
    ? `${providerFlowMap[authProvider]}?source=${sessionType}${
        couponCode ? `&cc=${couponCode}` : ''
      }`
    : `${DISPATCHER_PATH}?source=${sessionType}${couponCode ? `&cc=${couponCode}` : ''}`,
  autoStart = false,
  anonToken,
  anonID,
  onClosePress,
  onQMInitData,
}: QuickmatchFrameProps) => {
  const { colors } = useEmotionTheme();
  const closeModal = useCloseModal();
  const useNewNav = useNewMemberNav();

  const history = useHistory();
  const [showIFrameModal, setShowIFrameModal] = useState(autoStart);
  const [isError, setShowError] = useState(false);
  const [isLoading, setShowLoading] = useState(autoStart);
  const [isPendingMatchError, setIsPendingMatchError] = useState(false);
  const { id: userID } = getUserData();
  const apiHelperRef = useRef(new ApiHelper());
  const { addPromiseMessageTypeHandler } = usePromiseMessageContextActions();
  const ldClient = useLDClient();

  useEffect(
    () => () => {
      apiHelperRef.current.cancelAll();
    },
    []
  );

  const handleLaunchButton = () => {
    setShowLoading(true);
    setShowIFrameModal(true);
    onLaunchPress?.();
  };

  const onTokenRequest = async () => {
    try {
      if (sessionType === QMSessionTypeEnum.SignUp)
        return { token: anonToken || '', userID: anonID || '' };

      setShowLoading(true);

      const { anonID: apiAnonID, token } = await apiHelperRef.current.getQMFrameSession(
        userID,
        sessionType
      );

      if (!token || !apiAnonID) {
        setShowIFrameModal(false);
        setShowError(true);
      }
      return { token, userID: apiAnonID };
    } catch (e) {
      setIsPendingMatchError(e?.status === 409);
      setShowIFrameModal(false);
      setShowError(true);
      throw e;
    } finally {
      setShowLoading(false);
    }
  };

  const redirect = (returnData: ClosePopupAction | undefined) => {
    if (!returnData || !returnData.navigateTo || !('metadata' in returnData)) {
      if (onClosePress) {
        onClosePress();
      } else if (useNewNav && userID) {
        closeModal({ navigateTo: 'home', metadata: { path: '/' } });
      } else {
        closeModal();
      }

      return;
    }

    if (returnData.navigateTo === 'room' && returnData.metadata?.roomID) {
      const { metadata } = returnData;
      const reactFrameService = ReactFrameService.instance();
      const closingPayload: ClosePopupAction = {
        navigateTo: 'room',
        metadata: { roomID: metadata.roomID, source: metadata.source },
      };
      if (reactFrameService.isInFrame()) {
        reactFrameService.closePopup(closingPayload);
      } else {
        closeModal(closingPayload);
      }
    }

    if (returnData.navigateTo === 'emailVerification' && returnData.metadata?.email) {
      closeModal({
        navigateTo: 'emailVerificationSent',
        metadata: { email: returnData.metadata.email, token: returnData.metadata.token },
      });
    }

    if (returnData?.navigateTo === 'modal' && returnData?.metadata?.roomID) {
      const modalPath = getModalPath(returnData);
      if (modalPath) {
        history.push(modalPath);
      } else {
        closeModal();
      }
    }
  };

  useEffect(() => {
    let unsubscribe: Function | undefined;
    if (showIFrameModal && onQMInitData) {
      unsubscribe = addPromiseMessageTypeHandler(
        PromiseMessageTypeNames.getQMInitData,
        onQMInitData
      );
    }
    return () => {
      unsubscribe?.();
    };
  }, [addPromiseMessageTypeHandler, onQMInitData, showIFrameModal]);

  if (showIFrameModal) {
    const ldUserDataParams = convertLDUserDataToParams(ldClient, !qmFlowPath.includes('?'));
    return (
      <IFrameModal
        isLoading={isLoading}
        src={`${
          apiHelper().quickMatchWebEndpoint
        }${qmFlowPath}${ldUserDataParams}#origin=${encodeURIComponent(window.location.origin)}`}
        title={frameTitle}
        onClose={(returnData) => redirect(returnData)}
        onTokenRequest={onTokenRequest}
        showCloseButton={showCloseButton}
      />
    );
  }

  if (isError) {
    return (
      <ActionStatus
        isLoading={false}
        isError={isError}
        errorTitle={
          isPendingMatchError ? 'Finding a Perfect Match For You' : 'Something Went Wrong'
        }
        errorSubTitle={
          isPendingMatchError
            ? PENDING_SUBSCRIPTION_ERROR_MESSAGE
            : 'Please check your internet connection and try again.'
        }
        errorButtonText="Close"
        errorButtonAction={() => {
          closeModal();
        }}
        titleStyles={{ fontWeight: 800, fontSize: 29, marginTop: 25, textAlign: 'center' }}
        subTitleStyles={{
          fontSize: 16,
          color: colors.darkGray,
          marginTop: 13,
          marginBottom: 42,
          width: 200,
          textAlign: 'center',
        }}
      />
    );
  }
  return render({ handleLaunchButton });
};

export default QuickmatchFrame;
