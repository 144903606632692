import * as React from 'react';
import Svg, { Path } from 'svgs';
import { EmotionStyle } from '../../core/styled';

interface OpenBookProps {
  style?: EmotionStyle;
}

function OpenBook(props: OpenBookProps) {
  const { style } = props;
  const title = 'open book';
  return (
    <Svg
      width={59}
      height={45}
      title={title}
      aria-label={title}
      viewBox="0 0 59 45"
      fill="none"
      {...props}
      style={{
        mixBlendMode: 'multiply',
        margin: '9px 3px 10px',
        ...style,
      }}
    >
      <Path
        d="M36.137 33.693l-14.874-19.9-10.691-.926-8.367-.926 12.55 20.826h.93l17.663.926h2.789z"
        fill="#E5D5E1"
      />
      <Path d="M37.876 1.063L21.546 14.1 36.213 34.56l18.195-16.298-16.531-17.2z" fill="#E5D5E1" />
      <Path d="M56.01 28.688l-5.125-6.989-13.511 11.647 16.772-3.727 1.863-.931z" fill="#E5D5E1" />
      <Path
        d="M56.382 29.702a.649.649 0 00.355-.854c-.781-1.94-3.081-5.66-4.743-7.443a41.764 41.764 0 002.912-3.146.676.676 0 00-.044-.91c-4.51-4.51-8.257-9.624-15.79-16.841a.677.677 0 00-.884-.045c-5.272 4.1-12.307 8.32-16.657 13.145a.666.666 0 00-.42-.414c-5.08-1.599-14.241-.786-19.124-2.324a.677.677 0 00-.755.257c-.514.726.245.59 4.3 7.044-.864.413-3.923 2.072-4.305 2.192a.649.649 0 00-.483.756c-.728.699-.728 1.251-.52 2.22a9.968 9.968 0 001.261 3.126c3.63 5.898 7.498 11.847 11.184 17.757.393.63 1.112.916 1.786.708 6.677-2.057 13.155-4.327 19.857-6.36 1.188 2.635 6.091.697 5.602-1.757 5.283-.403 10.826-1.175 16.13-1.899.834-.113 1.487-.83 1.592-1.74l.015-.13a.65.65 0 00.306-1.02c-.203-.267-1.08-1.608-1.574-2.323zm-1.231-1.088c-4.863 1.048-10.976 2.62-16.067 3.772 4.046-3.489 8.202-6.528 11.935-10.034 1.338 1.435 3.2 4.337 4.132 6.262zM38.555 1.887c6.968 6.729 10.632 11.614 14.915 15.978-4.726 5.522-11.585 10.046-17.1 15.118l-.008.001-.01.005c-4.757-6.135-9.057-12.327-13.7-18.616C26.75 9.897 33.367 5.86 38.556 1.89v-.002zM20.705 14.486a.657.657 0 00.42-.018c.02.094.052.186.112.267 4.646 6.276 8.81 12.287 13.716 18.647-3.194-.915-7.662-.898-12.006-.876-2.953.016-5.747.029-7.81-.268-3.812-6.767-7.213-13.386-11.7-19.62 5.016.961 12.985.518 17.267 1.867zm-14.46 4.843c2.781 4.543 5.266 9.237 7.875 13.86a.677.677 0 00.478.336c5.344.886 16.145-.5 21.01 1.504.326.136.748 0 .89-.402.152-.053.2-.098.528-.403 5.54-1.178 12.197-2.927 17.959-4.186.024.046.493.734 1.248 1.872-5.88.775-11.76 1.478-17.491 2.094a.677.677 0 00-.603.633c-.051.86-.314 1.343-.906 1.669-.621.34-1.4.294-1.984-.12a.677.677 0 00-.581-.096c-7.627 2.24-19.564 5.991-20.03 5.837-.227-.072-.476-.434-.64-.673A165.831 165.831 0 012.317 21.35c.977-.482 3.127-1.65 3.926-2.022v.001z"
        fill="#3A5287"
      />
      <Path
        d="M33.42 20.625c1.267.486 2.613.438 3.802.402 3.5-.123 3.264.574 3.853.242.35-.198.303-.378.578-1.653.38-1.755.737-3.412.312-5.294-.23-1.013-.66-2.478-1.759-3.494-1.008-.932-2.794-1.42-4.116-.485-.684.485-1.146 1.283-1.302 2.25-.08.495.022.977.143 1.417-2.507-.158-4.098 1.61-4.065 3.37.032 1.576 1.305 2.765 2.553 3.245zm2.227-5.112a.677.677 0 00.869-.747c-.09-.593-.466-1.496-.392-1.959.095-.595.368-1.09.747-1.359.712-.505 1.789-.207 2.417.374.625.578 1.056 1.468 1.357 2.798.396 1.751-.025 3.349-.422 5.21-2.125-.464-4.5.229-6.32-.467-.798-.307-1.666-1.066-1.685-2.008-.024-1.32 1.5-2.437 3.43-1.842z"
        fill="#3A5287"
      />
      <Path
        d="M35.647 15.513a.677.677 0 00.869-.747c-.09-.593-.466-1.496-.392-1.959.095-.595.368-1.09.747-1.359.712-.505 1.789-.207 2.417.374.625.578 1.056 1.468 1.357 2.798.396 1.751-.025 3.349-.422 5.21-2.125-.464-4.5.229-6.32-.467-.798-.307-1.666-1.066-1.685-2.008-.024-1.32 1.5-2.437 3.43-1.842z"
        fill="#fff"
      />
    </Svg>
  );
}

export default OpenBook;
