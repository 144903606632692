import { FunctionComponent, useEffect } from 'react';

import { Checkout } from 'checkout';
import { RouteComponentProps, withRouter } from '../../core/routerLib';
import ErrorMessage from '../components/ErrorMessage';
import Spinner from '../components/Spinner';
import { useCloseModal } from '../../utils/ModalsContextProvider';
import {
  useInsuranceEligibilityActions,
  useInsuranceEligibilityState,
} from '../hooks/InsuranceEligibilityContext';
import useAccountDetails from '../../myAccount/hooks/useAccountDetails';
import { useMainActions, useMainState } from '../../hooks/mainContext';
import { GENERAL_ERROR } from '../utils/constants';
import { getUserData } from '../../auth/helpers/token';
import { extractRoomEntitiesFromState } from '../../utils/extractRoomEntitiesFromState';

interface OwnProps {
  scrollTop: number;
}

type Props = OwnProps & RouteComponentProps<{ roomID: string }>;

const CheckoutContainer: FunctionComponent<Props> = ({ match, history, scrollTop }) => {
  const roomID = Number(match.params.roomID);

  const {
    eligibilityInfo,
    planInfo,
    lineItems,
    savings,
    verificationStatus,
    verificationError,
    purchaseStatus,
    purchaseError,
  } = useInsuranceEligibilityState();
  const { verifyEligibility, purchaseBHSession } = useInsuranceEligibilityActions();
  const [{ paymentDetails, isLoading: isPaymentLoading }, { getPaymentDetails }] =
    useAccountDetails();
  const mainState = useMainState();
  const { getAllRooms } = useMainActions();
  const closeModal = useCloseModal();

  const { id } = getUserData();

  useEffect(() => {
    verifyEligibility(roomID);
  }, [roomID, verifyEligibility]);

  useEffect(() => {
    if (eligibilityInfo && eligibilityInfo.isEligible) {
      getAllRooms(id);
      getPaymentDetails();
    }
  }, [eligibilityInfo, getAllRooms, getPaymentDetails, id, roomID]);

  useEffect(() => {
    if (purchaseStatus === 'success') {
      closeModal({ navigateTo: 'room', metadata: { roomID } });
    }
  }, [closeModal, purchaseStatus, roomID]);

  useEffect(() => {
    if (purchaseStatus === 'error' && purchaseError.length && purchaseError !== GENERAL_ERROR) {
      history.push(`/insurance-eligibility/room/${roomID}/payment-details`);
    }
  }, [history, purchaseError, purchaseStatus, roomID]);

  const handleChangePaymentClick = () => {
    history.push(`/insurance-eligibility/room/${roomID}/payment-details`);
  };

  const handleContinueTherapyClick = () => {
    purchaseBHSession(roomID);
  };

  if (verificationStatus === 'loading' || isPaymentLoading) {
    return <Spinner text="Validating insurance information..." />;
  }

  if (purchaseStatus === 'loading') {
    return (
      <Spinner
        text={eligibilityInfo?.copayCents === 0 ? 'Processing coverage' : 'Processing payment'}
      />
    );
  }

  if (verificationStatus === 'error' || purchaseStatus === 'error') {
    return <ErrorMessage error={verificationError || purchaseError} goBack={() => closeModal()} />;
  }

  if (
    eligibilityInfo &&
    eligibilityInfo.isEligible &&
    planInfo &&
    Object.keys(mainState.therapistsByID).length > 0
  ) {
    const therapistFirstName = extractRoomEntitiesFromState(mainState, roomID).therapist.firstName;
    return (
      <Checkout
        title="Start your next session"
        subtitle={`To continue therapy, start your next session with ${therapistFirstName}.`}
        planReviewCardTitle="Session details"
        selectedSubscription={planInfo}
        lineItems={lineItems}
        total={eligibilityInfo.copayCents / 100}
        savings={savings}
        paymentDetails={paymentDetails}
        buttonText="Continue therapy"
        showInsuranceDisclaimer
        scrollTop={scrollTop}
        onChangePayment={handleChangePaymentClick}
        onCompleteCheckout={handleContinueTherapyClick}
      />
    );
  }

  return null;
};

export default withRouter(CheckoutContainer);
