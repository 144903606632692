import { useCallback, useState } from 'react';
import SurveyWizard from 'surveyWizard';
import { AppSource } from 'ts-frontend/types';
import { Screen } from '@talkspace/react-toolkit';
import { RouteComponentProps } from '@/core/routerLib';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import ErrorModal from '../ErrorModal';
import { trackEvent, tsAnalyticsTracker } from '../../../utils/analytics/eventTracker';

const SurveyWizardAction = ({
  location,
  history,
}: RouteComponentProps<{}, {}, { roomID: number; userRoomSurveyID: number }>) => {
  const { userRoomSurveyID, roomID } = location.state || {};
  const [error, setError] = useState<string | null>(null);
  const closeModal = useCloseModal();
  const openResults = useCallback(() => {
    // we are routing to the results screen using the modal router
    history.push(`/room/${roomID}/survey-results/${userRoomSurveyID}`);
    trackEvent(
      'Clinical Assessment Done',
      {
        actionName: 'clinicalAssessmentDone',
        roomID,
        userRoomSurveyID,
        isAssessmentDone: true,
        targets: [tsAnalyticsTracker.targetsEnum.BRAZE],
      },
      ['tsAnalytics']
    );
  }, [history, roomID, userRoomSurveyID]);

  return (
    <Screen showCloseButton onCloseButtonClick={closeModal}>
      {error ? (
        <ErrorModal
          // Remove error title
          error={' '}
          errorMessage={error}
          onButtonPress={() => closeModal()}
        />
      ) : (
        <SurveyWizard
          handleSurveysError={setError}
          closeWizardAction={openResults}
          surveyID={userRoomSurveyID}
          source={AppSource.client}
        />
      )}
    </Screen>
  );
};

export default SurveyWizardAction;
