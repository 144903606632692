import * as React from 'react';
import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const ArrowDownRight: React.FunctionComponent<{
  color?: string;
  height?: number;
  width?: number;
  style?: object;
}> = ({ color, width = 13, height = 13, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'downwards right arrow';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 13 13"
      {...otherProps}
    >
      <Path
        d="M13.54 9.262L11.437 7.07a.946.946 0 010-1.298l.17-.178a.855.855 0 011.245 0l3.87 4.036a.938.938 0 01.258.649v.078a.938.938 0 01-.258.649l-3.87 4.036a.855.855 0 01-1.245 0l-.17-.178a.946.946 0 010-1.297c.804-.84 2.103-2.193 2.103-2.193H2.904a.862.862 0 01-.623-.269.938.938 0 01-.257-.649v-.276c0-.244.092-.477.257-.65a.862.862 0 01.623-.268H13.54z"
        transform="translate(-3 -4) scale(1 -1) rotate(-45 -15.408 0)"
        fill={color || colors.green}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default ArrowDownRight;
