import appConfig from '@/utils/configs';

// eslint-disable-next-line import/prefer-default-export
export const getDefaultOfferByRoomType = (roomType: string | undefined) => {
  let offerID = appConfig.offers.defaultPrivateRoomOfferId;
  if (roomType === 'couplesRoom') {
    offerID = appConfig.offers.defaultCouplesRoomOfferId;
  }
  if (roomType === 'psychiatryRoom') {
    offerID = appConfig.offers.defaultPsychRoomOfferId;
  }
  return offerID;
};
