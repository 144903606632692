import moment from 'moment';
import sessionStorage from '@/core/storage/sessionStorage';
import { Step, TEEN_STEP_TYPES } from '../types';
import storage, { StoragePersistanceMode } from '@/core/storage';

export const IS_ONBOARDING_DISMISSED_STORAGE_KEY = 'isOnboardingDismissed';
export const IS_ONBOARDING_DISMISSED_TEST_ACCOUNT_STORAGE_KEY = 'isOnboardingDismissedTestAccount';
const IS_ONBOARDING_TEEN_FLOW = 'isOnboardingTeenFlow';

const safeGetStorageArr = (storageKey: string): (string | number)[] => {
  try {
    const storageValue = sessionStorage.getItem(storageKey) || '[]';
    const onboardingDismissedArr = JSON.parse(storageValue);
    if (!Array.isArray(onboardingDismissedArr)) {
      throw new Error(`Malformed storage value for ${storageKey}`);
    }
    return onboardingDismissedArr;
  } catch (e) {
    sessionStorage.removeItem(IS_ONBOARDING_DISMISSED_STORAGE_KEY);
    return [];
  }
};

const safePushStorageArr = (storageKey: string, arrItem: string | number): void => {
  const currentArr = safeGetStorageArr(storageKey);
  sessionStorage.setItem(storageKey, JSON.stringify([...currentArr, arrItem]));
};

const safePopStorageArr = (storageKey: string, arrItem: number): void => {
  if (isNaN(arrItem) || arrItem === null || arrItem === undefined) {
    return;
  }
  const currentArr = safeGetStorageArr(storageKey);
  if (currentArr && currentArr.length) {
    const filteredArr = currentArr.filter((item) => Number(item) !== arrItem);
    sessionStorage.setItem(storageKey, JSON.stringify(filteredArr));
  }
};

export const getIsOnboardingDismissed = (roomID: number | string) =>
  safeGetStorageArr(IS_ONBOARDING_DISMISSED_STORAGE_KEY).includes(Number(roomID));

export const setIsOnboardingDismissed = (roomID: number | string): void => {
  safePushStorageArr(IS_ONBOARDING_DISMISSED_STORAGE_KEY, Number(roomID));
};

export const popIsOnboardingDismissed = (roomID: number | string): void => {
  safePopStorageArr(IS_ONBOARDING_DISMISSED_STORAGE_KEY, Number(roomID));
};

export const getIsOnboardingDismissedTestAccount = (roomID: number | string) =>
  safeGetStorageArr(IS_ONBOARDING_DISMISSED_TEST_ACCOUNT_STORAGE_KEY).includes(Number(roomID));

export const setIsOnboardingDismissedTestAccount = (roomID: number | string) => {
  safePushStorageArr(IS_ONBOARDING_DISMISSED_TEST_ACCOUNT_STORAGE_KEY, Number(roomID));
};

export const popIsOnboardingDismissedTestAccount = (roomID: number | string) => {
  safePopStorageArr(IS_ONBOARDING_DISMISSED_TEST_ACCOUNT_STORAGE_KEY, Number(roomID));
};

export const checkIfTeensIntake = (steps: Step[]) =>
  storage.setTemporaryMode(StoragePersistanceMode.LOCAL, () => {
    let isTeensIntake = !!storage.getItemWithExpiration(IS_ONBOARDING_TEEN_FLOW);

    if (!isTeensIntake) {
      isTeensIntake = !!steps.find((step) => Object.values(TEEN_STEP_TYPES).includes(step.step));
      storage.setItemWithExpiration(
        IS_ONBOARDING_TEEN_FLOW,
        isTeensIntake,
        moment().add(1, 'week')
      );
    }
    return isTeensIntake;
  });
