import { useEffect } from 'react';
import { RouteComponentProps, withRouter } from '@/core/routerLib';

const OnboardingPageTeenEmergencyContact = ({
  match: {
    params: { roomID },
  },
  history,
}: RouteComponentProps<{ roomID: number }> & { totalSteps: number; currentStep: number }) => {
  // There is no intro screen between the parental consent form and the teen emergency contact
  useEffect(() => {
    history.push(`/room/${roomID}/onboarding/teen-emergency-contact/source/onboarding`);
  }, [history, roomID]);
  return null;
};

export default withRouter(OnboardingPageTeenEmergencyContact);
