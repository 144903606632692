import { CloseModal } from '../utils/ModalsContextProvider';
import { Route } from '../core/routerLib';
import HelpScreen from './screens/HelpScreen';
import AccountScreen from './screens/AccountScreen';
import PersonalInformationContainer from '../myAccount/containers/PersonalInformation';
import AccountDetailsContainer from '@/myAccount/containers/AccountDetails';
import NotificationsContainer from '../myAccount/containers/Notifications';
import UpdateCoverageContainer from '../updatePlans/containers/UpdateCoverageContainer';
import EligibilityWidget from '../eligibilityWidget';
import { ChangeProviderContainer } from '../updatePlans';
import ManageSubscriptionContainer from '../myAccount/containers/ManageSubscription';
import CustomerSupportScreen from './screens/CustomerSupportScreen';
import Superbills from '@/superbills';

interface HomeModalRoutesProps {
  closeModal: CloseModal;
  handleBackButtonPress: () => void;
}

const HomeModalRoutes: React.VFC<HomeModalRoutesProps> = ({
  handleBackButtonPress,
  closeModal,
}) => (
  <>
    <Route path="/home/help" render={() => <HelpScreen closeModal={closeModal} />} />
    <Route exact path="/home/account" render={() => <AccountScreen closeModal={closeModal} />} />
    <Route
      exact
      path="/home/account/personal-information"
      render={() => (
        <PersonalInformationContainer
          onBackButtonPress={handleBackButtonPress}
          onCloseButtonPress={closeModal}
        />
      )}
    />
    <Route
      exact
      path="/home/account/account-details"
      render={() => (
        <AccountDetailsContainer
          onBackButtonPress={handleBackButtonPress}
          onCloseButtonPress={closeModal}
        />
      )}
    />
    <Route
      exact
      path="/home/account/notifications"
      render={() => (
        <NotificationsContainer
          onBackButtonPress={handleBackButtonPress}
          onCloseButtonPress={closeModal}
        />
      )}
    />
    <Route exact path="/home/account/update-coverage" component={UpdateCoverageContainer} />
    <Route
      exact
      path={['/home/account/add-new-service/room/:roomID', '/home/account/add-new-service']}
      render={() => <EligibilityWidget qmFlow={90} />}
    />
    <Route exact path="/home/account/change-provider" component={ChangeProviderContainer} />
    <Route
      exact
      path={['/home/account/manage-subscription']}
      render={() => (
        <ManageSubscriptionContainer
          inNavShell
          onBackButtonPress={handleBackButtonPress}
          onCloseButtonPress={closeModal}
        />
      )}
    />
    <Route exact path={['/home/account/customer-support']} component={CustomerSupportScreen} />
    <Route path="/superbills/room/:roomID" component={Superbills} />
  </>
);

export default HomeModalRoutes;
