import { useQuery } from 'react-query';
import { superbillsListQueryKey } from './queryKeys';
import apiHelper from '../utils/apiHelper';
import { GetSuperbillsQueryResponse } from '../types';

const useQueryGetSuperbills = (roomID: number, enabled: boolean = true) =>
  useQuery<GetSuperbillsQueryResponse, Error>({
    queryKey: superbillsListQueryKey(roomID),
    queryFn: apiHelper.getSuperbills(roomID),
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: 'always',
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!roomID && enabled,
  });

export default useQueryGetSuperbills;
