import { useCallback } from 'react';
import { ClientUsageStats, Subscription } from 'ts-frontend/types';

import { trackCTAClick } from '../../utils/analytics/events';
import BookSessionCard from '../components/BookSessionCard';
import { useOpenModal } from '@/utils/ModalsContextProvider';

interface Props {
  clientUsageStats: ClientUsageStats | undefined;
  subscriptions: Subscription[];
  isLoading: boolean;
}
const BookSessionCardController = ({ clientUsageStats, subscriptions, isLoading }: Props) => {
  const openModal = useOpenModal();

  const onPress = useCallback(() => {
    if (isLoading) return;
    trackCTAClick('Book a session', 'schedule', { component: 'BookSessionCard' });
    openModal('/in-room-scheduling/choose-provider');
  }, [isLoading, openModal]);

  return (
    <BookSessionCard
      clientUsageStats={clientUsageStats}
      subscriptions={subscriptions}
      onPress={onPress}
      isLoading={isLoading}
    />
  );
};

export default BookSessionCardController;
