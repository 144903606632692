import { FunctionComponent, useState, FormEvent, useRef } from 'react';
import {
  View,
  Tiny,
  BaseButton,
  Button,
  Standard,
  ExtraHuge,
  Big,
  TSInput,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { StripeCardElement } from '@stripe/stripe-js';
import { useStripeTs } from 'stripe/stripeContext';
import { CreditCardInput } from 'checkout';
import { useNewMemberNav } from 'launchDarkly/hooks';
import styled from '../../../core/styled/styled';

const StyledForm = styled.form({ width: 336 });

const HeaderWrapper = styled(View)({
  textAlign: 'left',
  marginBottom: 20,
  paddingLeft: 4,
});

const InputWrapper = styled(View)({
  marginBottom: 10,
});

const Label = styled(Tiny)({
  textAlign: 'left',
  paddingLeft: 4,
});

const CancelButton = styled(BaseButton)(({ theme: { colors } }) => {
  return { marginTop: 10, marginBottom: 50, padding: 0, backgroundColor: colors.white };
});

const SaveButton: FunctionComponent<{ color: string }> = ({ color }) => (
  <Button type="submit" style={{ width: '100%' }} primaryColor={color}>
    <Big variant="bigWide">Save</Big>
  </Button>
);

interface Props {
  isEditMode: boolean;
  email: string;
  updatePaymentDetails: (cardToken: string) => void;
  onCancelPress: () => void;
}

const UpdatePaymentDetailsModal: FunctionComponent<Props> = ({
  isEditMode,
  email,
  updatePaymentDetails,
  onCancelPress,
}) => {
  const [error, setError] = useState('');
  const stripe = useStripeTs();
  const useNewNav = useNewMemberNav();
  const { colors, colorRoles } = useEmotionTheme();
  const saveButtonColor = useNewNav ? colorRoles.button.brandPrimarySurfaceDefault : colors.green;

  const cardElementRef = useRef<StripeCardElement | null>(null);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (cardElementRef.current) {
      const result = await stripe.createToken(cardElementRef.current);
      if (result.error) {
        setError(
          result.error && result.error.message
            ? result.error.message
            : 'Something went wrong. Please Try again'
        );
      } else if (result.token) {
        updatePaymentDetails(result.token.id);
      }
    }
  };

  const action = isEditMode ? 'Update' : 'Add';

  return (
    <StyledForm onSubmit={handleSubmit}>
      <HeaderWrapper>
        <ExtraHuge>{action} payment details</ExtraHuge>
      </HeaderWrapper>
      <InputWrapper>
        <Label>Email</Label>
        <TSInput onChange={() => undefined} value={email} disabled />
      </InputWrapper>
      <CreditCardInput stripe={stripe} cardElementRef={cardElementRef} serverError={error} />
      <SaveButton color={saveButtonColor} />
      <CancelButton onPress={onCancelPress}>
        <Standard variant="standardDarkGrey">Cancel</Standard>
      </CancelButton>
    </StyledForm>
  );
};

export default UpdatePaymentDetailsModal;
