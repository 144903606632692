import { FunctionComponent, useState } from 'react';
import { Modal, useWindowWidthState } from '@talkspace/react-toolkit';
import { RouteComponentProps } from '../../core/routerLib/routerLib';
import InformedConsentActionView from './InformedConsentActionView';
import InformedConsentActionSplash from './InformedConsentActionSplash';

import ChatModal from '../../clientChat/components/ChatModal';
import useInformedConsentV2 from '../../hooks/useInformedConsentV2';
import { useCloseModal } from '../../utils/ModalsContextProvider';

const Wrapper = ({
  isOldMessageType,
  isLoadingProp,
  history,
  isMobile,
  isLoading,
  children,
  handleOnBackdropPress,
}) => {
  const commonWrapperStyles = {
    overflowX: 'hidden',
    overflowY: 'hidden',
  };

  const height = isLoadingProp ? 390 : 562;
  const desktopStyles = isMobile
    ? { height: '100vh' }
    : {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      };
  return isOldMessageType ? (
    <ChatModal
      isLoading={isLoading}
      height={height}
      onBackdropPress={handleOnBackdropPress}
      isVisible
      wrapperStyle={commonWrapperStyles}
      titleText="Informed consent"
    >
      {children}
    </ChatModal>
  ) : (
    <Modal.Panel
      isLoading={isLoading}
      height={height}
      onBackdropPress={handleOnBackdropPress}
      wrapperStyle={{
        ...commonWrapperStyles,
        ...desktopStyles,
      }}
      contentViewStyle={{ paddingLeft: 0, paddingRight: 0 }}
    >
      {children}
    </Modal.Panel>
  );
};

const InformedConsentAction: FunctionComponent<
  RouteComponentProps<
    {
      roomID: number;
    },
    {},
    { messageSentBy: number; messageID: number; roomID: number }
  >
> = ({ history, location, match }) => {
  const [showSplashPage, setShowSplashPage] = useState<boolean>(true);
  const [hasAgreedJustNow, setHasAgreedJustNow] = useState<boolean>(false);

  const roomIDFromMatch = match?.params?.roomID;
  const roomIDFromHistory = location?.state?.roomID;
  const roomID = roomIDFromMatch || roomIDFromHistory;

  const closeModal = useCloseModal();

  const {
    informedConsent,
    informedConsentV2,
    agreedDate,
    hasConsented,
    handleAgree,
    isLoading,
    error,
  } = useInformedConsentV2({
    roomID,
    messageSentBy: location.state?.messageSentBy,
    messageID: location.state?.messageID,
  });

  const handleOnAgreePress = (e) => {
    e.stopPropagation();
    handleAgree().then(() => {
      setHasAgreedJustNow(true);
      setShowSplashPage(true);
    });
  };

  const goToSplash = () => {
    setHasAgreedJustNow(false);
    setShowSplashPage(true);
  };

  const goToAgreement = () => {
    setShowSplashPage(false);
  };

  const handleOnBackdropPress = () => {
    closeModal();
  };

  const isOldMessageType = location.pathname.includes('/modal/informed-consent');
  const { isMobile } = useWindowWidthState();

  return (
    <Wrapper
      isOldMessageType={isOldMessageType}
      isLoadingProp={isLoading}
      history={history}
      isMobile={isMobile}
      isLoading={isLoading}
      handleOnBackdropPress={handleOnBackdropPress}
    >
      {showSplashPage ? (
        <InformedConsentActionSplash
          onBackdropPress={handleOnBackdropPress}
          onBeginPress={goToAgreement}
          viewAgreement={goToAgreement}
          hasAgreedJustNow={hasAgreedJustNow}
          agreedDate={agreedDate}
          hasConsented={hasConsented}
          isOldMessageType={isOldMessageType}
        />
      ) : (
        <InformedConsentActionView
          returnToSplash={goToSplash}
          hasV1InformedConsent={!!informedConsent}
          onAgreePress={handleOnAgreePress}
          error={error}
          therapistID={location.state?.messageSentBy}
          scrollHeight={371}
          agreedDate={agreedDate}
          hasConsented={hasConsented}
          hasV2InformedConsent={informedConsentV2?.hasV2}
        />
      )}
    </Wrapper>
  );
};

export default InformedConsentAction;
