import { FunctionComponent } from 'react';
import { View, Huge, EmotionStyle } from '@talkspace/react-toolkit';
import { BreadcrumbRoute } from '@talkspace/react-toolkit/src/designSystems/components/Breadcrumb';
import styled from '../../../core/styled';
import MyAccountMenu from '../MyAccountMenu';
import { ID_TAB_PANEL } from '../../../utils/IDConstants';
import AccountPageWrapper from '../AccountPageWrapper';

const Wrapper = styled(View)<{ alwaysRow?: boolean }>(({ theme: { window }, alwaysRow }) => {
  const { isDesktop } = window;
  return {
    flexDirection: isDesktop || alwaysRow ? 'row' : 'column',
    paddingTop: 29,
    alignItems: 'stretch',
  };
});

const Title = styled(Huge)(({ theme: { window } }) => {
  return {
    display: window.isSmall || window.isMedium ? 'none' : 'inline-block', // inline block for saffari issues
    marginBottom: 15,
  };
});

const MenuWrapper = styled(View)(({ theme: { window } }) => {
  const { isMobile, isDesktop } = window;
  return {
    position: isDesktop ? 'fixed' : 'relative',
    top: isDesktop ? 96 : 0,
    display: isMobile ? 'none' : 'flex',
    marginBottom: 29,
  };
});

const ContentWrapper = styled(View)<{ alwaysRow?: boolean; isHomeNav?: boolean }>(
  ({ theme: { window }, alwaysRow, isHomeNav }) => {
    const { isDesktop, isMobile } = window;

    const getMargin = () => {
      if (isHomeNav) return 0;
      if (isDesktop) return 244;
      return alwaysRow && !isMobile ? 30 : 0;
    };

    return {
      marginLeft: getMargin(),
    };
  }
);

interface Props {
  title: string;
  style?: EmotionStyle;
  alwaysRow?: boolean;
  hideMenu?: boolean;
  breadcrumbRoutes?: BreadcrumbRoute[];
  inNavShell?: boolean;
}

// ContentWrapper is acting as a tabpanel controlled by whatever tab option gets selected so we set role="tabpanel"
const MyAccountPanelWrapper: FunctionComponent<Props> = ({
  title,
  breadcrumbRoutes,
  children,
  alwaysRow,
  hideMenu,
  inNavShell,
  ...otherProps
}) => {
  if (inNavShell && breadcrumbRoutes) {
    return (
      <AccountPageWrapper
        title={title}
        breadcrumbRoutes={breadcrumbRoutes}
        id={ID_TAB_PANEL}
        role="tabpanel"
      >
        {children}
      </AccountPageWrapper>
    );
  }

  return (
    <Wrapper alwaysRow={alwaysRow} {...otherProps}>
      <MenuWrapper>
        <MyAccountMenu />
      </MenuWrapper>
      <ContentWrapper id={ID_TAB_PANEL} role="tabpanel" alwaysRow={alwaysRow} flex={1}>
        <Title as="h2">{title}</Title>
        {children}
      </ContentWrapper>
    </Wrapper>
  );
};

export default MyAccountPanelWrapper;
