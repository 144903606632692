import { FunctionComponent } from 'react';
import { View, Big, Large, Tiny } from '@talkspace/react-toolkit';
import styled from '../../../core/styled';

interface InviteDataRowProps {
  title: string;
  subtitle: string;
  count: number;
  countColor?: string;
}

const HeaderRow = styled(View)({
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const Subtitle = styled(Tiny)(({ theme: { colors } }) => {
  return { color: colors.slateGrey };
});

const Wrapper = styled(View)({
  marginTop: 33,
  marginBottom: 33,
});

const InviteDataRow: FunctionComponent<InviteDataRowProps> = ({
  title,
  subtitle,
  count,
  countColor,
}) => (
  <Wrapper>
    <HeaderRow>
      <Big as="h4">{title}</Big>
      <Large style={{ color: countColor }}>{count}</Large>
    </HeaderRow>
    <Subtitle>{subtitle}</Subtitle>
  </Wrapper>
);

export default InviteDataRow;
