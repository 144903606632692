import { useCallback } from 'react';
import { Modal, SimpleHeader } from '@talkspace/react-toolkit';
import { useAppReviewCheckStorage } from 'ts-ionic/plugins/appReview';
import { useHistory } from '@/core/routerLib/routerLib';

import ClinicalProgressRoomContainer from '../../clinicalProgress/containers/ClinicalProgressRoomContainer';
import { trackInAppReview } from '../../utils/analytics/events';

const JourneyScreen = () => {
  const history = useHistory();
  const appReviewCallback = useCallback(() => {
    trackInAppReview();
  }, []);
  useAppReviewCheckStorage(appReviewCallback);
  return (
    <Modal isVisible isMobileFullscreen titleText="Choose provider">
      <Modal.FullScreenPanel
        safeAreaVariant="setHeightAndAddTop"
        renderHeader={() => (
          <SimpleHeader
            title="Choose provider"
            onClose={() => {
              history.push('/home');
            }}
          />
        )}
      >
        <ClinicalProgressRoomContainer isV2 />
      </Modal.FullScreenPanel>
    </Modal>
  );
};

export default JourneyScreen;
