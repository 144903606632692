import { FunctionComponent } from 'react';
import { View, Tiny, Standard, TouchableView, useEmotionTheme } from '@talkspace/react-toolkit';
import { useWizardState } from 'stepWizard';
import { RouteComponentProps, withRouter } from '../../core/routerLib';
import DollarSignComponent from '../components/DollarSignComponent';
import styled from '../../core/styled';

const RowStyledView = styled(View)({
  textAlign: 'left',
  marginTop: 93,
  alignSelf: 'center',
  width: '100%',
});

const IconStyledView = styled(View)({
  width: 70,
  height: 70,
  boxShadow: '0px 3px 10px rgba(16, 109, 149, 0.3)',
  borderRadius: 20,
});

const RefundDollarTextBoxContainer: FunctionComponent<RouteComponentProps> = ({ history }) => {
  const { source, selectedRefundableCharge: charge } = useWizardState();
  const refundPolicyHandler = () => {
    history.push('/refund-purchase/refund-policy', {
      source,
    });
  };

  const isSubscription = charge.chargeType === 'subscription';
  const { colors } = useEmotionTheme();
  return (
    <RowStyledView row>
      <IconStyledView justify="center" align="center">
        <DollarSignComponent />
      </IconStyledView>
      <View style={{ width: 'calc(100% - 82px)', marginLeft: 12 }}>
        <Standard variant="standardBoldBrightGreen" style={{ marginBottom: 5 }}>
          How are refunds calculated?
        </Standard>
        {isSubscription ? (
          <Tiny style={{ display: 'contents' }}>
            Your refund amount is based on when in your billing cycle your subscription was
            cancelled. <> </>
            <TouchableView
              onPress={refundPolicyHandler}
              style={{
                color: colors.greenText,
                textDecoration: 'none',
                fontWeight: 500,
              }}
            >
              Read more
            </TouchableView>
          </Tiny>
        ) : (
          <Tiny style={{ display: 'contents' }}>
            Copays for sessions that were not completed are eligible for full refund within 24 hours
            of payment.
          </Tiny>
        )}
      </View>
    </RowStyledView>
  );
};

export default withRouter(RefundDollarTextBoxContainer);
