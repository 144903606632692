import { FunctionComponent } from 'react';
import { TouchableView, Spinner, Text, CloseButton } from '@talkspace/react-toolkit';
import moment from 'moment';

import { formatCurrency } from 'ts-frontend/helpers/billingUtils';
import { CouponValueType, CouponDurationType, Coupon, Billing } from '../../utils/apiHelper';
import styled from '../../../core/styled';
import { COLORS } from '../../../utils/design';
import Success from '../CouponSuccess';
import Error from '../CouponError';

type CouponTextByTypeAndDuration = Record<CouponValueType, Record<CouponDurationType, string>>;

function getCouponDetailsText(coupon: Coupon, numberOfPayments: number) {
  const couponTextByTypeAndDuration: CouponTextByTypeAndDuration = {
    fixed: {
      once: `${formatCurrency(coupon.value, coupon.currency)} off your next payment`,
      recurring: `${formatCurrency(
        coupon.value,
        coupon.currency
      )} off your next ${numberOfPayments} payments`,
      forever: `${formatCurrency(coupon.value, coupon.currency)} off each payment`,
    },
    percent: {
      once: `${coupon.value}% off your next payment`,
      recurring: `${coupon.value}% off your next ${numberOfPayments} payments`,
      forever: `${coupon.value}% off your plan`,
    },
  };

  const couponDetailsText = couponTextByTypeAndDuration[coupon.valueType][coupon.duration];

  return couponDetailsText;
}

function formatDate(date: string) {
  return moment(date).format('MMMM D, YYYY');
}

const BoldText = styled(Text)({
  display: 'inline',
  fontWeight: 'bold',
});

type BillingTextByDuration = Record<CouponDurationType, JSX.Element>;

function getBillingDetailsText(couponDuration: CouponDurationType, billingDates: string[]) {
  const firstDate = billingDates.length > 0 && formatDate(billingDates[0]);
  const secondDate = billingDates.length > 1 && formatDate(billingDates[1]);

  const billingTextByDuration: BillingTextByDuration = {
    once: (
      <>
        Your coupon will be applied to your next payment on <BoldText>{firstDate}</BoldText>
      </>
    ),
    recurring: (
      <>
        Your coupon will be applied to your payments on <BoldText>{firstDate}</BoldText> and{' '}
        <BoldText>{secondDate}</BoldText>.
      </>
    ),
    forever: (
      <>
        Your coupon will be applied to your payments starting on <BoldText>{firstDate}</BoldText>.
      </>
    ),
  };

  const billingDetailsText = billingTextByDuration[couponDuration];

  return billingDetailsText;
}

const ContentWrapper = styled(TouchableView)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      backgroundColor: COLORS.white,
      width: 500,
      minHeight: 365,
      borderRadius: 10,
      ...(isMobile
        ? {
            height: '100%',
            width: '100%',
            borderRadius: 0,
          }
        : undefined),
    };
  }
);

const CloseButtonWrapper = styled(TouchableView)({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  paddingTop: 16,
  paddingRight: 16,
});

const Content = styled(TouchableView)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      paddingTop: 20,
      paddingLeft: isMobile ? 20 : 70,
      paddingRight: isMobile ? 20 : 70,
    };
  }
);

interface Props {
  couponDetails: Coupon;
  billingDetails: Billing;
  onContactCustomerSupportPress: () => void;
  onClosePress: () => void;
  isLoading: boolean;
  isError: boolean;
}

const CouponModal: FunctionComponent<Props> = (props) => {
  const {
    couponDetails,
    billingDetails,
    onContactCustomerSupportPress,
    onClosePress,
    isLoading,
    isError,
  } = props;

  function renderModalContent() {
    if (isLoading) return <Spinner isLoading />;

    if (isError)
      return (
        <Error
          onContactCustomerSupportPress={onContactCustomerSupportPress}
          onClosePress={onClosePress}
        />
      );

    const couponDetailsText = getCouponDetailsText(
      couponDetails,
      billingDetails.recurringDates.length
    );

    const billingDetailsText = getBillingDetailsText(
      couponDetails.duration,
      billingDetails.recurringDates
    );

    return (
      <Success
        couponDetailsText={couponDetailsText}
        billingDetailsText={billingDetailsText}
        onClosePress={onClosePress}
      />
    );
  }

  return (
    <ContentWrapper>
      {!isLoading && (
        <CloseButtonWrapper>
          <CloseButton onPress={onClosePress} />
        </CloseButtonWrapper>
      )}
      <Content>{renderModalContent()}</Content>
    </ContentWrapper>
  );
};

export default CouponModal;
