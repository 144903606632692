import { FunctionComponent, useEffect } from 'react';
import { View } from '@talkspace/react-toolkit';
import { persistData, useWizardState, useWizardActions } from 'stepWizard';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { RouteComponentProps, withRouter } from '../../core/routerLib/routerLib';
import useRefundWizard from '../hooks/useRefundWizard';
import RefundPolicyComponent from '../components/RefundPolicyComponent';
import styled from '../../core/styled';

const Wrapper = styled(View)({
  alignSelf: 'center',
  textAlign: 'left',
  alignItems: 'center',
  width: 340,
});

const RefundPolicyContainer: FunctionComponent<RouteComponentProps<{}>> = ({ history }) => {
  const { isVerifyExitModalOpen, responses, persisted } = useWizardState();
  const closeModal = useCloseModal();
  const [, { exitAction }] = useRefundWizard();
  const { setState } = useWizardActions();

  async function handleExitModal() {
    exitAction();
    persistData(responses, setState, 'Refund policy', '', { refundApplied: false }, false);
  }

  useEffect(() => {
    if (persisted)
      closeModal({
        navigateTo: 'dashboard',
      });
  }, [persisted, closeModal]);

  useEffect(() => {
    if (isVerifyExitModalOpen) {
      handleExitModal();
    }
  }, [isVerifyExitModalOpen]); // eslint-disable-line

  return (
    <Wrapper>
      <RefundPolicyComponent
        buttonHandler={() => history.goBack()}
        buttonText="<  Back to my refund"
      />
    </Wrapper>
  );
};

export default withRouter(RefundPolicyContainer);
