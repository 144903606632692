import moment from 'moment';
import storage from '../../core/storage';
import localStorage from '../../core/storage/localStorage';
import sessionStorage from '../../core/storage/sessionStorage';
import { setTrackerUserID } from '../../utils/analytics/eventTracker';

export const DEVICE_TOKEN_KEY = 'device_token';

interface Tokens {
  accessToken: string;
  refreshToken: string;
  accessTTLSeconds: number;
  refreshTTLSeconds: number;
}

interface StoredTokens {
  lastRefreshed: number;
  accessToken: string | null;
  refreshToken: string | null;
  /** Unix Timestamp for the expiration of the access token */
  accessTTL: number;
  /** Unix Timestamp for the expiration of the refresh token */
  refreshTTL: number;
}

interface UserData<T = number> {
  id: T;
}

export const getUserData = (): UserData => JSON.parse(storage.getItem('userData') || '{}');

export const getTokens = (): StoredTokens => {
  const lastRefreshed = Number(storage.getItem('lastRefreshed')) || 0;
  const accessToken = storage.getItem('accessToken');
  const refreshToken = storage.getItem('refreshToken');
  const accessTTL = Number(storage.getItem('accessTTL')) || 0;
  const refreshTTL = Number(storage.getItem('refreshTTL')) || 0;
  return {
    lastRefreshed,
    accessToken,
    refreshToken,
    accessTTL,
    refreshTTL,
  };
};

export const getAccessToken = () => storage.getItem('accessToken');

export const getRefreshToken = () => storage.getItem('refreshToken');

export const setTokenAndUserID = (token: string, userID: string, preventIdentify?: boolean) => {
  storage.setItem('accessToken', token);
  storage.setItem('userData', JSON.stringify({ id: userID }));
  if (!preventIdentify) setTrackerUserID(userID);
};

export const setTokens = (tokens: Tokens, userID?: number) => {
  const { accessToken, refreshToken, accessTTLSeconds, refreshTTLSeconds } = tokens;
  const accessExpiryUnix = moment().add(accessTTLSeconds, 'seconds').valueOf();
  const refreshExpiryUnix = moment().add(refreshTTLSeconds, 'seconds').valueOf();
  const lastRefreshed = Date.now();

  storage.setItem('lastRefreshed', String(lastRefreshed));
  storage.setItem('accessToken', accessToken);
  storage.setItem('refreshToken', refreshToken);
  storage.setItem('accessTTL', String(accessExpiryUnix));
  storage.setItem('refreshTTL', String(refreshExpiryUnix));

  if (userID) {
    storage.setItem('userData', JSON.stringify({ id: userID }));
    setTrackerUserID(userID);
  } else if (getUserData().id) setTrackerUserID(getUserData().id);
};

export const removeTokens = () => {
  storage.removeItem('lastRefreshed');
  storage.removeItem('accessToken');
  storage.removeItem('refreshToken');
  storage.removeItem('accessTTL');
  storage.removeItem('refreshTTL');
  storage.removeItem('userData');
  sessionStorage.removeItem('actionAuth');
  localStorage.removeItem('dismissedWaiDate'); // TODO: Make it constant, it's used on chat-poc
};

export const getBypassTwoFactorToken = () => localStorage.getItem('bypass_two_factor_token');

export const storeBypassTwoFactorToken = (bypassTwoFactorToken) =>
  localStorage.setItem('bypass_two_factor_token', bypassTwoFactorToken);

export const removeBypassTwoFactorToken = () => localStorage.removeItem('bypass_two_factor_token');

export const storeEmailVerificationLastDisplay = () => {
  const emailVerificationPrompt = Date.now();
  localStorage.setItem('email_verification_displayed_at', String(emailVerificationPrompt));
};

export const getEmailVerificationLastDisplay = () =>
  localStorage.getItem('email_verification_displayed_at') || 0;

export const saveDeviceToken = (token = '') => {
  storage.setItem(DEVICE_TOKEN_KEY, Buffer.from(token).toString('base64'));
};

export const getDeviceToken = () => {
  const token64 = storage.getItem(DEVICE_TOKEN_KEY);
  if (!token64) return null;
  return Buffer.from(token64, 'base64').toString();
};
