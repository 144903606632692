import finishVerifyEmail from '@talkspace/auth/finishVerifyEmail';
import { useReducer, useCallback } from 'react';
import resendConfirmation from '@talkspace/auth/resendConfirmation';
import API from '../../myAccount/utils/myAccountApiHelper';
import APIEmail, { ChangeEmailPayload } from '../utils/emailVerificationApiHelper';
import { emailVerificationReducer, State, initialState } from '../reducers/emailVerification';
import adminConfig from '../../utils/adminConfig';
import parseJwt from '../../utils/jwt';

export default function useEmailVerification(): [
  State,
  {
    sendEmailVerificationAction: (email: string) => void;
    checkEmailVerificationTokenAction: (token: string, tlt: string | null) => void;
    sendCognitoUserConfirmation: (email: string) => void;
    changeEmailAction: (payload: ChangeEmailPayload) => void;
    resetErrorAction: () => void;
  }
] {
  const [state, dispatch] = useReducer(emailVerificationReducer, initialState);

  function sendEmailVerification(email: string) {
    dispatch({ type: 'requestSendEmailVerification' });

    API.sendEmailVerification({ email })
      .then(() => {
        dispatch({
          type: 'receiveSendEmailVerification',
          payload: {
            emailSent: true,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: 'setError',
        });
      });
  }

  function sendCognitoUserConfirmation(email: string) {
    dispatch({ type: 'requestSendCognitoUserConfirmation' });

    resendConfirmation(email)
      .then(() => {
        dispatch({ type: 'receiveSendCognitoUserConfirmation' });
      })
      .catch(() => {
        dispatch({
          type: 'setError',
        });
      });
  }

  async function checkEmailVerificationToken(token: string, tlt: string | null) {
    dispatch({ type: 'requestCheckEmailVerificationToken' });
    let decodedJWT;
    try {
      let emailVerifiedRedirect;
      let skipDeeplink;
      const isCognitoLoginEnabled = Boolean(
        (await adminConfig.getPublicAdminOptionByName('cognito_user_migration')).data?.data
      );
      const isCognitoUserConfirmation = !!tlt;

      if (isCognitoUserConfirmation && isCognitoLoginEnabled) {
        const { result } = await finishVerifyEmail({ code: token });

        if (result !== 'success') {
          dispatch({
            type: 'setError',
          });

          return;
        }
      } else {
        decodedJWT = parseJwt(token);
        const response = await APIEmail.checkEmailVerificationToken(decodedJWT.userID, token);

        emailVerifiedRedirect = response.data.data.redirectTo;
        ({ skipDeeplink } = response.data.data);
      }
      if (!emailVerifiedRedirect) {
        const origin = `${window.location.protocol}//${window.location.host}`;

        emailVerifiedRedirect = isCognitoUserConfirmation
          ? `${origin}/login#tlt=${encodeURIComponent(tlt)}&firstEmailVerification=true`
          : `${origin}/login`;
      }
      dispatch({
        type: 'receiveCheckEmailVerificationToken',
        payload: { emailVerifiedRedirect, skipDeeplink, userID: decodedJWT.userID },
      });
    } catch (e) {
      const error = e as Error;

      if (error.message === '401') {
        dispatch({
          type: 'setExpiredTokenEmail',
          payload: { expiredTokenEmail: decodedJWT.resource[0].payload.email },
        });
      }
      dispatch({
        type: 'setError',
      });
    }
  }

  const resetError = () => dispatch({ type: 'resetError' });

  const changeEmail = async (payload: ChangeEmailPayload) => {
    try {
      dispatch({ type: 'requestChangeEmail' });

      const response = await APIEmail.changeEmail(payload);
      const { token } = response?.data?.data || {};

      dispatch({
        type: 'receiveChangeEmail',
        payload: {
          changeEmailToken: token || '',
        },
      });
    } catch (e) {
      dispatch({
        type: 'setError',
        error: e.message,
      });
    }
  };

  return [
    state,
    {
      sendEmailVerificationAction: useCallback(sendEmailVerification, []),
      checkEmailVerificationTokenAction: useCallback(checkEmailVerificationToken, []),
      sendCognitoUserConfirmation: useCallback(sendCognitoUserConfirmation, []),
      changeEmailAction: useCallback(changeEmail, []),
      resetErrorAction: useCallback(resetError, []),
    },
  ];
}
