import changePasswordCognito from '@talkspace/auth/changePassword';
import { useReducer, useCallback } from 'react';
import moment from 'moment';
import { parseCountryCallingCode } from '@talkspace/react-toolkit';
import signIn from '@talkspace/auth/signIn';
import paymentAPI from 'offer/utils/paymentApiHelper';
import { trackClient2FAVerification } from '@/TwoFactorAuthentication/utils/analytics';
import { getUserData, storeEmailVerificationLastDisplay } from '../../auth/helpers/token';
import { useMainState } from '../../hooks/mainContext';
import API from '../utils/myAccountApiHelper';
import { accountDetailsReducer, State, initialState, ModalType } from '../reducers/accountDetails';
import { setActionAuth } from '../utils/tokenHelper';
import AdminConfigAPI from '../../utils/adminConfig';
import { useClientAuthState } from '../../hooks/clientAuthContext';
import useAccountModalDeepRoute from './useAccountModalDeepRoute';

export default function useAccountDetails(): [
  State,
  {
    dispatchOpenModal: (modalType: ModalType) => void;
    dispatchCloseModal: () => void;
    dispatchSetSuccessModalText: (string) => void;
    dispatchClearError: () => void;
    updateNickname: (nickname: string) => void;
    verifyPassword: (password: string, nextModal: ModalType) => void;
    dispatchReceiveActionAuth: (token: string, expiration: Date) => void;
    updateEmail: (email: string) => void;
    updatePassword: (password: string, confirmedPassword: string) => void;
    getPaymentDetails: () => void;
    updatePaymentDetails: (cardToken: string, isDelayed?: boolean) => void;
    dispatchPaymentMethodError: (error: any) => void;
    onLoadStripeLink: () => void;
    sendEmailVerification: (email: string) => void;
    clearVerificationCodeError: () => void;
    clearPhoneNumber: () => void;
    getClientPhoneNumber: () => void;
    clearOtpToken: () => void;
    setOTPToken: (otpToken: string) => void;
    verifyCode: (code: number) => void;
    setPendingPhoneNumber: (pendingPhoneNumber: string, pendingCountryCode: string) => void;
    setPhoneNumber: (phoneNumber: string, countryCode: string) => void;
    setBackModal: (backModal: ModalType) => void;
    clear2faTokenExpiredError: () => void;
    set2faTokenExpiredError: () => void;
    setError: () => void;
  }
] {
  const { me } = useMainState();
  const {
    adminConfigs: { isCognitoLoginEnabled: isCognitoLoginEnabledFeatureFlag },
  } = useClientAuthState();

  const isCognitoLoginEnabled = isCognitoLoginEnabledFeatureFlag && !!me?.cognitoUsername;

  const [state, dispatch] = useReducer(accountDetailsReducer, initialState);

  const { shouldModalDeepRoute, routeToDeepModal } = useAccountModalDeepRoute();

  const dispatchOpenModal = (modalType: ModalType) => {
    dispatch({
      type: 'openModal',
      payload: {
        openModalType: modalType,
      },
    });
  };

  const dispatchCloseModal = () => {
    if (shouldModalDeepRoute) {
      routeToDeepModal('/account-details');
    } else {
      dispatch({
        type: 'closeModal',
      });
    }
  };

  const dispatchSetSuccessModalText = (text: string) => {
    dispatch({
      type: 'setSuccessModalText',
      payload: {
        successModalText: text,
      },
    });
  };

  const dispatchClearError = () => {
    dispatch({
      type: 'clearError',
    });
  };

  const updateNickname = (nickname: string) => {
    dispatch({ type: 'requestUpdateNickname' });
    const { id } = getUserData();
    API.patchUserNickname(id, nickname)
      .then(() => {
        dispatch({
          type: 'receiveUpdateNickname',
          payload: {
            nickname,
          },
        });
        dispatchSetSuccessModalText('Your nickname was changed.');
      })
      .catch(() => {
        dispatch({
          type: 'setError',
        });
      });
  };

  const dispatchReceiveActionAuth = (token: string, expiration: Date) => {
    dispatch({
      type: 'receiveVerifyPassword',
      payload: {
        actionAuth: { token, expiration },
      },
    });
  };

  async function verifyPassword(password: string, nextModal: ModalType) {
    dispatch({ type: 'requestVerifyPassword' });

    try {
      let cognitoResult;

      if (isCognitoLoginEnabled) {
        cognitoResult = await signIn({
          email: me?.email as string,
          password,
        });

        if (cognitoResult.result !== 'success') {
          dispatch({
            type: 'setError',
            error: 'Incorrect password',
          });

          return;
        }
      }

      if (isCognitoLoginEnabled && nextModal === 'changePassword') {
        dispatch({
          type: 'setCurrentPassword',
          payload: {
            currentPassword: password,
          },
        });
      } else {
        const response = isCognitoLoginEnabled
          ? await API.createJWTAuthTokenCognito({
              action: 'patchUserBasicDetails',
              idToken: cognitoResult.idToken,
            })
          : await API.createJWTAuthToken({
              action: 'patchUserBasicDetails',
              password,
            });

        if (!response.data.data) {
          dispatch({
            type: 'setError',
            error: '',
          });

          return;
        }

        const { token, ttlMinutes } = response.data.data;
        const expiration = moment().add(ttlMinutes, 'm').toDate();
        setActionAuth(token, expiration);
        dispatchReceiveActionAuth(token, expiration);
      }

      dispatchOpenModal(nextModal);
    } catch (e) {
      const error = e as Error;

      dispatch({
        type: 'setError',
        error: error.message === '403' ? 'Incorrect password' : '',
      });
    }
  }

  async function updateEmail(newEmail: string) {
    dispatch({ type: 'requestUpdateEmail' });

    const { id } = getUserData();

    try {
      storeEmailVerificationLastDisplay();
      const response = await API.patchUserEmail(id, newEmail);
      const { email, pendingEmail, emailVerificationStatus } = response.data.data;

      const emailVerificationSetting = await AdminConfigAPI.getAdminOptionByName(
        'email_verification'
      );

      if (emailVerificationSetting?.data?.data) {
        dispatch({
          type: 'requestSendEmailVerification',
        });

        await API.sendEmailVerification({ email: newEmail });

        dispatch({
          type: 'receiveSendEmailVerification',
        });

        dispatchOpenModal('emailVerification');

        dispatch({
          type: 'receiveUpdateEmail',
          payload: {
            email,
            pendingEmail,
            emailVerificationStatus,
          },
        });
      } else {
        dispatchSetSuccessModalText('Your email address was changed.');
      }
    } catch {
      dispatch({
        type: 'setError',
      });
    } finally {
      dispatch({
        type: 'setCurrentPassword',
        payload: {
          currentPassword: null,
        },
      });
    }
  }

  const updatePassword = async (password: string, confirmedPassword: string) => {
    dispatch({ type: 'requestUpdatePassword' });

    const { id } = getUserData();

    try {
      if (isCognitoLoginEnabled) {
        await changePasswordCognito({
          email: me?.email as string,
          newPassword: password,
          oldPassword: state.currentPassword as string,
        });
      } else {
        await API.patchUserPassword(id, password, confirmedPassword);
      }

      dispatch({
        type: 'receiveUpdatePassword',
      });
      dispatchSetSuccessModalText('Your password was changed.');
    } catch {
      dispatch({
        type: 'setError',
      });
    } finally {
      dispatch({
        type: 'setCurrentPassword',
        payload: {
          currentPassword: null,
        },
      });
    }
  };

  function getPaymentDetails(): void {
    dispatch({ type: 'requestGetPaymentDetails' });

    const { id } = getUserData();

    paymentAPI
      .getPaymentDetails(id)
      .then((response) => {
        if (response) {
          dispatch({
            type: 'receiveGetPaymentDetails',
            payload: {
              nickname: response.nickname,
              email: response.email,
              paymentDetails: response.billingInfo,
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: 'setError',
        });
      });
  }

  function updatePaymentDetails(cardToken: string, isDelayed = false): void {
    dispatch({ type: 'requestUpdatePaymentDetails' });
    const provider = (state && state.paymentDetails && state.paymentDetails.provider) || 'Stripe';

    const { id } = getUserData();

    paymentAPI
      .changePaymentMethod(id, cardToken, provider)
      .then((response) => {
        dispatch({
          type: 'receiveUpdatePaymentDetails',
          payload: {
            paymentDetails: {
              cardType: response.card_type,
              fourLastDigits: response.last_four,
              expirationMonth: response.month,
              expirationYear: response.year,
              provider: response.provider,
              isLink: response.is_link,
            },
          },
        });

        let successMessage = 'Your payment details were updated.';
        if (isDelayed) {
          successMessage += '\nYour account will be updated shortly.';
        }

        dispatchSetSuccessModalText(successMessage);
      })
      .catch((error) =>
        dispatch({
          type: 'setError',
          error: error && error.message !== '500' ? error.message : '',
        })
      );
  }

  const onLoadStripeLink = async () => {
    dispatch({
      type: 'requestGetSetupIntent',
    });

    const { id } = getUserData();
    const res = await paymentAPI.getSetupIntent({ userID: id });

    dispatch({
      type: 'receiveGetSetupIntent',
    });

    return res;
  };

  const dispatchPaymentMethodError = (error: any) => {
    dispatch({
      type: 'setError',
      error: error && error.message !== '500' ? error.message : '',
    });
  };

  const sendEmailVerification = async (email: string) => {
    dispatch({ type: 'requestSendEmailVerification' });

    try {
      await API.sendEmailVerification({ email });
      dispatch({ type: 'receiveSendEmailVerification' });
      dispatchOpenModal('emailVerification');
    } catch {
      dispatch({
        type: 'setError',
      });
    }
  };

  const setError = () => {
    dispatch({
      type: 'setError',
    });
  };

  const verifyCode = async (code: number) => {
    dispatch({ type: 'requestUpdate2FA' });
    trackClient2FAVerification('Continue code verification - Login and Security');
    try {
      if (state.otpToken) {
        await API.update2FA(state.otpToken, code);
        trackClient2FAVerification('2FA verification enable success - Login and Security');
        dispatch({ type: 'receiveUpdate2FA', payload: { disable2FA: false } });
        dispatch({
          type: 'setSuccessModalText',
          payload: {
            successModalTitle:
              state.backModal === 'changePhoneNumber2FA'
                ? `Two-factor authentication has been enabled`
                : `Phone number\nsuccessfully updated`,
            successModalText:
              state.backModal === 'changePhoneNumber2FA'
                ? ''
                : `For your added security, two-factor authentication has been enabled with your updated phone number.`,
          },
        });
        if (state.pendingCountryCode && state.pendingPhoneNumber) {
          dispatch({
            type: 'receiveUpdatePhoneNumber',
            payload: {
              phoneNumber: state.pendingPhoneNumber,
              phoneNumberCountryCode: state.pendingCountryCode,
            },
          });
        }
      }
    } catch (error) {
      trackClient2FAVerification('2FA verification enable failed - Login and Security');
      const { status } = error;
      if ([400, 401].includes(status)) {
        dispatch({
          type: 'setVerificationCodeError',
          payload: { verificationCodeError: true },
        });
      } else if (status === 404) {
        dispatch({
          type: 'set2faTokenExpiredError',
          payload: { twoFATokenExpiredError: 'The verification token has expired' },
        });
        setTimeout(() => {
          if (state.backModal) {
            dispatchOpenModal(state.backModal);
          }
        }, 2000);
      } else {
        dispatch({
          type: 'setError',
        });
      }
    }
  };

  const clearVerificationCodeError = () =>
    dispatch({ type: 'setVerificationCodeError', payload: { verificationCodeError: false } });

  const clear2faTokenExpiredError = () =>
    dispatch({
      type: 'set2faTokenExpiredError',
      payload: { twoFATokenExpiredError: '' },
    });

  const clearOtpToken = () => {
    dispatch({ type: 'clearOTPToken', payload: { otpToken: '' } });
  };

  const setOTPToken = (otpToken: string) => {
    dispatch({
      type: 'setOTPToken',
      payload: { otpToken },
    });
  };

  const set2faTokenExpiredError = () => {
    dispatch({
      type: 'set2faTokenExpiredError',
      payload: { twoFATokenExpiredError: 'The verification code has expired.' },
    });
  };

  const setPendingPhoneNumber = (pendingPhoneNumber: string, pendingCountryCode: string) => {
    dispatch({
      type: 'setPendingPhoneNumber',
      payload: { pendingPhoneNumber, pendingCountryCode },
    });
  };

  const setBackModal = (backModal: ModalType) => {
    dispatch({
      type: 'setBackModal',
      payload: { backModal },
    });
  };

  const setPhoneNumber = (phoneNumber: string, countryCode: string) => {
    dispatch({
      type: 'receiveUpdatePhoneNumber',
      payload: {
        phoneNumber,
        phoneNumberCountryCode: countryCode,
      },
    });
  };

  const clearPhoneNumber = async () =>
    dispatch({
      type: 'receiveUpdatePhoneNumber',
      payload: {
        phoneNumber: '',
        phoneNumberCountryCode: '',
      },
    });

  const getClientPhoneNumber = async () => {
    dispatch({ type: 'requestGetClientPhoneNumber' });
    try {
      dispatch({
        type: 'receiveGetClientPhoneNumber',
        payload: {
          phoneNumber:
            me?.phoneNumber && me.phoneNumberCountryCode
              ? `${parseCountryCallingCode(me?.phoneNumberCountryCode)}${me.phoneNumber}`
              : '',
          phoneNumberCountryCode: me?.phoneNumberCountryCode || '',
          disable2FA: !me?.is2FAToggleOn,
        },
      });
    } catch {
      dispatch({ type: 'setError' });
    }
  };

  return [
    state,
    {
      dispatchOpenModal: useCallback(dispatchOpenModal, []),
      dispatchCloseModal: useCallback(dispatchCloseModal, [shouldModalDeepRoute, routeToDeepModal]),
      dispatchSetSuccessModalText: useCallback(dispatchSetSuccessModalText, []),
      dispatchClearError: useCallback(dispatchClearError, []),
      updateNickname: useCallback(updateNickname, []),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      verifyPassword: useCallback(verifyPassword, [isCognitoLoginEnabled]),
      dispatchReceiveActionAuth: useCallback(dispatchReceiveActionAuth, []),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      updateEmail: useCallback(updateEmail, [state.currentPassword, isCognitoLoginEnabled]),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      updatePassword: useCallback(updatePassword, [state.currentPassword, isCognitoLoginEnabled]),
      getPaymentDetails: useCallback(getPaymentDetails, []),
      updatePaymentDetails: useCallback(updatePaymentDetails, [state]),
      dispatchPaymentMethodError: useCallback(dispatchPaymentMethodError, []),
      onLoadStripeLink: useCallback(onLoadStripeLink, []),
      sendEmailVerification: useCallback(sendEmailVerification, []),
      getClientPhoneNumber: useCallback(getClientPhoneNumber, [
        me?.phoneNumber,
        me?.is2FAToggleOn,
        me?.phoneNumberCountryCode,
      ]),
      clearVerificationCodeError: useCallback(clearVerificationCodeError, []),
      clearPhoneNumber: useCallback(clearPhoneNumber, []),
      setOTPToken: useCallback(setOTPToken, []),
      clearOtpToken: useCallback(clearOtpToken, []),
      verifyCode: useCallback(verifyCode, [
        state.otpToken,
        state.pendingPhoneNumber,
        state.pendingCountryCode,
        state.backModal,
      ]),
      setPendingPhoneNumber: useCallback(setPendingPhoneNumber, []),
      setPhoneNumber: useCallback(setPhoneNumber, []),
      setBackModal: useCallback(setBackModal, []),
      set2faTokenExpiredError: useCallback(set2faTokenExpiredError, []),
      clear2faTokenExpiredError: useCallback(clear2faTokenExpiredError, []),
      setError: useCallback(setError, []),
    },
  ];
}
