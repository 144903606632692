import { TouchableView, webOnlyStyle, RadioButton } from '@talkspace/react-toolkit';
import styled from '../../core/styled';

const RadioOptionWrapper = styled(TouchableView)<{
  index: number;
}>(({ index, theme: { colors } }) => {
  return {
    width: 335,
    minHeight: 58,
    ...(index !== 0
      ? webOnlyStyle({
          borderTop: `1px solid ${colors.baliHaiGrey}`,
        })
      : {}),
  };
});

const RadioContent = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
});

const RadioText = styled.div({
  flex: 1,
  display: 'flex',
  margin: 16,
  flexDirection: 'column',
});
const RadioTitle = styled.div({
  fontSize: 18,
  fontWeight: 'bold',
  alignSelf: 'flex-start',
});
const RadioDescription = styled.div({
  marginTop: 5,
  fontSize: 14,
});
const RadioInput = styled.div({
  alignSelf: 'center',
  marginRight: 16,
});

const RadioButtonChildren = styled.div({
  marginTop: '-5px',
  marginLeft: '16px',
  marginRight: '16px',
  marginBottom: '16px',
  overflow: 'hidden',
  transition: 'all 0.25s ease',
});

interface RadioAccordingOptionProps<OptionValue> {
  title: string;
  index?: number;
  description?: string;
  disabled?: boolean;
  isSelected?: boolean;
  value: OptionValue;
  onRadioChange?: () => void;
  onRadioChangeInternal?: (value: OptionValue) => void;
  children?: any;
  dataQa?: string;
  questionMarkWhenDisabled?: boolean;
  noContent?: boolean;
  hidden?: boolean;
  contentHeight: number;
  color: string;
}

const RadioAccordionOption = <OptionValue extends unknown = string>({
  title,
  index,
  description,
  isSelected,
  value,
  disabled = false,
  questionMarkWhenDisabled = false,
  dataQa,
  children,
  onRadioChange,
  onRadioChangeInternal,
  noContent,
  hidden,
  contentHeight,
  color,
}: RadioAccordingOptionProps<OptionValue>) => {
  const handleRadioClick = () => {
    if (!isSelected) {
      if (onRadioChangeInternal) {
        onRadioChangeInternal(value);
      }
      if (onRadioChange) {
        onRadioChange();
      }
    }
  };
  return (
    <RadioOptionWrapper index={index || 0}>
      <RadioContent onClick={() => !disabled && handleRadioClick()}>
        <RadioText>
          <RadioTitle>{`${title}`}</RadioTitle>
          <RadioDescription>{description}</RadioDescription>
        </RadioText>
        <RadioInput>
          <RadioButton
            isActive={!!isSelected}
            onPress={() => !disabled && handleRadioClick()}
            disabled={disabled}
            dataQa={dataQa}
            questionMarkWhenDisabled={questionMarkWhenDisabled}
            innerCircleStyle={{ backgroundColor: color, height: 14, width: 14 }}
            circleStyle={{ borderColor: color, height: 22, width: 22 }}
          />
        </RadioInput>
      </RadioContent>
      {!noContent && (
        <RadioButtonChildren style={{ height: isSelected ? contentHeight : 0 }}>
          {children}
        </RadioButtonChildren>
      )}
    </RadioOptionWrapper>
  );
};

export default RadioAccordionOption;
