import { ResponsiveLayoutWithHeader, SimpleHeader } from '@talkspace/react-toolkit';
import StarredMessagesScreen from 'chat/screens/StarredMessagesScreen';
import { useHistory, useParams } from '@/core/routerLib';

const StarredMessagesContainer = () => {
  const history = useHistory();
  const { roomID } = useParams<{ roomID?: string }>();
  const onClose = () => {
    history.goBack();
  };
  return (
    <ResponsiveLayoutWithHeader
      renderHeader={() => <SimpleHeader title="Starred messages" onClose={onClose} />}
    >
      <StarredMessagesScreen isTherapistChat={false} roomID={Number(roomID)} />
    </ResponsiveLayoutWithHeader>
  );
};

export default StarredMessagesContainer;
