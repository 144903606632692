import { useCallback } from 'react';
import { History } from 'history';
import { useQueryClient } from 'react-query';
import { subscriptionsQueryKey } from 'ts-frontend/hooks/queryKeys';
import {
  WizardScreen,
  useWizardActions,
  useWizardState,
  STEP_NUMBER_WELCOME_SCREEN,
} from 'stepWizard';
import { Screen } from '@talkspace/react-toolkit';
import { usePostLvsSwitchPrompt } from 'launchDarkly/hooks';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useIsModal } from '@/utils/ModalsContextProvider';
import { Route, Switch } from '../core/routerLib';
import MatchResultsContainer from './containers/MatchResultsContainer';
import TherapistConfirmationContainer from './containers/TherapistConfirmationContainer';
import SharingPreferencesContainer from './containers/SharingPreferencesContainer';
import SwitchReasonContainer from './containers/SwitchReasonContainer';
import SwitchReviewContainer from './containers/SwitchReviewContainer';
import SwitchSuccessContainer from './containers/SwitchSuccessContainer';
import SwitchConfirmBookingContainer from './containers/SwitchConfirmBookingContainer';
import useWindowWidth from '../clientChat/hooks/useWindowWidth';
import switchWizardScheme from './switchWizardScheme';
import switchWizardSchemeV2 from './switchWizardSchemeV2';
import { onboardingQueryKey, onboardingV3QueryKey, getAllRoomsQueryKey } from '@/utils/queryKeys';
import useSwitchWizard from './hooks/useSwitchWizard';
import { getUserData } from '@/auth/helpers/token';
import { State } from './reducers/switchWizardState';

const getTitle = (currentStepNumber: number, availabilitySwitch: boolean, path: string) => {
  if (!availabilitySwitch) {
    return 'Change provider';
  }
  // Welcome screen
  if (currentStepNumber >= 0 && currentStepNumber < 5) return 'Review preferences';
  if (path.includes('/therapist-matches')) return 'Available providers';
  if (path.includes('/sharing-preferences') || path.includes('/booking'))
    return 'Booking with new provider';
  return 'Find new provider';
};

const Routes = ({ history }: { history: History }) => {
  const isPostLvsSwitchPrompt = usePostLvsSwitchPrompt();
  const { onExitClick, setState, closeWizard } = useWizardActions();
  const { roomID, currentStepNumber } = useWizardState<State>();
  const { isMobile } = useWindowWidth();
  const isModal = useIsModal();

  const { availabilitySwitch } = useFlags();
  const { id: userID } = getUserData();

  const showBackButton =
    (availabilitySwitch && currentStepNumber === STEP_NUMBER_WELCOME_SCREEN) ||
    (history.location.pathname.includes('switch-provider/') &&
      !history.location.pathname.includes('select-timeslot')) ||
    (isPostLvsSwitchPrompt && history.location.state.source === 'postLvsCheckinSwitchProvider');

  const { exitAction } = useSwitchWizard()[1];
  const queryClient = useQueryClient();

  const handleExitModal = useCallback(() => {
    const queryKeys = [
      onboardingQueryKey({ roomID, userID }),
      onboardingV3QueryKey({ roomID, userID }),
      getAllRoomsQueryKey({ clientUserID: userID }),
      subscriptionsQueryKey({
        clientUserID: userID,
        roomID: Number(roomID),
      }),
    ];
    queryKeys.forEach((queryKey) => {
      queryClient.invalidateQueries(queryKey, { refetchActive: false });
    });
    exitAction();
    closeWizard(roomID, true);
  }, [exitAction, closeWizard, roomID, userID, queryClient]);

  const title = getTitle(currentStepNumber, !!availabilitySwitch, history.location.pathname);

  return (
    <Switch>
      <Screen
        safeAreaVariant={isModal ? 'setHeight' : 'setHeightAndAddTop'}
        contentWrapperStyle={{ paddingBottom: 0 }}
        showBackButton={showBackButton}
        onCloseButtonClick={onExitClick}
        onBackButtonClick={() => {
          if (
            currentStepNumber === STEP_NUMBER_WELCOME_SCREEN &&
            history.location.state.source === 'home'
          ) {
            onExitClick();
          }
          setState({ redirectParams: null });
          history.goBack();
        }}
        title={isMobile ? title : ''}
      >
        <Route exact path="/switch-provider/therapist-matches" component={MatchResultsContainer} />
        <Route
          path="/switch-provider/therapist-confirmation"
          render={() => (
            <TherapistConfirmationContainer
              isV2={!!availabilitySwitch}
              handleExitModal={handleExitModal}
            />
          )}
        />
        <Route path="/switch-provider/booking/:roomID" component={SwitchConfirmBookingContainer} />

        <Route
          exact
          path="/switch-provider/sharing-preferences"
          render={(props) => <SharingPreferencesContainer isV2={!!availabilitySwitch} {...props} />}
        />
        <Route exact path="/switch-provider/switch-reason" component={SwitchReasonContainer} />
        <Route exact path="/switch-provider/success" component={SwitchSuccessContainer} />
        <Route
          exact
          path="/switch-provider/review"
          render={(props) => <SwitchReviewContainer handleExitModal={handleExitModal} {...props} />}
        />
        <Route
          exact
          path="/switch-provider"
          render={() => (
            <WizardScreen
              wizardScheme={availabilitySwitch ? switchWizardSchemeV2 : switchWizardScheme}
            />
          )}
        />
      </Screen>
    </Switch>
  );
};

export default Routes;
