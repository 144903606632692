import * as React from 'react';
import Svg, { G, Circle, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

interface CircleCalendarProps {
  primaryColor?: string;
  secondaryColor?: string;
  height?: number;
  width?: number;
  style?: {};
}

const CircleCalendar: React.FunctionComponent<CircleCalendarProps> = ({
  primaryColor,
  secondaryColor,
  height = 21,
  width = 21,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'calendar';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 21 21"
      {...otherProps}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle
          cx={7.5}
          cy={7.5}
          r={9}
          transform="translate(3 3)"
          fill={primaryColor || colors.pink}
          stroke={secondaryColor || colors.white}
          strokeWidth={3}
        />
        <Path
          d="M10 9.563a.428.428 0 01-.417.437H5.417A.428.428 0 015 9.562V6.938c0-.242.187-.438.417-.438h4.166c.23 0 .417.196.417.438v2.625zM10.5 4a.5.5 0 00-1 0v.5h-4V4a.5.5 0 00-1 0v.5a1 1 0 00-1 1v5a1 1 0 001 1h6a1 1 0 001-1v-5a1 1 0 00-1-1V4z"
          fill={secondaryColor || colors.white}
          transform="translate(3 3)"
        />
      </G>
    </Svg>
  );
};

export default CircleCalendar;
