import Svg, { G, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const TherapistLicenseIcon = (props) => {
  const titleText = 'therapist license';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width="19px"
      height="21px"
      viewBox="0 0 19 21"
      title={titleText}
      aria-label={titleText}
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M15 9.5a1 1 0 01-1-1V4a2 2 0 00-2-2H4a2 2 0 00-2 2v10a2 2 0 002 2h2.5a1 1 0 010 2H4a4 4 0 01-4-4V4a4 4 0 014-4h8a4 4 0 014 4v4.5a1 1 0 01-1 1zM5 5h6a1 1 0 010 2H5a1 1 0 110-2zm0 3h2a1 1 0 110 2H5a1 1 0 110-2z"
          fill={colors.heatherGrey}
          fillRule="nonzero"
          transform="translate(-820 -374) translate(820 374)"
        />
        <Path
          d="M12.34 18.1l-2.485.84.032-2.622a.5.5 0 00-.099-.306L8.22 13.91l2.505-.78a.5.5 0 00.26-.188L12.5 10.8l1.516 2.141a.5.5 0 00.26.189l2.504.78-1.568 2.102a.5.5 0 00-.1.306l.033 2.623-2.485-.842a.5.5 0 00-.32 0z"
          stroke={colors.gold}
          strokeWidth={2}
          fill={colors.gold}
          transform="translate(-820 -374) translate(820 374)"
        />
      </G>
    </Svg>
  );
};

export default TherapistLicenseIcon;
