import { FunctionComponent } from 'react';
import {
  View,
  Large,
  Button,
  Big,
  ExtraHuge,
  Tiny,
  SpacingView,
  spacing,
} from '@talkspace/react-toolkit';

import moment from 'moment';
import styled from '../../../core/styled/styled';
import { PausedPlan } from '../../../components/icons';

const { space800 } = spacing;

const DATE_FORMAT_STRING = 'MMM DD, YYYY';

const LargeDarkGrey = styled(Large)(({ theme: { colors } }) => {
  return { color: colors.charcoal, textAlign: 'left', width: 335 };
});

const HeaderWrapper = styled(View)({
  width: 336,
  textAlign: 'left',
  marginBottom: 30,
});

const TherapyPausedDatePickerWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    border: `0.5px solid ${colors.periwinkleGrey}`,
    borderRadius: 10,
    width: 335,
    height: 70,
    paddingLeft: 19,
    paddingRight: 19,
    flexDirection: 'row',
    marginTop: 30,
    marginBottom: 20,
  };
});

const DatePickerSeperator = styled(View)(({ theme: { colors } }) => {
  return {
    width: 1,
    height: 39,
    alignSelf: 'center',
    border: `0.5px solid ${colors.periwinkleGrey}`,
  };
});

const DateSectionWrapper = styled(View)(() => {
  return {
    marginLeft: 20,
    marginRight: 20,
    flexDirection: 'column',
    alignItems: 'start',
    marginTop: 14,
  };
});

interface TherapyPausedDatePickerProps {
  iconComponent?: () => JSX.Element;
  period?: number;
}

const TherapyPausedDatePicker: FunctionComponent<TherapyPausedDatePickerProps> = ({
  iconComponent,
  period,
}) => {
  const todaysDate = moment().format(DATE_FORMAT_STRING);
  const pausedUntilDate = period
    ? moment().add(period, 'days').format(DATE_FORMAT_STRING)
    : moment().add(7, 'days').format(DATE_FORMAT_STRING);
  return (
    <TherapyPausedDatePickerWrapper>
      <View style={{ marginBottom: 19, marginTop: 19 }}>
        {iconComponent ? iconComponent() : null}
      </View>
      <DateSectionWrapper>
        <Tiny>Pause date</Tiny>
        <Big>{todaysDate}</Big>
      </DateSectionWrapper>
      <DatePickerSeperator />
      <DateSectionWrapper>
        <Tiny>Resume date</Tiny>
        <Big>{pausedUntilDate}</Big>
      </DateSectionWrapper>
    </TherapyPausedDatePickerWrapper>
  );
};

interface Props {
  pausePlan: () => void;
  onCancelPress: () => void;
  period?: number;
}

const PausePlanModal: FunctionComponent<Props> = ({ pausePlan, onCancelPress, period }) => {
  const handleSavePress = () => {
    pausePlan();
  };

  return (
    <View style={{ paddingBottom: space800 }}>
      <HeaderWrapper>
        <ExtraHuge as="h1">Thinking about taking a break from therapy?</ExtraHuge>
      </HeaderWrapper>
      <LargeDarkGrey>
        You can pause therapy for up to {period || 'seven'} days, and your therapist will be waiting
        for you when you return. You won’t be billed while your subscription is paused. You can
        resume your subscription anytime.
      </LargeDarkGrey>
      <TherapyPausedDatePicker iconComponent={() => <PausedPlan />} period={period} />
      <SpacingView>
        <Button
          dataQa="pause-therapy"
          onPress={handleSavePress}
          text="Pause therapy"
          style={{ flex: 1 }}
        />
        <Button
          dataQa="cancel"
          variant="secondary"
          onPress={onCancelPress}
          text="Cancel"
          style={{ flex: 1 }}
        />
      </SpacingView>
    </View>
  );
};

export default PausePlanModal;
