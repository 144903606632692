export const getReferralParams = () => {
  let referrerID: number | undefined;
  let channel: number | undefined;
  let platform: number | undefined;
  const urlParams = new URLSearchParams(window.location.search);
  const refid = urlParams.get('refid');
  if (refid) {
    const c = urlParams.get('channel');
    const p = urlParams.get('platform');
    referrerID = Number(refid);
    if (c) {
      const parsedC = Number(c);
      if (!Number.isNaN(parsedC)) channel = parsedC;
    }
    if (p) {
      const parsedP = Number(p);
      if (!Number.isNaN(parsedP)) platform = Number(p);
    }
  }
  return [referrerID, channel, platform];
};

export const getVoucherCode = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('accessCode') || undefined;
};
