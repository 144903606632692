import { useMemo } from 'react';
import useQueryActiveSessionAllRooms from 'inRoomScheduling/hooks/useQueryActiveSessionAllRooms';
import useQueryClientUsageStats from 'ts-frontend/hooks/useQueryClientUsageStats';
import useQuerySubscriptions from 'ts-frontend/hooks/useQuerySubscriptions';
import { ERoom } from 'ts-frontend/entities/Room';
import { Subscription, Booking, ClientUsageStats } from 'ts-frontend/types';
import { VideoCallAPIResponse } from 'chat/types/videoCallTypes';
import { ETherapistInfo } from 'ts-frontend/entities/Therapist';
import { MessagePreviewAPI } from 'ts-frontend/entities/MessagePreview';
import { isPendingSubscriptionRoom } from 'ts-frontend/helpers';
import { getUserData } from '@/auth/helpers/token';
import { EMe } from '@/entities/Me';
import useQueryOnboardingV3, {
  OnboardingAPIResponseV3,
} from '../onboarding/hooks/useQueryOnboardingV3';
import useQueryLastMessages from './useQueryLastMessages';
import useQueryGetClient from './useQueryGetClient';
import useQueryGetAllRooms from './useQueryGetAllRooms';
import useQueryBookingsAllRooms from './useQueryBookingsAllRooms';
import useQueryClientSurveys from './useQueryClientSurveys';
import { useMainState } from './mainContext';
import { UpcomingSurvey } from '../clinicalProgress/reducers/surveys';

export interface AggregatedRoomData {
  room?: ERoom;
  subscription?: Subscription;
  lastMessage?: MessagePreviewAPI;
  activeSession?: VideoCallAPIResponse;
  bookings?: Booking[];
  onboarding?: OnboardingAPIResponseV3[number];
  therapistInfo?: ETherapistInfo;
  hasUnreadMessage?: boolean;
  isPendingMatch?: boolean;
  upcomingSurveys?: UpcomingSurvey[];
}
export type AggregatedRoomDataByID = Record<number | string, AggregatedRoomData>;

export interface UseNavShellData {
  userID: number;
  activeSessionData: VideoCallAPIResponse | undefined;
  subscriptions: Subscription[] | undefined;
  rooms: ERoom[] | undefined;
  bookings: Booking[] | undefined;
  clientInfo: EMe | undefined;
  clientUsageStats: ClientUsageStats | undefined;
  dataByRoomID: AggregatedRoomDataByID | undefined;
  hasPendingMatchRoom: boolean;
  queries: {
    isLoadingRoomsList: boolean;
    isErrorRoomsList: boolean;
    isLoadingHomePage: boolean;
    isErrorHomePage: boolean;
    isLoadingOnboarding: boolean;
    isLoadingClientUsageStats: boolean;
    isErrorOnboarding: boolean;
    isFetchingOnboarding: boolean;
    isLoadingBookings: boolean;
    isErrorBookings: boolean;
    isLoadingSubscriptions: boolean;
    isFetchingSubscriptions: boolean;
    refetchSubscriptions: () => void;
    refetchLastMessages: () => void;
    refetchGetClientInfo: () => void;
    refetchBookingsAllRooms: () => void;
    refetchAllRooms: () => void;
    refetchActiveSessionAllRooms: () => void;
    refetchOnboarding: () => void;
    refetchClientSurveys: () => void;
  };
}

const useNavShellData = (): UseNavShellData => {
  // Requests and returns all data necessary for nav shell including computed aggregated dataByRoomID
  // Also returns use query loading states and refetch functions
  const { id: userID } = getUserData();

  const { roomsByID } = useMainState();

  const {
    data: subscriptions,
    isLoading: isLoadingSubscriptions,
    isFetching: isFetchingSubscriptions,
    isError: isErrorSubscriptions,
    refetch: refetchSubscriptions,
  } = useQuerySubscriptions({
    clientUserID: userID,
    includeInsuranceStatus: true,
    includePaymentDetails: true,
    includePlanDetails: true,
    includeLvsStatus: true,
  });

  const {
    data: lastMessages,
    isLoading: isLoadingLastMessages,
    isError: isErrorLastMessages,
    refetch: refetchLastMessages,
  } = useQueryLastMessages({ clientUserID: userID });
  const {
    data: clientInfo,
    isLoading: isLoadingClientInfo,
    isError: isErrorClientInfo,
    refetch: refetchGetClientInfo,
  } = useQueryGetClient({ clientUserID: userID });
  const {
    data: bookings,
    isLoading: isLoadingBookings,
    isError: isErrorBookings,
    refetch: refetchBookingsAllRooms,
  } = useQueryBookingsAllRooms({ clientUserID: userID, includeAsync: true });
  const allRooms = useQueryGetAllRooms({ clientUserID: userID });
  const {
    data: allRoomsData,
    isLoading: isLoadingAllRooms,
    isError: isErrorAllRooms,
    refetch: refetchAllRooms,
  } = allRooms || {};
  const {
    data: activeSessionData,
    isLoading: isLoadingActiveSessions,
    isError: isErrorActiveSessions,
    refetch: refetchActiveSessionAllRooms,
  } = useQueryActiveSessionAllRooms({ clientUserID: userID });

  const { data: clientUsageStats, isLoading: isLoadingClientUsageStats } = useQueryClientUsageStats(
    {
      clientID: userID,
    }
  );
  const {
    data: onboarding,
    isLoading: isLoadingOnboarding,
    isFetching: isFetchingOnboarding,
    isError: isErrorOnboarding,
    refetch: refetchOnboarding,
  } = useQueryOnboardingV3({ userID });

  const { data: clientSurveysData, refetch: refetchClientSurveys } = useQueryClientSurveys({
    disabled: !userID,
  });
  const upcomingSurveys = useMemo(
    () => (clientSurveysData ? clientSurveysData.upcomingSurveys : []),
    [clientSurveysData]
  );

  const { data: rooms, included: roomByIDIncluded } = allRoomsData || {};

  const dataByRoomID = useMemo<AggregatedRoomDataByID | undefined>(
    () =>
      rooms?.reduce((prev, roomRecord) => {
        const roomFromMainState = roomsByID[roomRecord.roomID];
        const { lastReadAckedMessageID, lastMessage: lastMessageMainState } =
          roomFromMainState || {};

        const lastRoomMessage = lastMessages?.find((x) => x.roomId === roomRecord.roomID);
        const lastMessageID = lastMessageMainState?.messageID || lastRoomMessage?.messageId || 0;

        const hasUnreadMessage = !!(
          lastRoomMessage &&
          lastReadAckedMessageID > 0 &&
          lastMessageID > lastReadAckedMessageID &&
          lastRoomMessage.userId !== userID
        );

        const therapistInfo = roomByIDIncluded?.therapistInfo.find(
          (x) => x.id === roomRecord.therapistID
        );
        const isPendingMatch = isPendingSubscriptionRoom({
          room: roomRecord,
          provider: therapistInfo,
        });

        return {
          ...prev,
          [roomRecord.roomID]: {
            room: {
              ...roomFromMainState,
              ...roomRecord,
            },
            subscription: subscriptions?.find((x) => x.id === roomRecord.roomID),
            lastMessage: lastRoomMessage,
            activeSession:
              activeSessionData?.roomID === roomRecord.roomID ? activeSessionData : null,
            bookings: bookings?.filter((x) => `${x.roomID}` === `${roomRecord.roomID}`),
            onboarding: onboarding && onboarding[roomRecord.roomID],
            therapistInfo,
            hasUnreadMessage,
            isPendingMatch,
            upcomingSurveys: upcomingSurveys.filter(
              (survey) => survey.roomID === roomRecord.roomID
            ),
          },
        };
      }, {}),
    [
      rooms,
      subscriptions,
      lastMessages,
      activeSessionData,
      bookings,
      onboarding,
      roomByIDIncluded,
      roomsByID,
      userID,
      upcomingSurveys,
    ]
  );

  const hasPendingMatchRoom: boolean = useMemo(
    () =>
      !!(dataByRoomID && Object.values(dataByRoomID).find(({ isPendingMatch }) => isPendingMatch)),
    [dataByRoomID]
  );

  return {
    userID,
    hasPendingMatchRoom,
    activeSessionData,
    clientInfo,
    subscriptions,
    rooms,
    bookings,
    clientUsageStats,
    dataByRoomID,
    queries: {
      isLoadingRoomsList: isLoadingAllRooms || isLoadingSubscriptions || isLoadingLastMessages,
      isErrorRoomsList: isErrorAllRooms || isErrorSubscriptions || isErrorLastMessages,
      isLoadingHomePage: isLoadingClientInfo,
      isErrorHomePage: isErrorClientInfo,
      isLoadingOnboarding,
      isFetchingOnboarding,
      isLoadingClientUsageStats,
      isErrorOnboarding,
      isLoadingBookings: isLoadingBookings || isLoadingActiveSessions,
      isErrorBookings: isErrorBookings || isErrorActiveSessions,
      isLoadingSubscriptions,
      isFetchingSubscriptions,
      refetchSubscriptions,
      refetchLastMessages,
      refetchGetClientInfo,
      refetchBookingsAllRooms,
      refetchAllRooms,
      refetchActiveSessionAllRooms,
      refetchOnboarding,
      refetchClientSurveys,
    },
  };
};

export default useNavShellData;
