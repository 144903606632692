import { Spinner, TextDS, useEmotionTheme, View, spacing } from '@talkspace/react-toolkit';

const { space100, space400 } = spacing;

interface Props {
  text: string;
}
const MoreResultsLoader = ({ text }: Props) => {
  const { colorRoles } = useEmotionTheme();
  return (
    <View
      align="center"
      style={{ backgroundColor: colorRoles.surfaces.defaultSubtle, padding: `${space400}px 0` }}
    >
      <Spinner />
      <TextDS colorRole="textSubtle" style={{ marginTop: space100 }}>
        {text}
      </TextDS>
    </View>
  );
};

export default MoreResultsLoader;
