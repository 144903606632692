/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useCallback, useContext, createContext, useRef, useEffect } from 'react';
import { EMessagePreview } from 'ts-frontend/entities/MessagePreview';
import useQuerySubscriptions from 'ts-frontend/hooks/useQuerySubscriptions';
import ApiHelper from '../utils/ApiHelper';
import { State, roomsReducer, initialState, AdminConfigs } from '../reducers/mainReducer';
import { EMe } from '../entities/Me';
import { IncomingSocketData } from '../utils/socket';
import AdminConfigAPI, { AdminConfig } from '../utils/adminConfig';
import { getUserData } from '@/auth/helpers/token';

export const StateContext = createContext<State | undefined>(undefined);

export interface MainActions {
  getAllRooms: (clientUserID: number) => Promise<null>;
  getInformedConsentStatus: (clientUserID: number) => Promise<void>;
  getLastMessages: (clientUserID: number) => Promise<null>;
  getClient: (clientUserID: number) => Promise<null>;
  getLVSSubscriptions: () => void;
  receiveLastMessage: (lastMessage: EMessagePreview) => null;
  updateLastReadAck: (roomID: number, lastReadAckedMessageID: number) => void;
  setDropdownMenuVisible: (isDropdownMenuVisible: boolean) => void;
  setNickname: (data: IncomingSocketData['changedNickname']) => void;
  getAdminConfigOption: (optionName: AdminConfig) => void;
}

export const ActionsContext = createContext<MainActions | undefined>(undefined);

function MainContextProvider({ children }) {
  const [state, dispatch] = useReducer(roomsReducer, initialState);
  const apiRef = useRef(new ApiHelper());
  const { current: api } = apiRef;

  const { id: userID } = getUserData();
  const { data: subscriptionsData, refetch: refetchSubscriptionsData } = useQuerySubscriptions({
    clientUserID: userID,
    includePaymentDetails: true,
    includeInsuranceStatus: true,
    includePlanDetails: true,
    includeLvsStatus: true,
    disabled: !userID || !state.isSubscriptionEnabled,
  });

  useEffect(() => {
    dispatch({
      type: 'receiveLVSGetSubscriptions',
      payload: subscriptionsData || [],
    });
  }, [subscriptionsData]);

  useEffect(
    () => () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      apiRef.current.cancelAll();
    },
    []
  );

  function receiveLastMessage(lastMessage: EMessagePreview): null {
    dispatch({
      type: 'receiveLastMessage',
      payload: { roomID: lastMessage.roomID, lastMessage },
    });
    return null;
  }

  function getLastMessages(clientUserID: number): Promise<null> {
    dispatch({ type: 'getLastMessages' });
    return api
      .getLastMessages(clientUserID)
      .then((res) => {
        res.data.forEach((m) => receiveLastMessage(new EMessagePreview(m)));
      })
      .then(() => null);
  }

  function getAllRooms(clientUserID: number): Promise<null> {
    dispatch({ type: 'getAllRooms' });
    return api
      .getAllRooms(clientUserID)
      .then((res) => {
        dispatch({
          type: 'receiveGetAllRooms',
          payload: {
            rooms: res.data,
            therapists: res.included.therapistInfo || [],
            clients: res.included.clients || [],
            cmsItems: res.included.cmsItems || [],
          },
        });
        return null;
      })
      .catch(api.dismissIfCancelled);
  }

  function getClient(clientUserID: number): Promise<null> {
    dispatch({ type: 'getClient' });
    return api.getClient(clientUserID).then(({ data }) => {
      const me = new EMe(data);
      dispatch({
        type: 'receiveGetClient',
        payload: { me },
      });
      return null;
    });
  }

  async function getInformedConsentStatus(clientUserID: number): Promise<void> {
    dispatch({ type: 'getInformedConsentStatus' });
    const informedConsentV1Status = await api.getInformedConsentStatus(clientUserID);
    const informedConsentV2Status = await api.getInformedConsentV2Status(clientUserID);
    const { informedConsentID, roomID, showProviderInfo, shouldSignConsent, therapistID } =
      informedConsentV1Status;
    dispatch({
      type: 'receiveGetInformedConsentStatus',
      payload: {
        informedConsentStatus: {
          shouldSignConsent: informedConsentV2Status.hasV2
            ? informedConsentV2Status.shouldSignConsent
            : shouldSignConsent,
          showProviderInfo,
          informedConsentID,
          therapistID,
          roomID,
        },
      },
    });
  }

  function getAdminConfigOption(optionName: AdminConfig): Promise<null> {
    dispatch({ type: 'getAdminConfigOption' });
    return AdminConfigAPI.getAdminOptionByName(optionName).then((response) => {
      if (response.data) {
        const { data: optionValue } = response.data;
        let keyName: keyof AdminConfigs;
        let keyValue: Required<AdminConfigs>[keyof AdminConfigs];
        switch (optionName) {
          case 'email_verification':
            keyName = 'emailVerificationFlagIsActive';
            keyValue = Boolean(
              optionValue
            ) as Required<AdminConfigs>['emailVerificationFlagIsActive'];
            break;
          case 'clinical_progress_web':
            keyName = 'isClinicalProgressEnabled';
            keyValue = Boolean(optionValue) as Required<AdminConfigs>['isClinicalProgressEnabled'];
            break;
          case 'treatment_intake_in_onboarding':
            keyName = 'treatmentIntakeInOnboardingActive';
            keyValue = Boolean(optionValue);
            break;
          default:
            // eslint-disable-next-line no-console
            console.error('Invalid admin config', optionName);
            return null;
        }
        dispatch({
          type: 'receiveAdminConfigOption',
          payload: {
            adminConfigs: { [keyName]: keyValue },
          },
        });
      }
      return null;
    });
  }

  function getLVSSubscriptions() {
    dispatch({ type: 'getLVSSubscriptions' });
    refetchSubscriptionsData();
  }

  function updateLastReadAck(roomID: number, lastReadAckedMessageID: number) {
    dispatch({
      type: 'setLastReadAck',
      payload: { roomID, lastReadAckedMessageID },
    });
  }

  function setDropdownMenuVisible(isDropdownMenuVisible: boolean) {
    dispatch({
      type: 'setDropdownMenuVisible',
      payload: { isDropdownMenuVisible },
    });
  }

  function setNickname(data: IncomingSocketData['changedNickname']) {
    dispatch({
      type: 'receiveNickname',
      payload: { displayName: data.nickname },
    });
  }

  const actions = {
    getAllRooms: useCallback(getAllRooms, []),
    getInformedConsentStatus: useCallback(getInformedConsentStatus, []),
    getLastMessages: useCallback(getLastMessages, []),
    getLVSSubscriptions: useCallback(getLVSSubscriptions, []),
    updateLastReadAck: useCallback(updateLastReadAck, []),
    getClient: useCallback(getClient, []),
    receiveLastMessage: useCallback(receiveLastMessage, []),
    setDropdownMenuVisible: useCallback(setDropdownMenuVisible, []),
    setNickname: useCallback(setNickname, []),
    getAdminConfigOption: useCallback(getAdminConfigOption, []),
  };

  return (
    <StateContext.Provider value={state}>
      <ActionsContext.Provider value={actions}>{children}</ActionsContext.Provider>
    </StateContext.Provider>
  );
}

function useMainState(): State {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error('StateContext must be used within a MainContextProvider');
  }
  return context;
}

function useMainActions() {
  const context = useContext(ActionsContext);
  if (context === undefined) {
    throw new Error('ActionsContext must be used within a MainContextProvider');
  }
  return context;
}

export { MainContextProvider, useMainState, useMainActions };
