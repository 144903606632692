import { useQuery } from 'react-query';

import { TherapistInfo } from 'ts-frontend/entities/Therapist';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

import { therapistInfoQueryKey } from '../utils/queryKeys';

interface QueryProps {
  roomID: string;
}
type QueryData = TherapistInfo;

const fetchTherapistInfo =
  ({ roomID }: QueryProps) =>
  async () => {
    const {
      data: { data },
    } = await apiWrapper.get(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/room-profile-info`); // NOTE: endpoint is misnamed, it uses the getTherapistProfile controller under the hood. This is indeed therapistInfo
    return data;
  };

const useQueryTherapistInfo = ({ roomID }: QueryProps) =>
  useQuery<QueryData, Error>({
    queryKey: therapistInfoQueryKey({ roomID }),
    queryFn: fetchTherapistInfo({ roomID }),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(roomID),
  });

export default useQueryTherapistInfo;
