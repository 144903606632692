import apiHelper from '../../core/api/apiHelper';
import apiWrapper from '../../core/api/apiWrapper';

function cancelNonSubscription(roomID: number, clientMetGoals?: boolean): Promise<{}> {
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/cancel-non-subscription`, {
      clientMetGoals,
    })
    .then((res) => res.data);
}

const b2bCancellationWizardApiHelper = { cancelNonSubscription };

export default b2bCancellationWizardApiHelper;
