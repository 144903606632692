import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const ArrowLeftAndroid = ({
  width = 24,
  height = 24,
  color,
  ...otherProps
}: {
  width?: number;
  height?: number;
  color?: string;
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'left arrow';
  return (
    <Svg
      viewBox="0 0 24 24"
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <Path
        d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
        fill={color || colors.green}
      />
    </Svg>
  );
};

export default ArrowLeftAndroid;
