import { useMemo } from 'react';
import * as React from 'react';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { useHistory } from 'react-router';

import {
  ResponsiveLayoutWithHeader,
  PanelHeader,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { NAV_SHELL_HEADER_HEIGHT } from 'ts-frontend/constants';
import { useTranslation } from '@talkspace/i18n';
import { TFNewMemberNav } from '@talkspace/i18n/types';

import useOpenModalSafe from '@/hooks/useOpenModalSafe';

import { DeepLinkAction, useDeepLinkAction } from '../../hooks/useDeepLink';
import MobileHeader from '../../components/MobileLeftHeader';
import PersonalInformation from '../components/PersonalInformation';
import MyAccountPanelWrapper from '../components/MyAccountPanelWrapper/MyAccountPanelWrapper';
import ReactFrameService from '../../auth/reactFrame/ReactFrameService';
import { PersonalInformationContextProvider } from '../hooks/usePersonalInformation';

interface Props {
  onCloseButtonPress: () => void;
  onBackButtonPress: () => void;
}

const getBreadcrumbRoutes = (tnewMemberNav: TFNewMemberNav) => [
  {
    label: tnewMemberNav('accountSettings.account', 'Account'),
    path: '/home/account',
  },
  {
    label: tnewMemberNav('personalInfo.personal', 'Personal Information'),
    path: '',
  },
];

const PersonalInformationContainer: React.VFC<Props> = ({
  onCloseButtonPress,
  onBackButtonPress,
}) => {
  const isInFrame = ReactFrameService.instance().isInFrame();
  const isiOS = ReactFrameService.isiOS();
  const { isMobile } = useWindowWidthState();
  const openModal = useOpenModalSafe();
  const history = useHistory();
  const useNewNav = useNewMemberNav();
  const isHomeNav = useNewNav && history.location.pathname.includes('home');
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  const breadcrumbRoutes = getBreadcrumbRoutes(tnewMemberNav);

  const handleRouting = (path) => {
    if (openModal) {
      openModal(path, undefined, isMobile, true);
    } else {
      history.push(path);
    }
  };

  const onEditMyInformation = () => {
    handleRouting('/edit-my-information');
  };

  const onEditEmergencyContact = () => {
    handleRouting('/edit-emergency-contact');
  };

  const onEditTeenspaceCommunity = () => {
    handleRouting('/edit-teenspace-community');
  };

  useDeepLinkAction(DeepLinkAction.PERSONAL_DETAILS)(
    useMemo(() => {
      return { openModal, isMobile, action: 'personal-details' };
    }, [openModal, isMobile])
  );

  const newNavHeaderHeight = isMobile ? 0 : NAV_SHELL_HEADER_HEIGHT;

  if (isHomeNav)
    return (
      <PersonalInformationContextProvider>
        <ResponsiveLayoutWithHeader
          outsideHeaderHeight={useNewNav ? newNavHeaderHeight : undefined}
          outsideFooterHeight={useNewNav ? 0 : undefined}
          headerHeight={!isMobile ? 0 : undefined}
          renderHeader={() => (
            <PanelHeader
              renderLeft={() => (
                <MobileHeader
                  centerTitle={isInFrame && isiOS}
                  title=""
                  onBackButtonPress={() => history.goBack()}
                />
              )}
              onRightPress={isInFrame ? undefined : onCloseButtonPress}
            />
          )}
          noHeaderLine
        >
          <MyAccountPanelWrapper
            alwaysRow={isInFrame}
            title={tnewMemberNav('personalInfo.personal', 'Personal Information')}
            breadcrumbRoutes={breadcrumbRoutes}
            inNavShell={isHomeNav}
          >
            <PersonalInformation
              onEditMyInformation={onEditMyInformation}
              onEditEmergencyContact={onEditEmergencyContact}
              onEditTeenspaceCommunity={onEditTeenspaceCommunity}
            />
          </MyAccountPanelWrapper>
        </ResponsiveLayoutWithHeader>
      </PersonalInformationContextProvider>
    );

  return (
    <PersonalInformationContextProvider>
      <ResponsiveLayoutWithHeader
        renderHeader={() => (
          <PanelHeader
            renderLeft={() => (
              <MobileHeader
                centerTitle={isInFrame && isiOS}
                title={tnewMemberNav('accountSettings.myAccount', 'Account')}
                mobileTitle={tnewMemberNav('accountSettings.personal', 'Personal Information')}
                onBackButtonPress={onBackButtonPress}
              />
            )}
            onRightPress={isInFrame ? undefined : onCloseButtonPress}
          />
        )}
      >
        <MyAccountPanelWrapper
          alwaysRow={isInFrame}
          title={tnewMemberNav('accountSettings.personal', 'Personal Information')}
        >
          <PersonalInformation
            onEditMyInformation={onEditMyInformation}
            onEditEmergencyContact={onEditEmergencyContact}
            onEditTeenspaceCommunity={onEditTeenspaceCommunity}
          />
        </MyAccountPanelWrapper>
      </ResponsiveLayoutWithHeader>
    </PersonalInformationContextProvider>
  );
};

export default PersonalInformationContainer;
