import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

function TwitterRounded({
  width = 42,
  height = 42,
  fill,
  ...props
}: {
  width?: number;
  height?: number;
  fill?: string;
}) {
  const { colors } = useEmotionTheme();
  const titleText = 'Twitter';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <Path
        fill={fill || colors.pictonBlue}
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        d="M76 0c11.598 0 21 9.402 21 21s-9.402 21-21 21-21-9.402-21-21S64.402 0 76 0zm3.942 13.251c-2.345 0-4.249 1.952-4.249 4.358 0 .342.037.673.11.993-3.532-.182-6.664-1.915-8.761-4.555a4.437 4.437 0 00-.575 2.193c0 1.511.75 2.846 1.89 3.627a4.166 4.166 0 01-1.926-.544v.054c0 2.112 1.466 3.875 3.411 4.274a4.051 4.051 0 01-1.12.153c-.273 0-.54-.026-.8-.077.542 1.73 2.11 2.991 3.97 3.024a8.39 8.39 0 01-6.292 1.807 11.83 11.83 0 006.515 1.957c7.819 0 12.093-6.64 12.093-12.4 0-.19-.004-.378-.01-.564a8.728 8.728 0 002.118-2.256c-.76.346-1.58.58-2.44.686a4.352 4.352 0 001.869-2.41 8.356 8.356 0 01-2.701 1.055 4.192 4.192 0 00-3.102-1.375z"
        transform="translate(-726 -458) translate(433 454) translate(238 4)"
      />
    </Svg>
  );
}

export default TwitterRounded;
