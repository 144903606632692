import { FunctionComponent } from 'react';
import { PlanIcon as Icon } from 'ts-frontend/types';
import { EmotionStyle } from '@/core/styled';
import Messaging from '../icons/Messaging';
import Messaging4Lvs from '../icons/Messaging4Lvs';
import Video from '../icons/Video';
import Psychiatry from '../icons/Psychiatry';

interface PlanIconProps {
  icon: Icon;
  className?: string;
  size?: number;
  style?: EmotionStyle;
}

const PlanIcon: FunctionComponent<PlanIconProps> = ({ icon, className, size, style }) => {
  switch (icon) {
    case Icon.messaging:
      return <Messaging className={className} size={size} style={style} />;
    case Icon.messaging1lvs:
      return <Messaging4Lvs className={className} size={size} style={style} />;
    case Icon.messaging4lvs:
      return <Messaging4Lvs className={className} size={size} style={style} />;
    case Icon.video:
      return <Video className={className} size={size} style={style} />;
    case Icon.psychiatry:
      return <Psychiatry className={className} size={size} style={style} />;
    default:
      return null;
  }
};

export default PlanIcon;
