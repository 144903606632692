import { useReducer, useRef, useCallback } from 'react';
import { wizardActionReducer, wizardActionInitialState, WizardActionState } from 'stepWizard';

import treatmentIntakeApiHelper, { MatchingIntakePayload } from '../../treatmentIntakeApiHelper';

export default function useEmergencyContact(): [
  WizardActionState,
  {
    submitIntakeResults: (roomId: number, clientUserID, payload: MatchingIntakePayload) => void;
  }
] {
  const [state, dispatch] = useReducer(wizardActionReducer, wizardActionInitialState());
  const apiRef = useRef(treatmentIntakeApiHelper);

  function submitMatchingIntake(roomId: number, clientUserID, payload: MatchingIntakePayload) {
    dispatch({
      type: 'requestAction',
    });
    apiRef.current
      .updateEmergencyContact(roomId, clientUserID, payload)
      .then(() => {
        dispatch({ type: 'receiveAction' });
      })
      .catch((error) => {
        dispatch({
          type: 'setError',
          error: (error && error.message) || '',
        });
      });
  }

  return [
    state as WizardActionState,
    {
      submitIntakeResults: useCallback(submitMatchingIntake, []),
    },
  ];
}
