import { useMutation } from 'react-query';

import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

export interface VerifyPhoneNumberResponse {
  otpToken: string;
  ttlSeconds: number;
  phoneNumber: string;
}

const verifyPhoneNumber = async (): Promise<VerifyPhoneNumberResponse> => {
  const response = await apiWrapper.post(`${apiHelper().authAPIEndpoint}/v3/2fa/verify-number`);
  return response.data.data;
};

const useMutationVerifyNumber = () =>
  useMutation<VerifyPhoneNumberResponse, Error, null>(verifyPhoneNumber);

export default useMutationVerifyNumber;
