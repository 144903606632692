import { VoidFunctionComponent, useEffect } from 'react';
import { useParams } from 'core/routerLib';
import { useHistory } from 'react-router-dom';
import { Spinner } from '@talkspace/react-toolkit';
import useQueryGetSuperbills from '../hooks/useQueryGetSuperbills';
import SuperbillsList from '../components/SuperbillsList';
import SuperbillsWrapper from '../components/SuperbillsWrapper';

const SuperbillsScreen: VoidFunctionComponent = () => {
  const { roomID } = useParams<{ roomID: string }>();
  const { isLoading, error, data: superbillData } = useQueryGetSuperbills(+roomID);

  const history = useHistory();

  useEffect(() => {
    if (error) {
      history.push(`/superbills/room/${roomID}/initial-error`);
    }
    if (!isLoading && (!superbillData?.superbills || superbillData.superbills.length === 0)) {
      history.push(`/superbills/room/${roomID}/no-superbills`);
    }
  }, [history, error, isLoading, roomID, superbillData]);

  if (isLoading) {
    return <Spinner />;
  }

  if (superbillData?.superbills) {
    return (
      <SuperbillsWrapper>
        <SuperbillsList />
      </SuperbillsWrapper>
    );
  }

  return null;
};

export default SuperbillsScreen;
