import { useMutation } from 'react-query';

import { getUserData } from '@/auth/helpers/token';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

type QueryParams = {
  displayName?: string;
  onboardedTeenspaceAt?: string;
};

export interface PutCommunityUserDataVariables {
  displayName?: string | undefined;
  onboardedTeenspaceAt?: string | undefined;
}

const putCommunityUserData = async ({
  displayName,
  onboardedTeenspaceAt,
}: PutCommunityUserDataVariables): Promise<number> => {
  const queryParams: QueryParams = {
    displayName,
    onboardedTeenspaceAt,
  };

  const { id: userID } = getUserData();

  const response = await apiWrapper.put(
    `${apiHelper().apiEndpoint}/v2/clients/${userID}/community-user-data`,
    queryParams
  );
  return response.status;
};
const useMutationPutCommunityUserData = () =>
  useMutation<
    number | null,
    {
      data?: { data?; status?: number };
    },
    PutCommunityUserDataVariables
  >(putCommunityUserData);

export default useMutationPutCommunityUserData;
