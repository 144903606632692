import { useMutation } from 'react-query';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

export interface ChangePhoneNumberVariables {
  phoneNumber: string;
  phoneNumberCountryCode: string;
  updateEmergencyContact?: boolean;
}
export interface ChangePhoneNumberResponse {
  otpToken: string;
  ttlSeconds: number;
  phoneNumber: string;
}

const changePhoneNumber = async ({
  phoneNumber,
  phoneNumberCountryCode,
  updateEmergencyContact = true,
}: ChangePhoneNumberVariables): Promise<ChangePhoneNumberResponse | null> => {
  const response = await apiWrapper.post(`${apiHelper().authAPIEndpoint}/v3/2fa/change-number`, {
    phoneNumber,
    phoneNumberCountryCode,
    updateEmergencyContact,
  });
  return response.status === 204 ? null : response.data.data;
};

const useMutationChangeNumber = () =>
  useMutation<
    ChangePhoneNumberResponse | null,
    {
      data?: { data?: { error: string } };
    },
    ChangePhoneNumberVariables
  >(changePhoneNumber);

export default useMutationChangeNumber;
