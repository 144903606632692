import { useEffect } from 'react';
import { VERIFICATION_CODE_LENGTH } from '../utils/helpers';

const useSend2FARequestWithTimeout = (
  isError: boolean,
  isLoading: boolean,
  verificationCode: string,
  callback: () => void
) => {
  useEffect(() => {
    const sendRequestTimer = setTimeout(() => {
      if (
        verificationCode &&
        verificationCode.length === VERIFICATION_CODE_LENGTH &&
        !isLoading &&
        !isError
      ) {
        callback();
      }
    }, 200);

    return () => {
      clearTimeout(sendRequestTimer);
    };
  }, [isError, isLoading, verificationCode, callback]);
};

export default useSend2FARequestWithTimeout;
