import { useQuery } from 'react-query';

import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { getUserSettingsQueryKey } from '../utils/queryKeys';

interface UserSettings {
  soundNotification: boolean;
  sendMessagesReceipts: boolean;
  highContrast: boolean;
}

interface QueryProps {
  userID: number;
}
type QueryData = UserSettings;

const fetchUserSettings =
  ({ userID }: QueryProps) =>
  async () => {
    const {
      data: { data },
    } = await apiWrapper.get(`${apiHelper().apiEndpoint}/v3/users/${userID}/user-settings`);
    return data;
  };

const useQueryUserSettings = ({ userID }: QueryProps) =>
  useQuery<QueryData, Error>({
    queryKey: getUserSettingsQueryKey({ userID }),
    queryFn: fetchUserSettings({ userID }),
    cacheTime: Infinity,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

export default useQueryUserSettings;
