import { AppSource, Currency } from 'ts-frontend/types';
import {
  trackVirtualPageView as trackVirtualPageViewInner,
  trackContinueToCheckout,
  trackPlanSelectedEvent,
  trackPurchaseEvent,
  trackTalkspacePageView,
  trackSeeAvailableOffersEvent,
  trackPlanExpendEvent,
  trackEvent as trackEventQM,
  trackGTMEvent as trackGTMEventQM,
} from '@/utils/analytics/events';
import { VWO } from '@/utils/analytics/eventTracker';

import { EventNameTypes, EventValueTypes } from '@/utils/analytics/trackerTypes';

function trackMixpanelQM(eventName: string, eventValue: object) {
  trackEventQM(eventName as EventNameTypes, eventValue as EventValueTypes);
}

function trackPageView(
  source: AppSource,
  pageName: string,

  funnelName: string,
  eventProperties?: object
) {
  if (source === AppSource.client) {
    trackTalkspacePageView(pageName, funnelName, eventProperties);
  }
}

function trackVirtualPageView(source, url: string) {
  if (source === AppSource.client) {
    trackVirtualPageViewInner(url);
  }
}

function seeAvailableOffers({
  source,
  offerID,
  requestedOfferID,
  currency,
  planPrices,
  topMostPlanPrice,
  Successful,
  funnelName,
  errorMessage,
  experimentName,
  experimentVariant,
}: {
  source: AppSource;
  offerID: number;
  requestedOfferID: number;
  currency: Currency;
  planPrices: string[];
  topMostPlanPrice: string;
  Successful: boolean;
  funnelName: string;
  errorMessage?: string;
  experimentName?: string;
  experimentVariant?: string;
}) {
  if (source === AppSource.client) {
    trackSeeAvailableOffersEvent(
      funnelName,
      offerID,
      requestedOfferID,
      currency,
      planPrices,
      topMostPlanPrice,
      Successful,
      errorMessage
    );
  } else if (source === AppSource.qm) {
    trackMixpanelQM('See Available Offers', {
      offerID,
      'Funnel Name': 'QuickMatch',
      Currency: currency,
      Successful,
      'Plan Prices': planPrices,
      'Top Most Plan Price': topMostPlanPrice,
      errorMessage,
      requestedOfferID,
      experimentName,
      experimentVariant,
    });
  }
}

function continueToCheckout({
  source,
  displayName,
  priceAmount,
  billingFrequency,
  discountPercent,
  funnelName,
}: {
  source: AppSource;
  displayName: string;
  priceAmount: number;
  billingFrequency: number;
  discountPercent: number;
  funnelName: string;
}) {
  if (source === AppSource.client) {
    trackContinueToCheckout(
      funnelName,
      displayName,
      priceAmount,
      billingFrequency,
      discountPercent
    );
  } else {
    trackMixpanelQM('Continue To Checkout', {
      'Subscription Plan': displayName,
      'Subscription Value ($)': priceAmount,
      'Billing Frequency': billingFrequency,
      'Discount %': discountPercent,
      'Funnel Name': 'QuickMatch',
    });
  }
}

function planSelectedEvent({
  source,
  funnelName,
  displayName,
  priceAmount,
  billingFrequency,
  discountPercent,
  experimentName,
  experimentVariant,
  isLoggedInUser,
}: {
  source: AppSource;
  funnelName: string;
  displayName: string;
  priceAmount: number;
  billingFrequency: number;
  discountPercent: number;
  experimentName?: string;
  experimentVariant?: string;
  isLoggedInUser: boolean;
}) {
  if (source === AppSource.client)
    trackPlanSelectedEvent(funnelName, displayName, priceAmount, billingFrequency, discountPercent);
  else if (source === AppSource.qm) {
    trackMixpanelQM('Select Subscription Plan', {
      'Subscription Plan': displayName,
      'Subscription Value ($)': priceAmount,
      'Billing Frequency': billingFrequency,
      'Discount %': discountPercent,
      'Funnel Name': 'QuickMatch',
      experimentName,
      experimentVariant,
    });
    if (!isLoggedInUser) {
      trackGTMEventQM('Selected Plan', {});
      VWO.trackSelectedPlanGoal();
    }
  }
}

function planExpend(source: AppSource, displayName: string, offerID: number, funnelName: string) {
  if (source === AppSource.client) {
    trackPlanExpendEvent(funnelName, offerID, displayName);
  } else if (source === AppSource.qm) {
    trackMixpanelQM('See Available Billing Frequency', {
      'Subscription Plan': displayName,
      offerID,
    });
  }
}

function purchaseEvent({
  source,
  roomID,
  planID,
  offerID,
  requestedOfferID,
  eventCategory,
  currency,
  discountPercent,
  revenue,
  price,
  planDisplayName,
  billingCycle,
  isTestUser,
  couponCode,
  partner,
  experiment,
  experimentID,
  isFirstPurchase = false,
  successful = true,
  errors,
}: {
  source: AppSource;
  roomID: number;
  planID: number;
  offerID: number;
  requestedOfferID: number;
  eventCategory: string;
  currency: string;
  discountPercent: number;
  revenue: number;
  price: number;
  planDisplayName: string;
  billingCycle: number;
  isTestUser: boolean;
  couponCode?: string;
  partner?: string;
  experiment?: object;
  experimentID?: string;
  isFirstPurchase?: boolean;
  successful?: boolean;
  errors?: string[];
}) {
  if (source === AppSource.client) {
    trackPurchaseEvent(
      roomID,
      planID,
      offerID,
      requestedOfferID,
      eventCategory,
      currency,
      discountPercent,
      revenue,
      price,
      planDisplayName,
      billingCycle,
      isTestUser,
      couponCode,
      partner,
      experiment,
      experimentID,
      isFirstPurchase,
      successful,
      errors
    );
  }
}

const offerAnalytics = {
  trackVirtualPageView,
  trackPageView,
  seeAvailableOffers,
  continueToCheckout,
  planSelectedEvent,
  planExpend,
  purchaseEvent,
};

export default offerAnalytics;
