import { trackEvent } from '../../utils/analytics/eventTracker';
import { EventNameTypes } from '../../utils/analytics/trackerTypes';
import { getUserData } from '../../auth/helpers/token';

enum EventCategory {
  CLIENT_2FA = 'Client 2FA',
  CLIENT_2FA_VERIFICATION = 'Client 2FA Verification',
}

export const trackClient2FA = (action: EventNameTypes) => {
  const { id: userID } = getUserData();
  trackEvent(
    action,
    {
      eventCategory: EventCategory.CLIENT_2FA,
      eventCategoryKey: 'eventCategory',
      'User ID': userID,
    },
    ['mixpanel']
  );
};

export const trackClient2FAVerification = (action: EventNameTypes) => {
  const { id: userID } = getUserData();
  trackEvent(
    action,
    {
      eventCategory: EventCategory.CLIENT_2FA_VERIFICATION,
      eventCategoryKey: 'eventCategory',
      'User ID': userID,
    },
    ['mixpanel']
  );
};
