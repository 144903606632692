import { WizardGenericActions } from 'stepWizard';
import { roomHasRealProvider } from 'ts-frontend/helpers/noMatches';
import { State } from '../reducers/b2bCancellationWizardState';
import therapistReviewApiHelper from '../../therapistReview/utils/therapistReviewApiHelper';
import { OtherWizardGenericActions } from '../../utils/genericWizardActions';

const initState: WizardGenericActions<State>['initState'] = async (_api, state) => {
  const therapistInfo = await therapistReviewApiHelper.getTherapistInfo(state.roomID);
  const hasProvider = roomHasRealProvider({ provider: therapistInfo });
  const setSelectedCancellationReason = (_, context) => {
    const partialState: Partial<State> = {};

    const { setState, wizardContext } = context;
    const { cancellationReasonV2 } = wizardContext;

    partialState.isFeelingBetterReason = false;
    partialState.isDissatisfiedWithProviderReason = false;
    partialState.isDissatisfiedWithAppReason = false;
    partialState.isExpensiveReason = false;
    partialState.isOtherReason = false;

    switch (cancellationReasonV2) {
      case 'feeling_better':
        partialState.isFeelingBetterReason = true;
        partialState.flow = 'feelingBetter';
        break;
      case 'dissatisfied_with_provider':
        partialState.isDissatisfiedWithProviderReason = true;
        partialState.flow = 'dissatisfiedWithProvider';
        break;
      case 'dissatisfied_with_app':
        partialState.isDissatisfiedWithAppReason = true;
        partialState.flow = 'dissatisfiedWithApp';
        break;
      case 'expensive':
        partialState.isExpensiveReason = true;
        partialState.flow = 'expensive';
        break;
      case 'other':
        partialState.isOtherReason = true;
        partialState.flow = 'other';
        break;
      default:
        partialState.flow = 'straightToCancel';
        break;
    }
    setState(partialState);
  };

  const dissatisfiedWithProviderPostAction = (_, context) => {
    const partialState: Partial<State> = {};

    const { setState, wizardContext } = context;
    const { dissatisfiedWithProviderReason } = wizardContext;

    partialState.isDissatisfiedWithProviderOther = dissatisfiedWithProviderReason === 'other';
    setState(partialState);
  };

  return {
    ...state,
    hasProvider,
    therapistInfo,
    setSelectedCancellationReason,
    dissatisfiedWithProviderPostAction,
  };
};

const prePersist: OtherWizardGenericActions<State>['prePersist'] = async (state) => {
  if (state.therapistReview) {
    const reviewPayload = {
      ...state.therapistReview,
      ratingSource: `${state.wizardType} wizard`,
    };
    await therapistReviewApiHelper.postTherapistReview(reviewPayload);
  }
};

const b2bCancellationWizardActions: OtherWizardGenericActions<State> = {
  initState,
  prePersist,
};

export default b2bCancellationWizardActions;
