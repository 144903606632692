import { useEffect, useMemo, useState } from 'react';

import {
  CommentRepository,
  getPostTopic,
  subscribeTopic,
  SubscriptionLevels,
} from '@amityco/ts-sdk';

import { useCommunityState } from './CommunityContext';

const useCommunityPostComments = ({ post }: { post: Amity.Post }) => {
  const [commentsData, setCommentsData] = useState<Amity.Comment[]>();
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [onLoadMoreObj, setOnLoadMoreObj] = useState<{
    onLoadMore: () => void;
  }>({ onLoadMore: () => {} });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedPost = useMemo(() => post, [post?.postId]); // NOTE: Don't change to [post] - will trigger re-render of comment list
  const { isConnected } = useCommunityState();

  useEffect(() => {
    const disposers: Amity.Unsubscriber[] = [];
    if (isConnected && memoizedPost) {
      const textOnlyParam: Amity.CommentLiveCollection = {
        referenceType: 'post',
        referenceId: memoizedPost.postId,
        dataTypes: {
          values: ['text'],
          matchType: 'exact',
        },
        limit: 5,
      };

      disposers.push(
        CommentRepository.getComments(
          textOnlyParam,
          ({ data: comments, onNextPage, hasNextPage, loading, error }) => {
            setCommentsData(comments);
            setIsLoading(loading);
            setIsError(error);
            setHasMore(hasNextPage || false);
            if (onNextPage) setOnLoadMoreObj({ onLoadMore: onNextPage });
          }
        )
      );

      disposers.push(subscribeTopic(getPostTopic(memoizedPost, SubscriptionLevels.COMMENT)));
    }
    return () => {
      disposers.forEach((fn) => fn());
    };
  }, [isConnected, memoizedPost]);

  return {
    comments: commentsData,
    hasMore,
    onLoadMore: onLoadMoreObj?.onLoadMore,
    isLoading,
    isError,
  };
};

export default useCommunityPostComments;
