import { useEffect } from 'react';

import { Subscription } from 'ts-frontend/types';

import { useOpenModal } from '../../utils/ModalsContextProvider';
import useNYCTeenRoomToRenew from './useNYCTeenRoomToRenew';

interface Props {
  subscriptions: Subscription[] | undefined;
}
const useNYCTeenDTERenewal = ({ subscriptions }: Props) => {
  const { roomID: roomToRenew } = useNYCTeenRoomToRenew({ subscriptions });
  const openModal = useOpenModal();

  useEffect(() => {
    if (roomToRenew) {
      openModal(`/dte-renewal/room/${roomToRenew}/nyc-teenspace`);
    }
  }, [roomToRenew, openModal]);
};

export default useNYCTeenDTERenewal;
