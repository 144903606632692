import { FunctionComponent } from 'react';
import Svg, { G, Circle, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

interface TimeOffIconProps {
  color?: string;
  width?: string;
  height?: string;
  style?: object;
}

const TimeOffIcon: FunctionComponent<TimeOffIconProps> = ({
  color,
  width = '20px',
  height = '20px',
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'time off';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...otherProps}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(-146 -577) translate(146 577)">
          <Circle fill={color || colors.pink} cx={10.5} cy={10.5} r={7.5} />
          <Path
            d="M10.5 21C16.299 21 21 16.299 21 10.5S16.299 0 10.5 0 0 4.701 0 10.5 4.701 21 10.5 21zm0-3a7.5 7.5 0 110-15 7.5 7.5 0 010 15z"
            fill={colors.white}
            fillRule="nonzero"
          />
        </G>
        <Path
          d="M159 589.563a.428.428 0 01-.417.437h-4.166a.428.428 0 01-.417-.438v-2.625c0-.241.187-.437.417-.437h4.166c.23 0 .417.196.417.438v2.625zm.5-5.563a.5.5 0 00-1 0v.5h-4v-.5a.5.5 0 00-1 0v.5a1 1 0 00-1 1v5a1 1 0 001 1h6a1 1 0 001-1v-5a1 1 0 00-1-1v-.5z"
          fill={colors.white}
          transform="translate(-146 -577)"
        />
      </G>
    </Svg>
  );
};

export default TimeOffIcon;
