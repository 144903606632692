import { FunctionComponent, useEffect } from 'react';
import { View, Text, Link, useEmotionTheme } from '@talkspace/react-toolkit';
import styled from '../../core/styled';
import MarketingCommunicationsContainer from '../containers/MarketingCommunications';

import NavBar from '../components/NavBar';

const MarketingCommunicationsWrapper = styled(View)({
  flex: 1,
  height: 'calc(100vh - 69px)',
  backgroundColor: '#f9f9fb',
  paddingTop: 57,
  paddingLeft: 30,
  paddingRight: 30,
  marginTop: 1, // for header border to show
  '@media (max-width: 375px)': {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

const MyAccount: FunctionComponent<{}> = () => {
  useEffect(() => {
    const { document = { title: '' } } = window;
    document.title = 'Notification Preferences';
    return () => {
      document.title = 'Talkspace';
    };
  });
  const { colors } = useEmotionTheme();
  return (
    <>
      <NavBar />
      <MarketingCommunicationsWrapper>
        <View row justify="center" alignSelf="stretch">
          <MarketingCommunicationsContainer />
        </View>
        <View row justify="center" style={{ marginTop: 40 }}>
          <Text style={{ color: colors.grey, textAlign: 'center' }}>
            Experiencing issues with your notification preferences?{' '}
          </Text>
          <Link
            text="Contact support"
            style={{
              whiteSpace: 'nowrap',
              color: colors.greenText,
              fontWeight: 600,
            }}
            href="https://help.talkspace.com/hc/en-us/requests/new"
            target="_blank"
          />
        </View>
      </MarketingCommunicationsWrapper>
    </>
  );
};

export default MyAccount;
