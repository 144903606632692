import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';

const useOnBrowserBack = (history: RouteComponentProps['history'], callback: () => void) => {
  const [isNavigatingBack, setIsNavigatingBack] = useState(false);

  useEffect(() => {
    const cleanup = history.listen(() => {
      if (history.action === 'POP') {
        setIsNavigatingBack(true);
        callback();
        setIsNavigatingBack(false);
      }
    });
    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isNavigatingBack;
};

export default useOnBrowserBack;
