import { WizardScheme } from 'stepWizard';
import { OptionType } from '@talkspace/react-toolkit';
import { ValueType } from 'react-select';
import WelcomeScreen from './components/WelcomeScreen';
import { actionDispatchGetLanguages } from './utils/switchWizardStepActions';

const ENGLISH_ID = 15;

const switchWizardScheme: WizardScheme = {
  wizardVersion: 1,
  wizardType: 'switchTherapist',
  wizardModalTitle: 'change provider',
  welcomeScreen: (handleStartButtonClick) => (
    <WelcomeScreen handleStartButton={handleStartButtonClick} />
  ),
  steps: [
    {
      title:
        'Before we find you a new provider, tell us about the quality of care you received from <!therapistInfo.firstName>.',
      subtitle: 'Your feedback will be anonymously shared with your provider.',
      inputType: 'therapistReview',
      inputState: 'therapistReview',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'autoAdvance',
      },
      secondaryButton: {
        displayText: 'I have not interacted enough with this provider to rate them',
        defaultValue: 'skip-have-not-interacted-enough',
        actionDispatch: `next`,
        whenVisible: 'always',
        displayStyle: 'secondaryFooter',
      },
      displayCondition: 'displayTherapistReview',
      name: 'Therapist Review',
      additionalInfo: ['therapistInfo'],
    },
    {
      title: "What's the reason for switching providers today?",
      inputType: 'select',
      inputState: 'switchReason',
      inputOptions: 'reasonForSwitch',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      displayCondition: 'displayReasonsForSwitching',
      name: 'Reason for switching',
      backOption: 'back',
    },
    {
      title: 'What would you like your new provider to focus on?',
      inputType: 'searchableMultiSelect',
      inputState: 'newTherapistFocus',
      inputOptions: 'presentingProblemsOptions',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      name: 'Focus on',
      backOption: 'back',
    },
    {
      inputType: 'stateCountrySelector',
      inputState: 'countryState',
      nextButton: {
        displayText: 'Next',
        actionDispatch: 'next',
        whenVisible: 'autoAdvance',
      },
      name: 'State country',
      backOption: 'back',
    },
    {
      title: 'What gender would you prefer in a provider?',
      inputType: 'select',
      inputState: 'therapistGender',
      inputOptions: 'therapistGenderOptions',
      nextButton: {
        displayText: 'Next',
        actionDispatch: actionDispatchGetLanguages(),
        whenVisible: 'onSelect',
      },
      secondaryButton: {
        displayText: 'No preference',
        actionDispatch: actionDispatchGetLanguages(),
        defaultValue: null,
      },
      name: 'Therapist gender',
      backOption: 'back',
    },
    {
      title: 'What’s your preferred language?',
      subtitle: 'Based on the state you selected, there are providers who speak these languages:',
      inputType: 'select',
      inputState: 'preferredLanguage',
      inputOptions: 'preferredLanguageOptions',
      nextButton: {
        displayText: 'Next',
        actionDispatch: (value) =>
          value === ENGLISH_ID ? '/switch-provider/therapist-matches' : 'next',
        whenVisible: 'onSelect',
      },
      name: 'Therapist language',
      backOption: 'back',
    },
    {
      title: (additionalInfo: object, wizardContext: any) => {
        const { preferredLanguage, preferredLanguageOptions } = wizardContext;
        const option = preferredLanguageOptions.find(
          (opt: ValueType<OptionType, false>) => opt?.value === preferredLanguage
        );
        return <>Are you willing to wait for a {option.label}-speaking provider?</>;
      },
      subtitle: (additionalInfo: object, wizardContext: any) => {
        const { preferredLanguage, preferredLanguageOptions } = wizardContext;
        const option = preferredLanguageOptions.find(
          (opt: ValueType<OptionType, false>) => opt?.value === preferredLanguage
        );
        return (
          <>
            The majority of our providers speak English, which means it may take longer to connect
            with a {option.label}-speaking provider
          </>
        );
      },
      inputType: 'buttonsOnly',
      inputState: 'preferredLanguageFilter',
      nextButton: {
        displayText: 'Yes, I’ll wait for my preferred language',
        actionDispatch: '/switch-provider/therapist-matches',
        defaultValue: 'hard',
        whenVisible: 'always',
      },
      secondaryButton: {
        displayText: 'No, I want to connect with a provider sooner, even if they speak English',
        actionDispatch: '/switch-provider/therapist-matches',
        defaultValue: 'soft',
        whenVisible: 'always',
      },
      name: 'Therapist language filter type',
      backOption: 'back',
    },
    // {
    //   title: "Are you looking for a provider of a certain ethnicity?",
    //   inputType: "select",
    //   inputState: "therapistEthnicity",
    //   inputOptions: "ethnicityOptions",
    //   nextButton: {
    //     displayText: "Next",
    //     actionDispatch: "/switch-provider/therapist-matches",
    //     whenVisible: "onSelect"
    //   },
    //   secondaryButton: {
    //     displayText: "No preference",
    //     actionDispatch: "/switch-provider/therapist-matches",
    //     defaultValue: null
    //   },
    //   name: "Therapist ethnicity",
    //   backOption: "back"
    // }
  ],
};

export default switchWizardScheme;
