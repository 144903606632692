import { useReducer, useRef, useCallback } from 'react';
import { wizardActionReducer, wizardActionInitialState, WizardActionState } from 'stepWizard';

import treatmentIntakeApiHelper, {
  MatchingIntakePayload,
  IntakeCustomerInformationPayload,
} from '../../treatmentIntakeApiHelper';

export type TeenEmergencyContactPayload = MatchingIntakePayload & IntakeCustomerInformationPayload;

export default function useTeenEmergencyContact(): [
  WizardActionState,
  {
    submitIntakeResults: (
      roomId: number,
      clientUserID,
      payload: TeenEmergencyContactPayload
    ) => void;
  }
] {
  const [state, dispatch] = useReducer(wizardActionReducer, wizardActionInitialState());
  const apiRef = useRef(treatmentIntakeApiHelper);

  function submitMatchingIntake(
    roomId: number,
    clientUserID,
    payload: TeenEmergencyContactPayload
  ) {
    const { ethnicity, dateOfBirth, gender, school, graduationYear, ...emergencyContactPayload } =
      payload;
    dispatch({
      type: 'requestAction',
    });
    apiRef.current
      .updateEmergencyContact(roomId, clientUserID, emergencyContactPayload)
      .then(() =>
        apiRef.current.updateCustomerInformation(clientUserID, {
          ethnicity,
          dateOfBirth,
          gender,
          school,
          graduationYear,
        })
      )
      .then(() => {
        dispatch({ type: 'receiveAction' });
      })
      .catch((error) => {
        dispatch({
          type: 'setError',
          error: (error && error.message) || '',
        });
      });
  }

  return [
    state as WizardActionState,
    {
      submitIntakeResults: useCallback(submitMatchingIntake, []),
    },
  ];
}
