import { Controller, Control } from 'react-hook-form';

import {
  EmotionStyle,
  Label,
  OptionType,
  SelectRounded,
  Tiny,
  View,
  useEmotionTheme,
  useUniqueID,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';

const findOptionByValue = (options: OptionType[], value: string | null): OptionType | undefined =>
  options && options.find((option) => option?.value === value);
const StyledError = styled(Tiny)(({ theme: { colors } }) => {
  return {
    marginTop: 0,
    color: colors.torchRed,
  };
});

interface Props {
  control: Control;
  options: OptionType[];
  fieldName: string;
  errorMessage: string | null;
  isDisabled?: boolean;
  label?: string;
  wrapperStyle?: EmotionStyle;
  borderColor?: string;
}
const SelectRHF = ({
  control,
  fieldName,
  options,
  errorMessage,
  isDisabled,
  label,
  wrapperStyle,
  borderColor,
}: Props) => {
  const { colors } = useEmotionTheme();
  const inputID = useUniqueID('inputID');
  const labelID = useUniqueID('labelID');
  const errorID = useUniqueID('errorID');

  const isError = !!errorMessage;

  return (
    <View style={wrapperStyle}>
      {label && <Label id={labelID}>{label}</Label>}
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { ref, value, ...otherFieldProps } }) => (
          <SelectRounded
            dataQa={`${fieldName}Dropdown`}
            wrapperStyle={{ margin: 0, width: '100%' }}
            inputId={inputID}
            placeholder="Select"
            options={options}
            isError={isError}
            {...otherFieldProps}
            value={findOptionByValue(options, value)}
            onChange={(newValue: OptionType) => {
              otherFieldProps.onChange(newValue.value);
            }}
            isDisabled={isDisabled}
            styles={{
              control: (provided) => {
                return {
                  ...provided,
                  border: `1px solid ${
                    isError ? colors.torchRed : borderColor || colors.periwinkleGrey
                  }`,
                };
              },
              singleValue: (provided) => {
                return {
                  ...provided,
                  fontWeight: 400,
                  fontSize: 16,
                };
              },
            }}
            refCallback={(el) => {
              ref(el?.select?.inputRef);
            }}
          />
        )}
      />
      {errorMessage && <StyledError id={errorID}>{errorMessage}</StyledError>}
    </View>
  );
};

export default SelectRHF;
