import { useRef, useEffect, useMemo } from 'react';

import {
  Button,
  ExtraHuge,
  Large,
  StepTip,
  TherapistCard,
  useWindowWidthState,
  View,
  Spinner,
  useEmotionTheme,
  getScreenSafeAreaInsets,
} from '@talkspace/react-toolkit';
import ScrollViewComponent from 'chat/components/ScrollViewComponent';
import { setFunnelName } from 'ts-frontend/hooks/funnelName';
import { Availability } from 'ts-frontend/entities/Therapist';
import {
  BusinessDays,
  Expertise,
  ImplicitBusinessHoursByDay,
  Info,
  License,
} from 'ts-frontend/types';
import { RouteComponentProps, withRouter } from '@/core/routerLib';
import styled from '@/core/styled';
import useInPlatformMatching from '../../clientChat/hooks/useInPlatformMatching';

import MeetYourProviderWrapper from '../../meetYourProvider/components/MeetYourProviderWrapper';

const TitleText = styled(ExtraHuge)({
  lineHeight: '33px',
  marginTop: 16,
  marginBottom: 16,
  textAlign: 'center',
});

const ScrollView = styled(ScrollViewComponent)(({ theme: { window } }) => {
  const { height } = window;
  return {
    paddingLeft: 14,
    alignItems: 'center',
    height: (13 * height) / 17,
  };
});

const FadeOut = styled(View)({
  backgroundImage: 'linear-gradient(to top, rgba(255,255,255,1),rgba(255,255,255,0))',
  backgroundRepeat: 'repeat-x',
  height: 100,
  pointerEvents: 'none',
  width: '90%',
  marginTop: -98,
  zIndex: 1,
});

const getHeight = (isMobile, isSmall) => {
  const safeAreaInsets = getScreenSafeAreaInsets();
  if (isSmall) {
    return (13 * window.innerHeight) / 17 - safeAreaInsets.top;
  }

  return 'auto';
};

interface Props extends RouteComponentProps<{ roomID: number }> {
  therapist?: {
    additionalLanguages?: string[];
    availability: Availability;
    bestMatch: boolean;
    businessDays: BusinessDays;
    expertise: Expertise;
    id: number;
    imageURL: string;
    implicitBusinessHoursByDay: ImplicitBusinessHoursByDay | undefined;
    info: Info;
    licenses: License[];
    videoURL: string;
  };
  showAvailability: boolean;
  onMainPress: () => void;
}

const MeetYourProvider = ({
  match: {
    params: { roomID },
  },
  therapist,
  showAvailability,
  onMainPress,
}: Props) => {
  const scrollViewRef = useRef<HTMLDivElement | null>(null);

  const { isMobile, isSmall } = useWindowWidthState();
  const [{ error, isLoading, roomPresentingProblems }, { getRoomPresentingProblems }] =
    useInPlatformMatching();
  const mainButtonText = 'Continue';
  const { colors } = useEmotionTheme();

  useEffect(() => {
    getRoomPresentingProblems(roomID);
    setFunnelName('Onboarding');
  }, [getRoomPresentingProblems, roomID]);

  const presentingProblem = useMemo(() => {
    const mappedExpertise = therapist?.expertise?.mappedExpertise;

    if (error || !mappedExpertise?.length) return '';
    const mappedProblems = roomPresentingProblems.filter(
      (problem) =>
        problem.expertiseID &&
        mappedExpertise.find((expertise) => expertise.id === problem.expertiseID)
    );
    return mappedProblems.length ? mappedProblems[0].name.toLowerCase() : null;
  }, [error, roomPresentingProblems, therapist]);

  if (!therapist) return null;

  if (isLoading) {
    <View align="center" justify="center" style={{ height: '100vh' }}>
      <Spinner />
    </View>;
  }

  const presentingProblemCopy = presentingProblem
    ? `${therapist.info.first_name} ${therapist.info.last_name} has been in practice for ${
        therapist.info.yearsInPractice
      } ${
        therapist.info.yearsInPractice === 1 ? 'year' : 'years'
      } and specializes in ${presentingProblem}.`
    : '';

  const mobilePaddingStyle = isMobile ? { paddingLeft: 10, paddingRight: 10 } : {};

  return (
    <View>
      <MeetYourProviderWrapper showLogo>
        <ScrollView ref={scrollViewRef} style={{ height: getHeight(isMobile, isSmall) }}>
          <Large variant="largeMediumTSBlack" style={{ textAlign: 'center', marginTop: 32 }}>
            Your personalized match is here.
          </Large>
          <TitleText style={mobilePaddingStyle}>
            We’re thrilled to introduce you to your provider
          </TitleText>
          <Large style={{ textAlign: 'center', marginBottom: 12, color: colors.permaFiord }}>
            {presentingProblemCopy}
          </Large>
          {therapist && (
            <TherapistCard
              availability={therapist.availability}
              businessDays={therapist.info.businessDays}
              buttonText={mainButtonText}
              firstName={therapist.info.first_name}
              imageURL={therapist.imageURL}
              implicitBusinessHoursByDay={therapist.implicitBusinessHoursByDay}
              joinedYearsAgo={therapist.info.joinedYearsAgo}
              lastName={therapist.info.last_name}
              licenses={therapist.licenses}
              focus={therapist.expertise.focus}
              publicDetails={therapist.info.public_details}
              timeOff={therapist.info.businessHours.businessHoursAvailability.timeOff}
              therapistTimezone={therapist.info.businessHours.therapistTimezone}
              videoURL={therapist.videoURL}
              yearsInPractice={therapist.info.yearsInPractice}
              onMainPress={onMainPress}
              showAvailability={showAvailability}
              hideMainButton={isMobile}
            />
          )}
          <StepTip
            tip="switchExplanation"
            style={{ maxWidth: 343, paddingTop: 0, ...(isMobile && { paddingBottom: 75 }) }}
          />
        </ScrollView>
        {isMobile && (
          <>
            <FadeOut />
            <Button
              text={mainButtonText}
              onPress={onMainPress}
              dataQa="therapistCardMobileMainButtonPress"
              style={{ marginBottom: 0, marginTop: 0 }}
            />
          </>
        )}
      </MeetYourProviderWrapper>
    </View>
  );
};

export default withRouter(MeetYourProvider);
