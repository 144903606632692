import { FunctionComponent, useEffect, useState } from 'react';
import { Large, Spinner, FAQQuestion, UnorderedList, ListItem } from '@talkspace/react-toolkit';
import useVWO from 'ts-analytics/VWO/useVWO';
import { calculateBillingTotalsWithInsuranceEligibility } from 'ts-frontend/helpers/billingUtils';
import { Checkout } from 'checkout';
import { RouteComponentProps, withRouter } from '@/core/routerLib';
import { useRoomOffersState, useRoomOffersActions } from '../hooks/roomOfferContext';
import styled from '@/core/styled';
import { CouponInputMode, RoomOfferRouteParams } from '../types';
import pushOfferRoute from '../utils/pushOfferRoute';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';

const StyledListItem = styled(ListItem)({
  listStyleType: "'- '",
});

const getFAQQuestions = (isChangePlan: boolean, isPsychiatry?: boolean): FAQQuestion[] => {
  if (isChangePlan) {
    return [
      {
        question: 'When does my new plan take effect?',
        answer: (
          <Large variant="largeDarkGrey">
            Your new plan takes effect immediately after purchase. If you are switching to a plan
            with a different billing cycle (i.e. Monthly to Quarterly) your new subscription period
            will begin immediately as well.
          </Large>
        ),
      },
      {
        question: 'I upgraded my plan – how much will I be charged this month?',
        answer: (
          <Large variant="largeDarkGrey">
            When you upgrade your plan you will be charged the prorated difference of the updated
            subscription based on the time remaining in your current plan.
          </Large>
        ),
      },
      {
        question: 'I downgraded my plan – what happens to my previous payment?',
        answer: (
          <Large variant="largeDarkGrey">
            When you downgrade your plan this will result in a credit on the account, which will be
            applied to the balance on your next subscription renewal.
          </Large>
        ),
      },
      {
        question: 'What happens to my unused video credits when I change my plan?',
        answer: (
          <Large variant="largeDarkGrey">
            When you change plans your unused video credits from your previous plan will be included
            in the credit to your account. The credits will not carry over to the new plan.
          </Large>
        ),
      },
    ];
  }

  if (isPsychiatry) {
    return [
      {
        question: 'What is the difference between therapy and psychiatry?',
        answer: (
          <Large variant="largeDarkGrey">
            Therapy is an umbrella-term that refers to counseling and “talk therapy.” Therapists are
            supportive listeners who work with you to discuss personal challenges and devise a plan
            for you to feel better. They are not able to prescribe medication. Psychiatry is a
            medical specialty that leverages medication to treat mental health conditions.
            Psychiatrists and nurse practitioners are licensed medical providers who specialize in
            mental health treatment and provide personalized medication management. You may choose
            to do just therapy, just psychiatry, or both treatments with Talkspace.
          </Large>
        ),
      },
      {
        question: 'What kind of medications are prescribed on Talkspace?',
        answer: (
          <Large variant="largeDarkGrey">
            Talkspace prescribers are able to prescribe all medications, with the exclusion of
            controlled substances. Controlled substances include, but are not limited to: Adderall,
            Ativan, Concerta, Klonopin, Librium, Tranxene, Ritalin, Vyvanse, Valium, Xanax,
            Suboxone.
          </Large>
        ),
      },
      {
        question: 'How does payment for Talkspace psychiatry work?',
        answer: (
          <Large variant="largeDarkGrey">
            Payment for Talkspace psychiatry is session-based. Initial evaluations cost $199.00, and
            follow-up sessions are $125.00. Clients can schedule follow-up sessions directly with
            their providers.
          </Large>
        ),
      },
      {
        question: 'What technology do I need to access Talkspace psychiatry?',
        answer: (
          <Large variant="largeDarkGrey">
            All Talkspace psychiatry sessions happen through Live Video Sessions (LVS) on the
            Talkspace platform. At this time, we support LVS on the web and mobile/tablet versions
            of Talkspace. The Talkspace app is available on both iOS (iPhone, iPad, or iPod) and
            Android devices. You can access Talkspace using cellular connection or WiFi.
          </Large>
        ),
      },
      {
        question: 'Will I speak to the same prescriber every time?',
        answer: (
          <Large variant="largeDarkGrey">
            Yes, you will work with the same prescriber throughout treatment. You can also easily
            switch at any point without any disruptions in treatment.
          </Large>
        ),
      },
      {
        question: 'How do I get my medication?',
        answer: (
          <Large variant="largeDarkGrey">
            You can pick up your medication from your local pharmacy. If you receive mail order
            delivery through your insurance, you will also be able to receive your medication
            through delivery as well.
          </Large>
        ),
      },
    ];
  }

  return [
    {
      question: 'What happens next?',
      answer: (
        <UnorderedList>
          <StyledListItem>
            <Large variant="largeDarkGrey">
              We’ll connect you with your selected therapist in a private, secure “room”
            </Large>
          </StyledListItem>
          <StyledListItem>
            <Large variant="largeDarkGrey">
              You’ll complete a brief treatment intake for your therapist to review
            </Large>
          </StyledListItem>
          <StyledListItem>
            <Large variant="largeDarkGrey">
              You can start sharing with your therapist right away and the therapy process begins!
            </Large>
          </StyledListItem>
        </UnorderedList>
      ),
    },
    {
      question: 'When will I hear from my therapist?',
      answer: (
        <Large variant="largeDarkGrey">
          Talkspace will give you immediate access to your therapy “room.” You can start sharing
          what’s on your mind with your therapist as soon as you’d like, using text, audio, picture,
          and video messages. For our subscription plans we offer guaranteed daily responses 5
          days/week.
        </Large>
      ),
    },
    {
      question: 'Can I cancel my subscription?',
      answer: (
        <Large variant="largeDarkGrey">
          You can cancel your subscription at any time through your Talkspace settings. Note that if
          you cancel before your plan expires, you will still have the remaining time you’ve already
          paid for. You can also change, upgrade, downgrade or pause your subscription at any time.
        </Large>
      ),
    },
    {
      question: 'What if I don’t like my therapist?',
      answer: (
        <Large variant="largeDarkGrey">
          We do our best to find you a therapist who will be the right fit for your needs, but we
          also understand that you might want to explore your options. That’s why we make it easy to
          switch therapists directly from your account.
        </Large>
      ),
    },
    {
      question: 'Are Talkspace therapists licensed?',
      answer: (
        <Large variant="largeDarkGrey">
          Absolutely! At Talkspace we have an extensive, nationwide network of licensed therapists.
          All Talkspace therapists have been carefully vetted and trained to use Talkspace.
        </Large>
      ),
    },
  ];
};

const getTitleAndSubtitle = (
  isChangePlan: boolean = false,
  isPsychiatry?: boolean
): { title: string; subtitle: string } => {
  let title = isPsychiatry ? 'Confirm Your Plan' : 'Review your therapy plan';
  let subtitle = isPsychiatry ? '' : 'You’re ready to begin!';
  if (isChangePlan) {
    title = 'Review your new plan';
    subtitle = 'Your new plan will take effect immediately.';
  }
  return { title, subtitle };
};

interface Props extends RouteComponentProps<RoomOfferRouteParams> {
  scrollTop: number;
  isBackButtonClickedInMobile: boolean;
  titleId?: string;
}

const CheckoutContainer: FunctionComponent<Props> = ({
  history,
  match,
  location,
  scrollTop,
  isBackButtonClickedInMobile,
  titleId,
}) => {
  const {
    selectedSubscription,
    selectedPlanSavings,
    changePlanCheckoutInfo,
    coupon,
    isErrorSubscription,
    trialOfferPrice,
    offer,
    isChangePlan,
    isNoMatches,
    paymentDetails,
    couponInputMode,
    therapistInfo,
    hideProviderInfo,
    isLoadingSubscription,
    insuranceEligibility,
    promoFromBanner,
    promoToDisplay,
    flowID,
  } = useRoomOffersState();

  const [experimentActive, experiment] = useVWO('HIDE_FAQ');

  const {
    setRoomOfferStateAction,
    validateCouponAction,
    checkoutConfirmedAction,
    changeTherapistAction,
  } = useRoomOffersActions();
  const isTrial = offer && offer.trialable ? offer.trialable : false;
  const isPsychiatry = offer?.roomType === 'psychiatry_room';
  const { title, subtitle } = getTitleAndSubtitle(isChangePlan, isPsychiatry);

  const urlParams = new URLSearchParams(location.search);
  const initialCouponCode =
    urlParams.get('coupon') ||
    // pre-fill promo code from VWO banner if validated
    (promoToDisplay?.promoAmount && promoFromBanner?.promoCode) ||
    undefined;
  const [initialCouponApplied, setInitialCouponApplied] = useState(false);
  const selectedSubscriptionAllowsCoupons = selectedSubscription
    ? selectedSubscription.allowCoupons !== false
    : true;

  useEffect(() => {
    if (!initialCouponApplied && initialCouponCode && !coupon.validatedCoupon && !isChangePlan) {
      validateCouponAction(initialCouponCode);
      setInitialCouponApplied(true);
    }
  }, [
    coupon.validatedCoupon,
    initialCouponApplied,
    initialCouponCode,
    isChangePlan,
    validateCouponAction,
  ]);

  useEffect(() => {
    if (isErrorSubscription) {
      setRoomOfferStateAction({ isErrorSubscription: false });
      // If there was a card error, send the user back to the CC page
      pushOfferRoute(match, history, location, '/payment-details');
    }
  }, [history, isErrorSubscription, location, match, setRoomOfferStateAction]);

  if (!selectedSubscription) {
    history.goBack();
    return null;
  }

  if (changePlanCheckoutInfo && changePlanCheckoutInfo.loading) {
    return <Spinner />;
  }
  const validatedCouponArray = coupon.validatedCoupon ? [coupon.validatedCoupon] : undefined;

  const { lineItems, total, savings } = calculateBillingTotalsWithInsuranceEligibility({
    selectedSubscription,
    validatedCoupons: validatedCouponArray,
    trialOfferPrice,
    changePlanCheckoutInfo,
    insuranceEligibility,
  });

  const handleChangeTherapist = () => {
    changeTherapistAction();
  };

  const handleCouponChange = () => {
    setRoomOfferStateAction({ coupon: { ...coupon, status: 'ready' } });
  };

  const handleChangeCard = () => {
    pushOfferRoute(match, history, location, '/payment-details');
  };

  const handleCompleteCheckout = () => {
    checkoutConfirmedAction();
  };

  return (
    <Checkout
      isInMobileFrame={ReactFrameService.instance().isMobileFrame()}
      title={title}
      subtitle={subtitle}
      titleId={titleId}
      planReviewCardTitle="Subscription plan"
      selectedSubscription={selectedSubscription}
      selectedPlanSavings={selectedPlanSavings}
      lineItems={lineItems}
      total={total}
      savings={savings}
      initialCouponCode={initialCouponCode}
      paymentDetails={paymentDetails}
      therapistInfo={therapistInfo}
      hideProviderInfo={hideProviderInfo}
      isTrial={isTrial}
      isProcessing={isLoadingSubscription}
      isCouponInputDisabled={couponInputMode === CouponInputMode.disabled}
      showCouponField={
        selectedSubscriptionAllowsCoupons &&
        !isTrial &&
        !isChangePlan &&
        couponInputMode !== CouponInputMode.hidden &&
        !isPsychiatry
      }
      buttonText={isChangePlan ? 'Confirm plan change' : 'Continue to checkout'}
      faqQuestions={
        experimentActive && !experiment.showFAQ ? [] : getFAQQuestions(!!isChangePlan, isPsychiatry)
      }
      showRenewalNotice
      showInsuranceDisclaimer={insuranceEligibility?.isEligible}
      scrollTop={scrollTop}
      couponState={coupon}
      onCouponChange={handleCouponChange}
      onApplyCoupon={validateCouponAction}
      onChangePayment={handleChangeCard}
      onCompleteCheckout={handleCompleteCheckout}
      onChangePlan={() => history.goBack()}
      onChangeTherapist={handleChangeTherapist}
      isNoMatches={isNoMatches}
      flowID={flowID}
      isBackButtonClickedInMobile={isBackButtonClickedInMobile}
      isOutOfNetwork={
        insuranceEligibility?.isEligible === false && !!insuranceEligibility?.ineligiblePromo
      }
    />
  );
};

export default withRouter(CheckoutContainer);
