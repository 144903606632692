import { FunctionComponent, useEffect, useRef } from 'react';
import { useEmotionTheme, View } from '@talkspace/react-toolkit';
import { useWizardActions, useWizardState } from 'stepWizard';
import { useTSAdminConfig } from 'ts-frontend/hooks/useTSAdminConfig';
import { AdminConfigName } from 'ts-frontend/types';
import { useFlags } from 'launchDarkly/FlagsProvider';
import ActionStatus from '@/components/ActionStatus';
import styled from '@/core/styled';
import useMentalHealth from '../hooks/useMentalHealth';
import { RouteComponentProps } from '../../../core/routerLib/routerLib';
import EmergencyResourcesModal from '../components/EmergencyResourcesModal';
import { State } from '../reducers/mentalHealthWizardState';

const Wrapper = styled(View)({
  alignSelf: 'center',
  width: 340,
});

const SubmitMentalHealth: FunctionComponent<RouteComponentProps> = ({ history }) => {
  const isOnboarding = history.location.pathname.includes('/onboarding');
  const [{ error, isSuccess, isLoading }, { submitIntakeResults }] = useMentalHealth();
  const requestSent = useRef(false);
  const { colors } = useEmotionTheme();
  const { nycTeensIntake } = useFlags();

  const {
    roomID,
    clientUserID,
    sleepQuality,
    sleepAidUse,
    socialSupportSystem,
    previousMentalHealthTreatment,
    previousMentalHealthIssues,
    hospitalizationHistory,
    previousRelativesMentalHealthIssues,
    selectedTraumaticExperience,
    selectedTraumaticFlashbacks,
    selectedGuilt,
    selectedIsolation,
    suicideIdeation,
    suicidePlanning,
    selectedSuicideIdeation,
    selectedSuicidePlanning,
    homicidalIdeation,
    homicidalPlanning,
    selectedAngerAggression,
  } = useWizardState<State>();

  const { onExitClick } = useWizardActions();

  const featureFlaggedPayload = nycTeensIntake
    ? {
        homicidalIdeation,
        homicidalPlanning,
        angerAggression: !!selectedAngerAggression,
      }
    : {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const payload = {
    sleepQuality,
    sleepAidUse,
    socialSupportSystem,
    previousMentalHealthTreatment,
    previousMentalHealthIssues: previousMentalHealthIssues || [],
    hospitalizationHistory,
    previousRelativesMentalHealthIssues: previousRelativesMentalHealthIssues || [],
    traumaticExperience: !!selectedTraumaticExperience,
    traumaticFlashbacks: !!selectedTraumaticFlashbacks,
    guilt: selectedGuilt,
    isolation: selectedIsolation,
    suicideIdeation,
    suicidePlanning,
    isOnboarding,
    ...featureFlaggedPayload,
  };

  const showEmergencyResources =
    selectedSuicideIdeation && selectedSuicidePlanning && !nycTeensIntake;

  useEffect(() => {
    if (!requestSent.current && roomID && payload) {
      requestSent.current = true;
      submitIntakeResults(roomID, clientUserID, payload);
    }
  }, [clientUserID, payload, roomID, submitIntakeResults]);

  const { data: treatmentIntakeInOnboardingActive } = useTSAdminConfig(
    AdminConfigName.TREATMENT_INTAKE_IN_ONBOARDING
  );

  useEffect(() => {
    if (isSuccess && !showEmergencyResources) {
      if (treatmentIntakeInOnboardingActive && isOnboarding) {
        history.replace(`/room/${roomID}/onboarding/medical-health/source/onboarding`);
      } else {
        history.push(`/treatment-intake-launcher/room/${roomID}/source/chat`, { initStep: 1 });
      }
    }
  }, [
    treatmentIntakeInOnboardingActive,
    history,
    isSuccess,
    roomID,
    showEmergencyResources,
    isOnboarding,
  ]);

  return (
    <Wrapper>
      {showEmergencyResources ? (
        <EmergencyResourcesModal history={history} />
      ) : (
        (error || isLoading) && (
          <ActionStatus
            isLoading={isLoading}
            isError={!!error}
            errorTitle="Something Went Wrong"
            errorSubTitle="Please check your internet connection and try again."
            errorButtonText="Close"
            errorButtonAction={onExitClick}
            titleStyles={{ fontWeight: 800, fontSize: 29, marginTop: 25 }}
            subTitleStyles={{
              fontSize: 16,
              color: colors.darkGray,
              marginTop: 13,
              marginBottom: 42,
              width: 200,
              textAlign: 'center',
            }}
          />
        )
      )}
    </Wrapper>
  );
};

export default SubmitMentalHealth;
