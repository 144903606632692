import { FunctionComponent } from 'react';
import Svg, { G, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

interface PencilIconProps {
  color?: string;
  width?: number;
  height?: number;
  style?: object;
}

const PencilIcon: FunctionComponent<PencilIconProps> = ({
  color,
  width = 32,
  height = 32,
  ...otherProps
}: {
  color?: string;
  width?: number;
  height?: number;
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'pencil';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...otherProps}
    >
      <G
        transform="translate(-30 -734) translate(19 724) rotate(45 10.743 25.935)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Path
          d="M10.88 21.288l3.19 5.649 3.062-5.642V5.682c0-.974-.78-1.758-1.745-1.758h-2.761c-.967 0-1.745.784-1.745 1.758v15.606z"
          stroke={color || colors.green}
          strokeWidth={1.5}
        />
        <Path
          fill={color || colors.green}
          d="M10.7093998 7.67537648H17.7093998V9.17537648H10.7093998z"
        />
        <Path
          fill={color || colors.green}
          d="M10.2356477 19.7456971H17.2356477V21.2456971H10.2356477z"
        />
      </G>
    </Svg>
  );
};

export default PencilIcon;
