import * as VWOTool from 'ts-analytics/VWO/VWOSetup';
import BrazePlugin from 'braze-cordova-sdk';
import createTSAnalyticsTracker from 'ts-analytics/trackers/tsAnalytics';
import { getIsIonic } from 'ts-ionic/ionicUtils';
import { initBraze, getBraze } from './tools/brazeSetup';
import { initMixpanel } from './tools/mixpanelSetup';
import * as mixpanel from './tools/mixpanelSetup';

import {
  TrackerTypes,
  EventNameTypes,
  EventProperties,
  TrackerFunctions,
  BrazeContentCards,
} from './trackerTypes';
import { isCommercialRoute, getPlatform } from './helpers';
import appConfig, { detectEnv } from '../configs';

const tsAnalyticsTracker = createTSAnalyticsTracker({
  endpoint: `${appConfig.analytics.mixpanel.tsAnalyticsEndpoint}/event`,
});

type AppMode = 'CLINICAL' | 'COMMERCIAL';

const APP_MODE: AppMode = isCommercialRoute() ? 'COMMERCIAL' : 'CLINICAL';

type DebugTypes = 'console' | 'console and remote' | 'remote';

const NOOP = () => undefined;

const DEBUG = detectEnv() !== 'prod';
/**
 * Only used when DEBUG is true
 */
const DEBUG_MODE: DebugTypes = 'console and remote';

const resolveOnTimeout = (durationSeconds: number) =>
  new Promise<true>((resolve) => setTimeout(() => resolve(true), durationSeconds * 1000));

const logEvent = (name: string, ...params: unknown[]) => {
  if (DEBUG && ['console', 'console and remote'].includes(DEBUG_MODE))
    console.log(`[Tracking][${name}]`, ...params); // eslint-disable-line no-console
};

/**
 * Assumes that callback is the last parameter of the function
 */

const swallowErrors =
  <T extends unknown[], U extends unknown>(fun: (...args: T) => Promise<U>) =>
  async (...args: T): Promise<U | void> => {
    try {
      const res = await fun(...args);
      return res;
    } catch (err) {
      if (DEBUG) {
        console.warn('[Tracking] - Error in function', fun.name, err); // eslint-disable-line no-console
      }
      return Promise.resolve();
    }
  };

interface Scope extends TrackerFunctions {
  /**
   * Note: userID is only set when one of the trackers is done loading
   * OR when debug mode is on
   */
  userID: string;
  calledSetUserID: boolean;
}

const scope: Scope = {
  mixpanel: {
    track: NOOP,
    identify: NOOP,
    peopleSet: NOOP,
    alias: NOOP,
    getDistinctId: NOOP,
  },
  braze: {
    logCustomEvent: NOOP,
    changeUser: NOOP,
    requestContentCardsRefresh: NOOP,
    getCachedContentCards: NOOP,
    logCardImpressions: NOOP,
    logCardClick: NOOP,
    logContentCardsDisplayed: NOOP,
    subscribeToContentCardsUpdates: NOOP,
    removeSubscription: NOOP,
    setPushNotificationSubscriptionType: NOOP,
  },
  userID: '',
  calledSetUserID: false,
};

let resolveBrazePromise: (value?: unknown) => void;
const brazeLoadedPromise = new Promise<void>((resolve) => {
  const sdkResolve = () => {
    if (getIsIonic()) {
      scope.braze = BrazePlugin;
    } else {
      scope.braze = getBraze();
    }
    resolve();
  };
  resolveBrazePromise = sdkResolve;
});

let resolveMixpanelPromise: (value?: unknown) => void;
const mixpanelLoadedPromise = new Promise<void>((resolve) => {
  const sdkResolve = () => {
    scope.mixpanel = mixpanel;
    resolve();
  };
  resolveMixpanelPromise = sdkResolve;
});

const waitBrazeReady = async (callback: Function) => {
  if (!getIsIonic()) {
    await brazeLoadedPromise;
  }
  return callback();
};

const waitMixpanelReady = async (callback: Function) => {
  await mixpanelLoadedPromise;
  return callback();
};

const logErrorIfMissingUserID = () => {
  if (!scope.calledSetUserID) {
    // eslint-disable-next-line no-console
    console.error(
      new Error('Cannot track event without a userID. Did you forget to use `setTrackerUserID`?')
    );
  }
};

const completedSetup = () => !!scope.userID;

const initTracker = async (apiWrapper) => {
  logEvent('initTracker');

  if (detectEnv() !== 'prod')
    // eslint-disable-next-line no-console
    console.log(`%c App mode: ${APP_MODE}`, 'color: red; font-size:20px;');

  if (DEBUG && !['remote', 'console and remote'].includes(DEBUG_MODE)) return;

  if (getIsIonic()) {
    scope.braze = BrazePlugin;
  } else {
    initBraze(resolveBrazePromise);
  }

  initMixpanel(resolveMixpanelPromise);

  tsAnalyticsTracker.init(apiWrapper);

  if (APP_MODE === 'COMMERCIAL') {
    VWOTool.initVWO();
  }
};

// eslint-disable-next-line @typescript-eslint/no-use-before-define
type TrackEventParams = Parameters<typeof trackEvent>[]; // eslint-disable-line no-use-before-define
let trackEventCachedCalls: TrackEventParams = [];

const addCachedCall = async (
  action: EventNameTypes,
  props: EventProperties,
  trackers?: TrackerTypes[]
) => {
  if (trackEventCachedCalls.length > 100) {
    trackEventCachedCalls = [[action, props, trackers]];
  } else {
    trackEventCachedCalls.push([action, props, trackers]);
  }
};

const trackEvent = async (
  action: EventNameTypes,
  props: EventProperties,
  trackers?: TrackerTypes[],
  skipUserIDAndSetupCheck = false,
  mpCB?: () => void
) => {
  if (!skipUserIDAndSetupCheck) {
    logErrorIfMissingUserID();
    if (!completedSetup()) {
      addCachedCall(action, props, trackers);
      return;
    }
  }

  logEvent('trackEvent', action, props, trackers);
  if (DEBUG && !['remote', 'console and remote'].includes(DEBUG_MODE)) return;

  const {
    eventCategory,
    eventCategoryKey = 'Event Category',
    eventProperty = '',
    eventPropertyValue,
    ...otherProps
  } = props;

  if (!trackers || trackers.includes('mixpanel'))
    waitMixpanelReady(() =>
      scope.mixpanel
        .track(
          action,
          {
            [eventCategoryKey]: eventCategory,
            ...(eventProperty ? { [eventProperty]: eventPropertyValue } : {}),
            ...otherProps,
            Platform: otherProps.Platform || getPlatform(),
          },
          ['Complete Purchase', 'Register'].includes(action) ? { send_immediately: true } : {}
        )
        .then(mpCB)
    );
  if (trackers && trackers.includes('tsAnalytics'))
    tsAnalyticsTracker.trackEvent(action, props).catch((err) => {
      if (DEBUG) {
        console.warn('[Tracking] - Error in function', 'tsAnalyticsTracker', err); // eslint-disable-line no-console
      }
    });
};

const clearCachedCalls = () => {
  trackEventCachedCalls.forEach((params) => trackEvent(...params));
  trackEventCachedCalls = [];
};

const setPeople = async (data: Record<string, any>, trackers?: TrackerTypes[]) => {
  logEvent('setPeople', data, trackers);
  if (DEBUG && !['remote', 'console and remote'].includes(DEBUG_MODE)) return;

  const promises: Promise<void>[] = [];

  if (!trackers || trackers.includes('mixpanel'))
    promises.push(waitMixpanelReady(() => scope.mixpanel.peopleSet(data)));

  await Promise.all(promises);
};

const setAlias = async (userID: string, trackers?: TrackerTypes[]) => {
  logEvent('setAlias', userID, trackers);
  if (DEBUG && !['remote', 'console and remote'].includes(DEBUG_MODE)) return;

  if (!trackers || trackers.includes('mixpanel'))
    waitMixpanelReady(() => scope.mixpanel.alias(userID));
};

const getMixpanelDistinctID = () => waitMixpanelReady(() => scope.mixpanel.getDistinctId());

const setTrackerUserID = async (userID: string | number, reportLoginEvent = false) => {
  if (!userID) {
    // eslint-disable-next-line no-console
    console.warn('[Tracking] - Called setTrackerUserID with an invalid userID');
    return;
  }

  if (scope.calledSetUserID && scope.userID === String(userID)) return;

  scope.calledSetUserID = true;
  tsAnalyticsTracker.setUserID(userID);
  logEvent('setTrackerUserID', userID);
  if (DEBUG && !['remote', 'console and remote'].includes(DEBUG_MODE)) {
    scope.userID = String(userID);
    return;
  }

  waitBrazeReady(() => {
    scope.userID = String(userID);
    scope.braze.changeUser(String(userID));
    if (reportLoginEvent) scope.braze.logCustomEvent('user_login');
  });
  waitMixpanelReady(() => {
    if (typeof userID === 'string') {
      scope.mixpanel.identify(userID);
    } else {
      scope.mixpanel.alias(String(userID));
    }
    scope.userID = String(userID);
  });

  await Promise.race([mixpanelLoadedPromise, resolveOnTimeout(60)]);
  clearCachedCalls();
};

const requestContentCardsRefresh = async () =>
  waitBrazeReady(() => scope.braze.requestContentCardsRefresh?.());

const getCachedContentCards = async (): Promise<BrazeContentCards> =>
  new Promise((resolve) => {
    waitBrazeReady(() => {
      resolve(scope.braze.getCachedContentCards?.());
    });
  });

const logCardClick = async (card) => waitBrazeReady(() => scope.braze.logCardClick?.(card, true));

const logCardImpressions = async (cards) =>
  waitBrazeReady(() => scope.braze.logCardImpressions?.(cards, true));

const logContentCardsDisplayed = async () =>
  waitBrazeReady(() => scope.braze.logContentCardsDisplayed?.());

const subscribeToContentCardsUpdates = async (cb: Function): Promise<string> =>
  waitBrazeReady(() => scope.braze.subscribeToContentCardsUpdates?.(cb));

const removeSubscription = async (subscriptionGuid: string) =>
  waitBrazeReady(() => scope.braze.removeSubscription?.(subscriptionGuid));

const braze = {
  requestContentCardsRefresh,
  getCachedContentCards,
  logCardClick,
  logCardImpressions,
  logContentCardsDisplayed,
  subscribeToContentCardsUpdates,
  removeSubscription,
};

const VWO = {
  trackPurchaseGoal: VWOTool.trackPurchaseGoal,
  trackRegisterGoal: VWOTool.trackRegisterGoal,
  trackVWOPageView: VWOTool.trackVWOPageView,
  trackVWOConversionGoal: VWOTool.trackVWOConversionGoal,
  trackInitiatedQMGoal: VWOTool.trackInitiatedQMGoal,
  trackQMLeadCapturedGoal: VWOTool.trackQMLeadCapturedGoal,
  trackSelectedPlanGoal: VWOTool.trackSelectedPlanGoal,
};

const safeInitTracker = swallowErrors(initTracker);
const safeSetTrackerUserID = swallowErrors(setTrackerUserID);
const safeTrackEvent = swallowErrors(trackEvent);
const safeSetPeople = swallowErrors(setPeople);
const safeSetAlias = swallowErrors(setAlias);
const safeGetMixpanelDistinctID = swallowErrors(getMixpanelDistinctID);

export {
  tsAnalyticsTracker,
  safeInitTracker as initTracker,
  safeSetTrackerUserID as setTrackerUserID,
  safeTrackEvent as trackEvent,
  safeSetPeople as setPeople,
  safeSetAlias as setAlias,
  safeGetMixpanelDistinctID as getMixpanelDistinctID,
  braze,
  VWO,
};
