import { Currency } from 'ts-frontend/types';
import apiHelper from '../../core/api/apiHelper';
import apiWrapper from '../../core/api/apiWrapper';

interface CancelSubscriptionApiResponse {
  expirationDate: string;
}

type couponValueType = 'fixed' | 'percent';

interface IsEligibleForCouponApiResponse {
  eligible: boolean;
  coupon: {
    value: number;
    currency: Currency;
    valueType: couponValueType;
  };
}

export interface CancelSubscriptionData {
  clientMetGoals?: boolean;
  cancellationReason?: string;
}

function cancelSubscription(
  roomID: number,
  data: CancelSubscriptionData
): Promise<CancelSubscriptionApiResponse> {
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/cancel-subscription`, data)
    .then((res) => res.data.data);
}

async function isEligibleForCoupon(
  roomID: number,
  couponCode
): Promise<IsEligibleForCouponApiResponse | undefined> {
  return apiWrapper
    .get(
      `${
        apiHelper().apiEndpoint
      }/v2/payments/room/${roomID}/voucher-coupon/${couponCode}/is-eligible`
    )
    .then((res) => {
      if (!res.data || !res.data.data) throw new Error('failed to check coupon eligibility');
      return res.data.data;
    });
}

const cancellationWizardApiHelper = { cancelSubscription, isEligibleForCoupon };

export default cancellationWizardApiHelper;
