import { FunctionComponent, Suspense } from 'react';
import { Spinner, Screen } from '@talkspace/react-toolkit';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { RouteComponentProps } from '../../core/routerLib/routerLib';
import WelcomeBackCTReactivationContainer from '../containers/WelcomeBackCTReactivation';

interface RouteParams {
  roomID: string;
}

const WelcomeBackCTReactivation: FunctionComponent<RouteComponentProps<RouteParams>> = ({
  match,
}) => {
  const { roomID } = match.params;
  const closeModal = useCloseModal();
  return (
    <Screen
      onCloseButtonClick={closeModal}
      closeButtonDataQa="CTReactivationScreenCloseButton"
      backButtonDataQa="CTReactivationScreenBackButton"
    >
      <Suspense fallback={<Spinner isLoading />}>
        <WelcomeBackCTReactivationContainer roomID={Number(roomID)} />
      </Suspense>
    </Screen>
  );
};

export default WelcomeBackCTReactivation;
