import Svg, { Circle, Defs, Ellipse, Path, Rect, Stop } from 'svgs';
import { useUniqueID } from '@talkspace/react-toolkit';

type Props = {
  width?: number;
  height?: number;
};

const MedicalHistory = ({ width = 265, height = 240 }: Props) => {
  const title = 'Medical questionnaire';
  const gradientID = useUniqueID('paint0_linear_2804_30851');
  const gradientID1 = useUniqueID('paint1_linear_2804_30851');
  const gradientID2 = useUniqueID('paint2_linear_2804_30851');
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 265 240"
      aria-label={title}
      title={title}
      fill="none"
    >
      <Circle cx="126.68" cy="120" r="120" fill={`url(#${gradientID})`} />
      <Path
        d="M0 23.4563C11.8254 18.1835 14.1865 5.62175 13.8889 0C15.9524 17.0591 22.1561 23.1978 25 24.1348C15 25.6856 11.7063 36.0244 11.3095 41C10.119 25.0265 3.27381 22.6485 0 23.4563Z"
        fill="#BCCCFF"
      />
      <Path
        d="M127 54.4563C138.825 49.1835 141.187 36.6217 140.889 31C142.952 48.0591 149.156 54.1978 152 55.1348C142 56.6856 138.706 67.0244 138.31 72C137.119 56.0265 130.274 53.6485 127 54.4563Z"
        fill="#BCCCFF"
      />
      <Rect x="13" y="68.4755" width="100" height="117.525" fill={`url(#${gradientID1})`} />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.1688 139.811C37.1688 139.103 37.7413 138.528 38.4476 138.528H88.5755C89.2817 138.528 89.8542 139.103 89.8542 139.811C89.8542 140.52 89.2817 141.094 88.5755 141.094H38.4476C37.7413 141.094 37.1688 140.52 37.1688 139.811Z"
        fill="#8A98AF"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.1688 152.642C37.1688 151.933 37.7413 151.358 38.4476 151.358H88.5755C89.2817 151.358 89.8542 151.933 89.8542 152.642C89.8542 153.35 89.2817 153.925 88.5755 153.925H38.4476C37.7413 153.925 37.1688 153.35 37.1688 152.642Z"
        fill="#8A98AF"
      />
      <Path
        d="M62.7443 128.521C58.243 120.309 45.7792 109.104 40.11 104.528C46.3504 104.528 57.2882 113.595 61.977 118.128C69.8542 103.348 84.4408 91.0994 90.7494 86.8227C81.8491 96.471 68.3709 118.642 62.7443 128.521Z"
        fill="#0FC1A7"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.9258 75.4038H95.7366C95.694 74.1635 95.0461 71.0672 92.7954 68.6038C90.4385 66.0241 87.8124 65.2454 84.3906 64.2308C83.728 64.0343 83.0356 63.829 82.3095 63.6C82.1058 63.5358 81.9077 63.4737 81.7148 63.4132C77.6684 62.1449 75.9096 61.5936 73.1023 57.1849C70.1611 52.566 68.4987 50 63.5115 50C58.5243 50 56.4783 52.4377 55.0716 55.6453C54.8084 56.2455 54.5944 56.8502 54.3836 57.446C53.4679 60.0339 52.6121 62.4528 48.0384 63.6C47.3683 63.7681 46.6639 63.9234 45.9385 64.0833C40.5728 65.2664 34.0662 66.701 31.9258 75.4038ZM63.7671 61.2906C65.9565 61.2906 67.7313 59.5098 67.7313 57.3132C67.7313 55.1166 65.9565 53.3358 63.7671 53.3358C61.5777 53.3358 59.8029 55.1166 59.8029 57.3132C59.8029 59.5098 61.5777 61.2906 63.7671 61.2906Z"
        fill="#8A98AF"
      />
      <Path
        d="M193.111 100.194C218.235 92.3668 234.09 91.2516 238.877 91.6724C244.053 100.257 243.033 112.083 241.875 116.922C222.938 119.132 220.886 121.972 216.31 124.655C211.733 127.338 204 136.176 199.897 140.752C196.615 144.413 183.695 149.327 177.646 151.325C179.276 152.378 182.569 155.176 182.696 157.954C182.854 161.425 181.591 161.268 179.539 160.005C177.898 158.995 171.701 156.533 168.808 155.429L163.916 157.322C167.23 160.005 170.229 164.582 169.282 167.738C168.335 170.894 166.125 168.054 164.547 166.791C163.285 165.781 157.919 162.267 155.394 160.636C149.923 162.846 138.161 167.612 134.879 169C130.775 170.736 129.986 167.896 130.933 162.215C131.691 157.67 139.771 152.43 143.716 150.379C142.822 149.695 140.939 147.696 140.56 145.171C140.181 142.646 142.506 135.597 143.716 132.388C142.454 131.126 140.402 130.021 124.147 130.968C107.893 131.915 96.3724 127.022 95.5833 125.286C94.7942 123.551 94.3208 121.499 97.477 121.657C100.633 121.815 107.261 121.657 127.935 119.447C148.608 117.238 161.707 109.979 193.111 100.194Z"
        fill="#C46F31"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.914 132.061C144.14 131.795 144.538 131.763 144.804 131.988C145.24 132.359 146.034 132.784 147.251 133.165C148.457 133.542 150.043 133.864 152.029 134.05C154.504 134.282 157.852 134.167 160.941 134.048C161.063 134.043 161.184 134.039 161.305 134.034C162.708 133.98 164.045 133.928 165.189 133.914C166.417 133.9 167.483 133.927 168.183 134.058L168.625 134.141L168.691 134.585C168.81 135.375 168.408 136.556 167.305 137.903C166.179 139.28 164.254 140.918 161.112 142.649C157.223 144.792 153.299 146.615 150.253 147.96C148.874 148.569 147.673 149.081 146.738 149.479C146.64 149.521 146.545 149.561 146.453 149.601C145.966 149.808 145.567 149.979 145.265 150.113C144.949 150.253 144.778 150.335 144.72 150.37C144.421 150.549 144.033 150.453 143.854 150.154C143.675 149.855 143.771 149.467 144.07 149.287C144.209 149.204 144.458 149.089 144.754 148.958C145.065 148.82 145.472 148.646 145.958 148.439C146.05 148.4 146.145 148.359 146.243 148.318C147.178 147.919 148.372 147.41 149.743 146.805C152.772 145.467 156.659 143.661 160.503 141.543C163.548 139.865 165.332 138.321 166.328 137.104C167.002 136.281 167.289 135.642 167.395 135.228C166.84 135.18 166.098 135.166 165.204 135.177C164.078 135.19 162.758 135.241 161.35 135.296C161.23 135.3 161.11 135.305 160.99 135.31C157.925 135.427 154.486 135.549 151.911 135.307C149.857 135.115 148.181 134.779 146.874 134.37C145.576 133.964 144.603 133.475 143.986 132.95C143.721 132.725 143.688 132.326 143.914 132.061Z"
        fill="#A95519"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M154.558 134.208C154.905 134.243 155.158 134.552 155.123 134.899C155.018 135.956 154.836 137.627 154.632 139.21C154.431 140.773 154.203 142.306 154 143.051C153.858 143.57 153.686 144.063 153.542 144.476C153.488 144.632 153.437 144.776 153.394 144.907C153.311 145.157 153.254 145.351 153.225 145.505C153.198 145.651 153.207 145.704 153.207 145.704C153.207 145.704 153.207 145.703 153.206 145.702C153.306 146.036 153.117 146.388 152.783 146.488C152.449 146.588 152.097 146.399 151.997 146.065C151.914 145.789 151.941 145.503 151.985 145.272C152.03 145.031 152.109 144.769 152.196 144.509C152.249 144.348 152.306 144.184 152.365 144.017C152.504 143.617 152.652 143.193 152.782 142.719C152.957 142.075 153.176 140.636 153.38 139.049C153.582 137.48 153.762 135.821 153.867 134.774C153.902 134.427 154.211 134.174 154.558 134.208Z"
        fill="#A95519"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M161.672 134.21C162.018 134.251 162.265 134.566 162.224 134.912C162.067 136.215 161.857 139.305 162.218 141.355C162.279 141.698 162.05 142.026 161.707 142.086C161.363 142.147 161.036 141.918 160.975 141.574C160.58 139.332 160.811 136.088 160.97 134.761C161.012 134.415 161.326 134.168 161.672 134.21Z"
        fill="#A95519"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M163.768 157.346C163.887 157.674 163.718 158.036 163.391 158.155L156.447 160.68C156.119 160.799 155.757 160.63 155.638 160.302C155.519 159.975 155.688 159.613 156.016 159.493L162.959 156.968C163.287 156.849 163.649 157.018 163.768 157.346Z"
        fill="#A95519"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M177.656 151.98C177.775 152.308 177.606 152.67 177.278 152.789L170.334 155.314C170.007 155.433 169.645 155.264 169.525 154.937C169.406 154.609 169.575 154.247 169.903 154.128L176.847 151.603C177.174 151.484 177.537 151.653 177.656 151.98Z"
        fill="#A95519"
      />
      <Path
        d="M230.095 123.455C231.067 122.557 235.477 120.279 245.336 118.347C250.902 117.256 256.72 117.429 256.72 117.429C260.047 113.077 258.11 91.9816 247.135 78.4113C234.148 85.9562 228.315 81.6483 224.623 80.6025C219.897 79.2639 214.718 82.279 216.784 86.8857C218.851 91.4923 223.402 90.1424 224.795 94.0137C226.187 97.885 222.338 99.2661 223.731 103.137C225.124 107.009 230.893 106.842 233.089 111.736C234.845 115.652 231.825 121.18 230.095 123.455Z"
        fill={`url(#${gradientID2})`}
      />
      <Ellipse
        cx="241.831"
        cy="113.046"
        rx="1.26224"
        ry="1.25465"
        transform="rotate(-114.161 241.831 113.046)"
        fill="#FF0075"
      />
      <Ellipse
        cx="232.159"
        cy="99.7022"
        rx="1.26224"
        ry="1.25465"
        transform="rotate(-114.161 232.159 99.7022)"
        fill="#FF0075"
      />
      <Ellipse
        cx="222.895"
        cy="85.1818"
        rx="1.26224"
        ry="1.25465"
        transform="rotate(-114.161 222.895 85.1818)"
        fill="#FF0075"
      />
      <Ellipse
        cx="239.935"
        cy="87.9137"
        rx="1.26224"
        ry="1.25465"
        transform="rotate(-114.161 239.935 87.9137)"
        fill="#FF0075"
      />
      <Ellipse
        cx="249.354"
        cy="96.4738"
        rx="1.26224"
        ry="1.25465"
        transform="rotate(-114.161 249.354 96.4738)"
        fill="#FF0075"
      />
      <Ellipse
        cx="253.071"
        cy="110.644"
        rx="1.26224"
        ry="1.25465"
        transform="rotate(-114.161 253.071 110.644)"
        fill="#FF0075"
      />
      <Defs>
        <linearGradient
          id={gradientID}
          x1="246.68"
          y1="120"
          x2="6.68017"
          y2="120"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#E3E8FB" />
          <Stop offset="1" stopColor="#E4F2F5" />
        </linearGradient>
        <linearGradient
          id={gradientID1}
          x1="63"
          y1="68.4755"
          x2="63"
          y2="186"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.416667" stopColor="#D7DEF3" />
          <Stop offset="1" stopColor="#BECBE0" />
        </linearGradient>
        <linearGradient
          id={gradientID2}
          x1="222.345"
          y1="106.18"
          x2="254.668"
          y2="91.68"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.460938" stopColor="#F5A623" />
          <Stop offset="1" stopColor="#C46F31" />
        </linearGradient>
      </Defs>
    </Svg>
  );
};

export default MedicalHistory;
