import { FunctionComponent, Dispatch, SetStateAction } from 'react';
import { BaseButton, View, Large, RedX } from '@talkspace/react-toolkit';

import styled from '../../../core/styled/styled';

const CloseButton = styled(BaseButton)(({ theme: { colors } }) => {
  return { marginTop: 40, marginBottom: 60, backgroundColor: colors.white };
});

interface Props {
  error?: string;
  onCancelPress: () => void;
  setIsCancel?: Dispatch<SetStateAction<boolean>>;
}

const ErrorModal: FunctionComponent<Props> = (props) => {
  const { error, onCancelPress, setIsCancel } = props;
  const onPress = () => {
    // prevents keyboard user from getting "lost" when succss modal closes
    if (setIsCancel) setIsCancel(true);
    else {
      onCancelPress();
    }
  };

  return (
    <>
      <View style={{ marginBottom: 20 }}>
        <RedX size={74} />
      </View>
      <Large variant="largeDarkGrey">
        {error || 'Something went wrong. Please try again later.'}
      </Large>
      <CloseButton onPress={onPress}>
        <Large variant="largeBoldWideGreen">Close</Large>
      </CloseButton>
    </>
  );
};

export default ErrorModal;
