import { useState } from 'react';
import { useWindowWidthState } from '@talkspace/react-toolkit/src/hooks/windowWidthContext';

import { ResponsiveScreen } from 'checkout';
import { StickyHeader } from 'offer';
import { Screen } from '@talkspace/react-toolkit';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import CheckoutContainer from '../containers/CheckoutContainer';

const CheckoutScreen = () => {
  const { isMobile } = useWindowWidthState();

  const [scrollTop, setScrollTop] = useState(0);
  const [stickHeader, setStickHeader] = useState(false);

  const closeModal = useCloseModal();

  const handleOnScrollChange = (scrollTopVal: number) => {
    setStickHeader(scrollTopVal > 50);
    setScrollTop(scrollTopVal);
  };

  const ScreenComponent = isMobile ? ResponsiveScreen : Screen;

  return (
    <ScreenComponent
      showCloseButton
      title={isMobile ? ' ' : undefined}
      onScrollChange={handleOnScrollChange}
      onCloseButtonClick={() => closeModal()}
    >
      <StickyHeader
        stickHeader={stickHeader}
        style={{ height: 66, backgroundColor: 'white', boxShadow: 'unset' }}
      />
      <CheckoutContainer scrollTop={scrollTop} />
    </ScreenComponent>
  );
};

export default CheckoutScreen;
