import { FunctionComponent, useCallback } from 'react';
import { useWizardActions, useWizardState } from 'stepWizard';
import { RouteComponentProps, withRouter } from '@/core/routerLib';
import SwitchReason from '../components/SwitchReason';
import { State } from '../reducers/switchWizardState';

const SwitchReasonContainer: FunctionComponent<RouteComponentProps> = ({ history }) => {
  const { roomID, reasonForSwitch, newTherapistMatch, isBookingSwitch } = useWizardState<State>();
  const { setStepResponse } = useWizardActions();

  const handleOnContinuePress = useCallback(
    (selectedValue?: string | null) => {
      setStepResponse({
        selectedValue: selectedValue || '',
        stepName: 'Reason for switching',
        additionalData: {
          therapistInfo: {
            id: newTherapistMatch?.therapistID || 0,
            firstName: newTherapistMatch?.therapistFirstName || '',
          },
        },
        stateUpdate: {
          switchReason: selectedValue,
        },
      });
      if (isBookingSwitch) {
        history.push(`/switch-provider/booking/${roomID}/confirm-booking`, {
          roomID,
        });
      } else {
        history.push('/switch-provider/therapist-confirmation', {
          roomID,
        });
      }
    },
    [
      history,
      roomID,
      newTherapistMatch?.therapistFirstName,
      newTherapistMatch?.therapistID,
      setStepResponse,
      isBookingSwitch,
    ]
  );

  return <SwitchReason onPress={handleOnContinuePress} selectOptions={reasonForSwitch} />;
};

export default withRouter(SwitchReasonContainer);
