import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TeenspaceProfileSetup from '../components/TeenspaceProfileSetup';
import { useCommunityState } from '../../hooks/CommunityContext';
import { generateUniqueName } from '../../utils/helpers';
import PersonalInformationEditTeenspaceCommunityAppSettingsModal from '../../../myAccount/components/PersonalInformationEditTeenspaceCommunityModal/PersonalInformationEditTeenspaceCommunityAppSettingsModal';

interface DisplayName {
  displayName: string | undefined;
}
interface Props {
  handleOnClose?: () => void;
  isPersonalInfoModal?: boolean;
}

const schema: yup.SchemaOf<DisplayName> = yup.object().shape({
  displayName: yup
    .string()
    .required('Enter a display name')
    .max(20, 'Display name cannot exceed 20  characters')
    .min(5, 'Display name must be at least 5 characters'),
});

const TeenspaceProfileSetupContainer: React.VFC<Props> = ({
  handleOnClose,
  isPersonalInfoModal,
}) => {
  const { displayName } = useCommunityState();
  const methods = useForm<DisplayName>({
    resolver: yupResolver(schema),
    defaultValues: {
      displayName: displayName && isPersonalInfoModal ? displayName : generateUniqueName(),
    },
  });
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleOnClose} style={{ height: '100%' }}>
        {isPersonalInfoModal && handleOnClose ? (
          <PersonalInformationEditTeenspaceCommunityAppSettingsModal onClose={handleOnClose} />
        ) : (
          <TeenspaceProfileSetup />
        )}
      </form>
    </FormProvider>
  );
};

export default TeenspaceProfileSetupContainer;
