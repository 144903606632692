import { Spinner } from '@talkspace/react-toolkit';
import { useEffect, useMemo, VoidFunctionComponent } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { ChargeType } from 'ts-frontend/types';
import { useHistory, useLocation, useParams } from '@/core/routerLib';

import useQueryClientTransactions from '../../hooks/useQueryClientTransactions';

interface Props {
  clientUserID: number;
}

const PaymentWizardFork: VoidFunctionComponent<Props> = ({ clientUserID }) => {
  const history = useHistory();
  const { roomID } = useParams<{ roomID: string }>();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const source = params.get('source');
  const { pendingInvoicesChargeTypes } =
    useFlags<{ pendingInvoicesChargeTypes: Array<ChargeType> }>();

  const isClientTransactionsEnabled =
    !!pendingInvoicesChargeTypes && pendingInvoicesChargeTypes.length > 0 && !!clientUserID;

  const { data: pendingInvoices, isError: isPendingInvoicesError } = useQueryClientTransactions(
    {
      chargeTypes: pendingInvoicesChargeTypes,
      clientUserID: clientUserID!,
    },
    {
      enabled: isClientTransactionsEnabled,
    }
  );

  const checkedPendingInvoices = pendingInvoices || isPendingInvoicesError;

  useEffect(() => {
    if (!isClientTransactionsEnabled) {
      history.replace(`/payment-wizard/room/${roomID}/pay?source=${source}`);
    }
    if (!checkedPendingInvoices) {
      return;
    }
    if (pendingInvoices && pendingInvoices.length > 0) {
      history.replace(
        `/payment-wizard/room/${roomID}/pending-invoices?source=updatePaymentMethodWithPendingInvoices`
      );
    } else {
      history.replace(`/payment-wizard/room/${roomID}/pay?source=${source}`);
    }
  }, [
    history,
    pendingInvoices,
    roomID,
    source,
    checkedPendingInvoices,
    isClientTransactionsEnabled,
  ]);

  return <Spinner />;
};

export default PaymentWizardFork;
