import { EmotionStyle } from './styled';

export const isNative = () => typeof window.document === 'undefined';

export const isWeb = () => !isNative();

// TODO: @Luis - We don't need these helper methods anymore.
/**
 * Returns the given object only if the code is running on a website, otherwise returns an empty object
 * @param   {style} EmotionStyle
 * @returns EmotionStyle
 */
export const webOnlyStyle = (style: EmotionStyle): EmotionStyle => (isWeb() ? style : {});

/**
 * Returns the given object only if the code is running on a react-native app, otherwise returns an empty object
 * @param   {style} EmotionStyle
 * @returns EmotionStyle
 * @deprecated
 */
export const nativeOnlyStyle = (style: unknown): EmotionStyle =>
  isNative() ? (style as EmotionStyle) : {};
