import Svg, { G, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const LinkIcon = (props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'link';
  return (
    <Svg
      width="13px"
      height="13px"
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 13 13"
      {...props}
    >
      <G
        transform="translate(-20 -1322) translate(20 1318) translate(0 4)"
        fill={colors.green}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      >
        <Path
          d="M13 10.4a2.6 2.6 0 01-2.6 2.6H2.6A2.6 2.6 0 010 10.4V2.6A2.6 2.6 0 012.6 0h2.6v1.733H2.6c-.441 0-.806.331-.86.758l-.007.108v7.802c0 .477.389.866.866.866h7.802a.867.867 0 00.866-.866l-.001-2.618L13 7.8v2.6z"
          fillRule="nonzero"
        />
        <Path
          transform="rotate(45 8.148 4.902)"
          d="M7.28089421 0.37403888L9.01422754 0.414772503 9.01422754 9.42897018 7.28089421 9.42897018z"
        />
        <Path d="M11.2783927 6.07839273L11.2666667 1.73333333 6.95130093 1.75130093 6.93333333 3.63709063e-13 13 3.63709063e-13 13 6.06666667z" />
      </G>
    </Svg>
  );
};
export default LinkIcon;
