import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button, Modal, View, TextDS, Spinner } from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import DisplayNameInputField from '../../../community/components/DisplayNameInputField';
import useMutationPutCommunityUserData from '../../../community/hooks/useMutationPutCommunityUserData';
import { DISPLAY_NAME_ERRORS, GENERIC_ERROR } from '../../../community/constants';
import { trackCommunityDisplayNameChanged } from '../../../community/utils/analytics';
import useQueryCommunityUserData from '../../../community/hooks/useQueryCommunityUserData';
import { getUserData } from '../../../auth/helpers/token';

const modalContentStyle = {
  width: '100%',
};
const SaveButton = styled(Button)(({ theme: { colorRoles, spacing } }) => {
  return {
    width: '100%',
    marginTop: spacing('space400'),
    backgroundColor: colorRoles.button.brandPrimarySurfaceDefault,
  };
});

const CancelButton = styled(Button)(({ theme: { colorRoles } }) => {
  return {
    color: colorRoles.button.brandPrimarySurfaceDefault,
    backgroundColor: colorRoles.button.brandPrimaryIconDefault,
    width: '100%',
  };
});

interface Props {
  onClose: () => void;
}
const PersonalInformationEditTeenspaceCommunityAppSettingsModal = ({ onClose }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    trigger,
    formState: { errors },
    watch,
    setError,
  } = useFormContext();
  const { id: clientUserID } = getUserData();
  const { refetch: refetchCommunityUserData } = useQueryCommunityUserData({ clientUserID });
  const displayName = watch('displayName');

  const [isSuggestedName, setIsSuggestedName] = useState<boolean>(true);

  const isFormError = displayName.length > 20 || displayName.length < 5 || displayName === '';
  const {
    mutate: putCommunityUserData,
    reset: resetMutation,
    isError,
  } = useMutationPutCommunityUserData();
  const canContinue = !isError && !errors.displayName;

  const onSavePress = async () => {
    setIsLoading(true);
    if (isFormError) {
      trigger('displayName');
      setIsLoading(false);
    } else {
      putCommunityUserData(
        { displayName },
        {
          onSuccess: (data) => {
            refetchCommunityUserData();
            trackCommunityDisplayNameChanged({ location: 'accountSettings', isSuggestedName });
            if (data) {
              onClose();
            }
          },
          onError: (err) => {
            const putCommunityUserDataStatusCode = err.data?.status;
            if (putCommunityUserDataStatusCode !== 200) {
              const putCommunityUserDataErrorMessage =
                (putCommunityUserDataStatusCode &&
                  DISPLAY_NAME_ERRORS[putCommunityUserDataStatusCode]) ||
                GENERIC_ERROR;
              setError('displayName', { message: putCommunityUserDataErrorMessage });
            }
            setIsLoading(false);
          },
        }
      );
    }
  };

  return (
    <Modal.Panel onBackdropPress={onClose} contentViewStyle={modalContentStyle}>
      {isLoading ? (
        <View justify="center" align="center" style={{ marginTop: 140 }}>
          <Spinner />
        </View>
      ) : (
        <View align="center">
          <TextDS variant="heading2xl">Teenspace community</TextDS>
          <DisplayNameInputField
            setIsSuggestedName={setIsSuggestedName}
            resetMutation={resetMutation}
            isInModalScreen
          />
          <SaveButton
            onPress={onSavePress}
            dataQa="teenspaceCommunityAppSettingsSaveButton"
            text="Save"
            disabled={!canContinue}
            style={{ maxWidth: '343px' }}
          />
          <CancelButton
            dataQa="teenspaceCommunityAppSettingsCancelButton"
            text="Cancel"
            onPress={onClose}
          />
        </View>
      )}
    </Modal.Panel>
  );
};

export default PersonalInformationEditTeenspaceCommunityAppSettingsModal;
