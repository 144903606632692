import { FunctionComponent } from 'react';
import { TouchableView, Big } from '@talkspace/react-toolkit';
import styled from '../../../core/styled';
import goalsSpriteSheet from '../../assets/goals_sprite_sheet.png';

const SPRITE_WIDTH = 1460;
const SPRITE_HEIGHT = 720;
const RECT_WIDTH = SPRITE_WIDTH / 4;
const RECT_HEIGHT = SPRITE_HEIGHT / 3;

// gets the top-left position of mask over the 4X3 sprite
function getBackgroundPosition(index: number): string {
  return `${RECT_WIDTH * (index % 4)}px ${RECT_HEIGHT * Math.floor(index / 4)}px`;
}

interface GoalCardProps {
  text?: string;
  index: number;
  onPress?: () => void;
  shouldRenderAsSquare?: boolean;
}

const Wrapper = styled(TouchableView)<{
  index: number;
  shouldRenderAsSquare?: boolean;
}>(({ index, shouldRenderAsSquare, theme: { window } }) => {
  const { isDesktop } = window;
  return {
    width: isDesktop && !shouldRenderAsSquare ? 365 : 240,
    height: 240,
    borderRadius: 15,
    backgroundImage: `url(${goalsSpriteSheet})`,
    backgroundPosition: getBackgroundPosition(index),
    backgroundSize: `${SPRITE_WIDTH}px ${SPRITE_HEIGHT}px`,
    paddingLeft: 25,
    paddingRight: 30,
    justifyContent: 'center',
    margin: 5,
  };
});

const GoalCard: FunctionComponent<GoalCardProps> = ({
  index,
  onPress,
  text,
  shouldRenderAsSquare,
}) => (
  <Wrapper index={index} onPress={onPress} shouldRenderAsSquare={shouldRenderAsSquare}>
    <Big variant="bigWide" style={{ color: 'white' }}>
      {text}
    </Big>
  </Wrapper>
);

export default GoalCard;
